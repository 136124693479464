import {InitStoreAction} from "../init";

export const CHANGE_RESET_PASSWORD_FIELD_VALUE = 'CHANGE_RESET_PASSWORD_FIELD_VALUE';
export const CHANGE_RESET_PASSWORD_ERROR_VALUE = 'CHANGE_RESET_PASSWORD_ERROR_VALUE';

export interface IResetPasswordState {
  fields: { [name: string]: string }
  errors: { [name: string]: string }
}

interface ChangeResetPasswordFieldValueAction {
  type: typeof CHANGE_RESET_PASSWORD_FIELD_VALUE
  payload: {
    field: string
    value: any
  }
}

interface ChangeResetPasswordErrorValueAction {
  type: typeof CHANGE_RESET_PASSWORD_ERROR_VALUE
  payload: {
    field: string
    value: string
  }
}

export type ResetPasswordActionTypes = ChangeResetPasswordFieldValueAction |
  ChangeResetPasswordErrorValueAction |
  InitStoreAction
