/**
 * @author arodic / https://github.com/arodic
 */
import {vec3, mat4} from "gl-matrix";
import {defaultSculptConfig, sculpt as sculptTypes} from "../../peregrine";
import {SculptPointAction} from "../../peregrine/processor";
import {peregrineId} from "../../peregrine/id";
import StateManager from "../../peregrine/sculpt/states/StateManager";
import lod from "lodash";
import Smooth from "../../peregrine/sculpt/editing/tools/Smooth";

let Sculpt = function () {
  this.sMeshes = [];
  this.selectSMeshes = [];
  this.sMesh = null;
  this.currentStroke = undefined;
  this.strokeIds = [];
  this.strokeStateIndices = [];
  this.strokeCursor = 0;
  this.objIds = [];
  this.objDescs = [];

  this.sceneProxy = new sculptTypes.SceneProxy(this);

  this._rendering = {
    shader: 0,
    matcapImage: 0,
    transparency: 0,
    flatShading: false,
    wireframe: false,
    symmetricPlane: false,
  };

  this.dispose = function () {
    this.sceneProxy.getStateManager().reset();
  };

  this.attachSculpt = function (meshes) {
    this.sMeshes = meshes;
    this.sMesh = meshes[0] || null;
    this.selectSMeshes = meshes[0] ? [meshes[0]] : [];
    this.strokeIds = [];
    this.strokeStateIndices = [];
    this.strokeCursor = 0;
    this.sceneProxy.getStateManager().reset();
  };

  this.resetHistory = function () {
    this.strokeIds = [];
    this.strokeStateIndices = [];
    this.strokeCursor = 0;
    this.sceneProxy.getStateManager().reset();
  };

  this.detach = function () {
    this.sMeshes = [];
    this.sMesh = null;
    this.selectSMeshes = [];
    this.strokeIds = [];
    this.strokeStateIndices = [];
    this.strokeCursor = 0;
    this.sceneProxy.getStateManager().reset();
  };

  this.applyStroke = function (stroke) {
    let config = lod.merge(lod.cloneDeep(defaultSculptConfig), stroke.config);
    this.setConfig(config, true);

    if (stroke.symmetryInfos) {
      for (let i = 0; i < Math.min(this.sMeshes.length, stroke.symmetryInfos.length); ++i) {
        this.sMeshes[i].setSymmetryNormal(vec3.fromValues(...stroke.symmetryInfos[i].normal));
        this.sMeshes[i].setSymmetryOffset(stroke.symmetryInfos[i].offset);
      }
    }

    this.sceneProxy._width = stroke.width;
    this.sceneProxy._height = stroke.height;

    mat4.copy(this.sceneProxy.getProjection(), stroke.projection);

    for (let i = 0, l = stroke.actions.length; i < l; ++i) {
      let action = stroke.actions[i];

      this.sceneProxy._mouseX = stroke.xs[i];
      this.sceneProxy._mouseY = stroke.ys[i];
      sculptTypes.Tablet.pressure = stroke.pressures[i];

      let mouseX = this.sceneProxy._mouseX;
      let mouseY = this.sceneProxy._mouseY;

      if (action === SculptPointAction.Start) {

        let tool = this.sceneProxy.getSculptManager().getCurrentTool();
        let canEdit = tool.start();

        if (canEdit)
          this.sceneProxy.setAction(sculptTypes.Enums.Action.SCULPT_EDIT);

      } else if (action === SculptPointAction.Update) {

        this.sceneProxy.getSculptManager().preUpdate();
        this.sceneProxy.getSculptManager().update();

      } else if (action === SculptPointAction.UpdateContinuous) {

        let tool = this.sceneProxy.getSculptManager().getCurrentTool();
        tool.updateContinuous();

      } else if (action === SculptPointAction.End) {

        this.sceneProxy.getSculptManager().end();
        this.sceneProxy.setAction(sculptTypes.Enums.Action.NOTHING);
        this.sceneProxy.getStateManager().cleanNoop();
        break;

      }

      this.sceneProxy._lastMouseX = mouseX;
      this.sceneProxy._lastMouseY = mouseY;
    }

    this.strokeIds.splice(this.strokeCursor, this.strokeIds.length, stroke.id);
    this.strokeStateIndices.splice(this.strokeCursor, this.strokeStateIndices.length, this.sceneProxy.getStateManager().getCurUndoIndex());
    this.strokeCursor = this.strokeIds.length;
  };

  function getLatestCommonIndexes(aIds, aC, bIds, bC) {
    if (bIds.length === 0)
      return [0, 0];
    let index = aIds.findIndex(id => id === bIds[0]);
    if (index < 0)
      return [0, 0];

    let off = 0;

    while (index + off < aC && off < bC) {
      if (aIds[index + off] !== bIds[off])
        break;
      ++off;
    }

    if (index + off > aC || off > bC) {
      return [0, 0];
    }

    return [index + off, off];
  }

  this.syncSculptStrokes = function (objIds, objDescs, strokes) {
    // console.log('compare sculpt strokes', this.strokeIds, strokes.map(stroke => stroke.id));
    if (this.strokeStateIndices.filter((v, i) => v !== i).length > 0)
      console.warn('sculpt state indices error', this.strokeStateIndices);

    let desc = objDescs[0] || '[]';
    let from = desc.indexOf('[');
    let to = desc.indexOf(']');
    let prevStrokeIds = [];

    if (from >= 0 && to > from)
      prevStrokeIds = desc.substring(from + 1, to).split(',').slice(1);
    let strokeIds = [...prevStrokeIds, ...strokes.map(stroke => stroke.id)];
    let pc = prevStrokeIds.length;
    let indexes = [0, 0];

    if (this.strokeIds.length === 0) {
      if (!lod.isEqual(this.objIds, objIds))
        return false;
      indexes = [pc, 0];
    } else {
      indexes = getLatestCommonIndexes(strokeIds, strokeIds.length, this.strokeIds, this.strokeCursor);

      if ((indexes[0] <= 0 || indexes[1] <= 0) && (
        (strokeIds[indexes[0]] && this.strokeIds[indexes[1]] && strokeIds[indexes[0]] !== this.strokeIds[indexes[1]]) ||
        !lod.isEqual(this.objIds, objIds)
      ))
        return false;

      for (let i = indexes[0]; i < strokeIds.length; ++i) {
        if (strokeIds[i] !== this.strokeIds[indexes[1] + i - indexes[0]] && i < pc)
          return false;
      }
    }

    for (let i = this.strokeCursor; i > indexes[1]; --i)
      this.undo();

    for (let i = indexes[0]; i < strokeIds.length; ++i) {
      if (strokeIds[i] === this.strokeIds[this.strokeCursor]) {
        this.redo();
      } else {
        this.applyStroke(strokes[i - pc]);
      }
    }

    this.objIds = objIds;
    this.objDescs = objDescs;

    return true;
  }

  this.collectConfig = function (config) {
    let brush = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.BRUSH);
    let inflate = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.INFLATE);
    let twist = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.TWIST);
    let smooth = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.SMOOTH);
    let flatten = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.FLATTEN);
    let pinch = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.PINCH);
    let crease = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.CREASE);
    let drag = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.DRAG);
    let move = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.MOVE);
    let masking = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.MASKING);
    let localScale = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.LOCALSCALE);

    return {
      tool: {
        sculptTool: this.sceneProxy.getSculptManager().getToolIndex(),
        symmetry: this.sceneProxy.getSculptManager().getSymmetry(),
        changeSymmetryPlane: false,
        symmetryPlaneX: false,
        symmetryPlaneY: false,
        symmetryPlaneZ: false,
        continuous: this.sceneProxy.getSculptManager().isUsingContinuous(),
        canBeContinuous: this.sceneProxy.getSculptManager().canBeContinuous(),
        cameraTargetOnPick: this.cameraTargetOnPick,
        brush: {
          radius: brush._radius,
          intensity: brush._intensity,
          negative: brush._negative,
          clay: brush._clay,
          culling: brush._culling,
          accumulate: brush._accumulate,
          lockPosition: brush._lockPosition,
          idAlpha: brush._idAlpha,
        },
        inflate: {
          radius: inflate._radius,
          intensity: inflate._intensity,
          negative: inflate._negative,
          culling: inflate._culling,
          idAlpha: inflate._idAlpha,
          lockPosition: inflate._lockPosition,
        },
        twist: {
          radius: twist._radius,
          culling: twist._culling,
          idAlpha: twist._idAlpha,
        },
        smooth: {
          radius: smooth._radius,
          intensity: smooth._intensity,
          culling: smooth._culling,
          tangent: smooth._tangent,
          idAlpha: smooth._idAlpha,
          lockPosition: smooth._lockPosition,
        },
        flatten: {
          radius: flatten._radius,
          intensity: flatten._intensity,
          negative: flatten._negative,
          culling: flatten._culling,
          idAlpha: flatten._idAlpha,
          lockPosition: flatten._lockPosition,
        },
        pinch: {
          radius: pinch._radius,
          intensity: pinch._intensity,
          negative: pinch._negative,
          culling: pinch._culling,
          idAlpha: pinch._idAlpha,
          lockPosition: pinch._lockPosition,
        },
        crease: {
          radius: crease._radius,
          intensity: crease._intensity,
          negative: crease._negative,
          culling: crease._culling,
          idAlpha: crease._idAlpha,
          lockPosition: crease._lockPosition,
        },
        drag: {
          radius: drag._radius,
          idAlpha: drag._idAlpha,
        },
        move: {
          radius: move._radius,
          intensity: move._intensity,
          topoCheck: move._topoCheck,
          alongNormal: move._negative,
          idAlpha: move._idAlpha,
        },
        masking: {
          radius: masking._radius,
          hardness: masking._hardness,
          intensity: masking._intensity,
          negative: masking._negative,
          culling: masking._culling,
          idAlpha: masking._idAlpha,
          lockPosition: masking._lockPosition,
          thickness: masking._thickness,
          clear: !!(config && config.tool && config.tool.masking && config.tool.masking.clear),
          invert: !!(config && config.tool && config.tool.masking && config.tool.masking.invert),
          extract: !!(config && config.tool && config.tool.masking && config.tool.masking.extract),
          extractRemaining: !!(config && config.tool && config.tool.masking && config.tool.masking.extractRemaining),
          blur: !!(config && config.tool && config.tool.masking && config.tool.masking.blur),
          sharpen: !!(config && config.tool && config.tool.masking && config.tool.masking.sharpen),
        },
        localScale: {
          radius: localScale._radius,
          culling: localScale._culling,
          idAlpha: localScale._idAlpha,
        }
      },
      rendering: {
        ...this._rendering,
        showMatcapImage: this._rendering.shader === 0
      },
      topology: {
        resolution: sculptTypes.Remesh.RESOLUTION,
        smoothing: sculptTypes.Remesh.SMOOTHING,
        block: sculptTypes.Remesh.BLOCK,
        dynamicTopology: this.sMesh ? (!!this.sMesh.isDynamic) : false,
        remesh: false, //!!(config && config.topology && config.topology.remesh),
        remeshRemaining: false, //!!(config && config.topology && config.topology.remeshRemaining),
        mirror: false, //!!(config && config.topology && config.topology.mirror),
        voxelRemesh: !!(config && config.topology && config.topology.voxelRemesh),
        voxelRemeshRemaining: !!(config && config.topology && config.topology.voxelRemeshRemaining),
        fillHole: !!(config && config.topology && config.topology.fillHole),
        smooth: !!(config && config.topology && config.topology.smooth),
      }
    };
  };

  this.setConfig = function (config) {
    let hasCommit = false;
    let brush = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.BRUSH);
    let inflate = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.INFLATE);
    let twist = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.TWIST);
    let smooth = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.SMOOTH);
    let flatten = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.FLATTEN);
    let pinch = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.PINCH);
    let crease = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.CREASE);
    let drag = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.DRAG);
    let move = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.MOVE);
    let masking = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.MASKING);
    let localScale = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.LOCALSCALE);

    if (config.tool) {
      if (config.tool.sculptTool !== undefined)
        this.sceneProxy.getSculptManager().setToolIndex(config.tool.sculptTool);
      if (config.tool.symmetry !== undefined)
        this.sceneProxy.getSculptManager().setSymmetry(config.tool.symmetry);
      if (config.tool.continuous !== undefined)
        this.sceneProxy.getSculptManager().setContinuous(config.tool.continuous);
      if (config.tool.cameraTargetOnPick !== undefined)
        this.cameraTargetOnPick = config.tool.cameraTargetOnPick;

      if (config.tool.brush) {
        if (config.tool.brush.radius !== undefined)
          brush._radius = config.tool.brush.radius;
        if (config.tool.brush.intensity !== undefined)
          brush._intensity = config.tool.brush.intensity;
        if (config.tool.brush.negative !== undefined)
          brush._negative = config.tool.brush.negative;
        if (config.tool.brush.clay !== undefined)
          brush._clay = config.tool.brush.clay;
        if (config.tool.brush.culling !== undefined)
          brush._culling = config.tool.brush.culling;
        if (config.tool.brush.accumulate !== undefined)
          brush._accumulate = config.tool.brush.accumulate;
        if (config.tool.brush.idAlpha !== undefined)
          brush._idAlpha = config.tool.brush.idAlpha;
        if (config.tool.brush.lockPosition !== undefined)
          brush._lockPosition = config.tool.brush.lockPosition;
      }
      if (config.tool.inflate) {
        if (config.tool.inflate.radius !== undefined)
          inflate._radius = config.tool.inflate.radius;
        if (config.tool.inflate.intensity !== undefined)
          inflate._intensity = config.tool.inflate.intensity;
        if (config.tool.inflate.negative !== undefined)
          inflate._negative = config.tool.inflate.negative;
        if (config.tool.inflate.culling !== undefined)
          inflate._culling = config.tool.inflate.culling;
        if (config.tool.inflate.idAlpha !== undefined)
          inflate._idAlpha = config.tool.inflate.idAlpha;
        if (config.tool.inflate.lockPosition !== undefined)
          inflate._lockPosition = config.tool.inflate.lockPosition;
      }
      if (config.tool.twist) {
        if (config.tool.twist.radius !== undefined)
          twist._radius = config.tool.twist.radius;
        if (config.tool.twist.culling !== undefined)
          twist._culling = config.tool.twist.culling;
        if (config.tool.twist.idAlpha !== undefined)
          twist._idAlpha = config.tool.twist.idAlpha;
      }
      if (config.tool.smooth) {
        if (config.tool.smooth.radius !== undefined)
          smooth._radius = config.tool.smooth.radius;
        if (config.tool.smooth.intensity !== undefined)
          smooth._intensity = config.tool.smooth.intensity;
        if (config.tool.smooth.culling !== undefined)
          smooth._culling = config.tool.smooth.culling;
        if (config.tool.smooth.tangent !== undefined)
          smooth._tangent = config.tool.smooth.tangent;
        if (config.tool.smooth.idAlpha !== undefined)
          smooth._idAlpha = config.tool.smooth.idAlpha;
        if (config.tool.smooth.lockPosition !== undefined)
          smooth._lockPosition = config.tool.smooth.lockPosition;
      }
      if (config.tool.flatten) {
        if (config.tool.flatten.radius !== undefined)
          flatten._radius = config.tool.flatten.radius;
        if (config.tool.flatten.intensity !== undefined)
          flatten._intensity = config.tool.flatten.intensity;
        if (config.tool.flatten.negative !== undefined)
          flatten._negative = config.tool.flatten.negative;
        if (config.tool.flatten.culling !== undefined)
          flatten._culling = config.tool.flatten.culling;
        if (config.tool.flatten.idAlpha !== undefined)
          flatten._idAlpha = config.tool.flatten.idAlpha;
        if (config.tool.flatten.lockPosition !== undefined)
          flatten._lockPosition = config.tool.flatten.lockPosition;
      }
      if (config.tool.pinch) {
        if (config.tool.pinch.radius !== undefined)
          pinch._radius = config.tool.pinch.radius;
        if (config.tool.pinch.intensity !== undefined)
          pinch._intensity = config.tool.pinch.intensity;
        if (config.tool.pinch.negative !== undefined)
          pinch._negative = config.tool.pinch.negative;
        if (config.tool.pinch.culling !== undefined)
          pinch._culling = config.tool.pinch.culling;
        if (config.tool.pinch.idAlpha !== undefined)
          pinch._idAlpha = config.tool.pinch.idAlpha;
        if (config.tool.pinch.lockPosition !== undefined)
          pinch._lockPosition = config.tool.pinch.lockPosition;
      }
      if (config.tool.crease) {
        if (config.tool.crease.radius !== undefined)
          crease._radius = config.tool.crease.radius;
        if (config.tool.crease.intensity !== undefined)
          crease._intensity = config.tool.crease.intensity;
        if (config.tool.crease.negative !== undefined)
          crease._negative = config.tool.crease.negative;
        if (config.tool.crease.culling !== undefined)
          crease._culling = config.tool.crease.culling;
        if (config.tool.crease.idAlpha !== undefined)
          crease._idAlpha = config.tool.crease.idAlpha;
        if (config.tool.crease.lockPosition !== undefined)
          crease._lockPosition = config.tool.crease.lockPosition;
      }
      if (config.tool.drag) {
        if (config.tool.drag.radius !== undefined)
          drag._radius = config.tool.drag.radius;
        if (config.tool.drag.idAlpha !== undefined)
          drag._idAlpha = config.tool.drag.idAlpha;
      }
      if (config.tool.move) {
        if (config.tool.move.radius !== undefined)
          move._radius = config.tool.move.radius;
        if (config.tool.move.intensity !== undefined)
          move._intensity = config.tool.move.intensity;
        if (config.tool.move.topoCheck !== undefined)
          move._topoCheck = config.tool.move.topoCheck;
        if (config.tool.move.alongNormal !== undefined)
          move._negative = config.tool.move.alongNormal;
        if (config.tool.move.idAlpha !== undefined)
          move._idAlpha = config.tool.move.idAlpha;
      }
      if (config.tool.masking) {
        if (config.tool.masking.radius !== undefined)
          masking._radius = config.tool.masking.radius;
        if (config.tool.masking.hardness !== undefined)
          masking._hardness = config.tool.masking.hardness;
        if (config.tool.masking.intensity !== undefined)
          masking._intensity = config.tool.masking.intensity;
        if (config.tool.masking.thickness !== undefined)
          masking._thickness = config.tool.masking.thickness;
        if (config.tool.masking.negative !== undefined)
          masking._negative = config.tool.masking.negative;
        if (config.tool.masking.culling !== undefined)
          masking._culling = config.tool.masking.culling;
        if (config.tool.masking.idAlpha !== undefined)
          masking._idAlpha = config.tool.masking.idAlpha;
        if (config.tool.masking.lockPosition !== undefined)
          masking._lockPosition = config.tool.masking.lockPosition;

        if (config.tool.masking.blur) {
          masking.blur();
          hasCommit = true;
        }

        if (config.tool.masking.sharpen) {
          masking.sharpen();
          hasCommit = true;
        }

        if (config.tool.masking.clear) {
          masking.clear();
          hasCommit = true;
        }

        if (config.tool.masking.invert) {
          masking.invert();
          hasCommit = true;
        }

        if (config.tool.masking.extract) {
          this.extract();
          hasCommit = true;
        }

        if (config.tool.masking.extractRemaining) {
          this.extractRemaining();
          hasCommit = true;
        }
      }
      if (config.tool.localScale) {
        if (config.tool.localScale.radius !== undefined)
          localScale._radius = config.tool.localScale.radius;
        if (config.tool.localScale.culling !== undefined)
          localScale._culling = config.tool.localScale.culling;
        if (config.tool.localScale.idAlpha !== undefined)
          localScale._idAlpha = config.tool.localScale.idAlpha;
      }
    }

    if (config.topology) {
      if (config.topology.resolution !== undefined)
        sculptTypes.Remesh.RESOLUTION = config.topology.resolution;
      if (config.topology.smoothing !== undefined)
        sculptTypes.Remesh.SMOOTHING = config.topology.smoothing;
      if (config.topology.block !== undefined)
        sculptTypes.Remesh.BLOCK = config.topology.block;
      if (config.topology.dynamicTopology !== undefined && this.sMesh) {
        this.setDynamic(config.topology.dynamicTopology);
        hasCommit = true;
      }

      if (config.topology.voxelRemesh) {
        this.remesh(true);
        hasCommit = true;
      }

      if (config.topology.voxelRemeshRemaining) {
        this.remesh(true);
        hasCommit = true;
      }

      if (config.topology.fillHole) {
        this.fillHole();
        hasCommit = true;
      }

      if (config.topology.smooth) {
        this.smooth();
        hasCommit = true;
      }
    }

    return hasCommit;
  };

  this.redo = function () {
    this.sceneProxy.getStateManager().redo();
    this.sceneProxy.getSculptManager().end();
    this.strokeCursor = Math.min(this.strokeCursor + 1, this.strokeIds.length);
    this.sceneProxy.render();
  };

  this.undo = function () {
    this.sceneProxy.setAction(sculptTypes.Enums.Action.NOTHING);
    this.sceneProxy.getSculptManager().end();
    this.sceneProxy.getStateManager().undo();
    this.strokeCursor = Math.max(this.strokeCursor - 1, 0);
    this.sceneProxy.render();
  };

  this.hasUndo = function () {
    return this.sceneProxy.getStateManager().hasUndo();
  }

  this.hasRedo = function () {
    return this.sceneProxy.getStateManager().hasRedo();
  }

  this.remesh = function (manifold) {
    let mesh = this.sMesh;
    if (mesh) {
      let wasDynamic = mesh.isDynamic;
      let staticMesh = this.convertToStaticMesh(mesh);

      let newMesh = sculptTypes.Remesh.remesh([staticMesh], staticMesh, manifold);

      if (wasDynamic)
        newMesh = new sculptTypes.MeshDynamic(newMesh);

      newMesh.updateBuffers();
      this.replaceMesh(mesh, newMesh);
    }
  };

  this.extract = function () {
    let mesh = this.sMesh;
    if (mesh) {
      let wasDynamic = mesh.isDynamic;
      let staticMesh = this.convertToStaticMesh(mesh);

      let masking = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.MASKING);
      let newMesh = masking.extract(staticMesh);

      if (newMesh) {
        if (wasDynamic)
          newMesh = new sculptTypes.MeshDynamic(newMesh);

        newMesh.updateBuffers();
        this.replaceMesh(mesh, newMesh);
      }
    }
  };

  this.extractRemaining = function () {
    let mesh = this.sMesh;
    if (mesh) {
      let wasDynamic = mesh.isDynamic;
      let staticMesh = this.convertToStaticMesh(mesh);

      let masking = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.MASKING);
      let newMesh = masking.extractRemaining(staticMesh);

      if (newMesh) {
        if (wasDynamic)
          newMesh = new sculptTypes.MeshDynamic(newMesh);

        newMesh.updateBuffers();
        this.replaceMesh(mesh, newMesh);
      }
    }
  };

  this.fillHole = function () {
    let mesh = this.sMesh;
    if (mesh) {
      let wasDynamic = mesh.isDynamic;
      let staticMesh = this.convertToStaticMesh(mesh);

      let newMesh = sculptTypes.HoleFilling.createClosedMesh(staticMesh);

      if (wasDynamic)
        newMesh = new sculptTypes.MeshDynamic(newMesh);

      newMesh.updateBuffers();
      this.replaceMesh(mesh, newMesh);
    }
  };

  this.smooth = function () {
    var mesh = this.getMesh();
    var smooth = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.SMOOTH);
    smooth.pushState();

    var nbVertices = mesh.getNbVertices();
    var indices = new Uint32Array(nbVertices);
    for (var i = 0; i < nbVertices; ++i) indices[i] = i;

    // undo-redo
    this.sceneProxy.getStateManager().pushVertices(indices);

    var smo = new Smooth();
    smo.setToolMesh(mesh);
    smo.smooth(indices, 1.0);
    mesh.updateGeometry();
    mesh.updateGeometryBuffers();

    this.sceneProxy.getStateManager().cleanNoop();
    this.sceneProxy.render();
  }

  this.setDynamic = function (dynamic) {
    let mesh = this.sMesh;
    let newMesh;
    if (mesh.isDynamic && !dynamic) {
      newMesh = this.convertToStaticMesh(mesh);
    } else if (!mesh.isDynamic && dynamic) {
      newMesh = new sculptTypes.MeshDynamic(mesh);
    }

    if (newMesh) {
      newMesh.updateBuffers();
      this.replaceMesh(mesh, newMesh);
    }
  };

  this.convertToStaticMesh = function (mesh) {
    if (!mesh.isDynamic) // already static
      return mesh;

    // dynamic to static mesh
    let newMesh = new sculptTypes.MeshStatic();
    newMesh.setID(mesh.getID());
    newMesh.setTransformData(mesh.getTransformData());
    newMesh.setVertices(mesh.getVertices().subarray(0, mesh.getNbVertices() * 3));
    newMesh.setMaskings(mesh.getMaskings().subarray(0, mesh.getNbVertices()));
    newMesh.setFaces(mesh.getFaces().subarray(0, mesh.getNbFaces() * 3));

    sculptTypes.Mesh.OPTIMIZE = false;
    newMesh.init();
    sculptTypes.Mesh.OPTIMIZE = true;

    newMesh.setRenderData(mesh.getRenderData());
    return newMesh;
  };

  this.getMesh = function () {
    return this.sMesh;
  };

  this.getSelectedMeshes = function () {
    return this.selectSMeshes;
  }

  this.getMeshes = function () {
    return this.sMeshes;
  };

  this.getIndexSelectMesh = function (mesh) {
    return this.getIndexMesh(mesh, true);
  }

  this.getIndexMesh = function (mesh, select) {
    let meshes = select ? this.selectSMeshes : this.sMeshes;
    let id = mesh.getID();
    for (let i = 0, nbMeshes = meshes.length; i < nbMeshes; ++i) {
      let testMesh = meshes[i];
      if (testMesh === mesh || testMesh.getID() === id)
        return i;
    }
    return -1;
  }

  this.setOrUnsetMesh = function (mesh, multiSelect) {
    if (!mesh) {
      this.selectSMeshes.length = 0;
    } else if (!multiSelect) {
      this.selectSMeshes.length = 0;
      this.selectSMeshes.push(mesh);
    } else {
      let id = this.getIndexSelectMesh(mesh);
      if (id >= 0) {
        if (this.selectSMeshes.length > 1) {
          this.selectSMeshes.splice(id, 1);
          mesh = this.selectSMeshes[0];
        }
      } else {
        this.selectSMeshes.push(mesh);
      }
    }

    this.sMesh = mesh;
    return mesh;
  };

  this.getRemeshResolution = function () {
    return sculptTypes.Remesh.RESOLUTION;
  };

  this.setMeshSymmetry = function (normal, offset) {
    let mesh = this.sMesh;

    if (mesh) {
      mesh.setSymmetryNormal(normal);
      mesh.setSymmetryOffset(offset);
    }
  };

  this.setMesh = function (mesh) {
    return this.setOrUnsetMesh(mesh);
  }

  this.replaceMesh = function (mesh, newMesh) {
    this.sceneProxy.getStateManager().pushStateAddRemove(newMesh, mesh);

    let index = this.getIndexMesh(mesh);
    if (index >= 0) this.sMeshes[index] = newMesh;
    if (this.sMesh === mesh) this.setMesh(newMesh);
  }

  this.addAction = function (action) {
    if (this.currentStroke) {
      this.currentStroke.actions.push(SculptPointAction[action]);
      this.currentStroke.xs.push(this.sceneProxy._mouseX);
      this.currentStroke.ys.push(this.sceneProxy._mouseY);
      this.currentStroke.pressures.push(sculptTypes.Tablet.pressure);
    }
  };

  this.startStroke = function (config) {
    let symmetryInfos = [];
    for (let sMesh of this.sMeshes) {
      let normal = sMesh.getSymmetryNormal();
      symmetryInfos.push({
        normal: [normal[0], normal[1], normal[2]],
        offset: sMesh.getSymmetryOffset()
      });
    }

    this.currentStroke = {
      id: peregrineId(),
      config: this.collectConfig(config),
      width: this.sceneProxy._width,
      height: this.sceneProxy._height,
      projection: Array.from(this.sceneProxy.getProjection()),
      symmetryInfos,
      xs: [],
      ys: [],
      pressures: [],
      actions: []
    };
  }

  this.endStroke = function () {
    if (this.currentStroke) {
      this.strokeIds.splice(this.strokeCursor, this.strokeIds.length, this.currentStroke.id);
      this.strokeStateIndices.splice(this.strokeCursor, this.strokeStateIndices.length, this.sceneProxy.getStateManager().getCurUndoIndex());
      this.strokeCursor = this.strokeIds.length;

      let pathesToPick = [];

      const traverse = (obj, path) => {
        for (let k in obj) {
          let subPath = path ? path + '.' + k : '' + k;
          if (obj[k] && typeof obj[k] === 'object') {
            traverse(obj[k], subPath);
          } else {
            if (lod.get(this.currentStroke.config, subPath) !== obj[k]) {
              pathesToPick.push(subPath);
            }
          }
        }
      }

      traverse(defaultSculptConfig);
      this.currentStroke.config = lod.pick(this.currentStroke.config, pathesToPick);
    }

    let result = this.currentStroke;
    this.currentStroke = undefined;

    return result;
  }

  this.render = function () {

  };
};

Sculpt.prototype = Object.assign(
  {

    constructor: Sculpt,

    isSculpt: true

  });

export {Sculpt};