import {
  ComponentTypes,
  InternalComponentTypes,
  LoopBackComponentTypes, NonMaterialComponentTypes, InputObjectParamTitles, OutputObjectParamTitle
} from "./component/types";
import lod from "lodash";
import {ObjectTypes, RenderedObjectTypes, XObject} from "./xobject";
import {
  decodeCalcDetailInfo,
  decodeCalcInputConfig,
  decodeCalcOutputConfig,
  decodeEitherMaterial,
  decodeHeatmapData,
  defaultCalcState,
  defaultEitherMaterial,
  defaultMetaInfo,
  encodeCalcDetailInfo,
  encodeCalcInputConfig,
  encodeCalcOutputConfig,
  encodeEitherMaterial,
  encodeHeatmapData,
  ICalc,
  ICalcConfigDiff,
  ICalcInputConfig,
  ICalcOutputConfig,
  ICalcSetting,
  ICalcSettingSubGroup,
  IEitherMaterial,
  IHeatmapData,
  IModelInfo,
  IModelMetaInfo,
  IRenderedFullObject,
  IRenderedObject,
  MaterialTypes
} from "./types";
import {
  createParameter,
  duplicateParameter, getBrepFromRef,
  getMeshFromRef,
  Param_Brep,
  Param_Compound,
  Param_Curve, Param_Json,
  Param_Mesh,
  Param_Point, Param_SculptMesh,
  Param_Transform,
  Param_Vertex,
  Parameter,
  ParamTitles,
  ParamTypes,
  RenderableParamTypes
} from "./parameter";
import {Relation} from "./relation";
import {InvalidId} from "./const";
import {getUniqueDesc, WompMesh, WompObjectRef} from "../WompObject";
import {solveComponent} from "./component";
import {_b, _c, _s, _t, isShallowEqual} from "../t";
import {vec3} from "gl-matrix";
import {decomposeTransform} from "../utils";
import {peregrineId} from "../id";

export class Calc extends XObject {
  protected _objectType = ObjectTypes.Calc;
  protected _scene: XObject = XObject.unset;

  get scene() {
    return this._scene;
  }

  set scene(scene: XObject) {
    this._scene = scene;
  }

  static unset = new Calc();

  get vertexCnt() {
    return this._state.vertexCnt;
  }

  protected _setVertexCnt(vertexCnt: number) {
    if (this._state.vertexCnt !== vertexCnt) {
      this._state = {
        ...this._state,
        vertexCnt
      };
    }
  }

  get faceCnt() {
    return this._state.faceCnt;
  }

  protected _setFaceCnt(faceCnt: number) {
    if (this._state.faceCnt !== faceCnt) {
      this._state = {
        ...this._state,
        faceCnt
      };
    }
  }

  protected _inputIds: string[] = [];

  get inputIds() {
    return this._inputIds;
  }

  protected _setInputIds(inputIds: string[]) {
    if (this._inputIds !== inputIds) {
      this._inputIds = inputIds;

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('inputIds')]: inputIds
        };
      }
    }
  }

  protected _outputIds: string[] = [];

  get outputIds() {
    return this._outputIds;
  }

  protected _setOutputIds(outputIds: string[]) {
    if (this._outputIds !== outputIds) {
      this._outputIds = outputIds;

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('outputIds')]: outputIds
        };
      }
    }
  }

  protected _inputs: { [id: string]: Parameter } = {};

  get inputs() {
    return this._inputs;
  }

  protected _outputs: { [id: string]: Parameter } = {};

  get outputs() {
    return this._outputs;
  }

  protected _inputConfigs: { [id: string]: ICalcInputConfig } = {};

  protected _outputConfig: ICalcOutputConfig = {inputProperties: {}};

  protected _hash: string = '';

  get hash() {
    return this._hash;
  }

  set hash(hash: string) {
    if (this._hash !== hash) {
      this._hash = hash;

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('hash')]: hash
        };
      }
    }
  }

  protected _heatmapData: IHeatmapData[] = [];

  get heatmapData() {
    return this._heatmapData;
  }

  set heatmapData(heatmapData: IHeatmapData[]) {
    if (this._heatmapData !== heatmapData) {
      this._heatmapData = heatmapData;
      this._state = {
        ...this._state,
        hasHeatmapData: heatmapData.length > 0 && lod.every(heatmapData, h => !h.sourceObjectId || h.objectId)
      };

      if (this.heatmap)
        this._stateValid = false;

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('heatmapData')]: heatmapData.map(encodeHeatmapData)
        };
      }
    }
  }

  get id() {
    return this._state.id;
  }

  set id(id: string) {
    if (this._state.id !== id) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:id]');
      this._id = id;
      this._state = {
        ...this._state,
        id
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('id')]: id
        };
      }
    }
  }

  get title() {
    return this._state.title;
  }

  set title(title: string) {
    title = title.replace(/:/g, '');
    if (this._state.title !== title) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:title]');
      let oldTitle = this._state.title;
      this._state = {
        ...this._state,
        title
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('title')]: title
        };
      }

      if (this._scene && (this._scene as any).changeCalcTitle)
        (this._scene as any).changeCalcTitle(this.id, oldTitle, title);

      this._stateValid = false;
    }
  }

  get component() {
    return this._state.component as ComponentTypes;
  }

  set component(component: ComponentTypes) {
    if (this._state.component !== component) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:component]');
      this._state = {
        ...this._state,
        component,
        internal: InternalComponentTypes.includes(component)
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('component')]: _s(component)
        };
      }
    }
  }

  get preview() {
    return this._state.preview;
  }

  set preview(preview: string) {
    if (this._state.preview !== preview) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:preview]');
      this._state = {
        ...this._state,
        preview
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('preview')]: preview
        };
      }
    }
  }

  get material() {
    return this._state.material;
  }

  set material(material: IEitherMaterial) {
    if (!lod.isEqual(this._state.material, material)) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:material]');
      this._state = {
        ...this._state,
        material
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('material')]: encodeEitherMaterial(material)
        };
      }
    }
  }

  get detailInfo() {
    return this._state.detailInfo;
  }

  set detailInfo(detailInfo: IModelInfo | null) {
    if (this._state.detailInfo !== detailInfo) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:detailInfo]');
      this._state = {
        ...this._state,
        detailInfo
      };
    }

    if (XObject.UpdateEncodedData) {
      this._data = {
        ...this._data,
        [_s('detailInfo')]: detailInfo ? encodeCalcDetailInfo(detailInfo) : null
      };
    }
  }

  get visible() {
    return this._state.visible;
  }

  set visible(visible: boolean) {
    if (this._state.visible !== visible) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:visible]');
      this._state = {
        ...this._state,
        visible
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('visible')]: _b(visible)
        };
      }
    }
  }

  get collapsed() {
    return this._state.collapsed;
  }

  set collapsed(collapsed: boolean) {
    if (this._state.collapsed !== collapsed) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:collapsed]');
      this._state = {
        ...this._state,
        collapsed
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('collapsed')]: _b(collapsed)
        };
      }
    }
  }

  get checked() {
    return this._state.checked;
  }

  set checked(checked: boolean) {
    if (this._state.checked !== checked) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:checked]');
      this._state = {
        ...this._state,
        checked
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('checked')]: _b(checked)
        };
      }
    }
  }

  get heatmap() {
    return this._state.heatmap;
  }

  set heatmap(heatmap: boolean) {
    if (this._state.heatmap !== heatmap) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:heatmap]');
      this._state = {
        ...this._state,
        heatmap
      };

      if (this._heatmapData.length > 0)
        this._stateValid = false;

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('heatmap')]: _b(heatmap)
        };
      }
    }
  }

  get boundingBox() {
    return this._state.boundingBox;
  }

  set boundingBox(boundingBox: boolean) {
    if (this._state.boundingBox !== boundingBox) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:boundingBox]');
      this._state = {
        ...this._state,
        boundingBox
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('boundingBox')]: _b(boundingBox)
        };
      }
    }
  }

  get settingGroupExpanded() {
    return this._state.settingGroupExpanded;
  }

  set settingGroupExpanded(settingGroupExpanded: { [key: number]: 1 }) {
    if (!lod.isEqual(this._state.settingGroupExpanded, settingGroupExpanded)) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:settingGroupExpanded]');
      this._state = {
        ...this._state,
        settingGroupExpanded
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('settingGroupExpanded')]: settingGroupExpanded
        };
      }
    }
  }

  get voided() {
    return this._state.voided;
  }

  set voided(voided: boolean) {
    if (this._state.voided !== voided) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:voided]');
      this._state = {
        ...this._state,
        voided
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('voided')]: _b(voided)
        };
      }
    }
  }

  get locked() {
    return this._state.locked;
  }

  set locked(locked: boolean) {
    if (this._state.locked !== locked) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:locked]');
      this._state = {
        ...this._state,
        locked
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('locked')]: _b(locked)
        };
      }
    }
  }

  get global() {
    return this._state.global;
  }

  set global(global: boolean) {
    if (this._state.global !== global) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:global]');
      this._state = {
        ...this._state,
        global
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('global')]: _b(global)
        };
      }
    }
  }

  get printable() {
    return this._state.printable;
  }

  set printable(printable: boolean) {
    if (this._state.printable !== printable) {
      if (!this._scene.isInvalid && !this._scene.locked)
        console.warn('scene was not locked before [calc:property:printable]');
      this._state = {
        ...this._state,
        printable
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('printable')]: _b(printable)
        };
      }
    }
  }

  get internal() {
    return this._state.internal;
  }

  get hasHeatmapData() {
    return this._state.hasHeatmapData;
  }

  get stateValid() {
    if (!this._stateValid)
      return false;

    for (let inputId of this.inputIds) {
      let param = this._inputs[inputId];
      if (param.hidden)
        continue;

      if (param.prevRelations.length === 0) {
        if (!param.stateValid)
          return false;
      } else {
        for (let relation of param.prevRelations) {
          let prevCalc = (relation as Relation).from.calc as Calc;
          if (prevCalc.state !== this._state.prevCalcs[prevCalc.id])
            return false;
        }
      }
    }

    for (let outputId of this._outputIds) {
      let param = this._outputs[outputId];

      if (param.hidden)
        continue;

      if (param.isOperable) {
        if (!param.stateValid)
          return false;
      }
    }

    return true;
  }

  protected _stateValid: boolean = false;

  protected set settingSubGroups(settingSubGroups: ICalcSettingSubGroup[]) {
    if (this._state.settingSubGroups !== settingSubGroups) {
      this._state = {
        ...this._state,
        settingSubGroups
      };
    }
  }

  protected set objects(objects: IRenderedObject[]) {
    if (this._state.objects !== objects) {
      this._state = {
        ...this._state,
        objects
      };
    }
  }

  get nextCalcIds() {
    return this._state.nextCalcIds;
  }

  protected setNextCalcIds(nextCalcIds: string[]) {
    if (this._state.nextCalcIds !== nextCalcIds) {
      this._state = {
        ...this._state,
        nextCalcIds
      };
    }
  }

  get prevCalcIds() {
    return this._state.prevCalcIds;
  }

  protected setPrevCalcIds(prevCalcIds: string[]) {
    if (this._state.prevCalcIds !== prevCalcIds) {
      this._state = {
        ...this._state,
        prevCalcIds
      };
    }
  }

  get prevCalcs() {
    return this._state.prevCalcs;
  }

  protected setPrevCalcs(prevCalcs: { [key: string]: ICalc }) {
    if (this._state.prevCalcs !== prevCalcs) {
      this._state = {
        ...this._state,
        prevCalcs
      };
    }
  }

  get searchContent() {
    return this._state.searchContent;
  }

  protected setSearchContent(searchContent: string) {
    if (this._state.searchContent !== searchContent) {
      this._state = {
        ...this._state,
        searchContent
      };
    }
  }

  protected _state: ICalc = defaultCalcState;

  get state() {
    if (!this.stateValid)
      this.validateGeneratedState();
    return this._state;
  }

  protected _fullObjects: IRenderedFullObject[] = [];

  protected setFullObjects(_fullObjects: IRenderedFullObject[]) {
    if (this._fullObjects !== _fullObjects) {
      this._fullObjects = _fullObjects;
    }
  }

  get fullObjects() {
    return this._fullObjects;
  }

  protected _metaInfo: IModelMetaInfo = defaultMetaInfo;

  protected setMetaInfo(metaInfo: IModelMetaInfo) {
    if (this._metaInfo !== metaInfo) {
      this._metaInfo = metaInfo;
    }
  }

  get metaInfo() {
    return this._metaInfo;
  }

  protected _dataValid: boolean = false;

  get dataValid() {
    if (!this._dataValid) {
      return false;
    }

    for (let inputId of this.inputIds) {
      let param = this._inputs[inputId];

      if (!param.dataValid)
        return false;

      if (param.data !== this._data[_s('inputs')][inputId])
        return false;
    }

    for (let outputId of this.outputIds) {
      let param = this._outputs[outputId];

      if (!param.dataValid)
        return false;

      if (param.data !== this._data[_s('outputs')][outputId])
        return false;
    }

    return true;
  }

  protected set dataInputs(inputs: { [key: string]: any }) {
    if (!isShallowEqual(this._data[_s('inputs')], inputs)) {
      this._data = {
        ...this._data,
        [_s('inputs')]: inputs
      }
    }
  }

  protected set dataOutputs(outputs: { [key: string]: any }) {
    if (!isShallowEqual(this._data[_s('outputs')], outputs)) {
      this._data = {
        ...this._data,
        [_s('outputs')]: outputs
      }
    }
  }

  protected set dataInputConfigs(inputConfigs: any) {
    if (!isShallowEqual(this._data[_s('inputConfigs')], inputConfigs)) {
      this._data = {
        ...this._data,
        [_s('inputConfigs')]: inputConfigs
      }
    }
  }

  protected set dataOutputConfig(outputConfig: any) {
    if (!isShallowEqual(this._data[_s('outputConfig')], outputConfig)) {
      this._data = {
        ...this._data,
        [_s('outputConfig')]: outputConfig
      }
    }
  }

  protected _data: any = {
    [_s('id')]: InvalidId,
    [_s('outputIds')]: [],
    [_s('inputIds')]: [],
    [_s('outputs')]: {},
    [_s('inputs')]: {},
    [_s('outputConfig')]: {
      [_s('inputProperties')]: {}
    },
    [_s('inputConfigs')]: {},
    [_s('detailInfo')]: null,
    [_s('title')]: '',
    [_s('component')]: _s(ComponentTypes.None),
    [_s('material')]: encodeEitherMaterial(defaultEitherMaterial),
    [_s('heatmapData')]: [],
    [_s('hash')]: '',
    [_s('preview')]: '',
    [_s('visible')]: 1,
    [_s('collapsed')]: 1,
    [_s('checked')]: 0,
    [_s('heatmap')]: 0,
    [_s('boundingBox')]: 0,
    [_s('voided')]: 0,
    [_s('global')]: 1,
    [_s('locked')]: 0,
    [_s('printable')]: 1,
    [_s('settingGroupExpanded')]: {},
  };

  get data() {
    if (!this.dataValid)
      this.validateGeneratedData();
    return this._data;
  }

  getMinimalData() {
    let inputDatas = lod.mapValues(this.inputs, input => input.prevRelations.length > 0 ? input.getMinimalData() : input.data);
    let outputDatas = lod.mapValues(this.outputs, output => output.getMinimalData());

    return {
      ...this.data,
      [_s('input')]: inputDatas,
      [_s('outputs')]: outputDatas,
      [_s('inputConfigs')]: {},
      [_s('outputConfig')]: {
        [_s('inputProperties')]: {}
      }
    }
  }

  get isInvalid() {
    return this._id === InvalidId;
  }

  static createInScene(scene: XObject, component: ComponentTypes, title: string) {
    let calc = new Calc();
    calc._scene = scene;
    calc.id = peregrineId();
    calc.title = title;
    calc.component = component;

    return calc;
  }

  static create() {
    let calc = new Calc();
    calc.id = peregrineId();
    calc._scene = XObject.unset;

    return calc;
  }

  protected getOutputConfig() {
    let outputConfig: ICalcOutputConfig = {inputProperties: {}, material: this.material, voided: this.voided};
    for (let inputId of this.inputIds) {
      let param = this._inputs[inputId];
      outputConfig.inputProperties[param.id] = param.generateProperties();
    }

    return outputConfig;
  }

  inputByTitle(title: string): Parameter {
    let cand = lod.find(Object.values(this._inputs), {title});
    if (cand) return cand;
    return Parameter.unset;
  }

  outputByTitle(title: string): Parameter {
    let cand = lod.find(Object.values(this._outputs), {title});
    if (cand) return cand;
    return Parameter.unset;
  }

  paramByTitle(title: string): Parameter {
    let cand = lod.find(Object.values(this._inputs), {title});
    if (cand) return cand;
    cand = lod.find(Object.values(this._outputs), {title});
    if (cand) return cand;
    return Parameter.unset;
  }

  renderableOutputs(): Parameter[] {
    return Object.values(this._outputs).filter(p => p.render);
  }

  addParameter(param: Parameter) {
    if (!this._scene.isInvalid && !this._scene.locked)
      console.warn('scene was not locked before [calc:addParameter]');

    if (param.isInput) {
      this._setInputIds([...this.inputIds, param.id]);
      this._inputs[param.id] = param;
    } else {
      this._setOutputIds([...this.outputIds, param.id]);
      this._outputs[param.id] = param;
    }
    param.calc = this;
    this._stateValid = false;
    this._dataValid = false;
  }

  getParameter(id: string) {
    if (this._inputs[id])
      return this._inputs[id];
    if (this._outputs[id])
      return this._outputs[id];
    return Parameter.unset;
  }

  hasRender() {
    for (let outputId of this._outputIds) {
      if (this._outputs[outputId].render)
        return true;
    }

    return false;
  }

  hasNext() {
    for (let outputId of this._outputIds) {
      if (this._outputs[outputId].nextRelations.length > 0)
        return true;
    }

    return false;
  }

  getPrevCalcs() {
    let calcs = new Set<Calc>();
    for (let inputId of this.inputIds) {
      let param = this._inputs[inputId];
      for (let relation of param.prevRelations) {
        calcs.add((relation as Relation).from.calc as Calc);
      }
    }
    return Array.from(calcs);
  }

  getNextCalcs() {
    let calcs = new Set<Calc>();
    for (let outputId of this._outputIds) {
      let param = this._outputs[outputId];
      for (let relation of param.nextRelations) {
        calcs.add((relation as Relation).to.calc as Calc);
      }
    }
    return Array.from(calcs);
  }

  duplicate(): Calc {
    if (!this._scene.isInvalid && !this._scene.locked)
      console.warn('scene was not locked before [calc:duplicate]');
    let calc = Calc.createInScene(XObject.unset, this.component, this.title);

    for (let inputId of this.inputIds) {
      let param = duplicateParameter(this._inputs[inputId]);
      calc.addParameter(param);
    }

    for (let outputId of this._outputIds) {
      let param = duplicateParameter(this._outputs[outputId]);
      calc.addParameter(param);
    }

    calc.preview = this.preview;
    calc.material = this.material;
    calc.detailInfo = this.detailInfo;
    calc.visible = this.visible;
    calc.voided = this.voided;
    calc.global = this.global;
    calc.locked = this.locked;
    calc.checked = this.checked;
    calc.collapsed = this.collapsed;
    calc.printable = this.printable;
    calc.settingGroupExpanded = this.settingGroupExpanded;
    calc.hash = this.hash;

    return calc;
  }

  protected generateSubGroups() {
    let settingGroups: ICalcSettingSubGroup[] = [];
    let settingGroup: ICalcSettingSubGroup = {label: '@@nogroup@@', settings: []};

    for (let inputId of this.inputIds) {
      let param = this._inputs[inputId];
      if (param.hidden)
        continue;

      if (param.prevRelations.length === 0) {
        let settings: ICalcSetting[] = param.state;

        if (settings.length > 0) {
          if (param.objectType === ParamTypes.Point) {
            settingGroups.push({
              label: param.title,
              settings: settings
            });
          } else {
            settingGroup.settings = [...settingGroup.settings, ...settings];
          }
        }
      }
    }

    for (let outputId of this._outputIds) {
      let param = this._outputs[outputId];
      if (param.hidden)
        continue;

      if (param.isOperable) {
        let settings: ICalcSetting[] = param.state;

        if (settings.length > 0) {
          if (param.objectType === ParamTypes.Point) {
            settingGroups.push({
              label: param.title,
              settings: settings
            });
          } else {
            settingGroup.settings = [...settingGroup.settings, ...settings];
          }
        }
      }
    }

    if (settingGroup.settings.length > 0) {
      if (settingGroup.label === '@@nogroup@@')
        settingGroups = [settingGroup, ...settingGroups];
      else
        settingGroups.push(settingGroup);
    }

    return settingGroups;
  }

  generateRenderedFullObjects(heatmap?: boolean): IRenderedFullObject[] {
    let objects: IRenderedFullObject[] = [];
    for (let outputId of this._outputIds) {
      let param = this._outputs[outputId];
      if (param.render) {
        switch (param.objectType) {
          case ParamTypes.Brep:
            let brepParam = param as Param_Brep;
            for (let i = 0; i < brepParam.caches.length; ++i) {
              objects.push({
                brep: getBrepFromRef(this, brepParam.values[i]),
                mesh: getMeshFromRef(this, brepParam.caches[i][0]),
                edge: getMeshFromRef(this, brepParam.caches[i][1]),
                type: RenderedObjectTypes.Brep,
                brepRef: brepParam.values[i],
                meshRef: brepParam.caches[i][0],
                edgeRef: brepParam.caches[i][1],
                property: {
                  ...brepParam.properties[i],
                  hash: getUniqueDesc(brepParam.caches[i][0])
                }
              });
            }
            break;
          case ParamTypes.Mesh:
            let paramMesh = param as Param_Mesh;
            for (let i = 0; i < paramMesh.length; ++i) {
              objects.push({
                mesh: getMeshFromRef(this, paramMesh.values[i]),
                type: RenderedObjectTypes.Mesh,
                meshRef: paramMesh.values[i],
                property: paramMesh.properties[i]
              });
            }
            break;
          case ParamTypes.SculptMesh:
            let paramSculptMesh = param as Param_SculptMesh;
            let strokeParam = this.inputByTitle(ParamTitles.Stroke) as Param_Json;
            for (let i = 0; i < paramSculptMesh.length; ++i) {
              objects.push({
                mesh: getMeshFromRef(this, paramSculptMesh.values[i]),
                type: RenderedObjectTypes.Mesh,
                meshRef: paramSculptMesh.values[i],
                property: paramSculptMesh.properties[i],
                strokes: strokeParam.isInvalid ? [] : [...strokeParam.values]
              });
            }
            break;
          case ParamTypes.Curve:
            let curveParam = param as Param_Curve;
            for (let i = 0; i < curveParam.caches.length; ++i) {
              objects.push({
                mesh: getMeshFromRef(this, curveParam.caches[i][0]),
                type: RenderedObjectTypes.Line,
                meshRef: curveParam.caches[i][0],
                property: curveParam.properties[i]
              });
            }
            break;
          case ParamTypes.Vertex:
            let vertexParam = param as Param_Vertex;
            for (let i = 0; i < vertexParam.length; ++i) {
              objects.push({
                mesh: [vertexParam.values[i][0], vertexParam.values[i][1], vertexParam.values[i][2]],
                type: RenderedObjectTypes.Vertex,
                property: vertexParam.properties[i]
              });
            }
            break;
          case ParamTypes.Compound:
            let compound = param as Param_Compound;
            for (let i = 0; i < compound.length; ++i) {
              let value = compound.values[i];
              let valueType = compound.valueTypes[i];
              let cache = compound.caches[i] as [WompObjectRef, WompObjectRef];
              let property = compound.properties[i];
              if (valueType === ObjectTypes.Brep) {
                objects.push({
                  brep: getBrepFromRef(this, value),
                  mesh: getMeshFromRef(this, cache[0]),
                  edge: getMeshFromRef(this, cache[1]),
                  type: RenderedObjectTypes.Brep,
                  meshRef: cache[0],
                  edgeRef: cache[1],
                  property: {
                    ...property,
                    hash: getUniqueDesc(cache[0])
                  }
                });
              } else if (valueType === ObjectTypes.Mesh || valueType === ObjectTypes.SculptMesh) {
                objects.push({
                  mesh: getMeshFromRef(this, value as WompObjectRef),
                  type: RenderedObjectTypes.Mesh,
                  meshRef: value,
                  property
                });
              } else if (valueType === ObjectTypes.Curve) {
                objects.push({
                  mesh: getMeshFromRef(this, cache[0]),
                  type: RenderedObjectTypes.Line,
                  meshRef: cache[0],
                  property
                });
              } else if (valueType === ObjectTypes.Vertex) {
                objects.push({
                  mesh: [value[0], value[1], value[2]],
                  type: RenderedObjectTypes.Vertex,
                  property
                });
              }
            }
            break;
          default:
            break;
        }
      }
    }

    if (heatmap && this.heatmap) {
      for (let i = 0; i < Math.min(this._heatmapData.length, objects.length); ++i) {
        let heatmapData = this._heatmapData[i];
        if (heatmapData.objectId) {
          let ref = new WompObjectRef(heatmapData.objectId, objects[i].mesh.matrix);
          objects[i].meshRef = ref;
          objects[i].mesh = getMeshFromRef(this, ref);
          objects[i].property = {
            ...objects[i].property,
            weldAngle: 90,
            hash: getUniqueDesc(ref)
          };
        }
      }
    }
    return objects;
  }

  protected generateSearchContent() {
    let searchContent = this.title;

    for (let id in this.prevCalcs) {
      let calc = this.prevCalcs[id];

      searchContent += ':' + calc.title;
    }

    return searchContent;
  }

  protected generatePrevCalcs() {
    let prevCalcs: { [key: string]: ICalc } = {};

    for (let inputId of this._inputIds) {
      let param = this._inputs[inputId];
      if (param.hidden)
        continue;

      for (let relation of param.prevRelations) {
        let prevCalc = (relation as Relation).from.calc as Calc;
        prevCalcs[prevCalc.id] = prevCalc.state;
      }
    }

    return prevCalcs;
  }

  protected generatePrevCalcIds() {
    let prevCalcIds: string[] = [];

    for (let inputId of this._inputIds) {
      let param = this._inputs[inputId];
      if (param.hidden)
        continue;

      for (let relation of param.prevRelations)
        prevCalcIds.push((relation as Relation).from.calc.id);
    }

    return prevCalcIds;
  }

  protected generateNextCalcIds() {
    let nextCalcIds: string[] = [];

    for (let outputId of this._outputIds) {
      let param = this._outputs[outputId];
      for (let relation of param.nextRelations)
        nextCalcIds.push((relation as Relation).to.calc.id);
    }

    return nextCalcIds;
  }

  protected generateMetaInfo(): IModelMetaInfo {
    let orgCenterParam = this.outputByTitle(ParamTitles.OrgCenter) as Param_Point;
    let globalSizeParam = this.outputByTitle(ParamTitles.GlobalSize) as Param_Point;
    let localSizeParam = this.outputByTitle(ParamTitles.LocalSize) as Param_Point;
    let positionParam = this.outputByTitle(ParamTitles.Position) as Param_Point;
    let transformParam = this.inputByTitle(ParamTitles.Transform) as Param_Transform;
    let sculptTransformParam = this.inputByTitle(ParamTitles.SculptTransform) as Param_Transform;

    if (orgCenterParam.isInvalid || orgCenterParam.length === 0) {
      return {
        orgCenter: vec3.create(),
        position: vec3.create(),
        scale: vec3.fromValues(1, 1, 1),
        localSize: vec3.create(),
        globalSize: vec3.create(),
        rotate: vec3.create(),
        translate: vec3.create(),
        skew: vec3.create(),
        holder: false,
        fake: true
      };
    }

    if (transformParam.isInvalid) {
      return {
        sculptTransform: sculptTransformParam.isInvalid ?
          undefined :
          sculptTransformParam.values[0],
        orgCenter: orgCenterParam.values[0],
        position: positionParam.values[0],
        scale: vec3.fromValues(1, 1, 1),
        localSize: globalSizeParam.values[0],
        globalSize: globalSizeParam.values[0],
        rotate: vec3.create(),
        translate: vec3.create(),
        skew: vec3.create(),
        holder: true,
        fake: false
      };
    } else {
      let transform = transformParam.values[0];

      let {translate, rotate, scale, skew} = decomposeTransform(transform);

      return {
        orgCenter: orgCenterParam.values[0],
        position: positionParam.values[0],
        globalSize: globalSizeParam.values[0],
        localSize: localSizeParam.values[0],
        scale: scale,
        rotate: rotate,
        translate: translate,
        skew: skew,
        holder: false,
        fake: false
      };
    }
  }

  setStateInvalid() {
    this._stateValid = false;
  }

  protected validateGeneratedState() {
    this.setPrevCalcs(this.generatePrevCalcs());
    this.setPrevCalcIds(this.generatePrevCalcIds());
    this.setNextCalcIds(this.generateNextCalcIds());
    this.settingSubGroups = this.generateSubGroups();
    this.setFullObjects(this.generateRenderedFullObjects(true));
    this.objects = this.fullObjects.map(obj => ({valid: obj.property.valid}));
    this.setMetaInfo(this.generateMetaInfo());
    this.setSearchContent(this.generateSearchContent());

    let faceCount = 0;
    let vertexCount = 0;
    for (let object of this._fullObjects) {
      switch (object.type) {
        case RenderedObjectTypes.Mesh:
        case RenderedObjectTypes.Brep:
          let geometry = object.mesh.geometry;

          faceCount += geometry.face ? geometry.face.length / 3 : 0;
          vertexCount += geometry.position ? geometry.position.length / 3 : 0;
          break;
        default:
          break;
      }
    }

    this._setFaceCnt(faceCount);
    this._setVertexCnt(vertexCount);

    this._stateValid = true;
  }

  protected validateGeneratedData() {
    this.dataInputs = lod.mapValues(this.inputs, i => i.data);
    this.dataOutputs = lod.mapValues(this.outputs, o => o.data);
    this.dataInputConfigs = lod.mapValues(this._inputConfigs, encodeCalcInputConfig);
    this.dataOutputConfig = encodeCalcOutputConfig(this._outputConfig);

    this._dataValid = true;
  }

  overwrite(jData: any, replaceData: boolean) {
    if (!this._scene.isInvalid && !this._scene.locked)
      console.warn('scene was not locked before [calc:overwrite]');

    if (jData === undefined || jData[_s('id')] === undefined) {
      console.warn('error decoding the calc', jData);
      return;
    }

    if (!this.dataValid)
      this.validateGeneratedData();
    if (!this.stateValid)
      this.validateGeneratedState();

    if (this._data[_s('id')] !== jData[_s('id')]) {
      this.id = jData[_s('id')];
    }

    if (this._data[_s('title')] !== jData[_s('title')]) {
      this.title = jData[_s('title')] !== undefined ? jData[_s('title')] : '';
    }

    if (this._data[_s('component')] !== jData[_s('component')]) {
      this.component = jData[_s('component')] !== undefined ? _t(jData[_s('component')]) as ComponentTypes : ComponentTypes.None;
    }

    if (this._data[_s('material')] !== jData[_s('material')]) {
      this.material = jData[_s('material')] !== undefined ? decodeEitherMaterial(jData[_s('material')]) : defaultEitherMaterial;
    }

    if (this._data[_s('preview')] !== jData[_s('preview')]) {
      this.preview = jData[_s('preview')] !== undefined ? jData[_s('preview')] : '';
    }

    if (this._data[_s('detailInfo')] !== jData[_s('detailInfo')]) {
      this.detailInfo = jData[_s('detailInfo')] ? decodeCalcDetailInfo(jData[_s('detailInfo')]) : null;
    }

    if (this._data[_s('heatmapData')] !== jData[_s('heatmapData')]) {
      this.heatmapData = jData[_s('heatmapData')] !== undefined ? jData[_s('heatmapData')].map(decodeHeatmapData) : [];
    }

    if (this._data[_s('visible')] !== jData[_s('visible')]) {
      this.visible = jData[_s('visible')] !== undefined ? _c(jData[_s('visible')]) : true;
    }

    if (this._data[_s('collapsed')] !== jData[_s('collapsed')]) {
      this.collapsed = jData[_s('collapsed')] !== undefined ? _c(jData[_s('collapsed')]) : true;
    }

    if (this._data[_s('checked')] !== jData[_s('checked')]) {
      this.checked = jData[_s('checked')] !== undefined ? _c(jData[_s('checked')]) : false;
    }

    if (this._data[_s('heatmap')] !== jData[_s('heatmap')]) {
      this.heatmap = jData[_s('heatmap')] !== undefined ? _c(jData[_s('heatmap')]) : false;
    }

    if (this._data[_s('boundingBox')] !== jData[_s('boundingBox')]) {
      this.boundingBox = jData[_s('boundingBox')] !== undefined ? _c(jData[_s('boundingBox')]) : false;
    }

    if (this._data[_s('settingGroupExpanded')] !== jData[_s('settingGroupExpanded')]) {
      this.settingGroupExpanded = jData[_s('settingGroupExpanded')] !== undefined ? jData[_s('settingGroupExpanded')] : {};
    }

    if (this._data[_s('voided')] !== jData[_s('voided')]) {
      this.voided = jData[_s('voided')] !== undefined ? _c(jData[_s('voided')]) : false;
    }

    if (this._data[_s('global')] !== jData[_s('global')]) {
      this.global = jData[_s('global')] !== undefined ? _c(jData[_s('global')]) : true;
    }

    if (this._data[_s('locked')] !== jData[_s('locked')]) {
      this.locked = jData[_s('locked')] !== undefined ? _c(jData[_s('locked')]) : false;
    }

    if (this._data[_s('printable')] !== jData[_s('printable')]) {
      this.printable = jData[_s('printable')] !== undefined ? _c(jData[_s('printable')]) : true;
    }

    if (this._data[_s('hash')] !== jData[_s('hash')]) {
      this.hash = jData[_s('hash')] !== undefined ? jData[_s('hash')] : '';
    }

    if (jData[_s('inputIds')] !== undefined && jData[_s('inputs')] !== undefined && jData[_s('inputConfigs')] !== undefined) {
      for (let inputId of [...this.inputIds]) {
        if (jData[_s('inputs')][inputId] === undefined) {
          delete this._inputs[inputId];
          this._setInputIds(this.inputIds.filter(i => i !== inputId));
          this._stateValid = false;
          this._dataValid = false;
        }
      }

      for (let inputId of jData[_s('inputIds')]) {
        let param = this.getParameter(inputId);
        if (param.isInvalid) {
          let param = createParameter(jData[_s('inputs')][inputId][_s('objectType')]);
          param.overwrite(jData[_s('inputs')][inputId], replaceData);
          this.addParameter(param);
        } else {
          param.overwrite(jData[_s('inputs')][inputId], replaceData);
        }
      }

      this._setInputIds(jData[_s('inputIds')]);

      // TODO: BYZ - check if _stateValid need to be set to false when input configs are different.
      this._inputConfigs = lod.mapValues(jData[_s('inputConfigs')], decodeCalcInputConfig);
    }

    if (jData[_s('outputIds')] !== undefined && jData[_s('outputs')] !== undefined && jData[_s('outputConfig')] !== undefined) {
      for (let outputId of [...this._outputIds]) {
        if (jData[_s('outputs')][outputId] === undefined) {
          delete this._outputs[outputId];
          this._setOutputIds(this.outputIds.filter(i => i !== outputId));
          this._stateValid = false;
          this._dataValid = false;
        }
      }

      for (let outputId of jData[_s('outputIds')]) {
        let param = this.getParameter(outputId);
        if (param.isInvalid) {
          let param = createParameter(jData[_s('outputs')][outputId][_s('objectType')]);
          param.overwrite(jData[_s('outputs')][outputId], replaceData);
          this.addParameter(param);
        } else {
          param.overwrite(jData[_s('outputs')][outputId], replaceData);
        }
      }

      this._setOutputIds(jData[_s('outputIds')]);
      let outputConfig = decodeCalcOutputConfig(jData[_s('outputConfig')]);

      if (!lod.isEqual(outputConfig, this._outputConfig))
        this._stateValid = false;

      this._outputConfig = outputConfig;
    }

    if (replaceData) {
      this._data = jData;
      this._dataValid = true;
    }

    // console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took to overwrite calc`);
  }

  collapse() {
    if (!this._scene.isInvalid && !this._scene.locked)
      console.warn('scene was not locked before [calc:collapse]');
    if (!this.collapsed || Object.keys(this.settingGroupExpanded).length > 0) {
      this._state = {
        ...this._state,
        collapsed: true,
        settingGroupExpanded: {}
      };
    }
  }

  replaceDigitalMaterialIds(materialIdMap: { [key: string]: string }) {
    if (!this._scene.isInvalid && !this._scene.locked)
      console.warn('scene was not locked before [calc:replaceDigitalMaterialIds]');

    let params = [];
    for (let inputId of this.inputIds) {
      params.push(this.inputs[inputId]);
    }
    for (let outputId of this.outputIds) {
      params.push(this.outputs[outputId]);
    }
    for (let param of params) {
      if (RenderableParamTypes.includes(param.objectType)) {
        if ((param as Param_Mesh).properties) {
          let properties = [], hasChange = false;
          for (let property of (param as Param_Mesh).properties) {
            if (property.material.type === MaterialTypes.DigitalMaterial && property.material.id && materialIdMap[property.material.id] !== undefined) {
              properties.push({
                ...property,
                material: {...property.material, id: materialIdMap[property.material.id]}
              });
              hasChange = true;
            } else {
              properties.push(property);
            }
          }

          if (hasChange)
            (param as Param_Mesh).properties = properties;
        }
      }
    }

    if (this.material.type === MaterialTypes.DigitalMaterial && this.material.id && materialIdMap[this.material.id] !== undefined) {
      this.material = {...this.material, id: materialIdMap[this.material.id]};
    }
  }

  replaceObjectIds(objectIdMap: { [key: string]: string }) {
    if (!this._scene.isInvalid && !this._scene.locked)
      console.warn('scene was not locked before [calc:replaceObjectIds]');

    let heatmapData = [], hasChange = false;
    for (let data of this.heatmapData) {
      if (objectIdMap[data.objectId]) {
        heatmapData.push({...data, objectId: objectIdMap[data.objectId]});
        hasChange = true;
      } else {
        heatmapData.push(data);
      }
    }

    if (hasChange) {
      this.heatmapData = heatmapData;
    }

    let params = [];
    for (let inputId of this.inputIds) {
      params.push(this.inputs[inputId]);
    }
    for (let outputId of this.outputIds) {
      params.push(this.outputs[outputId]);
    }

    for (let param of params) {
      if (param.objectType === ParamTypes.Mesh || param.objectType === ParamTypes.SculptMesh) {
        if ((param as Param_Mesh).values) {
          let values = [], hasChange = false;
          for (let value of (param as Param_Mesh).values) {
            if (objectIdMap[value.objectId]) {
              values.push(new WompObjectRef(objectIdMap[value.objectId], value.matrix));
              hasChange = true;
            } else {
              values.push(value);
            }
          }

          if (hasChange) {
            (param as Param_Mesh).values = values;
          }
        }
      } else if (param.objectType === ParamTypes.Brep || param.objectType === ParamTypes.Curve) {
        if ((param as Param_Brep).caches) {
          let caches: [WompObjectRef, WompObjectRef][] = [], hasChange = false;
          for (let cache of (param as Param_Brep).caches) {
            let newCache = [];
            for (let i = 0; i < 2; ++i) {
              if (objectIdMap[cache[i].objectId]) {
                newCache[i] = new WompObjectRef(objectIdMap[cache[i].objectId], cache[i].matrix);
                hasChange = true;
              } else {
                newCache.push(cache[i]);
              }
            }

            caches.push(newCache as [WompObjectRef, WompObjectRef]);
          }

          if (hasChange) {
            (param as Param_Brep).caches = caches;
          }
        }
      } else if (param.objectType === ParamTypes.Compound) {
        let paramComp = param as Param_Compound;
        if (paramComp.valueTypes && paramComp.values) {
          let values = [], caches = [], valueHasChange, cacheHasChange;
          for (let i = 0; i < paramComp.length; ++i) {
            let value = paramComp.values[i];
            let valueType = paramComp.valueTypes[i];
            let cache = paramComp.caches[i] as [WompObjectRef, WompObjectRef];

            if (valueType === ObjectTypes.Mesh || valueType === ObjectTypes.SculptMesh) {
              if (objectIdMap[value.objectId]) {
                values.push(new WompObjectRef(objectIdMap[value.objectId], value.matrix));
                valueHasChange = true;
              } else {
                values.push(value);
              }
            } else if (valueType === ObjectTypes.Curve || valueType === ObjectTypes.Brep) {
              if (cache) {
                let newCache = [];
                for (let i = 0; i < 2; ++i) {
                  if (objectIdMap[cache[i].objectId]) {
                    newCache[i] = new WompObjectRef(objectIdMap[cache[i].objectId], cache[i].matrix);
                    hasChange = true;
                  } else {
                    newCache.push(cache[i]);
                  }
                }

                caches.push(newCache as [WompObjectRef, WompObjectRef]);
              } else {
                caches.push(cache);
              }
            }
          }

          if (valueHasChange) {
            paramComp.values = values;
          }

          if (cacheHasChange) {
            paramComp.caches = caches;
          }
        }
      }
    }

    // TODO: BYZ - check if we need to replace hash strings in inputConfigs and outputConfig.
  }

  static compareOutputConfigs(a: ICalcOutputConfig, b: ICalcOutputConfig): ICalcConfigDiff {
    let inputIdSet = new Set([...Object.keys(a.inputProperties), ...Object.keys(b.inputProperties)]);
    if (inputIdSet.size !== Object.keys(a.inputProperties).length || inputIdSet.size !== Object.keys(b.inputProperties).length) return {obj: true};

    let calcVoided = false;
    let calcMaterial = false;
    let prevVoided = false;
    let prevMaterial = false;
    let gumball = false;

    if (a.voided !== b.voided) {
      calcVoided = true;
    }

    if ((a.material && !b.material) || (!a.material && b.material)) {
      calcMaterial = true;
    } else if (a.material && b.material) {
      if (a.material.id !== b.material.id || a.material.type !== b.material.type)
        calcMaterial = true;
    }

    for (let id in a.inputProperties) {
      if (a.inputProperties[id].length !== b.inputProperties[id].length) {
        return {obj: true};
      }

      for (let j = 0; j < a.inputProperties[id].length; ++j) {
        let aProp = a.inputProperties[id][j];
        let bProp = b.inputProperties[id][j];

        if ((aProp && !bProp) || (!aProp && bProp)) {
          return {obj: true};
        }

        if (aProp.hash !== bProp.hash) {
          if (aProp.gumball && bProp.gumball)
            gumball = true;
          else
            return {obj: true};
        }

        if (aProp.voided !== bProp.voided) {
          prevVoided = true;
        }

        if (!prevMaterial) {
          if ((aProp.material && !bProp.material) || (!aProp.material && bProp.material)) {
            prevMaterial = true;
          } else if (aProp.material && bProp.material) {
            if (aProp.material.id !== bProp.material.id || aProp.material.type !== bProp.material.type) {
              prevMaterial = true;
            }
          }
        }
      }
    }

    return {obj: false, gumball, calcMaterial, calcVoided, prevMaterial, prevVoided};
  }

  static getInputConfig(param: Parameter) {
    let inputConfig: ICalcInputConfig = {prevIds: [], outputProperties: {}};
    for (let relation of param.prevRelations) {
      let prev = (relation as unknown as Relation).from as unknown as Parameter;
      inputConfig.prevIds.push(prev.id);
      inputConfig.outputProperties[prev.id] = prev.generateProperties();
    }

    return inputConfig;
  }

  static compareInputConfigs(a: ICalcInputConfig, b: ICalcInputConfig): ICalcConfigDiff {
    if (a.prevIds.length !== b.prevIds.length) return {obj: true};
    for (let i = 0; i < a.prevIds.length; ++i) {
      if (a.prevIds[i] !== b.prevIds[i])
        return {obj: true};
    }

    let voided = false;
    let material = false;
    for (let i = 0; i < a.prevIds.length; ++i) {
      let prevId = a.prevIds[i];
      if (a.outputProperties[prevId].length !== b.outputProperties[prevId].length)
        return {obj: true};

      for (let j = 0; j < a.outputProperties[prevId].length; ++j) {
        let aProp = a.outputProperties[prevId][j];
        let bProp = b.outputProperties[prevId][j];

        if ((aProp && !bProp) || (!aProp && bProp)) {
          return {obj: true};
        }

        if (aProp.hash !== bProp.hash) {
          return {obj: true};
        }

        if (aProp.voided !== bProp.voided) {
          voided = true;
        }

        if (!material) {
          if ((aProp.material && !bProp.material) || (!aProp.material && bProp.material)) {
            material = true;
          } else if (aProp.material && bProp.material) {
            if (aProp.material.id !== bProp.material.id || aProp.material.type !== bProp.material.type)
              material = true;
          }
        }
      }
    }

    return {obj: false, prevMaterial: material, prevVoided: voided};
  }

  async solve(objDiff: boolean = false) {
    if (!this._scene.isInvalid && !this._scene.locked)
      console.warn('scene was not locked before [calc:solve]');

    for (let paramId of this.inputIds) {
      if (!(await this.solveParameter(paramId)))
        return false;
    }

    let newOutputConfig = this.getOutputConfig();

    let diff = Calc.compareOutputConfigs(this._outputConfig, newOutputConfig);
    diff.obj = diff.obj || objDiff;

    let ret = true;
    if ((diff.obj || diff.gumball || diff.calcVoided || diff.calcMaterial || diff.prevVoided || diff.prevMaterial)) {
      ret = await solveComponent(this, diff);

      if (LoopBackComponentTypes.includes(this.component))
        this._outputConfig = this.getOutputConfig();
      else
        this._outputConfig = newOutputConfig;
      if (diff.obj || diff.gumball) {
        this.detailInfo = null;
        this.heatmap = false;
      }

      this._stateValid = false;
      this._dataValid = false;
    }

    return ret;
  }

  protected async solveParameter(paramId: string) {
    let param = this.getParameter(paramId);
    if (param) {
      if (param.isInput) {
        let inputConfig = this._inputConfigs[param.id];
        let newInputConfig = Calc.getInputConfig(param);

        if (!inputConfig) {
          inputConfig = {
            prevIds: [],
            outputProperties: {}
          };
        }

        let diff = Calc.compareInputConfigs(inputConfig, newInputConfig);

        if ((diff.obj || diff.gumball || diff.calcVoided || diff.calcMaterial || diff.prevVoided || diff.prevMaterial)) {
          param.solve(diff);

          this._inputConfigs[param.id] = newInputConfig;
          this._dataValid = false;
        }

        await param.generateCaches();
      } else {
        if (!(await this.solve()))
          return false;

        await param.generateCaches();
      }
    }

    return true;
  }

  solveProperty(diff: ICalcConfigDiff) {
    if (!this._scene.isInvalid && !this._scene.locked)
      console.warn('scene was not locked before [calc:solveProperty]');

    let inParam = this.inputByTitle((InputObjectParamTitles[this.component] || [])[0]) as Param_Compound;
    let outParam = this.outputByTitle(OutputObjectParamTitle[this.component]) as Param_Compound;

    if (outParam.isInvalid)
      return;

    if (NonMaterialComponentTypes.includes(this.component)) {
      if (diff.calcVoided || diff.prevMaterial) {
        let properties = lod.cloneDeep(outParam.properties);
        for (let i = 0; i < properties.length; ++i) {
          if (diff.calcVoided)
            properties[i].voided = this.voided;

          if (diff.prevMaterial && inParam) {
            let property = inParam.properties[Math.min(i, inParam.properties.length - 1)];
            properties[i].material = property.material;
          }
        }
        outParam.properties = properties;
      }
    } else {
      if (diff.calcVoided || diff.calcMaterial) {
        let properties = lod.cloneDeep(outParam.properties);
        for (let i = 0; i < properties.length; ++i) {
          if (diff.calcVoided)
            properties[i].voided = this.voided;

          if (diff.calcMaterial)
            properties[i].material = this.material;
        }
        outParam.properties = properties;
      }
    }
  }
}