import lod from "lodash";
import {ComponentTypes} from "../types";
import {three as threeTypes} from "../../../types";
import {createGumball, solveGumball} from "../gumball";
import {
  getRegisteredId,
  Param_Mesh,
  Param_Number,
  ParamTitles,
  ParamValueTypes,
  registerGeometry
} from "../../parameter";
import {MeshResolution} from "../../const";
import {Calc} from "../../calc";
import {ObjectTypes} from "../../xobject";
import {ICalcConfigDiff} from "../../types";
import {getUniqueDesc, WompMesh, WompObjectRef} from "../../../WompObject";
import {getMeshDataFromThreeGeometry} from "../../../converter";
import {mat4, vec3} from "gl-matrix";
import {TubeBufferGeometry} from "../../../helper/three-basic/TubeBufferGeometry";
import {BasicMeshGenerator} from "./basic-gen";

export class ThreeTubeGenerator extends BasicMeshGenerator {
  static defaultParameter = {
    radius: 10,
    innerRadiusPercent: 50,
    height: 20
  };
  static draggingPreview = ThreeTubeGenerator.generateMesh();

  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.ThreeTubeGenerator;
    calc.title = 'tube';

    let innerRadiusParam = Param_Number.create(calc, ParamTitles.InnerRadius, true, true, {
      type: ParamValueTypes.Percent,
      defaultValue: this.defaultParameter.innerRadiusPercent
    });
    let tubeParam = Param_Mesh.create(calc, ParamTitles.Tube, false, false, true);

    calc.addParameter(innerRadiusParam);
    calc.addParameter(tubeParam);

    return calc;
  }

  static getDesc(calc: Calc) {
    let innerRadiusParam = calc.inputByTitle(ParamTitles.InnerRadius) as Param_Number;
    let innerRadiusPercent = innerRadiusParam.values[0] || this.defaultParameter.innerRadiusPercent;

    return `cytube[${innerRadiusPercent.toFixed(4)}]`;
  }

  static getParameter(calc: Calc) {
    let innerRadiusParam = calc.inputByTitle(ParamTitles.InnerRadius) as Param_Number;
    let innerRadiusPercent = innerRadiusParam.values[0] || this.defaultParameter.innerRadiusPercent;

    return {...this.defaultParameter, innerRadiusPercent};
  }

  static generateMesh(parameter: any = this.defaultParameter) {
    let value: threeTypes.BufferGeometry = new TubeBufferGeometry(
      this.defaultParameter.radius,
      parameter.innerRadiusPercent * this.defaultParameter.radius / 100,
      this.defaultParameter.height,
      MeshResolution,
      MeshResolution / 1.5
    );

    value.rotateX(Math.PI / 2);

    let geom = getMeshDataFromThreeGeometry(value);

    return new WompMesh(geom, this.generateMatrix(parameter));
  }

  static generateMatrix(parameter: any = this.defaultParameter) {
    return mat4.fromScaling(
      mat4.create(),
      vec3.fromValues(
        parameter.radius / this.defaultParameter.radius,
        parameter.radius / this.defaultParameter.radius,
        parameter.height / this.defaultParameter.height
      )
    );
  }
}