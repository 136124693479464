import {
  BufferGeometry,
  Float32BufferAttribute,
  Line,
  LineBasicMaterial,
  LineSegments,
  Matrix4,
  Object3D,
  Vector3
} from 'three';
import {NLightHelperPicker} from './NLightHelperPicker';

var _v1 = new Vector3();
var _v2 = new Vector3();
var _v3 = new Vector3();

function NDirectionalLightHelper(light, sizeWidth, sizeHeight, lightId, camera, hoverStartEvent, hoverStopEvent, updateCallback) {

  Object3D.call(this);

  this.light = light;
  this.light.updateMatrixWorld();

  this.lightId = lightId;
  this.updateCallback = updateCallback;

  this.matrix = light.matrixWorld;
  this.matrixAutoUpdate = false;

  this.sizeWidth = sizeWidth;
  this.sizeHeight = sizeHeight;

  var geometry = new BufferGeometry();

  geometry.setAttribute('position', new Float32BufferAttribute([
    -sizeWidth / 2, sizeHeight / 2, 0,
    -sizeWidth / 2, sizeHeight / 2, 1,

    -sizeWidth / 2, 0, 0,
    -sizeWidth / 2, 0, 1,

    sizeWidth / 2, sizeHeight / 2, 0,
    sizeWidth / 2, sizeHeight / 2, 1,

    sizeWidth / 2, 0, 0,
    sizeWidth / 2, 0, 1,

    sizeWidth / 2, -sizeHeight / 2, 0,
    sizeWidth / 2, -sizeHeight / 2, 1,

    0, -sizeHeight / 2, 0,
    0, -sizeHeight / 2, 1,

    -sizeWidth / 2, -sizeHeight / 2, 0,
    -sizeWidth / 2, -sizeHeight / 2, 1,

    0, sizeHeight / 2, 0,
    0, sizeHeight / 2, 1,

    0, 0, 0,
    0, 0, 1,
  ], 3));

  var material = new LineBasicMaterial({fog: false});

  this.targetLines = new LineSegments(geometry, material);
  this.add(this.targetLines);

  geometry = new BufferGeometry();
  geometry.setAttribute('position', new Float32BufferAttribute([
    -sizeWidth / 2, sizeHeight / 2, 0,
    sizeWidth / 2, sizeHeight / 2, 0,
    sizeWidth / 2, -sizeHeight / 2, 0,
    -sizeWidth / 2, -sizeHeight / 2, 0,
    -sizeWidth / 2, sizeHeight / 2, 0
  ], 3));

  material = new LineBasicMaterial({fog: false});
  this.lightPlane = new Line(geometry, material);
  this.add(this.lightPlane);

  this.positionPicker = new NLightHelperPicker(lightId + ':position', camera, hoverStartEvent, hoverStopEvent, this.onChangeLightPosition.bind(this));
  this.add(this.positionPicker);

  this.targetPicker = new NLightHelperPicker(lightId + ':target', camera, hoverStartEvent, hoverStopEvent, this.onChangeTargetPosition.bind(this));
  this.add(this.targetPicker);

  this.update(sizeWidth, sizeHeight);

}

NDirectionalLightHelper.prototype = Object.create(Object3D.prototype);
NDirectionalLightHelper.prototype.constructor = NDirectionalLightHelper;

NDirectionalLightHelper.prototype.dispose = function () {

  this.lightPlane.geometry.dispose();
  this.lightPlane.material.dispose();
  this.targetLines.geometry.dispose();
  this.targetLines.material.dispose();

  this.positionPicker.dispose();
  this.targetPicker.dispose();
};

NDirectionalLightHelper.prototype.setDelegate = function (delegate) {

  this.positionPicker.setDelegate(delegate);
  this.targetPicker.setDelegate(delegate);

};

NDirectionalLightHelper.prototype.unsetDelegate = function () {

  this.positionPicker.unsetDelegate();
  this.targetPicker.unsetDelegate();

};

NDirectionalLightHelper.prototype.setCamera = function (camera) {

  this.positionPicker.camera = camera;
  this.targetPicker.camera = camera;

};

NDirectionalLightHelper.prototype.onChangeLightPosition = function (x, y, z) {
  this.updateCallback(this.lightId, {
    position: [
      x + this.light.position.x,
      y + this.light.position.y,
      z + this.light.position.z
    ]
  });
};

NDirectionalLightHelper.prototype.onChangeTargetPosition = function (x, y, z) {
  this.updateCallback(this.lightId, {
    targetPosition: [
      x + this.light.target.position.x,
      y + this.light.target.position.y,
      z + this.light.target.position.z
    ]
  });
};

NDirectionalLightHelper.prototype.update = function (sizeWidth, sizeHeight) {
  this.light.updateMatrixWorld();
  this.light.target.updateMatrixWorld();

  this.matrix = this.light.matrixWorld;
  this.matrixAutoUpdate = false;

  if (sizeWidth !== undefined && sizeHeight !== undefined) {
    this.sizeWidth = sizeWidth;
    this.sizeHeight = sizeHeight;

    this.targetLines.geometry.setAttribute('position', new Float32BufferAttribute([
      -sizeWidth / 2, sizeHeight / 2, 0,
      -sizeWidth / 2, sizeHeight / 2, 1,

      -sizeWidth / 2, 0, 0,
      -sizeWidth / 2, 0, 1,

      sizeWidth / 2, sizeHeight / 2, 0,
      sizeWidth / 2, sizeHeight / 2, 1,

      sizeWidth / 2, 0, 0,
      sizeWidth / 2, 0, 1,

      sizeWidth / 2, -sizeHeight / 2, 0,
      sizeWidth / 2, -sizeHeight / 2, 1,

      0, -sizeHeight / 2, 0,
      0, -sizeHeight / 2, 1,

      -sizeWidth / 2, -sizeHeight / 2, 0,
      -sizeWidth / 2, -sizeHeight / 2, 1,

      0, sizeHeight / 2, 0,
      0, sizeHeight / 2, 1,

      0, 0, 0,
      0, 0, 1,
    ], 3));

    this.lightPlane.geometry.setAttribute('position', new Float32BufferAttribute([
      -sizeWidth / 2, sizeHeight / 2, 0,
      sizeWidth / 2, sizeHeight / 2, 0,
      sizeWidth / 2, -sizeHeight / 2, 0,
      -sizeWidth / 2, -sizeHeight / 2, 0,
      -sizeWidth / 2, sizeHeight / 2, 0
    ], 3));
  }

  _v1.setFromMatrixPosition(this.light.matrixWorld);
  _v2.setFromMatrixPosition(this.light.target.matrixWorld);
  _v3.subVectors(_v2, _v1);

  this.targetLines.lookAt(_v2);
  this.lightPlane.lookAt(_v2);
  this.targetLines.material.color.copy(this.light.color);
  this.lightPlane.material.color.copy(this.light.color);
  this.targetLines.scale.z = _v3.length();

  this.positionPicker.sprite.matrixAutoUpdate = true;
  new Matrix4().decompose(this.positionPicker.sprite.position, this.positionPicker.sprite.quaternion, this.positionPicker.sprite.scale);

  this.targetPicker.sprite.matrixAutoUpdate = true;
  new Matrix4().decompose(this.targetPicker.sprite.position, this.targetPicker.sprite.quaternion, this.targetPicker.sprite.scale);
  this.targetPicker.sprite.position.copy(_v3);
};


export {NDirectionalLightHelper};