import {useCallback, useContext} from "react";
import {CursorContext} from "./CursorContextProvider";
import isTouchDevice from "./is-touch-device";

export interface ICursorHandlerOptions {
  onMouseEnter?: (event: any) => void
  onMouseLeave?: (event: any) => void
}

export const useCursorHandlers = (options: ICursorHandlerOptions = {}) => {
  if (isTouchDevice) {
    return options;
  }

  const [cursor, setCursor] = useContext(CursorContext);

  const showCursor = (show: boolean) => {
    setCursor && setCursor(({ active }) => ({ active: show }));
  };

  const onMouseEnter = useCallback(event => {
    if (options.onMouseEnter) {
      options.onMouseEnter(event);
    }

    showCursor(true);
  }, []);

  const onMouseLeave = useCallback(event => {
    if (options.onMouseLeave) {
      options.onMouseLeave(event);
    }

    showCursor(false);
  }, []);

  return { onMouseEnter, onMouseLeave };
};