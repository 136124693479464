import {InitStoreAction} from "../init";

export const SET_AUTH_DATA = 'SET_AUTH_DATA';
export const CHANGE_AUTH_USERNAME = 'CHANGE_AUTH_USERNAME';
export const CHANGE_AUTH_USER_AVATAR = 'CHANGE_AUTH_USER_AVATAR';

export interface IEntityAuthState {
  username?: string
  token?: string
  userAvatar?: string
  userPoints?: number
  appAdmin: boolean
  verified: boolean
}

interface SetAuthDataAction {
  type: typeof SET_AUTH_DATA
  payload: {
    username?: string,
    token?: string,
    userAvatar?: string,
    userPoints?: number
    appAdmin?: boolean,
    verified?: boolean
  }
}

interface ChangeAuthUsernameAction {
  type: typeof CHANGE_AUTH_USERNAME
  payload: {
    username?: string
  }
}

interface ChangeAuthAvatarAction {
  type: typeof CHANGE_AUTH_USER_AVATAR
  payload: {
    userAvatar?: string
  }
}

export type AuthActionTypes = SetAuthDataAction |
  ChangeAuthUsernameAction |
  ChangeAuthAvatarAction |
  InitStoreAction
