import lod from "lodash";
import {ComponentTypes} from "../types";
import {pigeon as pigeonTypes, three as threeTypes} from "../../../types";
import {createGumball, Gumball, solveGumball} from "../gumball";
import {
  getRegisteredId,
  Param_Brep,
  ParamTitles,
  registerBrepGeometry,
  registerGeometry
} from "../../parameter";
import {Calc} from "../../calc";
import {MeshResolution} from "../../const";
import {ObjectTypes} from "../../xobject";
import {ICalcConfigDiff} from "../../types";
import {getUniqueDesc, WompBrep, WompMesh, WompObjectRef} from "../../../WompObject";
import {getMeshDataFromThreeGeometry} from "../../../converter";
import {mat4, vec3} from "gl-matrix";
import {BufferGeometryUtils} from "three/examples/jsm/utils/BufferGeometryUtils";
import {BasicBrepGenerator} from "./basic-gen";
import {ThreeSphereGenerator} from "../three-basic";

export class SphereGenerator extends BasicBrepGenerator {
  static defaultParameter = {
    radius: 10
  };
  static draggingPreview = ThreeSphereGenerator.generateMesh();

  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.SphereGenerator;
    calc.title = 'sphere';

    let sphereParam = Param_Brep.create(calc, ParamTitles.Sphere, false, false, true);

    calc.addParameter(sphereParam);

    return calc;
  }

  static getDesc(calc: Calc) {
    return 'b-sphere';
  }

  static async generateBrepGeometry(parameter: any = this.defaultParameter) {
    return pigeonTypes.sphere(
      this.defaultParameter.radius
    );
  }

  static generateMatrix(parameter: any = this.defaultParameter) {
    return mat4.fromScaling(
      mat4.create(),
      vec3.fromValues(
        parameter.radius / this.defaultParameter.radius,
        parameter.radius / this.defaultParameter.radius,
        parameter.radius / this.defaultParameter.radius
      )
    );
  }
}
