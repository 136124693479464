import * as THREE from 'three';
import {
  AdditiveBlending,
  AmbientLight,
  BackSide,
  Box3,
  BoxBufferGeometry,
  BufferGeometry,
  CineonToneMapping,
  Color,
  ConeBufferGeometry,
  DataTexture,
  DirectionalLight,
  DoubleSide,
  EdgesGeometry,
  Euler,
  Float32BufferAttribute,
  Font,
  FontLoader,
  Frustum,
  GammaEncoding,
  Group,
  HemisphereLight,
  InstancedMesh,
  Intersection,
  Light,
  Line,
  LinearFilter,
  LinearMipmapLinearFilter,
  LineBasicMaterial,
  LineDashedMaterial,
  LineSegments,
  Material,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  MeshMatcapMaterial,
  MeshPhysicalMaterial,
  MeshStandardMaterial,
  Object3D,
  OrthographicCamera,
  PCFShadowMap,
  PCFSoftShadowMap,
  PerspectiveCamera,
  Plane,
  PlaneBufferGeometry,
  PlaneGeometry,
  PointLight,
  Points,
  PointsMaterial,
  Quaternion,
  Ray,
  Raycaster,
  RectAreaLight,
  RepeatWrapping,
  Scene,
  Shader,
  ShaderChunk,
  ShaderMaterial,
  ShadowMaterial,
  ShapeBufferGeometry,
  Sphere,
  SphereBufferGeometry,
  SpotLight,
  Sprite,
  SpriteMaterial,
  TextBufferGeometry,
  Texture,
  TextureLoader,
  Uint32BufferAttribute,
  Vector2,
  Vector3,
  WebGL1Renderer,
  WebGLRenderer
} from 'three';
import {BufferGeometryUtils} from 'three/examples/jsm/utils/BufferGeometryUtils';
import {SVGLoader} from 'three/examples/jsm/loaders/SVGLoader';
import {OrbitControl} from './controls/OrbitControl';
import {TransformControl} from './controls/TransformControl';
import {AlignControl} from './controls/AlignControl';
import {SelectionBox} from './helpers/SelectionBox';
import {GridHelper} from './helpers/GridHelper';
import {SelectionHelper} from './helpers/SelectionHelper';
import {LineGeometry} from 'three/examples/jsm/lines/LineGeometry';
import {LineMaterial} from 'three/examples/jsm/lines/LineMaterial';
import {Line2} from 'three/examples/jsm/lines/Line2';
import helvetikerBoldFont from 'three/examples/fonts/helvetiker_bold.typeface.json';
import helvetikerFont from 'three/examples/fonts/helvetiker_regular.typeface.json';
import moment from 'moment';
import {
  CombinedAtomComponentTypes,
  ComponentTypes,
  composeThreeTransform,
  decomposeTransform,
  EnvMapTypes,
  ExclusiveTools,
  extrudeGeometry,
  extrudeLine,
  getBoundingBoxFromModelMetaInfo,
  getCurveFromArray,
  getLineSegmentsGeometryFromMeshData,
  getMat4FromThreeTransform,
  getMeshFromThreeMesh,
  getSculptMeshFromThreeMesh,
  getThreeGeometryFromMeshData,
  getThreeGeometryFromSculptMesh,
  getThreeTransformFromMat4,
  getThreeTransformFromSculptMesh,
  getThreeVectorFromVec3,
  getVec3FromThreeVector,
  IAmbientLight,
  IAnnotate,
  ICameraInfo,
  IDirectionalLight,
  IEnvironment,
  IFloor,
  IHemisphereLight,
  ILight,
  ILightEntity,
  IMeasure,
  IModelMetaInfo,
  IModelProperty,
  IPointLight,
  IPointOnMesh,
  IRectAreaLight,
  IRenderedFullObject, ISculptStroke,
  ISpotLight,
  IToolConfig,
  LightTypes,
  MeasureUnit,
  offsetGeometry,
  RenderedObjectTypes,
  sculpt as sculptTypes,
  Tools,
  ViewTypes
} from '../../peregrine';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer.js';
import {PMREMGenerator} from './render/PMREMGenerator';
import {SpriteText2D, textAlign} from 'three-text2d';
import lod from 'lodash';
import {RGBELoader} from 'three/examples/jsm/loaders/RGBELoader';
import {NDirectionalLightHelper} from './helpers/light/NDirectionalLightHelper';
import {NRectAreaLightHelper} from './helpers/light/NRectAreaLightHelper';
import {NHemisphereLightHelper} from './helpers/light/NHemisphereLightHelper';
import {NPointLightHelper} from './helpers/light/NPointLightHelper';
import {NSpotLightHelper} from './helpers/light/NSpotLightHelper';
import {ArrayControl} from './controls/ArrayControl';
import {SculptControl, SculptKeyCodes} from './controls/SculptControl';
import {MirrorControl} from "./controls/MirrorControl";
import {s3RootPath} from '../../api/const';
import matcapTexture0 from '../../assets/images/texture/clay.jpg';
import matcapTexture1 from '../../assets/images/texture/matcapFV.jpg';
import matcapTexture2 from '../../assets/images/texture/redClay.jpg';
import matcapTexture3 from '../../assets/images/texture/skinHazardousarts.jpg';
import matcapTexture4 from '../../assets/images/texture/skinHazardousarts2.jpg';
import matcapTexture5 from '../../assets/images/texture/pearl.jpg';
import matcapTexture6 from '../../assets/images/texture/matcap-porcelain-white.jpg';
import matcapTexture7 from '../../assets/images/texture/skin.jpg';
import matcapTexture8 from '../../assets/images/texture/green.jpg';
import matcapTexture9 from '../../assets/images/texture/white.jpg';
import iconImport from '../../assets/images/icon/import.svg';
import {mat4, vec2, vec3} from 'gl-matrix';
import {
  computeGeometryVertexNormals,
  getIdPart,
  getNonIdPart,
  getRenderedGeometry,
  WompMeshData
} from '../../peregrine/WompObject';
import Stats from 'three/examples/jsm/libs/stats.module';
import {MeshInstancingManager} from "./MeshInstancingManager";
import {IPBRDigitalMaterial, IPlaceholderBox, PlaceholderBoxStates} from "../../store/types/types";
import {_n, _p, _v} from "../../peregrine/t";
import {cleanScene, updateFrustum} from "../common/three";
import {convertImageDataToData, convertURIToImageData, cropURIToImageData} from "../common/image";
import {ISnapBoundingBox} from "./helpers/Snap";
import {ISnapTarget, SnapControl} from "./controls/SnapControl";
import {PROJECT_PREVIEW_HEIGHT, PROJECT_PREVIEW_WIDTH} from "../common/const";
import {IMagnetTarget, MagnetControl} from "./controls/MagnetControl";
import {WebGLCubeRenderTarget} from './render/WebGLCubeRenderTarget';
import {getCurvePointArray} from "../../peregrine/curve";
import Editor3dDelegate from "./Editor3dDelegate";
import {Mutex} from "async-mutex";
import {MarchingCubes} from "./helpers/MarchingCubes";

export const ACT_DBL_CLICK = 'ACT_DBL_CLICK';
export const ACT_SELECT_MODELS = 'ACT_SELECT_OBJECT';
export const ACT_COMMIT_TRANSFORM = 'ACT_COMMIT_TRANSFORM';
export const ACT_COMMIT_MAGNET = 'ACT_COMMIT_MAGNET';
export const ACT_COMMIT_MIRROR = 'ACT_COMMIT_MIRROR';
export const ACT_COMMIT_MEASUREMENT = 'ACT_COMMIT_MEASUREMENT';
export const ACT_DELETE_MEASUREMENT = 'ACT_DELETE_MEASUREMENT';
export const ACT_COMMIT_ANNOTATION = 'ACT_COMMIT_ANNOTATION';
export const ACT_DELETE_ANNOTATION = 'ACT_DELETE_ANNOTATION';
export const ACT_COMMIT_POLYLINE = 'ACT_COMMIT_POLYLINE';
export const ACT_COMMIT_CURVE = 'ACT_COMMIT_CURVE';
export const ACT_COMMIT_ARRAY = 'ACT_COMMIT_ARRAY';
export const ACT_SCULPT_STROKE = 'ACT_SCULPT_STROKE';
export const ACT_COPY = 'ACT_COPY';
export const ACT_GROUP = 'ACT_GROUP';
export const ACT_UNGROUP = 'ACT_UNGROUP';
export const ACT_DELETE = 'ACT_DELETE';
export const ACT_PASTE = 'ACT_PASTE';
export const ACT_FOCUS = 'ACT_FOCUS';
export const ACT_SELECT_TOOL = 'ACT_SELECT_TOOL';
export const ACT_SELECT_PLACEHOLDER = 'ACT_SELECT_PLACEHOLDER';
export const ACT_CANCEL_PLACEHOLDER = 'ACT_CANCEL_PLACEHOLDER';
export const ACT_SHOW_SCULPT_TOOL = 'ACT_SHOW_SCULPT_TOOL';
export const ACT_UNDO = 'ACT_UNDO';
export const ACT_REDO = 'ACT_REDO';
export const ACT_CONFIRM = 'ACT_CONFIRM';
export const ACT_CHATBOT = 'ACT_CHATBOT';
export const ACT_MOUSE_DOWN = 'ACT_MOUSE_DOWN';
export const ACT_MOUSE_UP = 'ACT_MOUSE_UP';
export const ACT_MOUSE_MOVE = 'ACT_MOUSE_MOVE';
export const ACT_MOUSE_CLICK = 'ACT_MOUSE_CLICK';
export const ACT_MOUSE_LEAVE = 'ACT_MOUSE_LEAVE';
export const ACT_KEYDOWN = 'ACT_KEYDOWN';
export const ACT_KEYUP = 'ACT_KEYUP';
export const ACT_UPDATE_LIGHT = 'ACT_UPDATE_LIGHT';
export const ACT_PUSH_EDIT_LEVEL = 'ACT_PUSH_EDIT_LEVEL';
export const ACT_POP_EDIT_LEVEL = 'ACT_POP_EDIT_LEVEL';
export const ACT_COMMIT_OFFSET = 'ACT_COMMIT_OFFSET';
export const ACT_UPDATE_CAMERA = 'ACT_UPDATE_CAMERA';
export const ACT_UPDATE_SCULPT_CONFIG = 'ACT_UPDATE_SCULPT_CONFIG';
export const ACT_SCULPT_REMESH = 'ACT_SCULPT_REMESH';
export const ACT_SCULPT_MIRROR = 'ACT_SCULPT_MIRROR';
export const ACT_SCULPT_PORK = 'ACT_SCULPT_PORK';
export const ACT_LOAD_ENVIRONMENT_START = 'ACT_LOAD_ENVIRONMENT_START';
export const ACT_LOAD_ENVIRONMENT_END = 'ACT_LOAD_ENVIRONMENT_END';
export const ACT_CHANGE_MINOR_VIEW_ZOOM = 'ACT_CHANGE_MINOR_VIEW_ZOOM';
export const ACT_TOGGLE_SNAP_CONFIG = 'ACT_TOGGLE_SNAP_CONFIG';
export const ACT_CHANGE_ARRAY_CONTROL = 'ACT_CHANGE_ARRAY_CONTROL';
export const ACT_CHANGE_MIRROR_CONTROL = 'ACT_CHANGE_MIRROR_CONTROL';
export const ACT_CHANGE_SNAP_CONTROL = 'ACT_CHANGE_SNAP_CONTROL';
export const ACT_HIGHLIGHT_MODELS = 'ACT_HIGHLIGHT_MODELS';
export const ACT_CHANGE_SERVER_RENDER_CONFIG = 'ACT_CHANGE_SERVER_RENDER_CONFIG';

const matcapTextures = [
  matcapTexture0,
  matcapTexture1,
  matcapTexture2,
  matcapTexture3,
  matcapTexture4,
  matcapTexture5,
  matcapTexture6,
  matcapTexture7,
  matcapTexture8,
  matcapTexture9
];

enum SharedMaterial {
  None = ViewTypes.None,
  Wireframe = ViewTypes.Wireframe,
  Shaded = ViewTypes.Shaded,
  Rendered = ViewTypes.Rendered,
  Ghosted = ViewTypes.Ghosted,
  Matcap = ViewTypes.Matcap,
  OutOfEdit = 7,
  Cursor = 8,
  MatcapMasking = 9,
  Heatmap = 10,
  EdgeLine = 11,
  InvalidEdgeLine = 12,
  PointPicker = 13,
  MeshWireframe = 14,
  BoxLine = 15,
  InvalidBoxLine = 16,
  ClosePicker = 17,
  Line = 18,
  Vertex = 19,
}

export enum RenderSize {
  HighRes,
  Half
}

export enum RenderMaterialTypes {
  None = 0,
  Original = 1,
  Voided = 2,
  Material = 3,
  DigitalMaterial = 4,
  Line = 5,
  Vertex = 6,
}

export enum AnnotateControlStates {
  SelectingTarget = 0,
  SelectingTextPos = 1,
  InputtingText = 2
}

export enum MeasureControlStates {
  SelectingFrom = 0,
  SelectingTo = 1,
  MovingPerpendicular = 2
}

export interface IEditor3dConfig {
  editControls: boolean
  lightControls: boolean
  highlight: boolean
  hoverHighlight: boolean
  grid: boolean
  cameraZoom: boolean
  cameraPan: boolean
  envMap: boolean
  orbit: boolean
  orbitSpeed: number
  blob: boolean
  blobPosition: vec3
  blobSize: number
}

interface IMeasurementGeometry {
  startMarker: Mesh
  endMarker: Mesh
  distanceText: SpriteText2D
  betweenLine1: Line
  betweenLine2: Line
  startLine: Line
  endLine: Line
  offLine: Line
  group: Group
  distance: number
  textWidth: number
  zoom: number
  measureUnit: string
  color: string
  type?: string
}

interface ICursorGeometry {
  handleMarker: Mesh
  lineMarker: LineSegments
  usernameText: SpriteText2D
  group: Group
  origin: vec3
  direction: vec3
}

interface IAnnotationGeometry {
  startMarker: Mesh
  annotationText: SpriteText2D
  angleLine: Line
  horizLine: Line
  group: Group
  annotation: string
  zoom: number
  height: number
  font: string
  direction: number
  bold: boolean
  italic: boolean
  underline: boolean
  lineStyle: string
  color: string
  type?: string
}

interface IPlaceholderBoxGeometry {
  box: Mesh
  filledBox: Mesh
  edgeBox: LineSegments
  edgeFilledBox: LineSegments
  descriptionText: SpriteText2D
  progressText: SpriteText2D
  cancelSprite: Sprite
  group: Group
  description: string
  progress: number
  state: PlaceholderBoxStates
  bBox: Box3
  zoom: number
  eye: Vector3
}

interface ILightGeometry {
  type: LightTypes
  light: Light
  helperVisible: boolean
  visible: boolean
  entity?: ILightEntity
  helper?: any
}

export interface IRenderMaterial {
  type: RenderMaterialTypes
  pbrHash: string
  luxHash?: string
  minThickness?: number
  texture?: Texture
  detail?: IPBRDigitalMaterial
}

interface ISubModel {
  geometryHash: string
  matrixHash: string
  materialHash: string
  luxHash: string
  materialId: string
  mesh: Mesh | LineSegments | Points
  edge: LineSegments
  material: MeshPhysicalMaterial
  valid: boolean
  minThickness: number
  renderedObjectType: RenderedObjectTypes
  heatmapData: (Float32Array | undefined)
}

interface IModel {
  title: string
  component: string
  subs: ISubModel[]
  metaInfo: IModelMetaInfo
  prevIds: string[]
  meshGroup: Group
  edgeGroup: Group
  boundingBox: LineSegments
  // points: Points[] // uncomment for pointSnap
  visible: boolean
  locked: boolean
  heatmap: boolean
  global: boolean
}

interface IMeshUnderPoint {
  mesh: Mesh
  instanceId: number
  faceIndex: number
  id: string
  index: number
  type: string
}

interface ISelection {
  tool: string
  editLevels: string[]
  space: string
  ids: string[]
  group: Group
  orgGroupMatrix: Matrix4
  groupMIM: MeshInstancingManager
  sMeshes: sculptTypes.Mesh[]
  meshes: Mesh[]
  wfMeshes: Mesh[]
  meshGroups: { [key: string]: Group }
  edgeGroups: { [key: string]: Group }
  groups: { [key: string]: Group }
  groupMIMs: { [key: string]: MeshInstancingManager }
  boundingBox: Box3
  globalBoundingBox: Box3
  selecting: boolean
}

interface IFaceSelection {
  id: string
  mesh: Mesh
  instanceId: number
  faceIndex: number
  outlineMesh: Mesh
}

interface IPolyline {
  points: Vector3[]
  pointSprites: Sprite[]
  line: Line
  group: Group
}

interface ICurve {
  points: Vector3[]
  pointSprites: Sprite[]
  line: Line
  baseLine: Line
  periodic: boolean
  group: Group
}

interface IGeometryMap {
  geometry: BufferGeometry
  edgeGeometry: BufferGeometry
  weldAngle: number
  orgWeldAngle: number
  usedIn: Set<string>
}

interface IMaterialMap {
  material: Material
  usedIn: Set<string>
}

const EPS = 0.000001;
const CAMERA_EPS = 0.00001;

const SCENE_DIMENSION = 100000;
const FONT_CURVE_SEGMENTS = 20;

const maskingMaterialBeforeCompile = (shader: Shader) => {
  shader.vertexShader = shader.vertexShader
    .replace(/#include <color_pars_vertex>/, `
          #if defined( USE_COLOR ) || defined( USE_INSTANCING_COLOR )
            varying vec3 vColor;
	          attribute vec3 masking;
          #endif`)
    .replace(/#include <color_vertex>/, `
          #if defined( USE_COLOR ) || defined( USE_INSTANCING_COLOR )
            vColor = vec3( 1.0 );
          #endif
          #ifdef USE_COLOR
            vColor.xyz *= masking.xxx * 0.6 + 0.4;
          #endif
          #ifdef USE_INSTANCING_COLOR
            vColor.xyz *= instanceColor.xyz;
          #endif`);
};

export default class Editor3d {
  delegate?: Editor3dDelegate;
  mutex = new Mutex();
  projectId: number = 0;

  pixelRatio: number = 1;
  antialias: boolean = true;
  stats?: Stats;
  drawCallPanel?: Stats.Panel;
  hadUpdatePanel?: Stats.Panel;

  width!: number;
  height!: number;

  container!: HTMLElement;
  orthoCamera!: OrthographicCamera;
  perspCamera!: PerspectiveCamera;
  scene!: Scene;
  renderer!: WebGLRenderer;
  sceneMIM!: MeshInstancingManager;
  orthoOrbitControl!: OrbitControl;
  perspOrbitControl!: OrbitControl;

  envMapRenderer!: WebGLRenderer;
  envMapCamera!: PerspectiveCamera;
  envMapScene!: Scene;

  controlRenderer!: WebGLRenderer;
  controlScene!: Scene;

  minorCamera?: OrthographicCamera;
  minorRenderer?: WebGLRenderer;
  minorOrbitControl?: OrbitControl;

  minorWidth?: number;
  minorHeight?: number;

  cubeCamera?: PerspectiveCamera;
  cubeScene?: Scene;
  cubeRenderer?: WebGLRenderer;
  cubeMesh?: Mesh;
  cubeSize: number = 60;
  cubeMarginX: number = -1;
  cubeMarginY: number = -1;

  defaultLightEnvMapTexture: Texture = new Texture();

  transformControl!: TransformControl;
  snapControl!: SnapControl;
  magnetControl!: MagnetControl;
  mirrorControl!: MirrorControl;
  alignControl!: AlignControl;
  arrayControl!: ArrayControl;
  sculptControl!: SculptControl;

  elemRect!: ClientRect;
  mouse = new Vector2();
  mouseOnCanvas = new Vector2();
  mouseOnCube = new Vector2();

  rayCaster = new Raycaster();

  cameraTo!: Vector3;
  controlTargetTo!: Vector3;
  sculptControlTargetTo: Vector3 | undefined;
  cameraOffset: Vector2 | undefined;
  zoomTo!: number;
  orbitControlChange: boolean = false;
  instantUpdateCamera: boolean = false;
  rotateCamera: boolean = false;
  cameraAngle: number = 10;

  composer!: EffectComposer;

  side = DoubleSide;

  floor!: GridHelper;
  floorGroup!: Group;

  basePlane!: Mesh;
  baseZ: number;

  blobObject?: MarchingCubes;

  disposed: boolean = false;

  protected _dPoints: Mesh[] = [];
  protected _dPointPositions: Vector3[] = [];
  protected _dPointMatrix: Matrix4 = new Matrix4();

  sharedMaterials: { [key: number]: Material } = {};

  measures: { [key: number]: IMeasure } = {};
  measurements: { [key: number]: IMeasurementGeometry } = {};
  measureState: MeasureControlStates = MeasureControlStates.SelectingFrom;
  measureColor: string = '#000000';
  measureLineMaterial = new LineBasicMaterial({color: this.measureColor});
  measureMarkerMaterial = new MeshBasicMaterial({color: this.measureColor});
  editingMeasureId = 0;

  annotates: { [key: number]: IAnnotate } = {};
  annotations: { [key: number]: IAnnotationGeometry } = {};
  annotateState: AnnotateControlStates = AnnotateControlStates.SelectingTarget;
  annotateColor: string = '#000000';
  annotateLineMaterial = new LineBasicMaterial({color: this.annotateColor});
  annotateMarkerMaterial = new MeshBasicMaterial({color: this.annotateColor});
  editingAnnotateId = 0;

  pBoxes: { [key: string]: IPlaceholderBox } = {};
  placeholderBoxes: { [key: string]: IPlaceholderBoxGeometry } = {};
  placeholderBoxMaterials = {
    [PlaceholderBoxStates.Success]: Editor3d.createGhostedMaterial(0x00ff00),
    [PlaceholderBoxStates.Warning]: Editor3d.createGhostedMaterial(0xffff00),
    [PlaceholderBoxStates.Error]: Editor3d.createGhostedMaterial(0xff0000)
  };

  placeholderBoxWireframeMaterial = new LineBasicMaterial({color: 0xC0C0C0});
  placeholderFilledBoxWireframeMaterial = new LineBasicMaterial({color: 0x808080});
  placeholderFilledBoxMaterial = Editor3d.createGhostedMaterial(0x0000ff);

  // lightInfos: { [key: number]: ILightEntity } = {};
  lights: { [key: number]: ILightGeometry } = {};

  measureUnit = MeasureUnit.Milli;

  cameraUsername: string = '';
  cursors: { [key: string]: ICursorGeometry } = {};

  cameraInfo: ICameraInfo | null = null;
  oldCameraState: any = {};
  oldMinorCameraState: any = {};

  importModelIcon?: Group;
  importModelOutline?: Line2;

  draggingPreview?: {
    mesh: (Mesh | Line | Points)
  };

  polyline!: IPolyline;
  curve!: ICurve;
  selection: ISelection;
  faceSelection?: IFaceSelection;
  selectionBox: SelectionBox;
  selectionHelper: SelectionHelper;

  boldFont: Font;
  font: Font;

  snapIncrement: number = 0;
  gridWidth: number = 500;
  gridHeight: number = 500;
  gridOpacity: number = 0.25;
  gridSquare: number = 1.0;
  gridShowUnits: boolean = false;
  gridUnitTexts: SpriteText2D[] = [];
  gridEnabled: boolean = true;

  envMapEnabled: boolean = true;
  highlightEnabled: boolean = true;
  hoverHighlightEnabled: boolean = true;
  editControlsEnabled: boolean = true;
  lightControlsEnabled: boolean = true;

  highlightedModelIds: string[] = [];

  refreshAnnotationsLater: boolean = false;
  refreshPlaceholderBoxesLater: boolean = false;
  refreshMeasurementsLater: boolean = false;
  refreshBoundingBoxesLater: boolean = false;
  refreshVisibilitiesLater: boolean = false;
  refreshMaterialsLater: boolean = false;
  refreshTransformControlLater: boolean = false;
  refreshFloorLater: boolean = false;

  defaultColor: string = '#070025';
  defaultBasicMaterial = new MeshBasicMaterial({color: this.defaultColor});
  defaultAnnotationText: string = 'type your text here.';

  models: { [key: string]: IModel } = {};
  geometryMap: { [key: string]: IGeometryMap } = {};
  materialMap: { [key: string]: IMaterialMap } = {};

  previewMeshes: Object3D[] = [];
  previewGroup = new Group();
  previewData: any;

  scope: Set<string> = new Set<string>();
  scopeWithPrev: Set<string> = new Set<string>();

  actionCallback: ((action: any) => void) = () => {
  };

  pointSnapText!: SpriteText2D;
  pointSnapMesh!: Mesh;
  pointSnapEnabled: boolean = false;

  pointerDownTime?: Date;
  pointerDownPos = new Vector2();
  pointerDownButton = 0;

  prevClickTime?: Date;
  prevClickPos = new Vector2();
  prevClickButton = 0;

  viewType: number = ViewTypes.Rendered;

  envMapExposure!: number;
  backgroundEnvMapType!: number;
  envMapChanged!: boolean;
  envMapTexture!: Texture | Color;

  lightingEnvironment?: IEnvironment;
  backgroundEnvironment?: IEnvironment;

  lightEnvMapTexture?: Texture;

  isHoverLightHelperPicker: { [id: string]: boolean } = {};

  offsetInputElement: HTMLInputElement | undefined;
  annotateInputElement: HTMLTextAreaElement | undefined;

  preventControlsValidate: boolean = false;
  tabKeyState: boolean = false;
  tabHotKeyUsed: boolean = false;

  toolConfig?: IToolConfig;

  static replaceShadowShader() {

    let shader = ShaderChunk.shadowmap_pars_fragment;

    if (shader.indexOf('float PCSS ( sampler2D shadowMap, vec4 coords ) {') < 0) {

      shader = shader.replace(
        '#ifdef USE_SHADOWMAP',
        '#ifdef USE_SHADOWMAP\n' +
        '        #define LIGHT_WORLD_SIZE 0.005\n' +
        '        #define LIGHT_FRUSTUM_WIDTH 3.75\n' +
        '        #define LIGHT_SIZE_UV (LIGHT_WORLD_SIZE / LIGHT_FRUSTUM_WIDTH)\n' +
        '        #define NEAR_PLANE 9.5\n' +
        '\n' +
        '        #define NUM_SAMPLES 17\n' +
        '        #define NUM_RINGS 11\n' +
        '        #define BLOCKER_SEARCH_NUM_SAMPLES NUM_SAMPLES\n' +
        '        #define PCF_NUM_SAMPLES NUM_SAMPLES\n' +
        '\n' +
        '        vec2 poissonDisk[NUM_SAMPLES];\n' +
        '\n' +
        '        void initPoissonSamples( const in vec2 randomSeed ) {\n' +
        '          float ANGLE_STEP = PI2 * float( NUM_RINGS ) / float( NUM_SAMPLES );\n' +
        '          float INV_NUM_SAMPLES = 1.0 / float( NUM_SAMPLES );\n' +
        '\n' +
        '          // jsfiddle that shows sample pattern: https://jsfiddle.net/a16ff1p7/\n' +
        '          float angle = rand( randomSeed ) * PI2;\n' +
        '          float radius = INV_NUM_SAMPLES;\n' +
        '          float radiusStep = radius;\n' +
        '\n' +
        '          for( int i = 0; i < NUM_SAMPLES; i ++ ) {\n' +
        '            poissonDisk[i] = vec2( cos( angle ), sin( angle ) ) * pow( radius, 0.75 );\n' +
        '            radius += radiusStep;\n' +
        '            angle += ANGLE_STEP;\n' +
        '          }\n' +
        '        }\n' +
        '\n' +
        '        float penumbraSize( const in float zReceiver, const in float zBlocker ) { // Parallel plane estimation\n' +
        '          return (zReceiver - zBlocker) / zBlocker;\n' +
        '        }\n' +
        '\n' +
        '        float findBlocker( sampler2D shadowMap, const in vec2 uv, const in float zReceiver ) {\n' +
        '          // This uses similar triangles to compute what\n' +
        '          // area of the shadow map we should search\n' +
        '          float searchRadius = LIGHT_SIZE_UV * ( zReceiver - NEAR_PLANE ) / zReceiver;\n' +
        '          float blockerDepthSum = 0.0;\n' +
        '          int numBlockers = 0;\n' +
        '\n' +
        '          for( int i = 0; i < BLOCKER_SEARCH_NUM_SAMPLES; i++ ) {\n' +
        '            float shadowMapDepth = unpackRGBAToDepth(texture2D(shadowMap, uv + poissonDisk[i] * searchRadius));\n' +
        '            if ( shadowMapDepth < zReceiver ) {\n' +
        '              blockerDepthSum += shadowMapDepth;\n' +
        '              numBlockers ++;\n' +
        '            }\n' +
        '          }\n' +
        '\n' +
        '          if( numBlockers == 0 ) return -1.0;\n' +
        '\n' +
        '          return blockerDepthSum / float( numBlockers );\n' +
        '        }\n' +
        '\n' +
        '        float PCF_Filter(sampler2D shadowMap, vec2 uv, float zReceiver, float filterRadius ) {\n' +
        '          float sum = 0.0;\n' +
        '          for( int i = 0; i < PCF_NUM_SAMPLES; i ++ ) {\n' +
        '            float depth = unpackRGBAToDepth( texture2D( shadowMap, uv + poissonDisk[ i ] * filterRadius ) );\n' +
        '            if( zReceiver <= depth ) sum += 1.0;\n' +
        '          }\n' +
        '          for( int i = 0; i < PCF_NUM_SAMPLES; i ++ ) {\n' +
        '            float depth = unpackRGBAToDepth( texture2D( shadowMap, uv + -poissonDisk[ i ].yx * filterRadius ) );\n' +
        '            if( zReceiver <= depth ) sum += 1.0;\n' +
        '          }\n' +
        '          return sum / ( 2.0 * float( PCF_NUM_SAMPLES ) );\n' +
        '        }\n' +
        '\n' +
        '        float PCSS ( sampler2D shadowMap, vec4 coords ) {\n' +
        '          vec2 uv = coords.xy;\n' +
        '          float zReceiver = coords.z; // Assumed to be eye-space z in this code\n' +
        '\n' +
        '          initPoissonSamples( uv );\n' +
        '          // STEP 1: blocker search\n' +
        '          float avgBlockerDepth = findBlocker( shadowMap, uv, zReceiver );\n' +
        '\n' +
        '          //There are no occluders so early out (this saves filtering)\n' +
        '          if( avgBlockerDepth == -1.0 ) return 1.0;\n' +
        '\n' +
        '          // STEP 2: penumbra size\n' +
        '          float penumbraRatio = penumbraSize( zReceiver, avgBlockerDepth );\n' +
        '          float filterRadius = penumbraRatio * LIGHT_SIZE_UV * NEAR_PLANE / zReceiver;\n' +
        '\n' +
        '          // STEP 3: filtering\n' +
        '          //return avgBlockerDepth;\n' +
        '          return PCF_Filter( shadowMap, uv, zReceiver, filterRadius );\n' +
        '        }'
      );

      shader = shader.replace(
        '#if defined( SHADOWMAP_TYPE_PCF )',
        'return PCSS( shadowMap, shadowCoord );\n' +
        '#if defined( SHADOWMAP_TYPE_PCF )'
      );

      console.log('shader replaced');
      ShaderChunk.shadowmap_pars_fragment = shader;

    }

  }

  static createWireframeMaterial(isMesh: boolean) {

    if (isMesh)
      return new MeshBasicMaterial({wireframe: true, color: 0x070025});
    else
      return new MeshPhysicalMaterial({opacity: 0, transparent: true});

  }

  createShadedMaterial() {

    return new MeshPhysicalMaterial({
      color: 0x999999,
      roughness: 0.3,
      metalness: 0.3,
      reflectivity: 0.5,
      side: this.side
    });

  }

  static createGhostedMaterial(color: number) {

    let vertexShader = [
      'uniform float p;',
      'varying float intensity;',
      'void main() {',
      ' vec3 transformed = vec3( position );',
      ' vec4 mvPosition = vec4( transformed, 1.0 );',
      ' #ifdef USE_INSTANCING',
      '  mvPosition = instanceMatrix * mvPosition;',
      ' #endif',
      ' vec4 modelViewPosition = modelViewMatrix * mvPosition;',
      ' vec3 vNormal = normalize( normalMatrix * normal );',
      ' intensity = pow(1.0 - abs(dot(vNormal, vec3(0, 0, 1))), p);',
      ' gl_Position = projectionMatrix * modelViewPosition;',
      '}'
    ].join('\n');

    let fragmentShader = [
      'uniform vec3 glowColor;',
      'varying float intensity;',
      'void main() {',
      ' vec3 glow = glowColor * intensity;',
      ' gl_FragColor = vec4( glow, 1.0 );',
      '}'
    ].join('\n');

    return new ShaderMaterial({
      uniforms: {
        p: {value: 2},
        glowColor: {value: new Color(color)}
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      side: DoubleSide, blending: AdditiveBlending,
      transparent: true, depthWrite: false
    });

  }

  createMatcapMaskingMaterial(index: number = 0) {

    let material = new MeshMatcapMaterial({
      vertexColors: true,
      color: 0xffffff,
      side: this.side,
      matcap: new TextureLoader().load(matcapTextures[index])
    });

    material.onBeforeCompile = maskingMaterialBeforeCompile;
    material.needsUpdate = true;

    return material;
  }

  createMatcapMaterial(index: number = 0) {

    return new MeshMatcapMaterial({
      color: 0xffffff,
      side: this.side,
      matcap: new TextureLoader().load(matcapTextures[index])
    });

  }

  static createHeatmapMaterial() {

    return new MeshMatcapMaterial({
      vertexColors: true,
      color: 0xffffff,
      matcap: new TextureLoader().load(matcapTexture0)
    });

  }

  static hideElement(elem: HTMLElement) {

    elem.style.display = 'none';

  }

  static showElement(elem: HTMLElement) {

    elem.style.display = 'block';

  }

  static isElementVisible(elem: HTMLElement) {

    return elem.style.display === 'block';

  }

  static getBaseTransform(mi: IModelMetaInfo) {

    let nt = new Matrix4().makeTranslation(-mi.orgCenter[0], -mi.orgCenter[1], -mi.orgCenter[2]);
    let pt = new Matrix4().makeTranslation(mi.orgCenter[0], mi.orgCenter[1], mi.orgCenter[2]);
    let translate = getThreeVectorFromVec3(mi.translate);
    let rotate = new Euler(mi.rotate[0], mi.rotate[1], mi.rotate[2], 'YXZ');
    let scale = getThreeVectorFromVec3(mi.scale);
    let skew = getThreeVectorFromVec3(mi.skew);


    let baseTransform = new Matrix4()
      .multiply(pt)
      .multiply(composeThreeTransform(translate, rotate, scale, skew))
      .multiply(nt);

    return {nt, pt, baseTransform};

  }

  static composeGroupTransformMatrix(mi: IModelMetaInfo, transform: Matrix4) {

    let {nt, pt, baseTransform} = Editor3d.getBaseTransform(mi);

    transform.premultiply(pt).multiply(nt);

    transform.multiply(baseTransform.clone().invert());

    return transform;

  }

  static decomposeGroupTransformMatrix(mi: IModelMetaInfo, transformMatrix: Matrix4) {

    let {nt, pt, baseTransform} = Editor3d.getBaseTransform(mi);

    baseTransform.premultiply(transformMatrix);

    baseTransform.premultiply(nt).multiply(pt);

    return baseTransform;

  }

  static createPointPickerMaterial() {

    let canvas = document.createElement('canvas');
    canvas.width = canvas.height = 512;

    let ctx = canvas.getContext('2d');

    if (ctx !== null) {

      ctx.fillStyle = 'rgba( 255, 255, 255, 0 )';
      ctx.fillRect(0, 0, 512, 512);

      ctx.fillStyle = 'rgba( 0, 0, 0, 1 )';
      ctx.beginPath();
      ctx.ellipse(256, 256, 256, 256, 0, 0, Math.PI * 2);
      ctx.fill();

      ctx.fillStyle = 'rgba( 255, 255, 255, 1 )';
      ctx.beginPath();
      ctx.ellipse(256, 256, 128, 128, 0, 0, Math.PI * 2);
      ctx.fill();

    }

    let texture = new Texture(canvas);
    texture.needsUpdate = true;

    return new SpriteMaterial({map: texture, depthTest: false});

  }

  static createClosePickerMaterial() {

    let canvas = document.createElement('canvas');
    canvas.width = canvas.height = 512;

    let ctx = canvas.getContext('2d');

    if (ctx !== null) {

      ctx.fillStyle = 'rgba( 255, 255, 255, 0 )';
      ctx.fillRect(0, 0, 512, 512);

      ctx.fillStyle = 'rgba( 255, 255, 255, 1 )';
      ctx.beginPath();
      ctx.ellipse(256, 256, 256, 256, 0, 0, Math.PI * 2);
      ctx.fill();

      ctx.fillStyle = 'rgba( 0, 0, 0, 1 )';
      ctx.beginPath();
      ctx.ellipse(256, 256, 224, 224, 0, 0, Math.PI * 2);
      ctx.fill();

      ctx.strokeStyle = 'rgba( 255, 255, 255, 1 )';
      ctx.lineWidth = 32;
      ctx.beginPath();
      ctx.moveTo(128, 128);
      ctx.lineTo(384, 384);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(128, 384);
      ctx.lineTo(384, 128);
      ctx.stroke();

    }

    let texture = new Texture(canvas);
    texture.needsUpdate = true;

    return new SpriteMaterial({map: texture, depthTest: false});

  }

  static updateLineSegmentGeometry(lineGeometry: BufferGeometry, from: Vector3, to: Vector3) {

    if (lineGeometry.attributes.position) {

      lineGeometry.attributes.position.setXYZ(0, from.x, from.y, from.z);
      lineGeometry.attributes.position.setXYZ(1, to.x, to.y, to.z);
      (lineGeometry.attributes.position as Float32BufferAttribute).needsUpdate = true;

    } else {

      lineGeometry.attributes.position = new Float32BufferAttribute([
        ...from.toArray(), ...to.toArray()
      ], 3);

    }

  }

  static createTargetObject(lightName: string, lightPosition: vec3): Object3D {

    let targetObj: Object3D = new Object3D();
    targetObj.name = lightName + '-target';
    targetObj.position.set(lightPosition[0], lightPosition[1], lightPosition[2]);

    return targetObj;

  }

  static saveFile(strData: any, filename: string) {

    let link = document.createElement('a');
    link.download = filename;
    link.href = strData;
    document.body.appendChild(link); //Firefox requires the link to be in the body
    link.click();
    document.body.removeChild(link); //remove the link when done

  }

  constructor(elemId: string) {

    Object3D.DefaultUp = new Vector3(0, 0, 1);

    this.pixelRatio = window.devicePixelRatio || 1;
    this.antialias = this.pixelRatio <= 1;

    let fontLoader = new FontLoader();
    this.boldFont = fontLoader.parse(helvetikerBoldFont);
    this.font = fontLoader.parse(helvetikerFont);

    this.initMain(elemId);

    this.initDrawingPolyline();

    this.initDrawingCurve();

    // Viewer.replaceShadowShader();
    this.createSharedMaterials();

    this.loadDefaultHDREnvMap();

    let selectionGroup = new Group();
    selectionGroup.name = `selection-group`;
    selectionGroup.matrixAutoUpdate = false;

    this.selection = {
      tool: Tools.Gumball,
      ids: [],
      editLevels: [],
      selecting: false,
      group: selectionGroup,
      orgGroupMatrix: new Matrix4(),
      groupMIM: new MeshInstancingManager(selectionGroup),
      meshes: [],
      wfMeshes: [],
      sMeshes: [],
      meshGroups: {},
      edgeGroups: {},
      boundingBox: new Box3(),
      globalBoundingBox: new Box3(),
      space: 'world',
      groups: {},
      groupMIMs: {}
    };

    this.baseZ = 0;
    this.refreshFloor();
    this.refreshBasePlane();

    // let axesHelper = new AxesHelper( 500 );
    // this.cubeScene.add( axesHelper );

    this.selectionBox = new SelectionBox(this.getCamera(), this.scene);
    this.selectionHelper = new SelectionHelper(this.selectionBox, 'model-selection-box');

    this.orthoOrbitControl.addEventListener('change', this.onOrbitControlChange);
    this.orthoOrbitControl.addEventListener('start', this.onOrbitControlChange);
    this.orthoOrbitControl.addEventListener('end', this.onOrbitControlChange);

    this.perspOrbitControl.addEventListener('change', this.onOrbitControlChange);
    this.perspOrbitControl.addEventListener('start', this.onOrbitControlChange);
    this.perspOrbitControl.addEventListener('end', this.onOrbitControlChange);

    if (this.minorOrbitControl)
      this.minorOrbitControl.addEventListener('change', this.onMinorOrbitControlChange);

    this.transformControl.addEventListener('change', this.onTransformControlChange);
    this.snapControl.addEventListener('change', this.onSnapControlChange);
    this.magnetControl.addEventListener('change', this.onMagnetControlChange);
    this.mirrorControl.addEventListener('change', this.onMirrorControlChange);
    this.alignControl.addEventListener('change', this.onAlignControlChange);
    this.arrayControl.addEventListener('change', this.onArrayControlChange);
    this.sculptControl.addEventListener('pork', this.onSculptControlPork);
    this.sculptControl.addEventListener('change', this.onSculptControlChange);
    this.sculptControl.addEventListener('change material', this.onSculptControlMaterialChange);
    this.sculptControl.addEventListener('change config', this.onSculptControlConfigChange);
    this.sculptControl.addEventListener('render', this.onSculptControlRender);
    this.sculptControl.addEventListener('external operation', this.onSculptControlMeshExternalOperation);

    this.offsetInputElement = this.createNumberInputElement('INPUT_OFFSET', (id, value) => {

      this.actionCallback({action: ACT_COMMIT_OFFSET, value: value});
      return value;

    });

    this.annotateInputElement = this.createStringInputElement('INPUT_ANNOTATION', (id, value) => {

      if (this.annotateInputElement)
        Editor3d.hideElement(this.annotateInputElement);

      if (this.editingAnnotateId > 0) {

        this.annotates[this.editingAnnotateId].text = value ? value : this.defaultAnnotationText;

        if (this.annotateState === AnnotateControlStates.InputtingText)
          this.saveAnnotate();

      }

      return value;

    }, this.defaultAnnotationText);

    // this.initPointSnap();
    this.refreshTransformControl();

    let svgLoader = new SVGLoader();

    svgLoader.load(
      iconImport,
      (data) => {
        let paths = data.paths;
        let group = new Group();

        for (let i = 0; i < paths.length; i++) {

          let path = paths[i];

          let material = new MeshBasicMaterial({
            side: DoubleSide,
            depthWrite: false
          });

          let shapes = path.toShapes(true);

          for (let j = 0; j < shapes.length; j++) {

            let shape = shapes[j];
            let geometry = new ShapeBufferGeometry(shape);
            geometry.computeBoundingBox();
            let center = new Vector3();
            if (geometry.boundingBox) {
              geometry.boundingBox.getCenter(center);
              geometry.translate(-center.x, -center.y, -center.z);
            }

            let mesh = new Mesh(geometry, material);
            mesh.position.set(0, this.gridWidth / 4, 0);

            group.add(mesh);

          }

        }

        let lineGeometry = new LineGeometry();
        let lineMaterial = new LineMaterial({
          color: 0xDBE1CA,
          gapSize: 1,
          dashSize: 1,
          dashScale: 0.2,
          linewidth: 3,
          dashed: true
        });
        let line = new Line2(lineGeometry, lineMaterial);
        lineMaterial.defines.USE_DASH = "";
        lineMaterial.resolution.set(this.width, this.height);

        this.importModelIcon = group;
        this.importModelIcon.visible = false;
        this.importModelOutline = line;
        this.importModelIcon.visible = false;

        let importGroup = new Group();
        importGroup.name = `import-svg`;
        importGroup.add(this.importModelIcon);
        importGroup.add(this.importModelOutline);

        this.sceneMIM.add(importGroup);

        this.refreshImportIcon();

      }
    );

    this.refreshLineMaterialResolution();

    this.animate();
    console.log('3d viewer: creating scene');

  }

  setDelegate(delegate: Editor3dDelegate) {
    this.unsetDelegate();
    this.delegate = delegate;

    if (delegate.cubeDomElement) {
      let allRect = delegate.domElement.getBoundingClientRect() as DOMRect;
      let cubeRect = delegate.cubeDomElement.getBoundingClientRect() as DOMRect;

      this.cubeMarginX = cubeRect.x - allRect.x;
      this.cubeMarginY = cubeRect.y - allRect.y;
    } else {
      this.cubeMarginX = -1;
      this.cubeMarginY = -1;
    }

    this.transformControl.setDelegate(delegate);
    this.alignControl.setDelegate(delegate);
    this.arrayControl.setDelegate(delegate);
    this.magnetControl.setDelegate(delegate);
    this.mirrorControl.setDelegate(delegate);
    this.sculptControl.setDelegate(delegate);
    this.snapControl.setDelegate(delegate);
    this.selectionHelper.setDelegate(delegate);

    delegate.domElement.addEventListener('touchstart', this.onTouchStart, false);
    delegate.domElement.addEventListener('touchmove', this.onTouchMove, false);
    delegate.domElement.addEventListener('touchend', this.onTouchEnd, false);
    delegate.domElement.addEventListener('mousemove', this.onMouseMove, false);
    delegate.domElement.addEventListener('mouseleave', this.onMouseLeave, false);
    delegate.domElement.addEventListener('mousedown', this.onMouseDown, false);
    delegate.domElement.addEventListener('mouseup', this.onMouseUp, false);

    this.orthoOrbitControl.setDelegate(delegate);
    this.perspOrbitControl.setDelegate(delegate);

    if (delegate.minorDomElement && this.minorOrbitControl)
      this.minorOrbitControl.setDelegate({domElement: delegate.minorDomElement});

    for (let lightId in this.lights) {
      let light = this.lights[lightId];
      if (light.helper)
        light.helper.setDelegate(delegate);
    }

    this.render();
  }

  unsetDelegate() {
    if (this.delegate) {
      this.delegate.domElement.removeEventListener('mousemove', this.onMouseMove);
      this.delegate.domElement.removeEventListener('mouseleave', this.onMouseLeave);
      this.delegate.domElement.removeEventListener('mousedown', this.onMouseDown);
      this.delegate.domElement.removeEventListener('mouseup', this.onMouseUp);
      this.delegate.domElement.removeEventListener('touchstart', this.onTouchStart);
      this.delegate.domElement.removeEventListener('touchmove', this.onTouchMove);
      this.delegate.domElement.removeEventListener('touchend', this.onTouchEnd);

      this.orthoOrbitControl.unsetDelegate();
      this.perspOrbitControl.unsetDelegate();

      if (this.minorOrbitControl)
        this.minorOrbitControl.unsetDelegate();

      this.transformControl.unsetDelegate();
      this.alignControl.unsetDelegate();
      this.arrayControl.unsetDelegate();
      this.magnetControl.unsetDelegate();
      this.mirrorControl.unsetDelegate();
      this.sculptControl.unsetDelegate();
      this.snapControl.unsetDelegate();
      this.selectionHelper.unsetDelegate();

      for (let lightId in this.lights) {
        let light = this.lights[lightId];
        if (light.helper)
          light.helper.unsetDelegate();
      }
    }

    this.delegate = undefined;
  }

  initStats() {

    this.stats = Stats();

    this.drawCallPanel = Stats.Panel('DCS', '#ff8', '#221');
    this.stats.addPanel(this.drawCallPanel);

    this.hadUpdatePanel = Stats.Panel('HU', '#f0f', '#202');
    this.stats.addPanel(this.hadUpdatePanel);

    this.stats.showPanel(0);
    document.body.appendChild(this.stats.dom);
    this.stats.dom.style.display = 'block';
    this.stats.dom.style.top = 'unset';
    this.stats.dom.style.left = 'unset';
    this.stats.dom.style.bottom = '32px';
    this.stats.dom.style.right = '32px';

  }

  initMinor(elemId: string) {

    // CONTAINER
    this.container = document.getElementById(elemId)!;

    this.minorWidth = 1;
    this.minorHeight = 1;

    // CAMERA
    this.minorCamera = new OrthographicCamera(
      this.width / -2,
      this.width / 2,
      this.height / 2,
      this.height / -2,
      0,
      SCENE_DIMENSION
    );

    this.minorCamera.position.set(0, 0.1, SCENE_DIMENSION / 2);

    // RENDERER
    this.minorRenderer = new WebGL1Renderer({
      antialias: this.antialias,
      alpha: true,
      powerPreference: "high-performance",
    });

    this.minorRenderer.setPixelRatio(this.pixelRatio);
    this.minorRenderer.setClearColor(0xF0F0F0);
    this.minorRenderer.setSize(this.minorWidth, this.minorHeight);
    this.minorRenderer.shadowMap.enabled = false;

    this.minorRenderer.toneMapping = CineonToneMapping;
    this.minorRenderer.toneMappingExposure = 1;
    this.minorRenderer.outputEncoding = GammaEncoding;

    this.minorOrbitControl = new OrbitControl(this.minorCamera);
    this.minorOrbitControl.enableKeys = false;
    this.minorOrbitControl.screenSpacePanning = true;
    this.minorOrbitControl.enableRotate = false;
    this.minorOrbitControl.panOnLeft = true;

    // ADD ON SCENE
    this.container.appendChild(this.minorRenderer.domElement);

  }

  initMain(elemId: string) {
    // CONTAINER
    this.container = document.getElementById(elemId)!;

    this.width = this.container.clientWidth || 1;
    this.height = this.container.clientHeight || 1;

    this.elemRect = this.delegate ? this.delegate.domElement.getBoundingClientRect() : this.container.getBoundingClientRect();

    // CAMERA
    this.perspCamera = new PerspectiveCamera(this.cameraAngle, this.width / this.height, 1, 70000);
    this.orthoCamera = new OrthographicCamera(
      this.width / -2,
      this.width / 2,
      this.height / 2,
      this.height / -2,
      0,
      SCENE_DIMENSION
    );

    this.orthoCamera.position.set(0, SCENE_DIMENSION / 2, 0);
    this.cameraTo = new Vector3(0, SCENE_DIMENSION / 2, 0);

    // SCENE
    this.scene = new Scene();
    this.controlScene = new Scene();
    //this.scene.background = new Color(0xf6f6f6)

    this.sceneMIM = new MeshInstancingManager(this.scene);

    // RENDERER
    this.renderer = new WebGL1Renderer({
      antialias: this.antialias,
      alpha: true,
      powerPreference: "high-performance",
    });

    this.renderer.setPixelRatio(this.pixelRatio);
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.setSize(this.width, this.height);
    this.renderer.shadowMap.type = PCFSoftShadowMap;
    this.renderer.shadowMap.enabled = true;

    this.renderer.toneMapping = CineonToneMapping;
    this.renderer.toneMappingExposure = 1;
    this.renderer.outputEncoding = GammaEncoding;
    this.renderer.domElement.id = 'shaded-canvas';

    this.controlRenderer = new WebGL1Renderer({
      antialias: this.antialias,
      alpha: true,
      powerPreference: "high-performance",
    });

    this.controlRenderer.setPixelRatio(this.pixelRatio);
    this.controlRenderer.setClearColor(0x000000, 0);
    this.controlRenderer.setSize(this.width, this.height);
    this.controlRenderer.domElement.id = 'control-canvas';

    this.envMapRenderer = new WebGL1Renderer({
      antialias: this.antialias,
      alpha: true,
      powerPreference: "high-performance",
    });

    this.envMapRenderer.setPixelRatio(this.pixelRatio);
    this.envMapRenderer.setClearColor(0xF0F0F0);
    this.envMapRenderer.setSize(this.width, this.height);
    this.envMapRenderer.toneMappingExposure = 1;
    this.envMapRenderer.domElement.id = 'env-map-canvas';

    this.backgroundEnvMapType = EnvMapTypes.Color;
    this.envMapExposure = 1.0;
    this.envMapChanged = true;
    this.envMapTexture = new Color(0xf8f8f8);
    this.envMapCamera = new PerspectiveCamera(45, this.width / this.height, 1, 70000);
    this.envMapScene = new Scene();

    this.composer = new EffectComposer(this.renderer);

    // CONTROLS
    this.alignControl = new AlignControl(this.getCamera(), this.renderer, this.sceneMIM, this.composer);
    this.transformControl = new TransformControl(this.getCamera(), this.sceneMIM, this.controlScene);
    this.snapControl = new SnapControl(this.getCamera(), this.sceneMIM, this.controlScene);
    this.magnetControl = new MagnetControl(this.getCamera(), this.sceneMIM, this.controlScene);
    this.mirrorControl = new MirrorControl(this.getCamera(), this.sceneMIM, this.controlScene);
    this.arrayControl = new ArrayControl(this.getCamera(), this.sceneMIM);
    this.sculptControl = new SculptControl(this.getCamera(), this.sceneMIM);

    this.sceneMIM.add(this.alignControl);
    this.controlScene.add(this.transformControl);
    this.controlScene.add(this.snapControl);
    this.controlScene.add(this.magnetControl);
    this.controlScene.add(this.mirrorControl);
    this.sceneMIM.add(this.arrayControl);
    this.sceneMIM.add(this.sculptControl);

    this.previewGroup.name = `preview-group`;
    this.sceneMIM.add(this.previewGroup);

    // ADD EVENT
    document.addEventListener('keydown', this.onGlobalKeyDown, false);
    document.addEventListener('keyup', this.onGlobalKeyUp, false);

    // ORBIT CONTROL
    this.orthoOrbitControl = new OrbitControl(this.orthoCamera);
    this.orthoOrbitControl.enableKeys = false;
    this.orthoOrbitControl.screenSpacePanning = true;

    // ORBIT CONTROL
    this.perspOrbitControl = new OrbitControl(this.perspCamera);
    this.perspOrbitControl.enableKeys = false;
    this.perspOrbitControl.screenSpacePanning = true;

    this.controlTargetTo = new Vector3(0, 0, 0);
    this.zoomTo = 1;

    this.setPerspFromOrtho();

    // CONTAINER
    this.container.appendChild(this.renderer.domElement);
    this.container.appendChild(this.envMapRenderer.domElement);
    this.container.appendChild(this.controlRenderer.domElement);

  }

  get controlSceneHasContent() {
    return this.transformControl.visible || this.snapControl.visible || this.magnetControl.visible || this.mirrorControl.visible;
  }

  initCube(elemId: string) {
    // CONTAINER
    this.container = document.getElementById(elemId)!;

    // CUBE CAMERA
    this.cubeCamera = new PerspectiveCamera(45, 1, 1, 550);
    this.cubeCamera.position.set(0, 100, 0);

    // CUBE SCENE
    this.cubeScene = new Scene();

    // CUBE RENDERER
    this.cubeRenderer = new WebGL1Renderer({
      antialias: this.antialias,
      alpha: true,
      powerPreference: "high-performance",
    });

    this.cubeRenderer.setPixelRatio(this.pixelRatio);
    this.cubeRenderer.setSize(this.cubeSize, this.cubeSize);

    let cubeGeometry = new BoxBufferGeometry(100, 100, 100);

    this.cubeMesh = new Mesh(cubeGeometry, new MeshBasicMaterial({
      color: 0xffffff
    }));
    this.cubeScene.add(this.cubeMesh);

    for (let inds of [[0, 0, 0, 1, 0, 0],
      [0, 0, 0, 0, 1, 0],
      [0, 0, 0, 0, 0, 1],
      [0, 0, 1, 0, 1, 1],
      [0, 0, 1, 1, 0, 1],
      [0, 1, 0, 0, 1, 1],
      [0, 1, 0, 1, 1, 0],
      [0, 1, 1, 1, 1, 1],
      [1, 0, 0, 1, 0, 1],
      [1, 0, 0, 1, 1, 0],
      [1, 0, 1, 1, 1, 1],
      [1, 1, 0, 1, 1, 1]
    ]) {

      let line = new Line2(new LineGeometry(), new LineMaterial({
        color: new Color(this.defaultColor).getHex(),
        linewidth: 2
      }));
      (line.geometry as LineGeometry).setPositions(inds.map(m => m * 102 - 51));
      this.cubeScene.add(line);

    }

    let frontGeometry = new TextBufferGeometry('front', {
      font: this.boldFont,
      size: 20,
      height: 2,
      curveSegments: FONT_CURVE_SEGMENTS,
      bevelEnabled: false
    });
    frontGeometry.center();
    let frontMesh = new Mesh(frontGeometry, this.defaultBasicMaterial);
    frontMesh.position.set(0, 50, 0);
    frontMesh.setRotationFromEuler(new Euler(-Math.PI / 2, 0, Math.PI));
    this.cubeScene.add(frontMesh);

    let leftGeometry = new TextBufferGeometry('left', {
      font: this.boldFont,
      size: 20,
      height: 2,
      curveSegments: FONT_CURVE_SEGMENTS,
      bevelEnabled: false
    });
    leftGeometry.center();
    let leftMesh = new Mesh(leftGeometry, this.defaultBasicMaterial);
    leftMesh.position.set(50, 0, 0);
    leftMesh.setRotationFromEuler(new Euler(0, Math.PI / 2, Math.PI / 2));
    this.cubeScene.add(leftMesh);

    let rightGeometry = new TextBufferGeometry('right', {
      font: this.boldFont,
      size: 20,
      height: 2,
      curveSegments: FONT_CURVE_SEGMENTS,
      bevelEnabled: false
    });
    rightGeometry.center();
    let rightMesh = new Mesh(rightGeometry, this.defaultBasicMaterial);
    rightMesh.position.set(-50, 0, 0);
    rightMesh.setRotationFromEuler(new Euler(0, -Math.PI / 2, -Math.PI / 2));
    this.cubeScene.add(rightMesh);

    let backGeometry = new TextBufferGeometry('back', {
      font: this.boldFont,
      size: 20,
      height: 2,
      curveSegments: FONT_CURVE_SEGMENTS,
      bevelEnabled: false
    });
    backGeometry.center();
    let backMesh = new Mesh(backGeometry, this.defaultBasicMaterial);
    backMesh.position.set(0, -50, 0);
    backMesh.setRotationFromEuler(new Euler(Math.PI / 2, 0, 0));
    this.cubeScene.add(backMesh);

    let topGeometry = new TextBufferGeometry('top', {
      font: this.boldFont,
      size: 20,
      height: 2,
      curveSegments: FONT_CURVE_SEGMENTS,
      bevelEnabled: false
    });
    topGeometry.center();
    let topMesh = new Mesh(topGeometry, this.defaultBasicMaterial);
    topMesh.position.set(0, 0, 50);
    topMesh.setRotationFromEuler(new Euler(Math.PI, Math.PI, 0));
    this.cubeScene.add(topMesh);

    let bottomGeometry = new TextBufferGeometry('bottom', {
      font: this.boldFont,
      size: 20,
      height: 2,
      curveSegments: FONT_CURVE_SEGMENTS,
      bevelEnabled: false
    });
    bottomGeometry.center();
    let bottomMesh = new Mesh(bottomGeometry, this.defaultBasicMaterial);
    bottomMesh.position.set(0, 0, -50);
    bottomMesh.setRotationFromEuler(new Euler(0, Math.PI, 0));
    this.cubeScene.add(bottomMesh);

    // ADD CUBE ON SCENE
    this.container.appendChild(this.cubeRenderer.domElement);
  }

  initCurrentAnnotation() {
    if (this.editingAnnotateId > 0) {
      this.annotates[this.editingAnnotateId] = {
        id: this.editingAnnotateId,
        start: {
          calcId: '',
          meshIndex: 0,
          position: [0, 0, 0]
        },
        offset: [0, 0, 0],
        text: this.defaultAnnotationText,
        font: 'Arial',
        height: 12,
        color: this.defaultColor,
        styles: {
          'bold': 'false',
          'italic': 'false',
          'underline': 'false',
          'lineStyle': 'none'
        },
        visible: true,
        collapsed: true
      };
    }
  }

  initCurrentMeasurement() {
    if (this.editingMeasureId > 0) {
      this.measures[this.editingMeasureId] = {
        id: this.editingMeasureId,
        start: {
          calcId: '',
          meshIndex: 0,
          position: [0, 0, 0]
        },
        end: {
          calcId: '',
          meshIndex: 0,
          position: [0, 0, 0]
        },
        mainAxis: 'x',
        subAxis: 'y',
        distance: 0,
        offDistance: 0,
        visible: true
      };
    }
  }

  disposeMinor() {

    if (this.minorOrbitControl) {
      this.minorOrbitControl.removeEventListener('change', this.onMinorOrbitControlChange);
      this.minorOrbitControl.dispose();

      this.minorOrbitControl = undefined;
    }

    if (this.minorRenderer) {
      this.minorRenderer.dispose();
      this.minorRenderer.forceContextLoss();

      this.container.removeChild(this.minorRenderer.domElement);
      this.minorRenderer = undefined;
    }

    this.minorCamera = undefined;

  }

  disposeCube() {

    if (this.cubeRenderer) {
      this.cubeRenderer.dispose();
      this.cubeRenderer.forceContextLoss();

      this.container.removeChild(this.cubeRenderer.domElement);
      this.cubeRenderer = undefined;
    }

    this.cubeCamera = undefined;

    cleanScene(this.cubeScene);
    this.cubeScene = undefined;

  }

  disposeStats() {

    if (this.stats)
      document.body.removeChild(this.stats.dom);

    this.stats = undefined;
    this.drawCallPanel = undefined;
    this.hadUpdatePanel = undefined;

  }

  dispose() {

    document.removeEventListener('keydown', this.onGlobalKeyDown);
    document.removeEventListener('keyup', this.onGlobalKeyUp);

    this.orthoOrbitControl.removeEventListener('change', this.onOrbitControlChange);
    this.orthoOrbitControl.removeEventListener('start', this.onOrbitControlChange);
    this.orthoOrbitControl.removeEventListener('end', this.onOrbitControlChange);

    this.perspOrbitControl.removeEventListener('change', this.onOrbitControlChange);
    this.perspOrbitControl.removeEventListener('start', this.onOrbitControlChange);
    this.perspOrbitControl.removeEventListener('end', this.onOrbitControlChange);

    this.transformControl.removeEventListener('change', this.onTransformControlChange);
    this.snapControl.removeEventListener('change', this.onSnapControlChange);
    this.magnetControl.removeEventListener('change', this.onMagnetControlChange);
    this.mirrorControl.removeEventListener('change', this.onMirrorControlChange);
    this.alignControl.removeEventListener('change', this.onAlignControlChange);
    this.arrayControl.removeEventListener('change', this.onArrayControlChange);
    this.sculptControl.removeEventListener('pork', this.onSculptControlPork);
    this.sculptControl.removeEventListener('change', this.onSculptControlChange);
    this.sculptControl.removeEventListener('change material', this.onSculptControlMaterialChange);
    this.sculptControl.removeEventListener('change config', this.onSculptControlConfigChange);
    this.sculptControl.removeEventListener('render', this.onSculptControlRender);
    this.sculptControl.removeEventListener('external operation', this.onSculptControlMeshExternalOperation);

    this._clear();

    this.sceneMIM.dispose();

    cleanScene(this.scene);
    cleanScene(this.envMapScene);

    for (let id in this.sharedMaterials)
      this.sharedMaterials[id].dispose();

    if (this.defaultLightEnvMapTexture)
      this.defaultLightEnvMapTexture.dispose();

    if (this.lightEnvMapTexture)
      this.lightEnvMapTexture.dispose();

    //@ts-ignore
    if (this.envMapTexture.isTexture)
      (this.envMapTexture as Texture).dispose();

    try {
      new PMREMGenerator(this.renderer).dispose();
    } catch (e) {
      console.log(e);
    }

    this.alignControl.dispose();
    this.transformControl.dispose();
    this.snapControl.dispose();
    this.magnetControl.dispose();
    this.mirrorControl.dispose();
    this.arrayControl.dispose();
    this.sculptControl.dispose();
    this.orthoOrbitControl.dispose();
    this.perspOrbitControl.dispose();

    this.renderer.dispose();
    this.renderer.forceContextLoss();

    this.controlRenderer.dispose();
    this.controlRenderer.forceContextLoss();

    this.envMapRenderer.dispose();
    this.envMapRenderer.forceContextLoss();

    if (this.offsetInputElement)
      this.offsetInputElement.remove();

    if (this.annotateInputElement)
      this.annotateInputElement.remove();

    if (this.refreshServerRenderTimer) {

      clearInterval(this.refreshServerRenderTimer);
      this.refreshServerRenderTimer = undefined;

    }

    this.disposeMinor();
    this.disposeCube();
    this.disposeStats();

    this.disposed = true;

    console.log('3d viewer: disposing scene');

  }

  configure(config: Partial<IEditor3dConfig>) {

    if (config.editControls !== undefined)
      this.enableEditControls(config.editControls);
    if (config.lightControls !== undefined)
      this.enableLightControls(config.lightControls);
    if (config.highlight !== undefined)
      this.enableHighlight(config.highlight);
    if (config.hoverHighlight !== undefined)
      this.enableHoverHighlight(config.hoverHighlight);
    if (config.grid !== undefined)
      this.enableGrid(config.grid);
    if (config.cameraZoom !== undefined)
      this.enableCameraZoom(config.cameraZoom);
    if (config.cameraPan !== undefined)
      this.enableCameraPan(config.cameraPan);
    if (config.blob !== undefined)
      this.enableBlob(config.blob, config.blobPosition || [0, 0, 0], config.blobSize || 50);
    if (config.envMap !== undefined)
      this.enableEnvMap(config.envMap);
    if (config.orbit !== undefined)
      this.orbitCamera(config.orbit, config.orbitSpeed);

  }

  getCamera = () => {

    return this.cameraAngle > 10 ? this.perspCamera : this.orthoCamera;

  };

  replaceMaterialEnvMaps = () => {

    let newEnvMap = this.lightEnvMapTexture ? this.lightEnvMapTexture : this.defaultLightEnvMapTexture!;

    for (let materialId in this.materialMap) {

      let materialMap = this.materialMap[materialId];

      if (materialMap) {

        //@ts-ignore
        if (materialMap.material.isMeshPhysicalMaterial || materialMap.material.isMeshStandardMaterial) {

          let material = materialMap.material as MeshPhysicalMaterial;

          if (material.envMap !== newEnvMap || material.envMapIntensity !== this.envMapExposure) {

            material.envMap = newEnvMap;
            material.envMapIntensity = this.envMapExposure;
            material.needsUpdate = true;
            this.sceneMIM.needsUpdate = true;

          }

        }

      }

    }

    if (this.blobObject) {

      let material = this.blobObject.material as MeshPhysicalMaterial;

      if (material.envMap !== newEnvMap || material.envMapIntensity !== this.envMapExposure) {

        material.envMap = newEnvMap;
        material.envMapIntensity = this.envMapExposure;
        material.needsUpdate = true;

      }

    }

    if (this.selection.tool === Tools.Sculpt) {
      this.onSculptControlMaterialChange({type: 'change material', target: this.sculptControl});
    }

  };

  loadHDREnvMap(hdr: string, background: boolean, callback: (texture: any, equiRectTexture: any) => void) {

    let loader: RGBELoader = new RGBELoader();

    if (!hdr.startsWith('http'))
      loader.setPath(`${s3RootPath}/public/hdri/`);

    this.actionCallback({action: ACT_LOAD_ENVIRONMENT_START, file: hdr});

    loader.load(hdr, (texture) => {

      this.actionCallback({action: ACT_LOAD_ENVIRONMENT_END, file: hdr});

      if (!this.disposed) {

        if (background) {

          callback(texture, new WebGLCubeRenderTarget(4096, {
            generateMipmaps: true,
            minFilter: LinearMipmapLinearFilter,
            magFilter: LinearFilter
          }).fromEquirectangularTexture(this.renderer, texture));

        } else {

          let pmremGenerator = new PMREMGenerator(this.renderer);
          pmremGenerator.compileEquirectangularShader();
          callback(texture, pmremGenerator.fromEquirectangular(texture).texture);

        }

      }

    });

  }

  loadMultiResHDREnvMap(lowResHdr: string, hdr: string, background: boolean, callback: (texture: any, equiRectTexture: any) => void) {

    if (!lowResHdr && !hdr)
      return;

    if (!lowResHdr)
      return this.loadHDREnvMap(hdr, background, callback);

    if (!hdr)
      return this.loadHDREnvMap(lowResHdr, background, callback);

    this.loadHDREnvMap(lowResHdr, background, (texture, equiRectTexture) => {
      callback(texture, equiRectTexture);

      this.loadHDREnvMap(hdr, background, callback);
    });

  }

  loadDefaultHDREnvMap() {

    this.loadMultiResHDREnvMap('ht_default.hdr', 'default.hdr', false, (texture, equiRectTexture) => {

      this.defaultLightEnvMapTexture = equiRectTexture;

      if (!this.lightingEnvironment || this.lightingEnvironment.hdrThumbnail === 'ht_default.hdr')
        this.replaceMaterialEnvMaps();

      texture.dispose();

    });

  }

  initPointSnap() {

    this.pointSnapText = new SpriteText2D('Point', {
      align: textAlign.top,
      font: '100px Arial',
      fillStyle: '#000000',
      shadowColor: '#000000',
      antialias: this.antialias
    });

    this.pointSnapText.scale.set(0.05, 0.05, 0.05);
    this.pointSnapText.material.transparent = true;
    this.pointSnapText.visible = false;
    this.sceneMIM.add(this.pointSnapText);

    let markerGeometry = new SphereBufferGeometry(2);
    // let markerGeometry = new SphereBufferGeometry(0.2)
    let pointSnapMesh = new Mesh(markerGeometry, this.defaultBasicMaterial);
    pointSnapMesh.visible = false;
    this.sceneMIM.add(pointSnapMesh);
    this.pointSnapMesh = pointSnapMesh;

  }

  initDrawingPolyline() {

    if (this.polyline) {

      this.polyline.group.remove(...this.polyline.group.children);
      this.sceneMIM.remove(this.polyline.group);

    }

    let polylineGeometry = new BufferGeometry();
    polylineGeometry.attributes.position = new Float32BufferAttribute([], 3);
    this.polyline = {
      points: [],
      pointSprites: [],
      line: new Line(polylineGeometry, new LineBasicMaterial({color: 0x000000})),
      group: new Group()
    };

    this.sceneMIM.add(this.polyline.group);

    this.polyline.group.name = `polyline`;
    this.polyline.group.add(this.polyline.line);

  }

  initDrawingCurve() {

    if (this.curve) {

      this.curve.group.remove(...this.curve.group.children);
      this.sceneMIM.remove(this.curve.group);

    }

    let curveGeometry = new BufferGeometry();
    curveGeometry.attributes.position = new Float32BufferAttribute([], 3);

    let geometry = new BufferGeometry();
    geometry.attributes.position = new Float32BufferAttribute([], 3);

    this.curve = {
      points: [],
      pointSprites: [],
      line: new Line(curveGeometry, new LineBasicMaterial({color: 0x000000})),
      baseLine: new Line(geometry, new LineDashedMaterial({color: 0x000000, dashSize: 0.3, gapSize: 0.2})),
      group: new Group(),
      periodic: false
    };
    this.curve.baseLine.computeLineDistances();

    this.sceneMIM.add(this.curve.group);

    this.curve.group.name = `curve`;
    this.curve.group.add(this.curve.line);
    this.curve.group.add(this.curve.baseLine);

  }

  refreshDPointScale() {
    let zoom = 1 / this.orthoCamera.zoom;

    for (let pointMarker of this._dPoints) {
      pointMarker.scale.set(zoom, zoom, zoom);
    }
  }

  addDPoint(position: vec3) {
    let pointGeometry = new SphereBufferGeometry(3);
    let pointMaterial = new MeshBasicMaterial({color: '#000000', transparent: true, depthTest: false});
    let pointMarker = new Mesh(pointGeometry, pointMaterial);

    let pointPos = getThreeVectorFromVec3(position);
    this._dPointPositions.push(pointPos);

    pointMarker.position.copy(pointPos.clone().applyMatrix4(this._dPointMatrix));
    this._dPoints.push(pointMarker);

    this.refreshDPointScale();

    this.sceneMIM.add(pointMarker);
  }

  clearDPoints() {
    this.sceneMIM.remove(...this._dPoints);
    this._dPoints = [];
  }

  undoAddDPoint() {
    if (this._dPoints.length > 0) {
      this.sceneMIM.remove(this._dPoints[this._dPoints.length - 1]);
      this._dPoints.pop();
    }
  }

  setDPointMatrix(matrix: mat4) {
    this._dPointMatrix = getThreeTransformFromMat4(matrix);

    for (let i = 0; i < this._dPoints.length; ++i) {
      this._dPoints[i].position.copy(this._dPointPositions[i].clone().applyMatrix4(this._dPointMatrix));
      this.setNeedsUpdate();
    }
  }

  setNeedsUpdate() {

    this.oldCameraState = {};
    this.oldMinorCameraState = {};

  }

  createSharedMaterials() {

    this.sharedMaterials[SharedMaterial.None] = new MeshBasicMaterial({visible: false});
    this.sharedMaterials[SharedMaterial.None].userData.hash = 'mat-none';

    this.sharedMaterials[SharedMaterial.Wireframe] = Editor3d.createWireframeMaterial(false);
    this.sharedMaterials[SharedMaterial.Wireframe].userData.hash = 'mat-wireframe';

    this.sharedMaterials[SharedMaterial.Shaded] = this.createShadedMaterial();
    this.sharedMaterials[SharedMaterial.Shaded].userData.hash = 'mat-shaded';

    this.sharedMaterials[SharedMaterial.Ghosted] = Editor3d.createGhostedMaterial(0x141219);
    this.sharedMaterials[SharedMaterial.Ghosted].userData.hash = 'mat-ghosted';

    this.sharedMaterials[SharedMaterial.Matcap] = this.createMatcapMaterial();
    this.sharedMaterials[SharedMaterial.Matcap].userData.hash = 'mat-matcap';

    this.sharedMaterials[SharedMaterial.OutOfEdit] = Editor3d.createGhostedMaterial(0x200000);
    this.sharedMaterials[SharedMaterial.OutOfEdit].userData.hash = 'mat-outofedit';

    this.sharedMaterials[SharedMaterial.Cursor] = this.createMatcapMaterial(8);
    this.sharedMaterials[SharedMaterial.Cursor].userData.hash = 'mat-cursor';

    this.sharedMaterials[SharedMaterial.Heatmap] = Editor3d.createHeatmapMaterial();
    this.sharedMaterials[SharedMaterial.Heatmap].userData.hash = 'mat-heatmap';

    this.sharedMaterials[SharedMaterial.EdgeLine] = new LineBasicMaterial({color: 0x000000});
    this.sharedMaterials[SharedMaterial.EdgeLine].userData.hash = 'mat-edgeline';

    this.sharedMaterials[SharedMaterial.InvalidEdgeLine] = new LineBasicMaterial({color: 0xFF0000});
    this.sharedMaterials[SharedMaterial.InvalidEdgeLine].userData.hash = 'mat-invalid-edgeline';

    this.sharedMaterials[SharedMaterial.BoxLine] = new LineBasicMaterial({color: 0x000000});
    this.sharedMaterials[SharedMaterial.BoxLine].userData.hash = 'mat-boxline';

    this.sharedMaterials[SharedMaterial.InvalidBoxLine] = new LineBasicMaterial({color: 0xFF0000});
    this.sharedMaterials[SharedMaterial.InvalidBoxLine].userData.hash = 'mat-invalid-boxline';

    this.sharedMaterials[SharedMaterial.MatcapMasking] = this.createMatcapMaskingMaterial();
    this.sharedMaterials[SharedMaterial.MatcapMasking].userData.hash = 'mat-matcap-masking';

    this.sharedMaterials[SharedMaterial.PointPicker] = Editor3d.createPointPickerMaterial();
    this.sharedMaterials[SharedMaterial.PointPicker].userData.hash = 'mat-point-picker';

    this.sharedMaterials[SharedMaterial.ClosePicker] = Editor3d.createClosePickerMaterial();
    this.sharedMaterials[SharedMaterial.ClosePicker].userData.hash = 'mat-close-picker';

    this.sharedMaterials[SharedMaterial.MeshWireframe] = Editor3d.createWireframeMaterial(true);
    this.sharedMaterials[SharedMaterial.MeshWireframe].userData.hash = 'mat-mesh-wireframe';

    this.sharedMaterials[SharedMaterial.Line] = new LineBasicMaterial({color: 0x000000});
    this.sharedMaterials[SharedMaterial.Line].userData.hash = 'mat-line';

    this.sharedMaterials[SharedMaterial.Vertex] = new PointsMaterial({color: 0x000000});
    this.sharedMaterials[SharedMaterial.Vertex].userData.hash = 'mat-points';

  }

  getCurrentMaterial(viewType: number, renderedObjectType: RenderedObjectTypes) {
    if (renderedObjectType === RenderedObjectTypes.Mesh && viewType === ViewTypes.Wireframe)
      return this.sharedMaterials[SharedMaterial.MeshWireframe];

    if (renderedObjectType === RenderedObjectTypes.Line) {
      return this.sharedMaterials[SharedMaterial.Line];
    } else if (renderedObjectType === RenderedObjectTypes.Vertex) {
      return this.sharedMaterials[SharedMaterial.Vertex];
    }

    return this.sharedMaterials[viewType];
  }

  isViewTypeSupported(viewType: number) {

    return true;

  }

  _setViewType(viewType: number, noRefresh?: boolean) {

    if (this.viewType !== viewType) {

      if (this.delegate && this.delegate.overlayCanvasElement) {

        if (viewType === ViewTypes.ServerRendered)
          this.delegate.overlayCanvasElement.style.display = 'block';
        else if (this.viewType === ViewTypes.ServerRendered)
          this.delegate.overlayCanvasElement.style.display = 'none';

      }

      this.viewType = viewType;

      if (noRefresh)
        this.refreshMaterialsLater = true;
      else
        this.refreshMaterials();

      this.onSculptControlMaterialChange({type: 'change material', target: this.sculptControl});

    }

    return true;

  }

  createStringInputElement(className: string, commitFunc: (id: string, val: string) => string, placeholder?: string) {

    let elem = document.createElement('textarea');
    elem.style.position = 'fixed';
    elem.style.zIndex = '9999';
    elem.style.display = 'none';
    elem.autocomplete = 'off';
    elem.className = className;

    if (placeholder)
      elem.placeholder = placeholder;

    elem.onkeypress = (evt) => {

      if (evt.key !== 'Enter' || evt.shiftKey) return;

      let target = (evt.target || evt.srcElement) as HTMLTextAreaElement;

      if (target)
        target.blur();

    };

    elem.onkeydown = (evt) => {

      let elem = evt.target! as HTMLTextAreaElement;

      setTimeout(function () {

        elem.style.height = 'auto';
        elem.style.height = '' + elem.scrollHeight + 'px';

      }, 0);

    };

    elem.onblur = (evt) => {

      let target = (evt.target || evt.srcElement) as HTMLTextAreaElement;

      if (target) {

        let id = target.id;
        let val = elem.value;
        let newVal = commitFunc(id, val);

        if (newVal !== val)
          target.value = newVal;

      }

    };

    document.body.appendChild(elem);
    return elem;

  }

  createNumberInputElement(className: string, commitFunc: (id: string, val: number) => number) {

    let rect = this.delegate ? this.delegate.domElement.getBoundingClientRect() as DOMRect : this.container.getBoundingClientRect() as DOMRect;

    let elem = document.createElement('input');
    elem.style.position = 'fixed';
    elem.style.zIndex = '9999';
    elem.style.top = Math.round(rect.y + rect.height / 2) + 'px';
    elem.style.left = Math.round(rect.x + rect.width / 2) + 'px';
    elem.style.width = '80px';
    elem.style.height = '32px';
    elem.style.display = 'none';
    elem.autocomplete = 'off';
    elem.className = className;

    elem.onkeypress = (evt) => {

      if (evt.key !== 'Enter') return;

      let target = (evt.target || evt.srcElement) as HTMLInputElement;

      if (target)
        target.blur();

    };

    elem.onblur = (evt) => {

      let target = (evt.target || evt.srcElement) as HTMLInputElement;

      if (target) {

        let id = target.id;
        let val = parseFloat(elem.value);
        let newVal = commitFunc(id, val);

        if (newVal !== val)
          target.value = '' + newVal;

      }

    };

    document.body.appendChild(elem);
    return elem;

  }

  previewComponent(component: string, previewData?: any) {

    this.previewGroup.remove(...this.previewMeshes);

    for (let previewMesh of this.previewMeshes)
      (previewMesh as Mesh).geometry.dispose();

    this.previewMeshes = [];

    if (previewData) {

      if (component === ComponentTypes.OffsetModifier) {

        if (this.offsetInputElement)
          Editor3d.showElement(this.offsetInputElement);

        if (previewData.ids && previewData.config) {

          if (this.offsetInputElement)
            this.offsetInputElement.value = previewData.config.distance.toFixed(2);

          for (let id of previewData.ids) {

            for (let i = 0; i < this.models[id].subs.length; ++i) {

              let mesh = this.models[id].subs[i].mesh as Mesh;

              if (mesh.isMesh) {

                let geometry = getThreeGeometryFromMeshData(
                  offsetGeometry(
                    getRenderedGeometry(getMeshFromThreeMesh(mesh)),
                    previewData.config.distance,
                    {
                      includeSource: previewData.config.cap,
                      includeTarget: true,
                      includeSide: previewData.config.cap
                    }
                  )
                );

                let material = this.sharedMaterials[SharedMaterial.Ghosted];
                let previewMesh = new Mesh(geometry, material);
                this.previewMeshes.push(previewMesh);

              }

            }

          }

        }

      } else if (component === ComponentTypes.ExtrudeModifier) {

        if (previewData.ids && previewData.config) {

          for (let id of previewData.ids) {

            for (let i = 0; i < this.models[id].subs.length; ++i) {

              let mesh = this.models[id].subs[i].mesh as Mesh;

              if (mesh.isMesh) {

                let geometry = getThreeGeometryFromMeshData(
                  extrudeGeometry(
                    getRenderedGeometry(getMeshFromThreeMesh(mesh)),
                    previewData.config.direction,
                    previewData.config.distance,
                    previewData.config.cap
                  )
                );
                let material = this.sharedMaterials[SharedMaterial.Ghosted];
                let previewMesh = new Mesh(geometry, material);
                this.previewMeshes.push(previewMesh);

                //@ts-ignore
              } else if (mesh.isLine) {

                let geometry = getThreeGeometryFromMeshData(
                  extrudeLine(
                    getRenderedGeometry(getMeshFromThreeMesh(mesh)),
                    previewData.config.direction,
                    previewData.config.distance,
                    previewData.config.thickness,
                    previewData.config.cap
                  )
                );
                let material = this.sharedMaterials[SharedMaterial.Ghosted];
                let previewMesh = new Mesh(geometry, material);
                this.previewMeshes.push(previewMesh);

              }

            }

          }

        }

      }

      this.previewGroup.add(...this.previewMeshes);
      this.refreshMaterials();

    } else {

      if (this.offsetInputElement)
        Editor3d.hideElement(this.offsetInputElement);

    }

    this.previewData = previewData;
    this.setNeedsUpdate();

  }

  hasDraggingPreview() {

    return this.draggingPreview;

  }

  setDraggingPreview(geometry: BufferGeometry) {

    if (this.draggingPreview)
      this.removeDraggingPreview();

    let mat = new MeshPhysicalMaterial({
      color: 0x808080,
      clearcoat: 0.0,
      clearcoatRoughness: 0.0,
      metalness: 0.0,
      roughness: 1.0,
      transparent: true,
      opacity: 0.5
    });

    mat.envMap = this.lightEnvMapTexture ? this.lightEnvMapTexture : this.defaultLightEnvMapTexture!;

    let mesh = new Mesh(geometry, mat);
    mesh.name = 'dragging-preview';
    mesh.geometry.computeBoundingBox();

    this.sceneMIM.add(mesh);
    this.draggingPreview = {mesh};

  }

  removeDraggingPreview() {

    if (this.draggingPreview) {

      this.sceneMIM.remove(this.draggingPreview.mesh);
      this.draggingPreview.mesh.geometry.dispose();
      (this.draggingPreview.mesh.material as Material).dispose();
      this.draggingPreview = undefined;

    }

  }

  showDraggingPreview(visible: boolean, offset?: vec3) {

    if (this.draggingPreview) {

      this.draggingPreview.mesh.visible = visible;

      if (offset) {
        let mouseX = (offset[0]) / this.width * 2 - 1;
        let mouseY = -(offset[1]) / this.height * 2 + 1;

        this.rayCaster.setFromCamera(new Vector2(mouseX, mouseY), this.getCamera());

        let intersectPlane = new Mesh(
          new PlaneBufferGeometry(300000, 300000),
          new MeshBasicMaterial({
            visible: false,
            side: this.side
          })
        );

        let orientVector = new Vector3(0, 0, 1).applyMatrix4(
          new Matrix4().makeRotationFromEuler(this.getCamera().rotation)
        );

        if (orientVector.z <= Math.sin(Math.PI / 18)) {

          let eye = this.getCamera().getWorldDirection(new Vector3());
          let baseVector = new Vector3(0, 0, 1);
          let alignVector = eye.clone().cross(baseVector);
          let dirVector = baseVector.clone().cross(alignVector);
          let tempMatrix = new Matrix4().lookAt(new Vector3(), dirVector, alignVector);
          intersectPlane.quaternion.setFromRotationMatrix(tempMatrix);
          intersectPlane.updateMatrixWorld();

        }

        let intersects = this.rayCaster.intersectObject(intersectPlane);

        if (intersects.length > 0) {

          let boundingBox = this.draggingPreview.mesh.geometry.boundingBox;
          this.draggingPreview.mesh.position.set(
            intersects[0].point.x,
            intersects[0].point.y,
            Math.max(boundingBox ? (boundingBox.max.z - boundingBox.min.z) / 2 : 0, 0)
          );

        }

      }

    }

    this.setNeedsUpdate();

  }

  getDraggingPreviewPosition() {

    if (this.draggingPreview)
      return getVec3FromThreeVector(this.draggingPreview.mesh.position);

    return vec3.create();

  }

  getSelectionBoundingBoxSize() {

    let size = new Vector3();
    this.selection.boundingBox.getSize(size);
    return getVec3FromThreeVector(size);

  }

  getSelectionBoundingBoxCenter() {

    let center = new Vector3();
    this.selection.boundingBox.getCenter(center);
    return getVec3FromThreeVector(center);

  }

  getMeshUnderMousePoint(mousePos: Vector2): IMeshUnderPoint | undefined {

    return this.getMeshUnderPoint(new Vector2(mousePos.x / this.width * 2 - 1, -mousePos.y / this.height * 2 + 1));

  }

  getMeshUnderPoint(pt: Vector2, except?: string[]): IMeshUnderPoint | undefined {

    let t0 = performance.now();
    this.rayCaster.setFromCamera(pt, this.getCamera());

    let scopeModels: IModel[] = [];

    this.scope.forEach(id => {

      if (this.models[id] && !(except && except.includes(id)))
        scopeModels.push(this.models[id]);

    });

    let intersects = this.rayCaster.intersectObjects(
      scopeModels.filter(m => m.meshGroup.visible).flatMap(m => m.subs).map(s => s.mesh)
    );

    if (intersects.length > 0) {

      let ret = {
        mesh: intersects[0].object as Mesh,
        faceIndex: intersects[0].faceIndex!,
        instanceId: intersects[0].instanceId!,
        id: '',
        index: 0,
        type: ''
      };

      for (let id of Object.keys(this.models)) {

        for (let j = 0; j < this.models[id].subs.length; ++j) {

          if (!this.models[id].locked && this.models[id].subs[j].mesh.id === ret.mesh.id) {

            ret.id = id;
            ret.index = j;
            ret.type = 'meshes';

          }

        }
      }

      if (ret.id.length > 0) {

        // console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took to detect mesh under mouse`);
        return ret;

      }

    }

    // console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took to detect mesh under mouse`);

  }

  getEditableObjectUnderPoint(pt: Vector2): { object: SpriteText2D | Mesh } | undefined {

    this.rayCaster.setFromCamera(pt, this.getCamera());
    let annotateIds = Object.keys(this.annotates).filter(i => this.annotates[+i].visible).map(Number);
    let measureIds = Object.keys(this.measures).filter(i => this.measures[+i].visible).map(Number);

    let intersects = this.rayCaster.intersectObjects([
      ...annotateIds.flatMap(i => this.annotations[i] ? [this.annotations[i].annotationText, this.annotations[i].startMarker] : []),
      ...measureIds.flatMap(i => this.measurements[i] ? [this.measurements[i].distanceText, this.measurements[i].startMarker, this.measurements[i].endMarker] : []),
      ...Object.values(this.placeholderBoxes).flatMap(p => [p.box, p.cancelSprite]).filter(o => o.visible)
    ]);

    if (intersects.length > 0)
      return {object: intersects[0].object as Mesh};

  }

  getMeshPoint(point: IPointOnMesh): Vector3 | undefined {

    if (this.models[point.calcId] === undefined)
      return;

    if (this.models[point.calcId].subs[point.meshIndex] === undefined)
      return;

    let mesh = this.models[point.calcId].subs[point.meshIndex].mesh;
    let vec = getThreeVectorFromVec3(point.position);

    vec.applyMatrix4(mesh.matrixWorld);
    return vec;

  }

  interpolation(calcId: string, meshIndex: number, faceIndex: number, point: Vector3): { t0: number, t1: number, t2: number } {

    let t0 = 0, t1 = 0, t2 = 0;

    if (this.models[calcId] === undefined)
      return {t0, t1, t2};

    if (this.models[calcId].subs[meshIndex] === undefined)
      return {t0, t1, t2};

    let mesh = this.models[calcId].subs[meshIndex].mesh;
    let geometry = (mesh.geometry as BufferGeometry);
    let positions = geometry.attributes.position;
    let f3 = faceIndex * 3;

    if ((geometry.index && (faceIndex + 1) * 3 <= geometry.index.count) || (!geometry.index && (faceIndex + 1) * 3 <= positions.count)) {

      let p0, p1, p2;

      if (geometry.index) {

        p0 = geometry.index.array[f3];
        p1 = geometry.index.array[f3 + 1];
        p2 = geometry.index.array[f3 + 2];

      } else {

        p0 = f3;
        p1 = f3 + 1;
        p2 = f3 + 2;

      }

      let pt0 = new Vector3(positions.getX(p0), positions.getY(p0), positions.getZ(p0));
      let pt1 = new Vector3(positions.getX(p1), positions.getY(p1), positions.getZ(p1));
      let pt2 = new Vector3(positions.getX(p2), positions.getY(p2), positions.getZ(p2));

      pt0.applyMatrix4(mesh.matrixWorld);
      pt1.applyMatrix4(mesh.matrixWorld);
      pt2.applyMatrix4(mesh.matrixWorld);

      let axisTrans = new Matrix4().makeBasis(pt0, pt1, pt2);

      if (axisTrans.determinant() === 0) {

        axisTrans.elements[2] += 1;
        axisTrans.elements[6] += 1;
        axisTrans.elements[10] += 1;
        point = point.clone().setZ(point.z + 1);

      }

      axisTrans.invert();

      ({x: t0, y: t1, z: t2} = point.applyMatrix4(axisTrans));

    }

    return {t0, t1, t2};

  }

  getDimText(dimension: number): string {

    if (this.measureUnit === MeasureUnit.Milli) {

      return dimension.toFixed(2) + ' mm';

    } else {

      return (dimension / 25.4).toFixed(2) + ' in';

    }

  }

  saveMeasure() {

    this.measureState = MeasureControlStates.SelectingFrom;

    if (this.editingMeasureId > 0) {

      this.actionCallback({action: ACT_COMMIT_MEASUREMENT, id: this.editingMeasureId});
      this.editingMeasureId = 0;

      this.refreshMeasurements();
    }

  }

  cancelMeasure() {

    this.measureState = MeasureControlStates.SelectingFrom;

    if (this.editingMeasureId > 0) {

      this.actionCallback({action: ACT_DELETE_MEASUREMENT, id: this.editingMeasureId});
      this.editingMeasureId = 0;

    }

  }

  saveAnnotate() {

    this.annotateState = AnnotateControlStates.SelectingTarget;

    if (this.editingAnnotateId > 0) {

      this.actionCallback({action: ACT_COMMIT_ANNOTATION, id: this.editingAnnotateId});
      this.editingAnnotateId = 0;

      this.refreshAnnotations();
    }

  }

  cancelAnnotate() {

    this.annotateState = AnnotateControlStates.SelectingTarget;

    if (this.editingAnnotateId > 0) {

      if (this.annotateInputElement) {
        Editor3d.hideElement(this.annotateInputElement);
      }

      this.actionCallback({action: ACT_DELETE_ANNOTATION, id: this.editingAnnotateId});
      this.editingAnnotateId = 0;

    }

  }

  commitPolyline() {

    this.actionCallback({action: ACT_COMMIT_POLYLINE});

    this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

  }

  commitCurve() {

    this.actionCallback({action: ACT_COMMIT_CURVE});

    this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

  }

  showMeasure(measureId: number, visible: boolean) {

    this.measures[measureId].visible = visible;
    this.refreshMeasurements();

  }

  showAnnotate(annotateId: number, visible: boolean) {

    this.annotates[annotateId].visible = visible;
    this.refreshAnnotations();

  }

  editMeasure(measureId: number, phase: MeasureControlStates) {

    if (this.editingMeasureId === measureId || this.measureState === MeasureControlStates.SelectingFrom) {

      this.editingMeasureId = measureId;
      this.measureState = phase;

    }

  }

  editAnnotate(annotateId: number, phase: AnnotateControlStates) {

    if (this.editingAnnotateId === annotateId || this.annotateState === AnnotateControlStates.SelectingTarget) {

      this.editingAnnotateId = annotateId;
      this.annotateState = phase;

      if (phase === AnnotateControlStates.InputtingText) {

        if (this.annotateInputElement) {

          let annotate = this.annotates[this.editingAnnotateId];
          this.annotateInputElement.value = annotate.text === this.defaultAnnotationText ? '' : annotate.text;
          Editor3d.showElement(this.annotateInputElement);
          setInterval(() => this.annotateInputElement && this.annotateInputElement.focus(), 100);
          this.refreshAnnotateInputElement();

        }

      }

    }

  }

  _getHeatmapData(bufferGeometry: WompMeshData, matrix: mat4) {

    let color = bufferGeometry.color;

    if (color) {
      let {uniform} = decomposeTransform(matrix);

      let colorArray = new Float32Array(color.length / 3);

      let tempBuf = new ArrayBuffer(4);
      let view = new DataView(tempBuf);

      for (let i = 0, l = color.length / 3; i < l; i++) {
        view.setUint8(0, 0);
        view.setUint8(1, Math.round(color[i * 3] * 255));
        view.setUint8(2, Math.round(color[i * 3 + 1] * 255));
        view.setUint8(3, Math.round(color[i * 3 + 2] * 255));

        colorArray[i] = view.getFloat32(0, true) * uniform;
      }

      return colorArray;
    }

  }

  async _preprocessGeometry(bufferGeometry: WompMeshData, type: RenderedObjectTypes, property: IModelProperty) {

    let processedBufferGeometry = {...bufferGeometry};

    if (type === RenderedObjectTypes.Brep) {

      // let processedBufferGeometry = await weldGeometryOnWorker(processedBufferGeometry, 22.5);

    } else {

      // processedBufferGeometry = await fixGeometryOnWorker(processedBufferGeometry);
      // const flatShading = property.weldAngle === 0 && type === RenderedObjectTypes.Mesh;
      //
      // if (flatShading) {
      //   processedBufferGeometry = await weldGeometryOnWorker(processedBufferGeometry, 0);
      // }

    }

    // delete processedBufferGeometry['uv'];

    let position = processedBufferGeometry.position;
    let normal = processedBufferGeometry.normal;

    if (position === undefined) {
      return;
    }

    if (normal === undefined || normal.filter(n => isNaN(n)).length > 0 || normal.filter(n => n !== 0).length === 0) {

      console.log('3d loader: removing incorrect normal attribute');
      delete processedBufferGeometry['normal'];
      computeGeometryVertexNormals(processedBufferGeometry);

    }

    return processedBufferGeometry;
  }

  async _replaceModel(id: string, title: string, component: string, objects: IRenderedFullObject[], heatmap: boolean, getRenderMaterial: (id: string, type: RenderedObjectTypes, property: IModelProperty) => IRenderMaterial, noRefresh: boolean = false) {

    if (!this.models[id])
      this._createModelStub(id, title, component);

    let skipped = 0;
    let model = this.models[id];

    for (let i = 0; i < objects.length; ++i) {

      const obj = objects[i];
      const index = i - skipped;
      const geometryHash = getIdPart(obj.property.hash);
      const matrixHash = getNonIdPart(obj.property.hash);
      const renderMat = getRenderMaterial(id, obj.type, obj.property);
      const materialHash = renderMat.pbrHash; //(obj.property);
      const luxHash = renderMat.luxHash || '';

      if (!model.subs[index])
        this._createSubModelStub(id, index);

      const sub = model.subs[index];

      if (sub.geometryHash !== geometryHash) {

        if (obj.type === RenderedObjectTypes.Mesh || obj.type === RenderedObjectTypes.Brep) {

          //@ts-ignore
          if (!sub.mesh.isMesh) {
            model.meshGroup.remove(sub.mesh);

            sub.mesh = new Mesh();

            sub.mesh.userData.id = id;
            sub.mesh.userData.index = index;
            sub.mesh.userData.locked = model.locked;

            sub.mesh.castShadow = true;
            sub.mesh.receiveShadow = true;
            sub.mesh.matrixAutoUpdate = false;

            model.meshGroup.add(sub.mesh);
          }

        } else if (obj.type === RenderedObjectTypes.Line) {

          //@ts-ignore
          if (!sub.mesh.isLineSegments) {
            model.meshGroup.remove(sub.mesh);

            sub.mesh = new LineSegments();

            sub.mesh.userData.id = id;
            sub.mesh.userData.index = index;
            sub.mesh.userData.locked = model.locked;

            sub.mesh.matrixAutoUpdate = false;

            model.meshGroup.add(sub.mesh);
          }

        } else { // (obj.type === RenderedObjectTypes.Vertex)

          //@ts-ignore
          if (!sub.mesh.isPoints) {
            model.meshGroup.remove(sub.mesh);

            sub.mesh = new Points();

            sub.mesh.userData.id = id;
            sub.mesh.userData.index = index;
            sub.mesh.userData.locked = model.locked;

            sub.mesh.matrixAutoUpdate = false;

            model.meshGroup.add(sub.mesh);
          }

        }

        let meshObj = obj.mesh;
        let meshData: WompMeshData = meshObj.geometry;

        if (!this.geometryMap[geometryHash]) {

          let geometry, edgeGeometry = new BufferGeometry();

          if (obj.type === RenderedObjectTypes.Mesh || obj.type === RenderedObjectTypes.Brep) {

            let preProcessed = await this._preprocessGeometry(meshData, obj.type, obj.property);

            if (!preProcessed) {
              skipped++;
              continue;
            }

            geometry = getThreeGeometryFromMeshData(preProcessed);

            if (obj.edge) {

              let edgeObj = obj.edge;
              let edgeData: WompMeshData = edgeObj.geometry;

              edgeGeometry = getLineSegmentsGeometryFromMeshData(edgeData);

            }

          } else if (obj.type === RenderedObjectTypes.Line) {

            geometry = getLineSegmentsGeometryFromMeshData(meshData);

          } else { // (obj.type === RenderedObjectTypes.Vertex)

            geometry = new BufferGeometry();
            geometry.attributes.position = new Float32BufferAttribute(meshObj, 3);

          }

          geometry.userData.hash = geometryHash;
          edgeGeometry.userData.hash = geometryHash + '.edge';

          this.geometryMap[geometryHash] = {
            geometry: geometry,
            edgeGeometry: edgeGeometry,
            weldAngle: obj.property.weldAngle,
            orgWeldAngle: obj.property.weldAngle,
            usedIn: new Set()
          };

        }

        this.removeFromGeometryMap(id, i);
        if (!this.geometryMap[geometryHash]) {
          console.error(this.geometryMap, geometryHash);
        }

        this.geometryMap[geometryHash].usedIn.add(`${id}:${i}`);

        sub.mesh.geometry = this.geometryMap[geometryHash].geometry;
        sub.edge.geometry = this.geometryMap[geometryHash].edgeGeometry;

        if (obj.type === RenderedObjectTypes.Mesh || obj.type === RenderedObjectTypes.Brep) {

          let heatmapData = undefined;

          if (heatmap)
            heatmapData = this._getHeatmapData(meshData, meshObj.matrix);

          this._changeHeatmapData(id, index, heatmapData, noRefresh);

        }

        sub.valid = obj.property.valid;
        sub.geometryHash = geometryHash;
        sub.renderedObjectType = obj.type;
        this.sceneMIM.needsUpdate = true;

        if (noRefresh) {

          this.refreshVisibilitiesLater = true;
          this.refreshMaterialsLater = true;
          this.refreshAnnotationsLater = true;
          this.refreshMeasurementsLater = true;

        } else {

          this.refreshVisibilities();
          this.refreshMaterials();
          this.refreshAnnotations();
          this.refreshMeasurements();

        }

        if (this.pBoxes[id]) {

          if (noRefresh)
            this.refreshPlaceholderBoxesLater = true;
          else
            this.refreshPlaceholderBoxes();

        }
        // console.log(`${id}[${index}][${obj.type}] replaced`);

      }

      if (sub.matrixHash !== matrixHash || !matrixHash) {

        let meshObj = obj.mesh;
        let matrix = new Matrix4();

        if (meshObj.matrix)
          matrix = getThreeTransformFromMat4(meshObj.matrix);

        sub.mesh.matrix = matrix;
        sub.mesh.userData.hash = matrixHash;

        sub.edge.matrix = matrix.clone();
        sub.edge.userData.hash = matrixHash;

        sub.mesh.updateWorldMatrix(false, false);
        sub.edge.updateWorldMatrix(false, false);

        sub.matrixHash = matrixHash;
        this.sceneMIM.needsUpdate = true;

        if (noRefresh) {

          this.refreshAnnotationsLater = true;
          this.refreshMeasurementsLater = true;

        } else {

          this.refreshAnnotations();
          this.refreshMeasurements();

        }

        // console.log(`${id}[${index}] matrix updated`);

      }

      if (sub.materialHash !== materialHash) {

        if (!this.materialMap[materialHash]) {

          let material = this.createMaterial(renderMat);

          material.userData.hash = materialHash;

          this.materialMap[materialHash] = {
            material,
            usedIn: new Set()
          };

        }

        this.removeFromMaterialMap(id, i);
        this.materialMap[materialHash].usedIn.add(`${id}:${i}`);

        sub.material = this.materialMap[materialHash].material as MeshPhysicalMaterial;

        sub.materialHash = materialHash;

        sub.materialId = obj.property.material.id;

        this.sceneMIM.needsUpdate = true;

        if (renderMat.minThickness && sub.minThickness !== renderMat.minThickness) {
          this.models[id].subs[index].minThickness = renderMat.minThickness;
          this.generateHeatmap(id, index);
        }

        if (noRefresh) {

          this.refreshMaterialsLater = true;

        } else {

          this.refreshMaterials();

        }

      }

      if (sub.luxHash !== luxHash) {

        sub.luxHash = luxHash;

      }

    }

    if (model && model.subs.length > objects.length - skipped) {

      for (let i = model.subs.length - 1; i >= objects.length - skipped; i--)
        this._removeModelLastMesh(id);

    }

    this.refreshImportIconVisibility();

  }

  _hasUncommitted(newTool: string, newIds: string[]) {

    if (this.selection.ids.length === 0)
      return false;

    if ([Tools.Gumball, Tools.Orient, Tools.Magnet, Tools.Snap].includes(this.selection.tool as Tools) && this.selection.group.parent === this.scene)
      return true;

    if (this.selection.tool === Tools.Align && lod.some(Object.values(this.selection.groups), g => g.parent === this.scene))
      return true;

    if (this.selection.tool === Tools.Mirror && this.selection.group.parent === this.scene)
      return newTool !== Tools.Mirror || !lod.isEqual(this.selection.ids, newIds);

    if (this.selection.tool === Tools.Array && this.selection.group.parent === this.scene)
      return newTool !== Tools.Array || !lod.isEqual(this.selection.ids, newIds);

    if (this.selection.tool === Tools.Sculpt && lod.some(this.selection.meshes, m => m.parent === this.scene))
      return newTool !== Tools.Sculpt || !lod.isEqual(this.selection.ids, newIds);

    return false;

  }

  _moveModelToScene(id: string) {

    let model = this.models[id];

    if (model) {

      if (model.meshGroup.visible)
        this.sceneMIM.add(model.meshGroup);

      if (model.edgeGroup.visible)
        this.sceneMIM.add(model.edgeGroup);

    }

  }

  _startTransformation() {

    let ids = this.selection.ids;

    if (ids.length > 0) {

      let boundingBox = new Box3();
      let globalBoundingBox = new Box3();
      let center = new Vector3();

      this.selection.space = ids.length === 1 && this.models[ids[0]] && !this.models[ids[0]].global ? 'local' : 'world';

      for (let id of ids) {

        let model = this.models[id];

        if (model) {

          let mi = model.metaInfo;

          boundingBox.union(getBoundingBoxFromModelMetaInfo(this.selection.space === 'world', mi));
          globalBoundingBox.union(getBoundingBoxFromModelMetaInfo(true, mi));

        }

      }

      boundingBox.getCenter(center);
      this.selection.boundingBox = boundingBox;
      this.selection.globalBoundingBox = globalBoundingBox;

      if ([Tools.Gumball, Tools.Orient, Tools.Snap, Tools.Magnet, Tools.Mirror].includes(this.selection.tool as Tools)) {

        this.sceneMIM.remove(this.selection.group);

        this.selection.group.matrix.identity().decompose(
          this.selection.group.position,
          this.selection.group.quaternion,
          this.selection.group.scale
        );

        for (let id of ids) {

          let model = this.models[id];

          if (model) {

            let mi = model.metaInfo;
            this.selection.meshGroups[id] = model.meshGroup.clone(true) as Group;
            this.selection.edgeGroups[id] = model.edgeGroup.clone(true) as Group;

            let transform = new Matrix4();
            if (ids.length === 1 && !model.global) {

              transform.makeTranslation(-mi.translate[0] - mi.orgCenter[0], -mi.translate[1] - mi.orgCenter[1], -mi.translate[2] - mi.orgCenter[2])
                .premultiply(new Matrix4().makeRotationFromEuler(new Euler(-mi.rotate[0], -mi.rotate[1], -mi.rotate[2], 'ZXY')))
                .premultiply(new Matrix4().makeTranslation(mi.translate[0] + mi.orgCenter[0], mi.translate[1] + mi.orgCenter[1], mi.translate[2] + mi.orgCenter[2]));

            }

            if (this.selection.space !== 'world') {

              this.selection.meshGroups[id].applyMatrix4(transform);
              this.selection.edgeGroups[id].applyMatrix4(transform);

            }

            this.sceneMIM.remove(model.meshGroup, model.edgeGroup);
            this.selection.groupMIM.add(this.selection.meshGroups[id], this.selection.edgeGroups[id]);

          }

        }

        let rotation = new Matrix4();

        for (let id of ids) {

          let model = this.models[id];

          if (model) {

            let mi = model.metaInfo;

            if (ids.length === 1 && !model.global) {

              rotation.makeRotationFromEuler(new Euler(mi.rotate[0], mi.rotate[1], mi.rotate[2], 'YXZ'));

            }

            let translation = new Matrix4().makeTranslation(-center.x, -center.y, -center.z);

            this.selection.meshGroups[id].applyMatrix4(translation);
            this.selection.edgeGroups[id].applyMatrix4(translation);

          }

        }

        if (this.selection.space === 'world') {

          this.selection.group.applyMatrix4(new Matrix4().makeTranslation(center.x, center.y, center.z));

        } else {

          this.selection.group.applyMatrix4(new Matrix4().makeTranslation(center.x, center.y, center.z).multiply(rotation));

        }

        this.selection.group.updateWorldMatrix(false, true);
        this.selection.orgGroupMatrix = this.selection.group.matrix.clone();

        this.sceneMIM.add(this.selection.group);
        this.sceneMIM.needsUpdate = true;

        this.selection.groupMIM.validate();

        if (this.selection.tool === Tools.Gumball) {

          let idSet = new Set(ids);
          let scopeIds = Array.from(this.scope);

          let environmentBoundingBoxes: { [key: string]: ISnapBoundingBox } = {};
          let selectionBoundingBoxes: { [key: string]: ISnapBoundingBox } = {};

          for (let id of scopeIds) {

            let model = this.models[id];

            if (model) {

              let mi = model.metaInfo;
              let bBox = {
                local: getBoundingBoxFromModelMetaInfo(false, mi),
                localTransform: new Matrix4().makeRotationFromEuler(new Euler(mi.rotate[0], mi.rotate[1], mi.rotate[2], 'YXZ')),
                global: getBoundingBoxFromModelMetaInfo(true, mi)
              };

              if (idSet.has(id)) {
                bBox.local.translate(center.clone().negate());
                bBox.global.translate(center.clone().negate());
                selectionBoundingBoxes[id] = bBox;
              } else {
                if (model.visible || this.selection.editLevels.length) {
                  environmentBoundingBoxes[id] = bBox;
                }
              }
            }

          }

          this.transformControl.space = this.selection.space;
          this.transformControl.boundingBox = boundingBox;
          this.transformControl.snap.setSelection(selectionBoundingBoxes);
          this.transformControl.snap.setEnvironment(environmentBoundingBoxes);
          this.transformControl.attach(this.selection.group);

        } else if (this.selection.tool === Tools.Snap) {

          let idSet = new Set(ids);
          let scopeIds = Array.from(this.scope);

          let targets: { [key: string]: ISnapTarget } = {};

          for (let id of scopeIds) {

            let model = this.models[id];

            if (model) {

              if (!idSet.has(id)) {

                targets[id] = {
                  box: getBoundingBoxFromModelMetaInfo(true, model.metaInfo),
                  obj: model.meshGroup
                };

              }
            }

          }

          this.snapControl.space = this.selection.space;
          this.snapControl.boundingBox = boundingBox;
          this.snapControl.attachTargets(targets);
          this.snapControl.attach(this.selection.group);

        } else if (this.selection.tool === Tools.Magnet) {

          let idSet = new Set(ids);
          let scopeIds = Array.from(this.scope);

          let targets: { [key: string]: IMagnetTarget } = {};

          for (let id of scopeIds) {

            let model = this.models[id];

            if (model) {

              targets[id] = {
                box: getBoundingBoxFromModelMetaInfo(true, model.metaInfo),
                obj: model.meshGroup,
                exclude: idSet.has(id)
              };
            }

          }

          this.magnetControl.space = this.selection.space;
          this.magnetControl.boundingBox = boundingBox;
          this.magnetControl.attachTargets(targets);
          this.magnetControl.attach(this.selection.group);

        } else if (this.selection.tool === Tools.Mirror) {

          this.mirrorControl.space = this.selection.space;
          this.mirrorControl.boundingBox = boundingBox;
          this.mirrorControl.attach(this.selection.group);

        } else if (this.selection.tool === Tools.Orient) {

        }

      } else if (this.selection.tool === Tools.Align) {

        let boundingBoxes = [];

        for (let id of ids)
          this.sceneMIM.remove(this.selection.groups[id]);

        for (let id in this.selection.groupMIMs)
          this.selection.groupMIMs[id].dispose();

        this.selection.groups = {};
        this.selection.groupMIMs = {};

        for (let id of ids) {

          let boundingBox = new Box3();

          let model = this.models[id];

          if (model) {

            this.selection.meshGroups[id] = model.meshGroup.clone(true) as Group;
            this.selection.edgeGroups[id] = model.edgeGroup.clone(true) as Group;

            boundingBox.union(getBoundingBoxFromModelMetaInfo(true, model.metaInfo));

            this.selection.groups[id] = new Group();
            this.selection.groups[id].name = `selection-group[${id}]`;
            this.selection.groups[id].matrixAutoUpdate = false;
            this.selection.groupMIMs[id] = new MeshInstancingManager(this.selection.groups[id]);

            this.sceneMIM.remove(model.meshGroup, model.edgeGroup);
            this.selection.groupMIMs[id].add(this.selection.meshGroups[id], this.selection.edgeGroups[id]);
            this.selection.groupMIMs[id].validate();

            this.sceneMIM.add(this.selection.groups[id]);

          }

          boundingBoxes.push(boundingBox);

        }

        this.alignControl.boundingBoxes = boundingBoxes;
        this.alignControl.attachObjects(Object.values(this.selection.groups));

      } else if (this.selection.tool === Tools.Array) {

        this.sceneMIM.remove(this.selection.group);
        this.selection.group.matrix.identity().decompose(
          this.selection.group.position,
          this.selection.group.quaternion,
          this.selection.group.scale
        );

        for (let id of ids) {

          let model = this.models[id];

          if (model) {

            this.selection.meshGroups[id] = model.meshGroup.clone(true) as Group;
            this.selection.edgeGroups[id] = model.edgeGroup.clone(true) as Group;

            this.sceneMIM.remove(model.meshGroup, model.edgeGroup);
            this.selection.groupMIM.add(this.selection.meshGroups[id], this.selection.edgeGroups[id]);

          }

        }

        this.selection.groupMIM.validate();

        this.sceneMIM.add(this.selection.group);
        this.arrayControl.boundingBox = globalBoundingBox;
        this.arrayControl.attach(this.selection.group);

      } else if (this.selection.tool === Tools.Sculpt) {

        this.scene.remove(...this.selection.meshes);
        this.scene.remove(...this.selection.wfMeshes);

        this.selection.sMeshes = [];
        this.selection.meshes = [];
        this.selection.wfMeshes = [];

        for (let i = 0; i < ids.length; ++i) {
          let id = ids[i];
          let geoms: BufferGeometry[] = [];
          let transform: Matrix4 = new Matrix4();

          let model = this.models[id];

          if (model) {

            for (let sub of model.subs)
              geoms.push(sub.mesh.geometry as BufferGeometry);

            this.sceneMIM.remove(model.meshGroup, model.edgeGroup);

            if (model.metaInfo.sculptTransform)
              transform = getThreeTransformFromMat4(model.metaInfo.sculptTransform);

          }

          if (geoms.length > 0) {

            let geom = BufferGeometryUtils.mergeBufferGeometries(geoms);
            let sMesh = getSculptMeshFromThreeMesh(geom, transform, undefined, true);

            if (sMesh) {

              let sculptGeometry = getThreeGeometryFromSculptMesh(sMesh);
              let sculptTransform = getThreeTransformFromSculptMesh(sMesh);

              if (sculptGeometry && sculptTransform) {

                let mesh = new Mesh(sculptGeometry, this.sharedMaterials[SharedMaterial.MatcapMasking]);
                mesh.name = `sculpt-mesh`;
                mesh.matrixAutoUpdate = false;
                mesh.matrix = sculptTransform;

                let wfMesh = new Mesh(sculptGeometry, new MeshPhysicalMaterial({
                  wireframe: true,
                  color: 0x070025
                }));
                wfMesh.name = `sculpt-wireframe-mesh`;
                wfMesh.matrixAutoUpdate = false;
                wfMesh.matrix = sculptTransform;
                wfMesh.renderOrder = 1;
                wfMesh.visible = false;

                this.selection.meshes.push(mesh);
                this.selection.wfMeshes.push(wfMesh);
                this.selection.sMeshes.push(sMesh);

              }

            }


            this.scene.add(...this.selection.meshes);
            this.scene.add(...this.selection.wfMeshes);

            this.sculptControl.attachSculpt(this.selection.sMeshes, this.selection.meshes, this.selection.wfMeshes);
            this.onSculptControlMaterialChange({type: 'change material', target: this.sculptControl});

          }

        }

      }

    }

    this.refreshTransformControl();

  }

  _cancelTransformation() {

    try {

      if ([Tools.Gumball, Tools.Orient, Tools.Snap, Tools.Magnet, Tools.Mirror].includes(this.selection.tool as Tools)) {

        if (this.selection.group.parent !== null) {

          // let transformMatrix = this.selection.group.matrix.clone();
          // this._moveModelToScene(id, transformMatrix);
          for (let id of this.selection.ids)
            this._moveModelToScene(id);

          this.selection.meshGroups = {};
          this.selection.edgeGroups = {};
          this.selection.groupMIM.clear();
          this.sceneMIM.remove(this.selection.group);

        }

        if (this.selection.tool === Tools.Gumball)
          this.transformControl.detach();
        else if (this.selection.tool === Tools.Snap)
          this.snapControl.detach();
        else if (this.selection.tool === Tools.Magnet)
          this.magnetControl.detach();
        else if (this.selection.tool === Tools.Mirror)
          this.mirrorControl.detach();

      } else if (this.selection.tool === Tools.Align) {

        for (let id of this.selection.ids) {

          if (this.selection.groups[id].parent !== null) {

            this._moveModelToScene(id);
            this.sceneMIM.remove(this.selection.groups[id]);

          }

        }

        this.selection.meshGroups = {};
        this.selection.edgeGroups = {};
        this.selection.groupMIM.clear();
        this.alignControl.detach();

      } else if (this.selection.tool === Tools.Array) {

        if (this.selection.group.parent !== null) {

          for (let id of this.selection.ids)
            this._moveModelToScene(id);

          this.selection.meshGroups = {};
          this.selection.edgeGroups = {};
          this.selection.groupMIM.clear();
          this.sceneMIM.remove(this.selection.group);
        }

        this.arrayControl.detach();

      } else if (this.selection.tool === Tools.Sculpt) {

        for (let mesh of this.selection.meshes) {

          if ((mesh.geometry as BufferGeometry).attributes.position) {

            for (let id of this.selection.ids)
              this._moveModelToScene(id);

          }

        }

        this.scene.remove(...this.selection.meshes);
        this.scene.remove(...this.selection.wfMeshes);

        this.selection.sMeshes = [];
        this.selection.meshes = [];
        this.selection.wfMeshes = [];

        this.sculptControl.detach();

      }

    } catch (error) {

      console.log(error);

    }

  }


  _syncSculptStrokes(objIds: string[], objDescs: string[], strokes: ISculptStroke[]) {

    if (this.selection.tool !== Tools.Sculpt || this.selection.ids.length === 0)
      return;

    let success = this.sculptControl.syncSculptStrokes(objIds, objDescs, strokes);
    if (!success) {
      console.warn('synchronization within state failed');

      this._setSelection(this.selection.tool, this.selection.ids, objIds, objDescs);
      this.sculptControl.syncSculptStrokes(objIds, objDescs, strokes);
    }

  }

  _commitSculpt(replace?: boolean) {

    if (this.selection.tool === Tools.Sculpt) {

      this.selection.sMeshes = this.sculptControl.sMeshes;

      let stroke = this.sculptControl.endStroke();

      let action = {
        action: ACT_SCULPT_STROKE,
        ids: [...this.selection.ids],
        stroke,
        sMeshes: this.sculptControl.sMeshes,
        replace
      };

      // if ((this.selection.meshes.geometry as BufferGeometry).attributes.position) {
      this.actionCallback(action);
      console.log('commit sculpt');
      // }

    }

  }

  _finishTransformation() {

    try {

      if ([Tools.Gumball, Tools.Orient, Tools.Snap].includes(this.selection.tool as Tools)) {

        let action = {
          action: ACT_COMMIT_TRANSFORM,
          ids: new Array<string>(),
          transforms: new Array<Matrix4>(),
          additionalInfos: new Array<any>()
        };

        let center = new Vector3();
        this.selection.boundingBox.getCenter(center);

        if (this.selection.group.parent !== null) {

          let transformMatrix = this.selection.group.matrix.clone();

          if (this.selection.space !== 'world') {

            let mi = this.models[this.selection.ids[0]].metaInfo;
            transformMatrix.multiply(new Matrix4().makeRotationFromEuler(new Euler(-mi.rotate[0], -mi.rotate[1], -mi.rotate[2], 'ZXY')));

          }

          transformMatrix.multiply(new Matrix4().makeTranslation(-center.x, -center.y, -center.z));

          for (let id of this.selection.ids) {

            if (this.models[id]) {

              let mi = this.models[id].metaInfo;

              action.ids.push(id);
              action.transforms.push(Editor3d.decomposeGroupTransformMatrix(mi, transformMatrix));

              if (mi.holder)
                action.additionalInfos.push({orgCenter: mi.orgCenter});

            }

          }

        }

        this._cancelTransformation();

        this.actionCallback(action);
        console.log('finish gumball');

      } else if (this.selection.tool === Tools.Magnet) {

        let action = {
          action: ACT_COMMIT_MAGNET,
          ids: this.selection.ids,
          destId: this.magnetControl.targetId || ''
        };

        this._cancelTransformation();

        this.actionCallback(action);
        console.log('finish magnet');

      } else if (this.selection.tool === Tools.Align) {

        let action = {
          action: ACT_COMMIT_TRANSFORM,
          ids: new Array<string>(),
          transforms: new Array<Matrix4>()
        };

        for (let id of this.selection.ids) {

          if (this.selection.groups[id].parent !== null) {

            let transformMatrix = this.selection.groups[id].matrix.clone();

            if (this.models[id]) {

              let transform = Editor3d.decomposeGroupTransformMatrix(this.models[id].metaInfo, transformMatrix);

              action.ids.push(id);
              action.transforms.push(transform);

            }

          }

        }

        this._cancelTransformation();

        this.actionCallback(action);
        console.log('finish align');

      } else if (this.selection.tool === Tools.Mirror) {

        let size = new Vector3();
        this.selection.globalBoundingBox.getSize(size);

        let action = {
          action: ACT_COMMIT_MIRROR,
          ids: new Array<string>(),
          transform: getMat4FromThreeTransform(this.mirrorControl.getCurrentTransform())
        };

        if (this.selection.group.parent !== null)
          action.ids.push(...this.selection.ids);

        this._cancelTransformation();

        this.actionCallback(action);
        console.log('finish mirror');

      } else if (this.selection.tool === Tools.Array) {

        let size = new Vector3();
        this.selection.globalBoundingBox.getSize(size);

        let action = {
          action: ACT_COMMIT_ARRAY,
          ids: new Array<string>(),
          offset: this.arrayControl.gapSize.clone().add(size).toArray(),
          itemOffset: this.arrayControl.itemOffset.clone().toArray(),
          arraySize: this.arrayControl.arraySize.clone().toArray()
        };

        if (this.selection.group.parent !== null)
          action.ids.push(...this.selection.ids);

        this._cancelTransformation();

        this.actionCallback(action);
        console.log('finish array');

      } else if (this.selection.tool === Tools.Sculpt) {

        this._cancelTransformation();
        console.log('finish sculpt');

      }

    } catch (error) {

      console.log(error);

    }

  }

  _setHeatmapVisible(id: string, heatmap: boolean, noRefresh: boolean = false) {

    if (this.models[id]) {

      if (this.models[id].heatmap !== heatmap) {

        this.models[id].heatmap = heatmap;

        if (noRefresh) {

          this.refreshMaterialsLater = true;
          this.refreshTransformControlLater = true;

        } else {

          this.refreshMaterials();
          this.refreshTransformControl();

        }

      }

    }

  }

  _lateRefreshVisibilities() {

    if (this.refreshVisibilitiesLater)
      this.refreshVisibilities();

    this.refreshVisibilitiesLater = false;

  }

  _lateRefreshMaterials(tool: string, ids: string[]) {

    if (this.selection.tool !== tool && (this.selection.tool === Tools.Sculpt || tool === Tools.Sculpt))
      this.refreshMaterialsLater = true;

    if (this.refreshMaterialsLater)
      this.refreshMaterials(tool, ids);

    this.refreshMaterialsLater = false;

  }

  _lateRefresh() {

    if (this.refreshTransformControlLater)
      this.refreshTransformControl();

    if (this.refreshAnnotationsLater)
      this.refreshAnnotations();

    if (this.refreshMeasurementsLater)
      this.refreshMeasurements();

    if (this.refreshPlaceholderBoxesLater)
      this.refreshPlaceholderBoxes();

    if (this.refreshFloorLater)
      this.refreshFloor();

    if (this.refreshBoundingBoxesLater)
      this.refreshBoundingBoxes();

    this.refreshBoundingBoxVisibilities();

    this.refreshTransformControlLater = false;
    this.refreshAnnotationsLater = false;
    this.refreshMeasurementsLater = false;
    this.refreshPlaceholderBoxesLater = false;
    this.refreshFloorLater = false;
    this.refreshBoundingBoxesLater = false;

  }

  refreshLineMaterialResolution() {

    for (let scene of [this.scene, this.controlScene]) {

      scene.traverse((object: Object3D) => {
        // @ts-ignore
        if (object.isLine2 && object.material) {
          ((object as Line2).material as LineMaterial).resolution.set(this.width, this.height);
        }
      });

    }

    if (this.cubeScene) {

      this.cubeScene.traverse((object: Object3D) => {
        // @ts-ignore
        if (object.isLine2 && object.material) {
          ((object as Line2).material as LineMaterial).resolution.set(this.cubeSize, this.cubeSize);
        }
      });

    }

  }

  refreshTransformControl() {

    let transformControlControlEnabled = true;

    if (this.selection.tool === Tools.Gumball) {

      for (let id of this.selection.ids) {

        if (this.models[id] && (this.models[id].metaInfo.fake || this.models[id].locked))
          transformControlControlEnabled = false;

      }

    }

    this.transformControl.objectDraggingEnabled = this.selection.tool !== Tools.Orient;
    this.transformControl.controlEnabled = transformControlControlEnabled;
    this.transformControl.enabled = this.editControlsEnabled;
    this.transformControl.measureUnitInch = this.measureUnit === MeasureUnit.Inch;

    let snapIncrement = this.snapIncrement;
    if (this.measureUnit === MeasureUnit.Inch)
      snapIncrement *= 25.4;

    this.transformControl.snapIncrement = snapIncrement;

  }

  _setModelGlobal(id: string, global: boolean) {

    if (this.models[id]) {

      if (this.models[id].global !== global)
        this.models[id].global = global;

    }

  }

  _setFloor(options: IFloor, noRefresh?: boolean) {

    let refreshFloor = false;
    let refreshImportIcon = false;

    if (this.gridHeight !== options.length) {

      this.gridHeight = options.length;
      refreshFloor = true;
      refreshImportIcon = true;

    }

    if (this.gridWidth !== options.width) {

      this.gridWidth = options.width;
      refreshFloor = true;
      refreshImportIcon = true;

    }

    if (this.gridOpacity !== options.opacity) {

      this.gridOpacity = options.opacity;
      refreshFloor = true;

    }

    if (this.gridSquare !== options.square) {

      this.gridSquare = options.square;
      refreshFloor = true;

    }

    if (this.floor.visible !== options.gridVisible) {

      this.floor.visible = options.gridVisible;
      refreshImportIcon = true;

    }

    if (refreshFloor) {
      if (noRefresh)
        this.refreshFloorLater = true;
      else
        this.refreshFloor();
    }

    if (refreshImportIcon)
      this.refreshImportIcon();

    if (this.basePlane.visible !== options.visible)
      this.basePlane.visible = options.visible;

    if (this.snapIncrement !== options.snapIncrement) {

      this.snapIncrement = options.snapIncrement;
      this.refreshTransformControl();

    }

    if (this.gridShowUnits !== options.showUnits) {

      this.gridShowUnits = options.showUnits;

      for (let unitText of this.gridUnitTexts) {

        unitText.visible = this.gridShowUnits;

      }

      this.resizeUnitTexts();
      this.setNeedsUpdate();

    }

  }

  _changeHeatmapData(id: string, index: number, data: Float32Array | undefined, noRefresh: boolean = false) {

    if (this.models[id]) {

      this.models[id].subs[index].heatmapData = data;

      this.generateHeatmap(id, index);
      if (noRefresh) {
        this.refreshMaterialsLater = true;
      } else {
        this.refreshMaterials();
      }

    }

  }

  _setPointSnapMode(pointSnap: boolean) {

    this.pointSnapEnabled = pointSnap;
    this.pointSnapText.visible = false;
    this.pointSnapMesh.visible = false;

  }

  _setMetaInfo(id: string, metaInfo: IModelMetaInfo, noRefresh?: boolean) {

    let model = this.models[id];

    if (model.metaInfo !== metaInfo) {
      model.metaInfo = metaInfo;

      if (noRefresh)
        this.refreshBoundingBoxesLater = true;
      else
        this.refreshBoundingBoxes();
    }

  }

  _setMeasurements(measures: { [key: number]: IMeasure }, noRefresh: boolean = false) {

    if (this.measures !== measures) {

      this.measures = lod.cloneDeep(measures);

      if (!this.measures[this.editingMeasureId])
        this.editingMeasureId = 0;

      if (noRefresh)
        this.refreshMeasurementsLater = true;
      else
        this.refreshMeasurements();

    }

  }

  _setAnnotations(annotates: { [key: number]: IAnnotate }, noRefresh: boolean = false) {

    if (this.annotates !== annotates) {

      this.annotates = lod.cloneDeep(annotates);

      if (!this.annotates[this.editingAnnotateId])
        this.editingAnnotateId = 0;

      if (noRefresh)
        this.refreshAnnotationsLater = true;
      else
        this.refreshAnnotations();

    }

  }

  _setMagnetMapping(mapping: { [key: string]: string }) {

    this.magnetControl.mapping = mapping;

  }

  _setPlaceholderBoxes(pBoxes: { [key: string]: IPlaceholderBox }, noRefresh: boolean = false) {

    if (!lod.isEqual(this.pBoxes, pBoxes)) {

      this.pBoxes = lod.cloneDeep(pBoxes);

      if (noRefresh)
        this.refreshPlaceholderBoxesLater = true;
      else
        this.refreshPlaceholderBoxes();

    }
  }

  _setLights(lights: { [key: number]: ILight }) {

    for (let lightId in lights) {

      let light = lights[lightId];

      if (this.lights[lightId] && this.lights[lightId].type !== light.type)
        this.removeLight(+lightId);

      if (!this.lights[lightId])
        this.addLight(light.type, light.id);

      this.updateLight(light);

    }

    for (let lightId of Object.keys(this.lights).map(Number)) {

      if (!lights[lightId])
        this.removeLight(lightId);

    }

  }

  _setPrevModelIds(id: string, prevModelIds: string[], noRefresh: boolean = false) {

    let refresh = false;

    if (this.models[id].prevIds !== prevModelIds) {

      this.models[id].prevIds = prevModelIds;
      refresh = true;

    }

    if (refresh) {

      if (noRefresh) {

        this.refreshVisibilitiesLater = true;
        this.refreshMaterialsLater = true;

      } else {

        this.refreshVisibilities();
        this.refreshMaterials();

      }

    }

  }

  _setEditLevels(editLevels: string[], noRefresh: boolean = false) {

    if (!lod.isEqual(this.selection.editLevels, editLevels)) {

      this.selection.editLevels = [...editLevels];

      if (noRefresh) {

        this.refreshVisibilitiesLater = true;
        this.refreshMaterialsLater = true;

      } else {

        this.refreshVisibilities();
        this.refreshMaterials();

      }

    }

  }

  _setSelection(tool: string, ids: string[], objIds: string[], objDescs: string[], toolConfig?: IToolConfig, noRefresh?: boolean) {

    if (this._hasUncommitted(tool, ids))
      this._cancelTransformation();

    if (toolConfig)
      this._setToolConfig(toolConfig, noRefresh);

    let oldTool: string = this.selection.tool;

    this.selection.ids = [...ids];
    this.selection.tool = tool;

    this._startTransformation();

    if (this.selection.tool === Tools.Sculpt) {
      this.sculptControl.objIds = objIds;
      this.sculptControl.objDescs = objDescs;
    }

    if (this.selection.tool === Tools.Measure && oldTool !== Tools.Measure) {

      if (noRefresh) {

        this.refreshMeasurementsLater = true;

      } else {

        this.refreshMeasurements();

      }

    }

    if (oldTool === Tools.Polyline && this.selection.tool !== Tools.Polyline) {

      this.initDrawingPolyline();

    }

    if (oldTool === Tools.Spline && this.selection.tool !== Tools.Spline) {

      this.initDrawingCurve();

    }

    if (oldTool === Tools.Measure && this.selection.tool !== Tools.Measure) {

      this.cancelMeasure();

    }

    if (oldTool === Tools.Annotate && this.selection.tool !== Tools.Annotate) {

      this.cancelAnnotate();

    }

    if (oldTool !== this.selection.tool && (oldTool === Tools.Sculpt || this.selection.tool === Tools.Sculpt)) {

      if (noRefresh)
        this.refreshTransformControlLater = true;
      else
        this.refreshTransformControl();

    }

    if (this.selection.tool === Tools.Sculpt) {

      this.orthoOrbitControl.sculptMode = true;
      this.perspOrbitControl.sculptMode = true;

    } else {

      this.orthoOrbitControl.sculptMode = true;
      this.perspOrbitControl.sculptMode = true;

    }

  }

  _setModelVisible(id: string, visible: boolean, noRefresh: boolean = false) {

    if (this.models[id]) {

      if (this.models[id].visible !== visible) {

        this.models[id].visible = visible;

        if (noRefresh) {

          this.refreshVisibilitiesLater = true;
          this.refreshMaterialsLater = true;

        } else {

          this.refreshVisibilities();
          this.refreshMaterials();

        }

      }

    }

  }

  _setModelLocked(id: string, locked: boolean) {

    let model = this.models[id];

    if (model) {

      if (model.locked !== locked) {

        model.locked = locked;

        model.meshGroup.userData.locked = locked;

      }

    }

  }

  _setToolConfig(config: IToolConfig, noRefresh?: boolean) {

    this._setMeasureColor(config.measure.color, noRefresh);
    this._setAnnotateColor(config.annotate.color, noRefresh);

    this.transformControl.snapToObjectsEnabled = config.gumball.snapToObjects;
    this.transformControl.snapToGridEnabled = config.gumball.snapToGrid;

    this.snapControl.gridEnabled = config.snap.grid;
    this.snapControl.faceEnabled = config.snap.face;
    this.snapControl.vertexEnabled = config.snap.vertex;
    this.snapControl.boxEnabled = config.snap.box;

    this.sculptControl.setConfig(config.sculpt);
    this.toolConfig = config;
  }


  _setMeasureUnit(measureUnit: MeasureUnit, noRefresh?: boolean) {

    let changed = false;

    if (measureUnit !== this.measureUnit) {

      this.measureUnit = measureUnit;
      changed = true;

    }

    if (changed) {

      if (noRefresh) {

        this.refreshMeasurementsLater = true;
        this.refreshTransformControlLater = true;
        this.refreshFloorLater = true;

      } else {

        this.refreshMeasurements();
        this.refreshTransformControl();
        this.refreshFloor();

      }

    }

  }

  _setMeasureColor(measureColor: string, noRefresh?: boolean) {

    if (this.measureColor !== measureColor) {

      this.measureColor = measureColor;

      this.measureLineMaterial.color = new Color(this.measureColor);
      this.measureLineMaterial.needsUpdate = true;

      this.measureMarkerMaterial.color = new Color(this.measureColor);
      this.measureMarkerMaterial.needsUpdate = true;

      if (noRefresh) {

        this.refreshMeasurementsLater = true;

      } else {

        this.refreshMeasurements();

      }

    }

  }

  _setAnnotateColor(annotateColor: string, noRefresh?: boolean) {

    if (this.annotateColor !== annotateColor) {

      this.annotateColor = annotateColor;

      this.annotateLineMaterial.color = new Color(this.annotateColor);
      this.annotateLineMaterial.needsUpdate = true;

      this.annotateMarkerMaterial.color = new Color(this.annotateColor);
      this.annotateMarkerMaterial.needsUpdate = true;

      if (noRefresh) {

        this.refreshAnnotationsLater = true;

      } else {

        this.refreshAnnotations();

      }

    }

  }

  _setLightingEnvironment(environment: IEnvironment) {

    if (EnvMapTypes.Lighting !== environment.type)
      return;

    if ('' === environment.image)
      return;

    if (!lod.isEqual(this.lightingEnvironment, environment)) {

      this.lightingEnvironment = environment;

      this.loadMultiResHDREnvMap(environment.hdrThumbnail, environment.image, false, (texture, equiRectTexture) => {

        if (this.lightingEnvironment !== environment)
          return;

        if (this.lightEnvMapTexture)
          this.lightEnvMapTexture.dispose();

        this.lightEnvMapTexture = equiRectTexture;
        this.envMapExposure = environment.exposure;

        this.replaceMaterialEnvMaps();

        this.envMapChanged = true;

      });

    }

  }

  _setBackgroundEnvironment(environment: IEnvironment) {

    let type = environment.type;

    if ((EnvMapTypes.Lighting === environment.type && '' === environment.image) ||
      (EnvMapTypes.Image === environment.type && '' === environment.image) ||
      (EnvMapTypes.Color === environment.type && '' === environment.color)) {

      this.backgroundEnvironment = environment;
      this.backgroundEnvMapType = EnvMapTypes.None;
      this.envMapChanged = true;
      return;

    }

    if (!lod.isEqual(this.backgroundEnvironment, environment)) {

      this.backgroundEnvironment = environment;

      if (this.envMapTexture) {

        if (this.backgroundEnvMapType === EnvMapTypes.Lighting || this.backgroundEnvMapType === EnvMapTypes.Image) {

          (this.envMapTexture as DataTexture).dispose();

        }

      }

      if (type === EnvMapTypes.Lighting) {

        this.loadMultiResHDREnvMap(environment.hdrThumbnail, environment.image, true, (texture, equiRectTexture) => {

          if (this.backgroundEnvironment !== environment)
            return;

          this.envMapTexture = equiRectTexture;
          this.backgroundEnvMapType = type;
          this.envMapChanged = true;

        });

      } else if (EnvMapTypes.Color === type) {

        this.envMapTexture = new Color(environment.color);
        this.backgroundEnvMapType = type;
        this.envMapChanged = true;

      } else if (EnvMapTypes.Image === type) {

        new TextureLoader().load(environment.image, (texture) => {

          this.envMapTexture = texture;
          this.backgroundEnvMapType = type;
          this.envMapChanged = true;

        });

      }

    }

  }

  _setCameraInfo(cameraInfo: ICameraInfo | null) {

    this.cameraInfo = cameraInfo;

  }

  _setCameraAngle(angle: number) {

    if (this.cameraAngle <= 10 && angle > 10) {

      this.transformControl.camera = this.perspCamera;
      this.snapControl.camera = this.perspCamera;
      this.magnetControl.camera = this.perspCamera;
      this.mirrorControl.camera = this.perspCamera;
      this.alignControl.setCamera(this.perspCamera);
      this.arrayControl.camera = this.perspCamera;
      this.sculptControl.camera = this.perspCamera;
      this.selectionBox.camera = this.perspCamera;

      for (let lightId in this.lights) {
        let light = this.lights[lightId];
        if (light.helper)
          light.helper.setCamera(this.perspCamera);
      }

    } else if (this.cameraAngle > 10 && angle <= 10) {

      this.transformControl.camera = this.orthoCamera;
      this.snapControl.camera = this.orthoCamera;
      this.magnetControl.camera = this.orthoCamera;
      this.mirrorControl.camera = this.orthoCamera;
      this.alignControl.setCamera(this.orthoCamera);
      this.arrayControl.camera = this.orthoCamera;
      this.sculptControl.camera = this.orthoCamera;
      this.selectionBox.camera = this.orthoCamera;

      for (let lightId in this.lights) {
        let light = this.lights[lightId];
        if (light.helper)
          light.helper.setCamera(this.orthoCamera);
      }

      this.setOrthoFromPersp();

    } else if (this.cameraAngle > 10 && angle > 10 && this.cameraAngle !== angle) {

      this.setOrthoFromPersp();

    }


    if (this.cameraAngle !== angle) {

      this.cameraAngle = angle;
      this.setPerspFromOrtho();

    }

  }

  getEditLevel(): string {

    if (this.selection.editLevels.length === 0)
      return '';

    return this.selection.editLevels[this.selection.editLevels.length - 1];

  }

  refreshDrawingPolyline() {

    let zoom = 1 / this.orthoCamera.zoom;
    let positions = new Array<number>(this.polyline.points.length * 3);

    for (let i = 0; i < this.polyline.points.length; ++i) {

      this.polyline.points[i].toArray(positions, i * 3);

    }

    let geometry = this.polyline.line.geometry as BufferGeometry;

    if (geometry.attributes.position.count !== this.polyline.points.length) {

      geometry.attributes.position = new Float32BufferAttribute(positions, 3);

    } else {

      for (let i = 0; i < this.polyline.points.length; ++i) {
        geometry.attributes.position.setXYZ(i, this.polyline.points[i].x, this.polyline.points[i].y, this.polyline.points[i].z);
      }

      (geometry.attributes.position as Float32BufferAttribute).needsUpdate = true;

    }

    let toAdd = this.polyline.points.length - this.polyline.pointSprites.length;
    for (let i = 0; i < toAdd; ++i) {

      let sprite = new Sprite(this.sharedMaterials[SharedMaterial.PointPicker] as SpriteMaterial);
      this.polyline.pointSprites.push(sprite);
      this.polyline.group.add(sprite);

    }

    for (let i = 0; i < this.polyline.points.length; ++i) {

      this.polyline.pointSprites[i].position.copy(this.polyline.points[i]);
      this.polyline.pointSprites[i].scale.set(zoom * 8, zoom * 8, zoom * 8);

    }

    this.setNeedsUpdate();

  }

  refreshDrawingCurve() {

    let zoom = 1 / this.orthoCamera.zoom;
    let geometry = this.curve.baseLine.geometry as BufferGeometry;

    if (geometry.attributes.position.count !== this.curve.points.length) {

      let points = new Array<number>(this.curve.points.length * 3);
      for (let i = 0; i < this.curve.points.length; ++i)
        this.curve.points[i].toArray(points, i * 3);

      geometry.attributes.position = new Float32BufferAttribute(points, 3);

    } else {

      for (let i = 0; i < this.curve.points.length; ++i) {

        geometry.attributes.position.setXYZ(i, this.curve.points[i].x, this.curve.points[i].y, this.curve.points[i].z);

      }

      (geometry.attributes.position as Float32BufferAttribute).needsUpdate = true;

    }

    this.curve.baseLine.computeLineDistances();

    let curveGeom = this.curve.line.geometry as BufferGeometry;

    let curve = getCurveFromArray(this.curve.points.map(p => p.toArray()), this.curve.periodic, false);

    if (curve)
      curveGeom.attributes.position = new Float32BufferAttribute(getCurvePointArray(curve), 3);

    let toAdd = this.curve.points.length - this.curve.pointSprites.length;

    for (let i = 0; i < toAdd; ++i) {

      let sprite = new Sprite(this.sharedMaterials[SharedMaterial.PointPicker] as SpriteMaterial);
      this.curve.pointSprites.push(sprite);
      this.curve.group.add(sprite);

    }

    for (let i = 0; i < this.curve.points.length; ++i) {

      this.curve.pointSprites[i].position.set(this.curve.points[i].x, this.curve.points[i].y, this.curve.points[i].z);
      this.curve.pointSprites[i].scale.set(zoom * 8, zoom * 8, zoom * 8);

    }

    this.setNeedsUpdate();

  }

  refreshFloor() {

    let visible = true;

    if (this.floorGroup) {

      visible = this.floor.visible;
      this.sceneMIM.remove(this.floorGroup);
      this.floorGroup.remove(...this.floorGroup.children);
      this.floor.geometry.dispose();

    }

    for (let unitText of this.gridUnitTexts) {

      this.sceneMIM.remove(unitText);

    }

    if (this.gridEnabled) {

      this.floor = new GridHelper(
        this.gridHeight,
        this.gridWidth,
        this.measureUnit === MeasureUnit.Milli ? this.gridSquare : this.gridSquare * 25.4 / 8,
        this.measureUnit === MeasureUnit.Milli ? this.gridSquare : this.gridSquare * 25.4 / 8,
        this.measureUnit === MeasureUnit.Milli ? 10 : 8,
        0x404040,
        0x808080,
        0xffffff,
        this.gridOpacity / 100.0
      );
      this.floor.position.set(0, 0, 0);
      this.floor.rotateX(-Math.PI / 2);
      this.floor.position.setZ(this.baseZ);
      this.floor.visible = visible;

      let width = '', height = '';

      if (this.measureUnit === MeasureUnit.Inch) {

        width = (this.gridWidth / 50.8).toFixed(2);
        height = (this.gridHeight / 50.8).toFixed(2);

      } else {

        width = (this.gridWidth / 2).toFixed(0);
        height = (this.gridHeight / 2).toFixed(0);

      }

      this.gridUnitTexts = [];

      this.gridUnitTexts.push(new SpriteText2D('0', {
        align: textAlign.center,
        font: '48px Arial',
        fillStyle: this.defaultColor,
        shadowColor: this.defaultColor,
        antialias: this.antialias
      }));

      this.gridUnitTexts.push(new SpriteText2D('0', {
        align: textAlign.center,
        font: '48px Arial',
        fillStyle: this.defaultColor,
        shadowColor: this.defaultColor,
        antialias: this.antialias
      }));

      this.gridUnitTexts.push(new SpriteText2D(width, {
        align: textAlign.center,
        font: '48px Arial',
        fillStyle: this.defaultColor,
        shadowColor: this.defaultColor,
        antialias: this.antialias
      }));

      this.gridUnitTexts.push(new SpriteText2D('-' + width, {
        align: textAlign.center,
        font: '48px Arial',
        fillStyle: this.defaultColor,
        shadowColor: this.defaultColor,
        antialias: this.antialias
      }));

      this.gridUnitTexts.push(new SpriteText2D(height, {
        align: textAlign.center,
        font: '48px Arial',
        fillStyle: this.defaultColor,
        shadowColor: this.defaultColor,
        antialias: this.antialias
      }));

      this.gridUnitTexts.push(new SpriteText2D('-' + height, {
        align: textAlign.center,
        font: '48px Arial',
        fillStyle: this.defaultColor,
        shadowColor: this.defaultColor,
        antialias: this.antialias
      }));

      this.gridUnitTexts.push(new SpriteText2D(width, {
        align: textAlign.center,
        font: '48px Arial',
        fillStyle: this.defaultColor,
        shadowColor: this.defaultColor,
        antialias: this.antialias
      }));

      this.gridUnitTexts.push(new SpriteText2D('-' + width, {
        align: textAlign.center,
        font: '48px Arial',
        fillStyle: this.defaultColor,
        shadowColor: this.defaultColor,
        antialias: this.antialias
      }));

      this.gridUnitTexts.push(new SpriteText2D(height, {
        align: textAlign.center,
        font: '48px Arial',
        fillStyle: this.defaultColor,
        shadowColor: this.defaultColor,
        antialias: this.antialias
      }));

      this.gridUnitTexts.push(new SpriteText2D('-' + height, {
        align: textAlign.center,
        font: '48px Arial',
        fillStyle: this.defaultColor,
        shadowColor: this.defaultColor,
        antialias: this.antialias
      }));

      this.gridUnitTexts[0].position.set(this.gridHeight / 2 + 20, 0, this.baseZ + 3);
      this.gridUnitTexts[1].position.set(0, this.gridWidth / 2 + 20, this.baseZ + 3);

      this.gridUnitTexts[2].position.set(this.gridHeight / 2 + 20, this.gridWidth / 2, this.baseZ + 3);
      this.gridUnitTexts[3].position.set(this.gridHeight / 2 + 20, -this.gridWidth / 2, this.baseZ + 3);
      this.gridUnitTexts[4].position.set(this.gridHeight / 2, this.gridWidth / 2 + 20, this.baseZ + 3);
      this.gridUnitTexts[5].position.set(-this.gridHeight / 2, this.gridWidth / 2 + 20, this.baseZ + 3);

      this.gridUnitTexts[6].position.set(-this.gridHeight / 2 - 20, this.gridWidth / 2, this.baseZ + 3);
      this.gridUnitTexts[7].position.set(-this.gridHeight / 2 - 20, -this.gridWidth / 2, this.baseZ + 3);
      this.gridUnitTexts[8].position.set(this.gridHeight / 2, -this.gridWidth / 2 - 20, this.baseZ + 3);
      this.gridUnitTexts[9].position.set(-this.gridHeight / 2, -this.gridWidth / 2 - 20, this.baseZ + 3);

      for (let unitText of this.gridUnitTexts) {

        unitText.visible = this.gridShowUnits;

      }

      this.floorGroup = new Group();
      this.floorGroup.name = `grid-helper`;
      this.floorGroup.add(...this.gridUnitTexts, this.floor);

      this.sceneMIM.add(this.floorGroup);
      this.resizeUnitTexts();

    }

    this.setNeedsUpdate();

  }

  toggleWireFrame(id: string) {

    if (this.models[id]) {

      for (let sub of this.models[id].subs) {

        //@ts-ignore
        if (sub.mesh.material.isMeshBasicMaterial || sub.mesh.material.isMeshPhysicalMaterial || sub.mesh.material.isMeshStandardMaterial) {

          let material = sub.mesh.material as MeshBasicMaterial;
          material.wireframe = !material.wireframe;
          material.depthTest = !material.depthTest;
          material.needsUpdate = true;

          this.setNeedsUpdate();

        }

      }

    }

  }

  applyCenter() {

    this.repositionCamera();

  }

  getModelBoundingBox(ids: string[]) {

    let maxX = -Infinity;
    let minX = +Infinity;
    let maxY = -Infinity;
    let minY = +Infinity;
    let maxZ = -Infinity;
    let minZ = +Infinity;

    for (let id of ids) {

      let model = this.models[id];

      if (model) {

        let mi = model.metaInfo;

        if (model.meshGroup.visible) {

          if (mi) {

            maxX = Math.max(mi.position[0] + mi.globalSize[0] / 2, maxX);
            minX = Math.min(mi.position[0] - mi.globalSize[0] / 2, minX);
            maxY = Math.max(mi.position[1] + mi.globalSize[1] / 2, maxY);
            minY = Math.min(mi.position[1] - mi.globalSize[1] / 2, minY);
            maxZ = Math.max(mi.position[2] + mi.globalSize[2] / 2, maxZ);
            minZ = Math.min(mi.position[2] - mi.globalSize[2] / 2, minZ);

          }

        }

      }

    }

    if (isNaN(maxX) || isNaN(minX) || isNaN(maxY) || isNaN(minY) || isNaN(maxZ) || isNaN(minZ) || maxX < minX || maxY < minY || maxZ < minZ) {

      maxX = maxY = maxZ = 100;
      minX = minY = minZ = -100;

    }

    return {minX, minY, minZ, maxX, maxY, maxZ};
  }

  getPlacehoderBoundingBox(ids: string[]) {

    let maxX = -Infinity;
    let minX = +Infinity;
    let maxY = -Infinity;
    let minY = +Infinity;
    let maxZ = -Infinity;
    let minZ = +Infinity;

    for (let id of ids) {

      let box = this.pBoxes[id];

      if (box.bBox) {

        maxX = Math.max(box.bBox[1][0], maxX);
        minX = Math.min(box.bBox[0][0], minX);
        maxY = Math.max(box.bBox[1][1], maxY);
        minY = Math.min(box.bBox[0][1], minY);
        maxZ = Math.max(box.bBox[1][2], maxZ);
        minZ = Math.min(box.bBox[0][2], minZ);

      }

    }

    if (maxX < minX || maxY < minY || maxZ < minZ) {

      maxX = maxY = maxZ = 100;
      minX = minY = minZ = -100;

    }

    return {minX, minY, minZ, maxX, maxY, maxZ};

  }

  applyMinorFit() {

    if (this.minorCamera && this.minorOrbitControl && this.minorWidth && this.minorHeight) {

      let {maxX, minX, maxY, minY, maxZ, minZ} = this.getModelBoundingBox(Array.from(this.scope));
      let avgLength = (maxX - minX) + (maxY - minY) + (maxZ - minZ);

      let offset = this.minorCamera.position.clone().addScaledVector(this.minorOrbitControl.target, -1);

      this.minorOrbitControl.target.set((maxX + minX) / 2, (maxY + minY) / 2, (maxZ + minZ) / 2);
      this.minorCamera.position.copy(this.minorOrbitControl.target).add(offset);
      this.minorCamera.zoom = Math.max(this.minorWidth, this.minorHeight) / avgLength;
      this.minorCamera.updateProjectionMatrix();
      this.minorOrbitControl.update();

    }

  }

  applyDrop() {

    if (this.selection.ids.length > 0) {

      this.selection.group.applyMatrix4(new Matrix4().makeTranslation(0, 0, -this.selection.globalBoundingBox.min.z));
      this._finishTransformation();

    }

  }

  applyDropOnObject() {

    if (this.selection.ids.length > 0) {

      let selectionIdSet = new Set(this.selection.ids);
      let dropDistance = +Infinity;

      for (let id in this.models) {

        let model = this.models[id];
        let modelVisible = this.isModelVisible(id);

        if (!selectionIdSet.has(id) && modelVisible) {

          let bBox = getBoundingBoxFromModelMetaInfo(true, model.metaInfo);
          let gBox = this.selection.globalBoundingBox;

          if (gBox.min.x < bBox.max.x && gBox.min.y < bBox.max.y && bBox.min.x < gBox.max.x && bBox.min.y < gBox.max.y && bBox.max.z < gBox.min.z && gBox.min.z - bBox.max.z < dropDistance) {

            dropDistance = gBox.min.z - bBox.max.z;

          }

        }

      }

      if (!isFinite(dropDistance)) {

        dropDistance = this.selection.globalBoundingBox.min.z;

      }


      this.selection.group.applyMatrix4(new Matrix4().makeTranslation(0, 0, -dropDistance));
      this._finishTransformation();

    }

  }

  applyCenterOnFloor() {

    if (this.selection.ids.length > 0) {

      let center = this.selection.globalBoundingBox.getCenter(new Vector3());
      this.selection.group.applyMatrix4(new Matrix4().makeTranslation(-center.x, -center.y, -this.selection.globalBoundingBox.min.z));
      this._finishTransformation();

    }

  }

  resizeUnitTexts() {

    // let zoom = this.controls.target.distanceTo(this.controls.object.position)
    let zoom = 1 / this.orthoCamera.zoom;

    for (let unitText of this.gridUnitTexts) {

      unitText.scale.set(zoom / 5.0, zoom / 5.0, 1);

    }

  }

  refreshImportIcon() {

    if (this.importModelIcon && this.importModelOutline) {

      let lineGeometry = this.importModelOutline.geometry as LineGeometry;

      lineGeometry.setPositions([
        -this.gridHeight / 2 + 20, 20, 0,
        -this.gridHeight / 2 + 20, this.gridWidth / 2 - 20, 0,
        -this.gridHeight / 2 + 20, this.gridWidth / 2 - 20, 0,
        this.gridHeight / 2 - 20, this.gridWidth / 2 - 20, 0,
        this.gridHeight / 2 - 20, this.gridWidth / 2 - 20, 0,
        this.gridHeight / 2 - 20, 20, 0,
        this.gridHeight / 2 - 20, 20, 0,
        -this.gridHeight / 2 + 20, 20, 0
      ]);

      this.importModelOutline.computeLineDistances();

      for (let mesh of this.importModelIcon.children) {

        mesh.position.set(0, this.gridWidth / 4, 0);

      }

    }

    this.refreshImportIconVisibility();
    this.setNeedsUpdate();

  }

  refreshImportIconVisibility() {

    if (this.importModelIcon && this.importModelOutline) {

      let visible = this.floor.visible && this.gridWidth >= 300 && this.gridHeight >= 300;
      visible = visible && Object.keys(this.models).length === 0;

      this.importModelIcon.visible = visible && this.gridEnabled;
      this.importModelOutline.visible = visible && this.gridEnabled;

    }

  }

  refreshBoundingBoxVisibilities() {

    let highlightedIdSet = new Set(this.highlightedModelIds);

    for (let id in this.models) {

      let model = this.models[id];
      let highlighted = highlightedIdSet.has(id);
      let invalid = model.subs.filter(s => !s.valid).length > 0;

      model.boundingBox.visible = highlighted || invalid;
      model.boundingBox.material = this.sharedMaterials[invalid ? SharedMaterial.InvalidBoxLine : SharedMaterial.BoxLine];

    }

  }

  refreshBoundingBoxes() {

    for (let id in this.models) {

      let model = this.models[id];
      let boundingBox = model.boundingBox;
      let bBox = getBoundingBoxFromModelMetaInfo(true, model.metaInfo);

      const min = bBox.min;
      const max = bBox.max;

      const position = (boundingBox.geometry as BufferGeometry).attributes.position;
      let array = position.array as number[];

      array[0] = max.x;
      array[1] = max.y;
      array[2] = max.z;
      array[3] = min.x;
      array[4] = max.y;
      array[5] = max.z;
      array[6] = min.x;
      array[7] = min.y;
      array[8] = max.z;
      array[9] = max.x;
      array[10] = min.y;
      array[11] = max.z;
      array[12] = max.x;
      array[13] = max.y;
      array[14] = min.z;
      array[15] = min.x;
      array[16] = max.y;
      array[17] = min.z;
      array[18] = min.x;
      array[19] = min.y;
      array[20] = min.z;
      array[21] = max.x;
      array[22] = min.y;
      array[23] = min.z;

      position.needsUpdate = true;

    }
  }

  refreshMeasurements() {

    for (let id in this.measurements) {

      if (this.measures[id] === undefined) {

        let measurement = this.measurements[id];

        measurement.group.remove(...measurement.group.children);
        measurement.startMarker.geometry.dispose();
        measurement.endMarker.geometry.dispose();
        measurement.betweenLine1.geometry.dispose();
        measurement.betweenLine2.geometry.dispose();
        measurement.startLine.geometry.dispose();
        measurement.endLine.geometry.dispose();
        measurement.offLine.geometry.dispose();
        measurement.distanceText.sprite.geometry.dispose();

        this.sceneMIM.remove(measurement.group);

        delete this.measurements[id];

      }

    }

    // let zoom = this.controls.target.distanceTo(this.controls.object.position)
    let zoom = 1 / this.orthoCamera.zoom;

    for (let id in this.measures) {

      let measure = this.measures[id];

      if (this.measurements[id] === undefined) {

        let group = new Group();
        group.name = `measurement[${id}]`;
        // group.userData.instantiatable = true;

        let markerGeometry = new SphereBufferGeometry(zoom / 0.3);
        let startMarker = new Mesh(markerGeometry, this.measureMarkerMaterial);
        startMarker.userData.measureId = id;
        startMarker.userData.start = true;
        startMarker.visible = false;
        group.add(startMarker);

        let endMarker = new Mesh(markerGeometry, this.measureMarkerMaterial);
        endMarker.userData.measureId = id;
        endMarker.visible = false;
        group.add(endMarker);

        let betweenLine1 = new Line(new BufferGeometry(), this.measureLineMaterial);
        betweenLine1.visible = false;
        group.add(betweenLine1);

        let betweenLine2 = new Line(new BufferGeometry(), this.measureLineMaterial);
        betweenLine2.visible = false;
        group.add(betweenLine2);

        let startLine = new Line(new BufferGeometry(), this.measureLineMaterial);
        startLine.visible = false;
        group.add(startLine);

        let offLine = new Line(new BufferGeometry(), this.measureLineMaterial);
        offLine.visible = false;
        group.add(offLine);

        let endLine = new Line(new BufferGeometry(), this.measureLineMaterial);
        endLine.visible = false;
        group.add(endLine);

        let distanceText = new SpriteText2D('', {
          align: textAlign.center,
          font: '48px Arial',
          fillStyle: this.measureColor,
          shadowColor: this.measureColor,
          antialias: this.antialias
        });
        distanceText.userData.measureId = id;
        distanceText.scale.set(zoom / 3.0, zoom / 3.0, 1);

        distanceText.visible = false;
        group.add(distanceText);

        this.sceneMIM.add(group);

        this.measurements[id] = {
          startMarker: startMarker,
          endMarker: endMarker,
          distanceText: distanceText,
          betweenLine1: betweenLine1,
          betweenLine2: betweenLine2,
          startLine: startLine,
          offLine: offLine,
          endLine: endLine,
          group: group,
          distance: 0,
          textWidth: 0,
          zoom: zoom,
          measureUnit: this.measureUnit,
          color: this.measureColor
        };

      }

      let measurement = this.measurements[id];

      if (measurement.color !== this.measureColor) {

        measurement.color = this.measureColor;

        measurement.group.remove(measurement.distanceText);
        measurement.distanceText.sprite.geometry.dispose();
        measurement.distanceText = new SpriteText2D('', {
          align: textAlign.center,
          font: '48px Arial',
          fillStyle: this.measureColor,
          shadowColor: this.measureColor,
          antialias: this.antialias
        });
        measurement.distanceText.userData.measureId = id;
        measurement.distanceText.scale.set(zoom / 3.0, zoom / 3.0, 1);

        measurement.distanceText.visible = false;
        measurement.group.add(measurement.distanceText);
        measurement = {
          ...measurement,
          distance: 0,
          textWidth: 0,
          zoom: zoom,
          measureUnit: this.measureUnit,
          color: this.measureColor
        };
      }

      if (Math.abs(measurement.zoom - zoom) >= EPS) {
        let markerGeometry = new SphereBufferGeometry(zoom / 0.3);
        measurement.startMarker.geometry.dispose();
        measurement.startMarker.geometry = markerGeometry;
        measurement.endMarker.geometry.dispose();
        measurement.endMarker.geometry = markerGeometry;
        measurement.distanceText.scale.set(zoom / 3.0, zoom / 3.0, 1);
      }

      let startPosition = this.getMeshPoint(measure.start);
      let endPosition = this.getMeshPoint(measure.end);

      if (startPosition && measure.visible) {

        measurement.startMarker.position.copy(startPosition);
        measurement.startMarker.visible = true;

        if (endPosition) {

          let nonAxis = 'xyz'.replace(measure.mainAxis, '').replace(measure.subAxis, '') as ('x' | 'y' | 'z');
          let startLineStartPosition = startPosition.clone();
          let startLineEndPosition = startPosition.clone();
          let endLineStartPosition = endPosition.clone();
          let endLineEndPosition = endPosition.clone();
          let offLineStartPosition = startPosition.clone();
          let offLineEndPosition = startPosition.clone();
          endLineEndPosition[measure.mainAxis] += measure.offDistance;
          startLineStartPosition[nonAxis] = endPosition[nonAxis];
          startLineEndPosition[nonAxis] = endPosition[nonAxis];
          startLineEndPosition[measure.mainAxis] += measure.offDistance + (endPosition[measure.mainAxis] - startPosition[measure.mainAxis]);

          offLineEndPosition[nonAxis] = endPosition[nonAxis];

          measurement.endMarker.position.copy(endPosition);

          let newDistance = startLineEndPosition.distanceTo(endLineEndPosition);

          if (measurement.distance !== newDistance ||
            measurement.measureUnit !== this.measureUnit ||
            Math.abs(measurement.zoom - zoom) >= EPS
          ) {

            measurement.distanceText.text = this.getDimText(newDistance);
            measurement.distanceText.updateText();

            measurement.measureUnit = this.measureUnit;
            measurement.distance = newDistance;
            measurement.textWidth = measurement.distanceText.width * measurement.distanceText.scale.x;

          }

          measurement.distanceText.position.copy(new Vector3().addVectors(startLineEndPosition, endLineEndPosition).multiplyScalar(0.5));

          let betweenLineBreakPosition1, betweenLineBreakPosition2;
          let textWidth = measurement.textWidth;

          if (newDistance < textWidth) {

            betweenLineBreakPosition1 = startLineEndPosition.clone();
            betweenLineBreakPosition2 = endLineEndPosition.clone();

          } else {

            betweenLineBreakPosition1 = startLineEndPosition.clone()
              .add(new Vector3()
                .subVectors(endLineEndPosition, startLineEndPosition)
                .normalize()
                .multiplyScalar((newDistance - textWidth * 1.2) / 2)
              );
            betweenLineBreakPosition2 = startLineEndPosition.clone()
              .add(new Vector3()
                .subVectors(endLineEndPosition, startLineEndPosition)
                .normalize()
                .multiplyScalar((newDistance + textWidth * 1.2) / 2)
              );

          }

          Editor3d.updateLineSegmentGeometry(measurement.betweenLine1.geometry as BufferGeometry, startLineEndPosition, betweenLineBreakPosition1);
          Editor3d.updateLineSegmentGeometry(measurement.betweenLine2.geometry as BufferGeometry, betweenLineBreakPosition2, endLineEndPosition);
          Editor3d.updateLineSegmentGeometry(measurement.startLine.geometry as BufferGeometry, startLineStartPosition, startLineEndPosition);
          Editor3d.updateLineSegmentGeometry(measurement.offLine.geometry as BufferGeometry, offLineStartPosition, offLineEndPosition);
          Editor3d.updateLineSegmentGeometry(measurement.endLine.geometry as BufferGeometry, endLineStartPosition, endLineEndPosition);

          measurement.endMarker.visible = true;
          measurement.betweenLine1.visible = true;
          measurement.betweenLine2.visible = true;
          measurement.startLine.visible = true;
          measurement.offLine.visible = true;
          measurement.endLine.visible = true;
          measurement.distanceText.visible = true;

          this.measures[id].distance = newDistance;

        } else {

          measurement.endMarker.visible = false;
          measurement.betweenLine1.visible = false;
          measurement.betweenLine2.visible = false;
          measurement.startLine.visible = false;
          measurement.offLine.visible = false;
          measurement.endLine.visible = false;
          measurement.distanceText.visible = false;

        }

      } else {

        measurement.startMarker.visible = false;
        measurement.endMarker.visible = false;
        measurement.betweenLine1.visible = false;
        measurement.betweenLine2.visible = false;
        measurement.startLine.visible = false;
        measurement.offLine.visible = false;
        measurement.endLine.visible = false;
        measurement.distanceText.visible = false;

      }

      if (Math.abs(measurement.zoom - zoom) >= EPS)
        measurement.zoom = zoom;

    }

    this.setNeedsUpdate();

  }

  refreshAnnotateInputElement() {

    let annotation = this.annotations[this.editingAnnotateId];

    if (this.annotateInputElement) {

      let width = annotation.annotationText.width * 0.5;
      let height = annotation.annotationText.height * 0.5;
      let pos = annotation.annotationText.position.clone().project(this.getCamera()).setZ(0);

      pos.x = (pos.x + 1) / 2 * this.width + this.elemRect.left;
      pos.y = (pos.y - 1) / -2 * this.height + this.elemRect.top;

      let top = pos.y - height / 2;
      let left = pos.x - width * (1 - annotation.annotationText.align.x) / 2;

      this.annotateInputElement.style.minHeight = '' + Math.round(height) + 'px';
      this.annotateInputElement.style.height = 'auto';
      this.annotateInputElement.style.width = '' + Math.round(width) + 'px';
      this.annotateInputElement.style.top = Math.round(top) + 'px';
      this.annotateInputElement.style.left = Math.round(left) + 'px';

    }

  }

  refreshAnnotations() {

    for (let id in this.annotations) {

      if (this.annotates[id] === undefined) {

        let annotation = this.annotations[id];

        annotation.group.remove(...annotation.group.children);

        annotation.startMarker.geometry.dispose();
        annotation.angleLine.geometry.dispose();
        annotation.horizLine.geometry.dispose();
        annotation.annotationText.sprite.geometry.dispose();

        this.sceneMIM.remove(annotation.group);

        delete this.annotations[id];

      }

    }

    let zoom = 1 / this.orthoCamera.zoom;
    // let zoom = this.controls.target.distanceTo(this.controls.object.position)

    for (let id in this.annotates) {

      let annotate = this.annotates[id];

      if (this.annotations[id] === undefined) {

        let group = new Group();
        group.name = `annotation[${id}]`;
        // group.userData.instantiatable = true;

        let markerGeometry = new SphereBufferGeometry(zoom / 0.3);
        let startMarker = new Mesh(markerGeometry, this.annotateMarkerMaterial);
        startMarker.userData.annotateId = id;
        startMarker.visible = false;
        group.add(startMarker);

        let angleLine = new Line(new BufferGeometry(), this.annotateLineMaterial);
        angleLine.visible = false;
        group.add(angleLine);

        let horizLine = new Line(new BufferGeometry(), this.annotateLineMaterial);
        horizLine.visible = false;
        group.add(horizLine);

        let annotationText = new SpriteText2D(this.defaultAnnotationText, {
          align: textAlign.top,
          font: '48px Arial',
          fillStyle: this.annotateColor,
          shadowColor: this.annotateColor,
          antialias: this.antialias
        });
        annotationText.userData.annotateId = id;
        annotationText.scale.set(zoom / 3.0, zoom / 3.0, 1);

        annotationText.visible = false;
        group.add(annotationText);

        this.sceneMIM.add(group);

        this.annotations[id] = {
          startMarker: startMarker,
          annotationText: annotationText,
          angleLine: angleLine,
          horizLine: horizLine,
          group: group,
          zoom: zoom,
          height: 12,
          font: 'Arial',
          direction: 0,
          annotation: this.defaultAnnotationText,
          bold: false,
          italic: false,
          underline: false,
          lineStyle: 'none',
          color: this.annotateColor
        };
      }

      let annotation = this.annotations[id];

      if (annotation.color !== this.annotateColor) {

        annotation.color = this.annotateColor;
        annotation.group.remove(annotation.annotationText);
        annotation.annotationText.sprite.geometry.dispose();
        annotation.annotationText = new SpriteText2D('', {
          align: textAlign.center,
          font: '48px Arial',
          fillStyle: this.annotateColor,
          shadowColor: this.annotateColor,
          antialias: this.antialias
        });
        annotation.annotationText.userData.annotateId = id;
        annotation.annotationText.scale.set(zoom / 3.0, zoom / 3.0, 1);

        annotation.annotationText.visible = false;
        annotation.group.add(annotation.annotationText);

        annotation = {
          ...annotation,
          zoom: zoom,
          height: 12,
          font: 'Arial',
          direction: 0,
          annotation: '',
          bold: false,
          italic: false,
          underline: false,
          lineStyle: 'none',
          color: this.annotateColor
        };
      }

      if (Math.abs(annotation.zoom - zoom) >= EPS) {

        let markerGeometry = new SphereBufferGeometry(zoom / 0.3);
        annotation.startMarker.geometry.dispose();
        annotation.startMarker.geometry = markerGeometry;
        annotation.annotationText.scale.set(zoom / 3.0, zoom / 3.0, 1);
        annotation.zoom = zoom;

      }

      let startPosition = this.getMeshPoint(annotate.start);

      if (startPosition && annotate.visible) {

        annotation.startMarker.position.copy(startPosition);
        annotation.startMarker.visible = true;

        if (annotate.offset[0] !== 0 || annotate.offset[1] !== 0 || annotate.offset[2] !== 0) {

          let endPosition = new Vector3().addVectors(startPosition, getThreeVectorFromVec3(annotate.offset));

          let angleLineStartPosition = startPosition.clone();
          let angleLineEndPosition = endPosition.clone();
          let horizLineStartPosition = endPosition.clone();

          let direction = startPosition.clone().project(this.getCamera()).x > endPosition.clone().project(this.getCamera()).x ? -1 : 1;
          let horizLineEndPosition = endPosition.clone().project(this.getCamera()).add(new Vector3(0.1 * direction, 0, 0)).unproject(this.getCamera());
          let textPosition = endPosition.clone().project(this.getCamera()).add(new Vector3(0.11 * direction, 0, 0)).unproject(this.getCamera());

          if (annotation.annotation !== annotate.text ||
            annotation.direction !== direction ||
            annotation.font !== annotate.font ||
            annotation.height !== annotate.height ||
            annotation.bold !== (annotate.styles['bold'] === 'true') ||
            annotation.italic !== (annotate.styles['italic'] === 'true') ||
            annotation.underline !== (annotate.styles['underline'] === 'true') ||
            annotation.lineStyle !== annotate.styles['lineStyle']
          ) {

            annotation.annotationText.align = direction > 0 ? textAlign.left : textAlign.right;
            annotation.annotationText.updateAlign();
            annotation.annotationText.font = (annotate.styles['bold'] === 'true' ? 'bold ' : '') +
              (annotate.styles['italic'] === 'true' ? 'italic ' : '') +
              annotate.height * 4 + 'px ' +
              annotate.font;

            if (annotate.styles['lineStyle'] === 'number') {

              annotation.annotationText.text = annotate.text.split('\n').map((s, i) => '' + (i + 1) + '. ' + s).join('\n');

            } else if (annotate.styles['lineStyle'] === 'bullet') {

              annotation.annotationText.text = annotate.text.split('\n').map(s => '• ' + s).join('\n');

            } else {

              annotation.annotationText.text = annotate.text;

            }

            annotation.annotationText.updateText();
            annotation.annotation = annotate.text;
            annotation.direction = direction;
            annotation.font = annotate.font;
            annotation.height = annotate.height;
            annotation.bold = (annotate.styles['bold'] === 'true');
            annotation.italic = (annotate.styles['italic'] === 'true');
            annotation.underline = (annotate.styles['underline'] === 'true');
            annotation.lineStyle = annotate.styles['lineStyle'];

          }

          annotation.annotationText.position.copy(textPosition);

          Editor3d.updateLineSegmentGeometry(annotation.angleLine.geometry as BufferGeometry, angleLineStartPosition, angleLineEndPosition);
          Editor3d.updateLineSegmentGeometry(annotation.horizLine.geometry as BufferGeometry, horizLineStartPosition, horizLineEndPosition);

          annotation.angleLine.visible = true;
          annotation.horizLine.visible = true;
          annotation.annotationText.visible = true;

        } else {

          annotation.angleLine.visible = false;
          annotation.horizLine.visible = false;
          annotation.annotationText.visible = false;

        }

      } else {

        annotation.startMarker.visible = false;
        annotation.angleLine.visible = false;
        annotation.horizLine.visible = false;
        annotation.annotationText.visible = false;

      }

    }

    this.setNeedsUpdate();

  }

  refreshPlaceholderBoxes() {

    for (let id in this.placeholderBoxes) {

      if (this.pBoxes[id] === undefined) {

        let placeholderBox = this.placeholderBoxes[id];

        placeholderBox.group.remove(...placeholderBox.group.children);
        placeholderBox.descriptionText.sprite.geometry.dispose();
        placeholderBox.progressText.sprite.geometry.dispose();

        this.sceneMIM.remove(placeholderBox.group);

        delete this.placeholderBoxes[id];

      }

    }

    let zoom = 1 / this.orthoCamera.zoom;
    let eye = this.getCamera().getWorldDirection(new Vector3());
    let toTop = new Vector3(0, 1, 0).unproject(this.getCamera()).sub(new Vector3(0, -1, 0).unproject(this.getCamera())).normalize();
    // let zoom = this.controls.target.distanceTo(this.controls.object.position)

    for (let id in this.pBoxes) {

      let pBox = this.pBoxes[id];

      if (this.placeholderBoxes[id] === undefined) {

        let group = new Group();
        group.name = `placeholder[${id}]`;
        // group.userData.instantiatable = true;

        let boxGeometry = new BoxBufferGeometry(1, 1, 1);
        let box = new Mesh(boxGeometry, this.placeholderBoxMaterials[PlaceholderBoxStates.Success]);
        box.userData.pBoxId = id;
        box.visible = false;
        group.add(box);

        let edgeGeometry = new EdgesGeometry(boxGeometry);
        let edgeBox = new LineSegments(edgeGeometry, this.placeholderBoxWireframeMaterial);
        edgeBox.visible = false;
        group.add(edgeBox);

        let filledBox = new Mesh(boxGeometry, this.placeholderFilledBoxMaterial);
        filledBox.visible = false;
        group.add(filledBox);

        let edgeFilledBox = new LineSegments(edgeGeometry, this.placeholderFilledBoxWireframeMaterial);
        edgeFilledBox.visible = false;
        group.add(edgeFilledBox);

        let descriptionText = new SpriteText2D('', {
          align: textAlign.top,
          font: '48px Arial',
          fillStyle: this.defaultColor,
          shadowColor: this.defaultColor,
          antialias: this.antialias
        });
        descriptionText.scale.set(zoom / 2.5, zoom / 2.5, 1);
        descriptionText.sprite.material.transparent = true;
        descriptionText.sprite.material.depthTest = false;

        descriptionText.visible = false;
        group.add(descriptionText);

        let progressText = new SpriteText2D('0%', {
          align: textAlign.top,
          font: '48px Arial',
          fillStyle: this.defaultColor,
          shadowColor: this.defaultColor,
          antialias: this.antialias
        });
        progressText.scale.set(zoom / 2.0, zoom / 2.0, 1);

        progressText.visible = false;
        group.add(progressText);
        progressText.sprite.material.transparent = true;
        progressText.sprite.material.depthTest = false;

        let cancelSprite = new Sprite(this.sharedMaterials[SharedMaterial.ClosePicker] as SpriteMaterial);
        cancelSprite.userData.pBoxId = id;
        cancelSprite.userData.cancel = true;
        cancelSprite.scale.set(zoom * 20, zoom * 20, 1);

        cancelSprite.visible = false;
        group.add(cancelSprite);

        this.sceneMIM.add(group);

        this.placeholderBoxes[id] = {
          box,
          filledBox,
          edgeBox,
          edgeFilledBox,
          descriptionText,
          progressText,
          cancelSprite,
          group,
          zoom,
          eye,
          bBox: new Box3(new Vector3(), new Vector3()),
          description: '',
          state: PlaceholderBoxStates.Success,
          progress: -1
        };
      }

      let placeholderBox = this.placeholderBoxes[id];
      let newBBox = new Box3();

      if (pBox.bBox) {
        newBBox = new Box3(getThreeVectorFromVec3(pBox.bBox[0]), getThreeVectorFromVec3(pBox.bBox[1]));
      } else if (this.models[id]) {
        newBBox = getBoundingBoxFromModelMetaInfo(true, this.models[id].metaInfo);
      }

      let center = newBBox.getCenter(new Vector3());
      let size = newBBox.getSize(new Vector3());
      let boxIsEmpty = newBBox.isEmpty();

      if (!placeholderBox.bBox.equals(newBBox) || placeholderBox.progress !== pBox.progress) {

        newBBox.getCenter(placeholderBox.box.position);
        newBBox.getSize(placeholderBox.box.scale);
        placeholderBox.box.position.set(
          center.x,
          center.y,
          (pBox.progress / 2) / 100 * size.z + center.z
        );

        placeholderBox.box.scale.set(
          size.x,
          size.y,
          size.z * (100 - pBox.progress) / 100
        );

        placeholderBox.edgeBox.position.copy(center);
        placeholderBox.edgeBox.scale.copy(size);
        this.setNeedsUpdate();

      }

      if (!placeholderBox.bBox.equals(newBBox) || placeholderBox.progress !== pBox.progress) {

        placeholderBox.filledBox.position.set(
          center.x,
          center.y,
          (pBox.progress / 2 - 50) / 100 * size.z + center.z
        );

        placeholderBox.filledBox.scale.set(
          size.x,
          size.y,
          size.z * pBox.progress / 100
        );

        placeholderBox.edgeFilledBox.position.copy(placeholderBox.filledBox.position);
        placeholderBox.edgeFilledBox.scale.copy(placeholderBox.filledBox.scale);
        this.setNeedsUpdate();

      }

      if (Math.abs(placeholderBox.zoom - zoom) >= EPS || placeholderBox.eye.clone().sub(eye).lengthSq() >= EPS || placeholderBox.progress !== pBox.progress) {

        placeholderBox.progressText.scale.set(zoom / 2.0, zoom / 2.0, 1);
        placeholderBox.progressText.position.copy(
          new Vector3(center.x, center.y, (pBox.progress - 50) / 100.0 * size.z + center.z + zoom * 20).addScaledVector(toTop, -zoom * 15)
        );
        this.setNeedsUpdate();

      }

      if (Math.abs(placeholderBox.zoom - zoom) >= EPS || placeholderBox.eye.clone().sub(eye).lengthSq() >= EPS || placeholderBox.description !== pBox.description) {

        placeholderBox.descriptionText.scale.set(zoom / 2.5, zoom / 2.5, 1);
        placeholderBox.descriptionText.position.copy(
          new Vector3(center.x, center.y, center.z + size.z / 2 + zoom * 40).addScaledVector(toTop, zoom * 15)
        );
        this.setNeedsUpdate();

      }

      if (!placeholderBox.bBox.equals(newBBox) || Math.abs(placeholderBox.zoom - zoom) >= EPS || placeholderBox.eye.clone().sub(eye).lengthSq() >= EPS) {

        placeholderBox.cancelSprite.scale.set(zoom * 20, zoom * 20, 1);

        let maxX = -Infinity, minY = +Infinity, spritePos = new Vector3();

        for (let i = 0; i < 4; ++i) {

          let x = center.x + (size.x / 2 + zoom * 10) * ((i & 0x2) ? 1 : -1);
          let y = center.y + (size.y / 2 + zoom * 10) * ((i & 0x1) ? 1 : -1);
          let z = center.z + size.z / 2 + zoom * 10;
          let pos = new Vector3(x, y, z).project(this.getCamera());

          if (pos.x > maxX || (pos.x === maxX && pos.y < minY)) {

            maxX = pos.x;
            minY = pos.y;
            spritePos = new Vector3(x, y, z);

          }

        }

        placeholderBox.cancelSprite.position.copy(spritePos);
        this.setNeedsUpdate();

      }

      if (placeholderBox.progress !== pBox.progress) {

        placeholderBox.progressText.text = `${Math.round(pBox.progress)}%`;
        placeholderBox.progressText.updateText();
        placeholderBox.progressText.sprite.material.transparent = true;
        placeholderBox.progressText.sprite.material.depthTest = false;
        this.setNeedsUpdate();

      }

      if (placeholderBox.description !== pBox.description) {

        placeholderBox.descriptionText.text = pBox.description;
        placeholderBox.descriptionText.updateText();
        placeholderBox.descriptionText.sprite.material.transparent = true;
        placeholderBox.descriptionText.sprite.material.depthTest = false;
        this.setNeedsUpdate();

      }

      if (placeholderBox.state !== pBox.state) {

        placeholderBox.box.material = this.placeholderBoxMaterials[pBox.state];
        this.setNeedsUpdate();

      }

      placeholderBox.bBox = newBBox;
      placeholderBox.zoom = zoom;
      placeholderBox.progress = pBox.progress;
      placeholderBox.description = pBox.description;
      placeholderBox.state = pBox.state;

      placeholderBox.progressText.visible = !boxIsEmpty;
      placeholderBox.descriptionText.visible = !boxIsEmpty && !!placeholderBox.description;
      placeholderBox.box.visible = !boxIsEmpty;
      placeholderBox.filledBox.visible = !boxIsEmpty;
      placeholderBox.edgeBox.visible = !boxIsEmpty;
      placeholderBox.edgeFilledBox.visible = !boxIsEmpty;
      placeholderBox.cancelSprite.visible = !boxIsEmpty && !!(pBox.info && pBox.info.cancel);

    }

  }

  selectPlaceholderBox() {

    let intersect = this.getEditableObjectUnderPoint(this.mouse);

    if (intersect && intersect.object.userData.pBoxId) {

      let id = intersect.object.userData.pBoxId;

      if (intersect.object.userData.cancel)
        this.actionCallback({action: ACT_CANCEL_PLACEHOLDER, id});
      else
        this.actionCallback({action: ACT_SELECT_PLACEHOLDER, id});

      return true;

    }

    return false;

  }

  selectMeasurement() {

    let intersect = this.getEditableObjectUnderPoint(this.mouse);

    let measureId = 0;
    let phase = MeasureControlStates.SelectingFrom;

    if (intersect && intersect.object.parent !== null && intersect.object.parent.userData.measureId > 0) {

      measureId = intersect.object.parent.userData.measureId;
      phase = MeasureControlStates.MovingPerpendicular;

    }

    if (intersect && intersect.object !== null && intersect.object.userData.measureId > 0) {

      measureId = intersect.object.userData.measureId;

      if (intersect.object.userData.start)
        phase = MeasureControlStates.SelectingFrom;
      else
        phase = MeasureControlStates.SelectingTo;

    }

    if (measureId > 0) {

      if (this.selection.tool !== Tools.Measure)
        this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Measure});

      this.editMeasure(measureId, phase);
      return true;

    }

    return false;

  }

  selectAnnotation() {

    let intersect = this.getEditableObjectUnderPoint(this.mouse);
    let annotateId = 0;
    let phase = AnnotateControlStates.SelectingTarget;

    if (intersect && intersect.object.parent !== null && intersect.object.parent.userData.annotateId > 0) {

      annotateId = intersect.object.parent.userData.annotateId;
      phase = AnnotateControlStates.SelectingTextPos;

    }

    if (intersect && intersect.object !== null && intersect.object.userData.annotateId > 0)
      annotateId = intersect.object.userData.annotateId;

    if (annotateId > 0) {

      if (this.selection.tool !== Tools.Annotate)
        this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Annotate});

      this.editAnnotate(annotateId, phase);
      return true;

    }

    return false;

  }

  filterSelectableIds = (ids: string[]) => {
    return ids.filter(id => this.models[id] && !this.models[id].locked && this.isModelVisible(id));
  };

  syncCameraInfo(cameraInfo: any) {

    if (cameraInfo && cameraInfo.camera && cameraInfo.controlTarget && cameraInfo.zoom) {

      this.cameraTo.set(+cameraInfo.camera[0], +cameraInfo.camera[1], +cameraInfo.camera[2]);
      this.controlTargetTo.set(+cameraInfo.controlTarget[0], +cameraInfo.controlTarget[1], +cameraInfo.controlTarget[2]);
      this.zoomTo = +cameraInfo.zoom;

    }

  }

  setCameraUsername(username: string) {

    this.cameraUsername = username;
    this.refreshCursors();

  }

  setSyncMouseInfo(username: string, mouseInfo?: any) {

    if (!mouseInfo) {

      if (this.cursors[username]) {

        let cursor = this.cursors[username];

        cursor.handleMarker.geometry.dispose();
        cursor.usernameText.sprite.geometry.dispose();

        cursor.group.remove(...cursor.group.children);
        this.sceneMIM.remove(cursor.group);

        delete this.cursors[username];

        this.setNeedsUpdate();

      }

      return;

    }

    if (!this.cursors[username]) {

      let group = new Group();
      group.name = `cursor[${username}]`;

      let handleMarker = new Mesh(
        new ConeBufferGeometry(1, 4, 30),
        this.sharedMaterials[SharedMaterial.Cursor]
      );

      let lineGeometry = new BufferGeometry();
      lineGeometry.attributes.position = new Float32BufferAttribute([0, 0, 0, 0, 0, 0], 3);
      let lineMarker = new LineSegments(lineGeometry, new LineBasicMaterial({color: 0x000000}));

      let usernameText = new SpriteText2D('   ' + username, {
        align: textAlign.left,
        font: '48px Arial',
        fillStyle: '#0E0E0F',
        shadowColor: '#0E0E0F',
        antialias: this.antialias
      });

      handleMarker.visible = false;
      lineMarker.visible = false;
      usernameText.visible = false;

      group.add(handleMarker);
      group.add(lineMarker);
      group.add(usernameText);

      this.sceneMIM.add(group);

      this.cursors[username] = {
        handleMarker,
        lineMarker,
        usernameText,
        group,
        origin: vec3.create(),
        direction: vec3.create()
      };

    }

    let cursor = this.cursors[username];

    let origin = [_n(cursor.origin[0]), _n(cursor.origin[1]), _n(cursor.origin[2])];
    let direction = [_n(cursor.direction[0]), _n(cursor.direction[1]), _n(cursor.direction[2])];

    if (JSON.stringify(mouseInfo) !== JSON.stringify({origin, direction})) {

      cursor.origin = vec3.fromValues(+mouseInfo.origin[0], +mouseInfo.origin[1], +mouseInfo.origin[2]);
      cursor.direction = vec3.fromValues(+mouseInfo.direction[0], +mouseInfo.direction[1], +mouseInfo.direction[2]);

      this.refreshCursors();

      this.setNeedsUpdate();

    }

  }

  getUnitOnCameraPlane(pt: Vector3) {

    let camera = this.getCamera();
    let z = pt.clone().project(camera).z;

    return new Vector3(0, -1, z).unproject(camera).distanceTo(new Vector3(0, 1, z).unproject(camera));

  }

  refreshCursors() {

    for (let username in this.cursors) {

      let cursor = this.cursors[username];

      let zoom = 1 / this.orthoCamera.zoom;
      let handleMarker = cursor.handleMarker;
      let lineMarker = cursor.lineMarker;
      let usernameText = cursor.usernameText;

      let direction = getThreeVectorFromVec3(cursor.direction);
      let origin = getThreeVectorFromVec3(cursor.origin);

      let quaternion = new Quaternion();

      // if (username === this.cameraUsername) {
      //
      //   this.rayCaster.setFromCamera(new Vector2(), this.getCamera());
      //   direction = this.rayCaster.ray.direction;
      //
      // }

      quaternion.setFromUnitVectors(new Vector3(0, 1, 0), direction);

      let {origin: newOrg, distance} = this.boundByCamera(origin, direction);
      let unit = 90;
      if ((this.getCamera() as any).isPerspectiveCamera)
        unit = this.getUnitOnCameraPlane(newOrg);

      handleMarker.quaternion.copy(quaternion);
      handleMarker.position.set(newOrg.x + direction.x * zoom * unit / 6, newOrg.y + direction.y * zoom * unit / 6, newOrg.z + direction.z * zoom * unit / 6);
      usernameText.position.copy(newOrg);

      usernameText.scale.set(zoom * unit / 270, zoom * unit / 270, zoom * unit / 270);
      handleMarker.scale.set(zoom * unit / 15, zoom * unit / 15, zoom * unit / 15);

      let position = lineMarker.geometry.attributes.position;
      position.setXYZ(0, handleMarker.position.x, handleMarker.position.y, handleMarker.position.z);
      position.setXYZ(1, newOrg.x + direction.x * distance, newOrg.y + direction.y * distance, newOrg.z + direction.z * distance);
      position.needsUpdate = true;

      handleMarker.visible = true;
      lineMarker.visible = true;
      usernameText.visible = true;

    }

  }

  boundByCamera(origin: Vector3, direction: Vector3) {

    let frustum = new Frustum();
    const margin = [60, 60];

    let left = -(1 - margin[0] / this.width * 2), top = -(1 - margin[1] / this.height * 2), right = -left, down = -top;
    updateFrustum(this.getCamera(), frustum, new Vector3(left, top, 0), new Vector3(right, down, 0), 100);

    let rayP = new Ray(origin, direction);
    let rayN = new Ray(origin, direction.clone().negate());
    let vecTemp = new Vector3();
    let intersects = [];

    for (let i = 0; i < 6; ++i) {

      if (rayP.intersectsPlane(frustum.planes[i])) {

        rayP.intersectPlane(frustum.planes[i], vecTemp);
        intersects.push(vecTemp.clone());

      } else if (rayN.intersectsPlane(frustum.planes[i])) {

        rayN.intersectPlane(frustum.planes[i], vecTemp);
        intersects.push(vecTemp.clone());

      }

    }

    let crosses = [];
    for (let cross of intersects) {

      let projected = cross.clone().project(this.getCamera());

      if (projected.x < right + EPS &&
        projected.x > left - EPS &&
        projected.y < down + EPS &&
        projected.y > top - EPS &&
        projected.z < 1 + EPS &&
        projected.z > -EPS
      ) {
        crosses.push(cross);
      }

    }

    if (crosses.length > 1) {

      let v1 = crosses[0].clone().addScaledVector(origin, -1);
      let v2 = crosses[1].clone().addScaledVector(origin, -1);
      v1.projectOnVector(direction);
      v2.projectOnVector(direction);
      let j = 0;

      if (Math.abs(direction.x) < Math.abs(direction.z) && Math.abs(direction.y) < Math.abs(direction.z)) {

        j = 2;

      } else if (Math.abs(direction.x) < Math.abs(direction.y) && Math.abs(direction.z) < Math.abs(direction.y)) {

        j = 1;

      }

      let dis1 = v1.getComponent(j) / direction.getComponent(j);
      let dis2 = v2.getComponent(j) / direction.getComponent(j);

      if (dis1 > dis2)
        [crosses[0], crosses[1]] = [crosses[1], crosses[0]];

      return {origin: crosses[0], distance: crosses[1].distanceTo(crosses[0])};

    } else if (crosses.length === 1) {

      return {origin: crosses[0], distance: 10};

    } else {

      return {origin, distance: 10};

    }

  }

  orbitCamera(rotate: boolean, speed?: number) {

    this.rotateCamera = rotate;
    this.orthoOrbitControl.autoRotateSpeed = speed || -15.0;

    if (!rotate)
      this.orthoOrbitControl.autoRotate = false;

  }

  repositionCamera(zoomMode?: string, ids?: string[]) {

    let maxX, minX, maxY, minY, maxZ, minZ;

    if (zoomMode === "placeholders") {

      if (ids === undefined)
        ids = Object.keys(this.pBoxes);

      ({maxX, minX, maxY, minY, maxZ, minZ} = this.getPlacehoderBoundingBox(ids));

    } else {

      if (ids === undefined)
        ids = this.selection.ids;

      if (zoomMode === 'preview' || (ids.length === 0 && zoomMode !== 'full'))
        ids = Array.from(this.scope);

      ({maxX, minX, maxY, minY, maxZ, minZ} = this.getModelBoundingBox(ids));

    }

    if (zoomMode === 'full') {

      let avgLength = (maxX - minX) + (maxY - minY) + (maxZ - minZ);
      this.cameraTo = new Vector3((maxX + minX) / 2 + SCENE_DIMENSION / 2, (maxY + minY) / 2 + SCENE_DIMENSION / 2 + 1, (maxZ + minZ) / 2 + SCENE_DIMENSION / 2);
      this.controlTargetTo = new Vector3((maxX + minX) / 2, (maxY + minY) / 2, (maxZ + minZ) / 2);
      this.zoomTo = Math.max(this.width, this.height) / avgLength / 1.5;

    } else if (zoomMode === 'preview' || zoomMode === 'preview-zoom' || zoomMode === 'hero-sculpt-zoom') {

      if (this.cameraInfo) {

        this.cameraTo = getThreeVectorFromVec3(this.cameraInfo.position);
        this.controlTargetTo = getThreeVectorFromVec3(this.cameraInfo.target);
        this.zoomTo = this.cameraInfo.zoom;

        if (this.cameraInfo.width && this.cameraInfo.height) {

          let orgRatio = this.cameraInfo.width / this.cameraInfo.height;
          let newRatio = this.container.clientWidth / this.container.clientHeight;

          if (orgRatio > newRatio)
            this.zoomTo *= newRatio / (orgRatio || 1);
          else
            this.zoomTo *= orgRatio / (newRatio || 1);

          this.zoomTo *= Math.max(this.container.clientWidth / this.cameraInfo.width, this.container.clientHeight / this.cameraInfo.height);

        }

      } else {

        let avgLength = Math.max((maxX - minX) + (maxY - minY) + (maxZ - minZ), 200);
        this.cameraTo = new Vector3((maxX + minX) / 2 + SCENE_DIMENSION / 2, (maxY + minY) / 2 + SCENE_DIMENSION / 2 + 1, (maxZ + minZ) / 2 + SCENE_DIMENSION / 2);
        this.controlTargetTo = new Vector3((maxX + minX) / 2, (maxY + minY) / 2, (maxZ + minZ) / 2);
        this.zoomTo = Math.max(this.width, this.height) / avgLength;

      }

      if (zoomMode === 'hero-sculpt-zoom') {
        this.cameraTo.add(new Vector3(0, 0, -50));
        this.controlTargetTo.add(new Vector3(0, 0, -50));
      }

      if (zoomMode === 'preview-zoom') {

        this.zoomTo /= 1.2;
        this.instantUpdateCamera = true;
        this.adjustCameraControl();
        this.instantUpdateCamera = false;

        setTimeout(() => {

          this.zoomTo *= 1.2;

        }, 100);

        setTimeout(() => {

          this.refreshAnnotations();
          this.refreshMeasurements();

        }, 1000);

      }

    } else {

      let offset = this.orthoCamera.position.clone().addScaledVector(this.orthoOrbitControl.target, -1);

      this.controlTargetTo = new Vector3((maxX + minX) / 2, (maxY + minY) / 2, (maxZ + minZ) / 2);
      this.cameraTo = this.controlTargetTo.clone().add(offset);

      if (zoomMode === "placeholders") {

        let avgLength = Math.max((maxX - minX) + (maxY - minY) + (maxZ - minZ), 200);
        this.zoomTo = Math.max(this.width, this.height) / avgLength;

      }

    }

    this.actionCallback({
      action: ACT_UPDATE_CAMERA,
      camera: this.cameraTo.toArray(),
      controlTarget: this.controlTargetTo.toArray(),
      zoom: this.zoomTo
    });

  }

  refreshBasePlane() {

    let visible = true;

    if (this.basePlane) {

      this.sceneMIM.remove(this.basePlane);
      visible = this.basePlane.visible;
      this.basePlane.geometry.dispose();

    }

    let minX = +Infinity;
    let minY = +Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    if (maxX < minX) {

      minX = -100;
      maxX = 100;

    }

    if (maxY < minY) {

      minY = -100;
      maxY = 100;

    }

    let planeGeometry = new PlaneGeometry((maxX - minX) * 40, (maxY - minY) * 40, 1, 1);
    let planeMaterial = new ShadowMaterial();
    planeMaterial.transparent = true;
    planeMaterial.opacity = 0.1;
    this.basePlane = new Mesh(planeGeometry, planeMaterial);
    this.basePlane.name = `base-plane`;
    this.basePlane.position.z = 0;
    this.basePlane.position.x = (minX + maxX) / 2;
    this.basePlane.position.y = (minY + maxY) / 2;
    this.basePlane.receiveShadow = true;
    this.basePlane.visible = visible;
    this.baseZ = 0;
    this.sceneMIM.add(this.basePlane);

  }

  _createModelStub(id: string, title: string, component: string) {

    let model: IModel = {
      title,
      component,
      subs: [],
      meshGroup: new Group(),
      edgeGroup: new Group(),
      boundingBox: new LineSegments(),
      prevIds: [],
      visible: true,
      locked: false,
      heatmap: false,
      global: true,
      metaInfo: {
        orgCenter: vec3.create(),
        position: vec3.create(),
        scale: vec3.create(),
        localSize: vec3.create(),
        globalSize: vec3.create(),
        rotate: vec3.create(),
        translate: vec3.create(),
        skew: vec3.create(),
        holder: false,
        fake: false
      }
    };

    model.meshGroup.name = `model-mesh[${id}]`;
    model.meshGroup.matrixAutoUpdate = false;
    model.meshGroup.userData.instantiatable = true;

    model.edgeGroup.name = `model-edge[${id}]`;
    model.edgeGroup.matrixAutoUpdate = false;
    model.edgeGroup.userData.instantiatable = true;

    model.boundingBox.name = `model-bounding-box[${id}]`;
    model.boundingBox.matrixAutoUpdate = false;

    let boundingBoxGeometry = new BufferGeometry();
    boundingBoxGeometry.index = new Uint32BufferAttribute([0, 1, 1, 2, 2, 3, 3, 0, 4, 5, 5, 6, 6, 7, 7, 4, 0, 4, 1, 5, 2, 6, 3, 7], 1);
    boundingBoxGeometry.attributes.position = new Float32BufferAttribute(24, 3);

    model.boundingBox.geometry = boundingBoxGeometry;
    model.boundingBox.material = this.sharedMaterials[SharedMaterial.BoxLine];
    model.boundingBox.visible = false;

    this.sceneMIM.add(model.meshGroup, model.edgeGroup);
    this.controlScene.add(model.boundingBox);

    this.models[id] = model;

  }

  _createSubModelStub(id: string, index: number) {

    const model = this.models[id];

    const material = new MeshPhysicalMaterial({});
    const sub: ISubModel = {
      mesh: new Mesh(new BufferGeometry(), material),
      edge: new LineSegments(new BufferGeometry(), this.sharedMaterials[SharedMaterial.EdgeLine]),
      renderedObjectType: RenderedObjectTypes.Brep,
      material,
      geometryHash: '',
      matrixHash: '',
      materialHash: '',
      luxHash: '',
      materialId: '',
      valid: true,
      minThickness: 0,
      heatmapData: undefined
    };

    sub.mesh.userData.id = id;
    sub.mesh.userData.index = index;
    sub.mesh.userData.locked = model.locked;

    sub.mesh.castShadow = true;
    sub.mesh.receiveShadow = true;
    sub.mesh.matrixAutoUpdate = false;

    sub.mesh.name = id;
    sub.mesh.castShadow = true;
    sub.mesh.receiveShadow = true;
    sub.mesh.matrixAutoUpdate = false;

    sub.edge.matrixAutoUpdate = false;

    model.meshGroup.add(sub.mesh);
    model.edgeGroup.add(sub.edge);

    model.subs[index] = sub;
  }

  onStartDragLightHelperPicker = (id: string) => {

    if (this.isHoverLightHelperPicker[id] !== true) {

      this.isHoverLightHelperPicker[id] = true;
      this.setNeedsUpdate();

    }

  };

  onStopDragLightHelperPicker = (id: string) => {

    if (this.isHoverLightHelperPicker[id] !== false) {

      this.isHoverLightHelperPicker[id] = false;
      this.setNeedsUpdate();

    }

  };

  onUpdateLight = (id: number, info: any) => {

    if (this.lights[id])
      this.actionCallback({
        action: ACT_UPDATE_LIGHT,
        id: id.toString(),
        info: {...this.lights[id].entity, ...info}
      });

  };

  addAmbientLight(lightId: number) {

    let lightObj = new AmbientLight();
    lightObj.name = `light[${lightId}]`;

    this.sceneMIM.add(lightObj);

    this.lights[lightId] = {
      type: LightTypes.Ambient,
      light: lightObj,
      visible: true,
      helperVisible: true,
    };

  }

  addDirectionalLight(lightId: number) {

    let lightObj = new DirectionalLight();
    lightObj.name = `light[${lightId}]`;
    lightObj.shadow.mapSize.set(2048, 2048);
    lightObj.shadow.radius = 5;

    let targetObj: Object3D = Editor3d.createTargetObject(lightObj.name, vec3.create());
    lightObj.target = targetObj;
    this.sceneMIM.add(targetObj);
    this.sceneMIM.add(lightObj);

    let helper = new NDirectionalLightHelper(
      lightObj,
      0,
      0,
      lightId,
      this.getCamera(),
      this.onStartDragLightHelperPicker,
      this.onStopDragLightHelperPicker,
      this.onUpdateLight
    );

    helper.name = `light-helper[${lightId}]`;
    if (this.delegate)
      helper.setDelegate(this.delegate);

    this.sceneMIM.add(helper);

    this.lights[lightId] = {
      type: LightTypes.Directional,
      light: lightObj,
      helper: helper,
      visible: true,
      helperVisible: true,
    };

  }

  addSpotLight(lightId: number) {

    let lightObj = new SpotLight();
    lightObj.name = `light[${lightId}]`;
    lightObj.shadow.mapSize.set(2048, 2048);
    lightObj.shadow.radius = 5;
    lightObj.shadow.camera.near = this.perspCamera.near;
    lightObj.shadow.camera.far = this.perspCamera.far;

    let targetObj = Editor3d.createTargetObject(lightObj.name, vec3.create());
    this.sceneMIM.add(targetObj);

    lightObj.target = targetObj;
    this.sceneMIM.add(lightObj);

    let helper = new NSpotLightHelper(
      lightObj,
      lightId,
      this.getCamera(),
      this.onStartDragLightHelperPicker,
      this.onStopDragLightHelperPicker,
      this.onUpdateLight
    );

    helper.name = `light-helper[${lightId}]`;
    if (this.delegate)
      helper.setDelegate(this.delegate);

    this.sceneMIM.add(helper);

    this.lights[lightId] = {
      type: LightTypes.Spot,
      light: lightObj,
      helper: helper,
      visible: true,
      helperVisible: true,
    };

  }

  addHemisphereLight(lightId: number) {

    let lightObj = new HemisphereLight();
    lightObj.name = `light[${lightId}]`;
    this.sceneMIM.add(lightObj);

    let helper = new NHemisphereLightHelper(
      lightObj,
      5,
      lightId,
      this.getCamera(),
      this.onStartDragLightHelperPicker,
      this.onStopDragLightHelperPicker,
      this.onUpdateLight
    );

    helper.name = `light-helper[${lightId}]`;
    if (this.delegate)
      helper.setDelegate(this.delegate);

    this.sceneMIM.add(helper);

    this.lights[lightId] = {
      type: LightTypes.Hemisphere,
      light: lightObj,
      helper: helper,
      visible: true,
      helperVisible: true,
    };

  }

  addPointLight(lightId: number) {

    let lightObj = new PointLight();
    lightObj.name = `light[${lightId}]`;
    lightObj.shadow.mapSize.set(2048, 2048);
    lightObj.shadow.radius = 5;
    lightObj.shadow.camera.near = this.perspCamera.near;
    lightObj.shadow.camera.far = this.perspCamera.far;
    this.sceneMIM.add(lightObj);

    let helper = new NPointLightHelper(
      lightObj,
      3,
      lightId,
      this.getCamera(),
      this.onStartDragLightHelperPicker,
      this.onStopDragLightHelperPicker,
      this.onUpdateLight
    );

    helper.name = `light-helper[${lightId}]`;
    if (this.delegate)
      helper.setDelegate(this.delegate);

    this.sceneMIM.add(helper);

    this.lights[lightId] = {
      type: LightTypes.Point,
      light: lightObj,
      helper: helper,
      visible: true,
      helperVisible: true,
    };

  }

  addRectAreaLight(lightId: number) {

    let lightObj = new RectAreaLight() as (RectAreaLight & { target: Vector3 });
    lightObj.name = `light[${lightId}]`;
    lightObj.target = new Vector3();
    this.sceneMIM.add(lightObj);

    let helper = new NRectAreaLightHelper(
      lightObj,
      lightId,
      this.getCamera(),
      this.onStartDragLightHelperPicker,
      this.onStopDragLightHelperPicker,
      this.onUpdateLight
    );

    helper.name = `light-helper[${lightId}]`;
    if (this.delegate)
      helper.setDelegate(this.delegate);

    this.sceneMIM.add(helper);

    this.lights[lightId] = {
      type: LightTypes.RectArea,
      light: lightObj,
      helper: helper,
      visible: true,
      helperVisible: true,
    };

  }

  updateAmbientLight(lightId: number, entity: IAmbientLight) {

    let currentLight = this.lights[lightId];

    if (!currentLight)
      return false;

    let lightObj = currentLight.light as AmbientLight;

    lightObj.color.set(entity.color);
    lightObj.intensity = entity.intensity * 10;

    return true;
  }

  updateDirectionalLight(lightId: number, entity: IDirectionalLight) {

    let currentLight = this.lights[lightId];

    if (!currentLight)
      return false;

    let lightObj = currentLight.light as DirectionalLight;

    if (lightObj) {

      lightObj.color.set(entity.color);
      lightObj.intensity = entity.intensity * 10;
      lightObj.castShadow = entity.castShadow;
      lightObj.position.set(entity.position[0], entity.position[1], entity.position[2]);
      lightObj.target.position.set(entity.targetPosition[0], entity.targetPosition[1], entity.targetPosition[2]);

      lightObj.shadow.camera.left = -entity.width / 2;
      lightObj.shadow.camera.right = entity.width / 2;
      lightObj.shadow.camera.bottom = -entity.height / 2;
      lightObj.shadow.camera.top = entity.height / 2;

      lightObj.shadow.camera.updateProjectionMatrix();

      let helper = currentLight.helper as NDirectionalLightHelper;
      helper.update(entity.width, entity.height);

    }

    return true;

  }

  updateHemisphereLight(lightId: number, entity: IHemisphereLight) {

    let currentLight = this.lights[lightId];

    if (!currentLight)
      return false;

    let lightObj = currentLight.light as HemisphereLight;

    lightObj.color.set(entity.color);
    lightObj.groundColor.set(entity.groundColor);
    lightObj.intensity = entity.intensity * 10;
    lightObj.position.set(entity.position[0], entity.position[1], entity.position[2]);

    let helper = currentLight.helper as NHemisphereLightHelper;
    helper.update();

    return true;

  }

  updatePointLight(lightId: number, entity: IPointLight) {

    let currentLight = this.lights[lightId];

    if (!currentLight)
      return false;

    let lightObj = currentLight.light as PointLight;

    if (lightObj) {

      lightObj.color.set(entity.color);
      lightObj.intensity = entity.intensity * 10;
      lightObj.castShadow = entity.castShadow;
      lightObj.decay = entity.decay;
      lightObj.distance = entity.distance;
      lightObj.position.set(entity.position[0], entity.position[1], entity.position[2]);

      let helper = currentLight.helper as NPointLightHelper;
      helper.update();

    }

    return true;

  }

  updateRectAreaLight(lightId: number, entity: IRectAreaLight) {

    let currentLight = this.lights[lightId];

    if (!currentLight)
      return false;

    let lightObj = currentLight.light as (RectAreaLight & { target: Vector3 });

    if (lightObj) {

      lightObj.color.set(entity.color);
      lightObj.intensity = entity.intensity * 10;
      lightObj.width = entity.width;
      lightObj.height = entity.height;
      lightObj.position.set(entity.position[0], entity.position[1], entity.position[2]);
      lightObj.target.set(entity.targetPosition[0], entity.targetPosition[1], entity.targetPosition[2]);
      lightObj.lookAt(entity.targetPosition[0], entity.targetPosition[1], entity.targetPosition[2]);

      let helper = currentLight.helper as NRectAreaLightHelper;
      helper.update();

    }

    return true;

  }

  updateSpotLight(lightId: number, entity: ISpotLight) {

    let currentLight = this.lights[lightId];

    if (!currentLight)
      return false;

    let lightObj = currentLight.light as SpotLight;

    lightObj.color.set(entity.color);
    lightObj.intensity = entity.intensity * 10;
    lightObj.angle = entity.angle;
    lightObj.decay = entity.decay;
    lightObj.distance = entity.distance;
    lightObj.penumbra = entity.penumbra;
    lightObj.castShadow = entity.castShadow;
    lightObj.position.set(entity.position[0], entity.position[1], entity.position[2]);
    lightObj.target.position.set(entity.targetPosition[0], entity.targetPosition[1], entity.targetPosition[2]);

    let helper = currentLight.helper as NSpotLightHelper;
    helper.update();

    return true;

  }

  addLight(lightType: LightTypes, lightId: number) {

    switch (lightType) {
      case LightTypes.Ambient:
        this.addAmbientLight(lightId);
        break;
      case LightTypes.Directional:
        this.addDirectionalLight(lightId);
        break;
      case LightTypes.Hemisphere:
        this.addHemisphereLight(lightId);
        break;
      case LightTypes.Point:
        this.addPointLight(lightId);
        break;
      case LightTypes.RectArea:
        this.addRectAreaLight(lightId);
        break;
      case LightTypes.Spot:
        this.addSpotLight(lightId);
        break;
    }

    this.setNeedsUpdate();

  }

  removeLight(lightId: number) {

    let light = this.lights[lightId];

    if (light) {

      this.sceneMIM.remove(light.light);

      if (light.helper) {

        light.helper.dispose();
        this.sceneMIM.remove(light.helper);

      }

      delete this.lights[lightId];
      this.setNeedsUpdate();

    }

  }

  updateLight(light: ILight): boolean {

    let currentLight = this.lights[light.id];

    if (currentLight) {

      if (currentLight.light.visible !== light.visible) {

        currentLight.light.visible = light.visible;
        this.setNeedsUpdate();

      }

      if (currentLight.helper && currentLight.helper.visible !== (light.helperVisible && this.lightControlsEnabled)) {

        currentLight.helper.visible = light.helperVisible && this.lightControlsEnabled;
        this.setNeedsUpdate();

      }

      currentLight.visible = light.visible;
      currentLight.helperVisible = light.helperVisible;

    }

    if (light.light === currentLight.entity)
      return false;

    switch (light.type) {
      case LightTypes.Ambient:
        this.updateAmbientLight(light.id, light.light as IAmbientLight);
        break;
      case LightTypes.Directional:
        this.updateDirectionalLight(light.id, light.light as IDirectionalLight);
        break;
      case LightTypes.Hemisphere:
        this.updateHemisphereLight(light.id, light.light as IHemisphereLight);
        break;
      case LightTypes.Point:
        this.updatePointLight(light.id, light.light as IPointLight);
        break;
      case LightTypes.RectArea:
        this.updateRectAreaLight(light.id, light.light as IRectAreaLight);
        break;
      case LightTypes.Spot:
        this.updateSpotLight(light.id, light.light as ISpotLight);
        break;
    }

    currentLight.entity = light.light;
    this.setNeedsUpdate();

    return true;
  }

  getNonCollidingPos(pos: { x: number, y: number }): { x: number, y: number } {

    if (this.selection.group === undefined)
      return pos;

    let step = 0;

    while (true) {

      let dialogWidth = 140;
      let dialogHeight = 60;
      ++step;

      let margin = 30;
      let objects: Object3D[] = this.transformControl.getPickers();

      let points: Vector3[] = [];

      for (let object of objects) {

        object.traverse((obj) => {

          if (obj.type !== 'Group')
            points.push(obj.position.clone().project(this.getCamera()));

        });

      }

      for (let point of points) {

        point.set(
          (point.x + 1) * this.width / 2,
          -(point.y - 1) * this.height / 2,
          0
        );

      }

      let boundingSphere = new Sphere();
      boundingSphere.setFromPoints(points);

      let point = new Vector3(pos.x, pos.y, 0);
      let distance = point.distanceTo(boundingSphere.center);

      point = boundingSphere.center.clone().addScaledVector(point.clone().addScaledVector(boundingSphere.center, -1), (boundingSphere.radius + margin + Math.max(dialogHeight, dialogWidth) / 2) / distance);
      pos = {x: point.x - dialogWidth / 2, y: point.y - dialogHeight / 2};

      let unchanged = true;

      if (pos.x < 100) {

        pos.x = 100;
        unchanged = false;

      } else if (pos.x > this.width - dialogWidth - 100) {

        pos.x = this.width - dialogWidth - 100;
        unchanged = false;

      }

      if (pos.y < 100) {

        pos.y = 100;
        unchanged = false;

      } else if (pos.y > this.height - dialogHeight - 100) {

        pos.y = this.height - dialogHeight - 100;
        unchanged = false;

      }

      if (unchanged || step > 5)
        break;

    }

    return pos;
  }

  getFaceVertices(mesh: Mesh, instanceId: number, faceIndex: number) {

    let geometry = mesh.geometry as BufferGeometry;
    let matrix = mesh.matrixWorld.clone();

    if ((mesh as InstancedMesh).isInstancedMesh) {

      let instanceMatrix = new Matrix4();
      (mesh as InstancedMesh).getMatrixAt(instanceId, instanceMatrix);

      matrix.multiply(instanceMatrix);

    }

    let position = geometry.attributes.position.array;

    let f3 = faceIndex * 3;
    let verts;
    let p0, p1, p2;

    if (geometry.index) {

      let index = geometry.index.array;
      p0 = index[f3] * 3;
      p1 = index[f3 + 1] * 3;
      p2 = index[f3 + 2] * 3;

    } else {

      p0 = f3 * 3;
      p1 = f3 * 3 + 3;
      p2 = f3 * 3 + 6;

    }

    verts = [
      new Vector3(position[p0], position[p0 + 1], position[p0 + 2]),
      new Vector3(position[p1], position[p1 + 1], position[p1 + 2]),
      new Vector3(position[p2], position[p2 + 1], position[p2 + 2])
    ];

    verts.map(v => v.applyMatrix4(matrix));

    return verts;

  }

  setOrientHighlightedMesh(id?: string, mesh?: Mesh, instanceId?: number, faceIndex?: number) {

    if (!this.faceSelection || mesh !== this.faceSelection.mesh || instanceId !== this.faceSelection.instanceId || faceIndex !== this.faceSelection.faceIndex) {

      if (this.faceSelection) {

        this.sceneMIM.remove(this.faceSelection.outlineMesh);
        this.faceSelection.outlineMesh.geometry.dispose();
        (this.faceSelection.outlineMesh.material as Material).dispose();

      }

      this.faceSelection = undefined;

      if (id && mesh && instanceId !== undefined && faceIndex !== undefined) {

        let vertices = this.getFaceVertices(mesh, instanceId, faceIndex);
        let faceGeom = new BufferGeometry();

        faceGeom.index = new Uint32BufferAttribute([0, 1, 2], 1);
        faceGeom.attributes.position = new Float32BufferAttribute(vertices.flatMap(v => v.toArray()), 3);

        let material = new MeshBasicMaterial({color: 0xff00000, transparent: true, depthTest: false});
        let faceMesh = new Mesh(faceGeom, material);
        faceMesh.name = 'face-selection-outline';

        this.faceSelection = {
          id,
          mesh: mesh,
          instanceId: instanceId,
          faceIndex: faceIndex,
          outlineMesh: faceMesh
        };

        this.sceneMIM.add(this.faceSelection.outlineMesh);

      }

    }

  }

  createMaterial(renderMat: IRenderMaterial) {

    if (renderMat.type === RenderMaterialTypes.Line) {

      return new LineBasicMaterial({color: 0x000000});

    } else if (renderMat.type === RenderMaterialTypes.Vertex) {

      return new PointsMaterial({color: 0x000000});

    } else {

      let mat = new MeshPhysicalMaterial({});

      mat.side = this.side;
      mat.shadowSide = BackSide;
      mat.envMap = this.lightEnvMapTexture ? this.lightEnvMapTexture : this.defaultLightEnvMapTexture!;

      if (renderMat.detail) {

        if (renderMat.detail.map) {

          let loader = new TextureLoader();

          if (!renderMat.detail.map.url.startsWith('data:image/'))
            loader.setPath(`${s3RootPath}/public/`);

          mat.map = loader.load(renderMat.detail.map.url);

          mat.map.wrapS = (THREE as any)[renderMat.detail.map.wrapS || ''] || RepeatWrapping;
          mat.map.wrapT = (THREE as any)[renderMat.detail.map.wrapT || ''] || RepeatWrapping;

          if (renderMat.detail.map.repeat)
            mat.map.repeat.set(...renderMat.detail.map.repeat);

          if (renderMat.detail.map.offset)
            mat.map.offset.set(...renderMat.detail.map.offset);

        } else {

          mat.color = new Color(renderMat.detail.color);
          mat.sheen = new Color(renderMat.detail.sheen);
          mat.clearcoat = renderMat.detail.clearcoat;
          mat.clearcoatRoughness = renderMat.detail.clearcoatRoughness;
          mat.metalness = renderMat.detail.metalness;
          mat.roughness = renderMat.detail.roughness;
          mat.opacity = renderMat.detail.opacity;
          mat.transparent = +renderMat.detail.opacity !== 1.0;
          mat.map = null;

        }

      }

      mat.needsUpdate = true;

      return mat;

    }

  }

  generateHeatmap(id: string, index: number) {

    let model = this.models[id];

    if (model === undefined)
      return;

    let data = model.subs[index].heatmapData;

    if (model.subs[index].minThickness === 0 || model.subs[index].heatmapData === undefined || data === undefined || data.length === 0)
      return;

    if (data) {

      let thickness = model.subs[index].minThickness;
      let geometry: BufferGeometry = model.subs[index].mesh.geometry as BufferGeometry;
      let count = geometry.attributes.position.count;

      let colors = new Float32BufferAttribute(new Float32Array(count * 3), 3);
      geometry.attributes.color = colors;

      let color = new Color();

      for (let i = 0; i < count; i++) {

        if (data[i]) {

          let distance = data[i];

          if (distance > thickness * 2)
            color.setHSL(0.666666, 1.0, 0.5);
          else
            color.setHSL(2 * Math.max(distance - thickness, 0) / (thickness * 3), 1.0, 0.5);

          colors.setXYZ(i, color.r, color.g, color.b);

          if (data.length === i)
            break;

        }

      }

    }

  }

  removeFromGeometryMap(id: string, index: number) {

    let model = this.models[id];
    let geometryHash = model.subs[index].geometryHash;
    let geometryMap = this.geometryMap[geometryHash];

    if (geometryMap) {

      geometryMap.usedIn.delete(`${id}:${index}`);

      if (geometryMap.usedIn.size === 0) {

        geometryMap.geometry.dispose();

        if (geometryMap.edgeGeometry)
          geometryMap.edgeGeometry.dispose();

        delete this.geometryMap[geometryHash];

      }

    }

  }

  removeAllGeometryMap() {

    for (let geometryHash in this.geometryMap) {

      let geometryMap = this.geometryMap[geometryHash];

      if (geometryMap) {

        geometryMap.geometry.dispose();

        if (geometryMap.edgeGeometry)
          geometryMap.edgeGeometry.dispose();

      }

    }

    this.geometryMap = {};

  }

  removeAllMaterialMap() {

    for (let materialHash in this.materialMap) {

      let materialMap = this.materialMap[materialHash];

      if (materialMap) {

        materialMap.material.dispose();

      }

    }

    this.materialMap = {};

  }

  removeFromMaterialMap(id: string, index: number) {

    let model = this.models[id];
    let materialHash = model.subs[index].materialHash;
    let materialMap = this.materialMap[materialHash];

    if (materialMap) {

      materialMap.usedIn.delete(`${id}:${index}`);

      if (materialMap.usedIn.size === 0) {

        materialMap.material.dispose();

        delete this.materialMap[materialHash];

      }

    }

  }

  _clear() {

    if (this.selection.ids.length > 0)
      this.actionCallback({action: ACT_SELECT_MODELS, ids: [], commit: true});

    this.sceneMIM.remove(...Object.values(this.models).flatMap(m => [m.meshGroup, m.edgeGroup]));
    this.controlScene.remove(...Object.values(this.models).map(m => m.boundingBox));

    for (let id in this.models) {

      let model = this.models[id];
      model.meshGroup.remove(...model.meshGroup.children);
      model.edgeGroup.remove(...model.edgeGroup.children);

    }

    this.removeAllGeometryMap();
    this.removeAllMaterialMap();

    this.models = {};

    this.refreshImportIconVisibility();

    this._setMeasurements({}, true);
    this._setAnnotations({}, true);
    this._setMagnetMapping({});
    this._setLights({});
    this._setEditLevels([], true);
    this._lateRefresh();

  }

  _emptyCamera() {

    this.cameraTo.set(0, 0, 0);
    this.controlTargetTo.set(0, 0, 0);
    this.zoomTo = 0;

    this.instantUpdateCamera = true;
    this.adjustCameraControl();
    this.instantUpdateCamera = false;

  }

  _removeModel(id: string) {

    let model = this.models[id];

    if (model) {

      this.sceneMIM.remove(model.meshGroup, model.edgeGroup);
      this.controlScene.remove(model.boundingBox);

      model.meshGroup.remove(...model.meshGroup.children);
      model.edgeGroup.remove(...model.edgeGroup.children);

      for (let index = 0; index < model.subs.length; ++index) {

        this.removeFromGeometryMap(id, index);
        this.removeFromMaterialMap(id, index);

      }

      delete this.models[id];

      this.refreshImportIconVisibility();
    }

  }

  _removeModelLastMesh(id: string) {

    let model = this.models[id];

    if (model && model.subs.length > 0) {

      let lastIndex = model.subs.length - 1;
      let lastSub = model.subs[lastIndex];

      model.meshGroup.remove(lastSub.mesh);
      model.edgeGroup.remove(lastSub.edge);

      this.removeFromGeometryMap(id, lastIndex);
      this.removeFromMaterialMap(id, lastIndex);

      model.subs.pop();

    }

  }

  sceneCameraPositions: any[] = [];
  objectsVisibilities: any[] = [];
  lightsCastShadows: any[] = [];
  objectsCastShadows: any[] = [];
  backgrounds: any[] = [];

  pushSceneCameraPosition() {

    this.sceneCameraPositions.push({
      cameraPosition: this.orthoCamera.position.clone(),
      controlsTarget: this.orthoOrbitControl.target.clone(),
      cameraZoom: this.orthoCamera.zoom,
      cameraPositionTo: this.cameraTo.clone(),
      controlTargetTo: this.controlTargetTo.clone(),
      cameraZoomTo: this.zoomTo
    });

  }

  popSceneCameraPosition() {

    if (this.sceneCameraPositions.length > 0) {

      let position = this.sceneCameraPositions.pop();
      this.orthoCamera.position.copy(position.cameraPosition);
      this.orthoOrbitControl.target.copy(position.controlsTarget);
      this.orthoCamera.zoom = position.cameraZoom;
      this.cameraTo = position.cameraPositionTo;
      this.controlTargetTo = position.controlTargetTo;
      this.zoomTo = position.cameraZoomTo;

    }

  }

  pushObjectsVisibility() {

    let visibility: { [id: number]: boolean } = {};
    let annotateVisibility: { [id: number]: boolean } = {};
    let measureVisibility: { [id: number]: boolean } = {};

    this.scene.traverse((object: Object3D) => {

      visibility[object.id] = object.visible;

    });

    for (let annotateId in this.annotates) {

      annotateVisibility[annotateId] = this.annotates[annotateId].visible;

    }

    for (let measureId in this.measures) {

      measureVisibility[measureId] = this.measures[measureId].visible;

    }

    this.objectsVisibilities.push({
      visibility,
      annotateVisibility,
      measureVisibility
    });

  }

  popObjectsVisibility() {

    if (this.objectsVisibilities.length > 0) {

      let {visibility, annotateVisibility, measureVisibility} = this.objectsVisibilities.pop();

      this.scene.traverse((object: Object3D) => {

        if (visibility[object.id] !== undefined)
          object.visible = visibility[object.id];

      });

      for (let annotateId in this.annotates) {

        if (annotateVisibility[annotateId] !== undefined)
          this.annotates[annotateId].visible = annotateVisibility[annotateId];

      }

      for (let measureId in this.measures) {

        if (measureVisibility[measureId] !== undefined)
          this.measures[measureId].visible = measureVisibility[measureId];

      }

    }

  }

  pushLightsCastShadows() {

    let castShadow: { [id: number]: boolean } = {};

    this.scene.traverse((object: Object3D) => {

      //@ts-ignore
      if (object.isLight && object.castShadow !== undefined)
        castShadow[object.id] = object.castShadow;

    });

    this.lightsCastShadows.push(castShadow);

  }

  popLightsCastShadows() {

    if (this.lightsCastShadows.length > 0) {

      let castShadow = this.lightsCastShadows.pop();

      this.scene.traverse((object: Object3D) => {

        if (castShadow[object.id] !== undefined)
          object.castShadow = castShadow[object.id];

      });

    }

  }

  pushSceneDecorators() {

    let castShadow: { [id: number]: boolean } = {};

    this.scene.traverse((object: Object3D) => {

      // @ts-ignore
      if (object.isLight && object.castShadow !== undefined) {

        castShadow[object.id] = object.castShadow;

      }

    });

    this.objectsCastShadows.push(castShadow);
    this.backgrounds.push(this.envMapScene.background);

  }

  popSceneDecorators() {

    if (this.objectsCastShadows.length > 0) {

      let castShadow = this.objectsCastShadows.pop();

      this.scene.traverse((object: Object3D) => {

        if (castShadow[object.id] !== undefined)
          object.castShadow = castShadow[object.id];

      });

    }

    if (this.backgrounds.length > 0) {

      this.envMapScene.background = this.backgrounds.pop();

    }

  }

  changeLightsCastShadow(castShadow: boolean) {

    this.scene.traverse((object: Object3D) => {

      // @ts-ignore
      if (object.isLight && object.castShadow !== undefined) {

        object.castShadow = castShadow;

      }

    });

  }

  getCameraInfo(): ICameraInfo {

    return {
      position: [
        this.orthoCamera.position.x,
        this.orthoCamera.position.y,
        this.orthoCamera.position.z
      ],
      target: [
        this.orthoOrbitControl.target.x,
        this.orthoOrbitControl.target.y,
        this.orthoOrbitControl.target.z
      ],
      zoom: this.orthoCamera.zoom,
      height: this.container.clientHeight,
      width: this.container.clientWidth
    };

  }

  async getScreenshotWithMetaInfo(disposing?: boolean) {

    let oldViewType = this.viewType;

    this._setViewType(ViewTypes.Rendered);

    this.orbitControlChange = true;
    this.instantUpdateCamera = true;

    this.pushSceneCameraPosition();
    this.pushObjectsVisibility();

    this.floor.visible = false;
    this.transformControl.visible = false;
    this.snapControl.visible = false;
    this.magnetControl.visible = false;
    this.mirrorControl.visible = false;

    for (let lightId in this.lights) {
      if (this.lights[lightId].helper)
        this.lights[lightId].helper.visible = false;
    }

    for (let unitText of this.gridUnitTexts)
      unitText.visible = false;

    for (let id in this.measures)
      this.showMeasure(+id, false);

    for (let id in this.annotates)
      this.showAnnotate(+id, false);

    this.repositionCamera('preview');
    this.adjustCameraControl();
    this.alignControl.refresh();
    this.arrayControl.refresh();
    this.sculptControl.refresh();
    this.render();

    let {minX, minY, maxX, maxY} = this.getScope2DBounding();

    let maxHeight = maxY - minY;
    let maxWidth = maxX - minX;

    let ratio = this.height / this.width;

    if (maxWidth > maxHeight * 2 * ratio) {

      maxHeight = maxWidth / 2 / ratio;

    }

    if (maxHeight > maxWidth * 2 / ratio) {

      maxWidth = maxHeight / 2 * ratio;

    }

    let minHeight = maxHeight;
    let minWidth = maxWidth;

    if (maxHeight > maxWidth) {

      maxHeight = maxHeight / maxWidth;
      maxWidth = 1;

    } else {

      maxWidth = maxWidth / maxHeight;
      maxHeight = 1;

    }

    if (minHeight > minWidth) {

      minHeight = 1;
      minWidth = minWidth / minHeight;

    } else {

      minWidth = 1;
      minHeight = minHeight / minWidth;

    }

    let height = Math.round(this.height * maxHeight);
    let width = Math.round(this.width * maxWidth);

    let oldWidth = this.width, oldHeight = this.height;
    this.onContainerResize(width, height);
    this.render();

    let zoom = Math.max(maxHeight / minHeight, 1);
    let image = this.getImage();
    let imageData: ImageData = await convertURIToImageData(
      image,
      1,
      PROJECT_PREVIEW_WIDTH * zoom,
      PROJECT_PREVIEW_HEIGHT * zoom
    ) as ImageData;
    let data = convertImageDataToData(imageData);

    if (!disposing) {

      this.onContainerResize(oldWidth, oldHeight);

      this.transformControl.visible = true;
      this.snapControl.visible = true;
      this.magnetControl.visible = true;
      this.mirrorControl.visible = true;

      this.popObjectsVisibility();
      this.popSceneCameraPosition();

      this.adjustCameraControl();

      this.transformControl.refresh();
      this.snapControl.refresh();
      this.magnetControl.refresh();
      this.mirrorControl.refresh();
      this.alignControl.refresh();
      this.arrayControl.refresh();
      this.sculptControl.refresh();
      this.render();

      this.instantUpdateCamera = false;
      this.orbitControlChange = false;

      this._setViewType(oldViewType);

    }

    return {data, zoom};

  }

  async getCurrentScreenshotWithMetaInfo(disposing?: boolean) {

    this.orbitControlChange = true;
    this.instantUpdateCamera = true;

    this.pushSceneCameraPosition();
    this.pushObjectsVisibility();

    this.floor.visible = false;
    this.transformControl.visible = false;
    this.snapControl.visible = false;
    this.magnetControl.visible = false;
    this.mirrorControl.visible = false;

    for (let lightId in this.lights) {
      if (this.lights[lightId].helper)
        this.lights[lightId].helper.visible = false;
    }

    for (let unitText of this.gridUnitTexts)
      unitText.visible = false;

    for (let id in this.measures)
      this.showMeasure(+id, false);

    for (let id in this.annotates)
      this.showAnnotate(+id, false);

    let {minX, minY, maxX, maxY} = this.getScope2DBounding();

    let maxHeight = maxY - minY;
    let maxWidth = maxX - minX;
    let minimumRatio = 0.75;

    let ratio = Math.min(Math.max(maxWidth / maxHeight, minimumRatio), 1 / minimumRatio);
    let zoom = 1;
    let imageData: ImageData = await cropURIToImageData(this.getImage(), ratio) as ImageData;
    let data = convertImageDataToData(imageData);

    if (!disposing) {

      this.transformControl.visible = true;
      this.snapControl.visible = true;
      this.magnetControl.visible = true;
      this.mirrorControl.visible = true;

      this.popObjectsVisibility();
      this.popSceneCameraPosition();
      this.render();

      this.instantUpdateCamera = false;
      this.orbitControlChange = false;

    }

    return {data: data, zoom: zoom};

  }

  getScope2DBounding() {
    let maxX = -Infinity;
    let minX = +Infinity;
    let maxY = -Infinity;
    let minY = +Infinity;

    this.scope.forEach(id => {

      if (this.models[id]) {

        for (let sub of this.models[id].subs) {

          let mesh = sub.mesh;
          let position = (mesh.geometry as BufferGeometry).attributes.position;

          for (let i = 0, l = position.count * 3; i < l; i += 3) {

            let vertex = new Vector3(position.array[i], position.array[i + 1], position.array[i + 2]);
            vertex.applyMatrix4(mesh.matrixWorld);
            vertex = vertex.project(this.orthoCamera);

            if (!isNaN(vertex.x)) {
              maxX = Math.max(vertex.x, maxX);
              minX = Math.min(vertex.x, minX);
            }

            if (!isNaN(vertex.y)) {
              maxY = Math.max(vertex.y, maxY);
              minY = Math.min(vertex.y, minY);
            }

          }

        }

      }

    });

    if (maxX <= minX || maxY <= minY) {

      maxX = maxY = 1;
      minX = minY = -1;

    }

    return {minX, minY, maxX, maxY};
  }

  getImage(strMime = 'image/png', transparent?: boolean) {

    if (this.viewType === ViewTypes.ServerRendered && this.delegate && this.delegate.overlayCanvasElement)
      return this.delegate.overlayCanvasElement.toDataURL(strMime);

    return this.getImageInternal(strMime, transparent);

  }

  getImageInternal(strMime = 'image/png', transparent?: boolean) {

    let imgData = '';

    try {

      if (transparent) {

        let oldEnabled = this.envMapEnabled;
        let oldGridVisible = this.floor.visible;
        this.envMapEnabled = false;
        this.floor.visible = false;

        this.render();

        this.envMapEnabled = oldEnabled;
        this.floor.visible = oldGridVisible;

      } else {

        this.render();

      }

      let can = document.createElement('canvas');
      can.width = this.width * this.pixelRatio;
      can.height = this.height * this.pixelRatio;

      document.body.appendChild(can);

      let ctx3 = can.getContext('2d');

      if (ctx3) {

        ctx3.drawImage(this.envMapRenderer.domElement, 0, 0);
        ctx3.drawImage(this.renderer.domElement, 0, 0);

      }

      imgData = can.toDataURL(strMime);
      document.body.removeChild(can);


    } catch (e) {

      console.warn(e);

    }

    return imgData;
  }

  saveAsImage(strMime = 'image/png', transparent?: boolean) {

    let imgData = this.getImage(strMime, transparent);
    let extension = strMime.split('/')[1] || 'png';

    if (imgData)
      Editor3d.saveFile(imgData.replace(strMime, 'image/octet-stream'), `Rendered-${moment().format()}.${extension}`);

  }

  getMinOrientOnPlane(direction2d: string) {

    let center = new Vector3(0, 0, -1).unproject(this.getCamera());
    let viewPointVectors: { [key: string]: Vector3 } = {
      '-x': new Vector3(-1, 0, -1).unproject(this.getCamera()).sub(center).normalize(),
      '+y': new Vector3(0, 1, -1 + EPS).unproject(this.getCamera()).sub(center).normalize(),
      '-y': new Vector3(0, -1, -1 - EPS).unproject(this.getCamera()).sub(center).normalize(),
      '+x': new Vector3(1, 0, -1).unproject(this.getCamera()).sub(center).normalize()
    };

    let viewPointVector = viewPointVectors[direction2d];

    if (viewPointVector === undefined)
      return '+x';

    let orientVectors: { [key: string]: Vector3 } = {
      '+x': new Vector3(1, 0, 0),
      '-x': new Vector3(-1, 0, 0),
      '+y': new Vector3(0, 1, 0),
      '-y': new Vector3(0, -1, 0)
    };

    let minAngle = +Infinity;
    let minOrient = '';

    for (let orient in orientVectors) {

      let v = orientVectors[orient];
      let angle = v.angleTo(viewPointVector);

      if (angle < minAngle) {

        minAngle = angle;
        minOrient = orient;

      }

    }

    return minOrient;

  }

  moveOneStepTo(direction3d: string) {

    let box = this.selection.globalBoundingBox;
    let snapIncrement = 1;

    if (direction3d === '+x') {

      let offset = snapIncrement;
      let gap = Math.abs((box.max.x % snapIncrement + snapIncrement) % snapIncrement);

      if (gap >= EPS && snapIncrement - gap >= EPS)
        offset = snapIncrement - gap;

      this.selection.group.applyMatrix4(new Matrix4().makeTranslation(offset, 0, 0));

    } else if (direction3d === '-x') {

      let offset = -snapIncrement;
      let gap = Math.abs((box.min.x % snapIncrement + snapIncrement) % snapIncrement);

      if (gap >= EPS && snapIncrement - gap >= EPS)
        offset = -gap;

      this.selection.group.applyMatrix4(new Matrix4().makeTranslation(offset, 0, 0));

    } else if (direction3d === '+y') {

      let offset = snapIncrement;
      let gap = Math.abs((box.max.y % snapIncrement + snapIncrement) % snapIncrement);

      if (gap >= EPS && snapIncrement - gap >= EPS)
        offset = snapIncrement - gap;

      this.selection.group.applyMatrix4(new Matrix4().makeTranslation(0, offset, 0));

    } else if (direction3d === '-y') {

      let offset = -snapIncrement;
      let gap = Math.abs((box.min.y % snapIncrement + snapIncrement) % snapIncrement);

      if (gap >= EPS && snapIncrement - gap >= EPS)
        offset = -gap;

      this.selection.group.applyMatrix4(new Matrix4().makeTranslation(0, offset, 0));

    } else if (direction3d === '+z') {

      let offset = snapIncrement;
      let gap = Math.abs(((box.max.z - this.baseZ) % snapIncrement + snapIncrement) % snapIncrement);

      if (gap >= EPS && snapIncrement - gap >= EPS)
        offset = snapIncrement - gap;

      this.selection.group.applyMatrix4(new Matrix4().makeTranslation(0, 0, offset));

    } else if (direction3d === '-z') {

      let offset = -snapIncrement;
      let gap = Math.abs(((box.min.z - this.baseZ) % snapIncrement + snapIncrement) % snapIncrement);

      if (gap >= EPS && snapIncrement - gap >= EPS)
        offset = -gap;

      this.selection.group.applyMatrix4(new Matrix4().makeTranslation(0, 0, offset));

    }

    this._finishTransformation();

  }

  getCubeDirections() {

    let cubeDirections: { [key: string]: string } = {};
    for (let direction of ['left', 'right', 'top', 'bottom'])
      cubeDirections[direction] = this.getNearestOrientFaceFromDirection(direction);

    return cubeDirections;

  }

  _refreshScopes() {

    let scope = new Set<string>();
    let scopeWithPrev = new Set<string>();

    if (this.selection.editLevels.length === 0) {

      let innerIdSet = new Set(Object.values(this.models).flatMap(model => model.prevIds));
      scope = new Set(Object.keys(this.models).filter(id => !innerIdSet.has(id)));
      scopeWithPrev = new Set(Object.keys(this.models).filter(id => !innerIdSet.has(id)));

    } else {

      let lastLevel = this.selection.editLevels[this.selection.editLevels.length - 1];
      scope = new Set(this.models[lastLevel] ? this.models[lastLevel].prevIds : []);

      for (let level of this.selection.editLevels) {

        if (this.models[level])
          this.models[level].prevIds.forEach(scopeWithPrev.add, scopeWithPrev);

      }
    }

    this.scope = scope;
    this.scopeWithPrev = scopeWithPrev;

    this.selectionBox.selectionScope = Array.from(this.scope);

  }

  refreshVisibilities() {

    for (let id in this.models) {

      let model = this.models[id];

      if (model) {

        let modelVisible = this.isModelVisible(id);

        if (model.meshGroup.visible && !modelVisible) {

          this.sceneMIM.remove(model.meshGroup);
          model.meshGroup.visible = false;

        } else if (!model.meshGroup.visible && modelVisible) {

          this.sceneMIM.add(model.meshGroup);
          model.meshGroup.visible = true;

        }

        if (model.edgeGroup.visible && !modelVisible) {

          this.sceneMIM.remove(model.edgeGroup);
          model.edgeGroup.visible = false;

        } else if (!model.edgeGroup.visible && modelVisible) {

          this.sceneMIM.add(model.edgeGroup);
          model.edgeGroup.visible = true;

        }

      }

    }

  }

  isModelVisible(id: string) {

    if (this.models[id] === undefined)
      return false;

    if (this.selection.editLevels.length === 0) {

      return this.models[id].visible;

    } else {

      if (this.selection.editLevels.includes(id)) {

        return false;

      } else if (this.scopeWithPrev.has(id)) {

        return true;

      } else {

        return this.models[id].visible;

      }

    }

  }

  refreshMaterials(tool?: string, ids?: string[]) {

    for (let id in this.models) {

      let model = this.models[id];
      let modelVisible = this.isModelVisible(id);

      for (let i = 0; i < model.subs.length; ++i) {

        let edge = model.subs[i].edge;

        if (modelVisible &&
          (this.viewType === ViewTypes.Ghosted ||
            this.viewType === ViewTypes.Wireframe ||
            model.subs[i].materialHash.startsWith('void') ||
            !this.scopeWithPrev.has(id) ||
            !model.subs[i].valid
          )
        ) {

          if (edge && !edge.visible) {
            model.edgeGroup.add(edge);
            edge.visible = true;
          }

        } else {

          if (edge && edge.visible) {
            model.edgeGroup.remove(edge);
            edge.visible = false;
          }

        }

      }

    }

    let selectionIdSet = new Set(ids !== undefined ? ids : this.selection.ids);
    let selectionTool = tool !== undefined ? tool : this.selection.tool;

    for (let id in this.models) {

      let model = this.models[id];
      let hasHeatmap = lod.every(model.subs, sub => sub.heatmapData && sub.heatmapData.length > 0);

      for (let i = 0; i < model.subs.length; ++i) {

        let mesh = model.subs[i].mesh;
        let edge = model.subs[i].edge;
        let heatmap = model.heatmap;
        let renderedObjectType = model.subs[i].renderedObjectType;
        let meshMaterial, edgeMaterial;

        edgeMaterial = model.subs[i].valid ? this.sharedMaterials[SharedMaterial.EdgeLine] : this.sharedMaterials[SharedMaterial.InvalidEdgeLine];

        if (this.scope.has(id)) {

          if (selectionTool !== Tools.Sculpt || selectionIdSet.has(id)) {

            if (heatmap && hasHeatmap) {

              meshMaterial = this.sharedMaterials[SharedMaterial.Heatmap];

            } else {

              if (this.viewType !== ViewTypes.Rendered && this.viewType !== ViewTypes.ServerRendered) {

                meshMaterial = this.getCurrentMaterial(this.viewType, renderedObjectType);

              } else {

                meshMaterial = this.materialMap[model.subs[i].materialHash].material;

              }

            }

            mesh.castShadow = true;
            mesh.receiveShadow = true;

          } else {

            meshMaterial = this.getCurrentMaterial(SharedMaterial.OutOfEdit, renderedObjectType);
            mesh.castShadow = false;
            mesh.receiveShadow = false;

          }

        } else {

          meshMaterial = this.getCurrentMaterial(SharedMaterial.OutOfEdit, renderedObjectType);
          mesh.castShadow = false;
          mesh.receiveShadow = false;

        }

        if (mesh.material !== meshMaterial) {
          mesh.material = meshMaterial;
          this.sceneMIM.needsUpdate = true;
        }

        if (edge.material !== edgeMaterial) {
          edge.material = edgeMaterial;
          this.sceneMIM.needsUpdate = true;
        }

      }

    }

  }

  onTransformControlChange = (evt: { type: string, target: TransformControl }) => {

    this._finishTransformation();

  };

  onSnapControlChange = (evt: { type: string, target: SnapControl }) => {

    this._finishTransformation();
    this.actionCallback({
      action: ACT_CHANGE_SNAP_CONTROL,
      ids: this.selection.ids,
      destId: this.snapControl.targetId || ''
    });

  };

  onMagnetControlChange = (evt: { type: string, target: SnapControl }) => {

    this._finishTransformation();

  };

  onMirrorControlChange = (evt: { type: string, target: MirrorControl }) => {

    this.actionCallback({
      action: ACT_CHANGE_MIRROR_CONTROL,
      distance: evt.target.distance,
      currentAxis: evt.target.currentAxis
    });

  };

  onAlignControlChange = (evt: { type: string, target: AlignControl }) => {

    this._finishTransformation();

  };

  onArrayControlChange = (evt: { type: string, target: ArrayControl }) => {

    this.actionCallback({
      action: ACT_CHANGE_ARRAY_CONTROL,
      gapSize: getVec3FromThreeVector(evt.target.gapSize, true),
      arraySize: getVec3FromThreeVector(evt.target.arraySize, true),
      itemOffset: getVec3FromThreeVector(evt.target.itemOffset, true)
    });

  };

  onSculptControlChange = (evt: { type: string, replace?: boolean, target: SculptControl }) => {

    this._commitSculpt(evt.replace);

  };


  onSculptControlPork = (evt: { type: string, target: SculptControl }) => {

    this.actionCallback({action: ACT_SCULPT_PORK});

  };

  onSculptControlMaterialChange = (evt: { type: string, target: SculptControl }) => {

    let needUpdateAfterRender = false;

    for (let i = 0; i < this.selection.sMeshes.length; ++i) {
      let id = this.selection.ids[i];
      let mesh = this.selection.meshes[i];
      let wfMesh = this.selection.wfMeshes[i];

      let rendering = this.sculptControl._rendering;

      if (this.viewType === ViewTypes.Rendered) {

        if (this.selection.ids.length > 0) {

          let material = this.materialMap[this.models[id].subs[0].materialHash].material.clone();
          material.vertexColors = true;
          material.onBeforeCompile = maskingMaterialBeforeCompile;
          material.needsUpdate = true;
          mesh.material = material;

        }

      } else if (this.viewType === ViewTypes.Shaded) {

        mesh.material = this.sharedMaterials[SharedMaterial.Shaded];

      } else {

        mesh.material = this.createMatcapMaskingMaterial(rendering.matcapImage);
        needUpdateAfterRender = true;

      }

      if (rendering.transparency === 0) {

        (mesh.material as Material).opacity = 1;
        (mesh.material as Material).transparent = false;

        (wfMesh.material as Material).opacity = 1;
        (wfMesh.material as Material).transparent = false;

      } else {

        (mesh.material as Material).opacity = 1 - rendering.transparency;
        (mesh.material as Material).transparent = true;

        (wfMesh.material as Material).opacity = 1 - rendering.transparency;
        (wfMesh.material as Material).transparent = true;

      }

      wfMesh.visible = rendering.wireframe || this.viewType === ViewTypes.Wireframe;

      this.setNeedsUpdate();
      this.sceneMIM.needsUpdate = true;
    }

    if (needUpdateAfterRender) {
      requestAnimationFrame(() => {
        this.setNeedsUpdate();
      });
    }

  };

  onSculptControlConfigChange = (evt: { type: string, target: SculptControl }) => {

    this.actionCallback({action: ACT_UPDATE_SCULPT_CONFIG, config: evt.target.collectConfig()});

  };

  onSculptControlRender = (evt: { type: string, target: SculptControl }) => {

    this.setNeedsUpdate();

  };

  onSculptControlMeshExternalOperation = (evt: { type: string, operation: string, index: number, target: SculptControl }) => {

    if (evt.operation === 'remesh')
      this.actionCallback({action: ACT_SCULPT_REMESH, index: evt.index, remaining: false});
    else if (evt.operation === 'remesh remaining')
      this.actionCallback({action: ACT_SCULPT_REMESH, index: evt.index, remaining: true});
    else if (evt.operation === 'mirror')
      this.actionCallback({action: ACT_SCULPT_MIRROR, index: evt.index});

  };

  onMinorOrbitControlChange = (evt: { type: string, target: OrbitControl, action: string, state?: number }) => {

    if (this.minorCamera)
      this.actionCallback({action: ACT_CHANGE_MINOR_VIEW_ZOOM, zoom: this.minorCamera.zoom});

  };

  onOrbitControlChange = (evt: { type: string, target: OrbitControl, action: string, state?: number }) => {

    let zoom = 1 / this.orthoCamera.zoom;

    if (evt.type === 'start') {

      if (evt.action === 'drag') {

        if (this.sculptControl.visible) {

          this.sculptControl.enabled = false;

          if ((evt.state === 0 || evt.state === 3) && this.sculptControl.cameraTargetOnPick) {

            this.sculptControlTargetTo = this.sculptControl.getPickingPosition();

            if (this.sculptControlTargetTo) {

              let projected = this.sculptControlTargetTo.clone().project(this.orthoCamera);
              this.cameraOffset = new Vector2(projected.x, projected.y);

            }

          }

        }

      }

      this.orbitControlChange = true;

    } else if (evt.type === 'end') {

      if (evt.action === 'drag') {

        if (this.sculptControl.visible) {

          this.sculptControl.enabled = this.editControlsEnabled;
          this.sculptControl.preUpdate();

          if ((evt.state === 0 || evt.state === 3) && this.sculptControl.cameraTargetOnPick) {

            this.sculptControlTargetTo = undefined;
            this.cameraOffset = undefined;

          }

        }

      }

      this.orbitControlChange = false;
      this.cameraTo = this.orthoCamera.position.clone();
      this.controlTargetTo = this.orthoOrbitControl.target.clone();
      this.zoomTo = this.orthoCamera.zoom;
      this.actionCallback({
        action: ACT_UPDATE_CAMERA,
        camera: this.cameraTo.toArray(),
        controlTarget: this.controlTargetTo.toArray(),
        zoom: this.zoomTo
      });

    } else if (evt.type === 'change') {

      if (evt.action !== 'drag') {

        this.sculptControl.preUpdate();

      }

    }

    if (this.selection.tool === Tools.Measure || lod.some(Object.values(this.measures), 'visible')) {

      this.refreshMeasurements();

    }

    if (this.selection.tool === Tools.Annotate || lod.some(Object.values(this.annotates), 'visible')) {

      this.refreshAnnotations();

    }

    if (Object.keys(this.placeholderBoxes).length > 0) {

      this.refreshPlaceholderBoxes();

    }

    if (this.gridShowUnits) {

      this.resizeUnitTexts();

    }

    if (this.annotateInputElement && Editor3d.isElementVisible(this.annotateInputElement)) {

      this.refreshAnnotateInputElement();

    }

    if (Object.keys(this.cursors).length > 0) {

      this.refreshCursors();

    }

    this.refreshDPointScale();

    this.basePlane.position.z = this.baseZ - zoom;

  };

  selectAll() {

    let ids = Array.from(this.scope);
    this.actionCallback({
      action: ACT_SELECT_MODELS,
      ids: this.filterSelectableIds(ids),
      commit: true
    });

  }

  lift(distance: number) {

    if (this.measureUnit === MeasureUnit.Inch)
      distance *= 25.4;

    if (this.selection.ids.length > 0) {

      this.selection.group.applyMatrix4(new Matrix4().makeTranslation(0, 0, distance));
      this._finishTransformation();

    }

  }

  move(direction: string, distance: number) {

    if (this.measureUnit === MeasureUnit.Inch)
      distance *= 25.4;

    if (this.selection.ids.length > 0) {

      switch (direction) {
        case 'x':
        case '+x':
          this.selection.group.applyMatrix4(new Matrix4().makeTranslation(distance, 0, 0));
          break;
        case 'y':
        case '+y':
          this.selection.group.applyMatrix4(new Matrix4().makeTranslation(0, distance, 0));
          break;
        case 'z':
        case '+z':
          this.selection.group.applyMatrix4(new Matrix4().makeTranslation(0, 0, distance));
          break;
        case '-x':
          this.selection.group.applyMatrix4(new Matrix4().makeTranslation(-distance, 0, 0));
          break;
        case '-y':
          this.selection.group.applyMatrix4(new Matrix4().makeTranslation(0, -distance, 0));
          break;
        case '-z':
          this.selection.group.applyMatrix4(new Matrix4().makeTranslation(0, 0, -distance));
          break;
        default:
          break;
      }

    }

    this._finishTransformation();

  }

  scale(direction: string, scale: number) {

    if (this.selection.ids.length > 0) {
      let scaleMatrix = new Matrix4();

      switch (direction) {
        case 'x':
          scaleMatrix = new Matrix4().makeScale(scale, 1, 1);
          break;
        case 'y':
          scaleMatrix = new Matrix4().makeScale(1, scale, 1);
          break;
        case 'z':
          scaleMatrix = new Matrix4().makeScale(1, 1, scale);
          break;
        default:
          break;
      }

      let objectMatrix = new Matrix4();
      let tempMatrix = new Matrix4();

      let objectPosition = new Vector3();
      let objectScale = new Vector3();
      let objectQuaternion = new Quaternion();

      objectMatrix.copy(this.selection.group.matrix);

      objectMatrix.decompose(
        objectPosition,
        objectQuaternion,
        objectScale
      );

      if (this.selection.space === 'world') {

        this.selection.group.matrix = objectMatrix.premultiply(tempMatrix.makeTranslation(-objectPosition.x, -objectPosition.y, -objectPosition.z))
          .premultiply(scaleMatrix)
          .premultiply(tempMatrix.makeTranslation(objectPosition.x, objectPosition.y, objectPosition.z));

      } else {

        this.selection.group.matrix = objectMatrix.premultiply(tempMatrix.makeTranslation(-objectPosition.x, -objectPosition.y, -objectPosition.z))
          .premultiply(tempMatrix.makeRotationFromQuaternion(objectQuaternion.clone().invert()))
          .premultiply(scaleMatrix)
          .premultiply(tempMatrix.makeRotationFromQuaternion(objectQuaternion))
          .premultiply(tempMatrix.makeTranslation(objectPosition.x, objectPosition.y, objectPosition.z));

      }

    }

    this._finishTransformation();

  }

  onGlobalKeyDown = (event: KeyboardEvent) => {

    if (this.offsetInputElement && Editor3d.isElementVisible(this.offsetInputElement)) {

      switch (event.code) {
        case 'ArrowLeft':
          this.offsetInputElement.focus();
          this.offsetInputElement.value = (parseFloat(this.offsetInputElement.value) + this.snapIncrement).toFixed(2);
          this.offsetInputElement.blur();
          return;
        case 'ArrowRight':
          this.offsetInputElement.focus();
          this.offsetInputElement.value = (parseFloat(this.offsetInputElement.value) - this.snapIncrement).toFixed(2);
          this.offsetInputElement.blur();
          return;
      }

    }

    if (document.activeElement &&
      (document.activeElement.tagName.toLowerCase() === 'body' ||
        document.activeElement.tagName.toLowerCase() === 'button' ||
        document.activeElement.id.toLowerCase() === 'three' ||
        document.activeElement.className.indexOf('MuiSlider') >= 0 ||
        document.activeElement.getAttribute('role') === 'dialog')
    ) {

      this.actionCallback({action: ACT_KEYDOWN, code: event.code});

      if (this.sculptControl.visible) {

        switch (event.code) {
          case 'ShiftLeft':
          case 'ShiftRight':
            if (!event.repeat)
              this.sculptControl.setKeyState(SculptKeyCodes.Smooth, true);
            break;
          case 'AltLeft':
          case 'AltRight':
            if (!event.repeat)
              this.sculptControl.setKeyState(SculptKeyCodes.Negative, true);
            break;
          case 'ControlLeft':
          case 'ControlRight':
          case 'MetaLeft':
          case 'MetaRight':
            if (!event.repeat)
              this.sculptControl.setKeyState(SculptKeyCodes.Disable, true);
            break;
          case 'KeyD':
            if (!event.repeat)
              this.sculptControl.setKeyState(SculptKeyCodes.Drag, true);
            return;
          case 'KeyX':
            if (!event.repeat)
              this.sculptControl.setKeyState(SculptKeyCodes.Radius, true);
            return;
          case 'KeyC':
            if (!event.repeat)
              this.sculptControl.setKeyState(SculptKeyCodes.Intensity, true);
            return;
          case 'KeyV':
            if (!event.repeat)
              this.sculptControl.setKeyState(SculptKeyCodes.Mask, true);
            return;
          case 'Space':
            if (!event.repeat)
              this.actionCallback({action: ACT_SHOW_SCULPT_TOOL, visible: true});

            event.preventDefault();
            event.stopPropagation();
            return;
          case 'Equal':
          case 'NumpadAdd':
            this.sculptControl.multiplyToolRadius(6.0 / 5);
            return;
          case 'Minus':
          case 'NumpadSubtract':
            this.sculptControl.multiplyToolRadius(5.0 / 6);
            return;
          case 'BracketRight':
            this.sculptControl.multiplyToolIntensity(6.0 / 5);
            return;
          case 'BracketLeft':
            this.sculptControl.multiplyToolIntensity(5.0 / 6);
            return;
        }

      }

      switch (event.code) {
        case 'KeyZ':
          if ((event.ctrlKey || event.metaKey) && !event.altKey) {
            event.preventDefault();
            event.stopPropagation();
            if (!event.repeat) {
              if (!event.shiftKey) {
                this.actionCallback({action: ACT_UNDO, ids: this.selection.ids});
              } else {
                this.actionCallback({action: ACT_REDO, ids: this.selection.ids});
              }
            }
          }
          return;
        case 'Enter':
        case 'NumpadEnter':
          if (!event.repeat) {
            if (this.selection.tool === Tools.Measure && this.measureState !== MeasureControlStates.SelectingFrom) {

              this.cancelMeasure();
              this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

            } else if (this.selection.tool === Tools.Annotate && this.annotateState !== AnnotateControlStates.SelectingTarget && this.annotateState !== AnnotateControlStates.InputtingText) {

              this.cancelAnnotate();
              this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

            } else if (this.selection.tool === Tools.Polyline) {

              this.commitPolyline();
              this.initDrawingPolyline();

            } else if (this.selection.tool === Tools.Spline) {

              this.commitCurve();
              this.initDrawingCurve();

            } else if (this.selection.tool === Tools.Gumball) {

              this.actionCallback({action: ACT_CHATBOT, command: 'confirm'});

            } else if (
              // this.selection.tool === Tools.Measure ||
              // this.selection.tool === Tools.Sculpt ||
              // this.selection.tool === Tools.Annotate ||
              // this.selection.tool === Tools.Array ||
              // this.selection.tool === Tools.Mirror ||
              this.selection.tool === Tools.Magnet ||
              this.selection.tool === Tools.Snap ||
              this.selection.tool === Tools.Orient ||
              this.selection.tool === Tools.Align
            ) {

              this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

            } else {

              this.actionCallback({action: ACT_CHATBOT, command: 'confirm'});

            }
          }

          event.preventDefault();

          return;
        case 'Escape':
          if (!event.repeat) {
            if (this.selection.tool === Tools.Measure && this.measureState !== MeasureControlStates.SelectingFrom) {

              this.cancelMeasure();
              this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

            } else if (this.selection.tool === Tools.Annotate && this.annotateState !== AnnotateControlStates.SelectingTarget && this.annotateState !== AnnotateControlStates.InputtingText) {

              this.cancelAnnotate();
              this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

            } else if (this.selection.tool === Tools.Polyline) {

              this.commitPolyline();
              this.initDrawingPolyline();

            } else if (this.selection.tool === Tools.Spline) {

              this.commitCurve();
              this.initDrawingCurve();

            } else if (this.selection.tool === Tools.Gumball) {

              if (this.selection.ids.length > 0) {

                if (this.transformControl.isDragging) {

                  this.selection.group.matrix.copy(this.selection.orgGroupMatrix);
                  this._cancelTransformation();
                  this._startTransformation();

                } else {

                  this.actionCallback({action: ACT_SELECT_MODELS, ids: [], commit: true});

                }


              } else if (this.selection.editLevels.length > 0) {

                let level = this.selection.editLevels[this.selection.editLevels.length - 1];
                this.actionCallback({action: ACT_POP_EDIT_LEVEL, id: level});

              } else {

                this.actionCallback({action: ACT_CHATBOT, command: 'cancel'});

              }

            } else if (this.selection.tool === Tools.Snap) {

              if (this.snapControl.isDragging) {

                this.selection.group.matrix.copy(this.selection.orgGroupMatrix);
                this._cancelTransformation();
                this._startTransformation();

              } else {

                this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

              }

            } else if (
              this.selection.tool === Tools.Magnet ||
              this.selection.tool === Tools.Orient ||
              this.selection.tool === Tools.Align
            ) {

              this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

            } else {

              this.actionCallback({action: ACT_CHATBOT, command: 'cancel'});

            }
          }

          event.preventDefault();

          return;
        case 'Delete':
        case 'Backspace':
          if (!event.repeat) {

            if (this.measureState !== MeasureControlStates.SelectingFrom) {

              this.cancelMeasure();
              this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

            } else if (this.annotateState !== AnnotateControlStates.SelectingTarget) {

              this.cancelAnnotate();
              this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

            } else {

              if (this.selection.tool === Tools.Gumball)
                this.actionCallback({action: ACT_DELETE, ids: this.selection.ids});

            }

          }
          return;
      }

      if (this.offsetInputElement && Editor3d.isElementVisible(this.offsetInputElement)) {

      } else {

        if (this.transformControl.visible) {

          if (!(event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            switch (event.code) {
              case 'ArrowLeft':
                this.moveOneStepTo(this.getMinOrientOnPlane('-x'));
                return;
              case 'ArrowUp':
                this.moveOneStepTo(this.getMinOrientOnPlane('+y'));
                return;
              case 'ArrowRight':
                this.moveOneStepTo(this.getMinOrientOnPlane('+x'));
                return;
              case 'ArrowDown':
                this.moveOneStepTo(this.getMinOrientOnPlane('-y'));
                return;
            }

          } else if (!(event.ctrlKey || event.metaKey) && event.shiftKey && !event.altKey) {

            switch (event.code) {
              case 'ArrowUp':
                this.moveOneStepTo('+z');
                return;
              case 'ArrowDown':
                this.moveOneStepTo('-z');
                return;
            }

          }

        }

      }

      switch (event.code) {
        case 'KeyA':
          if ((event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (!event.repeat)
              this.selectAll();

            event.preventDefault();
            event.stopPropagation();
            return;

          }
          break;
        case 'KeyB':
          if (!(event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (this.selection.tool === Tools.Snap) {

              if (!event.repeat)
                this.actionCallback({action: ACT_TOGGLE_SNAP_CONFIG, config: 'box'});

              return;

            }

          }
          break;
        case 'KeyC':
          if (!(event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (this.tabKeyState) {

              if (!event.repeat)
                this.applyCenterOnFloor();

              this.tabHotKeyUsed = true;
              return;

            }

          } else if ((event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (this.selection.ids.length > 0) {

              if (!event.repeat)
                this.actionCallback({action: ACT_COPY, ids: this.selection.ids});

              return;

            }

          }
          break;
        case 'KeyD':
          if (!(event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (this.tabKeyState) {

              if (!event.repeat)
                this.applyDropOnObject();

              this.tabHotKeyUsed = true;
              return;

            }

          } else if ((event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (this.selection.tool === Tools.Sculpt) {

              if (!event.repeat)
                this.sculptControl.setConfig({rendering: {wireframe: !this.sculptControl._rendering.wireframe}});

              return;

            } else {

              if (!event.repeat) {

                for (let id of this.selection.ids)
                  this.toggleWireFrame(id);

              }

              return;

            }

          }
          break;
        case 'KeyE':
          if ((event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (!event.repeat) {

              if (this.stats)
                this.disposeStats();
              else
                this.initStats();

            }

          }
          break;
        case 'KeyF':
          if (!(event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (this.tabKeyState) {

              if (!event.repeat)
                this.applyDrop();

              this.tabHotKeyUsed = true;
              return;

            } else if (this.selection.tool === Tools.Snap) {

              if (!event.repeat)
                this.actionCallback({action: ACT_TOGGLE_SNAP_CONFIG, config: 'face'});

              return;

            }

          }
          break;
        case 'KeyG':
          if ((event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (this.selection.ids.length > 1) {

              if (!event.repeat)
                this.actionCallback({action: ACT_GROUP, ids: this.selection.ids});

            } else {

              if (!event.repeat)
                this.actionCallback({action: ACT_UNGROUP, ids: this.selection.ids});

            }

            return;

          }

          if (!(event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (this.selection.tool === Tools.Snap) {

              if (!event.repeat)
                this.actionCallback({action: ACT_TOGGLE_SNAP_CONFIG, config: 'grid'});

              return;

            }

          }
          break;
        case 'KeyV':
          if ((event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (!event.repeat)
              this.actionCallback({action: ACT_PASTE});

            return;

          }

          if (!(event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (this.selection.tool === Tools.Snap) {

              if (!event.repeat)
                this.actionCallback({action: ACT_TOGGLE_SNAP_CONFIG, config: 'vertex'});

              return;

            }

          }
          break;
        case 'Tab':
          if (!(event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (!ExclusiveTools.includes(this.selection.tool as Tools)) {

              this.tabKeyState = true;
              this.tabHotKeyUsed = false;

            }

            event.preventDefault();
            event.stopPropagation();
            return;

          }
          break;
      }

      if (!(event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

        this.actionCallback({action: ACT_CHATBOT, code: event.code});

      }

    }

  };

  onGlobalKeyUp = (event: KeyboardEvent) => {

    if (document.activeElement &&
      (document.activeElement.tagName.toLowerCase() === 'body' ||
        document.activeElement.tagName.toLowerCase() === 'button' ||
        document.activeElement.id.toLowerCase() === 'three' ||
        document.activeElement.className.indexOf('MuiSlider') >= 0 ||
        document.activeElement.getAttribute('role') === 'dialog')
    ) {

      this.actionCallback({action: ACT_KEYUP, code: event.code});

      if (this.sculptControl.visible) {

        switch (event.code) {
          case 'ShiftLeft':
          case 'ShiftRight':
            this.sculptControl.setKeyState(SculptKeyCodes.Smooth, false);
            break;
          case 'AltLeft':
          case 'AltRight':
            this.sculptControl.setKeyState(SculptKeyCodes.Negative, false);
            break;
          case 'ControlLeft':
          case 'ControlRight':
          case 'MetaLeft':
          case 'MetaRight':
            this.sculptControl.setKeyState(SculptKeyCodes.Disable, false);
            break;
          case 'KeyD':
            this.sculptControl.setKeyState(SculptKeyCodes.Drag, false);
            return;
          case 'KeyX':
            this.sculptControl.setKeyState(SculptKeyCodes.Radius, false);
            return;
          case 'KeyC':
            this.sculptControl.setKeyState(SculptKeyCodes.Intensity, false);
            return;
          case 'KeyV':
            this.sculptControl.setKeyState(SculptKeyCodes.Mask, false);
            return;
          case 'Space':
            this.actionCallback({action: ACT_SHOW_SCULPT_TOOL, visible: false});
            event.preventDefault();
            event.stopPropagation();
            return;
        }

      }

      switch (event.code) {
        case 'Tab':
          if (!(event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey) {

            if (!this.tabHotKeyUsed) {

              if (this.selection.tool === Tools.Snap) {

                this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

              } else if (!ExclusiveTools.includes(this.selection.tool as Tools)) {

                this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Snap});

              }

            }

            this.tabKeyState = false;
            this.tabHotKeyUsed = false;

            event.preventDefault();
            event.stopPropagation();
            return;

          }
          break;
      }

    }

  };

  zoomMinorView = (factor: number) => {

    if (this.minorCamera) {

      this.minorCamera.zoom *= factor;
      this.minorCamera.updateProjectionMatrix();
      this.actionCallback({action: ACT_CHANGE_MINOR_VIEW_ZOOM, zoom: this.minorCamera.zoom});

    }

  };

  onEnterView = () => {

    this.elemRect = this.delegate ? this.delegate.domElement.getBoundingClientRect() : this.container.getBoundingClientRect();
    this.alignControl.refreshBoundingClientRect();
    this.arrayControl.refreshBoundingClientRect();
    this.magnetControl.refreshBoundingClientRect();
    this.mirrorControl.refreshBoundingClientRect();
    this.snapControl.refreshBoundingClientRect();
    this.sculptControl.refreshBoundingClientRect();
    this.transformControl.refreshBoundingClientRect();

  };

  onContainerResize = (width?: number, height?: number) => {
    let newWidth = width || 1;
    let newHeight = height || 1;

    if (newWidth === this.width && newHeight === this.height)
      return;

    this.height = newHeight;
    this.width = newWidth;

    this.perspCamera.aspect = this.width / this.height;

    this.orthoCamera.left = this.width / -2;
    this.orthoCamera.right = this.width / 2;
    this.orthoCamera.top = this.height / 2;
    this.orthoCamera.bottom = this.height / -2;

    this.envMapCamera.aspect = this.width / this.height;

    this.orthoCamera.updateProjectionMatrix();

    this.setPerspFromOrtho();

    this.renderer.setSize(this.width, this.height);
    this.envMapRenderer.setSize(this.width, this.height);
    this.controlRenderer.setSize(this.width, this.height);
    this.composer.setSize(this.width, this.height);

    this.alignControl.refreshBoundingClientRect();
    this.arrayControl.refreshBoundingClientRect();
    this.magnetControl.refreshBoundingClientRect();
    this.mirrorControl.refreshBoundingClientRect();
    this.snapControl.refreshBoundingClientRect();
    this.sculptControl.refreshBoundingClientRect();
    this.transformControl.refreshBoundingClientRect();

    this.refreshLineMaterialResolution();
    this.setNeedsUpdate();

    // if (this.scene.background !== null && (this.scene.background instanceof Texture)) {
    //   const canvasAspect = this.width / this.height;
    //   const imageAspect = this.scene.background.image ? this.scene.background.image.width / this.scene.background.image.height : 1;
    //   const aspect = imageAspect / canvasAspect;

    //   this.scene.background.offset.x = aspect > 1 ? (1 - 1 / aspect) / 2 : 0;
    //   this.scene.background.repeat.x = aspect > 1 ? 1 / aspect : 1;

    //   this.scene.background.offset.y = aspect > 1 ? 0 : (1 - aspect) / 2;
    //   this.scene.background.repeat.y = aspect > 1 ? 1 : aspect;
    // }

  };

  onMinorContainerResize = (width?: number, height?: number) => {

    if (this.minorCamera && this.minorRenderer) {
      let newWidth = width || 1;
      let newHeight = height || 1;

      if (newWidth === this.minorWidth && newHeight === this.minorHeight)
        return;

      this.minorHeight = newHeight;
      this.minorWidth = newWidth;
      this.minorCamera.left = this.minorWidth / -2;
      this.minorCamera.right = this.minorWidth / 2;
      this.minorCamera.top = this.minorHeight / 2;
      this.minorCamera.bottom = this.minorHeight / -2;

      this.minorCamera.updateProjectionMatrix();
      this.minorRenderer.setSize(this.minorWidth, this.minorHeight);

      this.setNeedsUpdate();
    }

  };

  highlightObjects(ids: string[]) {

    if (this.highlightEnabled) {

      let idsSet = new Set(ids);
      let oldIdsSet = new Set(this.highlightedModelIds);
      ids = Array.from(idsSet);

      if (!lod.isEqual(oldIdsSet, idsSet)) {

        this.highlightedModelIds = ids;
        this.actionCallback({action: ACT_HIGHLIGHT_MODELS, ids});
        this.refreshBoundingBoxVisibilities();

        this.setNeedsUpdate();
      }

    }

  }

  getModelUnderOffset(clientX?: number, clientY?: number) {

    if (clientX !== undefined && clientY !== undefined) {

      let mouseX = clientX - this.elemRect.left;
      let mouseY = clientY - this.elemRect.top;

      let objInfo = this.getMeshUnderMousePoint(new Vector2(mouseX, mouseY));

      if (objInfo && this.selection.ids.indexOf(objInfo.id) < 0) {

        return objInfo.id;

      }

    }

    if (this.hoverHighlightEnabled) {

      if (clientX === undefined || clientY === undefined) {

        this.highlightObjects(this.selection.ids);

      } else {

        let mouseX = clientX - this.elemRect.left;
        let mouseY = clientY - this.elemRect.top;

        let objInfo = this.getMeshUnderMousePoint(new Vector2(mouseX, mouseY));

        if (objInfo && this.selection.ids.indexOf(objInfo.id) < 0) {

          this.highlightObjects([objInfo.id]);
          return objInfo.id;

        } else {

          this.highlightObjects([]);

        }

      }

    }

  }

  getCurrentRenderScene = (live: boolean = true, resolution?: vec2) => {

    let models: { [key: string]: any } = {};
    let lights: { [key: string]: any } = {};
    for (let id in this.models) {
      let model = this.models[id];
      let visible = this.isModelVisible(id);

      if (visible) {
        for (let i = 0; i < model.subs.length; ++i) {
          let sub = model.subs[i];
          if ([RenderedObjectTypes.Mesh, RenderedObjectTypes.Brep].includes(sub.renderedObjectType)) {
            models[id + ':' + i] = {
              geometry: sub.geometryHash,
              matrix: sub.matrixHash.substr(1, sub.matrixHash.length - 2).split(',').map(Number),
              material: sub.materialId,
              lux: sub.luxHash
            };
          }
        }
      }
    }

    for (let id in this.lights) {
      let entity = this.lights[id].entity;
      let light = this.lights[id].light as any;
      if (entity && light.visible) {
        let type = '';
        if (light.isAmbientLight) {
          type = 'ambient';
        } else if (light.isDirectionalLight) {
          type = 'directional';
        } else if (light.isHemisphereLight) {
          type = 'hemisphere';
        } else if (light.isPointLight) {
          type = 'point';
        } else if (light.isRectAreaLight) {
          type = 'rect area';
        } else if (light.isSpotLight) {
          type = 'spot';
        }

        lights['' + id] = {
          ...entity,
          type
        };
      }
    }

    let background = '';
    let environment = '';

    if (this.lightingEnvironment)
      environment = this.lightingEnvironment.image;

    if (this.backgroundEnvironment) {
      if (this.backgroundEnvironment.type === EnvMapTypes.Image || this.backgroundEnvironment.type === EnvMapTypes.Lighting)
        background = this.backgroundEnvironment.image;
      if (this.backgroundEnvironment.type === EnvMapTypes.Color)
        background = this.backgroundEnvironment.color;
    }

    let {width, height} = this;
    let scale = 1;

    if (resolution) {
      width = resolution[0];
      height = resolution[1];
    } else {
      width = Math.round(width / 2);
      height = Math.round(height / 2);
      scale = 2;
    }

    let offset = this.perspCamera.position.clone().sub(this.perspOrbitControl.target).toArray();

    let offsetHeightRatio = _n(vec3.length(offset) / height);
    let direction = _v(vec3.normalize([0, 0, 0], offset));
    let target = _v(this.perspOrbitControl.target.toArray());

    return {
      width,
      height,
      scale,
      live,
      scene: {
        lights,
        models,
        background,
        environment,
        gridVisible: this.basePlane.visible,
        camera: {
          offsetHeightRatio,
          direction,
          target,
          angle: this.perspCamera.fov,
          zoom: this.perspCamera.zoom
        }
      }
    };

  };

  refreshServerRenderTimer: any;
  serverRenderConfig: any = {};
  currentServerRenderConfig: any = {};

  setServerRenderConfig = (config?: any) => {
    this.serverRenderConfig = config || {};
  };

  setServerRenderImage = (img?: string, config?: any) => {
    if (this.delegate && this.delegate.overlayCanvasElement) {
      let canvasElement = this.delegate.overlayCanvasElement;
      let scale = 1;
      if (config)
        scale = config['scale'] || 1;
      if (img) {
        const ctx = canvasElement.getContext("2d");

        let image = new Image();
        image.onload = () => {
          canvasElement.width = Math.round(image.width * scale);
          canvasElement.height = Math.round(image.height * scale);
          ctx && ctx.drawImage(image, 0, 0, canvasElement.width, canvasElement.height);
        };
        image.src = img;

      } else {
        canvasElement.width = this.width;
        canvasElement.height = this.height;
        const ctx = canvasElement.getContext("2d");
        if (ctx) {
          ctx.fillStyle = 'black';
          ctx.fillRect(0, 0, canvasElement.width, canvasElement.height);
        }
      }
    }

    this.currentServerRenderConfig = config || {};
  };

  updateMousePositions = (clientX: number, clientY: number) => {

    if (!this.elemRect)
      this.elemRect = this.delegate ? this.delegate.domElement.getBoundingClientRect() : this.container.getBoundingClientRect();

    this.mouseOnCanvas.x = clientX - this.elemRect.left;
    this.mouseOnCanvas.y = clientY - this.elemRect.top;

    this.mouse.x = this.mouseOnCanvas.x / this.width * 2 - 1;
    this.mouse.y = -this.mouseOnCanvas.y / this.height * 2 + 1;

    this.mouseOnCube.x = ((this.mouseOnCanvas.x - this.cubeMarginX) / this.cubeSize) * 2 - 1;
    this.mouseOnCube.y = -((this.mouseOnCanvas.y - this.cubeMarginY) / this.cubeSize) * 2 + 1;

  };

  getIntersectOnCube = () => {
    if (this.cubeMarginX < 0 && this.cubeMarginY < 0)
      return;

    if (!this.cubeCamera || !this.cubeMesh)
      return;

    if (Math.abs(this.mouseOnCube.x) <= 1 && Math.abs(this.mouseOnCube.y) <= 1) {
      this.rayCaster.setFromCamera(this.mouseOnCube, this.cubeCamera);
      let intersects = this.rayCaster.intersectObject(this.cubeMesh);

      if (intersects.length > 0)
        return intersects[0];
    }
  };

  onIntersectCube = (intersect: Intersection) => {

    if (intersect.faceIndex === 0 || intersect.faceIndex === 1) {

      this.moveToOrientFace('left');

    } else if (intersect.faceIndex === 2 || intersect.faceIndex === 3) {

      this.moveToOrientFace('right');

    } else if (intersect.faceIndex === 4 || intersect.faceIndex === 5) {

      this.moveToOrientFace('front');

    } else if (intersect.faceIndex === 6 || intersect.faceIndex === 7) {

      this.moveToOrientFace('back');

    } else if (intersect.faceIndex === 8 || intersect.faceIndex === 9) {

      this.moveToOrientFace('top');

    } else {

      this.moveToOrientFace('bottom');

    }

  };

  onTouchStart = (event: TouchEvent) => {

    if (event.touches.length > 1)
      return;

    event.preventDefault();

    this.elemRect = this.delegate ? this.delegate.domElement.getBoundingClientRect() : this.container.getBoundingClientRect();
    this.updateMousePositions(event.touches[0].clientX, event.touches[0].clientY);

    if (this.getIntersectOnCube()) {

      this.enableOrbitControl(false);
      return;

    }

    this.actionCallback({action: ACT_FOCUS});

    if (this.selection.tool === Tools.Measure) {

    } else if (this.selection.tool === Tools.Sculpt) {

      if (this.sculptControl.isDragging)
        this.enableOrbitControl(false);

    } else if (this.selection.tool === Tools.Annotate) {

    } else if (this.selection.tool === Tools.Polyline) {

    } else if (this.selection.tool === Tools.Spline) {

    } else {

      if (this.faceSelection === undefined) {

        if (this.transformControl.isDragging || this.snapControl.isDragging || this.magnetControl.isDragging || this.mirrorControl.isDragging || this.alignControl.isDragging || this.arrayControl.isDragging || this.sculptControl.isDragging) {

          if (!event.shiftKey && (event.ctrlKey || event.metaKey) && !event.altKey) {

            let objInfo = this.getMeshUnderMousePoint(this.mouseOnCanvas);

            if (objInfo) {

              let ids = [...this.selection.ids];

              if (ids.includes(objInfo.id))
                ids = ids.filter(id => id !== objInfo!.id);

              this.actionCallback({
                action: ACT_SELECT_MODELS,
                ids: this.filterSelectableIds(ids),
                commit: true
              });

            }

          }

          this.enableOrbitControl(false);

        } else if (!this.hasHoverPicker()) {

          if (event.shiftKey && !(event.ctrlKey || event.metaKey) && !event.altKey) {

            let objInfo = this.getMeshUnderMousePoint(this.mouseOnCanvas);

            if (objInfo) {

              let ids = [...this.selection.ids];

              if (!ids.includes(objInfo.id))
                ids = [...ids, objInfo.id];

              this.actionCallback({
                action: ACT_SELECT_MODELS,
                ids: this.filterSelectableIds(ids),
                commit: true
              });

            }

          }

          if (!event.shiftKey && !(event.ctrlKey || event.metaKey) && !event.altKey) {

            let objInfo = this.getMeshUnderMousePoint(this.mouseOnCanvas);

            if (objInfo) {

              if (this.selection.ids.length > 0)
                this.actionCallback({action: ACT_SELECT_MODELS, ids: []});

              this.selectionHelper.onSelectStart(event);

              if (this.hoverHighlightEnabled)
                this.highlightObjects([objInfo.id]);

              this.selection.selecting = true;
              this.selectionBox.startPoint.set(
                this.mouse.x,
                this.mouse.y,
                0.5
              );

              this.enableOrbitControl(false);

            }

            if (event.touches.length > 0) {

              this.pointerDownPos = new Vector2(event.touches[0].screenX, event.touches[0].screenY);
              this.pointerDownTime = new Date();

            }

          }

        }

      }

    }

  };

  onTouchMove = (event: TouchEvent) => {

    if (event.touches.length > 1)
      return;

    event.preventDefault();
    event.stopPropagation();

    this.updateMousePositions(event.touches[0].clientX, event.touches[0].clientY);

    if (this.selection.selecting) {

      this.selectionHelper.onSelectMove(event);

      this.selectionBox.endPoint.set(
        this.mouse.x,
        this.mouse.y,
        0.5
      );

      // if (this.hoverHighlightEnabled) {
      //
      //   let allSelected = this.selectionBox.select(this.selectionBox.startPoint, this.selectionBox.endPoint);
      //
      //   let ids: any[] = Array.from(new Set(allSelected.map(o => o.userData.id))).filter(id => id);
      //
      //   let objInfo = this.getMeshUnderMousePoint(this.mouseOnCanvas);
      //
      //   if (objInfo && ids.indexOf(objInfo.id) < 0)
      //     ids.push(objInfo.id);
      //
      //   let startObjInfo = this.getMeshUnderMousePoint(new Vector2(
      //     (this.selectionBox.startPoint.x + 1) / 2 * this.width,
      //     (1 - this.selectionBox.startPoint.y) / 2 * this.height
      //   ));
      //
      //   if (startObjInfo && ids.indexOf(startObjInfo.id) < 0)
      //     ids.push(startObjInfo.id);
      //
      //   this.highlightObjects(ids);
      //
      // }

    }

  };

  onTouchEnd = (event: TouchEvent) => {

    this.enableOrbitControl(true);

    if (this.getIntersectOnCube())
      return;

    if (this.selection.selecting) {

      this.selection.selecting = false;
      this.selectionHelper.onSelectOver();

      this.selectionBox.endPoint.set(
        this.mouse.x,
        this.mouse.y,
        0.5
      );

      let allSelected = this.selectionBox.select(this.selectionBox.startPoint, this.selectionBox.endPoint);

      let ids: any[] = Array.from(new Set(allSelected)).filter(id => id);

      let endObjInfo = this.getMeshUnderMousePoint(this.mouseOnCanvas);

      if (endObjInfo && ids.indexOf(endObjInfo.id) < 0)
        ids.push(endObjInfo.id);

      if (Math.abs(this.selectionBox.startPoint.x - this.mouse.x) > 0.01 || Math.abs(this.selectionBox.startPoint.y - this.mouse.y) > 0.01) {

        let startObjInfo = this.getMeshUnderMousePoint(new Vector2(
          (this.selectionBox.startPoint.x + 1) / 2 * this.width,
          (1 - this.selectionBox.startPoint.y) / 2 * this.height
        ));

        if (startObjInfo && ids.indexOf(startObjInfo.id) < 0)
          ids.push(startObjInfo.id);

      }

      this.actionCallback({
        action: ACT_SELECT_MODELS,
        ids: this.filterSelectableIds(ids),
        commit: true
      });

    } else {

      if (event.changedTouches.length > 0) {

        let upPos = new Vector2(event.changedTouches[0].screenX, event.changedTouches[0].screenY);

        if (upPos.distanceToSquared(this.pointerDownPos) < 16 && this.pointerDownTime && new Date().getTime() - this.pointerDownTime.getTime() < 250) {

          this.actionCallback({action: ACT_SELECT_MODELS, ids: [], commit: true});

        }

      }

    }

  };

  onMouseDown = (event: any) => {

    // if (event.target.hasPointerCapture(1))
    //   return;

    // INFO: null check is for safari
    event.target.setPointerCapture && event.target.setPointerCapture(1);

    this.updateMousePositions(event.clientX, event.clientY);

    if (this.getIntersectOnCube()) {

      this.enableOrbitControl(false);
      return;

    }

    this.actionCallback({action: ACT_MOUSE_DOWN, buttons: event.buttons});

    if (event.buttons === 1) {

      this.actionCallback({action: ACT_FOCUS});

    }

    this.pointerDownPos = new Vector2(event.clientX, event.clientY);
    this.pointerDownTime = new Date();
    this.pointerDownButton = event.button;

    if (this.selection.tool === Tools.Gumball || this.selection.tool === Tools.Snap || this.selection.tool === Tools.Magnet || this.selection.tool === Tools.Align) {

      if (this.faceSelection === undefined && event.buttons === 1) {

        if (this.hasHoverPicker() || this.transformControl.isDragging || this.snapControl.isDragging || this.magnetControl.isDragging || this.alignControl.isDragging) {

          this.enableOrbitControl(false);

        } else {

          if (!event.shiftKey && !event.altKey) {

            if (this.hoverHighlightEnabled) {

              let objInfo = this.getMeshUnderMousePoint(this.mouseOnCanvas);

              if (objInfo)
                this.highlightObjects([objInfo.id]);

            }

            if (event.ctrlKey || event.metaKey) {

              if (this.selection.ids.length > 0)
                this.actionCallback({action: ACT_SELECT_MODELS, ids: []});

              this.enableOrbitControl(false);
              this.selectionHelper.onSelectStart(event);
              this.selection.selecting = true;
              this.selectionBox.startPoint.set(
                this.mouse.x,
                this.mouse.y,
                0.5
              );

            }

          }

        }

      }

    } else if (this.selection.tool === Tools.Array) {

      if (this.arrayControl.isDragging)
        this.enableOrbitControl(false);

    } else if (this.selection.tool === Tools.Mirror) {

      if (this.mirrorControl.isDragging)
        this.enableOrbitControl(false);

    } else {

      if (this.transformControl.isDragging || this.snapControl.isDragging || this.magnetControl.isDragging || this.mirrorControl.isDragging || this.alignControl.isDragging || this.arrayControl.isDragging || this.sculptControl.isDragging)
        this.enableOrbitControl(false);

    }

  };

  onMouseLeave = (event: MouseEvent) => {

    this.actionCallback({action: ACT_MOUSE_LEAVE});

  };

  hasHoverPicker = () => {

    for (let value in this.isHoverLightHelperPicker) {
      let lightId = +(value.split(':')[0]);
      if (this.isHoverLightHelperPicker[value] && this.lights[lightId] && this.lights[lightId].helperVisible)
        return true;
    }

    return false;

  }

  onMouseMove = (event: MouseEvent) => {

    this.updateMousePositions(event.clientX, event.clientY);

    if (event.button !== 2) {

      this.rayCaster.setFromCamera(this.mouse, this.getCamera());
      this.actionCallback({
        action: ACT_MOUSE_MOVE,
        origin: this.rayCaster.ray.origin.toArray(),
        direction: this.rayCaster.ray.direction.toArray(),
        selecting: this.selection.selecting
      });

    }

    if (this.hasHoverPicker()) {

      this.setNeedsUpdate();

    } else if (this.selection.selecting) {

      this.selectionHelper.onSelectMove(event);

      this.selectionBox.endPoint.set(
        this.mouse.x,
        this.mouse.y,
        0.5
      );

      // if (this.hoverHighlightEnabled) {
      //
      //   let allSelected = this.selectionBox.select(this.selectionBox.startPoint, this.selectionBox.endPoint);
      //
      //   let ids: any[] = Array.from(new Set(allSelected)).filter(id => id);
      //
      //   let objInfo = this.getMeshUnderMousePoint(this.mouseOnCanvas);
      //
      //   if (objInfo && ids.indexOf(objInfo.id) < 0)
      //     ids.push(objInfo.id);
      //
      //   let startObjInfo = this.getMeshUnderMousePoint(new Vector2(
      //     (this.selectionBox.startPoint.x + 1) / 2 * this.width,
      //     (1 - this.selectionBox.startPoint.y) / 2 * this.height
      //   ));
      //
      //   if (startObjInfo && ids.indexOf(startObjInfo.id) < 0)
      //     ids.push(startObjInfo.id);
      //
      //   this.highlightObjects(ids);
      //
      // }

    } else {

      if (this.pointSnapEnabled) {

        this.pointSnapText.visible = false;
        this.pointSnapMesh.visible = false;

        this.rayCaster.setFromCamera(this.mouse, this.getCamera());

        let scopeModels: IModel[] = [];

        this.scope.forEach(id => {

          if (this.models[id])
            scopeModels.push(this.models[id]);

        });

        let intersects = this.rayCaster.intersectObjects(scopeModels.map(m => m.meshGroup).filter(m => m.visible));

        for (let intersect of intersects) {

          if (intersect.face === null) {

            let geometry = ((intersect.object as Points).geometry as BufferGeometry);
            let point = new Vector3(geometry.attributes.position.getX(intersect.index!),
              geometry.attributes.position.getY(intersect.index!),
              geometry.attributes.position.getZ(intersect.index!));

            let spritePoint = point.clone()
              .project(this.getCamera())
              .multiply(new Vector3(1, 1, 0.98))
              .add(new Vector3(0, -0.05, 0))
              .unproject(this.getCamera());

            this.pointSnapText.position.copy(spritePoint);
            // this.textTexture.needsUpdate = true
            this.pointSnapMesh.position.copy(point);

            this.pointSnapText.visible = true;
            this.pointSnapMesh.visible = true;

          } else {

            break;

          }

        }

      }

      if (event.buttons === 0 && event.button === 0) {

        if (this.selection.tool === Tools.Annotate) {

          let annotate = this.annotates[this.editingAnnotateId];

          if (this.annotateState !== AnnotateControlStates.InputtingText) {

            if (this.annotateState === AnnotateControlStates.SelectingTextPos) {

              let startPosition = this.annotations[this.editingAnnotateId].startMarker.position;

              let endPosition = startPosition.clone().project(this.getCamera()).setX(this.mouse.x).setY(this.mouse.y).unproject(this.getCamera());

              annotate.offset = [endPosition.x - startPosition.x, endPosition.y - startPosition.y, endPosition.z - startPosition.z];
              this.refreshAnnotations();

            } else if (this.annotateState === AnnotateControlStates.SelectingTarget) {

              this.rayCaster.setFromCamera(this.mouse, this.getCamera());

              let scopeModels: IModel[] = [];

              this.scope.forEach(id => {

                if (this.models[id])
                  scopeModels.push(this.models[id]);

              });

              let intersects = this.rayCaster.intersectObjects(scopeModels.map(m => m.meshGroup).filter(m => m.visible).flatMap(m => m.children));

              let pointIntersectExists = false;
              let annotateIntersectExists = false;

              for (let intersect of intersects) {

                if (intersect.face !== null) {

                  let position = intersect.point.clone().applyMatrix4(intersect.object.matrixWorld.clone().invert());

                  if (this.editingAnnotateId <= 0) {

                    if (Object.keys(this.annotates).length > 0)
                      this.editingAnnotateId = Math.max(...Object.keys(this.annotates).map(Number)) + 1;
                    else
                      this.editingAnnotateId = 1;

                    this.initCurrentAnnotation();
                    annotate = this.annotates[this.editingAnnotateId];
                  }

                  annotate.start = {
                    calcId: intersect.object.userData.id,
                    meshIndex: +intersect.object.userData.index,
                    position: [position.x, position.y, position.z]
                  };

                  this.refreshAnnotations();

                  pointIntersectExists = true;
                  break;

                } else {

                  if (intersect.object.userData.annotateId > 0) {

                    annotateIntersectExists = true;

                  } else {

                    break;

                  }

                }

              }

              if (!annotateIntersectExists)
                this.highlightObjects([]);

              if (!pointIntersectExists && this.editingAnnotateId > 0) {

                if (annotate.start.calcId) {

                  annotate.start = {
                    calcId: '',
                    meshIndex: 0,
                    position: [0, 0, 0]
                  };
                  this.refreshAnnotations();

                }

              }

            }

          }

        }

        if (this.selection.tool === Tools.Measure) {

          let measure = this.measures[this.editingMeasureId];

          if (this.measureState !== MeasureControlStates.MovingPerpendicular) {

            this.rayCaster.setFromCamera(this.mouse, this.getCamera());

            let scopeModels: IModel[] = [];

            this.scope.forEach(id => {

              if (this.models[id])
                scopeModels.push(this.models[id]);

            });

            let intersects = this.rayCaster.intersectObjects([
              ...scopeModels.map(m => m.meshGroup).filter(m => m.visible).flatMap(m => m.children),
              ...Object.values(this.measurements).flatMap(m => m.distanceText)
            ]);

            let pointIntersectExists = false;
            let measureIntersectExists = false;

            for (let intersect of intersects) {

              if (intersect.face !== null) {

                let position = intersect.point.clone().applyMatrix4(intersect.object.matrixWorld.clone().invert());

                if (this.measureState === MeasureControlStates.SelectingTo) {

                  measure.end = {
                    calcId: intersect.object.userData.id,
                    meshIndex: +intersect.object.userData.index,
                    position: [position.x, position.y, position.z]
                  };
                  this.refreshMeasurements();

                } else if (this.measureState === MeasureControlStates.SelectingFrom) {

                  if (this.editingMeasureId <= 0) {

                    if (Object.keys(this.measures).length > 0)
                      this.editingMeasureId = Math.max(...Object.keys(this.measures).map(Number)) + 1;
                    else
                      this.editingMeasureId = 1;

                    this.initCurrentMeasurement();
                    measure = this.measures[this.editingMeasureId];

                  }

                  measure.start = {
                    calcId: intersect.object.userData.id,
                    meshIndex: +intersect.object.userData.index,
                    position: [position.x, position.y, position.z]
                  };

                }

                this.refreshMeasurements();
                pointIntersectExists = true;
                break;

              } else {

                if (intersect.object.userData.measureId > 0) {

                  measureIntersectExists = true;

                } else {

                  break;

                }

              }

            }

            if (!measureIntersectExists)
              this.highlightObjects([]);

            if (!pointIntersectExists && this.editingMeasureId > 0) {

              if (this.measureState === MeasureControlStates.SelectingTo) {

                if (measure.end.calcId) {

                  measure.end = {
                    calcId: '',
                    meshIndex: 0,
                    position: [0, 0, 0]
                  };

                  this.refreshMeasurements();

                }

              } else {

                if (measure.start.calcId) {

                  measure.start = {
                    calcId: '',
                    meshIndex: 0,
                    position: [0, 0, 0]
                  };

                  this.refreshMeasurements();

                }

              }

            }

          } else {

            if (this.editingMeasureId > 0) {

              let startPosition = this.getMeshPoint(measure.start);
              let endPosition = this.getMeshPoint(measure.end);

              if (startPosition && endPosition) {

                let mouseStartPosition = endPosition;
                let scMouse = new Vector3(this.mouse.x, this.mouse.y, 0);
                let scOrigin = mouseStartPosition.clone().project(this.getCamera()).setZ(0);
                let scXOff = new Vector3(1, 0, 0).add(mouseStartPosition).project(this.getCamera()).sub(scOrigin).setZ(0);
                let scYOff = new Vector3(0, 1, 0).add(mouseStartPosition).project(this.getCamera()).sub(scOrigin).setZ(0);
                let scZOff = new Vector3(0, 0, 1).add(mouseStartPosition).project(this.getCamera()).sub(scOrigin).setZ(0);
                let axes: { [key: string]: { ray: Ray, off: Vector3 } } = {
                  'x': {ray: new Ray(scOrigin, scXOff.clone().normalize()), off: scXOff},
                  'x-': {
                    ray: new Ray(scOrigin, scXOff.clone().multiplyScalar(-1).normalize()),
                    off: scXOff.clone().multiplyScalar(-1)
                  },
                  'y': {ray: new Ray(scOrigin, scYOff.clone().normalize()), off: scYOff},
                  'y-': {
                    ray: new Ray(scOrigin, scYOff.clone().multiplyScalar(-1).normalize()),
                    off: scYOff.clone().multiplyScalar(-1)
                  },
                  'z': {ray: new Ray(scOrigin, scZOff.clone().normalize()), off: scZOff},
                  'z-': {
                    ray: new Ray(scOrigin, scZOff.clone().multiplyScalar(-1).normalize()),
                    off: scZOff.clone().multiplyScalar(-1)
                  }
                };

                let found = false;

                while (true) {

                  let minDistance = +Infinity, minDistanceId = '';

                  for (let axisId in axes) {

                    let axis = axes[axisId];
                    let distance = +Infinity;

                    if (new Vector3().subVectors(scMouse, axis.ray.origin).dot(axis.ray.direction) >= 0) {

                      distance = axis.ray.distanceToPoint(scMouse) / axis.off.length();

                    } else {

                      delete axes[axisId];

                    }

                    if (distance < minDistance) {

                      minDistance = distance;
                      minDistanceId = axisId;

                    }

                  }

                  if (Object.values(axes).length === 0)
                    break;

                  this.rayCaster.setFromCamera(this.mouse, this.getCamera());
                  let minPlanes: { [key: string]: { plane: Plane } } = {};

                  if (minDistanceId[0] !== 'z') {

                    minPlanes['xy'] = {plane: new Plane(new Vector3(0, 0, 1), -mouseStartPosition.z)};

                  }

                  if (minDistanceId[0] !== 'y') {

                    minPlanes['xz'] = {plane: new Plane(new Vector3(0, 1, 0), -mouseStartPosition.y)};

                  }

                  if (minDistanceId[0] !== 'x') {

                    minPlanes['yz'] = {plane: new Plane(new Vector3(1, 0, 0), -mouseStartPosition.x)};

                  }

                  while (true) {

                    let minPlaneDistance = +Infinity;
                    let minPlaneId = '';
                    let minPlaneTarget = new Vector3();

                    for (let planeId in minPlanes) {

                      let plane = minPlanes[planeId].plane;
                      let target = new Vector3();

                      if (this.rayCaster.ray.intersectPlane(plane, target) !== null) {

                        let distance = target.distanceTo(this.rayCaster.ray.origin);

                        if (distance < minPlaneDistance) {

                          minPlaneDistance = distance;
                          minPlaneId = planeId;
                          minPlaneTarget = target;

                        }

                      } else {

                        delete minPlanes[planeId];

                      }

                    }

                    if (Object.values(minPlanes).length === 0) {

                      delete axes[minDistanceId];
                      break;

                    }

                    let startLineEndPosition = startPosition.clone();
                    let endLineEndPosition = endPosition.clone();
                    let mainAxis = minDistanceId[0] as ('x' | 'y' | 'z');
                    let subAxis = minPlaneId.replace(mainAxis, '') as ('x' | 'y' | 'z');
                    let nonAxis = 'xyz'.replace(mainAxis, '').replace(subAxis, '') as ('x' | 'y' | 'z');

                    endLineEndPosition[mainAxis] = minPlaneTarget[mainAxis];
                    startLineEndPosition[nonAxis] = endPosition[nonAxis];
                    startLineEndPosition[mainAxis] = minPlaneTarget[mainAxis];

                    if (+startLineEndPosition.distanceTo(endLineEndPosition).toFixed(2) === 0) {

                      delete minPlanes[minPlaneId];
                      continue;

                    }

                    measure.offDistance = minPlaneTarget[mainAxis] - endPosition[mainAxis];
                    measure.mainAxis = mainAxis;
                    measure.subAxis = subAxis;
                    this.refreshMeasurements();

                    found = true;
                    break;

                  }

                  if (found)
                    break;

                }

              }

            }

          }

        }

        if (this.selection.tool === Tools.Orient) {

          let objInfo = this.getMeshUnderPoint(this.mouse);

          if (objInfo && this.selection.ids.includes(objInfo.id)) {

            this.setOrientHighlightedMesh(objInfo.id, objInfo.mesh, objInfo.instanceId, objInfo.faceIndex);

          } else {

            this.setOrientHighlightedMesh();

          }

        } else {

          this.setOrientHighlightedMesh();

        }

        if (this.hoverHighlightEnabled) {

          if (this.selection.tool === Tools.Gumball) {

            let objInfo = this.getMeshUnderMousePoint(this.mouseOnCanvas);

            if (this.selection.ids.length === 0) {

              if (objInfo && objInfo.id !== '') {

                this.highlightObjects([objInfo.id]);

              } else {

                this.highlightObjects([]);

              }

            }

          }

        }

      }

    }

  };

  onMouseUp = (event: any) => {

    // if (!event.target.hasPointerCapture(1))
    //   return;
    event.target.releasePointerCapture && event.target.releasePointerCapture(1);

    this.actionCallback({action: ACT_MOUSE_UP, buttons: event.buttons});

    this.updateMousePositions(event.clientX, event.clientY);
    let currentPos = new Vector2(event.clientX, event.clientY);

    let doubleClick = this.prevClickTime && new Date().getTime() - this.prevClickTime.getTime() < 250 && this.prevClickPos.distanceToSquared(currentPos) < 16 && event.button === this.prevClickButton;
    let click = this.pointerDownTime && new Date().getTime() - this.pointerDownTime.getTime() < 250 && this.pointerDownPos.distanceToSquared(currentPos) < 16 && event.button === this.pointerDownButton;

    if (doubleClick) {

      this.prevClickTime = undefined;

      this.onDoubleClick(event);

      if (this.selection.selecting) {

        this.selection.selecting = false;
        this.selectionHelper.onSelectOver();

      }

    } else {

      this.prevClickPos.set(event.clientX, event.clientY);
      this.prevClickTime = new Date();
      this.prevClickButton = event.button;

      this.onSingleClick(event, !click);

      this.pointerDownTime = undefined;

    }

    this.enableOrbitControl(true);

  };

  onSingleClick = (event: MouseEvent, drag: boolean) => {

    let cubeIntersection = this.getIntersectOnCube();

    if (cubeIntersection) {

      this.onIntersectCube(cubeIntersection);
      return;

    }

    if (!drag)
      this.actionCallback({action: ACT_MOUSE_CLICK, buttons: event.buttons});

    if (this.selection.tool === Tools.Gumball || this.selection.tool === Tools.Snap || this.selection.tool === Tools.Magnet || this.selection.tool === Tools.Align) {

      if (this.selection.selecting) {

        this.selection.selecting = false;
        this.selectionHelper.onSelectOver();

        this.selectionBox.endPoint.set(
          this.mouse.x,
          this.mouse.y,
          0.5
        );

        let allSelected = this.selectionBox.select(this.selectionBox.startPoint, this.selectionBox.endPoint);

        let ids: any[] = Array.from(new Set(allSelected)).filter(id => id);

        let endObjInfo = this.getMeshUnderMousePoint(this.mouseOnCanvas);

        if (endObjInfo && ids.indexOf(endObjInfo.id) < 0)
          ids.push(endObjInfo.id);

        if (Math.abs(this.selectionBox.startPoint.x - this.mouse.x) > 0.01 ||
          Math.abs(this.selectionBox.startPoint.y - this.mouse.y) > 0.01
        ) {

          let startObjInfo = this.getMeshUnderMousePoint(new Vector2(
            (this.selectionBox.startPoint.x + 1) / 2 * this.width,
            (1 - this.selectionBox.startPoint.y) / 2 * this.height
          ));

          if (startObjInfo && ids.indexOf(startObjInfo.id) < 0)
            ids.push(startObjInfo.id);

        }

        this.actionCallback({
          action: ACT_SELECT_MODELS,
          ids: this.filterSelectableIds(ids),
          commit: true
        });

      } else if (!drag) {

        if ((event.shiftKey || event.ctrlKey || event.metaKey) && !event.altKey) {

          let objInfo = this.getMeshUnderMousePoint(this.mouseOnCanvas);

          if (objInfo) {

            let ids = [...this.selection.ids];

            if (ids.includes(objInfo.id))
              ids = ids.filter(id => id !== objInfo!.id);
            else
              ids = [...ids, objInfo.id];

            this.actionCallback({
              action: ACT_SELECT_MODELS,
              ids: this.filterSelectableIds(ids),
              commit: true
            });

          }

        } else {

          let helperSelected = this.selectAnnotation() || this.selectMeasurement() || this.selectPlaceholderBox();

          if (!helperSelected) {

            let objInfo = this.getMeshUnderMousePoint(this.mouseOnCanvas);

            if (objInfo)
              this.actionCallback({action: ACT_SELECT_MODELS, ids: [objInfo.id], commit: true});
            else if (this.selection.tool === Tools.Align)
              this.actionCallback({action: ACT_SELECT_MODELS, ids: [], tool: Tools.Gumball, commit: true});
            else
              this.actionCallback({action: ACT_SELECT_MODELS, ids: [], commit: true});

          }

        }

      }

    } else if (!drag) {

      if (this.selection.tool === Tools.Annotate) {

        let annotate = this.annotates[this.editingAnnotateId];

        if (this.annotateState === AnnotateControlStates.SelectingTarget) {

          if (this.editingAnnotateId > 0 && annotate.start.calcId) {

            if (annotate.offset[0] !== 0 || annotate.offset[1] !== 0 || annotate.offset[2] !== 0) {

              if (annotate.text !== this.defaultAnnotationText && annotate.text !== '') {

                this.saveAnnotate();

              } else {

                this.editAnnotate(this.editingAnnotateId, AnnotateControlStates.InputtingText);

              }

            } else {

              this.editAnnotate(this.editingAnnotateId, AnnotateControlStates.SelectingTextPos);

            }

          } else {

            if (!this.selectAnnotation()) {

              this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

            }

          }

        } else if (this.annotateState === AnnotateControlStates.SelectingTextPos) {

          if (annotate.text !== this.defaultAnnotationText && annotate.text !== '') {

            this.saveAnnotate();

          } else {

            this.editAnnotate(this.editingAnnotateId, AnnotateControlStates.InputtingText);

          }

        } else if (this.annotateState === AnnotateControlStates.InputtingText) {

          if (this.annotateInputElement) {

            this.annotateInputElement.focus();
            this.annotateInputElement.blur();

          }

        }
      } else if (this.selection.tool === Tools.Measure) {

        let measure = this.measures[this.editingMeasureId];

        if (this.measureState === MeasureControlStates.SelectingFrom) {

          if (this.editingMeasureId > 0 && measure.start.calcId) {

            if (measure.end.calcId) {

              if (measure.offDistance !== 0) {

                this.saveMeasure();

              } else {

                this.editMeasure(this.editingMeasureId, MeasureControlStates.MovingPerpendicular);

              }

            } else {

              this.editMeasure(this.editingMeasureId, MeasureControlStates.SelectingTo);

            }

          } else {

            if (!this.selectMeasurement()) {

              this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

            }

          }

        } else if (this.measureState === MeasureControlStates.SelectingTo) {

          if (measure.offDistance !== 0) {

            this.saveMeasure();

          } else {

            this.editMeasure(this.editingMeasureId, MeasureControlStates.MovingPerpendicular);

          }

        } else if (this.measureState === MeasureControlStates.MovingPerpendicular) {

          this.saveMeasure();

        }

      } else if (this.selection.tool === Tools.Orient) {

        if (this.faceSelection) {

          let verts = this.getFaceVertices(
            this.faceSelection.mesh,
            this.faceSelection.instanceId,
            this.faceSelection.faceIndex
          );

          let cb = new Vector3(), ab = new Vector3();

          cb.subVectors(verts[2], verts[1]);
          ab.subVectors(verts[0], verts[1]);
          cb.cross(ab);
          cb.normalize();

          let center = new Vector3();
          center.add(verts[0]).add(verts[1]).add(verts[2]).divideScalar(3);

          let quaternion = new Quaternion();
          quaternion.setFromUnitVectors(cb, new Vector3(0, 0, -1));

          let rotationMatrix = new Matrix4().makeRotationFromQuaternion(quaternion);

          let newCenter = center.clone().applyMatrix4(rotationMatrix);
          let offset = new Vector3(center.x - newCenter.x, center.y - newCenter.y, -newCenter.z);

          this.selection.group.applyMatrix4(rotationMatrix.premultiply(new Matrix4().makeTranslation(offset.x, offset.y, offset.z)));

          this.setOrientHighlightedMesh();
          this._finishTransformation();

        }

        this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});

      } else if (this.selection.tool === Tools.Polyline) {

        this.rayCaster.setFromCamera(this.mouse, this.getCamera());

        let plane = new Plane(new Vector3(0, 0, 1), -this.baseZ);

        if (this.rayCaster.ray.intersectsPlane(plane)) {

          let intersect = new Vector3();
          this.rayCaster.ray.intersectPlane(plane, intersect);

          if (intersect.x + intersect.y + intersect.z < 100000) {

            this.polyline.points.push(intersect);
            this.refreshDrawingPolyline();

          }

        }

      } else if (this.selection.tool === Tools.Spline) {

        this.rayCaster.setFromCamera(this.mouse, this.getCamera());

        let plane = new Plane(new Vector3(0, 0, 1), -this.baseZ);

        if (this.rayCaster.ray.intersectsPlane(plane)) {

          let intersect = new Vector3();
          this.rayCaster.ray.intersectPlane(plane, intersect);

          if (intersect.x + intersect.y + intersect.z < 100000) {

            this.curve.points.push(intersect);
            this.refreshDrawingCurve();

          }

        }

      } else if (this.selection.tool === Tools.Sculpt) {

        this.selectPlaceholderBox();

      } else if (this.selection.tool === Tools.Array) {

        if (event.button === 0 && (!(event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey))
          this._finishTransformation();

      } else if (this.selection.tool === Tools.Mirror) {

        if (event.button === 0 && (!(event.ctrlKey || event.metaKey) && !event.shiftKey && !event.altKey))
          this._finishTransformation();

      }

    }

  };

  onDoubleClick = (event: MouseEvent) => {

    let actionMade = false;
    if (this.selection.tool === Tools.Annotate) {

      if (this.annotateState === AnnotateControlStates.SelectingTextPos) {

        this.editAnnotate(this.editingAnnotateId, AnnotateControlStates.InputtingText);
        actionMade = true;

      }

    } else if (this.selection.tool === Tools.Measure) {

      if (this.measureState === MeasureControlStates.MovingPerpendicular)
        actionMade = true;

    } else if (this.selection.tool === Tools.Polyline) {

      if (this.polyline.points.length > 1) {

        // this.rayCaster.setFromCamera(this.mouse, this.getCamera());

        this.polyline.points.push(this.polyline.points[0]);
        this.refreshDrawingPolyline();

        this.commitPolyline();
        this.initDrawingPolyline();

      }

      this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});
      actionMade = true;

    } else if (this.selection.tool === Tools.Spline) {

      if (this.curve.points.length > 1) {

        // this.rayCaster.setFromCamera(this.mouse, this.getCamera());

        this.curve.periodic = true;
        this.refreshDrawingCurve();

        this.commitCurve();
        this.initDrawingCurve();

      }

      this.actionCallback({action: ACT_SELECT_TOOL, tool: Tools.Gumball});
      actionMade = true;

    }

    if (!actionMade && this.selection.tool !== Tools.Sculpt) {

      this.rayCaster.setFromCamera(this.mouse, this.getCamera());
      let intersect = this.rayCaster.ray.intersectPlane(new Plane(new Vector3(0, 0, 1), 0), new Vector3());
      let grid = this.floor.visible && intersect && Math.abs(intersect.x) < this.gridHeight / 2 && Math.abs(intersect.y) < this.gridWidth / 2;

      if (this.selection.ids.length === 1) {

        if (this.models[this.selection.ids[0]] &&
          this.models[this.selection.ids[0]].prevIds.length > 0 &&
          !CombinedAtomComponentTypes.includes(this.models[this.selection.ids[0]].component)
        ) {

          this.actionCallback({action: ACT_PUSH_EDIT_LEVEL, id: this.selection.ids[0]});

        } else {

          this.actionCallback({action: ACT_DBL_CLICK, ids: this.selection.ids, grid});

        }

      } else {

        if (this.selection.editLevels.length > 0) {

          let level = this.selection.editLevels[this.selection.editLevels.length - 1];
          this.actionCallback({action: ACT_POP_EDIT_LEVEL, id: level});

        } else {

          this.actionCallback({action: ACT_DBL_CLICK, ids: [], grid});

        }

      }

    }

  };

  changeViewPoint(direction: string) {

    this.moveToOrientFace(this.getNearestOrientFaceFromDirection(direction));

  }

  protected enableOrbitControl(enabled: boolean) {

    this.orthoOrbitControl.enabled = enabled;
    this.perspOrbitControl.enabled = enabled;

  }

  protected enableHighlight(enabled: boolean) {

    this.highlightEnabled = enabled;

  }

  protected enableHoverHighlight(enabled: boolean) {

    this.hoverHighlightEnabled = enabled;

  }

  protected enableGrid(enabled: boolean) {

    if (this.gridEnabled !== enabled) {

      this.gridEnabled = enabled;
      this.refreshFloor();
      this.refreshImportIconVisibility();

    }

  }

  protected enableCameraZoom(enabled: boolean) {

    this.orthoOrbitControl.enableZoom = enabled;
    this.perspOrbitControl.enableZoom = enabled;

  }

  protected enableCameraPan(enabled: boolean) {

    this.orthoOrbitControl.enablePan = enabled;
    this.perspOrbitControl.enablePan = enabled;

  }

  protected enableEnvMap(enabled: boolean) {

    this.envMapEnabled = enabled;
    this.envMapRenderer.domElement.style.display = this.envMapEnabled ? 'block' : 'none';

  }

  protected enableBlob(enabled: boolean, pos: vec3, size: number) {

    if (enabled && !this.blobObject) {
      this.blobObject = new MarchingCubes(40, new MeshPhysicalMaterial({
        metalness: 1,
        roughness: 0.22,
        clearcoat: 1.00,
        clearcoatRoughness: 0.17,
        sheen: new Color(0xffffff),
        vertexColors: true
      }), false, true);
      this.blobObject.position.set(pos[0], pos[1], pos[2]);
      this.blobObject.scale.set(size, size, size);

      this.scene.add(this.blobObject);
    } else if (!enabled && this.blobObject) {
      this.scene.remove(this.blobObject);

      this.blobObject = undefined;
    }

  }

  protected enableEditControls(enabled: boolean) {

    this.editControlsEnabled = enabled;
    this.selectionHelper.enabled = this.editControlsEnabled;
    this.transformControl.enabled = this.editControlsEnabled;
    this.snapControl.enabled = this.editControlsEnabled;
    this.magnetControl.enabled = this.editControlsEnabled;
    this.mirrorControl.enabled = this.editControlsEnabled;
    this.sculptControl.enabled = this.editControlsEnabled;
    this.alignControl.enabled = this.editControlsEnabled;

  }

  protected enableLightControls(enabled: boolean) {

    this.lightControlsEnabled = enabled;

    for (let lightId in this.lights) {
      let light = this.lights[lightId];

      if (light.helper && (light.helper.visible !== light.helperVisible && this.lightControlsEnabled)) {
        light.helper.visible = light.helperVisible && this.lightControlsEnabled;
        this.setNeedsUpdate();
      }
    }

  }

  getNearestOrientFaceFromDirection(direction: string) {

    let center = new Vector3(0, 0, -1).unproject(this.getCamera());
    let viewPointVectors: { [key: string]: Vector3 } = {
      'left': new Vector3(-1, 0, -1).unproject(this.getCamera()).sub(center).normalize(),
      'top': new Vector3(0, 1, -1).unproject(this.getCamera()).sub(center).normalize(),
      'bottom': new Vector3(0, -1, -1).unproject(this.getCamera()).sub(center).normalize(),
      'right': new Vector3(1, 0, -1).unproject(this.getCamera()).sub(center).normalize()
    };

    let viewPointVector = viewPointVectors[direction];

    if (!viewPointVector)
      return '';

    let orientVectors: { [key: string]: Vector3 } = {
      'left': new Vector3(1, 0, 0),
      'right': new Vector3(-1, 0, 0),
      'top': new Vector3(0, 0, 1),
      'bottom': new Vector3(0, 0, -1),
      'front': new Vector3(0, 1, 0),
      'back': new Vector3(0, -1, 0)
    };

    let minAngle = +Infinity;
    let minOrient = '';

    for (let orient in orientVectors) {

      let v = orientVectors[orient];
      let angle = v.angleTo(viewPointVector);

      if (angle < minAngle) {

        minAngle = angle;
        minOrient = orient;

      }

    }

    return minOrient;
  }

  moveMinorToOrientFace(orient: string) {

    if (!this.minorCamera || !this.minorOrbitControl)
      return;

    let distance = this.minorCamera.position.distanceTo(this.minorOrbitControl.target);
    let cameraTo = new Vector3();

    if (orient === 'left') {

      cameraTo.set(
        this.minorOrbitControl.target.x + distance,
        this.minorOrbitControl.target.y,
        this.minorOrbitControl.target.z
      );

    } else if (orient === 'right') {

      cameraTo.set(
        this.minorOrbitControl.target.x - distance,
        this.minorOrbitControl.target.y,
        this.minorOrbitControl.target.z
      );

    } else if (orient === 'front') {

      cameraTo.set(
        this.minorOrbitControl.target.x,
        this.minorOrbitControl.target.y + distance,
        this.minorOrbitControl.target.z
      );

    } else if (orient === 'back') {

      cameraTo.set(
        this.minorOrbitControl.target.x,
        this.minorOrbitControl.target.y - distance,
        this.minorOrbitControl.target.z
      );

    } else if (orient === 'top') {

      cameraTo.set(
        this.minorOrbitControl.target.x,
        this.minorOrbitControl.target.y + 0.1,
        this.minorOrbitControl.target.z + distance
      );

    } else if (orient === 'bottom') {

      cameraTo.set(
        this.minorOrbitControl.target.x,
        this.minorOrbitControl.target.y + 0.1,
        this.minorOrbitControl.target.z - distance
      );

    }

    this.minorCamera.position.copy(cameraTo);
    this.minorCamera.updateProjectionMatrix();
    this.minorOrbitControl.update();

  }

  moveToOrientFace(orient: string) {

    let distance = this.orthoCamera.position.distanceTo(this.orthoOrbitControl.target);

    if (orient === 'left') {

      this.cameraTo.set(
        this.orthoOrbitControl.target.x + distance,
        this.orthoOrbitControl.target.y,
        this.orthoOrbitControl.target.z
      );

    } else if (orient === 'right') {

      this.cameraTo.set(
        this.orthoOrbitControl.target.x - distance,
        this.orthoOrbitControl.target.y,
        this.orthoOrbitControl.target.z
      );

    } else if (orient === 'front') {

      this.cameraTo.set(
        this.orthoOrbitControl.target.x,
        this.orthoOrbitControl.target.y + distance,
        this.orthoOrbitControl.target.z
      );

    } else if (orient === 'back') {

      this.cameraTo.set(
        this.orthoOrbitControl.target.x,
        this.orthoOrbitControl.target.y - distance,
        this.orthoOrbitControl.target.z
      );

    } else if (orient === 'top') {

      this.cameraTo.set(
        this.orthoOrbitControl.target.x,
        this.orthoOrbitControl.target.y + 0.1,
        this.orthoOrbitControl.target.z + distance
      );

    } else if (orient === 'bottom') {

      this.cameraTo.set(
        this.orthoOrbitControl.target.x,
        this.orthoOrbitControl.target.y + 0.1,
        this.orthoOrbitControl.target.z - distance
      );

    }

  }

  setPerspFromOrtho(
    cameraAngle: number = this.cameraAngle,
    srcCamera: OrthographicCamera = this.orthoCamera,
    srcOrbitControl: OrbitControl = this.orthoOrbitControl,
    destCamera: PerspectiveCamera = this.perspCamera,
    destOrbitControl: OrbitControl = this.perspOrbitControl
  ) {

    let offset = srcCamera.position.clone().sub(srcOrbitControl.target);
    let direction = offset.normalize();
    let height = this.height / srcCamera.zoom;
    let length = height / 2 / Math.tan(cameraAngle * Math.PI / 360.0);

    destOrbitControl.target.copy(srcOrbitControl.target);
    destCamera.position.copy(destOrbitControl.target).add(direction.clone().multiplyScalar(length));
    destCamera.fov = cameraAngle;

    destCamera.updateProjectionMatrix();
    destOrbitControl.update();

  }

  setOrthoFromPersp(
    srcCamera: PerspectiveCamera = this.perspCamera,
    srcOrbitControl: OrbitControl = this.perspOrbitControl,
    destCamera: OrthographicCamera = this.orthoCamera,
    destOrbitControl: OrbitControl = this.orthoOrbitControl
  ) {

    let offset = srcCamera.position.clone().sub(srcOrbitControl.target);
    let length = offset.length();
    let direction = offset.normalize();
    let height = length * Math.tan(srcCamera.fov * Math.PI / 360.0) * 2;

    destOrbitControl.target.copy(srcOrbitControl.target);
    destCamera.position.copy(destOrbitControl.target).add(direction.clone().multiplyScalar(SCENE_DIMENSION / 2));
    destCamera.zoom = this.height / height;

    destCamera.updateProjectionMatrix();
    destOrbitControl.update();

  }

  adjustCameraControl() {

    let newState: any = {};
    let moveSpeed = 0.2;

    if (this.cameraAngle <= 10 && this.sculptControlTargetTo && this.cameraOffset) {

      this.orthoOrbitControl.target.copy(this.sculptControlTargetTo);
      this.orthoOrbitControl.targetOffset.copy(this.cameraOffset);
      this.orthoCamera.updateProjectionMatrix();
      this.orthoOrbitControl.update();

    } else if (this.cameraAngle <= 10 && this.orthoOrbitControl.targetOffset && this.orthoOrbitControl.targetOffset.x !== 0 && this.orthoOrbitControl.targetOffset.y !== 0) {

      if (this.orthoOrbitControl.prevTargetOffset) {

        this.controlTargetTo.add(this.orthoOrbitControl.prevTargetOffset);
        this.cameraTo.add(this.orthoOrbitControl.prevTargetOffset);

      }

      this.orthoOrbitControl.targetOffset.set(0, 0);

      this.orthoCamera.position.copy(this.cameraTo);
      this.orthoOrbitControl.target.copy(this.controlTargetTo);
      this.orthoCamera.zoom = this.zoomTo;
      this.orthoCamera.updateProjectionMatrix();
      this.orthoOrbitControl.update();

    } else {

      let hasUpdate = false;

      if (!this.orbitControlChange) {

        if (isNaN(this.cameraTo.x) || isNaN(this.cameraTo.y) || isNaN(this.cameraTo.z)) {
          if (this.cameraInfo && this.cameraInfo.position)
            this.cameraTo = getThreeVectorFromVec3(this.cameraInfo.position);
          else
            this.cameraTo.set(0, 0, 0);
        }

        if (isNaN(this.controlTargetTo.x) || isNaN(this.controlTargetTo.y) || isNaN(this.controlTargetTo.z)) {
          if (this.cameraInfo && this.cameraInfo.target)
            this.controlTargetTo = getThreeVectorFromVec3(this.cameraInfo.target);
          else
            this.controlTargetTo.set(0, SCENE_DIMENSION / 2, 0);
        }

        if (isNaN(this.zoomTo)) {
          if (this.cameraInfo && this.cameraInfo.zoom)
            this.zoomTo = this.cameraInfo.zoom;
          else
            this.zoomTo = 1;
        }

        let cameraDistance = this.cameraTo.distanceToSquared(this.orthoCamera.position);

        if (cameraDistance > CAMERA_EPS) {

          let newPos = new Vector3()
            .addVectors(
              this.cameraTo.clone().multiplyScalar(moveSpeed),
              this.orthoCamera.position.clone().multiplyScalar(1 - moveSpeed)
            );
          this.orthoCamera.position.copy(newPos);

        }

        let controlDistance = this.controlTargetTo.distanceToSquared(this.orthoOrbitControl.target);

        if (controlDistance > CAMERA_EPS) {

          let newPos = new Vector3()
            .addVectors(
              this.controlTargetTo.clone().multiplyScalar(moveSpeed),
              this.orthoOrbitControl.target.clone().multiplyScalar(1 - moveSpeed)
            );
          this.orthoOrbitControl.target.copy(newPos);

        }

        let zoomDistance = Math.abs(this.zoomTo - this.orthoCamera.zoom);

        if (zoomDistance > CAMERA_EPS) {

          this.orthoCamera.zoom = this.zoomTo * moveSpeed + this.orthoCamera.zoom * (1 - moveSpeed);
          this.orthoCamera.updateProjectionMatrix();
          hasUpdate = true;

        }

        if (cameraDistance > CAMERA_EPS || controlDistance > CAMERA_EPS) {

          this.orthoOrbitControl.update();
          hasUpdate = true;

        } else if (cameraDistance !== 0 || controlDistance !== 0) {

          this.orthoCamera.position.copy(this.cameraTo);
          this.orthoOrbitControl.target.copy(this.controlTargetTo);

          this.orthoOrbitControl.update();

        }

      }

      if (this.instantUpdateCamera) {

        this.orthoCamera.position.copy(this.cameraTo);
        this.orthoCamera.zoom = this.zoomTo;
        this.orthoCamera.updateProjectionMatrix();

        this.orthoOrbitControl.target.copy(this.controlTargetTo);
        this.orthoOrbitControl.update();
        hasUpdate = true;

      }

      if (!hasUpdate) {

        if (this.rotateCamera) {

          this.orthoOrbitControl.autoRotate = true;
          this.orthoOrbitControl.update();
          this.orthoOrbitControl.autoRotate = false;

          this.cameraTo = this.orthoCamera.position.clone();
          this.controlTargetTo = this.orthoOrbitControl.target.clone();
          this.zoomTo = this.orthoCamera.zoom;

        }

      }

    }

    this.setPerspFromOrtho();

    if (this.cubeCamera) {

      this.cubeCamera.position.copy(this.orthoCamera.position);
      this.cubeCamera.position.sub(this.orthoOrbitControl.target);

      this.cubeCamera.position.setLength(275);
      this.cubeCamera.lookAt(0, 0, 0);

    }

    newState.cameraPos = this.orthoCamera.position.toArray().map(v => _n(v));
    newState.targetPos = this.orthoOrbitControl.target.toArray().map(v => _n(v));
    newState.zoom = _n(this.orthoCamera.zoom);

    let hadUpdate = !lod.isEqual(newState, this.oldCameraState);
    this.oldCameraState = newState;
    return hadUpdate;

  }

  adjustMinorCameraControl() {

    if (!this.minorCamera || !this.minorOrbitControl)
      return false;

    let newState: any = {};

    newState.cameraPos = this.minorCamera.position.toArray().map(v => _n(v));
    newState.targetPos = this.minorOrbitControl.target.toArray().map(v => _n(v));
    newState.zoom = _n(this.minorCamera.zoom);

    let hadUpdate = !lod.isEqual(newState, this.oldMinorCameraState);
    this.oldMinorCameraState = newState;
    return hadUpdate;

  }

  animate(time?: number) {

    let zoom = 1 / this.orthoCamera.zoom;

    this.stats && this.stats.begin();

    let hadUpdate = !!this.blobObject;
    let minorHasUpdate = false;
    let subHasUpdate;

    if (!this.preventControlsValidate) {

      this.sceneMIM.validate();
      subHasUpdate = this.sceneMIM.popHadUpdate();

      hadUpdate = subHasUpdate || hadUpdate;
      minorHasUpdate = subHasUpdate || minorHasUpdate;

      this.selection.groupMIM.validate();
      subHasUpdate = this.selection.groupMIM.popHadUpdate();

      hadUpdate = subHasUpdate || hadUpdate;
      minorHasUpdate = subHasUpdate || minorHasUpdate;

      for (let id in this.selection.groupMIMs) {

        this.selection.groupMIMs[id].validate();
        subHasUpdate = this.selection.groupMIMs[id].popHadUpdate();

        hadUpdate = subHasUpdate || hadUpdate;
        minorHasUpdate = subHasUpdate || minorHasUpdate;

      }

    }

    hadUpdate = this.adjustCameraControl() || hadUpdate;

    if (this.minorRenderer)
      minorHasUpdate = this.adjustMinorCameraControl() || minorHasUpdate;

    this.alignControl.setSize(zoom);
    this.arrayControl.setSize(zoom);
    this.sculptControl.setSize(zoom);
    this.transformControl.setSize(zoom);
    this.snapControl.setSize(zoom);
    this.magnetControl.setSize(zoom);
    this.mirrorControl.setSize(zoom);

    for (let lightId in this.lights) {
      let helper = this.lights[lightId].helper;
      if (helper && helper.positionPicker)
        helper.positionPicker.setSize(zoom);
      if (helper && helper.targetPicker)
        helper.targetPicker.setSize(zoom);
    }

    if (!this.preventControlsValidate) {

      hadUpdate = this.transformControl.refresh() || hadUpdate;
      hadUpdate = this.snapControl.refresh() || hadUpdate;
      hadUpdate = this.magnetControl.refresh() || hadUpdate;
      hadUpdate = this.mirrorControl.refresh() || hadUpdate;
      hadUpdate = this.alignControl.refresh() || hadUpdate;
      hadUpdate = this.arrayControl.refresh() || hadUpdate;
      hadUpdate = this.sculptControl.refresh() || hadUpdate;

    }

    hadUpdate = this.envMapChanged || hadUpdate;
    minorHasUpdate = this.envMapChanged || minorHasUpdate;

    if (this.envMapChanged) {

      if (this.backgroundEnvMapType === EnvMapTypes.Lighting || this.backgroundEnvMapType === EnvMapTypes.Image) {

        this.envMapScene.background = this.envMapTexture as Texture;

      } else if (this.backgroundEnvMapType === EnvMapTypes.Color) {

        this.envMapScene.background = this.envMapTexture as Color;

      } else {

        this.envMapScene.background = null;

      }

      this.envMapChanged = false;

    }

    this.controlRenderer.render(this.controlScene, this.getCamera());


    if (minorHasUpdate && this.minorRenderer && this.selection.tool !== Tools.Sculpt) {

      this.renderMinor(time);

    }

    if (hadUpdate) {

      this.render(time);
      this.drawCallPanel && this.drawCallPanel.update(this.renderer.info.render.calls, 100000);

    }

    if (this.viewType === ViewTypes.ServerRendered) {

      let newConfig = this.getCurrentRenderScene();
      let sceneChanging = this.orbitControlChange || this.transformControl.isDragging || this.snapControl.isDragging || this.magnetControl.isDragging || this.mirrorControl.isDragging || this.alignControl.isDragging || this.arrayControl.isDragging || this.sculptControl.isDragging;

      if ((this.serverRenderConfig && !lod.isEqual(newConfig.scene, this.serverRenderConfig.scene)) || sceneChanging) {

        if (!sceneChanging) {

          if (!this.refreshServerRenderTimer) {

            this.refreshServerRenderTimer = setInterval(() => {

              let _newConfig = this.getCurrentRenderScene();

              if (!lod.isEqual(newConfig.scene, _newConfig.scene)) {

                newConfig.scene = _newConfig.scene;

              } else if (!sceneChanging) {

                this.actionCallback({action: ACT_CHANGE_SERVER_RENDER_CONFIG});
                clearInterval(this.refreshServerRenderTimer);
                this.refreshServerRenderTimer = undefined;

              }

            }, 1000);

          }

        }

      } else {

        if (this.refreshServerRenderTimer) {

          clearInterval(this.refreshServerRenderTimer);
          this.refreshServerRenderTimer = undefined;

        }

      }

      if (this.delegate && this.delegate.overlayCanvasElement) {
        if ((this.serverRenderConfig && !lod.isEqual(newConfig.scene, this.currentServerRenderConfig.scene)) || sceneChanging)
          this.delegate.overlayCanvasElement.style.opacity = "0.5";
        else
          this.delegate.overlayCanvasElement.style.opacity = null;
      }

    }

    this.hadUpdatePanel && this.hadUpdatePanel.update(hadUpdate ? 1 : 0, 1);
    this.stats && this.stats.end();

    requestAnimationFrame((time: number) => {

      if (!this.disposed)
        this.animate(time);

    });
    // setTimeout( () => {
    // }, 1000 / 40 );

  }

  renderMinor(time?: number) {

    if (this.minorRenderer && this.minorCamera) {
      this.minorRenderer.render(this.scene, this.minorCamera);

      if (this.delegate)
        this.delegate.renderMinor();
    }

  }

  updateCubes(object: MarchingCubes, time: number, numblobs: number) {

    object.reset();

    if (Object.keys(this.lights).length === 0 && Object.keys(this.models).length === 0)
      return;
    // fill the field with some metaballs

    const rainbow = [
      new THREE.Color(0xff0000),
      new THREE.Color(0xff7f00),
      new THREE.Color(0xffff00),
      new THREE.Color(0x00ff00),
      new THREE.Color(0x0000ff),
      new THREE.Color(0x4b0082),
      new THREE.Color(0x9400d3)
    ];
    const subtract = 12;
    const strength = 1.2 / ((Math.sqrt(numblobs) - 1) / 4 + 1);

    for (let i = 0; i < numblobs; i++) {

      const ballx = Math.sin(i + 1.26 * time * (1.03 + 0.5 * Math.cos(0.21 * i))) * 0.15 + 0.5;
      const bally = Math.cos(i + 1.12 * time * Math.cos(1.22 + 0.1424 * i)) * 0.15 + 0.5;
      const ballz = Math.cos(i + 1.32 * time * Math.sin((0.92 + 0.53 * i))) * 0.15 + 0.5;

      object.addBall(ballx, bally, ballz, strength, subtract, rainbow[i % 7]);

    }

  }

  tick = 0;

  render(time?: number) {

    if (this.blobObject)
      this.updateCubes(this.blobObject, (++this.tick) / 50.0, 6);

    if (this.cubeRenderer && this.cubeScene && this.cubeCamera)
      this.cubeRenderer.render(this.cubeScene, this.cubeCamera);

    if (this.envMapEnabled) {

      this.envMapCamera.rotation.copy(this.orthoCamera.rotation);

      if (this.backgroundEnvMapType === EnvMapTypes.Lighting) {

        this.renderer.autoClear = true;

        this.renderer.render(this.envMapScene, this.envMapCamera);

      } else {

        this.envMapRenderer.render(this.envMapScene, this.envMapCamera);

      }

    }

    this.renderer.autoClear = !this.envMapEnabled;
    this.renderer.render(this.scene, this.getCamera());

    if (this.selection.tool === Tools.Align)
      this.composer.render();

    if (this.delegate)
      this.delegate.render();

  }

}