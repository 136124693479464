import {
  ArrayModifier,
  BreakableComponentTypes,
  BrepParameter,
  Calc,
  ComponentTypes,
  createComponent,
  CurveGenerator,
  CutModifier,
  defaultEitherMaterial,
  duplicateObject,
  EnvMapTypes,
  ExtrudeModifier,
  FilletModifier,
  getCurveFromRef,
  getObjectType,
  GroupModifier,
  GumballParamTitles,
  HollowModifier,
  IAnnotate,
  ICameraInfo,
  IEitherMaterial,
  IEnvironment,
  IFloor,
  IHeatmapData,
  ILight,
  IMeasure,
  IModelInfo,
  IntersectModifier,
  ISceneEnvironmentInfo,
  ISculptStroke,
  isParameterTitle,
  IToolConfig,
  LoftModifier,
  MaterialTypes,
  MeasureUnit,
  MeshParameter,
  MirrorModifier,
  NonMaterialComponentTypes,
  ObjectTypes,
  OffsetModifier,
  Param_Boolean,
  Param_Compound,
  Param_Curve,
  Param_Index,
  Param_Json,
  Param_Mesh,
  Param_Number,
  Param_Point,
  Param_SculptMesh,
  Param_String,
  Param_Transform,
  Param_Vertex,
  Parameter,
  ParamTitles,
  ParamTypes,
  PipeModifier,
  PolylineGenerator,
  registerBrepGeometry,
  registerGeometry,
  registerSculptGeometry,
  Relation,
  RenderedObjectTypes,
  RevolveModifier,
  Scene,
  SculptModifier,
  SurfaceGenerator,
  ThreePlaneGenerator,
  ThreeTextGenerator,
  Tools,
  UnionModifier,
  WeldModifier
} from "../../peregrine/processor";
import {sculpt as sculptTypes} from "../../peregrine/sculpt";
import {CommandManager} from "./command";
import {_n, _s} from "../../peregrine/t";
import {store} from "../../index";
import {IDigitalMaterial, IGAR, ILibraryItem, IProcessor, LibraryItemTypes} from "../../store/types/types";
import lod from "lodash";
import {atomic, SceneOperationResult} from "../action/global";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import Editor3dWrapper from "../3d/Editor3dWrapper";
import {mat4, quat, vec3} from "gl-matrix";
import {applySnapIncrement} from "../common/three";
import {
  getBoundingBoxCenter, getBoundingBoxFromGeometry,
  getBoundingBoxFromMesh,
  getBoundingBoxFromMeshes,
  getBoundingBoxSize,
  getUniqueDesc,
  isIdentity,
  WompBrepData, WompMesh,
  WompMeshData,
  WompObjectRef
} from "../../peregrine/WompObject";
import {peregrineId} from "../../peregrine/id";
import {DeepPartial} from "ts-essentials";
import {batch} from "react-redux";
import {addLibraryItems, removeLibraryItems} from "../../store/actions/entity/library-items";
import {addEnvironments, removeEnvironment} from "../../store/actions/entity/environments";
import {addProcessors, removeProcessors} from "../../store/actions/entity/processors";
import {addDigitalMaterials, removeDigitalMaterial} from "../../store/actions/entity/digital-materials";
import {removeLight} from "../../store/actions/entity/lights";
import {composeTransform, decomposeTransform} from "../../peregrine/utils";
import hash from "object-hash";

export enum SpecialIndex {
  Min = -2,
  Max = -1,
  Set = -3
}

export interface ISimpleCommand {
  id: string
  index: number
  operation: string
  username: string
  createdDate: Date
  description: string
}

export interface IServer {
  commands: ISimpleCommand[]
  cursor: number
}

export enum SyncType {
  View,
  Watch,
  Edit
}

export interface IRoute {
  id: number
  startCursor: number
  startCommand: string
  syncType: SyncType
  infoAcceptingRouteIds: number[]
}

export class ProjectScene extends Scene {
  commandManager = new CommandManager();
  server: IServer = {
    commands: [],
    cursor: 0
  };
  route: IRoute = {
    id: 0,
    startCursor: 0,
    startCommand: '',
    syncType: SyncType.View,
    infoAcceptingRouteIds: []
  };

  static async init(jData?: any, onProgress?: (progress: number) => void): Promise<ProjectScene> {
    let scene = await super.decode(jData, onProgress) as ProjectScene;
    scene.commandManager.init(scene.dataWithLibrary);

    return scene;
  }

  protected get dataWithLibrary() {
    let data: any = {};
    data[_s('scene')] = this.data;

    let digitalMaterialById = store.getState().entities.digitalMaterials.byId;
    let environmentById = store.getState().entities.environments.byId;
    let libraryItemById = store.getState().entities.libraryItems.byId;
    let username = store.getState().entities.auth.username;

    let libraryItems = Object.values(libraryItemById)
      .filter(item => item.projectId === this.projectId || item.username === username && (item.entityType === LibraryItemTypes.DigitalMaterial || item.entityType === LibraryItemTypes.Environment));

    let digitalMaterials = libraryItems.filter(item => item.entityType === LibraryItemTypes.DigitalMaterial)
      .map(item => digitalMaterialById[item.entityId])
      .filter(mat => mat)
      .map(mat => lod.omit(mat, ['lastUsedDate']));

    let environments = libraryItems.filter(item => item.entityType === LibraryItemTypes.Environment)
      .map(item => environmentById[item.entityId])
      .filter(env => env);

    data[_s('libraryItems')] = lod.keyBy(libraryItems, 'id');
    data[_s('digitalMaterials')] = lod.keyBy(digitalMaterials, 'id');
    data[_s('environments')] = lod.keyBy(environments, 'id');

    return data;
  }

  get snapshot() {
    let data: any = {};

    data[_s('calcs')] = lod.mapValues(this.calcs, c => ({
      [_s('visible')]: c.visible,
      [_s('properties')]: c.fullObjects.map(o => o.property)
    }));
    data[_s('backgroundEnvironment')] = lod.omit(this.data[_s('backgroundEnvironment')], [_s('id'), _s('thumbnail'), _s('title')]);
    data[_s('lightEnvironment')] = lod.omit(this.data[_s('lightEnvironment')], [_s('id'), _s('thumbnail'), _s('title')]);
    data[_s('isSetAsBackground')] = this.data[_s('isSetAsBackground')];
    data[_s('lights')] = lod.mapValues(this.data[_s('lights')], l => l[_s('light')]);
    data[_s('cameraInfo')] = this.data[_s('cameraInfo')];
    data[_s('viewType')] = this.data[_s('viewType')];

    let digitalMaterialById = store.getState().entities.digitalMaterials.byId;
    let libraryItemById = store.getState().entities.libraryItems.byId;

    let libraryItems = Object.values(libraryItemById)
      .filter(item => item.projectId === this.projectId);

    let digitalMaterials = libraryItems.filter(item => item.entityType === LibraryItemTypes.DigitalMaterial)
      .map(item => digitalMaterialById[item.entityId])
      .filter(mat => mat)
      .map(mat => lod.omit(mat, ['lastUsedDate', 'thumbnail']));

    data[_s('digitalMaterials')] = lod.keyBy(digitalMaterials, 'id');

    return data;
  }

  protected addCalcToLevel = (calc: Calc, level: string, options: Partial<{ calcIds: string[], excludeCalcIds: string[], setup: () => void }> = {}): string[] => {
    let calcTitles: string[] = [];
    this.addCalc(calc);

    if (options.setup) {
      options.setup();
    }

    let calcIds = options.calcIds || [];
    let excludeCalcIds = options.excludeCalcIds || [];

    if (calcIds.length === 0)
      calcTitles = [calc.title];

    for (let sourceCalcId of calcIds) {
      let sourceCalc = this.getCalcById(sourceCalcId);
      if (sourceCalc.isInvalid) continue;

      sourceCalc.visible = false;
      calcTitles.push(sourceCalc.title);

      if (!excludeCalcIds.includes(sourceCalcId)) {
        for (let param of sourceCalc.renderableOutputs())
          this.addRelation(param, calc.inputByTitle(ParamTitles.Object));
      }
    }

    let topCalc = this.getCalcById(level);

    if (topCalc.isInvalid)
      return calcTitles;

    for (let param of calc.renderableOutputs()) {
      this.addRelation(param, topCalc.inputByTitle(ParamTitles.Object));
    }
    calc.visible = false;

    for (let sourceCalcId of calcIds) {
      let sourceCalc = this.getCalcById(sourceCalcId);
      if (sourceCalc.isInvalid)
        continue;

      for (let param of sourceCalc.renderableOutputs()) {
        let relation = this.getCalcRelation(param, topCalc.inputByTitle(ParamTitles.Object));
        if (relation)
          this.deleteRelation(relation);
      }
    }

    return calcTitles;
  }

  protected isHistoryDisabled = () => {
    let project = store.getState().entities.projects.byId[this.projectId];

    return project && project.isPublic;
  };

  protected addSceneCalcToLevel = (calc: Calc, level: string, options: Partial<{ calcIds: string[], excludeCalcIds: string[], setup: () => void }> = {}): string[] => {
    calc.title = this.generateCalcTitle(calc.title);

    let calcTitles = this.addCalcToLevel(calc, level, options);

    this.selectedCalcIds = [calc.id];
    this.tool = Tools.Gumball;

    return calcTitles;
  }

  groupCalcs = (calcs: Calc[], title: string) => {
    let group = calcs.length > 1;
    let groupCalc;

    if (group) {
      groupCalc = GroupModifier.create();
      groupCalc.title = this.generateCalcTitle(title);

      this.addCalc(groupCalc);
    }

    for (let calc of calcs) {
      calc.title = this.generateCalcTitle(title);

      if (groupCalc) {
        for (let param of calc.renderableOutputs()) {
          this.addRelation(param, groupCalc.inputByTitle(ParamTitles.Object));
        }

        calc.visible = false;
      }
    }
  }

  updateSceneParameterWithKey(key: string, value: any) {
    let tokens = key.split(':');
    if (tokens.length < 3) return;

    let calcId = tokens[0];
    let calc = this.getCalcById(calcId);

    if (calc.isInvalid) return;

    let i = Number(tokens[2]);

    let j = undefined;
    if (tokens.length > 3) {
      if (tokens[3] === 'min') i = SpecialIndex.Min;
      else if (tokens[3] === 'max') i = SpecialIndex.Max;
      else j = Number(tokens[3]);
    }

    if (tokens.length > 4) {
      if (tokens[4] === 'min') i = SpecialIndex.Min;
      else if (tokens[4] === 'max') i = SpecialIndex.Max;
    }

    let paramId = tokens[1];
    let param;
    if (isParameterTitle(paramId))
      param = calc.paramByTitle(paramId);
    else
      param = calc.getParameter(paramId);

    if (param.isInvalid) {
      this.reverseUpdate(calc, paramId, undefined, value, i, j);
    } else {
      this.updateSceneParameter(param, value, i, j);
    }

    return calc;
  }

  updateSceneParameter(param: Parameter, value: any, i: number, j?: number): any {
    if (param.isInvalid) return;
    let calc = param.calc as Calc;

    let newValue: any = value.value;
    let oldValue: any;
    switch (param.objectType) {
      case ParamTypes.Number:
        let numParam = param as Param_Number;

        if (numParam.step !== 0)
          newValue = Math.round(newValue / numParam.step) * numParam.step;

        if (i === SpecialIndex.Set) {
          oldValue = numParam.values[0];

          numParam.values = [newValue];
        }
        if (i === SpecialIndex.Min) {
          let values = lod.cloneDeep(numParam.values);
          let bound = lod.cloneDeep(numParam.bound);

          bound[0] = newValue;
          for (let k = 0; k < values.length; ++k) {
            if (values[k] < newValue) {
              oldValue = numParam.values[k];

              values[k] = newValue;
            }
          }

          numParam.bound = bound;
          numParam.values = values;
        } else if (i === SpecialIndex.Max) {
          let values = lod.cloneDeep(numParam.values);
          let bound = lod.cloneDeep(numParam.bound);

          bound[1] = newValue;
          for (let k = 0; k < values.length; ++k) {
            if (values[k] > newValue) {
              oldValue = values[k];

              values[k] = newValue;
            }
          }

          numParam.bound = bound;
          numParam.values = values;
        } else if (i >= 0) {
          let values = lod.cloneDeep(numParam.values);
          let bound = lod.cloneDeep(numParam.bound);

          if (numParam.lockBound[0]) {
            newValue = Math.max(newValue, bound[0]);
          } else {
            bound[0] = Math.min(newValue, bound[0]);
          }
          if (numParam.lockBound[1]) {
            newValue = Math.min(newValue, bound[1]);
          } else {
            bound[1] = Math.max(newValue, bound[1]);
          }

          oldValue = values[i];

          values[i] = newValue;

          numParam.bound = bound;
          numParam.values = values;
        }
        break;
      case ParamTypes.Point:
        let pointParam = param as Param_Point;

        if (i === SpecialIndex.Set) {
          let point = newValue as vec3;
          oldValue = pointParam.values[0];

          for (let k = 0; k < point.length; ++k)
            point[k] = Math.round(point[k] / pointParam.steps[k]) * pointParam.steps[k];

          pointParam.values = [point];
        } else {
          if (j === undefined) break;

          if (pointParam.steps[j] !== 0)
            newValue = Math.round(newValue / pointParam.steps[j]) * pointParam.steps[j];

          if (i === SpecialIndex.Min) {
            let values = lod.cloneDeep(pointParam.values);
            let bounds = lod.cloneDeep(pointParam.bounds);

            bounds[j][0] = newValue;
            for (let k = 0; k < values.length; ++k) {
              let point = values[k] as vec3;

              if (point[j] < newValue) {
                oldValue = point[j];

                point[j] = newValue;
              }
            }

            pointParam.bounds = bounds;
            pointParam.values = values;
          } else if (i === SpecialIndex.Max) {
            let values = lod.cloneDeep(pointParam.values);
            let bounds = lod.cloneDeep(pointParam.bounds);

            bounds[j][1] = newValue;
            for (let k = 0; k < values.length; ++k) {
              let point = values[k] as vec3;

              if (point[j] > newValue) {
                oldValue = point[j];

                point[j] = newValue;
              }
            }

            pointParam.bounds = bounds;
            pointParam.values = values;
          } else if (i >= 0) {
            let values = lod.cloneDeep(pointParam.values);
            let bounds = lod.cloneDeep(pointParam.bounds);

            let point = values[i] as vec3;

            if (pointParam.lockBounds[j][0]) {
              newValue = Math.max(newValue, bounds[j][0]);
            } else {
              bounds[j][0] = Math.min(newValue, bounds[j][0]);
            }
            if (pointParam.lockBounds[j][1]) {
              newValue = Math.min(newValue, bounds[j][1]);
            } else {
              bounds[j][1] = Math.max(newValue, bounds[j][1]);
            }

            oldValue = point[j];

            point[j] = newValue;

            pointParam.bounds = bounds;
            pointParam.values = values;
          }
        }
        break;
      default: {
        let values = lod.cloneDeep((param as Param_Number).values);
        oldValue = values[i];

        values[i] = newValue;
        (param as Param_Number).values = values;
        break;
      }
    }

    if (oldValue === newValue) {
    } else {
      if (oldValue && newValue && oldValue.equals && newValue.equals && oldValue.equals(newValue)) {
      } else {
        // console.log('value change', calc.title, param.title, oldValue, newValue)
      }
    }

    if (!param.isInput && param.isOperable) {
      this.reverseUpdate(calc, param.title, oldValue, value, i, j);
    }

    if (param.title === ParamTitles.Transform) {
      for (let sourceId in this.magnetMappings) {
        let destId = this.magnetMappings[sourceId];
        if (destId === calc.id) {
          let fromCalc = this.calcs[sourceId];
          let relativeValue = mat4.multiply(mat4.create(), newValue, mat4.invert(mat4.create(), oldValue));
          let transformParam = fromCalc.inputByTitle(ParamTitles.Transform) as Param_Transform;
          if (transformParam.isInvalid) {
            this.updateSceneParameterWithKey(fromCalc.id + ':transform:' + i, {value: relativeValue});
          } else {
            this.updateSceneParameterWithKey(fromCalc.id + ':transform:' + i, {value: mat4.multiply(mat4.create(), relativeValue, transformParam.values[0])});
          }
        }
      }
    }
  }

  reverseUpdate(calc: Calc, paramId: string, oldValue: any, value: any, i: number, j: number | undefined) {
    let newValue = value.value;
    let targetParam;
    // console.log('reverseUpdate', paramId, oldValue, value, i, j)

    if (isParameterTitle(paramId))
      targetParam = calc.paramByTitle(paramId) as Param_Point;
    else
      targetParam = calc.getParameter(paramId) as Param_Point;

    let transformParam = calc.inputByTitle(ParamTitles.Transform) as Param_Transform;

    if (transformParam.isInvalid) {
      let orgCenterParam = calc.outputByTitle(ParamTitles.OrgCenter) as Param_Point;
      let translate = mat4.create();
      let invTranslate = mat4.create();
      let neg = vec3.create();

      if (!orgCenterParam.isInvalid) {
        mat4.fromTranslation(translate, orgCenterParam.values[0]);
        mat4.fromTranslation(invTranslate, vec3.negate(neg, orgCenterParam.values[0]));
      }

      if (paramId === ParamTitles.Transform) {
        let mirrorParam = calc.inputByTitle(ParamTitles.Mirror) as Param_Transform;
        let mirror = mat4.create();
        let invMirror = mat4.create();

        if (!mirrorParam.isInvalid) {
          mat4.copy(mirror, mirrorParam.values[0]);
          mat4.invert(invMirror, mirror);
        }

        for (let inputId of calc.inputIds) {
          let param = calc.inputs[inputId];
          for (let relation of param.prevRelations) {
            let fromCalc = ((relation as Relation).from.calc as Calc);

            let baseTransformParam = fromCalc.inputByTitle(ParamTitles.Transform) as Param_Transform;
            let baseTransform = mat4.create();
            if (!baseTransformParam.isInvalid)
              baseTransform = mat4.clone(baseTransformParam.values[0]);

            let baseOrgCenterParam = fromCalc.outputByTitle(ParamTitles.OrgCenter) as Param_Point;
            let baseTranslate = mat4.create();
            let invBaseTranslate = mat4.create();
            if (!baseOrgCenterParam.isInvalid) {
              mat4.fromTranslation(baseTranslate, baseOrgCenterParam.values[0]);
              mat4.fromTranslation(invBaseTranslate, vec3.negate(neg, baseOrgCenterParam.values[0]));
            }

            mat4.multiply(baseTransform, baseTranslate, baseTransform);
            if (!mirrorParam.isInvalid)
              mat4.multiply(baseTransform, mirror, baseTransform);
            mat4.multiply(baseTransform, invTranslate, baseTransform);
            mat4.multiply(baseTransform, newValue, baseTransform);
            mat4.multiply(baseTransform, translate, baseTransform);
            if (!mirrorParam.isInvalid)
              mat4.multiply(baseTransform, invMirror, baseTransform);
            mat4.multiply(baseTransform, invBaseTranslate, baseTransform);

            this.updateSceneParameterWithKey(fromCalc.id + ':transform:' + i, {value: baseTransform});
          }
        }

        let distanceParam = calc.inputByTitle(ParamTitles.Distance) as Param_Number;

        if (!distanceParam.isInvalid) {
          let {uniform, unitScale} = decomposeTransform(newValue);
          if (isIdentity(unitScale)) {
            let oldDistance = distanceParam.values[0];
            this.updateSceneParameterWithKey(calc.id + ':distance:' + i, {value: oldDistance / uniform});
          }
        }

        let sculptTransformParam = calc.inputByTitle(ParamTitles.SculptTransform) as Param_Transform;

        if (!sculptTransformParam.isInvalid) {
          let baseTransform = mat4.clone(sculptTransformParam.values[i]);

          let baseOriginalCenterParam = calc.outputByTitle(ParamTitles.OrgCenter) as Param_Point;
          let baseOriginalCenter: vec3;
          if (baseOriginalCenterParam.isInvalid)
            baseOriginalCenter = vec3.create();
          else
            baseOriginalCenter = baseOriginalCenterParam.values[0];

          mat4.multiply(baseTransform, mat4.fromTranslation(mat4.create(), vec3.negate(vec3.create(), baseOriginalCenter)), baseTransform);
          mat4.multiply(baseTransform, newValue, baseTransform);
          mat4.multiply(baseTransform, mat4.fromTranslation(mat4.create(), baseOriginalCenter), baseTransform);

          this.updateSceneParameterWithKey(calc.id + ':sculpt transform:' + i, {value: baseTransform});
        }
      } else if (paramId === ParamTitles.Position) {
        let positionParam = calc.outputByTitle(ParamTitles.Position) as Param_Point;
        let position;
        let orgPosition;
        if (positionParam.isInvalid)
          position = vec3.create();
        else
          position = positionParam.values[0];

        if (i >= 0) {
          if (j !== undefined) {
            orgPosition = vec3.clone(positionParam.values[0]);
            orgPosition[j] = oldValue;
          }
        } else if (i === SpecialIndex.Set) {
          orgPosition = oldValue;
        }

        if (orgPosition) {
          this.updateSceneParameterWithKey(
            calc.id + ':transform:0',
            {
              value: composeTransform(
                vec3.scaleAndAdd(position, position, orgPosition, -1),
                vec3.create(),
                vec3.fromValues(1, 1, 1),
                vec3.create()
              )
            }
          );
        }
      } else if (paramId === ParamTitles.GlobalSize) {
        let globalSizeParam = calc.outputByTitle(ParamTitles.GlobalSize) as Param_Point;
        let globalSize;
        let orgGlobalSize;
        if (globalSizeParam.isInvalid)
          globalSize = vec3.create();
        else
          globalSize = globalSizeParam.values[0];

        if (i >= 0) {
          if (j !== undefined) {
            orgGlobalSize = vec3.clone(globalSizeParam.values[0]);
            orgGlobalSize[j] = oldValue;
          }
        } else if (i === SpecialIndex.Set) {
          orgGlobalSize = oldValue;
        }

        if (orgGlobalSize) {
          let ratio = vec3.fromValues(
            orgGlobalSize[0] === 0 ? 1 : globalSize[0] / orgGlobalSize[0],
            orgGlobalSize[1] === 0 ? 1 : globalSize[1] / orgGlobalSize[1],
            orgGlobalSize[2] === 0 ? 1 : globalSize[2] / orgGlobalSize[2]
          );

          this.updateSceneParameterWithKey(
            calc.id + ':transform:0',
            {
              value: composeTransform(
                vec3.create(),
                vec3.create(),
                ratio,
                vec3.create()
              )
            }
          );
        }
      }
    } else {
      if (!targetParam.isInvalid) {
        let paramTitle = targetParam.title;

        if (GumballParamTitles.includes(paramTitle)) {
          let positionParam = calc.outputByTitle(ParamTitles.Position) as Param_Point;
          let rotateParam = calc.outputByTitle(ParamTitles.Rotate) as Param_Point;
          let translateParam = calc.outputByTitle(ParamTitles.Translate) as Param_Point;
          let skewParam = calc.outputByTitle(ParamTitles.Skew) as Param_Point;
          let scaleParam = calc.outputByTitle(ParamTitles.Scale) as Param_Point;
          let position, translate, rotate, skew, scale;

          if (positionParam.isInvalid)
            position = vec3.create();
          else
            position = positionParam.values[0];

          if (translateParam.isInvalid)
            translate = vec3.create();
          else
            translate = translateParam.values[0];

          if (rotateParam.isInvalid)
            rotate = vec3.create();
          else
            rotate = vec3.scale(vec3.create(), rotateParam.values[0], Math.PI / 180);

          if (skewParam.isInvalid)
            skew = vec3.create();
          else
            skew = skewParam.values[0];

          if (scaleParam.isInvalid)
            scale = vec3.fromValues(1, 1, 1);
          else
            scale = vec3.scale(vec3.create(), scaleParam.values[0], 0.01);

          if (paramTitle === ParamTitles.Position) {
            let baseTransform = mat4.clone(transformParam.values[0]);
            let orgPosition;

            if (i >= 0) {
              if (j !== undefined) {
                orgPosition = vec3.clone(positionParam.values[0]);
                orgPosition[j] = oldValue;
              }
            } else if (i === SpecialIndex.Set) {
              orgPosition = oldValue;
            }

            if (orgPosition) {
              mat4.multiply(baseTransform, mat4.fromTranslation(mat4.create(), vec3.scaleAndAdd(vec3.create(), position, orgPosition, -1)), baseTransform);

              this.updateSceneParameterWithKey(calc.id + ':transform:0', {value: baseTransform});
            }
          } else if (paramTitle === ParamTitles.LocalSize) {
            let localSizeParam = calc.outputByTitle(ParamTitles.LocalSize) as Param_Point;
            let localSize = localSizeParam.values[0];
            let baseTransform = mat4.clone(transformParam.values[0]);
            let orgSize;

            if (i >= 0) {
              if (j !== undefined) {
                orgSize = vec3.clone(localSizeParam.values[0]);
                orgSize[j] = oldValue;
              }
            } else if (i === SpecialIndex.Set) {
              orgSize = oldValue;
            }

            if (orgSize) {
              let relativeScale = vec3.fromValues(
                orgSize[0] ? localSize[0] / orgSize[0] : 1,
                orgSize[1] ? localSize[1] / orgSize[1] : 1,
                orgSize[2] ? localSize[2] / orgSize[2] : 1
              );

              let quatMat = mat4.create();
              mat4.multiply(quatMat, mat4.fromQuat(mat4.create(), quat.fromEuler(quat.create(), 0, 0, rotate[2] * 180 / Math.PI)), quatMat);
              mat4.multiply(quatMat, mat4.fromQuat(mat4.create(), quat.fromEuler(quat.create(), rotate[0] * 180 / Math.PI, rotate[1] * 180 / Math.PI, 0)), quatMat);

              mat4.multiply(baseTransform, mat4.fromTranslation(mat4.create(), vec3.negate(vec3.create(), translate)), baseTransform);
              mat4.multiply(baseTransform, mat4.invert(mat4.create(), quatMat), baseTransform);
              mat4.multiply(baseTransform, mat4.fromScaling(mat4.create(), relativeScale), baseTransform);
              mat4.multiply(baseTransform, quatMat, baseTransform);
              mat4.multiply(baseTransform, mat4.fromTranslation(mat4.create(), translate), baseTransform);

              this.updateSceneParameterWithKey(calc.id + ':transform:0', {value: baseTransform});
            }
          } else if (paramTitle === ParamTitles.GlobalSize) {
            let globalSizeParam = calc.outputByTitle(ParamTitles.GlobalSize) as Param_Point;
            let size = globalSizeParam.values[0];
            let baseTransform = mat4.clone(transformParam.values[0]);
            let orgSize;

            if (i >= 0) {
              if (j !== undefined) {
                orgSize = vec3.clone(globalSizeParam.values[0]);
                orgSize[j] = oldValue;
              }
            } else if (i === SpecialIndex.Set) {
              orgSize = oldValue;
            }

            if (orgSize) {
              let relativeScale = vec3.fromValues(
                orgSize[0] ? size[0] / orgSize[0] : 1,
                orgSize[1] ? size[1] / orgSize[1] : 1,
                orgSize[2] ? size[2] / orgSize[2] : 1
              );

              mat4.multiply(baseTransform, mat4.fromTranslation(mat4.create(), vec3.negate(vec3.create(), position)), baseTransform);
              mat4.multiply(baseTransform, mat4.fromScaling(mat4.create(), relativeScale), baseTransform);
              mat4.multiply(baseTransform, mat4.fromTranslation(mat4.create(), position), baseTransform);

              this.updateSceneParameterWithKey(calc.id + ':transform:0', {value: baseTransform});
            }
          } else {
            if (i >= 0 || i === SpecialIndex.Set) {
              this.updateSceneParameterWithKey(calc.id + ':transform:0', {value: composeTransform(translate, rotate, scale, skew)});
            }
          }
        }
      }
    }
  }


  createCommand(operation: string, description: string) {
    if (operation && !this.isHistoryDisabled()) {
      let lastIndex;
      if (this.commandManager.lastCommand)
        lastIndex = this.commandManager.lastCommand.index + 1;
      else
        lastIndex = this.route.startCursor + 1;
      return this.commandManager.createCommand(lastIndex, operation, this.dataWithLibrary, description);
    }
  }

  getProject() {
    return store.getState().entities.projects.byId[this.projectId];
  }

  getLibraryCategory(title: string, topTitle: string) {
    return Object.values(store.getState().entities.libraryCategories.byId).filter(c => (!title || c.title === title) &&
      (!topTitle || (store.getState().entities.libraryTopCategories.byId[c.topCategoryId] || {title: ''}).title === topTitle))[0];
  }

  addProcessor(_: any, processor: IProcessor, position?: vec3): SceneOperationResult {
    let calc = createComponent(processor.name);

    if (calc && !calc.isInvalid) {
      if (position) {
        let param = calc.inputByTitle(ParamTitles.Transform) as Param_Transform;
        let value = mat4.create();

        applySnapIncrement(position, this.floor.snapIncrement);
        mat4.fromTranslation(value, position);
        param.values = [value];
      }

      let calcTitles = this.addSceneCalcToLevel(calc, this.editLevel);

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `created <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addTextProcessor(_: any, text: string, height: number, depth: number): SceneOperationResult {
    let textCalc = ThreeTextGenerator.create();

    if (!textCalc.isInvalid) {
      let textParam = textCalc.inputByTitle(ParamTitles.Text) as Param_String;
      textParam.values = [text];

      let calcTitles = this.addSceneCalcToLevel(textCalc, this.editLevel);

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `added <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addSurfaceProcessor(_: any, calcIds: string[]): SceneOperationResult {
    let surfaceCalc = SurfaceGenerator.create();

    if (!surfaceCalc.isInvalid) {
      let calcTitles = this.addSceneCalcToLevel(surfaceCalc, this.editLevel, {calcIds});

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `created surface from <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addLoftProcessor(_: any, calcIds: string[], cap: boolean, smooth: boolean): SceneOperationResult {
    let loftCalc = LoftModifier.create();

    if (!loftCalc.isInvalid) {
      let capParam = loftCalc.inputByTitle(ParamTitles.Cap) as Param_Boolean;
      capParam.values = [cap];

      let smoothParam = loftCalc.inputByTitle(ParamTitles.Smooth) as Param_Boolean;
      smoothParam.values = [smooth];

      let calcTitles = this.addSceneCalcToLevel(loftCalc, this.editLevel, {calcIds});

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `created loft from <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addRevolveProcessor(_: any, calcIds: string[], cap: boolean, angle: number): SceneOperationResult {
    let axisCalc = Calc.unset;

    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      for (let param of calc.renderableOutputs()) {
        if (param.objectType === ParamTypes.Curve &&
          (param as Param_Curve).length === 1 &&
          (getCurveFromRef(this, (param as Param_Curve).values[0]).geometry.points || []).length === 2
        ) {
          axisCalc = calc;
          break;
        }
      }
    }

    if (axisCalc.isInvalid)
      return {success: false};

    let revolveCalc = RevolveModifier.create();

    if (!revolveCalc.isInvalid) {
      let capParam = revolveCalc.inputByTitle(ParamTitles.Cap) as Param_Boolean;
      capParam.values = [cap];

      let angleParam = revolveCalc.inputByTitle(ParamTitles.Angle) as Param_Number;
      angleParam.values = [angle];

      const setup = () => {
        for (let param of axisCalc.renderableOutputs())
          this.addRelation(param, revolveCalc.inputByTitle(ParamTitles.Axis));
      };

      let calcTitles = this.addSceneCalcToLevel(revolveCalc, this.editLevel, {
        calcIds,
        excludeCalcIds: [axisCalc.id],
        setup
      });

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `created revolve from <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addPipeProcessor(_: any, uCalcId: string, vCalcId: string): SceneOperationResult {
    let uCalc = this.getCalcById(uCalcId);
    let vCalc = this.getCalcById(vCalcId);

    if (uCalc.isInvalid || vCalc.isInvalid)
      return {success: false};

    let pipeCalc = PipeModifier.create();

    if (!pipeCalc.isInvalid) {
      const setup = () => {
        for (let param of uCalc.renderableOutputs())
          this.addRelation(param, pipeCalc.inputByTitle(ParamTitles.UCurve));
        for (let param of vCalc.renderableOutputs())
          this.addRelation(param, pipeCalc.inputByTitle(ParamTitles.VCurve));
      };

      let calcTitles = this.addSceneCalcToLevel(pipeCalc, this.editLevel, {
        calcIds: [uCalcId, vCalcId],
        excludeCalcIds: [uCalcId, vCalcId],
        setup
      });

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `created pipe from <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addArrayProcessor(_: any, calcIds: string[], options: { offset: vec3, arraySize: vec3, itemOffset: vec3 }): SceneOperationResult {
    let arrayCalcIds: string[] = [];
    let calcTitles: string[] = [];

    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      let arrayCalc = ArrayModifier.create();

      if (!arrayCalc.isInvalid) {
        arrayCalc.title = this.generateCalcTitle(arrayCalc.title);
        arrayCalcIds.push(arrayCalc.id);

        let itemSizeParam = calc.outputByTitle(ParamTitles.GlobalSize) as Param_Point;
        let itemSize = itemSizeParam.values[0];

        let countParam = arrayCalc.inputByTitle(ParamTitles.Count) as Param_Point;
        countParam.values = [options.arraySize];

        let offsetParam = arrayCalc.inputByTitle(ParamTitles.Offset) as Param_Point;
        offsetParam.values = [options.itemOffset];

        let spacingParam = arrayCalc.inputByTitle(ParamTitles.Spacing) as Param_Point;
        spacingParam.values = [vec3.sub(vec3.create(), options.offset, itemSize)];

        calcTitles = [...calcTitles, ...this.addCalcToLevel(arrayCalc, this.editLevel, {calcIds: [calcId]})];
      }
    }

    this.selectedCalcIds = arrayCalcIds;
    this.tool = Tools.Gumball;

    return {
      success: true,
      ids: arrayCalcIds,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'add-calc',
      description: `added array of <${calcTitles.join(', ')}>`
    };
  }

  addFixatedArrayProcessor(_: any, calcIds: string[], options: { offset: vec3, arraySize: vec3, itemOffset: vec3 }): SceneOperationResult {
    let result = this.addArrayProcessor(_, calcIds, options);
    if (!result.success)
      return result;
    if (result.ids) {
      this.fixateCalcs(_, result.ids);
      return lod.omit(result, ['ids']);
    }

    return result;
  }

  addMirrorProcessor(_: any, calcIds: string[], options: { transform: mat4 }): SceneOperationResult {
    let result = this.copyCalcs(_, this, '', calcIds);
    let mirrorCalcIds: string[] = [];
    let calcTitles: string[] = [];
    let pastedCalcIds = result.ids ? result.ids : [];
    for (let calcId of pastedCalcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      let mirrorCalc = MirrorModifier.create();
      let mirrorParam = mirrorCalc.inputByTitle(ParamTitles.Mirror) as Param_Transform;
      mirrorParam.values = [options.transform];

      if (!mirrorCalc.isInvalid) {
        mirrorCalc.title = this.generateCalcTitle(mirrorCalc.title);
        mirrorCalcIds.push(mirrorCalc.id);

        calcTitles = [...calcTitles, ...this.addCalcToLevel(mirrorCalc, this.editLevel, {calcIds: [calcId]})];
      }
    }

    this.selectedCalcIds = mirrorCalcIds;
    this.tool = Tools.Gumball;

    return {
      success: true,
      ids: mirrorCalcIds,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'add-calc',
      description: `added mirror of <${calcTitles.join(', ')}>`
    };
  }

  addUnionProcessor(_: any, calcIds: string[], material?: IEitherMaterial): SceneOperationResult {
    let unionCalc = UnionModifier.create();

    if (!unionCalc.isInvalid) {
      if (material)
        unionCalc.material = material;

      let calcTitles = this.addSceneCalcToLevel(unionCalc, this.editLevel, {calcIds});

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `unified <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addIntersectProcessor(_: any, calcIds: string[], material?: IEitherMaterial): SceneOperationResult {
    let intersectCalc = IntersectModifier.create();

    if (!intersectCalc.isInvalid) {
      if (material)
        intersectCalc.material = material;

      let calcTitles = this.addSceneCalcToLevel(intersectCalc, this.editLevel, {calcIds});

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `intersected <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addGroupProcessor(_: any, calcIds: string[]): SceneOperationResult {
    let groupCalc = GroupModifier.create();

    if (!groupCalc.isInvalid) {
      let calcTitles = this.addSceneCalcToLevel(groupCalc, this.editLevel, {calcIds});

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `grouped <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addOffsetProcessor(_: any, calcIds: string[], distance: number): SceneOperationResult {
    let offsetCalc = OffsetModifier.create();

    if (!offsetCalc.isInvalid) {
      let distanceParam = offsetCalc.inputByTitle(ParamTitles.Distance) as Param_Number;
      distanceParam.values = [distance];

      let calcTitles = this.addSceneCalcToLevel(offsetCalc, this.editLevel, {calcIds});

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `offset <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addFilletProcessor(_: any, calcIds: string[], distance: number): SceneOperationResult {
    let filletCalc = FilletModifier.create();

    if (!filletCalc.isInvalid) {
      let distanceParam = filletCalc.inputByTitle(ParamTitles.Distance) as Param_Number;
      distanceParam.values = [distance];

      let calcTitles = this.addSceneCalcToLevel(filletCalc, this.editLevel, {calcIds});

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `filleted <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addHollowProcessor(_: any, calcIds: string[], distance: number): SceneOperationResult {
    let hollowCalc = HollowModifier.create();

    if (!hollowCalc.isInvalid) {
      let distanceParam = hollowCalc.inputByTitle(ParamTitles.Distance) as Param_Number;
      distanceParam.values = [distance];

      let calcTitles = this.addSceneCalcToLevel(hollowCalc, this.editLevel, {calcIds});

      return {
        success: true,
        changesToSolve: true,
        operation: 'add-calc',
        description: `hollowed <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addExtrudeProcessor(_: any, calcIds: string[], distance: number): SceneOperationResult {
    let extrudeCalc = ExtrudeModifier.create();

    if (!extrudeCalc.isInvalid) {
      let distanceParam = extrudeCalc.inputByTitle(ParamTitles.Distance) as Param_Number;
      distanceParam.values = [Math.abs(distance)];
      let directionParam = extrudeCalc.inputByTitle(ParamTitles.Direction) as Param_Index;
      directionParam.values = [distance > 0 ? 'up' : 'down'];

      let calcTitles = this.addSceneCalcToLevel(extrudeCalc, this.editLevel, {calcIds});

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `extruded <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addCutProcessor(_: any, calcIds: string[]): SceneOperationResult {
    let allCalcIds = [calcIds, []];
    let cutCalcIds: string[] = [];

    for (let calcId of calcIds) {
      allCalcIds[1].push(this.duplicateCalc(calcId, true).id);
    }

    for (let i = 0; i < 2; ++i) {
      let cutCalc = CutModifier.create();

      if (!cutCalc.isInvalid) {
        cutCalc.title = this.generateCalcTitle(cutCalc.title);
        let invertParam = cutCalc.inputByTitle(ParamTitles.Invert) as Param_Boolean;
        invertParam.values = [!!i];
        cutCalcIds.push(cutCalc.id);

        this.addCalcToLevel(cutCalc, this.editLevel, {calcIds: allCalcIds[i]});
      }
    }

    this.selectedCalcIds = cutCalcIds;
    this.tool = Tools.Gumball;

    return {
      success: true,
      ids: cutCalcIds,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'add-calc',
      description: `cut model`
    };
  }

  addSplitProcessor(_: any, calcIds: string[]): SceneOperationResult {
    let allCount = Math.pow(2, calcIds.length) - 1;
    let intersectCalcIds: string[] = [];

    let used = false;
    let calcTitles = [];

    for (let i = 0; i < allCount; ++i) {
      let voided = (i).toString(2).split('').map(c => c === '1');
      let len = voided.length;
      for (let j = 0; j < calcIds.length - len; ++j) {
        voided = [false, ...voided];
      }

      let materials: IEitherMaterial[] = [];
      for (let j = 0; j < calcIds.length; ++j) {
        let calc = this.getCalcById(calcIds[j]);
        if (calc.isInvalid) continue;

        calcTitles.push(calc.title);
        if (!voided[j]) {
          if (calc.material)
            materials.push(calc.material);
        }
      }

      if (materials.length === 0)
        materials.push(defaultEitherMaterial);

      for (let material of materials) {
        let newCalcs = [];
        for (let j = 0; j < calcIds.length; ++j) {
          newCalcs.push(used ?
            this.duplicateCalc(calcIds[j], true) :
            this.getCalcById(calcIds[j])
          );
          if (newCalcs[j].voided !== voided[j]) {
            newCalcs[j].voided = voided[j];
          }
        }
        used = true;

        let intersectCalc = IntersectModifier.create();
        if (!intersectCalc.isInvalid) {
          intersectCalc.material = material;
          intersectCalc.title = this.generateCalcTitle('split');
          intersectCalcIds.push(intersectCalc.id);

          this.addCalcToLevel(intersectCalc, this.editLevel, {calcIds: newCalcs.map(calc => calc.id)});
        }
      }
    }

    this.selectedCalcIds = intersectCalcIds;
    this.tool = Tools.Gumball;

    return {
      success: true,
      ids: intersectCalcIds,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'add-calc',
      description: `split <${calcTitles.join(', ')}>`
    };
  }

  addPolyline(_: any, points: number[]): SceneOperationResult {
    let polylineCalc = PolylineGenerator.create();

    if (!polylineCalc.isInvalid) {
      // TODO: YX - check if property needs to be added.
      let param = polylineCalc.inputByTitle(ParamTitles.Vertex) as Param_Vertex;
      let values = [];

      for (let i = 0; i < points.length; i += 3) {
        values.push(vec3.fromValues(points[i], points[i + 1], points[i + 2]));
      }
      param.values = values;

      let calcTitles = this.addSceneCalcToLevel(polylineCalc, this.editLevel);

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `created <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addCurve(_: any, points: number[], periodic: boolean): SceneOperationResult {
    let curveCalc = CurveGenerator.create();

    if (!curveCalc.isInvalid) {
      let param = curveCalc.inputByTitle(ParamTitles.Vertex) as Param_Vertex;
      let periodicParam = curveCalc.inputByTitle(ParamTitles.Periodic) as Param_Boolean;
      let values = [];

      for (let i = 0; i < points.length; i += 3) {
        values.push(vec3.fromValues(points[i], points[i + 1], points[i + 2]));
      }

      param.values = values;
      periodicParam.values = [periodic];

      let calcTitles = this.addSceneCalcToLevel(curveCalc, this.editLevel);

      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-calc',
        description: `created <${calcTitles.join(', ')}>`
      };
    }

    return {success: false};
  }

  addSculptProcessors(_: any, calcIds: string[], sculptGeoms: { [key: string]: { data: sculptTypes.MeshData, matrix: mat4 }[] }): SceneOperationResult {
    let sculptCalcIds: string[] = [];
    let calcTitles: string[] = [];

    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      let sculptCalc = SculptModifier.create();
      if (!sculptCalc.isInvalid) {
        sculptCalc.title = this.generateCalcTitle('sculpted ' + calc.title);
        sculptCalc.material = lod.cloneDeep(calc.material);
        sculptCalcIds.push(sculptCalc.id);

        let sculptParam = sculptCalc.inputByTitle(ParamTitles.Sculpt) as Param_SculptMesh;
        let sculptTransformParam = sculptCalc.inputByTitle(ParamTitles.SculptTransform) as Param_Transform;

        let sculptParamValues = [];
        let sculptParamProperties = [];

        for (let geom of sculptGeoms[calcId] || []) {
          let newValue = new WompObjectRef(registerSculptGeometry(
            this,
            geom.data,
            `sculpt[${peregrineId()}]`)
          );
          sculptParamValues.push(newValue);
          sculptTransformParam.values = [mat4.clone(geom.matrix)];
          sculptParamProperties.push({
            hash: getUniqueDesc(newValue),
            voided: false,
            valid: true,
            material: defaultEitherMaterial,
            modelType: ObjectTypes.Mesh,
            weldAngle: 90
          });
        }

        sculptParam.values = sculptParamValues;
        sculptParam.properties = sculptParamProperties;

        calcTitles = [...calcTitles, ...this.addCalcToLevel(sculptCalc, this.editLevel, {calcIds: [calcId]})];
      }
    }

    this.selectedCalcIds = sculptCalcIds;

    return {
      success: true,
      ids: sculptCalcIds,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'add-calc',
      description: `sculpting <${calcTitles.join(', ')}>`
    };
  }

  addBezierCurves(_: any, modelId: string, title: string, points: vec3[][]): SceneOperationResult {
    let curveCalcs: Calc[] = [];

    for (let curvePoints of points) {
      let curveCalc = CurveGenerator.create();

      if (!curveCalc.isInvalid) {
        curveCalc.title = this.generateCalcTitle(curveCalc.title);

        let param = curveCalc.inputByTitle(ParamTitles.Vertex) as Param_Vertex;
        let periodicParam = curveCalc.inputByTitle(ParamTitles.Periodic) as Param_Boolean;
        let bezierParam = curveCalc.inputByTitle(ParamTitles.Bezier) as Param_Boolean;

        param.values = curvePoints;
        periodicParam.values = [false];
        bezierParam.values = [true];

        this.addCalc(curveCalc);

        curveCalcs.push(curveCalc);
      }
    }

    this.groupCalcs(curveCalcs, title);

    return {
      success: true,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'add-calc',
      description: `imported <${title}>`
    };
  }

  addMeshes(_: any, modelId: string, title: string, subGeometries: WompMeshData[]): SceneOperationResult {
    let weldModifiers: Calc[] = [];

    for (let subGeometry of subGeometries) {
      let weldModifier = WeldModifier.create();
      let objectParam = weldModifier.inputByTitle(ParamTitles.Object) as Param_Mesh;
      let weldParam = weldModifier.inputByTitle(ParamTitles.Weld) as Param_Number;

      weldModifier.title = this.generateCalcTitle('welded ' + title);
      weldModifier.hash = modelId;
      weldParam.values = [0];

      this.addCalc(weldModifier);

      objectParam.values = [new WompObjectRef(registerGeometry(weldModifier, subGeometry, `weld[${peregrineId()}]`))];
      objectParam.properties = [{
        hash: peregrineId(),
        voided: false,
        valid: true,
        material: defaultEitherMaterial,
        modelType: ObjectTypes.Mesh,
        weldAngle: 0
      }];

      weldModifiers.push(weldModifier);
    }

    let group = weldModifiers.length > 1;
    let groupCalc;

    if (group) {
      groupCalc = GroupModifier.create();
      groupCalc.title = this.generateCalcTitle(title);

      this.addCalc(groupCalc);
    }

    for (let weldModifier of weldModifiers) {
      let calc = MeshParameter.create();

      if (!calc.isInvalid) {
        calc.title = this.generateCalcTitle(title);

        this.addCalc(calc);

        if (groupCalc) {
          for (let param of calc.renderableOutputs()) {
            this.addRelation(param, groupCalc.inputByTitle(ParamTitles.Object));
          }

          calc.visible = false;
        }

        for (let param of weldModifier.renderableOutputs()) {
          this.addRelation(param, calc.inputByTitle(ParamTitles.Object));
        }
      }
    }

    return {
      success: true,
      info: {modelId, title},
      changesToSolve: true,
      refreshEditor: true,
      operation: 'add-calc',
      description: `imported <${title}>`
    };
  }

  duplicateMeshParameter(_: any, modelId: string, title: string, weldModifiers: Calc[]): SceneOperationResult {
    let calcTitles = [];
    for (let weldModifier of weldModifiers) {
      let calc = MeshParameter.create();

      if (!calc.isInvalid) {
        calc.title = this.generateCalcTitle(title);
        calcTitles.push(calc.title);

        this.addCalc(calc);

        for (let param of weldModifier.renderableOutputs()) {
          this.addRelation(param, calc.inputByTitle(ParamTitles.Object));
        }
      }
    }

    return {
      success: true,
      info: {modelId, title},
      changesToSolve: true,
      refreshEditor: true,
      operation: 'add-calc',
      description: `imported <${calcTitles.join(',')}>`
    }
  }

  addBreps(_: any, modelId: string, title: string, data: { brep: WompBrepData, mesh: WompMeshData, edge: WompMeshData }[]): SceneOperationResult {
    let brepParams: Calc[] = [];

    for (let i = 0; i < data.length; ++i) {
      let brepParam = BrepParameter.create();
      let objectParam = brepParam.inputByTitle(ParamTitles.Object) as Param_Compound;

      brepParam.title = this.generateCalcTitle(title);
      brepParam.hash = modelId;

      this.addCalc(brepParam);

      let desc = `brep[${peregrineId()}]`;
      let meshDesc = desc + '-mesh';
      let edgeDesc = desc + '-edge';

      let id = registerBrepGeometry(brepParam, data[i].brep, desc);
      let meshId = registerGeometry(brepParam, data[i].mesh, meshDesc);
      let edgeId = registerGeometry(brepParam, data[i].edge, edgeDesc);

      let values = [];
      let valueTypes = [];
      let caches = [];
      let properties = [];
      values.push(new WompObjectRef(id));
      caches.push([new WompObjectRef(meshId), new WompObjectRef(edgeId)]);
      valueTypes.push(ObjectTypes.Brep);
      properties.push({
        hash: peregrineId(),
        voided: false,
        valid: true,
        material: defaultEitherMaterial,
        modelType: ObjectTypes.Brep,
        weldAngle: 0
      });

      objectParam.values = values;
      objectParam.valueTypes = valueTypes;
      objectParam.properties = properties;

      brepParams.push(brepParam);
    }

    this.groupCalcs(brepParams, title);

    return {
      success: true,
      info: {modelId, title},
      changesToSolve: true,
      refreshEditor: true,
      operation: 'add-calc',
      description: `imported <${title}>`
    };
  }

  duplicateBrepParameter(_: any, modelId: string, title: string, brepParams: Calc[]): SceneOperationResult {
    let calcTitles = [];
    for (let brepParam of brepParams) {
      let objectParam = brepParam.inputByTitle(ParamTitles.Object) as Param_Compound;

      let calc = BrepParameter.create();

      if (!calc.isInvalid) {
        let calcObjectParam = calc.inputByTitle(ParamTitles.Object) as Param_Compound;
        calc.title = this.generateCalcTitle(title);
        calcTitles.push(calc.title);

        calc.hash = brepParam.hash;
        calcObjectParam.values = duplicateObject(objectParam.values);
        calcObjectParam.valueTypes = duplicateObject(objectParam.valueTypes);
        calcObjectParam.caches = duplicateObject(objectParam.caches);
        calcObjectParam.properties = duplicateObject(objectParam.properties);

        this.addCalc(calc);
      }
    }

    return {
      success: true,
      info: {modelId, title},
      changesToSolve: true,
      refreshEditor: true,
      operation: 'add-calc',
      description: `imported <${calcTitles.join(',')}>`
    }
  }

  addPlaneFromImageMaterial(dispatch: ThunkDispatch<{}, {}, AnyAction>, title: string, material: IDigitalMaterial, height: number, width: number): SceneOperationResult {
    this.createDigitalMaterial(dispatch, material, true);
    let scale = 10;
    let planeCalc = ThreePlaneGenerator.create();
    let calcTitles: string[] = [];

    if (planeCalc && !planeCalc.isInvalid) {
      planeCalc.title = this.generateCalcTitle(title);

      let param = planeCalc.inputByTitle(ParamTitles.Transform) as Param_Transform;
      let value = mat4.multiply(
        mat4.create(),
        mat4.fromTranslation(
          mat4.create(),
          vec3.fromValues(
            0,
            0,
            height / scale / 2
          )
        ),
        mat4.fromScaling(
          mat4.create(),
          vec3.fromValues(
            width / ThreePlaneGenerator.defaultParameter.width / scale,
            1,
            height / ThreePlaneGenerator.defaultParameter.height / scale
          )
        )
      );

      param.values = [value];

      calcTitles = this.addSceneCalcToLevel(planeCalc, this.editLevel);
      this.changeCalcsDigitalMaterial(dispatch, [planeCalc.id], material);
    }

    return {
      success: true,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'add-calc',
      description: `created <${calcTitles.join(',')}>`
    }
  }

  bakeCalcs(_: any, calcIds: string[]): SceneOperationResult {
    let calcTitles: string[] = [];
    let calcIdsToShow: string[] = [];

    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;
      if ([ComponentTypes.MeshParameter, ComponentTypes.BrepParameter, ComponentTypes.CurveParameter].includes(calc.component)) continue;

      calcTitles.push(calc.title);
      let renderedObjs = calc.generateRenderedFullObjects();

      for (let object of renderedObjs) {
        switch (object.type) {
          case RenderedObjectTypes.Mesh:
          case RenderedObjectTypes.Brep:
            if (!object.meshRef || !object.mesh) continue;
            let weldModifier;

            let modelId = hash(object.meshRef.objectId, {algorithm: 'md5'});
            let calcs = this.getStaticCalcs(ComponentTypes.WeldModifier, modelId);

            if (calcs.length > 0) {
              weldModifier = calcs[0];
            } else {
              weldModifier = WeldModifier.create();
              let objectParam = weldModifier.inputByTitle(ParamTitles.Object) as Param_Mesh;
              let weldParam = weldModifier.inputByTitle(ParamTitles.Weld) as Param_Number;

              weldModifier.title = this.generateCalcTitle('welded baked ' + calc.title);
              weldModifier.hash = modelId;

              this.addCalc(weldModifier);

              weldParam.values = [0];
              objectParam.values = [new WompObjectRef(registerGeometry(weldModifier, object.mesh.geometry, `weld[${peregrineId()}]`))];
              objectParam.properties = [{
                hash: peregrineId(),
                voided: false,
                valid: true,
                material: defaultEitherMaterial,
                modelType: ObjectTypes.Mesh,
                weldAngle: 0
              }];
            }

            let boundingBox = getBoundingBoxFromGeometry(object.mesh.geometry);
            let orgCenter = getBoundingBoxCenter(boundingBox);

            let meshParameter = MeshParameter.create();

            if (!meshParameter.isInvalid) {
              meshParameter.title = this.generateCalcTitle('baked ' + calc.title);
              meshParameter.material = object.property.material;

              let transformParam = meshParameter.inputByTitle(ParamTitles.Transform) as Param_Transform;

              let baseTransform = mat4.clone(object.mesh.matrix);
              mat4.multiply(baseTransform, mat4.fromTranslation(mat4.create(), vec3.negate(vec3.create(), orgCenter)), baseTransform);
              mat4.multiply(baseTransform, baseTransform, mat4.fromTranslation(mat4.create(), orgCenter));

              transformParam.values = [baseTransform];

              this.addCalc(meshParameter);

              for (let param of weldModifier.renderableOutputs()) {
                this.addRelation(param, meshParameter.inputByTitle(ParamTitles.Object));
              }

              this.addCalcToLevel(meshParameter, this.editLevel);
              calcIdsToShow.push(meshParameter.id);
            }

            break;
          case RenderedObjectTypes.Line:
          case RenderedObjectTypes.Vertex:
            break;
        }
      }
    }

    let deleteRes = this.deleteCalcs(_, calcIds, true);

    if (!deleteRes.success)
      return deleteRes;

    this.selectedCalcIds = calcIdsToShow;
    this.tool = Tools.Gumball;

    return {
      success: true,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'bake-calc',
      description: `baked <${calcTitles.join(', ')}>`
    };
  }

  fixateCalcs(_: any, calcIds: string[]): SceneOperationResult {
    let calcIdsToShow: string[] = [];
    let calcTitles: string[] = [];

    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      if (calc.component === ComponentTypes.ArrayModifier) {
        calcTitles.push(calc.title);
        let objectsParam = calc.inputByTitle(ParamTitles.Object) as Param_Compound;
        let offset = vec3.clone((calc.inputByTitle(ParamTitles.Offset) as Param_Point).values[0]);
        let spacing = vec3.clone((calc.inputByTitle(ParamTitles.Spacing) as Param_Point).values[0]);
        let count = vec3.clone((calc.inputByTitle(ParamTitles.Count) as Param_Point).values[0]);
        vec3.set(offset, Math.min(offset[0], count[0] - 1), Math.min(offset[1], count[1] - 1), Math.min(offset[2], count[2] - 1));

        for (let prevRel of objectsParam.prevRelations) {
          let prevParam = (prevRel as Relation).from;
          let prevCalc = prevParam.calc as Calc;

          let renderedObjs = prevCalc.generateRenderedFullObjects();
          let meshes = [];

          for (let object of renderedObjs) {
            switch (object.type) {
              case RenderedObjectTypes.Mesh:
              case RenderedObjectTypes.Brep:
                meshes.push(object.mesh);
                break;
            }
          }

          let boundingBox = getBoundingBoxFromMeshes(meshes);
          let itemSize = getBoundingBoxSize(boundingBox);

          calcIdsToShow.push(prevCalc.id);
          for (let i = 0; i < count[0]; ++i) {
            for (let j = 0; j < count[1]; ++j) {
              for (let k = 0; k < count[2]; ++k) {
                if (i === offset[0] && j === offset[1] && k === offset[2])
                  continue;
                let newCalc = this.duplicateCalc(prevCalc.id, true);
                let param = newCalc.inputByTitle(ParamTitles.Transform) as Param_Transform;
                let key = newCalc.id + ':transform:0';
                let transform = mat4.create();
                mat4.fromTranslation(transform, vec3.fromValues(
                  (i - offset[0]) * (spacing[0] + itemSize[0]),
                  (j - offset[1]) * (spacing[1] + itemSize[1]),
                  (k - offset[2]) * (spacing[2] + itemSize[2])
                ));

                if (!param.isInvalid) {
                  mat4.multiply(transform, transform, param.values[0]);
                }

                this.updateSceneParameterWithKey(key, {value: transform});
                calcIdsToShow.push(newCalc.id);
              }
            }
          }
        }
      }
    }

    let deleteRes = this.deleteCalcs(_, calcIds, false);

    if (!deleteRes.success)
      return deleteRes;

    let groupRes = this.addGroupProcessor(_, calcIdsToShow);

    if (!groupRes.success)
      return groupRes;

    return {
      success: true,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'fixate-calc',
      description: `fixated <${calcTitles.join(', ')}>`
    };
  }

  copyCalcs(_: any, fromScene: ProjectScene, level: string, calcIds: string[]): SceneOperationResult {
    let destIdMap: { [key: string]: string } = {};
    let calcTitles = [];

    if (fromScene === this) {
      for (let calcId of calcIds) {
        let calc = this.getCalcById(calcId);
        if (!calc.isInvalid)
          calcTitles.push(calc.title);
        let newCalc = this.duplicateCalc(calcId, true);
        destIdMap[calcId] = newCalc.id;
      }
    } else {
      destIdMap = this.copyCalcsFrom(fromScene, calcIds);
    }

    if (level) {
      let topCalc = this.getCalcById(level);

      for (let cId in destIdMap) {
        let calcId = destIdMap[cId];
        let calc = this.getCalcById(calcId);

        for (let param of calc.renderableOutputs()) {
          this.addRelation(param, topCalc.inputByTitle(ParamTitles.Object));
        }
        calc.visible = false;
      }
    } else {
      for (let cId in destIdMap) {
        let calcId = destIdMap[cId];
        let calc = this.getCalcById(calcId);
        calc.visible = true;
      }
    }

    let newSelectedCalcIds = Object.values(destIdMap);
    this.selectedCalcIds = newSelectedCalcIds;
    this.tool = Tools.Gumball;

    return {
      success: true,
      ids: newSelectedCalcIds,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'add-calc',
      description: `pasted <${calcTitles.join(', ')}>`
    };
  }

  moveCalcs(_: any, calcIds: string[], destCalcId: string): SceneOperationResult {
    let calcTitles = [];
    let destCalcTitle = 'scene';
    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      calcTitles.push(calc.title);
      let destCalc = this.getCalcById(calcId);
      if (!destCalc.isInvalid)
        destCalcTitle = destCalc.title;

      if (!calc.getPrevCalcs().map(calc => calc.id).includes(destCalcId)) {
        this.moveCalc(calcId, destCalcId);
        calc.visible = destCalcId === '';
      }
    }

    this.selectedCalcIds = this.selectedCalcIds.filter(id => !calcIds.includes(id));
    this.tool = Tools.Gumball;

    return {
      success: true,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'move-calc',
      description: `moved <${calcTitles.join(', ')}> under <${destCalcTitle}>`
    };
  }

  breakCalcs(_: any, calcIds: string[]): SceneOperationResult {
    let calcIdsToDelete: { [key: string]: boolean } = {};
    let calcIdsToShow: string[] = [];
    let calcTitles: string[] = [];

    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      if (BreakableComponentTypes.includes(calc.component)) {
        calcTitles.push(calc.title);
        calcIdsToDelete[calc.id] = true;

        let inputTitles = [ParamTitles.Object, ParamTitles.UCurve, ParamTitles.VCurve, ParamTitles.Axis];
        for (let inputTitle of inputTitles) {
          let inputParam = calc.inputByTitle(inputTitle);
          if (inputParam.isInvalid)
            continue;
          for (let prevRel of inputParam.prevRelations) {
            let prevParam = (prevRel as Relation).from;
            let prevCalc = prevParam.calc as Calc;

            if (prevParam.nextRelations.length === 1)
              calcIdsToShow.push(prevCalc.id);
          }
        }
      }
    }

    this.deleteCalcs(_, Object.keys(calcIdsToDelete), false);

    this.selectedCalcIds = calcIdsToShow;
    this.tool = Tools.Gumball;
    this.changeCalcsVisible(_, calcIdsToShow, true);

    return {
      success: true,
      ids: calcIdsToShow,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'delete-calc',
      description: `breaked <${calcTitles.join(', ')}>`
    };
  }

  deleteCalcs(_: any, calcIds: string[], deep: boolean): SceneOperationResult {
    let deletedCalcIds = new Set();
    let calcTitles: string[] = [];

    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      let prevCalcs: Calc[] = [];
      let nextCalcs: Calc[] = [];
      if (!calc.isInvalid)
        calcTitles.push(calc.title);

      if (BreakableComponentTypes.includes(calc.component)) {
        prevCalcs = calc.getPrevCalcs();
        nextCalcs = calc.getNextCalcs();
      }

      for (let id of this.deleteCalc(calcId, deep))
        deletedCalcIds.add(id);

      if (!deep) {
        for (let prevCalc of prevCalcs) {
          for (let nextCalc of nextCalcs) {
            if (BreakableComponentTypes.includes(nextCalc.component)) {
              for (let param of prevCalc.renderableOutputs()) {
                this.addRelation(param, nextCalc.inputByTitle(ParamTitles.Object));
              }
            }
          }
        }
      }
    }

    return {
      success: true,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'delete-calc',
      description: `deleted <${calcTitles.join(', ')}>`
    };
  }

  changeCalcDetailInfo(_: any, calcId: string, detailInfo: IModelInfo | null): SceneOperationResult {
    let calc = this.getCalcById(calcId);
    if (calc.isInvalid)
      return {success: false};

    calc.detailInfo = detailInfo;

    return {
      success: true,
      operation: 'change-detail-info',
      description: `calculate model info`
    }
  }

  changeCalcHeatmapData(_: any, calcId: string, heatmapData: IHeatmapData[]): SceneOperationResult {
    let calc = this.getCalcById(calcId);
    if (calc.isInvalid)
      return {success: false};

    calc.heatmapData = heatmapData;

    return {
      success: true,
      operation: 'change-heatmap',
      description: `calculate heatmap`
    }
  }

  changeCalcsVisible(_: any, calcIds: string[], visible: boolean): SceneOperationResult {
    let calcTitles = [];

    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      if (calc.visible !== visible) {
        calcTitles.push(calc.title);
        calc.visible = visible;
      }
    }

    this.selectedCalcIds = this.selectedCalcIds.filter(id => this.getCalcById(id).visible);

    return {
      success: true,
      refreshEditor: true,
      operation: 'change-calc-visible',
      description: `made <${calcTitles.join(', ')}> ${visible ? 'visible' : 'invisible'}`
    };
  }

  changeCalcsLocked(_: any, calcIds: string[], locked: boolean): SceneOperationResult {
    let calcTitles = [];

    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      if (calc.locked !== locked) {
        calcTitles.push(calc.title);
        calc.locked = locked;
      }
    }

    this.selectedCalcIds = this.selectedCalcIds.filter(id => !this.getCalcById(id).locked);

    return {
      success: true,
      refreshEditor: true,
      operation: 'change-calc-locked',
      description: `${locked ? 'locked' : 'unlocked'} <${calcTitles.join(', ')}>`
    };
  }

  changeCalcsVoided(_: any, calcIds: string[], voided: boolean): SceneOperationResult {
    let calcTitles = [];

    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      if (calc.voided !== voided) {
        calcTitles.push(calc.title);
        calc.voided = voided;
      }
    }

    return {
      success: true,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'change-calc-voided',
      description: `made <${calcTitles.join(', ')}> ${voided ? 'void' : 'solid'}`
    };
  }

  changeCalcsGlobal(_: any, calcIds: string[], global: boolean): SceneOperationResult {
    let calcTitles = [];

    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      if (calc.global !== global) {
        calcTitles.push(calc.title);
        calc.global = global;
      }
    }

    return {
      success: true,
      refreshEditor: true,
      operation: 'change-calc-global',
      description: `made <${calcTitles.join(', ')}> ${global ? 'global' : 'local'}`
    };
  }

  changeCalcsCollapsed(_: any, calcIds: string[], collapsed: boolean): SceneOperationResult {
    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      if (calc.collapsed !== collapsed) {
        calc.collapsed = collapsed;
      }
    }

    return {success: true};
  }

  changeCalcSubGroupCollapsed(_: any, calcId: string, index: number, collapsed: boolean): SceneOperationResult {
    let calc = this.getCalcById(calcId);
    if (calc.isInvalid)
      return {success: false};

    if (!calc.settingGroupExpanded[index] !== collapsed) {
      if (collapsed) {
        calc.settingGroupExpanded = lod.omit(calc.settingGroupExpanded, index);
      } else {
        calc.settingGroupExpanded = {...calc.settingGroupExpanded, [index]: 1};
      }
    }

    return {success: true};
  }

  changeCalcTitle(_: any, calcId: string, title: string): SceneOperationResult {
    let calc = this.getCalcById(calcId);
    if (calc.isInvalid)
      return {success: false};

    if (calc.title !== title) {
      let oldTitle = calc.title;
      calc.title = title;

      return {
        success: true,
        operation: 'change-calc-title',
        description: `changed <${oldTitle}>'s title to <${title}>`
      };
    }

    return {success: false};
  }

  changeCalcsDigitalMaterial(_: any, calcIds: string[], digitalMaterial: IDigitalMaterial): SceneOperationResult {
    let calcIdsToSet = new Set(calcIds);
    let processedSet = new Set();
    let calcTitles: string[] = [];

    let offset = calcIdsToSet.size;

    while (offset > 0) {
      offset = -calcIdsToSet.size;

      calcIdsToSet.forEach(calcId => {
        if (!processedSet.has(calcId)) {
          let calc = this.getCalcById(calcId);
          if (calc.isInvalid) return;

          calcTitles.push(calc.title);

          if (NonMaterialComponentTypes.includes(calc.component)) {
            for (let inputId of calc.inputIds) {
              for (let relation of calc.inputs[inputId].prevRelations) {
                calcIdsToSet.add((relation as Relation).from.calc.id);
              }
            }
          } else {
            if (calc.material.id !== digitalMaterial.id || calc.material.type !== MaterialTypes.DigitalMaterial) {
              calc.material = {
                type: MaterialTypes.DigitalMaterial,
                id: digitalMaterial.id
              };
              calc.heatmap = false;
            }

            digitalMaterial = {
              ...digitalMaterial,
              lastUsedDate: new Date()
            };
          }

          processedSet.add(calcId);
        }
      });

      offset += calcIdsToSet.size;
    }

    // TODO: Mingyi - test digital material wizard again. this might cause some issue.
    // dispatch(addDigitalMaterials([digitalMaterial]));

    return {
      success: true,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'change-calc-material',
      description: `assigned digital material to <${calcTitles.join(', ')}>`
    };
  }

  resetCalcsMaterial(_: any, calcIds: string[]): SceneOperationResult {
    let calcTitles = [];
    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      calcTitles.push(calc.title);
      calc.material = defaultEitherMaterial;
      calc.heatmap = false;
    }

    return {
      success: true,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'change-calc-material',
      description: `removed material of <${calcTitles.join(', ')}>`
    };
  }

  deleteDigitalMaterial(_: any, materialId: string): SceneOperationResult {
    let hasUpdate = false;
    for (let calcId of this.calcIds) {
      let calc = this.calcs[calcId];
      if (calc && calc.material.type === MaterialTypes.DigitalMaterial && calc.material.id === materialId) {
        console.warn(`${calc.title} has material to delete`);
        calc.material = defaultEitherMaterial;
        calc.heatmap = false;
        hasUpdate = true;
      }
    }

    return {
      success: true,
      changesToSolve: hasUpdate,
      refreshEditor: hasUpdate,
      operation: 'delete-digital-material',
      description: `deleted digital material`
    };
  }

  changeMagnetMapping(_: any, calcIds: string[], destId: string): SceneOperationResult {
    let calcTitles = [];
    let destTitle = '';

    let destCalc = this.getCalcById(destId);
    if (!destCalc.isInvalid)
      destTitle = destCalc.title;

    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      if (this.magnetMappings[calcId] && !destId) {
        calcTitles.push(calc.title);
        this.magnetMappings = lod.omit(this.magnetMappings, calcId);
      }

      if (destId && this.magnetMappings[calcId] !== destId) {
        calcTitles.push(calc.title);
        this.magnetMappings = {
          ...this.magnetMappings,
          [calcId]: destId
        };
      }
    }

    return {
      success: true,
      refreshEditor: true,
      operation: 'change-magnet-setting',
      description: destId ? `link <${calcTitles.join(', ')}> to ${destTitle}` : `unlink <${calcTitles.join(', ')}>`
    };
  }

  changeCalcSettings(_: any, settings: { [key: string]: any }, dontCommit?: boolean): SceneOperationResult {
    let calcTitles: string[] = [];

    for (let key in settings) {
      let calc = this.updateSceneParameterWithKey(key, settings[key]);
      if (calc) {
        calcTitles.push(calc.title);
      }
    }

    if (dontCommit) {
      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        preventStateUpdate: true
      };
    } else {
      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'change-calc',
        description: `changed parameters of <${calcTitles.join(', ')}>`
      };
    }
  }

  restoreCalcDefaultSettings(_: any, calcId: string, paramIds: string[]): SceneOperationResult {
    let calc = this.getCalcById(calcId);
    let calcTitle = '';

    if (!calc.isInvalid)
      calcTitle = calc.title;

    new Set(paramIds.map(p => p.split(':')[0])).forEach(paramId => {
      let param = calc.getParameter(paramId.split(':')[0]);

      if (param !== undefined && param.defaultValue !== undefined) {
        let objectType = getObjectType(param.objectType as ParamTypes);
        if (objectType === ObjectTypes.Point || objectType === ObjectTypes.Vertex || objectType === ObjectTypes.Transform || objectType === ObjectTypes.Number)
          this.updateSceneParameter(param, {value: duplicateObject(param.defaultValue)}, -3);
        else
          this.updateSceneParameter(param, {value: param.defaultValue}, 0);
      }
    });

    return {
      success: true,
      changesToSolve: true,
      refreshEditor: true,
      operation: 'change-calc',
      description: `restored default parameters of <${calcTitle}>`
    };
  }

  changeSculpts(_: any, calcIds: string[], sMeshDatas: sculptTypes.MeshData[], matrices: mat4[]): SceneOperationResult {
    let needUpdate = false;

    for (let i = 0; i < Math.min(sMeshDatas.length, matrices.length, calcIds.length); ++i) {
      let calcId = calcIds[i];
      let sMeshData = sMeshDatas[i];
      let matrix = matrices[i];
      let sculptCalc = this.getCalcById(calcId);

      if (!sculptCalc.isInvalid) {
        let sculptParam = sculptCalc.inputByTitle(ParamTitles.Sculpt) as Param_SculptMesh;
        let sculptTransformParam = sculptCalc.inputByTitle(ParamTitles.SculptTransform) as Param_Transform;
        let strokeParam = sculptCalc.inputByTitle(ParamTitles.Stroke) as Param_Json;
        let strokeIds = [];

        if (!strokeParam.isInvalid)
          strokeIds = strokeParam.values.map(stroke => stroke.id);
        let oldValue = sculptParam.values[0];

        let newValue = new WompObjectRef(registerSculptGeometry(
          sculptCalc,
          sMeshData,
          `sculpt[${oldValue.objectId},${strokeIds.join(',')}]`
        ));

        sculptParam.values = [newValue];
        sculptTransformParam.values = [matrix];
        sculptParam.properties = [{...sculptParam.properties[0], hash: getUniqueDesc(newValue)}];
        if (!strokeParam.isInvalid)
          strokeParam.values = [];
        needUpdate = true;
      }
    }

    if (needUpdate) {
      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'change-calc',
        description: `saved sculpt`
      };
    }

    return {success: true};
  }

  addSculptStroke(_: any, calcIds: string[], stroke: ISculptStroke): SceneOperationResult {
    let needUpdate = false;

    for (let i = 0; i < calcIds.length; ++i) {
      let calcId = calcIds[i];
      let sculptCalc = this.getCalcById(calcId);

      if (!sculptCalc.isInvalid) {
        let strokeParam = sculptCalc.inputByTitle(ParamTitles.Stroke) as Param_Json;

        if (!strokeParam.isInvalid) {
          strokeParam.values = [
            ...strokeParam.values,
            stroke
          ];
          needUpdate = true;
        }
      }
    }

    if (needUpdate) {
      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'add-sculpt-stroke',
        description: `sculpt stroke`
      };
    }

    return {success: true};
  }

  changeCalcsWeldAngle(_: any, calcIds: string[], angle: number): SceneOperationResult {
    let hasUpdate = false;
    let calcTitles: string[] = [];

    for (let i = 0; i < calcIds.length; ++i) {
      let calcId = calcIds[i];
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid || angle < 0) continue;

      if (calc.component === ComponentTypes.WeldModifier) {
        let weldParam = calc.inputByTitle(ParamTitles.Weld) as Param_Number;
        if (weldParam.length === 0 || weldParam.values[0] !== angle) {
          calcTitles.push(calc.title);
          weldParam.values = [angle];
          hasUpdate = true;
        }
      }
    }

    if (hasUpdate) {
      return {
        success: true,
        changesToSolve: true,
        refreshEditor: true,
        operation: 'change-calc',
        description: `welded <${calcTitles.join(', ')}> ${_n(angle)}°`
      };
    }

    return {success: true};
  }

  changeToolConfig(_: any, config: DeepPartial<IToolConfig>): SceneOperationResult {
    let newConfig = lod.merge(lod.cloneDeep(this.toolConfig), config);

    if (!lod.isEqual(this.toolConfig, newConfig)) {
      this.toolConfig = newConfig;

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-tool-config',
        description: `changed tool config`
      };
    }

    return {success: true};
  }

  changeTool(_: any, tool: string): SceneOperationResult {
    if (this.tool !== tool) {
      this.tool = tool;

      if (tool === Tools.Sculpt) {
        for (let calcId of this.selectedCalcIds) {
          this.calcs[calcId].heatmap = false;
        }
      }

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-tool',
        description: `selected ${tool} tool`
      };
    }

    return {success: true};
  }

  changeToolAndConfig(_: any, tool: string, config: DeepPartial<IToolConfig>): SceneOperationResult {
    this.changeToolConfig(_, config);
    return this.changeTool(_, tool);
  }

  changeSelectedCalcs(_: any, calcIds: string[], tool?: string, massSelect?: boolean, dontCommit?: boolean): SceneOperationResult {
    if (massSelect && calcIds.length > 0) {
      let scopeCalcIds: string[] = this.getEditLevelCalcIds();

      let fromIndex = 0;

      if (this.selectedCalcIds.length > 0) {
        let calcId = this.selectedCalcIds[this.selectedCalcIds.length - 1];
        fromIndex = scopeCalcIds.indexOf(calcId);
      }

      let toIndex = scopeCalcIds.indexOf(calcIds[0]);

      if (fromIndex < 0 || toIndex < 0)
        return {success: true};

      let step = fromIndex > toIndex ? -1 : 1;

      let selectedCalcIds = [...this.selectedCalcIds];

      if (selectedCalcIds.length > 0)
        selectedCalcIds = selectedCalcIds.slice(0, selectedCalcIds.length - 1);

      for (let i = toIndex; i !== fromIndex - step; i -= step) {

        if (!selectedCalcIds.includes(scopeCalcIds[i]))
          selectedCalcIds.push(scopeCalcIds[i]);

      }

      for (let i = toIndex + step; i >= 0 && i < scopeCalcIds.length; i += step)
        selectedCalcIds = selectedCalcIds.filter(s => s !== scopeCalcIds[i]);

      calcIds = selectedCalcIds;
    }

    if (!lod.isEqual(new Set(this.selectedCalcIds), new Set(calcIds)) || tool && this.tool !== tool) {
      let toolChanged = false;
      this.selectedCalcIds = calcIds;
      if (tool && this.tool !== tool) {
        this.tool = tool;
        toolChanged = true;
      }

      if (dontCommit) {
        return {
          success: true,
          refreshEditor: true,
          preventStateUpdate: true
        };
      } else {
        return {
          success: true,
          refreshEditor: true,
          operation: 'change-selected-calcs',
          description: toolChanged ? `selected ${tool} tool` : `${calcIds.length > 0 ? 'set' : 'removed'} selection`
        };
      }
    }

    return {success: true};
  }

  invertCalcSelection(_: any): SceneOperationResult {
    let calcIdSet = new Set(this.getEditLevelCalcIds());

    for (let calcId of this.selectedCalcIds)
      calcIdSet.delete(calcId);

    return this.changeSelectedCalcs(_, Array.from(calcIdSet));
  }

  changeEditLevels(_: any, editLevels: string[]): SceneOperationResult {
    if (!lod.isEqual(this.editLevels, editLevels)) {
      this.editLevels = editLevels;
      let calcId = editLevels.length > 0 ? editLevels[editLevels.length - 1] : '';
      let calc = this.getCalcById(calcId);
      let calcTitle = 'scene';

      if (!calc.isInvalid)
        calcTitle = calc.title;

      this.selectedCalcIds = [];

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-edit-levels',
        description: `jumped into <${calcTitle}>`
      };
    }

    return {success: true};
  }

  changeViewType(_: any, viewType: number): SceneOperationResult {
    if (this.viewType !== viewType) {
      this.viewType = viewType;

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-view-type',
        description: `changed view type`
      };
    }

    return {success: true};
  }

  changeMeasureUnit(_: any, measureUnit: MeasureUnit): SceneOperationResult {
    if (this.measureUnit !== measureUnit) {
      this.measureUnit = measureUnit;

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-measure-unit',
        description: `changed measure unit`
      };
    }

    return {success: true};
  }

  changeCameraAngle(_: any, cameraAngle: number): SceneOperationResult {
    if (this.cameraAngle !== cameraAngle) {
      this.cameraAngle = cameraAngle;

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-camera-angle',
        description: `changed camera angle`
      };
    }

    return {success: true};
  }

  changeCameraInfo(_: any, cameraInfo: ICameraInfo | null): SceneOperationResult {
    if (!lod.isEqual(this._state.cameraInfo, cameraInfo)) {
      this.cameraInfo = cameraInfo;

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-view',
        description: cameraInfo ? `saved camera setting` : `reset camera setting`
      }
    }

    return {success: true};
  }

  changeEnvironmentInfo(_: any, info: Partial<ISceneEnvironmentInfo>): SceneOperationResult {
    if (info.backgroundEnvironment !== undefined)
      this.backgroundEnvironment = info.backgroundEnvironment;

    if (info.lightEnvironment !== undefined)
      this.lightEnvironment = info.lightEnvironment;

    if (info.isSetAsBackground !== undefined)
      this.isSetAsBackground = info.isSetAsBackground;

    return {
      success: true,
      refreshEditor: true,
      operation: 'change-environment',
      description: `changed environment setting`
    };
  }

  deleteAnnotate(_: any, annotateId: number): SceneOperationResult {
    let {[annotateId]: annotate, ...newAnnotates} = this.annotates;
    if (annotate === undefined)
      return {success: true};

    this.annotates = newAnnotates;

    return {
      success: true,
      refreshEditor: true,
      operation: 'delete-annotate',
      description: `deleted annotation <${annotate.text.substr(0, 10)}...>`
    };
  }

  changeAnnotates(_: any, annotates: { [key: number]: IAnnotate }): SceneOperationResult {
    this.annotates = {
      ...this.annotates,
      ...annotates
    };

    return {
      success: true,
      refreshEditor: true,
      operation: 'change-annotate',
      description: `changed annotation`
    };
  }

  changeAnnotateVisible(_: any, annotateId: number, visible: boolean): SceneOperationResult {
    let annotate = this.annotates[annotateId];
    if (annotate === undefined)
      return {success: false};

    if (annotate.visible !== visible) {
      this.annotates = {
        ...this.annotates,
        [annotateId]: {
          ...annotate,
          visible
        }
      };

      return {
        success: true,
        refreshEditor: true
      };
    }

    return {success: true};
  }

  changeAnnotateSettingCollapsed(_: any, annotateId: number, collapsed: boolean): SceneOperationResult {
    let annotate = this.annotates[annotateId];
    if (annotate === undefined)
      return {success: false};

    if (annotate.collapsed !== collapsed) {
      this.annotates = {
        ...this.annotates,
        [annotateId]: {
          ...annotate,
          collapsed
        }
      };
    }

    return {success: true};
  }

  duplicateAnnotate(_: any, annotateId: number): SceneOperationResult {
    let annotate = this.annotates[annotateId];
    if (annotate === undefined)
      return {success: false};

    let newId = Math.max(...Object.keys(this.annotates).map(Number)) + 1;
    let newAnnotate = lod.cloneDeep(annotate);
    newAnnotate.id = newId;

    this.annotates = {
      ...this.annotates,
      [newId]: newAnnotate
    };

    return {
      success: true,
      ids: ['' + newId],
      refreshEditor: true,
      operation: 'duplicate-annotate',
      description: `duplicated annotation <${annotate.text.substr(0, 10)}...>`
    };
  }

  changeAnnotateHeight(_: any, annotateId: number, height: number): SceneOperationResult {
    let annotate = this.annotates[annotateId];
    if (annotate === undefined)
      return {success: false};

    if (annotate.height !== height) {
      this.annotates = {
        ...this.annotates,
        [annotateId]: {
          ...annotate,
          height
        }
      };

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-annotate-height',
        description: `modified annotation height`
      };
    }

    return {success: true};
  }

  changeAnnotateText(_: any, annotateId: number, text: string): SceneOperationResult {
    let annotate = this.annotates[annotateId];
    if (annotate === undefined)
      return {success: false};

    if (annotate.text !== text) {
      this.annotates = {
        ...this.annotates,
        [annotateId]: {
          ...annotate,
          text
        }
      };

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-annotate-text',
        description: `modified annotation text`
      };
    }

    return {success: true};
  }

  changeAnnotateFont(_: any, annotateId: number, font: string): SceneOperationResult {
    let annotate = this.annotates[annotateId];
    if (annotate === undefined)
      return {success: false};

    if (annotate.font !== font) {
      this.annotates = {
        ...this.annotates,
        [annotateId]: {
          ...annotate,
          font
        }
      };

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-annotate-font',
        description: `modified annotation font`
      };
    }

    return {success: true};
  }

  changeAnnotateStyles(_: any, annotateId: number, styles: { [key: string]: string }): SceneOperationResult {
    let annotate = this.annotates[annotateId];
    if (annotate === undefined)
      return {success: false};

    this.annotates = {
      ...this.annotates,
      [annotateId]: {
        ...annotate,
        styles: {
          ...annotate.styles,
          ...styles
        }
      }
    };

    return {
      success: true,
      refreshEditor: true,
      operation: 'change-annotate-style',
      description: `modified annotation style`
    };
  }

  deleteMeasure(_: any, measureId: number): SceneOperationResult {
    let {[measureId]: measure, ...newMeasures} = this.measures;
    if (measure === undefined)
      return {success: true};

    this.measures = newMeasures;

    return {
      success: true,
      refreshEditor: true,
      operation: 'delete-measure',
      description: `deleted measurement`
    };
  }

  duplicateMeasure(_: any, measureId: number): SceneOperationResult {
    let measure = this.measures[measureId];
    if (measure === undefined)
      return {success: false};

    let newId = Math.max(...Object.keys(this.measures).map(Number)) + 1;
    let newMeasure = lod.cloneDeep(measure);
    newMeasure.id = newId;

    this.measures = {
      ...this.measures,
      [newId]: newMeasure
    };

    return {
      success: true,
      refreshEditor: true,
      operation: 'duplicate-measure',
      description: `duplicated measurement`
    };
  }

  changeMeasures(_: any, measures: { [key: number]: IMeasure }): SceneOperationResult {
    this.measures = {
      ...this.measures,
      ...measures
    };

    return {
      success: true,
      refreshEditor: true,
      operation: 'change-measure',
      description: `changed measurement`
    };
  }

  changeMeasureVisible(_: any, measureId: number, visible: boolean): SceneOperationResult {
    let measure = this.measures[measureId];
    if (measure === undefined)
      return {success: false};

    if (measure.visible !== visible) {
      this.measures = {
        ...this.measures,
        [measureId]: {
          ...measure,
          visible
        }
      };

      return {
        success: true,
        refreshEditor: true
      };
    }

    return {success: true};
  }

  addLight(_: any, light: ILight): SceneOperationResult {
    if (Object.keys(this.lights).length === 0)
      light.id = 1;
    else
      light.id = 1 + Math.max(...Object.keys(this.lights).map(Number));

    this.lights = {
      ...this.lights,
      [light.id]: light
    };

    return {
      success: true,
      refreshEditor: true,
      operation: 'add-light',
      description: `added light`
    };
  }

  deleteLight(_: any, lightId: number): SceneOperationResult {
    let {[lightId]: light, ...newLights} = this.lights;
    if (light === undefined)
      return {success: true};

    this.lights = newLights;

    return {
      success: true,
      refreshEditor: true,
      operation: 'remove-light',
      description: `removed light`
    };
  }

  changeLight(_: any, light: ILight, dontCommit?: boolean): SceneOperationResult {
    this.lights = {
      ...this.lights,
      [light.id]: light
    };

    if (dontCommit) {
      return {
        success: true,
        refreshEditor: true,
        preventStateUpdate: true
      };
    } else {
      return {
        success: true,
        refreshEditor: true,
        operation: 'change-light',
        description: `changed light`
      };
    }
  }

  changeLightInfo(_: any, lightId: number, lightInfo: any): SceneOperationResult {
    let light = this.lights[lightId];
    if (light === undefined)
      return {success: false};

    this.lights = {
      ...this.lights,
      [lightId]: {
        ...light,
        light: lightInfo
      }
    };

    return {
      success: true,
      refreshEditor: true,
      operation: 'change-light',
      description: `changed light`
    };
  }

  changeLightVisible(_: any, lightId: number, visible: boolean): SceneOperationResult {
    let light = this.lights[lightId];
    if (light === undefined)
      return {success: false};

    if (light.visible !== visible) {
      this.lights = {
        ...this.lights,
        [lightId]: {
          ...light,
          visible,
          helperVisible: visible ? light.helperVisible : visible
        }
      };

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-light-visible',
        description: `made light ${visible ? 'visible' : 'invisible'}`
      };
    }

    return {success: true};
  }

  changeLightHelperVisible(_: any, lightId: number, helperVisible: boolean): SceneOperationResult {
    let light = this.lights[lightId];
    if (light === undefined)
      return {success: false};

    if (light.helperVisible !== helperVisible) {
      this.lights = {
        ...this.lights,
        [lightId]: {
          ...light,
          helperVisible
        }
      };

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-light-helper-visible',
        description: `made light helper ${helperVisible ? 'visible' : 'invisible'}`
      };
    }

    return {success: true};
  }

  changeLightCollapsed(_: any, lightId: number, collapsed: boolean): SceneOperationResult {
    let light = this.lights[lightId];
    if (light === undefined)
      return {success: false};

    this.lights = {
      ...this.lights,
      [lightId]: {
        ...light,
        collapsed
      }
    };

    return {success: true};
  }

  changeLightSubGroupCollapsed(_: any, lightId: number, index: number, collapsed: boolean): SceneOperationResult {
    let light = this.lights[lightId];
    if (light === undefined)
      return {success: false};

    if (light.settingGroupExpanded[index] && collapsed) {
      let settingGroupExpanded = lod.omit(light.settingGroupExpanded, index);
      this.lights = {
        ...this.lights,
        [lightId]: {
          ...light,
          settingGroupExpanded
        }
      };
    } else if (!light.settingGroupExpanded[index] && !collapsed) {
      this.lights = {
        ...this.lights,
        [lightId]: {
          ...light,
          settingGroupExpanded: {...light.settingGroupExpanded, [index]: 1}
        }
      };
    }

    return {success: true};
  }

  changeFloorVisible(_: any, visible: boolean): SceneOperationResult {
    if (this.floor.visible !== visible || this.floor.gridVisible !== visible || this.floor.showUnits !== visible) {
      this.floor = {
        ...this.floor,
        visible,
        gridVisible: visible,
        showUnits: visible
      };

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-floor-visible',
        description: `made floor ${visible ? 'visible' : 'invisible'}`
      };
    }

    return {success: true};
  }

  changeFloorGridVisible(_: any, gridVisible: boolean): SceneOperationResult {
    if ((gridVisible && !this.floor.visible) || this.floor.gridVisible !== gridVisible || this.floor.showUnits !== gridVisible) {
      this.floor = {
        ...this.floor,
        visible: gridVisible ? true : this.floor.visible,
        gridVisible: gridVisible,
        showUnits: gridVisible
      };

      return {
        success: true,
        refreshEditor: true,
        operation: 'change-floor-grid-visible',
        description: `made floor grid ${gridVisible ? 'visible' : 'invisible'}`
      };
    }

    return {success: true};
  }

  changeFloorSettingCollapsed(_: any, collapsed: boolean): SceneOperationResult {
    if (this.floor.collapsed !== collapsed) {
      this.floor = {
        ...this.floor,
        collapsed
      };
    }
    return {success: true};
  }

  changeFloorSettingSubGroupCollapsed(_: any, index: number, collapsed: boolean): SceneOperationResult {
    if (!this.floor.settingGroupExpanded[index] !== collapsed) {
      let settingGroupExpanded = this.floor.settingGroupExpanded;
      if (collapsed) {
        settingGroupExpanded = lod.omit(settingGroupExpanded, index);
      } else {
        settingGroupExpanded = {...settingGroupExpanded, [index]: 1};
      }

      this.floor = {
        ...this.floor,
        settingGroupExpanded
      };
    }

    return {success: true};
  }

  changeFloor(_: any, floor: IFloor, dontCommit?: boolean): SceneOperationResult {
    this.floor = {
      ...floor,
      gridVisible: floor.showUnits ? true : floor.gridVisible,
      visible: floor.showUnits ? true : floor.visible
    };

    if (dontCommit) {
      return {
        success: true,
        refreshEditor: true,
        preventStateUpdate: true
      };
    } else {
      return {
        success: true,
        refreshEditor: true,
        operation: 'change-floor',
        description: `changed floor setting`
      };
    }
  }

  deleteLibraryItem(dispatch: ThunkDispatch<{}, {}, AnyAction>, item: ILibraryItem): SceneOperationResult {
    batch(() => {
      dispatch(removeLibraryItems([item.id]));

      if (item.entityType === LibraryItemTypes.Environment && (item.projectId || item.username))
        dispatch(removeEnvironment(item.entityId));
      else if (item.entityType === LibraryItemTypes.Processor && (item.projectId || item.username))
        dispatch(removeProcessors([item.entityId]));
      else if (item.entityType === LibraryItemTypes.DigitalMaterial && (item.projectId || item.username))
        dispatch(removeDigitalMaterial(item.entityId));
      else if (item.entityType === LibraryItemTypes.Light && (item.projectId || item.username))
        dispatch(removeLight(item.entityId));
    });

    let result: SceneOperationResult = {
      success: true,
      operation: 'delete-library-item',
      description: `deleted library item`
    };

    if (item.entityType === LibraryItemTypes.DigitalMaterial)
      result = this.deleteDigitalMaterial(dispatch, item.entityId);

    return result;
  }

  createEnvironmentAndSet(dispatch: ThunkDispatch<{}, {}, AnyAction>, environment: IEnvironment, myLibrary: boolean, type: 'light' | 'back' | '', lightToBack: boolean): SceneOperationResult {
    let result = this.createEnvironment(dispatch, environment, myLibrary);
    if (type === 'light') {
      this.changeEnvironmentInfo(dispatch, {lightEnvironment: environment, isSetAsBackground: lightToBack});
    } else if (type === 'back') {
      this.changeEnvironmentInfo(dispatch, {backgroundEnvironment: environment, isSetAsBackground: lightToBack});
    }

    return {
      ...result,
      refreshEditor: !!type
    };
  }

  createEnvironment(dispatch: ThunkDispatch<{}, {}, AnyAction>, environment: IEnvironment, myLibrary: boolean): SceneOperationResult {
    let project = this.getProject();
    if (!project)
      return {success: false};

    let categoryTitle = ''

    if (environment.type === EnvMapTypes.Lighting)
      categoryTitle = 'lighting'
    else if (environment.type === EnvMapTypes.Color)
      categoryTitle = 'color';
    else if (environment.type === EnvMapTypes.Image)
      categoryTitle = 'image';

    let category = myLibrary ? this.getLibraryCategory('', 'my library') : this.getLibraryCategory(categoryTitle, 'environment');
    if (!category)
      return {success: false};

    let libraryItem: ILibraryItem = {
      id: peregrineId(),
      title: environment.title,
      image: 'images/default.png',
      entityType: LibraryItemTypes.Environment,
      entityId: environment.id,
      categoryId: category.id,
      projectId: myLibrary ? 0 : project.id,
      username: myLibrary ? project.username : '',
      hashtags: ['environment', environment.title]
    };

    batch(() => {
      dispatch(addEnvironments([environment]));
      dispatch(addLibraryItems([libraryItem]));
    });

    return {
      success: true,
      operation: 'create-environment',
      description: `created environment`
    };
  }

  changeEnvironmentAndSet(dispatch: ThunkDispatch<{}, {}, AnyAction>, environment: IEnvironment, type: 'light' | 'back' | '', lightToBack: boolean): SceneOperationResult {
    let result = this.changeEnvironments(dispatch, [environment]);
    if (type === 'light') {
      this.changeEnvironmentInfo(dispatch, {lightEnvironment: environment, isSetAsBackground: lightToBack});
    } else if (type === 'back') {
      this.changeEnvironmentInfo(dispatch, {backgroundEnvironment: environment, isSetAsBackground: lightToBack});
    }

    return {
      ...result,
      refreshEditor: !!type
    };
  }

  changeEnvironments(dispatch: ThunkDispatch<{}, {}, AnyAction>, environments: IEnvironment[]): SceneOperationResult {
    batch(() => {
      dispatch(addEnvironments(environments));
    });

    return {
      success: true,
      operation: 'change-environment',
      description: `changed environment`
    };
  }

  createDigitalMaterialAndSet(dispatch: ThunkDispatch<{}, {}, AnyAction>, material: IDigitalMaterial, myLibrary: boolean, calcIds: string[]): SceneOperationResult {
    let result = this.createDigitalMaterial(dispatch, material, myLibrary);
    if (!result.success)
      return result;
    return {
      ...this.changeCalcsDigitalMaterial(dispatch, calcIds, material),
      operation: result.operation,
      description: result.description
    };
  }

  createDigitalMaterial(dispatch: ThunkDispatch<{}, {}, AnyAction>, material: IDigitalMaterial, myLibrary: boolean): SceneOperationResult {
    return this.createDigitalMaterials(dispatch, [material], myLibrary);
  }

  createDigitalMaterials(dispatch: ThunkDispatch<{}, {}, AnyAction>, materials: IDigitalMaterial[], myLibrary: boolean): SceneOperationResult {
    let project = this.getProject();
    if (!project)
      return {success: false};

    let category = myLibrary ? this.getLibraryCategory('', 'my library') : this.getLibraryCategory('', 'digital material');
    if (!category)
      return {success: false};

    let libraryItems: ILibraryItem[] = [];

    for (let material of materials) {
      libraryItems.push({
        id: peregrineId(),
        title: material.title,
        entityType: LibraryItemTypes.DigitalMaterial,
        image: 'images/default.png',
        entityId: material.id,
        categoryId: category.id,
        projectId: myLibrary ? 0 : project.id,
        username: myLibrary ? project.username : '',
        hashtags: ['digital material']
      });
    }

    batch(() => {
      dispatch(addDigitalMaterials(materials));
      dispatch(addLibraryItems(libraryItems));
    });

    return {
      success: true,
      ids: libraryItems.map(item => item.id),
      operation: 'create-digital-material',
      description: `created digital material`
    };
  }

  changeDigitalMaterialAndSet(dispatch: ThunkDispatch<{}, {}, AnyAction>, material: IDigitalMaterial, calcIds: string[]): SceneOperationResult {
    let result = this.changeDigitalMaterial(dispatch, material);
    if (!result.success)
      return result;
    return {
      ...this.changeCalcsDigitalMaterial(dispatch, calcIds, material),
      operation: result.operation,
      description: result.description
    };
  }

  changeDigitalMaterial(dispatch: ThunkDispatch<{}, {}, AnyAction>, material: IDigitalMaterial): SceneOperationResult {
    return this.changeDigitalMaterials(dispatch, [material]);
  }

  changeDigitalMaterials(dispatch: ThunkDispatch<{}, {}, AnyAction>, materials: IDigitalMaterial[]): SceneOperationResult {
    batch(() => {
      dispatch(addDigitalMaterials(materials));
    });

    return {
      success: true,
      refreshEditor: true,
      operation: 'change-digital-material',
      description: `changed digital material`
    };
  }

  createProcessor(dispatch: ThunkDispatch<{}, {}, AnyAction>, processor: IProcessor, itemTitle: string, itemImage: string): SceneOperationResult {
    let project = this.getProject();
    if (!project)
      return {success: false};

    let category = this.getLibraryCategory('', 'my library');
    if (!category)
      return {success: false};

    let libraryItem: ILibraryItem = {
      id: peregrineId(),
      title: itemTitle,
      image: itemImage,
      entityType: LibraryItemTypes.Processor,
      entityId: processor.id,
      categoryId: category.id,
      projectId: 0,
      username: project.username,
      hashtags: ['model', itemTitle],
    };

    batch(() => {
      dispatch(addProcessors([processor]));
      dispatch(addLibraryItems([libraryItem]));
    });

    return {
      success: true,
      operation: 'create-digital-material',
      description: `created digital material`
    };
  }

  addToMyLibrary(dispatch: ThunkDispatch<{}, {}, AnyAction>, item: ILibraryItem, copy: boolean): SceneOperationResult {
    let project = this.getProject();
    if (!project)
      return {success: false};

    let category = this.getLibraryCategory('', 'my library');
    if (!category)
      return {success: false};

    let newItem: ILibraryItem = {
      ...item,
      id: copy ? peregrineId() : item.id,
      categoryId: category.id,
      projectId: 0,
      username: project.username,
    };

    let materials: IDigitalMaterial[] = [];
    let environments: IEnvironment[] = [];

    if (item.entityType === LibraryItemTypes.DigitalMaterial) {
      let material = store.getState().entities.digitalMaterials.byId[item.entityId];
      if (material) {
        materials.push({
          ...material,
          id: copy ? peregrineId() : material.id
        });
      }
    } else if (item.entityType === LibraryItemTypes.Environment) {
      let environment = store.getState().entities.environments.byId[item.entityId];
      if (environment) {
        environments.push({
          ...environment,
          id: copy ? peregrineId() : environment.id
        });
      }
    }

    batch(() => {
      dispatch(addLibraryItems([newItem]));
      if (materials.length > 0)
        dispatch(addDigitalMaterials(materials));
      if (environments.length > 0)
        dispatch(addEnvironments(environments));
    });

    return {
      success: true,
      operation: 'add-to-my-library',
      description: `added to my library`
    };
  }

  hideOtherCalcsAndSculpt(_: any, calcIds: string[]): SceneOperationResult {
    let allCalcIds = this.getEditLevelCalcIds(this.editLevel);
    this.changeCalcsVisible(_, allCalcIds, false);
    this.changeCalcsVisible(_, calcIds, true);
    return this.changeSelectedCalcs(_, calcIds, Tools.Sculpt);
  }
}

export let ProjectSceneDispatch: { [key: string]: (projectId: number, wrapper?: Editor3dWrapper, ...otherParams: any[]) => ThunkAction<Promise<IGAR>, {}, {}, AnyAction> } = {};

(function collectProjectSceneDispatches(destDispatch) {
  let names = Object.getOwnPropertyNames(ProjectScene.prototype);

  for (let name of names) {
    destDispatch[name] = (projectId: number, wrapper?: Editor3dWrapper, ...otherParams: any[]): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
      return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
        return dispatch(atomic(projectId, async (scene, dispatch) => {
          return (scene as any)[name](dispatch, ...otherParams);
        }, wrapper));
      }
    }
  }
})(ProjectSceneDispatch);