import {InitStoreAction} from "../../types/init";
import {IGAR} from "../types";

export const CHANGE_CONFIRM_DIALOG_OPENED = 'CHANGE_CONFIRM_DIALOG_OPENED';
export const CHANGE_SIGN_IN_DIALOG_OPENED = 'CHANGE_SIGN_IN_DIALOG_OPENED';
export const ADD_SIGN_IN_DIALOG_ON_AFTER_CLOSE = 'ADD_SIGN_IN_DIALOG_ON_AFTER_CLOSE';

export interface IDialogState {
  confirmDialogOpened: boolean
  confirmDialogText: string
  onConfirmDialogAfterClose: (confirmed: boolean) => void

  signInDialogOpened: boolean
  onSignInDialogAfterCloses: ((result: IGAR) => void)[]
}

interface ChangeConfirmDialogOpenedAction {
  type: typeof CHANGE_CONFIRM_DIALOG_OPENED
  payload: {
    opened: boolean
    text?: string
    onAfterClose?: (confirmed: boolean) => void
  }
}

interface ChangeSignInDialogOpenedAction {
  type: typeof CHANGE_SIGN_IN_DIALOG_OPENED
  payload: {
    opened: boolean
  }
}

interface AddSignInDialogOnAfterCloseAction {
  type: typeof ADD_SIGN_IN_DIALOG_ON_AFTER_CLOSE
  payload: {
    onAfterClose: (result: IGAR) => void
  }
}

export type DialogActionTypes = ChangeConfirmDialogOpenedAction |
  ChangeSignInDialogOpenedAction |
  AddSignInDialogOnAfterCloseAction |
  InitStoreAction