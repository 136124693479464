import {mat4, vec3} from "gl-matrix";
import Enums from "./misc/Enums";
import SculptManager from "./editing/SculptManager";
import StateManager from "./states/StateManager";
import Picking from "./math3d/Picking";

var _TMP_MAT = mat4.create();

export default class SceneProxy {
  constructor(scene) {
    this._scene = scene;
    this._sculptManager = new SculptManager(this);
    this._stateManager = new StateManager(this); // for undo-redo
    this._picking = new Picking(this); // the ray picking
    this._pickingSym = new Picking(this, true); // the symmetrical picking
    this._lastMouseX = 0;
    this._lastMouseY = 0;
    this._mouseX = 0;
    this._mouseY = 0;
    this._action = Enums.Action.NOTHING;

    this._projectionMatrix = mat4.create();
    this._width = 1;
    this._height = 1;
  };

  getPicking = () => {
    return this._picking;
  };

  getPickingSymmetry = () => {
    return this._pickingSym;
  };

  setCanvasCursor = (cursor) => {
    // TODO: BYZ - implement this
    // console.log('cursor changed to ' + cursor);
  };

  getSculptManager = () => {
    return this._sculptManager;
  };

  getPixelRatio = () => {
    return 1;
  };

  getProjection = () => {
    return this._projectionMatrix;
  }

  getAction = () => {
    return this._action;
  }

  setAction = (action) => {
    this._action = action;
  }

  unproject = (x, y, z) => {
    mat4.invert(_TMP_MAT, this._projectionMatrix);
    return vec3.transformMat4(vec3.create(), vec3.fromValues(
      x / this._width * 2 - 1,
      -y / this._height * 2 + 1,
      z * 2 - 1
    ), _TMP_MAT);
  };

  project = (vec) => {
    let res = vec3.transformMat4(vec3.create(), vec, this._projectionMatrix);
    return vec3.fromValues((res[0] + 1) * this._width / 2, (1 - res[1]) * this._height / 2, (res[2] + 1) / 2);
  };

  getMesh = () => {
    return this._scene.getMesh();
  };

  getMeshes = () => {
    return this._scene.getMeshes();
  };

  getSelectedMeshes = () => {
    return this._scene.getSelectedMeshes();
  };

  getIndexMesh = (mesh, select) => {
    return this._scene.getIndexMesh(mesh, select);
  }

  getStateManager() {
    return this._stateManager;
  }

  setMesh = (mesh) => {
    return this._scene.setMesh(mesh);
  };

  addAction = (type) => {
    if (this._scene && this._scene.addAction) {
      this._scene.addAction(type);
    }
  };

  render = () => {
    if (this._scene && this._scene.render) {
      return this._scene.render();
    }
  };
}