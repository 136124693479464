import {applyMiddleware, combineReducers, createStore, Store} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import thunk from 'redux-thunk';
import {entityAuthReducer} from "./reducers/entity/auth";
import {entityGlobalReducer} from "./reducers/entity/global";
import {signInReducer} from "./reducers/ui/sign-in";
import {signUpReducer} from "./reducers/ui/sign-up";
import {confirmEmailReducer} from "./reducers/ui/confirm-email";
import {resetPasswordReducer} from "./reducers/ui/reset-password";
import {entityProjectsReducer} from "./reducers/entity/projects";
import {globalReducer} from "./reducers/ui/global";
import {apiProjectReducer} from "./reducers/api/project";
import {projectReducer} from "./reducers/logic/project";
import {apiAuthReducer} from "./reducers/api/auth";
import {apiLibraryReducer} from "./reducers/api/library";
import {entityDigitalMaterialsReducer} from "./reducers/entity/digital-materials";
import {entityEnvironmentsReducer} from "./reducers/entity/environments";
import {entityLibraryCategoriesReducer} from "./reducers/entity/library-categories";
import {entityLibraryItemsReducer} from "./reducers/entity/library-items";
import {entityLibraryTopCategoriesReducer} from "./reducers/entity/library-top-categories";
import {entityLightsReducer} from "./reducers/entity/lights";
import {entityProcessorsReducer} from "./reducers/entity/processors";
import {entityScreenshotsReducer} from "./reducers/entity/screenshots";
import {entityChatSessionsReducer} from "./reducers/entity/chat-sessions";
import {entityChatsReducer} from "./reducers/entity/chats";
import {apiChatReducer} from "./reducers/api/chat";
import {entityNotificationsReducer} from "./reducers/entity/notifications";
import {apiSceneReducer} from "./reducers/api/scene";
import {chatBotReducer} from "./reducers/ui/chat-bot";
import {dialogReducer} from "./reducers/ui/dialog";
import {sceneReducer} from "./reducers/ui/scene";
import {wizardReducer} from "./reducers/ui/wizard";
import {entityProfilesReducer} from "./reducers/entity/profiles";
import {entityOrdersReducer} from "./reducers/entity/orders";
import {apiProfileReducer} from "./reducers/api/profile";
import {apiOrdersReducer} from "./reducers/api/order";
import {libraryReducer} from "./reducers/ui/library";
import {apiWaiterReducer} from "./reducers/api/waiter";
import {entityWaitersReducer} from "./reducers/entity/waiters";
import initSubscriber from 'redux-subscriber';
import {heroReducer} from "./reducers/ui/hero";

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  router: connectRouter(history),
  entities: combineReducers({
    global: entityGlobalReducer,
    auth: entityAuthReducer,
    projects: entityProjectsReducer,
    libraryTopCategories: entityLibraryTopCategoriesReducer,
    libraryCategories: entityLibraryCategoriesReducer,
    libraryItems: entityLibraryItemsReducer,
    digitalMaterials: entityDigitalMaterialsReducer,
    environments: entityEnvironmentsReducer,
    lights: entityLightsReducer,
    processors: entityProcessorsReducer,
    screenshots: entityScreenshotsReducer,
    chatSessions: entityChatSessionsReducer,
    profiles: entityProfilesReducer,
    waiters: entityWaitersReducer,
    orders: entityOrdersReducer,
    chats: entityChatsReducer,
    notifications: entityNotificationsReducer,
  }),
  ui: combineReducers({
    signIn: signInReducer,
    signUp: signUpReducer,
    confirmEmail: confirmEmailReducer,
    resetPassword: resetPasswordReducer,
    hero: heroReducer,
    global: globalReducer,
    chatBot: chatBotReducer,
    dialog: dialogReducer,
    library: libraryReducer,
    scene: sceneReducer,
    wizard: wizardReducer,
  }),
  logic: combineReducers({
    project: projectReducer,
  }),
  api: combineReducers({
    scene: apiSceneReducer,
    project: apiProjectReducer,
    auth: apiAuthReducer,
    chat: apiChatReducer,
    waiter: apiWaiterReducer,
    library: apiLibraryReducer,
    profile: apiProfileReducer,
    order: apiOrdersReducer,
  })
});

export type IAppState = ReturnType<typeof rootReducer>

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 100,
});

export function configureStore(): Store<IAppState> {
  const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      thunk
    )
  ));

  initSubscriber(store);

  return store as unknown as Store<IAppState>;
}