
import {Parameter} from "./parameter/parameter";
import {ObjectTypes, XObject} from "./xobject";
import {_s} from "../t";
import {peregrineId} from "../id";

export class Relation extends XObject {
  protected _objectType = ObjectTypes.Relation;
  protected _scene: XObject = XObject.unset;

  get id() {
    return this._id;
  }

  set id(id: string) {
    this._id = id;
  }

  get scene() {
    return this._scene;
  }

  protected _from: Parameter = Parameter.unset;

  get from() {
    return this._from;
  }

  protected _to: Parameter = Parameter.unset;

  get to() {
    return this._to;
  }

  protected _data: any;

  get data() {
    return this._data;
  }

  static create(scene: XObject, from: Parameter, to: Parameter) {
    let relation = new Relation();
    relation._id = peregrineId();
    relation._from = from;
    relation._to = to;
    relation._scene = scene;

    from.addNextRelation(relation);
    to.addPrevRelation(relation);

    relation._data = {
      [_s('from')]: {
        [_s('calc')]: relation._from.calc.id,
        [_s('param')]: relation._from.id
      },
      [_s('to')]: {
        [_s('calc')]: relation._to.calc.id,
        [_s('param')]: relation._to.id
      }
    };

    return relation;
  }
}