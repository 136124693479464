import {getComposeDesc, TransformComponent, WompObjectRef} from "../../WompObject";
import {mat4, vec3} from "gl-matrix";
import {Calc} from "../calc";
import {
  getObjectType,
  getRegisteredBrepIdWithTessellation,
  Param_Boolean,
  Param_Compound,
  Param_Number,
  ParamTitles,
  ParamTypes
} from "../parameter";
import {Gumball, IRenderedObjectInternal} from "./gumball";
import {MeshGeometryObjectTypes, ObjectTypes} from "../xobject";
import {InputObjectParamTitles, NonMaterialComponentTypes} from "./types";
import {composeTransform, decomposeTransform} from "../../utils";
import {_n} from "../../t";
import {Scene} from "../scene";
import hash from "object-hash";

export enum PredictedResult {
  CopyFirst,
  MeshFirst,
  Ignore,
  Mesh = ObjectTypes.Mesh,
  Brep = ObjectTypes.Brep,
  Curve = ObjectTypes.Curve,
  Vertex = ObjectTypes.Vertex,
}

export type ObjectFuncParameter = { desc: string, matrix: mat4 } & { [key: string]: any };

async function solveObjectFunction(
  calc: Calc,
  funcs: {
    predictResult: (calc: Calc, objs: IRenderedObjectInternal[]) => PredictedResult,
    getParameters: (calc: Calc, objs: IRenderedObjectInternal[]) => ObjectFuncParameter,
    funcObject: (calc: Calc, objs: IRenderedObjectInternal[], parameters: ObjectFuncParameter) => Promise<IRenderedObjectInternal | undefined>
  }
) {
  let inParams = [];
  let length = +Infinity;
  for (let inParamTitle of (InputObjectParamTitles[calc.component] || [])) {
    let inParam = calc.inputByTitle(inParamTitle) as Param_Compound;
    if (!inParam.isInvalid) {
      inParams.push(inParam);
      length = Math.min(length, inParam.length);
    }
  }

  let objects = [];
  if (!isFinite(length))
    length = 1;

  for (let i = 0; i < length; ++i) {
    let objs: IRenderedObjectInternal[] = [];

    for (let inParam of inParams) {
      if (i < inParam.length) {
        objs.push({
          value: inParam.values[i],
          valueType: inParam.valueTypes ? inParam.valueTypes[i] : getObjectType(inParam.objectType as ParamTypes),
          cache: inParam.caches[i],
          property: inParam.properties[i],
        });
      }
    }

    let noMaterial = NonMaterialComponentTypes.includes(calc.component);
    let prediction = funcs.predictResult(calc, objs);
    let willBeMesh = MeshGeometryObjectTypes.includes(prediction as unknown as ObjectTypes);

    if (prediction === PredictedResult.CopyFirst) {
      if (objs.length > 0) {
        objects.push({
          value: objs[0].value,
          valueType: MeshGeometryObjectTypes.includes(objs[0].valueType) ? ObjectTypes.Mesh : objs[0].valueType,
          cache: objs[0].cache,
          property: noMaterial ? objs[0].property : undefined
        });
      }
      continue;
    } else if (prediction === PredictedResult.MeshFirst) {
      if (objs.length > 0 && objs[0].valueType === ObjectTypes.Brep) {
        objects.push({
          value: objs[0].cache[0],
          valueType: ObjectTypes.Mesh,
          cache: null,
          property: noMaterial ? objs[0].property : undefined
        });
      }
      continue;
    } else if (prediction === PredictedResult.Ignore) {
      continue;
    }

    let parameters = funcs.getParameters(calc, objs);

    let {id, meshId, edgeId} = getRegisteredBrepIdWithTessellation(calc, parameters.desc);

    if ((willBeMesh && id) || (!willBeMesh && (id && meshId && edgeId))) {
      let value = new WompObjectRef(id, parameters.matrix);
      let cache = willBeMesh ? null : [new WompObjectRef(meshId, parameters.matrix), new WompObjectRef(edgeId, parameters.matrix)];

      objects.push({
        value,
        cache,
        valueType: prediction as unknown as ObjectTypes,
        property: noMaterial && objs.length > 0 ? objs[0].property : undefined
      });
    } else {
      let result = await funcs.funcObject(calc, objs, parameters);

      if (result) {
        objects.push({
          ...result,
          valueType: prediction as unknown as ObjectTypes,
          property: noMaterial && objs.length > 0 ? objs[0].property : undefined
        });
      }
    }
  }

  return Gumball.solveGumball(calc, objects);
}

export class BasicObjectFunction {
  static getParameters(calc: Calc, objs: IRenderedObjectInternal[]): ObjectFuncParameter {
    return {desc: '', matrix: mat4.create()};
  }

  static predictResult(calc: Calc, objs: IRenderedObjectInternal[]) {
    return PredictedResult.CopyFirst;
  }

  static async funcObject(calc: Calc, objs: IRenderedObjectInternal[], parameters: ObjectFuncParameter): Promise<IRenderedObjectInternal | undefined> {
    return undefined;
  }

  static async solve(calc: Calc) {
    return solveObjectFunction(calc, {
      predictResult: this.predictResult,
      getParameters: this.getParameters,
      funcObject: this.funcObject
    });
  }
}

export class BasicObjectMapper {
  static getParameters(calc: Calc, obj: IRenderedObjectInternal) {
    return BasicObjectFunction.getParameters(calc, [obj]);
  }

  static predictResult(calc: Calc, obj: IRenderedObjectInternal) {
    return BasicObjectFunction.predictResult(calc, [obj]);
  }

  static async mapObject(calc: Calc, obj: IRenderedObjectInternal, parameters: ObjectFuncParameter) {
    return BasicObjectFunction.funcObject(calc, [obj], parameters);
  }

  static async solve(calc: Calc) {
    if ((InputObjectParamTitles[calc.component] || []).length === 1)
      return solveObjectFunction(calc, {
        predictResult: (calc, objs) => this.predictResult(calc, objs[0]),
        getParameters: (calc, objs) => this.getParameters(calc, objs[0]),
        funcObject: (calc, objs, parameters) => this.mapObject(calc, objs[0], parameters)
      });
    else
      return false;
  }
}

export class BasicObjectGenerator {
  static getParameters(calc: Calc) {
    return BasicObjectFunction.getParameters(calc, []);
  }

  static predictResult(calc: Calc) {
    return BasicObjectFunction.predictResult(calc, []);
  }

  static async mapObject(calc: Calc, parameters: ObjectFuncParameter) {
    return BasicObjectFunction.funcObject(calc, [], parameters);
  }

  static async solve(calc: Calc) {
    if ((InputObjectParamTitles[calc.component] || []).length === 0)
      return solveObjectFunction(calc, {
        predictResult: (calc, objs) => this.predictResult(calc),
        getParameters: (calc, objs) => this.getParameters(calc),
        funcObject: (calc, objs, parameters) => this.mapObject(calc, parameters)
      });
    else
      return false;
  }
}

export class DistantObjectMapper extends BasicObjectMapper {
  static getDesc() {
    return '';
  }

  static getParameters(calc: Calc, obj: IRenderedObjectInternal) {
    let distanceParam = calc.inputByTitle(ParamTitles.Distance) as Param_Number;
    let distance = distanceParam.values[0];
    let capParam = calc.inputByTitle(ParamTitles.Cap) as Param_Boolean;

    let objDesc = getComposeDesc(obj.value, 3, [TransformComponent.Translation, TransformComponent.Uniform, TransformComponent.Scale, TransformComponent.Rotation, TransformComponent.Quaternion, TransformComponent.Perspective]);
    let matrixComponents = decomposeTransform(obj.value.matrix);
    let appliedMatrix = composeTransform(vec3.create(), vec3.create(), matrixComponents.unitScale, matrixComponents.skew);

    let matrix = mat4.multiply(mat4.create(), obj.value.matrix, mat4.invert(mat4.create(), appliedMatrix));
    let parameters = {
      distance: distance * matrixComponents.uniform,
      appliedMatrix,
      cap: capParam.isInvalid ? undefined : capParam.values[0]
    };
    let desc = `${this.getDesc()}[${[objDesc, _n(parameters.distance, 1), parameters.cap].filter(p => p !== undefined).join(',')}]`;

    return {desc, matrix, ...parameters};
  }

  static predictResult(calc: Calc, obj: IRenderedObjectInternal) {
    if (obj.valueType === ObjectTypes.Brep) {
      let distanceParam = calc.inputByTitle(ParamTitles.Distance) as Param_Number;
      let distance = distanceParam.values[0];

      return distance === 0 ? PredictedResult.CopyFirst : PredictedResult.Brep;
    } else if (MeshGeometryObjectTypes.includes(obj.valueType)) {
      return PredictedResult.CopyFirst;
    }

    return PredictedResult.Ignore;
  }
}

export class ObjectBoolean {
  static getDesc(infos: { [key: string]: { value: WompObjectRef, voided: boolean } }) {
    let descs = Object.keys(infos).sort();
    return `union[${descs.map(d => (infos[d].voided ? '-' : '') + d).join(',')}]`;
  }

  static getOperation() {
    return 'union';
  }

  static async solve(calc: Calc) {
    let inParam = calc.inputByTitle((InputObjectParamTitles[calc.component] || [])[0]) as Param_Compound;
    if (inParam.isInvalid)
      return false;

    let objects = [];

    let isMesh = false;
    let weldAngle = 90;

    let infos: { [key: string]: { value: WompObjectRef, voided: boolean, cache: [WompObjectRef, WompObjectRef] | null } } = {};

    for (let i = 0; i < inParam.length; ++i) {
      let property = inParam.properties[i];
      let valueType = inParam.valueTypes[i];

      if (property) {
        if (MeshGeometryObjectTypes.includes(valueType)) {
          isMesh = true;
          weldAngle = Math.min(property.weldAngle, weldAngle);
        } else if (valueType === ObjectTypes.Brep) {
          weldAngle = Math.min(property.weldAngle, 22.5);
        }
      }
    }

    for (let i = 0; i < inParam.length; ++i) {
      let value = inParam.values[i];
      let valueType = inParam.valueTypes[i];
      let cache = inParam.caches[i] as [WompObjectRef, WompObjectRef];
      let property = inParam.properties[i];
      let voided = property && property.voided;

      if (MeshGeometryObjectTypes.includes(valueType)) {
        let desc = getComposeDesc(value, 3);
        infos[desc] = {value, voided, cache: null};
      } else if (valueType === ObjectTypes.Brep) {
        if (isMesh) {
          let desc = getComposeDesc(cache[0], 3);
          infos[desc] = {value: cache[0], voided, cache: null};
        } else {
          let desc = getComposeDesc(value, 3);
          infos[desc] = {value, voided, cache};
        }
      }
    }

    let descs = Object.keys(infos).sort();

    if (descs.filter(d => !infos[d].voided).length > 0) {
      if (descs.length === 1 && descs.filter(d => infos[d].voided).length === 0) {
        objects.push({
          value: infos[descs[0]].value.clone(),
          valueType: isMesh ? ObjectTypes.Mesh : ObjectTypes.Brep,
          cache: infos[descs[0]].cache ? [infos[descs[0]].cache![0].clone(), infos[descs[0]].cache![1].clone()] : null
        });
      } else {
        let baseCount = 0;
        while (baseCount < descs.length - 1 && infos[descs[baseCount]].value.objectId === infos[descs[baseCount + 1]].value.objectId)
          ++baseCount;

        for (let i = baseCount; i >= 0; --i) {
          let matrix = infos[descs[i]].value.matrix;
          let invMatrix = mat4.invert(mat4.create(), matrix);

          let newInfos: { [key: string]: { value: WompObjectRef, voided: boolean } } = {};
          for (let desc of descs) {
            let value = infos[desc].value.clone();
            if (invMatrix)
              mat4.multiply(value.matrix, invMatrix, value.matrix);
            let newDesc = getComposeDesc(value, 3);
            newInfos[newDesc] = {value, voided: infos[desc].voided};
          }

          let newDescs = Object.keys(newInfos).sort();
          let desc = this.getDesc(newInfos);

          let {id, meshId, edgeId} = getRegisteredBrepIdWithTessellation(calc, desc);

          if ((isMesh && id) || (!isMesh && (id && meshId && edgeId))) {
            let value = new WompObjectRef(id, matrix);
            let cache = isMesh ? null : [new WompObjectRef(meshId, matrix), new WompObjectRef(edgeId, matrix)];

            objects.push({
              value,
              valueType: isMesh ? ObjectTypes.Mesh : ObjectTypes.Brep,
              cache
            });

            break;
          } else if (i === 0) {
            await (calc.scene as Scene).startOperationOnGeometries(this.getOperation(), calc.id, hash(desc, {algorithm: 'md5'}), {
              desc,
              infos: newDescs.map(d => ({...newInfos[d]})),
              isMesh,
              weldAngle
            });

            for (let i = 0; i < inParam.length; ++i) {
              let value = inParam.values[i];
              let valueType = inParam.valueTypes[i];
              let property = inParam.properties[i];
              let cache = inParam.caches[i];

              objects.push({
                value, valueType, cache, property: {
                  ...property,
                  valid: false
                }
              });
            }
          }
        }
      }
    }

    return Gumball.solveGumball(calc, objects);
  }
}