import lod from "lodash";
import {mat4} from "gl-matrix";
import {XObject} from "../xobject";
import {ICalcConfigDiff, IParameterProperty} from "../types";
import {Relation} from "../relation";
import {getObjectType, Parameter, ParamTitles, ParamTypes} from "./parameter";
import {Param_Compound} from "./param-compound";
import {_p, _q, _s} from "../../t";
import {peregrineId} from "../../id";

export class Param_Transform extends Parameter {
  protected _objectType = ParamTypes.Transform;
  protected _defaultValue: mat4 | undefined;
  protected _render = false;

  protected _stateValid = true;
  // Runtime Data
  protected _values: mat4[] = [];

  get values() {
    return this._values;
  }

  set values(values: mat4[]) {
    if (!lod.isEqual(this._values, values)) {
      this._values = values;
      this._dataValid = false;
    }
  }

  get defaultValue() {
    return this._defaultValue;
  }

  set defaultValue(defaultValue: mat4 | undefined) {
    if (this._defaultValue !== defaultValue) {
      this._defaultValue = defaultValue;
      this._data = {
        ...this._data,
        [_s('defaultValue')]: defaultValue ? _p(defaultValue) : undefined
      };
    }
  }

  get length() {
    return this._values.length;
  }

  static create(calc: XObject, title: string, isInput: boolean, isOperable: boolean,
                options?: { render?: boolean, defaultValue?: mat4 }) {
    let param = new Param_Transform();
    param._id = peregrineId();
    param._calc = calc;
    param._title = title;
    param._isInput = isInput;
    param._isOperable = isOperable;

    if (options === undefined) options = {};

    options.render !== undefined && (param._render = options.render);
    param.defaultValue = options.defaultValue === undefined ? mat4.create() : options.defaultValue;

    if (param.defaultValue)
      param.values = [mat4.clone(param.defaultValue)];

    param.setupInitialData();
    return param;
  }

  generateProperties(): IParameterProperty[] {
    let properties: IParameterProperty[] = [];

    for (let i = 0; i < this._values.length; ++i) {
      let value = this._values[i];

      properties.push({
        hash: _p(value).join(' '),
        gumball: this._title === ParamTitles.Transform
      });
    }

    return properties;
  }

  protected validateGeneratedData() {
    this.dataValues = this._values.map(v => _p(v));

    this._dataValid = true;
  }

  overwrite(jData: any, replaceData: boolean) {
    if (!this.dataValid)
      this.validateGeneratedData();

    super.overwrite(jData, replaceData);

    if (this._data[_s('values')] !== jData[_s('values')] && jData[_s('values')] !== undefined) {
      this.values = jData[_s('values')].map(_q);
    }

    if (this._data[_s('defaultValue')] !== jData[_s('defaultValue')] && jData[_s('defaultValue')] !== undefined) {
      this.defaultValue = _q(jData[_s('defaultValue')]);
    }

    if (replaceData) {
      this._data = jData;
      this._dataValid = true;
    }
  }

  solve(diff?: ICalcConfigDiff) {
    if (diff && diff.obj) {
      this._values = [];

      for (let relation of this._prevRelations) {
        let prev = (relation as unknown as Relation).from;
        if (prev.objectType === ParamTypes.Compound) {
          let prevComp = prev as Param_Compound;
          this._values = prevComp.filterValues(getObjectType(this._objectType));
        } else {
          if (prev.objectType === this._objectType) {
            let prevSame = prev as Param_Transform;
            this._values = [...this._values, ...prevSame._values];
          } else {
            console.warn('Type mismatch. Please implement type conversion');
          }
        }
      }

      if (this._prevRelations.length === 0 && this._defaultValue !== undefined) {
        this._values = [mat4.clone(this._defaultValue)];
      }
      this._dataValid = false;
    }

    for (let i = 0; i < this.length; ++i) {
      if (lod.some(this._values[i], isNaN)) {
        console.warn(`NaN value found in parameter[${this._title}] of calc[${(this._calc as any).title}]`);
        this._values[i] = mat4.create();
        this._dataValid = false;
      }
    }
  }
}
