import {
  ADD_LIBRARY_CATEGORIES,
  IEntityLibraryCategoriesState,
  LibraryCategoriesActionTypes,
  REMOVE_LIBRARY_CATEGORY,
  REPLACE_LIBRARY_CATEGORIES
} from '../../types/entity/library-categories';
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';
import {
  ADD_LIBRARY_ITEMS,
  LibraryItemsActionTypes,
  REMOVE_LIBRARY_ITEMS
} from "../../types/entity/library-items";

const initialState: IEntityLibraryCategoriesState = {
  byId: {},
  allIds: []
};

export function entityLibraryCategoriesReducer(
  state = initialState,
  action: LibraryCategoriesActionTypes | LibraryItemsActionTypes
): IEntityLibraryCategoriesState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case REPLACE_LIBRARY_CATEGORIES: {
      return {
        byId: lod.keyBy(action.payload.categories, 'id'),
        allIds: lod.map(action.payload.categories, 'id')
      };
    }
    case ADD_LIBRARY_CATEGORIES: {
      return {
        byId: {
          ...state.byId,
          ...lod.keyBy(action.payload.categories, 'id')
        },
        allIds: [
          ...state.allIds,
          ...lod.map(action.payload.categories, 'id').filter(id => !state.byId[id])
        ]
      };
    }
    case REMOVE_LIBRARY_CATEGORY: {
      return {
        byId: lod.omit(state.byId, action.payload.id),
        allIds: state.allIds.filter(id => id !== action.payload.id)
      };
    }

    case ADD_LIBRARY_ITEMS: {
      return {
        byId: lod.mapValues(state.byId, c => ({
          ...c,
          itemIds: [...c.itemIds, ...action.payload.items.filter(l => l.categoryId === c.id).map(l => l.id).filter(i => !c.itemIds.includes(i))]
        })),
        allIds: state.allIds
      };
    }
    case REMOVE_LIBRARY_ITEMS: {
      return {
        byId: lod.mapValues(state.byId, c => ({
          ...c,
          itemIds: c.itemIds.filter(i => !action.payload.ids.includes(i))
        })),
        allIds: state.allIds
      };
    }
    default:
      return state;
  }
}