import {INIT_STORE} from "../../types/init";
import {
  ApiSceneActionTypes, FETCH_ROUTE,
  GET_CALC_DETAIL_INFO,
  GET_CALC_HEATMAP,
  IApiSceneState, POST_CHANGE_CURSOR, POST_ROUTE
} from "../../types/api/scene";

const initialState: IApiSceneState = {
  heatmaps: {},
  detailInfos: {},
  upRoute: {
    posting: false
  },
  downRoute: {
    fetching: false
  },
  changeCursor: {
    posting: false
  }
};

export function apiSceneReducer(
  state = initialState,
  action: ApiSceneActionTypes
): IApiSceneState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case GET_CALC_HEATMAP: {
      return {
        ...state,
        heatmaps: {
          ...state.heatmaps,
          [action.payload.id]: {
            fetching: action.payload.fetching,
            message: action.payload.message,
            id: action.payload.id,
            extraInfo: action.payload.extraInfo
          }
        }
      };
    }
    case GET_CALC_DETAIL_INFO: {
      return {
        ...state,
        detailInfos: {
          ...state.detailInfos,
          [action.payload.id]: {
            fetching: action.payload.fetching,
            message: action.payload.message,
            id: action.payload.id,
            extraInfo: action.payload.extraInfo
          }
        }
      };
    }
    case POST_ROUTE:
      return {
        ...state,
        upRoute: {
          posting: action.payload.posting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    case FETCH_ROUTE:
      return {
        ...state,
        downRoute: {
          fetching: action.payload.fetching,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    case POST_CHANGE_CURSOR:
      return {
        ...state,
        changeCursor: {
          posting: action.payload.posting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    default:
      return state;
  }
}