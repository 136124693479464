import {reorientGeometry} from "./reorient";
import {mergeGeometryVertices, WompMeshData} from "../WompObject";
import {vec3} from "gl-matrix";

export interface IOffsetOptions {
  includeSource: boolean
  includeTarget: boolean
  includeSide: boolean
  direction?: vec3
}

const defaultOption: IOffsetOptions = {
  includeSource: false,
  includeTarget: true,
  includeSide: false
};

export function offsetGeometry(geometry: WompMeshData, distance: number, options: Partial<IOffsetOptions>) {

  let _opt = Object.assign({}, defaultOption, options);

  if (!geometry.position) {

    return geometry;

  }

  let orgGeometry = mergeGeometryVertices(geometry, 1e-6);

  if (!orgGeometry.face || !orgGeometry.position) {

    return geometry;

  }

  const keys = ['a', 'b', 'c'];
  const vertexInfos: { [key: string]: { normal: vec3 } } = {};
  const index = orgGeometry.face;
  const position = orgGeometry.position;
  const faceCnt = index.length / 3;
  const vertexCnt = orgGeometry.position.length / 3;
  let openEdgeCnt = 0;
  let faceNormals = new Array<{ normal: vec3, ws: number[] }>(faceCnt);
  let shiftMultiplier = Math.pow(10, 6);
  let edges: { [key: string]: any } = {};
  let verticeOnEdgeSet = new Set<number>();
  let verticeOnEdges = new Array<number>();
  let vertexMap: { [key: number]: number } = {};

  if (_opt.includeSide) {

    // Generate edge map
    for (let j = 0; j < faceCnt * 3; ++j) {

      let face: { [key: string]: number } = {
        a: index[j * 3],
        b: index[j * 3 + 1],
        c: index[j * 3 + 2]
      };

      for (let k = 0; k < 3; k++) {

        let vert1 = face[keys[k]];
        let vert2 = face[keys[(k + 1) % 3]];

        if (vert2 < vert1) [vert1, vert2] = [vert2, vert1];

        let key = vert1 + ',' + vert2;

        if (edges[key] === undefined) {

          edges[key] = {vert1: face[keys[k]], vert2: face[keys[(k + 1) % 3]], faces: [j, undefined], open: false};

        } else {

          edges[key].faces[1] = j;

        }

      }

    }

    for (let key in edges) {

      let edge = edges[key];

      // an edge is only rendered if the angle (in degrees) between the face normals of the adjoining faces exceeds this value.

      if (edge.faces[1] === undefined) {

        edge.open = true;

        ++openEdgeCnt;

        verticeOnEdgeSet.add(edge.vert1);
        verticeOnEdgeSet.add(edge.vert2);

      }

    }


    verticeOnEdges = Array.from(verticeOnEdgeSet);

    for (let ind in verticeOnEdges) {

      vertexMap[verticeOnEdges[ind]] = +ind;

    }

  }


  let cb = vec3.create(), ab = vec3.create();
  let t1 = vec3.create(), t2 = vec3.create();

  for (let i = 0; i < faceCnt; i++) {

    let a = index[i * 3];
    let b = index[i * 3 + 1];
    let c = index[i * 3 + 2];

    let vA = vec3.fromValues(position[a * 3], position[a * 3 + 1], position[a * 3 + 2]);
    let vB = vec3.fromValues(position[b * 3], position[b * 3 + 1], position[b * 3 + 2]);
    let vC = vec3.fromValues(position[c * 3], position[c * 3 + 1], position[c * 3 + 2]);

    vec3.sub(cb, vC, vB);
    vec3.sub(ab, vA, vB);
    vec3.cross(cb, cb, ab);

    vec3.normalize(cb, cb);


    let aTob = vec3.sqrDist(vA, vB);
    let aToc = vec3.sqrDist(vA, vC);
    let bToc = vec3.sqrDist(vB, vC);

    let cosAngle1 = aTob * aToc === 0 ? 1 : vec3.dot(vec3.sub(t1, vC, vA), vec3.sub(t2, vB, vA)) / (aTob * aToc);
    let cosAngle2 = aTob * bToc === 0 ? 1 : vec3.dot(vec3.sub(t1, vC, vB), vec3.sub(t2, vA, vB)) / (aTob * bToc);
    let cosAngle3 = aToc * bToc === 0 ? 1 : vec3.dot(vec3.sub(t1, vA, vC), vec3.sub(t2, vB, vC)) / (aToc * bToc);
    let angle1 = Math.acos(Math.max(Math.min(cosAngle1, 1), -1));
    let angle2 = Math.acos(Math.max(Math.min(cosAngle2, 1), -1));
    let angle3 = Math.acos(Math.max(Math.min(cosAngle3, 1), -1));

    faceNormals[i] = {

      normal: vec3.clone(cb),

      ws: [
        angle1 / (Math.PI / 2),
        angle2 / (Math.PI / 2),
        angle3 / (Math.PI / 2)
      ]

    };

  }

  for (let i = 0; i < faceCnt; i++) {

    for (let j = 0; j < 3; ++j) {

      let ind = index[i * 3 + j];

      let hash = `${~~(position[ind * 3] * shiftMultiplier)},${~~(position[ind * 3 + 1] * shiftMultiplier)},${~~(position[ind * 3 + 2] * shiftMultiplier)}`;

      if (hash in vertexInfos) {

        vec3.scaleAndAdd(vertexInfos[hash].normal, vertexInfos[hash].normal, faceNormals[i].normal, faceNormals[i].ws[j]);

      } else {

        vertexInfos[hash] = {normal: vec3.scale(vec3.create(), faceNormals[i].normal, faceNormals[i].ws[j])};

      }

    }

  }

  for (let hash in vertexInfos) {

    vec3.normalize(vertexInfos[hash].normal, vertexInfos[hash].normal);

  }

  let newGeometry: WompMeshData = {};

  let newFaceCnt = 0;
  let newVertexCnt = 0;

  if (_opt.includeSource) {

    newFaceCnt += faceCnt;
    newVertexCnt += vertexCnt;

  }

  if (_opt.includeTarget) {

    newFaceCnt += faceCnt;
    newVertexCnt += vertexCnt;

  }

  if (_opt.includeSide) {

    newFaceCnt += openEdgeCnt * 2;

    if (!_opt.includeSource) {

      newVertexCnt += openEdgeCnt;

    }

    if (!_opt.includeTarget) {

      newVertexCnt += openEdgeCnt;

    }

  }

  let newIndex = new Uint32Array(newFaceCnt * 3);
  let itemSizes: { [key: string]: number } = {
    'position': 3,
    'uv': 2,
    'color': 3,
    'normal': 3,
    'masking': 1
  };


  if (orgGeometry.color && orgGeometry.position && orgGeometry.color.length > orgGeometry.position.length) {
    itemSizes['color'] = 4;
  }

  for (let attributeName of ['position', 'uv', 'color', 'normal', 'masking']) {

    let attribute = (orgGeometry as { [key: string]: any })[attributeName];
    let itemSize = itemSizes[attributeName];

    if (attribute) {

      let newAttribute = new Float32Array(newVertexCnt * itemSize);

      const transferAttribute = (toInd: number, fromInd: number, target: boolean) => {

        if (attributeName === 'position' && target) {

          let pos = vec3.fromValues(position[fromInd * 3], position[fromInd * 3 + 1], position[fromInd * 3 + 2]);

          if (_opt.direction === undefined) {

            let hash = `${~~(pos[0] * shiftMultiplier)},${~~(pos[1] * shiftMultiplier)},${~~(pos[2] * shiftMultiplier)}`;

            vec3.scaleAndAdd(pos, pos, vertexInfos[hash].normal, distance);

          } else {

            vec3.scaleAndAdd(pos, pos, _opt.direction, distance);

          }

          newAttribute[toInd * itemSize] = pos[0];
          newAttribute[toInd * itemSize + 1] = pos[1];
          newAttribute[toInd * itemSize + 2] = pos[2];

        } else {

          for (let j = 0; j < itemSize; ++j) {

            newAttribute[toInd * itemSize + j] = attribute[fromInd * itemSize + j];

          }

        }

      };

      let vIndOffset = 0;

      if (_opt.includeSource) {

        for (let i = 0; i < vertexCnt; ++i) {

          transferAttribute(i, i, false);

        }

        vIndOffset += vertexCnt;

      }

      if (_opt.includeTarget) {

        for (let i = 0; i < vertexCnt; ++i) {

          transferAttribute(i + vIndOffset, i, true);

        }

        vIndOffset += vertexCnt;

      }

      if (_opt.includeSide) {

        if (!_opt.includeSource) {

          for (let i = 0; i < openEdgeCnt; ++i) {

            transferAttribute(i + vIndOffset, verticeOnEdges[i], false);

          }

          vIndOffset += openEdgeCnt;

        }

        if (!_opt.includeTarget) {

          for (let i = 0; i < openEdgeCnt; ++i) {

            transferAttribute(i + vIndOffset, verticeOnEdges[i], true);

          }

        }

      }

      (newGeometry as { [key: string]: any })[attributeName] = newAttribute;

    }

  }

  let fIndOffset = 0;
  let vIndOffset = 0;

  if (_opt.includeSource) {

    for (let i = 0; i < faceCnt * 3; ++i) {

      newIndex[i] = index[i];

    }

    fIndOffset += faceCnt;
    vIndOffset += vertexCnt;

  }

  if (_opt.includeTarget) {

    for (let i = 0; i < faceCnt; ++i) {

      newIndex[(i + fIndOffset) * 3] = index[i * 3 + 2] + vIndOffset;
      newIndex[(i + fIndOffset) * 3 + 1] = index[i * 3 + 1] + vIndOffset;
      newIndex[(i + fIndOffset) * 3 + 2] = index[i * 3] + vIndOffset;

    }

    fIndOffset += faceCnt;

  }

  if (_opt.includeSide) {

    let useSourceVertexMap = !_opt.includeSource;
    let useTargetVertexMap = !_opt.includeTarget;
    let sourceOffset = 0;
    let targetOffset = 0;

    if (_opt.includeSource) {
      targetOffset = vertexCnt;
    } else if (!_opt.includeSource && !_opt.includeTarget) {
      targetOffset = openEdgeCnt;
    } else {
      sourceOffset = vertexCnt;
    }

    for (let key in edges) {

      let edge = edges[key];

      if (edge.open) {

        let sv1 = edge.vert1;
        let sv2 = edge.vert2;
        let tv1 = edge.vert1;
        let tv2 = edge.vert2;

        if (useSourceVertexMap) {

          sv1 = vertexMap[sv1];
          sv2 = vertexMap[sv2];

        }

        if (useTargetVertexMap) {

          tv1 = vertexMap[tv1];
          tv2 = vertexMap[tv2];

        }

        newIndex[fIndOffset * 3] = sourceOffset + sv1;
        newIndex[fIndOffset * 3 + 1] = targetOffset + tv2;
        newIndex[fIndOffset * 3 + 2] = sourceOffset + sv2;

        ++fIndOffset;

        newIndex[fIndOffset * 3] = sourceOffset + sv1;
        newIndex[fIndOffset * 3 + 1] = targetOffset + tv1;
        newIndex[fIndOffset * 3 + 2] = targetOffset + tv2;

        ++fIndOffset;

      }

    }

  }

  newGeometry.face = newIndex;

  if (_opt.includeSource && _opt.includeTarget && _opt.includeSide) {

    reorientGeometry(newGeometry);

  }

  return newGeometry;

}