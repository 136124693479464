import {InvalidId} from "./const";
import {Mutex} from "async-mutex";

export enum ObjectTypes {
  None = '',
  Object = 'object',
  Calc = 'calc',
  Scene = 'scene',
  Parameter = 'parameter',
  Relation = 'relation',
  Number = 'number',
  String = 'string',
  Json = 'json',
  Boolean = 'boolean',
  Curve = 'curve',
  Brep = 'brep',
  Compound = 'compound',
  Point = 'point',
  Vertex = 'vertex',
  Mesh = 'mesh',
  Transform = 'transform',
  SculptMesh = 'sculpt-mesh',
  Geometry = 'geometry',
  SculptGeometry = 'sculpt-geometry',
  BrepGeometry = 'brep-geometry',
  CurveGeometry = 'curve-geometry',
}

export enum RenderedObjectTypes {
  Vertex = 'vertex',
  Line = 'line',
  Mesh = 'mesh',
  Brep = 'brep',
}

export class XObject {
  protected _id: string = InvalidId;
  protected mutex = new Mutex();
  protected mutexSessionId = '';

  get id() {
    return this._id;
  }

  protected _objectType: string = ObjectTypes.Object;

  get objectType() {
    return this._objectType;
  }

  get locked() {
    return this.mutex.isLocked();
  }

  static unset = new XObject();
  static UpdateEncodedData = true;

  get isInvalid() {
    return this._id === InvalidId;
  }
}

export const GeometryObjectTypes: string[] = [ObjectTypes.Vertex, ObjectTypes.Mesh, ObjectTypes.SculptMesh, ObjectTypes.Curve, ObjectTypes.Brep];
export const CachedGeometryObjectTypes: string[] = [ObjectTypes.Curve, ObjectTypes.Brep];
export const MeshGeometryObjectTypes: string[] = [ObjectTypes.Mesh, ObjectTypes.SculptMesh];