import Editor3d from "./Editor3d"

export default class Editor3dDelegate {
  readonly editor3d: Editor3d;

  readonly domElement: HTMLElement;
  protected canvasElement?: HTMLCanvasElement;

  readonly cubeDomElement?: HTMLElement;
  protected cubeCanvasElement?: HTMLCanvasElement;

  readonly minorDomElement?: HTMLElement;
  protected minorCanvasElement?: HTMLCanvasElement;

  readonly overlayCanvasElement?: HTMLCanvasElement;

  cubeSize = 60;

  constructor(editor3d: Editor3d, elemId: string, cubeElemId?: string, minorElemId?: string, overlay?: boolean) {
    this.editor3d = editor3d;
    this.domElement = document.getElementById(elemId) as HTMLElement;

    if (!this.domElement) {
      console.error('dom element not found');
      return;
    }

    if (cubeElemId) {
      this.cubeDomElement = document.getElementById(cubeElemId) as HTMLElement;
      this.cubeCanvasElement = this.createCanvas(this.cubeSize, this.cubeSize);
      this.cubeDomElement.appendChild(this.cubeCanvasElement);
    }

    if (minorElemId) {
      this.minorDomElement = document.getElementById(minorElemId) as HTMLElement;
      this.minorCanvasElement = this.createCanvas(this.minorDomElement.clientWidth, this.minorDomElement.clientHeight);
      this.minorDomElement.appendChild(this.minorCanvasElement);
    }

    if (overlay) {
      this.overlayCanvasElement = this.createCanvas(this.domElement.clientWidth, this.domElement.clientHeight);
      this.overlayCanvasElement.style.display = 'none';
      this.overlayCanvasElement.className = "overlay-canvas";
      this.domElement.appendChild(this.overlayCanvasElement);
    }

    this.canvasElement = this.createCanvas(this.domElement.clientWidth, this.domElement.clientHeight);
    this.domElement.appendChild(this.canvasElement);

    this.editor3d.setDelegate(this);
  }

  createCanvas(width: number, height: number) {
    let canvasElement = document.createElementNS( 'http://www.w3.org/1999/xhtml', 'canvas' ) as HTMLCanvasElement;
    canvasElement.style.display = 'block';
    this.resizeCanvas(canvasElement, width, height);
    return canvasElement;
  }

  resizeCanvas(canvasElement: HTMLCanvasElement, width: number, height: number) {
    let pixelRatio = this.editor3d.pixelRatio;
    canvasElement.style.width = `${width}px`;
    canvasElement.style.height = `${height}px`;
    canvasElement.width = Math.floor(width * pixelRatio);
    canvasElement.height = Math.floor(height * pixelRatio);
  }

  dispose() {
    if (this.editor3d.delegate === this)
      this.editor3d.unsetDelegate();
  }

  onContainerResize() {
    if (this.canvasElement)
      this.resizeCanvas(this.canvasElement, this.domElement.clientWidth, this.domElement.clientHeight);

    this.editor3d.onContainerResize(this.domElement.clientWidth, this.domElement.clientHeight);
  }

  onMinorContainerResize() {
    if (this.minorCanvasElement && this.minorDomElement) {
      this.resizeCanvas(this.minorCanvasElement, this.minorDomElement.clientWidth, this.minorDomElement.clientHeight);
      this.editor3d.onMinorContainerResize(this.minorDomElement.clientWidth, this.minorDomElement.clientHeight);
    }
  }

  drawOnCenter(ctx: CanvasRenderingContext2D, dest: HTMLCanvasElement, src: HTMLCanvasElement) {
    if (!src.clientWidth || !src.clientHeight)
      return;

    let offsetX = (dest.clientWidth - src.clientWidth) / 2;
    let offsetY = (dest.clientHeight - src.clientHeight) / 2;

    ctx.drawImage(src, offsetX, offsetY);
  }

  clear(ctx: CanvasRenderingContext2D, dest: HTMLCanvasElement) {
    let pixelRatio = this.editor3d.pixelRatio;

    ctx.clearRect(0, 0, Math.floor(dest.clientWidth * pixelRatio), Math.floor(dest.clientHeight * pixelRatio));
  }

  render() {
    if (!this.canvasElement)
      return;

    let ctx2D = this.canvasElement.getContext('2d');

    if (ctx2D) {
      this.clear(ctx2D, this.canvasElement);
      if (this.editor3d.envMapEnabled)
        this.drawOnCenter(ctx2D, this.canvasElement, this.editor3d.envMapRenderer.domElement);
      this.drawOnCenter(ctx2D, this.canvasElement, this.editor3d.renderer.domElement);
      if (this.editor3d.controlSceneHasContent)
        this.drawOnCenter(ctx2D, this.canvasElement, this.editor3d.controlRenderer.domElement);
    }

    if (!this.cubeCanvasElement || !this.editor3d.cubeRenderer)
      return;

    let cubeCtx2D = this.cubeCanvasElement.getContext('2d');

    if (cubeCtx2D) {
      this.clear(cubeCtx2D, this.cubeCanvasElement);
      this.drawOnCenter(cubeCtx2D, this.cubeCanvasElement, this.editor3d.cubeRenderer.domElement);
    }
  }

  renderMinor() {
    if (!this.minorCanvasElement || !this.editor3d.minorRenderer)
      return;

    let minorCtx2D = this.minorCanvasElement.getContext('2d');

    if (minorCtx2D) {
      this.clear(minorCtx2D, this.minorCanvasElement);
      this.drawOnCenter(minorCtx2D, this.minorCanvasElement, this.editor3d.minorRenderer.domElement);
    }
  }
}