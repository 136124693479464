import {store} from "./index";
import {fetchLibraryItems} from "./app/action/library";
import {initComponents} from "./peregrine/processor/component";
import {initPigeonOnWorker} from "./peregrine/processor/workers/pigen-index";

let engineLoading = false;

export function loadPeregrine(callback: (success: boolean) => void) {
  const __loadPeregrine = async () => {
    let dispatch = store.dispatch;
    Promise.all([
      // initComponents(),
      //@ts-ignore
      // dispatch(fetchMaterials()),
      //@ts-ignore
      // dispatch(fetchLibraryItems())
    ]).then(res => {
      console.log('peregrine engine was successfully loaded.');
      callback(true/*!!engine && sketcher*/);
    }).finally(() => {
      engineLoading = false;
    });
  };

  if (!engineLoading) {
    engineLoading = true;
    __loadPeregrine();
  }
}