import {DigitalMaterialEditingMode, IDigitalMaterial} from "../../types";

export const CHANGE_DIGITAL_MATERIAL_WIZARD_MATERIAL = 'CHANGE_DIGITAL_MATERIAL_WIZARD_MATERIAL';

export interface IDigitalMaterialWizardState {
  mode: DigitalMaterialEditingMode
  orgMaterial: IDigitalMaterial
  calcIds: string[]
  material: IDigitalMaterial
}

export interface ChangeDigitalMaterialWizardMaterialAction {
  type: typeof CHANGE_DIGITAL_MATERIAL_WIZARD_MATERIAL
  payload: {
    material: IDigitalMaterial
  }
}

