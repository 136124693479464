import lod from 'lodash';
import {EnvMapTypes, IEnvironment} from "../../peregrine/processor";

export function isValidEmail(email: string) {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isValidHexColorValue(value: string) {
  let re = /^#[A-Fa-f0-9]{6}$/;
  return re.test(String(value).toLowerCase());
}

export function isValidUsername(username: string) {
  let left = username.replace(/[0-9]|[a-z]|[A-Z]/g, '');
  return left.length === 0;
}

export function isValidPhone(phone: string) {
  let re = /^[2-9]\d{2}-\d{3}-\d{4}$/;
  return re.test(String(phone).toLowerCase());
}

export function isValidEnvMap(envMap: IEnvironment) {
  if (envMap.type === EnvMapTypes.Image && !envMap.image)
    return false;
  if (envMap.type === EnvMapTypes.Lighting && !envMap.image)
    return false;
  return true;
}

export function validatePhoneNumber(phone: string) {
  phone = phone.replace(/\D/g, '');

  if (phone.length > 6)
    phone = phone.substr(0, 6) + '-' + phone.substr(6);

  if (phone.length > 3)
    phone = phone.substr(0, 3) + '-' + phone.substr(3);

  phone = phone.substr(0, 12);

  return phone;
}

export function validateChannelTitle(name: string) {
  return '#' + name.replace('#', '').replace(/\s+/, '_').substr(0, 64);
}

export function validatePositiveInteger(num: string) {
  num = num.replace(/\D/g, '');
  return num;
}

export function isValidZip(zip: string) {
  let re = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return re.test(String(zip).toLowerCase());
}

export function validateZipCode(zip: string) {
  zip = zip.replace(/\D/g, '');

  zip = zip.substr(0, 5);

  return zip;
}

export function isValidJson(content: string) {
  try {
    let parsed = JSON.parse(content);
  } catch (e) {
    return false;
  }
  return true;
}

export function getFileNameExtension(name: string) {
  const extension = /(?:\.([^.]+))?$/.exec(name)![1];
  if (extension !== undefined) {
    name = name.substr(0, name.length - extension.length - 1);
  }
  return {name, extension};
}

export function getRandomColor() {
  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function getEstimatedBinarySTLSize(faceCount: number, vertexCount: number) {
  return faceCount * 2 + faceCount * 3 * 4 * 4 + 80 + 4;
}

export function getFuntionableProperty(obj: any, field: string, arg: any) {
  return obj ? (lod.isFunction(obj[field]) ? obj[field](arg) : obj[field]) : undefined;
}

export function removeEmptyObjects(obj: any): any {
  return lod(obj)
    .pickBy(lod.isObject)
    .mapValues(removeEmptyObjects)
    .omitBy(lod.isEmpty)
    .assign(lod.omitBy(obj, lod.isObject))
    .value();
}