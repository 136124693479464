import {IWomp20Water, NormalizedObjects} from "../types";
import {InitStoreAction} from "../init";

export const REPLACE_WAITERS = 'REPLACE_WAITERS';
export const REMOVE_WAITER = 'REMOVE_WAITER';
export const ADD_WAITERS = 'ADD_WAITERS';

export interface IEntityWaitersState extends NormalizedObjects<IWomp20Water> {
}

interface ReplaceWaitersAction {
  type: typeof REPLACE_WAITERS
  payload: {
    waiters: IWomp20Water[]
  }
}

interface AddWaitersAction {
  type: typeof ADD_WAITERS
  payload: {
    waiters: IWomp20Water[]
  }
}

interface RemoveWaiterAction {
  type: typeof REMOVE_WAITER
  payload: {
    id: number
  }
}

export type WaitersActionTypes = ReplaceWaitersAction |
  RemoveWaiterAction |
  AddWaitersAction |
  InitStoreAction