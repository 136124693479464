import SculptTools from './tools/SculptTools';
import Picking from '../math3d/Picking';
import Enums from '../misc/Enums';
import alphaSquare from "../../assets/images/square.jpg";
import alphaSkin from "../../assets/images/skin.jpg";

class SculptManager {

  constructor(main) {
    this._main = main;

    this._toolIndex = Enums.SculptTools.DRAG; // sculpting mode
    this._tools = []; // the sculpting tools

    // symmetry stuffs
    this._symmetry = true; // if symmetric sculpting is enabled  

    // continuous stuffs
    this._continuous = false; // continuous sculpting
    this._sculptTimer = -1; // continuous interval timer

    this.init();
  }

  setToolIndex(id) {
    this._toolIndex = id;
  }

  getToolIndex() {
    return this._toolIndex;
  }

  getCurrentTool() {
    return this._tools[this._toolIndex];
  }

  getSymmetry() {
    return this._symmetry;
  }

  setSymmetry(symmetry) {
    this._symmetry = symmetry;
  }

  setContinuous(continuous) {
    this._continuous = continuous;
  }

  getTool(index) {
    return this._tools[index];
  }

  init() {
    var main = this._main;
    var tools = this._tools;
    for (var i = 0, nb = SculptTools.length; i < nb; ++i) {
      if (SculptTools[i]) tools[i] = new SculptTools[i](main);
    }

    this.initAlphaTextures();
  }

  canBeContinuous() {
    switch (this._toolIndex) {
    case Enums.SculptTools.TWIST:
    case Enums.SculptTools.MOVE:
    case Enums.SculptTools.DRAG:
    case Enums.SculptTools.LOCALSCALE:
      return false;
    default:
      return true;
    }
  }

  isUsingContinuous() {
    return this._continuous && this.canBeContinuous();
  }

  start() {
    var tool = this.getCurrentTool();
    var canEdit = tool.start();
    if (this._main.getPicking().getMesh() && this.isUsingContinuous())
      this._sculptTimer = window.setInterval(tool._cbContinuous, 16.6);
    return canEdit;
  }

  end() {
    this.getCurrentTool().end();
    if (this._sculptTimer !== -1) {
      clearInterval(this._sculptTimer);
      this._sculptTimer = -1;
    }
  }

  preUpdate() {
    this.getCurrentTool().preUpdate(this.canBeContinuous());
  }

  update() {
    if (this.isUsingContinuous())
      return;
    this.getCurrentTool().update();
  }

  postRender() {

  }

  addSculptToScene(scene) {
    return this.getCurrentTool().addSculptToScene(scene);
  }

  initAlphaTextures() {
    if (Object.keys(Picking.ALPHAS).length === 1) {
      var alphas = [alphaSquare, alphaSkin];
      var names = ['square', 'skin'];
      for (var i = 0, nbA = alphas.length; i < nbA; ++i) {
        var am = new Image();
        am.src = alphas[i];
        am.crossOrigin = 'Anonymous';
        am.onload = this.onLoadAlphaImage.bind(this, am, names[i]);
      }
    }
  }

  onLoadAlphaImage(img, name) {
    var can = document.createElement('canvas');
    can.width = img.width;
    can.height = img.height;

    var ctx = can.getContext('2d');
    ctx.drawImage(img, 0, 0);
    var u8rgba = ctx.getImageData(0, 0, img.width, img.height).data;
    var u8lum = u8rgba.subarray(0, u8rgba.length / 4);
    for (var i = 0, j = 0, n = u8lum.length; i < n; ++i, j += 4)
      u8lum[i] = Math.round((u8rgba[j] + u8rgba[j + 1] + u8rgba[j + 2]) / 3);

    Picking.addAlpha(u8lum, img.width, img.height, name);
  }
}

export default SculptManager;
