import {ComponentTypes} from "../types";
import {createSimpleGumball} from "../gumball";
import {Param_Compound, ParamTitles} from "../../parameter";
import {WompObjectRef} from "../../../WompObject";

export class IntersectModifier {
  static prevVoided = true;

  static create() {

    let calc = createSimpleGumball();
    calc.component = ComponentTypes.IntersectModifier;
    calc.title = 'intersect';

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let intersectParam = Param_Compound.create(calc, ParamTitles.Intersect, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(intersectParam);

    return calc;

  }

  static getDesc(infos: { [key: string]: { value: WompObjectRef, voided: boolean } }) {
    let descs = Object.keys(infos).sort();

    let desc = `intersect[${descs.map(d => (infos[d].voided ? '-' : '') + d).join(',')}]`;
    if (descs.filter(d => !infos[d].voided).length === 1)
      desc = `union[${descs.map(d => (infos[d].voided ? '-' : '') + d).join(',')}]`;
    return desc;
  }

  static getOperation() {
    return 'intersect';
  }
}
