import {
  ADD_PLACEHOLDER_BOXES,
  CHANGE_HIGHLIGHTED_CALC_IDS,
  CHANGE_MINOR_VIEW_ZOOM,
  CHANGE_PREVIEW_DIGITAL_MATERIAL,
  CHANGE_PREVIEW_ENVIRONMENT_INFO,
  CHANGE_SERVER_RENDER_FRAME_NO,
  CHANGE_TEMPORARY_OBJECT_WELDINGS,
  CHANGE_TEMPORARY_TOOL_CONFIG,
  REMOVE_PLACEHOLDER_BOXES,
  SceneActionTypes,
  UPDATE_PLACEHOLDER_BOX
} from "../../types/ui/scene";
import {IDigitalMaterial, IObjectWelding, IPlaceholderBox, ITemporaryToolConfig} from "../../types/types";
import {ISceneEnvironmentInfo} from "../../../peregrine/processor";
import {DeepPartial} from "ts-essentials";

export function changeMinorViewZoom(zoom: number): SceneActionTypes {
  return {
    type: CHANGE_MINOR_VIEW_ZOOM,
    payload: {
      zoom
    }
  };
}

export function addPlaceholderBoxes(boxes: {[key: string]: IPlaceholderBox}): SceneActionTypes {
  return {
    type: ADD_PLACEHOLDER_BOXES,
    payload: {
      boxes
    }
  };
}

export function updatePlaceholderBox(id: string, box: Partial<IPlaceholderBox>): SceneActionTypes {
  return {
    type: UPDATE_PLACEHOLDER_BOX,
    payload: {
      id,
      box
    }
  };
}

export function removePlaceholderBoxes(ids: string[]): SceneActionTypes {
  return {
    type: REMOVE_PLACEHOLDER_BOXES,
    payload: {
      ids
    }
  };
}

export function changeTemporaryObjectWeldings(weldings: {[key: string]: IObjectWelding}): SceneActionTypes {
  return {
    type: CHANGE_TEMPORARY_OBJECT_WELDINGS,
    payload: {
      weldings
    }
  };
}

export function changePreviewDigitalMaterial(calcIds: string[], material?: IDigitalMaterial): SceneActionTypes {
  return {
    type: CHANGE_PREVIEW_DIGITAL_MATERIAL,
    payload: {
      calcIds,
      material
    }
  };
}

export function changePreviewEnvironmentInfo(info?: Partial<ISceneEnvironmentInfo>): SceneActionTypes {
  return {
    type: CHANGE_PREVIEW_ENVIRONMENT_INFO,
    payload: {
      info
    }
  };
}

export function changeTemporaryToolConfig(config: DeepPartial<ITemporaryToolConfig>): SceneActionTypes {
  return {
    type: CHANGE_TEMPORARY_TOOL_CONFIG,
    payload: {
      config
    }
  };
}

export function changeHighlightedCalcIds(calcIds: string[]): SceneActionTypes {
  return {
    type: CHANGE_HIGHLIGHTED_CALC_IDS,
    payload: {
      calcIds
    }
  }
}

export function changeServerRenderFrameNo(frameNo: number): SceneActionTypes {
  return {
    type: CHANGE_SERVER_RENDER_FRAME_NO,
    payload: {
      frameNo
    }
  }
}