import {INIT_STORE} from "../../types/init";
import {
  ADD_CHANNEL_ROUTES,
  ADD_CHANNELS,
  ADD_PROGRESS,
  CHANGE_PROGRESS_RANGE,
  CHANGE_SHOW_CURSOR,
  CHANGE_SYNC_CAMERA,
  CHANGE_SYNC_STATUS,
  CLEAR_PROGRESS,
  IProjectState,
  ProjectActionTypes,
  REMOVE_CHANNEL_ROUTES,
  REMOVE_PROGRESS,
  UPDATE_COMMAND_MANAGER
} from "../../types/logic/project";
import {defaultProjectDynamic} from "../../types/types";
import lod from "lodash";
import {INIT_SCENE} from "../../types/ui/global";

const initialState: IProjectState = {
  dynamic: defaultProjectDynamic,
  progressIds: [],
  progressFrom: 0,
  progressTo: 100,
};

export function projectReducer(
  state = initialState,
  action: ProjectActionTypes
): IProjectState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case INIT_SCENE:
      return {
        ...initialState,
        dynamic: {
          ...initialState.dynamic,
          channelRoutes: state.dynamic.channelRoutes
        }
      };
    case ADD_CHANNEL_ROUTES: {
      return {
        ...state,
        dynamic: {
          ...state.dynamic,
          channelRoutes: {
            ...state.dynamic.channelRoutes,
            [action.payload.id]: {
              ...state.dynamic.channelRoutes[action.payload.id],
              ...action.payload.channelRoutes
            }
          }
        }
      };
    }
    case ADD_CHANNELS: {
      let newIds = Array.from(new Set([...Object.keys(state.dynamic.channelRoutes), ...Object.keys(action.payload.channels)]));
      return {
        ...state,
        dynamic: {
          ...state.dynamic,
          channelRoutes: lod.mapValues(lod.keyBy(newIds), id => {
            return {
              ...state.dynamic.channelRoutes[+id],
              ...action.payload.channels[+id]
            };
          })
        }
      };
    }
    case REMOVE_CHANNEL_ROUTES: {
      return {
        ...state,
        dynamic: {
          ...state.dynamic,
          channelRoutes: {
            ...state.dynamic.channelRoutes,
            [action.payload.id]: lod.omit(state.dynamic.channelRoutes[action.payload.id], action.payload.routeIds)
          }
        }
      };
    }
    case CHANGE_SYNC_CAMERA: {
      let newChannelRoutes = lod.mapValues(state.dynamic.channelRoutes[action.payload.id], route => {
        if (route.username === action.payload.username)
          return {...route, syncCamera: action.payload.syncCamera};
        else if (action.payload.syncCamera)
          return {...route, syncCamera: false};
        else
          return route;
      });

      return {
        ...state,
        dynamic: {
          ...state.dynamic,
          channelRoutes: {
            ...state.dynamic.channelRoutes,
            [action.payload.id]: newChannelRoutes
          }
        }
      };
    }
    case CHANGE_SHOW_CURSOR: {
      let newChannelRoutes = lod.mapValues(state.dynamic.channelRoutes[action.payload.id], route => {
        if (route.username === action.payload.username)
          return {...route, showCursor: action.payload.showCursor};
        else
          return route;
      });

      return {
        ...state,
        dynamic: {
          ...state.dynamic,
          channelRoutes: {
            ...state.dynamic.channelRoutes,
            [action.payload.id]: newChannelRoutes
          }
        }
      };
    }
    case CHANGE_SYNC_STATUS:
      return {
        ...state,
        dynamic: {
          ...state.dynamic,
          routeStartCursor: action.payload.routeStartCursor,
          serverCommands: action.payload.serverCommands,
          serverCursor: action.payload.serverCursor
        }
      };
    case CHANGE_PROGRESS_RANGE:
      return {
        ...state,
        ...lod.pickBy(action.payload, v => v !== undefined)
      };
    case ADD_PROGRESS:
      return {
        ...state,
        progressIds: state.progressIds.includes(action.payload.progressId) ? state.progressIds : [...state.progressIds, action.payload.progressId]
      };
    case REMOVE_PROGRESS:
      return {
        ...state,
        progressIds: state.progressIds.filter(p => p !== action.payload.progressId)
      };
    case CLEAR_PROGRESS:
      return {
        ...state,
        progressIds: []
      };
    case UPDATE_COMMAND_MANAGER:
      return {
        ...state,
        dynamic: {
          ...state.dynamic,
          ...action.payload.newState
        }
      };
    default:
      return state;
  }
}