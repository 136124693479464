import {
  ADD_DIGITAL_MATERIALS,
  DigitalMaterialsActionTypes,
  REMOVE_DIGITAL_MATERIAL,
  REPLACE_DIGITAL_MATERIALS
} from '../../types/entity/digital-materials';
import {IDigitalMaterial} from '../../types/types';

export function replaceDigitalMaterials(materials: IDigitalMaterial[]): DigitalMaterialsActionTypes {
  return {
    type: REPLACE_DIGITAL_MATERIALS,
    payload: {
      materials
    }
  };
}

export function addDigitalMaterials(materials: IDigitalMaterial[]): DigitalMaterialsActionTypes {
  return {
    type: ADD_DIGITAL_MATERIALS,
    payload: {
      materials
    }
  };
}

export function removeDigitalMaterial(id: string): DigitalMaterialsActionTypes {
  return {
    type: REMOVE_DIGITAL_MATERIAL,
    payload: {
      id
    }
  };
}
