import {
  ADD_ENVIRONMENTS,
  EnvironmentsActionTypes,
  REMOVE_ENVIRONMENT,
  REPLACE_ENVIRONMENTS
} from '../../types/entity/environments';
import {IEnvironment} from "../../../peregrine/processor";

export function replaceEnvironments(environments: IEnvironment[]): EnvironmentsActionTypes {
  return {
    type: REPLACE_ENVIRONMENTS,
    payload: {
      environments
    }
  };
}

export function addEnvironments(environments: IEnvironment[]): EnvironmentsActionTypes {
  return {
    type: ADD_ENVIRONMENTS,
    payload: {
      environments
    }
  };
}

export function removeEnvironment(id: string): EnvironmentsActionTypes {
  return {
    type: REMOVE_ENVIRONMENT,
    payload: {
      id
    }
  };
}
