import {
  IEntityLibraryTopCategoriesState,
  LibraryTopCategoriesActionTypes,
  REPLACE_LIBRARY_TOP_CATEGORIES
} from '../../types/entity/library-top-categories';
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';

const initialState: IEntityLibraryTopCategoriesState = {
  byId: {},
  allIds: []
};

export function entityLibraryTopCategoriesReducer(
  state = initialState,
  action: LibraryTopCategoriesActionTypes
): IEntityLibraryTopCategoriesState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case REPLACE_LIBRARY_TOP_CATEGORIES: {
      return {
        byId: lod.keyBy(action.payload.libraryTopCategories, 'id'),
        allIds: lod.map(action.payload.libraryTopCategories, 'id')
      };
    }
    default:
      return state;
  }
}