import {
  ADD_NOTIFICATIONS,
  IEntityNotificationsState,
  NotificationsActionTypes,
  REMOVE_NOTIFICATION,
  REPLACE_NOTIFICATIONS
} from '../../types/entity/notifications';
import {INotification} from '../../types/types';
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';

const initialState: IEntityNotificationsState = {
  byId: {},
  allIds: []
};

export function entityNotificationsReducer(
  state = initialState,
  action: NotificationsActionTypes
): IEntityNotificationsState {
  const getSortedAllIds = (byId: { [key: number]: INotification }) => {
    return Object.keys(byId).map(Number).sort((a, b) => {
      if (!byId[a].createdDate || !byId[b].createdDate)
        return 0;

      if (byId[a].createdDate.getTime() < byId[b].createdDate.getTime())
        return 1;
      else if (byId[a].createdDate.getTime() > byId[b].createdDate.getTime())
        return -1;
      else
        return 0;
    });
  };

  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case REPLACE_NOTIFICATIONS: {
      let byId = lod.keyBy(action.payload.notifications, 'id');
      return {
        byId: byId,
        allIds: getSortedAllIds(byId)
      };
    }
    case REMOVE_NOTIFICATION: {
      let byId = lod.omit(state.byId, action.payload.id);
      return {
        byId: byId,
        allIds: getSortedAllIds(byId)
      };
    }
    case ADD_NOTIFICATIONS: {
      let byId = {
        ...state.byId,
        ...lod.keyBy(action.payload.notifications, 'id')
      };

      return {
        byId: byId,
        allIds: getSortedAllIds(byId)
      };
    }
    default:
      return state;
  }
}