import {
  IDeleteItemAsync, IFetchAsync,
  IFetchItemsAsync,
  IPostItemAsync
} from "../types";
import {InitStoreAction} from "../init";

export const FETCH_CHATS = 'FETCH_CHATS';
export const DELETE_CHAT = 'DELETE_CHAT';
export const POST_CHAT = 'POST_CHAT';
export const FETCH_UNREAD_CHATS = 'FETCH_UNREAD_CHATS';

export interface IApiChatState {
  getChats: IFetchItemsAsync<number>
  deleteChat: IDeleteItemAsync<number>
  postChat: IPostItemAsync<number>
  getUnreadChats: IFetchAsync
}

interface FetchChatsAction {
  type: typeof FETCH_CHATS
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    items?: number[]
  }
}

interface DeleteChatAction {
  type: typeof DELETE_CHAT
  payload: {
    deleting: boolean
    success?: boolean
    message?: string
    id: number
  }
}

interface PostChatAction {
  type: typeof POST_CHAT
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id?: number
  }
}

interface FetchUnreadChatsAction {
  type: typeof FETCH_UNREAD_CHATS
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
  }
}

export type ApiChatActionTypes = FetchChatsAction |
  DeleteChatAction |
  PostChatAction |
  FetchUnreadChatsAction |
  InitStoreAction;