import {ILibraryCategory, NormalizedObjects} from "../types";
import {InitStoreAction} from "../init";

export const REPLACE_LIBRARY_CATEGORIES = 'REPLACE_LIBRARY_CATEGORIES';
export const ADD_LIBRARY_CATEGORIES = 'ADD_LIBRARY_CATEGORIES';
export const REMOVE_LIBRARY_CATEGORY = 'REMOVE_LIBRARY_CATEGORY';

export interface IEntityLibraryCategoriesState extends NormalizedObjects<ILibraryCategory> {
}

interface ReplaceLibraryCategoriesAction {
  type: typeof REPLACE_LIBRARY_CATEGORIES
  payload: {
    categories: ILibraryCategory[]
  }
}

interface AddLibraryCategoriesAction {
  type: typeof ADD_LIBRARY_CATEGORIES
  payload: {
    categories: ILibraryCategory[]
  }
}

interface RemoveLibraryCategoryAction {
  type: typeof REMOVE_LIBRARY_CATEGORY
  payload: {
    id: number
  }
}

export type LibraryCategoriesActionTypes = ReplaceLibraryCategoriesAction |
  AddLibraryCategoriesAction |
  RemoveLibraryCategoryAction |
  InitStoreAction