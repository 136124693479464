import {INIT_STORE} from "../../types/init";
import {
  ApiLibraryActionTypes, ADD_TO_MY_LIBRARY,
  FETCH_LIBRARY_ITEMS,
  IApiLibraryState,
  POST_DIGITAL_MATERIAL, POST_ENV_MAP, DELETE_LIBRARY_ITEM
} from "../../types/api/library";

const initialState: IApiLibraryState = {
  fetchLibraryItems: {
    fetching: false
  },
  postDigitalMaterial: {
    posting: false
  },
  postEnvMap: {
    posting: false
  },
  addToMyLibrary: {
    posting: false
  },
  deleteLibraryItem: {
    deleting: false
  }
};

export function apiLibraryReducer(
  state = initialState,
  action: ApiLibraryActionTypes
): IApiLibraryState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case FETCH_LIBRARY_ITEMS:
      return {
        ...state,
        fetchLibraryItems: {
          fetching: action.payload.fetching,
          message: action.payload.message,
          items: action.payload.items
        }
      };
    case POST_DIGITAL_MATERIAL:
      return {
        ...state,
        postDigitalMaterial: {
          posting: action.payload.posting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    case POST_ENV_MAP:
      return {
        ...state,
        postEnvMap: {
          posting: action.payload.posting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    case ADD_TO_MY_LIBRARY:
      return {
        ...state,
        addToMyLibrary: {
          posting: action.payload.posting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    case DELETE_LIBRARY_ITEM:
      return {
        ...state,
        deleteLibraryItem: {
          deleting: action.payload.deleting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    default:
      return state;
  }
}