import {ComponentTypes} from "../types";
import {pigeon as pigeonTypes} from "../../../types";
import {createSimpleGumball, IRenderedObjectInternal} from "../gumball";
import iconDirectionUp from '../../../assets/images/direction-up.svg';
import iconDirectionDown from '../../../assets/images/direction-down.svg';
import iconDirectionBoth from '../../../assets/images/direction-both.svg';
import {offsetGeometry} from "../../../operation";
import {
  BooleanInterfaceTypes,
  createObjectFromBrepData,
  createObjectFromMeshData,
  getCurveFromRef,
  getMeshFromRef,
  IndexInterfaceTypes,
  Param_Boolean,
  Param_Compound,
  Param_Index,
  Param_Number,
  ParamTitles,
  ParamValueTypes
} from "../../parameter";
import {Calc} from "../../calc";
import {GeometryObjectTypes, ObjectTypes} from "../../xobject";
import {getComposeDesc, getRenderedGeometry, TransformComponent, WompObjectRef} from "../../../WompObject";
import {mat4, vec3} from "gl-matrix";
import {BasicObjectMapper, ObjectFuncParameter, PredictedResult} from "../base";
import {composeTransform, decomposeTransform} from "../../../utils";
import {_n} from "../../../t";

// TODO: BYZ - this is not perfect. rewrite this.

export class ExtrudeModifier extends BasicObjectMapper {
  static create() {
    let calc = createSimpleGumball();
    calc.component = ComponentTypes.ExtrudeModifier;
    calc.title = 'extrude';

    let list: { [id: string]: string } = {"up": iconDirectionUp, "down": iconDirectionDown, "both": iconDirectionBoth};

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let directionParam = Param_Index.create(calc, ParamTitles.Direction, list, true, true, {
      defaultValue: 'up',
      interfaceType: IndexInterfaceTypes.Image
    });
    let distanceParam = Param_Number.create(calc, ParamTitles.Distance, true, false, {type: ParamValueTypes.Length});
    let thicknessParam = Param_Number.create(calc, ParamTitles.Thickness, true, false, {type: ParamValueTypes.Offset});
    let capParam = Param_Boolean.create(calc, ParamTitles.Cap, true, true, {
      defaultValue: false,
      interfaceType: BooleanInterfaceTypes.Toggle
    });
    let extrudeParam = Param_Compound.create(calc, ParamTitles.Extrude, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(directionParam);
    calc.addParameter(distanceParam);
    calc.addParameter(thicknessParam);
    calc.addParameter(capParam);
    calc.addParameter(extrudeParam);

    return calc;
  }

  static getParameters(calc: Calc, obj: IRenderedObjectInternal) {
    let distanceParam = calc.inputByTitle(ParamTitles.Distance) as Param_Number;
    let capParam = calc.inputByTitle(ParamTitles.Cap) as Param_Boolean;
    let directionParam = calc.inputByTitle(ParamTitles.Direction) as Param_Index;
    let thicknessParam = calc.inputByTitle(ParamTitles.Thickness) as Param_Number;
    let distance = distanceParam.values[0];
    let cap = capParam.values[0];
    let direction = directionParam.values[0];
    let thickness = thicknessParam.values[0];

    let objDesc = getComposeDesc(obj.value, 3, [TransformComponent.Translation, TransformComponent.Uniform, TransformComponent.Scale, TransformComponent.Rotation, TransformComponent.Quaternion, TransformComponent.Perspective]);
    let matrixComponents = decomposeTransform(obj.value.matrix);
    let appliedMatrix = composeTransform(vec3.create(), vec3.create(), matrixComponents.unitScale, matrixComponents.skew);

    let matrix = mat4.multiply(mat4.create(), obj.value.matrix, mat4.invert(mat4.create(), appliedMatrix));
    let parameters = {
      distance: distance * matrixComponents.uniform,
      appliedMatrix,
      thickness: thickness * matrixComponents.uniform,
      direction,
      cap
    };
    let desc = `extrude[${[objDesc, parameters.direction, _n(parameters.distance, 1), _n(parameters.thickness, 1), parameters.cap].filter(p => p !== undefined).join(',')}]`;

    return {desc, matrix, ...parameters};
  }

  static predictResult(calc: Calc, obj: IRenderedObjectInternal) {
    if (GeometryObjectTypes.includes(obj.valueType)) {
      if (obj.valueType === ObjectTypes.Curve) {
        return PredictedResult.Brep;
      } else if (obj.valueType === ObjectTypes.Vertex) {
        return PredictedResult.Ignore;
      } else {
        return PredictedResult.Mesh;
      }
    }

    return PredictedResult.Ignore;
  }

  static async mapObject(calc: Calc, obj: IRenderedObjectInternal, parameters: ObjectFuncParameter) {
    if (obj.valueType === ObjectTypes.Curve) {
      let fromCurve = new WompObjectRef(obj.value.objectId, mat4.fromTranslation(mat4.create(), vec3.fromValues(0, 0, parameters.offset)));
      let toCurve = new WompObjectRef(obj.value.objectId, mat4.fromTranslation(mat4.create(), vec3.fromValues(0, 0, parameters.offset + length)));

      let brepData = await pigeonTypes.loft([getCurveFromRef(calc, fromCurve), getCurveFromRef(calc, toCurve)], parameters.cap, true);
      return createObjectFromBrepData(calc, brepData, parameters.desc, parameters.matrix);
    } else {
      let objectRef = obj.value;
      if (obj.valueType === ObjectTypes.Brep)
        objectRef = obj.cache[0];
      let mesh = getMeshFromRef(calc, objectRef);

      if (mesh) {
        mat4.translate(mesh.matrix, mesh.matrix, vec3.fromValues(0, 0, parameters.offset));

        let offset = offsetGeometry(
          getRenderedGeometry(mesh),
          length,
          {
            includeSource: parameters.direction === "down" ? parameters.cap : true,
            includeTarget: parameters.direction === "down" ? true : parameters.cap,
            includeSide: true,
            direction: vec3.fromValues(0, 0, 1)
          });

        return createObjectFromMeshData(calc, offset, parameters.desc, parameters.matrix);
      }
    }

    return obj;
  }
}
