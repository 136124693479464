import {IProcessor, NormalizedStringIndexedObjects} from "../types";
import {InitStoreAction} from "../init";

export const ADD_PROCESSORS = 'ADD_PROCESSORS';
export const REMOVE_PROCESSORS = 'REMOVE_PROCESSORS';
export const REPLACE_PROCESSORS = 'REPLACE_PROCESSORS';

export interface IEntityProcessorsState extends NormalizedStringIndexedObjects<IProcessor> {
}

interface ReplaceProcessorsAction {
  type: typeof REPLACE_PROCESSORS
  payload: {
    processors: IProcessor[]
  }
}

interface AddProcessorsAction {
  type: typeof ADD_PROCESSORS
  payload: {
    processors: IProcessor[]
  }
}

interface RemoveProcessorsAction {
  type: typeof REMOVE_PROCESSORS
  payload: {
    ids: string[]
  }
}

export type ProcessorsActionTypes = AddProcessorsAction |
  RemoveProcessorsAction |
  ReplaceProcessorsAction |
  InitStoreAction