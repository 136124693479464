import {
  ADD_LIGHTS,
  IEntityLightsState,
  LightsActionTypes,
  REMOVE_LIGHT,
  REPLACE_LIGHTS
} from "../../types/entity/lights";
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';

const initialState: IEntityLightsState = {
  byId: {},
  allIds: []
};

export function entityLightsReducer(
  state = initialState,
  action: LightsActionTypes
): IEntityLightsState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case REPLACE_LIGHTS: {
      return {
        byId: lod.keyBy(action.payload.lights, 'id'),
        allIds: lod.map(action.payload.lights, 'id')
      };
    }
    case ADD_LIGHTS: {
      return {
        byId: {
          ...state.byId,
          ...lod.keyBy(action.payload.lights, 'id')
        },
        allIds: [
          ...state.allIds,
          ...lod.map(action.payload.lights, 'id').filter(id => !state.byId[id])
        ]
      };
    }
    case REMOVE_LIGHT: {
      return {
        byId: lod.omit(state.byId, action.payload.id),
        allIds: state.allIds.filter(id => id !== action.payload.id)
      };
    }
    default:
      return state;
  }
}