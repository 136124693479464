import {
  ADD_SCREENSHOTS,
  IEntityScreenshotsState,
  REMOVE_SCREENSHOTS, REPLACE_SCREENSHOTS,
  ScreenshotsActionTypes
} from '../../types/entity/screenshots';
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';

const initialState: IEntityScreenshotsState = {
  byId: {},
  allIds: []
};

export function entityScreenshotsReducer(
  state = initialState,
  action: ScreenshotsActionTypes
): IEntityScreenshotsState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case REPLACE_SCREENSHOTS: {
      return {
        byId: lod.keyBy(action.payload.screenshots, 'id'),
        allIds: lod.map(action.payload.screenshots, 'id')
      };
    }
    case ADD_SCREENSHOTS: {
      return {
        byId: {
          ...state.byId,
          ...lod.keyBy(action.payload.screenshots, 'id')
        },
        allIds: [
          ...state.allIds,
          ...lod.map(action.payload.screenshots, 'id').filter(id => !state.byId[id])
        ]
      };
    }
    case REMOVE_SCREENSHOTS: {
      return {
        byId: lod.omit(state.byId, action.payload.ids),
        allIds: state.allIds.filter(id => !action.payload.ids.includes(id))
      };
    }
    default:
      return state;
  }
}