import {IOrder, NormalizedObjects} from "../types";
import {InitStoreAction} from "../init";

export const REPLACE_ORDERS = 'REPLACE_ORDERS';
export const ADD_ORDERS = 'ADD_ORDERS';
export const REMOVE_ORDER = 'REMOVE_ORDER';

export interface IEntityOrdersState extends NormalizedObjects<IOrder> {
}

interface ReplaceOrdersAction {
  type: typeof REPLACE_ORDERS
  payload: {
    orders: IOrder[]
  }
}

interface AddOrdersAction {
  type: typeof ADD_ORDERS
  payload: {
    orders: IOrder[]
  }
}

interface RemoveOrderAction {
  type: typeof REMOVE_ORDER
  payload: {
    id: number
  }
}

export type OrdersActionTypes = ReplaceOrdersAction |
  RemoveOrderAction |
  AddOrdersAction |
  InitStoreAction