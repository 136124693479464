import {IPostAsync} from "../types";
import {InitStoreAction} from "../init";

export const CHANGE_SIGN_UP_FIELD_VALUE = 'CHANGE_SIGN_UP_FIELD_VALUE';
export const CHANGE_SIGN_UP_ERROR_VALUE = 'CHANGE_SIGN_UP_ERROR_VALUE';

export interface ISignUpState {
  fields: { [name: string]: string }
  errors: { [name: string]: string }
}

interface ChangeSignUpFieldValueAction {
  type: typeof CHANGE_SIGN_UP_FIELD_VALUE
  payload: {
    field: string
    value: any
  }
}

interface ChangeSignUpErrorValueAction {
  type: typeof CHANGE_SIGN_UP_ERROR_VALUE
  payload: {
    field: string
    value: string
  }
}

export type SignUpActionTypes = ChangeSignUpFieldValueAction |
  ChangeSignUpErrorValueAction |
  InitStoreAction

