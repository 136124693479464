import {InitStoreAction} from "../init";

export const CHANGE_LIBRARY_STATE = 'CHANGE_LIBRARY_STATE';

export interface ILibraryState {
  selectedSection: string
  searchText: string
}

interface ChangeLibraryStateAction {
  type: typeof CHANGE_LIBRARY_STATE
  payload: {
    selectedSection?: string
    searchText?: string
  }
}

export type LibraryActionTypes = ChangeLibraryStateAction |
  InitStoreAction
