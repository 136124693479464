import {
  POST_ACTIVATE_ACCOUNT, POST_CHANGE_PASSWORD,
  POST_SEND_CONFIRM_EMAIL, POST_SEND_RESET_EMAIL, POST_SIGN_IN, POST_SIGN_UP
} from "../../types/api/auth";
import {INIT_STORE} from "../../types/init";
import {ApiAuthActionTypes, IApiAuthState} from "../../types/api/auth";

const initialState: IApiAuthState = {
  postSendConfirmEmail: {
    posting: false
  },
  postActivateAccount: {
    posting: false
  },
  postSendResetEmail: {
    posting: false
  },
  postChangePassword: {
    posting: false
  },
  postSignIn: {
    posting: false
  },
  postSignUp: {
    posting: false
  }
};

export function apiAuthReducer(
  state = initialState,
  action: ApiAuthActionTypes
): IApiAuthState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case POST_SEND_CONFIRM_EMAIL:
      return {
        ...state,
        postSendConfirmEmail: {
          posting: action.payload.posting,
          message: action.payload.message
        }
      };
    case POST_ACTIVATE_ACCOUNT:
      return {
        ...state,
        postActivateAccount: {
          posting: action.payload.posting,
          message: action.payload.message
        }
      };
    case POST_SEND_RESET_EMAIL:
      return {
        ...state,
        postSendResetEmail: {
          posting: action.payload.posting,
          message: action.payload.message
        }
      };
    case POST_CHANGE_PASSWORD:
      return {
        ...state,
        postChangePassword: {
          posting: action.payload.posting,
          message: action.payload.message
        }
      };
    case POST_SIGN_IN:
      return {
        ...state,
        postSignIn: {
          posting: action.payload.posting,
          message: action.payload.message
        }
      };
    case POST_SIGN_UP:
      return {
        ...state,
        postSignUp: {
          posting: action.payload.posting,
          message: action.payload.message
        }
      };
    default:
      return state;
  }
}