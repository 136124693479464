/**
 * @author arodic / https://github.com/arodic
 */

import {
  Box3,
  DoubleSide,
  EventDispatcher,
  Group,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneBufferGeometry,
  Quaternion,
  Raycaster,
  SphereBufferGeometry,
  Vector2,
  Vector3,
  BackSide,
  BoxBufferGeometry,
  BufferGeometry,
  Uint32BufferAttribute,
  Float32BufferAttribute,
  ShaderMaterial,
  Color,
  AdditiveBlending, CylinderBufferGeometry
} from 'three';
import {Line2} from 'three/examples/jsm/lines/Line2';
import {LineGeometry} from 'three/examples/jsm/lines/LineGeometry';
import {LineMaterial} from 'three/examples/jsm/lines/LineMaterial';
import lod from 'lodash';

const PI = Math.PI;

const DRAG_DROP_MODE = {
  BOX: 1,
  FLOOR: 2,
};

let MagnetControl = function (camera, scene, controlScene) {
  Object3D.call(this);

  this.domElement = document;
  let curTick = 0;
  let timer = setInterval(() => ++curTick, 1000);
  let lastEventTick;

  let Config = {
    ShowTime: 5
  };

  this.visible = false;
  this.camera = camera;
  this.scene = scene;
  this.controlScene = controlScene;
  this.eye = undefined;
  // this.intersectPlane = new Mesh(
  //   new PlaneBufferGeometry(1000, 1000),
  //   new MeshBasicMaterial({ visible: true, wireframe: false, side: DoubleSide, transparent: true, opacity: 0.1, color: 0x00ff00})
  // );
  this.intersectPlane = new Mesh(
    new PlaneBufferGeometry(300000, 300000),
    new MeshBasicMaterial({visible: false, wireframe: false, side: DoubleSide, transparent: true})
  );
  this.intersectPlane.name = 'magnet-control-intersect-plane';

  this.rayCaster = new Raycaster();
  this.rayCaster.layers.set(0);

  this.oldState = {};

  this.spriteSize = 1.2;
  this.isDragging = false;

  this.mouseOnCanvas = new Vector2();
  this.mouse = new Vector2();

  this.targets = {};
  this.mapping = {};
  this.boundingBox = new Box3();
  this.initialMatrix = new Matrix4();
  this.space = 'world';
  this.controlEnabled = true;
  this.elemRect = undefined;

  let scope = this;
  let unitX = new Vector3(1, 0, 0);
  let unitY = new Vector3(0, 1, 0);
  let unitZ = new Vector3(0, 0, 1);

  let objUnitX = new Vector3(1, 0, 0);
  let objUnitY = new Vector3(0, 1, 0);
  let objUnitZ = new Vector3(0, 0, 1);
  let objPosition = new Vector3();
  let objScale = new Vector3();
  let objQuaternion = new Quaternion();
  let boundingBoxSize = new Vector3();

  let targetObjPosition = new Vector3();
  let targetBoundingBoxSize = new Vector3();

  let hoverBox;
  let targetHoverBox;
  let targetHoverFloor;

  this.setSize = function (size) {
    this.spriteSize = size * 12.0;
  };

  this.refreshBoundingClientRect = function () {
    this.elemRect = this.domElement === document ? document.body.getBoundingClientRect() : this.domElement.getBoundingClientRect();
  };

  this.setDelegate = function (delegate) {

    this.unsetDelegate();

    delegate.domElement.addEventListener('mousedown', onPointerDown);
    delegate.domElement.addEventListener('touchstart', onTouchDown);

    delegate.domElement.addEventListener('mousemove', onPointerMove);
    delegate.domElement.addEventListener('touchmove', onPointerMove);

    delegate.domElement.addEventListener('mouseup', onPointerUp);
    delegate.domElement.addEventListener('touchend', onPointerUp);
    delegate.domElement.addEventListener('touchcancel', onPointerUp);
    delegate.domElement.addEventListener('touchleave', onPointerUp);

    this.domElement = delegate.domElement;

    this.refreshBoundingClientRect();
  }

  this.unsetDelegate = function () {

    if (this.domElement === document)
      return;

    this.domElement.removeEventListener('mousedown', onPointerDown);
    this.domElement.removeEventListener('touchstart', onTouchDown);

    this.domElement.removeEventListener('mousemove', onPointerMove);
    this.domElement.removeEventListener('touchmove', onPointerMove);

    this.domElement.removeEventListener('mouseup', onPointerUp);
    this.domElement.removeEventListener('touchend', onPointerUp);
    this.domElement.removeEventListener('touchcancel', onPointerUp);
    this.domElement.removeEventListener('touchleave', onPointerUp);

    this.domElement = document;

  }

  this.dispose = function () {
    this.detach();

    clearInterval(timer);

    scope = null;
  };

  this.attach = function (object) {
    this.object = object;
    this.visible = true;
    this.initialMatrix = object.matrix.clone();

    this.targetId = undefined;
    this.targetObject = undefined;

    hoverBox = undefined;
    targetHoverBox = undefined;
    targetHoverFloor = undefined;

    // this.refresh();
    // lastEventTick = -Config.ShowTime - 1;
  };

  this.boxGeometry = new BoxBufferGeometry(1, 1, 1);
  this.boxMaterial = new MeshBasicMaterial({transparent: true, side: DoubleSide, opacity: 0, visible: false});

  this.attachTargets = function (targets) {
    for (let id in this.targets) {
      this.controlScene.remove(this.targets[id].mesh);
    }

    this.targets = targets;
    for (let id in this.targets) {
      this.targets[id].mesh = new Mesh(this.boxGeometry, this.boxMaterial);
      this.targets[id].mesh.name = id;
      this.targets[id].mesh.matrixAutoUpdate = true;
      this.targets[id].box.getCenter(this.targets[id].mesh.position);
      this.targets[id].box.getSize(this.targets[id].mesh.scale);
      this.controlScene.add(this.targets[id].mesh);
    }
  };

  this.detach = function () {
    this.object = undefined;
    this.targets = {};

    for (let id in this.targets) {
      this.controlScene.remove(this.targets[id].mesh);
    }
    this.visible = false;
  };

  this.getRelativeTransform = function (transform) {
    return transform.copy(this.object.matrix).premultiply(this.initialMatrix.clone().invert());
  };

  this.getObjectBoundingBoxSize = function (size) {
    let relativeTransform = this.getRelativeTransform(new Matrix4());
    return this.boundingBox.clone().applyMatrix4(relativeTransform).getSize(size);
  };

  this.getObjectUnitAxes = function (x, y, z) {
    x.set(1, 0, 0);
    y.set(0, 1, 0);
    z.set(0, 0, 1);
    if (this.space !== 'world') {

      this.object.matrix.decompose(
        objPosition,
        objQuaternion,
        objScale
      );

      x.applyQuaternion(objQuaternion);
      y.applyQuaternion(objQuaternion);
      z.applyQuaternion(objQuaternion);
    }
  };

  this.getControlGroupMatrix = function () {
    this.object.matrix.decompose(
      objPosition,
      objQuaternion,
      objScale
    );

    return new Matrix4().makeTranslation(-objPosition.x, -objPosition.y, -objPosition.z)
      .premultiply(new Matrix4().makeRotationFromQuaternion(objQuaternion))
      .premultiply(new Matrix4().makeTranslation(objPosition.x, objPosition.y, objPosition.z));
  };

  this.refreshBoundingBoxLines = function (target) {

    let pos = target ? targetObjPosition : objPosition;
    let bBoxSize = target ? targetBoundingBoxSize : boundingBoxSize;
    let picker = target ? this.targetBoundingBoxLines : this.boundingBoxLines;
    let showControl = target ? this.isDragging : !this.isDragging;

    if (showControl) {

      picker.visible = true;
      picker.position.copy(pos);

      let positions = [
        [-.5, -.5, -.5, -.5, -.5, .5],
        [.5, -.5, -.5, .5, -.5, .5],
        [-.5, .5, -.5, -.5, .5, .5],
        [.5, .5, -.5, .5, .5, .5],
        [-.5, -.5, -.5, -.5, .5, -.5],
        [-.5, .5, -.5, .5, .5, -.5],
        [.5, .5, -.5, .5, -.5, -.5],
        [.5, -.5, -.5, -.5, -.5, -.5],
        [-.5, -.5, .5, -.5, .5, .5],
        [-.5, .5, .5, .5, .5, .5],
        [.5, .5, .5, .5, -.5, .5],
        [.5, -.5, .5, -.5, -.5, .5],
      ];

      for (let j = 0; j < 12; ++j) {

        for (let i = 0; i < positions[j].length; i += 3) {

          positions[j][i] *= bBoxSize.x;
          positions[j][i + 1] *= bBoxSize.y;
          positions[j][i + 2] *= bBoxSize.z;

        }

        picker.children[j].geometry.setPositions(positions[j]);
        picker.children[j].computeLineDistances();

        picker.children[j].visible = true;

      }

    }

  };

  this.refreshPathLine = function () {

    if (this.isDragging) {

      let position = [this.dragStartPosition.x, this.dragStartPosition.y, this.dragStartPosition.z, this.dragEndPosition.x, this.dragEndPosition.y, this.dragEndPosition.z];
      let targetValid = targetHoverFloor || targetHoverBox;

      this.pathLine.material = targetValid ? this.materials.path.valid : this.materials.path.invalid;
      this.pathLine.material.resolution.set(this.elemRect.width, this.elemRect.height);
      this.pathLine.geometry.setPositions(position);
      this.pathLine.computeLineDistances();

      this.pathLine.visible = true;

    }

  };

  this.refreshMappingPathLines = function () {

    if (this.visible) {
      for (let sourceId in this.mapping) {
        let targetId = this.mapping[sourceId];
        if (targetId) {
          if (!this.mappingPathLines[sourceId]) {
            this.mappingPathLines[sourceId] = this.createPathLine();
          }

          let position = [0, 0, 0, 0, 0, 0];
          if (this.targets[sourceId]) {
            let center = this.targets[sourceId].box.getCenter(new Vector3());
            position[0] = center.x;
            position[1] = center.y;
            position[2] = center.z;
          }

          if (this.targets[targetId]) {
            let center = this.targets[targetId].box.getCenter(new Vector3());
            position[3] = center.x;
            position[4] = center.y;
            position[5] = center.z;
          }

          this.mappingPathLines[sourceId].material = this.materials.path.valid;
          this.mappingPathLines[sourceId].material.resolution.set(this.elemRect.width, this.elemRect.height);
          this.mappingPathLines[sourceId].geometry.setPositions(position);
          this.mappingPathLines[sourceId].computeLineDistances();

          this.mappingPathLines[sourceId].visible = true;
        }
      }

      for (let sourceId in this.mappingPathLines) {
        if (!this.mapping[sourceId]) {
          this.controlScene.remove(this.mappingPathLines[sourceId]);
          delete this.mappingPathLines[sourceId];
        }
      }
    }
  };

  this.refresh = function () {

    if (!this.elemRect)
      scope.refreshBoundingClientRect();

    let newState = {};

    newState.enabled = this.enabled;
    this.boundingBoxLines.visible = false;
    this.targetBoundingBoxLines.visible = false;
    this.pathLine.visible = false;
    for (let sourceId in this.mappingPathLines) {
      this.mappingPathLines[sourceId].visible = false;
    }

    if (this.enabled && this.object !== undefined) {

      newState.space = this.space;
      newState.objectId = this.object.id;

      if (this.space === 'world') {

        this.controlGroup.matrix = new Matrix4();

      } else {

        this.controlGroup.matrix = this.getControlGroupMatrix();

      }

      newState.visible = this.visible;

      if (this.visible) {

        boundingBoxSize = new Vector3();
        this.getObjectBoundingBoxSize(boundingBoxSize);

        if (boundingBoxSize.x > 0 || boundingBoxSize.y > 0 || boundingBoxSize.z > 0) {

          this.eye = this.camera.getWorldDirection(new Vector3());

          this.object.matrix.decompose(
            objPosition,
            objQuaternion,
            objScale
          );

          newState.eye = this.eye.toArray();
          newState.position = objPosition.toArray();
          newState.quaternion = objQuaternion.toArray();
          newState.scale = objScale.toArray();
          newState.dragMode = this.dragMode;
          newState.hoverBox = hoverBox;
          newState.targetHoverBox = targetHoverBox;
          newState.targetHoverFloor = targetHoverFloor;
          newState.exceedTick = (lastEventTick + Config.ShowTime >= curTick);
          newState.spriteSize = this.spriteSize;

          this.refreshBoundingBoxLines();
          this.refreshPathLine();
          this.refreshMappingPathLines();

          if (this.targetObject) {

            this.targets[this.targetId].box.getSize(targetBoundingBoxSize);
            this.targets[this.targetId].box.getCenter(targetObjPosition);

            this.refreshBoundingBoxLines(true);

          }

        }

      }

    }

    // Object3D.prototype.updateMatrixWorld.call(this)
    let hadUpdate = !lod.isEqual(newState, this.oldState);
    this.oldState = newState;
    return hadUpdate;

  };

  function getPointer(event) {
    if (!scope.elemRect)
      scope.refreshBoundingClientRect();

    let pointer = event.changedTouches ? event.changedTouches[0] : event;

    scope.mouseOnCanvas.x = pointer.clientX - scope.elemRect.left;
    scope.mouseOnCanvas.y = pointer.clientY - scope.elemRect.top;

    scope.mouse.x = scope.mouseOnCanvas.x / scope.elemRect.width * 2 - 1;
    scope.mouse.y = -scope.mouseOnCanvas.y / scope.elemRect.height * 2 + 1;

    return {
      x: (pointer.clientX - scope.elemRect.left) / scope.elemRect.width * 2 - 1,
      y: -(pointer.clientY - scope.elemRect.top) / scope.elemRect.height * 2 + 1,
      button: event.button,
      type: event.changedTouches ? 'touch' : 'mouse'
    };
  }

  this.pointerDown = function (event, pointer) {

    lastEventTick = -Config.ShowTime - 1;
    if (!this.object) return;
    if (pointer.type === 'mouse' && pointer.button !== 0) return;

    if (!this.enabled || !this.controlEnabled) return;

    this.dragEndPosition = new Vector3(this.mouse.x, this.mouse.y, 0).unproject(this.camera);

    let intersect;
    this.rayCaster.setFromCamera(pointer, this.camera);

    this.eye = this.camera.getWorldDirection(new Vector3());
    this.getObjectUnitAxes(objUnitX, objUnitY, objUnitZ);

    this.object.matrix.decompose(
      objPosition,
      objQuaternion,
      objScale
    );

    intersect = this.rayCaster.intersectObject(this.object, true)[0] || false;

    if (intersect) {

      this.dragMode = DRAG_DROP_MODE.BOX;
      this.dragStartPosition = intersect.point;
      this.isDragging = true;
      event.preventDefault();

      return;

    }

    if (!this.isDragging) {
      this.dragMode = undefined;
      this.dropMode = undefined;
      lastEventTick = -Config.ShowTime - 1;
    }

  };

  this.pointerMove = function (event, pointer) {

    if (!this.object) return;

    this.rayCaster.setFromCamera(pointer, this.camera);

    targetHoverBox = undefined;
    targetHoverFloor = undefined;

    if (!this.isDragging) {

      let intersect;

      hoverBox = undefined;

      intersect = this.rayCaster.intersectObject(this.object, true)[0] || false;

      if (intersect) {

        hoverBox = true;
        return;

      }

      return;

    }

    event.preventDefault();

    this.dragEndPosition = new Vector3(this.mouse.x, this.mouse.y, 0).unproject(this.camera);

    let boxes = Object.values(this.targets).filter(t => !t.exclude).map(t => t.mesh);
    let intersect = this.rayCaster.intersectObjects(boxes)[0] || false;

    this.dropMode = undefined;

    if (intersect) {

      this.targetId = intersect.object.name;
      this.targetObject = this.targets[this.targetId].obj;

    } else {

      this.targetId = undefined;
      this.targetObject = undefined;

    }

    if (this.targetId) {

      intersect = this.rayCaster.intersectObject(this.targetObject, true)[0] || false;

      if (intersect) {
        targetHoverBox = true;
        this.dropMode = DRAG_DROP_MODE.BOX;
        return;
      }

    } else {

      intersect = this.rayCaster.intersectObject(this.intersectPlane, true)[0] || false;

      if (intersect) {

        targetHoverFloor = true;
        this.dropMode = DRAG_DROP_MODE.FLOOR;
        return;

      }

    }

  };

  this.pointerUp = function (event, pointer) {

    this.isDragging = false;

    if ((pointer.type === 'mouse' && pointer.button !== 0) || !this.dragMode) return;
    lastEventTick = curTick;

    if (this.visible && this.object) {

      targetHoverBox = undefined;
      hoverBox = undefined;

      if (this.dragMode && this.dropMode) {

        scope.dispatchEvent({type: 'change'});

      }

      this.dragMode = undefined;
      this.dropMode = undefined;

    }
  };

  function onPointerDown(event) {
    scope.pointerDown(event, getPointer(event));
  }

  function onTouchDown(event) {
    scope.refreshBoundingClientRect();
    scope.pointerDown(event, getPointer(event));
  }

  function onPointerMove(event) {
    scope.pointerMove(event, getPointer(event));
  }

  function onPointerUp(event) {
    scope.pointerUp(event, getPointer(event));
  }

  this.createVertexPickerMaterial = function (hover) {

    return new MeshBasicMaterial({color: hover ? 0x5B4CFF : 0xFFFFFF});

  };

  this.createBoundingBoxLines = function (target) {
    let group = new Group();

    let lineMaterial = new LineMaterial({color: 0x0D0032, linewidth: 1});

    for (let i = 0; i < 13; ++i) {
      let lineGeometry = new LineGeometry();
      lineGeometry.setPositions(new Array(6).fill(0));

      let line = new Line2(lineGeometry, lineMaterial);
      line.computeLineDistances();

      group.add(line);
    }

    group.visible = false;

    if (target)
      this.controlScene.add(group);
    else
      this.controlGroup.add(group);
    return group;
  };

  this.createPathLineMaterial = function (valid) {

    return new LineMaterial({color: valid ? 0x5B4CFF : 0xFF0000, linewidth: 2});

  };

  this.createPathLine = function () {

    let lineGeometry = new LineGeometry();
    lineGeometry.setPositions(new Array(6).fill(0));

    let line = new Line2(lineGeometry, this.materials.path.invalid);
    line.computeLineDistances();

    line.name = "path-line";

    line.visible = false;
    this.controlScene.add(line);
    return line;

  };

  this.materials = {
    path: {
      valid: this.createPathLineMaterial(true),
      invalid: this.createPathLineMaterial(false),
    }
  };

  // Children
  this.controlGroup = new Group();
  this.controlGroup.name = `magnet-control-group`;
  this.controlGroup.matrixAutoUpdate = false;

  this.boundingBoxLines = this.createBoundingBoxLines();
  this.pathLine = this.createPathLine();
  this.mappingPathLines = {};

  this.targetBoundingBoxLines = this.createBoundingBoxLines(true);

  this.controlScene.add(this.controlGroup);
  this.controlScene.add(this.intersectPlane);
};

MagnetControl.prototype = Object.assign(Object.create(Object3D.prototype), Object.create(EventDispatcher.prototype),
  {

    constructor: MagnetControl,

    isMagnetControls: true

  });

export {MagnetControl};