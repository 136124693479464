import {vec3, mat4, mat3} from 'gl-matrix';
import {_n, _p, _q, _s, _v, _v4, _vq} from "./t";
import {decomposeTransform} from "./utils";
import {peregrineId} from "./id";

export interface WompMeshData {
  position?: Float32Array
  face?: Uint32Array
  normal?: Float32Array
  color?: Float32Array
  uv?: Float32Array
  masking?: Float32Array
  center?: vec3 // center of the mesh (local space, before transformation)
}

export interface WompBrepData {
  shape?: Uint8Array
}

export interface WompCurveData {
  points?: number[][];
  periodic?: boolean;
  degree?: number;
  bezier?: boolean;
}

export class WompObjectRef {
  objectId: string = '';

  matrix: mat4 = mat4.create(); // transformation matrix of the mesh

  constructor(id: string, matrix?: mat4) {
    this.objectId = id;
    if (matrix)
      mat4.copy(this.matrix, matrix);
  }

  clone() {
    return new WompObjectRef(this.objectId, mat4.clone(this.matrix));
  }
}

export class WompMesh {
  geometry: WompMeshData = {};

  matrix: mat4 = mat4.create(); // transformation matrix of the mesh

  constructor(geometry?: WompMeshData, matrix?: mat4) {
    if (geometry)
      this.geometry = geometry;
    if (matrix)
      mat4.copy(this.matrix, matrix);
  }
}

export class WompBrep {
  geometry: WompBrepData = {};

  matrix: mat4 = mat4.create(); // transformation matrix of the shape

  constructor(geometry?: WompBrepData, matrix?: mat4) {
    if (geometry)
      this.geometry = geometry;
    if (matrix)
      mat4.copy(this.matrix, matrix);
  }
}

export class WompCurve {
  geometry: WompCurveData = {};

  matrix: mat4 = mat4.create(); // transformation matrix of the shape

  constructor(geometry?: WompCurveData, matrix?: mat4) {
    if (geometry)
      this.geometry = geometry;
    if (matrix)
      mat4.copy(this.matrix, matrix);
  }
}

export function isIdentity(matOrVec: mat4 | vec3) {
  let offset = 0;

  if (matOrVec.length === 16) {
    for (let i = 0; i < 16; ++i) {
      offset += Math.abs((i % 5 === 0 ? 1 : 0) - matOrVec[i]);
    }
  } else {
    for (let i = 0; i < 3; ++i) {
      offset += Math.abs(1 - matOrVec[i]);
    }
  }

  return offset < 0.0001;
}

export function mergeGeometryVertices(geometry: WompMeshData, tolerance: number = 1e-4): WompMeshData {

  tolerance = Math.max(tolerance || 0, Number.EPSILON);

  let hashToIndex: { [key: string]: number } = {};
  let indices = geometry.face;
  let positions = geometry.position;

  if (!positions)
    return geometry;

  let vertexCount = indices ? indices.length : positions.length / 3;

  let nextIndex = 0;

  let attributeNames = ['position', 'normal', 'uv', 'color', 'masking'];
  let itemSizes: { [key: string]: number } = {
    'position': 3,
    'uv': 2,
    'color': 3,
    'normal': 3,
    'masking': 1
  };

  if (geometry.color && geometry.position && geometry.color.length > geometry.position.length) {
    itemSizes['color'] = 4;
  }

  let attrArrays: { [key: string]: number[] } = {};
  let newIndices = [];

  // initialize the arrays
  for (let i = 0, l = attributeNames.length; i < l; i++) {

    let name = attributeNames[i];

    attrArrays[name] = [];

  }

  // convert the error tolerance to an amount of decimal places to truncate to
  let decimalShift = Math.log10(1 / tolerance);
  let shiftMultiplier = Math.pow(10, decimalShift);
  for (let i = 0; i < vertexCount; i++) {

    let index = indices ? indices[i] : i;

    // Generate a hash for the vertex attributes at the current index 'i'
    let hash = '';
    for (let j = 0, l = attributeNames.length; j < l; j++) {

      let name = attributeNames[j];
      let attribute = (geometry as { [key: string]: any })[name];
      let itemSize = itemSizes[name];

      if (attribute) {

        for (let k = 0; k < itemSize; k++) {

          // double tilde truncates the decimal value
          hash += `${~~(attribute[index * itemSize + k] * shiftMultiplier)},`;

        }

      }

    }

    // Add another reference to the vertex if it's already
    // used by another index
    if (hash in hashToIndex) {

      newIndices.push(hashToIndex[hash]);

    } else {

      // copy data to the new index in the attribute arrays
      for (let j = 0, l = attributeNames.length; j < l; j++) {

        let name = attributeNames[j];
        let attribute = (geometry as { [key: string]: any })[name];
        let itemSize = itemSizes[name];
        let newarray = attrArrays[name];

        if (attribute) {

          for (let k = 0; k < itemSize; k++) {

            newarray.push(attribute[index * itemSize + k]);

          }

        }

      }

      hashToIndex[hash] = nextIndex;
      newIndices.push(nextIndex);
      nextIndex++;

    }

  }

  let result: WompMeshData = {};
  for (let i = 0, l = attributeNames.length; i < l; i++) {

    let name = attributeNames[i];

    if (attrArrays[name].length > 0) {

      (result as { [key: string]: any })[name] = new Float32Array(attrArrays[name]);

    }

  }

  // indices

  result.face = new Uint32Array(newIndices);

  return result;
}

export function computeGeometryVertexNormals(geometry: WompMeshData) {

  if (geometry.position && geometry.face) {

    let face = geometry.face;
    let positions = geometry.position;

    if (geometry.normal === undefined) {

      geometry.normal = new Float32Array(positions.length);

    } else {

      // reset existing normals to zero

      let array = geometry.normal;

      for (let i = 0, il = array.length; i < il; i++) {

        array[i] = 0;

      }

    }

    let normals = geometry.normal;

    let vA, vB, vC;
    let pA = vec3.create(), pB = vec3.create(), pC = vec3.create();
    let cb = vec3.create(), ab = vec3.create();

    for (let i = 0, il = face.length; i < il; i += 3) {

      vA = face[i] * 3;
      vB = face[i + 1] * 3;
      vC = face[i + 2] * 3;

      vec3.set(pA, positions[vA], positions[vA + 1], positions[vA + 2]);
      vec3.set(pB, positions[vB], positions[vB + 1], positions[vB + 2]);
      vec3.set(pC, positions[vC], positions[vC + 1], positions[vC + 2]);

      vec3.sub(cb, pC, pB);
      vec3.sub(ab, pA, pB);
      vec3.cross(cb, cb, ab);

      normals[vA] += cb[0];
      normals[vA + 1] += cb[1];
      normals[vA + 2] += cb[2];

      normals[vB] += cb[0];
      normals[vB + 1] += cb[1];
      normals[vB + 2] += cb[2];

      normals[vC] += cb[0];
      normals[vC + 1] += cb[1];
      normals[vC + 2] += cb[2];

    }

    let vec = vec3.create();

    for (let i = 0, il = normals.length; i < il; i += 3) {

      vec3.set(vec, normals[i], normals[i + 1], normals[i + 2]);

      vec3.normalize(vec, vec);

      normals[i] = vec[0];
      normals[i + 1] = vec[1];
      normals[i + 2] = vec[2];

    }

  }

}

export function getBoundingBoxFromGeometry(geometry: WompMeshData) {

  return getBoundingBoxFromMesh(new WompMesh(geometry));

}

export function getBoundingBoxFromGeometries(geometries: WompMeshData[]) {

  return getBoundingBoxFromMeshes(geometries.map(g => new WompMesh(g)));

}

export function mergeGeometries(geometries: WompMeshData[]): WompMeshData {

  let mergedGeometry: WompMeshData = {};

  let faceCnt = 0;
  let positionCnt = 0;

  for (let i = 0; i < geometries.length; ++i) {

    let geometry = geometries[i];

    faceCnt += geometry.face ? geometry.face.length : 0;

    positionCnt += geometry.position ? geometry.position.length : 0;

  }

  let faceOffset = 0;

  let mergedFace = new Uint32Array(faceCnt);

  let mergedPosition = new Float32Array(positionCnt);

  faceCnt = 0;
  positionCnt = 0;

  for (let i = 0; i < geometries.length; ++i) {

    let geometry = geometries[i];

    if (geometry.face) {

      let face = geometry.face;

      for (let j = 0; j < face.length; ++j) {

        mergedFace[j + faceCnt] = (face[j] + faceOffset);

      }

    }

    if (geometry.position) {

      let position = geometry.position;

      for (let j = 0; j < position.length; ++j) {

        mergedPosition[j + positionCnt] = position[j];

      }

    }

    faceCnt += geometry.face ? geometry.face.length : 0;

    positionCnt += geometry.position ? geometry.position.length : 0;

    faceOffset += geometry.position ? geometry.position.length / 3 : 0;

  }

  mergedGeometry.face = mergedFace;

  mergedGeometry.position = mergedPosition;

  return mergedGeometry;

}

export function cloneGeometry(geometry: WompMeshData): WompMeshData {

  let newGeometry: WompMeshData = {};

  if (geometry.face) {
    newGeometry.face = new Uint32Array(geometry.face.length);
    newGeometry.face.set(geometry.face, 0);
  }

  if (geometry.position) {
    newGeometry.position = new Float32Array(geometry.position.length);
    newGeometry.position.set(geometry.position, 0);
  }

  if (geometry.normal) {
    newGeometry.normal = new Float32Array(geometry.normal.length);
    newGeometry.normal.set(geometry.normal, 0);
  }

  if (geometry.uv) {
    newGeometry.uv = new Float32Array(geometry.uv.length);
    newGeometry.uv.set(geometry.uv, 0);
  }

  if (geometry.color) {
    newGeometry.color = new Float32Array(geometry.color.length);
    newGeometry.color.set(geometry.color, 0);
  }

  return newGeometry;

}

export function getRenderedGeometry(mesh: WompMesh) {

  if (isIdentity(mesh.matrix)) {

    return mesh.geometry;

  } else {

    let geometry = cloneGeometry(mesh.geometry);

    applyGeometryTransform(geometry, mesh.matrix);

    return geometry;

  }

}

export function applyGeometryTransform(geometry: WompMeshData, transform: mat4) {

  if (geometry.position) {

    let t = vec3.create();

    for (let i = 0; i < geometry.position.length; i += 3) {

      vec3.set(t, geometry.position[i], geometry.position[i + 1], geometry.position[i + 2]);
      vec3.transformMat4(t, t, transform);
      geometry.position[i] = t[0];
      geometry.position[i + 1] = t[1];
      geometry.position[i + 2] = t[2];

    }

  }

  if (geometry.normal) {

    let normalMat = mat3.fromMat4(mat3.create(), transform);
    mat3.invert(normalMat, normalMat);
    mat3.transpose(normalMat, normalMat);

    let t = vec3.create();

    for (let i = 0; i < geometry.normal.length; i += 3) {

      vec3.set(t, geometry.normal[i], geometry.normal[i + 1], geometry.normal[i + 2]);
      vec3.transformMat3(t, t, normalMat);
      geometry.normal[i] = t[0];
      geometry.normal[i + 1] = t[1];
      geometry.normal[i + 2] = t[2];

    }

  }

}

export function getBoundingBoxFromMeshes(meshes: WompMesh[]): vec3[] {

  let box = [vec3.fromValues(+Infinity, +Infinity, +Infinity), vec3.fromValues(-Infinity, -Infinity, -Infinity)];

  for (let i = 0; i < meshes.length; ++i) {

    unionBoundingBox(box, getBoundingBoxFromMesh(meshes[i]));

  }

  return box;

}

export function getBoundingBoxFromMesh(mesh: WompMesh): vec3[] {
  let minX = +Infinity, minY = +Infinity, minZ = +Infinity, maxX = -Infinity, maxY = -Infinity, maxZ = -Infinity;

  if (mesh.geometry.position) {

    let position = mesh.geometry.position;

    if (isIdentity(mesh.matrix)) {

      for (let i = 0; i < position.length; i += 3) {

        // if (!position[i] && !position[i + 1] && !position[i + 2])
        //   console.log(i / 3);

        minX = Math.min(minX, position[i]);
        minY = Math.min(minY, position[i + 1]);
        minZ = Math.min(minZ, position[i + 2]);

        maxX = Math.max(maxX, position[i]);
        maxY = Math.max(maxY, position[i + 1]);
        maxZ = Math.max(maxZ, position[i + 2]);

      }

    } else {

      let vec = vec3.create();

      for (let i = 0; i < position.length; i += 3) {

        vec3.set(vec, position[i], position[i + 1], position[i + 2]);

        vec3.transformMat4(vec, vec, mesh.matrix);

        minX = Math.min(minX, vec[0]);
        minY = Math.min(minY, vec[1]);
        minZ = Math.min(minZ, vec[2]);

        maxX = Math.max(maxX, vec[0]);
        maxY = Math.max(maxY, vec[1]);
        maxZ = Math.max(maxZ, vec[2]);

      }

    }

  }

  return [vec3.fromValues(minX, minY, minZ), vec3.fromValues(maxX, maxY, maxZ)];

}

export function unionBoundingBox(boundingBox: vec3[], box: vec3[]) {

  if (!isBoundingBoxEmpty(box)) {

    expandBoundingBoxByPoint(boundingBox, box[0]);
    expandBoundingBoxByPoint(boundingBox, box[1]);

  }

  return boundingBox;

}

export function expandBoundingBoxByPoint(boundingBox: vec3[], point: vec3) {

  if (isBoundingBoxEmpty(boundingBox)) {

    vec3.set(boundingBox[0], point[0], point[1], point[2]);
    vec3.set(boundingBox[1], point[0], point[1], point[2]);

  } else {

    vec3.set(
      boundingBox[0],
      Math.min(point[0], boundingBox[0][0]),
      Math.min(point[1], boundingBox[0][1]),
      Math.min(point[2], boundingBox[0][2])
    );

    vec3.set(
      boundingBox[1],
      Math.max(point[0], boundingBox[1][0]),
      Math.max(point[1], boundingBox[1][1]),
      Math.max(point[2], boundingBox[1][2])
    );

  }

}

export function isBoundingBoxEmpty(boundingBox: vec3[]) {

  return boundingBox[0][0] > boundingBox[1][0] || boundingBox[0][1] > boundingBox[1][1] || boundingBox[0][2] > boundingBox[1][2];

}

export function getBoundingBoxCenter(boundingBox: vec3[]) {

  let v = vec3.add(vec3.create(), boundingBox[0], boundingBox[1]);
  return vec3.scale(v, v, 0.5);

}

export function getBoundingBoxSize(boundingBox: vec3[]) {

  return vec3.sub(vec3.create(), boundingBox[1], boundingBox[0]);

}

export function getUniqueDesc(obj: WompObjectRef, p?: number) {

  return `${obj.objectId}(${_p(obj.matrix, p).join(',')})`;

}

export enum TransformComponent {
  Translation,
  Uniform,
  UnitScale,
  Scale,
  Rotation,
  Quaternion,
  Skew,
  Perspective
}

export function getComposeDesc(obj: WompObjectRef, p?: number, exclude: TransformComponent[] = [TransformComponent.Rotation, TransformComponent.Scale, TransformComponent.Perspective]) {
  let {translate, skew, uniform, unitScale, scale, perspective, rotate, quaternion} = decomposeTransform(obj.matrix);

  let comps = [];
  if (!exclude.includes(TransformComponent.Translation))
    comps.push(`t(${_v(translate, p).join(',')})`);
  if (!exclude.includes(TransformComponent.Scale))
    comps.push(`s(${_v(scale, p).join(',')})`);
  if (!exclude.includes(TransformComponent.Uniform))
    comps.push(`u(${_n(uniform, p)})`);
  if (!exclude.includes(TransformComponent.UnitScale))
    comps.push(`i(${_v(unitScale, p).join(',')})`);
  if (!exclude.includes(TransformComponent.Quaternion))
    comps.push(`q(${_vq(quaternion, p).join(',')})`);
  if (!exclude.includes(TransformComponent.Rotation))
    comps.push(`r(${_v(rotate, p).join(',')})`);
  if (!exclude.includes(TransformComponent.Skew))
    comps.push(`k(${_v(skew, p).join(',')})`);
  if (!exclude.includes(TransformComponent.Perspective))
    comps.push(`p(${_v4(perspective, p).join(',')})`);

  return `${obj.objectId}(${comps.join(',')})`;
}

export function getHeatmapDesc(objId: string, scale: vec3, skew: vec3) {

  return `heatmap on ${objId}(${_v(scale).join(',')})(${_v(skew).join(',')})`;

}

export function normalizeScale(scale: vec3) {

  if (scale[0] * scale[0] + scale[1] * scale[1] + scale[2] * scale[2] === 0)
    return {uniform: 0, unitScale: vec3.fromValues(1, 1, 1)};

  let squareRatio = scale[0] * scale[0] + scale[1] * scale[1] + scale[2] * scale[2];
  let uniform = Math.sqrt(3 / squareRatio);

  return {uniform, unitScale: vec3.scale(vec3.create(), scale, uniform)};

}

export function getIdPart(hash: string) {

  return hash.substr(0, 23);

}

export function getNonIdPart(hash: string) {

  return hash.substr(23);

}

export function encodeWompObjectRef(objectRef: WompObjectRef): any {
  return {[_s('objectId')]: objectRef.objectId, [_s('matrix')]: _p(objectRef.matrix)};
}

export function decodeWompObjectRef(v: any): WompObjectRef {
  try {
    return new WompObjectRef(v[_s('objectId')], _q(v[_s('matrix')]));
  } catch (e) {
    console.warn(e);
    return new WompObjectRef(peregrineId());
  }
}
