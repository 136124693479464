import { vec3, mat3, mat4 } from 'gl-matrix';

var TransformData = function () {
  return {
    _center: vec3.create(), // center of the mesh (local space, before transformation)
    _matrix: mat4.create(), // transformation matrix of the mesh

    _symmetryNormal: [1.0, 0.0, 0.0], // symmetry normal
    _symmetryOffset: 0.0, // offset
  };
};

export default TransformData;
