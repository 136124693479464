import axios from 'axios';
import {apiRootPath} from "./const";

export const _setAuthHeader = (token?: string) => {
  if (!token) {
    delete axios.defaults.headers.common['Authorization'];
  } else {
    axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
  }
};

export function _postSignUp(fields: { [name: string]: string }) {
  return axios.post(apiRootPath + `/api/post-user`, fields);
}

export function _postSignIn(fields: { [name: string]: string }) {
  return axios.post(apiRootPath + `/api/auth-jwt`, fields);
}

export function _postVerifyToken(token: string) {
  return axios.post(apiRootPath + `/api/auth-jwt-verify`, {token});
}

export function _postSendConfirmEmail(email: string) {
  return axios.post(apiRootPath + `/api/auth-send-confirm-email`, {email});
}

export function _postActivateAccount(uidb64: string, token: string) {
  return axios.post(apiRootPath + `/api/auth-activate`, {uidb64, token});
}

export function _postSendResetEmail(email: string) {
  return axios.post(apiRootPath + `/api/auth-send-reset-email`, {email});
}

export function _postChangePassword(uidb64: string, token: string, password: string) {
  return axios.post(apiRootPath + `/api/auth-change-password`, {uidb64, token, password});
}
