import lod from "lodash";
import {CachedGeometryObjectTypes, ObjectTypes, XObject} from "../xobject";
import {decodeModelProperty, encodeModelProperty, ICalcConfigDiff, IModelProperty, IParameterProperty} from "../types";
import {decodeWompObjectRef, encodeWompObjectRef, WompObjectRef} from "../../WompObject";
import {curve as curveTypes, pigeon as pigeonTypes} from "../../types";
import {mat4, vec3} from "gl-matrix";
import {Relation} from "../relation";
import {
  getBrepFromRef,
  getCurveFromRef,
  getDescriptionFromObjectId,
  getObjectType,
  getRegisteredId,
  Parameter,
  ParamTypes,
  registerGeometry
} from "./parameter";
import {_b, _n, _p, _q, _s, _t, _v} from "../../t";
import {peregrineId} from "../../id";

export class Param_Compound extends Parameter {
  protected _objectType = ParamTypes.Compound;
  protected _defaultValue: undefined;

  protected _stateValid = true;
  // Runtime Data
  protected _values: any[] = [];

  get values() {
    return this._values;
  }

  set values(values: any[]) {
    if (!lod.isEqual(this._values, values)) {
      this._values = values;
      this._dataValid = false;
    }
  }

  protected _valueTypes: ObjectTypes[] = [];

  get valueTypes() {
    return this._valueTypes;
  }

  set valueTypes(valueTypes: ObjectTypes[]) {
    if (!lod.isEqual(this._valueTypes, valueTypes)) {
      this._valueTypes = valueTypes;
      this._dataValid = false;
    }
  }

  protected _properties: IModelProperty[] = [];

  get properties() {
    return this._properties;
  }

  set properties(properties: IModelProperty[]) {
    if (!lod.isEqual(this._properties, properties)) {
      this._properties = properties;
      this._dataValid = false;
    }
  }

  protected _caches: ([WompObjectRef, WompObjectRef] | null)[] = [];

  get caches() {
    return this._caches;
  }

  set caches(caches: ([WompObjectRef, WompObjectRef] | null)[]) {
    if (!lod.isEqual(this._caches, caches)) {
      this._caches = caches;
      this._dataValid = false;
    }
  }

  get length() {
    return Math.min(this._values.length, this._valueTypes.length, this._caches.length, this._properties.length);
  }

  static create(calc: XObject, title: string, isInput: boolean, isOperable: boolean, render: boolean) {
    let param = new Param_Compound();
    param._id = peregrineId();
    param._calc = calc;
    param._title = title;
    param._isInput = isInput;
    param._isOperable = isOperable;
    param._render = render;

    param.setupInitialData();
    return param;
  }

  generateProperties(): IParameterProperty[] {
    let properties: IParameterProperty[] = [];

    for (let i = 0; i < this._values.length; ++i) {
      let value = this._values[i];
      let valueType = this._valueTypes.length > i ? this._valueTypes[i] : undefined;
      let property = this._properties.length > i ? this._properties[i] : undefined;

      if (valueType) {
        if (
          CachedGeometryObjectTypes.includes(valueType) ||
          valueType === ObjectTypes.Vertex ||
          valueType === ObjectTypes.Mesh ||
          valueType === ObjectTypes.SculptMesh ||
          valueType === ObjectTypes.Brep
        ) {
          if (property) {
            properties.push({
              hash: property.hash,
              voided: property.voided,
              material: property.material
            });
          } else {
            properties.push({
              hash: ""
            });
          }
        } else if (valueType === ObjectTypes.Number) {
          properties.push({
            hash: _n(value)
          });
        } else if (valueType === ObjectTypes.String) {
          properties.push({
            hash: value
          });
        } else if (valueType === ObjectTypes.Boolean) {
          properties.push({
            hash: value ? "1" : "0"
          });
        } else if (valueType === ObjectTypes.Point) {
          properties.push({
            hash: `${_n(value[0])} ${_n(value[1])} ${_n(value[2])}`
          });
        } else if (valueType === ObjectTypes.Transform) {
          properties.push({
            hash: _p(value).join(' ')
          });
        } else {
          properties.push({
            hash: property ? property.hash : ""
          });
        }
      } else {
        properties.push({
          hash: property ? property.hash : ""
        });
      }
    }

    return properties;
  }

  protected validateGeneratedData() {
    let values = [];
    let caches = [];

    this.dataValueTypes = this._valueTypes.map(_s);
    this.dataProperties = this._properties.map(encodeModelProperty);

    for (let i = 0; i < this._valueTypes.length; ++i) {
      let value = this._values[i];
      let valueType = this._valueTypes[i];
      let cache = this._caches[i] as [WompObjectRef, WompObjectRef];

      if (valueType === ObjectTypes.Curve || valueType === ObjectTypes.Brep) {
        values.push(encodeWompObjectRef(value));
        caches.push([encodeWompObjectRef(cache[0]), encodeWompObjectRef(cache[1])]);
      } else if (valueType === ObjectTypes.Mesh || valueType === ObjectTypes.SculptMesh) {
        values.push(encodeWompObjectRef(value));
        caches.push(null);
      } else if (value === ObjectTypes.Point || value === ObjectTypes.Vertex) {
        values.push(_v(value));
        caches.push(null);
      } else if (value === ObjectTypes.Transform) {
        values.push(_p(value));
        caches.push(null);
      } else if (valueType === ObjectTypes.Boolean) {
        values.push(_b(value));
        caches.push(null);
      } else {
        values.push(value);
        caches.push(null);
      }
    }

    this.dataValues = values;
    this.dataCaches = caches;
    this._dataValid = true;
  }

  overwrite(jData: any, replaceData: boolean) {
    if (!this.dataValid)
      this.validateGeneratedData();

    super.overwrite(jData, replaceData);

    if (this._data[_s('caches')] !== jData[_s('caches')] && jData[_s('caches')] !== undefined) {
      let newCaches: ([WompObjectRef, WompObjectRef] | null)[] = [];
      for (let i = 0; i < Math.min(jData[_s('valueTypes')].length, jData[_s('caches')].length); ++i) {
        let valueType = jData[_s('valueTypes')][i];
        let cache = jData[_s('caches')][i];

        if (CachedGeometryObjectTypes.includes(_t(valueType))) {
          newCaches.push([decodeWompObjectRef(cache[0]), decodeWompObjectRef(cache[1])]);
        } else {
          newCaches.push(null);
        }
      }

      this.caches = newCaches;
    }

    if (this._data[_s('values')] !== jData[_s('values')] && jData[_s('values')] !== undefined) {
      let newValues = [];
      for (let i = 0; i < Math.min(jData[_s('valueTypes')].length, jData[_s('values')].length); ++i) {
        let value = jData[_s('values')][i];
        let valueType = jData[_s('valueTypes')][i];

        if (valueType === _s(ObjectTypes.Curve) || valueType === _s(ObjectTypes.Brep) || valueType === _s(ObjectTypes.Mesh) || valueType === _s(ObjectTypes.SculptMesh)) {
          newValues.push(decodeWompObjectRef(value));
        } else if (valueType === _s(ObjectTypes.Point) || valueType === _s(ObjectTypes.Vertex)) {
          newValues.push(vec3.fromValues(+value[0], +value[1], +value[2]));
        } else if (valueType === _s(ObjectTypes.Transform)) {
          newValues.push(_q(value));
        } else {
          newValues.push(value);
        }
      }
      this.values = newValues;
    }

    if (this._data[_s('valueTypes')] !== jData[_s('valueTypes')] && jData[_s('valueTypes')] !== undefined) {
      this.valueTypes = jData[_s('valueTypes')].map(_t);
    }

    if (this._data[_s('properties')] !== jData[_s('properties')] && jData[_s('properties')] !== undefined) {
      this.properties = jData[_s('properties')].map(decodeModelProperty);
    }

    if (replaceData) {
      this._data = jData;
      this._dataValid = true;
    }
  }

  async generateCaches() {
    if (this._caches.length > 0)
      return;
    for (let i = 0; i < this._values.length; ++i) {
      let valueType = this._valueTypes[i];
      let value = this._values[i];

      if (valueType === ObjectTypes.Brep) {
        let brep = getBrepFromRef(this._calc, value);
        let brepDesc = getDescriptionFromObjectId(this._calc, value.objectId);

        let meshId = getRegisteredId(this._calc, brepDesc+'-mesh');
        let edgeId = getRegisteredId(this._calc, brepDesc+'-edge');

        if (!meshId || !edgeId) {
          let [meshData, edgeData] = await pigeonTypes.tessellate(brep.geometry);

          meshId = registerGeometry(this._calc, meshData, brepDesc + '-mesh');
          edgeId = registerGeometry(this._calc, edgeData, brepDesc + '-edge');
        }

        this._caches.push([new WompObjectRef(meshId, value.matrix), new WompObjectRef(edgeId, value.matrix)]);
      } else if (valueType === ObjectTypes.Curve) {
        let value = this._values[i];
        let curve = getCurveFromRef(this._calc, value);
        let propertyHash = this._properties[i] ? this._properties[i].hash : peregrineId();

        let edgeId = getRegisteredId(this._calc, propertyHash + '-edge');

        if (!edgeId) {
          let edgeData = curveTypes.getCurveEdgeGeometry(curve.geometry);
          edgeId = registerGeometry(this._calc, edgeData, propertyHash + '-edge');
        }

        this._caches.push([new WompObjectRef(edgeId), new WompObjectRef(edgeId)]);
      } else {
        this._caches.push(null);
      }
    }
  }

  filterValues(objType: ObjectTypes) {
    let values = [];
    for (let i = 0; i < this.length; ++i) {
      if (this._valueTypes[i] === objType) {
        values.push(this._values[i]);
      }
    }

    return values;
  }

  filterProperties(objType: ObjectTypes) {
    let properties = [];
    for (let i = 0; i < this.length; ++i) {
      if (this._valueTypes[i] === objType) {
        properties.push(this._properties[i]);
      }
    }

    return properties;
  }

  solve(diff?: ICalcConfigDiff) {
    if (diff && diff.obj) {
      this._caches = [];
      this._values = [];
      this._valueTypes = [];

      for (let relation of this._prevRelations) {
        let prevAny = (relation as unknown as Relation).from as any;
        if (prevAny.objectType === ParamTypes.Compound) {
          let prevComp = prevAny as Param_Compound;
          this._valueTypes = this._valueTypes.concat(prevComp._valueTypes);
        } else {
          let objType = getObjectType(prevAny.objectType as ParamTypes);
          for (let i = 0; i < prevAny._values.length; ++i)
            this._valueTypes.push(objType);
        }
        this._values = [...this._values, ...prevAny._values];
        if (prevAny._caches) {
          this._caches = [...this._caches, ...prevAny._caches];
        } else {
          this._caches = [...this._caches, null];
        }
      }
      this._dataValid = false;
    }

    if (diff && (diff.obj || diff.prevVoided || diff.prevMaterial)) {
      this._properties = [];

      for (let relation of this._prevRelations) {
        let prevAny = (relation as unknown as Relation).from as any;
        this._properties = [...this._properties, ...prevAny._properties];
      }
      this._dataValid = false;
    }

    for (let i = 0; i < this._values.length; ++i) {
      if (this._valueTypes[i] === ObjectTypes.Transform || this._valueTypes[i] === ObjectTypes.Point || this._valueTypes[i] === ObjectTypes.Vertex) {
        if (lod.some(this._values[i], v => isNaN(+v))) {
          console.warn(`NaN value found in parameter[${this._title}] of calc[${(this._calc as any).title}]`);
          this._values[i] = mat4.create();
          this._dataValid = false;
        }
      } else if (this._valueTypes[i] === ObjectTypes.Number) {
        if (isNaN(+this._values[i])) {
          console.warn(`NaN value found in parameter[${this._title}] of calc[${(this._calc as any).title}]`);
          this._values[i] = 0;
          this._dataValid = false;
        }
      }
    }
  }
}
