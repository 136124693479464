import {
  DELETE_CHAT,
  ApiChatActionTypes,
  FETCH_CHATS,
  IApiChatState,
  POST_CHAT, FETCH_UNREAD_CHATS
} from '../../types/api/chat';
import {INIT_STORE} from "../../types/init";

const initialState: IApiChatState = {
  getChats: {
    fetching: false
  },
  deleteChat: {
    deleting: false
  },
  postChat: {
    posting: false
  },
  getUnreadChats: {
    fetching: false
  }
};

export function apiChatReducer(
  state = initialState,
  action: ApiChatActionTypes
): IApiChatState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case FETCH_CHATS:
      return {
        ...state,
        getChats: {
          fetching: action.payload.fetching,
          message: action.payload.message,
          items: action.payload.items
        }
      };
    case DELETE_CHAT:
      return {
        ...state,
        deleteChat: {
          deleting: action.payload.deleting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    case POST_CHAT:
      return {
        ...state,
        postChat: {
          posting: action.payload.posting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    case FETCH_UNREAD_CHATS:
      return {
        ...state,
        getUnreadChats: {
          fetching: action.payload.fetching,
          message: action.payload.message
        }
      };
    default:
      return state;
  }
}