import Utils from '../../misc/Utils';
import Subdivision from '../dynamic/Subdivision';
import Decimation from '../dynamic/Decimation';
import Mesh from '../Mesh';
import createMeshData from '../MeshData';

// Dynamic topology mesh (triangles only)
// Obviously less performant than the static topology mesh
// It "inherits" Mesh but in practice it almost overrides everything related to topology
//
// The edges are not computed though (kind of bothersome to update...)
//
// The wireframe is directly computed from the triangles (it's as stupid as 1 tri => 3 lines)
// Basically... "quick and dirty" (the edges will be drawn twice)

class MeshDynamic extends Mesh {

  constructor(mesh) {
    super();

    this.setID(mesh.getID());

    this._meshData = createMeshData();
    this.setRenderData(mesh.getRenderData());
    this.setTransformData(mesh.getTransformData());

    this._facesStateFlags = null; // state flags (<= Utils.STATE_FLAG) (Int32Array)

    this.initFromMesh(mesh);

    this.isDynamic = true;
  }

  subdivide(iTris, center, radius2, detail2, states) {
    return Subdivision.subdivision(this, iTris, center, radius2, detail2, states, MeshDynamic.LINEAR);
  }

  decimate(iTris, center, radius2, detail2, states) {
    return Decimation.decimation(this, iTris, center, radius2, detail2, states);
  }

  getSubdivisionFactor() {
    return MeshDynamic.SUBDIVISION_FACTOR * 0.01;
  }

  getDecimationFactor() {
    return MeshDynamic.DECIMATION_FACTOR * 0.01;
  }

  getVerticesProxy() {
    return this.getVertices(); // for now no proxy sculpting for dynamic meshes
  }

  addNbVertice(nb) {
    this._meshData._nbVertices += nb;
  }

  addNbFace(nb) {
    this._meshData._nbFaces += nb;
  }

  getNbEdges() {
    return this.getNbFaces() * 3;
  }

  getFacesStateFlags() {
    return this._facesStateFlags;
  }

  allocateArrays() {
    super.allocateArrays();
    this._meshData._vertRingVert = []; // vertex ring
    this._meshData._vertRingFace = []; // face ring
    this._facesStateFlags = new Int32Array(this.getNbFaces());
  }

  initFromMesh(mesh) {
    var nbVertices = mesh.getNbVertices();

    // make sure to strip UVs
    this.setVertices(new Float32Array(mesh.getVertices().subarray(0, nbVertices * 3)));
    this.setMaskings(new Float32Array(mesh.getMaskings().subarray(0, nbVertices)));
    this.setNbVertices(nbVertices);

    this.setFacesFromMesh(mesh);

    this.init();
  }

  setFacesFromMesh(mesh) {
    this.setFaces(new Uint32Array(mesh.getNbFaces() * 3));
    this.setNbFaces(mesh.getNbFaces());

    var fArMesh = mesh.getFaces();
    var nbFaces = this.getNbFaces();
    var fAr = this.getFaces();

    for (var i = 0; i < nbFaces; ++i) {
      var id3 = i * 3;
      fAr[id3] = fArMesh[id3];
      fAr[id3 + 1] = fArMesh[id3 + 1];
      fAr[id3 + 2] = fArMesh[id3 + 2];
    }
  }

  updateTopology(iFaces, iVerts) {
    this.updateVerticesOnEdge(iVerts);
  }

  updateVerticesOnEdge(iVerts) {
    var vOnEdge = this.getVerticesOnEdge();
    var vrings = this.getVerticesRingVert();
    var frings = this.getVerticesRingFace();
    var full = iVerts === undefined;
    var nbVerts = full ? this.getNbVertices() : iVerts.length;

    for (var i = 0; i < nbVerts; ++i) {
      var id = full ? i : iVerts[i];
      vOnEdge[id] = vrings[id].length !== frings[id].length ? 1 : 0;
    }
  }

  resizeArray(orig, targetSize) {
    if (!orig) return null;

    // shrink size
    if (orig.length >= targetSize) return orig.subarray(0, targetSize * 2);

    // expand
    var tmp = new orig.constructor(targetSize * 2);
    tmp.set(orig);

    return tmp;
  }

  /** Reallocate mesh resources */
  reAllocateArrays(nbAddElements) {
    var mdata = this._meshData;

    var nbDyna = this._facesStateFlags.length;
    var nbFaces = this.getNbFaces();
    var len = nbFaces + nbAddElements;
    if (nbDyna < len || nbDyna > len * 4) {
      this._facesStateFlags = this.resizeArray(this._facesStateFlags, len);

      mdata._facesABC = this.resizeArray(mdata._facesABC, len * 3);

      mdata._faceBoxes = this.resizeArray(mdata._faceBoxes, len * 6);
      mdata._faceNormalsXYZ = this.resizeArray(mdata._faceNormalsXYZ, len * 3);
      mdata._faceCentersXYZ = this.resizeArray(mdata._faceCentersXYZ, len * 3);

      mdata._facesTagFlags = this.resizeArray(mdata._facesTagFlags, len);

      mdata._facePosInLeaf = this.resizeArray(mdata._facePosInLeaf, len);
    }

    nbDyna = mdata._verticesXYZ.length / 3;
    var nbVertices = this.getNbVertices();
    len = nbVertices + nbAddElements;
    if (nbDyna < len || nbDyna > len * 4) {
      mdata._verticesXYZ = this.resizeArray(mdata._verticesXYZ, len * 3);
      mdata._normalsXYZ = this.resizeArray(mdata._normalsXYZ, len * 3);
      mdata._masking = this.resizeArray(mdata._masking, len);

      mdata._vertOnEdge = this.resizeArray(mdata._vertOnEdge, len);

      mdata._vertTagFlags = this.resizeArray(mdata._vertTagFlags, len);
      mdata._vertSculptFlags = this.resizeArray(mdata._vertSculptFlags, len);
      mdata._vertStateFlags = this.resizeArray(mdata._vertStateFlags, len);

      // mdata._vertProxy = this.resizeArray(mdata._vertProxy, len * 3);
    }
  }

  initTopology() {
    var vrings = this.getVerticesRingVert();
    var frings = this.getVerticesRingFace();
    var i = 0;
    var nbVertices = this.getNbVertices();
    vrings.length = frings.length = nbVertices;
    for (i = 0; i < nbVertices; ++i) {
      vrings[i] = [];
      frings[i] = [];
    }

    var nbFaces = this.getNbFaces();
    var fAr = this.getFaces();
    for (i = 0; i < nbFaces; ++i) {
      var j = i * 3;
      frings[fAr[j]].push(i);
      frings[fAr[j + 1]].push(i);
      frings[fAr[j + 2]].push(i);
    }

    var vOnEdge = this.getVerticesOnEdge();
    for (i = 0; i < nbVertices; ++i) {
      this.computeRingVertices(i);
      vOnEdge[i] = frings[i].length !== vrings[i].length ? 1 : 0;
    }
  }

  /** Compute the vertices around a vertex */
  computeRingVertices(iVert) {
    var tagFlag = ++Utils.TAG_FLAG;
    var fAr = this.getFaces();
    var vflags = this.getVerticesTagFlags();

    var vring = this._meshData._vertRingVert[iVert];
    var fring = this._meshData._vertRingFace[iVert];
    vring.length = 0;
    var nbTris = fring.length;

    for (var i = 0; i < nbTris; ++i) {
      var ind = fring[i] * 3;
      var iVer1 = fAr[ind];
      var iVer2 = fAr[ind + 1];

      if (iVer1 === iVert) iVer1 = fAr[ind + 2];
      else if (iVer2 === iVert) iVer2 = fAr[ind + 2];

      if (vflags[iVer1] !== tagFlag) {
        vflags[iVer1] = tagFlag;
        vring.push(iVer1);
      }

      if (vflags[iVer2] !== tagFlag) {
        vflags[iVer2] = tagFlag;
        vring.push(iVer2);
      }
    }
  }
}

MeshDynamic.SUBDIVISION_FACTOR = 75; // subdivision factor
MeshDynamic.DECIMATION_FACTOR = 0; // decimation factor
MeshDynamic.LINEAR = false; // linear subdivision

export default MeshDynamic;
