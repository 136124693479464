import {ParamTitles} from "../parameter";

export enum ComponentTypes {
  None = '',
  Gumball = 'gumball',
  SphereGenerator = 'basic-sphere-gen',
  CylinderGenerator = 'basic-cylinder-gen',
  ConeGenerator = 'basic-cone-gen',
  CubeGenerator = 'basic-cube-gen',
  PyramidGenerator = 'basic-pyramid-gen',
  TubeGenerator = 'basic-tube-gen',
  DonutGenerator = 'basic-donut-gen',
  PolylineGenerator = 'basic-polyline-gen',
  CurveGenerator = 'basic-curve-gen',
  SurfaceGenerator = 'basic-surface-gen',
  ThreeSphereGenerator = 'three-basic-sphere-gen',
  ThreeCylinderGenerator = 'three-basic-cylinder-gen',
  ThreeConeGenerator = 'three-basic-cone-gen',
  ThreeCubeGenerator = 'three-basic-cube-gen',
  ThreePyramidGenerator = 'three-basic-pyramid-gen',
  ThreeTubeGenerator = 'three-basic-tube-gen',
  ThreeDonutGenerator = 'three-basic-donut-gen',
  ThreeTextGenerator = 'three-basic-text-gen',
  ThreePlaneGenerator = 'three-basic-plane-gen',
  CurveParameter = 'param-curve',
  VertexParameter = 'param-vertex',
  MeshParameter = 'param-mesh',
  BrepParameter = 'param-brep',
  ExtrudeModifier = 'modifier-extrude',
  UnionModifier = 'modifier-union',
  IntersectModifier = 'modifier-intersect',
  CutModifier = 'modifier-cut',
  GroupModifier = 'modifier-group',
  FilletModifier = 'modifier-fillet',
  ArrayModifier = 'modifier-array',
  WeldModifier = 'modifier-weld',
  PipeModifier = 'modifier-pipe',
  SculptModifier = 'modifier-sculpt',
  OffsetModifier = 'modifier-offset',
  HollowModifier = 'modifier-hollow',
  MirrorModifier = 'modifier-mirror',
  RevolveModifier = 'modifier-revolve',
  LoftModifier = 'modifier-loft'
}

export const InputObjectParamTitles: {[key: string]: string[]} = {
  [ComponentTypes.PipeModifier]: [ParamTitles.UCurve, ParamTitles.VCurve],
  [ComponentTypes.ArrayModifier]: [ParamTitles.Object],
  [ComponentTypes.CutModifier]: [ParamTitles.Object],
  [ComponentTypes.ExtrudeModifier]: [ParamTitles.Object],
  [ComponentTypes.FilletModifier]: [ParamTitles.Object],
  [ComponentTypes.GroupModifier]: [ParamTitles.Object],
  [ComponentTypes.HollowModifier]: [ParamTitles.Object],
  [ComponentTypes.IntersectModifier]: [ParamTitles.Object],
  [ComponentTypes.LoftModifier]: [ParamTitles.Object],
  [ComponentTypes.MirrorModifier]: [ParamTitles.Object],
  [ComponentTypes.OffsetModifier]: [ParamTitles.Object],
  [ComponentTypes.RevolveModifier]: [ParamTitles.Object],
  [ComponentTypes.SculptModifier]: [ParamTitles.Object],
  [ComponentTypes.UnionModifier]: [ParamTitles.Object],
  [ComponentTypes.WeldModifier]: [ParamTitles.Object],
  [ComponentTypes.BrepParameter]: [ParamTitles.Object],
  [ComponentTypes.CurveParameter]: [ParamTitles.Object],
  [ComponentTypes.MeshParameter]: [ParamTitles.Object],
  [ComponentTypes.VertexParameter]: [ParamTitles.Object],
  [ComponentTypes.SurfaceGenerator]: [ParamTitles.Object],
}

export const OutputObjectParamTitle: {[key: string]: string} = {
  [ComponentTypes.ConeGenerator]: ParamTitles.Cone,
  [ComponentTypes.CubeGenerator]: ParamTitles.Cube,
  [ComponentTypes.CurveGenerator]: ParamTitles.Curve,
  [ComponentTypes.CylinderGenerator]: ParamTitles.Cylinder,
  [ComponentTypes.DonutGenerator]: ParamTitles.Donut,
  [ComponentTypes.PolylineGenerator]: ParamTitles.Polyline,
  [ComponentTypes.PyramidGenerator]: ParamTitles.Pyramid,
  [ComponentTypes.SphereGenerator]: ParamTitles.Sphere,
  [ComponentTypes.SurfaceGenerator]: ParamTitles.Surface,
  [ComponentTypes.TubeGenerator]: ParamTitles.Tube,
  [ComponentTypes.ArrayModifier]: ParamTitles.Array,
  [ComponentTypes.CutModifier]: ParamTitles.Cut,
  [ComponentTypes.ExtrudeModifier]: ParamTitles.Extrude,
  [ComponentTypes.FilletModifier]: ParamTitles.Fillet,
  [ComponentTypes.GroupModifier]: ParamTitles.Group,
  [ComponentTypes.HollowModifier]: ParamTitles.Hollow,
  [ComponentTypes.IntersectModifier]: ParamTitles.Intersect,
  [ComponentTypes.LoftModifier]: ParamTitles.Loft,
  [ComponentTypes.MirrorModifier]: ParamTitles.Out,
  [ComponentTypes.OffsetModifier]: ParamTitles.Offset,
  [ComponentTypes.PipeModifier]: ParamTitles.Pipe,
  [ComponentTypes.RevolveModifier]: ParamTitles.Revolve,
  [ComponentTypes.SculptModifier]: ParamTitles.Mesh,
  [ComponentTypes.UnionModifier]: ParamTitles.Union,
  [ComponentTypes.WeldModifier]: ParamTitles.Weld,
  [ComponentTypes.BrepParameter]: ParamTitles.Brep,
  [ComponentTypes.CurveParameter]: ParamTitles.Curve,
  [ComponentTypes.MeshParameter]: ParamTitles.Mesh,
  [ComponentTypes.VertexParameter]: ParamTitles.Vertex,
  [ComponentTypes.ThreeConeGenerator]: ParamTitles.Cone,
  [ComponentTypes.ThreeCubeGenerator]: ParamTitles.Cube,
  [ComponentTypes.ThreeCylinderGenerator]: ParamTitles.Cylinder,
  [ComponentTypes.ThreeDonutGenerator]: ParamTitles.Donut,
  [ComponentTypes.ThreePlaneGenerator]: ParamTitles.Plane,
  [ComponentTypes.ThreePyramidGenerator]: ParamTitles.Pyramid,
  [ComponentTypes.ThreeSphereGenerator]: ParamTitles.Sphere,
  [ComponentTypes.ThreeTextGenerator]: ParamTitles.Text,
  [ComponentTypes.ThreeTubeGenerator]: ParamTitles.Tube,
}

export const InternalComponentTypes: string[] = [ComponentTypes.WeldModifier];
export const ImmutableComponentTypes: string[] = [ComponentTypes.WeldModifier, ComponentTypes.SculptModifier];
export const NonMaterialComponentTypes: string[] = [ComponentTypes.GroupModifier, ComponentTypes.ArrayModifier, ComponentTypes.OffsetModifier, ComponentTypes.HollowModifier, ComponentTypes.ExtrudeModifier, ComponentTypes.FilletModifier];
export const BreakableComponentTypes: string[] = [ComponentTypes.CutModifier, ComponentTypes.UnionModifier, ComponentTypes.IntersectModifier, ComponentTypes.GroupModifier, ComponentTypes.ArrayModifier, ComponentTypes.SurfaceGenerator, ComponentTypes.PipeModifier, ComponentTypes.ExtrudeModifier, ComponentTypes.OffsetModifier, ComponentTypes.MirrorModifier, ComponentTypes.FilletModifier, ComponentTypes.HollowModifier, ComponentTypes.LoftModifier, ComponentTypes.RevolveModifier];
export const LoopBackComponentTypes: string[] = [ComponentTypes.WeldModifier];
export const CombinedAtomComponentTypes: string[] = [ComponentTypes.SculptModifier, ComponentTypes.MeshParameter, ComponentTypes.BrepParameter];
