import {Calc} from "../../calc";
import {createObjectFromBrepData, createObjectFromMeshData, getRegisteredId, registerGeometry} from "../../parameter";
import {Gumball} from "../gumball";
import {WompMesh, WompObjectRef} from "../../../WompObject";
import {ObjectTypes} from "../../xobject";
import {mat4} from "gl-matrix";
import {BasicObjectGenerator, ObjectFuncParameter, PredictedResult} from "../base";

export class BasicMeshGenerator extends BasicObjectGenerator {
  static getParameters(calc: Calc) {
    return {
      desc: this.getDesc(calc),
      matrix: this.generateMatrix(),
      ...this.getParameter(calc)
    }
  }

  static predictResult(calc: Calc) {
    return PredictedResult.Mesh;
  }

  static async mapObject(calc: Calc, parameters: ObjectFuncParameter) {
    let {desc, matrix, ...restParameters} = parameters;
    let mesh;

    if ((this as any).generateMeshAsync)
      mesh = await (this as any).generateMeshAsync(restParameters);
    else
      mesh = this.generateMesh(restParameters);

    return createObjectFromMeshData(calc, mesh.geometry, desc, matrix);
  }

  static getDesc(calc: Calc) {
    return '';
  }

  static generateMatrix() {
    return mat4.create();
  }

  static generateMesh(parameter: any) {
    return new WompMesh();
  }

  static getParameter(calc: Calc): any {
    return undefined;
  }
}