import {IChatSession, NormalizedObjects} from "../types";
import {InitStoreAction} from "../init";

export const REPLACE_CHAT_SESSIONS = 'REPLACE_CHAT_SESSIONS';
export const ADD_CHAT_SESSIONS = 'ADD_CHAT_SESSIONS';
export const REMOVE_CHAT_SESSION = 'REMOVE_CHAT_SESSION';
export const ADD_CHAT_SESSION_TYPING_USERNAME = 'ADD_CHAT_SESSION_TYPING_USERNAME';

export interface IEntityChatSessionsState extends NormalizedObjects<IChatSession> {
}

interface ReplaceChatSessionsAction {
  type: typeof REPLACE_CHAT_SESSIONS
  payload: {
    chatSessions: IChatSession[]
  }
}

interface AddChatSessionsAction {
  type: typeof ADD_CHAT_SESSIONS
  payload: {
    chatSessions: IChatSession[]
  }
}

interface RemoveChatSessionAction {
  type: typeof REMOVE_CHAT_SESSION
  payload: {
    id: number
  }
}

interface AddChatSessionTypingUsernameAction {
  type: typeof ADD_CHAT_SESSION_TYPING_USERNAME
  payload: {
    id: number
    username: string
    typing: boolean
  }
}

export type ChatSessionsActionTypes = ReplaceChatSessionsAction |
  AddChatSessionsAction |
  RemoveChatSessionAction |
  AddChatSessionTypingUsernameAction |
  InitStoreAction