import lod from "lodash";
import {XObject} from "../xobject";
import {ICalcConfigDiff, ICalcSetting, IParameterProperty} from "../types";
import {Relation} from "../relation";
import {getObjectType, Parameter, ParamTypes} from "./parameter";
import {Param_Compound} from "./param-compound";
import {_b, _c, _s} from "../../t";
import {peregrineId} from "../../id";

export enum BooleanInterfaceTypes {
  Button = 'button',
  Toggle = 'toggle'
}

export class Param_Boolean extends Parameter {
  protected _objectType = ParamTypes.Boolean;
  protected _defaultValue: boolean = false;

  protected _interfaceType: string = '';
  // Runtime Data
  protected _values: boolean[] = [];

  get values() {
    return this._values;
  }

  set values(values: boolean[]) {
    if (!lod.isEqual(this._values, values)) {
      this._values = values;
      this._stateValid = false;
      this._dataValid = false;
    }
  }

  get defaultValue() {
    return this._defaultValue;
  }

  set defaultValue(defaultValue: boolean) {
    if (this._defaultValue !== defaultValue) {
      this._defaultValue = defaultValue;
      this._data = {
        ...this._data,
        [_s('defaultValue')]: _b(defaultValue)
      };
    }
  }

  get length() {
    return this._values.length;
  }

  static create(calc: XObject, title: string, isInput: boolean, isOperable: boolean,
                options?: { interfaceType?: string, defaultValue?: boolean }) {
    let param = new Param_Boolean();
    param._id = peregrineId();
    param._calc = calc;
    param._title = title;
    param._isInput = isInput;
    param._isOperable = isOperable;

    if (options === undefined) options = {};

    param._interfaceType = options.interfaceType === undefined ? BooleanInterfaceTypes.Button : options.interfaceType;
    param.defaultValue = options.defaultValue === undefined ? false : options.defaultValue;

    param.values = [param.defaultValue];

    param.setupInitialData();
    return param;
  }

  generateProperties(): IParameterProperty[] {
    let properties: IParameterProperty[] = [];

    for (let i = 0; i < this._values.length; ++i) {
      let value = this._values[i];

      properties.push({
        hash: value ? "1" : "0"
      });
    }

    return properties;
  }

  generateState() {
    let settings: ICalcSetting[] = [];

    for (let i = 0; i < this._values.length; ++i) {
      settings.push({
        id: this._id + ':' + i,
        label: this._title,
        type: 'boolean',
        value: this._values[i],
        interfaceType: this._interfaceType
      });
    }
    return settings;
  }

  protected validateGeneratedData() {
    this.dataValues = this._values.map(_b);
    this._dataValid = true;
  }

  overwrite(jData: any, replaceData: boolean) {
    if (!this.dataValid)
      this.validateGeneratedData();

    super.overwrite(jData, replaceData);

    if (this._data[_s('values')] !== jData[_s('values')] && jData[_s('values')] !== undefined) {
      this.values = jData[_s('values')].map(_c);
    }

    if (this._data[_s('defaultValue')] !== jData[_s('defaultValue')] && jData[_s('defaultValue')] !== undefined) {
      this.defaultValue = _c(jData[_s('defaultValue')]);
    }

    if (replaceData) {
      this._data = jData;
      this._dataValid = true;
    }
  }

  solve(diff?: ICalcConfigDiff) {
    if (diff && diff.obj) {
      this._values = [];

      for (let relation of this._prevRelations) {
        let prev = (relation as unknown as Relation).from;
        if (prev.objectType === ParamTypes.Compound) {
          let prevComp = prev as Param_Compound;
          this._values = prevComp.filterValues(getObjectType(this._objectType));
        } else {
          if (prev.objectType === this._objectType) {
            let prevSame = prev as Param_Boolean;
            this._values = [...this._values, ...prevSame._values];
          } else {
            console.warn('Type mismatch. Please implement type conversion');
          }
        }
      }

      if (this._prevRelations.length === 0) {
        this._values = [this._defaultValue];
      }
      this._dataValid = false;
    }
  }
}