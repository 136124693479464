import {
  CHANGE_CONFIGURATION,
  CHANGE_PEREGRINE_ENGINE_LOADED,
  GlobalActionTypes,
  IEntityGlobalState, CHANGE_WAITER
} from '../../types/entity/global';

const initialState: IEntityGlobalState = {
  engineLoaded: false
};

export function entityGlobalReducer(
  state = initialState,
  action: GlobalActionTypes
): IEntityGlobalState {
  switch (action.type) {
    case CHANGE_PEREGRINE_ENGINE_LOADED:
      return {
        ...state,
        engineLoaded: action.payload.loaded
      };
    case CHANGE_CONFIGURATION:
      return {
        ...state,
        configuration: action.payload.configuration
      };
    case CHANGE_WAITER:
      return {
        ...state,
        waiter: action.payload.waiter
      };
    default:
      return state;
  }
}