import Enums from '../../misc/Enums';
import Brush from './Brush';
import Inflate from './Inflate';
import Twist from './Twist';
import Smooth from './Smooth';
import Flatten from './Flatten';
import Pinch from './Pinch';
import Crease from './Crease';
import Drag from './Drag';
import Move from './Move';
import Masking from './Masking';
import LocalScale from './LocalScale';

var SculptTools = [];

SculptTools[Enums.SculptTools.BRUSH] = Brush;
SculptTools[Enums.SculptTools.INFLATE] = Inflate;
SculptTools[Enums.SculptTools.TWIST] = Twist;
SculptTools[Enums.SculptTools.SMOOTH] = Smooth;
SculptTools[Enums.SculptTools.FLATTEN] = Flatten;
SculptTools[Enums.SculptTools.PINCH] = Pinch;
SculptTools[Enums.SculptTools.CREASE] = Crease;
SculptTools[Enums.SculptTools.DRAG] = Drag;
SculptTools[Enums.SculptTools.MOVE] = Move;
SculptTools[Enums.SculptTools.MASKING] = Masking;
SculptTools[Enums.SculptTools.LOCALSCALE] = LocalScale;
export default SculptTools;
