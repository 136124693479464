
var RenderData = function () {
  return {
    _vertexBuffer: null,
    _normalBuffer: null,
    _maskingBuffer: null,
    _indexBuffer: null
  };
};

export default RenderData;
