import {
  AuthActionTypes,
  CHANGE_AUTH_USER_AVATAR,
  CHANGE_AUTH_USERNAME,
  IEntityAuthState,
  SET_AUTH_DATA
} from '../../types/entity/auth';
import {_setAuthHeader} from '../../../api/auth';
import {INIT_STORE} from "../../types/init";

const initialState: IEntityAuthState = {
  appAdmin: false,
  verified: false
};

export function entityAuthReducer(
  state = initialState,
  action: AuthActionTypes
): IEntityAuthState {
  switch (action.type) {
    case INIT_STORE:
      _setAuthHeader(undefined);
      localStorage.removeItem('token');

      return initialState;
    case SET_AUTH_DATA:
      _setAuthHeader(action.payload.token);
      if (action.payload.token === undefined)
        localStorage.removeItem('token');
      else
        localStorage.setItem('token', action.payload.token);

      return {
        username: action.payload.username,
        userAvatar: action.payload.userAvatar,
        userPoints: action.payload.userPoints,
        token: action.payload.token,
        appAdmin: action.payload.appAdmin !== undefined ? action.payload.appAdmin : state.appAdmin,
        verified: action.payload.verified !== undefined ? action.payload.verified : state.verified,
      };
    case CHANGE_AUTH_USERNAME:
      return {
        ...state,
        username: action.payload.username
      };
    case CHANGE_AUTH_USER_AVATAR:
      return {
        ...state,
        userAvatar: action.payload.userAvatar
      };
    default:
      return state;
  }
}