import {
  CHANGE_CONFIRM_DIALOG_OPENED,
  ADD_SIGN_IN_DIALOG_ON_AFTER_CLOSE,
  CHANGE_SIGN_IN_DIALOG_OPENED,
  DialogActionTypes
} from "../../types/ui/dialog";
import {IGAR} from "../../types/types";

export function changeConfirmDialogOpened(opened: boolean, text?: string, onAfterClose?: (confirmed: boolean) => void): DialogActionTypes {
  return {
    type: CHANGE_CONFIRM_DIALOG_OPENED,
    payload: {
      opened,
      text,
      onAfterClose
    }
  };
}

export function changeSignInDialogOpened(opened: boolean): DialogActionTypes {
  return {
    type: CHANGE_SIGN_IN_DIALOG_OPENED,
    payload: {
      opened
    }
  };
}

export function addSignInDialogOnAfterClose(onAfterClose: (result: IGAR) => void): DialogActionTypes {
  return {
    type: ADD_SIGN_IN_DIALOG_ON_AFTER_CLOSE,
    payload: {
      onAfterClose
    }
  };
}