import {ILibraryItem, NormalizedStringIndexedObjects} from "../types";
import {InitStoreAction} from "../init";

export const REPLACE_LIBRARY_ITEMS = 'REPLACE_LIBRARY_ITEMS';
export const ADD_LIBRARY_ITEMS = 'ADD_LIBRARY_ITEMS';
export const REMOVE_LIBRARY_ITEMS = 'REMOVE_LIBRARY_ITEMS';

export interface IEntityLibraryItemsState extends NormalizedStringIndexedObjects<ILibraryItem> {
}

interface ReplaceLibraryItemsAction {
  type: typeof REPLACE_LIBRARY_ITEMS
  payload: {
    items: ILibraryItem[]
  }
}

interface AddLibraryItemsAction {
  type: typeof ADD_LIBRARY_ITEMS
  payload: {
    items: ILibraryItem[]
  }
}

interface RemoveLibraryItemsAction {
  type: typeof REMOVE_LIBRARY_ITEMS
  payload: {
    ids: string[]
  }
}

export type LibraryItemsActionTypes = ReplaceLibraryItemsAction |
  AddLibraryItemsAction |
  RemoveLibraryItemsAction |
  InitStoreAction