import * as React from 'react';
import iconToTop from '../../assets/images/icon/to-top.svg';
import iconToBottom from '../../assets/images/icon/to-bottom.svg';
import iconToLeft from '../../assets/images/icon/to-left.svg';
import iconToRight from '../../assets/images/icon/to-right.svg';
import Tooltip from '../component/common/Tooltip';
import Editor3dDelegate from "./Editor3dDelegate";
import Editor3dWrapper from "./Editor3dWrapper";
import lod from 'lodash';

export interface IEditor3dDelegateWrapperProps {
  editorWrapper: Editor3dWrapper
  elemId: string
  cubeElemId?: string
  minorElemId?: string
  overlay?: boolean
  className?: string
  overlayClassName?: string
  bottom?: number
  offsetX?: number
  offsetY?: number

  children?: any
}

interface IEditor3dDelegateWrapperStates {
  left: string
  right: string
  top: string
  bottom: string
}

export default class Editor3dDelegateWrapper extends React.PureComponent<IEditor3dDelegateWrapperProps, IEditor3dDelegateWrapperStates> {
  delegate?: Editor3dDelegate;
  containerElement: HTMLElement | null = null;

  state: IEditor3dDelegateWrapperStates = {
    left: 'left view',
    right: 'right view',
    top: 'top view',
    bottom: 'bottom view'
  };

  componentDidMount() {
    let {editorWrapper, elemId, cubeElemId, minorElemId, overlay} = this.props;
    if (editorWrapper.editor)
      this.delegate = new Editor3dDelegate(editorWrapper.editor, elemId, cubeElemId, minorElemId, overlay);
  }

  componentWillUnmount() {
    this.delegate && this.delegate.dispose();
    this.delegate = undefined;
  }

  onArrowMouseDown = (evt: any) => {
    evt.stopPropagation();
  };

  refreshCubeTooltips = () => {
    if (this.delegate && this.delegate.editor3d) {
      let directions = this.delegate.editor3d.getCubeDirections();
      this.setState(lod.mapValues(directions, v => v + ' view') as any);
    }
  };

  onArrowMouseEnter = (evt: any) => {
    this.refreshCubeTooltips();
  };

  onLeftArrowClick = (evt: any) => {
    evt.stopPropagation();
    if (this.delegate && this.delegate.editor3d) {
      this.delegate.editor3d.changeViewPoint('left');
      setTimeout(this.refreshCubeTooltips, 200);
    }
  };

  onRightArrowClick = (evt: any) => {
    evt.stopPropagation();
    if (this.delegate && this.delegate.editor3d) {
      this.delegate.editor3d.changeViewPoint('right');
      setTimeout(this.refreshCubeTooltips, 200);
    }
  };

  onTopArrowClick = (evt: any) => {
    evt.stopPropagation();
    if (this.delegate && this.delegate.editor3d) {
      this.delegate.editor3d.changeViewPoint('top');
      setTimeout(this.refreshCubeTooltips, 200);
    }
  };

  onBottomArrowClick = (evt: any) => {
    evt.stopPropagation();
    if (this.delegate && this.delegate.editor3d) {
      this.delegate.editor3d.changeViewPoint('bottom');
      setTimeout(this.refreshCubeTooltips, 200);
    }
  };

  onContainerElementClick = () => {
    if (this.containerElement !== null)
      this.containerElement.focus();
  };

  onContainerElementMouseEnter = () => {
    if (this.delegate && this.delegate.editor3d) {
      this.delegate && this.delegate.editor3d.onEnterView();
    }
  }

  render() {
    const {cubeElemId, className, overlayClassName, elemId, offsetX, offsetY, children, bottom: bottomOffset} = this.props;
    const {top, left, right, bottom} = this.state;

    let offsetStyle = {
      width: offsetX ? `calc(100% + ${Math.abs(offsetX) * 2}px)` : '100%',
      height: offsetY ? `calc(100% + ${Math.abs(offsetY) * 2}px)` : '100%',
      left: `${(offsetX || 0) >= 0 ? 0 : (offsetX || 0) * 2}px`,
      top: `${(offsetY || 0) >= 0 ? 0 : (offsetY || 0) * 2}px`,
    };

    return (
      <React.Fragment>
        {cubeElemId &&
        <div
          className="fixed z-1"
          style={{display: (bottomOffset || 95) < 95 ? "none" : "block"}}
        >
          <div id={cubeElemId}/>
          <Tooltip
            text={<span>{top}</span>}
            arrow
          >
            <button
              type="button"
              className="editor-cube-top-arrow"
              onMouseDown={this.onArrowMouseDown}
              onClick={this.onTopArrowClick}
              onMouseEnter={this.onArrowMouseEnter}
            >
              <img src={iconToTop} alt=""/>
            </button>
          </Tooltip>
          <Tooltip
            text={<span>{bottom}</span>}
            arrow
          >
            <button
              type="button"
              className="editor-cube-bottom-arrow"
              onMouseDown={this.onArrowMouseDown}
              onClick={this.onBottomArrowClick}
              onMouseEnter={this.onArrowMouseEnter}
            >
              <img src={iconToBottom} alt=""/>
            </button>
          </Tooltip>
          <Tooltip
            text={<span>{left}</span>}
            arrow
          >
            <button
              type="button"
              className="editor-cube-left-arrow"
              onMouseDown={this.onArrowMouseDown}
              onClick={this.onLeftArrowClick}
              onMouseEnter={this.onArrowMouseEnter}
            >
              <img src={iconToLeft} alt=""/>
            </button>
          </Tooltip>
          <Tooltip
            text={<span>{right}</span>}
            arrow
          >
            <button
              type="button"
              className="editor-cube-right-arrow"
              onMouseDown={this.onArrowMouseDown}
              onClick={this.onRightArrowClick}
              onMouseEnter={this.onArrowMouseEnter}
            >
              <img src={iconToRight} alt=""/>
            </button>
          </Tooltip>
        </div>
        }
        <div
          className={(className || "") + " relative overflow-hidden"}
          onClick={this.onContainerElementClick}
          onMouseEnter={this.onContainerElementMouseEnter}
        >
          <div
            id={elemId}
            tabIndex={0}
            className="absolute"
            style={offsetStyle}
            ref={ref => this.containerElement = ref}
          />
          {children &&
            <div className={overlayClassName} style={offsetStyle}>
              {children}
            </div>
          }
        </div>
      </React.Fragment>
    );
  }
}