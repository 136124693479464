import {ComponentTypes} from "../types";
import {pigeon as pigeonTypes} from "../../../types";
import {createGumball} from "../gumball";
import {Param_Brep, ParamTitles} from "../../parameter";
import {Calc} from "../../calc";
import {mat4, vec3} from "gl-matrix";
import {ThreePyramidGenerator} from "../three-basic";
import {BasicBrepGenerator} from "./basic-gen";

export class PyramidGenerator extends BasicBrepGenerator {
  static defaultParameter = {
    width: 20,
    height: 20,
    depth: 20
  };
  static draggingPreview = ThreePyramidGenerator.generateMesh();

  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.PyramidGenerator;
    calc.title = 'pyramid';

    let pyramidParam = Param_Brep.create(calc, ParamTitles.Pyramid, false, false, true);

    calc.addParameter(pyramidParam);

    return calc;
  }

  static getDesc(calc: Calc) {
    return 'b-pyramid';
  }

  static async generateBrepGeometry(parameter: any = this.defaultParameter) {
    return pigeonTypes.pyramid(
      this.defaultParameter.width,
      this.defaultParameter.depth,
      this.defaultParameter.height
    );
  }

  static generateMatrix(parameter: any = this.defaultParameter) {
    return mat4.fromScaling(
      mat4.create(),
      vec3.fromValues(
        parameter.width / this.defaultParameter.width,
        parameter.depth / this.defaultParameter.depth,
        parameter.height / this.defaultParameter.height
      )
    );
  }
}
