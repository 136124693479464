import {
  BufferAttribute,
  Color,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  OctahedronBufferGeometry,
  Vector3
} from 'three';
import {NLightHelperPicker} from './NLightHelperPicker';

var _vector = new Vector3();
var _color1 = new Color();
var _color2 = new Color();

function NHemisphereLightHelper(light, size, lightId, camera, hoverStartEvent, hoverStopEvent, updateCallback) {

  Object3D.call(this);

  this.light = light;
  this.light.updateMatrixWorld();

  this.lightId = lightId;
  this.updateCallback = updateCallback;

  this.matrix = light.matrixWorld;
  this.matrixAutoUpdate = false;

  var geometry = new OctahedronBufferGeometry(size);
  geometry.rotateY(Math.PI * 0.5);

  this.material = new MeshBasicMaterial({wireframe: true, fog: false});
  if (this.color === undefined) this.material.vertexColors = true;

  var position = geometry.getAttribute('position');
  var colors = new Float32Array(position.count * 3);

  geometry.setAttribute('color', new BufferAttribute(colors, 3));

  this.add(new Mesh(geometry, this.material));

  this.positionPicker = new NLightHelperPicker(lightId + ':position', camera, hoverStartEvent, hoverStopEvent, this.onChangeLightPosition.bind(this));
  this.add(this.positionPicker);

  this.update();

}

NHemisphereLightHelper.prototype = Object.create(Object3D.prototype);
NHemisphereLightHelper.prototype.constructor = NHemisphereLightHelper;

NHemisphereLightHelper.prototype.dispose = function () {

  this.children[0].geometry.dispose();
  this.children[0].material.dispose();

  this.positionPicker.dispose();
};

NHemisphereLightHelper.prototype.setDelegate = function (delegate) {

  this.positionPicker.setDelegate(delegate);

};

NHemisphereLightHelper.prototype.unsetDelegate = function () {

  this.positionPicker.unsetDelegate();

};

NHemisphereLightHelper.prototype.setCamera = function (camera) {

  this.positionPicker.camera = camera;

};

NHemisphereLightHelper.prototype.onChangeLightPosition = function (x, y, z) {
  this.updateCallback(this.lightId, {
    position: [
      x + this.light.position.x,
      y + this.light.position.y,
      z + this.light.position.z
    ]
  });
};

NHemisphereLightHelper.prototype.update = function () {

  this.light.updateMatrixWorld();

  this.matrix = this.light.matrixWorld;
  this.matrixAutoUpdate = false;

  var mesh = this.children[0];

  var colors = mesh.geometry.getAttribute('color');

  _color1.copy(this.light.color);
  _color2.copy(this.light.groundColor);

  for (var i = 0, l = colors.count; i < l; i++) {

    var color = (i < (l / 2)) ? _color1 : _color2;

    colors.setXYZ(i, color.r, color.g, color.b);

  }

  colors.needsUpdate = true;

  mesh.lookAt(_vector.setFromMatrixPosition(this.light.matrixWorld).negate());

  this.positionPicker.sprite.matrixAutoUpdate = true;
  new Matrix4().decompose(this.positionPicker.sprite.position, this.positionPicker.sprite.quaternion, this.positionPicker.sprite.scale);
};


export {NHemisphereLightHelper};