import lod from "lodash";
import {pigeon as pigeonTypes} from "../../types";
import {decodeModelProperty, encodeModelProperty, ICalcConfigDiff, IModelProperty, IParameterProperty} from "../types";
import {decodeWompObjectRef, encodeWompObjectRef, WompObjectRef} from "../../WompObject";
import {XObject} from "../xobject";
import {Relation} from "../relation";
import {
  getBrepFromRef,
  getDescriptionFromObjectId,
  getObjectType,
  getRegisteredId,
  Parameter,
  ParamTypes,
  registerGeometry
} from "./parameter";
import {Param_Compound} from "./param-compound";
import {_s} from "../../t";
import {peregrineId} from "../../id";

export class Param_Brep extends Parameter {
  protected _objectType = ParamTypes.Brep;
  protected _defaultValue: undefined;

  protected _stateValid = true;
  // Runtime Data
  protected _values: WompObjectRef[] = [];

  get values() {
    return this._values;
  }

  set values(values: any[]) {
    if (!lod.isEqual(this._values, values)) {
      this._values = values;
      this._dataValid = false;
    }
  }

  protected _properties: IModelProperty[] = [];

  get properties() {
    return this._properties;
  }

  set properties(properties: IModelProperty[]) {
    if (!lod.isEqual(this._properties, properties)) {
      this._properties = properties;
      this._dataValid = false;
    }
  }

  protected _caches: [WompObjectRef, WompObjectRef][] = [];

  get caches() {
    return this._caches;
  }

  set caches(caches: [WompObjectRef, WompObjectRef][]) {
    if (!lod.isEqual(this._caches, caches)) {
      this._caches = caches;
      this._dataValid = false;
    }
  }

  get length() {
    return Math.min(this._values.length, this._caches.length, this._properties.length);
  }

  static create(calc: XObject, title: string, isInput: boolean, isOperable: boolean, render: boolean) {
    let param = new Param_Brep();
    param._id = peregrineId();
    param._calc = calc;
    param._title = title;
    param._isInput = isInput;
    param._isOperable = isOperable;
    param._render = render;

    param.setupInitialData();
    return param;
  }

  generateProperties(): IParameterProperty[] {
    let properties: IParameterProperty[] = [];

    for (let i = 0; i < this._values.length; ++i) {
      let property = this._properties.length > i ? this._properties[i] : undefined;

      if (property) {
        properties.push({
          hash: property.hash,
          voided: property.voided,
          material: property.material
        });
      } else {
        properties.push({
          hash: ""
        });
      }
    }

    return properties;
  }

  protected validateGeneratedData() {
    this.dataValues = this._values.map(encodeWompObjectRef);
    this.dataCaches = this._caches.map(c => [encodeWompObjectRef(c[0]), encodeWompObjectRef(c[1])]);
    this.dataProperties = this._properties.map(encodeModelProperty);
    this._dataValid = true;
  }

  overwrite(jData: any, replaceData: boolean) {
    if (!this.dataValid)
      this.validateGeneratedData();

    super.overwrite(jData, replaceData);

    if (this._data[_s('values')] !== jData[_s('values')] && jData[_s('values')] !== undefined) {
      this.values = jData[_s('values')].map(decodeWompObjectRef);
    }

    if (this._data[_s('caches')] !== jData[_s('caches')] && jData[_s('caches')] !== undefined) {
      this.caches = jData[_s('caches')].map((c: any) => [decodeWompObjectRef(c[0]), decodeWompObjectRef(c[1])]);
    }

    if (this._data[_s('properties')] !== jData[_s('properties')] && jData[_s('properties')] !== undefined) {
      this.properties = jData[_s('properties')].map(decodeModelProperty);
    }

    if (replaceData) {
      this._data = jData;
      this._dataValid = true;
    }
  }

  async generateCaches() {
    if (this._caches.length > 0)
      return;
    for (let i = 0; i < this._values.length; ++i) {
      let value = this._values[i];
      let brep = getBrepFromRef(this._calc, value);
      let brepDesc = getDescriptionFromObjectId(this._calc, value.objectId);

      let meshId = getRegisteredId(this._calc, brepDesc+'-mesh');
      let edgeId = getRegisteredId(this._calc, brepDesc+'-edge');

      if (!meshId || !edgeId) {
        let [meshData, edgeData] = await pigeonTypes.tessellate(brep.geometry);

        meshId = registerGeometry(this._calc, meshData, brepDesc + '-mesh');
        edgeId = registerGeometry(this._calc, edgeData, brepDesc + '-edge');
      }

      this._caches.push([new WompObjectRef(meshId, value.matrix), new WompObjectRef(edgeId, value.matrix)]);
    }
  }

  solve(diff?: ICalcConfigDiff) {
    if (diff && diff.obj) {
      this._values = [];
      this._caches = [];

      for (let relation of this._prevRelations) {
        let prev = (relation as unknown as Relation).from;
        if (prev.objectType === ParamTypes.Compound) {
          let prevComp = prev as Param_Compound;
          this._values = prevComp.filterValues(getObjectType(this._objectType));
        } else {
          if (prev.objectType === this._objectType) {
            let prevSame = prev as Param_Brep;
            this._values = [...this._values, ...prevSame._values];
            this._caches = [...this._caches, ...prevSame._caches];
          } else {
            console.warn('Type mismatch. Please implement type conversion');
          }
        }
      }
      this._dataValid = false;
    }

    if (diff && (diff.obj || diff.prevVoided || diff.prevMaterial)) {
      this._properties = [];

      for (let relation of this._prevRelations) {
        let prev = (relation as unknown as Relation).from;
        if (prev.objectType === ParamTypes.Compound) {
          let prevComp = prev as Param_Compound;
          this._properties = prevComp.filterProperties(getObjectType(this._objectType));
        } else {
          if (prev.objectType === this._objectType) {
            let prevSame = prev as Param_Brep;
            this._properties = [...this._properties, ...prevSame._properties];
          } else {
            console.warn('Type mismatch. Please implement type conversion');
          }
        }
      }
      this._dataValid = false;
    }
  }
}