import {
  ApiSceneActionTypes, FETCH_ROUTE,
  GET_CALC_DETAIL_INFO,
  GET_CALC_HEATMAP, POST_CHANGE_CURSOR,
  POST_ROUTE
} from "../../types/api/scene";

export function getCalcHeatmapStarted(id: string, extraInfo?: any): ApiSceneActionTypes {
  return {
    type: GET_CALC_HEATMAP,
    payload: {
      fetching: true,
      id,
      extraInfo: extraInfo
    }
  };
}

export function getCalcHeatmapSuccess(id: string): ApiSceneActionTypes {
  return {
    type: GET_CALC_HEATMAP,
    payload: {
      fetching: false,
      success: true,
      id
    }
  };
}

export function getCalcHeatmapFailure(id: string, message: string): ApiSceneActionTypes {
  return {
    type: GET_CALC_HEATMAP,
    payload: {
      id,
      fetching: false,
      success: false,
      message: message
    }
  };
}

export function getCalcDetailInfoStarted(id: string, extraInfo?: any): ApiSceneActionTypes {
  return {
    type: GET_CALC_DETAIL_INFO,
    payload: {
      fetching: true,
      id,
      extraInfo: extraInfo
    }
  };
}

export function getCalcDetailInfoSuccess(id: string): ApiSceneActionTypes {
  return {
    type: GET_CALC_DETAIL_INFO,
    payload: {
      fetching: false,
      success: true,
      id
    }
  };
}

export function getCalcDetailInfoFailure(id: string, message: string): ApiSceneActionTypes {
  return {
    type: GET_CALC_DETAIL_INFO,
    payload: {
      id,
      fetching: false,
      success: false,
      message: message
    }
  };
}


export function postRouteStarted(id: number): ApiSceneActionTypes {
  return {
    type: POST_ROUTE,
    payload: {
      posting: true,
      id
    }
  };
}

export function postRouteSuccess(id: number): ApiSceneActionTypes {
  return {
    type: POST_ROUTE,
    payload: {
      posting: false,
      success: true,
      id
    }
  };
}

export function postRouteFailure(message: string): ApiSceneActionTypes {
  return {
    type: POST_ROUTE,
    payload: {
      posting: false,
      success: false,
      message: message
    }
  };
}

export function fetchRouteStarted(id: number): ApiSceneActionTypes {
  return {
    type: FETCH_ROUTE,
    payload: {
      fetching: true,
      id
    }
  };
}

export function fetchRouteSuccess(id: number): ApiSceneActionTypes {
  return {
    type: FETCH_ROUTE,
    payload: {
      fetching: false,
      success: true,
      id
    }
  };
}

export function fetchRouteFailure(message: string): ApiSceneActionTypes {
  return {
    type: FETCH_ROUTE,
    payload: {
      fetching: false,
      success: false,
      message: message
    }
  };
}

export function postChangeCursorStarted(id: number): ApiSceneActionTypes {
  return {
    type: POST_CHANGE_CURSOR,
    payload: {
      posting: true,
      id
    }
  };
}

export function postChangeCursorSuccess(id: number): ApiSceneActionTypes {
  return {
    type: POST_CHANGE_CURSOR,
    payload: {
      posting: false,
      success: true,
      id
    }
  };
}

export function postChangeCursorFailure(message: string): ApiSceneActionTypes {
  return {
    type: POST_CHANGE_CURSOR,
    payload: {
      posting: false,
      success: false,
      message: message
    }
  };
}