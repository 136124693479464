import {
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneBufferGeometry,
  Raycaster,
  Sprite,
  SpriteMaterial,
  Texture,
  Vector3,
  DoubleSide
} from "three";

function NLightHelperPicker(pickerId, camera, hoverStartEvent, hoverStopEvent, positionChangeCallback) {
  Object3D.call(this);

  let scope = this;
  let ray = new Raycaster();
  let eye = new Vector3();
  let dragStartPosition = new Vector3();
  let dragCurrentPosition = new Vector3();
  let originalMatrix = new Matrix4();
  let lastMaterialType = false;

  let unitZ = new Vector3(0, 0, 1);
  let tempMatrix = new Matrix4();
  let intersectPlane = new Mesh(new PlaneBufferGeometry(300000, 300000),
    new MeshBasicMaterial({visible: true, wireframe: false, side: DoubleSide, transparent: true, opacity: 0.1}));

  this.setDelegate = function (delegate) {

    this.unsetDelegate();

    delegate.domElement.addEventListener("mousedown", onPointerDown);
    delegate.domElement.addEventListener("touchstart", onPointerDown);

    delegate.domElement.addEventListener("mousemove", onPointerMove);
    delegate.domElement.addEventListener("touchmove", onPointerMove);

    delegate.domElement.addEventListener("mouseup", onPointerUp);
    delegate.domElement.addEventListener("touchend", onPointerUp);
    delegate.domElement.addEventListener("touchcancel", onPointerUp);
    delegate.domElement.addEventListener("touchleave", onPointerUp);

    this.domElement = delegate.domElement;

  };

  this.unsetDelegate = function () {

    if (this.domElement === document)
      return;

    this.domElement.removeEventListener('mousedown', onPointerDown);
    this.domElement.removeEventListener('touchstart', onPointerDown);

    this.domElement.removeEventListener('mousemove', onPointerMove);
    this.domElement.removeEventListener('touchmove', onPointerMove);

    this.domElement.removeEventListener('mouseup', onPointerUp);
    this.domElement.removeEventListener('touchend', onPointerUp);
    this.domElement.removeEventListener('touchcancel', onPointerUp);
    this.domElement.removeEventListener('touchleave', onPointerUp);

    this.domElement = document;

  };

  this.dispose = function () {
    this.sprite.geometry.dispose();
    this.sprite.material.dispose();

    intersectPlane.geometry.dispose();
    intersectPlane.material.dispose();
  };

  this.setSize = function (size) {
    this.spriteSize = size * 12.0;
  };

  this.updateMatrixWorld = function () {
    if (lastMaterialType !== (this.isDragging || this.isHover)) {
      this.sprite.material.dispose();
      this.sprite.material = createMaterial(this.isDragging || this.isHover);
      lastMaterialType = this.isDragging || this.isHover;
    }

    if (this.isDragging) {
      this.sprite.matrixAutoUpdate = false;
      this.sprite.matrix.copy(originalMatrix);
      let toLocal = this.matrixWorld.clone().invert();
      let current = dragCurrentPosition.clone().applyMatrix4(toLocal);
      let start = dragStartPosition.clone().applyMatrix4(toLocal);
      tempMatrix.makeTranslation(current.x - start.x,
        current.y - start.y,
        current.z - start.z);
      this.sprite.applyMatrix4(tempMatrix);
      // console.log('update', this.sprite.position)
      // this.sprite.updateMatrixWorld()
    }

    this.sprite.scale.set(this.spriteSize, this.spriteSize, this.spriteSize);

    eye = this.camera.getWorldDirection(new Vector3());
    Object3D.prototype.updateMatrixWorld.call(this);
  };

  function getPointer(event) {
    let pointer = event.changedTouches ? event.changedTouches[0] : event;
    let rect = scope.domElement.getBoundingClientRect();

    return {
      x: (pointer.clientX - rect.left) / rect.width * 2 - 1,
      y: -(pointer.clientY - rect.top) / rect.height * 2 + 1,
      button: event.button
    };
  }

  function doPointerDown(pointer) {
    if (pointer.button !== 0) return;

    ray.setFromCamera(pointer, scope.camera);
    if (!scope.parent.visible || ray.intersectObject(scope.sprite, true).length === 0) return;

    intersectPlane.position.copy(scope.sprite.getWorldPosition(new Vector3()));
    intersectPlane.quaternion.setFromUnitVectors(unitZ, eye);
    intersectPlane.updateMatrixWorld();

    let intersect = ray.intersectObject(intersectPlane, true)[0] || false;

    if (intersect) {
      scope.isDragging = true;
      hoverStartEvent(scope.uuid);
      dragStartPosition.copy(intersect.point);
      dragCurrentPosition.copy(dragStartPosition);
      originalMatrix.copy(scope.sprite.matrix);
    }
  }

  function doPointerMove(pointer) {
    ray.setFromCamera(pointer, scope.camera);
    if (!scope.isDragging) {
      if (ray.intersectObject(scope.sprite, true).length === 0) {
        scope.isHover = false;
        hoverStopEvent(scope.pickerId);
      } else {
        scope.isHover = true;
        hoverStartEvent(scope.pickerId);
      }
    } else {
      let intersect = ray.intersectObject(intersectPlane, true)[0] || false;
      if (intersect) {
        dragCurrentPosition.copy(intersect.point);
      }
    }
  }

  function doPointerUp(pointer) {
    if (scope.isDragging) {
      scope.isDragging = false;
      let offset = dragCurrentPosition.clone().addScaledVector(dragStartPosition, -1);
      positionChangeCallback(offset.x, offset.y, offset.z);
    }
  }

  function onPointerDown(event) {
    doPointerDown(getPointer(event));
  }

  function onPointerMove(event) {
    doPointerMove(getPointer(event));
  }

  function onPointerUp(event) {
    doPointerUp(getPointer(event));
  }

  function createMaterial(hover = false) {
    let canvas1 = document.createElement('canvas');
    canvas1.width = canvas1.height = 512;
    let cx = canvas1.width / 2;
    let cy = canvas1.height / 2;

    let context1 = canvas1.getContext('2d');

    context1.clearRect(0, 0, 512, 512);

    context1.beginPath();
    context1.arc(cx, cy, canvas1.width / 2 - 100, 0, 2 * Math.PI, false);
    context1.fillStyle = hover ? "rgb(255, 0, 0)" : "rgb(255, 255, 255)";
    context1.fill();
    context1.lineWidth = 100;
    context1.strokeStyle = 'rgb(0, 0, 0)';
    context1.stroke();

    let texture1 = new Texture(canvas1);
    texture1.needsUpdate = true;

    return new SpriteMaterial({map: texture1, depthTest: false});
  }

  this.pickerId = pickerId;
  this.camera = camera;
  this.domElement = document;
  this.sprite = new Sprite(createMaterial());
  this.spriteSize = 1.2;
  this.isDragging = false;
  this.isHover = false;
  this.add(this.sprite);
}

NLightHelperPicker.prototype = Object.create(Object3D.prototype);
NLightHelperPicker.prototype.constructor = NLightHelperPicker;

export {NLightHelperPicker};