import {
  CHANGE_GLOBAL_SEARCH_TEXT,
  CHANGE_SCREEN_SIZE,
  GlobalActionTypes,
  IGlobalState
} from "../../types/ui/global";
import {INIT_STORE} from "../../types/init";

const initialState: IGlobalState = {
  screenSize: {width: 0, height: 0},
  isMobile: true,
  searchText: ''
};

export function globalReducer(
  state = initialState,
  action: GlobalActionTypes
): IGlobalState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case CHANGE_SCREEN_SIZE:
      return {
        ...state,
        screenSize: action.payload.screenSize,
        isMobile: action.payload.screenSize.width <= 768
      };
    case CHANGE_GLOBAL_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload.searchText
      };
    default:
      return state;
  }
}