import {ComponentTypes} from "../types";
import {pigeon as pigeonTypes} from "../../../types";
import {createSimpleGumball, IRenderedObjectInternal} from "../gumball";
import {createObjectFromBrepData, getCurveFromRef, Param_Brep, Param_Curve, ParamTitles} from "../../parameter";
import {Calc} from "../../calc";
import {ObjectTypes} from "../../xobject";
import {getComposeDesc, isIdentity, WompObjectRef} from "../../../WompObject";
import {mat4} from "gl-matrix";
import {BasicObjectFunction, ObjectFuncParameter, PredictedResult} from "../base";

export class PipeModifier extends BasicObjectFunction {
  static create() {
    let calc = createSimpleGumball();
    calc.component = ComponentTypes.PipeModifier;
    calc.title = 'pipe';

    let uCurveParam = Param_Curve.create(calc, ParamTitles.UCurve, true, false, false);
    let vCurveParam = Param_Curve.create(calc, ParamTitles.VCurve, true, false, false);
    let pipeParam = Param_Brep.create(calc, ParamTitles.Pipe, false, false, true);

    calc.addParameter(uCurveParam);
    calc.addParameter(vCurveParam);
    calc.addParameter(pipeParam);

    return calc;
  }

  static getParameters(calc: Calc, objs: IRenderedObjectInternal[]): ObjectFuncParameter {
    let uCurve = objs[0].value;
    let vCurve = objs[1].value;

    let matrix = uCurve.matrix;

    if (!isIdentity(matrix)) {
      let invMatrix = mat4.invert(mat4.create(), matrix);

      if (invMatrix) {
        uCurve = new WompObjectRef(uCurve.objectId, mat4.multiply(mat4.create(), invMatrix, uCurve.matrix));
        vCurve = new WompObjectRef(vCurve.objectId, mat4.multiply(mat4.create(), invMatrix, vCurve.matrix));
      }
    }

    let desc = `pipe[${getComposeDesc(uCurve, 3)},${getComposeDesc(vCurve, 3)}]`;

    return {desc, matrix};
  }

  static predictResult(calc: Calc, objs: IRenderedObjectInternal[]) {
    if (objs.length === 2 && objs[0].valueType === ObjectTypes.Curve && objs[1].valueType === ObjectTypes.Curve) {
      return PredictedResult.Brep;
    }
    return PredictedResult.Ignore;
  }

  static async funcObject(calc: Calc, objs: IRenderedObjectInternal[], parameters: ObjectFuncParameter): Promise<IRenderedObjectInternal | undefined> {
    let uCurve = objs[0].value;
    let vCurve = objs[1].value;

    if (!isIdentity(parameters.matrix)) {
      let invMatrix = mat4.invert(mat4.create(), parameters.matrix);

      if (invMatrix) {
        uCurve = new WompObjectRef(uCurve.objectId, mat4.multiply(mat4.create(), invMatrix, uCurve.matrix));
        vCurve = new WompObjectRef(vCurve.objectId, mat4.multiply(mat4.create(), invMatrix, vCurve.matrix));
      }
    }

    let brepData = await pigeonTypes.pipe(getCurveFromRef(calc, uCurve), getCurveFromRef(calc, vCurve));

    return createObjectFromBrepData(calc, brepData, parameters.desc, parameters.matrix);
  }
}
