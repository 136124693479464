import * as React from 'react';
import {pure} from 'recompose';

interface IImageButtonProps extends React.HTMLProps<HTMLButtonElement> {
  image?: string
  hoverImage?: string
  svg?: any
  hoverSvg?: any
  hoverClassName?: string
  customdata?: string
}

const ImageButton: React.FC<IImageButtonProps> = ({className, image, hoverImage, children, svg, hoverSvg, ...props}) => {
  className = className || '';

  return (
    <button
      {...props}
      type="button"
      className={((hoverImage || hoverSvg) ? "has-hover " : "") + "image-button " + className}
    >
      {svg && React.cloneElement(svg, {className: "default"})}
      {hoverSvg && React.cloneElement(hoverSvg, {className: "hover"})}
      {image && <img className="default pointer-none" src={image} alt="button"/>}
      {hoverImage && <img className="hover pointer-none" src={hoverImage} alt="button"/>}
      {children}
    </button>
  );
};

export default pure(ImageButton);
