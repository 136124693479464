import useMousePosition from "./use-mouse-position";
import React, {useContext, useEffect, useState} from "react";
import cursorRed from "../../../../assets/images/cursor/red.svg";
import cursorBlue from "../../../../assets/images/cursor/red.svg";
import {CursorContext} from "./CursorContextProvider";
import isTouchDevice from "./is-touch-device";

export const Cursor: React.FC = () => {
  if (isTouchDevice) {
    return null;
  }

  const { clientX, clientY } = useMousePosition();
  const [cursor] = useContext(CursorContext);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleMouseEnter = () => setIsVisible(true);
    const handleMouseLeave = () => setIsVisible(false);
    document.body.addEventListener("mouseenter", handleMouseEnter);
    document.body.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.body.removeEventListener("mouseenter", handleMouseEnter);
      document.body.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, []);

  return (
    <div
      className="womp-cursor red-cursor"
      style={{
        left: clientX,
        top: clientY,
        // TODO: extra check on clientX needed here
        // because mouseleave event not always firing
        // when slowly exiting left side of browser
        opacity: cursor.active && isVisible && clientX > 1 ? 1 : 0,
      }}
    >
      <img src={cursorRed} alt='me'/>
      <div>
        <p>you</p>
      </div>
    </div>
  );
};