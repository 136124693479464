import {InitStoreAction} from "../init";

export const CHANGE_SCREEN_SIZE = 'CHANGE_SCREEN_SIZE';
export const CHANGE_GLOBAL_SEARCH_TEXT = 'CHANGE_GLOBAL_SEARCH_TEXT';
export const INIT_SCENE = 'INIT_SCENE';

export interface IScreenSize {
  width: number
  height: number
}

export interface IGlobalState {
  screenSize: IScreenSize
  isMobile: boolean
  searchText: string
}

interface ChangeScreenSizeAction {
  type: typeof CHANGE_SCREEN_SIZE
  payload: {
    screenSize: IScreenSize
  }
}

interface ChangeGlobalSearchTextAction {
  type: typeof CHANGE_GLOBAL_SEARCH_TEXT
  payload: {
    searchText: string
  }
}

export interface InitSceneAction {
  type: typeof INIT_SCENE
  payload: {
    id: number
  }
}

export type GlobalActionTypes = ChangeScreenSizeAction |
  ChangeGlobalSearchTextAction |
  InitSceneAction |
  InitStoreAction
