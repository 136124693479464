import {ComponentTypes} from "../types";
import {createSimpleGumball, IRenderedObjectInternal} from "../gumball";
import {
  createObjectFromBrepData,
  getBrepFromRef,
  Param_Compound,
  Param_Number,
  ParamTitles,
  ParamValueTypes
} from "../../parameter";
import {Calc} from "../../calc";
import {ObjectTypes} from "../../xobject";
import {mat4} from "gl-matrix";
import {DistantObjectMapper, ObjectFuncParameter} from "../base";
import {pigeon as pigeonTypes} from "../../../types";

export class FilletModifier extends DistantObjectMapper {
  static create() {
    let calc = createSimpleGumball();
    calc.component = ComponentTypes.FilletModifier;
    calc.title = 'fillet';

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let distanceParam = Param_Number.create(calc, ParamTitles.Distance, true, false, {type: ParamValueTypes.Offset});
    let filletParam = Param_Compound.create(calc, ParamTitles.Fillet, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(distanceParam);
    calc.addParameter(filletParam);
    return calc;
  }

  static getDesc() {
    return 'fillet';
  }

  static async mapObject(calc: Calc, obj: IRenderedObjectInternal, parameters: ObjectFuncParameter) {
    if (obj.valueType === ObjectTypes.Brep) {
      let brep = getBrepFromRef(calc, obj.value);
      mat4.copy(brep.matrix, parameters.appliedMatrix);
      let brepData = (await pigeonTypes.fillet([brep], parameters.distance))[0];

      return createObjectFromBrepData(calc, brepData, parameters.desc, parameters.matrix);
    }

    return obj;
  }
}