import {ComponentTypes} from "../types";
import {createSimpleGumball, Gumball, solveSimpleGumball} from "../gumball";
import {Calc} from "../../calc";
import {Param_Compound, ParamTitles} from "../../parameter";
import {ICalcConfigDiff} from "../../types";
import lod from "lodash";

export class GroupModifier {
  static create() {
    let calc = createSimpleGumball();
    calc.component = ComponentTypes.GroupModifier;
    calc.title = 'group';

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let groupParam = Param_Compound.create(calc, ParamTitles.Group, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(groupParam);
    return calc;
  }

  static async solve(calc: Calc, diff: ICalcConfigDiff) {
    let objectParam = calc.inputByTitle(ParamTitles.Object) as Param_Compound;

    let objects = [];

    for (let i = 0; i < objectParam.length; ++i) {
      let value = objectParam.values[i];
      let valueType = objectParam.valueTypes[i];
      let cache = objectParam.caches[i];
      let property = {...objectParam.properties[i]};

      if (calc.voided)
        property.voided = calc.voided;

      objects.push({value, valueType, cache, property});
    }

    return Gumball.solveGumball(calc, objects);
  }

  static solveProperty(calc: Calc, diff: ICalcConfigDiff) {
    let objectParam = calc.inputByTitle(ParamTitles.Object) as Param_Compound;
    let groupParam = calc.outputByTitle(ParamTitles.Group) as Param_Compound;

    if (!diff.obj) {
      if (diff.calcVoided || diff.prevVoided || diff.prevMaterial) {
        let properties = lod.cloneDeep(groupParam.properties);
        for (let i = 0; i < properties.length; ++i) {
          if (diff.calcVoided || diff.prevVoided) {
            let property = objectParam.properties[Math.min(i, objectParam.properties.length - 1)];
            properties[i].voided = property.voided;
            if (calc.voided)
              properties[i].voided = calc.voided;
          }

          if (diff.prevMaterial) {
            let property = objectParam.properties[Math.min(i, objectParam.properties.length - 1)];
            properties[i].material = property.material;
          }
        }
        groupParam.properties = properties;
      }
    }
  }
}