import {EventDispatcher, InstancedMesh, Matrix4} from "three";
import lod from "lodash";

function MeshInstancingManager(target) {

  this.target = target;
  this.childrenById = {};
  this.instancedMeshesByHash = {};
  this.nonInstantiatableObjectByHash = {};
  this.targetMatrixWorld = new Matrix4();
  this.needsUpdate = false;
  this.hadUpdate = false;
  this.preventValidate = false;

  this.target.userData.hasMeshInstancingManager = true;

}

function isIdentity(mat) {
  let offset = 0;

  for (let i = 0; i < 16; ++i) {
    offset += Math.abs((i % 5 === 0 ? 1 : 0) - mat.elements[i]);
  }

  return offset < 0.0001;
}

MeshInstancingManager.prototype = Object.assign(Object.create(EventDispatcher.prototype), {

  constructor: MeshInstancingManager,

  isMeshInstancingManager: true,

  dispose: function () {

    delete this.target.userData.hasMeshInstancingManager;

  },

  isInstantiatable: function (object) {

    if (object.userData.hasMeshInstancingManager)
      return false;

    if (object.isInstancedMesh)
      return false;

    if (object.isMesh || object.isLine || object.isLineSegments || object.isPoints)
      return object.userData && object.geometry.userData && object.geometry.userData.hash && object.material.userData && object.material.userData.hash;

    return !!object.userData.instantiatable;
  },

  popHadUpdate: function () {

    let hadUpdate = this.hadUpdate;
    this.hadUpdate = false;
    return hadUpdate;

  },

  add: function (object) {

    if (arguments.length > 1) {

      for (let i = 0; i < arguments.length; i++) {

        this.add(arguments[i]);

      }

      return this;

    }

    if (object === this) {

      console.error(`MeshInstancingManager.add: object can't be added as a child of itself.`, object);
      return this;

    }

    if (!object)
      return this;

    if ((object && object.isObject3D)) {

      if (this.isInstantiatable(object)) {

        this.childrenById[object.id] = object;
        this.needsUpdate = true;

      } else {

        this.target.add(object);
        this.hadUpdate = true;

      }

    } else {

      console.error(`MeshInstancingManager.add: object not an instance of THREE.Object3D.`, object);

    }


    return this;

  },

  remove: function (object) {

    if (arguments.length > 1) {

      for (let i = 0; i < arguments.length; i++) {

        this.remove(arguments[i]);

      }

      return this;

    }

    if (!object)
      return this;

    if (this.isInstantiatable(object)) {

      delete this.childrenById[object.id];
      this.needsUpdate = true;

    } else {

      this.target.remove(object);
      this.hadUpdate = true;

    }

    return this;

  },

  clear: function () {

    this.childrenById = {};
    this.instancedMeshesByHash = {};
    this.nonInstantiatableObjectByHash = {};
    this.targetMatrixWorld = new Matrix4();
    this.target.clear();
    this.hadUpdate = true;
    return this;

  },

  validate: function () {

    if (this.needsUpdate && !this.preventValidate) {

      let instancedMeshes = {};
      let nonInstantiatableObject = {};

      for (let id in this.childrenById) {

        let child = this.childrenById[id];

        child.traverse((obj) => {

          if (this.isInstantiatable(obj)) {

            if (obj.isMesh) {

              let hash = obj.geometry.userData.hash + obj.material.userData.hash;
              let meshHash = obj.userData.hash;

              if (!instancedMeshes[hash]) {

                instancedMeshes[hash] = {
                  geometry: obj.geometry,
                  material: obj.material,
                  meshHashes: new Set(),
                  matrixWorlds: {},
                  ids: {},
                };

              }

              let im = instancedMeshes[hash];

              im.meshHashes.add(meshHash);
              im.matrixWorlds[meshHash] = obj.matrixWorld;
              if (!im.ids[meshHash])
                im.ids[meshHash] = [];

              im.ids[meshHash].push(obj.userData.id);

            } else if (obj.isLine || obj.isLineSegments || obj.isPoints) {

              nonInstantiatableObject[obj.uuid] = obj;

            }

          } else {

            console.warn(`Non-instantiatable object within decendent.`);

          }

        });

      }

      this.target.updateWorldMatrix(false, false);
      let isTargetInvariant = isIdentity(this.target.matrixWorld);
      let invMatrix = new Matrix4();
      let targetMatrixEqual = this.targetMatrixWorld.equals(this.target.matrixWorld);

      if (!isTargetInvariant)
        invMatrix = this.target.matrixWorld.clone().invert();

      let instancedMeshByHash = {};
      let nonInstantiatableObjectByHash = {};

      for (let hash in this.instancedMeshesByHash) {

        if (!instancedMeshes[hash])
          this.target.remove(this.instancedMeshesByHash[hash]);

        this.hadUpdate = true;

      }

      for (let hash in instancedMeshes) {

        let im = instancedMeshes[hash];

        if (targetMatrixEqual &&
          this.instancedMeshesByHash[hash] &&
          lod.isEqual(instancedMeshes[hash].meshHashes, this.instancedMeshesByHash[hash].userData.hashes)
        ) {

          if (lod.isEqual(instancedMeshes[hash].ids, this.instancedMeshesByHash[hash].userData.ids)) {

            instancedMeshByHash[hash] = this.instancedMeshesByHash[hash];

          } else {

            instancedMeshByHash[hash] = this.instancedMeshesByHash[hash];
            instancedMeshByHash[hash].userData.ids = instancedMeshes[hash].ids;

          }

          continue;

        }

        if (this.instancedMeshesByHash[hash])
          this.target.remove(this.instancedMeshesByHash[hash]);

        let instancedMesh = new InstancedMesh(im.geometry, im.material, im.meshHashes.size);
        instancedMesh.matrixAutoUpdate = false;
        instancedMesh.castShadow = true;
        instancedMesh.receiveShadow = true;

        let index = 0;

        for (let meshHash of im.meshHashes) {

          let matrix = im.matrixWorlds[meshHash];

          if (!isTargetInvariant)
            matrix = matrix.clone().premultiply(invMatrix);

          instancedMesh.setMatrixAt(index, matrix);

          ++index;

        }

        instancedMesh.instanceMatrix.needsUpdate = true;
        instancedMesh.userData.hashes = im.meshHashes;
        instancedMesh.userData.ids = im.ids;

        instancedMeshByHash[hash] = instancedMesh;
        this.target.add(instancedMesh);

        this.hadUpdate = true;

      }

      for (let hash in this.nonInstantiatableObjectByHash) {

        if (!nonInstantiatableObject[hash])
          this.target.remove(this.nonInstantiatableObjectByHash[hash]);

        this.hadUpdate = true;

      }

      for (let hash in nonInstantiatableObject) {

        let ni = nonInstantiatableObject[hash];

        if (targetMatrixEqual &&
          this.nonInstantiatableObjectByHash[hash] &&
          lod.isEqual(nonInstantiatableObject[hash].matrix.toArray(), this.nonInstantiatableObjectByHash[hash].matrix.toArray())
        ) {

          nonInstantiatableObjectByHash[hash] = this.nonInstantiatableObjectByHash[hash];
          continue;

        }

        if (this.nonInstantiatableObjectByHash[hash])
          this.target.remove(this.nonInstantiatableObjectByHash[hash]);

        let newMesh = ni.clone();
        newMesh.matrixAutoUpdate = false;
        // newMesh.matrix = newMesh.matrix.clone();

        if (!isTargetInvariant)
          newMesh.matrix.premultiply(invMatrix);

        nonInstantiatableObjectByHash[hash] = newMesh;
        this.target.add(newMesh);

        this.hadUpdate = true;

      }

      this.instancedMeshesByHash = instancedMeshByHash;
      this.nonInstantiatableObjectByHash = nonInstantiatableObjectByHash;
      this.targetMatrixWorld = this.target.matrixWorld.clone();

      this.needsUpdate = false;

    }

  },

});

export {MeshInstancingManager};