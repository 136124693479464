import {IChatBotMessage} from "../types";
import {InitStoreAction} from "../init";

export const REPLACE_CHAT_BOT_MESSAGES = 'REPLACE_CHAT_BOT_MESSAGES';
export const ADD_CHAT_BOT_MESSAGE = 'ADD_CHAT_BOT_MESSAGE';

export interface IChatBotState {
  chatBotMsgs: IChatBotMessage[]
}

interface ReplaceChatBotMessagesAction {
  type: typeof REPLACE_CHAT_BOT_MESSAGES
  payload: {
    messages: IChatBotMessage[]
  }
}

interface AddDPChatBotMessageAction {
  type: typeof ADD_CHAT_BOT_MESSAGE
  payload: {
    message: IChatBotMessage
  }
}

export type ChatBotActionTypes =
  ReplaceChatBotMessagesAction |
  AddDPChatBotMessageAction |
  InitStoreAction