import {ISculptConfig} from "../../../../peregrine/processor";
import {DeepPartial} from "ts-essentials";

export const CHANGE_SCULPT_WIZARD_CONFIG = 'CHANGE_SCULPT_WIZARD_CONFIG';
export const CHANGE_SCULPT_WIZARD_SUB_SCREEN = 'CHANGE_SCULPT_WIZARD_SUB_SCREEN';

export interface ISculptWizardState {
  subScreen: number
  config: ISculptConfig
}

export interface ChangeSculptWizardSubScreenAction {
  type: typeof CHANGE_SCULPT_WIZARD_SUB_SCREEN
  payload: {
    subScreen: number
  }
}

export interface ChangeSculptWizardConfigAction {
  type: typeof CHANGE_SCULPT_WIZARD_CONFIG
  payload: {
    config: DeepPartial<ISculptConfig>
  }
}

