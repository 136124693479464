import {ComponentTypes} from "../types";
import {createSimpleGumball, IRenderedObjectInternal} from "../gumball";
import {offsetGeometry} from "../../../operation";
import {
  BooleanInterfaceTypes, createObjectFromBrepData, createObjectFromMeshData,
  getBrepFromRef,
  getMeshFromRef,
  Param_Boolean,
  Param_Compound,
  Param_Number,
  ParamTitles,
  ParamValueTypes
} from "../../parameter";
import {Calc} from "../../calc";
import {MeshGeometryObjectTypes, ObjectTypes} from "../../xobject";
import {getRenderedGeometry} from "../../../WompObject";
import {pigeon as pigeonTypes} from "../../../types";
import {mat4} from "gl-matrix";
import {DistantObjectMapper, ObjectFuncParameter, PredictedResult} from "../base";

export class OffsetModifier extends DistantObjectMapper {
  static create() {
    let calc = createSimpleGumball();
    calc.component = ComponentTypes.OffsetModifier;
    calc.title = 'offset';

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let distanceParam = Param_Number.create(calc, ParamTitles.Distance, true, false, {type: ParamValueTypes.Offset});
    let capParam = Param_Boolean.create(calc, ParamTitles.Cap, true, true, {
      defaultValue: false,
      interfaceType: BooleanInterfaceTypes.Toggle
    });
    let offsetParam = Param_Compound.create(calc, ParamTitles.Offset, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(distanceParam);
    calc.addParameter(capParam);
    calc.addParameter(offsetParam);
    return calc;
  }

  static getDesc() {
    return 'offset';
  }

  static predictResult(calc: Calc, obj: IRenderedObjectInternal) {
    if (obj.valueType === ObjectTypes.Brep) {
      let distanceParam = calc.inputByTitle(ParamTitles.Distance) as Param_Number;
      let distance = distanceParam.values[0];

      return distance === 0 ? PredictedResult.CopyFirst : PredictedResult.Brep;
    } else if (MeshGeometryObjectTypes.includes(obj.valueType)) {
      let distanceParam = calc.inputByTitle(ParamTitles.Distance) as Param_Number;
      let distance = distanceParam.values[0];

      return distance === 0 ? PredictedResult.CopyFirst : PredictedResult.Mesh;
    }

    return PredictedResult.Ignore;
  }

  static async mapObject(calc: Calc, obj: IRenderedObjectInternal, parameters: ObjectFuncParameter) {
    if (obj.valueType === ObjectTypes.Brep) {
      let brep = getBrepFromRef(calc, obj.value);
      mat4.copy(brep.matrix, parameters.appliedMatrix);
      let brepData = (await pigeonTypes.offset([brep], parameters.distance))[0];

      return createObjectFromBrepData(calc, brepData, parameters.desc, parameters.matrix);
    } else if (MeshGeometryObjectTypes.includes(obj.valueType)) {
      let mesh = getMeshFromRef(calc, obj.value);

      if (mesh) {
        let offset = offsetGeometry(
          getRenderedGeometry(mesh),
          parameters.distance,
          {
            includeSource: parameters.cap,
            includeTarget: true,
            includeSide: parameters.cap
          }
        );

        return createObjectFromMeshData(calc, offset, parameters.desc, parameters.matrix);
      }
    }

    return obj;
  }
}