import {NormalizedStringIndexedObjects} from "../types";
import {InitStoreAction} from "../init";
import {IEnvironment} from "../../../peregrine/processor";

export const REPLACE_ENVIRONMENTS = 'REPLACE_ENVIRONMENTS';
export const ADD_ENVIRONMENTS = 'ADD_ENVIRONMENTS';
export const REMOVE_ENVIRONMENT = 'REMOVE_ENVIRONMENT';

export interface IEntityEnvironmentsState extends NormalizedStringIndexedObjects<IEnvironment> {
}

interface ReplaceEnvironmentsAction {
  type: typeof REPLACE_ENVIRONMENTS
  payload: {
    environments: IEnvironment[]
  }
}

interface AddEnvironmentsAction {
  type: typeof ADD_ENVIRONMENTS
  payload: {
    environments: IEnvironment[]
  }
}

interface RemoveEnvironmentAction {
  type: typeof REMOVE_ENVIRONMENT
  payload: {
    id: string
  }
}


export type EnvironmentsActionTypes = ReplaceEnvironmentsAction |
  AddEnvironmentsAction |
  RemoveEnvironmentAction |
  InitStoreAction