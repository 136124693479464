import {AuthActionTypes, CHANGE_AUTH_USER_AVATAR, CHANGE_AUTH_USERNAME, SET_AUTH_DATA} from "../../types/entity/auth";

export function setAuthData(data: {token?: string, username?: string, userAvatar?: string, userPoints?: number, appAdmin?: boolean, verified?: boolean}): AuthActionTypes {
  return {
    type: SET_AUTH_DATA,
    payload: {
      ...data
    }
  };
}

export function changeAuthUsername(username?: string): AuthActionTypes {
  return {
    type: CHANGE_AUTH_USERNAME,
    payload: {
      username
    }
  };
}

export function changeAuthUserAvatar(userAvatar?: string): AuthActionTypes {
  return {
    type: CHANGE_AUTH_USER_AVATAR,
    payload: {
      userAvatar
    }
  };
}