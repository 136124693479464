import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {IGAR} from "../../store/types/types";
import {AnyAction} from "redux";
import {_fetchConfigurations} from "../../api/config";
import {changeConfiguration} from "../../store/actions/entity/global";

export const fetchConfiguration = (): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    return _fetchConfigurations()
      .then(res => {
        let configuration = Object.assign({}, ...res.data.map((row: { value: any }) => row.value));
        dispatch(changeConfiguration(configuration));

        return {success: true};
      })
      .catch(err => {
        return {success: false, message: err.message};
      });
  };
};