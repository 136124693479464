import {InitStoreAction} from "../init";
import {IPostAsync} from "../types";

export const POST_SEND_CONFIRM_EMAIL = 'POST_SEND_CONFIRM_EMAIL';
export const POST_ACTIVATE_ACCOUNT = 'POST_ACTIVATE_ACCOUNT';
export const POST_SEND_RESET_EMAIL = 'POST_SEND_RESET_EMAIL';
export const POST_CHANGE_PASSWORD = 'POST_CHANGE_PASSWORD';
export const POST_SIGN_IN = 'POST_SIGN_IN';
export const POST_SIGN_UP = 'POST_SIGN_UP';

export interface IApiAuthState {
  postSendConfirmEmail: IPostAsync
  postActivateAccount: IPostAsync
  postSendResetEmail: IPostAsync
  postChangePassword: IPostAsync
  postSignIn: IPostAsync
  postSignUp: IPostAsync
}

interface PostSendConfirmEmailAction {
  type: typeof POST_SEND_CONFIRM_EMAIL
  payload: {
    posting: boolean
    success?: boolean
    message?: string
  }
}

interface PostActivateAccountAction {
  type: typeof POST_ACTIVATE_ACCOUNT
  payload: {
    posting: boolean
    success?: boolean
    message?: string
  }
}

interface PostSendResetEmailAction {
  type: typeof POST_SEND_RESET_EMAIL
  payload: {
    posting: boolean
    success?: boolean
    message?: string
  }
}

interface PostChangePasswordAction {
  type: typeof POST_CHANGE_PASSWORD
  payload: {
    posting: boolean
    success?: boolean
    message?: string
  }
}

interface PostSignInAction {
  type: typeof POST_SIGN_IN
  payload: {
    posting: boolean
    success?: boolean
    message?: string
  }
}

interface PostSignUpAction {
  type: typeof POST_SIGN_UP
  payload: {
    posting: boolean
    success?: boolean
    message?: string
  }
}

export type ApiAuthActionTypes = PostActivateAccountAction |
  PostSendConfirmEmailAction |
  PostSendResetEmailAction |
  PostChangePasswordAction |
  PostSignInAction |
  PostSignUpAction |
  InitStoreAction