import {WompCurve, WompCurveData, WompMeshData} from "../WompObject";
import {vec3} from "gl-matrix";
import {evaluateBezier} from "../helper/bezier";

const nurbs = require('nurbs');

function getCurvePointAt(curve: WompCurveData & {spline?: any}, t: number): vec3 {
  let p: [number, number, number] = [0, 0, 0];
  if (curve.bezier) {
    let index = Math.floor(t);
    evaluateBezier(p, (curve.points || []) as vec3[], index * (curve.degree || 1), (index + 1) * (curve.degree || 1), t - index);
  } else {
    if (curve.spline)
      curve.spline.evaluate(p, t);
  }
  return p;
}

function getCurveDomain(curve: WompCurveData & {spline?: any}) {
  if (curve.bezier) {
    if (curve.periodic) {
      return [0, ((curve.points || []).length - 1) / (curve.degree || 1) + 1];
    } else {
      return [0, ((curve.points || []).length - 1) / (curve.degree || 1)];
    }
  } else {
    if (curve.spline)
      return curve.spline.domain[0];
  }
}

export function getCurvePointArray(curve: WompCurveData & {spline?: any}, zoom: number = 30): number[] {
  if (curve.degree === 1) {
    zoom = 1;
  }

  if (!curve.bezier) {
    let spline = nurbs({
      points: curve.points || [],
      boundary: curve.periodic ? 'closed' : 'clamped',
      degree: curve.degree || 1
    });

    curve = {...curve, spline};
  }

  let domain = getCurveDomain(curve);
  let points = new Array<number>(((domain[1] - domain[0]) * zoom + 1) * 3);
  for (let i = 0; i <= (domain[1] - domain[0]) * zoom; ++i) {
    let t = i / zoom + domain[0];
    let p = getCurvePointAt(curve, t);
    points[i * 3] = p[0];
    points[i * 3 + 1] = p[1];
    points[i * 3 + 2] = p[2];
  }

  return points;
}

export function getCurveEdgeGeometry(curve: WompCurveData, zoom: number = 30): WompMeshData {
  let points = getCurvePointArray(curve, zoom);
  let segmentCnt = points.length / 3 - 1;

  let position = new Float32Array(segmentCnt * 6 + 3);
  let face = new Uint32Array(segmentCnt * 3);

  let avgPos = vec3.create();
  for (let i = 0; i < segmentCnt; ++i) {
    for (let j = 0; j < 2; ++j) {
      face[i * 3 + j] = i * 2 + j;

      for (let k = 0; k < 3; ++k) {
        position[(i * 2 + j) * 3 + k] = points[(i + j) * 3 + k];
        avgPos[k] += points[(i + j) * 3 + k] / (segmentCnt * 2);
      }
    }

    face[i * 3 + 2] = segmentCnt * 2;
  }

  position[segmentCnt * 6] = avgPos[0]
  position[segmentCnt * 6 + 1] = avgPos[1]
  position[segmentCnt * 6 + 2] = avgPos[2];

  return {face, position};
}
