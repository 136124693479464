/**
 * @author arodic / https://github.com/arodic
 */

import {
  EventDispatcher,
  Group,
  Matrix4,
  Object3D,
  Vector3,
  Mesh,
  SphereBufferGeometry,
  CylinderBufferGeometry,
  MeshBasicMaterial,
  Matrix3, Uint32BufferAttribute, Float32BufferAttribute
} from 'three';
import {
  getMat4FromThreeTransform,
  getMeshFromSculptMesh,
  getThreeTransformFromMat4,
  sculpt as sculptTypes
} from "../../../peregrine";
import {vec3, mat4} from 'gl-matrix';
import {Sculpt} from "../Sculpt";
import lod from "lodash";
import {peregrineId} from "../../../peregrine/id";
import {SculptPointAction} from "../../../peregrine/processor";

const MOUSE_LEFT = 1;
const MOUSE_MIDDLE = 2;
const MOUSE_RIGHT = 3;

const DRAG_MODE = {
  SCULPT: 1,
  SYMSET: 2
};

export const SculptKeyCodes = {
  Mask: 'mask',
  Radius: 'radius',
  Intensity: 'intensity',
  Disable: 'disable',
  Smooth: 'smooth',
  Drag: 'drag',
  Negative: 'negative'
};


let SculptControl = function (camera, scene) {
  Object3D.call(this);
  Sculpt.call(this);

  this.domElement = document;

  let scope = this;

  function onPointerDown(event) {
    if (!scope.elemRect)
      scope.refreshBoundingClientRect();
    scope.setMousePosition(event);
    scope.pointerDown(scope.getPointer(event), event);
  }

  function onTouchDown(event) {
    scope.refreshBoundingClientRect();
    scope.setMousePosition(event);
    scope.pointerDown(scope.getPointer(event), event);
  }

  function onPointerMove(event) {
    if (!scope.elemRect)
      scope.refreshBoundingClientRect();
    scope.setMousePosition(event);
    scope.pointerMove(scope.getPointer(event), event);
  }

  function onPointerUp(event) {
    if (!scope.elemRect)
      scope.refreshBoundingClientRect();
    scope.setMousePosition(event);
    scope.pointerUp(scope.getPointer(event), event);
  }

  this.visible = false;
  this.camera = camera;
  this.scene = scene;

  this.spriteSize = 1.2;
  this.isDragging = false;
  this.dragMode = DRAG_MODE.SCULPT;
  this.dragStartPosition = undefined;
  this.dragEndPosition = undefined;
  this.oldSymmetryOffset = undefined;
  this.oldSymmetryNormal = undefined;
  this.keyStates = {
    [SculptKeyCodes.Intensity]: false,
    [SculptKeyCodes.Radius]: false,
    [SculptKeyCodes.Smooth]: false,
    [SculptKeyCodes.Negative]: false,
    [SculptKeyCodes.Mask]: false,
    [SculptKeyCodes.Drag]: false,
    [SculptKeyCodes.Disable]: false,
  };
  this.appliedKeyStates = {
    [SculptKeyCodes.Intensity]: false,
    [SculptKeyCodes.Radius]: false,
    [SculptKeyCodes.Smooth]: false,
    [SculptKeyCodes.Negative]: false,
    [SculptKeyCodes.Mask]: false,
    [SculptKeyCodes.Drag]: false,
    [SculptKeyCodes.Disable]: false,
  }
  this.enabled = true;
  this.orgToolIndex = -1;
  this.negativeToolIndex = -1;
  this.cameraTargetOnPick = true;

  this._lastPageX = 0;
  this._lastPageY = 0;

  this._refX = 0;
  this._refY = 0;

  this.sMeshes = [];
  this.meshes = [];
  this.wfMeshes = [];

  this.elemRect = undefined;

  this.refreshBoundingClientRect = function () {
    this.elemRect = this.domElement === document ? document.body.getBoundingClientRect() : this.domElement.getBoundingClientRect();
  };

  this.setDelegate = function (delegate) {

    this.unsetDelegate();

    delegate.domElement.addEventListener('mousedown', onPointerDown);
    delegate.domElement.addEventListener('touchstart', onTouchDown);

    delegate.domElement.addEventListener('mousemove', onPointerMove);
    delegate.domElement.addEventListener('touchmove', onPointerMove);

    delegate.domElement.addEventListener('mouseup', onPointerUp);
    delegate.domElement.addEventListener('touchend', onPointerUp);
    delegate.domElement.addEventListener('touchcancel', onPointerUp);
    delegate.domElement.addEventListener('touchleave', onPointerUp);

    this.domElement = delegate.domElement;

    this.refreshBoundingClientRect();
  }

  this.unsetDelegate = function () {

    if (this.domElement === document)
      return;

    this.domElement.removeEventListener('mousedown', onPointerDown);
    this.domElement.removeEventListener('touchstart', onTouchDown);

    this.domElement.removeEventListener('mousemove', onPointerMove);
    this.domElement.removeEventListener('touchmove', onPointerMove);

    this.domElement.removeEventListener('mouseup', onPointerUp);
    this.domElement.removeEventListener('touchend', onPointerUp);
    this.domElement.removeEventListener('touchcancel', onPointerUp);
    this.domElement.removeEventListener('touchleave', onPointerUp);

    this.domElement = document;

  }

  this.actOnKeyState = function (key, state) {

    // console.log('%c' + key + ' act', state ? 'color: #ea0a05' : 'color: #bada55');

    if (key === SculptKeyCodes.Intensity) {

      if (state) {

        this._startModalBrushIntensity(this._lastPageX, this._lastPageY);
        this._modalBrushIntensity = true;

      } else {

        this.setMousePosition({pageX: this._lastPageX, pageY: this._lastPageY});
        this.sceneProxy.getPicking().intersectionMouseMeshes();
        this._modalBrushIntensity = false;

      }

    } else if (key === SculptKeyCodes.Radius) {

      if (state) {

        this._startModalBrushRadius(this._lastPageX, this._lastPageY);
        this._modalBrushRadius = true;

      } else {

        this.setMousePosition({pageX: this._lastPageX, pageY: this._lastPageY});
        this.sceneProxy.getPicking().intersectionMouseMeshes();
        this._modalBrushRadius = false;

      }

    } else if (key === SculptKeyCodes.Smooth) {

      if (state) {

        this.orgToolIndex = this.sceneProxy.getSculptManager().getToolIndex();
        this.setConfig({tool: {sculptTool: sculptTypes.Enums.SculptTools.SMOOTH}});
        // this.dispatchEvent({type: 'change config'});
        this.sceneProxy.getSculptManager().preUpdate();

      } else {

        if (this.orgToolIndex >= 0) {

          this.setConfig({tool: {sculptTool: this.orgToolIndex}});
          // this.dispatchEvent({type: 'change config'});
          this.orgToolIndex = -1;
          this.sceneProxy.getSculptManager().preUpdate();

        }

      }

    } else if (key === SculptKeyCodes.Drag) {

      if (state) {

        this.orgToolIndex = this.sceneProxy.getSculptManager().getToolIndex();
        this.setConfig({tool: {sculptTool: sculptTypes.Enums.SculptTools.DRAG}});
        // this.dispatchEvent({type: 'change config'});
        this.sceneProxy.getSculptManager().preUpdate();

      } else {

        if (this.orgToolIndex >= 0) {

          this.setConfig({tool: {sculptTool: this.orgToolIndex}});
          // this.dispatchEvent({type: 'change config'});
          this.orgToolIndex = -1;
          this.sceneProxy.getSculptManager().preUpdate();

        }

      }

    } else if (key === SculptKeyCodes.Negative) {

      if (state) {

        this.negativeToolIndex = this.sceneProxy.getSculptManager().getToolIndex();
        let cur = this.sceneProxy.getSculptManager().getTool(this.negativeToolIndex);

        if (cur) {

          if (cur._negative !== undefined)
            cur._negative = !cur._negative;

          this.dispatchEvent({type: 'change config'});
          this.sceneProxy.getSculptManager().preUpdate();

        }

      } else {

        let cur = this.sceneProxy.getSculptManager().getTool(this.negativeToolIndex);

        if (cur) {

          if (cur._negative !== undefined)
            cur._negative = !cur._negative;

          this.dispatchEvent({type: 'change config'});
          this.sceneProxy.getSculptManager().preUpdate();

        }

      }

    } else if (key === SculptKeyCodes.Mask) {

      if (state) {

        this.orgToolIndex = this.sceneProxy.getSculptManager().getToolIndex();
        this.setConfig({tool: {sculptTool: sculptTypes.Enums.SculptTools.MASKING}});
        // this.dispatchEvent({type: 'change config'});
        this.sceneProxy.getSculptManager().preUpdate();

      } else {

        if (this.orgToolIndex >= 0) {

          this.setConfig({tool: {sculptTool: this.orgToolIndex}});
          this.orgToolIndex = -1;
          // this.dispatchEvent({type: 'change config'});
          this.sceneProxy.getSculptManager().preUpdate();

        }

      }

    }

    this.appliedKeyStates[key] = state;

  };

  this.setKeyState = function (key, state, noRepeatCheck) {

    // console.log('%c' + key, state ? 'color: #ea0a05' : 'color: #bada55');

    if (!noRepeatCheck && this.keyStates[key] === state)
      return;

    if (key === SculptKeyCodes.Smooth) {

      if (!this.isDragging && !this.appliedKeyStates[SculptKeyCodes.Mask] && !this.appliedKeyStates[SculptKeyCodes.Drag])
        this.actOnKeyState(key, state);

    } else if (key === SculptKeyCodes.Drag) {

      if (!this.isDragging && !this.appliedKeyStates[SculptKeyCodes.Mask] && !this.appliedKeyStates[SculptKeyCodes.Smooth])
        this.actOnKeyState(key, state);

    } else if (key === SculptKeyCodes.Mask) {

      if (!this.isDragging && !this.appliedKeyStates[SculptKeyCodes.Smooth] && !this.appliedKeyStates[SculptKeyCodes.Drag])
        this.actOnKeyState(key, state);

    } else if (key === SculptKeyCodes.Intensity || key === SculptKeyCodes.Radius || key === SculptKeyCodes.Negative) {

      if (!this.isDragging)
        this.actOnKeyState(key, state);

    }

    this.keyStates[key] = state;

  };

  this.setSize = function (size) {
    this.spriteSize = size * 12.0;
  };

  this.multiplyToolIntensity = function (ratio) {
    let curToolIndex = this.sceneProxy.getSculptManager().getToolIndex();
    let cur = this.sceneProxy.getSculptManager().getTool(curToolIndex);

    if (cur._intensity !== undefined) {
      let newIntensity = Math.max(Math.min(cur._intensity * ratio, 1), 0);

      for (let toolIndex in this.sceneProxy.getSculptManager()._tools) {
        let tool = this.sceneProxy.getSculptManager()._tools[toolIndex];

        if (tool._intensity !== undefined)
          tool._intensity = newIntensity;
      }
      this.dispatchEvent({type: 'change config'});
      this.sceneProxy.getSculptManager().preUpdate();
    }
  };

  this.multiplyToolRadius = function (ratio) {
    let curToolIndex = this.sceneProxy.getSculptManager().getToolIndex();
    let cur = this.sceneProxy.getSculptManager().getTool(curToolIndex);

    if (cur._radius !== undefined) {
      let newRadius = Math.max(Math.min(cur._radius * ratio, 500), 0);

      for (let toolIndex in this.sceneProxy.getSculptManager()._tools) {
        let tool = this.sceneProxy.getSculptManager()._tools[toolIndex];

        if (tool._radius !== undefined)
          tool._radius = newRadius;
      }

      this.dispatchEvent({type: 'change config'});
      this.sceneProxy.getSculptManager().preUpdate();
    }
  };

  this.dispose = function () {
    this.detach();

    this.sceneProxy.getStateManager().reset();
    scope = null;
  };

  this.refresh = function () {
    let newState = {};
    newState.enabled = this.enabled;
    newState.visible = this.visible;

    this.controlGroup.visible = false;

    if (this.enabled && this.visible && this.sMeshes.length > 0) {
      newState.objectIds = this.meshes.map(mesh => mesh.id);
      newState.dragMode = this.dragMode;
      newState.isDragging = this.isDragging;

      if (sculptTypes.Tablet.pressure === undefined)
        sculptTypes.Tablet.pressure = 0.5;

      this.controlGroup.visible = true;

      let curToolIndex = this.sceneProxy.getSculptManager().getToolIndex();
      let cur = this.sceneProxy.getSculptManager().getTool(curToolIndex);
      let rWorld = Math.sqrt(this.sceneProxy.getPicking().getWorldRadius2());
      let iFace = this.sceneProxy.getPicking().getPickedFace();
      let iSymFace = this.sceneProxy.getPickingSymmetry().getPickedFace();

      let tmp = vec3.create();
      let tmpSym = vec3.create();

      let pickedMesh = this.sceneProxy.getPicking().getMesh();
      let currentMesh = this.getMesh();
      if (pickedMesh) {
        let matrix = pickedMesh.getMatrix();

        vec3.copy(tmp, this.sceneProxy.getPicking().getIntersectionPoint());
        vec3.transformMat4(tmp, tmp, matrix);
        vec3.copy(tmpSym, this.sceneProxy.getPickingSymmetry().getIntersectionPoint());
        vec3.transformMat4(tmpSym, tmpSym, matrix);
      }

      newState.pickingPos = [tmp[0], tmp[1], tmp[2]];
      newState.pickingSymPos = [tmpSym[0], tmpSym[1], tmpSym[2]];
      newState.showSymmetricPlane = this._rendering.symmetricPlane;
      newState.temporarySymmetricAxis = this._rendering.temporarySymmetricAxis;
      newState.pickedFace = iFace;
      newState.wireframe = this._rendering.wireframe;
      newState.flatShading = this._rendering.flatShading;
      newState.keyStates = this.keyStates;
      newState.spriteSize = this.spriteSize;
      newState.tool = curToolIndex;
      newState.radius = cur._radius;
      newState.intensity = cur._intensity;
      newState.materials = this.meshes.map(m => m.material.name);
      newState.undos = this.sceneProxy.getStateManager()._undos.length;
      newState.redos = this.sceneProxy.getStateManager()._redos.length;

      if (this.dragMode === DRAG_MODE.SCULPT) {
        this.pickingSphere.position.set(...tmp);
        this.pickingSphere.scale.set(this.spriteSize / 5, this.spriteSize / 5, this.spriteSize / 5);

        this.pickingSymSphere.position.set(...tmpSym);
        this.pickingSymSphere.scale.set(this.spriteSize / 5, this.spriteSize / 5, this.spriteSize / 5);

        if (iFace >= 0) {
          this.pickingSphere.visible = true;
          if (this.isDragging) {
            this.pickingCylinder.visible = false;
          } else {
            this.pickingCylinder.visible = true;

            this.pickingCylinder.position.set(...tmp);
            this.pickingCylinder.scale.set(rWorld / 10, 1, rWorld / 10);
            this.pickingCylinder.material.opacity = 0.2 * (cur._intensity === undefined ? 1 : cur._intensity);

            if (this._modalBrushRadius || this._modalBrushIntensity) {
              let eye = this.camera.getWorldDirection(new Vector3());
              this.pickingCylinder.quaternion.setFromUnitVectors(new Vector3(0, 1, 0), eye);
            } else {
              if (iFace >= 0) {
                let matrix = pickedMesh.getMatrix();
                let faceNormals = pickedMesh.getFaceNormals();
                let normalMatrix = new Matrix3().getNormalMatrix(getThreeTransformFromMat4(matrix));

                let normal = new Vector3(faceNormals[iFace * 3], faceNormals[iFace * 3 + 1], faceNormals[iFace * 3 + 2]).normalize();
                normal.applyMatrix3(normalMatrix);
                this.pickingCylinder.quaternion.setFromUnitVectors(new Vector3(0, 1, 0), normal.normalize());
              } else {
              }
            }
          }
        } else {
          this.pickingSphere.visible = false;
          this.pickingCylinder.visible = false;
        }

        if (this.sceneProxy.getSculptManager().getSymmetry() && iFace >= 0 && iSymFace >= 0) {
          this.pickingSymSphere.visible = true;
        } else {
          this.pickingSymSphere.visible = false;
        }
      } else if (this.dragMode === DRAG_MODE.SYMSET) {
        let setOld = false;

        this.pickingCylinder.visible = false;

        if (this.dragStartPosition && this.dragEndPosition && currentMesh) {
          let matrix = currentMesh.getMatrix();
          this.pickingSphere.position.set(...vec3.transformMat4(vec3.create(), this.dragStartPosition, matrix));
          this.pickingSphere.scale.set(this.spriteSize / 5, this.spriteSize / 5, this.spriteSize / 5);

          this.pickingSymSphere.position.set(...vec3.transformMat4(vec3.create(), this.dragEndPosition, matrix));
          this.pickingSymSphere.scale.set(this.spriteSize / 5, this.spriteSize / 5, this.spriteSize / 5);

          this.pickingSphere.visible = true;
          this.pickingSymSphere.visible = true;

          let normal = vec3.sub(vec3.create(), this.dragEndPosition, this.dragStartPosition);

          if (vec3.squaredLength(normal) > 0) {
            vec3.normalize(normal, normal);

            currentMesh.setSymmetryOffset(0);
            let origin = currentMesh.getSymmetryOrigin();
            let localRadius = currentMesh.computeLocalRadius();

            let dir = vec3.create();
            vec3.add(dir, this.dragStartPosition, this.dragEndPosition);
            vec3.scale(dir, dir, 0.5);
            vec3.sub(dir, dir, origin);

            let offset = vec3.dot(dir, normal);

            currentMesh.setSymmetryNormal(normal);
            currentMesh.setSymmetryOffset(offset / localRadius);
          } else {
            setOld = true;
          }
        } else {
          this.pickingSphere.visible = false;
          this.pickingSymSphere.visible = false;
          setOld = true;
        }

        if (setOld && this.oldSymmetryNormal !== undefined && this.oldSymmetryOffset !== undefined) {
          currentMesh.setSymmetryNormal(this.oldSymmetryNormal);
          currentMesh.setSymmetryOffset(this.oldSymmetryOffset);
        }
      }

      if (currentMesh && (this._rendering.symmetricPlane || this.dragMode === DRAG_MODE.SYMSET)) {
        let matrix = currentMesh.getMatrix();
        let symOrigin = currentMesh.getSymmetryOrigin();
        let symNormal = currentMesh.getSymmetryNormal();

        if (this._rendering.temporarySymmetricAxis) {
          symOrigin = currentMesh.getCenter();
          if (this._rendering.temporarySymmetricAxis === 'x')
            symNormal = vec3.fromValues(1, 0, 0);
          else if (this._rendering.temporarySymmetricAxis === 'y')
            symNormal = vec3.fromValues(0, 1, 0);
          else if (this._rendering.temporarySymmetricAxis === 'z')
            symNormal = vec3.fromValues(0, 0, 1);
        }

        let origin = vec3.transformMat4(vec3.create(), symOrigin, matrix);
        let target = vec3.transformMat4(vec3.create(), vec3.add(vec3.create(), symOrigin, symNormal), matrix);
        let normal = vec3.sub(vec3.create(), target, origin);
        vec3.normalize(normal, normal);

        this.symmetricCylinder.position.set(...origin);
        let bound = currentMesh.getLocalBound();
        let bBoxSize = vec3.transformMat4(vec3.create(), vec3.fromValues(bound[3] - bound[0], bound[4] - bound[1], bound[5] - bound[2]), matrix);
        let maxBound = Math.max(...bBoxSize);
        this.symmetricCylinder.scale.set(maxBound, 1, maxBound);
        this.symmetricCylinder.quaternion.setFromUnitVectors(new Vector3(0, 1, 0), new Vector3(...normal));
        this.symmetricCylinder.visible = true;
      } else {
        this.symmetricCylinder.visible = false;
      }
    }

    // Object3D.prototype.updateMatrixWorld.call(this)
    let hadUpdate = !lod.isEqual(newState, this.oldState);
    this.oldState = newState;

    return hadUpdate;
  };

  this.getPointer = function (event) {
    let pointer = event.changedTouches ? event.changedTouches[0] : event;

    return {
      x: (pointer.clientX - this.elemRect.left) / this.elemRect.width * 2 - 1,
      y: -(pointer.clientY - this.elemRect.top) / this.elemRect.height * 2 + 1,
      button: event.button,
      type: event.changedTouches ? 'touch' : 'mouse',
      force: pointer.force
    };

  };

  this.setMousePosition = function (event) {
    if (!this.elemRect)
      scope.refreshBoundingClientRect();

    this.sceneProxy._width = this.elemRect.width;
    this.sceneProxy._height = this.elemRect.height;

    mat4.copy(
      this.sceneProxy.getProjection(),
      getMat4FromThreeTransform(this.camera.projectionMatrix.clone().multiply(this.camera.matrixWorldInverse))
    );

    let pointer = event ? (event.changedTouches ? event.changedTouches[0] : event) : this.lastPointer;

    if (!pointer)
      return;

    this.sceneProxy._mouseX = pointer.pageX - this.elemRect.left;
    this.sceneProxy._mouseY = pointer.pageY - this.elemRect.top;

    this.lastPointer = pointer;
  };

  this._startModalBrushRadius = function (x, y) {
    this._refX = x;
    this._refY = y;
    let cur = this.sceneProxy.getSculptManager().getTool(this.sceneProxy.getSculptManager().getToolIndex());
    if (cur._radius !== undefined) {
      let rad = cur._radius;
      this._refX -= rad;
    }
  };

  this._startModalBrushIntensity = function (x, y) {
    this._refX = x;
    this._refY = y;
  };

  this.getPickingPosition = function () {
    if (this.pickingSphere.visible)
      return this.pickingSphere.position.clone();
  };

  this.getSymmetricPlaneCoeffs = function () {
    let o = this.sMesh.getSymmetryOrigin();
    let n = this.sMesh.getSymmetryNormal();
    let off = this.sMesh.getSymmetryOffset();

    return [n[0], n[1], n[2], -(n[0] * o[0] + n[1] * o[1] + n[2] * o[2])];
  };

  this.preUpdate = function () {
    if (this.sMeshes.length === 0) return;

    this.setMousePosition();
    this.sceneProxy.getSculptManager().preUpdate();
  };

  this.pointerDown = function (pointer, event) {
    if (this.sMeshes.length === 0) return;

    if (pointer.type === 'mouse' && pointer.button !== 0) return;

    if (!this.enabled || this.keyStates[SculptKeyCodes.Disable]) {

      if (this.dragMode === DRAG_MODE.SCULPT) {
        let button = event.which;

        if (pointer.force) {
          sculptTypes.Tablet.pressure = pointer.force;
        }

        let canEdit = false;
        if (button === MOUSE_LEFT || pointer.type === 'touch')
          canEdit = this.sceneProxy.getSculptManager().start();

        if (canEdit) {
          this.sceneProxy.getSculptManager().end();
          this.dispatchEvent({type: 'pork'});
        }
      }

      return;
    }

    if (this._modalBrushRadius)
      this.setKeyState(SculptKeyCodes.Radius, false);

    if (this._modalBrushIntensity)
      this.setKeyState(SculptKeyCodes.Intensity, false);

    // console.log('start', {...this.keyStates});

    this.startStroke();

    let mouseX = this.sceneProxy._mouseX;
    let mouseY = this.sceneProxy._mouseY;

    if (this.dragMode === DRAG_MODE.SCULPT) {
      let button = event.which;

      if (pointer.force) {
        sculptTypes.Tablet.pressure = pointer.force;
      }

      let canEdit = false;
      if (button === MOUSE_LEFT || pointer.type === 'touch')
        canEdit = this.sceneProxy.getSculptManager().start();

      if ((button === MOUSE_LEFT || pointer.type === 'touch') && canEdit)
        this.sceneProxy.setCanvasCursor('none');

      if (canEdit) {
        this.sceneProxy.setAction(sculptTypes.Enums.Action.SCULPT_EDIT);
        this.isDragging = true;
        event.preventDefault();
      }
    } else if (this.dragMode === DRAG_MODE.SYMSET) {
      this.oldSymmetryNormal = this.sMesh.getSymmetryNormal();
      this.oldSymmetryOffset = this.sMesh.getSymmetryOffset();

      if (this.sceneProxy.getPicking().getPickedFace() >= 0) {
        this.dragStartPosition = vec3.clone(this.sceneProxy.getPicking().getIntersectionPoint());
        this.isDragging = true;
      }
    }

    this.sceneProxy._lastMouseX = mouseX;
    this.sceneProxy._lastMouseY = mouseY;
  };

  this.pointerMove = function (pointer, event) {
    if (this.sMeshes.length === 0) return;
    if (!this.enabled) return;

    let mouseX = this.sceneProxy._mouseX;
    let mouseY = this.sceneProxy._mouseY;
    let action = this.sceneProxy.getAction();

    if (this.dragMode === DRAG_MODE.SCULPT) {
      let cur = this.sceneProxy.getSculptManager().getTool(this.sceneProxy.getSculptManager().getToolIndex());

      if (this._modalBrushRadius && cur._radius !== undefined) {
        let dx = event.pageX - this._refX;
        let dy = event.pageY - this._refY;
        cur._radius = Math.sqrt(dx * dx + dy * dy);
        cur._radius = Math.max(Math.min(cur._radius, 500), 0);

        this.sceneProxy._mouseX = this._refX - this.elemRect.left;
        this.sceneProxy._mouseY = this._refY - this.elemRect.top;

        this.dispatchEvent({type: 'change config'});
        this.sceneProxy.getSculptManager().preUpdate();
      } else if (this._modalBrushIntensity && cur._intensity !== undefined) {
        cur._intensity = cur._intensity + (event.pageX - this._lastPageX) / 100;
        cur._intensity = Math.max(Math.min(cur._intensity, 1), 0);

        this.sceneProxy._mouseX = this._refX - this.elemRect.left;
        this.sceneProxy._mouseY = this._refY - this.elemRect.top;

        this.dispatchEvent({type: 'change config'});
        this.sceneProxy.getSculptManager().preUpdate();
      } else {
        if (pointer.force) {
          sculptTypes.Tablet.pressure = pointer.force;
        }

        this.sceneProxy.getSculptManager().preUpdate();

        if (action === sculptTypes.Enums.Action.SCULPT_EDIT) {
          this.sceneProxy.getSculptManager().update();
          event.preventDefault();
        }
      }
    } else if (this.dragMode === DRAG_MODE.SYMSET) {
      this.sceneProxy.getSculptManager().preUpdate();

      if (this.sceneProxy.getPicking().getPickedFace() >= 0) {
        this.dragEndPosition = vec3.clone(this.sceneProxy.getPicking().getIntersectionPoint());
      } else {
        this.dragEndPosition = undefined;
      }
    }

    this.sceneProxy._lastMouseX = mouseX;
    this.sceneProxy._lastMouseY = mouseY;

    this._lastPageX = event.pageX;
    this._lastPageY = event.pageY;
  };

  this.pointerUp = function (pointer, event) {
    if (this.sMeshes.length === 0) return;

    this.isDragging = false;

    if (pointer.type === 'mouse' && pointer.button !== 0) return;

    if (this.dragMode === DRAG_MODE.SCULPT) {
      let hasChange = this.visible && this.sceneProxy.getAction() !== sculptTypes.Enums.Action.NOTHING;
      this.sceneProxy.setCanvasCursor('default');
      this.sceneProxy.getSculptManager().end();

      this.sceneProxy.setAction(sculptTypes.Enums.Action.NOTHING);

      this.sceneProxy.getStateManager().cleanNoop();

      if (hasChange)
        this.dispatchEvent({type: 'change'});
    } else if (this.dragMode === DRAG_MODE.SYMSET) {
      this.dragStartPosition = undefined;
      this.dragEndPosition = undefined;
      this.oldSymmetryNormal = undefined;
      this.oldSymmetryOffset = undefined;
      this.dragMode = DRAG_MODE.SCULPT;
    }

    // console.log('end', {...this.keyStates});

    if (!this.keyStates[SculptKeyCodes.Drag] && this.appliedKeyStates[SculptKeyCodes.Drag] !== this.keyStates[SculptKeyCodes.Drag]) {
      this.setKeyState(SculptKeyCodes.Drag, false, true);
    }

    if (!this.keyStates[SculptKeyCodes.Mask] && this.appliedKeyStates[SculptKeyCodes.Mask] !== this.keyStates[SculptKeyCodes.Mask]) {
      this.setKeyState(SculptKeyCodes.Mask, false, true);
    }

    if (!this.keyStates[SculptKeyCodes.Smooth] && this.appliedKeyStates[SculptKeyCodes.Smooth] !== this.keyStates[SculptKeyCodes.Smooth]) {
      this.setKeyState(SculptKeyCodes.Smooth, false, true);
    }

    if (!this.keyStates[SculptKeyCodes.Negative] && this.appliedKeyStates[SculptKeyCodes.Negative] !== this.keyStates[SculptKeyCodes.Negative]) {
      this.setKeyState(SculptKeyCodes.Negative, false, true);
    }

    if (this.keyStates[SculptKeyCodes.Drag] && this.appliedKeyStates[SculptKeyCodes.Drag] !== this.keyStates[SculptKeyCodes.Drag]) {
      this.setKeyState(SculptKeyCodes.Drag, true, true);
    }

    if (this.keyStates[SculptKeyCodes.Mask] && this.appliedKeyStates[SculptKeyCodes.Mask] !== this.keyStates[SculptKeyCodes.Mask]) {
      this.setKeyState(SculptKeyCodes.Mask, true, true);
    }

    if (this.keyStates[SculptKeyCodes.Smooth] && this.appliedKeyStates[SculptKeyCodes.Smooth] !== this.keyStates[SculptKeyCodes.Smooth]) {
      this.setKeyState(SculptKeyCodes.Smooth, true, true);
    }

    if (this.keyStates[SculptKeyCodes.Negative] && this.appliedKeyStates[SculptKeyCodes.Negative] !== this.keyStates[SculptKeyCodes.Negative]) {
      this.setKeyState(SculptKeyCodes.Negative, true, true);
    }

  };

  this.createPickingCylinder = function () {
    let cylinderGeometry = new CylinderBufferGeometry(10, 10, 0, 72);
    let mesh = new Mesh(cylinderGeometry, this.materials.pickingCylinder);
    mesh.visible = false;

    this.controlGroup.add(mesh);
    return mesh;
  };

  this.createPickingSphere = function () {
    let sphereGeometry = new SphereBufferGeometry(1, 20, 20);
    let mesh = new Mesh(sphereGeometry, this.materials.picking);
    mesh.visible = false;

    this.controlGroup.add(mesh);
    return mesh;
  };

  this.createSymmetricCylinder = function () {
    let cylinderGeometry = new CylinderBufferGeometry(1, 1, 0, 72);
    let mesh = new Mesh(cylinderGeometry, this.materials.symmetricCylinder);
    mesh.visible = false;

    this.controlGroup.add(mesh);
    return mesh;
  };

  this.createPickingMaterial = function () {
    return new MeshBasicMaterial({color: 0xff0000, transparent: true, depthTest: false});
  };

  this.createPickingCylinderMaterial = function () {
    return new MeshBasicMaterial({color: 0xff0000, opacity: 0.2, transparent: true, depthTest: false});
  };

  this.createSymmetricCylinderMaterial = function () {
    return new MeshBasicMaterial({color: 0x0000ff, opacity: 0.2, transparent: true});
  };

  this.materials = {
    picking: this.createPickingMaterial(),
    pickingCylinder: this.createPickingCylinderMaterial(),
    symmetricCylinder: this.createSymmetricCylinderMaterial(),
  };

  // Children
  this.controlGroup = new Group();
  this.controlGroup.name = `sculpt-control-group`;
  this.controlGroup.matrixAutoUpdate = true;
  this.pickingSphere = this.createPickingSphere();
  this.pickingCylinder = this.createPickingCylinder();
  this.pickingSymSphere = this.createPickingSphere();
  this.symmetricCylinder = this.createSymmetricCylinder();

  this.scene.add(this.controlGroup);

  this.getUnmaskedMesh = function (index) {
    let mesh = this.sMeshes[index];
    if (mesh) {
      let staticMesh = this.convertToStaticMesh(mesh);

      let masking = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.MASKING);
      let newMesh = masking.extractRemaining(staticMesh);

      if (newMesh) {
        return getMeshFromSculptMesh(newMesh);
      }
    }
  };

  this.getMaskedMesh = function (index) {
    let mesh = this.sMeshes[index];
    if (mesh) {
      let staticMesh = this.convertToStaticMesh(mesh);

      let masking = this.sceneProxy.getSculptManager().getTool(sculptTypes.Enums.SculptTools.MASKING);
      let newMesh = masking.extract(staticMesh);

      if (newMesh) {
        return getMeshFromSculptMesh(newMesh);
      }
    }
  };

  // this.setMesh = function (mesh) {
  //   if (this.sMeshes !== mesh) {
  //     this.sculptData = mesh;
  //     this.dispatchEvent({type: 'change'});
  //   }
  //
  //   this.dispatchEvent({type: 'change config'});
  //   this.render();
  //   return this.sMeshes;
  // };

  this.render = function () {
    for (let i = 0; i < this.sMeshes.length; ++i) {
      let sMesh = this.sMeshes[i];
      let mesh = this.meshes[i];
      let wfMesh = this.wfMeshes[i];
      let indexBuffer = sMesh.getIndexBuffer();

      if (!indexBuffer || indexBuffer.length === 0)
        sMesh.updateBuffers();

      mesh.matrix = getThreeTransformFromMat4(sMesh.getMatrix());
      wfMesh.matrix = getThreeTransformFromMat4(sMesh.getMatrix());

      // this is for wireframe update. temporary roundabout.
      let oldVersion = mesh.geometry.index.version;
      mesh.geometry.index = new Uint32BufferAttribute(sMesh.getIndexBuffer(), 1);
      mesh.geometry.index.version = oldVersion + 1;

      // this is for avoiding model not showing up because of CPU frustum culling.
      if (mesh.geometry.boundingSphere)
        mesh.geometry.boundingSphere.set( new Vector3(), Infinity );

      mesh.geometry.attributes.position = new Float32BufferAttribute(sMesh.getVertexBuffer(), 3);

      if (mesh.geometry.attributes.masking)
        mesh.geometry.attributes.masking = new Float32BufferAttribute(sMesh.getMaskingBuffer(), 1);

      if (mesh.geometry.attributes.normal)
        mesh.geometry.attributes.normal = new Float32BufferAttribute(sMesh.getNormalBuffer(), 3);
    }
    this.dispatchEvent({type: 'render'});
  };

};

SculptControl.prototype = Object.assign(
  Object.create(Object3D.prototype),
  Object.create(EventDispatcher.prototype),
  Object.create(Sculpt.prototype),
  {

    constructor: SculptControl,

    isSculptControls: true

  });

SculptControl = (function (original) {

  function SculptControl() {

    original.apply(this, arguments);   // apply constructor

    let _attachSculpt = this.attachSculpt;
    let _detach = this.detach;
    let _applyStroke = this.applyStroke;
    let _setConfig = this.setConfig;
    let _undo = this.undo;
    let _redo = this.redo;
    let _setMesh = this.setMesh;
    let _replaceMesh = this.replaceMesh;

    this.attachSculpt = function (sMeshes, meshes, wfMeshes) {
      _attachSculpt.call(this, sMeshes);

      this.meshes = meshes;
      this.wfMeshes = wfMeshes;
      this.visible = true;

      this.refresh();
    };

    this.detach = function () {
      _detach.call(this);

      this.meshes = [];
      this.wfMeshes = [];
      this.visible = false;
    };

    this.setMesh = function (mesh) {
      console.log('sculpt control set mesh'/*, mesh*/);
      let result = _setMesh.call(this, mesh);

      this.render();
      return result;
    };

    this.undo = function () {
      console.log('sculpt control undo');
      let result = _undo.call(this);

      this.dispatchEvent({type: 'change config'});
      return result;
    };

    this.redo = function () {
      console.log('sculpt control redo');
      let result = _redo.call(this);

      this.dispatchEvent({type: 'change config'});
      return result;
    };

    this.applyStroke = function (stroke) {
      _applyStroke.call(this, stroke);
      this.dispatchEvent({type: 'change config'});
    };

    this.setConfig = function (config, noCommit) {
      let hasCommit = _setConfig.call(this, config, noCommit);

      if (config.tool) {
        if (config.tool.changeSymmetryPlane)
          this.dragMode = DRAG_MODE.SYMSET;
        if (config.tool.symmetryPlaneX)
          this.setMeshSymmetry(vec3.fromValues(1, 0, 0), 0);
        if (config.tool.symmetryPlaneY)
          this.setMeshSymmetry(vec3.fromValues(0, 1, 0), 0);
        if (config.tool.symmetryPlaneZ)
          this.setMeshSymmetry(vec3.fromValues(0, 0, 1), 0);
      }

      if (config.rendering) {
        let needsUpdate = false;

        if (config.rendering.shader !== undefined && this._rendering.shader !== config.rendering.shader) {
          this._rendering.shader = config.rendering.shader;
          needsUpdate = true;
        }

        if (config.rendering.matcapImage !== undefined && this._rendering.matcapImage !== config.rendering.matcapImage) {
          this._rendering.matcapImage = config.rendering.matcapImage;
          needsUpdate = true;
        }

        if (config.rendering.transparency !== undefined && this._rendering.transparency !== config.rendering.transparency) {
          this._rendering.transparency = config.rendering.transparency;
          needsUpdate = true;
        }

        if (config.rendering.flatShading !== undefined && this._rendering.flatShading !== config.rendering.flatShading) {
          this._rendering.flatShading = config.rendering.flatShading;
          needsUpdate = true;
        }

        if (config.rendering.wireframe !== undefined && this._rendering.wireframe !== config.rendering.wireframe) {
          this._rendering.wireframe = config.rendering.wireframe;
          needsUpdate = true;
        }

        if (config.rendering.symmetricPlane !== undefined && this._rendering.symmetricPlane !== config.rendering.symmetricPlane) {
          this._rendering.symmetricPlane = config.rendering.symmetricPlane;
        }

        if (config.rendering.temporarySymmetricAxis !== undefined && this._rendering.temporarySymmetricAxis !== config.rendering.temporarySymmetricAxis) {
          this._rendering.temporarySymmetricAxis = config.rendering.temporarySymmetricAxis;
        }

        if (needsUpdate) {
          this.dispatchEvent({type: 'change material'});
        }
      }

      if (config.topology && config.topology.remesh) {
        this.dispatchEvent({
          type: 'external operation',
          operation: 'remesh',
          index: this.getIndexMesh(this.sMesh)
        });
      }

      if (config.topology && config.topology.remeshRemaining) {
        this.dispatchEvent({
          type: 'external operation',
          operation: 'remesh remaining',
          index: this.getIndexMesh(this.sMesh)
        });
      }

      if (config.topology && config.topology.mirror) {
        this.dispatchEvent({
          type: 'external operation',
          operation: 'mirror',
          index: this.getIndexMesh(this.sMesh)
        });
      }

      if (hasCommit && !noCommit) {
        this.startStroke(config);
        this.dispatchEvent({type: 'change'});
      }

      this.dispatchEvent({type: 'change config'});
      return this.collectConfig();
    };

    this.replaceMesh = function (mesh, newMesh, fireChangeEvent) {
      let result = _replaceMesh.call(this, mesh, newMesh);

      if (fireChangeEvent) {
        this.dispatchEvent({type: 'change', replace: true});
      }

      return result;
    }
  }

  SculptControl.prototype = original.prototype; // reset prototype
  SculptControl.prototype.constructor = SculptControl; // fix constructor property
  return SculptControl;
})(SculptControl);


export {SculptControl};