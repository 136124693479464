import {
  FETCH_PROFILE,
  FETCH_PROFILES,
  IProfileState,
  POST_PROFILE,
  ApiProfileActionTypes
} from "../../types/api/profile";
import {INIT_STORE} from "../../types/init";

const initialState: IProfileState = {
  save: {
    posting: false
  },
  load: {
    fetching: false
  },
  getProfiles: {
    fetching: false
  }
};

export function apiProfileReducer(
  state = initialState,
  action: ApiProfileActionTypes
): IProfileState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case FETCH_PROFILE:
      return {
        ...state,
        load: {
          fetching: action.payload.fetching,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    case FETCH_PROFILES:
      return {
        ...state,
        getProfiles: {
          fetching: action.payload.fetching,
          message: action.payload.message,
          items: action.payload.items
        }
      };
    case POST_PROFILE:
      return {
        ...state,
        save: {
          posting: action.payload.posting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    default:
      return state;
  }
}