import {WompCurveData} from "../WompObject";
import {vec3} from "gl-matrix";

export function getCurveFromArray(points: vec3[], periodic: boolean, bezier: boolean): WompCurveData | undefined {
  if (points.length < 2) return;

  let pointArray: number[][] = [];

  for (let point of points)
    pointArray.push([point[0], point[1], point[2]]);

  return {
    points: pointArray,
    periodic,
    bezier,
    degree: Math.max(1, Math.min(3, points.length - 1))
  };
}