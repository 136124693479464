import axios from "axios";
import {apiRootPath} from "./const";
import {IProfile, IWomp20Water} from "../store/types/types";

export function filterWaiters(waiters: any[]): IWomp20Water[] {
  return waiters.map(waiter => filterWaiter(waiter));
}

export function filterWaiter(waiter: any): IWomp20Water {
  return {
    ...waiter
  }
}

export function _fetchWaiter(email: string) {
  return axios.get(apiRootPath + `/api/get-waiter`, {params: {email}});
}

export function _fetchWaiters() {
  return axios.get(apiRootPath + `/api/waiters/`);
}

export function _postWaiter(email: string, point?: number) {
  return axios.post(apiRootPath + `/api/post-waiter`, {email, point});
}

export function _postWaiterVideo(email: string, title: string, video: string, model?: string) {
  return axios.post(apiRootPath + `/api/post-waiter-video`, {email,title, video, model});
}

export function _postWaiterAction(email: string, action: string) {
  return axios.post(apiRootPath + `/api/post-waiter-action`, {email, action});
}

export function _putWaiter(id: number, newValues: Partial<IWomp20Water>) {
  return axios.patch(apiRootPath + `/api/waiters/${id}/`, newValues);
}

export function _putCurrentWaiter(id: number, newValues: Partial<IWomp20Water>) {
  return axios.patch(apiRootPath + `/api/waiters-simple/${id}/`, newValues);
}

export function _sendWaiterInviteRemindEmail(name: string, email: string) {
  return axios.post(apiRootPath + `/api/post-send-waiter-invite-remind-email`, {name, email});
}

export function _sendWaiterInviteEmail(name: string, email: string) {
  return axios.post(apiRootPath + `/api/post-send-waiter-invite-email`, {name, email});
}