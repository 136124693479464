import {Calc, IModelGeometryInfo, RenderedObjectTypes, Scene} from '../processor';
import {stlExporter} from "../utils";
import {vec3} from "gl-matrix";
import {
  expandBoundingBoxByPoint,
  getBoundingBoxFromMesh,
  getRenderedGeometry,
  unionBoundingBox,
  WompMeshData
} from "../WompObject";
import {getThreeGeometryFromMeshData} from "../converter";
import {three as threeTypes} from "../types/three";
import {pigeon as pigeonTypes} from "../types";

export function getRenderedObjectsBoundingBox(objects: { mesh: any, type: RenderedObjectTypes }[]): vec3[] {
  let boundingBox: vec3[] = [vec3.fromValues(+Infinity, +Infinity, +Infinity), vec3.fromValues(-Infinity, -Infinity, -Infinity)];

  for (let object of objects) {
    switch (object.type) {
      case RenderedObjectTypes.Mesh:
      case RenderedObjectTypes.Brep:
      case RenderedObjectTypes.Line:
        let mesh = object.mesh;
        if (mesh)
          unionBoundingBox(boundingBox, getBoundingBoxFromMesh(mesh));
        break;
      case RenderedObjectTypes.Vertex:
        let point = object.mesh as vec3;
        expandBoundingBoxByPoint(boundingBox, point);
        break;
    }
  }

  return boundingBox;
}

export function exportGeometriesToSTL(geometries: WompMeshData[]) {
  if (geometries.length > 0) {
    let group = new threeTypes.Group();
    let material = new threeTypes.MeshBasicMaterial({});
    for (let geometry of geometries) {
      group.add(new threeTypes.Mesh(getThreeGeometryFromMeshData(geometry), material));
    }

    // let res = stlExporter.parse(group);
    // return new Blob([res], {type: 'application/vnd.ms-pki.stl'});

    let res = stlExporter.parse(group, {binary: true});
    let buffer = (res as unknown as DataView).buffer;

    return new Blob([buffer], {type: 'application/vnd.ms-pki.stl'});
  } else {
    return new Blob([''], {type: 'application/vnd.ms-pki.stl'});
  }
}

export function exportRenderedObjectsToSTL(objects: any[]) {
  let geometries = [];
  for (let object of objects) {
    geometries.push({face: object.face, position: object.position, normal: object.normal});
  }

  return exportGeometriesToSTL(geometries);
}

export async function exportCalcsToSTL(scene: Scene, calcIds: string[]): Promise<Blob> {
  let objects = [];

  for (let calcId of calcIds) {
    let calc = scene.calcs[calcId];

    if (calc !== undefined && !calc.internal) {
      let renderedObjs = calc.generateRenderedFullObjects();

      for (let object of renderedObjs) {
        switch (object.type) {
          case RenderedObjectTypes.Mesh:
          case RenderedObjectTypes.Brep:
            objects.push(getRenderedGeometry(object.mesh));
            break;
          default:
            break;
        }
      }
    }
  }

  return exportRenderedObjectsToSTL(objects)
}

export async function exportCalcsToBREP(scene: Scene, calcIds: string[]): Promise<Blob> {
  for (let calcId of calcIds) {
    let calc = scene.calcs[calcId];

    if (calc !== undefined && !calc.internal) {
      let renderedObjs = calc.generateRenderedFullObjects();

      for (let object of renderedObjs) {
        switch (object.type) {
          case RenderedObjectTypes.Brep:
            if (object.brep) {
              return new Blob([await pigeonTypes.saveBREP(object.brep)], {type: 'application/octet-stream'});
            }
            break;
          default:
            break;
        }
      }
    }
  }

  return new Blob();
}

export function exportSceneToSTL(scene: Scene): Blob {
  let objects = [];
  for (let calcId of scene.calcIds) {
    let calc = scene.calcs[calcId];

    if (calc !== undefined && calc.getNextCalcs().length === 0 && !calc.internal && calc.visible) {
      let renderedObjs = calc.generateRenderedFullObjects();

      for (let object of renderedObjs) {
        switch (object.type) {
          case RenderedObjectTypes.Mesh:
          case RenderedObjectTypes.Brep:
            objects.push(getRenderedGeometry(object.mesh));
            break;
          default:
            break;
        }
      }
    }
  }

  return exportRenderedObjectsToSTL(objects);
}
