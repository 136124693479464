import {ComponentTypes} from "../types";
import {createSimpleGumball} from "../gumball";
import {Param_Compound, ParamTitles} from "../../parameter";
import {WompObjectRef} from "../../../WompObject";
import {ObjectBoolean} from "../base";

export class UnionModifier extends ObjectBoolean {
  static prevVoided = true;

  static create() {

    let calc = createSimpleGumball();
    calc.component = ComponentTypes.UnionModifier;
    calc.title = 'union';

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let unionParam = Param_Compound.create(calc, ParamTitles.Union, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(unionParam);

    return calc;

  }

  static getDesc(infos: { [key: string]: { value: WompObjectRef, voided: boolean } }) {
    let descs = Object.keys(infos).sort();
    let desc = `union[${descs.map(d => (infos[d].voided ? '-' : '') + d).join(',')}]`;
    return desc;
  }

  static getOperation() {
    return 'union';
  }
}
