import * as React from "react";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {IAppState} from "../../../store";
import {withRef} from "../common/WithRef";
import LoadingDots from "../common/LoadingDots";
import ProgressBar from "../common/ProgressBar";
import {IFetchItemAsync} from "../../../store/types/types";

export interface IProjectLoadingBarProps extends React.HTMLProps<HTMLDivElement> {
  projectId: number

  load: { [key: number]: IFetchItemAsync<number> }
}

class ProjectLoadingBar extends React.PureComponent<IProjectLoadingBarProps> {

  render() {
    const {load, projectId} = this.props;
    let percentage = 0;
    let fetching = load[projectId] && load[projectId].fetching;
    if (load[projectId] && load[projectId].extraInfo)
      percentage = load[projectId].extraInfo.percentage;

    if (fetching) {
      return (
        <div className="absolute absolute--fill flex flex-column justify-center items-center z-1">
          <p className="mb2">
            <span>loading model </span>
            <LoadingDots/>
          </p>
          <ProgressBar percentage={percentage}/>
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    load: store.api.project.load,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(withRef(ProjectLoadingBar));

