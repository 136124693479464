import {proxy, wrap} from "comlink";

let pigeon: any = {};

export async function initPigeonOnWorker(onProgress?: (percentage: number) => void) {
  pigeon.worker = new Worker('./pigeon-worker', {name: 'pigeon-worker', type: 'module'});
  pigeon.workerApi = wrap<import('./pigeon-worker').PeregrineWorker>(pigeon.worker);
  return new Promise<void>((resolve, reject) => {
    console.log('pigeon worker started');
    pigeon.workerApi.initPigeon(onProgress ? proxy(onProgress) : undefined).then(async (result: any) => {
      console.log('pigeon worker success');
      resolve();
    }).catch((err: any) => {
      console.log('pigeon worker failure', err);
      reject();
    });
  });
}

export async function callPigeonOnWorker(params: {func: string, returnType: string, paramTypes: string[], params: any[]}, onProgress?: (percentage: number) => void) {
  return new Promise<any>((resolve, reject) => {
    console.log('pigeon worker started');
    pigeon.workerApi.callPigeonTrans(params, onProgress ? proxy(onProgress) : undefined).then(async (result: any) => {
      console.log('pigeon worker success');
      resolve(result);
    }).catch((err: any) => {
      console.log('pigeon worker failure', err);
      reject();
    });
  });
}