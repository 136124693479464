import * as React from 'react';
import {pure} from 'recompose';

interface IInlineVideoProps extends React.HTMLProps<HTMLVideoElement> {
}

const InlineVideo: React.FC<IInlineVideoProps> = ({children, ...props}) => {
  return (
    <video {...props} {...({playsInline: "playsinline"} as any)}>
      {children}
    </video>
  );
};

export default pure(InlineVideo);
