import {
  CHANGE_CONFIGURATION,
  CHANGE_PEREGRINE_ENGINE_LOADED,
  GlobalActionTypes,
  CHANGE_WAITER
} from "../../types/entity/global";
import {IConfiguration, IWomp20Water} from "../../types/types";

export function changePeregrineEngineLoaded(loaded: boolean): GlobalActionTypes {
  return {
    type: CHANGE_PEREGRINE_ENGINE_LOADED,
    payload: {
      loaded
    }
  };
}

export function changeConfiguration(configuration?: Partial<IConfiguration>): GlobalActionTypes {
  return {
    type: CHANGE_CONFIGURATION,
    payload: {
      configuration
    }
  };
}

export function changeWaiter(waiter?: IWomp20Water): GlobalActionTypes {
  return {
    type: CHANGE_WAITER,
    payload: {
      waiter
    }
  };
}