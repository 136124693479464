import {WompMeshData} from "../WompObject";
import {three as threeTypes} from "../types";
import {getThreeGeometryFromMeshData} from "../converter/three";

export function reorientGeometry(geometry: WompMeshData) {

  if (!geometry.face || !geometry.position) {

    return;

  }

  let threeGeom = getThreeGeometryFromMeshData(geometry);
  let a = 0, b = 1, c = 2;
  let cb = new threeTypes.Vector3(), ab = new threeTypes.Vector3(), fc = new threeTypes.Vector3();

  if (threeGeom.index) {

    a = threeGeom.index.array[0];
    b = threeGeom.index.array[1];
    c = threeGeom.index.array[2];

  }

  let vA = new threeTypes.Vector3(threeGeom.attributes.position.array[a * 3], threeGeom.attributes.position.array[a * 3 + 1], threeGeom.attributes.position.array[a * 3 + 2]);
  let vB = new threeTypes.Vector3(threeGeom.attributes.position.array[b * 3], threeGeom.attributes.position.array[b * 3 + 1], threeGeom.attributes.position.array[b * 3 + 2]);
  let vC = new threeTypes.Vector3(threeGeom.attributes.position.array[c * 3], threeGeom.attributes.position.array[c * 3 + 1], threeGeom.attributes.position.array[c * 3 + 2]);

  fc.add(vA).add(vB).add(vC).divideScalar(3);

  cb.subVectors(vC, vB);
  ab.subVectors(vA, vB);
  cb.cross(ab);

  cb.normalize();

  threeGeom.computeBoundingSphere();

  if (!threeGeom.boundingSphere) {

    return;

  }

  let ray = new threeTypes.Ray(fc, cb);

  let origin = ray.intersectSphere(new threeTypes.Sphere(threeGeom.boundingSphere.center, threeGeom.boundingSphere.radius * 2), new threeTypes.Vector3());

  if (origin === null) {

    console.warn('object outside ray point search failed');
    return;

  }

  let raycaster = new threeTypes.Raycaster(origin, cb.multiplyScalar(-1));

  let intersects = raycaster.intersectObject(new threeTypes.Mesh(threeGeom, new threeTypes.MeshBasicMaterial({side: threeTypes.DoubleSide})));

  if (!intersects) {

    console.warn('raycast to object failed');
    return;

  }

  let turnedOut = false;

  for (let intersect of intersects) {

    if (intersect.face) {

      turnedOut = intersect.face.normal.dot(cb) > 0;
      break;

    }

  }

  if (turnedOut) {

    for (let i = 0; i < geometry.face.length; i += 3) {

      let t = geometry.face[i];
      geometry.face[i] = geometry.face[i + 2];
      geometry.face[i + 2] = t;

    }

  }

}