import {mat4, quat, vec3, vec4} from "gl-matrix";

const r_map: { [key: string]: string } = {
  'ac': 'annotateColor',
  'al': 'alongNormal',
  'an': 'angle',
  'as': 'annotates',
  'at': 'annotate',
  'au': 'accumulate',
  'ax': 'axis',
  'ba': 'brush',
  'bb': 'boundingBox',
  'bc': 'basic-cone-gen',
  'bd': 'bound',
  'be': 'backgroundEnvironment',
  'bf': 'basic-surface-gen',
  'bg': 'box',
  'bh': 'basic-sphere-gen',
  'bi': 'bezier',
  'bk': 'block',
  'bl': 'boolean',
  'bm': 'blur',
  'bn': 'bounds',
  'bo': 'basic-donut-gen',
  'bp': 'basic-polyline-gen',
  'bq': 'brep-geometry',
  'br': 'basic-pyramid-gen',
  'bs': 'baseCost',
  'bt': 'basic-tube-gen',
  'bu': 'basic-cube-gen',
  'bv': 'basic-curve-gen',
  'bx': 'button',
  'by': 'basic-cylinder-gen',
  'bz': 'brep',
  'ca': 'caches',
  'cb': 'continuous',
  'cc': 'calc',
  'cd': 'calcId',
  'ce': 'clear',
  'cf': 'cameraInfo',
  'cg': 'cameraAngle',
  'ch': 'checked',
  'ci': 'calcIds',
  'cj': 'culling',
  'ck': 'canBeContinuous',
  'cl': 'clay',
  'cm': 'compound',
  'cn': 'combo',
  'co': 'collapsed',
  'cp': 'component',
  'cq': 'cameraTargetOnPick',
  'cr': 'color',
  'cs': 'calcs',
  'ct': 'cost',
  'cu': 'curve',
  'cv': 'castShadow',
  'cw': 'cut',
  'cx': 'crease',
  'cy': 'curve-geometry',
  'da': 'days',
  'de': 'degree',
  'di': 'detailInfo',
  'dm': 'digital material',
  'dn': 'digitalMaterials',
  'dr': 'drag',
  'ds': 'distance',
  'dt': 'data',
  'dv': 'defaultValue',
  'dy': 'decay',
  'dz': 'dynamicTopology',
  'el': 'editLevels',
  'en': 'end',
  'er': 'extractRemaining',
  'et': 'extract',
  'ev': 'environments',
  'ex': 'exposure',
  'fa': 'flatten',
  'fc': 'face',
  'fi': 'faceIndex',
  'fj': 'fillet',
  'fh': 'fillHole',
  'fl': 'float',
  'fo': 'font',
  'fr': 'from',
  'fs': 'flatShading',
  'gc': 'groundColor',
  'ge': 'geometry',
  'gh': 'floor',
  'gi': 'geometryId',
  'gl': 'global',
  'gm': 'magnetMappings',
  'gr': 'grid',
  'gu': 'gumball',
  'gv': 'gridVisible',
  'ha': 'hash',
  'hd': 'heatmapData',
  'he': 'height',
  'hg': 'highlighted',
  'hi': 'hidden',
  'hm': 'heatmap',
  'hn': 'hardness',
  'ho': 'hollow',
  'hr': 'shader',
  'ht': 'hdrThumbnail',
  'hv': 'helperVisible',
  'ia': 'idAlpha',
  'ib': 'isSetAsBackground',
  'ic': 'inputConfigs',
  'id': 'id',
  'ie': 'inputProperties',
  'if': 'inflate',
  'ii': 'inputIds',
  'il': 'innerLink',
  'im': 'image',
  'in': 'intensity',
  'io': 'isOperable',
  'ip': 'isInput',
  'is': 'inputs',
  'it': 'interfaceType',
  'iv': 'invert',
  'jp': 'parameter:json',
  'js': 'json',
  'ke': 'stroke',
  'lb': 'lockBound',
  'le': 'lightEnvironment',
  'lg': 'lights',
  'lh': 'light',
  'li': 'libraryItems',
  'lj': 'localScale',
  'lk': 'locked',
  'ln': 'length',
  'lo': 'loft',
  'lp': 'lockPosition',
  'ls': 'lockBounds',
  'lt': 'list',
  'ma': 'material',
  'mb': 'meshIndex',
  'mc': 'measureColor',
  'md': 'manuDays',
  'me': 'modifier-extrude',
  'mf': 'minLength',
  'mg': 'modifier-group',
  'mh': 'mesh',
  'mi': 'modifier-intersect',
  'mj': 'materialId',
  'mk': 'markupCost',
  'ml': 'maxLength',
  'mm': 'matrix',
  'mn': 'maxWidth',
  'mo': 'modifier-offset',
  'mp': 'materialPricings',
  'mq': 'maxSquare',
  'mr': 'modifier-array',
  'ms': 'measures',
  'mt': 'modifier-sculpt',
  'mu': 'modifier-union',
  'mv': 'minWidth',
  'mw': 'modifier-weld',
  'mx': 'mainAxis',
  'my': 'modelType',
  'mz': 'minSquare',
  'na': 'negative',
  'nc': 'modifier-cut',
  'nf': 'modifier-fillet',
  'ng': 'material group',
  'nh': 'modifier-hollow',
  'nl': 'modifier-loft',
  'np': 'modifier-pipe',
  'nr': 'mirror',
  'nu': 'number',
  'nv': 'modifier-revolve',
  'nz': 'non-zero-length',
  'ob': 'object',
  'oc': 'outputConfig',
  'od': 'objectId',
  'oe': 'outputProperties',
  'of': 'offset',
  'oi': 'outputIds',
  'oj': 'objects',
  'on': 'options',
  'op': 'opacity',
  'os': 'outputs',
  'ot': 'objectType',
  'ou': 'offDistance',
  'oz': 'pinch',
  'pa': 'param',
  'pb': 'parameter:boolean',
  'pc': 'param-curve',
  'pd': 'parameter:index',
  'pe': 'parameter:brep',
  'pf': 'parameter:surface',
  'pg': 'parameter:string',
  'ph': 'parameter:mesh',
  'pi': 'projectId',
  'pj': 'percent',
  'pk': 'parameter',
  'pl': 'printable',
  'pm': 'param-mesh',
  'pn': 'parameter:',
  'po': 'parameter:compound',
  'pp': 'properties',
  'pq': 'point',
  'pr': 'preview',
  'ps': 'parameter:sculpt-mesh',
  'pt': 'parameter:point',
  'pu': 'parameter:curve',
  'pv': 'param-vertex',
  'pw': 'plane',
  'px': 'parameter:vertex',
  'py': 'parameter:number',
  'pz': 'parameter:transform',
  'qa': 'periodic',
  'qb': 'points',
  'qc': 'productionCost',
  'qd': 'pipe',
  'qe': 'penumbra',
  'qf': 'param-brep',
  'ql': 'topology',
  'qp': 'position',
  'qr': 'prevIds',
  'ra': 'radius',
  'rd': 'render',
  're': 'relation',
  'rf': 'remesh',
  'rg': 'remeshRemaining',
  'ri': 'relationIds',
  'rr': 'rendering',
  'rs': 'relations',
  'rv': 'revolve',
  'sa': 'start',
  'sb': 'shipping2Days',
  'sc': 'selectedCalcIds',
  'sd': 'shipping1Days',
  'se': 'scene',
  'sf': 'surface',
  'sg': 'sculpt-geometry',
  'sh': 'shipping1Cost',
  'si': 'sourceObjectId',
  'sk': 'shipping2Cost',
  'sl': 'scale',
  'sm': 'sculpt-mesh',
  'sn': 'snapIncrement',
  'so': 'shortOptions',
  'sp': 'snap',
  'sq': 'square',
  'sr': 'string',
  'ss': 'steps',
  'st': 'step',
  'su': 'showUnits',
  'sv': 'subAxis',
  'sw': 'skew',
  'sx': 'surfaces',
  'sy': 'styles',
  'sz': 'settingGroupExpanded',
  't0': 't0',
  't1': 't1',
  't2': 't2',
  'ta': 'target',
  'tb': 'three-basic-tube-gen',
  'tc': 'three-basic-cone-gen',
  'td': 'three-basic-cylinder-gen',
  'te': 'text',
  'tf': 'sharpen',
  'tg': 'toggle',
  'th': 'thumbnail',
  'ti': 'title',
  'tj': 'thickness',
  'tk': 'topoCheck',
  'tl': 'tool',
  'tm': 'transparency',
  'tn': 'taxCost',
  'to': 'to',
  'tp': 'three-basic-pyramid-gen',
  'tq': 'targetPosition',
  'tr': 'transform',
  'ts': 'three-basic-sphere-gen',
  'tt': 'three-basic-text-gen',
  'tu': 'three-basic-cube-gen',
  'tv': 'three-basic-donut-gen',
  'tw': 'smooth',
  'tx': 'smoothing',
  'ty': 'type',
  'tz': 'three-basic-plane-gen',
  'ua': 'twist',
  'uc': 'uCurve',
  'ud': 'uniqueDesc',
  'ui': 'unsigned-int',
  'va': 'valid',
  'vc': 'vCurve',
  'vi': 'visible',
  'vl': 'value',
  'vo': 'voided',
  'vp': 'voxelRemesh',
  'vq': 'voxelRemeshRemaining',
  'vr': 'version',
  'vs': 'values',
  'vt': 'valueTypes',
  'vx': 'vertex',
  'vy': 'viewType',
  'wa': 'weldAngle',
  'wf': 'wireframe',
  'wi': 'width',
  'yp': 'symmetricPlane',
  'za': 'showMatcapImage',
  'zb': 'matcapImage',
  'zc': 'sculptTool',
  'zd': 'symmetry',
  'ze': 'symmetryPlaneX',
  'zf': 'symmetryPlaneY',
  'zg': 'symmetryPlaneZ',
  'zh': 'changeSymmetryPlane',
  'zk': 'move',
  'zl': 'masking',
  'zm': 'snapToGrid',
  'zn': 'snapToObjects',
  'zo': 'zoom',
  'zp': 'measure',
  'zq': 'sculpt',
  'zr': 'toolConfig',
  'zs': 'processors',
  'zt': 'measureUnit'
};

let t_map: { [key: string]: string } = {};

for (let c in r_map) {
  t_map[r_map[c]] = c;
}

// string
export function _s(s: string) {
  if (t_map[s])
    return t_map[s];
  return s;
}

export function _t(s: string) {
  if (r_map[s])
    return r_map[s];
  return s;
}

// boolean
export function _b(b: boolean) {
  return b ? 1 : 0;
}

export function _c(d: number) {
  return !!d;
}

// number
export function _n(n: number, p?: number) {
  let ret = n.toFixed(p === undefined ? 6 : p);
  if (ret.lastIndexOf('.') < 0)
    return ret;
  let lastInd = ret.length - 1;
  while (ret[lastInd] === '0') {
    --lastInd;
  }
  if (ret[lastInd] === '.') {
    --lastInd;
  }

  if (lastInd === ret.length - 1)
    return ret;

  ret = ret.substr(0, lastInd + 1);
  return ret === '-0' ? '0' : ret;
}

export function _m(num: string) {
  return isNaN(+num) ? 0 : +num;
}

// vec3
export function _v(v: vec3, p?: number): string[] {
  return [
    _n(v[0], p),
    _n(v[1], p),
    _n(v[2], p)
  ];
}

export function _w(v: any[]): vec3 {
  if (v.length !== 3)
    return vec3.create();

  return vec3.fromValues(
    _m(v[0]),
    _m(v[1]),
    _m(v[2])
  );
}

// vec4
export function _v4(v: vec4, p?: number): string[] {
  return [
    _n(v[0], p),
    _n(v[1], p),
    _n(v[2], p),
    _n(v[3], p)
  ];
}

export function _w4(v: any[]): vec4 {
  if (v.length !== 4)
    return vec4.create();

  return vec4.fromValues(
    _m(v[0]),
    _m(v[1]),
    _m(v[2]),
    _m(v[3])
  );
}

// quat
export function _vq(v: quat, p?: number): string[] {
  let q = [
    +_n(v[0], p),
    +_n(v[1], p),
    +_n(v[2], p),
    +_n(v[3], p)
  ]
  if (q[0] < 0 || (q[0] === 0 && q[1] < 0) || (q[0] === 0 && q[1] === 0 && q[2] < 0) || (q[0] === 0 && q[1] === 0 && q[2] === 0 && q[3] < 0))
    return [
      _n(-v[0], p),
      _n(-v[1], p),
      _n(-v[2], p),
      _n(-v[3], p)
    ];

  return [
    _n(v[0], p),
    _n(v[1], p),
    _n(v[2], p),
    _n(v[3], p)
  ];
}

export function _wq(v: any[]): quat {
  if (v.length !== 4)
    return quat.create();

  return quat.fromValues(
    _m(v[0]),
    _m(v[1]),
    _m(v[2]),
    _m(v[3])
  );
}

// mat4
export function _p(v: mat4, p?: number): string[] {
  return [
    _n(v[0], p),
    _n(v[1], p),
    _n(v[2], p),
    _n(v[3], p),
    _n(v[4], p),
    _n(v[5], p),
    _n(v[6], p),
    _n(v[7], p),
    _n(v[8], p),
    _n(v[9], p),
    _n(v[10], p),
    _n(v[11], p),
    _n(v[12], p),
    _n(v[13], p),
    _n(v[14], p),
    _n(v[15], p)
  ];
}

export function _q(v: any[]): mat4 {
  if (v.length !== 16)
    return mat4.create();

  return mat4.fromValues(
    _m(v[0]),
    _m(v[1]),
    _m(v[2]),
    _m(v[3]),
    _m(v[4]),
    _m(v[5]),
    _m(v[6]),
    _m(v[7]),
    _m(v[8]),
    _m(v[9]),
    _m(v[10]),
    _m(v[11]),
    _m(v[12]),
    _m(v[13]),
    _m(v[14]),
    _m(v[15])
  );
}

export function isShallowEqual(v: any, o: any) {
  if (!v && o)
    return false;

  if (v && !o)
    return false;

  if (!v && !o && v === o)
    return true;

  for (let key in v)
    if (!(key in o) || v[key] !== o[key])
      return false;

  for (let key in o)
    if (!(key in v) || v[key] !== o[key])
      return false;

  return true;
}

export function matrixEncodeEqual(m1: mat4, m2: mat4) {

  return _p(m1).join(',') === _p(m2).join(',');

}
