import {ComponentTypes} from "../types";
import {three as threeTypes} from "../../../types";
import {createGumball} from "../gumball";
import {Param_Mesh, ParamTitles} from "../../parameter";
import {Calc} from "../../calc";
import {getMeshDataFromThreeGeometry} from "../../../converter";
import {WompMesh} from "../../../WompObject";
import {mat4, vec3} from "gl-matrix";
import {BasicMeshGenerator} from "./basic-gen";

export class ThreePyramidGenerator extends BasicMeshGenerator {
  static defaultParameter = {
    width: 20,
    height: 20,
    depth: 20
  };
  static draggingPreview = ThreePyramidGenerator.generateMesh();

  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.ThreePyramidGenerator;
    calc.title = 'pyramid';

    let pyramidParam = Param_Mesh.create(calc, ParamTitles.Pyramid, false, false, true);

    calc.addParameter(pyramidParam);

    return calc;
  }

  static getDesc(calc: Calc) {
    return 'pyramid';
  }

  static generateMesh(parameter: any = this.defaultParameter) {
    let value = new threeTypes.BufferGeometry();
    let w = this.defaultParameter.width;
    let d = this.defaultParameter.depth;
    let h = this.defaultParameter.height;

    let vertices = [
      [-w / 2, -d / 2, -h / 2],
      [-w / 2, -d / 2, -h / 2],
      [-w / 2, -d / 2, -h / 2],
      [-w / 2, d / 2, -h / 2],
      [-w / 2, d / 2, -h / 2],
      [-w / 2, d / 2, -h / 2],
      [w / 2, d / 2, -h / 2],
      [w / 2, d / 2, -h / 2],
      [w / 2, d / 2, -h / 2],
      [w / 2, -d / 2, -h / 2],
      [w / 2, -d / 2, -h / 2],
      [w / 2, -d / 2, -h / 2],
      [0, 0, h / 2],
      [0, 0, h / 2],
      [0, 0, h / 2],
      [0, 0, h / 2]
    ];

    let faces = [
      [0, 3, 6],
      [0, 6, 9],
      [12, 4, 1],
      [13, 7, 5],
      [14, 10, 8],
      [15, 2, 11]
    ];

    let indexArray = new Uint32Array(faces.length * 3);
    let positionArray = new Float32Array(vertices.length * 3);

    for (let i = 0; i < faces.length; ++i) {
      indexArray.set(faces[i], i * 3);
    }

    for (let i = 0; i < vertices.length; ++i) {
      positionArray.set(vertices[i], i * 3);
    }

    value.index = new threeTypes.Uint32BufferAttribute(indexArray, 1);
    value.attributes.position = new threeTypes.Float32BufferAttribute(positionArray, 3);
    value.computeVertexNormals();

    let geom = getMeshDataFromThreeGeometry(value);

    return new WompMesh(geom, this.generateMatrix(parameter));
  }

  static generateMatrix(parameter: any = this.defaultParameter) {
    return mat4.fromScaling(
      mat4.create(),
      vec3.fromValues(
        parameter.width / this.defaultParameter.width,
        parameter.depth / this.defaultParameter.depth,
        parameter.height / this.defaultParameter.height
      )
    );
  }
}
