import {IGAR} from "../../../store/types/types";
import Editor3dWrapper from "../../3d/Editor3dWrapper";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {store} from "../../../index";
import {batch} from "react-redux";
import {
  fetchRouteStarted,
  fetchRouteSuccess,
  postChangeCursorStarted,
  postChangeCursorSuccess,
  postRouteFailure,
  postRouteSuccess
} from "../../../store/actions/api/scene";
import {
  addProgress,
  changeProgressRange,
  changeSyncStatus,
  removeProgress,
  updateCommandManager
} from "../../../store/actions/logic/project";
import {getGlobalObjects, processAuthentication, refreshScene} from "../global";
import {_s} from "../../../peregrine/t";
import {_postRoute} from "../../../api/project";
import {ICommand} from "../../core";
import {addLibraryItems, removeLibraryItems} from "../../../store/actions/entity/library-items";
import {addDigitalMaterials, removeDigitalMaterial} from "../../../store/actions/entity/digital-materials";
import {addEnvironments, removeEnvironment} from "../../../store/actions/entity/environments";
import {Tools} from "../../../peregrine/processor";

const getLibraryActions = (applyCommands: ICommand[], reverseCommands: ICommand[]) => {
  let libraryActions = [];

  for (let op of [...reverseCommands.flatMap(c => c.reverseOps), ...applyCommands.flatMap(c => c.applyOps)]) {
    let libraryItemMatches = new RegExp(`^/${_s('libraryItems')}/(;[a-zA-Z0-9-_]{22})$`, 'g').exec(op.path);
    if (libraryItemMatches !== null && libraryItemMatches.length > 1) {
      let id = libraryItemMatches[1];

      if (op.op === 'add' || op.op === 'replace') {
        libraryActions.push(addLibraryItems([op.value]));
      } else if (op.op === 'remove') {
        libraryActions.push(removeLibraryItems([id]));
      }
    }

    let digitalMaterialMatches = new RegExp(`^/${_s('digitalMaterials')}/(;[a-zA-Z0-9-_]{22})$`, 'g').exec(op.path);
    if (digitalMaterialMatches !== null && digitalMaterialMatches.length > 1) {
      let id = digitalMaterialMatches[1];

      if (op.op === 'add' || op.op === 'replace') {
        libraryActions.push(addDigitalMaterials([op.value]));
      } else if (op.op === 'remove') {
        libraryActions.push(removeDigitalMaterial(id));
      }
    }

    let environmentMatches = new RegExp(`^/${_s('environments')}/(;[a-zA-Z0-9-_]{22})$`, 'g').exec(op.path);
    if (environmentMatches !== null && environmentMatches.length > 1) {
      let id = environmentMatches[1];

      if (op.op === 'add' || op.op === 'replace') {
        libraryActions.push(addEnvironments([op.value]));
      } else if (op.op === 'remove') {
        libraryActions.push(removeEnvironment(id));
      }
    }
  }

  return libraryActions;
};

export const redo = (projectId: number, wrapper: Editor3dWrapper, insideSculpt?: boolean): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    const {scene} = getGlobalObjects(projectId);

    if (insideSculpt && scene.tool === Tools.Sculpt) {
      wrapper.editor && wrapper.editor.sculptControl.redo();
      return {success: true};
    } else {
      return dispatch(changeCursor(projectId, wrapper, scene.commandManager.cursor + 1));
    }
  };
};

export const undo = (projectId: number, wrapper: Editor3dWrapper, insideSculpt?: boolean): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    const {scene} = getGlobalObjects(projectId);

    if (insideSculpt && scene.tool === Tools.Sculpt) {
      wrapper.editor && wrapper.editor.sculptControl.undo();
      return {success: true};
    } else {
      return dispatch(changeCursor(projectId, wrapper, scene.commandManager.cursor - 1));
    }
  };
};

export const changeCursor = (projectId: number, wrapper: Editor3dWrapper, cursor: number, clear?: boolean): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    const {scene} = getGlobalObjects(projectId);

    // console.log('change cursor');
    batch(() => {
      dispatch(postChangeCursorStarted(projectId));
      dispatch(addProgress('edit: change cursor'));
    });
    let commandAvailable = false;

    if (scene) {
      if (cursor >= 0 && cursor <= scene.commandManager.commands.length) {
        let needRefresh = await scene.runExclusive(async () => {
          let [applyCommands, reverseCommands] = scene.commandManager.moveTo(cursor);

          if (clear) {
            scene.commandManager.clearAfterCursor();
          }

          if (applyCommands.length > 0 || reverseCommands.length > 0) {
            commandAvailable = true;

            let libraryActions = getLibraryActions(applyCommands, reverseCommands);

            batch(() => {
              libraryActions.map(action => dispatch(action));
            });

            await scene.overwrite(scene.commandManager.state[_s('scene')]);
            await scene.solve();
          }

          return true;
        });

        if (needRefresh) {
          await refreshScene(projectId, dispatch, wrapper);
          dispatch(updateCommandManager(projectId, scene.commandManager.commandState));
        }
      } else if (cursor < 0 || cursor > scene.commandManager.commands.length) {
        let insertAt = cursor + scene.route.startCursor;
        if (insertAt >= 0 && insertAt <= scene.server.commands.length && !store.getState().api.scene.upRoute.posting) {
          commandAvailable = true;
          dispatch(postRoute(projectId, wrapper, scene.route.id, {insertAt, commands: []}, []));
        }
      }
    }

    batch(() => {
      dispatch(postChangeCursorSuccess(projectId));
      dispatch(removeProgress('edit: change cursor'));
    });

    return {success: commandAvailable};
  };
};

export const postRoute = (projectId: number, wrapper: Editor3dWrapper, routeId: number, data: any, files: any[]): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    const {scene} = getGlobalObjects(projectId);

    return _postRoute(projectId, routeId, scene.commandManager.commands.length, data, files)
      .then(async res => {
        let data = res.data.data;
        if (res.data.success) {
          dispatch(fetchRouteStarted(projectId));

          if (data) {
            let startCursor = scene.route.startCursor;

            let commands = scene.server.commands;
            let cursor = data.insertAt + data.commands.length;

            if (data.commands.length > 0)
              commands = [...commands.slice(0, data.insertAt), ...data.commands];

            const offset = data.offset || 0;
            const nextCommands = data.nextCommands || [];
            const prevCommands = data.prevCommands || [];

            if (offset && !(nextCommands.length || prevCommands.length)) {
              await scene.runExclusive(async () => {
                startCursor = Math.max(startCursor - offset, 0);
                cursor = Math.max(cursor - offset, 0);
                for (let i = 0; i < scene.commandManager.commands.length; ++i) {
                  if (commands[offset].id === scene.commandManager.commands[i].id) {
                    scene.commandManager.removePrevs(i);
                    break;
                  }
                }
                commands = commands.slice(offset);

                let startCommand = startCursor > 0 ? commands[startCursor - 1].id : '';
                scene.server = {cursor, commands};

                scene.route = {
                  ...scene.route,
                  startCursor,
                  startCommand
                };
              });

              batch(() => {
                dispatch(updateCommandManager(projectId, scene.commandManager.commandState));
                dispatch(changeSyncStatus(projectId, scene.route.startCursor, scene.server.commands, scene.server.cursor));
              });
            } else {
              scene.server = {cursor, commands};
              dispatch(changeSyncStatus(projectId, scene.route.startCursor, scene.server.commands, scene.server.cursor));

              if (offset || nextCommands.length || prevCommands.length) {
                await scene.runExclusive(async () => {
                  return dispatch(fetchRoute(projectId, wrapper, cursor, offset, nextCommands, prevCommands));
                });
              }
            }
          }

          batch(() => {
            dispatch(fetchRouteSuccess(projectId));
            dispatch(postRouteSuccess(0));
            dispatch(removeProgress(`edit: post-route-${routeId}`));
            dispatch(changeProgressRange(0, 100));
          });

          return {success: true};
        } else {
          batch(() => {
            dispatch(postRouteFailure(res.data.message));
            dispatch(removeProgress(`edit: post-route-${routeId}`));
            dispatch(changeProgressRange(0, 100));
          });
          return {success: false, message: res.data.message};
        }
      })
      .catch(err => {
        batch(() => {
          dispatch(postRouteFailure(err.message));
          dispatch(removeProgress(`edit: post-route-${routeId}`));
          dispatch(changeProgressRange(0, 100));
        });
        return processAuthentication(err, dispatch).then(res => {
          if (res.success) {
            return dispatch(postRoute(projectId, wrapper, routeId, data, files));
          } else {
            return res;
          }
        });
      });
  };
};

export const fetchRoute = (projectId: number, wrapper: Editor3dWrapper, insertAt: number, offset: number, nextCommands: ICommand[], prevCommands: ICommand[]): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    const {scene} = getGlobalObjects(projectId);

    let startCursor = scene.route.startCursor;

    if (prevCommands) {
      scene.commandManager.addPrevs(prevCommands);
      startCursor -= prevCommands.length;
    }

    insertAt -= startCursor;

    let applyCommands = [], reverseCommands = [];
    while (insertAt < scene.commandManager.cursor) {
      let command = scene.commandManager.undo();

      if (!command) {
        console.error('no more undo');
        break;
      }

      reverseCommands.push(command);
    }

    while (insertAt > scene.commandManager.cursor) {
      let command = scene.commandManager.redo();

      if (!command) {
        console.error('no more redo');
        break;
      }

      applyCommands.push(command);
    }

    for (let command of nextCommands) {
      scene.commandManager.add(command);
      applyCommands.push(command);
    }

    let cursor = scene.server.cursor;
    let commands = scene.server.commands;

    if (offset > 0) {
      startCursor = Math.max(startCursor - offset, 0);
      cursor = Math.max(cursor - offset, 0);
      for (let i = 0; i < scene.commandManager.commands.length; ++i) {
        if (commands[offset].id === scene.commandManager.commands[i].id) {
          scene.commandManager.removePrevs(i);
          break;
        }
      }
      commands = commands.slice(offset);
    }

    if (offset > 0)
      scene.server = {cursor, commands};

    let startCommand = startCursor > 0 ? commands[startCursor - 1].id : '';

    scene.route = {
      ...scene.route,
      startCursor,
      startCommand
    };

    if (applyCommands.length > 0 || reverseCommands.length > 0) {
      let libraryActions = getLibraryActions(applyCommands, reverseCommands);

      batch(() => {
        libraryActions.map(action => dispatch(action));
      });
    }

    if (applyCommands.length > 0 || reverseCommands.length > 0) {
      await scene.overwrite(scene.commandManager.state[_s('scene')]);
      await scene.solve();

      await refreshScene(projectId, dispatch, wrapper);
      dispatch(updateCommandManager(projectId, scene.commandManager.commandState));
      dispatch(changeSyncStatus(projectId, scene.route.startCursor, scene.server.commands, scene.server.cursor));
    }

    return {success: true};
  };
};
