import axios from "axios";
import {apiRootPath} from "./const";
import {IDigitalMaterial, ILibraryCategory, ILibraryItem, ILibraryTopCategory, IProcessor} from "../store/types/types";
import {IEnvironment, ILightPrototype} from "../peregrine/processor";
import lod from "lodash";

export function _postAddToProcessor(projectId: number, title: string, calcIds: string[], thumbnail: string, modelPreview: any) {
  return axios.post(apiRootPath + `/api/post-dp-add-to-processor`, {
    projectId, title, calcIds, thumbnail, modelPreview
  });
}

export function filterProcessorsFromLibraryItems(libraryItems: any[]): IProcessor[] {
  return libraryItems.filter((libraryItem: any) => libraryItem.processor).map((libraryItem: any) => {
    return {
      ...libraryItem.processor
    };
  });
}

export function filterDigitalMaterial(digitalMaterial: any): IDigitalMaterial {
  return {
    ...digitalMaterial,
    pbr: {
      ...digitalMaterial.pbr,
      clearcoat: +digitalMaterial.pbr.clearcoat,
      clearcoatRoughness: +digitalMaterial.pbr.clearcoatRoughness,
      metalness: +digitalMaterial.pbr.metalness,
      opacity: +digitalMaterial.pbr.opacity,
      roughness: +digitalMaterial.pbr.roughness
    }
  };
}

export function filterEnvironment(environment: any): IEnvironment {
  return {
    ...environment,
    exposure: +environment.exposure,
  }
}

export function filterDigitalMaterialsFromLibraryItems(libraryItems: any[]): IDigitalMaterial[] {
  return libraryItems.filter((libraryItem: any) => libraryItem.digitalMaterial).map(
    (libraryItem: any) => filterDigitalMaterial(libraryItem.digitalMaterial)
  );
}

export function filterEnvironmentsFromLibraryItems(libraryItems: any[]): IEnvironment[] {
  return libraryItems.filter((libraryItem: any) => libraryItem.environment).map(
    (libraryItem: any) => filterEnvironment(libraryItem.environment)
  );
}

export function filterLightsFromLibraryItems(libraryItems: any[]): ILightPrototype[] {
  return libraryItems.filter((libraryItem: any) => libraryItem.light).map((libraryItem: any) => {
    return {
      ...libraryItem.light
    };
  });
}

export function filterLibraryItems(libraryItems: any[]): ILibraryItem[] {
  return libraryItems.map((libraryItem: any) => {
    return {
      ...libraryItem,
      projectId: libraryItem.projectId || 0,
      username: libraryItem.username || 0
    };
  });
}

export function removeUnusedFieldsFromLibraryItems(libraryItems: any[]): ILibraryItem[] {
  return libraryItems.map(removeUnusedFieldsFromLibraryItem);
}

export function removeUnusedFieldsFromLibraryItem(libraryItem: any): ILibraryItem {
  return lod.pickBy({
    ...libraryItem,
    digitalMaterial: undefined,
    environment: undefined,
    processor: undefined,
    light: undefined,
    entity: undefined,
  }, v => v !== undefined) as unknown as ILibraryItem;
}

export function filterDigitalMaterials(materials: any[]): IDigitalMaterial[] {
  return materials.map(filterDigitalMaterial);
}

export function filterLibraryCategories(categories: any[]): ILibraryCategory[] {
  return categories.map((category: any) => {
    return {
      ...category
    };
  });
}

export function filterLibraryTopCategories(topCategories: any[]): ILibraryTopCategory[] {
  return topCategories.map((topCategory: any) => {
    return {
      ...topCategory,
      categoryIds: topCategory.categoryIds.sort((a: number, b: number) => a - b)
    };
  });
}

export function _fetchLibraryItems(projectIdOrSlug?: string | number) {
  return axios.get(apiRootPath + `/api/get-dp-library-items/${projectIdOrSlug || 0}`);
}

export function _generateEnvironmentThumbnail(envMap: IEnvironment) {
  return axios.post(apiRootPath + `/api/generate_env_thumbnail`, {...envMap});
}