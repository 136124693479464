import {Calc} from "../../calc";
import {getRegisteredCurveIdWithEdge, registerCurveGeometryWithEdge} from "../../parameter";
import {Gumball} from "../gumball";
import {WompCurveData, WompObjectRef} from "../../../WompObject";
import {ObjectTypes} from "../../xobject";
import {vec3} from "gl-matrix";
import hash from "object-hash";
import {_v} from "../../../t";

export class BasicCurveGenerator {

  static getDesc(curve: WompCurveData) {
    return `curve${curve.degree || 1}[${curve.periodic ? "true" : "false"}][${curve.bezier ? "true" : "false"}][${hash((curve.points || []).map(p => _v(p as vec3)), {algorithm: 'md5'})}]`;
  }

  static getCurve(calc: Calc) {

  }

  static async solve(calc: Calc) {
    let curve = this.getCurve(calc);
    if (curve === undefined) return false;

    let desc = this.getDesc(curve);

    let {id, edgeId} = getRegisteredCurveIdWithEdge(calc, desc);

    if (!id || !edgeId) {
      ({id, edgeId} = await registerCurveGeometryWithEdge(calc, curve, desc));
    }

    return Gumball.solveGumball(calc, [{
      value: new WompObjectRef(id),
      valueType: ObjectTypes.Curve,
      cache: [new WompObjectRef(edgeId), new WompObjectRef(edgeId)]
    }]);
  }

}