import * as React from 'react';
import {pure} from 'recompose';
import RcTooltip from 'rc-tooltip';

export interface ITooltipProps extends React.HTMLProps<HTMLElement> {
  children: any
  placement?: 'left' | 'right' | 'top' | 'bottom' | 'topLeft' | 'leftTop' | 'topRight' | 'rightTop' | 'bottomRight' | 'rightBottom' | 'bottomLeft' | 'leftBottom'
  text?: string | JSX.Element
  tooltipClassName?: string
  targetClassName?: string
  arrow?: boolean
  disabled?: boolean
}

const Tooltip: React.FC<ITooltipProps> = ({children, text, arrow, placement, tooltipClassName, targetClassName, disabled}) => {
  if (!['button', 'string', 'div', 'a', 'img'].includes(children.type)) {
    children = (
      <div
        className={"tooltip-target" + (disabled ? " disabled" : "") + (targetClassName ? " " + targetClassName : "")}
      >
        {children}
      </div>
    );
  }

  if (disabled) {
    const {onClick, style, ...props} = children.props;
    if (React.isValidElement(children)) {
      children = React.cloneElement(children, {
        ...props,
        style: {...style, opacity: 0.4},
        onClick: (evt: any) => {
          evt.stopPropagation();
          evt.preventDefault();
        }
      });
    }

    // children = React.Children.map(children, child => {
    //   const {onClick, ...props} = child.props;
    //   if (React.isValidElement(child)) {
    //     return React.cloneElement(child, props);
    //   }
    //   return child;
    // });
  }

  let innerElem = disabled ?
    <RcTooltip
      overlayClassName={"tooltip tooltip-white"}
      placement={placement ? placement : "right"}
      overlay={'coming soon'}
      prefixCls={'rc-tooltip-noarrow'}
      transitionName={'rc-tooltip-zoom'}
      destroyTooltipOnHide
      trigger={['click']}
    >
      {children}
    </RcTooltip> :
    children;

  return (
    text ?
      <RcTooltip
        overlayClassName={tooltipClassName ? tooltipClassName : "tooltip"}
        placement={placement ? placement : "right"}
        overlay={text}
        prefixCls={(!disabled && arrow) ? 'rc-tooltip' : 'rc-tooltip-noarrow'}
        mouseEnterDelay={0}
        mouseLeaveDelay={0}
        destroyTooltipOnHide
      >
        {innerElem}
      </RcTooltip> :
      innerElem
  );
};

export default pure(Tooltip);
