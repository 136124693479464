import {ComponentTypes} from "../types";
import {Param_Json, Param_Mesh, Param_SculptMesh, Param_Transform, ParamTitles} from "../../parameter";
import {Calc} from "../../calc";
import {ObjectTypes} from "../../xobject";
import {createSimpleGumball, Gumball} from "../gumball";
import {ICalcConfigDiff} from "../../types";
import {WompObjectRef} from "../../../WompObject";

export class SculptModifier {
  static create() {
    let calc = createSimpleGumball();
    calc.component = ComponentTypes.SculptModifier;
    calc.title = 'sculpted-mesh';

    let sculptTransformParam = Param_Transform.create(calc, ParamTitles.SculptTransform, true, false);
    let objectParam = Param_Mesh.create(calc, ParamTitles.Object, true, false, false);
    let sculptParam = Param_SculptMesh.create(calc, ParamTitles.Sculpt, true, false, false);
    let strokeParam = Param_Json.create(calc, ParamTitles.Stroke, true, false);
    let outParam = Param_SculptMesh.create(calc, ParamTitles.Mesh, false, false, true);

    calc.addParameter(sculptTransformParam);
    calc.addParameter(objectParam);
    calc.addParameter(sculptParam);
    calc.addParameter(strokeParam);
    calc.addParameter(outParam);
    return calc;
  }

  static async solve(calc: Calc, diff: ICalcConfigDiff) {
    let sculptTransformParam = calc.inputByTitle(ParamTitles.SculptTransform) as Param_Transform;
    let objectParam = calc.inputByTitle(ParamTitles.Object) as Param_Mesh;
    let sculptParam = calc.inputByTitle(ParamTitles.Sculpt) as Param_SculptMesh;

    let objects = [];

    for (let i = 0; i < sculptParam.length; ++i) {
      let value = sculptParam.values[i];
      if (value) {
        objects.push({
          value: new WompObjectRef(value.objectId, sculptTransformParam.values[0]),
          valueType: ObjectTypes.SculptMesh,
          cache: null
        });
      }
    }

    return Gumball.solveGumball(calc, objects);
  }
}