import {
  CHANGE_CONFIRM_DIALOG_OPENED,
  CHANGE_SIGN_IN_DIALOG_OPENED,
  ADD_SIGN_IN_DIALOG_ON_AFTER_CLOSE,
  DialogActionTypes,
  IDialogState
} from "../../types/ui/dialog";
import {INIT_STORE} from "../../types/init";

const initialState: IDialogState = {
  confirmDialogOpened: false,
  confirmDialogText: '',
  onConfirmDialogAfterClose: () => {
  },
  signInDialogOpened: false,
  onSignInDialogAfterCloses: [],
};

export function dialogReducer(
  state = initialState,
  action: DialogActionTypes
): IDialogState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case CHANGE_CONFIRM_DIALOG_OPENED:
      return {
        ...state,
        confirmDialogOpened: action.payload.opened,
        confirmDialogText: action.payload.text === undefined ? state.confirmDialogText : action.payload.text,
        onConfirmDialogAfterClose: action.payload.onAfterClose === undefined ? state.onConfirmDialogAfterClose : action.payload.onAfterClose
      };
    case CHANGE_SIGN_IN_DIALOG_OPENED:
      return {
        ...state,
        signInDialogOpened: action.payload.opened,
        onSignInDialogAfterCloses: (action.payload.opened !== state.signInDialogOpened && action.payload.opened) ? [] : state.onSignInDialogAfterCloses
      };
    case ADD_SIGN_IN_DIALOG_ON_AFTER_CLOSE:
      return {
        ...state,
        onSignInDialogAfterCloses: [...state.onSignInDialogAfterCloses, action.payload.onAfterClose]
      };
    default:
      return state;
  }
}