import React, {createContext, useState} from "react";

export interface ICursor {
  active: boolean
}

export type CursorContextType = [ICursor, React.Dispatch<React.SetStateAction<ICursor>> | undefined];

export const CursorContext = createContext<CursorContextType>([{active: false}, undefined]);

export const CursorContextProvider: React.FC = ({children}) => {
  const [cursor, setCursor] = useState({active: false});

  return (
    <CursorContext.Provider value={[cursor, setCursor]}>
      {children}
    </CursorContext.Provider>
  );
}