import {ComponentTypes, InputObjectParamTitles, OutputObjectParamTitle} from './types';
import {
  CubeGenerator,
  SphereGenerator,
  CylinderGenerator,
  DonutGenerator,
  PyramidGenerator,
  TubeGenerator,
  ConeGenerator
} from "./basic";
import {
  ThreeConeGenerator,
  ThreeCubeGenerator,
  ThreeCylinderGenerator,
  ThreeDonutGenerator,
  ThreePyramidGenerator,
  ThreeSphereGenerator,
  ThreeTextGenerator,
  ThreeTubeGenerator,
  ThreePlaneGenerator
} from './three-basic';
import {CurveParameter, VertexParameter, MeshParameter, BrepParameter} from './param';
import {
  CurveGenerator,
  PolylineGenerator,
  SurfaceGenerator,
  ArrayModifier,
  CutModifier,
  ExtrudeModifier,
  GroupModifier,
  IntersectModifier,
  OffsetModifier,
  SculptModifier,
  UnionModifier,
  WeldModifier,
  MirrorModifier,
  FilletModifier,
  HollowModifier, PipeModifier, RevolveModifier, LoftModifier
} from './modifier';
import {Gumball} from './gumball';
import {Calc} from "../calc";
import {ICalcConfigDiff} from "../types";
import {WompMesh} from "../../WompObject";
import lod from "lodash";
import {ObjectTypes} from "../xobject";
import {getObjectType, Param_Compound, ParamTypes} from "../parameter";

function getComponentMap() {
  const components: { [key: string]: any } = {
    [ComponentTypes.PolylineGenerator]: PolylineGenerator,
    [ComponentTypes.CurveGenerator]: CurveGenerator,
    [ComponentTypes.SurfaceGenerator]: SurfaceGenerator,
    [ComponentTypes.CubeGenerator]: CubeGenerator,
    [ComponentTypes.SphereGenerator]: SphereGenerator,
    [ComponentTypes.CylinderGenerator]: CylinderGenerator,
    [ComponentTypes.DonutGenerator]: DonutGenerator,
    [ComponentTypes.PyramidGenerator]: PyramidGenerator,
    [ComponentTypes.TubeGenerator]: TubeGenerator,
    [ComponentTypes.ConeGenerator]: ConeGenerator,
    [ComponentTypes.ThreeSphereGenerator]: ThreeSphereGenerator,
    [ComponentTypes.ThreeCylinderGenerator]: ThreeCylinderGenerator,
    [ComponentTypes.ThreeConeGenerator]: ThreeConeGenerator,
    [ComponentTypes.ThreePlaneGenerator]: ThreePlaneGenerator,
    [ComponentTypes.ThreeCubeGenerator]: ThreeCubeGenerator,
    [ComponentTypes.ThreePyramidGenerator]: ThreePyramidGenerator,
    [ComponentTypes.ThreeDonutGenerator]: ThreeDonutGenerator,
    [ComponentTypes.ThreeTubeGenerator]: ThreeTubeGenerator,
    [ComponentTypes.ThreeTextGenerator]: ThreeTextGenerator,
    [ComponentTypes.CurveParameter]: CurveParameter,
    [ComponentTypes.VertexParameter]: VertexParameter,
    [ComponentTypes.MeshParameter]: MeshParameter,
    [ComponentTypes.BrepParameter]: BrepParameter,
    [ComponentTypes.ExtrudeModifier]: ExtrudeModifier,
    [ComponentTypes.CutModifier]: CutModifier,
    [ComponentTypes.UnionModifier]: UnionModifier,
    [ComponentTypes.IntersectModifier]: IntersectModifier,
    [ComponentTypes.GroupModifier]: GroupModifier,
    [ComponentTypes.ArrayModifier]: ArrayModifier,
    [ComponentTypes.MirrorModifier]: MirrorModifier,
    [ComponentTypes.PipeModifier]: PipeModifier,
    [ComponentTypes.WeldModifier]: WeldModifier,
    [ComponentTypes.SculptModifier]: SculptModifier,
    [ComponentTypes.OffsetModifier]: OffsetModifier,
    [ComponentTypes.FilletModifier]: FilletModifier,
    [ComponentTypes.HollowModifier]: HollowModifier,
    [ComponentTypes.RevolveModifier]: RevolveModifier,
    [ComponentTypes.LoftModifier]: LoftModifier,
    [ComponentTypes.Gumball]: Gumball
  };
  return components;
}

export function createComponent(name: string): Calc | undefined {
  const components = getComponentMap();

  if (components[name]) {
    return components[name].create();
  }
}

export async function solveComponent(calc: Calc, diff: ICalcConfigDiff | undefined) {
  const components = getComponentMap();

  let component = components[calc.component];
  if (component && diff) {
    let res = true;

    let allValid = true;
    for (let inParamTitle of (InputObjectParamTitles[calc.component] || [])) {
      let inParam = calc.inputByTitle(inParamTitle) as Param_Compound;
      if (!inParam.isInvalid) {
        for (let i = 0; i < inParam.length; ++i) {
          if (inParam.properties && inParam.properties[i] && !inParam.properties[i].valid) {
            allValid = false;
            break;
          }
        }
      }
    }

    if (allValid) {
      if (diff.obj || (diff.gumball && Gumball.hasGumball(calc) || (diff.prevVoided && component.prevVoided))) {
        res = await component.solve(calc, diff);
      } else {
        if (component.solveProperty) {
          component.solveProperty(calc, diff);
        } else {
          calc.solveProperty(diff);
        }
      }
    } else {
      let objects = [];
      if ((InputObjectParamTitles[calc.component] || []).length === 1) {
        let inParam = calc.inputByTitle(InputObjectParamTitles[calc.component][0]) as Param_Compound;
        for (let i = 0; i < inParam.length; ++i) {
          let value = inParam.values[i];
          let property = inParam.properties[i];
          let valueType = inParam.valueTypes ? inParam.valueTypes[i] : getObjectType(inParam.objectType as ParamTypes);
          let cache = inParam.caches ? inParam.caches[i] : null;

          objects.push({
            value, valueType, cache, property
          });
        }
      }

      res = Gumball.solveGumball(calc, objects);
    }

    if (!res) {
      let outParam = calc.outputByTitle(OutputObjectParamTitle[calc.component]) as Param_Compound;
      if (outParam.values)
        outParam.values = [];
      if (outParam.caches)
        outParam.caches = [];
      if (outParam.valueTypes)
        outParam.valueTypes = [];
      if (outParam.properties)
        outParam.properties = [];
    }
    return res;
  }
  return false;
}

export async function initComponents() {
  const components = getComponentMap();

  for (let name in components) {
    if (components[name].init)
      await components[name].init();
  }
}

export function getDraggingMesh(name: string): WompMesh {
  const components = getComponentMap();

  if (components[name] && components[name].draggingPreview) {
    return components[name].draggingPreview;
  }
  return new WompMesh({});
}

// TODO: BYZ - check how this works with vec3, mat4
export function duplicateObject(obj: any): any {
  if (obj === undefined)
    return undefined;
  if (obj === null)
    return null;
  if (obj.clone)
    return obj.clone();
  if (obj.duplicate)
    return obj.duplicate();
  if (Array.isArray(obj))
    return obj.map(duplicateObject);
  return lod.cloneDeep(obj);
}