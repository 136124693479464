import {IFetchItemAsync, IFetchItemsAsync, IPostItemAsync} from "../types";
import {InitStoreAction} from "../init";

export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const PUT_ORDER = 'PUT_ORDER';

export interface IOrdersState {
  orders: IFetchItemsAsync<number>
  order: IFetchItemAsync<number>
  post: IPostItemAsync<number>
}

interface FetchOrdersAction {
  type: typeof FETCH_ORDERS
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    items?: number[]
  }
}

interface PutOrderAction {
  type: typeof PUT_ORDER
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id?: number
  }
}

interface FetchOrderAction {
  type: typeof FETCH_ORDER
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    id?: number
  }
}


export type ApiOrdersActionTypes = FetchOrdersAction |
  PutOrderAction |
  FetchOrderAction |
  InitStoreAction