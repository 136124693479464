import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {
  IGAR,
  IPostProject,
  IProject,
  IProjectFetchConfig,
  LibraryItemTypes,
  PlaceholderBoxStates
} from "../../store/types/types";
import {AnyAction} from "redux";
import {store} from "../../index";
import {
  __scenes__,
  clearScenes,
  getGlobalObjects,
  processAuthentication,
  refreshScene,
  runWithBreaks,
  waitForRerender,
  waitForStore,
  waitForStoreMultiple
} from "./global";
import {
  _deleteProject, _fetchMediaProject,
  _fetchProject,
  _fetchProjects,
  _fetchSharedProject,
  _getDenoisedImage, _postAcceptInfoRouteId,
  _postBookmark, _postChangeCollaborators,
  _postCreate,
  _postDuplicate,
  _postLike,
  _postProject,
  _postShare,
  _postUnshare,
  filterChannels,
  filterProject,
  filterProjectChatSessions,
  filterProjects,
  filterScreenshots
} from "../../api/project";
import {batch} from "react-redux";
import {addProject, addProjects, removeProject, replaceProjects} from "../../store/actions/entity/projects";
import {
  createProjectFailure,
  createProjectStarted,
  createProjectSuccess,
  deleteProjectFailure,
  deleteProjectStarted,
  deleteProjectSuccess,
  downloadProjectFailure,
  downloadProjectStarted,
  downloadProjectSuccess,
  duplicateProjectFailure,
  duplicateProjectStarted,
  duplicateProjectSuccess,
  fetchProjectLoadFailure,
  fetchProjectLoadStarted,
  fetchProjectLoadSuccess,
  fetchProjectsFailure,
  fetchProjectsStarted,
  fetchProjectsSuccess,
  fetchSharedProjectFailure,
  fetchSharedProjectStarted,
  fetchSharedProjectSuccess,
  postProjectFailure,
  postProjectSaveFailure,
  postProjectSaveStarted,
  postProjectSaveSuccess,
  postProjectStarted,
  postProjectSuccess
} from "../../store/actions/api/project";
import {
  addChannels,
  addProgress,
  changeProgressRange,
  removeProgress,
  updateCommandManager
} from "../../store/actions/logic/project";
import {goBack, push} from "connected-react-router";
import Editor3dWrapper from "../3d/Editor3dWrapper";
import {fetchLibraryItems} from "./library";
import {_s} from "../../peregrine/t";
import {
  AlphaSceneVersion,
  BetaSceneVersion,
  createDefaultLight,
  defaultLightEnvironment,
  encodeEnvironment,
  getBrepFromRef,
  IDirectionalLight,
  LightTypes,
  Scene,
  ViewTypes
} from "../../peregrine/processor";
import {compare, getLatestCommonIndexes, ProjectScene, ProjectSceneDispatch} from "../core";
import {exportCalcsToBREP, exportCalcsToSTL, exportSceneToSTL} from "../../peregrine/helper";
import {pigeon as pigeonTypes} from "../../peregrine/types";
import {toast} from "react-toastify";
import lod from 'lodash';
import {addScenePlaceholderBoxes, removeScenePlaceholderBoxes} from "./scene/scene";
import {addScreenshots} from "../../store/actions/entity/screenshots";
import {addChatSessions} from "../../store/actions/entity/chat-sessions";
import {downloadBlobObject, downloadObjectAsWomp} from "../common/download";
import moment from 'moment';
import {
  filterDigitalMaterials,
  filterLibraryItems,
  filterProcessorsFromLibraryItems,
  removeUnusedFieldsFromLibraryItems
} from "../../api/library";
import {addDigitalMaterials} from "../../store/actions/entity/digital-materials";
import {_cancelOperation, _doBooleanOperation, _doCutOperation} from "../../api/operation";
import {getBooleanAsyncResponse, getCutAsyncResponse} from "./scene";
import {WompObjectRef} from "../../peregrine/WompObject";
import {addProcessors, removeProcessors} from "../../store/actions/entity/processors";
import {addLibraryItems, removeLibraryItems} from "../../store/actions/entity/library-items";
import fetchProgress from "fetch-progress";

export const fetchProjects = (config: IProjectFetchConfig): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    let prevConfig = store.getState().entities.projects.config;
    let authToken = store.getState().entities.auth.token;

    if (!config.dontReplace && (!prevConfig || prevConfig.last === 0)) {
      clearScenes();
    }

    let differentMode = !prevConfig ||
      prevConfig.mode !== config.mode ||
      prevConfig.rootId !== config.rootId ||
      prevConfig.sortBy !== config.sortBy ||
      prevConfig.needle !== config.needle ||
      prevConfig.username !== config.username ||
      prevConfig.authToken !== authToken;

    await waitForStoreMultiple([
      {path: 'api.project.createProject.posting', value: false},
      {path: 'api.project.postProject.posting', value: false}
    ]);

    if (differentMode || !prevConfig.reachLast) {
      dispatch(fetchProjectsStarted());

      return _fetchProjects(config.mode, config.rootId, config.sortBy, config.username, config.needle, (!differentMode && prevConfig) ? prevConfig.last : 0, config.count)
        .then(res => {
          if (res.data.success) {
            let projects = filterProjects(res.data.data.projects);
            let channels = filterChannels(res.data.data.projects);
            let actions: any[] = [addChannels(channels)];
            if (differentMode && !config.dontReplace) {
              actions.push(replaceProjects(projects, {
                mode: config.mode,
                rootId: config.rootId,
                sortBy: config.sortBy,
                needle: config.needle,
                username: config.username,
                authToken: authToken,
                last: res.data.data.last,
                reachLast: res.data.data.last === 0
              }));
            } else {
              actions.push(addProjects(projects, {
                mode: config.mode,
                rootId: config.rootId,
                sortBy: config.sortBy,
                needle: config.needle,
                username: config.username,
                authToken: authToken,
                last: res.data.data.last,
                reachLast: res.data.data.last === (prevConfig ? prevConfig.last : 0)
              }));
            }

            batch(() => {
              actions.map(action => dispatch(action));
              dispatch(fetchProjectsSuccess([]));
            });
            return {success: true};
          } else {
            dispatch(fetchProjectsFailure(res.data.message));
            return {success: false, info: {invalid: true}, message: res.data.message};
          }
        })
        .catch(err => {
          dispatch(fetchProjectsFailure(err.message));
          return processAuthentication(err, dispatch).then(res => {
            if (res.success) {
              return dispatch(fetchProjects(config));
            } else {
              return res;
            }
          });
        });
    } else {
      return {success: true};
    }
  };
};

const sceneCallback = (scene: ProjectScene, wrapper: Editor3dWrapper, dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  return async (data: any) => {
    const {op, calcId, operationId, operands} = data;
    const routeId = scene.route.id;

    const cancel = async () => {
      await _cancelOperation(scene.projectId, routeId, operationId);
      await dispatch(removeScenePlaceholderBoxes([calcId], wrapper));
      await dispatch(ProjectSceneDispatch.breakCalcs(scene.projectId, wrapper, [calcId]));
    };

    if (op === "union" || op === "intersect") {

      let infos = operands.infos as { value: WompObjectRef, voided: boolean }[];
      let operation = scene.operations[operationId];

      if (operation && operation.fetching) {

        operation.waitingCalcIdSet.add(calcId);

      } else {

        scene.operations[operationId] = {
          fetching: true,
          waitingCalcIdSet: new Set([calcId]),
          infos: {
            isMesh: operands.isMesh,
            weldAngle: operands.weldAngle,
            desc: operands.desc
          }
        };

        await dispatch(addScenePlaceholderBoxes({
          [calcId]: {
            description: op,
            state: PlaceholderBoxStates.Success,
            progress: 0,
            info: {cancel}
          }
        }, wrapper));

        if (operands.isMesh) {

          _doBooleanOperation(
            scene.projectId,
            routeId,
            operationId,
            op,
            infos.filter(info => !info.voided).map(info => info.value),
            infos.filter(info => info.voided).map(info => info.value)
          );

        } else {

          pigeonTypes.boolean(
            op,
            infos.filter(info => !info.voided).map(info => getBrepFromRef(scene, info.value)),
            infos.filter(info => info.voided).map(info => getBrepFromRef(scene, info.value))
          ).then(result => {
            dispatch(getBooleanAsyncResponse(false)(scene.projectId, wrapper, operationId, result));
          });

        }

      }

    } else if (op === "cut") {

      let infos = operands.infos as { value: WompObjectRef, plane: number[], isMesh: boolean, weldAngle: number, desc: string }[];
      let operation = scene.operations[operationId];

      if (operation && operation.fetching) {

        operation.waitingCalcIdSet.add(calcId);

      } else {

        let hasMesh = lod.some(infos, info => info.isMesh);
        let hasBrep = lod.some(infos, info => !info.isMesh);
        scene.operations[operationId] = {
          fetching: true,
          waitingCalcIdSet: new Set([calcId]),
          infos,
          options: {
            waitForMesh: hasMesh,
            waitForBrep: hasBrep,
          }
        };

        await dispatch(addScenePlaceholderBoxes({
          [calcId]: {
            description: op,
            state: PlaceholderBoxStates.Success,
            progress: 0,
            info: {cancel}
          }
        }, wrapper));

        if (hasMesh)
          _doCutOperation(scene.projectId, routeId, operationId, infos.filter(info => info.isMesh));

        if (hasBrep) {
          pigeonTypes.cut(
            infos.filter(info => !info.isMesh).map(info => getBrepFromRef(scene, info.value)),
            infos.filter(info => !info.isMesh).map(info => new Float32Array(info.plane)),
          ).then(result => {
            dispatch(getCutAsyncResponse(false)(scene.projectId, wrapper, operationId, result));
          });
        }

      }

    }
  };
};

export const fetchProjectLoad = (id: number, wrapper: Editor3dWrapper, readOnly: boolean, pauseOnFinish?: boolean): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    let {scene} = getGlobalObjects(id);

    let loadLibrary = dispatch(fetchLibraryItems(id));

    let seconds: number[] = [];
    let messages: string[] = [];
    let startedTime = performance.now();
    let message = 'downloading scene';
    dispatch(fetchProjectLoadStarted(id, message, {percentage: 0, messages, seconds}));

    return new Promise((resolve, reject) =>
      _fetchProject(id)
        .then(async res => {
          let project: IProject | undefined;
          let initialScene: any;

          runWithBreaks([async () => {
            await loadLibrary;

            if (res.data.scene) {
              project = filterProject(res.data);
              let projectId = project.id;

              if (project.isDirectory) {
                dispatch(fetchProjectLoadFailure(id, ''));
                resolve({success: false});
                project = undefined;
                dispatch(push(`/dashboard/projects/${projectId}`));
                return;
              }

              let {username, appAdmin} = store.getState().entities.auth;

              if (project.username !== username && !readOnly && !appAdmin) {
                batch(() => {
                  dispatch(fetchProjectLoadSuccess(id));
                  dispatch(goBack());
                });
                project = undefined;
                return;
              }

              if (res.data.scene[_s('version')] !== AlphaSceneVersion && res.data.scene[_s('version')] !== BetaSceneVersion) {
                message = `project version[${res.data.scene[_s('version')] ? res.data.scene[_s('version')] : '0.0.0'}] mismatch. latest beta version is ${BetaSceneVersion}.`;
                dispatch(fetchProjectLoadFailure(id, message));
                resolve({success: false, message});
                project = undefined;
                dispatch(goBack());
                return;
              }

              if (readOnly) {
                res.data.scene['editLevels'] = [];
                res.data.scene['selectedCalcIds'] = [];
              }

              initialScene = res.data.scene;
              scene = await ProjectScene.init(initialScene);

              scene.callback = sceneCallback(scene, wrapper, dispatch);

              __scenes__[projectId] = scene;

              let screenshots = filterScreenshots(res.data);
              let sessions = filterProjectChatSessions(res.data);

              batch(() => {
                dispatch(addScreenshots(screenshots));
                dispatch(addChatSessions(sessions));
              });

              await scene.runExclusive(async () => {
                if (res.data.scene[_s('version')] === AlphaSceneVersion && !readOnly) {
                  toast("This project is alpha version. converting to beta version. Please reopen the project.", {
                    className: "toast toast-alert",
                    containerId: 'default',
                    autoClose: 3000
                  });

                  scene.version = BetaSceneVersion;
                }

                scene.fix();
                await scene.solve();

                let diffs = compare(initialScene, scene.data, true);

                if (diffs.length > 0 && !readOnly) {
                  console.log(diffs);
                  scene.createCommand('fix scene', `fix scene`);
                }
              });
            }

            await waitForStore('entities.global.engineLoaded', true);

            if (project)
              dispatch(addProject(project));

            messages = [...messages, message];
            seconds = [...seconds, ((performance.now() - startedTime) / 1000.0)];
            startedTime = performance.now();
            message = 'adding models to scene';
            dispatch(fetchProjectLoadStarted(id, message, {percentage: 80, messages, seconds}));

          }, async () => {
            if (!project) return;

            if (wrapper.editor) {
              wrapper.editor._clear();

              await scene.runExclusive(async () => {
                for (let id of scene.calcIds) {
                  let calc = scene.getCalcById(id);
                  if (lod.some(calc.state.objects, obj => !obj.valid))
                    scene.updateCalcIdSet.add(id);
                }

                if (scene.viewType === ViewTypes.ServerRendered)
                  scene.viewType = ViewTypes.Rendered;

                await scene.solve();
              });

              await refreshScene(project.id, dispatch, wrapper);
              dispatch(updateCommandManager(project.id, scene.commandManager.commandState));
            }

            messages = [...messages, message];
            seconds = [...seconds, ((performance.now() - startedTime) / 1000.0)];
            startedTime = performance.now();
            message = 'validating snapshot';
            dispatch(fetchProjectLoadStarted(id, message, {percentage: 90, messages, seconds}));

          }, async () => {
            if (!project) return;

            let fields: IPostProject = {};
            let snapshot: any;
            let infoNeedsUpdate;
            let thumbnailNeedsUpdate;
            let hasContentChange;
            let isEmptyScene;

            if (!project.isPublic) {

              snapshot = scene.snapshot;
              let diffs = compare(project.snapshot || {}, snapshot);

              snapshot[_s('lightEnvironment')] = lod.omit(encodeEnvironment(defaultLightEnvironment), [_s('id'), _s('thumbnail'), _s('title')]);
              snapshot[_s('isSetAsBackground')] = scene.data[_s('isSetAsBackground')];

              let diffs2 = compare(project.snapshot || {}, snapshot);
              hasContentChange = diffs.length > 0 && diffs2.length > 0;

              if (hasContentChange)
                console.log(diffs, diffs2);

              infoNeedsUpdate = !project.info ||
                project.info.faceCount === undefined ||
                project.info.vertexCount === undefined ||
                project.info.renderFaceCount === undefined ||
                project.info.renderVertexCount === undefined ||
                project.info.renderedObjCount === undefined ||
                project.info.fileSize === undefined ||
                hasContentChange;

              thumbnailNeedsUpdate = hasContentChange;
              isEmptyScene = Object.keys(snapshot[_s('calcs')] || {}).length === 0;

              fields.snapshot = snapshot;

            }

            if (thumbnailNeedsUpdate) {
              if (isEmptyScene) {
                fields.thumbnail = '';
              } else if (wrapper.editor) {
                let dataWithZoom = await wrapper.editor.getScreenshotWithMetaInfo();

                fields.thumbnail = dataWithZoom.data;
                fields.thumbnailZoom = dataWithZoom.zoom;

                if (project.thumbnail === fields.thumbnail) {
                  fields.thumbnail = undefined;
                  fields.thumbnailZoom = undefined;
                }
              }
            }

            if (infoNeedsUpdate) {
              if (project.info)
                fields.info = lod.cloneDeep(project.info);
              else
                fields.info = {};

              if (fields.info) {
                fields.info.faceCount = scene.analysisInfo.faceCount;
                fields.info.vertexCount = scene.analysisInfo.vertexCount;
                fields.info.renderVertexCount = scene.analysisInfo.renderVertexCount;
                fields.info.renderFaceCount = scene.analysisInfo.renderFaceCount;
                fields.info.renderedObjCount = scene.analysisInfo.renderedObjCount;
              }
            }

            const finishProcess = async (data: IGAR) => {
              wrapper.editor && wrapper.editor.repositionCamera('preview-zoom');

              messages = [...messages, message];
              seconds = [...seconds, ((performance.now() - startedTime) / 1000.0)];
              startedTime = performance.now();
              message = 'done';

              messages = [...messages, message];
              seconds = [...seconds, seconds.reduce((a, b) => a + b, 0)];
              message = 'done';

              batch(() => {
                dispatch(fetchProjectLoadStarted(id, message, {percentage: 100, messages, seconds}));
                if (!pauseOnFinish)
                  dispatch(fetchProjectLoadSuccess(id));
              });

              return {...data};
            };

            if ((thumbnailNeedsUpdate || infoNeedsUpdate) && !readOnly) {
              resolve(dispatch(changeProject(project.id, fields))
                .then(finishProcess));
            } else {
              resolve(finishProcess({success: true}));
            }
          }]);
        })
        .catch(err => {
          dispatch(fetchProjectLoadFailure(id, err.message));
          resolve(processAuthentication(err, dispatch).then(res => {
            if (res.success) {
              return dispatch(fetchProjectLoad(id, wrapper, readOnly, pauseOnFinish));
            } else {
              return res;
            }
          }));
        })
    );
  };
};

export const fetchSharedProject = (slug: string, password: string, wrapper: Editor3dWrapper): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {

    dispatch(fetchSharedProjectStarted(0));

    return _fetchSharedProject(slug, password)
      .then(async res => {
        if (res.data.success) {
          let loadLibrary = dispatch(fetchLibraryItems(slug));

          let project = filterProject(res.data.data);

          if (res.data.data.scene) {
            __scenes__[project.id] = await ProjectScene.init(res.data.data.scene);
            __scenes__[project.id].projectId = project.id;
          }

          await loadLibrary;
          await waitForStore('entities.global.engineLoaded', true);

          await __scenes__[project.id].solve();
          let scene = __scenes__[project.id];

          dispatch(addProject(project));

          wrapper.editor && wrapper.editor._clear();

          await refreshScene(project.id, dispatch, wrapper);

          wrapper.editor && wrapper.editor.repositionCamera('preview-zoom');

          dispatch(fetchSharedProjectSuccess(res.data.data.id));

          return {success: true};
        } else {
          dispatch(fetchSharedProjectFailure(res.data.message));
          return {success: false, message: res.data.message};
        }
      })
      .catch(err => {
        dispatch(fetchSharedProjectFailure(err.message));
        return processAuthentication(err, dispatch).then(res => {
          if (res.success) {
            return dispatch(fetchSharedProject(slug, password, wrapper));
          } else {
            return res;
          }
        });
      });
  };
};

export const postProjectSave = (projectId: number, wrapper: Editor3dWrapper, pauseOnFinish?: boolean, noCommandSync?: boolean): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const {scene} = getGlobalObjects(projectId);
    let project = store.getState().entities.projects.byId[projectId];

    let seconds: number[] = [];
    let messages: string[] = [];
    let startedTime = performance.now();
    let message = '';
    let firstCommandsLeft = 0;
    let lastCommandsLeft = 0;

    return new Promise((resolve, reject) => {
      message = `waiting for command(s) to be synchronized`;
      dispatch(postProjectSaveStarted(projectId, message, {percentage: 0, messages, seconds}));

      let waitSync = setInterval(async () => {
        let commandsLeft = 0;

        if (noCommandSync) {
          clearTimeout(waitSync);
        } else {
          let project = store.getState().entities.projects.byId[projectId];
          if (project) {
            let indexes = getLatestCommonIndexes(
              ['', ...scene.server.commands.map(c => c.id)],
              scene.server.cursor,
              [scene.route.startCommand, ...scene.commandManager.commands.map(c => c.id)],
              scene.commandManager.cursor
            );

            if (indexes[0] >= 0 && indexes[1] >= 0) {
              if (scene.commandManager.cursor > indexes[1]) {
                commandsLeft = scene.commandManager.cursor - indexes[1];
              } else if (indexes[0] !== scene.server.cursor) {
                commandsLeft = 1;
              }
            }
          }

          if (commandsLeft > 0) {
            firstCommandsLeft = Math.max(firstCommandsLeft, commandsLeft);

            if (lastCommandsLeft !== commandsLeft) {
              message = `waiting for ${commandsLeft} command(s) to be synchronized`;
              dispatch(postProjectSaveStarted(project.id, message, {percentage: 0, messages, seconds}));
              lastCommandsLeft = commandsLeft;
            }
            return;
          } else {
            clearTimeout(waitSync);
          }

          if (firstCommandsLeft !== 0) {
            messages = [...messages, message = `waiting for ${firstCommandsLeft} command(s) to be synchronized`];
            seconds = [...seconds, ((performance.now() - startedTime) / 1000.0)];
            startedTime = performance.now();
          }
        }

        if (wrapper.editor) {
          let fields: IPostProject = {};
          let snapshot: any;
          let infoNeedsUpdate = false;
          let thumbnailNeedsUpdate = false;
          let hasContentChange = false;
          let isEmptyScene = false;

          runWithBreaks([async () => {
            snapshot = scene.snapshot;

            let diffs = compare(project.snapshot || {}, snapshot);
            hasContentChange = diffs.length > 0;

            if (hasContentChange)
              console.log(diffs);

            infoNeedsUpdate = !project.info ||
              project.info.faceCount === undefined ||
              project.info.vertexCount === undefined ||
              project.info.renderFaceCount === undefined ||
              project.info.renderVertexCount === undefined ||
              project.info.renderedObjCount === undefined ||
              project.info.fileSize === undefined ||
              hasContentChange;

            thumbnailNeedsUpdate = hasContentChange;
            isEmptyScene = Object.keys(snapshot[_s('calcs')]).length === 0;

            if (infoNeedsUpdate || thumbnailNeedsUpdate) {
              if (message) {
                messages = [...messages, message];
                seconds = [...seconds, ((performance.now() - startedTime) / 1000.0)];
                startedTime = performance.now();
              }
              message = 'generating thumbnail';
              dispatch(postProjectSaveStarted(project.id, message, {percentage: 30, messages, seconds}));
            }
          }, async () => {

            const finishProcess = () => {
              messages = [...messages, message];
              seconds = [...seconds, ((performance.now() - startedTime) / 1000.0)];
              startedTime = performance.now();
              message = 'done';

              messages = [...messages, message];
              seconds = [...seconds, seconds.reduce((a, b) => a + b, 0)];
              message = 'done';

              batch(() => {
                dispatch(postProjectSaveStarted(project.id, message, {percentage: 100, messages, seconds}));
                if (!pauseOnFinish)
                  dispatch(postProjectSaveSuccess(project.id));
              });
            };

            if (infoNeedsUpdate || thumbnailNeedsUpdate) {
              fields.snapshot = snapshot;

              if (thumbnailNeedsUpdate) {
                if (isEmptyScene) {
                  fields.thumbnail = '';
                } else {
                  let dataWithZoom = await wrapper.editor!.getScreenshotWithMetaInfo();

                  fields.thumbnail = dataWithZoom.data;
                  fields.thumbnailZoom = dataWithZoom.zoom;
                }
              }

              if (infoNeedsUpdate) {
                if (project.info)
                  fields.info = lod.cloneDeep(project.info);
                else
                  fields.info = {};

                if (fields.info) {
                  fields.info.faceCount = scene.analysisInfo.faceCount;
                  fields.info.vertexCount = scene.analysisInfo.vertexCount;
                  fields.info.renderVertexCount = scene.analysisInfo.renderVertexCount;
                  fields.info.renderFaceCount = scene.analysisInfo.renderFaceCount;
                  fields.info.renderedObjCount = scene.analysisInfo.renderedObjCount;
                }
              }

              runWithBreaks([async () => {
                messages = [...messages, message];
                seconds = [...seconds, ((performance.now() - startedTime) / 1000.0)];
                startedTime = performance.now();
                message = 'transferring';
                dispatch(postProjectSaveStarted(project.id, message, {percentage: 50, messages, seconds}));
              }, async () => {
                if (!infoNeedsUpdate && !thumbnailNeedsUpdate && project.scn.calcIds.length !== 0) {
                  finishProcess();
                  resolve({success: true});
                } else {
                  resolve(dispatch(changeProject(project.id, fields))
                    .then(async (data) => {
                      if (data.success) {
                        await refreshScene(project.id, dispatch);
                        finishProcess();
                      } else {
                        dispatch(postProjectSaveFailure(projectId, data.message || ''));
                      }

                      return data;
                    })
                  );
                }
              }]);
            } else {
              finishProcess();
              resolve({success: true});
            }
          }]);
        } else {
          resolve({success: false});
        }
      }, 100);
    });
  };
};

export const changeProject = (projectId: number, fields: IPostProject): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    await waitForStoreMultiple([
      {path: 'api.project.getProjects.fetching', value: false}
    ]);

    dispatch(postProjectStarted(projectId));

    return _postProject(projectId, fields)
      .then(res => {
        if (res.data.success) {
          let projects = filterProjects([res.data.data]);
          batch(() => {
            dispatch(addProjects(projects));
            dispatch(postProjectSuccess(projectId));
          });

          return {success: true};
        } else {
          dispatch(postProjectFailure(res.data.message));

          return {success: false, message: res.data.message};
        }
      })
      .catch(err => {
        dispatch(postProjectFailure(err.message));
        return processAuthentication(err, dispatch).then(res => {
          if (res.success) {
            return dispatch(changeProject(projectId, fields));
          } else {
            return res;
          }
        });
      });
  };
};

export const createProject = (directoryId: number, isDirectory: boolean): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    await waitForStoreMultiple([
      {path: 'api.project.getProjects.fetching', value: false}
    ]);

    dispatch(createProjectStarted());

    let sceneData = undefined;
    if (!isDirectory) {
      let newScene = await ProjectScene.init();
      let defaultLight = createDefaultLight(LightTypes.Directional);
      let light = defaultLight.light as IDirectionalLight;

      defaultLight.helperVisible = false;
      light.position = [100, -100, 100];
      light.width = 500;
      light.height = 500;

      newScene.addLight(dispatch, defaultLight);

      sceneData = newScene.data;
    }

    return _postCreate(directoryId, isDirectory, sceneData, true)
      .then(res => {
        if (res.data.success) {
          let project = filterProject(res.data.data);

          batch(() => {
            dispatch(addProject(project));
            dispatch(createProjectSuccess(res.data.data.id));
          });
          return {success: true, ids: ['' + project.id]};
        } else {
          dispatch(createProjectFailure(res.data.message));
          return {success: false};
        }
      })
      .catch(err => {
        dispatch(createProjectFailure(err.message));
        return processAuthentication(err, dispatch).then(res => {
          if (res.success) {
            return dispatch(createProject(directoryId, isDirectory));
          } else {
            return res;
          }
        });
      });
  };
};


export const deleteProject = (id: number): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(deleteProjectStarted(id));

    _deleteProject(id)
      .then(res => {
        batch(() => {
          dispatch(removeProject(id));
          dispatch(deleteProjectSuccess(id));
        });
      })
      .catch(err => {
        dispatch(deleteProjectFailure(id, err.message));
        processAuthentication(err, dispatch);
      });
  };
};


export const duplicateProject = (projectId: number, fork?: boolean): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    dispatch(duplicateProjectStarted(projectId));

    return _postDuplicate(projectId, fork ? fork : false)
      .then(async res => {
        if (res.data.success) {
          let project = filterProject(res.data.data);

          batch(() => {
            dispatch(addProject(project));
            dispatch(duplicateProjectSuccess(projectId));
          });
          return {success: true, ids: ["" + project.id]};
        } else {
          dispatch(duplicateProjectFailure(projectId, res.data.message));
          return {success: false, message: res.data.message};
        }
      })
      .catch(err => {
        dispatch(duplicateProjectFailure(projectId, err.message));
        processAuthentication(err, dispatch);
        return {success: false, message: err.message};
      });
  };
};

export const likeProject = (projectId: number, like: boolean): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {

    return _postLike(projectId, like)
      .then(async res => {
        return res.data;
      })
      .catch(err => {
        return processAuthentication(err, dispatch).then(res => {
          if (res.success) {
            return dispatch(likeProject(projectId, like));
          } else {
            return res;
          }
        });
      });
  };
};

export const bookmarkProject = (projectId: number, bookmark: boolean): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {

    return _postBookmark(projectId, bookmark)
      .then(async res => {
        if (res.data.success) {
          let libraryItems = filterLibraryItems(res.data.items);
          let processors = filterProcessorsFromLibraryItems(libraryItems);

          batch(() => {
            dispatch(addProcessors(processors));
            dispatch(addLibraryItems(removeUnusedFieldsFromLibraryItems(libraryItems)));
            dispatch(removeLibraryItems(res.data.removedItemIds));
            dispatch(removeProcessors(res.data.removedProcessorIds));
          });

          return {success: true};
        }
        return {success: false, message: res.data.message};
      })
      .catch(err => {
        return processAuthentication(err, dispatch).then(res => {
          if (res.success) {
            return dispatch(bookmarkProject(projectId, bookmark));
          } else {
            return res;
          }
        });
      });
  };
};

export const shareProject = (project: IProject): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    dispatch(postProjectStarted(project.id));
    return _postShare(project.id)
      .then(res => {
        if (res.data.success) {
          batch(() => {
            dispatch(postProjectSuccess(project.id));
            dispatch(addProject({...project, sharedSlug: res.data.slug}));
          });

          let path = window.location.origin + '/shared/' + res.data.slug;

          return {success: true, message: path};
        } else {
          dispatch(postProjectFailure(res.data.message));

          return {success: false, message: res.data.message};
        }
      })
      .catch(err => {
        dispatch(postProjectFailure(err.message));

        return processAuthentication(err, dispatch).then(res => {
          if (res.success) {
            return dispatch(shareProject(project));
          } else {
            return res;
          }
        });
      });
  };
};

export const unshareProject = (project: IProject): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    dispatch(postProjectStarted(project.id));

    return _postUnshare(project.id)
      .then(res => {
        if (res.data.success) {
          batch(() => {
            dispatch(postProjectSuccess(project.id));
            dispatch(addProject({...project, sharedSlug: undefined}));
          });

          return {success: true};
        } else {
          dispatch(postProjectFailure(res.data.message));

          return {success: false, message: res.data.message};
        }
      })
      .catch(err => {
        dispatch(postProjectFailure(err.message));

        return processAuthentication(err, dispatch).then(res => {
          if (res.success) {
            return dispatch(unshareProject(project));
          } else {
            return res;
          }
        });
      });
  };
};

export const replaceContent = (project: IProject, jsonFile: File): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    batch(() => {
      dispatch(addProgress('edit: import'));
      dispatch(changeProgressRange(0, 100));
    });

    return new Promise<IGAR>((resolve, reject) => {
      let reader = new FileReader();
      reader.onloadend = (evt) => {
        if (evt.target) {
          let target = evt.target as FileReader;
          if (target.readyState === FileReader.DONE && target.result !== null) {
            let scene = JSON.parse(target.result as string);

            return dispatch(changeProject(project.id, {
              scene: scene[_s('scene')],
              digitalMaterials: scene[_s('digitalMaterials')]
            })).then((res) => {
              dispatch(goBack());
              resolve({success: true});
            });
          }
        }
      };

      reader.readAsText(jsonFile);
    }).then(res => {
      dispatch(removeProgress('edit: import'));
      return res;
    });
  };
};

export const downloadSceneJson = (projectId: number, projectTitle: string, mediaForm?: boolean): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    batch(() => {
      dispatch(downloadProjectStarted(projectId));
      dispatch(addProgress('edit: download-scene-json'));
    });

    let loadLibrary = dispatch(fetchLibraryItems(projectId));

    _fetchProject(projectId)
      .then(async res => {
        await loadLibrary;

        if (res.data.scene) {
          let data: any = {};

          let digitalMaterialById = store.getState().entities.digitalMaterials.byId;
          let libraryItemById = store.getState().entities.libraryItems.byId;

          let libraryItems = Object.values(libraryItemById)
            .filter(item => item.projectId === projectId);

          let digitalMaterials: any[] = libraryItems.filter(item => item.entityType === LibraryItemTypes.DigitalMaterial)
            .map(item => digitalMaterialById[item.entityId])
            .filter(mat => mat)
            .map(mat => lod.omit(mat, ['lastUsedDate']));

          if (mediaForm) {
            digitalMaterials = digitalMaterials.map(mat => lod.omit(mat, ["thumbnail"]));
            data = lod.omit(res.data, ['snapshot']);
            data.digitalMaterials = lod.keyBy(digitalMaterials, 'id');
          } else {
            data[_s('scene')] = res.data.scene;
            data[_s('digitalMaterials')] = lod.keyBy(digitalMaterials, 'id');
          }

          downloadObjectAsWomp(data, projectTitle);
        }

        batch(() => {
          dispatch(downloadProjectSuccess(projectId));
          dispatch(removeProgress('edit: download-scene-json'));
        });
      })
      .catch(err => {
        batch(() => {
          dispatch(downloadProjectFailure(projectId, err.message));
          dispatch(removeProgress('edit: download-scene-json'));
        });

        processAuthentication(err, dispatch);
      });
  };
};

export const downloadSceneSTL = (projectId: number, projectTitle: string): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const {scene} = getGlobalObjects(projectId);

    batch(() => {
      dispatch(downloadProjectStarted(projectId));
      dispatch(addProgress('edit: download-project'));
    });

    await waitForRerender();
    if (scene) {
      downloadBlobObject(exportSceneToSTL(__scenes__[projectId]), projectTitle + '.stl');

      batch(() => {
        dispatch(downloadProjectSuccess(projectId));
        dispatch(removeProgress('edit: download-project'));
      });
    } else {
      _fetchProject(projectId)
        .then(async res => {
          if (res.data.scene) {
            if (res.data.scene[_s('version')] !== AlphaSceneVersion && res.data.scene[_s('version')] !== BetaSceneVersion) {
              toast("Project version mismatch.", {
                className: "toast toast-alert",
                containerId: 'default',
                autoClose: 3000
              });
              return;
            }

            let scene = await Scene.decode(res.data.scene);
            downloadBlobObject(exportSceneToSTL(scene), projectTitle + '.stl');
          }

          batch(() => {
            dispatch(downloadProjectSuccess(projectId));
            dispatch(removeProgress('edit: download-project'));
          });
        })
        .catch(err => {
          batch(() => {
            dispatch(downloadProjectFailure(projectId, err.message));
            dispatch(removeProgress('edit: download-project'));
          });

          processAuthentication(err, dispatch);
        });
    }
  };
};

export const downloadModels = (project: IProject, title: string, calcIds: string[], brep?: boolean): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    let {scene} = getGlobalObjects(project.id);

    batch(() => {
      dispatch(downloadProjectStarted(project.id));
      dispatch(addProgress('edit: download-models'));
    });

    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        let exportCalcs;
        if (brep)
          exportCalcs = exportCalcsToBREP(scene, calcIds);
        else
          exportCalcs = exportCalcsToSTL(scene, calcIds);

        exportCalcs.then(data => {
          downloadBlobObject(data, title + (brep ? '.brep' : '.stl'));
          batch(() => {
            dispatch(downloadProjectSuccess(project.id));
            dispatch(removeProgress('edit: download-models'));
          });
        });
      });
    });
  };
};

export const downloadDenoisedImage = (imageData: string, projectTitle: string): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(addProgress('edit: download-denoised-image'));

    _getDenoisedImage(imageData)
      .then(async res => {
        if (res.data.success) {
          const link = document.createElement('a');
          link.href = res.data.data;
          link.setAttribute('download', `Denoised-${moment().format()}.png`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        dispatch(removeProgress('edit: download-denoised-image'));
      })
      .catch(err => {
        dispatch(removeProgress('edit: download-denoised-image'));

        processAuthentication(err, dispatch);
      });
  };
};

export const fetchMediaProjectLoad = (projectId: number, wrapper: Editor3dWrapper): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    dispatch(fetchProjectLoadStarted(projectId));

    if (__scenes__[projectId]) {
      let scene = __scenes__[projectId];
      wrapper.editor && wrapper.editor._clear();
      await refreshScene(projectId, dispatch, wrapper);

      dispatch(updateCommandManager(projectId, scene.snapshot));

      setTimeout(() => {
        wrapper.editor && wrapper.editor.repositionCamera('preview-zoom');
      }, 100);

      dispatch(fetchProjectLoadSuccess(projectId));
      return {success: true};
    } else {
      return _fetchMediaProject(projectId)
        .then(
          fetchProgress({
            onProgress(progress) {
              if (progress.total) {
                dispatch(fetchProjectLoadStarted(projectId, 'downloading model', {percentage: progress.transferred / progress.total * 30}));
              }
            }
          })
        ).then(async res => {
          let data = await res.json();
          let digitalMaterials = filterDigitalMaterials(Object.values(data.digitalMaterials));
          let project = filterProject(data);

          let initialScene = data.scene;
          let scene = await ProjectScene.init(initialScene, (progress) => {
            dispatch(fetchProjectLoadStarted(projectId, 'composing model', {percentage: progress * 70 / 100 + 30}));
          });
          __scenes__[projectId] = scene;

          await scene.runExclusive(async () => {
            await scene.solve();
          });

          dispatch(addProject(project));
          dispatch(addDigitalMaterials(digitalMaterials));

          wrapper.editor && wrapper.editor._clear();
          await refreshScene(projectId, dispatch, wrapper);

          dispatch(updateCommandManager(projectId, scene.snapshot));

          setTimeout(() => {
            wrapper.editor && wrapper.editor.repositionCamera('preview-zoom');
          }, 100);

          dispatch(fetchProjectLoadSuccess(projectId));
          return {success: true};
        })
        .catch(err => {
          console.warn(err);

          return {success: false};
        });
    }
  };
};

export const changeCollaborators = (projectId: number, usernames: string[]): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    return _postChangeCollaborators(projectId, usernames)
      .then(async res => {
        if (res.data.success) {
          let projects = filterProjects(res.data.data);

          dispatch(addProjects(projects));
          return {success: true};
        }
        return {success: false, message: res.data.message};
      })
      .catch(err => {
        return processAuthentication(err, dispatch).then(res => {
          if (res.success) {
            return dispatch(changeCollaborators(projectId, usernames));
          } else {
            return res;
          }
        });
      });
  };
};

export const acceptInfoFromRoute = (projectId: number, sourceRouteId: number, accept: boolean): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    let {scene} = getGlobalObjects(projectId);

    if (!scene)
      return {success: false};

    return _postAcceptInfoRouteId(scene.route.id, sourceRouteId, accept)
      .then(async res => {
        return {success: true};
      })
      .catch(err => {
        return processAuthentication(err, dispatch).then(res => {
          if (res.success) {
            return dispatch(acceptInfoFromRoute(scene.route.id, sourceRouteId, accept));
          } else {
            return res;
          }
        });
      });
  };
};
