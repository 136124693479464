import {XObject} from "../xobject";
import {ICalcConfigDiff, IParameterProperty} from "../types";
import {Relation} from "../relation";
import {getObjectType, Parameter, ParamTypes} from "./parameter";
import {Param_Compound} from "./param-compound";
import hash from "object-hash";
import {_s} from "../../t";
import {peregrineId} from "../../id";

export class Param_Json extends Parameter {
  protected _objectType = ParamTypes.Json;
  protected _defaultValue: undefined;

  // Runtime Data
  protected _values: any[] = [];

  get values() {
    return this._values;
  }

  set values(values: any[]) {
    if (this._values !== values) {
      this._values = values;
      this._stateValid = false;
      this._dataValid = false;
    }
  }

  get length() {
    return this._values.length;
  }

  static create(calc: XObject, title: string, isInput: boolean, isOperable: boolean) {
    let param = new Param_Json();
    param._id = peregrineId();
    param._calc = calc;
    param._title = title;
    param._values = [];
    param._isInput = isInput;
    param._isOperable = isOperable;

    param.setupInitialData();
    return param;
  }

  generateProperties(): IParameterProperty[] {
    let properties: IParameterProperty[] = [];

    for (let i = 0; i < this._values.length; ++i) {
      let value = this._values[i];

      properties.push({
        hash: hash(value, {algorithm: 'md5'})
      });
    }

    return properties;
  }

  protected validateGeneratedData() {
    this.dataValues = this._values;

    this._dataValid = true;
  }

  overwrite(jData: any, replaceData: boolean) {
    if (!this.dataValid)
      this.validateGeneratedData();

    super.overwrite(jData, replaceData);

    if (this._data[_s('values')] !== jData[_s('values')] && jData[_s('values')] !== undefined) {
      this.values = jData[_s('values')];
    }

    if (replaceData) {
      this._data = jData;
      this._dataValid = true;
    }
  }

  solve(diff?: ICalcConfigDiff) {
    if (diff && diff.obj) {
      this._values = [];

      for (let relation of this._prevRelations) {
        let prev = (relation as unknown as Relation).from;
        if (prev.objectType === ParamTypes.Compound) {
          let prevComp = prev as Param_Compound;
          this._values = prevComp.filterValues(getObjectType(this._objectType));
        } else {
          if (prev.objectType === this._objectType) {
            let prevSame = prev as Param_Json;
            this._values = [...this._values, ...prevSame._values];
          } else {
            console.warn('Type mismatch. Please implement type conversion');
          }
        }
      }

      if (this._prevRelations.length === 0 && this._defaultValue !== undefined) {
        this._values = [this._defaultValue];
      }
      this._dataValid = false;
    }
  }
}
