import {
  ADD_LIBRARY_ITEMS,
  IEntityLibraryItemsState,
  LibraryItemsActionTypes,
  REMOVE_LIBRARY_ITEMS,
  REPLACE_LIBRARY_ITEMS
} from '../../types/entity/library-items';
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';

const initialState: IEntityLibraryItemsState = {
  byId: {},
  allIds: []
};

export function entityLibraryItemsReducer(
  state = initialState,
  action: LibraryItemsActionTypes
): IEntityLibraryItemsState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case REPLACE_LIBRARY_ITEMS: {
      return {
        byId: lod.keyBy(action.payload.items, 'id'),
        allIds: lod.map(action.payload.items, 'id')
      };
    }
    case ADD_LIBRARY_ITEMS: {
      return {
        byId: {
          ...state.byId,
          ...lod.keyBy(action.payload.items, 'id')
        },
        allIds: [
          ...state.allIds,
          ...lod.map(action.payload.items, 'id').filter(id => !state.byId[id])
        ]
      };
    }
    case REMOVE_LIBRARY_ITEMS: {
      return {
        byId: lod.omit(state.byId, action.payload.ids),
        allIds: state.allIds.filter(id => !action.payload.ids.includes(id))
      };
    }
    default:
      return state;
  }
}