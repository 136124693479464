import {ComponentTypes} from "../types";
import {
  createObjectFromMeshData,
  getBrepFromRef,
  getMeshFromRef,
  Param_Compound,
  Param_Transform,
  ParamTitles, registerBrepGeometry,
  registerBrepGeometryWithTessellation,
  registerGeometry
} from "../../parameter";
import {turnOutGeometryOnWorker} from "../../workers";
import {Calc} from "../../calc";
import {mat4} from "gl-matrix";
import {copyObject, createSimpleGumball, IRenderedObjectInternal, setObjectTransform} from "../gumball";
import {GeometryObjectTypes, MeshGeometryObjectTypes, ObjectTypes} from "../../xobject";
import {BasicObjectMapper, ObjectFuncParameter, PredictedResult} from "../base";
import {WompObjectRef} from "../../../WompObject";

export class MirrorModifier extends BasicObjectMapper {
  static create() {
    let calc = createSimpleGumball();
    calc.component = ComponentTypes.MirrorModifier;
    calc.title = 'mirror';

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let mirrorParam = Param_Transform.create(calc, ParamTitles.Mirror, true, false);
    let outParam = Param_Compound.create(calc, ParamTitles.Out, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(mirrorParam);
    calc.addParameter(outParam);
    return calc;
  }

  static getParameters(calc: Calc, obj: IRenderedObjectInternal) {
    let mirrorParam = calc.inputByTitle(ParamTitles.Mirror) as Param_Transform;
    let mirror = mirrorParam.values[0];

    let desc;
    if (MeshGeometryObjectTypes.includes(obj.valueType) || obj.valueType === ObjectTypes.Brep) {
      if (obj.value.objectId.startsWith('turnOut[')) {
        let lastInd = obj.value.objectId.lastIndexOf(']');
        if (lastInd < 0)
          lastInd = obj.value.objectId.length - 1;
        desc = obj.value.objectId.substr(5, lastInd - 5);
      } else
        desc = `turnOut[${obj.value.objectId}]`;
    } else {
      desc = obj.value.objectId;
    }

    let matrix = mat4.create();
    mat4.multiply(matrix, mirror, obj.value.matrix);

    return {desc, matrix};
  }

  static predictResult(calc: Calc, obj: IRenderedObjectInternal) {
    if (GeometryObjectTypes.includes(obj.valueType))
      if (obj.valueType === ObjectTypes.Vertex)
        return PredictedResult.CopyFirst;
      else if (obj.valueType === ObjectTypes.SculptMesh)
        return PredictedResult.Mesh;
      else
        return obj.valueType as unknown as PredictedResult;
    return PredictedResult.Ignore;
  }

  static async mapObject(calc: Calc, obj: IRenderedObjectInternal, parameters: ObjectFuncParameter) {
    if (MeshGeometryObjectTypes.includes(obj.valueType)) {
      let mesh = getMeshFromRef(calc, obj.value);
      if (mesh) {
        let turnedOut = await turnOutGeometryOnWorker(mesh.geometry);
        return createObjectFromMeshData(calc, turnedOut, parameters.desc, parameters.matrix);
      }
    } else if (obj.valueType === ObjectTypes.Brep) {
      let brep = getBrepFromRef(calc, obj.value);
      if (brep && obj.cache) {
        let mesh = getMeshFromRef(calc, obj.cache[0]);
        let edge = getMeshFromRef(calc, obj.cache[1]);
        if (mesh && edge) {
          let turnedOut = await turnOutGeometryOnWorker(mesh.geometry);
          let id = registerBrepGeometry(calc, brep.geometry, parameters.desc);
          let meshId = registerGeometry(calc, turnedOut, parameters.desc + '-mesh');
          let edgeId = registerGeometry(calc, edge.geometry, parameters.desc + '-edge');

          return {
            value: new WompObjectRef(id, parameters.matrix),
            valueType: ObjectTypes.Brep,
            cache: [new WompObjectRef(meshId, parameters.matrix), new WompObjectRef(edgeId, parameters.matrix)]
          };
        }
      }
    }

    return setObjectTransform(copyObject(obj), parameters.matrix);
  }
}