import {
  ADD_PROJECT,
  ADD_PROJECTS,
  ADD_SCREENSHOT_IDS,
  CHANGE_FIELDS,
  ProjectsActionTypes,
  IEntityProjectsState,
  REMOVE_PROJECT,
  REMOVE_SCREENSHOT_IDS,
  REPLACE_ATTACHMENTS, REPLACE_CHAT_SESSION_IDS,
  REPLACE_PROJECTS,
  UPDATE_SCENE
} from '../../types/entity/projects';
import {IProject} from '../../types/types';
import {INIT_STORE} from "../../types/init";
import {defaultScene} from "../../../peregrine/processor";
import lod from 'lodash';

const initialState: IEntityProjectsState = {
  byId: {},
  allIds: []
};

export function entityProjectsReducer(
  state = initialState,
  action: ProjectsActionTypes
): IEntityProjectsState {
  const getSortedAllIds = (byId: { [key: number]: IProject }) => {
    return Object.keys(byId).map(Number).sort((a, b) => {
      if (!byId[a].lastModifiedDate || !byId[b].lastModifiedDate)
        return 0;

      if (byId[a].lastModifiedDate.getTime() < byId[b].lastModifiedDate.getTime())
        return 1;
      else if (byId[a].lastModifiedDate.getTime() > byId[b].lastModifiedDate.getTime())
        return -1;
      else
        return 0;
    });
  };

  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case ADD_PROJECTS: {
      let newProjects: { [id: number]: IProject } = {...state.byId};
      for (let project of action.payload.projects) {
        let orgProject = newProjects[project.id];
        let oldDirId = orgProject ? orgProject.directoryId : 0;
        let newDirId = project.directoryId;

        if (orgProject === undefined)
          newProjects[project.id] = project;
        else {
          newProjects[project.id] = {
            ...project,
            snapshot: (project.snapshot !== undefined ? project.snapshot : orgProject.snapshot),
            screenshotIds: (project.screenshotIds.length !== 0 ? project.screenshotIds : orgProject.screenshotIds),
            attachments: (project.attachments !== undefined && project.attachments.length !== 0 ? project.attachments : orgProject.attachments),
            otherChatSessionIds: (project.otherChatSessionIds !== undefined && project.otherChatSessionIds.length !== 0 ? project.otherChatSessionIds : orgProject.otherChatSessionIds),
            scn: (project.scn !== defaultScene ? project.scn : orgProject.scn)
          };
        }

        if (oldDirId !== newDirId) {
          let oldDir = newProjects[oldDirId];
          let newDir = newProjects[newDirId];

          if (oldDir) {
            newProjects[oldDirId] = {
              ...oldDir,
              innerProjects: oldDir.innerProjects.filter(p => p.id !== project.id)
            };
          }

          if (newDir) {
            newProjects[newDirId] = {
              ...newDir,
              innerProjects: [
                ...newDir.innerProjects,
                lod.pick(orgProject, ['id', 'thumbnail', 'thumbnailWidth', 'thumbnailHeight', 'thumbnailZoom', 'isDirectory'])
              ]
            };
          }
        }
      }

      return {
        byId: newProjects,
        allIds: getSortedAllIds(newProjects),
        config: action.payload.config
      };
    }
    case REPLACE_PROJECTS: {
      let newProjects: { [id: number]: IProject } = {};
      for (let project of action.payload.projects) {
        let orgProject = newProjects[project.id];

        if (orgProject === undefined)
          newProjects[project.id] = project;
        else {
          newProjects[project.id] = {
            ...project,
            snapshot: (project.snapshot !== undefined ? project.snapshot : orgProject.snapshot),
            screenshotIds: (project.screenshotIds.length !== 0 ? project.screenshotIds : orgProject.screenshotIds),
            attachments: (project.attachments !== undefined && project.attachments.length !== 0 ? project.attachments : orgProject.attachments),
            otherChatSessionIds: (project.otherChatSessionIds !== undefined && project.otherChatSessionIds.length !== 0 ? project.otherChatSessionIds : orgProject.otherChatSessionIds),
            scn: (project.scn !== defaultScene ? project.scn : orgProject.scn)
          };
        }
      }

      return {
        byId: newProjects,
        allIds: getSortedAllIds(newProjects),
        config: action.payload.config
      };
    }
    case REPLACE_ATTACHMENTS: {
      if (!state.byId[action.payload.id]) {
        return state;
      } else {
        return {
          byId: {
            ...state.byId,
            [action.payload.id]: {
              ...state.byId[action.payload.id],
              attachments: action.payload.attachments
            }
          },
          allIds: state.allIds,
          config: state.config
        };
      }
    }
    case REPLACE_CHAT_SESSION_IDS: {
      if (!state.byId[action.payload.id]) {
        return state;
      } else {
        return {
          byId: {
            ...state.byId,
            [action.payload.id]: {
              ...state.byId[action.payload.id],
              otherChatSessionIds: action.payload.chatSessionIds
            }
          },
          allIds: state.allIds,
          config: state.config
        };
      }
    }
    case ADD_PROJECT: {
      let byId = {
        ...state.byId,
        [action.payload.project.id]: action.payload.project
      };

      return {
        byId: byId,
        allIds: getSortedAllIds(byId),
        config: state.config
      };
    }
    case REMOVE_PROJECT: {
      return {
        byId: lod.omit(state.byId, action.payload.id),
        allIds: state.allIds.filter(id => id !== action.payload.id),
        config: state.config
      };
    }
    case ADD_SCREENSHOT_IDS: {
      if (!state.byId[action.payload.id]) {
        return state;
      } else {
        let screenshotIds = action.payload.screenshotIds.filter(id => !state.byId[action.payload.id].screenshotIds.includes(id));
        return {
          byId: {
            ...state.byId,
            [action.payload.id]: {
              ...state.byId[action.payload.id],
              screenshotIds: [...state.byId[action.payload.id].screenshotIds, ...screenshotIds]
            }
          },
          allIds: state.allIds,
          config: state.config
        };
      }
    }
    case REMOVE_SCREENSHOT_IDS: {
      return {
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            screenshotIds: state.byId[action.payload.id].screenshotIds.filter(id => !action.payload.screenshotIds.includes(id))
          }
        },
        allIds: state.allIds,
        config: state.config
      };
    }
    case UPDATE_SCENE:
      if (state.byId[action.payload.id] !== undefined) {
        return {
          byId: {
            ...state.byId,
            [action.payload.id]: {
              ...state.byId[action.payload.id],
              scn: action.payload.newState
            }
          },
          allIds: state.allIds,
          config: state.config
        };
      } else {
        return state;
      }
    case CHANGE_FIELDS:
      return {
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...state.byId[action.payload.id],
            ...action.payload.fields
          }
        },
        allIds: state.allIds,
        config: state.config
      };
    default:
      return state;
  }
}