import {
  BufferGeometry,
  Float32BufferAttribute,
  LineBasicMaterial,
  LineSegments,
  Matrix4,
  Object3D,
  Vector3
} from 'three';
import {NLightHelperPicker} from './NLightHelperPicker';

var _vector = new Vector3();
var _v1 = new Vector3();
var _v2 = new Vector3();
var _v3 = new Vector3();

function NSpotLightHelper(light, lightId, camera, hoverStartEvent, hoverStopEvent, updateCallback) {

  Object3D.call(this);

  this.light = light;
  this.light.updateMatrixWorld();

  this.lightId = lightId;
  this.updateCallback = updateCallback;

  this.matrix = light.matrixWorld;
  this.matrixAutoUpdate = false;

  var geometry = new BufferGeometry();

  var positions = [
    0, 0, 0, 0, 0, 1,
    0, 0, 0, 1, 0, 1,
    0, 0, 0, -1, 0, 1,
    0, 0, 0, 0, 1, 1,
    0, 0, 0, 0, -1, 1
  ];

  for (var i = 0, j = 1, l = 32; i < l; i++, j++) {

    var p1 = (i / l) * Math.PI * 2;
    var p2 = (j / l) * Math.PI * 2;

    positions.push(
      Math.cos(p1), Math.sin(p1), 1,
      Math.cos(p2), Math.sin(p2), 1
    );

  }

  geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));

  var material = new LineBasicMaterial({fog: false});

  this.cone = new LineSegments(geometry, material);
  this.add(this.cone);

  this.positionPicker = new NLightHelperPicker(lightId + ':position', camera, hoverStartEvent, hoverStopEvent, this.onChangeLightPosition.bind(this));
  this.add(this.positionPicker);

  this.targetPicker = new NLightHelperPicker(lightId + ':target', camera, hoverStartEvent, hoverStopEvent, this.onChangeTargetPosition.bind(this));
  this.add(this.targetPicker);

  this.update();

}

NSpotLightHelper.prototype = Object.create(Object3D.prototype);
NSpotLightHelper.prototype.constructor = NSpotLightHelper;

NSpotLightHelper.prototype.dispose = function () {

  this.cone.geometry.dispose();
  this.cone.material.dispose();

  this.positionPicker.dispose();
  this.targetPicker.dispose();
};

NSpotLightHelper.prototype.setDelegate = function (delegate) {

  this.positionPicker.setDelegate(delegate);
  this.targetPicker.setDelegate(delegate);

};

NSpotLightHelper.prototype.unsetDelegate = function () {

  this.positionPicker.unsetDelegate();
  this.targetPicker.unsetDelegate();

};

NSpotLightHelper.prototype.setCamera = function (camera) {

  this.positionPicker.camera = camera;
  this.targetPicker.camera = camera;

};

NSpotLightHelper.prototype.onChangeLightPosition = function (x, y, z) {
  this.updateCallback(this.lightId, {
    position: [
      x + this.light.position.x,
      y + this.light.position.y,
      z + this.light.position.z
    ]
  });
};

NSpotLightHelper.prototype.onChangeTargetPosition = function (x, y, z) {
  this.updateCallback(this.lightId, {
    targetPosition: [
      x + this.light.target.position.x,
      y + this.light.target.position.y,
      z + this.light.target.position.z
    ]
  });
};

NSpotLightHelper.prototype.update = function () {

  this.light.updateMatrixWorld();
  this.light.target.updateMatrixWorld();

  this.matrix = this.light.matrixWorld;
  this.matrixAutoUpdate = false;

  _v1.setFromMatrixPosition(this.light.matrixWorld);
  _v2.setFromMatrixPosition(this.light.target.matrixWorld);
  _v3.subVectors(_v2, _v1);

  var coneLength = this.light.distance ? this.light.distance : 1000;
  var coneWidth = coneLength * Math.tan(this.light.angle);

  this.cone.scale.set(coneWidth, coneWidth, coneLength);

  _vector.setFromMatrixPosition(this.light.target.matrixWorld);

  this.cone.lookAt(_vector);

  this.cone.material.color.copy(this.light.color);

  this.positionPicker.sprite.matrixAutoUpdate = true;
  new Matrix4().decompose(this.positionPicker.sprite.position, this.positionPicker.sprite.quaternion, this.positionPicker.sprite.scale);

  this.targetPicker.sprite.matrixAutoUpdate = true;
  new Matrix4().decompose(this.targetPicker.sprite.position, this.targetPicker.sprite.quaternion, this.targetPicker.sprite.scale);
  this.targetPicker.sprite.position.copy(_v3);
};


export {NSpotLightHelper};