import {
	Matrix3,
	Vector3
} from "three";
/**
 * Usage:
 *  var exporter = new STLExporter();
 *
 *  // second argument is a list of options
 *  var data = exporter.parse( mesh, { binary: true } );
 *
 */

let STLExporter = function () {};

STLExporter.prototype = {

	constructor: STLExporter,

	parse: ( function () {

		let vector;
		let tmp = new Vector3();
		let normalMatrixWorld = new Matrix3();

		function outputVectorBinary(output, v, offset) {

			output.setFloat32(offset, v.x, true);
			offset += 4; // normal
			output.setFloat32(offset, v.y, true);
			offset += 4;
			output.setFloat32(offset, v.z, true);
			offset += 4;

			return offset;

		}

		function getNormal(object, j) {

			if (object.isBufferGeometry) {

				let a = getVertex(object, j, 0).clone();
				let b = getVertex(object, j, 1).clone();
				let c = getVertex(object, j, 2).clone();

				tmp.crossVectors(c.sub(b), a.sub(b)).normalize();
				return tmp;

			} else {

				return object.geometry.faces[j].normal;

			}

		}

		function getVertex(object, j, k) {

			if (object.isBufferGeometry) {

				let index = j * 3 + k;
				let vertices = object.geometry.attributes.position;
				let faces = object.geometry.index;

				if (object.hasIndex)
					index = faces.array[index];

				let i3 = index * 3;

				tmp.set(vertices.array[i3], vertices.array[i3 + 1],vertices.array[i3 + 2]);

				return tmp;

			} else {

				let face = object.geometry.faces[j];
				let index = k === 0 ? face.a : (k === 1 ? face.b : face.c);
				return object.geometry.vertices[index];

			}

		}

		return function parse( scene, options ) {

			if ( options === undefined ) options = {};

			let binary = options.binary !== undefined ? options.binary : false;

			//

			let objects = [];
			let triangles = 0;

			scene.traverse( function ( object ) {

				if ( object.isMesh ) {

					let geometry = object.geometry;
					let faceCount;

					if ( geometry.isBufferGeometry ) {

						faceCount = geometry.index ? geometry.index.count / 3 : geometry.attributes.position.count / 3;

						objects.push( {
							geometry,
							faceCount,
							isBufferGeometry: true,
							hasIndex: !!geometry.index,
							matrixWorld: object.matrixWorld
						} );

					} else if ( geometry.isGeometry ) {

						faceCount = geometry.faces.length;

						objects.push( {
							geometry,
							faceCount,
							isBufferGeometry: false,
							matrixWorld: object.matrixWorld
						} );

					}

					triangles += faceCount;

				}

			} );

			if ( binary ) {

				let offset = 80; // skip header
				let bufferLength = triangles * 2 + triangles * 3 * 4 * 4 + 80 + 4;
				console.log(`allocating buffer ${bufferLength}bytes.`);
				let arrayBuffer = new ArrayBuffer( bufferLength );
				let output = new DataView( arrayBuffer );
				output.setUint32( offset, triangles, true ); offset += 4;

				for ( let i = 0, il = objects.length; i < il; i ++ ) {

					let object = objects[ i ];
					let matrixWorld = object.matrixWorld;
					normalMatrixWorld.getNormalMatrix(matrixWorld);

					for (let j = 0, jl = object.faceCount; j < jl; j++) {

						vector = getNormal( object, j ).applyMatrix3(normalMatrixWorld).normalize();
						offset = outputVectorBinary(output, vector, offset);

						for (let k = 0; k < 3; k++) {

							vector = getVertex( object, j, k ).applyMatrix4(matrixWorld);
							offset = outputVectorBinary(output, vector, offset);

						}

						output.setUint16(offset, 0, true);
						offset += 2; // attribute byte count

					}

				}

				return output;

			} else {

				let output = '';

				output += 'solid exported\n';

				for ( let i = 0, il = objects.length; i < il; i ++ ) {

					let object = objects[ i ];
					let matrixWorld = object.matrixWorld;

					normalMatrixWorld.getNormalMatrix( matrixWorld );

					for ( let j = 0, jl = object.faceCount; j < jl; j ++ ) {

						vector = getNormal( object, j ).applyMatrix3( normalMatrixWorld ).normalize();

						output += '\tfacet normal ' + vector.x + ' ' + vector.y + ' ' + vector.z + '\n';
						output += '\t\touter loop\n';

						for ( let k = 0; k < 3; k ++ ) {

							vector = getVertex( object, j, k ).applyMatrix4( matrixWorld );
							output += '\t\t\tvertex ' + vector.x + ' ' + vector.y + ' ' + vector.z + '\n';

						}

						output += '\t\tendloop\n';
						output += '\tendfacet\n';

					}

				}

				output += 'endsolid exported\n';

				return output;

			}

		};

	}() )

};

export { STLExporter };
