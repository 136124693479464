import {IConfiguration, IWomp20Water} from "../types";

export const CHANGE_PEREGRINE_ENGINE_LOADED = 'CHANGE_PEREGRINE_ENGINE_LOADED';
export const CHANGE_CONFIGURATION = 'CHANGE_CONFIGURATION';
export const CHANGE_WAITER = 'CHANGE_WAITER';

export interface IEntityGlobalState {
  engineLoaded: boolean
  configuration?: Partial<IConfiguration>
  waiter?: IWomp20Water
}

interface ChangePeregrineEngineLoadedAction {
  type: typeof CHANGE_PEREGRINE_ENGINE_LOADED
  payload: {
    loaded: boolean
  }
}

interface ChangeConfigurationAction {
  type: typeof CHANGE_CONFIGURATION
  payload: {
    configuration?: Partial<IConfiguration>
  }
}

interface ReplaceWatierAction {
  type: typeof CHANGE_WAITER
  payload: {
    waiter?: IWomp20Water
  }
}



export type GlobalActionTypes = ChangePeregrineEngineLoadedAction |
  ChangeConfigurationAction |
  ReplaceWatierAction
