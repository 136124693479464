import {IFetchItemAsync, IFetchItemsAsync, IPostItemAsync} from "../types";
import {InitStoreAction} from "../init";

export const POST_PROFILE = 'POST_PROFILE';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILES = 'FETCH_PROFILES';

export interface IProfileState {
  load: IFetchItemAsync<number>
  save: IPostItemAsync<number>
  getProfiles: IFetchItemsAsync<number>
}

interface PostProfileAction {
  type: typeof POST_PROFILE
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id?: number
  }
}

interface FetchProfileAction {
  type: typeof FETCH_PROFILE
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    id?: number
  }
}

interface FetchProfilesAction {
  type: typeof FETCH_PROFILES
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    items?: number[]
  }
}

export type ApiProfileActionTypes =
  PostProfileAction |
  FetchProfileAction |
  FetchProfilesAction |
  InitStoreAction
