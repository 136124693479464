import * as React from 'react';
import {pure} from 'recompose';
import ReactResizeDetector from "react-resize-detector";
import {useRef} from "react";

interface IResizeDetectorProps extends React.HTMLProps<HTMLDivElement> {
  onResize?: (width: number, height: number) => void
}

const ResizeDetector: React.FC<IResizeDetectorProps> = ({onResize, className, children}) => {
  const innerDivRef = useRef(null);

  return (
    <ReactResizeDetector onResize={onResize} targetRef={innerDivRef}>
      <div ref={innerDivRef} className={"w-100 h-100 " + (className || "")}>
        {children}
      </div>
    </ReactResizeDetector>
  );
};

export default pure(ResizeDetector);
