import {
  ExclusiveTools,
  fixGeometryOnWorker,
  IModelProperty,
  ISculptStroke,
  MaterialTypes,
  RenderedObjectTypes,
  Tools,
  weldGeometryOnWorker
} from "../../peregrine/processor";
import {push} from "connected-react-router";
import {AxiosError} from "axios";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {IDigitalMaterial, IGAR, IPBRDigitalMaterial, PlaceholderBoxStates} from "../../store/types/types";
import {setAuthData} from "../../store/actions/entity/auth";
import {changeSignInFieldValue, changeSignInMessage} from "../../store/actions/ui/sign-in";
import {store} from "../../index";
import {batch} from "react-redux";
import lod from 'lodash';
import {_s} from "../../peregrine/t";
import numeral from "numeral";
import Editor3d, {IRenderMaterial, RenderMaterialTypes} from "../3d/Editor3d";
import Editor3dWrapper from "../3d/Editor3dWrapper";
import {updateScene} from "../../store/actions/entity/projects";
import {Sculpt} from "../3d/Sculpt";
import {
  getGeometryFromSculptMesh,
  getMeshDataFromThreeGeometry,
  getSculptGeometryFromGeometry,
  getThreeGeometryFromMeshData
} from "../../peregrine/converter";
import {addScenePlaceholderBoxes, removeScenePlaceholderBoxes, updateScenePlaceholderBox} from "./scene/scene";
import {updateCommandManager} from "../../store/actions/logic/project";
import {addSignInDialogOnAfterClose, changeSignInDialogOpened} from "../../store/actions/ui/dialog";
import {subscribe} from 'redux-subscriber';
import pngVoidTexture from "../../assets/images/texture/void.png";
import hash from "object-hash";
import {ICommand, ProjectScene} from "../core";
import {sculpt as sculptTypes} from "../../peregrine/sculpt";

export let __scenes__: { [id: number]: ProjectScene } = {};
export let __sculpt__: Sculpt | undefined;

export function clearScenes() {
  __scenes__ = {};
}

export function detachSculpt() {
  if (__sculpt__) {
    __sculpt__.detach();
    __sculpt__ = undefined;
  }
}

export function createSculpt(meshes: any[]) {
  __sculpt__ = new Sculpt();
  __sculpt__.attachSculpt(meshes);
}

function isSameSculptingSession(editor3d: Editor3d, scene: ProjectScene) {
  if (!(editor3d.selection.tool === Tools.Sculpt && scene.tool === Tools.Sculpt && lod.isEqual(editor3d.selection.ids, scene.selectedCalcIds)))
    return false;

  // for (let calcId of scene.selectedCalcIds) {
  //   let calc = scene.getCalcById(calcId);
  //   let model = editor3d.models[calcId];
  //
  //   if (!model || !lod.isEqual(model.subs.map(s => s.geometryHash + s.matrixHash), calc.fullObjects.map(obj => obj.property.hash)))
  //     return false;
  // }

  return true;
}

export async function refreshScene(projectId: number, dispatch: ThunkDispatch<{}, {}, AnyAction>, wrapper?: Editor3dWrapper, dontUpdateState?: boolean) {
  const {scene} = getGlobalObjects(projectId);
  if (!scene)
    return;

  if (!wrapper || !wrapper.editor) {
    if (!dontUpdateState)
      dispatch(updateScene(projectId, scene.state));
    return;
  }

  const editor3d = wrapper.editor;

  await editor3d.mutex.runExclusive(async () => {
    console.log("===== Start refresh scene =====");

    const state = scene.state;
    const sameSculptingSession = isSameSculptingSession(editor3d, scene);
    editor3d.projectId = state.projectId;

    let t0 = performance.now();

    for (const calcId in state.calcs) {
      let iCalc = state.calcs[calcId];
      let calc = scene.getCalcById(calcId);
      if (calc.isInvalid || iCalc.internal) continue;

      editor3d.sceneMIM.preventValidate = true;
      await editor3d._replaceModel(calcId, calc.title, calc.component, calc.fullObjects, iCalc.heatmap, getRenderMaterial, true);
      editor3d.sceneMIM.preventValidate = false;
      editor3d._setPrevModelIds(calcId, iCalc.prevCalcIds, true);
      editor3d._setMetaInfo(calcId, calc.metaInfo, true);
    }

    for (const calcId in editor3d.models) {
      if (state.calcs[calcId]) {
        let calc = state.calcs[calcId];
        if (calc.internal) continue;

        editor3d._setModelVisible(calcId, calc.visible, true);
        editor3d._setHeatmapVisible(calcId, calc.heatmap, true);
        editor3d._setModelLocked(calcId, calc.locked);
        editor3d._setModelGlobal(calcId, calc.global);
      } else {
        editor3d._removeModel(calcId);
      }
    }

    console.log(
      `[${Object.keys(state.calcs).length}] Total Models, ` +
      `[${Object.values(editor3d.geometryMap).flatMap(v => Array.from(v.usedIn)).length}] Total Solid Objects, ` +
      `[${Object.keys(editor3d.geometryMap).length}] Unique Solid Objects`
    );

    editor3d._setMeasurements(state.measures, true);
    editor3d._setAnnotations(state.annotates, true);
    editor3d._setMagnetMapping(state.magnetMappings);
    editor3d._setLights(state.lights);

    editor3d._setMeasureUnit(state.measureUnit);
    editor3d._setFloor(state.floor);
    editor3d._setCameraInfo(state.cameraInfo);
    editor3d._setCameraAngle(state.cameraAngle);

    editor3d._setEditLevels(state.editLevels, true);

    const envInfo = store.getState().ui.scene.previewEnvironmentInfo;
    let {isSetAsBackground, lightEnvironment, backgroundEnvironment} = state;

    if (envInfo && envInfo.lightEnvironment !== undefined)
      lightEnvironment = envInfo.lightEnvironment;

    if (envInfo && envInfo.backgroundEnvironment !== undefined)
      backgroundEnvironment = envInfo.backgroundEnvironment;

    if (envInfo && envInfo.isSetAsBackground !== undefined)
      isSetAsBackground = envInfo.isSetAsBackground;

    editor3d._setLightingEnvironment(lightEnvironment);
    editor3d._setBackgroundEnvironment(isSetAsBackground ? lightEnvironment : backgroundEnvironment);

    editor3d._setViewType(state.viewType);

    editor3d._refreshScopes();
    editor3d._lateRefreshVisibilities();
    editor3d._lateRefreshMaterials(state.tool, state.selectedCalcIds);

    let objIds = [], objDescs = [];
    if (state.tool === Tools.Sculpt) {
      for (let calcId of state.selectedCalcIds) {
        let calc = scene.getCalcById(calcId);
        for (let obj of calc.fullObjects) {
          let objId = obj.meshRef ? obj.meshRef.objectId : '';
          let rObj = scene.getRegisteredObject(objId);
          if (rObj) {
            objIds.push(objId);
            objDescs.push(rObj.uniqueDesc);
          }
        }
      }
    }

    if (!sameSculptingSession) {
      editor3d._setSelection(state.tool, state.selectedCalcIds, objIds, objDescs, state.toolConfig, true);
    }

    if (state.tool === Tools.Sculpt) {
      let strokes: ISculptStroke[] = [];
      for (let calcId of state.selectedCalcIds) {
        let calc = scene.getCalcById(calcId);
        for (let obj of calc.fullObjects) {
          if (obj.strokes) {
            strokes = obj.strokes;
          }
        }
      }
      editor3d._syncSculptStrokes(objIds, objDescs, strokes);
    }

    editor3d._lateRefresh();

    if (!dontUpdateState)
      dispatch(updateScene(projectId, state));

    console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took to refresh scene`);
    console.log(`===== End refresh scene =====`);
  });
}

export function refreshPlaceholders(wrapper: Editor3dWrapper) {

  const editor3d = wrapper.editor;

  if (editor3d) {

    const placeholderBoxes = store.getState().ui.scene.placeholderBoxes;

    editor3d._setPlaceholderBoxes(placeholderBoxes, true);
    editor3d._lateRefresh();

  }

}

export async function refreshObjectWeldings(dispatch: ThunkDispatch<{}, {}, AnyAction>, wrapper: Editor3dWrapper) {

  const editor3d = wrapper.editor;

  if (editor3d) {

    const objectWeldings = store.getState().ui.scene.temporaryObjectWeldings;

    for (let objectId in editor3d.geometryMap) {

      let welding = objectWeldings[objectId];
      let obj = editor3d.geometryMap[objectId];
      let calcId = welding ? welding.baseCalcId : '';

      let targetAngle = welding ? welding.angle : obj.orgWeldAngle;

      if (obj && obj.weldAngle !== targetAngle) {

        if (calcId) {

          let bBox = editor3d.getModelBoundingBox([calcId]);

          await dispatch(addScenePlaceholderBoxes({
            [calcId]: {
              bBox: [[bBox.minX, bBox.minY, bBox.minZ], [bBox.maxX, bBox.maxY, bBox.maxZ]],
              description: `welding`,
              state: PlaceholderBoxStates.Success,
              progress: 0
            }
          }, wrapper));

        }

      }

    }

    let weldPromises = [];
    let objs: any[] = [];
    let weldings: any[] = [];

    for (let objectId in editor3d.geometryMap) {

      let welding = objectWeldings[objectId];
      let obj = editor3d.geometryMap[objectId];
      let calcId = welding ? welding.baseCalcId : '';

      let targetAngle = welding ? welding.angle : obj.orgWeldAngle;

      if (obj && obj.weldAngle !== targetAngle) {

        weldPromises.push(weldGeometryOnWorker(getMeshDataFromThreeGeometry(obj.geometry), targetAngle, false, async (progress) => {
          if (calcId) {
            await dispatch(updateScenePlaceholderBox(calcId, {
              progress: progress
            }, wrapper));
          }
        }));

        objs.push(obj);
        weldings.push(obj);

      }

    }

    await Promise.all(weldPromises).then(weldedGeoms => {

      for (let i = 0; i < weldedGeoms.length; ++i) {

        let weldedGeom = weldedGeoms[i];
        let obj = objs[i];

        let weldedThreeGeom = getThreeGeometryFromMeshData(weldedGeom);
        weldedThreeGeom.computeVertexNormals();

        obj.geometry.attributes = weldedThreeGeom.attributes;
        obj.geometry.index = weldedThreeGeom.index;

      }

      editor3d.setNeedsUpdate();

    });

    for (let objectId in editor3d.geometryMap) {

      let welding = objectWeldings[objectId];
      let obj = editor3d.geometryMap[objectId];
      let calcId = welding ? welding.baseCalcId : '';

      let targetAngle = welding ? welding.angle : obj.orgWeldAngle;

      if (obj && obj.weldAngle !== targetAngle) {

        if (calcId)
          await dispatch(removeScenePlaceholderBoxes([calcId], wrapper));

        obj.weldAngle = targetAngle;

      }

    }

  }

}

// export const voidTexture = new TextureLoader().load(pngVoidTexture);
// voidTexture.wrapS = RepeatWrapping;
// voidTexture.wrapT = RepeatWrapping;
// voidTexture.repeat.set(14, 8);
// voidTexture.offset.set(1.0 / 4, -1.0 / 7);

const voidDetail: IPBRDigitalMaterial = {
  color: '#808080',
  sheen: '#000000',
  clearcoat: 0.0,
  clearcoatRoughness: 0.0,
  metalness: 0.0,
  roughness: 1.0,
  opacity: 0.99,
  map: {
    url: pngVoidTexture,
    wrapS: 'RepeatWrapping',
    wrapT: 'RepeatWrapping',
    repeat: [14, 8],
    offset: [1.0 / 4, -1.0 / 7],
  }
};

const originalDetail: IPBRDigitalMaterial = {
  color: '#808080',
  sheen: '#000000',
  clearcoat: 0.0,
  clearcoatRoughness: 0.0,
  metalness: 0.0,
  roughness: 1.0,
  opacity: 1.0
};

const getPropertyHash = (material: IDigitalMaterial) => {
  return hash(JSON.stringify(material.pbr), {algorithm: 'md5'});
};

const getRenderMaterial = (id: string, type: RenderedObjectTypes, property: IModelProperty): IRenderMaterial => {
  const state = store.getState();
  // const materialById = state.entities.materials.byId;
  // const materialGroupById = state.entities.materialGroups.byId;
  const digitalMaterialById = state.entities.digitalMaterials.byId;
  const previewCalcIds = state.ui.scene.previewCalcIds;
  const previewDigitalMaterial = state.ui.scene.previewDigitalMaterial;

  let eitherMaterial = property.material;
  let voided = property.voided;

  if (type === RenderedObjectTypes.Line) {
    return {
      pbrHash: `line`,
      type: RenderMaterialTypes.Line,
    };
  } else if (type === RenderedObjectTypes.Vertex) {
    return {
      pbrHash: `vertex`,
      type: RenderMaterialTypes.Vertex,
    };
  } else {
    if (voided) {
      return {
        pbrHash: `void`,
        type: RenderMaterialTypes.Voided,
        detail: voidDetail
      };
    } else if (eitherMaterial.type === MaterialTypes.MaterialGroup) {
      // const groupId = eitherMaterial.id;
      //
      // if (materialGroupById[groupId] && materialGroupById[groupId].materialIds.length > 0) {
      //   let material = materialById[materialGroupById[groupId].materialIds[0]];
      //
      //   if (material) {
      //     return {
      //       hash: `mat[${flatShading}:${getPropertyHash(material.rendering)}]`,
      //       type: RenderMaterialTypes.Material,
      //       flatShading: flatShading,
      //       minThickness: material.details.wall[0],
      //       detail: material.rendering
      //     };
      //   }
      // }
    } else if (eitherMaterial.type === MaterialTypes.DigitalMaterial) {
      const materialId = eitherMaterial.id;
      let material = digitalMaterialById[materialId];

      if (previewDigitalMaterial &&
        previewDigitalMaterial.id &&
        materialId === previewDigitalMaterial.id &&
        (previewCalcIds.length === 0 || previewCalcIds.includes(id))
      ) {
        material = previewDigitalMaterial;
      }

      if (material) {
        return {
          pbrHash: `digmat:${getPropertyHash(material)}]`,
          luxHash: material.lux.hash,
          type: RenderMaterialTypes.DigitalMaterial,
          detail: material.pbr
        };
      }
    }

    return {
      pbrHash: `org`,
      luxHash: '',
      type: RenderMaterialTypes.Original,
      detail: originalDetail
    };
  }
};

// TODO: BYZ - check if this works on all platforms
export const waitForRerender = () => {
  return new Promise((resolve) => {
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        resolve();
      });
    });
  });
};

export const getGlobalObjects = (projectId: number) => {
  return {
    scene: __scenes__[projectId]
  };
};

export const runWithBreaks = async (thunks: any[]): Promise<any> => {
  if (thunks.length > 0) {
    await thunks[0]();
    new Promise(r => setTimeout(r, 1)).then(async () => {
      runWithBreaks(thunks.slice(1));
    });
  }
};

export const processAuthentication = async (error: AxiosError, dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
  return new Promise<IGAR>((resolve, reject) => {
    console.error('authentication error', error);
    let info: { [key: string]: any } = {};
    if (error) {
      if (error.response) {
        info.status = error.response.status;

        if (error.response.status === 401) {
          dispatch(setAuthData({appAdmin: false, verified: true}));

          if (error.response.data['detail'] === 'Signature has expired.') {
            dispatch(changeSignInMessage('your session has expired. please login again.'));
          } else if (error.response.data['detail'] === 'Authentication credentials were not provided.') {
            dispatch(changeSignInMessage('please login.'));
          } else if (error.response.data['detail'] === 'Permission denied.') {
            if (store.getState().entities.auth.token) {
              dispatch(changeSignInMessage('you do not have access to the resource. please consider logging into another account'));
            } else {
              dispatch(changeSignInMessage('please login.'));
            }
          }

          let locationPath = store.getState().router.location.pathname;

          if (locationPath.startsWith('/discover') || locationPath.startsWith('/create/') || locationPath.startsWith('/public') || locationPath.startsWith('/dashboard')) {
            if (!store.getState().ui.dialog.signInDialogOpened) {
              batch(() => {
                dispatch(changeSignInFieldValue('username_or_email', localStorage.getItem('username')));
                dispatch(changeSignInDialogOpened(true));
              });
            }

            dispatch(addSignInDialogOnAfterClose((result: IGAR) => {
              resolve(result);
              if (!result.success) {
                window.location.pathname = '/sign-in';
              }
            }));
          } else {
            resolve({success: false, message: 'back to sign in'});
            window.location.pathname = '/sign-in';
          }
          return;
        }
      } else {
        if (String(error).indexOf('Network Error') >= 0) {
          dispatch(push('/sign-in'));
          dispatch(changeSignInMessage('please check your internet connection.'));
          resolve({success: false, message: 'internet connection'});
          return;
        } else {
          resolve({success: false, message: error.message});
          return;
        }
      }
    }
    resolve({success: false, message: error.message, info});
  });
};

export const waitForStoreMultiple = async (conditions: { path: string, value: any }[]) => {
  return Promise.all(
    conditions.map(c => waitForStore(c.path, c.value))
  );
};

export const waitForStore = async (path: string, value: any): Promise<true> => {
  let curValue = lod.get(store.getState(), path);
  const checkValue = (v: any) => {
    if (lod.isFunction(value))
      return value(v);
    else
      return v === value;
  };

  if (curValue === undefined || checkValue(curValue)) {
    // console.log('immediate', path, curValue);
    return true;
  } else {
    return new Promise<true>((resolve) => {
      const unsubscribe = subscribe(path, state => {
        let curValue = lod.get(state, path);
        if (checkValue(curValue)) {
          // console.log('late', path, curValue);
          unsubscribe();
          resolve(true);
        }
      });
    });
  }
};

export type SceneOperationResult = {
  changesToSolve?: boolean
  refreshEditor?: boolean
  preventStateUpdate?: boolean
  operation?: string
  description?: string
  callback?: (command?: ICommand) => void
} & IGAR;

export const atomic = (projectId: number, operate: (scene: ProjectScene, dispatch: ThunkDispatch<{}, {}, AnyAction>) => Promise<SceneOperationResult>, wrapper?: Editor3dWrapper): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    const {scene} = getGlobalObjects(projectId);

    let {refreshEditor, preventStateUpdate, ...result} = await scene.runExclusive(async () => {
      let oldTool = scene.tool;
      let oldSelectedCalcIds = scene.selectedCalcIds;
      let {changesToSolve, operation, description, callback, ...other} = await operate(scene, dispatch) || {
        changesToSolve: false,
        refreshEditor: false,
        preventStateUpdate: false,
        operation: '',
        description: '',
        callback: undefined
      };

      if (wrapper &&
        wrapper.editor &&
        oldTool === Tools.Sculpt &&
        (scene.tool !== Tools.Sculpt || !lod.isEqual(scene.selectedCalcIds, oldSelectedCalcIds))
      ) {
        await scene.overwrite(scene.commandManager.state[_s('scene')]);

        let strokes: ISculptStroke[] = [];
        for (let calcId of scene.selectedCalcIds) {
          let calc = scene.getCalcById(calcId);
          for (let obj of calc.fullObjects) {
            if (obj.strokes) {
              strokes = obj.strokes;
              break;
            }
          }

          if (strokes.length > 0)
            break;
        }

        if (strokes.length > 0) {
          let sMeshes = wrapper.editor.sculptControl.sMeshes as sculptTypes.Mesh[];
          wrapper.editor.sculptControl.endStroke();

          let fixedMeshes = await Promise.all(sMeshes.map(async sMesh => {
            let geometry = getGeometryFromSculptMesh(sMesh);
            geometry = await fixGeometryOnWorker(geometry);

            return getSculptGeometryFromGeometry(geometry);
          }));

          ({
            changesToSolve,
            operation,
            description,
            callback,
            ...other
          } = scene.changeSculpts(dispatch, scene.selectedCalcIds, fixedMeshes, sMeshes.map(sMesh => sMesh.getMatrix())));

          if (changesToSolve)
            await scene.solve();

          let command = scene.createCommand(operation || '', description || '');

          if (callback)
            callback(command);
        }

        ({changesToSolve, operation, description, callback, ...other} = await operate(scene, dispatch) || {
          changesToSolve: false,
          refreshEditor: false,
          preventStateUpdate: false,
          operation: '',
          description: '',
          callback: undefined
        });
      }

      if (changesToSolve)
        await scene.solve();

      let command: ICommand | undefined;
      
      if (!ExclusiveTools.includes(oldTool) || !ExclusiveTools.includes(scene.tool))
        command = scene.createCommand(operation || '', description || '');

      if (callback)
        callback(command);

      return other;
    });

    if (refreshEditor || !preventStateUpdate)
      await refreshScene(projectId, dispatch, refreshEditor ? wrapper : undefined, preventStateUpdate);
    dispatch(updateCommandManager(projectId, scene.commandManager.commandState));

    return result;
  };
};