import lod from "lodash";
import {XObject} from "../xobject";
import {ICalcConfigDiff, ICalcSetting, IParameterProperty} from "../types";
import {Relation} from "../relation";
import {getObjectType, Parameter, ParamTypes} from "./parameter";
import {Param_Compound} from "./param-compound";
import {_s} from "../../t";
import {peregrineId} from "../../id";

export enum IndexInterfaceTypes {
  Image = 'image',
  Combo = 'combo'
}

export class Param_Index extends Parameter {
  protected _objectType = ParamTypes.Index;
  protected _defaultValue: string = '';
  protected _list: { [id: string]: string } = {};

  get list() {
    return this._list;
  }

  set list(list: { [id: string]: string }) {
    if (!lod.isEqual(this._list, list)) {
      this._list = list;
      this._stateValid = false;
      this._data = {
        ...this._data,
        [_s('list')]: list
      };
    }
  }

  protected _interfaceType: string = '';

  // Runtime Data
  protected _values: string[] = [];

  get values() {
    return this._values;
  }

  set values(values: string[]) {
    if (!lod.isEqual(this._values, values)) {
      this._values = values;
      this._stateValid = false;
      this._dataValid = false;
    }
  }

  get defaultValue() {
    return this._defaultValue;
  }

  set defaultValue(defaultValue: string) {
    if (this._defaultValue !== defaultValue) {
      this._defaultValue = defaultValue;
      this._data = {
        ...this._data,
        [_s('defaultValue')]: defaultValue
      };
    }
  }

  get length() {
    return this._values.length;
  }

  static create(calc: XObject, title: string, list: { [id: string]: string }, isInput: boolean, isOperable: boolean,
                options?: { interfaceType?: string, defaultValue?: string }) {
    let param = new Param_Index();
    param._id = peregrineId();
    param._calc = calc;
    param._title = title;
    param._isInput = isInput;
    param._isOperable = isOperable;
    param.list = list;

    if (options === undefined) options = {};

    param._interfaceType = options.interfaceType === undefined ? IndexInterfaceTypes.Combo : options.interfaceType;
    param.defaultValue = options.defaultValue === undefined ? '' : options.defaultValue;

    param.values = [param.defaultValue];

    param.setupInitialData();
    return param;
  }

  generateProperties(): IParameterProperty[] {
    let properties: IParameterProperty[] = [];

    for (let i = 0; i < this._values.length; ++i) {
      let value = this._values[i];

      properties.push({
        hash: '' + value
      });
    }

    return properties;
  }

  generateState() {
    let settings: ICalcSetting[] = [];

    for (let i = 0; i < this.length; ++i) {
      settings.push({
        id: this._id + ':' + i,
        label: this._title,
        type: 'index',
        value: this._values[i],
        list: this._list,
        interfaceType: this._interfaceType
      });
    }
    return settings;
  }

  protected validateGeneratedData() {
    this.dataValues = this._values;

    this._dataValid = true;
  }

  overwrite(jData: any, replaceData: boolean) {
    if (!this.dataValid)
      this.validateGeneratedData();

    super.overwrite(jData, replaceData);

    if (this._data[_s('list')] !== jData[_s('list')] && jData[_s('list')] !== undefined) {
      this.list = jData[_s('list')];
    }

    if (this._data[_s('values')] !== jData[_s('values')] && jData[_s('values')] !== undefined) {
      this.values = jData[_s('values')];
    }

    if (this._data[_s('defaultValue')] !== jData[_s('defaultValue')] && jData[_s('defaultValue')] !== undefined) {
      this.defaultValue = jData[_s('defaultValue')];
    }

    if (replaceData) {
      this._data = jData;
      this._dataValid = true;
    }
  }

  solve(diff?: ICalcConfigDiff) {
    if (diff && diff.obj) {
      this._values = [];

      for (let relation of this._prevRelations) {
        let prev = (relation as unknown as Relation).from;
        if (prev.objectType === ParamTypes.Compound) {
          let prevComp = prev as Param_Compound;
          this._values = prevComp.filterValues(getObjectType(this._objectType));
        } else {
          if (prev.objectType === this._objectType) {
            let prevSame = prev as Param_Index;
            this._values = [...this._values, ...prevSame._values];
          } else {
            console.warn('Type mismatch. Please implement type conversion');
          }
        }
      }

      if (this._prevRelations.length === 0 && this._defaultValue !== undefined) {
        this._values = [this._defaultValue];
      }
      this._dataValid = false;
    }
  }
}
