import {IPostAsync} from "../types";
import {InitStoreAction} from "../init";

export const CHANGE_SIGN_IN_FIELD_VALUE = 'CHANGE_SIGN_IN_FIELD_VALUE';
export const CHANGE_SIGN_IN_ERROR_VALUE = 'CHANGE_SIGN_IN_ERROR_VALUE';
export const CHANGE_SIGN_IN_MESSAGE = 'CHANGE_SIGN_IN_MESSAGE';
export const CHANGE_EMAIL_CONFIRMED_VISIBLE = 'CHANGE_EMAIL_CONFIRMED_VISIBLE';
export const CHANGE_PASSOWRD_RESET_VISIBLE = 'CHANGE_PASSOWRD_RESET_VISIBLE';

export interface ISignInState {
  fields: { [name: string]: string }
  errors: { [name: string]: string }
  signInMessage: string
  emailConfirmedVisible: boolean
  passwordResetVisible: boolean
}

interface ChangeSignInFieldValueAction {
  type: typeof CHANGE_SIGN_IN_FIELD_VALUE
  payload: {
    field: string
    value: any
  }
}

interface ChangeSignInErrorValueAction {
  type: typeof CHANGE_SIGN_IN_ERROR_VALUE
  payload: {
    field: string
    value: string
  }
}

interface ChangeSignInMessageAction {
  type: typeof CHANGE_SIGN_IN_MESSAGE
  payload: {
    message: string
  }
}

interface ChangeEmailConfirmedVisibleAction {
  type: typeof CHANGE_EMAIL_CONFIRMED_VISIBLE
  payload: {
    visible: boolean
  }
}

interface ChangePasswordResetVisibleAction {
  type: typeof CHANGE_PASSOWRD_RESET_VISIBLE
  payload: {
    visible: boolean
  }
}

export type SignInActionTypes = ChangeEmailConfirmedVisibleAction |
  ChangePasswordResetVisibleAction |
  ChangeSignInFieldValueAction |
  ChangeSignInErrorValueAction |
  ChangeSignInMessageAction |
  InitStoreAction
