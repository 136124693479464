import {ComponentTypes} from "../types";
import {getCurveFromArray} from "../../../converter";
import {createGumball} from "../gumball";
import {BooleanInterfaceTypes, Param_Boolean, Param_Curve, Param_Vertex, ParamTitles} from "../../parameter";
import {Calc} from "../../calc";
import {BasicCurveGenerator} from "./basic-curve-gen";

export class CurveGenerator extends BasicCurveGenerator {
  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.CurveGenerator;
    calc.title = 'curve';

    let vertexParam = Param_Vertex.create(calc, ParamTitles.Vertex, true, false, false);
    let periodicParam = Param_Boolean.create(calc, ParamTitles.Periodic, true, true, {
      defaultValue: false,
      interfaceType: BooleanInterfaceTypes.Toggle
    });
    let bezierParam = Param_Boolean.create(calc, ParamTitles.Bezier, true, true, {
      defaultValue: false,
      interfaceType: BooleanInterfaceTypes.Toggle
    });
    let curveParam = Param_Curve.create(calc, ParamTitles.Curve, false, false, true);

    calc.addParameter(vertexParam);
    calc.addParameter(periodicParam);
    calc.addParameter(bezierParam);
    calc.addParameter(curveParam);

    return calc;
  }

  static getCurve(calc: Calc) {
    let vertexParam = calc.inputByTitle(ParamTitles.Vertex) as Param_Vertex;
    let periodicParam = calc.inputByTitle(ParamTitles.Periodic) as Param_Boolean;
    let bezierParam = calc.inputByTitle(ParamTitles.Bezier) as Param_Boolean;

    return getCurveFromArray(vertexParam.values, periodicParam.values[0], bezierParam.values[0]);
  }
}
