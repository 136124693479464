import Utils from './Utils';

// enum marked with /!\ shouldn't change (serialized in sgl file)

var Enums = {};

Enums.Action = {
  NOTHING: 0,
  SCULPT_EDIT: 1
};

// sculpt tools
Enums.SculptTools = {
  BRUSH: 0,
  INFLATE: 1,
  TWIST: 2,
  SMOOTH: 3,
  FLATTEN: 4,
  PINCH: 5,
  CREASE: 6,
  DRAG: 7,
  MOVE: 9,
  MASKING: 10,
  LOCALSCALE: 11
};

// used by multiresolution to choose which multi res level to render
Enums.MultiState = {
  NONE: 0,
  SCULPT: 1,
  CAMERA: 2,
  PICKING: 3
};

// actions linked to shortcuts
// tools index must match
var acc = Object.keys(Enums.SculptTools).length;
Enums.KeyAction = Utils.extend({
  INTENSITY: acc++,
  RADIUS: acc++,
  NEGATIVE: acc++,
  PICKER: acc++,
  DELETE: acc++,
  CAMERA_FRONT: acc++,
  CAMERA_TOP: acc++,
  CAMERA_LEFT: acc++,
  CAMERA_RESET: acc++,
  STRIFE_LEFT: acc++,
  STRIFE_RIGHT: acc++,
  STRIFE_UP: acc++,
  STRIFE_DOWN: acc++,
  WIREFRAME: acc++,
  REMESH: acc++
}, Enums.SculptTools);

export default Enums;
