import {IDeleteItemAsync, IFetchItemsAsync, IPostItemAsync} from "../types";
import {InitStoreAction} from "../init";

export const FETCH_LIBRARY_ITEMS = 'FETCH_LIBRARY_ITEMS';
export const POST_DIGITAL_MATERIAL = 'POST_DIGITAL_MATERIAL';
export const POST_ENV_MAP = 'POST_ENV_MAP';
export const ADD_TO_MY_LIBRARY = 'ADD_TO_MY_LIBRARY';
export const DELETE_LIBRARY_ITEM = 'DELETE_LIBRARY_ITEM';

export interface IApiLibraryState {
  fetchLibraryItems: IFetchItemsAsync<string>
  postDigitalMaterial: IPostItemAsync<string>
  postEnvMap: IPostItemAsync<string>
  addToMyLibrary: IPostItemAsync<string>
  deleteLibraryItem: IDeleteItemAsync<string>
}

interface FetchLibraryItemsAction {
  type: typeof FETCH_LIBRARY_ITEMS
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    items?: string[]
  }
}

interface PostDigitalMaterialAction {
  type: typeof POST_DIGITAL_MATERIAL
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id?: string
  }
}

interface PostEnvMapAction {
  type: typeof POST_ENV_MAP
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id?: string
  }
}

interface AddToMyLibraryAction {
  type: typeof ADD_TO_MY_LIBRARY
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id?: string
  }
}

interface DeleteLibraryItemAction {
  type: typeof DELETE_LIBRARY_ITEM
  payload: {
    deleting: boolean
    success?: boolean
    message?: string
    id?: string
  }

}

export type ApiLibraryActionTypes = FetchLibraryItemsAction |
  PostDigitalMaterialAction |
  PostEnvMapAction |
  AddToMyLibraryAction |
  DeleteLibraryItemAction |
  InitStoreAction;