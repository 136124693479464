import { vec3, mat3, mat4 } from 'gl-matrix';
import Utils from '../misc/Utils';
import OctreeCell from '../math3d/OctreeCell';
import RenderData from './RenderData';

/*
Basic usage:
var mesh = new MeshStatic(gl); // provide gl only if we need to render the mesh
mesh.setVertices(vertices); // vec3 xyz
mesh.setFaces(faces); // ivec4 abcd (d=Utils.TRI_INDEX if tri)

mesh.init(); // compute octree/topo/UV, etc...
*/

class Mesh {

  constructor() {
    this._id = Mesh.ID++; // useful id to retrieve a mesh
    this._meshData = null;
    this._transformData = null;
    this._renderData = null;
  }

  setID(id) {
    this._id = id;
  }

  setVertices(vAr) {
    this._meshData._verticesXYZ = vAr;
    this._meshData._nbVertices = vAr.length / 3;
  }

  setFaces(fAr) {
    this._meshData._facesABC = fAr;
    this._meshData._nbFaces = fAr.length / 3;
  }

  setMaskings(mAr) {
    this._meshData._masking = mAr;
  }

  setMeshData(mdata) {
    this._meshData = mdata;
  }

  setRenderData(rdata) {
    this._renderData = rdata;
  }

  setTransformData(tdata) {
    this._transformData = tdata;
  }

  setNbVertices(nbVertices) {
    this._meshData._nbVertices = nbVertices;
  }

  setNbFaces(nbFaces) {
    this._meshData._nbFaces = nbFaces;
  }

  getID() {
    return this._id;
  }

  getRenderData() {
    return this._renderData;
  }

  getMeshData() {
    return this._meshData;
  }

  getTransformData() {
    return this._transformData;
  }

  getNbVertices() {
    return this._meshData._nbVertices;
  }

  getNbFaces() {
    return this._meshData._nbFaces;
  }

  getVertices() {
    return this._meshData._verticesXYZ;
  }

  getNormals() {
    return this._meshData._normalsXYZ;
  }

  getMaskings() {
    return this._meshData._masking;
  }

  getVerticesTagFlags() {
    return this._meshData._vertTagFlags;
  }

  getVerticesSculptFlags() {
    return this._meshData._vertSculptFlags;
  }

  getVerticesStateFlags() {
    return this._meshData._vertStateFlags;
  }

  getVerticesRingVertStartCount() {
    return this._meshData._vrvStartCount;
  }

  getVerticesRingVert() {
    return this._meshData._vertRingVert;
  }

  getVerticesRingFaceStartCount() {
    return this._meshData._vrfStartCount;
  }

  getVerticesRingFace() {
    return this._meshData._vertRingFace;
  }

  getVerticesOnEdge() {
    return this._meshData._vertOnEdge;
  }

  getVerticesProxy() {
    return this._meshData._vertProxy;
  }

  getFaces() {
    return this._meshData._facesABC;
  }

  getFaceNormals() {
    return this._meshData._faceNormalsXYZ;
  }

  getFaceBoxes() {
    return this._meshData._faceBoxes;
  }

  getFaceCenters() {
    return this._meshData._faceCentersXYZ;
  }

  getFacesTagFlags() {
    return this._meshData._facesTagFlags;
  }

  getFaceEdges() {
    return this._meshData._faceEdges;
  }

  getEdges() {
    return this._meshData._edges;
  }

  getNbEdges() {
    return this._meshData._edges.length;
  }

  getOctree() {
    return this._meshData._octree;
  }

  getCenter() {
    return this._transformData._center;
  }

  getMatrix() {
    return this._transformData._matrix;
  }

  setMatrix(matrix) {
    this._transformData._matrix = matrix;
  }

  getScale2() {
    var m = this._transformData._matrix;
    return m[0] * m[0] + m[4] * m[4] + m[8] * m[8];
  }

  getScale() {
    return Math.sqrt(this.getScale2());
  }

  getSymmetryOrigin() {
    var orig = vec3.create();
    var tdata = this._transformData;
    var offset = tdata._symmetryOffset * this.computeLocalRadius();
    return vec3.scaleAndAdd(orig, tdata._center, tdata._symmetryNormal, offset);
  }

  getSymmetryOffset() {
    return this._transformData._symmetryOffset;
  }

  setSymmetryOffset(offset) {
    this._transformData._symmetryOffset = offset;
  }

  getSymmetryNormal() {
    return this._transformData._symmetryNormal;
  }

  setSymmetryNormal(normal) {
    this._transformData._symmetryNormal = normal;
  }

  getFacePosInLeaf() {
    return this._meshData._facePosInLeaf;
  }

  getFaceLeaf() {
    return this._meshData._faceLeaf;
  }

  getLeavesToUpdate() {
    return this._meshData._leavesToUpdate;
  }

  getLocalBound() {
    return this._meshData._octree._aabbLoose;
  }

  getRenderNbEdges() {
    return this.getNbEdges();
  }

  init() {
    this.initColorsAndMaskings();
    this.allocateArrays();
    this.initTopology();
    this.updateGeometry();
    this.updateCenter();
  }

  initTopology() {
    this.initFaceRings();
    this.optimize();
    this.initEdges();
    this.initVertexRings();
  }

  updateGeometry(iFaces, iVerts) {
    this.updateFacesAabbAndNormal(iFaces);
    this.updateVerticesNormal(iVerts);
    this.updateOctree(iFaces);
  }

  allocateArrays() {
    var nbVertices = this.getNbVertices();

    this._meshData._normalsXYZ = this._meshData._normalsXYZ || new Float32Array(nbVertices * 3);
    this._meshData._masking = this._meshData._masking || new Float32Array(nbVertices);

    this._meshData._vertOnEdge = new Uint8Array(nbVertices);
    this._meshData._vrvStartCount = new Uint32Array(nbVertices * 2);
    this._meshData._vrfStartCount = new Uint32Array(nbVertices * 2);
    this._meshData._vertTagFlags = new Int32Array(nbVertices);
    this._meshData._vertSculptFlags = new Int32Array(nbVertices);
    this._meshData._vertStateFlags = new Int32Array(nbVertices);
    this._meshData._vertProxy = new Float32Array(nbVertices * 3);

    var nbFaces = this.getNbFaces();
    this._meshData._faceEdges = new Uint32Array(nbFaces * 3);
    this._meshData._faceBoxes = new Float32Array(nbFaces * 6);
    this._meshData._faceNormalsXYZ = new Float32Array(nbFaces * 3);
    this._meshData._faceCentersXYZ = new Float32Array(nbFaces * 3);
    this._meshData._facesTagFlags = new Int32Array(nbFaces);

    this._meshData._facePosInLeaf = new Uint32Array(nbFaces);
    var faceLeaf = this._meshData._faceLeaf;

    faceLeaf.length = nbFaces;
    for (var i = 0; i < nbFaces; ++i)
      faceLeaf[i] = null;
  }

  /** Init color and masking array */
  initColorsAndMaskings() {
    var nbVertices = this.getNbVertices();
    var i = 0;
    var len = nbVertices;
    if (!this._meshData._masking || this._meshData._masking.length !== len) {
      var mAr = this._meshData._masking = new Float32Array(len);
      for (i = 0; i < nbVertices; ++i) {
        mAr[i] = 1.0;
      }
    }
  }

  /** Computes faces ring around vertices */
  initFaceRings() {
    var fAr = this.getFaces();
    var nbVertices = this.getNbVertices();
    var nbFaces = this.getNbFaces();
    var i = 0;
    var id = 0;
    var countRing = new Uint32Array(nbVertices);
    for (i = 0; i < nbFaces; ++i) {
      id = i * 3;
      countRing[fAr[id]]++;
      countRing[fAr[id + 1]]++;
      countRing[fAr[id + 2]]++;
    }

    var ringFace = this.getVerticesRingFaceStartCount();
    var acc = 0;
    for (i = 0; i < nbVertices; ++i) {
      var count = countRing[i];
      ringFace[i * 2] = acc;
      ringFace[i * 2 + 1] = count;
      acc += count;
    }

    var vrf = new Uint32Array(Utils.getMemory(4 * nbFaces * 6), 0, nbFaces * 6);
    acc = 0;
    for (i = 0; i < nbFaces; ++i) {
      id = i * 3;
      var iv1 = fAr[id];
      var iv2 = fAr[id + 1];
      var iv3 = fAr[id + 2];
      vrf[ringFace[iv1 * 2] + (--countRing[iv1])] = i;
      vrf[ringFace[iv2 * 2] + (--countRing[iv2])] = i;
      vrf[ringFace[iv3 * 2] + (--countRing[iv3])] = i;
    }

    this._meshData._vertRingFace = new Uint32Array(vrf.subarray(0, nbFaces * 3 + acc));
  }

  /** Update a group of vertices' normal */
  updateVerticesNormal(iVerts) {
    var vrfStartCount = this.getVerticesRingFaceStartCount();
    var vertRingFace = this.getVerticesRingFace();
    var ringFaces = vertRingFace instanceof Array ? vertRingFace : null;
    var nAr = this.getNormals();
    var faceNormals = this.getFaceNormals();

    var full = iVerts === undefined;
    var nbVerts = full ? this.getNbVertices() : iVerts.length;
    for (var i = 0; i < nbVerts; ++i) {
      var ind = full ? i : iVerts[i];
      var start, end;
      if (ringFaces) {
        vertRingFace = ringFaces[ind];
        start = 0;
        end = vertRingFace.length;
      } else {
        start = vrfStartCount[ind * 2];
        end = start + vrfStartCount[ind * 2 + 1];
      }
      var nx = 0.0;
      var ny = 0.0;
      var nz = 0.0;
      for (var j = start; j < end; ++j) {
        var id = vertRingFace[j] * 3;
        nx += faceNormals[id];
        ny += faceNormals[id + 1];
        nz += faceNormals[id + 2];
      }
      var len = end - start;
      if (len !== 0.0) len = 1.0 / len;
      ind *= 3;
      nAr[ind] = nx * len;
      nAr[ind + 1] = ny * len;
      nAr[ind + 2] = nz * len;
    }
  }

  /** Computes vertex ring around vertices */
  initVertexRings() {
    var vrvStartCount = this.getVerticesRingVertStartCount();
    var vertRingVert = this._meshData._vertRingVert = new Uint32Array(this.getNbEdges() * 2);
    var vrfStartCount = this.getVerticesRingFaceStartCount();
    var vertRingFace = this.getVerticesRingFace();
    var vertTagFlags = this.getVerticesTagFlags();
    var vertOnEdge = this.getVerticesOnEdge();
    var fAr = this.getFaces();
    var vrvStart = 0;

    for (var i = 0, l = this.getNbVertices(); i < l; ++i) {
      var tagFlag = ++Utils.TAG_FLAG;
      var vrfStart = vrfStartCount[i * 2];
      var vrfEnd = vrfStart + vrfStartCount[i * 2 + 1];
      var vrvCount = 0;

      for (var j = vrfStart; j < vrfEnd; ++j) {
        var ind = vertRingFace[j] * 3;
        var iVer1 = fAr[ind];
        var iVer2 = fAr[ind + 1];
        var iVer3 = fAr[ind + 2];

        if (iVer1 === i) iVer1 = iVer3;
        else if (iVer2 === i) iVer2 = iVer3;

        if (vertTagFlags[iVer1] !== tagFlag) {
          vertRingVert[vrvStart + (vrvCount++)] = iVer1;
          vertTagFlags[iVer1] = tagFlag;
        }

        if (vertTagFlags[iVer2] !== tagFlag) {
          vertRingVert[vrvStart + (vrvCount++)] = iVer2;
          vertTagFlags[iVer2] = tagFlag;
        }
      }

      vrvStartCount[i * 2] = vrvStart;
      vrvStartCount[i * 2 + 1] = vrvCount;
      vrvStart += vrvCount;
      if ((vrfEnd - vrfStart) !== vrvCount)
        vertOnEdge[i] = 1;
    }
  }

  /** Get more vertices (n-ring) */
  expandsVertices(iVerts, nRing) {
    var tagFlag = ++Utils.TAG_FLAG;
    var nbVerts = iVerts.length;
    var vrvStartCount = this.getVerticesRingVertStartCount();
    var vertRingVert = this.getVerticesRingVert();
    var ringVerts = vertRingVert instanceof Array ? vertRingVert : null;
    var vertTagFlags = this.getVerticesTagFlags();
    var acc = nbVerts;
    var nbVertices = this.getNbVertices();
    var iVertsExpanded = new Uint32Array(Utils.getMemory(4 * nbVertices), 0, nbVertices);
    iVertsExpanded.set(iVerts);

    var i = 0;
    for (i = 0; i < nbVerts; ++i)
      vertTagFlags[iVertsExpanded[i]] = tagFlag;

    var iBegin = 0;
    while (nRing) {
      --nRing;
      for (i = iBegin; i < nbVerts; ++i) {
        var idVert = iVertsExpanded[i];
        var start, end;
        if (ringVerts) {
          vertRingVert = ringVerts[idVert];
          start = 0;
          end = vertRingVert.length;
        } else {
          start = vrvStartCount[idVert * 2];
          end = start + vrvStartCount[idVert * 2 + 1];
        }

        for (var j = start; j < end; ++j) {
          var id = vertRingVert[j];
          if (vertTagFlags[id] === tagFlag)
            continue;

          vertTagFlags[id] = tagFlag;
          iVertsExpanded[acc++] = id;
        }
      }
      iBegin = nbVerts;
      nbVerts = acc;
    }

    return new Uint32Array(iVertsExpanded.subarray(0, acc));
  }

  /** Return all the vertices linked to a group of faces */
  getVerticesFromFaces(iFaces) {
    var tagFlag = ++Utils.TAG_FLAG;
    var nbFaces = iFaces.length;
    var vertTagFlags = this.getVerticesTagFlags();
    var fAr = this.getFaces();
    var acc = 0;
    var verts = new Uint32Array(Utils.getMemory(4 * iFaces.length * 3), 0, iFaces.length * 3);

    for (var i = 0; i < nbFaces; ++i) {
      var ind = iFaces[i] * 3;
      var iVer1 = fAr[ind];
      var iVer2 = fAr[ind + 1];
      var iVer3 = fAr[ind + 2];
      if (vertTagFlags[iVer1] !== tagFlag) {
        vertTagFlags[iVer1] = tagFlag;
        verts[acc++] = iVer1;
      }
      if (vertTagFlags[iVer2] !== tagFlag) {
        vertTagFlags[iVer2] = tagFlag;
        verts[acc++] = iVer2;
      }
      if (vertTagFlags[iVer3] !== tagFlag) {
        vertTagFlags[iVer3] = tagFlag;
        verts[acc++] = iVer3;
      }
    }
    return new Uint32Array(verts.subarray(0, acc));
  }

  /** Update a group of faces normal and aabb */
  updateFacesAabbAndNormal(iFaces) {
    var faceNormals = this.getFaceNormals();
    var faceBoxes = this.getFaceBoxes();
    var faceCenters = this.getFaceCenters();
    var vAr = this.getVertices();
    var fAr = this.getFaces();

    var full = iFaces === undefined;
    var nbFaces = full ? this.getNbFaces() : iFaces.length;
    for (var i = 0; i < nbFaces; ++i) {
      var ind = full ? i : iFaces[i];
      var idTri = ind * 3;
      var idFace = ind * 3;
      var idBox = ind * 6;
      var ind1 = fAr[idFace] * 3;
      var ind2 = fAr[idFace + 1] * 3;
      var ind3 = fAr[idFace + 2] * 3;

      var v1x = vAr[ind1];
      var v1y = vAr[ind1 + 1];
      var v1z = vAr[ind1 + 2];
      var v2x = vAr[ind2];
      var v2y = vAr[ind2 + 1];
      var v2z = vAr[ind2 + 2];
      var v3x = vAr[ind3];
      var v3y = vAr[ind3 + 1];
      var v3z = vAr[ind3 + 2];

      // compute normals
      var ax = v2x - v1x;
      var ay = v2y - v1y;
      var az = v2z - v1z;
      var bx = v3x - v1x;
      var by = v3y - v1y;
      var bz = v3z - v1z;
      var crx = ay * bz - az * by;
      var cry = az * bx - ax * bz;
      var crz = ax * by - ay * bx;

      // compute boxes
      // for code readability of course
      var xmin = v1x < v2x ? v1x < v3x ? v1x : v3x : v2x < v3x ? v2x : v3x;
      var xmax = v1x > v2x ? v1x > v3x ? v1x : v3x : v2x > v3x ? v2x : v3x;
      var ymin = v1y < v2y ? v1y < v3y ? v1y : v3y : v2y < v3y ? v2y : v3y;
      var ymax = v1y > v2y ? v1y > v3y ? v1y : v3y : v2y > v3y ? v2y : v3y;
      var zmin = v1z < v2z ? v1z < v3z ? v1z : v3z : v2z < v3z ? v2z : v3z;
      var zmax = v1z > v2z ? v1z > v3z ? v1z : v3z : v2z > v3z ? v2z : v3z;

      // normals
      faceNormals[idTri] = crx;
      faceNormals[idTri + 1] = cry;
      faceNormals[idTri + 2] = crz;
      // boxes
      faceBoxes[idBox] = xmin;
      faceBoxes[idBox + 1] = ymin;
      faceBoxes[idBox + 2] = zmin;
      faceBoxes[idBox + 3] = xmax;
      faceBoxes[idBox + 4] = ymax;
      faceBoxes[idBox + 5] = zmax;
      // compute centers
      faceCenters[idTri] = (xmin + xmax) * 0.5;
      faceCenters[idTri + 1] = (ymin + ymax) * 0.5;
      faceCenters[idTri + 2] = (zmin + zmax) * 0.5;
    }
  }

  /** Get more faces (n-ring) */
  expandsFaces(iFaces, nRing) {
    var tagFlag = ++Utils.TAG_FLAG;
    var nbFaces = iFaces.length;
    var vrfStartCount = this.getVerticesRingFaceStartCount();
    var vertRingFace = this.getVerticesRingFace();
    var ringFaces = vertRingFace instanceof Array ? vertRingFace : null;
    var ftf = this.getFacesTagFlags();
    var fAr = this.getFaces();
    var acc = nbFaces;
    var iFacesExpanded = new Uint32Array(Utils.getMemory(4 * this.getNbFaces()), 0, this.getNbFaces());
    iFacesExpanded.set(iFaces);
    var i = 0;
    for (i = 0; i < nbFaces; ++i)
      ftf[iFacesExpanded[i]] = tagFlag;
    var iBegin = 0;
    while (nRing) {
      --nRing;
      for (i = iBegin; i < nbFaces; ++i) {
        var ind = iFacesExpanded[i] * 3;

        for (var j = 0; j < 3; ++j) {
          var idv = fAr[ind + j];

          if (idv === Utils.TRI_INDEX)
            continue;

          var start, end;
          if (ringFaces) {
            vertRingFace = ringFaces[idv];
            start = 0;
            end = vertRingFace.length;
          } else {
            start = vrfStartCount[idv * 2];
            end = start + vrfStartCount[idv * 2 + 1];
          }
          for (var k = start; k < end; ++k) {
            var id = vertRingFace[k];
            if (ftf[id] === tagFlag)
              continue;
            ftf[id] = tagFlag;
            iFacesExpanded[acc++] = id;
          }
        }
      }
      iBegin = nbFaces;
      nbFaces = acc;
    }
    return new Uint32Array(iFacesExpanded.subarray(0, acc));
  }

  /** Return all the faces linked to a group of vertices */
  getFacesFromVertices(iVerts, contain) {
    var tagFlag = ++Utils.TAG_FLAG;
    var nbVerts = iVerts.length;
    var nbVertices = this.getNbVertices();
    var nbFaces = this.getNbFaces();
    var vrfStartCount = this.getVerticesRingFaceStartCount();
    var vertRingFace = this.getVerticesRingFace();
    var ringFaces = vertRingFace instanceof Array ? vertRingFace : null;
    var ftf = this.getFacesTagFlags();
    var acc = 0;
    var buffer = Utils.getMemory(4 * nbFaces + nbVertices);
    var iFaces = new Uint32Array(buffer, 0, nbFaces);
    for (var i = 0; i < nbVerts; ++i) {
      var idVert = iVerts[i];
      var start, end;
      if (ringFaces) {
        vertRingFace = ringFaces[idVert];
        start = 0;
        end = vertRingFace.length;
      } else {
        start = vrfStartCount[idVert * 2];
        end = start + vrfStartCount[idVert * 2 + 1];
      }
      for (var j = start; j < end; ++j) {
        var iFace = vertRingFace[j];
        if (ftf[iFace] !== tagFlag) {
          ftf[iFace] = tagFlag;
          iFaces[acc++] = iFace;
        }
      }
    }

    if (!contain)
      return new Uint32Array(iFaces.subarray(0, acc));

    var inVerts = new Uint8Array(buffer, nbFaces * 4, nbVertices);
    for (var i = 0; i < nbVertices; ++i)
      inVerts[i] = 0;

    for (var i = 0; i < nbVerts; ++i)
      inVerts[iVerts[i]] = 1;

    var fAr = this.getFaces();
    var newAcc = 0;
    for (var i = 0; i < acc; ++i) {
      var idFace = iFaces[i];
      var id = idFace * 3;
      if (inVerts[fAr[id]] && inVerts[fAr[id + 1]] && inVerts[fAr[id + 2]]) {
        iFaces[newAcc++] = idFace;
      }
    }

    return new Uint32Array(iFaces.subarray(0, newAcc));
  }

  initEdges() {
    var fAr = this.getFaces();
    var feAr = this.getFaceEdges();
    var nbEdges = 0;
    var vertEdgeTemp = new Uint32Array(this.getNbVertices());
    var vrfStartCount = this.getVerticesRingFaceStartCount();
    var vertRingFace = this.getVerticesRingFace();
    for (var i = 0, nbVerts = this.getNbVertices(); i < nbVerts; ++i) {
      var start = vrfStartCount[i * 2];
      var end = start + vrfStartCount[i * 2 + 1];
      var compTest = nbEdges;
      for (var j = start; j < end; ++j) {
        var id = vertRingFace[j] * 3;
        var iv1 = fAr[id];
        var iv2 = fAr[id + 1];
        var iv3 = fAr[id + 2];
        var t = 0;
        var idEdge = 0;
        if (i > iv1) {
          t = vertEdgeTemp[iv1];
          idEdge = id + (i === iv2 ? 0 : 2);
          if (t <= compTest) {
            feAr[idEdge] = nbEdges;
            vertEdgeTemp[iv1] = ++nbEdges;
          } else {
            feAr[idEdge] = t - 1;
          }
        }
        if (i > iv2) {
          t = vertEdgeTemp[iv2];
          idEdge = id + (i === iv1 ? 0 : 1);
          if (t <= compTest) {
            feAr[idEdge] = nbEdges;
            vertEdgeTemp[iv2] = ++nbEdges;
          } else {
            feAr[idEdge] = t - 1;
          }
        }
        if (i > iv3) {
          t = vertEdgeTemp[iv3];
          idEdge = id + (i === iv1 ? 2 : 1);
          if (t <= compTest) {
            feAr[idEdge] = nbEdges;
            vertEdgeTemp[iv3] = ++nbEdges;
          } else {
            feAr[idEdge] = t - 1;
          }
        }
      }
    }
    var eAr = this._meshData._edges = new Uint8ClampedArray(nbEdges);
    for (var k = 0, nbFaces = this.getNbFaces(); k < nbFaces; ++k) {
      var idf = k * 3;
      eAr[feAr[idf]]++;
      eAr[feAr[idf + 1]]++;
      eAr[feAr[idf + 2]]++;
    }
  }

  /////////////////
  // TRANSFORM DATA
  /////////////////
  updateCenter() {
    var box = this.getLocalBound();
    vec3.set(this._transformData._center, (box[0] + box[3]) * 0.5, (box[1] + box[4]) * 0.5, (box[2] + box[5]) * 0.5);
  }

  computeLocalRadius() {
    var box = this.getLocalBound();
    return 0.5 * vec3.dist([box[0], box[1], box[2]], [box[3], box[4], box[5]]);
  }

  normalizeSize() {
    var scale = Utils.SCALE / (2.0 * this.computeLocalRadius());
    mat4.scale(this._transformData._matrix, this._transformData._matrix, [scale, scale, scale]);
  }

  computeWorldBound() {
    var worldb = this._meshData._worldBound;
    var localb = this.getLocalBound();
    var mat = this.getMatrix();

    // trans
    worldb[0] = worldb[3] = mat[12];
    worldb[1] = worldb[4] = mat[13];
    worldb[2] = worldb[5] = mat[14];

    // rotate per component
    for (var i = 0; i < 3; ++i) {
      var i4 = i * 4;
      var mini = localb[i];
      var maxi = localb[i + 3];
      for (var j = 0; j < 3; ++j) {
        var cm = mat[i4 + j];
        var a = cm * maxi;
        var b = cm * mini;
        if (a < b) {
          worldb[j] += a;
          worldb[j + 3] += b;
        } else {
          worldb[j] += b;
          worldb[j + 3] += a;
        }
      }
    }

    return worldb;
  }

  /////////
  // OCTREE
  /////////

  /** Return faces intersected by a ray */
  intersectRay(vNear, eyeDir, collectLeaves) {
    var nbFaces = this.getNbFaces();
    var collectFaces = new Uint32Array(Utils.getMemory(nbFaces * 4), 0, nbFaces);
    return this._meshData._octree.collectIntersectRay(vNear, eyeDir, collectFaces, collectLeaves ? this._meshData._leavesToUpdate : undefined);
  }

  /** Return faces inside a sphere */
  intersectSphere(vert, radiusSquared, collectLeaves) {
    var nbFaces = this.getNbFaces();
    var collectFaces = new Uint32Array(Utils.getMemory(nbFaces * 4), 0, nbFaces);
    return this._meshData._octree.collectIntersectSphere(vert, radiusSquared, collectFaces, collectLeaves ? this._meshData._leavesToUpdate : undefined);
  }

  /**
   * Update Octree
   * For each faces we check if its position inside the octree has changed
   * if so... we mark this face and we remove it from its former cells
   * We push back the marked faces into the octree
   */
  updateOctree(iFaces) {
    if (iFaces)
      this.updateOctreeAdd(this.updateOctreeRemove(iFaces));
    else
      this.computeOctree();
  }

  computeAabb() {
    var nbVertices = this.getNbVertices();
    var vAr = this.getVertices();
    var xmin = Infinity;
    var ymin = Infinity;
    var zmin = Infinity;
    var xmax = -Infinity;
    var ymax = -Infinity;
    var zmax = -Infinity;
    for (var i = 0; i < nbVertices; ++i) {
      var j = i * 3;
      var vx = vAr[j];
      var vy = vAr[j + 1];
      var vz = vAr[j + 2];
      if (vx < xmin) xmin = vx;
      if (vx > xmax) xmax = vx;
      if (vy < ymin) ymin = vy;
      if (vy > ymax) ymax = vy;
      if (vz < zmin) zmin = vz;
      if (vz > zmax) zmax = vz;
    }
    return [xmin, ymin, zmin, xmax, ymax, zmax];
  }

  /** Compute the octree */
  computeOctree() {
    var abRoot = this.computeAabb();
    var xmin = abRoot[0];
    var ymin = abRoot[1];
    var zmin = abRoot[2];
    var xmax = abRoot[3];
    var ymax = abRoot[4];
    var zmax = abRoot[5];
    var dx = xmax - xmin;
    var dy = ymax - ymin;
    var dz = zmax - zmin;

    // add minimal thickness
    var offset = Math.sqrt(dx * dx + dy * dy + dz * dz) * 0.2;
    var eps = 1e-16;
    if (xmax - xmin < eps) {
      xmin -= offset;
      xmax += offset;
    }
    if (ymax - ymin < eps) {
      ymin -= offset;
      ymax += offset;
    }
    if (zmax - zmin < eps) {
      zmin -= offset;
      zmax += offset;
    }

    // root octree bigger than minimum aabb... (to avoid too many octree rebuild)
    var dfx = dx * 0.3;
    var dfy = dy * 0.3;
    var dfz = dz * 0.3;
    var xmin2 = xmin - dfx;
    var xmax2 = xmax + dfx;
    var ymin2 = ymin - dfy;
    var ymax2 = ymax + dfy;
    var zmin2 = zmin - dfz;
    var zmax2 = zmax + dfz;

    // octree construction
    var octree = this._meshData._octree = new OctreeCell();
    octree.resetNbFaces(this.getNbFaces());
    octree.setAabbLoose(xmin, ymin, zmin, xmax, ymax, zmax);
    octree.setAabbSplit(xmin2, ymin2, zmin2, xmax2, ymax2, zmax2);
    octree.build(this);
  }

  updateOctreeRemove(iFaces) {
    var faceCenters = this.getFaceCenters();
    var fboxes = this.getFaceBoxes();
    var facePosInLeaf = this._meshData._facePosInLeaf;
    var faceLeaf = this._meshData._faceLeaf;
    var nbFaces = iFaces.length;
    var acc = 0;
    var facesToMove = new Uint32Array(Utils.getMemory(4 * nbFaces), 0, nbFaces);
    // recompute position inside the octree
    for (var i = 0; i < nbFaces; ++i) {
      var idFace = iFaces[i];
      var idb = idFace * 6;
      var idCen = idFace * 3;
      var leaf = faceLeaf[idFace];
      var ab = leaf._aabbSplit;

      var vx = faceCenters[idCen];
      var vy = faceCenters[idCen + 1];
      var vz = faceCenters[idCen + 2];

      if (vx <= ab[0] || vy <= ab[1] || vz <= ab[2] || vx > ab[3] || vy > ab[4] || vz > ab[5]) {
        // a face center has moved from its cell
        facesToMove[acc++] = iFaces[i];
        var facesInLeaf = leaf._iFaces;
        if (facesInLeaf.length > 0) { // remove faces from octree cell
          var iFaceLast = facesInLeaf[facesInLeaf.length - 1];
          var iPos = facePosInLeaf[idFace];
          facesInLeaf[iPos] = iFaceLast;
          facePosInLeaf[iFaceLast] = iPos;
          facesInLeaf.pop();
        }
      } else { // expands cell aabb loose if necessary
        leaf.expandsAabbLoose(fboxes[idb], fboxes[idb + 1], fboxes[idb + 2], fboxes[idb + 3], fboxes[idb + 4], fboxes[idb + 5]);
      }
    }
    return new Uint32Array(facesToMove.subarray(0, acc));
  }

  updateOctreeAdd(facesToMove) {
    var fc = this.getFaceCenters();
    var fb = this.getFaceBoxes();
    var facePosInLeaf = this._meshData._facePosInLeaf;
    var faceLeaf = this._meshData._faceLeaf;
    var nbFacesToMove = facesToMove.length;

    var root = this._meshData._octree;
    var rootSplit = root._aabbSplit;
    var xmin = rootSplit[0];
    var ymin = rootSplit[1];
    var zmin = rootSplit[2];
    var xmax = rootSplit[3];
    var ymax = rootSplit[4];
    var zmax = rootSplit[5];

    for (var i = 0; i < nbFacesToMove; ++i) { // add face to the octree
      var idFace = facesToMove[i];
      var idb = idFace * 6;
      var ibux = fb[idb];
      var ibuy = fb[idb + 1];
      var ibuz = fb[idb + 2];
      var iblx = fb[idb + 3];
      var ibly = fb[idb + 4];
      var iblz = fb[idb + 5];

      if (ibux > xmax || iblx < xmin || ibuy > ymax || ibly < ymin || ibuz > zmax || iblz < zmin) {
        // a face is outside the root node, we reconstruct the whole octree, slow... but rare
        this.computeOctree();
        this._meshData._leavesToUpdate.length = 0;
        break;
      }

      var idc = idFace * 3;
      var newleaf = root.addFace(idFace, ibux, ibuy, ibuz, iblx, ibly, iblz, fc[idc], fc[idc + 1], fc[idc + 2]);
      if (newleaf) {
        facePosInLeaf[idFace] = newleaf._iFaces.length - 1;
        faceLeaf[idFace] = newleaf;
      } else { // failed to insert face in octree, re-insert it back
        var facesInLeaf = faceLeaf[idFace]._iFaces;
        facePosInLeaf[idFace] = facesInLeaf.length;
        facesInLeaf.push(facesToMove[i]);
      }
    }
  }

  /** balance octree (cut empty leaves or go deeper if needed) */
  balanceOctree() {
    ++OctreeCell.FLAG;
    var leavesToUpdate = this._meshData._leavesToUpdate;
    var nbLeaves = leavesToUpdate.length;

    for (var i = 0; i < nbLeaves; ++i) {
      var leaf = leavesToUpdate[i];
      if (leaf._flag === OctreeCell.FLAG)
        continue;

      if (leaf._iFaces.length === 0)
        leaf.pruneIfPossible();
      else if (leaf._iFaces.length > OctreeCell.MAX_FACES && leaf._depth < OctreeCell.MAX_DEPTH)
        leaf.build(this);

      leaf._flag = OctreeCell.FLAG;
    }

    leavesToUpdate.length = 0;
  }

  //////////////
  // RENDER DATA
  //////////////

  getVertexBuffer() {
    return this._renderData._vertexBuffer;
  }

  getNormalBuffer() {
    return this._renderData._normalBuffer;
  }

  getMaskingBuffer() {
    return this._renderData._maskingBuffer;
  }

  getIndexBuffer() {
    return this._renderData._indexBuffer;
  }

  setVertexBuffer(buffer) {
    this._renderData._vertexBuffer = buffer;
  }

  setNormalBuffer(buffer) {
    this._renderData._normalBuffer = buffer;
  }

  setMaskingBuffer(buffer) {
    this._renderData._maskingBuffer = buffer;
  }

  setIndexBuffer(buffer) {
    this._renderData._indexBuffer = buffer;
  }

  /////////////////
  // UPDATE BUFFERS
  /////////////////
  getRenderNbVertices() {
    return this.getNbVertices();
  }

  updateVertexBuffer() {
    var vertices = this.getVertices();
    this.setVertexBuffer(vertices.subarray(0, this.getRenderNbVertices() * 3));
  }

  updateNormalBuffer() {
    var normals = this.getNormals();
    this.setNormalBuffer(normals.subarray(0, this.getRenderNbVertices() * 3));
  }

  updateMaskingBuffer() {
    var maskings = this.getMaskings();
    this.setMaskingBuffer(maskings.subarray(0, this.getRenderNbVertices()));
  }

  updateIndexBuffer() {
    var triangles = this.getFaces();
    this.setIndexBuffer(triangles.subarray(0, this.getNbFaces() * 3));
  }

  updateGeometryBuffers() {
    this.updateVertexBuffer();
    this.updateNormalBuffer();
  }

  updateBuffers() {
    this.updateGeometryBuffers();
    this.updateMaskingBuffer();
    this.updateIndexBuffer();
  }

  copyTransformData(mesh) {
    vec3.copy(this.getCenter(), mesh.getCenter());
    mat4.copy(this.getMatrix(), mesh.getMatrix());
    vec3.copy(this.getSymmetryNormal(), mesh.getSymmetryNormal());
  }

  copyData(mesh) {
    this.setVertices(mesh.getVertices().slice());
    this.setFaces(mesh.getFaces().slice());

    this.setMaskings(mesh.getMaskings().slice());
    this.init();

    this.copyTransformData(mesh);
  }

  optimize() {
    if (this.getNbFaces() === 0 || !Mesh.OPTIMIZE)
      return;

    // lower is better :
    // ACTVR : ~1 is optimal (soup or not)
    // ACMR : ~0.5 optimal ratio, 3 for triangle soup
    // var data = this.computeCacheScore();

    this.optimizePostTransform();
    this.optimizePreTransform();
    this.initFaceRings();

    // console.log('\nbefore ACMR ' + data.ACMR);
    // console.log('before ACTVR ' + data.ACTVR);
    // data = this.computeCacheScore();
    // console.log('after ACMR ' + data.ACMR);
    // console.log('after ACTVR ' + data.ACTVR);
  }

  computeCacheScore() {
    var fAr = this.getFaces();
    var nbFaces = this.getNbFaces();

    var sizeCache = 32;
    var cache = [];
    cache.length = sizeCache;

    var cacheMiss = 0;
    var k = 0;
    for (var i = 0; i < nbFaces; ++i) {
      var id = i * 3;
      var nbPoly = 3;
      // check in cache
      for (var j = 0; j < nbPoly; ++j) {

        var idFace = fAr[id + j];
        // check in cache
        for (k = 0; k < sizeCache; ++k) {
          if (cache[k] === undefined) {
            cache[k] = idFace;
            cacheMiss++;
            break;
          } else if (cache[k] === idFace) {
            break;
          }
        }

        if (k === sizeCache) {
          cacheMiss++;
          cache.shift();
          cache.push(idFace);
        }
      }
    }

    return {
      ACMR: cacheMiss / nbFaces,
      ACTVR: cacheMiss / this.getNbVertices()
    };
  }

  optimizePostTransform() {
    // post transform optimization (index buffer re-index), it implements tipsy
    // http://gfx.cs.princeton.edu/pubs/Sander_2007_%3ETR/tipsy.pdf

    var i = 0;
    var cacheSize = 32;
    var fAr = this.getFaces();
    var fArUV = fAr;

    var nbFaces = this.getNbFaces();
    var nbUniqueVertices = this.getNbVertices();
    var nbVertices = nbUniqueVertices;

    var mapToUnique = new Uint32Array(nbVertices - nbUniqueVertices);

    var fringsStartCount = this.getVerticesRingFaceStartCount();
    var frings = this.getVerticesRingFace();

    var livesTriangles = new Int32Array(nbVertices);
    for (i = 0; i < nbUniqueVertices; ++i) {
      livesTriangles[i] = fringsStartCount[i * 2 + 1];
    }

    for (i = nbUniqueVertices; i < nbVertices; ++i) {
      livesTriangles[i] = fringsStartCount[mapToUnique[i - nbUniqueVertices] * 2 + 1];
    }

    var vTimeStamp = new Uint32Array(nbVertices);
    var timeStamp = cacheSize + 1;
    var cursor = 0;

    var deadEndStack = new Uint32Array(Utils.getMemory(4 * nbFaces * 3), 0, nbFaces * 3);
    var deadEndCount = 0;
    var emittedFaces = new Uint8Array(nbFaces);

    var fArUVNew = new Uint32Array(nbFaces * 3);
    var fcount = 0;

    var fanningVertex = 0;
    while (fanningVertex >= 0) {

      var ringCandidates = [];

      var idRing = fanningVertex >= nbUniqueVertices ? mapToUnique[fanningVertex - nbUniqueVertices] : fanningVertex;
      var start = fringsStartCount[idRing * 2];
      var end = start + fringsStartCount[idRing * 2 + 1];

      for (i = start; i < end; ++i) {
        var idFace = frings[i];
        if (emittedFaces[idFace]) continue;
        emittedFaces[idFace] = 1;

        var idf = idFace * 3;

        var idv1 = deadEndStack[deadEndCount++] = fArUVNew[fcount++] = fArUV[idf];
        var idv2 = deadEndStack[deadEndCount++] = fArUVNew[fcount++] = fArUV[idf + 1];
        var idv3 = deadEndStack[deadEndCount++] = fArUVNew[fcount++] = fArUV[idf + 2];

        ringCandidates.push(idv1, idv2, idv3);

        --livesTriangles[idv1];
        --livesTriangles[idv2];
        --livesTriangles[idv3];

        if (timeStamp - vTimeStamp[idv1] > cacheSize) vTimeStamp[idv1] = timeStamp++;
        if (timeStamp - vTimeStamp[idv2] > cacheSize) vTimeStamp[idv2] = timeStamp++;
        if (timeStamp - vTimeStamp[idv3] > cacheSize) vTimeStamp[idv3] = timeStamp++;
      }

      // get emitted next vertex
      fanningVertex = -1;
      var bestPriority = -1;
      var nbCandidates = ringCandidates.length;
      for (i = 0; i < nbCandidates; ++i) {
        var idc = ringCandidates[i];
        var liveCount = livesTriangles[idc];
        if (liveCount <= 0) continue;

        var priority = 0;
        var posCache = timeStamp - vTimeStamp[idc];
        if (posCache + 2 * liveCount <= cacheSize) {
          priority = posCache;
        }

        if (priority > bestPriority) {
          bestPriority = priority;
          fanningVertex = idc;
        }
      }

      if (fanningVertex !== -1) continue;

      while (deadEndCount > 0) {
        var vEnd = deadEndStack[--deadEndCount];
        if (livesTriangles[vEnd] > 0) {
          fanningVertex = vEnd;
          break;
        }
      }

      if (fanningVertex !== -1) continue;

      while (cursor < nbVertices) {
        if (livesTriangles[cursor++] > 0) {
          fanningVertex = cursor - 1;
          break;
        }
      }

    }

    fArUV.set(fArUVNew.subarray(0, nbFaces * 3));

  }

  optimizePreTransform() {
    // pre transform optimization (not as important as post transform though)
    // it also removes unused vertices

    var vArOld = this.getVertices();
    var nbVertices = this.getNbVertices();
    var mArOld = this.getMaskings();

    var fAr = this.getFaces();
    var fArCount = this.getNbFaces() * 3;

    var vArNew = new Float32Array(nbVertices * 3);
    var mArNew = new Float32Array(nbVertices);

    var idvPos = new Uint32Array(nbVertices);
    var acc = 0;
    var i = 0;
    for (i = 0; i < fArCount; ++i) {
      var iv = fAr[i];
      if (iv === Utils.TRI_INDEX) continue;

      var tag = idvPos[iv] - 1;
      if (tag === -1) {
        var idNew = acc * 3;
        var idOld = iv * 3;
        vArNew[idNew] = vArOld[idOld];
        vArNew[idNew + 1] = vArOld[idOld + 1];
        vArNew[idNew + 2] = vArOld[idOld + 2];

        mArNew[acc] = mArOld[iv];

        tag = acc++;
        idvPos[iv] = tag + 1;
      }

      fAr[i] = tag;
    }

    var nbUnusedVertex = nbVertices - acc;
    if (nbUnusedVertex > 0)
      this.setNbVertices(acc);

    vArOld.set(vArNew);
    mArOld.set(mArNew);
  }
}

Mesh.OPTIMIZE = true;
Mesh.ID = 0;

export default Mesh;
