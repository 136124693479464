import {NormalizedStringIndexedObjects} from "../types";
import {InitStoreAction} from "../init";
import {ILightPrototype} from "../../../peregrine/processor";

export const ADD_LIGHTS = 'ADD_LIGHTS';
export const REMOVE_LIGHT = 'REMOVE_LIGHT';
export const REPLACE_LIGHTS = 'REPLACE_LIGHTS';

export interface IEntityLightsState extends NormalizedStringIndexedObjects<ILightPrototype> {
}

interface AddLightsAction {
  type: typeof ADD_LIGHTS
  payload: {
    lights: ILightPrototype[]
  }
}

interface ReplaceLightsAction {
  type: typeof REPLACE_LIGHTS,
  payload: {
    lights: ILightPrototype[]
  }
}

interface RemoveLightAction {
  type: typeof REMOVE_LIGHT
  payload: {
    id: string
  }
}

export type LightsActionTypes =
  AddLightsAction |
  RemoveLightAction |
  ReplaceLightsAction |
  InitStoreAction