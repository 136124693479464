import lod from "lodash";
import {decodeWompObjectRef, encodeWompObjectRef, getUniqueDesc, WompObjectRef} from "../../WompObject";
import {decodeModelProperty, encodeModelProperty, ICalcConfigDiff, IModelProperty, IParameterProperty} from "../types";
import {ObjectTypes, XObject} from "../xobject";
import {Relation} from "../relation";
import {getObjectType, Parameter, ParamTypes} from "./parameter";
import {Param_Compound} from "./param-compound";
import {_s} from "../../t";
import {peregrineId} from "../../id";
import {Param_Brep} from "./param-brep";

export class Param_Mesh extends Parameter {
  protected _objectType = ParamTypes.Mesh;
  protected _defaultValue: undefined;

  protected _stateValid = true;
  // Runtime Data
  protected _values: WompObjectRef[] = [];

  get values() {
    return this._values;
  }

  set values(values: any[]) {
    if (!lod.isEqual(this._values, values)) {
      this._values = values;
      this._dataValid = false;
    }
  }

  protected _properties: IModelProperty[] = [];

  get properties() {
    return this._properties;
  }

  set properties(properties: IModelProperty[]) {
    if (!lod.isEqual(this._properties, properties)) {
      this._properties = properties;
      this._dataValid = false;
    }
  }

  get length() {
    return Math.min(this._values.length, this._properties.length);
  }

  static create(calc: XObject, title: string, isInput: boolean, isOperable: boolean, render: boolean) {
    let param = new Param_Mesh();
    param._id = peregrineId();
    param._calc = calc;
    param._title = title;
    param._isInput = isInput;
    param._isOperable = isOperable;
    param._render = render;

    param.setupInitialData();
    return param;
  }

  generateProperties(): IParameterProperty[] {
    let properties: IParameterProperty[] = [];

    for (let i = 0; i < this._values.length; ++i) {
      let property = this._properties.length > i ? this._properties[i] : undefined;

      if (property) {
        properties.push({
          hash: property.hash,
          voided: property.voided,
          material: property.material
        });
      } else {
        properties.push({
          hash: ""
        });
      }
    }

    return properties;
  }

  protected validateGeneratedData() {
    this.dataValues = this._values.map(encodeWompObjectRef);
    this.dataProperties = this._properties.map(encodeModelProperty);

    this._dataValid = true;
  }

  overwrite(jData: any, replaceData: boolean) {
    if (!this.dataValid)
      this.validateGeneratedData();

    super.overwrite(jData, replaceData);

    if (this._data[_s('values')] !== jData[_s('values')] && jData[_s('values')] !== undefined) {
      this.values = jData[_s('values')].map(decodeWompObjectRef);
    }

    if (this._data[_s('properties')] !== jData[_s('properties')] && jData[_s('properties')] !== undefined) {
      this.properties = jData[_s('properties')].map(decodeModelProperty);
    }

    if (replaceData) {
      this._data = jData;
      this._dataValid = true;
    }
  }

  solve(diff?: ICalcConfigDiff) {
    if (diff && diff.obj) {
      this._values = [];

      for (let relation of this._prevRelations) {
        let prev = (relation as unknown as Relation).from;
        if (prev.objectType === ParamTypes.Compound) {
          let prevComp = prev as Param_Compound;
          this._values = prevComp.filterValues(getObjectType(this._objectType));
        } else {
          if (prev.objectType === this._objectType) {
            let prevSame = prev as Param_Mesh;
            this._values = [...this._values, ...prevSame._values];
          } else if (prev.objectType === ParamTypes.Brep) {
            let prevSame = prev as Param_Brep;
            this._values = [...this._values, ...prevSame.caches.map(c => c[0])];
          } else {
            console.warn('Type mismatch. Please implement type conversion');
          }
        }
      }
      this._dataValid = false;
    }

    if (diff && (diff.obj || diff.prevVoided || diff.prevMaterial)) {
      this._properties = [];

      for (let relation of this._prevRelations) {
        let prev = (relation as unknown as Relation).from;
        if (prev.objectType === ParamTypes.Compound) {
          let prevComp = prev as Param_Compound;
          this._properties = prevComp.filterProperties(getObjectType(this._objectType));
        } else {
          if (prev.objectType === this._objectType) {
            let prevSame = prev as Param_Mesh;
            this._properties = [...this._properties, ...prevSame._properties];
          } else if (prev.objectType === ParamTypes.Brep) {
            let prevSame = prev as Param_Brep;
            this._properties = [...this._properties, ...prevSame.properties.map((p, i) => ({
              ...p,
              modelType: ObjectTypes.Mesh,
              hash: getUniqueDesc(prevSame.values[i])
            }))];
          } else {
            console.warn('Type mismatch. Please implement type conversion');
          }
        }
      }
    }
    this._dataValid = false;
  }
}
