import Utils from '../misc/Utils';

class StateColorAndMasking {

  constructor(main, mesh) {
    this._main = main; // main application
    this._mesh = mesh; // the mesh
    this._idVertState = []; // ids of vertices
    this._mArState = []; // copies of masking vertices
  }

  isNoop() {
    return this._idVertState.length === 0;
  }

  undo() {
    this.pullVertices();
    var mesh = this._mesh;
    this._main.setMesh(mesh);
  }

  redo() {
    this.undo();
  }

  createRedo() {
    var redo = new StateColorAndMasking(this._main, this._mesh);
    this.pushRedoVertices(redo);
    return redo;
  }

  pushVertices(iVerts) {
    var idVertState = this._idVertState;
    var mArState = this._mArState;

    var mesh = this._mesh;
    var mAr = mesh.getMaskings();
    var vertStateFlags = mesh.getVerticesStateFlags();

    var stateFlag = Utils.STATE_FLAG;
    var nbVerts = iVerts.length;
    for (var i = 0; i < nbVerts; ++i) {
      var id = iVerts[i];
      if (vertStateFlags[id] === stateFlag)
        continue;
      vertStateFlags[id] = stateFlag;
      idVertState.push(id);
      mArState.push(mAr[id]);
    }
  }

  pushRedoVertices(redoState) {
    var mesh = redoState._mesh;
    var mAr = mesh.getMaskings();

    var idVertUndoState = this._idVertState;
    var nbVerts = idVertUndoState.length;

    var mArRedoState = redoState._mArState = new Float32Array(nbVerts);
    var idVertRedoState = redoState._idVertState = new Uint32Array(nbVerts);
    for (var i = 0; i < nbVerts; ++i) {
      var id = idVertRedoState[i] = idVertUndoState[i];
      mArRedoState[i] = mAr[id];
    }
  }

  pullVertices() {
    var mArState = this._mArState;
    var idVertState = this._idVertState;
    var nbVerts = idVertState.length;

    var mesh = this._mesh;
    var mAr = mesh.getMaskings();
    for (var i = 0; i < nbVerts; ++i) {
      var id = idVertState[i];
      mAr[id] = mArState[i];
    }
  }
}

export default StateColorAndMasking;
