import {
  ADD_PROCESSORS,
  ProcessorsActionTypes,
  REMOVE_PROCESSORS,
  REPLACE_PROCESSORS
} from '../../types/entity/processors';
import {IProcessor} from '../../types/types';

export function replaceProcessors(processors: IProcessor[]): ProcessorsActionTypes {
  return {
    type: REPLACE_PROCESSORS,
    payload: {
      processors
    }
  };
}

export function addProcessors(processors: IProcessor[]): ProcessorsActionTypes {
  return {
    type: ADD_PROCESSORS,
    payload: {
      processors
    }
  };
}

export function removeProcessors(ids: string[]): ProcessorsActionTypes {
  return {
    type: REMOVE_PROCESSORS,
    payload: {
      ids
    }
  };
}
