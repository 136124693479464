import {WompMeshData, WompBrepData, WompBrep, WompCurve} from "../WompObject";
import {mat4, vec3} from "gl-matrix";
import {encodeCurveGeometryCodecTrans} from "../utils/curve";
import {callPigeonOnWorker} from "../processor/workers/pigen-index";

let pigeon: any = undefined;

try {
  pigeon = (window as any).Module;
} catch (e) {
}

function matToFixed(mat: mat4) {
  return mat.map((v: number) => Math.round(v * 50) / 50.0);
}

export async function box(width: number, depth: number, height: number) {
  try {
    let shape = await callPigeonOnWorker({
      func: "box",
      returnType: "typedArray",
      paramTypes: ["number", "number", "number"],
      params: [width, depth, height]
    });
    return {shape};
  } catch (e) {
    console.warn(e);
  }
  return {};
}

export async function sphere(radius: number) {
  try {
    let shape = await callPigeonOnWorker({
      func: "sphere",
      returnType: "typedArray",
      paramTypes: ["number"],
      params: [radius]
    });
    return {shape};
  } catch (e) {
    console.warn(e);
  }
  return {};
}

export async function cone(radius: number, height: number) {
  try {
    let shape = await callPigeonOnWorker({
      func: "cone",
      returnType: "typedArray",
      paramTypes: ["number", "number"],
      params: [radius, height]
    });
    return {shape};
  } catch (e) {
    console.warn(e);
  }
  return {};
}

export async function cylinder(radius: number, height: number) {
  try {
    let shape = await callPigeonOnWorker({
      func: "cylinder",
      returnType: "typedArray",
      paramTypes: ["number", "number"],
      params: [radius, height]
    });
    return {shape};
  } catch (e) {
    console.warn(e);
  }
  return {};
}

export async function donut(outerRadius: number, innerRadius: number) {
  try {
    let shape = await callPigeonOnWorker({
      func: "donut",
      returnType: "typedArray",
      paramTypes: ["number", "number"],
      params: [outerRadius, innerRadius]
    });
    return {shape};
  } catch (e) {
    console.warn(e);
  }
  return {};
}

export async function tube(outerRadius: number, innerRadius: number, height: number) {
  try {
    let shape = await callPigeonOnWorker({
      func: "tube",
      returnType: "typedArray",
      paramTypes: ["number", "number", "number"],
      params: [outerRadius, innerRadius, height]
    });
    return {shape};
  } catch (e) {
    console.warn(e);
  }
  return {};
}

export async function pyramid(width: number, depth: number, height: number) {
  try {
    let shape = await callPigeonOnWorker({
      func: "pyramid",
      returnType: "typedArray",
      paramTypes: ["number", "number", "number"],
      params: [width, depth, height]
    });
    return {shape};
  } catch (e) {
    console.warn(e);
  }
  return {};
}

export async function offset(values: WompBrep[], distance: number): Promise<WompBrepData[]> {
  try {
    let shapes = await callPigeonOnWorker({
      func: "buildOffset",
      returnType: "typedArrays",
      paramTypes: ["typedArrays", "typedArrays", "number"],
      params: [
        values.map(brep => brep.geometry.shape),
        values.map(brep => matToFixed(brep.matrix)),
        distance
      ]
    });
    return shapes.map((shape: any) => ({shape}));
  } catch (e) {
    console.warn(e);
  }
  return [];
}

export async function fillet(values: WompBrep[], distance: number): Promise<WompBrepData[]> {
  try {
    let shapes = await callPigeonOnWorker({
      func: distance > 0 ? "buildFillet" : "buildChamfer",
      returnType: "typedArrays",
      paramTypes: ["typedArrays", "typedArrays", "number"],
      params: [
        values.map(brep => brep.geometry.shape),
        values.map(brep => matToFixed(brep.matrix)),
        Math.abs(distance)
      ]
    });
    return shapes.map((shape: any) => ({shape}));
  } catch (e) {
    console.warn(e);
  }
  return [];
}

export async function hollow(values: WompBrep[], thickness: number): Promise<WompBrepData[]> {
  try {
    let shapes = await callPigeonOnWorker({
      func: "buildHollow",
      returnType: "typedArrays",
      paramTypes: ["typedArrays", "typedArrays", "number"],
      params: [
        values.map(brep => brep.geometry.shape),
        values.map(brep => matToFixed(brep.matrix)),
        thickness
      ]
    });
    return shapes.map((shape: any) => ({shape}));
  } catch (e) {
    console.warn(e);
  }
  return [];
}

export async function boolean(op: string, addValues: WompBrep[], subtractValues: WompBrep[]): Promise<WompBrepData> {
  try {
    let shape = await callPigeonOnWorker({
      func: "buildBoolean",
      returnType: "typedArray",
      paramTypes: ["string", "typedArrays", "typedArrays", "typedArrays", "typedArrays"],
      params: [
        op,
        addValues.map(brep => brep.geometry.shape),
        addValues.map(brep => matToFixed(brep.matrix)),
        subtractValues.map(brep => brep.geometry.shape),
        subtractValues.map(brep => matToFixed(brep.matrix))
      ]
    });
    return {shape};
  } catch (e) {
    console.warn(e);
  }
  return {};
}

export async function cut(values: WompBrep[], planes: Float32Array[]): Promise<WompBrepData[]> {
  try {
    let shapes = await callPigeonOnWorker({
      func: "buildCut",
      returnType: "typedArrays",
      paramTypes: ["typedArrays", "typedArrays"],
      params: [
        values.map(brep => brep.geometry.shape),
        planes
      ]
    });
    return shapes.map((shape: any) => ({shape}));
  } catch (e) {
    console.warn(e);
  }
  return [];
}

export async function sweep(curve: WompCurve): Promise<WompBrepData> {
  try {
    let shape = await callPigeonOnWorker({
      func: "buildSweep",
      returnType: "typedArray",
      paramTypes: ["typedArray", "typedArray"],
      params: [
        new Uint8Array(encodeCurveGeometryCodecTrans(curve.geometry)),
        matToFixed(curve.matrix)
      ]
    });
    return {shape};
  } catch (e) {
    console.warn(e);
  }
  return {};
}

export async function pipe(uCurve: WompCurve, vCurve: WompCurve): Promise<WompBrepData> {
  try {
    let shape = await callPigeonOnWorker({
      func: "buildPipe",
      returnType: "typedArray",
      paramTypes: ["typedArray", "typedArray", "typedArray", "typedArray"],
      params: [
        new Uint8Array(encodeCurveGeometryCodecTrans(uCurve.geometry)),
        matToFixed(uCurve.matrix),
        new Uint8Array(encodeCurveGeometryCodecTrans(vCurve.geometry)),
        matToFixed(vCurve.matrix)
      ]
    });
    return {shape};
  } catch (e) {
    console.warn(e);
  }
  return {};
}

export async function loft(curves: WompCurve[], solid: boolean, ruled: boolean): Promise<WompBrepData> {
  try {
    let shape = await callPigeonOnWorker({
      func: "buildLoft",
      returnType: "typedArray",
      paramTypes: ["typedArrays", "typedArrays", "boolean", "boolean"],
      params: [
        curves.map(curve => new Uint8Array(encodeCurveGeometryCodecTrans(curve.geometry))),
        curves.map(curve => matToFixed(curve.matrix)),
        solid,
        ruled
      ]
    });
    return {shape};
  } catch (e) {
    console.warn(e);
  }
  return {};
}

export async function revolve(curve: WompCurve, axFrom: vec3, axTo: vec3, solid: boolean, angle: number): Promise<WompBrepData> {
  try {
    let shape = await callPigeonOnWorker({
      func: "buildRevolve",
      returnType: "typedArray",
      paramTypes: ["typedArray", "typedArray", "typedArray", "boolean", "number"],
      params: [
        new Uint8Array(encodeCurveGeometryCodecTrans(curve.geometry)),
        matToFixed(curve.matrix),
        new Float32Array([...Array.from(axFrom), ...Array.from(axTo)]),
        solid,
        angle
      ]
    });
    return {shape};
  } catch (e) {
    console.warn(e);
  }
  return {};
}

export async function tessellate(geom: WompBrepData): Promise<[WompMeshData, WompMeshData]> {
  try {
    let geomData = await callPigeonOnWorker({
      func: "tessellate",
      returnType: "typedArrays",
      paramTypes: ["typedArray"],
      params: [geom.shape]
    });

    return [{
      face: geomData[0],
      position: geomData[1],
      normal: geomData[2]
    }, {
      face: geomData[3],
      position: geomData[4]
    }];
  } catch (e) {
    console.warn(e);
  }

  return [{}, {}];
}

export async function loadSTEP(data: ArrayBuffer): Promise<WompBrepData[]> {
  try {
    let shapes = await callPigeonOnWorker({
      func: "loadSTEP",
      returnType: "typedArrays",
      paramTypes: ["typedArray"],
      params: [
        new Uint8Array(data)
      ]
    });
    return shapes.map((shape: any) => ({shape}));
  } catch (e) {
    console.warn(e);
  }
  return [];
}

export async function loadBREP(data: ArrayBuffer): Promise<WompBrepData[]> {
  try {
    let shapes = await callPigeonOnWorker({
      func: "loadBREP",
      returnType: "typedArrays",
      paramTypes: ["typedArray"],
      params: [
        new Uint8Array(data)
      ]
    });
    return shapes.map((shape: any) => ({shape}));
  } catch (e) {
    console.warn(e);
  }
  return [];
}

export async function saveBREP(brep: WompBrep): Promise<ArrayBuffer> {
  try {
    let buffer = await callPigeonOnWorker({
      func: "saveBREP",
      returnType: "typedArray",
      paramTypes: ["typedArray", "typedArray"],
      params: [
        brep.geometry.shape,
        matToFixed(brep.matrix)
      ]
    });
    return buffer.buffer;
  } catch (e) {
    console.warn(e);
  }
  return new ArrayBuffer(0);
}
