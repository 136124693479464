import {ComponentTypes} from "../types";
import {createGumball, IRenderedObjectInternal} from "../gumball";
import {Param_Compound, Param_Vertex, ParamTitles} from "../../parameter";
import {Calc} from "../../calc";
import {ObjectTypes} from "../../xobject";
import {BasicObjectMapper, PredictedResult} from "../base";

export class VertexParameter extends BasicObjectMapper {
  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.VertexParameter;
    calc.title = 'vertex';

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let vertexParam = Param_Vertex.create(calc, ParamTitles.Vertex, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(vertexParam);
    return calc;
  }

  static predictResult(calc: Calc, obj: IRenderedObjectInternal) {
    if (obj.valueType === ObjectTypes.Vertex)
      return PredictedResult.CopyFirst;

    return PredictedResult.Ignore;
  }
}