import {ComponentTypes} from "../types";
import {applyObjectTransform, createSimpleGumball, Gumball} from "../gumball";
import lod from "lodash";
import {getMeshFromRef, Param_Compound, Param_Point, ParamTitles, ParamValueTypes} from "../../parameter";
import {Calc} from "../../calc";
import {CachedGeometryObjectTypes, GeometryObjectTypes, ObjectTypes} from "../../xobject";
import {ICalcConfigDiff} from "../../types";
import {mat4, vec3} from "gl-matrix";
import {getBoundingBoxFromMeshes, getBoundingBoxSize, WompObjectRef} from "../../../WompObject";
import {duplicateObject} from "../index";

export class ArrayModifier {
  static create() {
    let calc = createSimpleGumball();
    calc.component = ComponentTypes.ArrayModifier;
    calc.title = 'array';

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let countParam = Param_Point.create(calc, ParamTitles.Count, true, false, {type: ParamValueTypes.UnsignedInt});
    let spacingParam = Param_Point.create(calc, ParamTitles.Spacing, true, false, {type: ParamValueTypes.Offset});
    let offsetParam = Param_Point.create(calc, ParamTitles.Offset, true, false, {
      type: ParamValueTypes.UnsignedInt,
      hidden: true
    });
    let arrayParam = Param_Compound.create(calc, ParamTitles.Array, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(countParam);
    calc.addParameter(spacingParam);
    calc.addParameter(offsetParam);
    calc.addParameter(arrayParam);
    return calc;
  }

  static async solve(calc: Calc, diff: ICalcConfigDiff) {
    let objectParam = calc.inputByTitle(ParamTitles.Object) as Param_Compound;
    let countParam = calc.inputByTitle(ParamTitles.Count) as Param_Point;
    let spacingParam = calc.inputByTitle(ParamTitles.Spacing) as Param_Point;
    let offsetParam = calc.inputByTitle(ParamTitles.Offset) as Param_Point;

    let count = countParam.values[0];

    let offset = vec3.clone(offsetParam.values[0]);
    vec3.set(offset, Math.min(offset[0], count[0] - 1), Math.min(offset[1], count[1] - 1), Math.min(offset[2], count[2] - 1));
    let spacing = spacingParam.values[0];

    let meshes = [];
    for (let i = 0; i < objectParam.length; ++i) {
      let value = objectParam.values[i];
      let valueType = objectParam.valueTypes[i];
      let cache = objectParam.caches[i];

      if (GeometryObjectTypes.includes(valueType)) {
        if (CachedGeometryObjectTypes.includes(valueType) && cache) {
          meshes.push(getMeshFromRef(calc, cache[0]));
        } else if (valueType === ObjectTypes.Vertex) {

        } else {
          meshes.push(getMeshFromRef(calc, value));
        }
      }
    }

    if (meshes.length === 0)
      return false;

    let boundingBox = getBoundingBoxFromMeshes(meshes);
    let itemSize = getBoundingBoxSize(boundingBox);

    let objects = [];
    for (let i = 0; i < count[0]; ++i) {
      for (let j = 0; j < count[1]; ++j) {
        for (let k = 0; k < count[2]; ++k) {
          for (let l = 0; l < objectParam.length; ++l) {
            let value = objectParam.values[l] as WompObjectRef;
            let valueType = objectParam.valueTypes[l];
            let cache = objectParam.caches[l];
            let property = objectParam.properties[l];

            if (GeometryObjectTypes.includes(valueType)) {
              let translation = mat4.fromTranslation(mat4.create(), vec3.fromValues(
                (i - offset[0]) * (spacing[0] + itemSize[0]),
                (j - offset[1]) * (spacing[1] + itemSize[1]),
                (k - offset[2]) * (spacing[2] + itemSize[2])
              ));

              let object = {value: duplicateObject(value), valueType, cache: duplicateObject(cache), property};
              applyObjectTransform(object, translation);

              objects.push(object);
            }
          }
        }
      }
    }

    return Gumball.solveGumball(calc, objects);
  }

  static solveProperty(calc: Calc, diff: ICalcConfigDiff) {
    let objectParam = calc.inputByTitle(ParamTitles.Object) as Param_Compound;
    let countParam = calc.inputByTitle(ParamTitles.Count) as Param_Point;
    let arrayParam = calc.outputByTitle(ParamTitles.Array) as Param_Compound;
    let count = countParam.values[0];

    if (diff.calcVoided || diff.prevMaterial) {
      let properties = lod.cloneDeep(arrayParam.properties);

      if (diff.calcVoided) {
        for (let i = 0; i < properties.length; ++i) {
          properties[i].voided = calc.voided;
        }
      }

      if (diff.prevMaterial) {
        let j = 0;
        for (let i = 0; i < count[0] * count[1] * count[2]; ++i) {
          for (let l = 0; l < objectParam.length; ++l) {
            let valueType = objectParam.valueTypes[l];
            if (GeometryObjectTypes.includes(valueType)) {
              if (properties[j])
                properties[j].material = objectParam.properties[l].material;
              ++j;
            }
          }
        }
      }

      arrayParam.properties = properties;
    }
  }
}