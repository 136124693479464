import Utils from '../misc/Utils';

var Subdivision = {};
Subdivision.LINEAR = false;

//       v3
//       /\
//      /3T\ 
//   m3/____\m2
//    /\ 0T /\
//   /1T\  /2T\
//  /____\/____\ 
// v1    m1    v2

// v4____m3____v3
// |     |     |
// |     |     |
// |m4___|c____|m2
// |     |     |
// |     |     |
// |_____|_____|
// v1   m1     v2

// Helper class
class OddVertexComputer {

  constructor(mesh, vArOut, mArOut) {
    this._pArOut = vArOut;
    this._mArOut = mArOut;
    this._pAr = mesh.getVertices();
    this._mAr = mesh.getMaskings();
    this._eAr = mesh.getEdges();
    this._nbVertices = mesh.getNbVertices();
    this._tagEdges = new Int32Array(mesh.getNbEdges());
  }

  computeTriangleEdgeVertex(iv1, iv2, iv3, ide) {
    var vAr = this._pAr;
    var mAr = this._mAr;
    var eAr = this._eAr;
    var vArOut = this._pArOut;
    var mArOut = this._mArOut;
    var tagEdges = this._tagEdges;
    var id1 = iv1 * 3;
    var id2 = iv2 * 3;
    var idOpp = iv3 * 3;
    var testEdge = tagEdges[ide] - 1;
    var ivMid = testEdge === -1 ? this._nbVertices++ : testEdge;
    var idMid = ivMid * 3;
    var edgeValue = eAr[ide];
    if (edgeValue === 1 || edgeValue >= 3 || Subdivision.LINEAR) { // mid edge vertex or non manifold shit
      if (testEdge !== -1) // no need to recompute weird non manifold stuffs
        return ivMid;
      tagEdges[ide] = ivMid + 1;
      vArOut[idMid] = 0.5 * (vAr[id1] + vAr[id2]);
      vArOut[idMid + 1] = 0.5 * (vAr[id1 + 1] + vAr[id2 + 1]);
      vArOut[idMid + 2] = 0.5 * (vAr[id1 + 2] + vAr[id2 + 2]);

      mArOut[ivMid] = 0.5 * (mAr[iv1] + mAr[iv2]);
    } else if (testEdge === -1) { // new mid vertex
      tagEdges[ide] = ivMid + 1;
      vArOut[idMid] = 0.125 * vAr[idOpp] + 0.375 * (vAr[id1] + vAr[id2]);
      vArOut[idMid + 1] = 0.125 * vAr[idOpp + 1] + 0.375 * (vAr[id1 + 1] + vAr[id2 + 1]);
      vArOut[idMid + 2] = 0.125 * vAr[idOpp + 2] + 0.375 * (vAr[id1 + 2] + vAr[id2 + 2]);

      mArOut[ivMid] = 0.125 * mAr[iv3] + 0.375 * (mAr[iv1] + mAr[iv2]);
    } else { // mid vertex already exists
      vArOut[idMid] += 0.125 * vAr[idOpp];
      vArOut[idMid + 1] += 0.125 * vAr[idOpp + 1];
      vArOut[idMid + 2] += 0.125 * vAr[idOpp + 2];

      mArOut[ivMid] += 0.125 * mAr[iv3];
    }
    return ivMid;
  }
}

/** Even vertices smoothing */
var applyEvenSmooth = function (baseMesh, even, maskingOut) {
  var nbVerts = baseMesh.getNbVertices();

  maskingOut.set(baseMesh.getMaskings().subarray(0, nbVerts));

  var vArOld = baseMesh.getVertices();
  var fArOld = baseMesh.getFaces();
  var eArOld = baseMesh.getEdges();
  var feArOld = baseMesh.getFaceEdges();
  var vertOnEdgeOld = baseMesh.getVerticesOnEdge();
  var vrvStartCount = baseMesh.getVerticesRingVertStartCount();
  var vertRingVert = baseMesh.getVerticesRingVert();
  var vrfStartCount = baseMesh.getVerticesRingFaceStartCount();
  var vertRingFace = baseMesh.getVerticesRingFace();

  for (var i = 0; i < nbVerts; ++i) {
    var j = i * 3;
    var avx = 0.0;
    var avy = 0.0;
    var avz = 0.0;
    var beta = 0.0;
    var alpha = 0.0;
    var k = 0;
    var id = 0;

    // edge vertex
    if (vertOnEdgeOld[i] || Subdivision.LINEAR) {
      var startF = vrfStartCount[i * 2];
      var endF = startF + vrfStartCount[i * 2 + 1];
      for (k = startF; k < endF; ++k) {
        var idFace = vertRingFace[k] * 3;
        var i1 = fArOld[idFace];
        var i2 = fArOld[idFace + 1];
        var i3 = fArOld[idFace + 2];

        if (i1 === i) {
          if (eArOld[feArOld[idFace]] === 1) id = i2;
          else if (eArOld[feArOld[idFace + 2]] === 1) id = i3;
        } else if (i2 === i) {
          if (eArOld[feArOld[idFace]] === 1) id = i1;
          else if (eArOld[feArOld[idFace + 1]] === 1) id = i3;
        } else if (i3 === i) {
          if (eArOld[feArOld[idFace + 1]] === 1) id = i2;
          else if (eArOld[feArOld[idFace + 2]] === 1) id = i1;
        }
      }
      if (beta < 2) { // non manifold boring stuffs
        even[j] = vArOld[j];
        even[j + 1] = vArOld[j + 1];
        even[j + 2] = vArOld[j + 2];
      } else {
        beta = 0.25 / beta;
        alpha = 0.75;
        even[j] = vArOld[j] * alpha + avx * beta;
        even[j + 1] = vArOld[j + 1] * alpha + avy * beta;
        even[j + 2] = vArOld[j + 2] * alpha + avz * beta;
      }
      continue;
    }
    var start = vrvStartCount[i * 2];
    var count = vrvStartCount[i * 2 + 1];
    var end = start + count;
    // interior vertex
    for (k = start; k < end; ++k) {
      id = vertRingVert[k] * 3;
      avx += vArOld[id];
      avy += vArOld[id + 1];
      avz += vArOld[id + 2];
    }
    // only vertex tri
    if (count === 6) {
      beta = 0.0625;
      alpha = 0.625;
    } else if (count === 3) { // warren weights
      beta = 0.1875;
      alpha = 0.4375;
    } else {
      beta = 0.375 / count;
      alpha = 0.625;
    }
    even[j] = vArOld[j] * alpha + avx * beta;
    even[j + 1] = vArOld[j + 1] * alpha + avy * beta;
    even[j + 2] = vArOld[j + 2] * alpha + avz * beta;
  }
};

/** Odd vertices smoothing */
var applyOddSmooth = function (mesh, odds, maskingOut, fArOut) {
  var fAr = mesh.getFaces();
  var feAr = mesh.getFaceEdges();
  var oddComputer = new OddVertexComputer(mesh, odds, maskingOut);
  for (var i = 0, len = mesh.getNbFaces(); i < len; ++i) {
    var id = i * 3;
    var iv1 = fAr[id];
    var iv2 = fAr[id + 1];
    var iv3 = fAr[id + 2];
    var ivMid1, ivMid2, ivMid3;

    ivMid1 = oddComputer.computeTriangleEdgeVertex(iv1, iv2, iv3, feAr[id]);
    ivMid2 = oddComputer.computeTriangleEdgeVertex(iv2, iv3, iv1, feAr[id + 1]);
    ivMid3 = oddComputer.computeTriangleEdgeVertex(iv3, iv1, iv2, feAr[id + 2]);

    if (!fArOut)
      continue;

    id *= 4;
    fArOut[id] = fArOut[id + 4] = fArOut[id + 6] = ivMid1;
    fArOut[id + 1] = fArOut[id + 8] = fArOut[id + 9] = ivMid2;
    fArOut[id + 2] = fArOut[id + 5] = fArOut[id + 11] = ivMid3;
    fArOut[id + 3] = iv1;
    fArOut[id + 7] = iv2;
    fArOut[id + 10] = iv3;
  }
  return oddComputer._tagEdges;
};

/** Apply a complete subdivision (by updating the topology) */
Subdivision.fullSubdivision = function (baseMesh, newMesh) {
  var nbVertices = baseMesh.getNbVertices() + baseMesh.getNbEdges();
  newMesh.setVertices(new Float32Array(nbVertices * 3));
  newMesh.setMaskings(new Float32Array(nbVertices));
  newMesh.setFaces(new Uint32Array(baseMesh.getNbFaces() * 3 * 4));
  applyEvenSmooth(baseMesh, newMesh.getVertices(), newMesh.getMaskings());
  applyOddSmooth(baseMesh, newMesh.getVertices(), newMesh.getMaskings(), newMesh.getFaces());
  newMesh.allocateArrays();
};

/** Apply subdivision without topology computation */
Subdivision.partialSubdivision = function (baseMesh, vertOut, maskingOut) {
  applyEvenSmooth(baseMesh, vertOut, maskingOut);
  applyOddSmooth(baseMesh, vertOut, maskingOut);
};

export default Subdivision;
