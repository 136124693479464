import {
  Color,
  CustomBlending,
  DoubleSide,
  LinearFilter,
  Matrix4,
  MeshBasicMaterial,
  MeshDepthMaterial,
  NoBlending,
  OneFactor,
  OneMinusSrcAlphaFactor,
  RGBADepthPacking,
  RGBAFormat,
  ShaderMaterial,
  UniformsUtils,
  Vector2,
  Vector3,
  WebGLRenderTarget
} from "three";
import {Pass} from "three/examples/jsm/postprocessing/Pass";
import {CopyShader} from "three/examples/jsm/shaders/CopyShader";
import {FXAAShader} from "three/examples/jsm/shaders/FXAAShader";

var OutlinePass = function (resolution, scene, camera) {

  this.renderScene = scene;
  this.renderCamera = camera;
  this.selectedObjects = [];
  this.visibleEdgeColor = new Color(1, 1, 1);
  this.hiddenEdgeColor = new Color(1, 1, 1);
  this.selectFaceColor = new Color(1, 1, 0);

  Pass.call(this);

  this.resolution = resolution ? new Vector2(resolution.x, resolution.y) : new Vector2(256, 256);
  this.seperateObjects = false;
  this.hideHiddenEdges = false;
  this.thickness = 1;

  var pars = {minFilter: LinearFilter, magFilter: LinearFilter, format: RGBAFormat};

  this.maskBufferMaterial = new MeshBasicMaterial({color: 0xffffff});
  this.maskBufferMaterial.side = DoubleSide;
  this.renderTargetMaskBuffer = new WebGLRenderTarget(this.resolution.x, this.resolution.y, pars);
  this.renderTargetMaskBuffer.texture.name = "OutlinePass.mask";
  this.renderTargetMaskBuffer.texture.generateMipmaps = false;

  this.depthMaterial = new MeshDepthMaterial();
  this.depthMaterial.side = DoubleSide;
  this.depthMaterial.depthPacking = RGBADepthPacking;
  this.depthMaterial.blending = NoBlending;

  this.prepareMaskMaterial = this.getPrepareMaskMaterial();
  this.prepareMaskMaterial.side = DoubleSide;
  this.prepareMaskMaterial.fragmentShader = replaceDepthToViewZ(this.prepareMaskMaterial.fragmentShader, this.renderCamera);

  this.renderTargetDepthBuffer = new WebGLRenderTarget(this.resolution.x, this.resolution.y, pars);
  this.renderTargetDepthBuffer.texture.name = "OutlinePass.depth";
  this.renderTargetDepthBuffer.texture.generateMipmaps = false;

  this.edgeDetectionMaterial = this.getEdgeDetectionMaterial();
  this.renderTargetEdgeBuffer1 = new WebGLRenderTarget(this.resolution.x, this.resolution.y, pars);
  this.renderTargetEdgeBuffer1.texture.name = "OutlinePass.edge1";
  this.renderTargetEdgeBuffer1.texture.generateMipmaps = false;

  // this.renderTargetFXAA = new WebGLRenderTarget( this.resolution.x, this.resolution.y, pars );
  // this.renderTargetFXAA.texture.name = "OutlinePass.FXAA";
  // this.renderTargetFXAA.texture.generateMipmaps = false;
  this.FXAAMaterial = this.getFXAAMaterial();
  this.FXAAMaterial.uniforms['resolution'].value.set(1 / this.resolution.x, 1 / this.resolution.y);

  // copy material
  if (CopyShader === undefined)
    console.error("OutlinePass relies on CopyShader");

  var copyShader = CopyShader;

  this.copyUniforms = UniformsUtils.clone(copyShader.uniforms);
  this.copyUniforms["opacity"].value = 1.0;

  this.materialCopy = new ShaderMaterial({
    uniforms: this.copyUniforms,
    vertexShader: copyShader.vertexShader,
    fragmentShader: copyShader.fragmentShader,
    blending: NoBlending,
    depthTest: false,
    depthWrite: false,
    transparent: true
  });

  this.enabled = true;
  this.needsSwap = false;

  this.oldClearColor = new Color();
  this.oldClearAlpha = 1;

  this.fsQuad = new Pass.FullScreenQuad(null);

  this.textureMatrix = new Matrix4();

  function replaceDepthToViewZ(string, camera) {

    var type = camera.isPerspectiveCamera ? 'perspective' : 'orthographic';

    return string.replace(/DEPTH_TO_VIEW_Z/g, type + 'DepthToViewZ');

  }

};

OutlinePass.prototype = Object.assign(Object.create(Pass.prototype), {

  constructor: OutlinePass,

  dispose: function () {

    this.renderTargetMaskBuffer.dispose();
    this.renderTargetDepthBuffer.dispose();
    this.renderTargetEdgeBuffer1.dispose();

  },

  setSize: function (width, height) {

    this.renderTargetMaskBuffer.setSize(width, height);

    this.renderTargetDepthBuffer.setSize(width, height);
    this.renderTargetEdgeBuffer1.setSize(width, height);
    this.FXAAMaterial.uniforms['resolution'].value.set(1 / width, 1 / height);

  },

  changeVisibilityOfSelectedObjects: function (bVisible) {

    function gatherSelectedMeshesCallBack(object) {

      if (object.isMesh) {

        if (bVisible) {

          object.visible = object.userData.oldVisible;
          delete object.userData.oldVisible;

        } else {

          object.userData.oldVisible = object.visible;
          object.visible = bVisible;

        }

      }

    }

    for (var i = 0; i < this.selectedObjects.length; i++) {

      var selectedObject = this.selectedObjects[i];
      selectedObject.traverse(gatherSelectedMeshesCallBack);
      // gatherSelectedMeshesCallBack(selectedObject);

    }

  },

  changeVisibilityOfNonSelectedObjects: function (bVisible) {

    var selectedMeshes = [];

    function gatherSelectedMeshesCallBack(object) {

      if (object.isMesh) selectedMeshes.push(object);

    }

    for (var i = 0; i < this.selectedObjects.length; i++) {

      var selectedObject = this.selectedObjects[i];
      selectedObject.traverse(gatherSelectedMeshesCallBack);
      // gatherSelectedMeshesCallBack(selectedObject);
    }

    function VisibilityChangeCallBack(object) {

      if (object.isMesh || object.isLine || object.isSprite) {

        var bFound = false;

        for (var i = 0; i < selectedMeshes.length; i++) {

          var selectedObjectId = selectedMeshes[i].id;

          if (selectedObjectId === object.id) {

            bFound = true;
            break;

          }

        }

        if (!bFound) {

          var visibility = object.visible;

          if (!bVisible || object.bVisible) object.visible = bVisible;

          object.bVisible = visibility;

        }

      }

    }

    this.renderScene.traverse(VisibilityChangeCallBack);

    // for (var i = 0; i < this.renderScene.children; i++) {
    //
    //   VisibilityChangeCallBack ( this.renderScene.children[i]);
    //
    // }

  },

  updateTextureMatrix: function () {

    this.textureMatrix.set(0.5, 0.0, 0.0, 0.5,
      0.0, 0.5, 0.0, 0.5,
      0.0, 0.0, 0.5, 0.5,
      0.0, 0.0, 0.0, 1.0);
    this.textureMatrix.multiply(this.renderCamera.projectionMatrix);
    this.textureMatrix.multiply(this.renderCamera.matrixWorldInverse);

  },

  innerRender: function (renderer, writeBuffer, readBuffer, deltaTime, maskActive) {
    this.oldClearColor.copy(renderer.getClearColor());
    this.oldClearAlpha = renderer.getClearAlpha();
    var oldAutoClear = renderer.autoClear;

    renderer.autoClear = false;

    if (maskActive) renderer.state.buffers.stencil.setTest(false);

    renderer.setClearColor(0xffffff, 1);

    // Make selected objects invisible
    this.changeVisibilityOfSelectedObjects(false);

    var currentBackground = this.renderScene.background;
    this.renderScene.background = null;

    // 1. Draw Non Selected objects in the depth buffer
    this.renderScene.overrideMaterial = this.depthMaterial;
    renderer.setRenderTarget(this.renderTargetDepthBuffer);
    renderer.clear();
    renderer.render(this.renderScene, this.renderCamera);

    // Make selected objects visible
    this.changeVisibilityOfSelectedObjects(true);

    // Update Texture Matrix for Depth compare
    this.updateTextureMatrix();

    // Make non selected objects invisible, and draw only the selected objects, by comparing the depth buffer of non selected objects
    this.changeVisibilityOfNonSelectedObjects(false);
    this.renderScene.overrideMaterial = this.prepareMaskMaterial;
    this.prepareMaskMaterial.uniforms["cameraNearFar"].value = new Vector2(this.renderCamera.near, this.renderCamera.far);
    this.prepareMaskMaterial.uniforms["depthTexture"].value = this.renderTargetDepthBuffer.texture;
    this.prepareMaskMaterial.uniforms["textureMatrix"].value = this.textureMatrix;
    renderer.setRenderTarget(this.renderTargetMaskBuffer);
    renderer.clear();
    renderer.render(this.renderScene, this.renderCamera);
    this.renderScene.overrideMaterial = null;
    this.changeVisibilityOfNonSelectedObjects(true);

    this.renderScene.background = currentBackground;

    // 3. Apply Edge Detection Pass
    this.fsQuad.material = this.edgeDetectionMaterial;
    this.edgeDetectionMaterial.uniforms["maskTexture"].value = this.renderTargetMaskBuffer.texture;
    this.edgeDetectionMaterial.uniforms["texSize"].value = new Vector2(this.renderTargetMaskBuffer.width, this.renderTargetMaskBuffer.height);
    this.edgeDetectionMaterial.uniforms["visibleEdgeColor"].value = this.visibleEdgeColor;
    this.edgeDetectionMaterial.uniforms["hiddenEdgeColor"].value = this.hiddenEdgeColor;
    this.edgeDetectionMaterial.uniforms["selectFaceColor"].value = this.selectFaceColor;
    this.edgeDetectionMaterial.uniforms["hideHiddenEdges"].value = this.hideHiddenEdges ? 1.0 : 0.0;
    this.edgeDetectionMaterial.uniforms["thickness"].value = this.thickness;

    renderer.setRenderTarget(this.renderTargetEdgeBuffer1);
    renderer.clear();
    this.fsQuad.render(renderer);

    if (maskActive) renderer.state.buffers.stencil.setTest(true);

    this.fsQuad.material = this.FXAAMaterial;
    this.FXAAMaterial.uniforms["tDiffuse"].value = this.renderTargetEdgeBuffer1.texture;

    renderer.setRenderTarget(null);
    this.fsQuad.render(renderer);


    renderer.setClearColor(this.oldClearColor, this.oldClearAlpha);
    renderer.autoClear = oldAutoClear;
  },

  render: function (renderer, writeBuffer, readBuffer, deltaTime, maskActive) {

    if (this.selectedObjects.length > 0) {

      if (this.seperateObjects) {

        var objects = [...this.selectedObjects];

        for (var i = 0; i < objects.length; ++i) {

          this.selectedObjects = [objects[i]];
          this.innerRender(renderer, writeBuffer, readBuffer, deltaTime, maskActive);

        }

        this.selectedObjects = objects;
      } else {

        this.innerRender(renderer, writeBuffer, readBuffer, deltaTime, maskActive);

      }
    }
  },

  getPrepareMaskMaterial: function () {

    return new ShaderMaterial({

      uniforms: {
        "depthTexture": {value: null},
        "cameraNearFar": {value: new Vector2(0.5, 0.5)},
        "textureMatrix": {value: new Matrix4()}
      },

      vertexShader: [
        'varying vec4 projTexCoord;',
        'varying vec4 vPosition;',
        'uniform mat4 textureMatrix;',

        'void main() {',

        '	vPosition = modelViewMatrix * vec4( position, 1.0 );',
        '	vec4 worldPosition = modelMatrix * vec4( position, 1.0 );',
        '	projTexCoord = textureMatrix * worldPosition;',
        '	gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',

        '}'
      ].join('\n'),

      fragmentShader: [
        '#include <packing>',
        'varying vec4 vPosition;',
        'varying vec4 projTexCoord;',
        'uniform sampler2D depthTexture;',
        'uniform vec2 cameraNearFar;',

        'void main() {',

        '	float depth = unpackRGBAToDepth(texture2DProj( depthTexture, projTexCoord ));',
        '	float viewZ = - DEPTH_TO_VIEW_Z( depth, cameraNearFar.x, cameraNearFar.y );',
        '	float depthTest = (-vPosition.z > viewZ) ? 1.0 : 0.0;',
        '	gl_FragColor = vec4(0.0, depthTest, 1.0, 1.0);',

        '}'
      ].join('\n')

    });

  },

  getEdgeDetectionMaterial: function () {

    return new ShaderMaterial({

      uniforms: {
        "maskTexture": {value: null},
        "texSize": {value: new Vector2(0.5, 0.5)},
        "visibleEdgeColor": {value: new Vector3(1.0, 1.0, 1.0)},
        "hiddenEdgeColor": {value: new Vector3(1.0, 1.0, 1.0)},
        "selectFaceColor": {value: new Vector3(1.0, 1.0, 0.0)},
        "hideHiddenEdges": {value: 0.0},
        "thickness": {value: 1.0},
      },

      vertexShader:
        "varying vec2 vUv;\n\
        void main() {\n\
          vUv = uv;\n\
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );\n\
        }",

      fragmentShader:
        "varying vec2 vUv;\
        uniform sampler2D maskTexture;\
        uniform vec2 texSize;\
        uniform vec3 visibleEdgeColor;\
        uniform vec3 hiddenEdgeColor;\
        uniform vec3 selectFaceColor;\
        uniform float hideHiddenEdges;\
        uniform float thickness;\
        \
        void main() {\n\
          vec2 invSize = 1.0 / texSize;\
          vec4 uvOffset = vec4(thickness, 0.0, 0.0, thickness) * vec4(invSize, invSize);\
          vec4 c1 = texture2D( maskTexture, vUv + uvOffset.xy );\
          vec4 c2 = texture2D( maskTexture, vUv - uvOffset.xy );\
          vec4 c3 = texture2D( maskTexture, vUv + uvOffset.yw );\
          vec4 c4 = texture2D( maskTexture, vUv - uvOffset.yw );\
          vec4 c0 = texture2D( maskTexture, vUv );\
          float diff1 = (c1.r - c2.r)*0.5;\
          float diff2 = (c3.r - c4.r)*0.5;\
          float d = length( vec2(diff1, diff2) );\
          float a1 = min(c1.g, c2.g);\
          float a2 = min(c3.g, c4.g);\
          float visibilityFactor = min(a1, a2);\
          vec3 edgeColor = 1.0 - visibilityFactor > 0.001 ? visibleEdgeColor : hiddenEdgeColor;\
          if (hideHiddenEdges > 0.0) {\
            d = 1.0 - visibilityFactor > 0.001 ? d : 0.0;\
          }\
          gl_FragColor = vec4(edgeColor, 1.0) * vec4(d);\
          gl_FragColor += 0.2 * (1.0 - c0.r) * vec4(selectFaceColor, 0.0);\
        }"
      // gl_FragColor = vec4(edgeColor, 1.0) * vec4(d);
    });

  },

  getFXAAMaterial: function () {

    return new ShaderMaterial({

      uniforms: FXAAShader.uniforms,
      vertexShader: FXAAShader.vertexShader,
      fragmentShader: FXAAShader.fragmentShader,

      blending: CustomBlending,
      blendSrc: OneFactor,
      blendDst: OneMinusSrcAlphaFactor,
      depthTest: false,
      depthWrite: false,
      transparent: true

    });

  }

});

// OutlinePass.BlurDirectionX = new Vector2( 1.0, 0.0 );
// OutlinePass.BlurDirectionY = new Vector2( 0.0, 1.0 );

export {OutlinePass};
