import {
  CHANGE_GLOBAL_SEARCH_TEXT,
  CHANGE_SCREEN_SIZE,
  GlobalActionTypes, INIT_SCENE, IScreenSize
} from "../../types/ui/global";

export function changeScreenSize(screenSize: IScreenSize): GlobalActionTypes {
  return {
    type: CHANGE_SCREEN_SIZE,
    payload: {
      screenSize
    }
  };
}

export function changeGlobalSearchText(searchText: string): GlobalActionTypes {
  return {
    type: CHANGE_GLOBAL_SEARCH_TEXT,
    payload: {
      searchText
    }
  }
}

export function initScene(id: number) {
  return {
    type: INIT_SCENE,
    payload: {
      id
    }
  }
}