import {
  IDeleteItemAsync,
  IFetchItemAsync,
  IFetchItemsAsync,
  IPostItemAsync
} from "../types";
import {InitStoreAction} from "../init";

export const GET_CALC_HEATMAP = 'GET_CALC_HEATMAP';
export const GET_CALC_DETAIL_INFO = 'GET_CALC_DETAIL_INFO';
export const POST_ROUTE = 'POST_ROUTE';
export const FETCH_ROUTE = 'FETCH_ROUTE';
export const POST_CHANGE_CURSOR = 'POST_CHANGE_CURSOR';

export interface IApiSceneState {
  heatmaps: { [key: string]: IFetchItemAsync<string> }
  detailInfos: { [key: string]: IFetchItemAsync<string> }
  upRoute: IPostItemAsync<number>
  downRoute: IFetchItemAsync<number>
  changeCursor: IPostItemAsync<number>
}

interface GetCalcHeatmapAction {
  type: typeof GET_CALC_HEATMAP
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    id: string
    extraInfo?: any
  }
}

interface GetCalcDetailInfoAction {
  type: typeof GET_CALC_DETAIL_INFO
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    id: string
    extraInfo?: any
  }
}


interface PostRouteAction {
  type: typeof POST_ROUTE
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id?: number
  }
}

interface FetchRouteAction {
  type: typeof FETCH_ROUTE
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    id?: number
  }
}

interface PostChangeCursorAction {
  type: typeof POST_CHANGE_CURSOR
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id?: number
  }
}


export type ApiSceneActionTypes = GetCalcHeatmapAction |
  GetCalcDetailInfoAction |
  PostRouteAction |
  FetchRouteAction |
  PostChangeCursorAction |
  InitStoreAction;