import {
  CHANGE_INITIAL_POINT,
  HeroActionTypes,
  IHeroState
} from "../../types/ui/hero";
import {INIT_STORE} from "../../types/init";

const initialState: IHeroState = {
  initialPoint: 0
};

export function heroReducer(
  state = initialState,
  action: HeroActionTypes
): IHeroState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case CHANGE_INITIAL_POINT:
      return {
        ...state,
        initialPoint: action.payload.initialPoint
      };
    default:
      return state;
  }
}