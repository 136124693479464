import * as React from 'react';
import {createRef} from 'react';
import {IAppState} from '../../../store';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {MoonLoader} from "react-spinners";
import Editor3dWrapper from "../../3d/Editor3dWrapper";
import {IEntityProjectsState} from "../../../store/types/entity/projects";
import {
  IDigitalMaterial,
  IFetchAsync,
  IFetchItemAsync,
  IGAR,
  IPostAsync,
  IProject,
  IWomp20Water
} from "../../../store/types/types";
import iconRedo from "../../../assets/images/icon/hero/redo.svg";
import iconUndo from "../../../assets/images/icon/hero/undo.svg";
import iconRefresh from "../../../assets/images/icon/hero/refresh.svg";
import iconAltKey from "../../../assets/images/icon/hero/alt-key.svg";
import iconDrag from "../../../assets/images/icon/hero/drag.svg";
import iconDKey from "../../../assets/images/icon/hero/d-key.svg";
import iconNegative from "../../../assets/images/icon/hero/negative.svg";
import iconPlusKey from "../../../assets/images/icon/hero/plus-key.svg";
import iconMinusKey from "../../../assets/images/icon/hero/minus-key.svg";
import iconLeftBracketKey from "../../../assets/images/icon/hero/left-bracket-key.svg";
import iconRightBracketKey from "../../../assets/images/icon/hero/right-bracket-key.svg";
import iconRecord from "../../../assets/images/icon/hero/record.svg";
import iconRecordActive from "../../../assets/images/icon/hero/record-active.svg";
import iconScreenshot from "../../../assets/images/icon/hero/screenshot.svg";
import iconShiftKey from "../../../assets/images/icon/hero/shift-key.svg";
import iconShuffle from "../../../assets/images/icon/hero/shuffle.svg";
import iconSmooth from "../../../assets/images/icon/hero/smooth.svg";
import iconSpaceKey from "../../../assets/images/icon/hero/space-key.svg";
import iconSymmetric from "../../../assets/images/icon/hero/symmetric.svg";
import iconWaveKey from "../../../assets/images/icon/hero/wave-key.svg";
import iconStrength from "../../../assets/images/icon/hero/strength.svg";
import iconRadius from "../../../assets/images/icon/hero/radius.svg";
import iconPlay from "../../../assets/images/icon/hero/play.svg";
import iconDownload from "../../../assets/images/icon/hero/download.svg";
import iconWomp from "../../../assets/images/icon/hero/womp.svg";
import iconHeroBadge from "../../../assets/images/icon/hero/badge.svg";
import iconHeroBadgeGreen from "../../../assets/images/icon/hero/badge-green.svg";
import iconTwitter from "../../../assets/images/icon/hero/twitter.svg";
import iconInstagram from "../../../assets/images/icon/hero/instagram.svg";
import iconTiktok from "../../../assets/images/icon/hero/tiktok.svg";
import iconYoutube from "../../../assets/images/icon/hero/youtube.svg";
import iconBack from "../../../assets/images/icon/hero/back.svg";
import iconNext from "../../../assets/images/icon/hero/next.svg";
import iconSend from "../../../assets/images/icon/hero/send.svg";
import iconExpand from "../../../assets/images/icon/hero/expand.svg"
import imgGradientP from "../../../assets/images/background/gradient_p.png";
import vidGradientP from "../../../assets/videos/gradient_p.mp4";
import imgTutorial from "../../../assets/images/background/tutorial.png";
import vidTutorial from "../../../assets/videos/tutorial.mp4";
import {fetchMediaProjectLoad} from "../../action/project";
import {
  changeCursor,
  changeScenePreviewDigitalMaterial,
  redo,
  undo
} from "../../action/scene";
import {
  addWaiterAction,
  createWaiter,
  getWaiter,
  postWaiterVideo,
  putCurrentWaiter,
  sendWaiterInviteEmail,
  sendWaiterInviteRemindEmail
} from "../../action/waiter";
import {getRandomColor, isValidEmail} from "../../common/validate";
import {changeWaiter} from "../../../store/actions/entity/global";
import {Color, MeshPhysicalMaterial} from "three";
import ResizeDetector from "../../component/common/ResizeDetector";
import ImageButton from "../../component/common/ImageButton";
import {ISliderValue} from "../../component/common/Slider";
import {
  HERO_EYE_URL,
  HERO_FRONT_URL,
  HERO_PROJECT_ID,
  nothing,
  SCULPT_TOOL_INFOS
} from "../../common/const";
import {defaultSculptConfig, ISculptConfig, MaterialTypes, Tools} from "../../../peregrine/processor";
import {store} from "../../../index";
import iconPoint from "../../../assets/images/icon/header/point.png";
import numeral from "numeral";
import Tooltip from "../../component/common/Tooltip";
import lod from "lodash";
import {encodeBuffer, encodeGeometry} from "../../../peregrine/utils";
import ScrollPanel from "../../component/common/ScrollPanel";
import Div100vh from "react-div-100vh";
import {getGeometryFromSculptMesh} from "../../../peregrine/converter";
import {MeshCodec} from "../../../peregrine/types";
import ProgressBar from "../../component/common/ProgressBar";
import {ProjectSceneDispatch} from "../../core";
import {push} from "connected-react-router";
import {
  ACT_KEYDOWN,
  ACT_MOUSE_DOWN, ACT_MOUSE_UP, ACT_REDO,
  ACT_SCULPT_STROKE,
  ACT_SELECT_MODELS, ACT_UNDO,
  ACT_UPDATE_SCULPT_CONFIG
} from "../../3d/Editor3d";
import Editor3dDelegateWrapper from "../../3d/Editor3dDelegateWrapper";
import ProjectLoadingBar from "../../component/viewer/ProjectLoadingBar";
import InlineVideo from "../../component/common/InlineVideo";
import {changeInitialPoint} from "../../../store/actions/ui/hero";
import {Link} from "react-router-dom";
import iconFeature1 from "../../../assets/images/icon/hero/feature/1.svg";
import iconFeature2 from "../../../assets/images/icon/hero/feature/2.svg";
import iconFeature3 from "../../../assets/images/icon/hero/feature/3.svg";
import iconFeature4 from "../../../assets/images/icon/hero/feature/4.svg";
import iconFeature5 from "../../../assets/images/icon/hero/feature/5.svg";
import iconFeature6 from "../../../assets/images/icon/hero/feature/6.svg";
import CursorDiv from "../../component/common/cursor/CursorDiv";
import {Cursor} from "../../component/common/cursor/Cursor";
import imgTrailer from "../../../assets/images/background/trailer.png";
import vidTrailer from "../../../assets/videos/trailer.mp4";
import {Animated} from "react-animated-css";
import iconMinus from "../../../assets/images/icon/hero/minus.svg";
import iconPlus from "../../../assets/images/icon/hero/plus.svg";
import {IScreenSize} from "../../../store/types/ui/global";
import iconProductHunt from "../../../assets/images/icon/hero/product-hunt.png";
import HeroFooter from "./HeroFooter";

export interface IHero2Props {
  page: 'main' | 'hero'
  editorWrapper: Editor3dWrapper

  entityState: IEntityProjectsState
  load: { [key: number]: IFetchItemAsync<number> }

  engineLoaded: boolean
  waiter?: IWomp20Water
  screenSize: IScreenSize

  undoRedoPosting: boolean

  fetchWaiter: IFetchAsync
  postWaiter: IPostAsync
  postWaiterAction: IPostAsync

  initialPoint: number

  fetchMediaProjectLoad: (id: number, wrapper: Editor3dWrapper) => Promise<IGAR>
  undo: (projectId: number, wrapper: Editor3dWrapper, insideSculpt?: boolean) => Promise<IGAR>
  redo: (projectId: number, wrapper: Editor3dWrapper, insideSculpt?: boolean) => Promise<IGAR>
  sendWaiterInviteRemindEmail: (name: string, email: string) => Promise<IGAR>
  sendWaiterInviteEmail: (name: string, email: string) => Promise<IGAR>
  getWaiter: (email: string) => Promise<IGAR>
  createWaiter: (email: string, point: number) => Promise<IGAR>
  postWaiterVideo: (email: string, title: string, video: string, model?: string) => Promise<IGAR>
  putWaiter: (waiterId: number, newValues: Partial<IWomp20Water>) => Promise<IGAR>
  changeWaiter: (waiter?: IWomp20Water) => void
  addWaiterAction: (email: string, action: string) => Promise<IGAR>
  changeScenePreviewDigitalMaterial: (projectId: number, wrapper: Editor3dWrapper, calcIds: string[], material?: IDigitalMaterial) => void
  changeDigitalMaterials: (projectId: number, wrapper: Editor3dWrapper, materials: IDigitalMaterial[]) => void
  hideOtherCalcsAndSculpt: (projectId: number, wrapper: Editor3dWrapper, calcIds: string[]) => Promise<IGAR>
  changeCursor: (projectId: number, wrapper: Editor3dWrapper, cursor: number, clear?: boolean) => Promise<IGAR>
  changeInitialPoint: (point: number) => void
  goToMain: () => void
  goToHero: () => void
}

enum Hero2Messages {
  EarlyAccess = 'early access',
  WompPoint = 'womp point',
  PlayGame = 'play game',
  AddEmail = 'add email',
  GameRule = 'game rule',
  DontPlay = 'dont play',
  NeedPractice = 'need practice',
  ReadyCountDown = 'ready countdown',
  ChallengeCountDown = 'challenge countdown',
  ChallengeFinish = 'challenge finish',
  TryAgain = 'try again',
  Submitted = 'submitted',
  RecordShare = 'record share',
  Tutorial = 'tutorial',
  ReferFriend = 'refer friend',
  TellMore = 'tell more',
  TellMore1 = 'tell more 1',
  TellMore2 = 'tell more 2',
  TellMore3 = 'tell more 3',
  TellMore4 = 'tell more 4',
  TellMore5 = 'tell more 5',
  TellMore6 = 'tell more 6',
  TellMore7 = 'tell more 7',
  TellMore8 = 'tell more 8',
  TellMoreSubmit = 'tell more submit',
  About = 'about'
}

const ControlMessages: string[] = [
  Hero2Messages.EarlyAccess,
  Hero2Messages.WompPoint,
  Hero2Messages.PlayGame,
  Hero2Messages.AddEmail,
  Hero2Messages.GameRule,
  Hero2Messages.DontPlay,
  Hero2Messages.NeedPractice,
  Hero2Messages.ReadyCountDown,
  Hero2Messages.ChallengeCountDown,
  Hero2Messages.TryAgain,
  Hero2Messages.Submitted,
  Hero2Messages.RecordShare
];

const ShowOnlyMessages: string[] = [
  Hero2Messages.About,
  Hero2Messages.TellMore,
  Hero2Messages.TellMore1,
  Hero2Messages.TellMore2,
  Hero2Messages.TellMore3,
  Hero2Messages.TellMore4,
  Hero2Messages.TellMore5,
  Hero2Messages.TellMore6,
  Hero2Messages.TellMore7,
  Hero2Messages.TellMore8
];

const TellMoreMessages: string[] = [
  Hero2Messages.TellMore,
  Hero2Messages.TellMore1,
  Hero2Messages.TellMore2,
  Hero2Messages.TellMore3,
  Hero2Messages.TellMore4,
  Hero2Messages.TellMore5,
  Hero2Messages.TellMore6,
  Hero2Messages.TellMore7,
  Hero2Messages.TellMore8
];

const HideSculptMessages: string[] = [
  Hero2Messages.Tutorial,
  Hero2Messages.ReferFriend
];

interface IHero2InnerState {
  email: string
  cheatCode: string
  recordingCaption: string
  friendReferringName: string
  friendReferringNameInvalid: boolean
  inputting: boolean
  confirmed: boolean
  recording: boolean
  showVideo: boolean
  ffmpegReady: boolean
  videoReplayPlaying: boolean
  videoReplayDownloading: boolean
  videoReplayDownloadPercentage: number
  tutorialVideoPlaying: boolean
  readyCountDown: number
  challengeCountDown: number
  pointAnimationCount: number
  targetAnimationCount: number
  message: string
  controlEnabled: boolean
  mediaRecordSupport: boolean
  point: number
  friendEmails: { [key: number]: any }
  detailFields: { [key: string]: string }
  config: ISculptConfig
}

class Hero2 extends React.PureComponent<IHero2Props, IHero2InnerState> {
  delegateWrapper!: Editor3dDelegateWrapper;
  elementsOnViewerSelector = "button, input, .top-controls>div, .left-controls>div, .scroll-area>div, " +
    ".record-controls>div, .user-points, .womp-logo, .sns-icons";
  emailChangeTimer: any;
  friendEmailChangeTimer: { [key: number]: any } = {};
  confirmTimer: any;
  readyTimer: any;
  challengeTimer: any;
  earlyAccessStayTimer: any;
  eyeShuffleTimers: any[] = [];
  chunks: any[] = [];
  videoWidth: number = 0;
  videoHeight: number = 0;
  videoReplayElem: any;
  tutorialVideoElem: any;
  tellMoreFieldInputElem: any;
  mediaRecorder: any;
  recordingLength: number = 0;

  wrapperRef: any = createRef();

  // dmIdByCalcId: { [key: string]: string } = {};
  // dmSculptId: string = '';
  // eyeUnionCalcId: string = '';
  modelData: string = '';

  tellMoreInfos: { [key: string]: any } = {
    [Hero2Messages.TellMore]: {field: '', question: ''},
    [Hero2Messages.TellMore1]: {field: 'fullName', question: 'what is your name?', placeholder: 'first           last'},
    [Hero2Messages.TellMore2]: {field: 'job', question: 'what do you do for a living?'},
    [Hero2Messages.TellMore3]: {field: 'interest', question: 'why are you interested in womp?'},
    [Hero2Messages.TellMore4]: {field: 'has3DExperience', question: 'have you ever used 3D before?'},
    [Hero2Messages.TellMore5]: {field: 'device', question: 'do you use a laptop or desktop?'},
    [Hero2Messages.TellMore6]: {field: 'thought', question: 'any thoughts on Womp you’d like to share?'},
    [Hero2Messages.TellMore7]: {field: 'os', question: 'do you use macOS or windows?'},
    [Hero2Messages.TellMore8]: {field: 'usage', question: 'what will you use 3D content for?'}
  };

  config = {
    earlyAccessStayTime: 60000,
    inputDelayTime: 1000,
    waiterConfirmDelay: 2000,
    pointAnimationInterval: 100,
    pointAnimationDuration: 1000,
    unitPoint: 50,
    challengeDuration: 60,
    userIdFrom: 800
  };

  state: IHero2InnerState = {
    email: '',
    cheatCode: '',
    recordingCaption: 'dog',
    friendReferringName: '',
    friendReferringNameInvalid: false,
    inputting: false,
    confirmed: false,
    recording: false,
    showVideo: false,
    ffmpegReady: false,
    videoReplayPlaying: false,
    videoReplayDownloading: false,
    videoReplayDownloadPercentage: 0,
    tutorialVideoPlaying: false,
    controlEnabled: false,
    mediaRecordSupport: true,
    message: '',
    point: 0,
    readyCountDown: 0,
    challengeCountDown: 0,
    pointAnimationCount: 0,
    targetAnimationCount: 0,
    friendEmails: {},
    detailFields: {},
    config: defaultSculptConfig
  };

  worker = new Worker("ffmpeg-worker-mp4.js");

  componentDidMount() {
    const {editorWrapper} = this.props;

    editorWrapper.setActionCallback(this.onEditor3dAction);

    if (editorWrapper.editor) {
      try {
        let canvas = editorWrapper.editor.renderer.domElement;
        //@ts-ignore
        let videoStream = canvas.captureStream(30);
        this.mediaRecorder = new MediaRecorder(videoStream);
      } catch (e) {
        this.setState({mediaRecordSupport: false});
        console.log('no media recorder support');
      }
    }

    this.mediaRecorder = undefined;

    this.worker.onmessage = (e) => {
      const msg = e.data;
      switch (msg.type) {
        case "ready":
          console.log("ffmpeg.js ready");
          this.setState({ffmpegReady: true});
          break;
        case "stdout":
          console.log(msg.data);
          break;
        case "stderr":
          console.log(msg.data);
          let matches = /time=(\d\d):(\d\d):(\d\d)\.(\d\d)/g.exec(msg.data);
          if (matches !== null) {
            let currentStop = +matches[1] * 3600000 + (+matches[2]) * 60000 + (+matches[3]) * 1000 + (+matches[4]) * 10;
            if (this.recordingLength > 0)
              this.setState({videoReplayDownloadPercentage: 100 * currentStop / this.recordingLength});
          }
          break;
        case "done":
          // console.log(msg.data.MEMFS[0].data);
          this.setState({videoReplayDownloadPercentage: 100});
          try {
            let mp4blob = new Blob([msg.data.MEMFS[0].data], {type: 'video/mp4'});
            const urlCreator = window.URL || (window as any).webkitURL;
            let videoURL = urlCreator.createObjectURL(mp4blob);

            let link = document.createElement('a');
            link.download = 'record.mp4';
            link.href = videoURL;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } catch (ex) {
            console.warn(ex);
          }
          this.setState({videoReplayDownloading: false});
          break;
      }
    };

    this.initialize();
  }

  componentDidUpdate(prevProps: Readonly<IHero2Props>, prevState: Readonly<IHero2InnerState>) {
    let newState: Partial<IHero2InnerState> = {};

    const {page: _page} = prevProps;
    const {page} = this.props;
    if (page !== _page) {
      this.initialize();
    }

    let project = this.getProject();
    let _project = this.getProject(prevProps);
    if (project !== _project && project && page === 'hero') {
      this.initializeWaiter();
    }

    if (prevState.email !== this.state.email) {
      const currentEmail = this.state.email;
      if (this.emailChangeTimer)
        clearTimeout(this.emailChangeTimer);

      this.emailChangeTimer = setTimeout(
        () => this.onEmailChangeTimeout(currentEmail),
        this.config.inputDelayTime
      );
    }

    for (let index in this.state.friendEmails) {
      let prevInfo = prevState.friendEmails[index] || {email: ''};
      let info = this.state.friendEmails[index] || {email: ''};
      if (prevInfo.email !== info.email) {
        const currentEmail = info.email;
        if (this.friendEmailChangeTimer[index])
          clearTimeout(this.friendEmailChangeTimer[index]);

        this.friendEmailChangeTimer[index] = setTimeout(
          () => this.onFriendEmailChangeTimeout(+index, currentEmail),
          this.config.inputDelayTime
        );
      }
    }

    if (!prevProps.waiter && this.props.waiter) {
      if (this.confirmTimer)
        clearTimeout(this.confirmTimer);

      localStorage.setItem('waiter_email', this.state.email);

      this.confirmTimer = setTimeout(this.onConfirmTimeout, this.config.waiterConfirmDelay);

      newState = {
        ...newState,
        message: Hero2Messages.EarlyAccess
      };

    } else if (!this.props.waiter) {
      if (this.confirmTimer)
        clearTimeout(this.confirmTimer);

      this.setState({confirmed: false});
    }

    if (this.props.waiter && (!prevProps.waiter || this.props.waiter.friendInfo !== prevProps.waiter.friendInfo)) {
      newState = {
        ...newState,
        friendEmails: lod.mapValues(this.props.waiter.friendInfo, f => ({
          email: f.email,
          exist: f.exist,
          sent: !!f.sent,
          inputting: false
        }))
      };
    }

    if (this.props.waiter && (!prevProps.waiter || this.props.waiter.info !== prevProps.waiter.info)) {
      if (this.props.waiter.info && this.props.waiter.info.fullName) {
        newState = {
          ...newState,
          friendReferringName: this.props.waiter.info.fullName
        };
      }
    }

    if (prevState.message !== Hero2Messages.EarlyAccess && this.state.message === Hero2Messages.EarlyAccess) {
      this.earlyAccessStayTimer = setTimeout(this.onEarlyAccessStayTimeout, this.config.earlyAccessStayTime);
    }

    if (prevState.message === Hero2Messages.EarlyAccess && this.state.message !== Hero2Messages.EarlyAccess) {
      if (this.earlyAccessStayTimer) {
        clearTimeout(this.earlyAccessStayTimer);
        this.earlyAccessStayTimer = undefined;
      }
    }

    if (prevState.message !== this.state.message && TellMoreMessages.includes(this.state.message)) {
      if (this.tellMoreFieldInputElem)
        this.tellMoreFieldInputElem.focus();
    }

    if (prevState.message !== Hero2Messages.ReadyCountDown && this.state.message === Hero2Messages.ReadyCountDown) {
      newState = {
        ...newState,
        readyCountDown: 10
      };
      this.readyTimer = setInterval(this.onReadyTimeout, 1000);
    }

    if (prevState.message !== Hero2Messages.ChallengeCountDown && this.state.message === Hero2Messages.ChallengeCountDown) {
      this.resetSculpt();
      this.startRecording();
      newState = {
        ...newState,
        challengeCountDown: this.config.challengeDuration,
        recording: true
      };
      this.challengeTimer = setInterval(this.onChallengeTimeout, 1000);
    }

    if (this.state.message === Hero2Messages.ChallengeFinish) {
      if (!this.state.showVideo) {
        newState = {
          ...newState,
          showVideo: true
        };
      }
    } else if (this.state.message !== Hero2Messages.RecordShare) {
      if (this.state.showVideo) {
        newState = {
          ...newState,
          showVideo: false
        };
      }
    }

    if (this.props.editorWrapper.editor) {
      let editor3d = this.props.editorWrapper.editor;

      if (project && project.scn.tool === Tools.Sculpt) {
        if (ControlMessages.includes(this.state.message)) {
          editor3d.configure({editControls: true});
          if (!this.state.controlEnabled) {
            newState = {
              ...newState,
              controlEnabled: true
            };
          }
        } else {
          editor3d.configure({editControls: false});
          if (this.state.controlEnabled) {
            newState = {
              ...newState,
              controlEnabled: false
            };
          }
        }
      } else {
        editor3d.configure({editControls: false});
        if (this.state.controlEnabled) {
          newState = {
            ...newState,
            controlEnabled: false
          };
        }
      }
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState as any);
    }
  }

  initialize = async () => {
    const {page, editorWrapper, fetchMediaProjectLoad, changeCursor, changeInitialPoint} = this.props;

    if (page === 'main') {
      if (editorWrapper.editor) {
        editorWrapper.editor._clear();
        editorWrapper.editor._emptyCamera();
        editorWrapper.editor.configure({
          editControls: false,
          lightControls: false,
          highlight: false,
          hoverHighlight: false,
          grid: false,
          envMap: false,
          orbit: false,
          blob: true,
          blobPosition: [-80, 240, 360],
          blobSize: 120,
          cameraZoom: false,
          cameraPan: false,
        });

        if (editorWrapper.editor.projectId !== HERO_PROJECT_ID) {
          fetchMediaProjectLoad(HERO_PROJECT_ID, editorWrapper).then(res => {
            changeCursor(HERO_PROJECT_ID, editorWrapper, 0);
          });
        } else {
          changeCursor(HERO_PROJECT_ID, editorWrapper, 0);
          setTimeout(() => editorWrapper.editor && editorWrapper.editor.repositionCamera('preview-zoom'), 1000);
        }
      }

      changeInitialPoint(0);
    } else if (page === 'hero') {
      if (editorWrapper.editor) {
        editorWrapper.editor.configure({
          editControls: false,
          lightControls: false,
          highlight: false,
          hoverHighlight: false,
          grid: false,
          envMap: false,
          orbit: false,
          blob: false,
          cameraZoom: true,
          cameraPan: true,
        });

        if (editorWrapper.editor.projectId !== HERO_PROJECT_ID) {
          editorWrapper.editor._emptyCamera();
          fetchMediaProjectLoad(HERO_PROJECT_ID, editorWrapper);
        } else {
          editorWrapper.editor.repositionCamera('preview-zoom');
        }
      }

      this.initializeWaiter();
    }
  }

  initializeWaiter = () => {
    let project = this.getProject();
    if (project) {
      let email = localStorage.getItem('waiter_email');
      this.setState({
        message: Hero2Messages.EarlyAccess
      }, () => {
        this.switchToSculpt().then(res => {
          this.setState({email: email || ''});
        })
      });

      if (!email) {
        this.setState({point: this.props.initialPoint});
        this.playPointAnimation(this.props.initialPoint);
      }
    }
  }

  componentWillUnmount() {
    if (this.emailChangeTimer)
      clearTimeout(this.emailChangeTimer);
    for (let index in this.friendEmailChangeTimer)
      clearTimeout(this.friendEmailChangeTimer[index]);
    if (this.confirmTimer)
      clearTimeout(this.confirmTimer);
  }

  onRequestAccess = () => {
    const {goToHero, editorWrapper} = this.props;
    const project = this.getProject();

    if (this.eyeShuffleTimers.length > 0)
      return;

    if (project && editorWrapper.editor) {
      editorWrapper.editor.configure({orbit: true, orbitSpeed: 20});
      for (let calcId of project.scn.calcIds) {
        let calc = project.scn.calcs[calcId];
        if (calc && calc.title.includes('eye') && calc.title !== 'eye union') {
          let intervalId = setInterval(() => {
            this.shuffleEyeMaterial(calcId);
          }, 200 + Math.random() * 800);
          this.eyeShuffleTimers.push(intervalId);
        }
      }
    }

    setTimeout(() => {
      for (let timer of this.eyeShuffleTimers)
        clearInterval(timer);
      this.eyeShuffleTimers = [];
      goToHero();
    }, 3000);
  }

  onAccessClick = (evt: any) => {
    const {changeInitialPoint} = this.props;
    this.onRequestAccess();
    changeInitialPoint(100);
  }

  onChallengeTimeout = () => {
    if (this.state.challengeCountDown === 1) {
      clearTimeout(this.challengeTimer);
      this.stopRecording();
      this.saveModel();
      this.setState({
        message: Hero2Messages.ChallengeFinish,
        challengeCountDown: this.config.challengeDuration,
        recording: false
      });
    } else {
      this.setState({challengeCountDown: this.state.challengeCountDown - 1});
    }
  };

  onReadyTimeout = () => {
    if (this.state.readyCountDown === 1) {
      clearTimeout(this.readyTimer);
      this.setState({message: Hero2Messages.ChallengeCountDown});
    } else {
      this.setState({readyCountDown: this.state.readyCountDown - 1});
    }
  };

  onEmailChangeTimeout = (currentEmail: string) => {
    if (this.state.email === currentEmail) {
      const {email} = this.state;
      const {getWaiter, changeWaiter} = this.props;
      if (isValidEmail(email)) {
        getWaiter(email).then(res => changeWaiter(res.info));
      }
      this.setState({inputting: false});
    }
  };

  onEarlyAccessStayTimeout = () => {
    this.setState({message: Hero2Messages.PlayGame}, () => {
      this.switchToSculpt();
    });
  };

  onConfirmTimeout = () => {
    this.setState({confirmed: true});
  };

  onFriendEmailChangeTimeout = (index: number, currentEmail: string) => {
    let info = this.state.friendEmails[index] || {email: ''};
    if (info.email === currentEmail) {
      const {getWaiter} = this.props;
      if (isValidEmail(info.email)) {
        this.setState({
          friendEmails: {
            ...this.state.friendEmails,
            [index]: {
              ...this.state.friendEmails[index],
              exist: undefined
            }
          }
        });

        getWaiter(info.email).then(res => {
          let waiter = res.info;
          this.setState({
            friendEmails: {
              ...this.state.friendEmails,
              [index]: {
                ...this.state.friendEmails[index],
                exist: !!waiter
              }
            }
          });
        });
      }

      this.setState({
        friendEmails: {
          ...this.state.friendEmails,
          [index]: {
            ...this.state.friendEmails[index],
            inputting: false
          }
        }
      });
    }
  };

  getProject = (props?: IHero2Props) => {
    const projectById = (props || this.props).entityState.byId;
    return projectById[HERO_PROJECT_ID];
  };

  setPointerEvents = (event: string = "auto") => {
    document.querySelectorAll(this.elementsOnViewerSelector)
      .forEach(element => {
        let divElem = element as HTMLDivElement;
        if (divElem.style)
          divElem.style.pointerEvents = event;
      });
  };

  addAction = (action: string) => {
    const {waiter, addWaiterAction, postWaiterAction} = this.props;
    if (waiter && !waiter.actions[action] && !postWaiterAction.posting) {
      addWaiterAction(waiter.email, action).then(res => {
        if (res.success && res.info && res.info.point) {
          this.playPointAnimation(res.info.point);
        }
      });
    }
  };

  shuffleMaterial = (material: IDigitalMaterial) => {
    return {
      ...material,
      pbr: {
        ...material.pbr,
        color: getRandomColor(),
        sheen: getRandomColor()
      }
    };
  };

  shuffleEyeMaterial = (calcId: string) => {
    const project = this.getProject();
    const {editorWrapper, changeDigitalMaterials} = this.props;
    let dmById = store.getState().entities.digitalMaterials.byId;
    let materials = [];

    if (project) {
      let dmId = project.scn.calcs[calcId].material.id;
      if (dmId) {
        materials.push(this.shuffleMaterial(dmById[dmId]));
        changeDigitalMaterials(project.id, editorWrapper, materials);
      }
    }
  };

  updateState = (config: ISculptConfig) => {
    this.setState({config});
  };

  shuffleSculptMaterial = () => {
    const {editorWrapper} = this.props;

    if (editorWrapper.editor && editorWrapper.editor.selection.meshes.length > 0) {
      let material = editorWrapper.editor.selection.meshes[0].material as MeshPhysicalMaterial;
      material.color = new Color(getRandomColor());
      material.sheen = new Color(getRandomColor());
      material.metalness = Math.random();
      material.roughness = Math.random();
      material.clearcoat = Math.random();
      material.clearcoatRoughness = Math.random();
      material.needsUpdate = true;
      editorWrapper.editor.setNeedsUpdate();

      this.addAction('shuffle');
      return true;
    }
  };

  startRecording = () => {
    const {editorWrapper} = this.props;
    const {mediaRecordSupport} = this.state;

    if (mediaRecordSupport && editorWrapper.editor) {
      let canvas = editorWrapper.editor.renderer.domElement;

      //@ts-ignore
      let videoStream = canvas.captureStream(30);

      this.mediaRecorder = new MediaRecorder(videoStream, {mimeType: 'video/webm;codecs=h264'});

      this.chunks = [];
      this.mediaRecorder.ondataavailable = (e: any) => {
        this.chunks.push(e.data);
      };

      let recordingStart = 0;
      this.mediaRecorder.onstart = (e: any) => {
        recordingStart = performance.now();
      }

      this.mediaRecorder.onstop = (e: any) => {
        this.recordingLength = performance.now() - recordingStart;
        let blob = new Blob(this.chunks, {'type': 'video/webm'});

        this.chunks = [];
        const urlCreator = window.URL || (window as any).webkitURL;
        let videoURL = urlCreator.createObjectURL(blob);
        if (this.videoReplayElem) {
          this.videoReplayElem.src = videoURL;
        }
      };

      this.mediaRecorder.start();
    }
  };

  stopRecording = () => {
    const {mediaRecordSupport} = this.state;

    if (mediaRecordSupport && this.mediaRecorder) {
      this.mediaRecorder.stop();
      this.mediaRecorder = undefined;
    }
  };

  saveModel = () => {
    const {editorWrapper} = this.props;

    if (editorWrapper.editor && editorWrapper.editor.sculptControl.sMeshes.length > 0) {
      let sMesh = editorWrapper.editor.sculptControl.sMeshes[0];
      let meshData = getGeometryFromSculptMesh(sMesh);

      encodeGeometry(meshData, MeshCodec.Ply).then(res => {
        this.modelData = res;
      });
    }
  }

  switchToSculpt = async () => {
    const {editorWrapper, hideOtherCalcsAndSculpt} = this.props;
    const project = this.getProject();

    if (project) {
      if (project.scn.tool !== Tools.Sculpt) {
        for (let calcId of project.scn.calcIds) {
          let calc = project.scn.calcs[calcId];
          if (calc.title === 'eye union')
            await hideOtherCalcsAndSculpt(project.id, editorWrapper, [calc.id]);
        }

        // editorWrapper.editor && editorWrapper.editor.repositionCamera('hero-sculpt-zoom');
      }
    }
  };

  resetSculpt = async () => {
    const {editorWrapper} = this.props;
    const project = this.getProject();

    if (project && project.scn.tool === Tools.Sculpt) {
      if (editorWrapper.editor) {
        editorWrapper.editor._cancelTransformation();
        editorWrapper.editor._startTransformation();
      }
    }
  };

  playPointAnimation = (point: number) => {
    let count = Math.round(point / this.config.unitPoint);
    let targetCount = this.state.targetAnimationCount + count;
    this.setState({targetAnimationCount: targetCount});
    let animationTimer = setInterval(() => {
      if (this.state.targetAnimationCount <= this.state.pointAnimationCount) {
        setTimeout(() => {
          clearTimeout(animationTimer);
          this.setState({targetAnimationCount: 0, pointAnimationCount: 0});
        }, this.config.pointAnimationDuration - this.config.pointAnimationInterval);
        return;
      }
      this.setState({pointAnimationCount: this.state.pointAnimationCount + 1});
    }, this.config.pointAnimationInterval);
  };

  onRadiusChange = ({value, commit}: ISliderValue) => {
    const {editorWrapper} = this.props;
    if (editorWrapper.editor && commit) {
      editorWrapper.editor.sculptControl.setConfig({
        tool: {
          brush: {radius: value},
          drag: {radius: value},
          smooth: {radius: value}
        }
      });
    }
  };

  onIntensityChange = ({value, commit}: ISliderValue) => {
    const {editorWrapper} = this.props;
    if (editorWrapper.editor && commit) {
      editorWrapper.editor.sculptControl.setConfig({
        tool: {
          brush: {intensity: value},
          smooth: {intensity: value}
        }
      });
    }
  };

  onEditor3dAction = (action: any) => {
    const project = this.getProject();
    const {editorWrapper, undoRedoPosting, redo, undo, page} = this.props;
    const {message, config} = this.state;

    if (project && editorWrapper.editor) {
      switch (action.action) {
        case ACT_SELECT_MODELS:
          if (page === 'main' && action.ids && action.ids.length > 0) {
            let calc = project.scn.calcs[action.ids[0]];
            if (calc && calc.title.includes('eye')) {
              this.onRequestAccess();
              changeInitialPoint(500);
            }
          }
          break;
        case ACT_MOUSE_DOWN:
          this.setPointerEvents("none");
          if (action.buttons === 1) {
            if (message === Hero2Messages.WompPoint)
              this.setState({message: ''});
          }
          break;
        case ACT_MOUSE_UP:
          this.setPointerEvents();
          break;
        case ACT_UNDO:
          if (editorWrapper.editor && editorWrapper.editor.editControlsEnabled) {
            if (!undoRedoPosting)
              undo(project.id, editorWrapper, true);

            this.addAction('undo');
          }
          break;
        case ACT_REDO:
          if (editorWrapper.editor && editorWrapper.editor.editControlsEnabled) {
            if (!undoRedoPosting)
              redo(project.id, editorWrapper, true);

            this.addAction('redo');
          }
          break;
        case ACT_SCULPT_STROKE:
          this.addAction('sculpt');
          break;
        case ACT_KEYDOWN:
          if (editorWrapper.editor && editorWrapper.editor.editControlsEnabled) {
            if (action.code === 'Space') {
              this.shuffleSculptMaterial();
            } else if (action.code === 'Backquote') {
              editorWrapper.editor.sculptControl.setConfig({
                tool: {
                  symmetry: !config.tool.symmetry
                }
              });
            }
          }
          break;
        case ACT_UPDATE_SCULPT_CONFIG:
          this.updateState(action.config);
          break;
      }
    }
  };

  onEditor3dResize = () => {
    this.delegateWrapper.delegate && this.delegateWrapper.delegate.onContainerResize();
  };

  onLogoClick = (evt: any) => {
    this.setState({message: ''}, async () => {
      this.props.goToMain();
    });
  };

  onExpandClick = (evt: any) => {
    this.props.goToMain();
  }

  onEmailChange = (evt: any) => {
    this.setState({email: evt.target.value, inputting: true});
  };

  onEmailKeyDown = (evt: any) => {
    const {postWaiter, waiter, createWaiter} = this.props;
    const {email} = this.state;

    if (evt.key === 'Enter') {
      const disableSubmit = postWaiter.posting || !isValidEmail(email);

      if (!waiter && !disableSubmit) {
        if (isValidEmail(email)) {
          createWaiter(email, this.state.point);
        }
      }
    }
  };

  onCheatCodeChange = (evt: any) => {
    this.setState({cheatCode: evt.target.value});
  };

  onFriendReferringNameChange = (evt: any) => {
    this.setState({friendReferringName: evt.target.value, friendReferringNameInvalid: false});
  };

  onFriendEmailChange = (evt: any) => {
    const index = +evt.target.getAttribute('customdata');
    this.setState({
      friendEmails: {
        ...this.state.friendEmails,
        [index]: {
          email: evt.target.value,
          inputting: true,
          sent: false,
          exist: undefined
        }
      }
    });
  };

  onFriendEmailKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      const index = +evt.target.getAttribute('customdata');
      this.submitFriendEmail(index);
    }
  };

  onFriendEmailBlur = (evt: any) => {
    const index = +evt.target.getAttribute('customdata');
    this.submitFriendEmail(index);
  };

  sendInviteEmail = async (index: number) => {
    const {sendWaiterInviteEmail} = this.props;
    const {friendReferringName, friendEmails} = this.state;
    const info = friendEmails[index] || {email: '', inputting: false, exist: undefined};

    if (isValidEmail(info.email)) {
      if (friendReferringName) {
        await sendWaiterInviteEmail(friendReferringName, info.email);
        return true;
      } else
        this.setState({friendReferringNameInvalid: true});
    }
  };

  sendRemindEmail = (index: number) => {
    const {sendWaiterInviteRemindEmail} = this.props;
    const {friendReferringName, friendEmails} = this.state;
    const info = friendEmails[index] || {email: '', inputting: false, exist: undefined};

    if (isValidEmail(info.email)) {
      if (friendReferringName)
        sendWaiterInviteRemindEmail(friendReferringName, info.email);
      else
        this.setState({friendReferringNameInvalid: true});
    }
  };

  submitFriendEmail = (index: number) => {
    const {waiter, putWaiter} = this.props;
    const {friendEmails} = this.state;

    if (!waiter)
      return;

    const info = friendEmails[index] || {email: '', inputting: false, exist: undefined};
    const friendInfo = (waiter.friendInfo && waiter.friendInfo[index]) ? waiter.friendInfo[index] : {
      email: '',
      sent: false
    };

    if (info.email !== friendInfo.email || !!info.sent !== !!friendInfo.sent) {
      if (info.email) {
        putWaiter(waiter.id, {
          friends: {
            ...waiter.friends,
            [index]: {
              email: info.email,
              sent: !!info.sent,
              bonus: 0
            }
          }
        });
      } else {
        putWaiter(waiter.id, {
          friends: lod.omit(waiter.friends, index)
        });
      }
    }
  };

  onSendReminderClick = (evt: any) => {
    const index = +evt.target.getAttribute('customdata');
    this.sendRemindEmail(index);
  };

  onSendClick = async (evt: any) => {
    const index = +evt.target.getAttribute('customdata');

    let success = await this.sendInviteEmail(index);

    if (success && this.state.friendEmails[index]) {
      this.setState({
        friendEmails: {
          ...this.state.friendEmails,
          [index]: {
            ...this.state.friendEmails[index],
            sent: true
          }
        }
      }, () => {
        this.submitFriendEmail(index);
      });
    }
  };

  onVideoReplayClick = (evt: any) => {
    const {videoReplayPlaying, videoReplayDownloading} = this.state;
    if (!videoReplayDownloading) {
      if (videoReplayPlaying)
        this.videoReplayElem.pause();
      else
        this.videoReplayElem.play();
    }
  };

  onVideoReplayDownloadClick = async (evt: any) => {
    evt.stopPropagation();
    this.setState({videoReplayDownloading: true, videoReplayDownloadPercentage: 0});

    let r = await fetch(this.videoReplayElem.src);
    const content = new Uint8Array(await r.arrayBuffer());

    if (this.state.ffmpegReady) {
      this.worker.postMessage({
        type: "run",
        MEMFS: [{name: "test.webm", data: content}],
        arguments: ['-i', 'test.webm', '-vcodec', 'libx264', '-vf', 'scale=640:trunc(ow/a/2)*2', '-acodec', 'aac', '-vb', '1024k', '-minrate', '1024k', '-maxrate', '1024k', '-bufsize', '1024k', '-ar', '44100', '-strict', 'experimental', '-r', '30', 'output.mp4']
      });
    } else {
      this.setState({videoReplayDownloading: false});
      let link = document.createElement('a');
      link.download = 'record.webm';
      link.href = this.videoReplayElem.src;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  onTutorialVideoClick = (evt: any) => {
    const {tutorialVideoPlaying} = this.state;
    if (tutorialVideoPlaying)
      this.tutorialVideoElem.pause();
    else
      this.tutorialVideoElem.play();
  };

  onTutorialVideoDownloadClick = (evt: any) => {
    evt.stopPropagation();

    let link = document.createElement('a');
    link.download = 'tutorial.mp4';
    link.href = this.tutorialVideoElem.src;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  onWompPointClick = (evt: any) => {
    if (this.state.message === '') {
      this.setState({message: Hero2Messages.WompPoint});
    }
  };

  onPlayChallengeClick = (evt: any) => {
    const {waiter} = this.props;
    const {mediaRecordSupport} = this.state;
    if (mediaRecordSupport) {
      if (waiter) {
        this.setState({message: Hero2Messages.GameRule});
      } else
        this.setState({message: Hero2Messages.AddEmail});
    }
  };

  onTellUsMoreClick = (evt: any) => {
    let {waiter} = this.props;
    if (waiter) {
      this.setState({message: Hero2Messages.TellMore});
    } else {
      this.setState({message: Hero2Messages.AddEmail});
    }
  };

  onTutorialClick = (evt: any) => {
    this.setState({message: Hero2Messages.Tutorial});
  };

  onReferFriendClick = (evt: any) => {
    let {waiter} = this.props;
    if (waiter) {
      this.setState({message: Hero2Messages.ReferFriend});
    } else {
      this.setState({message: Hero2Messages.AddEmail});
    }
  };

  onRecordShareClick = (evt: any) => {
    let {waiter} = this.props;
    if (waiter) {
      this.setState({message: Hero2Messages.RecordShare});
    } else {
      this.setState({message: Hero2Messages.AddEmail});
    }
  };

  onPlayGameYesClick = (evt: any) => {
    let {waiter} = this.props;
    if (waiter) {
      this.setState({message: Hero2Messages.GameRule});
    } else {
      this.setState({message: Hero2Messages.AddEmail});
    }
  };

  onPlayGameNoClick = (evt: any) => {
    this.setState({message: Hero2Messages.DontPlay});
  };

  onPlayGameNeedPracticeClick = (evt: any) => {
    this.setState({message: Hero2Messages.NeedPractice});
  };

  onPlayGameBackClick = (evt: any) => {
    this.setState({message: Hero2Messages.EarlyAccess});
  };

  onGameRuleBackClick = (evt: any) => {
    this.setState({message: Hero2Messages.PlayGame});
  };

  onGameRuleStartClick = (evt: any) => {
    this.setState({message: Hero2Messages.ReadyCountDown});
  };

  onChallengeCountDownDoneClick = (evt: any) => {
    clearTimeout(this.challengeTimer);
    this.stopRecording();
    this.saveModel();
    this.setState({message: Hero2Messages.ChallengeFinish, recording: false});
  };

  onRecordShareBackClick = (evt: any) => {
    this.stopRecording();
    this.saveModel();
    if (this.videoReplayElem)
      this.videoReplayElem.pause();
    this.setState({message: Hero2Messages.EarlyAccess, recording: false});
  };

  onTutorialBackClick = (evt: any) => {
    if (this.tutorialVideoElem)
      this.tutorialVideoElem.pause();
    this.setState({message: Hero2Messages.EarlyAccess});
  };

  onSubmitToWompClick = async (evt: any) => {
    const {postWaiterVideo, waiter, postWaiter} = this.props;
    const {recordingCaption} = this.state;
    if (waiter && recordingCaption && !postWaiter.posting) {
      let res = await fetch(this.videoReplayElem.src);
      let arrayBuffer = await res.arrayBuffer();
      postWaiterVideo(waiter.email, recordingCaption, encodeBuffer(arrayBuffer), this.modelData).then(res => {
        this.setState({message: Hero2Messages.Submitted, recordingCaption: ''});
        if (res.success) {
          this.playPointAnimation(800);
        }
      });
    }
  };

  onChallengeTryAgainClick = (evt: any) => {
    this.setState({message: Hero2Messages.TryAgain});
  };

  onTryAgainClick = (evt: any) => {
    this.setState({message: Hero2Messages.ReadyCountDown});
    this.resetSculpt();
  };

  onGetMoreClick = (evt: any) => {
    this.setState({message: Hero2Messages.EarlyAccess});
  };

  onTellMoreBackClick = (evt: any) => {
    let index = TellMoreMessages.indexOf(this.state.message);
    if (index === 0)
      this.setState({message: Hero2Messages.EarlyAccess});
    else
      this.setState({message: TellMoreMessages[index - 1]});
  };

  onTellMoreNextClick = async (evt: any) => {
    const {message, detailFields} = this.state;
    const {waiter, putWaiter} = this.props;
    let index = TellMoreMessages.indexOf(message);
    let field = this.tellMoreInfos[message].field;

    if (!index || detailFields[field]) {
      if (index === TellMoreMessages.length - 1) {
        if (waiter) {
          await putWaiter(waiter.id, {info: detailFields});

          this.setState({message: Hero2Messages.TellMoreSubmit}, () => {
            this.addAction('tellMore');
          });
        }
      } else {
        this.setState({message: TellMoreMessages[index + 1]});
      }
    }
  };

  onReferFriendBackClick = (evt: any) => {
    this.setState({message: Hero2Messages.EarlyAccess});
  };

  onTellMoreFieldChange = (evt: any) => {
    const field = evt.target.getAttribute('customdata');
    this.setState({
      detailFields: {
        ...this.state.detailFields,
        [field]: evt.target.value
      }
    });
  };

  onTellMoreFieldKeyDown = (evt: any) => {
    if (evt.key === 'Enter') {
      this.onTellMoreNextClick(evt);
    }
  };

  onRecordingCaptionChange = (evt: any) => {
    this.setState({recordingCaption: evt.target.value});
  };

  onAboutClick = (evt: any) => {
    this.setState({message: Hero2Messages.About});
  };

  onRecordClick = (evt: any) => {
    const {recording, message} = this.state;
    const {mediaRecordSupport} = this.state;
    if (mediaRecordSupport) {
      if (message === Hero2Messages.RecordShare) {
        if (recording) {
          this.stopRecording();
          this.setState({recording: false, showVideo: true});
        } else {
          this.startRecording();
          this.setState({recording: true, showVideo: false});
        }
      }
    }
  };

  onScreenshotClick = (evt: any) => {
    const {editorWrapper} = this.props;
    editorWrapper.editor && editorWrapper.editor.saveAsImage();
  };

  onRedoClick = (evt: any) => {
    const {editorWrapper, undoRedoPosting, redo} = this.props;
    const project = this.getProject();

    if (project && editorWrapper.editor && editorWrapper.editor.editControlsEnabled) {
      if (!undoRedoPosting)
        redo(project.id, editorWrapper, true);

      this.addAction('redo');
    }
  };

  onUndoClick = (evt: any) => {
    const {editorWrapper, undoRedoPosting, undo} = this.props;
    const project = this.getProject();

    if (project && editorWrapper.editor && editorWrapper.editor.editControlsEnabled) {
      if (!undoRedoPosting)
        undo(project.id, editorWrapper, true);

      this.addAction('undo');
    }
  };

  onShuffleClick = (evt: any) => {
    this.shuffleSculptMaterial();
  };

  onSmoothClick = (evt: any) => {
    const {editorWrapper} = this.props;
    const {config} = this.state;

    const toolInfo = SCULPT_TOOL_INFOS[config.tool.sculptTool];
    let smooth = toolInfo && toolInfo.id === 'smooth';

    if (editorWrapper.editor) {
      editorWrapper.editor.sculptControl.setConfig({
        tool: {
          sculptTool: smooth ? 0 : 3
        }
      });
    }
  };

  onDragClick = (evt: any) => {
    const {editorWrapper} = this.props;
    const {config} = this.state;

    const toolInfo = SCULPT_TOOL_INFOS[config.tool.sculptTool];
    let drag = toolInfo && toolInfo.id === 'drag';

    if (editorWrapper.editor) {
      editorWrapper.editor.sculptControl.setConfig({
        tool: {
          sculptTool: drag ? 0 : 7
        }
      });
    }
  };

  onNegativeClick = (evt: any) => {
    const {editorWrapper} = this.props;
    const {config} = this.state;

    const toolInfo = SCULPT_TOOL_INFOS[config.tool.sculptTool];
    let negative = toolInfo && toolInfo.id === 'brush' && config.tool.brush.negative;

    if (editorWrapper.editor) {
      editorWrapper.editor.sculptControl.setConfig({
        tool: {
          sculptTool: 0,
          brush: {
            negative: !negative
          }
        }
      });
    }
  };

  onPlusClick = (evt: any) => {
    const {editorWrapper} = this.props;

    if (editorWrapper.editor) {
      editorWrapper.editor.sculptControl.multiplyToolRadius(6.0 / 5);
    }
  };

  onMinusClick = (evt: any) => {
    const {editorWrapper} = this.props;

    if (editorWrapper.editor) {
      editorWrapper.editor.sculptControl.multiplyToolRadius(5.0 / 6);
    }
  };

  onRefreshClick = (evt: any) => {
    this.resetSculpt();
  };

  renderPoint = (icon?: string) => {
    return <img className="hero-point-icon" src={icon || iconPoint} alt='point'/>;
  };

  renderSNSIcons = () => {
    return (
      <div className="sns-icons">
        <a className="mh5" href="https://twitter.com" target="_blank">
          <img src={iconTwitter} alt='twitter'/>
        </a>
        <a className="mh5" href="https://instagram.com" target="_blank">
          <img src={iconInstagram} alt='instagram'/>
        </a>
        <a className="mh5" href="https://tiktok.com" target="_blank">
          <img src={iconTiktok} alt='tiktok'/>
        </a>
        <a className="mh5" href="https://youtube.com" target="_blank">
          <img src={iconYoutube} alt='youtube'/>
        </a>
      </div>
    );
  };

  renderPointBracket = (content: any) => {
    return <React.Fragment>
      {this.renderPoint()}
      <span className="semi-bold">{content}</span>
      {this.renderPoint()}
    </React.Fragment>;
  };

  renderPlayChallengeButton = (text?: string) => {
    const {mediaRecordSupport} = this.state;

    if (mediaRecordSupport) {
      return (
        <Tooltip tooltipClassName='hero-tooltip' placement='top' arrow text={<p>
          {this.renderPointBracket('  800+  ')}
        </p>
        }>
          <button className="hero-button" onClick={this.onPlayChallengeClick}>
            {text || '🏆  play challenge'}
          </button>
        </Tooltip>
      );
    }
  };

  renderRecordButton = () => {
    return (
      <Tooltip tooltipClassName='hero-tooltip' placement='top' arrow text={
        <p>
          {this.renderPointBracket('  800  ')}/ea
        </p>
      }>
        <button className="hero-button" onClick={this.onRecordShareClick}>
          📹&nbsp;&nbsp;record & share
        </button>
      </Tooltip>
    );
  };

  renderReferFriendButton = () => {
    return (
      <Tooltip tooltipClassName='hero-tooltip' placement='top' arrow text={
        <p>
          {this.renderPointBracket('  1200  ')}/ea
        </p>
      }>
        <button className="hero-button" onClick={this.onReferFriendClick}>
          📤️&nbsp;&nbsp;refer friends
        </button>
      </Tooltip>
    );
  };

  renderTellUsMoreButton = (text?: string) => {
    return (
      <Tooltip tooltipClassName='hero-tooltip' placement='top' arrow text={
        <p>
          {this.renderPointBracket('  1500  ')}
        </p>
      }>
        <button className="hero-button" onClick={this.onTellUsMoreClick}>
          {text || '👽  tell us more'}
        </button>
      </Tooltip>
    );
  };

  renderTutorialButton = () => {
    return (
      <Tooltip tooltipClassName='hero-tooltip' placement='top' arrow text={
        <p>
          🧠&nbsp;&nbsp;tips&nbsp;🧠&nbsp;
        </p>
      }>
        <button className="hero-button" onClick={this.onTutorialClick}>
          💡&nbsp;&nbsp;tutorial
        </button>
      </Tooltip>
    );
  };

  renderActionButtons = () => {
    const {waiter} = this.props;

    return (
      <React.Fragment>
        <div className="flex justify-center mb4">
          {this.renderPlayChallengeButton()}
          {this.renderRecordButton()}
          {this.renderReferFriendButton()}
        </div>
        <div className="flex justify-center">
          {!(waiter && Object.keys(waiter.info).length > 0) && this.renderTellUsMoreButton()}
          {this.renderTutorialButton()}
        </div>
      </React.Fragment>
    );
  };

  renderMessage = () => {
    const {message, readyCountDown, challengeCountDown, recording, recordingCaption, detailFields, showVideo, mediaRecordSupport} = this.state;
    const {waiter, postWaiter} = this.props;

    if (message === Hero2Messages.WompPoint) {
      return (
        <div className="f-2 mb5">
          <p className="f-1 semi-bold tc mb5 lh-3">
            {this.renderPointBracket('  womp points  ')}
          </p>
          <p className="mb5 lh-3">accumulate, climb the waitlist, unlock features & win 3D prints.<br/>
            There are many ways to get womp points:
          </p>
          {this.renderActionButtons()}
        </div>
      );
    } else if (message === Hero2Messages.AddEmail) {
      return (
        <div className="f-2 mb5">
          <p className="lh-3">
            great! make sure to <span className="semi-bold">add your email</span> 👇&nbsp; so we can<br/>
            keep track of your points and place on waitlist
          </p>
        </div>
      );
    } else if (message === Hero2Messages.PlayGame) {
      return (
        <div className="f-2 mb5">
          <p className="f-1 semi-bold tc mb5 lh-3">
            Want to play a game for
            {this.renderPointBracket('800+')}
            points?
          </p>
          <div className="flex justify-center mb4">
            <button className="hero-button" onClick={this.onPlayGameYesClick}>
              🔥&nbsp; yes 🔥&nbsp;
            </button>
            <button className="hero-button" onClick={this.onPlayGameNoClick}>
              🙀&nbsp; no - I’m scared
            </button>
            <button className="hero-button" onClick={this.onPlayGameNeedPracticeClick}>
              🥉&nbsp; need to practice!
            </button>
          </div>
          <div className="flex justify-center">
            <button className="hero-button" onClick={this.onPlayGameBackClick}>
              <img className="mr2" src={iconBack} alt='back'/>
              back
            </button>
          </div>
        </div>
      );
    } else if (message === Hero2Messages.GameRule) {
      return (
        <div className="f-2 mb5">
          <p className="f-1 semi-bold lh-3 tc mb5">
            you have 1 minute to sculpt this eye into a dog 🐶
          </p>
          <div className="flex justify-center">
            <button className="hero-button" onClick={this.onGameRuleBackClick}>
              <img className="mr2" src={iconBack} alt='back'/>
              back
            </button>
            <button className="hero-button" onClick={this.onGameRuleStartClick}>
              start
              <img className="ml2" src={iconNext} alt='next'/>
            </button>
          </div>
        </div>
      );
    } else if (message === Hero2Messages.NeedPractice) {
      return (
        <div className="f-2 mb5">
          <p className="f-1 semi-bold lh-3 tc mb5">have at it ☝️&nbsp;</p>
          <div className="flex justify-center">
            {this.renderPlayChallengeButton('🏆  ok let’s do it')}
            {this.renderTutorialButton()}
          </div>
        </div>
      );
    } else if (message === Hero2Messages.DontPlay) {
      return (
        <div className="f-2 mb5">
          <p className="tc mb5 lh-3">You can still explore and get points {this.renderPoint()}</p>
          {this.renderActionButtons()}
        </div>
      );
    } else if (message === Hero2Messages.ReadyCountDown) {
      return (
        <div className="f-1 semi-bold mb8 flex items-center">
          <p className="mr3">starts in </p>
          <p className="count-down-value">{readyCountDown}</p>
        </div>
      );
    } else if (message === Hero2Messages.ChallengeCountDown) {
      return (
        <div className="f-1 semi-bold mb8">
          <p className="tc mb4">{challengeCountDown} secs left</p>
          <ProgressBar className="count-down-progress-bar mb4"
                       percentage={100 - challengeCountDown * 100 / this.config.challengeDuration}/>
          <div className="flex justify-center">
            <button className="hero-button" onClick={this.onChallengeCountDownDoneClick}>
              done
            </button>
          </div>
        </div>
      );
    } else if (message === Hero2Messages.ChallengeFinish) {
      return (
        <div className="f-2 mb5">
          <div className="flex justify-center mb5">
            <input
              className='hero-input tc pointer-auto'
              value={recordingCaption}
              placeholder='caption here'
              onChange={this.onRecordingCaptionChange}
            />
          </div>
          <p className="tc mb5 lh-3">
            post & tag @wompxyz w/ #{waiter ? this.config.userIdFrom + waiter.id : this.config.userIdFrom}<br/>
            for {this.renderPointBracket('2500')}
          </p>
          {this.renderSNSIcons()}
          <div className="flex justify-center">
            <button className="hero-button relative" onClick={this.onSubmitToWompClick}>
              submit to womp 💌&nbsp;
              <div className="absolute absolute--fill ma-auto pointer-none flex items-center justify-center">
                <MoonLoader
                  size={20}
                  color='#593EFF'
                  loading={postWaiter.posting}
                />
              </div>
            </button>
            <button className="hero-button" onClick={this.onChallengeTryAgainClick}>
              wait let me try again! 💩&nbsp;
            </button>
          </div>
        </div>
      );
    } else if (message === Hero2Messages.TryAgain) {
      return (
        <div className="f-2 mb5">
          <p className="fw5 tc mb5 lh-3">
            before you start again, share & tag @wompwomp<br/>
            with #wompwomp #tryagain for
            {this.renderPointBracket('500')}
          </p>
          {this.renderSNSIcons()}
          <div className="flex justify-center">
            <button className="hero-button" onClick={this.onTryAgainClick}>
              let me try again
            </button>
          </div>
        </div>
      );
    } else if (message === Hero2Messages.Submitted) {
      return (
        <div className="f-2 mb5">
          <p className="f-1 semi-bold tc mb5 lh-3">
            success! submitted for review
            {this.renderPoint(iconHeroBadgeGreen)}
          </p>
          <p className="tc fw5 mb5 lh-3">
            keep an eye out for womp updates & emails! 💛&nbsp;
          </p>
          <p className="tc fw5 mb5 lh-3">
            post & tag @wompxyz w/ #{waiter ? this.config.userIdFrom + waiter.id : this.config.userIdFrom}<br/>
            for {this.renderPointBracket('2500')}
          </p>
          {this.renderSNSIcons()}
          <div className="flex justify-center">
            <button className="hero-button" onClick={this.onGetMoreClick}>
              get more {this.renderPoint()}
            </button>
          </div>
        </div>
      );
    } else if (message === Hero2Messages.RecordShare) {
      return (
        <div className="f-2 mb5">
          {!showVideo &&
          <div className="flex justify-center mb5 record-controls">
            <Tooltip text='screenshot' tooltipClassName='hero-tooltip' placement='top' arrow>
              <div className="action-button mr6" onClick={this.onScreenshotClick}>
                <img src={iconScreenshot} alt='screenshot'/>
              </div>
            </Tooltip>
            {mediaRecordSupport &&
            <Tooltip
              text={recording ? 'end recording' : 'record'}
              tooltipClassName='hero-tooltip'
              placement='top'
              arrow
            >
              <div className={"action-button" + (recording ? " record-active" : "")} onClick={this.onRecordClick}>
                <img src={recording ? iconRecordActive : iconRecord} alt='record'/>
              </div>
            </Tooltip>
            }
          </div>
          }
          <p className="semi-bold tc mb5 lh-3">
            share and tag @wompxyz for 800 {this.renderPoint()}<br/>
            (don’t forget to include #{waiter ? this.config.userIdFrom + waiter.id : this.config.userIdFrom})
          </p>
          {this.renderSNSIcons()}
          <div className="flex justify-center">
            <button className="hero-button" onClick={this.onRecordShareBackClick}>
              <img className="mr2" src={iconBack} alt='back'/>
              back
            </button>
          </div>
        </div>
      );
    } else if (message === Hero2Messages.Tutorial) {
      return (
        <div className="f-2 mb5">
          <p className="f-1 semi-bold tc mb5 lh-3">
            💡&nbsp;&nbsp;quick tips 💡&nbsp;
          </p>
          <div className="flex justify-center">
            <button className="hero-button" onClick={this.onTutorialBackClick}>
              <img className="mr2" src={iconBack} alt='back'/>
              back
            </button>
          </div>
        </div>
      );
    } else if (message === Hero2Messages.EarlyAccess) {
      return (
        <div className="f-2 mb5">
          <p className="tc mb2 lh-3">
            this is only a demo!
          </p>
          <p className="f-1 semi-bold tc mb5 lh-3">
            womp early access
          </p>
          <p className="fw5 tc mb5 lh-3">
            having fun? explore below to accumulate points {this.renderPoint()}, unlock <br/>
            features 🔑️&nbsp; & climb the waitlist 🦄&nbsp; and access the full womp tool
          </p>
          {this.renderActionButtons()}
        </div>
      );
    } else if (message === Hero2Messages.About) {
      return (
        <div className="f-2 mb5">
          <p className="f-1 semi-bold tc mb5 lh-3">
            Womp was started by a group of artists & engineers who<br/>
            agreed it’s time we all 3D.
          </p>
          <p className="f-2 mb5 tc lh-3">
            💌&nbsp; drop us a note:&nbsp;&nbsp;
            <a className="pointer-auto no-underline black" href="mailto:info@womp.xyz">info@womp.xyz</a>
          </p>
          <div className="flex justify-center">
            <button className="hero-button" onClick={this.onGetMoreClick}>
              get more {this.renderPoint()}
            </button>
          </div>
        </div>
      );
    } else if (TellMoreMessages.includes(message)) {
      let index = TellMoreMessages.indexOf(message);
      const {field, question, placeholder} = this.tellMoreInfos[message];
      return (
        <div className="f-2 mb5">
          <p className="tc mb5 lh-3">
            👽&nbsp; tell us more {index ? `${index}/${TellMoreMessages.length - 1}` : ''}
          </p>
          {index ?
            <p className="f-1 semi-bold tc mb5 lh-3">
              {question}
            </p> :
            <p className="f-1 semi-bold tc mb5 lh-3">
              answer some questions for 1500 {this.renderPoint()}<br/>
              really good answers get +200 {this.renderPoint()}
            </p>
          }
          {!!index &&
          <div className='mb5 flex justify-center'>
            <input
              key={field}
              className='tell-more-input pointer-auto'
              customdata={field}
              placeholder={placeholder}
              value={detailFields[field]}
              onChange={this.onTellMoreFieldChange}
              onKeyDown={this.onTellMoreFieldKeyDown}
              ref={ref => this.tellMoreFieldInputElem = ref}
            />
          </div>
          }
          <div className="flex justify-center">
            <button className="hero-button" onClick={this.onTellMoreBackClick}>
              <img className="mr2" src={iconBack} alt='back'/>
              back
            </button>
            <button className="hero-button" onClick={this.onTellMoreNextClick}>
              {index ? 'next' : 'let’s do it'}
              <img className="ml2" src={iconNext} alt='next'/>
            </button>
          </div>
        </div>
      );
    } else if (message === Hero2Messages.TellMoreSubmit) {
      return (
        <div className="f-2 mb5">
          <p className="f-1 semi-bold tc mb5 lh-3">
            success! submitted to womp
            {this.renderPoint(iconHeroBadgeGreen)}
          </p>
          <p className="tc fw5 mb5 lh-3">
            keep an eye out for womp updates & emails! 💛&nbsp;
          </p>
          <div className="flex justify-center">
            <button className="hero-button" onClick={this.onGetMoreClick}>
              get more {this.renderPoint()}
            </button>
          </div>
        </div>
      );
    } else if (message === Hero2Messages.ReferFriend) {
      return (
        <div className="f-2 mb5">
          <div className="flex justify-center">
            <button className="hero-button" onClick={this.onReferFriendBackClick}>
              <img className="mr2" src={iconBack} alt='back'/>
              back
            </button>
          </div>
        </div>
      );
    }
  };

  renderReferFriendForm = () => {
    const {waiter, fetchWaiter} = this.props;
    const {friendEmails, friendReferringName, friendReferringNameInvalid} = this.state;
    let emailInputElems = [];

    if (waiter) {
      let friendCnt = Math.max(-1, ...Object.keys(friendEmails).map(Number), ...Object.keys(waiter.friendInfo || {}).map(Number));

      for (let i = 0; i < friendCnt + 2; ++i) {
        let friendInfo = (waiter.friendInfo || {})[i] || {email: '', bonus: 0};
        let info = friendEmails[i] || {email: '', inputting: false, exist: undefined};
        const submitting = info.email && (fetchWaiter.fetching || info.inputting);
        const disableSubmit = submitting || !isValidEmail(info.email) || info.exist !== false || !!friendInfo.bonus;
        const preventChange = !!friendInfo.bonus || !!friendInfo.sent;

        emailInputElems.push(
          <div key={i} className="friend-email-input-container mb5">
            <Tooltip
              text={submitting ? "finding..." : (!!friendInfo.bonus || info.exist ? 'already joined' : "")}
              tooltipClassName='hero-tooltip'
              placement='bottom'
              arrow
            >
              <button className="badge-button">
                <img
                  src={iconHeroBadge}
                  className={submitting ? "disabled" : (!!friendInfo.bonus || info.exist ? "" : "dn")}
                />
              </button>
            </Tooltip>
            <input
              className='friend-email-input pointer-auto'
              customdata={'' + i}
              value={info.email}
              placeholder={`email #${i + 1}`}
              onChange={preventChange ? nothing : this.onFriendEmailChange}
              onKeyDown={preventChange ? nothing : this.onFriendEmailKeyDown}
              onBlur={preventChange ? nothing : this.onFriendEmailBlur}
            />
            {!disableSubmit &&
            (!!friendInfo.sent ?
                <Tooltip text='send reminder' tooltipClassName='hero-tooltip' placement='bottom' arrow>
                  <button customdata={'' + i} className="send-reminder-button" onClick={this.onSendReminderClick}>
                    🔔
                  </button>
                </Tooltip> :
                <Tooltip text='send' tooltipClassName='hero-tooltip' placement='bottom' arrow>
                  <button customdata={'' + i} className="send-button" onClick={this.onSendClick}>
                    <img src={iconSend} alt='send'/>
                  </button>
                </Tooltip>
            )
            }
            {!!friendInfo.bonus &&
            <div className="bonus">
              {this.renderPointBracket('' + friendInfo.bonus)}
            </div>
            }
          </div>
        );
      }
    }

    return (
      <div className="refer-friend-form">
        <p className="f-1 mb5 semi-bold">refer a friend!</p>
        <div className="flex justify-center mb8">
          <input
            className={'hero-input tc pointer-auto' + (friendReferringNameInvalid ? " error" : "")}
            value={friendReferringName}
            placeholder='your name here'
            onChange={this.onFriendReferringNameChange}
          />
        </div>
        <p className="tc f8-l f8-m f10-ns mb10-l mb10-m mb5-ns ph5 lh-3">
          hey there!<br/>
          {friendReferringName || '“your name”'} thinks you should check out Womp: a collaborative 3D platform for
          creatives. sign up here to join the waitlist!
        </p>
        <ScrollPanel
          noScrollX
          style={{
            flexGrow: 1,
            width: '80%'
          }}
        >
          {emailInputElems}
        </ScrollPanel>
      </div>
    );
  };

  renderPointAnimation = () => {
    let animationElems = [];
    const {pointAnimationCount} = this.state;

    for (let i = 0; i < pointAnimationCount; ++i) {
      animationElems.push(
        <div key={i} className="flex hero-point-animation">
          {this.renderPoint()}
          <p className="ml2">{this.config.unitPoint}</p>
        </div>
      );
    }
    return (
      <div className="absolute absolute--fill pointer-none z-2">
        {animationElems}
      </div>
    );
  };

  renderProductHunt = () => {
    const {point} = this.state;
    const {waiter, page, screenSize} = this.props;

    const wompPoint = waiter ? (waiter.point + waiter.additionalPoint) : point;

    return <Animated animationIn="bounceInRight" animationOut="bounceOutRight" isVisible={page === 'hero'}>
      {screenSize.width > 480 || !wompPoint ?
        <a
          className="product-hunt"
          href="https://www.producthunt.com/posts/womp?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-womp"
          target="_blank"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=282316&theme=light"
            alt="Womp - Webtool for easy and collaborative 3D design | Product Hunt"
          />
        </a> :
        <div className="product-hunt-small">
          <a target="_blank" href="https://www.producthunt.com/posts/womp">
            <p>follow us</p>
            <img src={iconProductHunt} alt={'product hunt'}/>
          </a>
        </div>
      }
    </Animated>;
  };

  renderUserPoint = () => {
    const {point, message} = this.state;
    const {waiter, page} = this.props;

    const wompPoint = waiter ? (waiter.point + waiter.additionalPoint) : point;

    return (
      <Animated
        className="user-points flex items-center pointer-auto mb5-l mb5-m"
        animationIn="bounceInRight" animationOut="bounceOutRight"
        isVisible={page === 'hero'}
      >
        {waiter &&
        <Tooltip text='user ID' tooltipClassName='hero-tooltip' placement='bottom' arrow>
          <p className="f-1 semi-bold mr4 pointer-auto">user: {this.config.userIdFrom + waiter.id}</p>
        </Tooltip>
        }
        {!!wompPoint &&
        <Tooltip text='womp points' tooltipClassName='hero-tooltip' placement='bottom' arrow>
          <div className={"points" + (message ? "" : " pointer")} onClick={this.onWompPointClick}>
            <img className="mr3" src={iconPoint} alt='point'/>
            <p>{numeral(wompPoint).format("000000")}</p>
          </div>
        </Tooltip>
        }
      </Animated>
    );
  };

  renderBlendingEdit = () => {
    const {email, inputting, confirmed, message, cheatCode} = this.state;
    const {postWaiter, fetchWaiter, waiter, page} = this.props;
    const submitting = postWaiter.posting || fetchWaiter.fetching || inputting;

    return (
      <div className="blending-edit">
        <div>
          <div className="flex justify-between w-100 items-center-ns">
            <div className="flex items-start mt3-l mt3-m">
              <img className="womp-logo pointer-auto pointer" src={iconWomp} alt='womp' onClick={this.onLogoClick}/>
              <p className="f-1 semi-bold ml2">womp</p>
            </div>
            <div className="flex flex-column-l flex-column-m items-end-l items-end-m">
              {this.renderUserPoint()}
              {this.renderProductHunt()}
            </div>
          </div>
        </div>
        <Animated animationIn="bounceInUp" animationOut="bounceOutDown" isVisible={page === 'hero'}>
          {!ShowOnlyMessages.includes(message) &&
          <React.Fragment>
            {confirmed ?
              <Tooltip text='👂 follow us @wompxyz 👂 👇' tooltipClassName='hero-tooltip' placement='top' arrow>
                <input
                  className='cheat-code-input pointer-auto'
                  value={cheatCode}
                  placeholder='👁  cheatcodes here  👁'
                  onChange={this.onCheatCodeChange}
                />
              </Tooltip> :
              <Tooltip text='enter email' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div className="email-input-container">
                  <input
                    className='email-input pointer-auto'
                    value={email}
                    placeholder='enter email to join waitlist'
                    onChange={waiter ? nothing : this.onEmailChange}
                    onKeyDown={this.onEmailKeyDown}
                  />
                  <img
                    className={submitting ? "disabled" : (waiter ? "" : "dn")}
                    src={iconHeroBadge}
                  />
                </div>
              </Tooltip>
            }
          </React.Fragment>
          }
        </Animated>
        <Animated className="expand-button" animationIn="bounceInUp" animationOut="bounceOutDown" isVisible={page === 'hero'}>
          <ImageButton image={iconExpand} onClick={this.onExpandClick}>
            <p className="ml2">full site</p>
          </ImageButton>
        </Animated>
      </div>
    );
  };

  renderEdit = () => {
    const {page} = this.props;

    return (
      <div className="edit">
        <div/>
        <div>
          <Animated animationIn="bounceInUp" animationOut="bounceOutDown" isVisible={page === 'hero'}>
            {this.renderMessage()}
          </Animated>
        </div>
      </div>
    );
  };

  renderControls = () => {
    const {message, showVideo, videoReplayPlaying, videoReplayDownloading, videoReplayDownloadPercentage, tutorialVideoPlaying, config, controlEnabled} = this.state;
    const {page, screenSize} = this.props;

    let elements = [];

    if (screenSize.width === 0 || screenSize.width > 640) {
      elements.push(
        <React.Fragment>
          <div
            className={"video-replay" + (showVideo ? "" : " dn")}
            onClick={this.onVideoReplayClick}
          >
            <video ref={ref => {
              this.videoReplayElem = ref;

              if (this.videoReplayElem) {
                this.videoReplayElem.addEventListener("play", () => {
                  this.setState({videoReplayPlaying: true});
                }, false);

                this.videoReplayElem.addEventListener("pause", () => {
                  this.setState({videoReplayPlaying: false});
                }, false);
              }
            }}/>
            {!videoReplayPlaying && !videoReplayDownloading &&
            <img src={iconPlay} alt='play'/>
            }
            {videoReplayDownloading &&
            <div className="download-loader">
              <MoonLoader
                size={50}
                color='#593EFF'
                loading={true}
              />
              <p>Please wait... ({Math.round(videoReplayDownloadPercentage)}%)</p>
            </div>
            }
            {!videoReplayDownloading &&
            <ImageButton className="download-button" image={iconDownload} onClick={this.onVideoReplayDownloadClick}/>
            }
          </div>
          {message === Hero2Messages.Tutorial &&
          <div
            className="tutorial-video"
            onClick={this.onTutorialVideoClick}
          >
            <video
              autoPlay
              poster={imgTutorial}
              ref={ref => {
                this.tutorialVideoElem = ref;

                if (this.tutorialVideoElem) {
                  this.tutorialVideoElem.addEventListener("play", () => {
                    this.setState({tutorialVideoPlaying: true});
                  }, false);

                  this.tutorialVideoElem.addEventListener("pause", () => {
                    this.setState({tutorialVideoPlaying: false});
                  }, false);

                  // this.tutorialVideoElem.addEventListener("ended", () => {
                  //   this.addAction('tutorial');
                  // }, false);
                }
              }}
            >
              <source type="video/mp4" src={vidTutorial}/>
            </video>
            {!tutorialVideoPlaying &&
            <img src={iconPlay} alt='play'/>
            }
            <ImageButton className="download-button" image={iconDownload} onClick={this.onTutorialVideoDownloadClick}/>
          </div>
          }
          {message === Hero2Messages.ReferFriend &&
          this.renderReferFriendForm()
          }
        </React.Fragment>
      );
    }

    const project = this.getProject();
    if (project) {
      const toolInfo = SCULPT_TOOL_INFOS[config.tool.sculptTool];
      let drag = toolInfo && toolInfo.id === 'drag';
      let smooth = toolInfo && toolInfo.id === 'smooth';
      let negative = toolInfo && toolInfo.id === 'brush' && config.tool.brush.negative;

      elements.push(
        <Animated
          className="controls" animationIn="bounceInUp" animationOut="bounceOutDown"
          isVisible={page === "hero" && project && project.scn.tool === Tools.Sculpt && controlEnabled && !!screenSize.width && screenSize.width <= 640}
        >
          <div>
            <ImageButton image={iconShuffle} onClick={this.onShuffleClick}/>
            <ImageButton className={drag ? "active" : ""} onClick={this.onDragClick}>
              <p>drag</p>
            </ImageButton>
            <ImageButton className={negative ? "active" : ""} onClick={this.onNegativeClick}>
              <p>carve</p>
            </ImageButton>
            <ImageButton className={smooth ? "active" : ""} onClick={this.onSmoothClick}>
              <p>smooth</p>
            </ImageButton>
          </div>
          <div>
            <ImageButton image={iconMinus} onClick={this.onMinusClick}/>
            <ImageButton image={iconPlus} onClick={this.onPlusClick}/>
          </div>
        </Animated>
      );
    }

    return <React.Fragment>
      {elements}
    </React.Fragment>
  }

  renderBlendingControls = () => {
    const {engineLoaded, page, load} = this.props;
    const {config, message, showVideo, controlEnabled} = this.state;
    const project = this.getProject();
    const loading = load[HERO_PROJECT_ID] && load[HERO_PROJECT_ID].fetching;

    if (project) {
      const toolInfo = SCULPT_TOOL_INFOS[config.tool.sculptTool];
      let drag = toolInfo && toolInfo.id === 'drag';
      let smooth = toolInfo && toolInfo.id === 'smooth';
      let negative = toolInfo && toolInfo.id === 'brush' && config.tool.brush.negative;

      return (
        <div className="z-3 blending-edit-control">
          <Animated
            className="top-controls" animationIn="bounceInDown" animationOut="bounceOutUp"
            isVisible={project && project.scn.tool === Tools.Sculpt && !showVideo && !HideSculptMessages.includes(message) && controlEnabled && page === 'hero' && !loading && engineLoaded}
          >
            <Tooltip text='undo' tooltipClassName='hero-tooltip' placement='bottom' arrow>
              <div className="action-button" onClick={this.onUndoClick}>
                <img src={iconUndo} alt='undo'/>
              </div>
            </Tooltip>
            <Tooltip text='refresh' tooltipClassName='hero-tooltip' placement='bottom' arrow>
              <div className="action-button" onClick={this.onRefreshClick}>
                <img src={iconRefresh} alt='refresh'/>
              </div>
            </Tooltip>
            <Tooltip text='redo' tooltipClassName='hero-tooltip' placement='bottom' arrow>
              <div className="action-button" onClick={this.onRedoClick}>
                <img src={iconRedo} alt='redo'/>
              </div>
            </Tooltip>
          </Animated>
          <Animated
            className="left-controls" animationIn="bounceInLeft" animationOut="bounceOutLeft"
            isVisible={project && project.scn.tool === Tools.Sculpt && !showVideo && !HideSculptMessages.includes(message) && controlEnabled && page === 'hero' && !loading && engineLoaded}
          >
            <div>
              <Tooltip text='tap space bar' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div>
                  <img src={iconSpaceKey} alt='space'/>
                </div>
              </Tooltip>
              <Tooltip text='shuffle color' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div>
                  <img src={iconShuffle} alt='shuffle'/>
                </div>
              </Tooltip>
            </div>
            <div className={negative ? "active" : ""}>
              <Tooltip text='hold down alt key' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div>
                  <img src={iconAltKey} alt='alt'/>
                </div>
              </Tooltip>
              <Tooltip text='negative tool' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div>
                  <img src={iconNegative} alt='negative'/>
                </div>
              </Tooltip>
            </div>
            <div className={smooth ? "active" : ""}>
              <Tooltip text='hold down shift key' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div>
                  <img src={iconShiftKey} alt='shift'/>
                </div>
              </Tooltip>
              <Tooltip text='smooth' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div>
                  <img src={iconSmooth} alt='space'/>
                </div>
              </Tooltip>
            </div>
            <div className={drag ? "active" : ""}>
              <Tooltip text='hold down D key' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div>
                  <img src={iconDKey} alt='d'/>
                </div>
              </Tooltip>
              <Tooltip text='drag' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div>
                  <img src={iconDrag} alt='drag'/>
                </div>
              </Tooltip>
            </div>
            <div className={config.tool.symmetry ? "active" : ""}>
              <Tooltip text='tap ~ key' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div>
                  <img src={iconWaveKey} alt='~'/>
                </div>
              </Tooltip>
              <Tooltip text='symmetry on/off' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div>
                  <img src={iconSymmetric} alt='symmetric'/>
                </div>
              </Tooltip>
            </div>
            <div>
              <div>
                <Tooltip text='smaller' tooltipClassName='hero-tooltip' placement='top' arrow>
                  <div className="mr2">
                    <img src={iconLeftBracketKey} alt='['/>
                  </div>
                </Tooltip>
                <Tooltip text='larger' tooltipClassName='hero-tooltip' placement='top' arrow>
                  <div>
                    <img src={iconRightBracketKey} alt=']'/>
                  </div>
                </Tooltip>
              </div>
              <Tooltip text='intensity' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div>
                  <img src={iconStrength} alt='intensity'/>
                </div>
              </Tooltip>
            </div>
            <div>
              <div>
                <Tooltip text='smaller' tooltipClassName='hero-tooltip' placement='top' arrow>
                  <div className="mr2">
                    <img src={iconMinusKey} alt='minus'/>
                  </div>
                </Tooltip>
                <Tooltip text='larger' tooltipClassName='hero-tooltip' placement='top' arrow>
                  <div>
                    <img src={iconPlusKey} alt='plus'/>
                  </div>
                </Tooltip>
              </div>
              <Tooltip text='radius' tooltipClassName='hero-tooltip' placement='top' arrow>
                <div>
                  <img src={iconRadius} alt='radius'/>
                </div>
              </Tooltip>
            </div>
          </Animated>
        </div>
      );
    }
  };

  renderFeatureCards() {
    return <div className="feature-cards">
      <div>
        <div>
          <img src={iconFeature1} alt='feature'/>
          <div>
            <p>3D for everyone - you too.</p>
            <p>
              <span>3D expert? total 3D newb? Womp is a free, easy & incredibly powerful 3D engine.</span>
              <span className="bold"> - all from your browser.</span>
            </p>
          </div>
        </div>
        <div>
          <img src={iconFeature2} alt='feature'/>
          <div>
            <p>production-ready 3D</p>
            <p>
              <span>Your Womp file - whether a character, product or even metaverse is</span>
              <span className="bold"> industry quality 3D, ready for it’s digital or physical debut.</span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <img src={iconFeature3} alt='feature'/>
          <div>
            <p>no code 3D design</p>
            <p>
              <span>Start from scratch or build from a library of shared, parametric &</span>
              <span className="bold"> endlessly editable files.</span>
            </p>
          </div>
        </div>
        <div>
          <img src={iconFeature4} alt='feature'/>
          <div>
            <p>3D collaborative</p>
            <p>
              <span>The only truly collaborative web 3D tool. Womp lets you work or play together in realtime in the same 3D file.</span>
              <span className="bold"> Wanna womp?</span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <img src={iconFeature5} alt='feature'/>
          <div>
            <p>looking good</p>
            <p>
              <span>Apply realistic materials to your models for beautiful HD renderers, png’s GIF animations, live 3D web embeds or more.</span>
              <span className="bold"> just say “make it glass”</span>
            </p>
          </div>
        </div>
        <div>
          <img src={iconFeature6} alt='feature'/>
          <div>
            <p>not just another 3D tool</p>
            <p>
              <span>Explore and get inspired by your favorite artists, brands & designers. Discover new creators, collab, learn, share, buy, sell and connect.</span>
              <span className="bold"> Womp &lt;3</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  }

  renderBackground() {
    return <InlineVideo loop muted autoPlay poster={imgGradientP}>
      <source type="video/mp4" src={vidGradientP}/>
    </InlineVideo>;
  }

  render1() {
    return <div
      className="flex flex-column justify-center items-start w-100-m pt11-m pb10-m items-center-m h-100-m justify-between-m w-100-ns pt11-ns pb10-ns items-center-ns h-100-ns justify-between-ns">
      <p className="f2-l f3-m f3-ns bold mb5 lh-3 tc-m tc-ns">easy, <br/>collaborative 3D</p>
      <p className="mb8 lh-3 dn-m dn-ns">
        <span>the web's most powerful</span>
        <span className="bold">&nbsp;3D engine&nbsp;</span>
        <span>for creators.</span>
      </p>
      <button className="access-button f9" onClick={this.onAccessClick}>i want access</button>
    </div>;
  }

  render3() {
    return <React.Fragment>
      <div className="trailer-container">
        <InlineVideo loop muted autoPlay controls poster={imgTrailer}>
          <source type="video/mp4" src={vidTrailer}/>
        </InlineVideo>
      </div>
      <div className="flex flex-column w-100-l w-90-m w-90-ns items-center">
        <p className="f2-l f3-m f3-ns bold tc mt14-l mb12-l mt12-m mb8-m mt12-ns mb8-ns">a revolution in 3D
          creation</p>
        {this.renderFeatureCards()}
        <div className="mt12-l mt10-m mt10-ns mb5 flex flex-column items-center">
          <p className="f5-l f6-m f6-ns bold tc mb7">we'd rather just show you.</p>
          <p className="f2-l f3-m f3-ns bold">let's 3D</p>
        </div>
        <Link className="access-button f10" to={HERO_EYE_URL}>gimme early access!</Link>
        <p className="f7 bold mt11 mb13-l mb11-m mb11-ns">
          <span>oh, & </span>
          <a className="black underline"
             href="https://jobs.lever.co/womp"
             target="_blank">we’re hiring!</a>
          <span> 🎉</span>
        </p>
      </div>
    </React.Fragment>;
  }

  renderContent() {
    const {editorWrapper, page, load} = this.props;
    const {showVideo, message} = this.state;

    return <div className="hero-content">
      <Div100vh className={`eye-viewer-container page-${page}`}>
        <div className="viewer-description">
          <Animated animationIn="bounceInLeft" animationOut="fadeOut" animationOutDuration={0} isVisible={page === 'main'}>
            {this.render1()}
          </Animated>
        </div>
        <CursorDiv className="eye-viewer" disabled={page !== 'main'}>
          {page === 'main' && <Cursor/>}
          <ResizeDetector
            className={(message === Hero2Messages.ReferFriend || showVideo || HideSculptMessages.includes(message)) ? "hidden" : ""}
            onResize={this.onEditor3dResize}
          >
            <Editor3dDelegateWrapper
              editorWrapper={editorWrapper}
              elemId="three"
              className="w-100 h-100"
              overlayClassName="z-1 absolute absolute--fill ma-auto pointer-none flex flex-column items-center justify-center"
              ref={ref => {
                if (ref !== null) {
                  this.delegateWrapper = ref;
                }
              }}
            >
              <ProjectLoadingBar projectId={HERO_PROJECT_ID}/>
            </Editor3dDelegateWrapper>
          </ResizeDetector>
        </CursorDiv>
      </Div100vh>
      {page === 'main' &&
      this.render3()
      }
      {this.renderEdit()}
      {this.renderBlendingEdit()}
      {this.renderPointAnimation()}
      {this.renderControls()}
      {this.renderBlendingControls()}
    </div>;
  }

  public render() {
    const {page, editorWrapper} = this.props;
    return (
      <React.Fragment>
        <div className="hero2-root">
          {this.renderBackground()}
          {this.renderContent()}
          {page !== 'hero' &&
          <HeroFooter editorWrapper={editorWrapper} page={page}/>
          }
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    load: store.api.project.load,
    entityState: store.entities.projects,
    engineLoaded: store.entities.global.engineLoaded,
    undoRedoPosting: store.api.scene.changeCursor.posting,
    waiter: store.entities.global.waiter,
    screenSize: store.ui.global.screenSize,
    fetchWaiter: store.api.waiter.fetchWaiter,
    postWaiter: store.api.waiter.postWaiter,
    postWaiterAction: store.api.waiter.postWaiterAction,
    initialPoint: store.ui.hero.initialPoint
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  return {
    fetchMediaProjectLoad: (id: number, wrapper: Editor3dWrapper) => dispatch(fetchMediaProjectLoad(id, wrapper)),
    changeScenePreviewDigitalMaterial: (projectId: number, wrapper: Editor3dWrapper, calcIds: string[], material?: IDigitalMaterial) => dispatch(changeScenePreviewDigitalMaterial(projectId, wrapper, calcIds, material)),
    redo: (projectId: number, wrapper: Editor3dWrapper, insideSculpt?: boolean) => dispatch(redo(projectId, wrapper, insideSculpt)),
    undo: (projectId: number, wrapper: Editor3dWrapper, insideSculpt?: boolean) => dispatch(undo(projectId, wrapper, insideSculpt)),
    sendWaiterInviteRemindEmail: (name: string, email: string) => dispatch(sendWaiterInviteRemindEmail(name, email)),
    sendWaiterInviteEmail: (name: string, email: string) => dispatch(sendWaiterInviteEmail(name, email)),
    getWaiter: (email: string) => dispatch(getWaiter(email)),
    createWaiter: (email: string, point: number) => dispatch(createWaiter(email, point)),
    postWaiterVideo: (email: string, title: string, video: string, model?: string) => dispatch(postWaiterVideo(email, title, video, model)),
    putWaiter: (waiterId: number, newValues: Partial<IWomp20Water>) => dispatch(putCurrentWaiter(waiterId, newValues)),
    changeWaiter: (waiter?: IWomp20Water) => dispatch(changeWaiter(waiter)),
    addWaiterAction: (email: string, action: string) => dispatch(addWaiterAction(email, action)),
    changeDigitalMaterials: (projectId: number, wrapper: Editor3dWrapper, materials: IDigitalMaterial[]) => dispatch(ProjectSceneDispatch.changeDigitalMaterials(projectId, wrapper, materials)),
    hideOtherCalcsAndSculpt: (projectId: number, wrapper: Editor3dWrapper, calcIds: string[]) => dispatch(ProjectSceneDispatch.hideOtherCalcsAndSculpt(projectId, wrapper, calcIds)),
    changeCursor: (projectId: number, wrapper: Editor3dWrapper, cursor: number, clear?: boolean) => dispatch(changeCursor(projectId, wrapper, cursor, clear)),
    changeInitialPoint: (point: number) => dispatch(changeInitialPoint(point)),
    goToMain: () => dispatch(push(HERO_FRONT_URL)),
    goToHero: () => dispatch(push(HERO_EYE_URL)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Hero2);