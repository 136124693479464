import {BackDropEditingMode} from "../../types";
import {IEnvironment} from "../../../../peregrine/processor";
import {IFileWithMeta} from "react-dropzone-uploader";

export const CHANGE_BACK_DROP_WIZARD_ENVIRONMENT = 'CHANGE_BACK_DROP_WIZARD_ENVIRONMENT';

export interface IBackDropWizardState {
  mode: BackDropEditingMode
  orgEnvMap: IEnvironment
  envMap: IEnvironment
  setAsCurrent?: boolean
  fileData?: IFileWithMeta
}


export interface ChangeBackDropWizardEnvironmentAction {
  type: typeof CHANGE_BACK_DROP_WIZARD_ENVIRONMENT
  payload: {
    envMap: IEnvironment
  }
}

