import {
  IDeleteItemAsync,
  IFetchItemAsync,
  IFetchItemsAsync,
  IPostItemAsync
} from "../types";
import {InitStoreAction} from "../init";

export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_SHARED_PROJECT = 'FETCH_SHARED_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const DUPLICATE_PROJECT = 'DUPLICATE_PROJECT';
export const DOWNLOAD_PROJECT = 'DOWNLOAD_PROJECT';
export const FETCH_PROJECT_LOAD = 'FETCH_PROJECT_LOAD';
export const POST_PROJECT_SAVE = 'POST_PROJECT_SAVE';
export const POST_PROJECT = 'POST_PROJECT';
export const CREATE_PROJECT = 'CREATE_PROJECT';

export interface IApiProjectState {
  getProjects: IFetchItemsAsync<number>
  deleteProjects: {[key: number]: IDeleteItemAsync<number>}
  duplicateProjects: {[key: number]: IPostItemAsync<number>}
  downloadProjects: {[key: number]: IPostItemAsync<number>}
  save: {[key: number]: IPostItemAsync<number>}
  load: {[key: number]: IFetchItemAsync<number>}
  fetchShared: IFetchItemAsync<number>
  postProject: IPostItemAsync<number>
  newProject: IPostItemAsync<number>
}

interface FetchProjectsAction {
  type: typeof FETCH_PROJECTS
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    items?: number[]
  }
}

interface DeleteProjectAction {
  type: typeof DELETE_PROJECT
  payload: {
    deleting: boolean
    success?: boolean
    message?: string
    id: number
  }
}

interface DownloadProjectAction {
  type: typeof DOWNLOAD_PROJECT
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id: number
  }
}

interface DuplicateProjectAction {
  type: typeof DUPLICATE_PROJECT
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id: number
  }
}

interface FetchProjectLoadAction {
  type: typeof FETCH_PROJECT_LOAD
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    id: number
    extraInfo?: any
  }
}

interface FetchSharedProjectAction {
  type: typeof FETCH_SHARED_PROJECT
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    id?: number
  }
}

interface PostProjectSaveAction {
  type: typeof POST_PROJECT_SAVE
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id: number
    extraInfo?: any
  }
}

interface PostProjectAction {
  type: typeof POST_PROJECT
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id?: number
  }
}

interface CreateProjectAction {
  type: typeof CREATE_PROJECT
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id?: number
  }
}

export type ApiProjectActionTypes = FetchProjectsAction |
  DeleteProjectAction |
  DuplicateProjectAction |
  DownloadProjectAction |
  FetchProjectLoadAction |
  FetchSharedProjectAction |
  PostProjectSaveAction |
  PostProjectAction |
  CreateProjectAction |
  InitStoreAction;