import {ComponentTypes} from "../types";
import {createGumball, IRenderedObjectInternal} from "../gumball";
import {Calc} from "../../calc";
import {MeshGeometryObjectTypes, ObjectTypes} from "../../xobject";
import {Param_Compound, Param_Mesh, ParamTitles} from "../../parameter";
import {BasicObjectMapper, PredictedResult} from "../base";

export class MeshParameter extends BasicObjectMapper {
  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.MeshParameter;
    calc.title = 'mesh';

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let outParam = Param_Mesh.create(calc, ParamTitles.Mesh, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(outParam);
    return calc;
  }

  static predictResult(calc: Calc, obj: IRenderedObjectInternal) {
    if (MeshGeometryObjectTypes.includes(obj.valueType))
      return PredictedResult.CopyFirst;
    else if (obj.valueType === ObjectTypes.Brep)
      return PredictedResult.MeshFirst;

    return PredictedResult.Ignore;
  }
}