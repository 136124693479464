import {
  ADD_DIGITAL_MATERIALS,
  DigitalMaterialsActionTypes,
  IEntityDigitalMaterialsState,
  REMOVE_DIGITAL_MATERIAL,
  REPLACE_DIGITAL_MATERIALS
} from '../../types/entity/digital-materials';
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';

const initialState: IEntityDigitalMaterialsState = {
  byId: {},
  allIds: []
};

export function entityDigitalMaterialsReducer(
  state = initialState,
  action: DigitalMaterialsActionTypes
): IEntityDigitalMaterialsState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case REPLACE_DIGITAL_MATERIALS: {
      return {
        byId: lod.keyBy(action.payload.materials, 'id'),
        allIds: lod.map(action.payload.materials, 'id')
      };
    }
    case ADD_DIGITAL_MATERIALS: {
      return {
        byId: {
          ...state.byId,
          ...lod.keyBy(action.payload.materials, 'id')
        },
        allIds: [
          ...state.allIds,
          ...lod.map(action.payload.materials, 'id').filter(id => !state.byId[id])
        ]
      };
    }
    case REMOVE_DIGITAL_MATERIAL: {
      return {
        byId: lod.omit(state.byId, action.payload.id),
        allIds: state.allIds.filter(id => id !== action.payload.id)
      };
    }
    default:
      return state;
  }
}