import {
  BufferGeometry,
  Float32BufferAttribute,
  Vector3,
  Vector2
} from 'three';

class TubeBufferGeometry extends BufferGeometry {

  constructor( outerRadius = 1, innerRadius = 0.5, height = 1, radialSegments = 8, heightSegments = 1, openEnded = false, thetaStart = 0, thetaLength = Math.PI * 2 ) {

    super();
    this.type = 'TubeBufferGeometry';

    this.parameters = {
      outerRadius: outerRadius,
      innerRadius: innerRadius,
      height: height,
      radialSegments: radialSegments,
      heightSegments: heightSegments,
      openEnded: openEnded,
      thetaStart: thetaStart,
      thetaLength: thetaLength
    };

    const scope = this;

    radialSegments = Math.floor( radialSegments );
    heightSegments = Math.floor( heightSegments );

    // buffers

    const indices = [];
    const vertices = [];
    const normals = [];
    const uvs = [];

    // helper variables

    let index = 0;
    const halfHeight = height / 2;
    let groupStart = 0;

    // generate geometry

    generateTorso(true);
    generateTorso(false);

    if ( openEnded === false ) {

      generateCap( true );
      generateCap( false);

    }

    // build geometry

    this.setIndex( indices );
    this.setAttribute( 'position', new Float32BufferAttribute( vertices, 3 ) );
    this.setAttribute( 'normal', new Float32BufferAttribute( normals, 3 ) );
    this.setAttribute( 'uv', new Float32BufferAttribute( uvs, 2 ) );

    function generateTorso( outer ) {

      const indexArray = [];
      const normal = new Vector3();
      const vertex = new Vector3();

      let groupCount = 0;

      // generate vertices, normals and uvs

      for ( let y = 0; y <= heightSegments; y ++ ) {

        const indexRow = [];

        const v = y / heightSegments;

        // calculate the radius of the current row

        const radius = outer ? outerRadius : innerRadius;

        for ( let x = 0; x <= radialSegments; x ++ ) {

          const u = x / radialSegments;

          const theta = u * thetaLength + thetaStart;

          const sinTheta = Math.sin( theta );
          const cosTheta = Math.cos( theta );

          // vertex

          vertex.x = radius * sinTheta;
          vertex.y = - v * height + halfHeight;
          vertex.z = radius * cosTheta;
          vertices.push( vertex.x, vertex.y, vertex.z );

          // normal

          normal.set( sinTheta, 0, cosTheta ).normalize();
          if (!outer)
            normal.negate();
          normals.push( normal.x, normal.y, normal.z );

          // uv

          uvs.push( u, 1 - v );

          // save index of vertex in respective row

          indexRow.push( index ++ );

        }

        // now save vertices of the row in our index array

        indexArray.push( indexRow );

      }

      // generate indices

      for ( let x = 0; x < radialSegments; x ++ ) {

        for ( let y = 0; y < heightSegments; y ++ ) {

          // we use the index array to access the correct indices

          const a = indexArray[ y ][ x ];
          const b = indexArray[ y + 1 ][ x ];
          const c = indexArray[ y + 1 ][ x + 1 ];
          const d = indexArray[ y ][ x + 1 ];

          // faces

          indices.push( a, b, d );
          indices.push( b, c, d );

          // update group counter

          groupCount += 6;

        }

      }

      // add a group to the geometry. this will ensure multi material support

      scope.addGroup( groupStart, groupCount, outer === true ? 0 : 1 );

      // calculate new start value for groups

      groupStart += groupCount;

    }

    function generateCap( top ) {

      // save the index of the first center vertex
      const innerIndexStart = index;

      const uv = new Vector2();
      const vertex = new Vector3();

      let groupCount = 0;

      const sign = ( top === true ) ? 1 : - 1;

      // first we generate the center vertex data of the cap.
      // because the geometry needs one set of uvs per face,
      // we must generate a center vertex per face/segment

      for ( let x = 0; x <= radialSegments; x ++ ) {

        const u = x / radialSegments;
        const theta = u * thetaLength + thetaStart;

        const cosTheta = Math.cos( theta );
        const sinTheta = Math.sin( theta );

        // vertex

        vertex.x = innerRadius * sinTheta;
        vertex.y = halfHeight * sign;
        vertex.z = innerRadius * cosTheta;
        vertices.push( vertex.x, vertex.y, vertex.z );

        // normal

        normals.push( 0, sign, 0 );

        // uv

        uv.x = ( cosTheta * 0.25 ) + 0.5;
        uv.y = ( sinTheta * 0.25 * sign ) + 0.5;
        uvs.push( uv.x, uv.y );

        // increase index

        index ++;

      }

      // save the index of the last center vertex
      const innerIndexEnd = index;

      // now we generate the surrounding vertices, normals and uvs

      for ( let x = 0; x <= radialSegments; x ++ ) {

        const u = x / radialSegments;
        const theta = u * thetaLength + thetaStart;

        const cosTheta = Math.cos( theta );
        const sinTheta = Math.sin( theta );

        // vertex

        vertex.x = outerRadius * sinTheta;
        vertex.y = halfHeight * sign;
        vertex.z = outerRadius * cosTheta;
        vertices.push( vertex.x, vertex.y, vertex.z );

        // normal

        normals.push( 0, sign, 0 );

        // uv

        uv.x = ( cosTheta * 0.5 ) + 0.5;
        uv.y = ( sinTheta * 0.5 * sign ) + 0.5;
        uvs.push( uv.x, uv.y );

        // increase index

        index ++;

      }

      // generate indices

      for ( let x = 0; x < radialSegments; x ++ ) {

        const i = innerIndexStart + x;
        const o = innerIndexEnd + x;

        if ( top === true ) {

          // face top

          indices.push( o, o + 1, i );
          indices.push( i, o + 1, i + 1 );

        } else {

          // face bottom

          indices.push( o + 1, o, i );
          indices.push( o + 1, i, i + 1 );

        }

        groupCount += 3;

      }

      // add a group to the geometry. this will ensure multi material support

      scope.addGroup( groupStart, groupCount, top === true ? 2 : 3 );

      // calculate new start value for groups

      groupStart += groupCount;

    }

  }

}


export { TubeBufferGeometry };