import {ComponentTypes} from "../types";
import {createGumball, IRenderedObjectInternal} from "../gumball";
import {Calc} from "../../calc";
import {ObjectTypes} from "../../xobject";
import {Param_Brep, Param_Compound, ParamTitles} from "../../parameter";
import {BasicObjectMapper, PredictedResult} from "../base";

export class BrepParameter extends BasicObjectMapper {
  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.BrepParameter;
    calc.title = 'brep';

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let outParam = Param_Brep.create(calc, ParamTitles.Brep, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(outParam);
    return calc;
  }

  static predictResult(calc: Calc, obj: IRenderedObjectInternal) {
    if (obj.valueType === ObjectTypes.Brep)
      return PredictedResult.CopyFirst;

    return PredictedResult.Ignore;
  }
}