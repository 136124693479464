import {ComponentTypes} from "../types";
import {three as threeTypes} from "../../../types";
import {createGumball} from "../gumball";
import {Param_Mesh, ParamTitles} from "../../parameter";
import {Calc} from "../../calc";
import {MeshResolution} from "../../const";
import {WompMesh} from "../../../WompObject";
import {getMeshDataFromThreeGeometry} from "../../../converter";
import {mat4, vec3} from "gl-matrix";
import {BufferGeometryUtils} from "three/examples/jsm/utils/BufferGeometryUtils";
import {BasicMeshGenerator} from "./basic-gen";

const createQuadSphereBufferGeometry = (radius: number, segments: number) => {
  let geometry = new threeTypes.BoxBufferGeometry(2, 2, 2, segments, segments, segments);
  delete geometry.attributes.uv;
  delete geometry.attributes.normal;
  geometry.clearGroups();

  let merged = BufferGeometryUtils.mergeVertices(geometry);
  let position = merged.attributes.position;
  let normalArray = new Float32Array(position.count * 3);

  for (let i = 0; i < position.count; ++i) {
    let i3 = i * 3;
    let vec = new threeTypes.Vector3(position.array[i3], position.array[i3 + 1], position.array[i3 + 2]).normalize();
    position.setXYZ(i, vec.x * radius, vec.y * radius, vec.z * radius);
    normalArray[i3] = vec.x;
    normalArray[i3 + 1] = vec.y;
    normalArray[i3 + 2] = vec.z;
  }

  merged.attributes.normal = new threeTypes.Float32BufferAttribute(normalArray, 3);

  return merged;
};

export class ThreeSphereGenerator extends BasicMeshGenerator {
  static defaultParameter = {
    radius: 10
  };
  static draggingPreview = ThreeSphereGenerator.generateMesh();

  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.ThreeSphereGenerator;
    calc.title = 'sphere';

    let sphereParam = Param_Mesh.create(calc, ParamTitles.Sphere, false, false, true);

    calc.addParameter(sphereParam);

    return calc;
  }

  static getDesc(calc: Calc) {
    return 'quadsphere';
  }

  static generateMesh(parameter: any = this.defaultParameter) {
    let value = createQuadSphereBufferGeometry(this.defaultParameter.radius, MeshResolution / 3);

    let geom = getMeshDataFromThreeGeometry(value);

    return new WompMesh(geom, this.generateMatrix(parameter));
  }

  static generateMatrix(parameter: any = this.defaultParameter) {
    return mat4.fromScaling(
      mat4.create(),
      vec3.fromValues(
        parameter.radius / this.defaultParameter.radius,
        parameter.radius / this.defaultParameter.radius,
        parameter.radius / this.defaultParameter.radius
      )
    );
  }
}
