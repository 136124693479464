import {IAttachment, IDeleteItemAsync, IFetchItemsAsync, IPostItemAsync, IProject, NormalizedObjects} from "../types";
import {InitStoreAction} from "../init";
import {IScene} from "../../../peregrine";

export const REPLACE_PROJECTS = 'REPLACE_PROJECTS';
export const ADD_PROJECTS = 'ADD_PROJECTS';
export const ADD_PROJECT = 'ADD_PROJECT';
export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const ADD_SCREENSHOT_IDS = 'ADD_SCREENSHOT_IDS';
export const REMOVE_SCREENSHOT_IDS = 'REMOVE_SCREENSHOT_IDS';
export const UPDATE_SCENE = 'UPDATE_SCENE';
export const REPLACE_ATTACHMENTS = 'REPLACE_ATTACHMENTS';
export const REPLACE_CHAT_SESSION_IDS = 'REPLACE_CHAT_SESSION_IDS';
export const CHANGE_FIELDS = 'CHANGE_FIELDS';

export interface IEntityProjectsState extends NormalizedObjects<IProject>  {
}

interface ReplaceProjectsAction {
  type: typeof REPLACE_PROJECTS
  payload: {
    projects: IProject[],
    config?: any
  }
}

interface ReplaceAttachmentsAction {
  type: typeof REPLACE_ATTACHMENTS
  payload: {
    id: number
    attachments: IAttachment[]
  }
}

interface ReplaceChatSessionIdsAction {
  type: typeof REPLACE_CHAT_SESSION_IDS
  payload: {
    id: number
    chatSessionIds: number[]
  }
}

interface AddProjectsAction {
  type: typeof ADD_PROJECTS
  payload: {
    projects: IProject[],
    config?: any
  }
}

interface AddProjectAction {
  type: typeof ADD_PROJECT
  payload: {
    project: IProject
  }
}

interface RemoveProjectAction {
  type: typeof REMOVE_PROJECT
  payload: {
    id: number
  }
}

interface AddScreenshotIdsAction {
  type: typeof ADD_SCREENSHOT_IDS
  payload: {
    id: number
    screenshotIds: number[]
  }
}

interface RemoveScreenshotIdsAction {
  type: typeof REMOVE_SCREENSHOT_IDS
  payload: {
    id: number
    screenshotIds: number[]
  }
}

interface ChangeFieldsAction {
  type: typeof CHANGE_FIELDS
  payload: {
    id: number
    fields: Partial<IProject>
  }
}

export interface UpdateSceneAction {
  type: typeof UPDATE_SCENE
  payload: {
    id: number
    newState: IScene
  }
}

export type ProjectsActionTypes = ReplaceProjectsAction |
  ReplaceAttachmentsAction |
  ReplaceChatSessionIdsAction |
  AddProjectsAction |
  AddProjectAction |
  RemoveProjectAction |
  AddScreenshotIdsAction |
  RemoveScreenshotIdsAction |
  UpdateSceneAction |
  ChangeFieldsAction |
  InitStoreAction