import {IEnvironment} from "../../../../peregrine/processor";
import {IFileWithMeta} from "react-dropzone-uploader/dist/Dropzone";
import {LightMapEditingMode} from "../../types";

export const CHANGE_LIGHT_MAP_WIZARD_ENVIRONMENT = 'CHANGE_LIGHT_MAP_WIZARD_ENVIRONMENT';

export interface ILightMapWizardState {
  mode: LightMapEditingMode
  orgEnvMap: IEnvironment
  envMap: IEnvironment
  fileData?: IFileWithMeta
}

export interface ChangeLightMapWizardEnvironmentAction {
  type: typeof CHANGE_LIGHT_MAP_WIZARD_ENVIRONMENT
  payload: {
    envMap: IEnvironment
  }
}

