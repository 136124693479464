import { vec3 } from 'gl-matrix';
import Utils from '../misc/Utils';

class StateDynamic {

  constructor(main, mesh) {
    this._main = main; // main application
    this._mesh = mesh; // the mesh
    this._center = vec3.copy([0.0, 0.0, 0.0], mesh.getCenter());

    this._nbFacesState = mesh.getNbFaces(); // number of faces
    this._nbVerticesState = mesh.getNbVertices(); // number of vertices

    this._idVertState = []; // ids of vertices
    this._fRingState = []; // ring of faces around vertices
    this._pRingState = []; // ring of faces around vertices
    this._pArState = []; // copies of vertices coordinates
    this._mArState = []; // copies of masking vertices

    this._idFaceState = []; // ids of faces
    this._fArState = []; // copies of face indices
  }

  isNoop() {
    return this._idVertState.length === 0 && this._idFaceState.length === 0;
  }

  undo() {
    this.pullVertices();
    this.pullFaces();
    var mesh = this._mesh;
    // mesh.getVerticesRingFace().length = this._nbVerticesState;
    // mesh.getVerticesRingVert().length = this._nbVerticesState;
    mesh.setNbVertices(this._nbVerticesState);
    mesh.setNbFaces(this._nbFacesState);

    mesh.updateTopology( /*this._idFaceState, this._idVertState*/ ); // TODO local update ?
    mesh.updateGeometry( /*this._idFaceState, this._idVertState*/ ); // TODO local update ?
    // mesh.updateMaskingBuffer();
    mesh.updateBuffers();
    vec3.copy(mesh.getCenter(), this._center);
    this._main.setMesh(mesh);
  }

  redo() {
    this.undo();
  }

  createRedo() {
    var redo = new StateDynamic(this._main, this._mesh);
    this.pushRedoVertices(redo);
    this.pushRedoFaces(redo);
    return redo;
  }

  pushVertices(iVerts) {
    var idVertState = this._idVertState;
    var fRingState = this._fRingState;
    var vRingState = this._pRingState;
    var vArState = this._pArState;
    var mArState = this._mArState;

    var mesh = this._mesh;
    var fRing = mesh.getVerticesRingFace();
    var vRing = mesh.getVerticesRingVert();
    var vAr = mesh.getVertices();
    var mAr = mesh.getMaskings();
    var vStateFlags = mesh.getVerticesStateFlags();

    var stateFlag = Utils.STATE_FLAG;
    var nbVerts = iVerts.length;
    for (var i = 0; i < nbVerts; ++i) {
      var id = iVerts[i];
      if (vStateFlags[id] === stateFlag)
        continue;
      vStateFlags[id] = stateFlag;
      fRingState.push(fRing[id].slice());
      vRingState.push(vRing[id].slice());
      idVertState.push(id);
      mArState.push(mAr[id]);
      id *= 3;
      vArState.push(vAr[id], vAr[id + 1], vAr[id + 2]);
    }
  }

  pushFaces(iFaces) {
    var idFaceState = this._idFaceState;
    var fArState = this._fArState;

    var mesh = this._mesh;
    var fAr = mesh.getFaces();
    var fStateFlags = mesh.getFacesStateFlags();

    var stateFlag = Utils.STATE_FLAG;
    var nbFaces = iFaces.length;
    for (var i = 0; i < nbFaces; ++i) {
      var id = iFaces[i];
      if (fStateFlags[id] === stateFlag)
        continue;
      fStateFlags[id] = stateFlag;
      idFaceState.push(id);
      id *= 3;
      fArState.push(fAr[id], fAr[id + 1], fAr[id + 2]);
    }
  }

  pushRedoVertices(redoState) {
    var mesh = redoState._mesh;
    var nbMeshVertices = mesh.getNbVertices();
    var fRing = mesh.getVerticesRingFace();
    var vRing = mesh.getVerticesRingVert();
    var vAr = mesh.getVertices();
    var mAr = mesh.getMaskings();

    var i = 0;
    var id = 0;
    var acc = 0;
    var idVertUndoState = this._idVertState;
    var nbVerts = idVertUndoState.length;
    var nbVerticesState = this._nbVerticesState;
    var nbMin = Math.min(nbVerticesState, nbMeshVertices);
    var idVertRedoState = new Uint32Array(Utils.getMemory(nbMeshVertices * 3), 0, nbMeshVertices);
    for (i = 0; i < nbVerts; ++i) {
      id = idVertUndoState[i];
      if (id < nbMin)
        idVertRedoState[acc++] = id;
    }
    for (i = nbVerticesState; i < nbMeshVertices; ++i) {
      idVertRedoState[acc++] = i;
    }

    nbVerts = acc;
    idVertRedoState = redoState._idVertState = new Uint32Array(idVertRedoState.subarray(0, nbVerts));
    var fRingRedoState = redoState._fRingState = new Array(nbVerts);
    var vRingRedoState = redoState._pRingState = new Array(nbVerts);
    var vArRedoState = redoState._pArState = new Float32Array(nbVerts * 3);
    var mArRedoState = redoState._mArState = new Float32Array(nbVerts);
    for (i = 0; i < nbVerts; ++i) {
      id = idVertRedoState[i];
      fRingRedoState[i] = fRing[id].slice();
      vRingRedoState[i] = vRing[id].slice();
      mArRedoState[i] = mAr[id];
      id *= 3;
      var j = i * 3;
      vArRedoState[j] = vAr[id];
      vArRedoState[j + 1] = vAr[id + 1];
      vArRedoState[j + 2] = vAr[id + 2];
    }
  }

  pushRedoFaces(redoState) {
    var mesh = redoState._mesh;
    var nbMeshFaces = mesh.getNbFaces();
    var fAr = mesh.getFaces();

    var i = 0;
    var id = 0;
    var acc = 0;
    var idFaceUndoState = this._idFaceState;
    var nbFaces = idFaceUndoState.length;
    var nbFacesState = this._nbFacesState;
    var nbMin = Math.min(nbFacesState, nbMeshFaces);
    var idFaceRedoState = new Uint32Array(Utils.getMemory(nbMeshFaces * 3), 0, nbMeshFaces);
    for (i = 0; i < nbFaces; ++i) {
      id = idFaceUndoState[i];
      if (id < nbMin)
        idFaceRedoState[acc++] = id;
    }
    for (i = nbFacesState; i < nbMeshFaces; ++i) {
      idFaceRedoState[acc++] = i;
    }

    nbFaces = acc;
    idFaceRedoState = redoState._idFaceState = new Uint32Array(idFaceRedoState.subarray(0, nbFaces));
    var fArRedoState = redoState._fArState = new Int32Array(nbFaces * 3);
    for (i = 0; i < nbFaces; ++i) {
      id = idFaceRedoState[i];
      id *= 3;
      var j = i * 3;
      fArRedoState[j] = fAr[id];
      fArRedoState[j + 1] = fAr[id + 1];
      fArRedoState[j + 2] = fAr[id + 2];
    }
  }

  pullVertices() {
    var nbMeshVertices = this._nbVerticesState;
    var fRingState = this._fRingState;
    var vRingState = this._pRingState;
    var vArState = this._pArState;
    var mArState = this._mArState;
    var idVertState = this._idVertState;
    var nbVerts = idVertState.length;

    var mesh = this._mesh;
    var fRing = mesh.getVerticesRingFace();
    var vRing = mesh.getVerticesRingVert();
    var vAr = mesh.getVertices();
    var mAr = mesh.getMaskings();
    for (var i = 0; i < nbVerts; ++i) {
      var id = idVertState[i];
      if (id >= nbMeshVertices)
        continue;
      fRing[id] = fRingState[i].slice();
      vRing[id] = vRingState[i].slice();
      mAr[id] = mArState[i];
      id *= 3;
      var j = i * 3;
      vAr[id] = vArState[j];
      vAr[id + 1] = vArState[j + 1];
      vAr[id + 2] = vArState[j + 2];
    }
  }

  pullFaces() {
    var nbMeshFaces = this._nbFacesState;
    var fArState = this._fArState;
    var idFaceState = this._idFaceState;
    var nbFaces = idFaceState.length;

    var mesh = this._mesh;
    var fAr = mesh.getFaces();
    for (var i = 0; i < nbFaces; ++i) {
      var id = idFaceState[i];
      if (id >= nbMeshFaces)
        continue;
      id *= 3;
      var j = i * 3;
      fAr[id] = fArState[j];
      fAr[id + 1] = fArState[j + 1];
      fAr[id + 2] = fArState[j + 2];
    }
  }
}

export default StateDynamic;
