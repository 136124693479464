/**
 * @author arodic / https://github.com/arodic
 */

import {
  Box3,
  DoubleSide,
  EventDispatcher,
  Group,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneBufferGeometry,
  Quaternion,
  Raycaster,
  SphereBufferGeometry,
  Vector2,
  Vector3,
  BackSide,
  BoxBufferGeometry,
  BufferGeometry,
  Uint32BufferAttribute,
  Float32BufferAttribute,
  ShaderMaterial,
  Color,
  AdditiveBlending, CylinderBufferGeometry
} from 'three';
import {Line2} from 'three/examples/jsm/lines/Line2';
import {LineGeometry} from 'three/examples/jsm/lines/LineGeometry';
import {LineMaterial} from 'three/examples/jsm/lines/LineMaterial';
import lod from 'lodash';

const PI = Math.PI;

const VERTEX_PICKER_INFO = {
  LUB: {
    position: new Vector3(-.5, .5, -.5),
    offset: new Vector3(-1, 1, -1),
  },
  RUB: {
    position: new Vector3(.5, .5, -.5),
    offset: new Vector3(1, 1, -1),
  },
  LDB: {
    position: new Vector3(-.5, -.5, -.5),
    offset: new Vector3(-1, -1, -1),
  },
  RDB: {
    position: new Vector3(.5, -.5, -.5),
    offset: new Vector3(1, -1, -1),
  },
  LUT: {
    position: new Vector3(-.5, .5, .5),
    offset: new Vector3(-1, 1, 1),
  },
  RUT: {
    position: new Vector3(.5, .5, .5),
    offset: new Vector3(1, 1, 1),
  },
  LDT: {
    position: new Vector3(-.5, -.5, .5),
    offset: new Vector3(-1, -1, 1),
  },
  RDT: {
    position: new Vector3(.5, -.5, .5),
    offset: new Vector3(1, -1, 1),
  },
  T: {
    position: new Vector3(0, 0, .5),
    offset: new Vector3(0, 0, 1),
  },
  B: {
    position: new Vector3(0, 0, -.5),
    offset: new Vector3(0, 0, -1),
  }
};

// R = X, U = Y, T = Z
const FACE_PICKER_INFO = {
  R: {
    position: new Vector3(.5, 0, 0),
    offset: new Vector3(1, 0, 0),
    width: new Vector3(0, 0, 1),
    height: new Vector3(0, 1, 0),
  },
  U: {
    position: new Vector3(0, .5, 0),
    offset: new Vector3(0, 1, 0),
    width: new Vector3(1, 0, 0),
    height: new Vector3(0, 0, 1),
  },
  T: {
    position: new Vector3(0, 0, .5),
    offset: new Vector3(0, 0, 1),
    width: new Vector3(1, 0, 0),
    height: new Vector3(0, 1, 0),
  },
  L: {
    position: new Vector3(-.5, 0, 0),
    offset: new Vector3(-1, 0, 0),
    width: new Vector3(0, 0, 1),
    height: new Vector3(0, 1, 0),
  },
  D: {
    position: new Vector3(0, -.5, 0),
    offset: new Vector3(0, -1, 0),
    width: new Vector3(1, 0, 0),
    height: new Vector3(0, 0, 1),
  },
  B: {
    position: new Vector3(0, 0, -.5),
    offset: new Vector3(0, 0, -1),
    width: new Vector3(1, 0, 0),
    height: new Vector3(0, 1, 0),
  },
};

const DRAG_DROP_MODE = {
  OBJECT: 1,
  FACE: 2,
  VERTEX: 3,
  BOX: 4,
  FLOOR: 5,
};

let SnapControl = function (camera, scene, controlScene) {
  Object3D.call(this);

  this.domElement = document;
  let curTick = 0;
  let timer = setInterval(() => ++curTick, 1000);
  let lastEventTick;

  let Config = {
    ShowTime: 5
  };

  this.visible = false;
  this.camera = camera;
  this.scene = scene;
  this.controlScene = controlScene;
  this.eye = undefined;
  // this.intersectPlane = new Mesh(
  //   new PlaneBufferGeometry(1000, 1000),
  //   new MeshBasicMaterial({ visible: true, wireframe: false, side: DoubleSide, transparent: true, opacity: 0.1, color: 0x00ff00})
  // );
  this.intersectPlane = new Mesh(
    new PlaneBufferGeometry(300000, 300000),
    new MeshBasicMaterial({visible: false, wireframe: false, side: DoubleSide, transparent: true})
  );
  this.intersectPlane.name = 'snap-control-intersect-plane';

  this.rayCaster = new Raycaster();
  this.rayCaster.layers.set(0);

  this.oldState = {};

  this.spriteSize = 1.2;
  this.isDragging = false;

  this.mouseOnCanvas = new Vector2();
  this.mouse = new Vector2();

  this.shiftKeyState = false;
  this.targets = {};
  this.boundingBox = new Box3();
  this.initialMatrix = new Matrix4();
  this.space = 'world';
  this.boxEnabled = true;
  this.faceEnabled = true;
  this.vertexEnabled = true;
  this.gridEnabled = true;
  this.controlEnabled = true;
  this.distanceRatio = 1;
  this.elemRect = undefined;

  let scope = this;
  let unitX = new Vector3(1, 0, 0);
  let unitY = new Vector3(0, 1, 0);
  let unitZ = new Vector3(0, 0, 1);

  let objUnitX = new Vector3(1, 0, 0);
  let objUnitY = new Vector3(0, 1, 0);
  let objUnitZ = new Vector3(0, 0, 1);
  let objPosition = new Vector3();
  let objScale = new Vector3();
  let objQuaternion = new Quaternion();
  let boundingBoxSize = new Vector3();

  let targetObjPosition = new Vector3();
  let targetBoundingBoxSize = new Vector3();

  let hoverObject;
  let hoverBox;
  let faceHoverPickerName;
  let vertexHoverPickerName;
  let faceMesh;
  let faceIndex;
  let instanceId;

  let targetHoverObject;
  let targetHoverBox;
  let targetFaceHoverPickerName;
  let targetVertexHoverPickerName;
  let targetFaceMesh;
  let targetFaceIndex;
  let targetInstanceId;
  let targetHoverFloor;
  let targetFloorPos;

  function updateObjectLayers(object, newVisible) {

    if (object.visible && !newVisible) {

      object.traverse(obj => {

        obj.layers.disable(0);
        obj.layers.enable(1);

      });

    } else if (!object.visible && newVisible) {

      object.traverse(obj => {

        obj.layers.disable(1);
        obj.layers.enable(0);

      });

    }

    object.visible = newVisible;

  }

  function createGhostedMaterial(color) {

    let vertexShader = [
      'uniform float p;',
      'varying float intensity;',
      'void main() {',
      ' vec3 transformed = vec3( position );',
      ' vec4 mvPosition = vec4( transformed, 1.0 );',
      ' #ifdef USE_INSTANCING',
      '  mvPosition = instanceMatrix * mvPosition;',
      ' #endif',
      ' vec4 modelViewPosition = modelViewMatrix * mvPosition;',
      ' vec3 vNormal = normalize( normalMatrix * normal );',
      ' intensity = pow(1.0 - abs(dot(vNormal, vec3(0, 0, 1))), p);',
      ' gl_Position = projectionMatrix * modelViewPosition;',
      '}'
    ].join('\n');

    let fragmentShader = [
      'uniform vec3 glowColor;',
      'varying float intensity;',
      'void main() {',
      ' vec3 glow = glowColor * intensity;',
      ' gl_FragColor = vec4( glow, 1.0 );',
      '}'
    ].join('\n');

    return new ShaderMaterial({
      uniforms: {
        p: {value: 2},
        glowColor: {value: new Color(color)}
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      side: DoubleSide, blending: AdditiveBlending,
      transparent: true, depthWrite: false
    });

  }

  this.setSize = function (size) {
    this.spriteSize = size * 12.0;
  };

  this.refreshBoundingClientRect = function () {
    this.elemRect = this.domElement === document ? document.body.getBoundingClientRect() : this.domElement.getBoundingClientRect();
  };

  this.setDelegate = function (delegate) {

    this.unsetDelegate();

    delegate.domElement.addEventListener('mousedown', onPointerDown);
    delegate.domElement.addEventListener('touchstart', onTouchDown);

    delegate.domElement.addEventListener('mousemove', onPointerMove);
    delegate.domElement.addEventListener('touchmove', onPointerMove);

    delegate.domElement.addEventListener('mouseup', onPointerUp);
    delegate.domElement.addEventListener('touchend', onPointerUp);
    delegate.domElement.addEventListener('touchcancel', onPointerUp);
    delegate.domElement.addEventListener('touchleave', onPointerUp);

    this.domElement = delegate.domElement;

    this.refreshBoundingClientRect();
  }

  this.unsetDelegate = function () {

    if (this.domElement === document)
      return;

    this.domElement.removeEventListener('mousedown', onPointerDown);
    this.domElement.removeEventListener('touchstart', onTouchDown);

    this.domElement.removeEventListener('mousemove', onPointerMove);
    this.domElement.removeEventListener('touchmove', onPointerMove);

    this.domElement.removeEventListener('mouseup', onPointerUp);
    this.domElement.removeEventListener('touchend', onPointerUp);
    this.domElement.removeEventListener('touchcancel', onPointerUp);
    this.domElement.removeEventListener('touchleave', onPointerUp);

    this.domElement = document;

  }

  this.dispose = function () {
    this.detach();

    clearInterval(timer);

    scope = null;
  };

  this.attach = function (object) {
    this.object = object;
    this.visible = true;
    this.initialMatrix = object.matrix.clone();

    this.targetId = undefined;
    this.targetObject = undefined;

    this.previewObject = this.object.clone(true);

    this.previewObject.traverse((object) => {
      if (object.isMesh)
        object.material = this.materials.object;
    });
    this.previewObject.visible = false;
    this.scene.add(this.previewObject);

    hoverObject = undefined;
    hoverBox = undefined;
    faceHoverPickerName = undefined;
    vertexHoverPickerName = undefined;
    faceMesh = undefined;
    instanceId = undefined;
    faceIndex = undefined;

    targetHoverObject = undefined;
    targetHoverBox = undefined;
    targetFaceHoverPickerName = undefined;
    targetVertexHoverPickerName = undefined;
    targetFaceMesh = undefined;
    targetInstanceId = undefined;
    targetFaceIndex = undefined;
    targetFloorPos = undefined;
    targetHoverFloor = undefined;

    // this.refresh();
    // lastEventTick = -Config.ShowTime - 1;
  };

  this.boxGeometry = new BoxBufferGeometry(1, 1, 1);
  this.boxMaterial = new MeshBasicMaterial({transparent: true, side: DoubleSide, opacity: 0, visible: false});

  this.attachTargets = function (targets) {
    for (let id in this.targets) {
      this.controlScene.remove(this.targets[id].mesh);
    }

    this.targets = targets;
    for (let id in this.targets) {
      this.targets[id].mesh = new Mesh(this.boxGeometry, this.boxMaterial);
      this.targets[id].mesh.name = id;
      this.targets[id].mesh.matrixAutoUpdate = true;
      this.targets[id].box.getCenter(this.targets[id].mesh.position);
      this.targets[id].box.getSize(this.targets[id].mesh.scale);
      this.controlScene.add(this.targets[id].mesh);
    }
  };

  this.detach = function () {
    this.object = undefined;
    this.targets = {};
    this.scene.remove(this.previewObject);
    this.isDragging = false;
    this.dragMode = undefined;
    this.dropMode = undefined;

    for (let id in this.targets) {
      this.controlScene.remove(this.targets[id].mesh);
    }
    this.visible = false;
  };

  this.getRelativeTransform = function (transform) {
    return transform.copy(this.object.matrix).premultiply(this.initialMatrix.clone().invert());
  };

  this.getObjectBoundingBoxSize = function (size) {
    let relativeTransform = this.getRelativeTransform(new Matrix4());
    return this.boundingBox.clone().applyMatrix4(relativeTransform).getSize(size);
  };

  this.getObjectUnitAxes = function (x, y, z) {
    x.set(1, 0, 0);
    y.set(0, 1, 0);
    z.set(0, 0, 1);
    if (this.space !== 'world') {

      this.object.matrix.decompose(
        objPosition,
        objQuaternion,
        objScale
      );

      x.applyQuaternion(objQuaternion);
      y.applyQuaternion(objQuaternion);
      z.applyQuaternion(objQuaternion);
    }
  };

  this.getPositionNormalVector = (target) => {

    let mode = target ? this.dropMode : this.dragMode;
    let obj = target ? targetHoverObject : hoverObject;
    let box = target ? targetHoverBox : hoverBox;
    let face = target ? targetFaceHoverPickerName : faceHoverPickerName;
    let vertex = target ? targetVertexHoverPickerName : vertexHoverPickerName;
    let fMesh = target ? targetFaceMesh : faceMesh;
    let fIndex = target ? targetFaceIndex : faceIndex;
    let iId = target ? targetInstanceId : instanceId;
    let pos = target ? targetObjPosition : objPosition;
    let bBoxSize = target ? targetBoundingBoxSize : boundingBoxSize;
    let quaternion = target ? new Quaternion() : objQuaternion;

    if (mode) {

      if (obj) {

        let vertices = this.getFaceVertices(fMesh, iId, fIndex);

        let center = new Vector3();

        for (let i = 0; i < 3; ++i) {
          for (let j = 0; j < 3; ++j)
            center.setComponent(j, center.getComponent(j) + vertices[i * 3 + j]);
        }

        center.multiplyScalar(1 / 3);
        let v1 = new Vector3(vertices[0], vertices[1], vertices[2]);
        let v2 = new Vector3(vertices[3], vertices[4], vertices[5]);

        return [center, v1.sub(center).cross(v2.sub(center)).normalize()];

      } else if (box) {

        return [pos.clone(), unitZ.clone().applyQuaternion(quaternion)];

      } else if (face) {

        let curInfo = FACE_PICKER_INFO[face];
        return [pos.clone().add(curInfo.position.clone().applyQuaternion(quaternion).multiply(bBoxSize)), curInfo.offset.clone().normalize().applyQuaternion(quaternion)];

      } else if (vertex) {

        let curInfo = VERTEX_PICKER_INFO[vertex];
        return [pos.clone().add(curInfo.position.clone().applyQuaternion(quaternion).multiply(bBoxSize)), curInfo.offset.clone().normalize().applyQuaternion(quaternion)];

      } else if (targetHoverFloor) {

        return [targetFloorPos.clone(), unitZ.clone()];

      }

    }

    return [undefined, undefined];

  };

  this.getControlGroupMatrix = function () {
    this.object.matrix.decompose(
      objPosition,
      objQuaternion,
      objScale
    );

    return new Matrix4().makeTranslation(-objPosition.x, -objPosition.y, -objPosition.z)
      .premultiply(new Matrix4().makeRotationFromQuaternion(objQuaternion))
      .premultiply(new Matrix4().makeTranslation(objPosition.x, objPosition.y, objPosition.z));
  };

  this.refreshBoundingBoxLines = function (target) {

    let pos = target ? targetObjPosition : objPosition;
    let bBoxSize = target ? targetBoundingBoxSize : boundingBoxSize;
    let picker = target ? this.targetBoundingBoxLines : this.boundingBoxLines;
    let showControl = target ? this.isDragging : !this.isDragging;

    if (showControl) {

      picker.visible = true;
      picker.position.copy(pos);

      let positions = [
        [-.5, -.5, -.5, -.5, -.5, .5],
        [.5, -.5, -.5, .5, -.5, .5],
        [-.5, .5, -.5, -.5, .5, .5],
        [.5, .5, -.5, .5, .5, .5],
        [-.5, -.5, -.5, -.5, .5, -.5],
        [-.5, .5, -.5, .5, .5, -.5],
        [.5, .5, -.5, .5, -.5, -.5],
        [.5, -.5, -.5, -.5, -.5, -.5],
        [-.5, -.5, .5, -.5, .5, .5],
        [-.5, .5, .5, .5, .5, .5],
        [.5, .5, .5, .5, -.5, .5],
        [.5, -.5, .5, -.5, -.5, .5],
        [0, 0, .5, 0, 0, -.5],
      ];

      for (let j = 0; j < 13; ++j) {

        for (let i = 0; i < positions[j].length; i += 3) {

          positions[j][i] *= bBoxSize.x;
          positions[j][i + 1] *= bBoxSize.y;
          positions[j][i + 2] *= bBoxSize.z;

        }

        picker.children[j].geometry.setPositions(positions[j]);
        picker.children[j].computeLineDistances();

        picker.children[j].visible = true;

      }

    }

  };

  this.refreshPathLine = function () {

    if (this.isDragging) {

      let position = [this.dragStartPosition.x, this.dragStartPosition.y, this.dragStartPosition.z, this.dragEndPosition.x, this.dragEndPosition.y, this.dragEndPosition.z];
      let targetValid = targetHoverFloor || targetHoverObject || targetHoverBox || targetFaceHoverPickerName || targetVertexHoverPickerName;

      this.pathLine.material = targetValid ? this.materials.path.valid : this.materials.path.invalid;
      this.pathLine.material.resolution.set(this.elemRect.width, this.elemRect.height);
      this.pathLine.geometry.setPositions(position);
      this.pathLine.computeLineDistances();

      this.pathLine.visible = true;

    }

  };

  this.refreshBoxPicker = function (target) {

    let pos = target ? targetObjPosition : objPosition;
    let hover = target ? targetHoverBox : hoverBox;
    let picker = target ? this.targetBoxPicker : this.boxPicker;
    let mode = target ? this.dropMode : this.dragMode;
    let showControl = false;
    // let showControl = target ? this.isDragging : !this.isDragging;

    if (DRAG_DROP_MODE.BOX === mode || showControl) {

      let curPicker = picker;

      if (hover)
        curPicker.material = this.materials.box.hover;
      else
        curPicker.material = this.materials.box.normal;

      curPicker.position.copy(pos);
      curPicker.scale.set(this.spriteSize, this.spriteSize, this.spriteSize);

      updateObjectLayers(curPicker, showControl);
    }

  };

  this.refreshFacePicker = function (target) {

    let pos = target ? targetObjPosition : objPosition;
    let bBoxSize = target ? targetBoundingBoxSize : boundingBoxSize;
    let hoverPickerName = target ? targetFaceHoverPickerName : faceHoverPickerName;
    let picker = target ? this.targetFacePicker : this.facePicker;
    let mode = target ? this.dropMode : this.dragMode;
    let pickingAxis = target ? this.dropAxis : this.dragAxis;
    let showControl = target ? this.isDragging : !this.isDragging;

    if (DRAG_DROP_MODE.FACE === mode || showControl) {

      picker.visible = true;

      let facePickerArray = picker.children;

      for (let i = facePickerArray.length; i--;) {

        let curPicker = facePickerArray[i];
        let axis = curPicker.name;

        let curInfo = FACE_PICKER_INFO[axis];

        if (hoverPickerName === axis)
          curPicker.children[0].material = this.materials.face.hover;
        else
          curPicker.children[0].material = this.materials.face.normal;

        curPicker.position.copy(pos).add(curInfo.position.clone().multiply(bBoxSize));

        curPicker.quaternion.setFromUnitVectors(unitZ, curInfo.offset);

        let width = bBoxSize.dot(curInfo.width);
        let height = bBoxSize.dot(curInfo.height);

        curPicker.children[0].scale.set(width * 0.8, height * 0.8, 1);

        if (this.eye.dot(curInfo.offset) < 0)
          curPicker.children[1].scale.set(width * 0.8, height * 0.8, 1);
        else
          curPicker.children[1].scale.set(width, height, 1);

        let newVisible = showControl;
        newVisible = newVisible || (DRAG_DROP_MODE.FACE === mode && pickingAxis === axis);

        updateObjectLayers(curPicker, newVisible);
      }

    }

  };

  this.refreshVertexPicker = function (target) {

    let pos = target ? targetObjPosition : objPosition;
    let bBoxSize = target ? targetBoundingBoxSize : boundingBoxSize;
    let hoverPickerName = target ? targetVertexHoverPickerName : vertexHoverPickerName;
    let picker = target ? this.targetVertexPicker : this.vertexPicker;
    let mode = target ? this.dropMode : this.dragMode;
    let pickingAxis = target ? this.dropAxis : this.dragAxis;
    let showControl = target ? this.isDragging : !this.isDragging;

    if (DRAG_DROP_MODE.VERTEX === mode || showControl) {

      picker.visible = true;

      let vertexPickerArray = picker.children;

      for (let i = vertexPickerArray.length; i--;) {

        let curPicker = vertexPickerArray[i];
        let axis = curPicker.name;

        let curInfo = VERTEX_PICKER_INFO[axis];

        if (hoverPickerName === axis)
          curPicker.children[0].material = this.materials.vertex.hover;
        else
          curPicker.children[0].material = this.materials.vertex.normal;

        curPicker.position.copy(pos).add(curInfo.position.clone().multiply(bBoxSize.clone().sub(new Vector3(this.spriteSize / 2, this.spriteSize / 2, this.spriteSize / 2))));

        curPicker.scale.set(this.spriteSize, this.spriteSize, this.spriteSize);

        let newVisible = showControl;
        newVisible = newVisible || (DRAG_DROP_MODE.VERTEX === mode && pickingAxis === axis);

        updateObjectLayers(curPicker, newVisible);

      }

    }

  };

  this.refreshPreviewObject = function () {

    if (this.dragMode && this.dropMode && this.previewObject) {

      this.previewObject.matrix.copy(this.object.matrix);
      this.previewObject.visible = true;

      ([this.v1, this.n1] = this.getPositionNormalVector());
      ([this.v2, this.n2] = this.getPositionNormalVector(true));

      if (hoverBox && (targetHoverBox || targetHoverFloor)) {
        this.n2.negate();
      }

      if (this.v1 && this.v2 && this.n1 && this.n2) {
        if (!this.shiftKeyState)
          this.n2.negate();

        this.previewObject.matrix
          .premultiply(new Matrix4().makeTranslation(-this.v1.x, -this.v1.y, -this.v1.z))
          .premultiply(new Matrix4().makeRotationFromQuaternion(new Quaternion().setFromUnitVectors(this.n1, this.n2)))
          .premultiply(new Matrix4().makeTranslation(this.v2.x, this.v2.y, this.v2.z));

      }

    }

  };

  this.refreshSelectedFace = function (target) {

    let picker = target ? this.targetSelectedFace : this.selectedFace;
    let mode = target ? this.dropMode : this.dragMode;

    if (DRAG_DROP_MODE.OBJECT === mode || hoverObject) {

      let fIndex = target ? targetFaceIndex : faceIndex;
      let iId = target ? targetInstanceId : instanceId;
      let mesh = target ? targetFaceMesh : faceMesh;

      if (mesh) {

        let vertices = this.getFaceVertices(mesh, iId, fIndex);

        let center = new Vector3();

        for (let i = 0; i < 3; ++i) {
          for (let j = 0; j < 3; ++j)
            center.setComponent(j, center.getComponent(j) + vertices[i * 3 + j]);
        }

        center.multiplyScalar(1 / 3);

        for (let i = 0; i < 3; ++i) {
          for (let j = 0; j < 3; ++j)
            vertices[i * 3 + j] -= center.getComponent(j);
        }

        picker.geometry.attributes.position.set(vertices);
        picker.geometry.attributes.position.needsUpdate = true;
        picker.geometry.computeBoundingSphere();

        let size = picker.geometry.boundingSphere.radius;

        if (size < this.spriteSize * 2 && size)
          picker.scale.set(this.spriteSize * 2 / size, this.spriteSize * 2 / size, this.spriteSize * 2 / size);
        else
          picker.scale.set(1, 1, 1);

        picker.position.copy(center);

        picker.visible = true;

      }

    }

  };


  this.refreshFloorFace = function () {

    let picker = this.targetFloorFace;
    let mode = this.dropMode;

    if (DRAG_DROP_MODE.FLOOR === mode || targetHoverFloor) {

      if (targetFloorPos) {

        picker.position.copy(targetFloorPos);
        picker.scale.set(this.spriteSize, this.spriteSize, this.spriteSize);
        picker.visible = true;

      }

    }

  };

  this.refresh = function () {

    let newState = {};

    newState.enabled = this.enabled;
    this.boundingBoxLines.visible = false;
    this.boxPicker.visible = false;
    this.facePicker.visible = false;
    this.vertexPicker.visible = false;
    this.selectedFace.visible = false;
    this.targetBoundingBoxLines.visible = false;
    this.targetBoxPicker.visible = false;
    this.targetFacePicker.visible = false;
    this.targetVertexPicker.visible = false;
    this.targetSelectedFace.visible = false;
    this.targetFloorFace.visible = false;
    this.pathLine.visible = false;
    if (this.previewObject)
      this.previewObject.visible = false;

    for (let picker of [this.boxPicker, ...this.facePicker.children, ...this.vertexPicker.children, this.targetBoxPicker, ...this.targetFacePicker.children, ...this.targetVertexPicker.children])
      updateObjectLayers(picker, false);

    if (this.enabled && this.object !== undefined) {

      newState.space = this.space;
      newState.objectId = this.object.id;

      if (this.space === 'world') {

        this.controlGroup.matrix = new Matrix4();

      } else {

        this.controlGroup.matrix = this.getControlGroupMatrix();

      }

      newState.visible = this.visible;

      if (this.visible) {

        boundingBoxSize = new Vector3();
        this.getObjectBoundingBoxSize(boundingBoxSize);

        if (boundingBoxSize.x > 0 || boundingBoxSize.y > 0 || boundingBoxSize.z > 0) {

          this.eye = this.camera.getWorldDirection(new Vector3());

          this.object.matrix.decompose(
            objPosition,
            objQuaternion,
            objScale
          );

          newState.eye = this.eye.toArray();
          newState.position = objPosition.toArray();
          newState.quaternion = objQuaternion.toArray();
          newState.scale = objScale.toArray();
          newState.dragMode = this.dragMode;
          newState.hoverObject = hoverObject;
          newState.hoverBox = hoverBox;
          newState.faceHoverPickerName = faceHoverPickerName;
          newState.vertexHoverPickerName = vertexHoverPickerName;
          newState.meshName = faceMesh ? faceMesh.name : '';
          newState.faceIndex = faceIndex ? faceIndex : 0;
          newState.instanceId = instanceId ? instanceId : 0;
          newState.gridEnabled = this.gridEnabled;
          newState.boxEnabled = this.boxEnabled;
          newState.faceEnabled = this.faceEnabled;
          newState.vertexEnabled = this.vertexEnabled;
          newState.targetHoverObject = targetHoverObject;
          newState.targetHoverBox = targetHoverBox;
          newState.targetHoverFloor = targetHoverFloor;
          newState.shiftKeyState = this.shiftKeyState;
          newState.distanceRatio = this.distanceRatio;
          newState.targetFaceHoverPickerName = targetFaceHoverPickerName;
          newState.targetVertexHoverPickerName = targetVertexHoverPickerName;
          newState.targetMeshName = targetFaceMesh ? targetFaceMesh.name : '';
          newState.targetFaceIndex = targetFaceIndex ? targetFaceIndex : 0;
          newState.targetInstanceId = targetInstanceId ? targetInstanceId : 0;
          newState.targetFloorPos = targetFloorPos ? targetFloorPos.toArray() : [];
          newState.exceedTick = (lastEventTick + Config.ShowTime >= curTick);
          newState.spriteSize = this.spriteSize;

          this.refreshBoundingBoxLines();
          if (this.boxEnabled)
            this.refreshBoxPicker();
          if (this.faceEnabled)
            this.refreshFacePicker();
          if (this.vertexEnabled)
            this.refreshVertexPicker();
          if (this.faceEnabled)
            this.refreshSelectedFace();
          if (this.gridEnabled)
            this.refreshFloorFace();
          this.refreshPathLine();
          this.refreshPreviewObject();

          if (this.targetObject) {

            this.targets[this.targetId].box.getSize(targetBoundingBoxSize);
            this.targets[this.targetId].box.getCenter(targetObjPosition);

            this.refreshBoundingBoxLines(true);
            if (this.boxEnabled)
              this.refreshBoxPicker(true);
            if (this.faceEnabled)
              this.refreshFacePicker(true);
            if (this.vertexEnabled)
              this.refreshVertexPicker(true);
            if (this.faceEnabled)
              this.refreshSelectedFace(true);

          }

        }

      }

    }

    // Object3D.prototype.updateMatrixWorld.call(this)
    let hadUpdate = !lod.isEqual(newState, this.oldState);
    this.oldState = newState;
    return hadUpdate;

  };

  this.getPointer = function (event) {
    if (!scope.elemRect)
      scope.refreshBoundingClientRect();

    let pointer = event.changedTouches ? event.changedTouches[0] : event;

    scope.mouseOnCanvas.x = pointer.clientX - scope.elemRect.left;
    scope.mouseOnCanvas.y = pointer.clientY - scope.elemRect.top;

    scope.mouse.x = scope.mouseOnCanvas.x / scope.elemRect.width * 2 - 1;
    scope.mouse.y = -scope.mouseOnCanvas.y / scope.elemRect.height * 2 + 1;

    return {
      x: (pointer.clientX - scope.elemRect.left) / scope.elemRect.width * 2 - 1,
      y: -(pointer.clientY - scope.elemRect.top) / scope.elemRect.height * 2 + 1,
      button: event.button,
      type: event.changedTouches ? 'touch' : 'mouse'
    };

  };

  this.getFaceVertices = (mesh, instanceId, faceIndex) => {

    let geometry = mesh.geometry;
    let matrix = mesh.matrixWorld.clone();

    if (mesh.isInstancedMesh) {

      let instanceMatrix = new Matrix4();
      mesh.getMatrixAt(instanceId, instanceMatrix);

      matrix.multiply(instanceMatrix);

    }

    let position = geometry.attributes.position.array;

    let f3 = faceIndex * 3;
    let verts;
    let p0, p1, p2;

    if (geometry.index) {

      let index = geometry.index.array;
      p0 = index[f3] * 3;
      p1 = index[f3 + 1] * 3;
      p2 = index[f3 + 2] * 3;

    } else {

      p0 = f3 * 3;
      p1 = f3 * 3 + 3;
      p2 = f3 * 3 + 6;

    }

    verts = [
      new Vector3(position[p0], position[p0 + 1], position[p0 + 2]),
      new Vector3(position[p1], position[p1 + 1], position[p1 + 2]),
      new Vector3(position[p2], position[p2 + 1], position[p2 + 2])
    ];

    verts.map(v => v.applyMatrix4(matrix));

    return verts.flatMap(v => v.toArray());

  };

  this.pointerDown = function (event, pointer) {

    lastEventTick = -Config.ShowTime - 1;
    if (!this.object) return;
    if (pointer.type === 'mouse' && pointer.button !== 0) return;
    if (!this.enabled || !this.controlEnabled) return;

    this.shiftKeyState = event.shiftKey;

    this.dragEndPosition = new Vector3(this.mouse.x, this.mouse.y, 0).unproject(this.camera);

    let intersect;
    this.rayCaster.setFromCamera(pointer, this.camera);

    this.eye = this.camera.getWorldDirection(new Vector3());
    this.getObjectUnitAxes(objUnitX, objUnitY, objUnitZ);
    this.distanceRatio = 1;

    this.object.matrix.decompose(
      objPosition,
      objQuaternion,
      objScale
    );

    // intersect = this.rayCaster.intersectObject(this.boxPicker, true)[0] || false;
    //
    // if (intersect && this.boxEnabled) {
    //
    //   this.dragMode = DRAG_DROP_MODE.BOX;
    //   this.dragStartPosition = intersect.point;
    //   this.isDragging = true;
    //   event.preventDefault();
    //
    //   return;
    //
    // }

    intersect = this.rayCaster.intersectObject(this.vertexPicker, true)[0] || false;

    if (intersect && this.vertexEnabled) {

      this.dragMode = DRAG_DROP_MODE.VERTEX;
      this.dragAxis = intersect.object.name;
      this.dragStartPosition = intersect.point;
      this.isDragging = true;
      event.preventDefault();

      return;

    }

    intersect = this.rayCaster.intersectObject(this.object, true)[0] || false;

    if (intersect && this.faceEnabled) {

      this.dragMode = DRAG_DROP_MODE.OBJECT;
      this.dragStartPosition = intersect.point;
      this.isDragging = true;
      event.preventDefault();

      return;

    }

    intersect = this.rayCaster.intersectObject(this.facePicker, true)[0] || false;

    if (intersect && this.faceEnabled) {

      this.dragMode = DRAG_DROP_MODE.FACE;
      this.dragAxis = intersect.object.name;
      this.dragStartPosition = intersect.point;
      this.isDragging = true;
      event.preventDefault();

      return;

    }

    if (!this.isDragging) {
      this.dragMode = undefined;
      this.dropMode = undefined;
      lastEventTick = -Config.ShowTime - 1;
    }

  };

  this.pointerMove = function (event, pointer) {

    if (!this.object) return;

    this.shiftKeyState = event.shiftKey;

    this.rayCaster.setFromCamera(pointer, this.camera);

    targetHoverObject = undefined;
    targetHoverBox = undefined;
    targetFaceHoverPickerName = undefined;
    targetVertexHoverPickerName = undefined;
    targetFaceMesh = undefined;
    targetInstanceId = undefined;
    targetFaceIndex = undefined;
    targetHoverFloor = undefined;
    targetFloorPos = undefined;

    if (!this.isDragging) {

      let intersect;

      hoverObject = undefined;
      hoverBox = undefined;
      faceHoverPickerName = undefined;
      vertexHoverPickerName = undefined;
      faceMesh = undefined;
      instanceId = undefined;
      faceIndex = undefined;

      // intersect = this.rayCaster.intersectObject(this.boxPicker, true)[0] || false;
      //
      // if (intersect && this.boxEnabled) {
      //
      //   hoverBox = true;
      //   return;
      //
      // }

      intersect = this.rayCaster.intersectObject(this.vertexPicker, true)[0] || false;

      if (intersect && this.vertexEnabled) {

        vertexHoverPickerName = intersect.object.name;
        return;

      }

      intersect = this.rayCaster.intersectObject(this.object, true)[0] || false;

      if (intersect && this.faceEnabled) {
        hoverObject = true;
        faceMesh = intersect.object;
        instanceId = intersect.instanceId;
        faceIndex = intersect.faceIndex;
        return;
      }

      intersect = this.rayCaster.intersectObject(this.facePicker, true)[0] || false;

      if (intersect && this.faceEnabled) {

        faceHoverPickerName = intersect.object.name;
        return;

      }

      return;

    }

    event.preventDefault();

    this.dragEndPosition = new Vector3(this.mouse.x, this.mouse.y, 0).unproject(this.camera);

    let boxes = Object.values(this.targets).map(t => t.mesh);
    let intersect = this.rayCaster.intersectObjects(boxes)[0] || false;

    this.dropMode = undefined;

    if (intersect) {

      this.targetId = intersect.object.name;
      this.targetObject = this.targets[this.targetId].obj;

    } else {

      this.targetId = undefined;
      this.targetObject = undefined;

    }

    if (this.targetId) {

      // intersect = this.rayCaster.intersectObject(this.targetBoxPicker, true)[0] || false;
      //
      // if (intersect && this.boxEnabled) {
      //
      //   targetHoverBox = true;
      //   this.dropMode = DRAG_DROP_MODE.BOX;
      //   return;
      //
      // }

      intersect = this.rayCaster.intersectObject(this.targetVertexPicker, true)[0] || false;

      if (intersect && this.vertexEnabled) {

        targetVertexHoverPickerName = intersect.object.name;
        this.dropMode = DRAG_DROP_MODE.VERTEX;
        this.dropAxis = intersect.object.name;
        return;

      }

      intersect = this.rayCaster.intersectObject(this.targetObject, true)[0] || false;

      if (intersect && this.faceEnabled) {
        targetHoverObject = true;
        targetFaceMesh = intersect.object;
        targetInstanceId = intersect.instanceId;
        targetFaceIndex = intersect.faceIndex;
        this.dropMode = DRAG_DROP_MODE.OBJECT;
        return;
      }

      intersect = this.rayCaster.intersectObject(this.targetFacePicker, true)[0] || false;

      if (intersect && this.faceEnabled) {

        targetFaceHoverPickerName = intersect.object.name;
        this.dropMode = DRAG_DROP_MODE.FACE;
        this.dropAxis = intersect.object.name;
        return;

      }

    } else {

      intersect = this.rayCaster.intersectObject(this.intersectPlane, true)[0] || false;

      if (intersect && this.gridEnabled) {

        targetHoverFloor = true;
        targetFloorPos = intersect.point;
        this.dropMode = DRAG_DROP_MODE.FLOOR;
        return;

      }

    }

  };

  this.pointerUp = function (event, pointer) {

    this.isDragging = false;

    if ((pointer.type === 'mouse' && pointer.button !== 0) || !this.dragMode) return;
    lastEventTick = curTick;

    this.shiftKeyState = event.shiftKey;

    if (this.visible && this.object) {

      targetHoverObject = undefined;
      targetHoverBox = undefined;
      targetFaceHoverPickerName = undefined;
      targetVertexHoverPickerName = undefined;
      targetFaceMesh = undefined;
      targetInstanceId = undefined;
      targetFaceIndex = undefined;

      hoverObject = undefined;
      hoverBox = undefined;
      faceHoverPickerName = undefined;
      vertexHoverPickerName = undefined;
      faceMesh = undefined;
      instanceId = undefined;
      faceIndex = undefined;

      if (this.dragMode && this.dropMode) {

        this.object.matrix.copy(this.previewObject.matrix);
        scope.dispatchEvent({type: 'change'});

      }

      this.dragMode = undefined;
      this.dropMode = undefined;

    }
  };

  this.commitDistanceRatio = function (ratio) {
    let unitLength = (boundingBoxSize.x + boundingBoxSize.y + boundingBoxSize.z) / 3;
    if (this.n1) {
      let length = (this.distanceRatio - ratio) * unitLength;
      this.object.matrix.premultiply(
        new Matrix4().makeTranslation(length * this.n1.x, length * this.n1.y, length * this.n1.z)
      );

      this.distanceRatio = ratio;
      scope.dispatchEvent({type: 'change'});
    }
  };

  function onPointerDown(event) {
    scope.pointerDown(event, scope.getPointer(event));
  }

  function onTouchDown(event) {
    scope.refreshBoundingClientRect();
    scope.pointerDown(event, scope.getPointer(event));
  }

  function onPointerMove(event) {
    scope.pointerMove(event, scope.getPointer(event));
  }

  function onPointerUp(event) {
    scope.pointerUp(event, scope.getPointer(event));
  }

  this.createVertexPickerMaterial = function (hover) {

    return new MeshBasicMaterial({color: hover ? 0x5B4CFF : 0xFFFFFF});

  };

  this.createVertexPickerUnit = function (name) {

    let group = new Group();

    let geometry = new SphereBufferGeometry(0.2);
    let outerGeometry = new SphereBufferGeometry(0.4);

    let sphereMesh = new Mesh(geometry);

    let outerSphereMesh = new Mesh(outerGeometry, new MeshBasicMaterial({color: 0x000000, side: BackSide}));

    group.add(sphereMesh);
    group.add(outerSphereMesh);

    sphereMesh.name = name;
    outerSphereMesh.name = name;
    group.name = name;

    return group;

  };

  this.createVertexPicker = function (target) {
    let group = new Group();

    for (let name in VERTEX_PICKER_INFO) {
      group.add(this.createVertexPickerUnit(name));
    }

    group.visible = false;

    if (target)
      this.controlScene.add(group);
    else
      this.controlGroup.add(group);
    return group;
  };

  this.createSelectedFace = function () {
    let geometry = new BufferGeometry();
    geometry.index = new Uint32BufferAttribute([0, 1, 2], 1);
    geometry.attributes.position = new Float32BufferAttribute(new Array(9).fill(0), 3);

    let mesh = new Mesh(geometry, new MeshBasicMaterial({
      color: 0x5B4CFF,
      side: DoubleSide,
      transparent: true,
      opacity: 0.4,
      depthTest: false
    }));

    mesh.visible = false;

    this.controlScene.add(mesh);
    return mesh;
  };

  this.createFloorFace = function () {
    let geometry = new CylinderBufferGeometry(1, 1, 0.1);

    let mesh = new Mesh(geometry, new MeshBasicMaterial({
      color: 0x5B4CFF,
      side: DoubleSide,
      transparent: true,
      opacity: 0.4,
      depthTest: false
    }));

    mesh.quaternion.setFromUnitVectors(unitY, unitZ);
    mesh.visible = false;

    this.controlScene.add(mesh);
    return mesh;
  };

  this.createBoundingBoxLines = function (target) {
    let group = new Group();

    let lineMaterial = new LineMaterial({color: 0x0D0032, linewidth: 1});

    for (let i = 0; i < 13; ++i) {
      let lineGeometry = new LineGeometry();
      lineGeometry.setPositions(new Array(6).fill(0));

      let line = new Line2(lineGeometry, lineMaterial);
      line.computeLineDistances();

      group.add(line);
    }

    group.visible = false;

    if (target)
      this.controlScene.add(group);
    else
      this.controlGroup.add(group);
    return group;
  };

  this.createPathLineMaterial = function (valid) {

    return new LineMaterial({color: valid ? 0x5B4CFF : 0xFF0000, linewidth: 2});

  };

  this.createPathLine = function () {

    let lineGeometry = new LineGeometry();
    lineGeometry.setPositions(new Array(6).fill(0));

    let line = new Line2(lineGeometry, this.materials.path.invalid);
    line.computeLineDistances();

    line.name = "path-line";

    line.visible = false;
    this.controlScene.add(line);
    return line;

  };

  this.createFacePickerMaterial = function (hover) {

    return new MeshBasicMaterial({
      color: 0x5B4CFF,
      transparent: true,
      opacity: hover ? 0.5 : 0,
      side: DoubleSide
    });

  };

  this.createFacePickerUnitForAxis = function (axis) {
    let group = new Group();

    let geometry = new PlaneBufferGeometry(1, 1);

    let hoverPlane = new Mesh(geometry);
    let interPlane = new Mesh(geometry, new MeshBasicMaterial({
      transparent: true,
      opacity: 0,
      visible: false,
      side: DoubleSide
    }));

    group.add(hoverPlane, interPlane);

    hoverPlane.name = axis;
    interPlane.name = axis;
    group.name = axis;

    return group;
  };

  this.createFacePicker = function (target) {
    let group = new Group();

    for (let axis in FACE_PICKER_INFO)
      group.add(this.createFacePickerUnitForAxis(axis));

    group.visible = false;

    if (target)
      this.controlScene.add(group);
    else
      this.controlGroup.add(group);
    return group;
  };

  this.createBoxPickerMaterial = function (hover) {

    return new MeshBasicMaterial({
      color: hover ? 0x5B4CFF : 0xFFFFFF,
      transparent: true,
      depthTest: false,
    });

  };

  this.createBoxPicker = function (target) {

    let geometry = new SphereBufferGeometry(0.6);
    let sphereMesh = new Mesh(geometry);
    sphereMesh.name = 'box';

    sphereMesh.visible = false;

    if (target)
      this.controlScene.add(sphereMesh);
    else
      this.controlGroup.add(sphereMesh);
    return sphereMesh;

  };

  this.materials = {
    box: {
      hover: this.createBoxPickerMaterial(true),
      normal: this.createBoxPickerMaterial(false)
    },
    vertex: {
      hover: this.createVertexPickerMaterial(true),
      normal: this.createVertexPickerMaterial(false)
    },
    face: {
      hover: this.createFacePickerMaterial(true),
      normal: this.createFacePickerMaterial(false)
    },
    path: {
      valid: this.createPathLineMaterial(true),
      invalid: this.createPathLineMaterial(false),
    },
    object: createGhostedMaterial(0xFF0000)
  };

  // Children
  this.controlGroup = new Group();
  this.controlGroup.name = `snap-control-group`;
  this.controlGroup.matrixAutoUpdate = false;

  this.boundingBoxLines = this.createBoundingBoxLines();
  this.pathLine = this.createPathLine();
  this.vertexPicker = this.createVertexPicker();
  this.boxPicker = this.createBoxPicker();
  this.facePicker = this.createFacePicker();
  this.selectedFace = this.createSelectedFace();

  this.targetBoundingBoxLines = this.createBoundingBoxLines(true);
  this.targetVertexPicker = this.createVertexPicker(true);
  this.targetBoxPicker = this.createBoxPicker(true);
  this.targetFacePicker = this.createFacePicker(true);
  this.targetSelectedFace = this.createSelectedFace();
  this.targetFloorFace = this.createFloorFace();

  this.controlScene.add(this.controlGroup);
  this.controlScene.add(this.intersectPlane);
};

SnapControl.prototype = Object.assign(Object.create(Object3D.prototype), Object.create(EventDispatcher.prototype),
  {

    constructor: SnapControl,

    isSnapControls: true

  });

export {SnapControl};