import {IDigitalMaterial, NormalizedStringIndexedObjects} from "../types";
import {InitStoreAction} from "../init";

export const REPLACE_DIGITAL_MATERIALS = 'REPLACE_DIGITAL_MATERIALS';
export const ADD_DIGITAL_MATERIALS = 'ADD_DIGITAL_MATERIALS';
export const REMOVE_DIGITAL_MATERIAL = 'REMOVE_DIGITAL_MATERIAL';

export interface IEntityDigitalMaterialsState extends NormalizedStringIndexedObjects<IDigitalMaterial> {
}

interface ReplaceDigitalMaterialsAction {
  type: typeof REPLACE_DIGITAL_MATERIALS
  payload: {
    materials: IDigitalMaterial[]
  }
}

interface AddDigitalMaterialsAction {
  type: typeof ADD_DIGITAL_MATERIALS
  payload: {
    materials: IDigitalMaterial[]
  }
}

interface RemoveDigitalMaterialAction {
  type: typeof REMOVE_DIGITAL_MATERIAL
  payload: {
    id: string
  }
}


export type DigitalMaterialsActionTypes = ReplaceDigitalMaterialsAction |
  AddDigitalMaterialsAction |
  RemoveDigitalMaterialAction |
  InitStoreAction