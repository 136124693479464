import {ComponentTypes} from "../types";
import {three as threeTypes} from "../../../types";
import {createGumball} from "../gumball";
import {Param_Mesh, ParamTitles} from "../../parameter";
import {MeshResolution} from "../../const";
import {Calc} from "../../calc";
import {WompMesh} from "../../../WompObject";
import {mat4, vec3} from "gl-matrix";
import {getMeshDataFromThreeGeometry} from "../../../converter";
import {BasicMeshGenerator} from "./basic-gen";

export class ThreeCylinderGenerator extends BasicMeshGenerator {
  static defaultParameter = {
    radius: 10,
    height: 20
  };
  static draggingPreview = ThreeCylinderGenerator.generateMesh();

  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.ThreeCylinderGenerator;
    calc.title = 'cylinder';

    let cylinderParam = Param_Mesh.create(calc, ParamTitles.Cylinder, false, false, true);

    calc.addParameter(cylinderParam);

    return calc;
  }

  static getDesc(calc: Calc) {
    return 'cylinder';
  }

  static generateMesh(parameter: any = this.defaultParameter) {
    let value: threeTypes.BufferGeometry = new threeTypes.CylinderBufferGeometry(
      this.defaultParameter.radius,
      this.defaultParameter.radius,
      this.defaultParameter.height,
      MeshResolution,
      MeshResolution / 1.5
    );

    value.rotateX(Math.PI / 2);

    let geom = getMeshDataFromThreeGeometry(value);

    return new WompMesh(geom, this.generateMatrix(parameter));
  }

  static generateMatrix(parameter: any = this.defaultParameter) {
    return mat4.fromScaling(
      mat4.create(),
      vec3.fromValues(
        parameter.radius / this.defaultParameter.radius,
        parameter.radius / this.defaultParameter.radius,
        parameter.height / this.defaultParameter.height
      )
    );
  }
}