import {WompCurveData} from "../WompObject";
import {vec3} from "gl-matrix";

export function getPolylineFromArray(points: vec3[], periodic: boolean): WompCurveData | undefined {
  if (points.length < 2) return;

  let pointArray: number[][] = [];

  for (let point of points)
    pointArray.push([point[0], point[1], point[2]]);

  return {
    points: pointArray,
    periodic,
    bezier: false,
    degree: 1
  };
}
