import {
  CHANGE_LIBRARY_STATE,
  ILibraryState,
  LibraryActionTypes
} from "../../types/ui/library";
import {INIT_STORE} from "../../types/init";

const initialState: ILibraryState = {
  searchText: '',
  selectedSection: ''
};

export function libraryReducer(
  state = initialState,
  action: LibraryActionTypes
): ILibraryState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case CHANGE_LIBRARY_STATE:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}