import {BackSide, LinearFilter, LinearMipmapLinearFilter, NoBlending, RGBAFormat} from 'three/src/constants';
import {Matrix4, Mesh, BoxGeometry, ShaderMaterial, WebGLRenderTarget, CubeTexture} from 'three';
import {cloneUniforms} from 'three/src/renderers/shaders/UniformsUtils'
import {CubeCamera} from "./CubeCamera";

class WebGLCubeRenderTarget extends WebGLRenderTarget {

  constructor(size, options, dummy) {

    if (Number.isInteger(options)) {

      console.warn('THREE.WebGLCubeRenderTarget: constructor signature is now WebGLCubeRenderTarget( size, options )');

      options = dummy;

    }

    super(size, size, options);

    Object.defineProperty(this, 'isWebGLCubeRenderTarget', {value: true});

    options = options || {};

    this.texture = new CubeTexture(undefined, options.mapping, options.wrapS, options.wrapT, options.magFilter, options.minFilter, options.format, options.type, options.anisotropy, options.encoding);

    this.texture._needsFlipEnvMap = false;

  }

  fromEquirectangularTexture(renderer, texture) {

    this.texture.type = texture.type;
    this.texture.format = RGBAFormat; // see #18859
    this.texture.encoding = texture.encoding;

    this.texture.generateMipmaps = texture.generateMipmaps;
    this.texture.minFilter = texture.minFilter;
    this.texture.magFilter = texture.magFilter;

    const shader = {

      uniforms: {
        tEquirect: {value: null},
      },

      vertexShader: /* glsl */`

				varying vec3 vWorldDirection;

				vec3 transformDirection( in vec3 dir, in mat4 matrix ) {

					return normalize( ( matrix * vec4( dir, 0.0 ) ).xyz );

				}

				void main() {

					vWorldDirection = transformDirection( position, modelMatrix );

					#include <begin_vertex>
					#include <project_vertex>

				}
			`,

      fragmentShader: /* glsl */`

				uniform sampler2D tEquirect;

				varying vec3 vWorldDirection;

				#include <common>

				void main() {

					vec3 direction = normalize( vWorldDirection );

					vec2 sampleUV = equirectUv( direction );

					gl_FragColor = texture2D( tEquirect, sampleUV );

				}
			`
    };

    const geometry = new BoxGeometry(5, 5, 5);

    const material = new ShaderMaterial({

      name: 'CubemapFromEquirect',

      uniforms: cloneUniforms(shader.uniforms),
      vertexShader: shader.vertexShader,
      fragmentShader: shader.fragmentShader,
      side: BackSide,
      blending: NoBlending

    });

    material.uniforms.tEquirect.value = texture;

    const mesh = new Mesh(geometry, material);
    mesh.geometry.applyMatrix4(new Matrix4().makeRotationY(Math.PI / 2));

    const currentMinFilter = texture.minFilter;

    // Avoid blurred poles
    if (texture.minFilter === LinearMipmapLinearFilter) texture.minFilter = LinearFilter;

    const camera = new CubeCamera(1, 10, this);
    camera.update(renderer, mesh);

    texture.minFilter = currentMinFilter;

    mesh.geometry.dispose();
    mesh.material.dispose();

    return this;

  }

  clear(renderer, color, depth, stencil) {

    const currentRenderTarget = renderer.getRenderTarget();

    for (let i = 0; i < 6; i++) {

      renderer.setRenderTarget(this, i);

      renderer.clear(color, depth, stencil);

    }

    renderer.setRenderTarget(currentRenderTarget);

  }

}


export {WebGLCubeRenderTarget};
