import {
  decodeAnnotate,
  decodeCameraInfo,
  decodeEnvironment,
  decodeFloor,
  decodeLight,
  decodeMeasure,
  defaultEnvironment,
  defaultFloor,
  defaultLightEnvironment, defaultSceneAnalysisInfo,
  defaultScene, defaultToolConfig,
  encodeAnnotate,
  encodeCameraInfo,
  encodeEnvironment,
  encodeFloor,
  encodeLight,
  encodeMeasure, encodeToolConfig,
  IAnnotate, ICalc,
  ICameraInfo,
  IEnvironment,
  IFloor,
  ILight,
  IMeasure, IScene, ISceneAnalysisInfo, IToolConfig, MaterialTypes,
  Tools, ViewTypes, decodeToolConfig, MeasureUnit
} from './types';
import lod from 'lodash';
import {
  Param_Brep,
  Param_Compound,
  Param_Mesh,
  Parameter,
  ParamTitles,
  ParamTypes, RenderableParamTypes
} from "./parameter";
import {ObjectTypes, RenderedObjectTypes, XObject} from "./xobject";
import {Calc} from "./calc";
import {Relation} from "./relation";
import {BetaSceneVersion, InvalidId} from "./const";
import {decodeGeometryOnWorker, decodeSculptGeometryOnWorker} from "./workers";
import {getRenderedObjectsBoundingBox} from "../helper";
import {_b, _c, _m, _n, _s, _t, isShallowEqual} from "../t";
import {MeshCodec, BrepCodec, SculptMeshCodec, CurveCodec} from "../types";
import {
  disposeDracoWorkers,
  encodeGeometryCodecTrans,
  decodeBrepGeometry,
  encodeBrepGeometryCodecTrans,
  decodeCurveGeometry,
  encodeCurveGeometryCodecTrans
} from "../utils";
import {getGeometryFromSculptGeometry} from "../converter";
import {peregrineId} from "../id";
import numeral from "numeral";
import {nanoid} from "nanoid";

export interface OperationStatus {
  fetching: boolean
  waitingCalcIdSet: Set<string>
  infos: any
  options?: any
}

export class Scene extends XObject {
  protected _objectType = ObjectTypes.Scene;

  get id() {
    return this._id;
  }

  set id(id: string) {
    if (this._id !== id) {
      this._id = id;

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('id')]: id
        };
      }
    }
  }

  get projectId() {
    return this._state.projectId;
  }

  set projectId(projectId: number) {
    if (this._state.projectId !== projectId) {
      if (!this.locked)
        console.warn('scene was not locked before [property:projectId]');

      this._state = {
        ...this._state,
        projectId
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('projectId')]: projectId
        };
      }
    }
  }

  get analysisInfo() {
    return this._state.analysisInfo;
  }

  protected _setAnalysisInfo(analysisInfo: ISceneAnalysisInfo) {
    if (this._state.analysisInfo !== analysisInfo) {
      this._state = {
        ...this._state,
        analysisInfo
      };
    }
  }

  get calcIds() {
    return this._state.calcIds;
  }

  protected _setCalcIds(calcIds: string[]) {
    if (this._state.calcIds !== calcIds) {
      this._state = {
        ...this._state,
        calcIds
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('calcIds')]: calcIds
        };
      }
    }
  }

  protected _calcs: { [id: string]: Calc } = {};

  get calcs() {
    return this._calcs;
  }

  protected _relationIds: string[] = [];

  get relationIds() {
    return this._relationIds;
  }

  protected _setRelationIds(relationIds: string[]) {
    if (this._relationIds !== relationIds) {
      this._relationIds = relationIds;

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('relationIds')]: relationIds
        };
      }
    }
  }

  protected _relations: { [id: string]: Relation } = {};

  get relations() {
    return this._relations;
  }

  protected _removeRelation(relationId: string) {
    this._relations = lod.omit(this._relations, relationId);

    if (XObject.UpdateEncodedData) {

      this._data = {
        ...this._data,
        [_s('relations')]: lod.omit(this._data[_s('relations')], relationId)
      };
    }
  }

  protected _addRelation(relation: Relation) {
    this._relations = {...this._relations, [relation.id]: relation};

    if (XObject.UpdateEncodedData) {
      this._data = {
        ...this._data,
        [_s('relations')]: {...this._data[_s('relations')], [relation.id]: relation.data}
      };
    }
  }

  protected _objects: { [id: string]: { type: ObjectTypes, uniqueDesc: string, value: any } } = {};

  get objects() {
    return this._objects;
  }

  protected _setObjects(objects: { [id: string]: { type: ObjectTypes, uniqueDesc: string, value: any } }) {
    if (!lod.isEqual(new Set(Object.keys(this._objects)), new Set(Object.keys(objects)))) {
      this._objects = objects;
      this._dataValid = false;
    }
  }

  protected _calcSequence: string[] = [];
  protected _calcSequenceValid: boolean = false;

  protected _calcIdByTitle: { [key: string]: Set<string> } = {};

  get measures() {
    return this._state.measures;
  }

  set measures(measures: { [key: number]: IMeasure }) {
    if (this._state.measures !== measures) {
      if (!this.locked)
        console.warn('scene was not locked before [property:measures]');

      this._state = {
        ...this._state,
        measures
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('measures')]: lod.mapValues(measures, encodeMeasure)
        };
      }
    }
  }

  get annotates() {
    return this._state.annotates;
  }

  set annotates(annotates: { [key: number]: IAnnotate }) {
    if (this._state.annotates !== annotates) {
      if (!this.locked)
        console.warn('scene was not locked before [property:annotates]');

      this._state = {
        ...this._state,
        annotates
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('annotates')]: lod.mapValues(annotates, encodeAnnotate)
        };
      }
    }
  }

  get magnetMappings() {
    return this._state.magnetMappings;
  }

  set magnetMappings(magnetMappings: { [key: string]: string }) {
    if (this._state.magnetMappings !== magnetMappings) {
      if (!this.locked)
        console.warn('scene was not locked before [property:magnetMappings]');

      this._state = {
        ...this._state,
        magnetMappings
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('magnetMappings')]: magnetMappings
        };
      }
    }
  }

  get floor() {
    return this._state.floor;
  }

  set floor(floor: IFloor) {
    if (!lod.isEqual(this._state.floor, floor)) {
      if (!this.locked)
        console.warn('scene was not locked before [property:floor]');

      this._state = {
        ...this._state,
        floor
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('floor')]: encodeFloor(floor)
        };
      }
    }
  }

  get cameraInfo() {
    return this._state.cameraInfo;
  }

  set cameraInfo(cameraInfo: ICameraInfo | null) {
    if (!lod.isEqual(this._state.cameraInfo, cameraInfo)) {
      if (!this.locked)
        console.warn('scene was not locked before [property:cameraInfo]');

      this._state = {
        ...this._state,
        cameraInfo
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('cameraInfo')]: cameraInfo ? encodeCameraInfo(cameraInfo) : null
        };
      }
    }
  }

  get cameraAngle() {
    return this._state.cameraAngle;
  }

  set cameraAngle(cameraAngle: number) {
    if (this._state.cameraAngle !== cameraAngle) {
      if (!this.locked)
        console.warn('scene was not locked before [property:cameraAngle]');

      this._state = {
        ...this._state,
        cameraAngle
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('cameraAngle')]: _n(cameraAngle)
        };
      }
    }
  }

  get selectedCalcIds() {
    return this._state.selectedCalcIds;
  }

  set selectedCalcIds(calcIds: string[]) {
    if (!this.locked)
      console.warn('scene was not locked before [property:selectedCalcIds]');

    let selectedCalcIds: string[] = [];

    for (let calcId of calcIds) {
      let calc = this.calcs[calcId];
      if (calc) {
        selectedCalcIds.push(calcId);
      }
    }

    if (selectedCalcIds.length === 0) {
      this._setSelectedCalcIds([]);
      return;
    }

    let calcIdCounts: { [key: string]: number } = {'': 0};

    calcIds = selectedCalcIds;
    selectedCalcIds = [];
    for (let calcId of calcIds) {
      let calc = this.calcs[calcId];
      if (calc) {
        let nextCalcs = calc.getNextCalcs();

        for (let calc of nextCalcs) {
          if (calcIdCounts[calc.id] === undefined)
            calcIdCounts[calc.id] = 0;
          ++calcIdCounts[calc.id];
        }

        if (nextCalcs.length === 0)
          ++calcIdCounts[''];
      }
    }

    let maxCalcId = '';
    for (let calcId in calcIdCounts) {
      if (calcIdCounts[calcId] > calcIdCounts[maxCalcId]) {
        maxCalcId = calcId;
      }
    }

    if (maxCalcId === '') {
      this.editLevels = [];
    } else {
      if (this.editLevels.length === 0 || this.editLevels[this.editLevels.length - 1] !== maxCalcId) {
        this.editLevels = this.generateEditLevel(maxCalcId);
      }
    }

    for (let calcId of calcIds) {
      let calc = this.calcs[calcId];
      if (calc) {
        let nextCalcs = calc.getNextCalcs();
        if (this.editLevels.length === 0 && nextCalcs.length === 0) {
          selectedCalcIds.push(calcId);
        } else if (this.editLevels.length > 0) {
          let lastLevel = this.editLevels[this.editLevels.length - 1];
          for (let nextCalc of nextCalcs) {
            if (nextCalc.id === lastLevel) {
              selectedCalcIds.push(calcId);
              break;
            }
          }
        }
      }
    }

    this._setSelectedCalcIds(selectedCalcIds);
  }


  protected _setSelectedCalcIds(selectedCalcIds: string[]) {
    if (!lod.isEqual(this._state.selectedCalcIds, selectedCalcIds)) {
      this._state = {
        ...this._state,
        selectedCalcIds
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('selectedCalcIds')]: selectedCalcIds
        };
      }
    }
  }

  get lightEnvironment() {
    return this._state.lightEnvironment;
  }

  set lightEnvironment(lightEnvironment: IEnvironment) {
    if (!lod.isEqual(this._state.lightEnvironment, lightEnvironment)) {
      if (!this.locked)
        console.warn('scene was not locked before [property:lightEnvironment]');

      this._state = {
        ...this._state,
        lightEnvironment
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('lightEnvironment')]: encodeEnvironment(lightEnvironment)
        };
      }
    }
  }

  get backgroundEnvironment() {
    return this._state.backgroundEnvironment;
  }

  set backgroundEnvironment(backgroundEnvironment: IEnvironment) {
    if (!lod.isEqual(this._state.backgroundEnvironment, backgroundEnvironment)) {
      if (!this.locked)
        console.warn('scene was not locked before [property:backgroundEnvironment]');

      this._state = {
        ...this._state,
        backgroundEnvironment
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('backgroundEnvironment')]: encodeEnvironment(backgroundEnvironment)
        };
      }
    }
  }

  get lights() {
    return this._state.lights;
  }

  set lights(lights: { [key: number]: ILight }) {
    if (this._state.lights !== lights) {
      if (!this.locked)
        console.warn('scene was not locked before [property:lights]');

      this._state = {
        ...this._state,
        lights
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('lights')]: lod.mapValues(lights, encodeLight)
        };
      }
    }
  }

  get viewType() {
    return this._state.viewType;
  }

  set viewType(viewType: number) {
    if (this._state.viewType !== viewType) {
      if (!this.locked)
        console.warn('scene was not locked before [property:viewType]');

      this._state = {
        ...this._state,
        viewType
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('viewType')]: viewType
        };
      }
    }
  }

  get measureUnit() {
    return this._state.measureUnit;
  }

  set measureUnit(measureUnit: MeasureUnit) {
    if (this._state.measureUnit !== measureUnit) {
      if (!this.locked)
        console.warn('scene was not locked before [property:measureUnit]');

      this._state = {
        ...this._state,
        measureUnit
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('measureUnit')]: measureUnit
        };
      }
    }
  }

  get editLevels() {
    return this._state.editLevels;
  }

  set editLevels(editLevels: string[]) {
    if (!lod.isEqual(this._state.editLevels, editLevels)) {
      if (!this.locked)
        console.warn('scene was not locked before [property:editLevels]');

      this._state = {
        ...this._state,
        editLevels
      };
      this._stateValid = false;

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('editLevels')]: editLevels
        };
      }
    }
  }

  get editLevel() {
    return this._state.editLevels.length === 0 ? '': this._state.editLevels[this._state.editLevels.length - 1];
  }

  get tool() {
    return this._state.tool;
  }

  set tool(tool: string) {
    if (this._state.tool !== tool) {
      if (!this.locked)
        console.warn('scene was not locked before [property:tool]');

      this._state = {
        ...this._state,
        tool
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('tool')]: tool
        };
      }
    }
  }

  get toolConfig() {
    return this._state.toolConfig;
  }

  set toolConfig(toolConfig: IToolConfig) {
    if (this._state.toolConfig !== toolConfig) {
      if (!this.locked)
        console.warn('scene was not locked before [property:toolConfig]');

      this._state = {
        ...this._state,
        toolConfig
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('toolConfig')]: encodeToolConfig(toolConfig)
        };
      }
    }
  }

  get isSetAsBackground() {
    return this._state.isSetAsBackground;
  }

  set isSetAsBackground(isSetAsBackground: boolean) {
    if (this._state.isSetAsBackground !== isSetAsBackground) {
      if (!this.locked)
        console.warn('scene was not locked before [property:isSetAsBackground]');

      this._state = {
        ...this._state,
        isSetAsBackground
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('isSetAsBackground')]: _b(isSetAsBackground)
        };
      }
    }
  }

  get version() {
    return this._state.version;
  }

  set version(version: string) {
    if (this._state.version !== version) {
      if (!this.locked)
        console.warn('scene was not locked before [property:version]');

      this._state = {
        ...this._state,
        version
      };

      if (XObject.UpdateEncodedData) {
        this._data = {
          ...this._data,
          [_s('version')]: version
        };
      }
    }
  }

  protected _stateValid: boolean = false;

  protected get stateValid() {
    if (!this._stateValid) {
      return false;
    }

    for (let calcId of this.calcIds) {
      let calc = this._calcs[calcId];
      if (!calc)
        return true;

      if (!calc.stateValid)
        return false;

      if (calc.state !== this._state.calcs[calcId])
        return false;
    }

    return true;
  }

  protected set stateCalcs(calcs: { [key: string]: ICalc }) {
    if (!isShallowEqual(this._state.calcs, calcs)) {
      this._state = {
        ...this._state,
        calcs
      };
    }
  }

  protected _state: IScene = defaultScene;

  get state() {
    if (!this.stateValid)
      this.validateGeneratedState();
    return this._state;
  }

  protected _dataValid: boolean = false;

  protected get dataValid() {
    if (!this._dataValid) {
      return false;
    }

    for (let calcId of this.calcIds) {
      let calc = this._calcs[calcId];
      if (!calc)
        return true;

      if (!calc.dataValid)
        return false;

      if (calc.data !== this._data[_s('calcs')][calcId])
        return false;
    }

    return true;
  }

  protected set dataCalcs(calcs: { [key: string]: any }) {
    if (!isShallowEqual(this._data[_s('calcs')], calcs)) {
      this._data = {
        ...this._data,
        [_s('calcs')]: calcs
      };
    }
  }

  protected set dataObjects(objects: { [key: string]: any }) {
    this._data = {
      ...this._data,
      [_s('objects')]: objects
    };
  }

  protected _data: any = {
    [_s('id')]: InvalidId,
    [_s('projectId')]: 0,
    [_s('calcs')]: {},
    [_s('calcIds')]: [],
    [_s('relations')]: {},
    [_s('relationIds')]: [],
    [_s('selectedCalcIds')]: [],
    [_s('cameraInfo')]: null,
    [_s('cameraAngle')]: 10,
    [_s('annotates')]: {},
    [_s('measures')]: {},
    [_s('magnetMappings')]: {},
    [_s('floor')]: encodeFloor(defaultFloor),
    [_s('isSetAsBackground')]: 0,
    [_s('lights')]: {},
    [_s('lightEnvironment')]: encodeEnvironment(defaultLightEnvironment),
    [_s('backgroundEnvironment')]: encodeEnvironment(defaultEnvironment),
    [_s('editLevels')]: [],
    [_s('tool')]: Tools.Gumball,
    [_s('toolConfig')]: encodeToolConfig(defaultToolConfig),
    [_s('viewType')]: ViewTypes.Rendered,
    [_s('measureUnit')]: MeasureUnit.Milli,
    [_s('version')]: BetaSceneVersion
  };

  get data() {
    if (!this.dataValid)
      this.validateGeneratedData();
    return this._data;
  }

  getMinimalData() {
    return {
      ...this.data,
      [_s('calcs')]: lod.mapValues(this.calcs, c => c.getMinimalData()),
    };
  }

  get isInvalid() {
    return this._id === InvalidId;
  }

  protected static create(): Scene {
    let scene = new this();
    scene.id = peregrineId();

    return scene;
  }

  static async decode(jData?: any, onProgress?: (progress: number) => void): Promise<Scene> {
    let scene = this.create();
    if (jData === undefined || jData[_s('id')] === undefined) {
      console.warn('error decoding the scene', jData);
      return scene;
    }

    await scene.runExclusive(async () => {
      await scene.overwrite(jData, true, onProgress);
    });
    // TODO: BYZ - find out why we used to use false.
    // await scene.overwrite(jData, false);

    return scene;
  }

  runExclusive<T>(callback: () => Promise<T> | T, noLog?: boolean): Promise<T> {
    let sessionId = nanoid(8);
    if (this.locked)
      console.info(`mutex is already locked. waiting for release - ${this.mutexSessionId}`);

    return this.mutex.runExclusive(async () => {
      // if (!noLog)
      //   console.info(`mutex locked - ${sessionId}`);
      this.mutexSessionId = sessionId;

      let res = await callback();
      this.mutexSessionId = '';

      // if (!noLog)
      //   console.info(`mutex released - ${sessionId}`);

      return res;
    });
  }

  protected generateCalcStates() {
    let calcs: { [key: string]: ICalc } = {};

    if (!this._calcSequenceValid) {
      this.validateCalcSequence();
    }

    for (let calcId of this._calcSequence) {
      let calc = this.calcs[calcId];
      calcs[calcId] = calc.state;
    }

    return calcs;
  }

  getRenderingCalcIdsFromScene(): string[] {
    let calcIds: string[] = [];

    for (let calcId of this.calcIds) {
      let calc = this._calcs[calcId];

      if (!calc)
        continue;

      for (let outputId of calc.outputIds) {
        let param = calc.outputs[outputId];
        if (param.render) {
          calcIds.push(calcId);
          break;
        }
      }
    }
    return calcIds;
  }

  registerObject(type: ObjectTypes, obj: any, desc: string, id?: string): string {
    if (!this.locked)
      console.warn('scene was not locked before [registerObject]');

    if (this.isInvalid)
      return '';

    if (!id)
      id = peregrineId();

    if (this._objects[id])
      return id;

    this._setObjects({
      ...this._objects,
      [id]: {type, uniqueDesc: desc, value: obj}
    });
    return id;
  }

  getRegisteredObject(objectId: any) {
    return this._objects[objectId];
  }

  getRegisteredId(desc: string) {
    for (let id in this._objects) {
      if (this._objects[id] && this._objects[id].uniqueDesc === desc)
        return id;
    }
    return '';
  }

  getStaticCalcs(component: string, hash: string) {
    let calcs = [];
    for (let calcId of this.calcIds) {
      let calc = this._calcs[calcId];

      if (!calc)
        continue;

      if (calc.component === component && calc.hash === hash) {
        calcs.push(calc);
      }
    }

    return calcs;
  }

  addCalc(calc: Calc): boolean {
    if (!this.locked)
      console.warn('scene was not locked before [addCalc]');

    if (calc.isInvalid) return false;

    if (this._calcs[calc.id] !== undefined) {
      return false;
    }

    this._calcSequenceValid = false;
    this._stateValid = false;
    this._dataValid = false;

    calc.scene = this;

    this.addCalcTitle(calc.id, calc.title);
    this._calcs[calc.id] = calc;
    this._setCalcIds([...this.calcIds, calc.id]);

    return true;
  }

  addRelation(from: Parameter, to: Parameter, id?: string) {
    if (!this.locked)
      console.warn('scene was not locked before [addRelation]');

    if (from.isInvalid || to.isInvalid) return InvalidId;
    let fromCalc = from.calc as unknown as Calc;
    let toCalc = to.calc as unknown as Calc;
    if (fromCalc.scene !== this || toCalc.scene !== this) return InvalidId;

    if (!this.canAdd(from, to)) return InvalidId;

    this._calcSequenceValid = false;
    this._dataValid = false;

    let relation = Relation.create(this, from, to);
    if (id !== undefined) relation.id = id;

    this._setRelationIds([...this.relationIds, relation.id]);
    this._addRelation(relation);

    return relation.id;
  }

  moveCalc(calcId: string, destCalcId: string) {
    if (!this.locked)
      console.warn('scene was not locked before [moveCalc]');

    let calc = this._calcs[calcId];
    if (calc === undefined) return;
    let destCalc = this._calcs[destCalcId];

    this._calcSequenceValid = false;
    this._stateValid = false;
    this._dataValid = false;

    for (let outputId of calc.outputIds) {
      let param = calc.outputs[outputId];
      for (let relation of param.nextRelations)
        this.deleteRelation(relation as Relation);
    }

    if (destCalc !== undefined) {
      for (let param of calc.renderableOutputs()) {
        this.addRelation(param, destCalc.inputByTitle(ParamTitles.Object));
      }
    }
  }

  generateEditLevel(calcId: string): string[] {
    let calc = this.calcs[calcId];

    if (!calc)
      return [];

    let nextCalcs = calc.getNextCalcs();

    if (nextCalcs.length > 0) {
      return [...this.generateEditLevel(nextCalcs[0].id), calcId];
    } else {
      return [calcId];
    }
  }

  protected removeCalcTitle(calcId: string, calcTitle: string) {
    if (this._calcIdByTitle[calcTitle]) {
      this._calcIdByTitle[calcTitle].delete(calcId);

      if (this._calcIdByTitle[calcTitle].size === 0)
        delete this._calcIdByTitle[calcTitle];
    }
  }

  protected addCalcTitle(calcId: string, calcTitle: string) {
    if (!this._calcIdByTitle[calcTitle])
      this._calcIdByTitle[calcTitle] = new Set<string>();
    this._calcIdByTitle[calcTitle].add(calcId);
  }

  changeCalcTitle(calcId: string, oldTitle: string, newTitle: string) {
    if (!this.locked)
      console.warn('scene was not locked before [changeCalcTitle]');

    this.removeCalcTitle(calcId, oldTitle);
    this.addCalcTitle(calcId, newTitle);
  }

  protected hasCalcTitle(calcTitle: string) {
    return !!this._calcIdByTitle[calcTitle];
  }

  generateCalcTitle(baseTitle: string): string {
    if (!this.hasCalcTitle(baseTitle))
      return baseTitle;

    let matches = /^(.*[^\d])(\d+)$/g.exec(baseTitle);
    let from = 1;
    if (matches !== null && matches.length > 1) {
      baseTitle = matches[1];
      from = Math.round(+matches[2]) + 1;
    }

    for (let i = from; true; ++i) {
      if (!this.hasCalcTitle(baseTitle + i)) {
        return baseTitle + i;
      }
    }
  }

  getEditLevelCalcIds(editLevel?: string): string[] {
    if (editLevel === undefined)
      editLevel = this.editLevels[this.editLevels.length - 1];

    if (editLevel) {
      let calc = this.calcs[editLevel];
      if (!calc)
        return [];
      return [...calc.prevCalcIds];
    } else {
      return this.calcIds.filter(id => !this.calcs[id].hasNext());
    }
  }

  copyCalcsFrom(scene: Scene, calcIds: string[]): { [key: string]: string } {
    if (!this.locked)
      console.warn('scene was not locked before [copyCalcsFrom]');

    if (calcIds.length === 0)
      calcIds = scene.calcIds;

    let calcIdSet = new Set(calcIds);
    let calcIdsToCopy = new Set(calcIds);
    let processedSet = new Set();
    let destIdMap: { [key: string]: string } = {};

    this._calcSequenceValid = false;
    this._stateValid = false;
    this._dataValid = false;

    let offset = calcIdsToCopy.size;

    while (offset > 0) {
      offset = -calcIdsToCopy.size;

      calcIdsToCopy.forEach(calcId => {
        if (!processedSet.has(calcId)) {
          let calc = scene.getCalcById(calcId);
          if (calc.isInvalid) return;
          for (let inputId of calc.inputIds) {
            for (let relation of calc.inputs[inputId].prevRelations) {
              calcIdsToCopy.add((relation as Relation).from.calc.id);
            }
          }

          processedSet.add(calcId);
        }
      });

      offset += calcIdsToCopy.size;
    }

    let calcIdMap: { [id: string]: Calc } = {};
    for (let calcId of Array.from(calcIdsToCopy)) {
      let calc = scene.getCalcById(calcId);
      if (calc.isInvalid) continue;

      let newCalc = calc.duplicate();
      newCalc.title = this.generateCalcTitle(newCalc.title);
      if (calcIdSet.has(calcId))
        destIdMap[calcId] = newCalc.id;

      this.addCalc(newCalc);
      calcIdMap[calcId] = newCalc;
    }

    for (let relationId of scene._relationIds) {
      let relation = scene._relations[relationId] as Relation;
      let fromCalc = relation.from.calc as Calc;
      let toCalc = relation.to.calc as Calc;
      if (calcIdsToCopy.has(fromCalc.id) && calcIdsToCopy.has(toCalc.id)) {
        this.addRelation(calcIdMap[fromCalc.id].paramByTitle(relation.from.title),
          calcIdMap[toCalc.id].paramByTitle(relation.to.title));
      }
    }

    let sourceObjectIds = Array.from(scene.getUsedObjectIdSet(Array.from(calcIdsToCopy)));
    let objectIdMap: { [key: string]: string } = {};
    let hasChange = true;

    while (hasChange) {
      hasChange = false;

      for (let objectId of sourceObjectIds) {
        let obj = scene.getRegisteredObject(objectId);
        let desc = obj.uniqueDesc.replace(/;[a-zA-Z0-9\-_]{22}/g, (match) => objectIdMap[match] ? objectIdMap[match] : match);
        let destId = this.getRegisteredId(desc);

        if (!destId)
          destId = peregrineId();

        if (!objectIdMap[objectId] || (this._objects[destId] && destId !== objectIdMap[objectId])) {
          objectIdMap[objectId] = destId;
          hasChange = true;
        }
      }
    }

    for (let objectId in objectIdMap) {
      let obj = scene._objects[objectId];
      let desc = obj.uniqueDesc.replace(/;[a-zA-Z0-9\-_]{22}/g, (match) => objectIdMap[match] ? objectIdMap[match] : match);
      this.registerObject(obj.type, obj.value, desc, objectIdMap[objectId]);
    }

    this.replaceObjectIds(objectIdMap);

    return destIdMap;
  }

  deleteRelation(relation: Relation): boolean {
    if (!this.locked)
      console.warn('scene was not locked before [deleteRelation]');

    relation.from.removeNextRelation(relation);
    relation.to.removePrevRelation(relation);

    this._calcSequenceValid = false;
    this._dataValid = false;

    this._setRelationIds(this.relationIds.filter(r => r !== relation.id));
    this._removeRelation(relation.id);

    return true;
  }

  duplicateCalc(calcId: string, deep: boolean = false): Calc {
    if (!this.locked)
      console.warn('scene was not locked before [duplicateCalc]');

    let calc = this._calcs[calcId];
    if (calc === undefined) return Calc.unset;

    if (calc.internal) return calc;

    let newCalc = calc.duplicate();
    newCalc.title = this.generateCalcTitle(newCalc.title);

    this.addCalc(newCalc);

    for (let i = 0; i < calc.inputIds.length; ++i) {
      for (let relation of calc.inputs[calc.inputIds[i]].prevRelations) {
        let from = (relation as Relation).from as Parameter;
        let to = newCalc.inputs[newCalc.inputIds[i]] as Parameter;
        if (deep) {
          let fromCalc = this.duplicateCalc(from.calc.id, deep);
          this.addRelation(fromCalc.outputByTitle(from.title), to);
        } else {
          this.addRelation(from, to);
        }
      }
    }

    return newCalc;
  }

  deleteCalc(calcId: string, deep: boolean = false): string[] {
    if (!this.locked)
      console.warn('scene was not locked before [deleteCalc]');

    let calc = this._calcs[calcId];
    if (calc === undefined) return [];

    let deletedCalcIds: string[] = [];

    let toDeleteIds: string[] = [];

    this._calcSequenceValid = false;
    this._stateValid = false;
    this._dataValid = false;

    for (let inputId of calc.inputIds) {
      let param = calc.inputs[inputId];
      for (let relation of param.prevRelations) {
        let fromCalc = ((relation as Relation).from.calc as Calc);
        if (!this.deleteRelation(relation as Relation))
          return deletedCalcIds;

        if ((!fromCalc.hasRender() || fromCalc.internal || deep) && !fromCalc.hasNext()) {
          toDeleteIds.push(fromCalc.id);
        }
      }
    }

    for (let outputId of calc.outputIds) {
      let param = calc.outputs[outputId];
      for (let relation of param.nextRelations) {
        if (!this.deleteRelation(relation as Relation))
          return deletedCalcIds;
      }
    }

    this.removeCalcTitle(calcId, calc.title);
    delete this._calcs[calcId];
    this._setCalcIds(this.calcIds.filter(c => c !== calcId));
    this._setSelectedCalcIds(this.selectedCalcIds.filter(c => c !== calcId));
    this.editLevels = this.editLevels.filter(c => c !== calcId);

    let deletingAnnotateIds = Object.keys(this.annotates).map(Number).filter(id => this.annotates[id].start.calcId === calcId);

    if (deletingAnnotateIds.length > 0) {
      this.annotates = lod.omit(this.annotates, deletingAnnotateIds);
    }

    let deletingMeasureIds = Object.keys(this.measures).map(Number).filter(id => this.measures[id].start.calcId === calcId || this.measures[id].end.calcId === calcId);

    if (deletingMeasureIds.length > 0) {
      this.measures = lod.omit(this.measures, deletingMeasureIds);
    }

    let deletingMagnetMappingIds = Object.keys(this.magnetMappings).filter(id => id === calcId || this.magnetMappings[id] === calcId);

    if (deletingMagnetMappingIds.length > 0) {
      this.magnetMappings = lod.omit(this.magnetMappings, deletingMagnetMappingIds);
    }

    deletedCalcIds.push(calcId);

    for (let toDeleteId of toDeleteIds) {
      deletedCalcIds = deletedCalcIds.concat(this.deleteCalc(toDeleteId, deep));
    }

    return Array.from(new Set(deletedCalcIds));
  }

  protected canAdd(from: Parameter, to: Parameter): boolean {
    let relatedParamSet = new Set([to]);
    let processedSet = new Set();

    let offset = relatedParamSet.size;

    while (offset > 0) {
      offset = -relatedParamSet.size;
      relatedParamSet.forEach((next) => {
        if (!processedSet.has(next)) {
          if (next.isInput) {
            let calc = (next.calc as unknown as Calc);
            for (let paramId of calc.outputIds) {
              let param = calc.outputs[paramId];
              relatedParamSet.add(param);
            }
          } else {
            for (let rel of next.nextRelations) {
              let relation = (rel as unknown as Relation);
              relatedParamSet.add(relation.to);
            }
          }

          processedSet.add(next);
        }
      });

      offset += relatedParamSet.size;
      if (relatedParamSet.has(from))
        return false;
    }

    return true;
  }

  fix() {
    if (!this.locked)
      console.warn('scene was not locked before [fix]');

    let invalidObjectIds = [];
    for (let objectId in this._objects) {
      let obj = this._objects[objectId];

      if (Object.keys(obj.value).length === 0) {
        invalidObjectIds.push(objectId);
      }
    }

    for (let objectId of invalidObjectIds) {
      let newObjects = lod.omit(this._objects, objectId);
      this._setObjects(newObjects);
    }

    if (!this._calcSequenceValid) {
      this.validateCalcSequence();
    }

    let calcIds = [...this._calcSequence];
    for (let calcId of calcIds) {
      let calc = this.getCalcById(calcId);
      if (calc.isInvalid) continue;

      let objects = calc.generateRenderedFullObjects(true);
      let objectsInvalid = false;
      for (let obj of objects) {
        if (obj.meshRef && !this.objects[obj.meshRef.objectId]) {
          objectsInvalid = true;
          break;
        }

        if (obj.edgeRef && !this.objects[obj.edgeRef.objectId]) {
          objectsInvalid = true;
          break;
        }
      }

      if (objectsInvalid) {
        console.warn(`${calc.title}: objects are invalid`);
        this.deleteCalc(calcId);
      } else {
        let bBox = getRenderedObjectsBoundingBox(objects);

        if (isNaN(bBox[0][0]) || isNaN(bBox[0][1]) || isNaN(bBox[0][2]) || isNaN(bBox[1][0]) || isNaN(bBox[1][1]) || isNaN(bBox[1][2])) {
          console.warn(`${calc.title}: bounding box is NaN`);
          this.deleteCalc(calcId);
        }
      }
    }

    this._setCalcIds(this.calcIds.filter(id => this.calcs[id]));
  }

  async solve() {
    if (!this.locked)
      console.warn('scene was not locked before [solve]');

    let t0 = performance.now();

    if (!this._calcSequenceValid) {
      this.validateCalcSequence();
    }

    let updateCalcIdSet = this._updateCalcIdSet;
    this._updateCalcIdSet = new Set();

    for (let calcId of this._calcSequence) {
      let calc = this._calcs[calcId];
      await calc.solve(updateCalcIdSet.has(calcId));
    }

    console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took to solve scene`);
  }

  protected validateCalcSequence() {
    this._calcSequence = [];

    let addedCalcIdSet = new Set();

    for (let i = 0; i < this.calcIds.length; ++i) {
      for (let calcId of this.calcIds) {
        if (addedCalcIdSet.has(calcId)) continue;

        let calc = this._calcs[calcId];
        if (calc === undefined) {
          addedCalcIdSet.add(calcId);
          continue;
        }

        let hasPrevUnadded = false;
        for (let prevCalc of calc.getPrevCalcs()) {
          if (!addedCalcIdSet.has(prevCalc.id)) {
            hasPrevUnadded = true;
            break;
          }
        }

        if (!hasPrevUnadded) {
          addedCalcIdSet.add(calcId);
          this._calcSequence.push(calcId);
        }
      }

      if (addedCalcIdSet.size === this.calcIds.length)
        break;
    }

    this._calcSequenceValid = true;
  }

  collapseAll() {
    if (!this.locked)
      console.warn('scene was not locked before [collapseAll]');

    for (let calcId of this.calcIds) {
      let calc = this._calcs[calcId];

      if (calc)
        calc.collapse();
    }

    if (!this.floor.collapsed || Object.keys(this.floor.settingGroupExpanded).length > 0) {
      this.floor = {
        ...this.floor,
        collapsed: true,
        settingGroupExpanded: {}
      };
    }

    let annotates: { [key: number]: IAnnotate } = {};
    let hasChange = false;
    for (let annotateId in this.annotates) {
      let annotate = this.annotates[annotateId];
      if (!annotate.collapsed) {
        annotates[annotateId] = {
          ...annotate,
          collapsed: true
        };
        hasChange = true;
      } else {
        annotates[annotateId] = annotate;
      }
    }

    if (hasChange) {
      this.annotates = annotates;
    }

    let lights: { [key: number]: ILight } = {};
    hasChange = false;
    for (let lightId in this.lights) {
      let light = this.lights[lightId];
      if (!light.collapsed || Object.keys(light.settingGroupExpanded).length > 0) {
        lights[lightId] = {
          ...light,
          collapsed: true,
          settingGroupExpanded: {}
        };
        hasChange = true;
      } else {
        lights[lightId] = light;
      }
    }

    if (hasChange) {
      this.lights = lights;
    }
  }

  getCalcById(calcId: string) {
    if (this._calcs[calcId])
      return this._calcs[calcId];
    return Calc.unset;
  }

  getUsedDigitalMaterialIdSet(calcIds?: string[]) {
    let scopeCalcIds = this.calcIds;

    if (calcIds)
      scopeCalcIds = calcIds;

    let materialIdSet = new Set<string>();
    for (let calcId of scopeCalcIds) {
      let calc = this.calcs[calcId];
      if (calc) {
        let params = [];
        for (let inputId of calc.inputIds) {
          params.push(calc.inputs[inputId]);
        }
        for (let outputId of calc.outputIds) {
          params.push(calc.outputs[outputId]);
        }
        for (let param of params) {
          if (RenderableParamTypes.includes(param.objectType)) {
            if ((param as Param_Mesh).properties) {
              for (let property of (param as Param_Mesh).properties) {
                if (property.material.type === MaterialTypes.DigitalMaterial && property.material.id)
                  materialIdSet.add(property.material.id);
              }
            }
          }
        }

        if (calc.material.type === MaterialTypes.DigitalMaterial && calc.material.id)
          materialIdSet.add(calc.material.id);
      } else {
        console.warn(`calc ${calcId} was not found in this scene`);
      }
    }

    return materialIdSet;
  }

  getUsedObjectIdSet(calcIds?: string[]) {
    let scopeCalcIds = this.calcIds;

    if (calcIds)
      scopeCalcIds = calcIds;

    let objectIdSet = new Set<string>();
    for (let calcId of scopeCalcIds) {
      let calc = this.calcs[calcId];
      if (calc) {
        for (let data of calc.heatmapData) {
          if (data.objectId) {
            objectIdSet.add(data.objectId);
          }
        }
        let params = [];
        for (let inputId of calc.inputIds) {
          params.push(calc.inputs[inputId]);
        }
        for (let outputId of calc.outputIds) {
          params.push(calc.outputs[outputId]);
        }
        for (let param of params) {
          if (param.objectType === ParamTypes.Curve || param.objectType === ParamTypes.Brep || param.objectType === ParamTypes.Mesh || param.objectType === ParamTypes.SculptMesh) {
            if ((param as Param_Mesh).values) {
              for (let value of (param as Param_Mesh).values) {
                objectIdSet.add(value.objectId);
              }
            }
          }

          if (param.objectType === ParamTypes.Brep || param.objectType === ParamTypes.Curve) {
            if ((param as Param_Brep).caches) {
              for (let cache of (param as Param_Brep).caches) {
                objectIdSet.add(cache[0].objectId);
                objectIdSet.add(cache[1].objectId);
              }
            }
          }

          if (param.objectType === ParamTypes.Compound) {
            let paramComp = param as Param_Compound;
            if (paramComp.valueTypes && paramComp.values) {
              for (let i = 0; i < paramComp.length; ++i) {
                let value = paramComp.values[i];
                let valueType = paramComp.valueTypes[i];
                let cache = paramComp.caches[i];

                if (valueType === ObjectTypes.Curve || valueType === ObjectTypes.Brep || valueType === ObjectTypes.Mesh || valueType === ObjectTypes.SculptMesh) {
                  objectIdSet.add(value.objectId);
                }

                if (valueType === ObjectTypes.Brep || valueType === ObjectTypes.Curve) {
                  if (cache) {
                    objectIdSet.add(cache[0].objectId);
                    objectIdSet.add(cache[1].objectId);
                  }
                }
              }
            }
          }
        }
      } else {
        console.warn(`calc ${calcId} was not found in this scene`);
      }
    }

    return objectIdSet;
  }

  protected replaceObjectIds(objectIdMap: { [key: string]: string }) {
    for (let calcId of this.calcIds) {
      if (this.calcs[calcId]) {
        this.calcs[calcId].replaceObjectIds(objectIdMap);
      }
    }
  }

  replaceDigitalMaterialIds(materialIdMap: { [key: string]: string }) {
    if (!this.locked)
      console.warn('scene was not locked before [replaceDigitalMaterialIds]');

    for (let calcId of this.calcIds) {
      if (this.calcs[calcId]) {
        this.calcs[calcId].replaceDigitalMaterialIds(materialIdMap);
      }
    }
  }

  getCalcRelation(fromParam: Parameter, toParam: Parameter): Relation | undefined {
    for (let relation of fromParam.nextRelations) {
      if ((relation as Relation).to.id === toParam.id)
        return relation as Relation;
    }
  }

  protected validateGeneratedState() {
    this.stateCalcs = this.generateCalcStates();
    let info: ISceneAnalysisInfo = lod.cloneDeep(defaultSceneAnalysisInfo);
    let objectsIncluded = new Set<string>();

    for (let calcId in this._state.calcs) {
      let calc = this._state.calcs[calcId];
      if (calc.internal) continue;

      ++info.calcCount;

      info.renderVertexCount += calc.vertexCnt;
      info.renderFaceCount += calc.faceCnt;
      info.renderedObjCount += calc.objects.length;
    }

    for (let calcId in this.calcs) {
      let calc = this.calcs[calcId];
      if (!calc || calc.internal) continue;

      for (let object of calc.fullObjects) {
        switch (object.type) {
          case RenderedObjectTypes.Mesh:
          case RenderedObjectTypes.Brep:
            if (object.meshRef && !objectsIncluded.has(object.meshRef.objectId)) {
              let geometry = object.mesh.geometry;

              info.faceCount += geometry.face ? geometry.face.length / 3 : 0;
              info.vertexCount += geometry.position ? geometry.position.length / 3 : 0;

              objectsIncluded.add(object.meshRef.objectId);
            }
            break;
          default:
            break;
        }
      }
    }

    let lastCalc;
    for (let i = 0; i < this.editLevels.length + 1; ++i) {
      if (!lastCalc) {
        for (let calcId in this._state.calcs) {
          let calc = this._state.calcs[calcId];
          if (calc.visible && calc.nextCalcIds.length === 0) {
            info.visibleVertexCount += calc.vertexCnt;
            info.visibleFaceCount += calc.faceCnt;
            info.visibleObjCount += calc.objects.length;
            // console.log(`+${calc.title}`);
          }
        }
      } else {
        for (let prevCalcId of lastCalc.prevCalcIds) {
          let calc = this._state.calcs[prevCalcId];
          if (calc.visible || i === this.editLevels.length) {
            info.visibleVertexCount += calc.vertexCnt;
            info.visibleFaceCount += calc.faceCnt;
            info.visibleObjCount += calc.objects.length;
            // console.log(`+${calc.title}`);
          }
        }
      }

      let level = this.editLevels[i];
      if (level) {
        lastCalc = this._state.calcs[level];

        if (lastCalc) {
          info.visibleVertexCount -= lastCalc.vertexCnt;
          info.visibleFaceCount -= lastCalc.faceCnt;
          info.visibleObjCount -= lastCalc.objects.length;
          // console.log(`-${lastCalc.title}`);
        }
      }
    }

    this._setAnalysisInfo(info);
    this._stateValid = true;
  }

  protected validateGeneratedData() {
    this.dataCalcs = lod.mapValues(this.calcs, c => c.data);
    let idSet = this.getUsedObjectIdSet();

    let objects: { [id: string]: { type: ObjectTypes, uniqueDesc: string, value: any } } = {};
    for (let id of Array.from(idSet)) {
      objects[id] = this.objects[id];
    }

    this._setObjects(objects);

    this.dataObjects = lod.mapValues(lod.pickBy(this.objects), obj => {
      if (obj.type === ObjectTypes.Geometry) {
        return {
          [_s('type')]: _s(obj.type),
          [_s('uniqueDesc')]: obj.uniqueDesc,
          [_s('value')]: [MeshCodec.Trans, encodeGeometryCodecTrans(obj.value)]
        };
      } else if (obj.type === ObjectTypes.SculptGeometry) {
        let res = encodeGeometryCodecTrans(getGeometryFromSculptGeometry(obj.value));
        return {
          [_s('type')]: _s(obj.type),
          [_s('uniqueDesc')]: obj.uniqueDesc,
          [_s('value')]: [SculptMeshCodec.Trans, res]
        };
      } else if (obj.type === ObjectTypes.BrepGeometry) {
        return {
          [_s('type')]: _s(obj.type),
          [_s('uniqueDesc')]: obj.uniqueDesc,
          [_s('value')]: [BrepCodec.Trans, encodeBrepGeometryCodecTrans(obj.value)]
        };
      } else if (obj.type === ObjectTypes.CurveGeometry) {
        return {
          [_s('type')]: _s(obj.type),
          [_s('uniqueDesc')]: obj.uniqueDesc,
          [_s('value')]: [CurveCodec.Trans, encodeCurveGeometryCodecTrans(obj.value)]
        };
      }
    });
    this._dataValid = true;
  }

  async overwrite(jData: any, replaceData = true, onProgress?: (progress: number) => void) {
    if (!this.locked)
      console.warn('scene was not locked before [overwrite]');

    let t0 = performance.now();

    if (!this.stateValid)
      this.validateGeneratedState();
    if (!this.dataValid)
      this.validateGeneratedData();

    onProgress && onProgress(10);

    XObject.UpdateEncodedData = !replaceData;
    if (jData === undefined || jData[_s('id')] === undefined) {
      console.warn('error decoding the scene', jData);
      return;
    }

    if (this._data[_s('id')] !== jData[_s('id')]) {
      this.id = jData[_s('id')];
    }

    if (this._data[_s('projectId')] !== jData[_s('projectId')]) {
      this.projectId = jData[_s('projectId')] !== undefined ? jData[_s('projectId')] : 0;
    }

    if (this._data[_s('measures')] !== jData[_s('measures')]) {
      this.measures = jData[_s('measures')] !== undefined ? lod.mapValues(jData[_s('measures')], decodeMeasure) : {};
    }

    if (this._data[_s('annotates')] !== jData[_s('annotates')]) {
      this.annotates = jData[_s('annotates')] !== undefined ? lod.mapValues(jData[_s('annotates')], decodeAnnotate) : {};
    }

    if (this._data[_s('magnetMappings')] !== jData[_s('magnetMappings')]) {
      this.magnetMappings = jData[_s('magnetMappings')] !== undefined ? jData[_s('magnetMappings')] : {};
    }

    if (this._data[_s('selectedCalcIds')] !== jData[_s('selectedCalcIds')]) {
      this._setSelectedCalcIds(jData[_s('selectedCalcIds')] !== undefined ? jData[_s('selectedCalcIds')] : []);
    }

    if (this._data[_s('floor')] !== jData[_s('floor')]) {
      this.floor = jData[_s('floor')] !== undefined ? decodeFloor(jData[_s('floor')]) : defaultFloor;
    }

    if (this._data[_s('cameraInfo')] !== jData[_s('cameraInfo')]) {
      this.cameraInfo = jData[_s('cameraInfo')] ? decodeCameraInfo(jData[_s('cameraInfo')]) : null;
    }

    if (this._data[_s('cameraAngle')] !== jData[_s('cameraAngle')]) {
      this.cameraAngle = jData[_s('cameraAngle')] ? _m(jData[_s('cameraAngle')]) : 10;
    }

    if (this._data[_s('lightEnvironment')] !== jData[_s('lightEnvironment')]) {
      this.lightEnvironment = jData[_s('lightEnvironment')] !== undefined ? decodeEnvironment(jData[_s('lightEnvironment')]) : defaultLightEnvironment;
    }

    if (this._data[_s('backgroundEnvironment')] !== jData[_s('backgroundEnvironment')]) {
      this.backgroundEnvironment = jData[_s('backgroundEnvironment')] !== undefined ? decodeEnvironment(jData[_s('backgroundEnvironment')]) : defaultEnvironment;
    }

    if (this._data[_s('isSetAsBackground')] !== jData[_s('isSetAsBackground')]) {
      this.isSetAsBackground = jData[_s('isSetAsBackground')] !== undefined ? _c(jData[_s('isSetAsBackground')]) : false;
    }

    if (this._data[_s('lights')] !== jData[_s('lights')]) {
      this.lights = jData[_s('lights')] !== undefined ? lod.mapValues(jData[_s('lights')], decodeLight) : {};
    }

    if (this._data[_s('editLevels')] !== jData[_s('editLevels')]) {
      this.editLevels = jData[_s('editLevels')] !== undefined ? jData[_s('editLevels')] : [];
    }

    if (this._data[_s('tool')] !== jData[_s('tool')]) {
      this.tool = jData[_s('tool')] !== undefined ? jData[_s('tool')] : Tools.Gumball;
    }

    if (this._data[_s('toolConfig')] !== jData[_s('toolConfig')]) {
      this.toolConfig = decodeToolConfig(jData[_s('toolConfig')]);
    }

    if (this._data[_s('viewType')] !== jData[_s('viewType')]) {
      this.viewType = jData[_s('viewType')] !== undefined ? jData[_s('viewType')] : ViewTypes.Rendered;
    }

    if (this._data[_s('measureUnit')] !== jData[_s('measureUnit')]) {
      this.measureUnit = jData[_s('measureUnit')] !== undefined ? jData[_s('measureUnit')] : MeasureUnit.Milli;
    }

    if (this._data[_s('version')] !== jData[_s('version')]) {
      this.version = jData[_s('version')] !== undefined ? jData[_s('version')] : BetaSceneVersion;
    }

    onProgress && onProgress(15);

    if (jData[_s('calcIds')] !== undefined) {
      for (let calcId of [...this.calcIds]) {
        if (jData[_s('calcs')][calcId] === undefined) {
          this.removeCalcTitle(calcId, this._calcs[calcId].title);
          delete this._calcs[calcId];
          this._calcSequenceValid = false;
          this._stateValid = false;
        }
      }

      for (let calcId of jData[_s('calcIds')]) {
        if (this._calcs[calcId]) {
          this._calcs[calcId].overwrite(jData[_s('calcs')][calcId], replaceData);
        } else {
          if (jData[_s('calcs')][calcId]) {
            let calc = Calc.create();
            calc.overwrite(jData[_s('calcs')][calcId], replaceData);
            this.addCalc(calc);
          }
        }
      }
    }

    onProgress && onProgress(25);

    this._setCalcIds(jData[_s('calcIds')]);

    if (jData[_s('relationIds')] !== undefined) {
      for (let relationId of [...this.relationIds]) {
        if (jData[_s('relations')][relationId] === undefined) {
          this.deleteRelation(this._relations[relationId]);
        }
      }

      for (let relationId of jData[_s('relationIds')]) {
        if (jData[_s('relations')][relationId]) {
          let fromCalcId = jData[_s('relations')][relationId][_s('from')][_s('calc')];
          let fromParamId = jData[_s('relations')][relationId][_s('from')][_s('param')];
          let toCalcId = jData[_s('relations')][relationId][_s('to')][_s('calc')];
          let toParamId = jData[_s('relations')][relationId][_s('to')][_s('param')];

          let fromParam = this.getCalcById(fromCalcId).getParameter(fromParamId);
          let toParam = this.getCalcById(toCalcId).getParameter(toParamId);

          let relation = this.getCalcRelation(fromParam, toParam);
          if (relation) {
            relation.id = relationId;
          } else {
            this.addRelation(this.getCalcById(fromCalcId).getParameter(fromParamId), this.getCalcById(toCalcId).getParameter(toParamId), relationId);
          }
        }
      }
    }

    this._setRelationIds(jData[_s('relationIds')]);

    onProgress && onProgress(30);
    let totalObjectsToLoad = 0;
    let objectsLoaded = 0;

    for (let objectId in jData[_s('objects')]) {
      if (!this._objects[objectId])
        ++totalObjectsToLoad;
    }

    for (let objectId in jData[_s('objects')]) {
      let obj = jData[_s('objects')][objectId];
      if (!this._objects[objectId]) {
        if (obj[_s('type')] === _s(ObjectTypes.Geometry)) {
          this._setObjects({
            ...this._objects,
            [objectId]: {
              type: _t(obj[_s('type')]) as ObjectTypes,
              uniqueDesc: obj[_s('uniqueDesc')],
              value: await decodeGeometryOnWorker(obj[_s('value')])
            }
          });
        } else if (obj[_s('type')] === _s(ObjectTypes.SculptGeometry)) {
          this._setObjects({
            ...this._objects,
            [objectId]: {
              type: _t(obj[_s('type')]) as ObjectTypes,
              uniqueDesc: obj[_s('uniqueDesc')],
              value: await decodeSculptGeometryOnWorker(obj[_s('value')])
            }
          });
        } else if (obj[_s('type')] === _s(ObjectTypes.BrepGeometry)) {
          this._setObjects({
            ...this._objects,
            [objectId]: {
              type: _t(obj[_s('type')]) as ObjectTypes,
              uniqueDesc: obj[_s('uniqueDesc')],
              value: await decodeBrepGeometry(obj[_s('value')])
            }
          });
        } else if (obj[_s('type')] === _s(ObjectTypes.CurveGeometry)) {
          this._setObjects({
            ...this._objects,
            [objectId]: {
              type: _t(obj[_s('type')]) as ObjectTypes,
              uniqueDesc: obj[_s('uniqueDesc')],
              value: await decodeCurveGeometry(obj[_s('value')])
            }
          });
        }
        ++objectsLoaded;

        onProgress && totalObjectsToLoad && onProgress(objectsLoaded / totalObjectsToLoad * 60 + 30);
      }
    }

    for (let objectId in this._objects) {
      if (!jData[_s('objects')][objectId]) {
        let newObjects = lod.omit(this._objects, objectId);
        this._setObjects(newObjects);
      }
    }

    onProgress && onProgress(100);

    XObject.UpdateEncodedData = true;

    if (replaceData) {
      this._data = jData;
      this._dataValid = true;
    }

    disposeDracoWorkers();
    console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took to overwrite the scene`);
  }

  protected _operations: { [key: string]: OperationStatus } = {};
  protected _updateCalcIdSet: Set<string> = new Set<string>();
  protected _callback?: (data: any) => any;

  get operations() {
    return this._operations;
  }

  get updateCalcIdSet() {
    return this._updateCalcIdSet;
  }

  set callback(func: (data: any) => any) {
    this._callback = func;
  }

  async startOperationOnGeometries(op: string, calcId: string, operationId: string, operands: any) {

    if (this._callback)
      return this._callback({op, calcId, operationId, operands});

  }

}
