import {ComponentTypes} from "../types";
import {pigeon as pigeonTypes} from "../../../types";
import {createSimpleGumball, Gumball} from "../gumball";
import {
  BooleanInterfaceTypes,
  createObjectFromBrepData,
  getCurveFromRef,
  getRegisteredBrepIdWithTessellation,
  Param_Boolean,
  Param_Brep,
  Param_Curve,
  ParamTitles
} from "../../parameter";
import {Calc} from "../../calc";
import {ObjectTypes} from "../../xobject";
import {ICalcConfigDiff} from "../../types";
import {getComposeDesc, isIdentity, WompObjectRef} from "../../../WompObject";
import {mat4} from "gl-matrix";

export class LoftModifier {
  static create() {
    let calc = createSimpleGumball();
    calc.component = ComponentTypes.LoftModifier;
    calc.title = 'loft';

    let curveParam = Param_Curve.create(calc, ParamTitles.Object, true, false, false);
    let capParam = Param_Boolean.create(calc, ParamTitles.Cap, true, true, {
      defaultValue: true,
      interfaceType: BooleanInterfaceTypes.Toggle
    });
    let smoothParam = Param_Boolean.create(calc, ParamTitles.Smooth, true, true, {
      defaultValue: false,
      interfaceType: BooleanInterfaceTypes.Toggle
    });
    let loftParam = Param_Brep.create(calc, ParamTitles.Loft, false, false, true);

    calc.addParameter(curveParam);
    calc.addParameter(capParam);
    calc.addParameter(smoothParam);
    calc.addParameter(loftParam);

    return calc;
  }

  static async solve(calc: Calc, diff: ICalcConfigDiff) {
    let objectParam = calc.inputByTitle(ParamTitles.Object) as Param_Curve;
    let capParam = calc.inputByTitle(ParamTitles.Cap) as Param_Boolean;
    let smoothParam = calc.inputByTitle(ParamTitles.Smooth) as Param_Boolean;

    let cap = capParam.values[0];
    let smooth = smoothParam.values[0];

    let objects = [];
    let objValues = objectParam.values;

    if (objectParam.length > 1) {
      let matrix = objectParam.values[0].matrix;

      if (!isIdentity(matrix)) {
        let invMatrix = mat4.invert(mat4.create(), matrix);

        if (invMatrix) {
          let newObjValues = [];
          for (let objValue of objValues) {
            let newObj = new WompObjectRef(objValue.objectId, mat4.multiply(mat4.create(), invMatrix, objValue.matrix));
            newObjValues.push(newObj);
          }

          objValues = newObjValues;
        }
      }

      let desc = `loft[${objValues.map(v => getComposeDesc(v, 3)).join(',')},${cap},${smooth}]`;
      let {id, meshId, edgeId} = getRegisteredBrepIdWithTessellation(calc, desc);

      let value, cache;
      if (!id || !meshId || !edgeId) {
        let brepData = await pigeonTypes.loft(objValues.map(v => getCurveFromRef(calc, v)), cap, !smooth);
        objects.push(await createObjectFromBrepData(calc, brepData, desc, mat4.create()));
      } else {
        value = new WompObjectRef(id, matrix);
        cache = [new WompObjectRef(meshId, matrix), new WompObjectRef(edgeId, matrix)];

        objects.push({value, valueType: ObjectTypes.Brep, cache});
      }
    }

    return Gumball.solveGumball(calc, objects);
  }
}
