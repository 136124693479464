import {
  SceneActionTypes,
  ISceneState,
  CHANGE_MINOR_VIEW_ZOOM,
  ADD_PLACEHOLDER_BOXES,
  REMOVE_PLACEHOLDER_BOXES,
  UPDATE_PLACEHOLDER_BOX,
  CHANGE_TEMPORARY_OBJECT_WELDINGS,
  CHANGE_PREVIEW_ENVIRONMENT_INFO,
  CHANGE_PREVIEW_DIGITAL_MATERIAL,
  CHANGE_TEMPORARY_TOOL_CONFIG,
  CHANGE_HIGHLIGHTED_CALC_IDS,
  CHANGE_SERVER_RENDER_FRAME_NO
} from "../../types/ui/scene";
import {INIT_STORE} from "../../types/init";
import lod from "lodash";
import {defaultTemporaryToolConfig} from "../../types/types";

const initialState: ISceneState = {
  minorViewZoom: 1,
  placeholderBoxes: {},
  temporaryObjectWeldings: {},
  previewCalcIds: [],
  temporaryToolConfig: defaultTemporaryToolConfig,
  highlightedCalcIds: {},
  serverRenderFrameNo: 0,
};

export function sceneReducer(
  state = initialState,
  action: SceneActionTypes
): ISceneState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case CHANGE_MINOR_VIEW_ZOOM:
      return {
        ...state,
        minorViewZoom: action.payload.zoom
      };
    case ADD_PLACEHOLDER_BOXES:
      return {
        ...state,
        placeholderBoxes: {
          ...state.placeholderBoxes,
          ...action.payload.boxes
        }
      };
    case UPDATE_PLACEHOLDER_BOX:
      if (state.placeholderBoxes[action.payload.id]) {
        return {
          ...state,
          placeholderBoxes: {
            ...state.placeholderBoxes,
            [action.payload.id]: {
              ...state.placeholderBoxes[action.payload.id],
              ...action.payload.box
            }
          }
        };
      } else
        return state;
    case REMOVE_PLACEHOLDER_BOXES:
      return {
        ...state,
        placeholderBoxes: lod.omit(state.placeholderBoxes, action.payload.ids)
      };
    case CHANGE_TEMPORARY_OBJECT_WELDINGS:
      return {
        ...state,
        temporaryObjectWeldings: action.payload.weldings
      };
    case CHANGE_PREVIEW_DIGITAL_MATERIAL:
      return {
        ...state,
        previewDigitalMaterial: action.payload.material,
        previewCalcIds: action.payload.calcIds
      };
    case CHANGE_PREVIEW_ENVIRONMENT_INFO:
      return {
        ...state,
        previewEnvironmentInfo: action.payload.info
      };
    case CHANGE_TEMPORARY_TOOL_CONFIG:
      return {
        ...state,
        temporaryToolConfig: lod.merge(lod.cloneDeep(state.temporaryToolConfig), action.payload.config)
      };
    case CHANGE_HIGHLIGHTED_CALC_IDS:
      return {
        ...state,
        highlightedCalcIds: lod.mapValues(lod.keyBy(action.payload.calcIds), v => true) as {[key: string]: true}
      };
    case CHANGE_SERVER_RENDER_FRAME_NO:
      return {
        ...state,
        serverRenderFrameNo: action.payload.frameNo
      };
    default:
      return state;
  }
}