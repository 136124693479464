import {IProfile, NormalizedObjects} from "../types";
import {InitStoreAction} from "../init";

export const REPLACE_PROFILES = 'REPLACE_PROFILES';
export const REMOVE_PROFILE = 'REMOVE_PROFILE';
export const ADD_PROFILES = 'ADD_PROFILES';

export interface IEntityProfilesState extends NormalizedObjects<IProfile> {
}

interface ReplaceProfilesAction {
  type: typeof REPLACE_PROFILES
  payload: {
    profiles: IProfile[]
  }
}

interface AddProfilesAction {
  type: typeof ADD_PROFILES
  payload: {
    profiles: IProfile[]
  }
}

interface RemoveProfileAction {
  type: typeof REMOVE_PROFILE
  payload: {
    id: number
  }
}

export type ProfilesActionTypes = ReplaceProfilesAction |
  RemoveProfileAction |
  AddProfilesAction |
  InitStoreAction