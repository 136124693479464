import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {IGAR, IProfile, IWomp20Water} from "../../store/types/types";
import {AnyAction} from "redux";
import {
  _fetchWaiter,
  _postWaiter,
  _postWaiterAction,
  _postWaiterVideo, _putCurrentWaiter,
  _putWaiter, _sendWaiterInviteEmail, _sendWaiterInviteRemindEmail,
  filterWaiter
} from "../../api/waiter";
import {changeWaiter} from "../../store/actions/entity/global";
import {
  fetchWaiterFailure,
  fetchWaiterStarted,
  fetchWaiterSuccess, postWaiterActionFailure,
  postWaiterActionStarted, postWaiterActionSuccess, postWaiterFailure,
  postWaiterStarted, postWaiterSuccess
} from "../../store/actions/api/waiter";
import {fetchWaitersFailure, fetchWaitersStarted, fetchWaitersSuccess} from "../../store/actions/api/waiter";
import {_fetchWaiters, filterWaiters} from "../../api/waiter";
import {batch} from "react-redux";
import {addWaiters} from "../../store/actions/entity/waiters";
import {processAuthentication} from "./global";

export const sendWaiterInviteRemindEmail = (name: string, email: string): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    return _sendWaiterInviteRemindEmail(name, email)
      .then(res => {
        return {success: true};
      })
      .catch(err => {
        return {success: false, message: err.message};
      });
  };
};

export const sendWaiterInviteEmail = (name: string, email: string): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    return _sendWaiterInviteEmail(name, email)
      .then(res => {
        return {success: true};
      })
      .catch(err => {
        return {success: false, message: err.message};
      });
  };
};

export const getWaiter = (email: string): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    dispatch(fetchWaiterStarted());
    return _fetchWaiter(email)
      .then(res => {
        let waiters = res.data.data;

        dispatch(fetchWaiterSuccess());
        return {success: true, info: waiters[0]};
      })
      .catch(err => {
        dispatch(fetchWaiterFailure(err.message));
        return {success: false, message: err.message};
      });
  };
};

export const createWaiter = (email: string, point?: number): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    dispatch(postWaiterStarted());
    return _postWaiter(email, point)
      .then(res => {
        let waiter = res.data.data;
        dispatch(changeWaiter(waiter));

        dispatch(postWaiterSuccess(0));
        return {success: true};
      })
      .catch(err => {
        dispatch(postWaiterFailure(err.message));
        return {success: false, message: err.message};
      });
  };
};

export const postWaiterVideo = (email: string, title: string, video: string, model?: string): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    dispatch(postWaiterStarted());
    return _postWaiterVideo(email, title, video, model)
      .then(res => {
        if (res.data.success) {
          let waiter = res.data.data;
          dispatch(changeWaiter(waiter));

          dispatch(postWaiterSuccess(0));
          return {success: true};
        } else {
          dispatch(postWaiterFailure(res.data.message));
          return {success: false, message: res.data.message};
        }
      })
      .catch(err => {
        dispatch(postWaiterFailure(err.message));
        return {success: false, message: err.message};
      });
  };
};

export const addWaiterAction = (email: string, action: string): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    dispatch(postWaiterActionStarted());
    return _postWaiterAction(email, action)
      .then(res => {
        if (res.data.success) {
          let waiter = res.data.data;
          dispatch(changeWaiter(waiter));
          dispatch(postWaiterActionSuccess());
          return {success: true, info: {point: res.data.point}};
        } else {
          dispatch(postWaiterActionFailure(res.data.message));
          return {success: false};
        }
      })
      .catch(err => {
        dispatch(postWaiterActionFailure(err.message));
        return {success: false, message: err.message};
      });
  };
};

export const fetchWaiters = (): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    dispatch(fetchWaitersStarted());

    return _fetchWaiters()
      .then(res => {
        let waiters = filterWaiters(res.data);

        batch(() => {
          dispatch(addWaiters(waiters));
          dispatch(fetchWaitersSuccess([]));
        });
        return {success: true};
      })
      .catch(err => {
        dispatch(fetchWaitersFailure(err.message));
        processAuthentication(err, dispatch);
        return {success: false, message: err.message};
      });
  };
};

export const putCurrentWaiter = (id: number, waiter: Partial<IWomp20Water>): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    dispatch(postWaiterStarted());

    return _putCurrentWaiter(id, waiter)
      .then(res => {
        let waiter = filterWaiter(res.data);

        batch(() => {
          dispatch(changeWaiter(waiter));
          dispatch(postWaiterSuccess(0));
        });
        return {success: true, };
      })
      .catch(err => {
        dispatch(postWaiterFailure(err.message));
        if (err.response && err.response.data.non_field_errors) {
          return {success: false, message: err.response.data.non_field_errors[0]};
        } else {
          return {success: false, message: err.message};
        }
      });
  };
};

export const putWaiter = (id: number, waiter: Partial<IWomp20Water>): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    dispatch(postWaiterStarted());

    return _putWaiter(id, waiter)
      .then(res => {
        let waiter = filterWaiter(res.data);

        batch(() => {
          dispatch(addWaiters([waiter]));
          dispatch(postWaiterSuccess(0));
        });
        return {success: true, };
      })
      .catch(err => {
        dispatch(postWaiterFailure(err.message));
        if (err.response && err.response.data.non_field_errors) {
          return {success: false, message: err.response.data.non_field_errors[0]};
        } else {
          return {success: false, message: err.message};
        }
      });
  };
};