/**
 * @author HypnosNova / https://www.threejs.org.cn/gallery
 * This is a class to check whether objects are in a selection area in 3D space
 */

import {Frustum, Matrix4, Vector3} from "three";
import {updateFrustum} from "../../common/three";

var SelectionBox = (function () {

  var frustum = new Frustum();
  var center = new Vector3();

  function SelectionBox(camera, scene, deep) {

    this.camera = camera;
    this.scene = scene;
    this.startPoint = new Vector3();
    this.endPoint = new Vector3();
    this.collection = [];
    this.deep = deep || Number.MAX_VALUE;
    this.selectionScope = [];

  }

  SelectionBox.prototype.select = function (startPoint, endPoint) {
    let t0 = performance.now();

    this.startPoint = startPoint || this.startPoint;
    this.endPoint = endPoint || this.endPoint;
    this.collection = [];

    if (this.updateFrustum(this.startPoint, this.endPoint)) {

      this.searchChildInFrustum(frustum, this.scene);

    }

    // console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took to detect mesh within area`);

    return this.collection;

  };

  SelectionBox.prototype.updateFrustum = function (startPoint, endPoint) {

    startPoint = startPoint || this.startPoint;
    endPoint = endPoint || this.endPoint;

    this.camera.updateProjectionMatrix();
    this.camera.updateMatrixWorld();

    let left = Math.min(startPoint.x, endPoint.x);
    let top = Math.max(startPoint.y, endPoint.y);
    let right = Math.max(startPoint.x, endPoint.x);
    let down = Math.min(startPoint.y, endPoint.y);

    if (left === right || top === down) {

      return false;

    }

    if (this.camera.isPerspectiveCamera || this.camera.isOrthographicCamera) {

      updateFrustum(this.camera, frustum, startPoint, endPoint, this.deep);

    } else {

      // camera neither orthographic nor perspective
      console.warn('WARNING: SelectionBox.js encountered an unknown camera type.');

      return false;
    }

    return true;
  };

  SelectionBox.prototype.searchChildInFrustum = function (frustum, object) {

    if (object.isMesh || object.isLine || object.isPoints) {

      if (object.isInstancedMesh) {

        if (object.userData.ids !== undefined) {

          if (object.material && object.visible) {

            object.geometry.computeBoundingSphere();

            let matrix = new Matrix4();
            let hashes = Array.from(object.userData.hashes);

            for (let index = 0; index < object.count; ++index) {

              for (let id of object.userData.ids[hashes[index]]) {

                if (this.selectionScope.includes(id)) {

                  center.copy(object.geometry.boundingSphere.center);

                  object.getMatrixAt(index, matrix);

                  center.applyMatrix4(matrix).applyMatrix4(object.matrixWorld);

                  if (!isNaN(center.x) && !isNaN(center.y) && !isNaN(center.z) && frustum.containsPoint(center)) {

                    this.collection.push(id);

                  }

                }

              }

            }

          }

        }

      } else if (object.userData.id !== undefined && this.selectionScope.includes(object.userData.id)) {

        if (object.material && object.visible) {

          object.geometry.computeBoundingSphere();

          center.copy(object.geometry.boundingSphere.center);

          center.applyMatrix4(object.matrixWorld);

          if (frustum.containsPoint(center)) {

            this.collection.push(object.userData.id);

          }

        }

      }

    }

    if (object.children.length > 0) {

      for (var x = 0; x < object.children.length; x++) {

        this.searchChildInFrustum(frustum, object.children[x]);

      }

    }

  };

  return SelectionBox;

})();

export {SelectionBox};
