import React, {Component, ComponentClass, forwardRef, RefObject} from 'react';

export const withRef = <T extends Component, OriginalProps extends {}>(
  WrappedComponent: ComponentClass<OriginalProps>
) => {
  type PrivateProps = { forwardedRef: RefObject<T> }

  type Props = OriginalProps & PrivateProps

  class WithRef extends Component<Props> {

    render() {
      const {
        forwardedRef,
        ...restPropsTmp
      } = this.props as PrivateProps;

      const props = {...restPropsTmp, ref: forwardedRef} as unknown as OriginalProps;

      return <WrappedComponent {...props} />;
    }
  }

  const RefForwardingFactory = (props: OriginalProps, ref: RefObject<T>) => (
    <WithRef {...props} forwardedRef={ref}/>
  );

  return forwardRef<T, OriginalProps>(RefForwardingFactory as any);
};