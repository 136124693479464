import {
  CHANGE_INITIAL_POINT,
  HeroActionTypes
} from "../../types/ui/hero";

export function changeInitialPoint(initialPoint: number): HeroActionTypes {
  return {
    type: CHANGE_INITIAL_POINT,
    payload: {
      initialPoint
    }
  };
}