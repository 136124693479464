import {
  CHANGE_SIGN_UP_ERROR_VALUE,
  CHANGE_SIGN_UP_FIELD_VALUE,
  ISignUpState,
  SignUpActionTypes
} from "../../types/ui/sign-up";
import {INIT_STORE} from "../../types/init";

const initialState: ISignUpState = {
  fields: {
    'email': '',
    'password': '',
    'username': '',
    'rememberMe': 'false',
    'privacyPolicy': 'false'
  },
  errors: {},
};

export function signUpReducer(
  state = initialState,
  action: SignUpActionTypes
): ISignUpState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case CHANGE_SIGN_UP_FIELD_VALUE:
      return {
        ...state,
        fields: Object.assign({},
          state.fields,
          {[action.payload.field]: action.payload.value}),
        // signUp: Object.assign({},
        //   state.signUp,
        //   {id: undefined})
      };
    case CHANGE_SIGN_UP_ERROR_VALUE:
      return {
        ...state,
        errors: Object.assign({},
          state.errors,
          {[action.payload.field]: action.payload.value})
      };
    default:
      return state;
  }
}