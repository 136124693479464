import {ComponentTypes} from "../types";
import {pigeon as pigeonTypes} from "../../../types";
import {createSimpleGumball, IRenderedObjectInternal} from "../gumball";
import {
  BooleanInterfaceTypes,
  createObjectFromBrepData,
  getCurveFromRef,
  Param_Boolean,
  Param_Brep,
  Param_Curve,
  Param_Number,
  ParamTitles,
  ParamValueTypes
} from "../../parameter";
import {Calc} from "../../calc";
import {ObjectTypes} from "../../xobject";
import {getComposeDesc, isIdentity, WompObjectRef} from "../../../WompObject";
import {_n, _v} from "../../../t";
import {mat4} from "gl-matrix";
import {BasicObjectMapper, ObjectFuncParameter, PredictedResult} from "../base";

export class RevolveModifier extends BasicObjectMapper {
  static create() {
    let calc = createSimpleGumball();
    calc.component = ComponentTypes.RevolveModifier;
    calc.title = 'revolve';

    let axisParam = Param_Curve.create(calc, ParamTitles.Axis, true, false, false);
    let curveParam = Param_Curve.create(calc, ParamTitles.Object, true, false, false);
    let capParam = Param_Boolean.create(calc, ParamTitles.Cap, true, true, {
      defaultValue: true,
      interfaceType: BooleanInterfaceTypes.Toggle
    });
    let angleParam = Param_Number.create(calc, ParamTitles.Angle, true, true, {type: ParamValueTypes.Degree})
    let revolveParam = Param_Brep.create(calc, ParamTitles.Revolve, false, false, true);

    calc.addParameter(axisParam);
    calc.addParameter(curveParam);
    calc.addParameter(capParam);
    calc.addParameter(angleParam);
    calc.addParameter(revolveParam);

    return calc;
  }

  static getParameters(calc: Calc, obj: IRenderedObjectInternal) {
    let axisParam = calc.inputByTitle(ParamTitles.Axis) as Param_Curve;
    let capParam = calc.inputByTitle(ParamTitles.Cap) as Param_Boolean;
    let angleParam = calc.inputByTitle(ParamTitles.Angle) as Param_Number;

    let cap = capParam.values[0];
    let angle = angleParam.values[0] || 360;

    let axisCurve = getCurveFromRef(calc, axisParam.values[0]);
    let points = axisCurve.geometry.points || [];
    let axFrom = points[0] as [number, number, number];
    let axTo = points[points.length - 1] as [number, number, number];

    let matrix = axisParam.values[0].matrix;
    let invMatrix = mat4.invert(mat4.create(), matrix);

    let objValue = obj.value;
    if (!isIdentity(invMatrix)) {
      objValue = new WompObjectRef(objValue.objectId, mat4.multiply(mat4.create(), invMatrix, objValue.matrix));
    }

    let desc = `revolve[${getComposeDesc(objValue, 3)},(${_v(axFrom).join(',')}),(${_v(axTo).join(',')}),${cap},${_n(angle)}]`;

    return {desc, matrix, axFrom, axTo, cap, angle};
  }

  static predictResult(calc: Calc, obj: IRenderedObjectInternal) {
    if (obj.valueType === ObjectTypes.Curve)
      return PredictedResult.Brep;
    return PredictedResult.Ignore;
  }

  static async mapObject(calc: Calc, obj: IRenderedObjectInternal, parameters: ObjectFuncParameter) {
    let invMatrix = mat4.invert(mat4.create(), parameters.matrix);

    let objValue = obj.value;
    if (!isIdentity(invMatrix)) {
      objValue = new WompObjectRef(objValue.objectId, mat4.multiply(mat4.create(), invMatrix, objValue.matrix));
    }

    let brepData = await pigeonTypes.revolve(getCurveFromRef(calc, objValue), parameters.axFrom, parameters.axTo, parameters.cap, parameters.angle);

    return createObjectFromBrepData(calc, brepData, parameters.desc, parameters.matrix);
  }
}
