import {Matrix4, Vector3, Mesh, Object3D, PlaneBufferGeometry, MeshBasicMaterial, BackSide} from 'three';
import {NLightHelperPicker} from './NLightHelperPicker';

function NRectAreaLightHelper(light, lightId, camera, hoverStartEvent, hoverStopEvent, updateCallback) {

  Object3D.call(this);

  this.light = light;
  this.light.updateMatrixWorld();

  this.lightId = lightId;
  this.updateCallback = updateCallback;

  this.matrix = light.matrixWorld;
  this.matrixAutoUpdate = false;
  // console.log('rect create', light.matrixWorld)

  this.lightPlane = new Mesh(new PlaneBufferGeometry(), new MeshBasicMaterial({
    side: BackSide,
    color: light.color
  }));
  this.lightPlane.scale.x = light.width;
  this.lightPlane.scale.y = light.height;

  this.add(this.lightPlane);

  this.lightPlaneBack = new Mesh(new PlaneBufferGeometry(), new MeshBasicMaterial({color: 0x707070}));
  this.lightPlane.add(this.lightPlaneBack);

  this.positionPicker = new NLightHelperPicker(lightId + ':position', camera, hoverStartEvent, hoverStopEvent, this.onChangeLightPosition.bind(this));
  // this.positionPicker.position.set(lightInfo.position[0], lightInfo.position[1], lightInfo.position[2])
  this.add(this.positionPicker);

  this.targetPicker = new NLightHelperPicker(lightId + ':target', camera, hoverStartEvent, hoverStopEvent, this.onChangeTargetPosition.bind(this));
  // this.targetPicker.position.set(lightInfo.targetPosition[0], lightInfo.targetPosition[1], lightInfo.targetPosition[2])
  this.add(this.targetPicker);

  this.update();

}

NRectAreaLightHelper.prototype = Object.create(Object3D.prototype);
NRectAreaLightHelper.prototype.constructor = NRectAreaLightHelper;

NRectAreaLightHelper.prototype.dispose = function () {

  this.lightPlane.geometry.dispose();
  this.lightPlane.material.dispose();
  this.lightPlaneBack.geometry.dispose();
  this.lightPlaneBack.material.dispose();

  this.positionPicker.dispose();
  this.targetPicker.dispose();
};

NRectAreaLightHelper.prototype.setDelegate = function (delegate) {

  this.positionPicker.setDelegate(delegate);
  this.targetPicker.setDelegate(delegate);

};

NRectAreaLightHelper.prototype.unsetDelegate = function () {

  this.positionPicker.unsetDelegate();
  this.targetPicker.unsetDelegate();

};

NRectAreaLightHelper.prototype.setCamera = function (camera) {

  this.positionPicker.camera = camera;
  this.targetPicker.camera = camera;

};

NRectAreaLightHelper.prototype.onChangeLightPosition = function (x, y, z) {
  // console.log('offset', x, y, z)
  this.updateCallback(this.lightId, {
    position: [
      x + this.light.position.x,
      y + this.light.position.y,
      z + this.light.position.z
    ]
  });
};

NRectAreaLightHelper.prototype.onChangeTargetPosition = function (x, y, z) {
  // console.log('offset', x, y, z)
  this.updateCallback(this.lightId, {
    targetPosition: [
      x + this.light.target.x,
      y + this.light.target.y,
      z + this.light.target.z
    ]
  });
};

NRectAreaLightHelper.prototype.update = function () {
  this.light.updateMatrixWorld();

  this.matrix = this.light.matrixWorld;
  this.matrixAutoUpdate = false;

  this.lightPlane.scale.x = this.light.width;
  this.lightPlane.scale.y = this.light.height;
  this.lightPlane.material.color.copy(this.light.color).multiplyScalar(this.light.intensity);

  this.positionPicker.sprite.matrixAutoUpdate = true;
  new Matrix4().decompose(this.positionPicker.sprite.position, this.positionPicker.sprite.quaternion, this.positionPicker.sprite.scale);

  this.targetPicker.sprite.matrixAutoUpdate = true;
  new Matrix4().decompose(this.targetPicker.sprite.position, this.targetPicker.sprite.quaternion, this.targetPicker.sprite.scale);

  var toLocal = this.matrix.clone().invert();
  var offset = new Vector3().copy(this.light.target);
  offset.applyMatrix4(toLocal);

  this.targetPicker.position.set(offset.x, offset.y, offset.z);
};


export {NRectAreaLightHelper};