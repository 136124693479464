import React from "react";
import {IAppState} from "../../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import iconTwitter from "../../../assets/images/icon/hero/twitter.svg";
import iconInstagram from "../../../assets/images/icon/hero/instagram.svg";
import iconTiktok from "../../../assets/images/icon/hero/tiktok.svg";
import imgGradientP from "../../../assets/images/background/gradient_p.png";
import vidGradientP from "../../../assets/videos/gradient_p.mp4";
import iconWomp from "../../../assets/images/icon/hero/womp.svg";
import {Link} from "react-router-dom";
import {push} from "connected-react-router";
import Editor3dWrapper from "../../3d/Editor3dWrapper";
import {IFetchItemAsync, IGAR} from "../../../store/types/types";
import Editor3dDelegateWrapper from "../../3d/Editor3dDelegateWrapper";
import ResizeDetector from "../../component/common/ResizeDetector";
import {fetchMediaProjectLoad} from "../../action/project";
import {ABOUT_PROJECT_ID, HERO_EYE_URL, HERO_FRONT_URL, HERO_PROJECT_ID} from "../../common/const";
import {IEntityProjectsState} from "../../../store/types/entity/projects";
import ProjectLoadingBar from "../../component/viewer/ProjectLoadingBar";
import InlineVideo from "../../component/common/InlineVideo";
import HeroFooter from "./HeroFooter";

interface IAboutProps {
  editorWrapper: Editor3dWrapper

  entityState: IEntityProjectsState
  load: { [key: number]: IFetchItemAsync<number> }

  fetchMediaProjectLoad: (id: number, wrapper: Editor3dWrapper) => Promise<IGAR>
  goToMain: () => void
  goToHero: () => void
}

class About extends React.PureComponent<IAboutProps> {
  delegateWrapper!: Editor3dDelegateWrapper;

  componentDidMount() {
    const {editorWrapper, fetchMediaProjectLoad} = this.props;
    editorWrapper.setActionCallback();

    if (editorWrapper.editor) {
      editorWrapper.editor._clear();
      editorWrapper.editor._emptyCamera();
      editorWrapper.editor.configure({
        editControls: false,
        lightControls: false,
        highlight: false,
        hoverHighlight: false,
        grid: false,
        envMap: false,
        orbit: true,
        orbitSpeed: 10,
        blob: false,
        cameraZoom: false,
        cameraPan: false,
      });

      if (editorWrapper.editor.projectId !== ABOUT_PROJECT_ID) {
        fetchMediaProjectLoad(ABOUT_PROJECT_ID, editorWrapper);
      }
    }
  }

  onLogoClick = (evt: any) => {
    this.props.goToMain();
  }

  onEditor3dResize = () => {
    this.delegateWrapper.delegate && this.delegateWrapper.delegate.onContainerResize();
  };

  getProject = (props?: IAboutProps) => {
    const projectById = (props || this.props).entityState.byId;
    return projectById[HERO_PROJECT_ID];
  };

  renderHeader() {
    return <div className="about-header">
      <div className="womp-logo items-start mt8">
        <img src={iconWomp} alt='womp' onClick={this.onLogoClick}/>
        <p>womp</p>
      </div>
    </div>;
  }

  renderAboutContent() {
    const {editorWrapper, load} = this.props;

    return <div className="about-content">
      <div className="flex flex-column justify-center">
        <p className="f3 bold mb11 lh-3">about us</p>
        <div className="f9">
          <p className="lh-3 mb7">We are a team of engineers, artists and designers who agree it’s time we all 3D.</p>
          <p className="lh-3 mb7">
            We once had 3D blow our minds and change how we saw the future of content, creativity, collaboration, art, design - how we buy, sell, interact and make objects. From AR to 3D printing, for nearly a decade we’ve obsessed with 3D.
          </p>
          <p className="lh-3 mb7">
            <span>But not just with making 3D ourselves, but with answering the question:</span><br/>
            <span className="semi-bold">how do we make 3D easy for anyone to make?</span>
          </p>
          <p className="lh-3 mb7">
            Whether you’re a 3D expert, a total 3D noob, a figma addict, product designer, javascripter, NFT creator or collector, cryptovoxeler, minecrafter, cermaisist, DIY’er, fashion designer, notebook scribbler, crayon doodler, chewing gum sculptor....
          </p>
          <p className="lh-3 mb10">We built womp for you. We hope you like it.</p>
          <p className="semi-bold">🌈️&nbsp;&nbsp;Gaby, Bian & the Womp team</p>
        </div>
      </div>
      <div>
        <ResizeDetector onResize={this.onEditor3dResize}>
          <Editor3dDelegateWrapper
            editorWrapper={editorWrapper}
            elemId="about-three"
            className="w-100 h-100"
            overlayClassName="z-1 absolute pointer-none flex items-center justify-center"
            ref={ref => {
              if (ref !== null) {
                this.delegateWrapper = ref;
              }
            }}
          >
            {(!load[ABOUT_PROJECT_ID] || !load[ABOUT_PROJECT_ID].fetching) &&
            <p className="f3 bold">let's 3D</p>
            }
            <ProjectLoadingBar projectId={ABOUT_PROJECT_ID}/>
          </Editor3dDelegateWrapper>
        </ResizeDetector>
      </div>
    </div>
  }

  renderBackground() {
    return <InlineVideo loop muted autoPlay poster={imgGradientP}>
      <source type="video/mp4" src={vidGradientP}/>
    </InlineVideo>;
  }

  render() {
    const {editorWrapper} = this.props;

    return <div className="about-root">
      {this.renderBackground()}
      {this.renderHeader()}
      {this.renderAboutContent()}
      <HeroFooter editorWrapper={editorWrapper} page="about"/>
    </div>;
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    load: store.api.project.load,
    entityState: store.entities.projects,
    engineLoaded: store.entities.global.engineLoaded
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  return {
    fetchMediaProjectLoad: (id: number, wrapper: Editor3dWrapper) => dispatch(fetchMediaProjectLoad(id, wrapper)),
    goToMain: () => dispatch(push(HERO_FRONT_URL)),
    goToHero: () => dispatch(push(HERO_EYE_URL)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(About);