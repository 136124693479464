import {IScreenshot, NormalizedObjects} from "../types";
import {InitStoreAction} from "../init";

export const ADD_SCREENSHOTS = 'ADD_SCREENSHOTS';
export const REMOVE_SCREENSHOTS = 'REMOVE_SCREENSHOTS';
export const REPLACE_SCREENSHOTS = 'REPLACE_SCREENSHOTS';

export interface IEntityScreenshotsState extends NormalizedObjects<IScreenshot> {
}

interface ReplaceScreenshotsAction {
  type: typeof REPLACE_SCREENSHOTS
  payload: {
    screenshots: IScreenshot[]
  }
}

interface AddScreenshotsAction {
  type: typeof ADD_SCREENSHOTS
  payload: {
    screenshots: IScreenshot[]
  }
}

interface RemoveScreenshotsAction {
  type: typeof REMOVE_SCREENSHOTS
  payload: {
    ids: number[]
  }
}

export type ScreenshotsActionTypes = AddScreenshotsAction |
  RemoveScreenshotsAction |
  ReplaceScreenshotsAction |
  InitStoreAction