import {ADD_LIGHTS, LightsActionTypes, REMOVE_LIGHT, REPLACE_LIGHTS} from "../../types/entity/lights";
import {ILightPrototype} from "../../../peregrine/processor";

export function replaceLights(lights: ILightPrototype[]): LightsActionTypes {
  return {
    type: REPLACE_LIGHTS,
    payload: {
      lights
    }
  };
}

export function addLights(lights: ILightPrototype[]): LightsActionTypes {
  return {
    type: ADD_LIGHTS,
    payload: {
      lights
    }
  };
}

export function removeLight(id: string): LightsActionTypes {
  return {
    type: REMOVE_LIGHT,
    payload: {
      id
    }
  };
}