import lod from "lodash";
import {vec3} from "gl-matrix";
import {XObject} from "../xobject";
import {ICalcConfigDiff, ICalcSetting, IParameterProperty} from "../types";
import {Relation} from "../relation";
import {getObjectType, Parameter, ParamTypes, ParamValueTypes} from "./parameter";
import {Param_Compound} from "./param-compound";
import {_b, _c, _m, _n, _s, _t, _v, _w} from "../../t";
import {peregrineId} from "../../id";

export class Param_Point extends Parameter {
  protected _objectType = ParamTypes.Point;
  protected _defaultValue: vec3 | undefined;
  protected _render = false;

  protected _bounds: number[][] = [];

  get bounds() {
    return this._bounds;
  }

  set bounds(bounds: number[][]) {
    if (!lod.isEqual(this._bounds, bounds)) {
      this._bounds = bounds;
      this._stateValid = false;
      this._data = {
        ...this._data,
        [_s('bounds')]: bounds.map(b => b.map(v => _n(v)))
      };
    }
  }

  protected _type: string = ParamValueTypes.Float;

  get type() {
    return this._type;
  }

  set type(type: string) {
    if (this._type !== type) {
      this._type = type;
      this._stateValid = false;
      this._data = {
        ...this._data,
        [_s('type')]: _s(this._type)
      };
    }
  }

  protected _lockBounds: boolean[][] = [];

  get lockBounds() {
    return this._lockBounds;
  }

  set lockBounds(lockBounds: boolean[][]) {
    if (!lod.isEqual(this._lockBounds, lockBounds)) {
      this._lockBounds = lockBounds;
      this._stateValid = false;
      this._data = {
        ...this._data,
        [_s('lockBounds')]: lockBounds.map(l => l.map(_b))
      };
    }
  }

  protected _steps: number[] = [];

  get steps() {
    return this._steps;
  }

  set steps(steps: number[]) {
    if (!lod.isEqual(this._steps, steps)) {
      this._steps = steps;
      this._stateValid = false;
      this._data = {
        ...this._data,
        [_s('steps')]: this._steps.map(v => _n(v))
      };
    }
  }

  // Runtime Data
  protected _values: vec3[] = [];

  get values() {
    return this._values;
  }

  set values(values: vec3[]) {
    if (!lod.isEqual(this._values, values)) {
      this._values = values;
      this._stateValid = false;
      this._dataValid = false;
    }
  }

  get defaultValue() {
    return this._defaultValue;
  }

  set defaultValue(defaultValue: vec3 | undefined) {
    if (this._defaultValue !== defaultValue) {
      this._defaultValue = defaultValue;
      this._data = {
        ...this._data,
        [_s('defaultValue')]: defaultValue ? _v(defaultValue) : undefined
      };
    }
  }

  get length() {
    return this._values.length;
  }

  static create(calc: XObject, title: string, isInput: boolean, isOperable: boolean,
                options?: { type?: string, hidden?: boolean, defaultValue?: vec3, bounds?: number[][], lockBounds?: boolean[][], steps?: number[] }) {
    let param = new Param_Point();
    param._id = peregrineId();
    param._calc = calc;
    param._title = title;
    param._isInput = isInput;
    param._isOperable = isOperable;

    if (options === undefined) options = {};

    options.type !== undefined && (param.type = options.type);
    options.hidden !== undefined && (param._hidden = options.hidden);

    switch (param._type) {
      case ParamValueTypes.Length:
        param.bounds = options.bounds === undefined ? [[0, 100], [0, 100], [0, 100]] : options.bounds;
        param.lockBounds = options.lockBounds === undefined ? [[true, false], [true, false], [true, false]] : options.lockBounds;
        param.steps = options.steps === undefined ? [0.01, 0.01, 0.01] : options.steps;
        param.defaultValue = options.defaultValue === undefined ? vec3.fromValues(20, 20, 20) : options.defaultValue;
        break;
      case ParamValueTypes.NonZeroLength:
        param.steps = options.steps === undefined ? [0.01, 0.01, 0.01] : options.steps;
        param.bounds = options.bounds === undefined ? [[param._steps[0], 100], [param._steps[1], 100], [param._steps[2], 100]] : options.bounds;
        param.lockBounds = options.lockBounds === undefined ? [[true, false], [true, false], [true, false]] : options.lockBounds;
        param.defaultValue = options.defaultValue === undefined ? vec3.fromValues(20, 20, 20) : options.defaultValue;
        break;
      case ParamValueTypes.Offset:
        param.bounds = options.bounds === undefined ? [[-100, 100], [-100, 100], [-100, 100]] : options.bounds;
        param.lockBounds = options.lockBounds === undefined ? [[false, false], [false, false], [false, false]] : options.lockBounds;
        param.steps = options.steps === undefined ? [0.01, 0.01, 0.01] : options.steps;
        param.defaultValue = options.defaultValue === undefined ? vec3.fromValues(0, 0, 0) : options.defaultValue;
        break;
      case ParamValueTypes.Degree:
        param.bounds = options.bounds === undefined ? [[0, 360], [0, 360], [0, 360], [0, 360]] : options.bounds;
        param.lockBounds = options.lockBounds === undefined ? [[true, true], [true, true], [true, true]] : options.lockBounds;
        param.steps = options.steps === undefined ? [0.01, 0.01, 0.01] : options.steps;
        param.defaultValue = options.defaultValue === undefined ? vec3.fromValues(0, 0, 0) : options.defaultValue;
        break;
      case ParamValueTypes.Percent:
        param.bounds = options.bounds === undefined ? [[0, 100], [0, 100], [0, 100]] : options.bounds;
        param.lockBounds = options.lockBounds === undefined ? [[true, true], [true, true], [true, true]] : options.lockBounds;
        param.steps = options.steps === undefined ? [0.01, 0.01, 0.01] : options.steps;
        param.defaultValue = options.defaultValue === undefined ? vec3.fromValues(100, 100, 100) : options.defaultValue;
        break;
      case ParamValueTypes.Skew:
        param.bounds = options.bounds === undefined ? [[-2, 2], [-2, 2], [-2, 2]] : options.bounds;
        param.lockBounds = options.lockBounds === undefined ? [[false, false], [false, false], [false, false]] : options.lockBounds;
        param.steps = options.steps === undefined ? [0.01, 0.01, 0.01] : options.steps;
        param.defaultValue = options.defaultValue === undefined ? vec3.fromValues(0, 0, 0) : options.defaultValue;
        break;
      case ParamValueTypes.Scale:
        param.bounds = options.bounds === undefined ? [[-1000, 1000], [-1000, 1000], [-1000, 1000]] : options.bounds;
        param.lockBounds = options.lockBounds === undefined ? [[false, false], [false, false], [false, false]] : options.lockBounds;
        param.steps = options.steps === undefined ? [0.01, 0.01, 0.01] : options.steps;
        param.defaultValue = options.defaultValue === undefined ? vec3.fromValues(100, 100, 100) : options.defaultValue;
        break;
      case ParamValueTypes.UnsignedInt:
        param.bounds = options.bounds === undefined ? [[1, 100], [1, 100], [1, 100]] : options.bounds;
        param.lockBounds = options.lockBounds === undefined ? [[true, false], [true, false], [true, false]] : options.lockBounds;
        param.steps = options.steps === undefined ? [1, 1, 1] : options.steps;
        param.defaultValue = options.defaultValue === undefined ? vec3.fromValues(1, 1, 1) : options.defaultValue;
        break;
      default:
        param.bounds = options.bounds === undefined ? [[-100, +100], [-100, +100], [-100, +100]] : options.bounds;
        param.lockBounds = options.lockBounds === undefined ? [[true, true], [true, true], [true, true]] : options.lockBounds;
        param.steps = options.steps === undefined ? [1, 1, 1] : options.steps;
        param.defaultValue = options.defaultValue === undefined ? undefined : options.defaultValue;
    }

    if (param.defaultValue)
      param.values = [vec3.clone(param.defaultValue)];

    param.setupInitialData();
    return param;
  }

  generateProperties(): IParameterProperty[] {
    let properties: IParameterProperty[] = [];

    for (let i = 0; i < this._values.length; ++i) {
      let value = this._values[i];

      properties.push({
        hash: `${_n(value[0])} ${_n(value[1])} ${_n(value[2])}`
      });
    }

    return properties;
  }

  generateState() {
    let settings: ICalcSetting[] = [];

    let labels = ['x axis', 'y axis', 'z axis'];
    for (let i = 0; i < this._values.length; ++i) {
      let value = this._values[i];
      for (let j = 0; j < 3; ++j) {
        settings.push({
          id: this._id + ':' + i + ':' + j,
          label: labels[j] + (this._values.length ? '' : i),
          type: 'number',
          value: value[j],
          min: this._bounds[j][0],
          max: this._bounds[j][1],
          step: this._steps[j],
          lockMin: this._lockBounds[j][0],
          lockMax: this._lockBounds[j][1],
          valueType: this._type,
          interfaceType: this._interfaceType
        });
      }
    }
    return settings;
  }

  protected validateGeneratedData() {
    this.dataValues = this._values.map(v => _v(v));

    this._dataValid = true;
  }

  overwrite(jData: any, replaceData: boolean) {
    if (!this.dataValid)
      this.validateGeneratedData();

    super.overwrite(jData, replaceData);

    if (this._data[_s('values')] !== jData[_s('values')] && jData[_s('values')] !== undefined) {
      this.values = jData[_s('values')].map(_w);
    }

    if (this._data[_s('defaultValue')] !== jData[_s('defaultValue')] && jData[_s('defaultValue')] !== undefined) {
      this.defaultValue = _w(jData[_s('defaultValue')]);
    }

    if (this._data[_s('bounds')] !== jData[_s('bounds')] && jData[_s('bounds')] !== undefined) {
      this.bounds = jData[_s('bounds')].map((b: string[]) => b.map(_m));
    }

    if (this._data[_s('lockBounds')] !== jData[_s('lockBounds')] && jData[_s('lockBounds')] !== undefined) {
      this.lockBounds = jData[_s('lockBounds')].map((l: number[]) => l.map(_c));
    }

    if (this._data[_s('steps')] !== jData[_s('steps')] && jData[_s('steps')] !== undefined) {
      this.steps = jData[_s('steps')].map(_m);
    }

    if (this._data[_s('type')] !== jData[_s('type')] && jData[_s('type')] !== undefined) {
      this.type = _t(jData[_s('type')]);
    }

    if (replaceData) {
      this._data = jData;
      this._dataValid = true;
    }
  }

  adjustBounds() {
    let bounds = lod.cloneDeep(this._bounds);
    for (let j = 0; j < 3; ++j) {
      if (isNaN(bounds[j][0]))
        bounds[j][0] = -100;
      if (isNaN(bounds[j][0]))
        bounds[j][1] = 100;
    }

    for (let i = 0; i < this._values.length; ++i) {
      let values = this._values[i];
      for (let j = 0; j < 3; ++j) {
        if (!this._lockBounds[j][0])
          bounds[j][0] = Math.min(bounds[j][0], values[j]);
        if (!this._lockBounds[j][1])
          bounds[j][1] = Math.max(bounds[j][1], values[j]);
      }
    }

    this.bounds = bounds;
  }

  solve(diff?: ICalcConfigDiff) {
    if (diff && diff.obj) {
      this._values = [];

      for (let relation of this._prevRelations) {
        let prev = (relation as unknown as Relation).from;
        if (prev.objectType === ParamTypes.Compound) {
          let prevComp = prev as Param_Compound;
          this._values = prevComp.filterValues(getObjectType(this._objectType));
        } else {
          if (prev.objectType === this._objectType) {
            let prevSame = prev as Param_Point;
            this._values = [...this._values, ...prevSame._values];
            this._type = prevSame._type;
            this._bounds = prevSame._bounds;
            this._lockBounds = prevSame._lockBounds;
            this._steps = prevSame._steps;
          } else {
            console.warn('Type mismatch. Please implement type conversion');
          }
        }
      }

      if (this._prevRelations.length === 0 && this._defaultValue !== undefined) {
        this._values = [vec3.clone(this._defaultValue)];
      }
      this._dataValid = false;
    }

    for (let i = 0; i < this.length; ++i) {
      if (lod.some(this._values[i], isNaN)) {
        console.warn(`NaN value found in parameter[${this._title}] of calc[${(this._calc as any).title}]`);
        this._values[i] = vec3.create();
        this.adjustBounds();
        this._dataValid = false;
      }
    }
  }
}