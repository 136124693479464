import {ILibraryTopCategory, NormalizedObjects} from "../types";
import {InitStoreAction} from "../init";

export const REPLACE_LIBRARY_TOP_CATEGORIES = 'REPLACE_LIBRARY_TOP_CATEGORIES';

export interface IEntityLibraryTopCategoriesState extends NormalizedObjects<ILibraryTopCategory> {
}

interface ReplaceLibraryTopCategoriesAction {
  type: typeof REPLACE_LIBRARY_TOP_CATEGORIES
  payload: {
    libraryTopCategories: ILibraryTopCategory[]
  }
}

export type LibraryTopCategoriesActionTypes = ReplaceLibraryTopCategoriesAction |
  InitStoreAction