import {IChannelRoute, IProjectDynamic} from "../types";
import {InitStoreAction} from "../init";
import {InitSceneAction} from "../ui/global";
import {ICommandManagerState, ISimpleCommand} from "../../../app/core";

export const ADD_CHANNEL_ROUTES = 'ADD_CHANNEL_ROUTES';
export const ADD_CHANNELS = 'ADD_CHANNELS';
export const REMOVE_CHANNEL_ROUTES = 'REMOVE_CHANNEL_ROUTES';
export const UPDATE_COMMAND_MANAGER = 'UPDATE_COMMAND_MANAGER';
export const CHANGE_SHOW_CURSOR = 'CHANGE_SHOW_CURSOR';
export const CHANGE_SYNC_CAMERA = 'CHANGE_SYNC_CAMERA';
export const CHANGE_SYNC_STATUS = 'CHANGE_SYNC_STATUS';
export const ADD_PROGRESS = 'ADD_PROGRESS';
export const REMOVE_PROGRESS = 'REMOVE_PROGRESS';
export const CLEAR_PROGRESS = 'CLEAR_PROGRESS';
export const CHANGE_PROGRESS_RANGE = 'CHANGE_PROGRESS_RANGE';

export interface IProjectState {
  dynamic: IProjectDynamic
  progressIds: string[]
  progressFrom: number
  progressTo: number
}

interface AddChannelRoutesAction {
  type: typeof ADD_CHANNEL_ROUTES
  payload: {
    id: number
    channelRoutes: {[key: number]: IChannelRoute}
  }
}

interface AddChannelsAction {
  type: typeof ADD_CHANNELS
  payload: {
    channels: {[key: number]: {[key: number]: IChannelRoute}}
  }
}

interface RemoveChannelRoutesAction {
  type: typeof REMOVE_CHANNEL_ROUTES
  payload: {
    id: number
    routeIds: number[]
  }
}

interface UpdateCommandManagerAction {
  type: typeof UPDATE_COMMAND_MANAGER
  payload: {
    id: number
    newState: ICommandManagerState
  }
}

interface ChangeShowCursorAction {
  type: typeof CHANGE_SHOW_CURSOR
  payload: {
    id: number
    username: string
    showCursor: boolean
  }
}

interface ChangeSyncCameraAction {
  type: typeof CHANGE_SYNC_CAMERA
  payload: {
    id: number
    username: string
    syncCamera: boolean
  }
}

interface ChangeSyncStatusAction {
  type: typeof CHANGE_SYNC_STATUS
  payload: {
    id: number
    routeStartCursor: number
    serverCursor: number
    serverCommands: ISimpleCommand[]
  }
}

interface AddProgressAction {
  type: typeof ADD_PROGRESS
  payload: {
    progressId: string
  }
}

interface RemoveProgressAction {
  type: typeof REMOVE_PROGRESS
  payload: {
    progressId: string
  }
}

interface ClearProgressAction {
  type: typeof CLEAR_PROGRESS
  payload: {
  }
}

interface ChangeProgressRangeAction {
  type: typeof CHANGE_PROGRESS_RANGE
  payload: {
    progressFrom?: number
    progressTo?: number
  }
}

export type ProjectActionTypes = AddChannelRoutesAction |
  AddChannelsAction |
  RemoveChannelRoutesAction |
  UpdateCommandManagerAction |
  ChangeShowCursorAction |
  ChangeSyncCameraAction |
  ChangeSyncStatusAction |
  ChangeProgressRangeAction |
  AddProgressAction |
  RemoveProgressAction |
  ClearProgressAction |
  InitSceneAction |
  InitStoreAction
