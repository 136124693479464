import {ComponentTypes} from "../types";
import {three as threeTypes} from "../../../types";
import {createGumball} from "../gumball";
import {Calc} from "../../calc";
import {Param_Mesh, ParamTitles} from "../../parameter";
import {WompMesh} from "../../../WompObject";
import {getMeshDataFromThreeGeometry} from "../../../converter";
import {mat4, vec3} from "gl-matrix";
import {BasicMeshGenerator} from "./basic-gen";

export class ThreePlaneGenerator extends BasicMeshGenerator {
  static defaultParameter = {
    width: 20,
    height: 20
  };
  static draggingPreview = ThreePlaneGenerator.generateMesh();

  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.ThreePlaneGenerator;
    calc.title = 'plane';

    let planeParam = Param_Mesh.create(calc, ParamTitles.Plane, false, false, true);

    calc.addParameter(planeParam);

    return calc;
  }

  static getDesc(calc: Calc) {
    return 'plane';
  }

  static generateMesh(parameter: any = this.defaultParameter) {
    let value: threeTypes.BufferGeometry = new threeTypes.PlaneBufferGeometry(
      this.defaultParameter.width,
      this.defaultParameter.height,
      1,
      1
    );

    value.rotateX(Math.PI / 2);
    value.rotateZ(Math.PI);

    let geom = getMeshDataFromThreeGeometry(value);

    return new WompMesh(geom, this.generateMatrix(parameter));
  }

  static generateMatrix(parameter: any = this.defaultParameter) {
    return mat4.fromScaling(
      mat4.create(),
      vec3.fromValues(
        parameter.width / this.defaultParameter.width,
        parameter.height / this.defaultParameter.height,
        1
      )
    );
  }
}
