import {ComponentTypes} from "../types";
import {createGumball, IRenderedObjectInternal} from "../gumball";
import {Calc} from "../../calc";
import {ObjectTypes} from "../../xobject";
import {Param_Compound, Param_Curve, ParamTitles} from "../../parameter";
import {BasicObjectMapper, PredictedResult} from "../base";

export class CurveParameter extends BasicObjectMapper {
  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.CurveParameter;
    calc.title = 'curve';

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let curveParam = Param_Curve.create(calc, ParamTitles.Curve, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(curveParam);
    return calc;
  }

  static predictResult(calc: Calc, obj: IRenderedObjectInternal) {
    if (obj.valueType === ObjectTypes.Curve)
      return PredictedResult.CopyFirst;

    return PredictedResult.Ignore;
  }
}
