import {
  DELETE_PROJECT,
  ApiProjectActionTypes,
  FETCH_PROJECTS,
  IApiProjectState,
  DUPLICATE_PROJECT,
  DOWNLOAD_PROJECT, POST_PROJECT_SAVE, FETCH_PROJECT_LOAD, POST_PROJECT, CREATE_PROJECT, FETCH_SHARED_PROJECT
} from '../../types/api/project';
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';

const initialState: IApiProjectState = {
  getProjects: {
    fetching: false
  },
  deleteProjects: {},
  duplicateProjects: {},
  downloadProjects: {},
  load: {},
  save: {},
  postProject: {
    posting: false
  },
  newProject: {
    posting: false
  },
  fetchShared: {
    fetching: false
  }
};

export function apiProjectReducer(
  state = initialState,
  action: ApiProjectActionTypes
): IApiProjectState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case FETCH_PROJECTS:
      return {
        ...state,
        getProjects: {
          fetching: action.payload.fetching,
          message: action.payload.message,
          items: action.payload.items
        }
      };
    case DELETE_PROJECT:
      if (!action.payload.deleting && action.payload.success) {
        return {
          ...state,
          deleteProjects: lod.omit(state.deleteProjects, action.payload.id)
        };
      }

      return {
        ...state,
        deleteProjects: {
          ...state.deleteProjects,
          [action.payload.id]: {
            deleting: action.payload.deleting,
            message: action.payload.message,
            id: action.payload.id
          }
        }
      };
    case DUPLICATE_PROJECT:
      if (!action.payload.posting && action.payload.success) {
        return {
          ...state,
          duplicateProjects: lod.omit(state.duplicateProjects, action.payload.id)
        };
      }

      return {
        ...state,
        duplicateProjects: {
          ...state.duplicateProjects,
          [action.payload.id]: {
            posting: action.payload.posting,
            message: action.payload.message,
            id: action.payload.id
          }
        }
      };
    case DOWNLOAD_PROJECT:
      if (!action.payload.posting && action.payload.success) {
        return {
          ...state,
          downloadProjects: lod.omit(state.downloadProjects, action.payload.id)
        };
      }

      return {
        ...state,
        downloadProjects: {
          ...state.downloadProjects,
          [action.payload.id]: {
            posting: action.payload.posting,
            message: action.payload.message,
            id: action.payload.id
          }
        }
      };

    case POST_PROJECT_SAVE:
      if (!action.payload.posting && action.payload.success) {
        return {
          ...state,
          save: lod.omit(state.save, action.payload.id)
        };
      }

      return {
        ...state,
        save: {
          ...state.save,
          [action.payload.id]: {
            posting: action.payload.posting,
            message: action.payload.message,
            id: action.payload.id,
            extraInfo: action.payload.extraInfo
          }
        }
      };
    case FETCH_PROJECT_LOAD:
      if (!action.payload.fetching && action.payload.success) {
        return {
          ...state,
          load: lod.omit(state.load, action.payload.id)
        };
      }

      return {
        ...state,
        load: {
          ...state.load,
          [action.payload.id]: {
            fetching: action.payload.fetching,
            message: action.payload.message,
            id: action.payload.id,
            extraInfo: action.payload.extraInfo
          }
        }
      };
    case FETCH_SHARED_PROJECT:
      return {
        ...state,
        fetchShared: {
          fetching: action.payload.fetching,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    case POST_PROJECT:
      return {
        ...state,
        postProject: {
          posting: action.payload.posting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    case CREATE_PROJECT:
      return {
        ...state,
        newProject: {
          posting: action.payload.posting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    default:
      return state;
  }
}