import {
  ConfirmEmailActionTypes,
  IConfirmEmailState,
} from "../../types/ui/confirm-email";
import {INIT_STORE} from "../../types/init";

const initialState: IConfirmEmailState = {
};

export function confirmEmailReducer(
  state = initialState,
  action: ConfirmEmailActionTypes
): IConfirmEmailState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    default:
      return state;
  }
}