/**
 * @author arodic / https://github.com/arodic
 */

import {
  Box3,
  BufferGeometry,
  ConeBufferGeometry,
  DoubleSide,
  Euler,
  EventDispatcher,
  ExtrudeBufferGeometry,
  Face3,
  Float32BufferAttribute,
  Group,
  LineBasicMaterial,
  LineSegments,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneBufferGeometry,
  Quaternion,
  Raycaster,
  RingBufferGeometry,
  SphereBufferGeometry,
  Sphere,
  Sprite,
  SpriteMaterial,
  Texture,
  Vector2,
  Vector3,
  FrontSide,
  BackSide,
  CylinderBufferGeometry, Uint32BufferAttribute
} from 'three';
import {Line2} from 'three/examples/jsm/lines/Line2';
import {LineGeometry} from 'three/examples/jsm/lines/LineGeometry';
import {LineMaterial} from 'three/examples/jsm/lines/LineMaterial';
import {SVGLoader} from 'three/examples/jsm/loaders/SVGLoader';
import {SpriteText2D, textAlign} from 'three-text2d';
import lod from 'lodash';
import Snap from "../helpers/Snap";

const ARROW_IMAGE_DATA = `<svg width="26" height="12" viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.1442 3.4794C17.699 3.03426 17.4761 2.36696 17.8091 1.81134C18.0309 1.14443 18.5865 0.811377 19.2536 0.811973L24.1457 0.816345C24.3681 0.816544 24.5905 0.816742 24.7017 0.928026C24.9241 0.928224 25.1467 1.15079 25.258 1.26208C25.3692 1.37336 25.5918 1.59593 25.592 1.81829C25.5922 2.04066 25.7035 2.15194 25.7037 2.37431L25.7081 7.26641C25.7087 7.93351 25.3756 8.48913 24.7087 8.7109C24.0418 8.93268 23.4858 8.821 23.0406 8.37586L21.7052 7.04046L20.2611 8.48456C16.3732 12.3725 10.1469 12.367 6.25195 8.47205L4.80527 7.02536L3.47226 8.35838C3.02792 8.80272 2.36101 9.02449 1.8048 8.69044C1.13749 8.46748 0.803444 7.91126 0.802848 7.24415L0.798479 2.35206C0.79828 2.12969 0.798081 1.90732 0.909166 1.79624C1.02005 1.46279 1.35331 1.12953 1.79784 0.907562C2.02021 0.90776 2.1313 0.796677 2.35366 0.796875L7.24576 0.801245C7.69049 0.801643 8.02414 0.913126 8.35799 1.24698C8.46928 1.35826 8.69185 1.58083 8.69204 1.80319C8.91501 2.4705 8.80432 3.02632 8.35998 3.47065L7.02697 4.80367L8.47365 6.25035C11.1444 8.92115 15.3694 8.92493 18.0355 6.2589L19.4796 4.8148L18.1442 3.4794Z" fill="#0D0032"/>
</svg>`;

const ARROW_BACK_IMAGE_DATA = `<svg width="39" height="22" viewBox="0 0 39 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.1248 21.6158C7.59897 21.6158 -0.0678523 13.912 0.000452884 4.40892C0.0193871 1.77466 2.43164 0 5.06596 0H33.1307C35.7527 0 38.1436 1.787 38.1248 4.40892C38.0565 13.912 30.2789 21.6158 20.7531 21.6158H17.1248Z" fill="black"/>
</svg>`;

const TRIANGLE_IMAGE_DATA = `<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.54609 0.983703C3.72489 0.596579 4.27513 0.596578 4.45393 0.983702L7.13634 6.79128C7.28937 7.1226 7.04737 7.50094 6.68242 7.50094H1.31761C0.952649 7.50094 0.710654 7.1226 0.863686 6.79128L3.54609 0.983703Z" fill="black"/>
</svg>`;

const TRIANGLE_BACK_IMAGE_DATA = `<svg width="12" height="24" viewBox="0 0 12 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.09205 0.967662C5.44955 0.193073 6.55047 0.19307 6.90797 0.967659L11.8066 11.5814C11.9293 11.8473 11.9293 12.1536 11.8066 12.4195L6.90797 23.0332C6.55047 23.8078 5.44955 23.8078 5.09205 23.0332L0.19342 12.4195C0.0706992 12.1536 0.0706991 11.8473 0.19342 11.5814L5.09205 0.967662Z" fill="white"/>
</svg>`;

const PI = Math.PI;
const PI2 = PI / 2;
const EPSILON = 0.00001;

const AXIS_TO_INDEX = {
  'X': 0,
  'Y': 1,
  'Z': 2
}
const SCALE_PICKER_INFO = {
  LUB: {
    position: new Vector3(-.5, .5, -.5),
    offset: new Vector3(-1, 1, 0),
    type: 'corner',
    axes: ['X', 'Y']
  },
  RUB: {
    position: new Vector3(.5, .5, -.5),
    offset: new Vector3(1, 1, 0),
    type: 'corner',
    axes: ['X', 'Y']
  },
  LDB: {
    position: new Vector3(-.5, -.5, -.5),
    offset: new Vector3(-1, -1, 0),
    type: 'corner',
    axes: ['X', 'Y']
  },
  RDB: {
    position: new Vector3(.5, -.5, -.5),
    offset: new Vector3(1, -1, 0),
    type: 'corner',
    axes: ['X', 'Y']
  },
  B: {
    position: new Vector3(0, 0, -.5),
    offset: new Vector3(0, 0, -1),
    type: 'corner',
    axes: ['Z']
  },
  LUT: {
    position: new Vector3(-.5, .5, .5),
    offset: new Vector3(-1, 1, 0),
    type: 'corner',
    axes: ['X', 'Y']
  },
  RUT: {
    position: new Vector3(.5, .5, .5),
    offset: new Vector3(1, 1, 0),
    type: 'corner',
    axes: ['X', 'Y']
  },
  LDT: {
    position: new Vector3(-.5, -.5, .5),
    offset: new Vector3(-1, -1, 0),
    type: 'corner',
    axes: ['X', 'Y']
  },
  RDT: {
    position: new Vector3(.5, -.5, .5),
    offset: new Vector3(1, -1, 0),
    type: 'corner',
    axes: ['X', 'Y']
  },
  T: {
    position: new Vector3(0, 0, .5),
    offset: new Vector3(0, 0, 1),
    type: 'corner',
    axes: ['Z']
  },
  LB: {
    position: new Vector3(-.5, 0, -.5),
    offset: new Vector3(-1, 0, 0),
    type: 'edge',
    axes: ['X']
  },
  RB: {
    position: new Vector3(.5, 0, -.5),
    offset: new Vector3(1, 0, 0),
    type: 'edge',
    axes: ['X']
  },
  UB: {
    position: new Vector3(0, .5, -.5),
    offset: new Vector3(0, 1, 0),
    type: 'edge',
    axes: ['Y']
  },
  DB: {
    position: new Vector3(0, -.5, -.5),
    offset: new Vector3(0, -1, 0),
    type: 'edge',
    axes: ['Y']
  },
};

// R = X, U = Y, T = Z
const TRANSLATE_PICKER_INFO = {
  R: {
    position: new Vector3(.5, 0, 0),
    offset: new Vector3(1, 0, 0),
    planePosition: new Vector3(0, .5, .5),
    axes: ['X']
  },
  U: {
    position: new Vector3(0, .5, 0),
    offset: new Vector3(0, 1, 0),
    planePosition: new Vector3(.5, 0, .5),
    axes: ['Y']
  },
  T: {
    position: new Vector3(0, 0, .5),
    offset: new Vector3(0, 0, 1),
    planePosition: new Vector3(.5, .5, 0),
    axes: ['Z']
  },
};

const ROTATE_PICKER_INFO = {
  X: {
    rulerPosition: new Vector3(-0.5, 0, 0),
    rulerRotation: new Euler(0, PI2, PI),
    scale: new Vector3(0, 1, 1),
    position: new Vector3(-0.5, 0, 0.5),
    rotation: new Euler(0, -PI2, -PI2),
    offset: new Vector3(1, 0, 0)
  },
  Y: {
    rulerPosition: new Vector3(0, -0.5, 0),
    rulerRotation: new Euler(-PI2, 0, -PI2),
    scale: new Vector3(1, 0, 1),
    position: new Vector3(0, -0.5, 0.5),
    rotation: new Euler(PI2, 0, 0),
    offset: new Vector3(0, 1, 0)
  },
  Z: {
    rulerPosition: new Vector3(0, 0, -0.5),
    rulerRotation: new Euler(0, 0, PI),
    scale: new Vector3(1, 1, 0),
    position: new Vector3(-0.5, 0, -0.5),
    rotation: new Euler(0, 0, PI2),
    offset: new Vector3(0, 0, 1)
  },
};

const SCALE_IND_ROT_INFO = [
  [0, 0, PI2],
  [0, 0, 0],
  [PI2, 0, 0]
];

const RING_INFO = {
  SEG: 100,
  WIDTH: 1,
  LINE_SM: 1.3,
  LINE_BG: 2,
  SPACE: 0.1,
  OUT_LINE_COUNT: 72,
  IN_LINE_COUNT: 16,
  SHIFT_LINE_COUNT: 8,
  IN_COLOR: 0x4444ff,
  OUT_COLOR: 0x8888ff,
  SEL_COLOR: 0xff0000
};

const DRAG_MODE = {
  OBJECT: 1,
  TRANSLATE: 2,
  TRANSLATE_PLANE: 3,
  SCALE: 4,
  ROTATE: 5
};

const SCALE_INDICATOR_STATE = {
  NONE: 0,
  LR: 1,
  UD: 2,
  TB: 4,
  NONSHIFT: 7,
  SHIFT: 8,
};

const SCALE_HOVER_STATE = {
  NONE: 0,
  LR: 1,
  UD: 2,
  TB: 4,
};

let TransformControl = function (camera, scene, controlScene) {
  Object3D.call(this);

  this.domElement = document;
  let curTick = 0;
  let timer = setInterval(() => ++curTick, 1000);
  let lastEventTick;

  let Config = {
    Input: {
      WIDTH: 80,
      HEIGHT: 32
    },
    ShowTime: 5
  };

  let inputBoxes = [createInputElement('T_INP_0'), createInputElement('T_INP_1'), createInputElement('T_INP_2')];

  this.visible = false;
  this.camera = camera;
  this.scene = scene;
  this.controlScene = controlScene;
  this.eye = undefined;
  // this.intersectPlane = new Mesh(
  //   new PlaneBufferGeometry(200, 200),
  //   new MeshBasicMaterial({ visible: true, wireframe: false, side: DoubleSide, transparent: true, opacity: 0.1, color: 0x00ff00})
  // );
  this.intersectPlane = new Mesh(
    new PlaneBufferGeometry(300000, 300000),
    new MeshBasicMaterial({visible: false, wireframe: false, side: DoubleSide, transparent: true})
  );
  this.intersectPlane.name = 'transform-control-intersect-plane';

  this.rayCaster = new Raycaster();
  this.rayCaster.layers.set(0);

  this.oldState = {};

  this.spriteSize = 1.2;
  this.isDragging = false;
  this.isShowRotateRuler = false;
  this.showRotateRulerAxis = undefined;
  this.isInner = false;
  this.dragMode = undefined;
  this.dragAxis = undefined;
  this.dragStartPosition = undefined;
  this.shiftKeyState = false;
  this.altKeyState = false;
  this.scaleShiftState = false;
  this.scaleAltState = false;
  this.focusedInputId = -1;
  this.focusedValue = 0;
  this.space = 'world';
  this.boundingBox = new Box3();
  this.snap = new Snap();
  this.initialMatrix = new Matrix4();
  this.snapIncrement = 0;
  this.measureUnitInch = false;
  this.objectDraggingEnabled = true;
  this.controlEnabled = true;
  this.snapToObjectsEnabled = true;
  this.snapToGridEnabled = false;
  this.enabled = true;

  this.snapPlaneGroup = new Group();
  this.snapPointGroup = new Group();
  this.snapMatrix = new Matrix4();
  this.snapPlaneGeometry = new PlaneBufferGeometry(1, 1);
  this.snapPlaneMaterials = [
    new MeshBasicMaterial({side: DoubleSide, transparent: true, depthTest: false, opacity: 0.1, color: 0xFF0000}),
    new MeshBasicMaterial({side: DoubleSide, transparent: true, depthTest: false, opacity: 0.1, color: 0x00FF00}),
    new MeshBasicMaterial({side: DoubleSide, transparent: true, depthTest: false, opacity: 0.1, color: 0x0000FF})
  ];

  this.snapPointGeometry = new SphereBufferGeometry(0.2);
  this.snapPointMaterial = new MeshBasicMaterial({transparent: true, depthTest: false, color: 0xFF0000});
  this.elemRect = undefined;

  let scope = this;

  let unitX = new Vector3(1, 0, 0);
  let unitY = new Vector3(0, 1, 0);
  let unitZ = new Vector3(0, 0, 1);

  let temp2DVec = new Vector2();
  let tempVector = new Vector3();
  let tempMatrix = new Matrix4();
  let alignVector = new Vector3();
  let dirVector = new Vector3();

  let objUnitX = new Vector3(1, 0, 0);
  let objUnitY = new Vector3(0, 1, 0);
  let objUnitZ = new Vector3(0, 0, 1);
  let objPosition = new Vector3();
  let objScale = new Vector3();
  let objQuaternion = new Quaternion();
  let objBoundingBoxSize = new Vector3();

  let objectUnitX = new Vector3(1, 0, 0);
  let objectUnitY = new Vector3(0, 1, 0);
  let objectUnitZ = new Vector3(0, 0, 1);
  let objectPosition = new Vector3();
  let objectScale = new Vector3();
  let objectQuaternion = new Quaternion();
  let objectMatrix = new Matrix4();
  let objectBoundingBoxSize = new Vector3();

  let scaleHoverState = SCALE_HOVER_STATE.NONE;
  let scaleIndicatorState = SCALE_INDICATOR_STATE.NONE;

  let scaleHoverPickerName;
  let translateHoverPickerName;
  let translatePlaneHoverPickerName;
  let rotateHoverPickerName;

  let scaleOffset = new Vector3();
  let scaleSize = new Vector3();

  let rotateInnerAngle = 0.0;
  let rotateAngle = 0.0;
  let rotateScale = 1.0;
  let rotateStartVec = new Vector2();
  let rotateCursorPos = new Vector3();

  function commitInputValue(id, val, oldVal) {
    if (scope.object) {
      scope.object.matrix.decompose(
        objPosition,
        objQuaternion,
        objScale
      );

      if (DRAG_MODE.TRANSLATE === scope.dragMode) {

        let offsetVector = new Vector3();
        if ('L' === scope.dragAxis || 'R' === scope.dragAxis) offsetVector.x = val;
        else if ('U' === scope.dragAxis || 'D' === scope.dragAxis) offsetVector.y = val;
        else if ('T' === scope.dragAxis || 'B' === scope.dragAxis) offsetVector.z = val;

        offsetVector.copy(new Vector3()
          .addScaledVector(objectUnitX, offsetVector.x)
          .addScaledVector(objectUnitY, offsetVector.y)
          .addScaledVector(objectUnitZ, offsetVector.z)
        );

        scope.object.matrix = objectMatrix.clone()
          .premultiply(tempMatrix.makeTranslation(offsetVector.x, offsetVector.y, offsetVector.z));

      } else if (DRAG_MODE.OBJECT === scope.dragMode) {

        let offsetVector = new Vector3();
        if (0 === id) {

          offsetVector.x = val;
          offsetVector.y = objPosition.y - objectPosition.y;

        } else {

          offsetVector.x = objPosition.x - objectPosition.x;
          offsetVector.y = val;

        }

        offsetVector.copy(new Vector3()
          .addScaledVector(objectUnitX, offsetVector.x)
          .addScaledVector(objectUnitY, offsetVector.y)
          .addScaledVector(objectUnitZ, offsetVector.z)
        );

        scope.object.matrix = objectMatrix.clone()
          .premultiply(tempMatrix.makeTranslation(offsetVector.x, offsetVector.y, offsetVector.z));

      } else if (DRAG_MODE.ROTATE === scope.dragMode) {

        rotateAngle = rotateInnerAngle = (val - oldVal) * PI / 180.0;
        scope.isInner = true;
        let quaternion;

        if ('X' === scope.dragAxis) quaternion = new Quaternion().setFromAxisAngle(objectUnitX, rotateAngle);
        else if ('Y' === scope.dragAxis) quaternion = new Quaternion().setFromAxisAngle(objectUnitY, rotateAngle);
        else if ('Z' === scope.dragAxis) quaternion = new Quaternion().setFromAxisAngle(objectUnitZ, rotateAngle);

        scope.object.matrix = new Matrix4().compose(
          objectPosition,
          objectQuaternion.clone().premultiply(quaternion),
          objectScale
        );

      } else if (DRAG_MODE.SCALE === scope.dragMode) {

        val = Math.max(Math.abs(val), 0.01);
        let oldSize = new Vector3();
        let newSize = new Vector3();
        let offPos = new Vector3();
        let sizeRatio = new Vector3();

        scope.getObjectBoundingBoxSize(newSize);
        oldSize.copy(newSize);
        newSize.setComponent(id, val);
        sizeRatio.copy(newSize).divide(oldSize);

        // if (scope.scaleShiftState) sizeRatio.set(sizeRatio.getComponent(id), sizeRatio.getComponent(id), sizeRatio.getComponent(id))

        offPos.copy(oldSize).multiply(sizeRatio).addScaledVector(oldSize, -1).multiplyScalar(0.5);

        // if (scope.scaleAltState) offPos.set(0, 0, 0)

        offPos.copy(new Vector3()
          .addScaledVector(objectUnitX, offPos.x)
          .addScaledVector(objectUnitY, offPos.y)
          .addScaledVector(objectUnitZ, offPos.z)
        );

        scope.object.matrix = new Matrix4().compose(
          objPosition.clone().add(offPos),
          objQuaternion,
          objScale.clone().multiply(sizeRatio)
        );

      }

      if (scope.visible && scope.object)
        scope.dispatchEvent({type: 'change'});
    }

    return val;
  }

  function createInputElement(className) {
    let elem = document.createElement('input');
    elem.style.position = 'fixed';
    elem.style.zIndex = 9999;
    elem.style.top = '0px';
    elem.style.left = '0px';
    elem.style.width = Config.Input.WIDTH + 'px';
    elem.style.height = Config.Input.HEIGHT + 'px';
    elem.style.display = 'none';
    elem.autocomplete = 'off';
    elem.className = className;

    elem.onkeypress = (evt) => {
      if (evt.key !== 'Enter') return;

      let target = evt.target || evt.srcElement;
      target.blur();
    };

    elem.onkeydown = (evt) => {
      lastEventTick = curTick;
    };

    elem.onfocus = (evt) => {
      lastEventTick = curTick;

      let target = evt.target || evt.srcElement;
      let id = parseInt(target.id.split('_')[2]);
      if (scope.focusedInputId !== id) {
        scope.focusedInputId = id;
        scope.focusedValue = parseFloat(inputBoxes[id].value);
      }
    };

    elem.onblur = (evt) => {
      lastEventTick = curTick;

      let target = evt.target || evt.srcElement;
      let id = parseInt(target.id.split('_')[2]);

      if (scope.focusedInputId === id)
        scope.focusedInputId = -1;
      else
        console.error('non-focused input blurred');

      let val = parseFloat(inputBoxes[id].value);
      let newVal = commitInputValue(id, val, scope.focusedValue);
      if (newVal !== val) {
        target.value = newVal;
      }
    };
    // elem.onchange = function() { lastEventTick = curTick }

    document.body.appendChild(elem);
    return elem;
  }

  function moveElement(elem, obj, planes) {
    let boundingBoxSize = new Vector3();

    scope.object.matrix.decompose(
      objPosition,
      objQuaternion,
      objScale
    );

    scope.getObjectBoundingBoxSize(boundingBoxSize);
    scope.getObjectUnitAxes(objUnitX, objUnitY, objUnitZ);
    let rad = scope.spriteSize;

    let mainLine = obj.getObjectByName('MAIN_LINE');
    let linePos = mainLine.geometry.attributes.position.array;
    let from = new Vector3(linePos[0], linePos[1], linePos[2]).applyMatrix4(mainLine.matrixWorld);
    let to = new Vector3(linePos[3], linePos[4], linePos[5]).applyMatrix4(mainLine.matrixWorld);
    tempVector.copy(from).multiplyScalar(0.5).addScaledVector(to, 0.5);
    tempVector.project(scope.camera).setZ(0);

    if (!scope.elemRect)
      scope.refreshBoundingClientRect();

    let rect = scope.elemRect;

    if (planes) {
      let points = [];

      for (let plane of planes) {
        for (let i = 0; i < 4; ++i) {
          let point = objPosition.clone();
          point.addScaledVector(objUnitX, ((Math.floor(i / 2)) * 2 - 1) * (boundingBoxSize.x / 2 + rad));
          point.addScaledVector(objUnitY, ((i % 2) * 2 - 1) * (boundingBoxSize.y / 2 + rad));
          point.addScaledVector(objUnitZ, (plane === 'T' ? 1 : -1) * boundingBoxSize.z / 2);
          point.project(scope.camera).setZ(0);
          points.push(point);
        }
      }

      let box2d = new Box3();
      for (let point of points)
        box2d.expandByPoint(point);

      box2d.expandByVector(new Vector3(Config.Input.WIDTH / rect.width * 2, Config.Input.HEIGHT / rect.height * 2, 0));

      let center = box2d.getCenter(new Vector3());
      let radius = box2d.getBoundingSphere(new Sphere()).radius;
      let offset = tempVector.addScaledVector(center, -1);
      if (offset.length() < 0.0001)
        tempVector.set(0, -1, 0).multiplyScalar(radius).add(center);
      else
        tempVector.normalize().multiplyScalar(radius).add(center);
      box2d.clampPoint(tempVector, tempVector);
    }

    let x = tempVector.x * rect.width / 2 + rect.width / 2 + rect.left - Config.Input.WIDTH / 2;
    let y = -tempVector.y * rect.height / 2 + rect.height / 2 + rect.top - Config.Input.HEIGHT / 2;
    elem.style.top = y + 'px';
    elem.style.left = x + 'px';
  }

  function isAncestor(object, ancestor) {

    while (object) {
      if (object === ancestor)
        return true;
      object = object.parent;
    }

    return false;

  }

  function updateObjectLayers(object, newVisible) {

    if (object.visible && !newVisible) {

      object.traverse(obj => {

        obj.layers.disable(0);
        obj.layers.enable(1);

      });

    } else if (!object.visible && newVisible) {

      object.traverse(obj => {

        obj.layers.disable(1);
        obj.layers.enable(0);

      });

    }

    object.visible = newVisible;

  }

  this.getPickers = function () {
    return [this.translatePicker, this.scalePicker, this.rotatePicker];
  };

  this.setSize = function (size) {
    this.spriteSize = size * 12.0;
  };

  this.refreshBoundingClientRect = function () {
    this.elemRect = this.domElement === document ? document.body.getBoundingClientRect() : this.domElement.getBoundingClientRect();
  };

  this.isDraggingObject = function () {
    return this.isDragging && this.dragMode === DRAG_MODE.OBJECT;
  };

  this.setDelegate = function (delegate) {

    this.unsetDelegate();

    delegate.domElement.addEventListener('mousedown', onPointerDown);
    delegate.domElement.addEventListener('touchstart', onTouchDown);

    delegate.domElement.addEventListener('mousemove', onPointerMove);
    delegate.domElement.addEventListener('touchmove', onPointerMove);

    delegate.domElement.addEventListener('mouseup', onPointerUp);
    delegate.domElement.addEventListener('touchend', onPointerUp);
    delegate.domElement.addEventListener('touchcancel', onPointerUp);
    delegate.domElement.addEventListener('touchleave', onPointerUp);

    this.domElement = delegate.domElement;

    this.refreshBoundingClientRect();
  }

  this.unsetDelegate = function () {

    if (this.domElement === document)
      return;

    this.domElement.removeEventListener('mousedown', onPointerDown);
    this.domElement.removeEventListener('touchstart', onTouchDown);

    this.domElement.removeEventListener('mousemove', onPointerMove);
    this.domElement.removeEventListener('touchmove', onPointerMove);

    this.domElement.removeEventListener('mouseup', onPointerUp);
    this.domElement.removeEventListener('touchend', onPointerUp);
    this.domElement.removeEventListener('touchcancel', onPointerUp);
    this.domElement.removeEventListener('touchleave', onPointerUp);

    this.domElement = document;

  }

  this.dispose = function () {
    this.detach();

    clearInterval(timer);

    if (inputBoxes) {
      for (let inputBox of inputBoxes) {
        inputBox.remove();
      }
    }

    scope = null;
  };

  this.attach = function (object) {
    this.object = object;
    this.visible = true;
    this.initialMatrix = object.matrix.clone();

    scaleHoverState = SCALE_HOVER_STATE.NONE;
    scaleIndicatorState = SCALE_INDICATOR_STATE.NONE;

    scaleHoverPickerName = undefined;
    translateHoverPickerName = undefined;
    translatePlaneHoverPickerName = undefined;
    rotateHoverPickerName = undefined;

    // this.refresh();

    // lastEventTick = -Config.ShowTime - 1;
  };

  this.detach = function () {
    this.object = undefined;
    this.visible = false;
    this.isDragging = false;

    this.setSnapInfo();
  };

  this.D3ToD2 = function (axis, point, res) {
    let xPart = point.dot(objectUnitX);
    let yPart = point.dot(objectUnitY);
    let zPart = point.dot(objectUnitZ);

    if ('X' === axis) res.set(yPart, zPart);
    else if ('Y' === axis) res.set(zPart, xPart);
    else if ('Z' === axis) res.set(xPart, yPart);
  };

  function hideElement(elem) {
    if (elem.style.display !== 'none')
      elem.style.display = 'none';
  }

  function showElement(elem) {
    if (elem.style.display !== 'block')
      elem.style.display = 'block';
  }

  function activateElementEvent(active) {
    for (let i = 0; i < inputBoxes.length; ++i)
      inputBoxes[i].style.pointerEvents = active ? 'auto' : 'none';
  }

  this.getRelativeTransform = function (transform) {
    return transform.copy(this.object.matrix).premultiply(this.initialMatrix.clone().invert());
  };

  this.getObjectBoundingBoxSize = function (size) {
    let relativeTransform = this.getRelativeTransform(new Matrix4());
    return this.boundingBox.clone().applyMatrix4(relativeTransform).getSize(size);
  };

  this.getObjectUnitAxes = function (x, y, z) {
    x.set(1, 0, 0);
    y.set(0, 1, 0);
    z.set(0, 0, 1);
    if (this.space !== 'world') {

      this.object.matrix.decompose(
        objPosition,
        objQuaternion,
        objScale
      );

      x.applyQuaternion(objQuaternion);
      y.applyQuaternion(objQuaternion);
      z.applyQuaternion(objQuaternion);
    }
  };

  this.getControlGroupMatrix = function () {
    this.object.matrix.decompose(
      objPosition,
      objQuaternion,
      objScale
    );

    return new Matrix4().makeTranslation(-objPosition.x, -objPosition.y, -objPosition.z)
      .premultiply(new Matrix4().makeRotationFromQuaternion(objQuaternion))
      .premultiply(new Matrix4().makeTranslation(objPosition.x, objPosition.y, objPosition.z));
  };

  this.refreshBoundingBoxLines = function () {

    if (DRAG_MODE.SCALE === this.dragMode || !this.isDragging) {

      this.boundingBoxLines.visible = true;
      this.boundingBoxLines.position.copy(objPosition);

      for (let j = 0; j < 2; ++j) {

        let positions = [
          -.5, -.5, .5,
          .5, -.5, .5,
          .5, .5, .5,
          -.5, .5, .5,
          -.5, -.5, .5,
        ];

        for (let i = 0; i < positions.length; i += 3) {

          positions[i] *= objBoundingBoxSize.x;
          positions[i + 1] *= objBoundingBoxSize.y;
          positions[i + 2] = objBoundingBoxSize.z * (0.5 - j);

        }

        this.boundingBoxLines.children[j * 2].geometry.setPositions(positions);
        this.boundingBoxLines.children[j * 2 + 1].material.dashScale = 1 / this.spriteSize;
        this.boundingBoxLines.children[j * 2 + 1].computeLineDistances();

        let newVisible = !this.isDragging;
        newVisible = newVisible || (DRAG_MODE.SCALE === this.dragMode && this.dragAxis.includes(['T', 'B'][j]));
        // newVisible = newVisible && boundingBoxSize.length() > this.spriteSize * 16;

        this.boundingBoxLines.children[j * 2].visible = newVisible;
        this.boundingBoxLines.children[j * 2 + 1].visible = newVisible;

      }

    }

  };

  this.refreshAxisIndicator = function () {

    this.axisIndicator.visible = true;
    this.axisIndicator.position.copy(objPosition);
    this.axisIndicator.scale.copy(objBoundingBoxSize);

    if (!this.isDragging || DRAG_MODE.TRANSLATE === this.dragMode)
      this.axisIndicator.scale.add(new Vector3(5 * this.spriteSize, 5 * this.spriteSize, 8 * this.spriteSize));

    let axisIndicatorArray = this.axisIndicator.children;

    for (let i = axisIndicatorArray.length; i--;) {

      let curIndicator = axisIndicatorArray[i];
      let axis = curIndicator.name;

      let newVisible = !this.isDragging;
      newVisible = newVisible || (DRAG_MODE.TRANSLATE === this.dragMode && TRANSLATE_PICKER_INFO[this.dragAxis].axes.includes(axis));
      newVisible = newVisible || (DRAG_MODE.TRANSLATE_PLANE === this.dragMode && !TRANSLATE_PICKER_INFO[this.dragAxis].axes.includes(axis));
      newVisible = newVisible || (DRAG_MODE.SCALE === this.dragMode && SCALE_PICKER_INFO[this.dragAxis].axes.includes(axis));
      newVisible = newVisible || (DRAG_MODE.ROTATE === this.dragMode && this.dragAxis === axis);
      newVisible = newVisible || (DRAG_MODE.OBJECT === this.dragMode && ['X', 'Y'].includes(axis));

      curIndicator.visible = newVisible;
    }

  };

  this.refreshDimText = function () {

    let unit = this.measureUnitInch ? 'in' : 'mm';
    let unitRatio = this.measureUnitInch ? 25.4 : 1;

    let newX = (objBoundingBoxSize.x / unitRatio).toFixed(2) + unit;
    if (this.dimText.children[0].text !== newX) {
      this.dimText.children[0].text = newX;
      this.dimText.children[0].updateText();
    }

    let newY = (objBoundingBoxSize.y / unitRatio).toFixed(2) + unit;
    if (this.dimText.children[1].text !== newY) {
      this.dimText.children[1].text = newY;
      this.dimText.children[1].updateText();
    }

    let newZ = (objBoundingBoxSize.z / unitRatio).toFixed(2) + unit;
    if (this.dimText.children[2].text !== newZ) {
      this.dimText.children[2].text = newZ;
      this.dimText.children[2].updateText();
    }

    if (DRAG_MODE.ROTATE !== this.dragMode || !this.isDragging) {

      this.dimText.position.copy(objPosition);
      this.dimText.scale.copy(objBoundingBoxSize);
      // this.dimText.visible = true;

      for (let child of this.dimText.children)
        child.scale.set(this.spriteSize / (objBoundingBoxSize.x * 40), this.spriteSize / (objBoundingBoxSize.y * 40), this.spriteSize / (objBoundingBoxSize.z * 40));

    }

  };

  this.refreshZAxisLine = function () {

    if (DRAG_MODE.SCALE === this.dragMode || !this.isDragging) {

      let curLine = this.zAxisLine;
      curLine.position.copy(objPosition);

      let positions = [
        0, 0, -.5,
        0, 0, .5
      ];

      for (let i = 0; i < positions.length; i += 3) {

        positions[i + 2] *= objBoundingBoxSize.z;

      }

      curLine.material.dashScale = 1 / this.spriteSize;
      curLine.geometry.setPositions(positions);
      curLine.computeLineDistances();

      let newVisible = !this.isDragging;
      newVisible = newVisible || (DRAG_MODE.SCALE === this.dragMode && SCALE_PICKER_INFO[this.dragAxis].axes.includes('Z'));

      curLine.visible = newVisible;

    }

  };

  this.refreshTranslateIndicator = function () {

    if ((DRAG_MODE.TRANSLATE === this.dragMode || DRAG_MODE.TRANSLATE_PLANE === this.dragMode || DRAG_MODE.OBJECT === this.dragMode) && (lastEventTick + Config.ShowTime >= curTick || this.isDragging)) {

      this.translateIndicator.visible = true;

      let translatorIndicatorArray = this.translateIndicator.children;
      let units = [objectUnitX, objectUnitY, objectUnitZ];
      let unitRatio = this.measureUnitInch ? 25.4 : 1;

      for (let i = 0; i < translatorIndicatorArray.length; ++i) {

        let curIndicator = translatorIndicatorArray[i];

        let lineLen = 0, dir = 1;

        let offset = objPosition.clone().addScaledVector(objectPosition, -1);
        let unit;

        unit = units[i];

        if (DRAG_MODE.TRANSLATE === this.dragMode) {
          if ((('L' === this.dragAxis || 'R' === this.dragAxis) && i === 0) || (('U' === this.dragAxis || 'D' === this.dragAxis) && i === 1) || (('T' === this.dragAxis || 'B' === this.dragAxis) && i === 2)) {
            showElement(inputBoxes[i]);
            curIndicator.visible = true;
          } else {
            curIndicator.visible = false;
          }
        } else if (DRAG_MODE.TRANSLATE_PLANE === this.dragMode) {
          if ((('L' === this.dragAxis || 'R' === this.dragAxis) && i !== 0) || (('U' === this.dragAxis || 'D' === this.dragAxis) && i !== 1) || (('T' === this.dragAxis || 'B' === this.dragAxis) && i !== 2)) {
            showElement(inputBoxes[i]);
            curIndicator.visible = true;
          } else {
            curIndicator.visible = false;
          }
        } else if (DRAG_MODE.OBJECT === this.dragMode) {
          if (i === 0 || i === 1) {
            showElement(inputBoxes[i]);
            curIndicator.visible = true;
          } else {
            curIndicator.visible = false;
          }
        }

        lineLen = offset.dot(unit);
        if (lineLen < 0) {
          dir = -1;
          lineLen = -lineLen;
        }

        let rad = 2 * this.spriteSize;
        let triRad = 0.7 * this.spriteSize;

        let mainLinePos = curIndicator.getObjectByName('MAIN_LINE').geometry.attributes.position;
        mainLinePos.array[0] = 0;
        mainLinePos.array[1] = lineLen / 2;
        mainLinePos.array[2] = 0;
        mainLinePos.array[3] = 0;
        mainLinePos.array[4] = -lineLen / 2;
        mainLinePos.array[5] = 0;
        mainLinePos.needsUpdate = true;

        let subLine1Pos = curIndicator.getObjectByName('SUB_LINE_1').geometry.attributes.position;
        subLine1Pos.array[0] = -rad;
        subLine1Pos.array[1] = lineLen / 2;
        subLine1Pos.array[2] = 0;
        subLine1Pos.array[3] = rad;
        subLine1Pos.array[4] = lineLen / 2;
        subLine1Pos.array[5] = 0;
        subLine1Pos.needsUpdate = true;

        let subLine2Pos = curIndicator.getObjectByName('SUB_LINE_2').geometry.attributes.position;
        subLine2Pos.array[0] = -rad;
        subLine2Pos.array[1] = -lineLen / 2;
        subLine2Pos.array[2] = 0;
        subLine2Pos.array[3] = rad;
        subLine2Pos.array[4] = -lineLen / 2;
        subLine2Pos.array[5] = 0;
        subLine2Pos.needsUpdate = true;

        let triObj = curIndicator.getObjectByName('TRIANGLE');
        triObj.position.set(0, dir * (lineLen / 2 - triRad), 0);
        triObj.scale.set(triRad, triRad, triRad);
        if (-1 === dir) triObj.rotation.set(0, 0, PI);
        else triObj.rotation.set(0, 0, 0);

        if (i === 0) {

          curIndicator.rotation.set(0, 0, -PI2);
          curIndicator.position.set(
            objPosition.x - dir * lineLen / 2,
            objPosition.y - objBoundingBoxSize.y / 2 - rad,
            objPosition.z - objBoundingBoxSize.z / 2
          );

        } else if (i === 1) {

          curIndicator.rotation.set(0, 0, 0);
          curIndicator.position.set(
            objPosition.x + objBoundingBoxSize.x / 2 + rad,
            objPosition.y - dir * lineLen / 2,
            objPosition.z - objBoundingBoxSize.z / 2
          );

        } else if (i === 2) {

          curIndicator.rotation.set(PI2, 0, 0);
          curIndicator.position.set(
            objPosition.x + objBoundingBoxSize.x / 2 + rad,
            objPosition.y - objBoundingBoxSize.y / 2,
            objPosition.z - dir * lineLen / 2
          );

        }

        moveElement(inputBoxes[i], curIndicator);
        // if (lastEventTick + Config.ShowTime < curTick)
        if (this.focusedInputId < 0) {
          inputBoxes[i].value = (lineLen * dir / unitRatio).toFixed(2);
        }

      }

    }

  };

  this.refreshTranslatePicker = function () {

    if (DRAG_MODE.TRANSLATE === this.dragMode || !this.isDragging) {

      this.translatePicker.visible = true;

      let translatePickerArray = this.translatePicker.children;

      for (let i = translatePickerArray.length; i--;) {

        let curPicker = translatePickerArray[i];
        let axis = curPicker.name;

        let curInfo = TRANSLATE_PICKER_INFO[axis];

        if (translateHoverPickerName === axis)
          curPicker.children[1].material = this.materials.translate.hover;
        else
          curPicker.children[1].material = this.materials.translate.normal;

        curPicker.position.set(
          objPosition.x + curInfo.position.x * (objBoundingBoxSize.x + (('L' === axis || 'R' === axis) ? (7 * this.spriteSize) : 0)),
          objPosition.y + curInfo.position.y * (objBoundingBoxSize.y + (('U' === axis || 'D' === axis) ? (7 * this.spriteSize) : 0)),
          objPosition.z + curInfo.position.z * (objBoundingBoxSize.z + (('T' === axis || 'B' === axis) ? (10 * this.spriteSize) : 0))
        );

        curPicker.quaternion.setFromUnitVectors(unitY, curInfo.offset);
        curPicker.scale.set(this.spriteSize, this.spriteSize, this.spriteSize);

        let length = curInfo.offset.clone().applyQuaternion(objQuaternion).cross(this.eye).length();

        let newVisible = !this.isDragging;
        newVisible = newVisible || (DRAG_MODE.TRANSLATE === this.dragMode && this.dragAxis === axis);
        newVisible = newVisible && (length > Math.sin(PI / 18));

        updateObjectLayers(curPicker, newVisible);

      }

    }

  };

  this.refreshTranslateCircle = function () {

    if (DRAG_MODE.TRANSLATE === this.dragMode || !this.isDragging) {

      this.translateCircle.visible = true;
      let translateCircleArray = this.translateCircle.children;

      for (let i = translateCircleArray.length; i--;) {

        let curCircle = translateCircleArray[i];
        let axis = curCircle.name;

        let curInfo = TRANSLATE_PICKER_INFO[axis];

        if (translateHoverPickerName === axis)
          curCircle.material = this.materials.translatePlane.hover[i];
        else
          curCircle.material = this.materials.translatePlane.normal[i];

        curCircle.position.set(
          objPosition.x + curInfo.position.x * (objBoundingBoxSize.x + (('L' === axis || 'R' === axis) ? (4.5 * this.spriteSize) : 0)),
          objPosition.y + curInfo.position.y * (objBoundingBoxSize.y + (('U' === axis || 'D' === axis) ? (4.5 * this.spriteSize) : 0)),
          objPosition.z + curInfo.position.z * (objBoundingBoxSize.z + (('T' === axis || 'B' === axis) ? (7.5 * this.spriteSize) : 0))
        );

        curCircle.quaternion.setFromUnitVectors(unitY, curInfo.offset);
        curCircle.scale.set(this.spriteSize, this.spriteSize, this.spriteSize);

        let length = curInfo.offset.clone().applyQuaternion(objQuaternion).cross(this.eye).length();

        let newVisible = !this.isDragging;
        newVisible = newVisible || (DRAG_MODE.TRANSLATE === this.dragMode && this.dragAxis === axis);
        newVisible = newVisible && (length > Math.sin(PI / 18));

        updateObjectLayers(curCircle, newVisible);

      }

    }

  };

  this.refreshTranslatePlanePicker = function () {

    if (DRAG_MODE.TRANSLATE_PLANE === this.dragMode || !this.isDragging) {

      this.translatePlanePicker.visible = true;
      let translatePlanePickerArray = this.translatePlanePicker.children;

      for (let i = translatePlanePickerArray.length; i--;) {

        let curPicker = translatePlanePickerArray[i];
        let axis = curPicker.name;

        let curInfo = TRANSLATE_PICKER_INFO[axis];

        if (translatePlaneHoverPickerName === axis)
          curPicker.material = this.materials.translatePlane.hover[i];
        else
          curPicker.material = this.materials.translatePlane.normal[i];

        curPicker.position.set(
          objPosition.x + curInfo.planePosition.x * (('L' === axis || 'R' === axis) ? 0 : (4 * this.spriteSize)),
          objPosition.y + curInfo.planePosition.y * (('U' === axis || 'D' === axis) ? 0 : (4 * this.spriteSize)),
          objPosition.z + curInfo.planePosition.z * (('T' === axis || 'B' === axis) ? 0 : (4 * this.spriteSize))
        );

        curPicker.quaternion.setFromUnitVectors(unitY, curInfo.offset);
        curPicker.scale.set(this.spriteSize, this.spriteSize, this.spriteSize);

        let length = curInfo.offset.clone().applyQuaternion(objQuaternion).cross(this.eye).length();

        let newVisible = !this.isDragging;
        newVisible = newVisible || (DRAG_MODE.TRANSLATE_PLANE === this.dragMode && this.dragAxis === axis);
        newVisible = newVisible && (length < Math.cos(PI / 18));
        newVisible = newVisible && objBoundingBoxSize.length() > this.spriteSize * 16;

        updateObjectLayers(curPicker, newVisible);

      }

    }

  };

  this.refreshScalePicker = function () {

    if (DRAG_MODE.SCALE === this.dragMode || !this.isDragging) {

      this.scalePicker.visible = true;
      let scalePikerArray = this.scalePicker.children;

      for (let i = scalePikerArray.length; i--;) {

        let curPicker = scalePikerArray[i];
        let axis = curPicker.name;

        let curInfo = SCALE_PICKER_INFO[axis];

        if (curInfo.type === 'corner') {
          curPicker.material = axis === scaleHoverPickerName ? this.materials.scale.corner.hover : this.materials.scale.corner.normal;
          curPicker.scale.set(this.spriteSize, this.spriteSize, this.spriteSize);
        } else {
          curPicker.children[0].material = axis === scaleHoverPickerName ? this.materials.scale.edge.hover : this.materials.scale.edge.normal;
          curPicker.quaternion.setFromUnitVectors(unitY, curInfo.offset);
          curPicker.scale.set(this.spriteSize / 8, this.spriteSize / 8, this.spriteSize / 8);
        }

        curPicker.position.copy(objPosition).add(curInfo.position.clone().multiply(objBoundingBoxSize));

        let length = curInfo.offset.clone().applyQuaternion(objQuaternion).cross(this.eye).length();

        let hasDimension = true;
        for (let axis of curInfo.axes) {
          if (Math.abs(objBoundingBoxSize.getComponent(AXIS_TO_INDEX[axis])) < EPSILON)
            hasDimension = false;
        }

        let newVisible = !this.isDragging;
        newVisible = newVisible || (DRAG_MODE.SCALE === this.dragMode && this.dragAxis === axis);
        newVisible = newVisible && hasDimension;
        newVisible = newVisible && (length > Math.sin(PI / 18));
        newVisible = newVisible && objBoundingBoxSize.length() > this.spriteSize * 5;

        updateObjectLayers(curPicker, newVisible);

      }

    }

  };

  this.refreshScaleIndicator = function () {

    if (scaleHoverState || (DRAG_MODE.SCALE === this.dragMode && (lastEventTick + Config.ShowTime >= curTick || this.isDragging))) {

      this.scaleIndicator.visible = true;
      let rad = 4 * this.spriteSize;
      let triRad = this.spriteSize * 0.7;

      for (let i = 0; i < 3; ++i) {

        let indicator = this.scaleIndicator.children[i];
        let xLen = objBoundingBoxSize.getComponent(i);
        let unitRatio = this.measureUnitInch ? 25.4 : 1;

        if ((scaleHoverState & (1 << i)) || (DRAG_MODE.SCALE === this.dragMode && (lastEventTick + Config.ShowTime >= curTick || this.isDragging) && ((SCALE_INDICATOR_STATE.SHIFT & scaleIndicatorState) || (scaleIndicatorState & (1 << i))))) {

          indicator.visible = true;
          let mainLinePos = indicator.getObjectByName('MAIN_LINE').geometry.attributes.position;
          mainLinePos.array[0] = 0;
          mainLinePos.array[1] = -xLen / 2;
          mainLinePos.array[2] = 0;
          mainLinePos.array[3] = 0;
          mainLinePos.array[4] = xLen / 2;
          mainLinePos.array[5] = 0;
          mainLinePos.needsUpdate = true;

          let subLine1Pos = indicator.getObjectByName('SUB_LINE_1').geometry.attributes.position;
          subLine1Pos.array[0] = -rad;
          subLine1Pos.array[1] = xLen / 2;
          subLine1Pos.array[2] = 0;
          subLine1Pos.array[3] = rad;
          subLine1Pos.array[4] = xLen / 2;
          subLine1Pos.array[5] = 0;
          subLine1Pos.needsUpdate = true;

          let subLine2Pos = indicator.getObjectByName('SUB_LINE_2').geometry.attributes.position;
          subLine2Pos.array[0] = -rad;
          subLine2Pos.array[1] = -xLen / 2;
          subLine2Pos.array[2] = 0;
          subLine2Pos.array[3] = rad;
          subLine2Pos.array[4] = -xLen / 2;
          subLine2Pos.array[5] = 0;
          subLine2Pos.needsUpdate = true;

          let objTri1 = indicator.getObjectByName('TRIANGLE_1');
          objTri1.position.set(0, xLen / 2 - triRad, 0);
          objTri1.rotation.set(0, 0, 0);
          objTri1.scale.set(triRad, triRad, triRad);

          let objTri2 = indicator.getObjectByName('TRIANGLE_2');
          objTri2.position.set(0, -xLen / 2 + triRad, 0);
          objTri2.rotation.set(0, 0, PI);
          objTri2.scale.set(triRad, triRad, triRad);

          indicator.rotation.set(SCALE_IND_ROT_INFO[i][0], SCALE_IND_ROT_INFO[i][1], SCALE_IND_ROT_INFO[i][2]);

          if ('X' === indicator.name) {

            indicator.position.copy(objPosition);
            if (scaleHoverPickerName && scaleHoverPickerName.includes('D'))
              indicator.position.y -= objBoundingBoxSize.y / 2 + rad;
            else
              indicator.position.y += objBoundingBoxSize.y / 2 + rad;

            if (scaleHoverPickerName && scaleHoverPickerName.includes('B'))
              indicator.position.z -= objBoundingBoxSize.z / 2;
            else
              indicator.position.z += objBoundingBoxSize.z / 2;

          } else if ('Y' === indicator.name) {

            indicator.position.copy(objPosition);
            if (scaleHoverPickerName && scaleHoverPickerName.includes('L'))
              indicator.position.x -= objBoundingBoxSize.x / 2 + rad;
            else
              indicator.position.x += objBoundingBoxSize.x / 2 + rad;

            if (scaleHoverPickerName && scaleHoverPickerName.includes('B'))
              indicator.position.z -= objBoundingBoxSize.z / 2;
            else
              indicator.position.z += objBoundingBoxSize.z / 2;

          } else if ('Z' === indicator.name) {

            let offset = new Vector2(this.eye.y, -this.eye.x).normalize();
            indicator.applyQuaternion(new Quaternion().setFromUnitVectors(
              new Vector3(1, 0, 0),
              new Vector3(offset.x, offset.y, 0)
            ));
            indicator.position.set(objPosition.x + offset.x * rad, objPosition.y + offset.y * rad, objPosition.z);

          }

          if ('X' === indicator.name || 'Y' === indicator.name)
            moveElement(inputBoxes[i], indicator, (scaleHoverPickerName && scaleHoverPickerName.includes('B')) ? ['B'] : ['T']);
          else if ('Z' === indicator.name)
            moveElement(inputBoxes[i], indicator, ['B', 'T']);

          // if (scaleHoverState || lastEventTick + Config.ShowTime < curTick)
          if (this.focusedInputId < 0) {
            inputBoxes[i].value = (xLen / unitRatio).toFixed(2);
          }

          showElement(inputBoxes[i]);

        } else {

          indicator.visible = false;

        }

      }

    }

  };

  this.refreshRotateRuler = function () {

    let rotateRulerArray;

    if (this.shiftKeyState) {
      this.rotateShiftRuler.visible = true;
      rotateRulerArray = this.rotateShiftRuler.children;
    } else {
      this.rotateRuler.visible = true;
      rotateRulerArray = this.rotateRuler.children;
    }

    for (let i = rotateRulerArray.length; i--;) {
      let curRotater = rotateRulerArray[i];
      let curInfo = ROTATE_PICKER_INFO[curRotater.name];

      // TODO: BYZ - check if this is right.
      if ((lastEventTick + Config.ShowTime >= curTick && DRAG_MODE.ROTATE === this.dragMode && curRotater.name === this.showRotateRulerAxis) || (this.isShowRotateRuler && curRotater.name === this.showRotateRulerAxis)) {
        curRotater.visible = true;
      } else {
        curRotater.visible = false;
        continue;
      }

      let inSelRing = curRotater.getObjectByName('RING_SEL');
      let inNonSelRing = curRotater.getObjectByName('RING_NON_SEL');
      let outSelRing = curRotater.getObjectByName('OUTRING_SEL');
      let outNonSelRing = curRotater.getObjectByName('OUTRING_NON_SEL');

      let startAngle1, angleWidth1, startAngle2, angleWidth2;
      let curAngle = rotateAngle;

      if (this.isInner) {
        curAngle = rotateInnerAngle;
        if (Math.abs(PI - curAngle) < 1e-6 && rotateAngle < 0.0) curAngle = -PI;
        if (Math.abs(-PI - curAngle) < 1e-6 && rotateAngle > 0.0) curAngle = PI;
      }

      if (curAngle >= 0.0) {

        startAngle1 = 0;
        angleWidth1 = curAngle;
        startAngle2 = curAngle;
        angleWidth2 = PI * 2 - curAngle;

        if (this.space !== 'world') {
          startAngle1 -= angleWidth1;
          startAngle2 += angleWidth2;
        }

      } else {

        startAngle1 = 2 * PI + curAngle;
        angleWidth1 = -curAngle;
        startAngle2 = 0;
        angleWidth2 = 2 * PI + curAngle;

        if (this.space !== 'world') {
          startAngle1 += angleWidth1;
          startAngle2 -= angleWidth2;
        }
      }

      let rad = rotateScale / 2;

      if (this.shiftKeyState) {

        inSelRing.geometry.dispose();
        inSelRing.geometry = new RingBufferGeometry(
          rad + this.spriteSize * RING_INFO.SPACE,
          rad + this.spriteSize * RING_INFO.SPACE + this.spriteSize * RING_INFO.LINE_BG,
          RING_INFO.SEG,
          1,
          startAngle1,
          angleWidth1
        );

        inNonSelRing.geometry.dispose();
        inNonSelRing.geometry = new RingBufferGeometry(
          rad + this.spriteSize * RING_INFO.SPACE,
          rad + this.spriteSize * RING_INFO.SPACE + this.spriteSize * RING_INFO.LINE_BG,
          RING_INFO.SEG,
          1,
          startAngle2,
          angleWidth2
        );

      } else {

        inSelRing.geometry.dispose();
        inSelRing.geometry = new RingBufferGeometry(
          rad - this.spriteSize * RING_INFO.SPACE - this.spriteSize * RING_INFO.WIDTH,
          rad - this.spriteSize * RING_INFO.SPACE,
          RING_INFO.SEG,
          1,
          this.isInner ? startAngle1 : 0,
          this.isInner ? angleWidth1 : 0
        );

        inNonSelRing.geometry.dispose();
        inNonSelRing.geometry = new RingBufferGeometry(
          rad - this.spriteSize * RING_INFO.SPACE - this.spriteSize * RING_INFO.WIDTH,
          rad - this.spriteSize * RING_INFO.SPACE,
          RING_INFO.SEG,
          1,
          this.isInner ? startAngle2 : 0,
          this.isInner ? angleWidth2 : (PI * 2)
        );

        outSelRing.geometry.dispose();
        outSelRing.geometry = new RingBufferGeometry(
          rad + this.spriteSize * RING_INFO.SPACE,
          rad + this.spriteSize * RING_INFO.SPACE + this.spriteSize * RING_INFO.WIDTH,
          RING_INFO.SEG,
          1,
          this.isInner ? 0 : startAngle1,
          this.isInner ? 0 : angleWidth1
        );

        outNonSelRing.geometry.dispose();
        outNonSelRing.geometry = new RingBufferGeometry(
          rad + this.spriteSize * RING_INFO.SPACE,
          rad + this.spriteSize * RING_INFO.SPACE + this.spriteSize * RING_INFO.WIDTH,
          RING_INFO.SEG,
          1,
          this.isInner ? 0 : startAngle2,
          this.isInner ? (PI * 2) : angleWidth2);

      }


      let outLinePosition = curRotater.getObjectByName('OUT_LINES').geometry.attributes.position;
      let outArray = outLinePosition.array;
      let outLineCount;

      if (this.shiftKeyState) {
        outLineCount = RING_INFO.SHIFT_LINE_COUNT;
      } else {
        outLineCount = RING_INFO.OUT_LINE_COUNT;
      }

      for (let j = 0; j < outLineCount; ++j) {
        let angle = j * 2 * PI / outLineCount;
        let inR = rad + this.spriteSize * RING_INFO.SPACE;
        let outR;
        if (this.shiftKeyState) {
          outR = rad + this.spriteSize * RING_INFO.SPACE + this.spriteSize * RING_INFO.LINE_BG;
        } else {
          outR = rad + this.spriteSize * RING_INFO.SPACE + this.spriteSize * (j % 9 ? RING_INFO.LINE_SM : RING_INFO.LINE_BG);
        }

        outArray[j * 6] = inR * Math.cos(angle);
        outArray[j * 6 + 1] = inR * Math.sin(angle);
        outArray[j * 6 + 2] = 0;

        outArray[j * 6 + 3] = outR * Math.cos(angle);
        outArray[j * 6 + 4] = outR * Math.sin(angle);
        outArray[j * 6 + 5] = 0;
      }

      outLinePosition.needsUpdate = true;

      if (!this.shiftKeyState) {
        let inLinePosition = curRotater.getObjectByName('IN_LINES').geometry.attributes.position;
        let inArray = inLinePosition.array;

        for (let j = 0; j < RING_INFO.IN_LINE_COUNT; ++j) {
          let angle = j * 2 * PI / RING_INFO.IN_LINE_COUNT;
          let inR = rad - this.spriteSize * RING_INFO.SPACE - this.spriteSize * RING_INFO.LINE_BG;
          let outR = rad - this.spriteSize * RING_INFO.SPACE;

          inArray[j * 6] = inR * Math.cos(angle);
          inArray[j * 6 + 1] = inR * Math.sin(angle);
          inArray[j * 6 + 2] = 0;

          inArray[j * 6 + 3] = outR * Math.cos(angle);
          inArray[j * 6 + 4] = outR * Math.sin(angle);
          inArray[j * 6 + 5] = 0;
        }

        inLinePosition.needsUpdate = true;
      }

      curRotater.position.copy(objPosition.clone()).add(curInfo.rulerPosition.clone().multiply(objBoundingBoxSize));
      curRotater.rotation.copy(curInfo.rulerRotation);
    }

  };

  this.refreshRotatePicker = function () {

    if (!this.isDragging || DRAG_MODE.ROTATE === this.dragMode) {

      this.rotatePicker.visible = true;
      let rotatePickerArray = this.rotatePicker.children;

      for (let i = rotatePickerArray.length; i--;) {

        let curPicker = rotatePickerArray[i];
        let axis = curPicker.name;
        let curInfo = ROTATE_PICKER_INFO[axis];

        if (rotateHoverPickerName === axis)
          curPicker.children[0].material = this.materials.rotate.hover;
        else
          curPicker.children[0].material = this.materials.rotate.normal;

        tempVector.set(0, 0, 0);

        if ('X' === axis) tempVector.z = 2.5 * this.spriteSize;
        else if ('Y' === axis) tempVector.z = 2.5 * this.spriteSize;
        else if ('Z' === axis) tempVector.x -= 2.5 * this.spriteSize;

        let box = new Vector3();
        box.copy(objectBoundingBoxSize);

        if (!this.isDragging) box.copy(objBoundingBoxSize);

        curPicker.rotation.copy(curInfo.rotation);
        curPicker.scale.set(this.spriteSize / 10, this.spriteSize / 10, this.spriteSize / 10);
        curPicker.position.copy(objPosition).add(tempVector).add(curInfo.position.clone().multiply(box));

        let length = curInfo.offset.clone().applyQuaternion(objQuaternion).cross(this.eye).length();

        let newVisible = !this.isDragging;
        newVisible = newVisible || (DRAG_MODE.ROTATE === this.dragMode && this.dragAxis === axis);
        newVisible = newVisible && (length < Math.cos(PI / 18));

        updateObjectLayers(curPicker, newVisible);

      }

    }

  };

  this.refreshRotateIndicator = function () {

    if (DRAG_MODE.ROTATE === this.dragMode && (lastEventTick + Config.ShowTime >= curTick || this.isDragging)) {

      this.rotateIndicator.visible = true;
      let rotateIndicator = this.rotateIndicator.getObjectByName('MAIN_LINE');
      let position = rotateIndicator.geometry.attributes.position.array;
      position[0] = this.intersectPlane.position.x;
      position[1] = this.intersectPlane.position.y;
      position[2] = this.intersectPlane.position.z;

      if ((lastEventTick + Config.ShowTime >= curTick) || this.isInner) {
        let radius = rotateScale;

        if (this.shiftKeyState) {
          let r1 = this.intersectPlane.position.distanceTo(rotateCursorPos) * 2;
          if (r1 > radius) radius = r1;
        }

        let curAngle = rotateInnerAngle;
        let xx = radius * ('X' === this.dragAxis ? -Math.sin(curAngle) : Math.cos(curAngle)) / 2;
        let yy = radius * ('X' === this.dragAxis ? Math.cos(curAngle) : Math.sin(curAngle)) / 2;

        tempVector.copy(this.intersectPlane.position);

        if ('X' === this.dragAxis) {
          tempVector.addScaledVector(objectUnitY, xx).addScaledVector(objectUnitZ, yy);
        } else if ('Y' === this.dragAxis) {
          tempVector.addScaledVector(objectUnitZ, xx).addScaledVector(objectUnitX, yy);
        } else if ('Z' === this.dragAxis) {
          tempVector.addScaledVector(objectUnitX, -xx).addScaledVector(objectUnitY, -yy);
        }

        position[3] = tempVector.x;
        position[4] = tempVector.y;
        position[5] = tempVector.z;

      } else {
        position[3] = rotateCursorPos.x;
        position[4] = rotateCursorPos.y;
        position[5] = rotateCursorPos.z;
      }

      rotateIndicator.geometry.attributes.position.needsUpdate = true;

      let curAngle = rotateAngle;
      if (this.isInner) {
        curAngle = rotateInnerAngle;
        if (Math.abs(PI - curAngle) < 1e-6 && rotateAngle < 0.0) curAngle = -PI;
        if (Math.abs(-PI - curAngle) < 1e-6 && rotateAngle > 0.0) curAngle = PI;
      }

      moveElement(inputBoxes[0], rotateIndicator);

      // if (lastEventTick + Config.ShowTime < curTick)
      if (this.focusedInputId < 0) {
        inputBoxes[0].value = (curAngle * 180 / PI).toFixed(0);
      }

      showElement(inputBoxes[0]);

    }

  };

  this.refresh = function () {

    let newState = {};

    newState.enabled = this.enabled;
    this.boundingBoxLines.visible = false;
    this.axisIndicator.visible = false;
    this.dimText.visible = false;
    this.zAxisLine.visible = false;
    this.scalePicker.visible = false;
    this.scaleIndicator.visible = false;
    this.translatePicker.visible = false;
    this.translateCircle.visible = false;
    this.translatePlanePicker.visible = false;
    this.translateIndicator.visible = false;
    this.rotatePicker.visible = false;
    this.rotateIndicator.visible = false;
    this.rotateRuler.visible = false;
    this.rotateShiftRuler.visible = false;

    for (let picker of [...this.translatePicker.children, ...this.translateCircle.children, ...this.translatePlanePicker.children, ...this.scalePicker.children, ...this.rotatePicker.children])
      updateObjectLayers(picker, false);

    for (let i = 0; i < inputBoxes.length; ++i) {
      hideElement(inputBoxes[i]);
    }

    if (this.enabled && this.object !== undefined) {

      newState.space = this.space;
      newState.objectId = this.object.id;

      if (this.space === 'world') {

        this.controlGroup.matrix = new Matrix4();

      } else {

        this.controlGroup.matrix = this.getControlGroupMatrix();

      }

      newState.visible = this.visible;

      if (this.visible) {

        objBoundingBoxSize = new Vector3();
        this.getObjectBoundingBoxSize(objBoundingBoxSize);

        if (objBoundingBoxSize.x > 0 || objBoundingBoxSize.y > 0 || objBoundingBoxSize.z > 0) {

          this.eye = this.camera.getWorldDirection(new Vector3());

          this.object.matrix.decompose(
            objPosition,
            objQuaternion,
            objScale
          );

          newState.eye = this.eye.toArray();
          newState.position = objPosition.toArray();
          newState.quaternion = objQuaternion.toArray();
          newState.scale = objScale.toArray();
          newState.dragMode = this.dragMode;
          newState.isDragging = this.isDragging;
          newState.measureUnitInch = this.measureUnitInch;
          newState.scaleHoverState = scaleHoverState;
          newState.scaleHoverPickerName = scaleHoverPickerName;
          newState.scaleIndicatorState = scaleIndicatorState;
          newState.translateHoverPickerName = translateHoverPickerName;
          newState.translatePlaneHoverPickerName = translatePlaneHoverPickerName;
          newState.rotateHoverPickerName = rotateHoverPickerName;
          newState.exceedTick = (lastEventTick + Config.ShowTime >= curTick);
          newState.spriteSize = this.spriteSize;
          newState.shiftKeyState = this.shiftKeyState;
          newState.altKeyState = this.altKeyState;

          this.refreshBoundingBoxLines();
          this.refreshAxisIndicator();
          this.refreshZAxisLine();

          if (this.controlEnabled) {

            this.refreshDimText();
            this.refreshTranslatePicker();
            this.refreshTranslateCircle();
            this.refreshTranslatePlanePicker();
            this.refreshTranslateIndicator();
            this.refreshScalePicker();
            this.refreshScaleIndicator();
            this.refreshRotateRuler();
            this.refreshRotatePicker();
            this.refreshRotateIndicator();

          }

        }

      }

    }

    // Object3D.prototype.updateMatrixWorld.call(this)
    let hadUpdate = !lod.isEqual(newState, this.oldState);
    this.oldState = newState;
    return hadUpdate;

  };

  function getPointer(event) {
    if (!scope.elemRect)
      scope.refreshBoundingClientRect();

    let pointer = event.changedTouches ? event.changedTouches[0] : event;

    return {
      x: (pointer.clientX - scope.elemRect.left) / scope.elemRect.width * 2 - 1,
      y: -(pointer.clientY - scope.elemRect.top) / scope.elemRect.height * 2 + 1,
      button: event.button,
      type: event.changedTouches ? 'touch' : 'mouse'
    };
  }

  this.setSnapInfo = function (units, snapInfo, local) {

    if (!snapInfo) {
      this.scene.remove(this.snapPlaneGroup);
      this.scene.remove(this.snapPointGroup);
      this.snapPlaneGroup = new Group();
      this.snapPointGroup = new Group();
      this.snapMatrix = new Matrix4();

      return;
    }

    this.snapMatrix.copy(snapInfo.snapMatrix);

    let curPlaneCnt = this.snapPlaneGroup.children.length;
    if (snapInfo.planes.length > curPlaneCnt) {
      for (let i = 0; i < snapInfo.planes.length - curPlaneCnt; ++i) {
        this.snapPlaneGroup.add(new Mesh(this.snapPlaneGeometry));
      }
    }

    if (snapInfo.planes.length < curPlaneCnt) {
      for (let i = 0; i < curPlaneCnt - snapInfo.planes.length; ++i) {
        this.snapPlaneGroup.remove(this.snapPlaneGroup.children[this.snapPlaneGroup.children.length - 1]);
      }
    }

    for (let i = 0; i < snapInfo.planes.length; ++i) {
      let plane = snapInfo.planes[i];
      let min = new Vector3(+Infinity, +Infinity, +Infinity), max = new Vector3(-Infinity, -Infinity, -Infinity);
      for (let point of plane.points) {
        min.x = Math.min(min.x, point.pos.x);
        min.y = Math.min(min.y, point.pos.y);
        min.z = Math.min(min.z, point.pos.z);
        max.x = Math.max(max.x, point.pos.x);
        max.y = Math.max(max.y, point.pos.y);
        max.z = Math.max(max.z, point.pos.z);
      }

      let center = min.clone().add(max).multiplyScalar(0.5);

      let size = max.clone().sub(min);
      let minInd = size.x > size.y ? (size.z > size.y ? 1 : 2) : (size.z > size.x ? 0 : 2);
      let scale = [100, 100];
      let material;
      if (minInd === 0) {
        scale = [size.z, size.y];
        material = this.snapPlaneMaterials[0];
      } else if (minInd === 1) {
        scale = [size.x, size.z];
        material = this.snapPlaneMaterials[1];
      } else if (minInd === 2) {
        scale = [size.x, size.y];
        material = this.snapPlaneMaterials[2];
      }

      this.snapPlaneGroup.children[i].material = material;
      this.snapPlaneGroup.children[i].quaternion.setFromUnitVectors(unitZ, plane.plane.normal);
      this.snapPlaneGroup.children[i].position.copy(center);
      this.snapPlaneGroup.children[i].scale.set(scale[0], scale[1], 1);
    }

    let ptDescSet = new Set();

    for (let i = 0; i < snapInfo.planes.length; ++i) {
      let plane = snapInfo.planes[i];
      for (let point of plane.points) {
        let ptDesc = `${point.pos.x} ${point.pos.y} ${point.pos.z}`;
        ptDescSet.add(ptDesc);
      }
    }

    let curPointCnt = this.snapPointGroup.children.length;
    if (ptDescSet.size > curPointCnt) {
      for (let i = 0; i < ptDescSet.size - curPointCnt; ++i) {
        this.snapPointGroup.add(new Mesh(this.snapPointGeometry, this.snapPointMaterial));
      }
    }

    if (ptDescSet.size < curPointCnt) {
      for (let i = 0; i < curPointCnt - ptDescSet.size; ++i) {
        this.snapPointGroup.remove(this.snapPointGroup.children[this.snapPointGroup.children.length - 1]);
      }
    }

    let ptDescs = Array.from(ptDescSet);

    for (let i = 0; i < ptDescs.length; ++i) {
      let coords = ptDescs[i].split(' ').map(Number);
      let pos = new Vector3(coords[0], coords[1], coords[2]);
      this.snapPointGroup.children[i].position.copy(pos);
      this.snapPointGroup.children[i].scale.set(this.spriteSize, this.spriteSize, this.spriteSize);
    }

    this.scene.add(this.snapPlaneGroup);
    this.scene.add(this.snapPointGroup);
    this.object.matrix.premultiply(this.snapMatrix);

  };

  this.pointerDown = function (event, pointer) {
    lastEventTick = -Config.ShowTime - 1;
    if (!this.object) return;
    if (pointer.type === 'mouse' && pointer.button !== 0) return;
    if (!this.enabled || !this.controlEnabled) return;

    this.shiftKeyState = event.shiftKey;
    this.altKeyState = event.altKey;

    this.rayCaster.setFromCamera(pointer, this.camera);

    this.eye = this.camera.getWorldDirection(new Vector3());
    this.getObjectUnitAxes(objUnitX, objUnitY, objUnitZ);
    let orientVectorZ = objUnitZ.clone().cross(this.eye).length();

    this.object.matrix.decompose(
      objPosition,
      objQuaternion,
      objScale
    );

    let intersect = this.rayCaster.intersectObjects([this.translatePlanePicker, this.rotatePicker, this.scalePicker, this.translatePicker, this.translateCircle], true)[0] || false;

    if (intersect) {

      if (isAncestor(intersect.object, this.translatePlanePicker)) {

        this.dragAxis = intersect.object.name;

        if ('L' === intersect.object.name || 'R' === intersect.object.name) this.setIntersectPlaneFromNormal(objUnitX);
        else if ('U' === intersect.object.name || 'D' === intersect.object.name) this.setIntersectPlaneFromNormal(objUnitY);
        else if ('T' === intersect.object.name || 'B' === intersect.object.name) this.setIntersectPlaneFromNormal(objUnitZ);

        intersect = this.rayCaster.intersectObject(this.intersectPlane, true)[0] || false;

        if (intersect) {

          objectMatrix.copy(this.object.matrix);
          this.object.matrix.decompose(
            objectPosition,
            objectQuaternion,
            objectScale
          );
          this.getObjectBoundingBoxSize(objectBoundingBoxSize);
          this.getObjectUnitAxes(objectUnitX, objectUnitY, objectUnitZ);

          this.dragMode = DRAG_MODE.TRANSLATE_PLANE;
          this.dragStartPosition = intersect.point;
          this.isDragging = true;
          event.preventDefault();

          activateElementEvent(false);
          return;

        }

      } else if (isAncestor(intersect.object, this.rotatePicker)) {

        this.dragAxis = intersect.object.name;
        let curInfo = ROTATE_PICKER_INFO[this.dragAxis];

        let boundingBoxSize = new Vector3();
        this.getObjectBoundingBoxSize(boundingBoxSize);
        tempVector.copy(objPosition);
        if ('X' === this.dragAxis) {
          if (this.space === 'world') {
            this.intersectPlane.rotation.set(0, PI2, 0);
          } else {
            this.intersectPlane.quaternion.copy(objQuaternion.clone().premultiply(new Quaternion().setFromAxisAngle(objUnitY, PI2)));
          }
        } else if ('Y' === this.dragAxis) {
          if (this.space === 'world') {
            this.intersectPlane.rotation.set(PI2, 0, 0);
          } else {
            this.intersectPlane.quaternion.copy(objQuaternion.clone().premultiply(new Quaternion().setFromAxisAngle(objUnitX, PI2)));
          }
        } else if ('Z' === this.dragAxis) {
          if (this.space === 'world') {
            this.intersectPlane.rotation.set(0, 0, 0);
          } else {
            this.intersectPlane.quaternion.copy(objQuaternion);
          }
        }
        tempVector.add(boundingBoxSize.clone().multiply(curInfo.rulerPosition));

        this.intersectPlane.position.copy(tempVector);
        this.intersectPlane.updateMatrixWorld();

        intersect = this.rayCaster.intersectObject(this.intersectPlane, true)[0] || false;
        if (intersect) {

          let startPos = intersect.point;

          let curInfo = ROTATE_PICKER_INFO[this.dragAxis];
          let boundingBoxSize = new Vector3();
          this.getObjectBoundingBoxSize(boundingBoxSize);

          rotateScale = curInfo.scale.clone().multiply(boundingBoxSize).length() + 7 * this.spriteSize;
          objectMatrix.copy(this.object.matrix);
          this.object.matrix.decompose(
            objectPosition,
            objectQuaternion,
            objectScale
          );

          this.getObjectBoundingBoxSize(objectBoundingBoxSize);
          this.getObjectUnitAxes(objectUnitX, objectUnitY, objectUnitZ);

          this.D3ToD2(this.dragAxis, startPos.clone().addScaledVector(objPosition, -1), rotateStartVec);

          rotateAngle = rotateInnerAngle = 0.0;
          rotateCursorPos = startPos;

          this.isInner = true;
          this.dragMode = DRAG_MODE.ROTATE;
          this.dragStartPosition = startPos;
          this.isShowRotateRuler = true;
          this.showRotateRulerAxis = this.dragAxis;
          this.isDragging = true;
          event.preventDefault();

          activateElementEvent(false);
          return;

        }

      } else if (isAncestor(intersect.object, this.scalePicker)) {

        this.dragAxis = intersect.object.name;

        if ('T' !== this.dragAxis && 'B' !== this.dragAxis) {
          if (orientVectorZ < Math.cos(PI / 18)) {
            let boundingBoxSize = new Vector3();
            this.getObjectBoundingBoxSize(boundingBoxSize);
            tempVector.copy(objPosition);
            tempVector.addScaledVector(objUnitZ, -boundingBoxSize.z / 2);
            this.intersectPlane.position.copy(tempVector);
            if (this.space === 'world') {
              this.intersectPlane.quaternion.set(0, 0, 0, 1);
            } else {
              this.intersectPlane.quaternion.copy(objQuaternion);
            }
            this.intersectPlane.updateMatrixWorld();
          } else {
            this.setIntersectPlane(objUnitZ);
          }
        } else {
          this.setIntersectPlane(objUnitZ);
        }

        intersect = this.rayCaster.intersectObject(this.intersectPlane, true)[0] || false;
        if (intersect) {
          objectMatrix.copy(this.object.matrix);
          this.object.matrix.decompose(
            objectPosition,
            objectQuaternion,
            objectScale
          );

          this.getObjectBoundingBoxSize(objectBoundingBoxSize);
          this.getObjectUnitAxes(objectUnitX, objectUnitY, objectUnitZ);

          this.dragStartPosition = intersect.point;
          this.dragMode = DRAG_MODE.SCALE;
          this.isDragging = true;
          activateElementEvent(false);

          scaleIndicatorState = SCALE_INDICATOR_STATE.NONE;
          if (this.dragAxis === 'T' || this.dragAxis === 'B') scaleIndicatorState |= SCALE_INDICATOR_STATE.TB;
          if (this.dragAxis.includes('L') || this.dragAxis.includes('R')) scaleIndicatorState |= SCALE_INDICATOR_STATE.LR;
          if (this.dragAxis.includes('U') || this.dragAxis.includes('D')) scaleIndicatorState |= SCALE_INDICATOR_STATE.UD;

          return;
        }

      } else if (isAncestor(intersect.object, this.translatePicker) || isAncestor(intersect.object, this.translateCircle)) {

        this.dragAxis = intersect.object.name;

        if ('L' === intersect.object.name || 'R' === intersect.object.name) this.setIntersectPlane(objUnitX);
        else if ('U' === intersect.object.name || 'D' === intersect.object.name) this.setIntersectPlane(objUnitY);
        else if ('T' === intersect.object.name || 'B' === intersect.object.name) this.setIntersectPlane(objUnitZ);

        intersect = this.rayCaster.intersectObject(this.intersectPlane, true)[0] || false;

        if (intersect) {
          objectMatrix.copy(this.object.matrix);
          this.object.matrix.decompose(
            objectPosition,
            objectQuaternion,
            objectScale
          );
          this.getObjectBoundingBoxSize(objectBoundingBoxSize);
          this.getObjectUnitAxes(objectUnitX, objectUnitY, objectUnitZ);

          this.dragMode = DRAG_MODE.TRANSLATE;
          this.dragStartPosition = intersect.point;
          this.isDragging = true;
          event.preventDefault();

          activateElementEvent(false);
          return;

        }

      }

    }

    if (this.objectDraggingEnabled) {

      // Check Start Dragging Object
      intersect = this.rayCaster.intersectObject(this.object, true)[0] || false;
      if (intersect) {

        if (orientVectorZ < Math.cos(PI / 18)) {
          if (this.space === 'world') {
            this.intersectPlane.quaternion.set(0, 0, 0, 1);
          } else {
            this.intersectPlane.quaternion.copy(objQuaternion);
          }
          this.intersectPlane.updateMatrixWorld();
        } else {
          this.setIntersectPlane(objUnitZ);
        }

        intersect = this.rayCaster.intersectObject(this.intersectPlane, true)[0] || false;

        if (intersect) {

          objectMatrix.copy(this.object.matrix);
          this.object.matrix.decompose(
            objectPosition,
            objectQuaternion,
            objectScale
          );

          this.getObjectBoundingBoxSize(objectBoundingBoxSize);
          this.getObjectUnitAxes(objectUnitX, objectUnitY, objectUnitZ);

          this.dragStartPosition = intersect.point;
          this.dragMode = DRAG_MODE.OBJECT;
          this.isDragging = true;
          event.preventDefault();

          activateElementEvent(false);
          return;

        }

      }

    }

    if (!this.isDragging) {
      this.dragMode = undefined;
      lastEventTick = -Config.ShowTime - 1;
    }
  };

  this.pointerMove = function (event, pointer) {
    if (!this.object) return;

    this.shiftKeyState = event.shiftKey;
    this.altKeyState = event.altKey;

    this.rayCaster.setFromCamera(pointer, this.camera);

    if (!this.isDragging) {

      let intersect = this.rayCaster.intersectObjects([this.translatePlanePicker, this.rotatePicker, this.scalePicker, this.translatePicker, this.translateCircle], true)[0] || false;

      rotateHoverPickerName = undefined;
      scaleHoverPickerName = undefined;
      translateHoverPickerName = undefined;
      translatePlaneHoverPickerName = undefined;

      this.isShowRotateRuler = false;
      scaleHoverState = SCALE_HOVER_STATE.NONE;

      if (intersect) {

        let objName = intersect.object.name;

        if (isAncestor(intersect.object, this.rotatePicker)) {

          rotateHoverPickerName = objName;

          let curInfo = ROTATE_PICKER_INFO[objName];
          let boundingBoxSize = new Vector3();
          this.getObjectBoundingBoxSize(boundingBoxSize);

          rotateScale = curInfo.scale.clone().multiply(boundingBoxSize).length() + 7 * this.spriteSize;
          rotateAngle = rotateInnerAngle = 0.0;

          this.isInner = true;
          this.isShowRotateRuler = true;
          this.showRotateRulerAxis = objName;

          if (DRAG_MODE.ROTATE === this.dragMode) lastEventTick = -Config.ShowTime - 1;

        } else if (isAncestor(intersect.object, this.scalePicker)) {

          scaleHoverPickerName = objName;

          scaleHoverState = SCALE_HOVER_STATE.NONE;
          if (objName === 'T' || objName === 'B') scaleHoverState |= SCALE_HOVER_STATE.TB;
          if (objName.includes('L') || objName.includes('R')) scaleHoverState |= SCALE_HOVER_STATE.LR;
          if (objName.includes('U') || objName.includes('D')) scaleHoverState |= SCALE_HOVER_STATE.UD;

        } else if (isAncestor(intersect.object, this.translatePicker) || isAncestor(intersect.object, this.translateCircle)) {

          translateHoverPickerName = objName;

        } else if (isAncestor(intersect.object, this.translatePlanePicker)) {

          translatePlaneHoverPickerName = objName;

        }

      }

      return;

    }

    event.preventDefault();

    let intersect = this.rayCaster.intersectObject(this.intersectPlane, true)[0] || false;

    if (!intersect) return;

    let snapThreshold = this.spriteSize;
    this.snap.setCamera(this.camera);
    this.snap.setSnapIncrement(this.snapIncrement);

    if (DRAG_MODE.OBJECT === this.dragMode) {

      tempVector.copy(intersect.point).addScaledVector(this.dragStartPosition, -1);

      let [unit1, unit2] = [objectUnitX, objectUnitY];
      tempVector = unit1.clone().multiplyScalar(snapRound(tempVector.dot(unit1))).add(unit2.clone().multiplyScalar(snapRound(tempVector.dot(unit2))));

      this.object.matrix = objectMatrix.clone()
        .premultiply(tempMatrix.makeTranslation(tempVector.x, tempVector.y, tempVector.z));

      this.snap.setMoving([unit1, unit2]);
      this.snap.setSelectionMatrix(this.object.matrix);
      this.setSnapInfo([unit1, unit2], this.snap.snap(snapThreshold, this.snapToObjectsEnabled, this.snapToGridEnabled));

    } else if (DRAG_MODE.TRANSLATE === this.dragMode) {

      tempVector.copy(intersect.point).addScaledVector(this.dragStartPosition, -1);

      let unit;
      if ('L' === this.dragAxis || 'R' === this.dragAxis) unit = objectUnitX;
      else if ('U' === this.dragAxis || 'D' === this.dragAxis) unit = objectUnitY;
      else if ('T' === this.dragAxis || 'B' === this.dragAxis) unit = objectUnitZ;

      tempVector.copy(unit.clone().multiplyScalar(snapRound(tempVector.dot(unit))));

      this.object.matrix = objectMatrix.clone()
        .premultiply(tempMatrix.makeTranslation(tempVector.x, tempVector.y, tempVector.z));

      this.snap.setMoving([unit]);
      this.snap.setSelectionMatrix(this.object.matrix);
      this.setSnapInfo([unit], this.snap.snap(snapThreshold, this.snapToObjectsEnabled, this.snapToGridEnabled));

    } else if (DRAG_MODE.TRANSLATE_PLANE === this.dragMode) {

      tempVector.copy(intersect.point).addScaledVector(this.dragStartPosition, -1);

      let unit1, unit2;
      if ('L' === this.dragAxis || 'R' === this.dragAxis) [unit1, unit2] = [objectUnitY, objectUnitZ];
      else if ('U' === this.dragAxis || 'D' === this.dragAxis) [unit1, unit2] = [objectUnitX, objectUnitZ];
      else if ('T' === this.dragAxis || 'B' === this.dragAxis) [unit1, unit2] = [objectUnitX, objectUnitY];

      tempVector = unit1.clone().multiplyScalar(snapRound(tempVector.dot(unit1))).add(unit2.clone().multiplyScalar(snapRound(tempVector.dot(unit2))));

      this.object.matrix = objectMatrix.clone()
        .premultiply(tempMatrix.makeTranslation(tempVector.x, tempVector.y, tempVector.z));

      this.snap.setMoving([unit1, unit2]);
      this.snap.setSelectionMatrix(this.object.matrix);
      this.setSnapInfo([unit1, unit2], this.snap.snap(snapThreshold, this.snapToObjectsEnabled, this.snapToGridEnabled));

    } else if (DRAG_MODE.ROTATE === this.dragMode) {

      rotateCursorPos = intersect.point;

      this.D3ToD2(this.dragAxis, rotateCursorPos.clone().addScaledVector(objectPosition, -1), temp2DVec);

      rotateAngle = temp2DVec.angle() - rotateStartVec.angle();
      rotateAngle = (rotateAngle + 2 * PI) % (2 * PI);

      if (this.shiftKeyState) {

        rotateInnerAngle = Math.round(rotateAngle / (PI / 4)) * (PI / 4);
        this.isInner = true;

        if (rotateInnerAngle >= PI) rotateInnerAngle -= 2 * PI;

      } else {

        let dist = rotateCursorPos.distanceTo(this.intersectPlane.position);

        if (dist <= rotateScale / 2) {

          rotateInnerAngle = Math.round(rotateAngle / (PI / 8)) * (PI / 8);
          this.isInner = true;

          if (rotateInnerAngle >= PI) rotateInnerAngle -= 2 * PI;

        } else {

          this.isInner = false;
          rotateInnerAngle = rotateAngle;

        }
      }

      if (rotateAngle >= PI) rotateAngle -= 2 * PI;

      let offset = this.isInner ? rotateInnerAngle : rotateAngle;

      if (this.space === 'world') {

        let matrix = objectMatrix.clone()
          .premultiply(tempMatrix.makeTranslation(-objectPosition.x, -objectPosition.y, -objectPosition.z));

        if ('X' === this.dragAxis) matrix.premultiply(tempMatrix.makeRotationX(offset));
        else if ('Y' === this.dragAxis) matrix.premultiply(tempMatrix.makeRotationY(offset));
        else if ('Z' === this.dragAxis) matrix.premultiply(tempMatrix.makeRotationZ(offset));

        this.object.matrix = matrix.premultiply(tempMatrix.makeTranslation(objectPosition.x, objectPosition.y, objectPosition.z));

      } else {

        let unit;
        if ('X' === this.dragAxis) unit = objectUnitX;
        else if ('Y' === this.dragAxis) unit = objectUnitY;
        else if ('Z' === this.dragAxis) unit = objectUnitZ;

        this.object.matrix = new Matrix4().compose(
          objectPosition,
          objectQuaternion.clone().premultiply(new Quaternion().setFromAxisAngle(unit, offset)),
          objectScale
        );

      }

    } else if (DRAG_MODE.SCALE === this.dragMode) {

      let offset = intersect.point.clone().addScaledVector(this.dragStartPosition, -1);
      let origin = new Vector3();
      let units = [];
      scaleSize.copy(objectBoundingBoxSize);

      scaleOffset.set(0, 0, 0);
      if (this.dragAxis.includes('R')) {
        scaleOffset.x = snapRound(offset.dot(objectUnitX));
        origin.setComponent(0, -0.5);
      }
      if (this.dragAxis.includes('L')) {
        scaleOffset.x = -snapRound(offset.dot(objectUnitX));
        origin.setComponent(0, 0.5);
      }
      if (this.dragAxis.includes('U')) {
        scaleOffset.y = snapRound(offset.dot(objectUnitY));
        origin.setComponent(1, -0.5);
      }
      if (this.dragAxis.includes('D')) {
        scaleOffset.y = -snapRound(offset.dot(objectUnitY));
        origin.setComponent(1, 0.5);
      }
      if (this.dragAxis.includes('T')) {
        scaleOffset.z = snapRound(offset.dot(objectUnitZ));
        origin.setComponent(2, -0.5);
      }
      if (this.dragAxis.includes('B')) {
        scaleOffset.z = -snapRound(offset.dot(objectUnitZ));
        origin.setComponent(2, 0.5);
      }

      if (this.dragAxis.includes('R') || this.dragAxis.includes('L')) units.push(objectUnitX);
      if (this.dragAxis.includes('U') || this.dragAxis.includes('D')) units.push(objectUnitY);
      if (this.dragAxis === 'T' || this.dragAxis === 'B') units.push(objectUnitZ);

      if (this.shiftKeyState) {

        this.scaleShiftState = true;
        let rate = 0.0;

        scaleIndicatorState |= SCALE_INDICATOR_STATE.SHIFT;
        // console.log(scaleOffset.toArray(), origin.toArray(), objectBoundingBoxSize.toArray());

        for (let c = 0; c < 3; ++c) {
          if (objectBoundingBoxSize.getComponent(c) !== 0) {
            rate += scaleOffset.getComponent(c) / objectBoundingBoxSize.getComponent(c);
          }
        }

        scaleOffset.copy(objectBoundingBoxSize).multiplyScalar(rate);

        units = [objectUnitX, objectUnitY, objectUnitZ];

      } else {

        this.scaleShiftState = false;
        scaleIndicatorState &= SCALE_INDICATOR_STATE.NONSHIFT;

      }

      if (this.altKeyState) {

        tempVector.set(0, 0, 0);
        origin.set(0, 0, 0);
        scaleOffset.multiplyScalar(2);
        this.scaleAltState = true;

      } else {

        this.scaleAltState = false;
        tempVector.copy(scaleOffset).divideScalar(2);

        if (this.dragAxis.includes('L'))
          tempVector.x = -tempVector.x;
        else if (!this.dragAxis.includes('R'))
          tempVector.x = 0;

        if (this.dragAxis.includes('D'))
          tempVector.y = -tempVector.y;
        else if (!this.dragAxis.includes('U'))
          tempVector.y = 0;

        if (this.dragAxis.includes('B'))
          tempVector.z = -tempVector.z;
        else if (!this.dragAxis.includes('T'))
          tempVector.z = 0;

        tempVector = new Vector3().addScaledVector(objectUnitX, tempVector.x)
          .addScaledVector(objectUnitY, tempVector.y)
          .addScaledVector(objectUnitZ, tempVector.z);

      }

      scaleSize.add(scaleOffset);

      if (0 === objectBoundingBoxSize.x) objectBoundingBoxSize.x = 0 === scaleSize.x ? 1 : scaleSize.x;
      if (0 === objectBoundingBoxSize.y) objectBoundingBoxSize.y = 0 === scaleSize.y ? 1 : scaleSize.y;
      if (0 === objectBoundingBoxSize.z) objectBoundingBoxSize.z = 0 === scaleSize.z ? 1 : scaleSize.z;

      scaleSize.divide(objectBoundingBoxSize);

      if (this.space === 'world') {

        this.object.matrix = objectMatrix.clone()
          .premultiply(tempMatrix.makeTranslation(-objectPosition.x, -objectPosition.y, -objectPosition.z))
          .premultiply(tempMatrix.makeScale(scaleSize.x, scaleSize.y, scaleSize.z))
          .premultiply(tempMatrix.makeTranslation(objectPosition.x + tempVector.x, objectPosition.y + tempVector.y, objectPosition.z + tempVector.z));

      } else {

        this.object.matrix = objectMatrix.clone()
          .premultiply(tempMatrix.makeTranslation(-objectPosition.x, -objectPosition.y, -objectPosition.z))
          .premultiply(tempMatrix.makeRotationFromQuaternion(objectQuaternion.clone().invert()))
          .premultiply(tempMatrix.makeScale(scaleSize.x, scaleSize.y, scaleSize.z))
          .premultiply(tempMatrix.makeRotationFromQuaternion(objectQuaternion))
          .premultiply(tempMatrix.makeTranslation(objectPosition.x + tempVector.x, objectPosition.y + tempVector.y, objectPosition.z + tempVector.z));

      }

      this.snap.setScale(units, origin, this.space !== 'world', this.scaleShiftState);
      this.snap.setSelectionMatrix(this.object.matrix);
      this.setSnapInfo(units, this.snap.snap(snapThreshold, this.snapToObjectsEnabled, this.snapToGridEnabled), this.space !== 'world');

    }
  };

  this.pointerUp = function (event, pointer) {
    if (this.isDragging) {
      this.isDragging = false;
      activateElementEvent(true);

      if ((pointer.type === 'mouse' && pointer.button !== 0) || !this.dragMode) return;
      lastEventTick = curTick;

      this.shiftKeyState = event.shiftKey;
      this.altKeyState = event.altKey;

      this.setSnapInfo();
      if (this.visible && this.object)
        scope.dispatchEvent({type: 'change'});
    }
  };

  function onPointerDown(event) {
    scope.pointerDown(event, getPointer(event));
  }

  function onTouchDown(event) {
    scope.refreshBoundingClientRect();
    scope.pointerDown(event, getPointer(event));
  }

  function onPointerMove(event) {
    scope.pointerMove(event, getPointer(event));
  }

  function onPointerUp(event) {
    scope.pointerUp(event, getPointer(event));
  }

  function snapRound(value) {
    return value;
    // if (scope.snapIncrement <= 0) {
    //   return value;
    // } else {
    //   return Math.round(value / scope.snapIncrement) * scope.snapIncrement;
    // }
  }

  this.setIntersectPlane = function (baseVector) {
    alignVector.copy(this.eye).cross(baseVector);
    dirVector.copy(baseVector).cross(alignVector);
    tempMatrix.lookAt(tempVector.set(0, 0, 0), dirVector, alignVector);

    this.object.matrix.decompose(
      objPosition,
      objQuaternion,
      objScale
    );

    this.intersectPlane.position.copy(objPosition);
    this.intersectPlane.quaternion.setFromRotationMatrix(tempMatrix);
    this.intersectPlane.updateMatrixWorld();
  };

  this.setIntersectPlaneFromNormal = function (normal) {
    this.object.matrix.decompose(
      objPosition,
      objQuaternion,
      objScale
    );

    this.intersectPlane.position.copy(objPosition);
    this.intersectPlane.quaternion.setFromUnitVectors(unitZ, normal);
    this.intersectPlane.updateMatrixWorld();
  };

  this.createScalePickerMaterial = function (type, hover) {

    if (type === 'corner') {

      let canvas1 = document.createElement('canvas');
      canvas1.width = canvas1.height = 512;

      let context1 = canvas1.getContext('2d');

      context1.clearRect(0, 0, 512, 512);

      context1.fillStyle = 'rgba(0, 0, 0)';
      context1.fillRect(0, 0, 512, 512);

      context1.fillStyle = 'rgb(255, 255, 255)';

      if (hover)
        context1.fillStyle = 'rgb(255, 0, 0)';

      context1.fillRect(120, 120, 272, 272);

      let texture1 = new Texture(canvas1);
      texture1.needsUpdate = true;

      return new SpriteMaterial({map: texture1});

    } else {

      return new MeshBasicMaterial({
        color: hover ? 0xFF0000 : 0xFFFFFF,
        side: BackSide
      });

    }
  };

  this.createScalePickerUnit = function (name, type, hover) {

    if (type === 'corner') {

      let material = hover ? this.materials.scale.corner.hover : this.materials.scale.corner.normal;

      let sprite = new Sprite(material);
      sprite.name = name;
      return sprite;

    } else {

      let group = new Group();

      let loader = new SVGLoader();

      let triangleData = loader.parse(TRIANGLE_IMAGE_DATA);
      let triangleShape = triangleData.paths[0].toShapes(true)[0];

      let geometry = new ExtrudeBufferGeometry(triangleShape, {depth: 1, bevelEnabled: false, curveSegments: 40});
      geometry.center();

      let backTriangleData = loader.parse(TRIANGLE_BACK_IMAGE_DATA);
      let backTriangleShape = backTriangleData.paths[0].toShapes(true)[0];

      let backGeometry = new ExtrudeBufferGeometry(backTriangleShape, {
        depth: 1,
        bevelEnabled: false,
        curveSegments: 40
      });
      backGeometry.center();
      // let material = hover ? this.materials.scale.edge.hover : this.materials.scale.edge.normal;

      let triangleMesh = new Mesh(geometry, new MeshBasicMaterial({color: 0x000000}));
      triangleMesh.position.set(0, -5.5, 0);

      let triangleRevMesh = new Mesh(geometry, new MeshBasicMaterial({color: 0x000000}));
      triangleRevMesh.position.set(0, 5.5, 0);
      triangleRevMesh.quaternion.setFromAxisAngle(unitZ, PI);

      let triangleBackMesh = new Mesh(backGeometry);

      triangleMesh.name = name;
      triangleRevMesh.name = name;
      triangleBackMesh.name = name;

      group.name = name;
      group.add(triangleBackMesh, triangleMesh, triangleRevMesh);

      return group;

    }

  };

  this.createScalePicker = function () {
    let group = new Group();

    for (let name in SCALE_PICKER_INFO) {
      group.add(this.createScalePickerUnit(name, SCALE_PICKER_INFO[name].type, false));
    }

    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createZAxisLine = function () {

    let lineGeometry = new LineGeometry();
    lineGeometry.setPositions(new Array(6).fill(0));

    let lineMaterial = new LineMaterial({
      color: 0x000000,
      linewidth: 1.5,
      gapSize: 0.75,
      dashSize: 0.75,
      dashScale: 1,
      dashed: true
    });
    lineMaterial.defines.USE_DASH = "";

    let line = new Line2(lineGeometry, lineMaterial);
    line.computeLineDistances();
    line.visible = false;
    this.controlGroup.add(line);
    return line;
  };

  this.createAxisIndicator = function () {
    let group = new Group();
    let xAxisGeometry = new LineGeometry();
    let yAxisGeometry = new LineGeometry();
    let zAxisGeometry = new LineGeometry();

    xAxisGeometry.setPositions([0, 0, 0, .5, 0, 0]);
    yAxisGeometry.setPositions([0, 0, 0, 0, .5, 0]);
    zAxisGeometry.setPositions([0, 0, 0, 0, 0, .5]);

    let xLineMaterial = new LineMaterial({color: 0xFF4C4C, linewidth: 3});
    let yLineMaterial = new LineMaterial({color: 0x4CFFDF, linewidth: 3});
    let zLineMaterial = new LineMaterial({color: 0xA54CFF, linewidth: 3});

    let xAxisLine = new Line2(xAxisGeometry, xLineMaterial);
    let yAxisLine = new Line2(yAxisGeometry, yLineMaterial);
    let zAxisLine = new Line2(zAxisGeometry, zLineMaterial);
    xAxisLine.name = 'X';
    yAxisLine.name = 'Y';
    zAxisLine.name = 'Z';

    group.add(xAxisLine);
    group.add(yAxisLine);
    group.add(zAxisLine);
    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createDimText = function () {
    let group = new Group();
    let rDim = new SpriteText2D('0.00mm', {
      align: textAlign.center,
      font: '48px Arial',
      fillStyle: '#0e0e0f',
      shadowColor: '#0000ff',
      shadowBlur: 5,
      antialias: true
    });
    let uDim = new SpriteText2D('0.00mm', {
      align: textAlign.center,
      font: '48px Arial',
      fillStyle: '#0e0e0f',
      shadowColor: '#0000ff',
      shadowBlur: 5,
      antialias: true
    });
    let tDim = new SpriteText2D('0.00mm', {
      align: textAlign.center,
      font: '48px Arial',
      fillStyle: '#0e0e0f',
      shadowColor: '#0000ff',
      shadowBlur: 5,
      antialias: true
    });
    rDim.material.depthTest = false;
    uDim.material.depthTest = false;
    tDim.material.depthTest = false;

    rDim.position.set(0.2, 0.5, -0.5);
    uDim.position.set(0.5, 0.2, -0.5);
    tDim.position.set(0.5, 0.5, 0);

    group.add(rDim);
    group.add(uDim);
    group.add(tDim);
    this.controlGroup.add(group);
    return group;
  };

  this.createBoundingBoxLines = function () {
    let group = new Group();

    let topLineGeometry = new LineGeometry();
    topLineGeometry.setPositions(new Array(15).fill(0));

    let bottomLineGeometry = new LineGeometry();
    bottomLineGeometry.setPositions(new Array(15).fill(0));

    let backLineMaterial = new LineMaterial({color: 0xFFFFFF, linewidth: 1.5});
    let edgeLineMaterial = new LineMaterial({
      color: 0x000000,
      linewidth: 1.5,
      gapSize: 0.75,
      dashSize: 0.75,
      dashScale: 1,
      dashed: true
    });
    edgeLineMaterial.defines.USE_DASH = "";

    let topBackLine = new Line2(topLineGeometry, backLineMaterial);
    let topEdgeLine = new Line2(topLineGeometry, edgeLineMaterial);

    let bottomBackLine = new Line2(bottomLineGeometry, backLineMaterial);
    let bottomEdgeLine = new Line2(bottomLineGeometry, edgeLineMaterial);

    topEdgeLine.computeLineDistances();
    bottomEdgeLine.computeLineDistances();

    group.add(topBackLine, topEdgeLine, bottomBackLine, bottomEdgeLine);
    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createRotateRulerForAxis = function (axis) {
    let group = new Group();

    // Create Outer Non Selected Ring

    let outNonSelGeometry = new RingBufferGeometry();
    let outNonSelMaterial = new MeshBasicMaterial({
      color: RING_INFO.OUT_COLOR,
      side: DoubleSide,
      opacity: 0.3
    });
    let outNonSelMesh = new Mesh(outNonSelGeometry, outNonSelMaterial);
    outNonSelMesh.name = 'OUTRING_NON_SEL';

    group.add(outNonSelMesh);

    // Create Outer Selected Ring

    let outSelGeometry = new RingBufferGeometry();
    let outSelMaterial = new MeshBasicMaterial({
      color: RING_INFO.SEL_COLOR,
      side: DoubleSide,
      opacity: 0.3
    });
    let outSelMesh = new Mesh(outSelGeometry, outSelMaterial);
    outSelMesh.name = 'OUTRING_SEL';

    group.add(outSelMesh);

    // Create Inner Non Selected Ring
    let inNonSelGeometry = new RingBufferGeometry();
    let inNonSelMaterial = new MeshBasicMaterial({
      color: RING_INFO.IN_COLOR,
      side: DoubleSide,
      opacity: 0.3
    });
    let inNonSelMesh = new Mesh(inNonSelGeometry, inNonSelMaterial);
    inNonSelMesh.name = 'RING_NON_SEL';

    group.add(inNonSelMesh);

    // Create Inner Selected Ring
    let inSelGeometry = new RingBufferGeometry();
    let inSelMaterial = new MeshBasicMaterial({
      color: 0xff0000,
      side: DoubleSide,
      opacity: 0.3
    });
    let inSelMesh = new Mesh(inSelGeometry, inSelMaterial);
    inSelMesh.name = 'RING_SEL';

    group.add(inSelMesh);

    // Create Outer Line Group
    let outLineposition = [];

    for (let i = 0; i < RING_INFO.OUT_LINE_COUNT; ++i)
      outLineposition.push(0, 0, 0, 0, 0, 0);

    let outLineGeometry = new BufferGeometry();
    outLineGeometry.attributes.position = new Float32BufferAttribute(outLineposition, 3);
    let outLineSegments = new LineSegments(outLineGeometry, new LineBasicMaterial({
      color: 0x000000, transparent: true, depthTest: false
    }));
    outLineSegments.computeLineDistances();
    outLineSegments.name = 'OUT_LINES';

    group.add(outLineSegments);

    // Create Inner Line Group
    let inLineposition = [];

    for (let i = 0; i < RING_INFO.IN_LINE_COUNT; ++i)
      inLineposition.push(0, 0, 0, 0, 0, 0);

    let inLineGeometry = new BufferGeometry();
    inLineGeometry.attributes.position = new Float32BufferAttribute(inLineposition, 3);
    let inLineSegments = new LineSegments(inLineGeometry, new LineBasicMaterial({
      color: 0x000000, transparent: true, depthTest: false
    }));
    inLineSegments.computeLineDistances();
    inLineSegments.name = 'IN_LINES';

    group.add(inLineSegments);
    group.name = axis;
    group.visible = false;

    return group;
  };

  this.createRotateRuler = function () {
    let group = new Group();

    for (let axis in ROTATE_PICKER_INFO) {
      group.add(this.createRotateRulerForAxis(axis));
    }

    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createRotateShiftRulerForAxis = function (axis) {
    let group = new Group();

    // Create Inner Non Selected Ring
    let inNonSelGeometry = new RingBufferGeometry();
    let inNonSelMaterial = new MeshBasicMaterial({
      color: RING_INFO.IN_COLOR,
      side: DoubleSide,
      opacity: 0.3
    });
    let inNonSelMesh = new Mesh(inNonSelGeometry, inNonSelMaterial);
    inNonSelMesh.name = 'RING_NON_SEL';

    group.add(inNonSelMesh);

    // Create Inner Selected Ring
    let inSelGeometry = new RingBufferGeometry();
    let inSelMaterial = new MeshBasicMaterial({
      color: RING_INFO.SEL_COLOR,
      side: DoubleSide,
      opacity: 0.3
    });
    let inSelMesh = new Mesh(inSelGeometry, inSelMaterial);
    inSelMesh.name = 'RING_SEL';

    group.add(inSelMesh);

    // Create Outer Line Group
    let outLineposition = [];

    for (let i = 0; i < RING_INFO.SHIFT_LINE_COUNT; ++i) outLineposition.push(0, 0, 0, 0, 0, 0);

    let outLineGeometry = new BufferGeometry();
    outLineGeometry.attributes.position = new Float32BufferAttribute(outLineposition, 3);
    let outLineSegments = new LineSegments(outLineGeometry, new LineBasicMaterial({
      color: 0x000000,
    }));
    outLineSegments.computeLineDistances();
    outLineSegments.name = 'OUT_LINES';

    group.add(outLineSegments);

    group.name = axis;

    return group;
  };

  this.createRotateShiftRuler = function () {
    let group = new Group();

    for (let axis in ROTATE_PICKER_INFO) {
      group.add(this.createRotateShiftRulerForAxis(axis));
    }

    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createTranslatePickerUnitForAxis = function (axis) {
    let group = new Group();

    let geometry = new ConeBufferGeometry(0.5, 1.5, 50);

    let material = new MeshBasicMaterial({color: 0x000000, side: FrontSide});
    let cone = new Mesh(geometry, material);

    let whiteCone = new Mesh(geometry);
    whiteCone.scale.set(1.5, 1.5, 1.5);
    whiteCone.position.set(0, 0.15, 0);

    cone.name = axis;
    whiteCone.name = axis;

    group.add(cone);
    group.add(whiteCone);
    group.name = axis;

    return group;
  };

  this.createTranslatePicker = function () {
    let group = new Group();

    for (let axis in TRANSLATE_PICKER_INFO)
      group.add(this.createTranslatePickerUnitForAxis(axis));

    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createTranslateCircle = function () {
    let group = new Group();
    let cylinderGeometry = new CylinderBufferGeometry(2, 2, 0.1, 40);

    let xMaterial = new MeshBasicMaterial({
      color: 0xFF4C4C,
      side: FrontSide,
      transparent: true,
      opacity: 0.3
    });

    let yMaterial = new MeshBasicMaterial({
      color: 0x4CFFDF,
      side: FrontSide,
      transparent: true,
      opacity: 0.3
    });

    let zMaterial = new MeshBasicMaterial({
      color: 0xA54CFF,
      side: FrontSide,
      transparent: true,
      opacity: 0.3
    });

    let xAxisCircle = new Mesh(cylinderGeometry, xMaterial);
    let yAxisCircle = new Mesh(cylinderGeometry, yMaterial);
    let zAxisCircle = new Mesh(cylinderGeometry, zMaterial);

    xAxisCircle.name = 'R';
    yAxisCircle.name = 'U';
    zAxisCircle.name = 'T';

    group.add(xAxisCircle);
    group.add(yAxisCircle);
    group.add(zAxisCircle);
    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createTranslatePlanePicker = function () {
    let group = new Group();
    let cylinderGeometry = new CylinderBufferGeometry(1, 1, 0.1, 40);

    let xAxisCircle = new Mesh(cylinderGeometry);
    let yAxisCircle = new Mesh(cylinderGeometry);
    let zAxisCircle = new Mesh(cylinderGeometry);

    xAxisCircle.name = 'R';
    yAxisCircle.name = 'U';
    zAxisCircle.name = 'T';

    group.add(xAxisCircle);
    group.add(yAxisCircle);
    group.add(zAxisCircle);
    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createRotatePickerMaterial = function (hover) {
    return new MeshBasicMaterial({
      color: hover ? 0xFF0000 : 0xFFFFFF,
      side: BackSide,
    });
  };

  this.createTranslatePickerMaterial = function (hover) {
    return new MeshBasicMaterial({
      color: hover ? 0xFF0000 : 0xFFFFFF,
      side: BackSide
    });
  };

  this.createTranslatePlanePickerMaterial = function (hover) {

    let xMaterial = new MeshBasicMaterial({
      color: 0xFF4C4C,
      side: FrontSide,
      transparent: true,
      opacity: hover ? 1 : 0.3
    });

    let yMaterial = new MeshBasicMaterial({
      color: 0x4CFFDF,
      side: FrontSide,
      transparent: true,
      opacity: hover ? 1 : 0.3
    });

    let zMaterial = new MeshBasicMaterial({
      color: 0xA54CFF,
      side: FrontSide,
      transparent: true,
      opacity: hover ? 1 : 0.3
    });

    return [xMaterial, yMaterial, zMaterial];
  };

  this.createRotatePickerUnit = function (axis) {
    let group = new Group();

    let loader = new SVGLoader();

    let arrowData = loader.parse(ARROW_IMAGE_DATA);
    let arrowShape = arrowData.paths[0].toShapes(true)[0];

    let geometry = new ExtrudeBufferGeometry(arrowShape, {depth: 1, bevelEnabled: false, curveSegments: 40});
    geometry.center();

    let arrowBackData = loader.parse(ARROW_BACK_IMAGE_DATA);
    let arrowBackShape = arrowBackData.paths[0].toShapes(true)[0];

    let backGeometry = new ExtrudeBufferGeometry(arrowBackShape, {depth: 1, bevelEnabled: false, curveSegments: 40});
    backGeometry.center();

    let arrowMesh = new Mesh(geometry, new MeshBasicMaterial({color: 0x000000}));
    let arrowBackMesh = new Mesh(backGeometry, this.materials.rotate.normal);

    arrowMesh.name = axis;
    arrowBackMesh.name = axis;

    group.add(arrowBackMesh, arrowMesh);
    group.name = axis;

    return group;
  };

  this.createRotatePicker = function () {
    let group = new Group();
    for (let axis in ROTATE_PICKER_INFO)
      group.add(this.createRotatePickerUnit(axis));
    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createRotateIndicator = function () {
    let group = new Group();
    group.name = `transform-control-rotate-indicator`;

    let geometry = new BufferGeometry();
    let position = [0, 0, 0, 0, 0, 0];
    geometry.attributes.position = new Float32BufferAttribute(position, 3);
    let line = new LineSegments(geometry, new LineBasicMaterial({
      color: 0xff0000,
      transparent: true,
      depthTest: false,
    }));
    line.name = 'MAIN_LINE';

    group.add(line);
    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createTriangle = function (name = 'TRIANGLE') {
    let geometry = new BufferGeometry();
    let r = 1.0;
    let vertices = [];
    for (let i = 0; i < 3; ++i) {
      let ang = i * 2 * PI / 3;
      vertices.push(r * Math.sin(ang), r * Math.cos(ang), 0);
    }
    geometry.index = new Uint32BufferAttribute([0, 1, 2], 1);
    geometry.attributes.position = new Float32BufferAttribute(vertices, 3);

    let mesh = new Mesh(geometry, new MeshBasicMaterial({
      color: 0x000000,
      side: DoubleSide
    }));
    mesh.name = name;
    return mesh;
  };

  this.createEmptyLine = function (name, color = 0x000000) {
    let geometry = new BufferGeometry();
    geometry.attributes.position = new Float32BufferAttribute([0, 0, 0, 0, 0, 0], 3);
    let lineSegments = new LineSegments(geometry, new LineBasicMaterial({
      color: color
    }));
    lineSegments.computeLineDistances();
    lineSegments.name = name;

    return lineSegments;
  };

  this.createAxisTranslateIndicator = function (name) {
    let group = new Group();

    group.add(this.createEmptyLine('MAIN_LINE'));
    group.add(this.createEmptyLine('SUB_LINE_1'));
    group.add(this.createEmptyLine('SUB_LINE_2'));
    group.add(this.createTriangle());

    group.name = name;
    group.visible = false;
    return group;
  };

  this.createTranslateIndicator = function () {
    let group = new Group();

    group.add(this.createAxisTranslateIndicator('X_AXIS'));
    group.add(this.createAxisTranslateIndicator('Y_AXIS'));
    group.add(this.createAxisTranslateIndicator('Z_AXIS'));

    group.visible = false;
    this.controlGroup.add(group);
    return group;
  };

  this.createScaleIndicatorForAxis = function (axis) {
    let group = new Group();
    group.add(this.createEmptyLine('MAIN_LINE'));
    group.add(this.createEmptyLine('SUB_LINE_1'));
    group.add(this.createEmptyLine('SUB_LINE_2'));
    group.add(this.createTriangle('TRIANGLE_1'));
    group.add(this.createTriangle('TRIANGLE_2'));
    group.name = axis;
    return group;
  };

  this.createScaleIndicator = function () {
    let group = new Group();
    group.add(this.createScaleIndicatorForAxis('X'));
    group.add(this.createScaleIndicatorForAxis('Y'));
    group.add(this.createScaleIndicatorForAxis('Z'));
    group.visible = false;
    this.controlGroup.add(group);
    return group;
  };

  this.materials = {
    translate: {
      hover: this.createTranslatePickerMaterial(true),
      normal: this.createTranslatePickerMaterial(false)
    },
    translatePlane: {
      hover: this.createTranslatePlanePickerMaterial(true),
      normal: this.createTranslatePlanePickerMaterial(false)
    },
    rotate: {
      hover: this.createRotatePickerMaterial(true),
      normal: this.createRotatePickerMaterial(false)
    },
    scale: {
      edge: {
        hover: this.createScalePickerMaterial('edge', true),
        normal: this.createScalePickerMaterial('edge', false)
      },
      corner: {
        hover: this.createScalePickerMaterial('corner', true),
        normal: this.createScalePickerMaterial('corner', false)
      }
    },
  };

  // Children
  this.controlGroup = new Group();
  this.controlGroup.name = `transform-control-group`;
  this.controlGroup.matrixAutoUpdate = false;
  this.boundingBoxLines = this.createBoundingBoxLines();
  this.axisIndicator = this.createAxisIndicator();
  this.dimText = this.createDimText();
  this.zAxisLine = this.createZAxisLine();
  this.scalePicker = this.createScalePicker();
  this.translatePicker = this.createTranslatePicker();
  this.translateCircle = this.createTranslateCircle();
  this.translatePlanePicker = this.createTranslatePlanePicker();
  this.rotatePicker = this.createRotatePicker();
  this.rotateRuler = this.createRotateRuler();
  this.rotateShiftRuler = this.createRotateShiftRuler();
  this.rotateIndicator = this.createRotateIndicator();
  this.translateIndicator = this.createTranslateIndicator();
  this.scaleIndicator = this.createScaleIndicator();

  this.controlScene.add(this.controlGroup);
  this.controlScene.add(this.intersectPlane);
};

TransformControl.prototype = Object.assign(Object.create(Object3D.prototype), Object.create(EventDispatcher.prototype),
  {

    constructor: TransformControl,

    isTransformControls: true

  });

export {TransformControl};