import {curve as curveTypes, pigeon as pigeonTypes, sculpt as sculptTypes} from "../../types";
import {getGeometryFromSculptGeometry} from "../../converter";
import {ObjectTypes, XObject} from "../xobject";
import {InvalidId} from "../const";
import {ICalcConfigDiff, ICalcSetting, IParameterProperty} from "../types";
import {mat4} from "gl-matrix";
import {
  WompBrep,
  WompBrepData,
  WompCurve,
  WompCurveData,
  WompMesh,
  WompMeshData,
  WompObjectRef
} from "../../WompObject";
import {_b, _c, _s, _t} from "../../t";
import lod from 'lodash';

export enum ParamTypes {
  None = 'parameter:',
  Number = 'parameter:number',
  String = 'parameter:string',
  Json = 'parameter:json',
  Index = 'parameter:index',
  Boolean = 'parameter:boolean',
  Curve = 'parameter:curve',
  Brep = 'parameter:brep',
  Compound = 'parameter:compound',
  Point = 'parameter:point',
  Vertex = 'parameter:vertex',
  Mesh = 'parameter:mesh',
  Transform = 'parameter:transform',
  SculptMesh = 'parameter:sculpt-mesh',
}

export const RenderableParamTypes: string[] = [ParamTypes.Curve, ParamTypes.Brep, ParamTypes.Brep, ParamTypes.Compound, ParamTypes.Vertex, ParamTypes.Mesh, ParamTypes.SculptMesh];

export enum ParamValueTypes {
  Float = 'float',
  UnsignedInt = 'unsigned-int',
  Length = 'length',
  NonZeroLength = 'non-zero-length',
  Offset = 'offset',
  Degree = 'degree',
  Percent = 'percent',
  Skew = 'skew',
  Scale = 'scale'
}

export enum ParamTitles {
  Transform = 'transform',
  OrgCenter = 'original center',
  Position = 'position',
  Scale = 'scale',
  Translate = 'translate',
  Rotate = 'rotate',
  LocalSize = '@@local@@size',
  GlobalSize = '@@global@@size',
  Skew = 'skew',
  Object = 'object',
  Out = 'out',
  Vertex = 'vertex',
  Periodic = 'periodic',
  Bezier = 'bezier',
  Curve = 'curve',
  Polyline = 'polyline',
  UCurve = 'u curve',
  VCurve = 'v curve',
  Invert = 'invert',
  Surface = 'surface',
  Count = 'count',
  Spacing = 'spacing',
  Offset = 'offset',
  Array = 'array',
  Direction = 'direction',
  Distance = 'distance',
  Thickness = 'thickness',
  Hollow = 'hollow',
  Cap = 'cap',
  Cut = 'cut',
  Fillet = 'fillet',
  Extrude = 'extrude',
  Group = 'group',
  Intersect = 'intersect',
  SculptTransform = 'sculpt transform',
  Sculpt = 'sculpt',
  Stroke = 'stroke',
  Mesh = 'mesh',
  Brep = 'brep',
  Mirror = 'mirror',
  Normal = 'normal',
  Union = 'union',
  Weld = 'weld',
  Cone = 'cone',
  Cube = 'cube',
  Cylinder = 'cylinder',
  InnerRadius = 'inner radius',
  Donut = 'donut',
  Pyramid = 'pyramid',
  Pipe = 'pipe',
  Sphere = 'sphere',
  Text = 'text',
  Plane='plane',
  Font = 'font',
  Bold = 'bold',
  Italic = 'italic',
  Tube = 'tube',
  Loft = 'loft',
  Smooth = 'smooth',
  Axis = 'axis',
  Angle = 'angle',
  Revolve = 'revolve'
}

export const GumballParamTitles: string[] = [
  ParamTitles.LocalSize,
  ParamTitles.GlobalSize,
  ParamTitles.Scale,
  ParamTitles.Rotate,
  ParamTitles.Position,
  ParamTitles.Translate,
  ParamTitles.Skew
];

export function getObjectType(paramType: ParamTypes): ObjectTypes {
  switch (paramType) {
    case ParamTypes.Number:
      return ObjectTypes.Number;
    case ParamTypes.String:
      return ObjectTypes.String;
    case ParamTypes.Json:
      return ObjectTypes.Json;
    case ParamTypes.Index:
      return ObjectTypes.String;
    case ParamTypes.Boolean:
      return ObjectTypes.Boolean;
    case ParamTypes.Point:
      return ObjectTypes.Point;
    case ParamTypes.Vertex:
      return ObjectTypes.Vertex;
    case ParamTypes.Curve:
      return ObjectTypes.Curve;
    case ParamTypes.Brep:
      return ObjectTypes.Brep;
    case ParamTypes.Mesh:
      return ObjectTypes.Mesh;
    case ParamTypes.Transform:
      return ObjectTypes.Transform;
    case ParamTypes.SculptMesh:
      return ObjectTypes.SculptMesh;
  }
  return ObjectTypes.None;
}

export function isParameterTitle(str: string) {
  return Object.values(ParamTitles).includes(str as ParamTitles);
}

export class Parameter extends XObject {
  protected _id: string = InvalidId;
  protected _objectType: ParamTypes = ParamTypes.None;
  protected _calc = XObject.unset;

  get calc() {
    return this._calc;
  }

  set calc(calc: XObject) {
    this._calc = calc;
  }

  get id() {
    return this._id;
  }

  set id(id: string) {
    if (this._id !== id) {
      this._id = id;
      this._data = {
        ...this._data,
        [_s('id')]: id
      }
    }
  }

  protected _isInput: boolean = true;

  get isInput() {
    return this._isInput;
  }

  protected _isOperable: boolean = true;

  get isOperable() {
    return this._isOperable;
  }

  protected _title: string = '';

  get title () {
    return this._title;
  }

  protected _render: boolean = false;

  get render () {
    return this._render;
  }

  protected _hidden: boolean = false;

  get hidden () {
    return this._hidden;
  }

  protected _interfaceType: string = '';

  get interfaceType() {
    return this._interfaceType;
  }

  protected _nextRelations: XObject[] = [];

  get nextRelations() {
    return this._nextRelations;
  }

  protected _prevRelations: XObject[] = [];

  get prevRelations() {
    return this._prevRelations;
  }

  _setPrevRelations(prevRelations: XObject[]) {
    if (this._prevRelations !== prevRelations) {
      this._prevRelations = prevRelations;

      if (!this._calc.isInvalid) {
        (this._calc as any).setStateInvalid();
      }
    }
  }

  _setNextRelations(nextRelations: XObject[]) {
    if (this._nextRelations !== nextRelations) {
      this._nextRelations = nextRelations;

      if (!this._calc.isInvalid) {
        (this._calc as any).setStateInvalid();
      }
    }
  }

  protected _values: any[] = [];

  protected _defaultValue: any;

  get defaultValue() {
    return this._defaultValue;
  }

  protected _stateValid: boolean = false;

  get stateValid() {
    return this._stateValid;
  }

  protected _state: ICalcSetting[] = [];

  get state() {
    if (!this.stateValid) {
      this._state = this.generateState();
      this._stateValid = true;
    }
    return this._state;
  }

  protected _dataValid: boolean = false;

  get dataValid() {
    return this._dataValid;
  }

  protected set dataValues(values: any) {
    this._data = {
      ...this._data,
      [_s('values')]: values
    }
  }

  protected set dataValueTypes(valueTypes: any) {
    this._data = {
      ...this._data,
      [_s('valueTypes')]: valueTypes
    }
  }

  protected set dataCaches(caches: any) {
    this._data = {
      ...this._data,
      [_s('caches')]: caches
    }
  }

  protected set dataProperties(properties: any) {
    this._data = {
      ...this._data,
      [_s('properties')]: properties
    }
  }

  protected _data: any = {};

  get data() {
    if (!this.dataValid)
      this.validateGeneratedData();
    return this._data;
  }

  getMinimalData() {
    let minimalData = {...this.data};
    if (minimalData[_s('values')])
      minimalData[_s('values')] = [];
    if (minimalData[_s('valueTypes')])
      minimalData[_s('valueTypes')] = [];
    if (minimalData[_s('caches')])
      minimalData[_s('caches')] = [];
    if (minimalData[_s('properties')])
      minimalData[_s('properties')] = [];
    return minimalData;
  }

  get length() {
    return 0;
  }

  static unset = new Parameter();

  get isInvalid() {
    return this._id === InvalidId;
  }

  generateProperties(): IParameterProperty[] {
    return [];
  }

  generateState(): ICalcSetting[] {
    return [];
  }

  protected validateGeneratedData() {
    this._dataValid = true;
  }

  setupInitialData() {
    this._data = {
      ...this._data,
      [_s('id')]: this._id,
      [_s('title')]: this._title,
      [_s('isInput')]: _b(this._isInput),
      [_s('isOperable')]: _b(this._isOperable),
      [_s('render')]: _b(this._render),
      [_s('hidden')]: _b(this._hidden),
      [_s('objectType')]: _s(this._objectType),
      [_s('interfaceType')]: _s(this._interfaceType)
    };
  }

  overwrite(jData: any, replaceData: boolean) {
    if (this._data[_s('id')] !== jData[_s('id')] && jData[_s('id')] !== undefined) {
      this._id = jData[_s('id')];
    }

    if (this._data[_s('title')] !== jData[_s('title')] && jData[_s('title')] !== undefined) {
      this._title = jData[_s('title')];
    }

    if (this._data[_s('isInput')] !== jData[_s('isInput')] && jData[_s('isInput')] !== undefined) {
      this._isInput = _c(jData[_s('isInput')]);
    }

    if (this._data[_s('render')] !== jData[_s('render')] && jData[_s('render')] !== undefined) {
      this._render = _c(jData[_s('render')]);
    }

    if (this._data[_s('hidden')] !== jData[_s('hidden')] && jData[_s('hidden')] !== undefined) {
      this._hidden = _c(jData[_s('hidden')]);
    }

    if (this._data[_s('objectType')] !== jData[_s('objectType')] && jData[_s('objectType')] !== undefined) {
      this._objectType = _t(jData[_s('objectType')]) as ParamTypes;
    }

    if (this._data[_s('interfaceType')] !== jData[_s('interfaceType')] && jData[_s('interfaceType')] !== undefined) {
      this._interfaceType = _t(jData[_s('interfaceType')]);
    }

    if (this._data[_s('isOperable')] !== jData[_s('isOperable')] && jData[_s('isOperable')] !== undefined) {
      this._isOperable = _c(jData[_s('isOperable')]);
    }

    if (!replaceData) {
      this.setupInitialData();
    }
  }

  async generateCaches() {
  }

  solve(diff?: ICalcConfigDiff) {
  }

  addNextRelation(relation: XObject) {
    this._setNextRelations([...this.nextRelations, relation]);
  }

  addPrevRelation(relation: XObject) {
    this._setPrevRelations([...this.prevRelations, relation]);
  }

  removeNextRelation(relation: XObject) {
    this._setNextRelations(this.nextRelations.filter(r => r !== relation));
  }

  removePrevRelation(relation: XObject) {
    this._setPrevRelations(this.prevRelations.filter(r => r !== relation));
  }
}

export function registerSceneGeometry(scene: any, type: ObjectTypes, obj: WompMeshData | sculptTypes.MeshData | WompBrepData | WompCurveData, desc: string) {
  return scene.registerObject(type, obj, desc);
}

export function registerGeometry(calcOrScene: any, obj: WompMeshData, desc: string) {
  // TODO: BYZ - decide what to do to make sure that geometries are correct.
  // fixGeometry(obj);
  if (calcOrScene.objectType === ObjectTypes.Calc)
    calcOrScene = calcOrScene.scene;
  return registerSceneGeometry(calcOrScene, ObjectTypes.Geometry, obj, desc);
}

export async function registerBrepGeometryWithTessellation(calcOrScene: any, obj: WompBrepData, desc: string) {
  let id = registerBrepGeometry(calcOrScene, obj, desc);
  let [meshData, edgeData] = await pigeonTypes.tessellate(obj);
  let meshId = registerGeometry(calcOrScene, meshData, desc + '-mesh');
  let edgeId = registerGeometry(calcOrScene, edgeData, desc + '-edge');

  return {id, meshId, edgeId};
}

export async function registerCurveGeometryWithEdge(calcOrScene: any, obj: WompCurveData, desc: string) {
  let id = registerCurveGeometry(calcOrScene, obj, desc);
  let edgeData = curveTypes.getCurveEdgeGeometry(obj);
  let edgeId = registerGeometry(calcOrScene, edgeData, desc+'-edge');

  return {id, edgeId};
}

export function registerBrepGeometry(calcOrScene: any, obj: WompBrepData, desc: string) {
  if (calcOrScene.objectType === ObjectTypes.Calc)
    calcOrScene = calcOrScene.scene;
  return registerSceneGeometry(calcOrScene, ObjectTypes.BrepGeometry, obj, desc);
}

export function registerCurveGeometry(calcOrScene: any, obj: WompCurveData, desc: string) {
  if (calcOrScene.objectType === ObjectTypes.Calc)
    calcOrScene = calcOrScene.scene;
  return registerSceneGeometry(calcOrScene, ObjectTypes.CurveGeometry, obj, desc);
}

export function registerSculptGeometry(calcOrScene: any, obj: sculptTypes.MeshData, desc: string) {
  if (calcOrScene.objectType === ObjectTypes.Calc)
    calcOrScene = calcOrScene.scene;
  return registerSceneGeometry(calcOrScene, ObjectTypes.SculptGeometry, obj, desc);
}

export function getRegisteredBrepIdWithTessellation(calc: any, desc: string) {
  let id = getRegisteredId(calc, desc);
  let meshId = getRegisteredId(calc, desc+'-mesh');
  let edgeId = getRegisteredId(calc, desc+'-edge');
  return {id, meshId, edgeId};
}

export function getRegisteredCurveIdWithEdge(calc: any, desc: string) {
  let id = getRegisteredId(calc, desc);
  let edgeId = getRegisteredId(calc, desc+'-edge');
  return {id, edgeId};
}

export function getRegisteredId(calc: any, desc: string) {
  return calc.scene.getRegisteredId(desc);
}

export async function createObjectFromBrepData(calc: any, brepData: WompBrepData, desc: string, matrix: mat4) {
  let {id, meshId, edgeId} = await registerBrepGeometryWithTessellation(calc, brepData, desc);

  return {
    value: new WompObjectRef(id, matrix),
    valueType: ObjectTypes.Brep,
    cache: [new WompObjectRef(meshId, matrix), new WompObjectRef(edgeId, matrix)]
  };
}

export function createObjectFromMeshData(calc: any, meshData: WompMeshData, desc: string, matrix: mat4) {
  return {
    value: new WompObjectRef(registerGeometry(calc, meshData, desc), matrix),
    valueType: ObjectTypes.Mesh,
    cache: null
  };
}

export function getMeshFromRef(calcOrScene: any, objRef: WompObjectRef) {
  let scene;
  if (calcOrScene.objectType === ObjectTypes.Scene) {
    scene = calcOrScene;
  } else if (calcOrScene.objectType === ObjectTypes.Calc) {
    scene = calcOrScene.scene;
  }
  let obj = scene.getRegisteredObject(objRef.objectId);
  if (obj) {
    if (obj.type === ObjectTypes.Geometry) {
      return new WompMesh(obj.value, objRef.matrix);
    } else if (obj.type === ObjectTypes.SculptGeometry) {
      let geometry = getGeometryFromSculptGeometry(obj.value);
      // TODO: BYZ - this was bottle-neck. find alternative for this.
      // fixGeometry(geometry);
      return new WompMesh(geometry, objRef.matrix);
    }
  } else {
    console.warn(`can't find object ${objRef.objectId}` );
  }

  return new WompMesh();
}

export function getBrepFromRef(calcOrScene: any, objRef: WompObjectRef) {
  let scene;
  if (calcOrScene.objectType === ObjectTypes.Scene) {
    scene = calcOrScene;
  } else if (calcOrScene.objectType === ObjectTypes.Calc) {
    scene = calcOrScene.scene;
  }
  let obj = scene.getRegisteredObject(objRef.objectId);
  if (obj) {
    if (obj.type === ObjectTypes.BrepGeometry) {
      return new WompBrep(obj.value, objRef.matrix);
    }
  } else {
    console.warn(`can't find object ${objRef.objectId}` );
  }

  return new WompBrep();
}

export function getCurveFromRef(calcOrScene: any, objRef: WompObjectRef) {
  let scene;
  if (calcOrScene.objectType === ObjectTypes.Scene) {
    scene = calcOrScene;
  } else if (calcOrScene.objectType === ObjectTypes.Calc) {
    scene = calcOrScene.scene;
  }
  let obj = scene.getRegisteredObject(objRef.objectId);
  if (obj) {
    if (obj.type === ObjectTypes.CurveGeometry) {
      return new WompCurve(obj.value, objRef.matrix);
    }
  } else {
    console.warn(`can't find object ${objRef.objectId}` );
  }

  return new WompCurve();
}

export function getDescriptionFromObjectId(calc: any, objectId: string) {
  let obj = calc.scene.getRegisteredObject(objectId);

  return obj.uniqueDesc;
}