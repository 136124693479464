import {
  ADD_PROCESSORS,
  IEntityProcessorsState,
  ProcessorsActionTypes,
  REMOVE_PROCESSORS,
  REPLACE_PROCESSORS
} from '../../types/entity/processors';
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';

const initialState: IEntityProcessorsState = {
  byId: {},
  allIds: []
};

export function entityProcessorsReducer(
  state = initialState,
  action: ProcessorsActionTypes
): IEntityProcessorsState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case REPLACE_PROCESSORS: {
      return {
        byId: lod.keyBy(action.payload.processors, 'id'),
        allIds: lod.map(action.payload.processors, 'id')
      };
    }
    case ADD_PROCESSORS: {
      return {
        byId: {
          ...state.byId,
          ...lod.keyBy(action.payload.processors, 'id')
        },
        allIds: [
          ...state.allIds,
          ...lod.map(action.payload.processors, 'id').filter(id => !state.byId[id])
        ]
      };
    }
    case REMOVE_PROCESSORS: {
      return {
        byId: lod.omit(state.byId, action.payload.ids),
        allIds: state.allIds.filter(id => !action.payload.ids.includes(id))
      };
    }
    default:
      return state;
  }
}