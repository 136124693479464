import {MeshCodec, SculptMeshCodec} from "../types";
import {ObjectTypes, RenderedObjectTypes} from "./xobject";
import {s3RootPath} from "../../api/const";
import lod from "lodash";
import {BetaSceneVersion, InvalidId} from "./const";
import {ComponentTypes} from "./component/types";
import {_b, _c, _m, _n, _s, _t, _v} from "../t";
import {WompBrep, WompObjectRef} from "../WompObject";
import {mat4, vec3} from "gl-matrix";

export enum Tools {
  Gumball = 'gumball',
  Snap = 'snap',
  Measure = 'measure',
  Orient = 'orient',
  Align = 'align',
  Annotate = 'annotate',
  Spline = 'spline',
  Polyline = 'polyline',
  Array = 'array',
  Mirror = 'mirror',
  Sculpt = 'sculpt',
  Magnet = 'magnet',

  // One-time Tools
  Focus = 'focus',
  Drop = 'drop',
  Center = 'center',
}

export const SelectionTools: string[] = [Tools.Gumball, Tools.Snap, Tools.Orient, Tools.Align, Tools.Array, Tools.Sculpt];
export const NonSelectionTools: string[] = [Tools.Measure, Tools.Annotate, Tools.Spline, Tools.Polyline];
export const ExclusiveTools: string[] = [Tools.Array, Tools.Mirror];
export const RequirePreselectionTools: string[] = [Tools.Array, Tools.Sculpt, Tools.Mirror, Tools.Align, Tools.Orient, Tools.Magnet];

export const SETTING_TYPE_NUMBER = 'number';
export const SETTING_TYPE_BOOLEAN = 'boolean';
export const SETTING_TYPE_STRING = 'string';
export const SETTING_TYPE_INDEX = 'index';

export interface ICalcCommonString {
  id: string
  label: string
  interfaceType: string
}

export interface ICalcNumberSetting extends ICalcCommonString {
  type: typeof SETTING_TYPE_NUMBER
  min: number
  max: number
  value: number
  lockMin: boolean
  lockMax: boolean
  valueType: string
  step: number
}

export interface ICalcBooleanSetting extends ICalcCommonString {
  type: typeof SETTING_TYPE_BOOLEAN
  value: boolean
}

export interface ICalcStringSetting extends ICalcCommonString {
  type: typeof SETTING_TYPE_STRING
  value: string
}

export interface ICalcIndexSetting extends ICalcCommonString {
  type: typeof SETTING_TYPE_INDEX
  value: string
  list: { [id: string]: string }
}

export type ICalcSetting = ICalcNumberSetting | ICalcBooleanSetting | ICalcStringSetting | ICalcIndexSetting

export interface ICalcSettingSubGroup {
  label: string
  settings: ICalcSetting[]
}

export interface ICalc {
  id: string
  prevCalcs: { [key: string]: ICalc }
  prevCalcIds: string[]
  nextCalcIds: string[]
  detailInfo: IModelInfo | null
  title: string
  component: string
  searchContent: string
  settingSubGroups: ICalcSettingSubGroup[]
  material: IEitherMaterial
  objects: IRenderedObject[]
  preview: string
  visible: boolean
  collapsed: boolean
  checked: boolean
  heatmap: boolean
  hasHeatmapData: boolean
  boundingBox: boolean
  voided: boolean
  global: boolean
  locked: boolean
  internal: boolean
  printable: boolean
  settingGroupExpanded: { [key: number]: 1 }
  faceCnt: number
  vertexCnt: number
}

export enum ViewTypes {
  None = 0,
  Wireframe = 1,
  Shaded = 2,
  Rendered = 3,
  Ghosted = 4,
  Matcap = 5,
  // Raytraced = 6,
  ServerRendered = 7,
}

export interface ISceneAnalysisInfo {
  faceCount: number
  vertexCount: number
  renderFaceCount: number
  renderVertexCount: number
  renderedObjCount: number
  visibleFaceCount: number
  visibleVertexCount: number
  visibleObjCount: number
  calcCount: number
}

export interface IBox {
  points: vec3[]
}

export interface IBoundingBox {
  minMaxPoints: vec3[]
}

export interface IModelGeometryInfo {
  minBoundingBox: IBox
  boundingBox: IBoundingBox
}

export interface IModelMetaInfo {
  sculptTransform?: mat4
  orgCenter: vec3
  position: vec3
  scale: vec3
  localSize: vec3
  globalSize: vec3
  rotate: vec3
  translate: vec3
  skew: vec3
  holder: boolean
  fake: boolean
}

export interface ISculptBrushConfig {
  radius: number
  intensity: number
  negative: boolean
  clay: boolean
  culling: boolean
  accumulate: boolean
  idAlpha: string
  lockPosition: boolean
}

export interface ISculptInflateConfig {
  radius: number
  intensity: number
  negative: boolean
  culling: boolean
  idAlpha: string
  lockPosition: boolean
}

export interface ISculptTwistConfig {
  radius: number
  culling: boolean
  idAlpha: string
}

export interface ISculptSmoothConfig {
  radius: number
  intensity: number
  culling: boolean
  tangent: boolean
  idAlpha: string
  lockPosition: boolean
}

export interface ISculptFlattenConfig {
  radius: number
  intensity: number
  negative: boolean
  culling: boolean
  idAlpha: string
  lockPosition: boolean
}

export interface ISculptPinchConfig {
  radius: number
  intensity: number
  negative: boolean
  culling: boolean
  idAlpha: string
  lockPosition: boolean
}

export interface ISculptCreaseConfig {
  radius: number
  intensity: number
  negative: boolean
  culling: boolean
  idAlpha: string
  lockPosition: boolean
}

export interface ISculptDragConfig {
  radius: number
  idAlpha: string
}

export interface ISculptMoveConfig {
  radius: number
  intensity: number
  topoCheck: boolean
  alongNormal: boolean
  idAlpha: string
}

export interface ISculptMaskingConfig {
  radius: number
  hardness: number
  intensity: number
  thickness: number
  negative: boolean
  culling: boolean
  idAlpha: string
  lockPosition: boolean
  clear: boolean
  invert: boolean
  extract: boolean
  extractRemaining: boolean
  blur: boolean
  sharpen: boolean
}

export interface ISculptLocalScaleConfig {
  radius: number
  culling: boolean
  idAlpha: string
}

export interface ISculptTopologyConfig {
  resolution: number
  block: boolean
  smoothing: boolean
  dynamicTopology: boolean
  mirror: boolean
  remesh: boolean
  remeshRemaining: boolean
  voxelRemesh: boolean
  voxelRemeshRemaining: boolean
  fillHole: boolean
  smooth: boolean
}

export interface ISculptRenderingConfig {
  shader: number
  showMatcapImage: boolean
  matcapImage: number
  transparency: number
  flatShading: boolean
  symmetricPlane: boolean
  wireframe: boolean
}

export interface ISculptToolConfig {
  sculptTool: number
  symmetry: boolean
  symmetryPlaneX: boolean
  symmetryPlaneY: boolean
  symmetryPlaneZ: boolean
  changeSymmetryPlane: boolean
  continuous: boolean
  canBeContinuous: boolean
  cameraTargetOnPick: boolean

  brush: ISculptBrushConfig
  inflate: ISculptInflateConfig
  twist: ISculptTwistConfig
  smooth: ISculptSmoothConfig
  flatten: ISculptFlattenConfig
  pinch: ISculptPinchConfig
  crease: ISculptCreaseConfig
  drag: ISculptDragConfig
  move: ISculptMoveConfig
  masking: ISculptMaskingConfig
  localScale: ISculptLocalScaleConfig
}

export interface ISculptConfig {
  tool: ISculptToolConfig
  topology: ISculptTopologyConfig
  rendering: ISculptRenderingConfig
}

export interface ISnapConfig {
  face: boolean
  vertex: boolean
  box: boolean
  grid: boolean
}

export interface IGumballConfig {
  snapToGrid: boolean
  snapToObjects: boolean
}

export interface IAnnotateConfig {
  color: string
}

export interface IMeasureConfig {
  color: string
}

export interface IToolConfig {
  gumball: IGumballConfig
  snap: ISnapConfig
  sculpt: ISculptConfig
  annotate: IAnnotateConfig
  measure: IMeasureConfig
}

export enum MeasureUnit {
  Inch = 'in',
  Milli = 'mm'
}

export interface IScene {
  projectId: number
  calcIds: string[]
  calcs: { [key: string]: ICalc }
  selectedCalcIds: string[]
  measures: { [key: number]: IMeasure }
  annotates: { [key: number]: IAnnotate }
  floor: IFloor
  magnetMappings: { [key: string]: string }
  cameraInfo: ICameraInfo | null
  cameraAngle: number
  lightEnvironment: IEnvironment
  backgroundEnvironment: IEnvironment
  isSetAsBackground: boolean
  lights: { [key: number]: ILight }
  editLevels: string[]
  tool: string
  toolConfig: IToolConfig
  version: string
  viewType: number
  measureUnit: MeasureUnit
  analysisInfo: ISceneAnalysisInfo
}

export interface IModelProperty {
  hash: string
  modelType: ObjectTypes
  material: IEitherMaterial
  voided: boolean
  valid: boolean
  weldAngle: number
}

export interface IRenderedObject {
  valid: boolean
}

export enum SculptPointAction {
  Start,
  End,
  Update,
  UpdateContinuous
}

export interface ISculptSymmetryInfo {
  normal: number[]
  offset: number
}

export interface ISculptStroke {
  id: string
  config?: ISculptConfig
  width: number
  height: number
  projection: number[]
  xs: number[]
  ys: number[]
  pressures: number[]
  symmetryInfos: ISculptSymmetryInfo[]
  actions: SculptPointAction[]
}

export interface IRenderedFullObject {
  mesh: any
  edge?: any
  brep?: WompBrep
  type: RenderedObjectTypes
  meshRef?: WompObjectRef
  edgeRef?: WompObjectRef
  brepRef?: WompObjectRef
  property: IModelProperty
  strokes?: ISculptStroke[]
}

export interface IParameterProperty {
  hash: string
  gumball?: boolean
  voided?: boolean
  material?: IEitherMaterial
}

export interface ICalcOutputConfig {
  inputProperties: {
    [id: string]: IParameterProperty[]
  }
  material?: IEitherMaterial
  voided?: boolean
}

export interface ICalcInputConfig {
  prevIds: string[]
  outputProperties: {
    [key: string]: IParameterProperty[]
  }
}

export interface ICalcConfigDiff {
  obj?: boolean
  gumball?: boolean
  prevVoided?: boolean
  prevMaterial?: boolean
  calcVoided?: boolean
  calcMaterial?: boolean
}

export interface ICodecConfig {
  threeFormat: MeshCodec
  sculptFormat: SculptMeshCodec
  exclude: any
  include: string[]
}

export interface IHeatmapData {
  sourceObjectId: string
  scale: [number, number, number]
  skew: [number, number, number]
  objectId: string
}

export interface IPointOnMesh {
  calcId: string
  meshIndex: number
  position: [number, number, number]
}

export interface IMeasure {
  id: number
  start: IPointOnMesh
  end: IPointOnMesh
  mainAxis: 'x' | 'y' | 'z'
  subAxis: 'x' | 'y' | 'z'
  distance: number
  offDistance: number
  visible: boolean
}

export interface IAnnotate {
  id: number
  start: IPointOnMesh
  offset: [number, number, number]
  text: string
  font: string
  height: number
  color: string
  styles: { [key: string]: string }
  visible: boolean
  collapsed: boolean
}

export interface IFloor {
  square: number
  minSquare: number
  maxSquare: number
  length: number
  minLength: number
  maxLength: number
  width: number
  minWidth: number
  maxWidth: number
  snapIncrement: number
  opacity: number
  collapsed: boolean
  visible: boolean
  gridVisible: boolean
  showUnits: boolean
  settingGroupExpanded: { [key: number]: 1 }
}

export interface ICameraInfo {
  position: [number, number, number]
  target: [number, number, number]
  zoom: number
  width: number
  height: number
}

export enum MaterialTypes {
  MaterialGroup = 'material group',
  DigitalMaterial = 'digital material',
}

export interface IEitherMaterial {
  id: string
  type: string
}

export enum EnvMapTypes {
  None = -1,
  Lighting = 0,
  Color = 1,
  Image = 2,
}

export interface IEnvironment {
  id: string
  title: string
  type: number
  hdrThumbnail: string
  thumbnail: string
  image: string
  color: string
  exposure: number
}

export interface ISceneEnvironmentInfo {
  lightEnvironment: IEnvironment
  backgroundEnvironment: IEnvironment
  isSetAsBackground: boolean
}

export interface IModelPricing {
  baseCost: number
  productionCost: number
  markupCost: number
  taxCost: number
  shipping1Cost: number
  shipping2Cost: number
  cost: number
  manuDays: number
  shipping1Days: number
  shipping2Days: number
  days: number
  options: string[]
  shortOptions: []
  materialId?: number
}

export interface IModelInfo {
  materialPricings: { [id: number]: IModelPricing[] }
}

export interface ILightPrototype {
  id: string
  type: number
}

export interface IBasicLight {
  color: number
  intensity: number
}

export interface IAmbientLight extends IBasicLight {
}

export interface IDirectionalLight extends IBasicLight {
  castShadow: boolean
  position: [number, number, number]
  targetPosition: [number, number, number]
  width: number
  height: number
}

export interface IHemisphereLight extends IBasicLight {
  groundColor: number
  position: [number, number, number]
}

export interface IPointLight extends IBasicLight {
  castShadow: boolean
  decay: number
  distance: number
  position: [number, number, number]
}

export interface IRectAreaLight extends IBasicLight {
  width: number
  height: number
  position: [number, number, number]
  targetPosition: [number, number, number]
}

export interface ISpotLight extends IBasicLight {
  castShadow: boolean
  angle: number
  decay: number
  distance: number
  penumbra: number
  position: [number, number, number]
  targetPosition: [number, number, number]
}

export type ILightEntity = IAmbientLight
  | IDirectionalLight
  | IHemisphereLight
  | IPointLight
  | IRectAreaLight
  | ISpotLight

export enum LightTypes {
  Ambient = 0,
  Directional = 1,
  Hemisphere = 2,
  Point = 3,
  RectArea = 4,
  Spot = 5
}

export interface ILight {
  id: number
  type: number
  visible: boolean
  helperVisible: boolean
  collapsed: boolean
  settingGroupExpanded: { [key: number]: 1 }

  light: ILightEntity
}

export function createDefaultAmbientLight(): IAmbientLight {
  return {
    color: 0xffffff,
    intensity: 0.1
  };
}

export function createDefaultDirectionalLight(): IDirectionalLight {
  return {
    color: 0xffffff,
    intensity: 0.1,
    castShadow: true,
    position: [100, 0, 0],
    targetPosition: [0, 0, 0],
    width: 100,
    height: 100
  };
}

export function createDefaultHemisphereLight(): IHemisphereLight {
  return {
    color: 0x20CCF0,
    intensity: 0.1,
    groundColor: 0xD87418,
    position: [0, 0, 0]
  };
}

export function createDefaultPointLight(): IPointLight {
  return {
    color: 0xffffff,
    intensity: 0.1,
    castShadow: true,
    decay: 1,
    distance: 0,
    position: [0, 0, 100]
  };
}

export function createDefaultRectAreaLight(): IRectAreaLight {
  return {
    color: 0xffffff,
    intensity: 0.1,
    width: 10,
    height: 10,
    position: [0, 100, 0],
    targetPosition: [0, 0, 0]
  };
}

export function createDefaultSpotLight(): ISpotLight {
  return {
    color: 0xffffff,
    intensity: 0.1,
    distance: 0,
    angle: Math.PI / 3,
    castShadow: true,
    decay: 1,
    penumbra: 0.0,
    position: [100, 100, 100],
    targetPosition: [0, 0, 0]
  };
}

export function createDefaultLight(type: number): ILight {
  let result: ILight = {
    id: 1,
    type: type,
    visible: true,
    helperVisible: true,
    collapsed: true,
    settingGroupExpanded: {},
    light: createDefaultAmbientLight()
  };
  switch (type) {
    case LightTypes.Ambient: {
      result.light = createDefaultAmbientLight();
      break;
    }
    case LightTypes.Directional: {
      result.light = createDefaultDirectionalLight();
      break;
    }
    case LightTypes.Hemisphere: {
      result.light = createDefaultHemisphereLight();
      break;
    }
    case LightTypes.Point: {
      result.light = createDefaultPointLight();
      break;
    }
    case LightTypes.RectArea: {
      result.light = createDefaultRectAreaLight();
      break;
    }
    case LightTypes.Spot: {
      result.light = createDefaultSpotLight();
    }
  }

  return result;
}

export const defaultEitherMaterial: IEitherMaterial = {
  id: '',
  type: MaterialTypes.DigitalMaterial
};

export const defaultFloor: IFloor = {
  length: 500,
  width: 500,
  snapIncrement: 1.0,
  minLength: 0,
  maxLength: 1000,
  minWidth: 0,
  maxWidth: 1000,
  square: 1.0,
  minSquare: 0,
  maxSquare: 250,
  opacity: 25,
  visible: true,
  gridVisible: true,
  collapsed: true,
  showUnits: false,
  settingGroupExpanded: {}
};

export const defaultEnvironment: IEnvironment = {
  id: '',
  type: EnvMapTypes.Color,
  title: 'default',
  image: `${s3RootPath}/public/images/default.png`,
  thumbnail: `${s3RootPath}/public/images/default.png`,
  hdrThumbnail: `${s3RootPath}/public/images/default.png`,
  color: '#F0F0F0',
  exposure: 1
};

export const defaultLightEnvironment: IEnvironment = {
  id: '',
  type: EnvMapTypes.Lighting,
  title: 'default',
  image: `${s3RootPath}/public/hdri/default.hdr`,
  hdrThumbnail: `${s3RootPath}/public/hdri/ht_default.hdr`,
  thumbnail: `${s3RootPath}/public/thumbnails/default.png`,
  color: '#F0F0F0',
  exposure: 1
};

export const defaultSceneAnalysisInfo: ISceneAnalysisInfo = {
  faceCount: 0,
  vertexCount: 0,
  renderFaceCount: 0,
  renderVertexCount: 0,
  renderedObjCount: 0,
  visibleFaceCount: 0,
  visibleVertexCount: 0,
  visibleObjCount: 0,
  calcCount: 0
};

export const defaultSculptBrushConfig: ISculptBrushConfig = {
  radius: 50,
  intensity: 0.5,
  negative: false,
  clay: true,
  culling: false,
  accumulate: true,
  idAlpha: 'none',
  lockPosition: false
};

export const defaultSculptInflateConfig: ISculptInflateConfig = {
  radius: 50,
  intensity: 0.3,
  negative: false,
  culling: false,
  idAlpha: 'none',
  lockPosition: false
};

export const defaultSculptTwistConfig: ISculptTwistConfig = {
  radius: 75,
  culling: false,
  idAlpha: 'none'
};

export const defaultSculptSmoothConfig: ISculptSmoothConfig = {
  radius: 50,
  intensity: 0.75,
  culling: false,
  tangent: false,
  idAlpha: 'none',
  lockPosition: false
};

export const defaultSculptFlattenConfig: ISculptFlattenConfig = {
  radius: 50,
  intensity: 0.75,
  negative: true,
  culling: false,
  idAlpha: 'none',
  lockPosition: false
};

export const defaultSculptPinchConfig: ISculptPinchConfig = {
  radius: 50,
  intensity: 0.75,
  negative: false,
  culling: false,
  idAlpha: 'none',
  lockPosition: false
};

export const defaultSculptCreaseConfig: ISculptCreaseConfig = {
  radius: 25,
  intensity: 0.75,
  negative: true,
  culling: false,
  idAlpha: 'none',
  lockPosition: false
};

export const defaultSculptDragConfig: ISculptDragConfig = {
  radius: 150,
  idAlpha: 'none'
};

export const defaultSculptMoveConfig: ISculptMoveConfig = {
  radius: 150,
  intensity: 1.0,
  topoCheck: true,
  alongNormal: false,
  idAlpha: 'none'
};

export const defaultSculptMaskingConfig: ISculptMaskingConfig = {
  radius: 50,
  hardness: 0.25,
  intensity: 0.5,
  negative: true,
  culling: false,
  idAlpha: 'none',
  lockPosition: false,
  thickness: 0.0,
  clear: false,
  invert: false,
  extract: false,
  extractRemaining: false,
  blur: false,
  sharpen: false
};

export const defaultSculptLocalScaleConfig: ISculptLocalScaleConfig = {
  radius: 50,
  culling: false,
  idAlpha: 'none'
};

export const defaultSculptToolConfig: ISculptToolConfig = {
  sculptTool: 7,
  symmetry: true,
  symmetryPlaneX: false,
  symmetryPlaneY: false,
  symmetryPlaneZ: false,
  changeSymmetryPlane: false,
  continuous: false,
  canBeContinuous: false,
  cameraTargetOnPick: false,
  brush: defaultSculptBrushConfig,
  inflate: defaultSculptInflateConfig,
  twist: defaultSculptTwistConfig,
  smooth: defaultSculptSmoothConfig,
  flatten: defaultSculptFlattenConfig,
  pinch: defaultSculptPinchConfig,
  crease: defaultSculptCreaseConfig,
  drag: defaultSculptDragConfig,
  move: defaultSculptMoveConfig,
  masking: defaultSculptMaskingConfig,
  localScale: defaultSculptLocalScaleConfig
};

export const defaultSculptRenderingConfig: ISculptRenderingConfig = {
  shader: 0,
  showMatcapImage: true,
  matcapImage: 0,
  transparency: 0,
  flatShading: false,
  wireframe: false,
  symmetricPlane: false
};

export const defaultSculptTopologyConfig: ISculptTopologyConfig = {
  resolution: 100,
  block: false,
  smoothing: true,
  dynamicTopology: true,
  mirror: false,
  remesh: false,
  remeshRemaining: false,
  voxelRemesh: false,
  voxelRemeshRemaining: false,
  fillHole: false,
  smooth: false,
};

export const defaultSculptConfig: ISculptConfig = {
  tool: defaultSculptToolConfig,
  rendering: defaultSculptRenderingConfig,
  topology: defaultSculptTopologyConfig
};

export const defaultGumballConfig: IGumballConfig = {
  snapToObjects: true,
  snapToGrid: false
};

export const defaultSnapConfig: ISnapConfig = {
  face: true,
  vertex: true,
  box: true,
  grid: true
};

export const defaultAnnotateConfig: IAnnotateConfig = {
  color: '#000000'
};

export const defaultMeasureConfig: IMeasureConfig = {
  color: '#000000'
};

export const defaultToolConfig: IToolConfig = {
  gumball: defaultGumballConfig,
  snap: defaultSnapConfig,
  sculpt: defaultSculptConfig,
  annotate: defaultAnnotateConfig,
  measure: defaultMeasureConfig
};

export const defaultScene: IScene = {
  projectId: 0,
  calcs: {},
  calcIds: [],
  selectedCalcIds: [],
  cameraInfo: null,
  cameraAngle: 10,
  annotates: {},
  measures: {},
  magnetMappings: {},
  floor: defaultFloor,
  lightEnvironment: defaultLightEnvironment,
  backgroundEnvironment: defaultEnvironment,
  isSetAsBackground: false,
  lights: {},
  editLevels: [],
  tool: Tools.Gumball,
  toolConfig: defaultToolConfig,
  version: BetaSceneVersion,
  viewType: ViewTypes.Rendered,
  measureUnit: MeasureUnit.Milli,
  analysisInfo: defaultSceneAnalysisInfo
};

export const defaultMetaInfo: IModelMetaInfo = {
  orgCenter: vec3.create(),
  position: vec3.create(),
  scale: vec3.fromValues(1, 1, 1),
  localSize: vec3.create(),
  globalSize: vec3.create(),
  rotate: vec3.create(),
  translate: vec3.create(),
  skew: vec3.create(),
  holder: false,
  fake: true
};

export const defaultCalcState: ICalc = {
  id: InvalidId,
  prevCalcs: {},
  prevCalcIds: [],
  nextCalcIds: [],
  detailInfo: null,
  title: '',
  searchContent: '',
  component: ComponentTypes.None,
  settingSubGroups: [],
  material: defaultEitherMaterial,
  objects: [],
  preview: '',
  visible: true,
  collapsed: true,
  checked: false,
  heatmap: false,
  hasHeatmapData: false,
  boundingBox: false,
  voided: false,
  global: true,
  locked: false,
  internal: false,
  printable: true,
  settingGroupExpanded: {},
  vertexCnt: 0,
  faceCnt: 0
};

export const defaultCodecConfig: ICodecConfig = {
  threeFormat: MeshCodec.Json,
  sculptFormat: SculptMeshCodec.Json,
  exclude: {},
  include: []
};

export function encodeEitherMaterial(eitherMaterial: IEitherMaterial) {
  return {
    [_s('id')]: eitherMaterial.id,
    [_s('type')]: _s(eitherMaterial.type)
  };
}

export function decodeEitherMaterial(jData: any): IEitherMaterial {
  return {
    id: jData[_s('id')],
    type: _t(jData[_s('type')])
  };
}

export function encodeCameraInfo(cameraInfo: ICameraInfo) {
  return {
    [_s('position')]: cameraInfo.position.map(v => _n(v)),
    [_s('target')]: cameraInfo.target.map(v => _n(v)),
    [_s('zoom')]: _n(cameraInfo.zoom),
    [_s('width')]: _n(cameraInfo.width),
    [_s('height')]: _n(cameraInfo.height)
  };
}

export function decodeCameraInfo(jData: any): ICameraInfo {
  return {
    position: jData[_s('position')].map(_m),
    target: jData[_s('target')].map(_m),
    zoom: _m(jData[_s('zoom')]),
    width: _m(jData[_s('width')]),
    height: _m(jData[_s('height')])
  };
}

export function encodePointOnCalc(pt: IPointOnMesh) {
  return {
    [_s('calcId')]: pt.calcId,
    [_s('meshIndex')]: pt.meshIndex,
    [_s('position')]: pt.position.map(v => _n(v))
  };
}

export function decodePointOnCalc(jData: any): IPointOnMesh {
  return {
    calcId: jData[_s('calcId')],
    meshIndex: jData[_s('meshIndex')],
    position: jData[_s('position')].map(_m)
  };
}

export function encodeMeasure(measure: IMeasure) {
  return {
    [_s('id')]: measure.id,
    [_s('start')]: encodePointOnCalc(measure.start),
    [_s('end')]: encodePointOnCalc(measure.end),
    [_s('mainAxis')]: measure.mainAxis,
    [_s('subAxis')]: measure.subAxis,
    [_s('distance')]: _n(measure.distance),
    [_s('offDistance')]: _n(measure.offDistance),
    [_s('visible')]: _b(measure.visible)
  };
}

export function decodeMeasure(jData: any): IMeasure {
  return {
    id: jData[_s('id')],
    start: decodePointOnCalc(jData[_s('start')]),
    end: decodePointOnCalc(jData[_s('end')]),
    mainAxis: jData[_s('mainAxis')],
    subAxis: jData[_s('subAxis')],
    distance: _m(jData[_s('distance')]),
    offDistance: _m(jData[_s('offDistance')]),
    visible: _c(jData[_s('visible')]),
  };
}

export function encodeAnnotate(annotate: IAnnotate) {
  return {
    [_s('id')]: annotate.id,
    [_s('start')]: encodePointOnCalc(annotate.start),
    [_s('offset')]: annotate.offset.map(v => _n(v)),
    [_s('text')]: annotate.text,
    [_s('font')]: annotate.font,
    [_s('height')]: annotate.height,
    [_s('color')]: annotate.color,
    [_s('styles')]: annotate.styles,
    [_s('visible')]: _b(annotate.visible),
    [_s('collapsed')]: _b(annotate.collapsed)
  };
}

export function decodeAnnotate(jData: any): IAnnotate {
  return {
    id: jData[_s('id')],
    start: decodePointOnCalc(jData[_s('start')]),
    offset: jData[_s('offset')].map(_m),
    text: jData[_s('text')],
    font: jData[_s('font')],
    height: jData[_s('height')],
    color: jData[_s('color')],
    styles: jData[_s('styles')],
    visible: _c(jData[_s('visible')]),
    collapsed: _c(jData[_s('collapsed')])
  };
}


export function encodeFloor(floor: IFloor) {
  return {
    [_s('square')]: _n(floor.square),
    [_s('minSquare')]: _n(floor.minSquare),
    [_s('maxSquare')]: _n(floor.maxSquare),
    [_s('length')]: _n(floor.length),
    [_s('minLength')]: _n(floor.minLength),
    [_s('maxLength')]: _n(floor.maxLength),
    [_s('width')]: _n(floor.width),
    [_s('minWidth')]: _n(floor.minWidth),
    [_s('maxWidth')]: _n(floor.maxWidth),
    [_s('snapIncrement')]: _n(floor.snapIncrement),
    [_s('opacity')]: _n(floor.opacity),
    [_s('collapsed')]: _b(floor.collapsed),
    [_s('visible')]: _b(floor.visible),
    [_s('gridVisible')]: _b(floor.gridVisible),
    [_s('showUnits')]: _b(floor.showUnits),
    [_s('settingGroupExpanded')]: floor.settingGroupExpanded
  };
}

export function decodeFloor(jData: any): IFloor {
  return {
    square: _m(jData[_s('square')]),
    minSquare: _m(jData[_s('minSquare')]),
    maxSquare: _m(jData[_s('maxSquare')]),
    length: _m(jData[_s('length')]),
    minLength: _m(jData[_s('minLength')]),
    maxLength: _m(jData[_s('maxLength')]),
    width: _m(jData[_s('width')]),
    minWidth: _m(jData[_s('minWidth')]),
    maxWidth: _m(jData[_s('maxWidth')]),
    snapIncrement: _m(jData[_s('snapIncrement')]),
    opacity: _m(jData[_s('opacity')]),
    collapsed: _c(jData[_s('collapsed')]),
    visible: _c(jData[_s('visible')]),
    gridVisible: _c(jData[_s('gridVisible')]),
    showUnits: _c(jData[_s('showUnits')]),
    settingGroupExpanded: jData[_s('settingGroupExpanded')]
  };
}

export function encodeEnvironment(environment: IEnvironment) {
  return {
    [_s('id')]: environment.id,
    [_s('title')]: environment.title,
    [_s('type')]: environment.type,
    [_s('hdrThumbnail')]: environment.hdrThumbnail.replace(`${s3RootPath}/public/`, ''),
    [_s('thumbnail')]: environment.thumbnail.replace(`${s3RootPath}/public/`, ''),
    [_s('image')]: environment.image.replace(`${s3RootPath}/public/`, ''),
    [_s('color')]: environment.color,
    [_s('exposure')]: _n(environment.exposure)
  };
}

export function decodeEnvironment(jData: any): IEnvironment {
  return {
    id: jData[_s('id')],
    title: jData[_s('title')],
    type: jData[_s('type')],
    thumbnail: jData[_s('thumbnail')] ? `${s3RootPath}/public/${jData[_s('thumbnail')]}` : '',
    hdrThumbnail: jData[_s('hdrThumbnail')] ? `${s3RootPath}/public/${jData[_s('hdrThumbnail')]}` : '',
    image: jData[_s('image')] ? `${s3RootPath}/public/${jData[_s('image')]}` : '',
    color: jData[_s('color')],
    exposure: _m(jData[_s('exposure')])
  };
}

export function encodeLight(light: any) {
  return {
    [_s('id')]: light.id,
    [_s('type')]: light.type,
    [_s('visible')]: _b(light.visible),
    [_s('helperVisible')]: _b(light.helperVisible),
    [_s('collapsed')]: _b(light.collapsed),
    [_s('settingGroupExpanded')]: light.settingGroupExpanded,
    [_s('light')]: lod.pickBy({
      [_s('color')]: light.light.color,
      [_s('intensity')]: light.light.intensity !== undefined ? _n(light.light.intensity) : undefined,
      [_s('castShadow')]: light.light.castShadow !== undefined ? _b(light.light.castShadow) : undefined,
      [_s('position')]: light.light.position !== undefined ? light.light.position.map((v: number) => _n(v)) : undefined,
      [_s('targetPosition')]: light.light.targetPosition !== undefined ? light.light.targetPosition.map((v: number) => _n(v)) : undefined,
      [_s('width')]: light.light.width !== undefined ? _n(light.light.width) : undefined,
      [_s('height')]: light.light.height !== undefined ? _n(light.light.height) : undefined,
      [_s('groundColor')]: light.light.groundColor,
      [_s('decay')]: light.light.decay !== undefined ? _n(light.light.decay) : undefined,
      [_s('distance')]: light.light.distance !== undefined ? _n(light.light.distance) : undefined,
      [_s('angle')]: light.light.angle !== undefined ? _n(light.light.angle) : undefined,
      [_s('penumbra')]: light.light.penumbra !== undefined ? _n(light.light.penumbra) : undefined
    }, v => v !== undefined)
  };
}

export function decodeLight(jData: any): ILight {
  return {
    id: jData[_s('id')],
    type: jData[_s('type')],
    visible: _c(jData[_s('visible')]),
    helperVisible: _c(jData[_s('helperVisible')]),
    collapsed: _c(jData[_s('collapsed')]),
    settingGroupExpanded: jData[_s('settingGroupExpanded')],
    light: lod.pickBy({
      color: jData[_s('light')][_s('color')],
      intensity: jData[_s('light')][_s('intensity')] !== undefined ? _m(jData[_s('light')][_s('intensity')]) : undefined,
      castShadow: jData[_s('light')][_s('castShadow')] !== undefined ? _c(jData[_s('light')][_s('castShadow')]) : undefined,
      position: jData[_s('light')][_s('position')] !== undefined ? jData[_s('light')][_s('position')].map(_m) : undefined,
      targetPosition: jData[_s('light')][_s('targetPosition')] !== undefined ? jData[_s('light')][_s('targetPosition')].map(_m) : undefined,
      width: jData[_s('light')][_s('width')] !== undefined ? _m(jData[_s('light')][_s('width')]) : undefined,
      height: jData[_s('light')][_s('height')] !== undefined ? _m(jData[_s('light')][_s('height')]) : undefined,
      groundColor: jData[_s('light')][_s('groundColor')],
      decay: jData[_s('light')][_s('decay')] !== undefined ? _m(jData[_s('light')][_s('decay')]) : undefined,
      distance: jData[_s('light')][_s('distance')] !== undefined ? _m(jData[_s('light')][_s('distance')]) : undefined,
      angle: jData[_s('light')][_s('angle')] !== undefined ? _m(jData[_s('light')][_s('angle')]) : undefined,
      penumbra: jData[_s('light')][_s('penumbra')] ? _m(jData[_s('light')][_s('penumbra')]) : undefined
    }, v => v !== undefined) as unknown as ILightEntity
  };
}

export function encodeHeatmapData(data: IHeatmapData) {
  return {
    [_s('sourceObjectId')]: data.sourceObjectId,
    [_s('scale')]: data.scale.map(v => _n(v)),
    [_s('skew')]: data.skew.map(v => _n(v)),
    [_s('objectId')]: data.objectId
  };
}

export function decodeHeatmapData(data: any): IHeatmapData {
  return {
    sourceObjectId: data[_s('sourceObjectId')],
    scale: data[_s('scale')].map(_m),
    skew: data[_s('skew')].map(_m),
    objectId: data[_s('objectId')]
  };
}

export function encodeModelProperty(property: IModelProperty) {
  return lod.pickBy({
    [_s('hash')]: property.hash,
    [_s('voided')]: property.voided !== undefined ? _b(property.voided) : undefined,
    [_s('valid')]: property.valid !== undefined ? _b(property.valid) : undefined,
    [_s('modelType')]: property.modelType !== undefined ? _s(property.modelType) : undefined,
    [_s('weldAngle')]: property.weldAngle !== undefined ? _n(property.weldAngle) : undefined,
    [_s('material')]: property.material !== undefined ? encodeEitherMaterial(property.material) : undefined
  }, v => v !== undefined);
}

export function decodeModelProperty(jData: any): IModelProperty {
  return {
    hash: jData[_s('hash')],
    voided: jData[_s('voided')] !== undefined ? _c(jData[_s('voided')]) : false,
    valid: jData[_s('valid')] !== undefined ? _c(jData[_s('valid')]) : true,
    modelType: jData[_s('modelType')] !== undefined ? _t(jData[_s('modelType')]) as ObjectTypes : ObjectTypes.Mesh,
    weldAngle: jData[_s('weldAngle')] !== undefined ? _m(jData[_s('weldAngle')]) : 90,
    material: jData[_s('material')] !== undefined ? decodeEitherMaterial(jData[_s('material')]) : defaultEitherMaterial
  };
}

export function encodeParameterProperty(property: IParameterProperty) {
  return lod.pickBy({
    [_s('hash')]: property.hash,
    [_s('gumball')]: property.gumball !== undefined ? _b(property.gumball) : undefined,
    [_s('voided')]: property.voided !== undefined ? _b(property.voided) : undefined,
    [_s('material')]: property.material ? encodeEitherMaterial(property.material) : undefined
  }, v => v !== undefined);
}

export function decodeParameterProperty(jData: any): IParameterProperty {
  return lod.pickBy({
    hash: jData[_s('hash')],
    gumball: jData[_s('gumball')] !== undefined ? _c(jData[_s('gumball')]) : undefined,
    voided: jData[_s('voided')] !== undefined ? _c(jData[_s('voided')]) : undefined,
    material: jData[_s('material')] ? decodeEitherMaterial(jData[_s('material')]) : undefined
  }, v => v !== undefined) as unknown as IParameterProperty;
}

export function encodeCalcInputConfig(config: ICalcInputConfig) {
  return {
    [_s('prevIds')]: config.prevIds,
    [_s('outputProperties')]: lod.mapValues(config.outputProperties, p => p.map(encodeParameterProperty))
  };
}

export function decodeCalcInputConfig(jData: any): ICalcInputConfig {
  return {
    prevIds: jData[_s('prevIds')],
    outputProperties: lod.mapValues(jData[_s('outputProperties')], p => p.map(decodeParameterProperty))
  };
}

export function encodeCalcOutputConfig(config: ICalcOutputConfig) {
  return lod.pickBy({
    [_s('material')]: config.material ? encodeEitherMaterial(config.material) : undefined,
    [_s('voided')]: config.voided !== undefined ? _b(config.voided) : undefined,
    [_s('inputProperties')]: lod.mapValues(config.inputProperties, p => p.map(encodeParameterProperty))
  }, v => v !== undefined);
}

export function decodeCalcOutputConfig(jData: any): ICalcOutputConfig {
  return lod.pickBy({
    material: jData[_s('material')] ? decodeEitherMaterial(jData[_s('material')]) : undefined,
    voided: jData[_s('voided')] !== undefined ? _c(jData[_s('voided')]) : undefined,
    inputProperties: lod.mapValues(jData[_s('inputProperties')], p => p.map(decodeParameterProperty))
  }, v => v !== undefined) as unknown as ICalcOutputConfig;
}

export function encodeModelPricing(pricing: IModelPricing) {
  return {
    [_s('baseCost')]: _n(pricing.baseCost),
    [_s('productionCost')]: _n(pricing.productionCost),
    [_s('markupCost')]: _n(pricing.markupCost),
    [_s('taxCost')]: _n(pricing.taxCost),
    [_s('shipping1Cost')]: _n(pricing.shipping1Cost),
    [_s('shipping2Cost')]: _n(pricing.shipping2Cost),
    [_s('cost')]: _n(pricing.cost),
    [_s('manuDays')]: pricing.manuDays,
    [_s('shipping1Days')]: pricing.shipping1Days,
    [_s('shipping2Days')]: pricing.shipping2Days,
    [_s('days')]: pricing.days,
    [_s('options')]: pricing.options,
    [_s('shortOptions')]: pricing.shortOptions,
    [_s('materialId')]: pricing.materialId !== undefined ? pricing.materialId : undefined
  };
}

export function decodeModelPricing(jData: any): IModelPricing {
  return {
    baseCost: _m(jData[_s('baseCost')]),
    productionCost: _m(jData[_s('productionCost')]),
    markupCost: _m(jData[_s('markupCost')]),
    taxCost: _m(jData[_s('taxCost')]),
    shipping1Cost: _m(jData[_s('shipping1Cost')]),
    shipping2Cost: _m(jData[_s('shipping2Cost')]),
    cost: _m(jData[_s('cost')]),
    manuDays: jData[_s('manuDays')],
    shipping1Days: jData[_s('shipping1Days')],
    shipping2Days: jData[_s('shipping2Days')],
    days: jData[_s('days')],
    options: jData[_s('options')],
    shortOptions: jData[_s('shortOptions')],
    materialId: jData[_s('materialId')] !== undefined ? jData[_s('materialId')] : undefined
  };
}

export function encodeCalcDetailInfo(info: IModelInfo) {
  return {
    [_s('materialPricings')]: lod.mapValues(info.materialPricings, p => p.map(encodeModelPricing))
  };
}

export function decodeCalcDetailInfo(jData: any): IModelInfo {
  return {
    materialPricings: lod.mapValues(jData[_s('materialPricings')], p => p.map(decodeModelPricing))
  };
}

export function encodeGumballConfig(gumballConfig: IGumballConfig) {
  return {
    [_s('snapToGrid')]: _b(gumballConfig.snapToGrid),
    [_s('snapToObjects')]: _b(gumballConfig.snapToObjects)
  };
}

export function decodeGumballConfig(jData: any): IGumballConfig {
  return jData ? {
    snapToGrid: _c(jData[_s('snapToGrid')]),
    snapToObjects: _c(jData[_s('snapToObjects')])
  } : defaultGumballConfig;
}

export function encodeAnnotateConfig(annotateConfig: IAnnotateConfig) {
  return {
    [_s('color')]: annotateConfig.color
  };
}

export function decodeAnnotateConfig(jData: any): IAnnotateConfig {
  return jData ? {
    color: jData[_s('color')]
  } : defaultAnnotateConfig;
}

export function encodeMeasureConfig(measureConfig: IMeasureConfig) {
  return {
    [_s('color')]: measureConfig.color
  };
}

export function decodeMeasureConfig(jData: any): IMeasureConfig {
  return jData ? {
    color: jData[_s('color')]
  } : defaultMeasureConfig;
}

export function encodeSnapConfig(snapConfig: ISnapConfig) {
  return {
    [_s('vertex')]: _b(snapConfig.vertex),
    [_s('box')]: _b(snapConfig.box),
    [_s('face')]: _b(snapConfig.face),
    [_s('grid')]: _b(snapConfig.grid)
  };
}

export function decodeSnapConfig(jData: any): ISnapConfig {
  return jData ? {
    vertex: _c(jData[_s('vertex')]),
    box: _c(jData[_s('box')]),
    face: _c(jData[_s('face')]),
    grid: _c(jData[_s('grid')])
  } : defaultSnapConfig;
}

export function encodeSculptTopologyConfig(topologyConfig: ISculptTopologyConfig) {
  return {
    [_s('resolution')]: _n(topologyConfig.resolution),
    [_s('block')]: _b(topologyConfig.block),
    [_s('smoothing')]: _b(topologyConfig.smoothing),
    [_s('dynamicTopology')]: _b(topologyConfig.dynamicTopology),
    [_s('mirror')]: _b(topologyConfig.mirror),
    [_s('remesh')]: _b(topologyConfig.remesh),
    [_s('remeshRemaining')]: _b(topologyConfig.remeshRemaining),
    [_s('voxelRemesh')]: _b(topologyConfig.voxelRemesh),
    [_s('voxelRemeshRemaining')]: _b(topologyConfig.voxelRemeshRemaining),
    [_s('fillHole')]: _b(topologyConfig.fillHole),
    [_s('smooth')]: _b(topologyConfig.smooth),
  };
}

export function decodeSculptTopologyConfig(jData: any): ISculptTopologyConfig {
  return jData ? {
    resolution: _m(jData[_s('resolution')]),
    block: _c(jData[_s('block')]),
    smoothing: _c(jData[_s('smoothing')]),
    dynamicTopology: _c(jData[_s('dynamicTopology')]),
    mirror: _c(jData[_s('mirror')]),
    remesh: _c(jData[_s('remesh')]),
    remeshRemaining: _c(jData[_s('remeshRemaining')]),
    voxelRemesh: _c(jData[_s('voxelRemesh')]),
    voxelRemeshRemaining: _c(jData[_s('voxelRemeshRemaining')]),
    fillHole: _c(jData[_s('fillHole')]),
    smooth: _c(jData[_s('smooth')])
  } : defaultSculptTopologyConfig;
}

export function encodeSculptRenderingConfig(renderingConfig: ISculptRenderingConfig) {
  return {
    [_s('shader')]: _n(renderingConfig.shader),
    [_s('showMatcapImage')]: _b(renderingConfig.showMatcapImage),
    [_s('matcapImage')]: _n(renderingConfig.matcapImage),
    [_s('transparency')]: _n(renderingConfig.transparency),
    [_s('flatShading')]: _b(renderingConfig.flatShading),
    [_s('symmetricPlane')]: _b(renderingConfig.symmetricPlane),
    [_s('wireframe')]: _b(renderingConfig.wireframe)
  };
}

export function decodeSculptRenderingConfig(jData: any): ISculptRenderingConfig {
  return jData ? {
    shader: _m(jData[_s('shader')]),
    showMatcapImage: _c(jData[_s('showMatcapImage')]),
    matcapImage: _m(jData[_s('matcapImage')]),
    transparency: _m(jData[_s('transparency')]),
    flatShading: _c(jData[_s('flatShading')]),
    symmetricPlane: _c(jData[_s('symmetricPlane')]),
    wireframe: _c(jData[_s('wireframe')])
  } : defaultSculptRenderingConfig;
}

export function encodeSculptBrushConfig(brushConfig: ISculptBrushConfig) {
  return {
    [_s('radius')]: _n(brushConfig.radius),
    [_s('intensity')]: _n(brushConfig.intensity),
    [_s('negative')]: _b(brushConfig.negative),
    [_s('clay')]: _b(brushConfig.clay),
    [_s('culling')]: _b(brushConfig.culling),
    [_s('accumulate')]: _b(brushConfig.accumulate),
    [_s('idAlpha')]: brushConfig.idAlpha,
    [_s('lockPosition')]: _b(brushConfig.lockPosition)
  };
}

export function decodeSculptBrushConfig(jData: any): ISculptBrushConfig {
  return jData ? {
    radius: _m(jData[_s('radius')]),
    intensity: _m(jData[_s('intensity')]),
    negative: _c(jData[_s('negative')]),
    clay: _c(jData[_s('clay')]),
    culling: _c(jData[_s('culling')]),
    accumulate: _c(jData[_s('accumulate')]),
    idAlpha: jData[_s('idAlpha')],
    lockPosition: _c(jData[_s('lockPosition')])
  } : defaultSculptBrushConfig;
}

export function encodeSculptInflateConfig(inflateConfig: ISculptInflateConfig) {
  return {
    [_s('radius')]: _n(inflateConfig.radius),
    [_s('intensity')]: _n(inflateConfig.intensity),
    [_s('negative')]: _b(inflateConfig.negative),
    [_s('culling')]: _b(inflateConfig.culling),
    [_s('idAlpha')]: inflateConfig.idAlpha,
    [_s('lockPosition')]: _b(inflateConfig.lockPosition)
  };
}

export function decodeSculptInflateConfig(jData: any): ISculptInflateConfig {
  return jData ? {
    radius: _m(jData[_s('radius')]),
    intensity: _m(jData[_s('intensity')]),
    negative: _c(jData[_s('negative')]),
    culling: _c(jData[_s('culling')]),
    idAlpha: jData[_s('idAlpha')],
    lockPosition: _c(jData[_s('lockPosition')])
  } : defaultSculptInflateConfig;
}

export function encodeSculptTwistConfig(twistConfig: ISculptTwistConfig) {
  return {
    [_s('radius')]: _n(twistConfig.radius),
    [_s('culling')]: _b(twistConfig.culling),
    [_s('idAlpha')]: twistConfig.idAlpha
  };
}

export function decodeSculptTwistConfig(jData: any): ISculptTwistConfig {
  return jData ? {
    radius: _m(jData[_s('radius')]),
    culling: _c(jData[_s('culling')]),
    idAlpha: jData[_s('idAlpha')]
  } : defaultSculptTwistConfig;
}

export function encodeSculptSmoothConfig(smoothConfig: ISculptSmoothConfig) {
  return {
    [_s('radius')]: _n(smoothConfig.radius),
    [_s('intensity')]: _n(smoothConfig.intensity),
    [_s('culling')]: _b(smoothConfig.culling),
    [_s('tangent')]: _b(smoothConfig.tangent),
    [_s('idAlpha')]: smoothConfig.idAlpha,
    [_s('lockPosition')]: _b(smoothConfig.lockPosition)
  };
}

export function decodeSculptSmoothConfig(jData: any): ISculptSmoothConfig {
  return jData ? {
    radius: _m(jData[_s('radius')]),
    intensity: _m(jData[_s('intensity')]),
    culling: _c(jData[_s('culling')]),
    tangent: _c(jData[_s('tangent')]),
    idAlpha: jData[_s('idAlpha')],
    lockPosition: _c(jData[_s('lockPosition')])
  } : defaultSculptSmoothConfig;
}

export function encodeSculptFlattenConfig(flattenConfig: ISculptFlattenConfig) {
  return {
    [_s('radius')]: _n(flattenConfig.radius),
    [_s('intensity')]: _n(flattenConfig.intensity),
    [_s('negative')]: _b(flattenConfig.negative),
    [_s('culling')]: _b(flattenConfig.culling),
    [_s('idAlpha')]: flattenConfig.idAlpha,
    [_s('lockPosition')]: _b(flattenConfig.lockPosition)
  };
}

export function decodeSculptFlattenConfig(jData: any): ISculptFlattenConfig {
  return jData ? {
    radius: _m(jData[_s('radius')]),
    intensity: _m(jData[_s('intensity')]),
    negative: _c(jData[_s('negative')]),
    culling: _c(jData[_s('culling')]),
    idAlpha: jData[_s('idAlpha')],
    lockPosition: _c(jData[_s('lockPosition')])
  } : defaultSculptFlattenConfig;
}

export function encodeSculptPinchConfig(pinchConfig: ISculptPinchConfig) {
  return {
    [_s('radius')]: _n(pinchConfig.radius),
    [_s('intensity')]: _n(pinchConfig.intensity),
    [_s('negative')]: _b(pinchConfig.negative),
    [_s('culling')]: _b(pinchConfig.culling),
    [_s('idAlpha')]: pinchConfig.idAlpha,
    [_s('lockPosition')]: _b(pinchConfig.lockPosition)
  };
}

export function decodeSculptPinchConfig(jData: any): ISculptPinchConfig {
  return jData ? {
    radius: _m(jData[_s('radius')]),
    intensity: _m(jData[_s('intensity')]),
    negative: _c(jData[_s('negative')]),
    culling: _c(jData[_s('culling')]),
    idAlpha: jData[_s('idAlpha')],
    lockPosition: _c(jData[_s('lockPosition')])
  } : defaultSculptPinchConfig;
}

export function encodeSculptCreaseConfig(creaseConfig: ISculptCreaseConfig) {
  return {
    [_s('radius')]: _n(creaseConfig.radius),
    [_s('intensity')]: _n(creaseConfig.intensity),
    [_s('negative')]: _b(creaseConfig.negative),
    [_s('culling')]: _b(creaseConfig.culling),
    [_s('idAlpha')]: creaseConfig.idAlpha,
    [_s('lockPosition')]: _b(creaseConfig.lockPosition)
  };
}

export function decodeSculptCreaseConfig(jData: any): ISculptCreaseConfig {
  return jData ? {
    radius: _m(jData[_s('radius')]),
    intensity: _m(jData[_s('intensity')]),
    negative: _c(jData[_s('negative')]),
    culling: _c(jData[_s('culling')]),
    idAlpha: jData[_s('idAlpha')],
    lockPosition: _c(jData[_s('lockPosition')])
  } : defaultSculptCreaseConfig;
}

export function encodeSculptDragConfig(dragConfig: ISculptDragConfig) {
  return {
    [_s('radius')]: _n(dragConfig.radius),
    [_s('idAlpha')]: dragConfig.idAlpha
  };
}

export function decodeSculptDragConfig(jData: any): ISculptDragConfig {
  return jData ? {
    radius: _m(jData[_s('radius')]),
    idAlpha: jData[_s('idAlpha')]
  } : defaultSculptDragConfig;
}

export function encodeSculptMoveConfig(moveConfig: ISculptMoveConfig) {
  return {
    [_s('radius')]: _n(moveConfig.radius),
    [_s('intensity')]: _n(moveConfig.intensity),
    [_s('topoCheck')]: _b(moveConfig.topoCheck),
    [_s('alongNormal')]: _b(moveConfig.alongNormal),
    [_s('idAlpha')]: moveConfig.idAlpha
  };
}

export function decodeSculptMoveConfig(jData: any): ISculptMoveConfig {
  return jData ? {
    radius: _m(jData[_s('radius')]),
    intensity: _m(jData[_s('intensity')]),
    topoCheck: _c(jData[_s('topoCheck')]),
    alongNormal: _c(jData[_s('alongNormal')]),
    idAlpha: jData[_s('idAlpha')]
  } : defaultSculptMoveConfig;
}

export function encodeSculptMaskingConfig(maskingConfig: ISculptMaskingConfig) {
  return {
    [_s('radius')]: _n(maskingConfig.radius),
    [_s('hardness')]: _n(maskingConfig.hardness),
    [_s('intensity')]: _n(maskingConfig.intensity),
    [_s('thickness')]: _n(maskingConfig.thickness),
    [_s('negative')]: _b(maskingConfig.negative),
    [_s('culling')]: _b(maskingConfig.culling),
    [_s('idAlpha')]: maskingConfig.idAlpha,
    [_s('lockPosition')]: _b(maskingConfig.lockPosition),
    [_s('clear')]: _b(maskingConfig.clear),
    [_s('invert')]: _b(maskingConfig.invert),
    [_s('extract')]: _b(maskingConfig.extract),
    [_s('extractRemaining')]: _b(maskingConfig.extractRemaining),
    [_s('blur')]: _b(maskingConfig.blur),
    [_s('sharpen')]: _b(maskingConfig.sharpen)
  };
}

export function decodeSculptMaskingConfig(jData: any): ISculptMaskingConfig {
  return jData ? {
    radius: _m(jData[_s('radius')]),
    hardness: _m(jData[_s('hardness')]),
    intensity: _m(jData[_s('intensity')]),
    thickness: _m(jData[_s('thickness')]),
    negative: _c(jData[_s('negative')]),
    culling: _c(jData[_s('culling')]),
    idAlpha: jData[_s('idAlpha')],
    lockPosition: _c(jData[_s('lockPosition')]),
    clear: _c(jData[_s('clear')]),
    invert: _c(jData[_s('invert')]),
    extract: _c(jData[_s('extract')]),
    extractRemaining: _c(jData[_s('extractRemaining')]),
    blur: _c(jData[_s('blur')]),
    sharpen: _c(jData[_s('sharpen')])
  } : defaultSculptMaskingConfig;
}

export function encodeSculptLocalScaleConfig(localScaleConfig: ISculptLocalScaleConfig) {
  return {
    [_s('radius')]: _n(localScaleConfig.radius),
    [_s('culling')]: _b(localScaleConfig.culling),
    [_s('idAlpha')]: localScaleConfig.idAlpha
  };
}

export function decodeSculptLocalScaleConfig(jData: any): ISculptLocalScaleConfig {
  return jData ? {
    radius: _m(jData[_s('radius')]),
    culling: _c(jData[_s('culling')]),
    idAlpha: jData[_s('idAlpha')]
  } : defaultSculptLocalScaleConfig;
}

export function encodeSculptToolConfig(toolConfig: ISculptToolConfig) {
  return {
    [_s('sculptTool')]: _n(toolConfig.sculptTool),
    [_s('symmetry')]: _b(toolConfig.symmetry),
    [_s('symmetryPlaneX')]: _b(toolConfig.symmetryPlaneX),
    [_s('symmetryPlaneY')]: _b(toolConfig.symmetryPlaneY),
    [_s('symmetryPlaneZ')]: _b(toolConfig.symmetryPlaneZ),
    [_s('changeSymmetryPlane')]: _b(toolConfig.changeSymmetryPlane),
    [_s('continuous')]: _b(toolConfig.continuous),
    [_s('canBeContinuous')]: _b(toolConfig.canBeContinuous),
    [_s('cameraTargetOnPick')]: _b(toolConfig.cameraTargetOnPick),
    [_s('brush')]: encodeSculptBrushConfig(toolConfig.brush),
    [_s('inflate')]: encodeSculptInflateConfig(toolConfig.inflate),
    [_s('twist')]: encodeSculptTwistConfig(toolConfig.twist),
    [_s('smooth')]: encodeSculptSmoothConfig(toolConfig.smooth),
    [_s('flatten')]: encodeSculptFlattenConfig(toolConfig.flatten),
    [_s('pinch')]: encodeSculptPinchConfig(toolConfig.pinch),
    [_s('crease')]: encodeSculptCreaseConfig(toolConfig.crease),
    [_s('drag')]: encodeSculptDragConfig(toolConfig.drag),
    [_s('move')]: encodeSculptMoveConfig(toolConfig.move),
    [_s('masking')]: encodeSculptMaskingConfig(toolConfig.masking),
    [_s('localScale')]: encodeSculptLocalScaleConfig(toolConfig.localScale)
  };
}

export function decodeSculptToolConfig(jData: any): ISculptToolConfig {
  return jData ? {
    sculptTool: _m(jData[_s('sculptTool')]),
    symmetry: _c(jData[_s('symmetry')]),
    symmetryPlaneX: _c(jData[_s('symmetryPlaneX')]),
    symmetryPlaneY: _c(jData[_s('symmetryPlaneY')]),
    symmetryPlaneZ: _c(jData[_s('symmetryPlaneZ')]),
    changeSymmetryPlane: _c(jData[_s('changeSymmetryPlane')]),
    continuous: _c(jData[_s('continuous')]),
    canBeContinuous: _c(jData[_s('canBeContinuous')]),
    cameraTargetOnPick: _c(jData[_s('cameraTargetOnPick')]),
    brush: decodeSculptBrushConfig(jData[_s('brush')]),
    inflate: decodeSculptInflateConfig(jData[_s('inflate')]),
    twist: decodeSculptTwistConfig(jData[_s('twist')]),
    smooth: decodeSculptSmoothConfig(jData[_s('smooth')]),
    flatten: decodeSculptFlattenConfig(jData[_s('flatten')]),
    pinch: decodeSculptPinchConfig(jData[_s('pinch')]),
    crease: decodeSculptCreaseConfig(jData[_s('crease')]),
    drag: decodeSculptDragConfig(jData[_s('drag')]),
    move: decodeSculptMoveConfig(jData[_s('move')]),
    masking: decodeSculptMaskingConfig(jData[_s('masking')]),
    localScale: decodeSculptLocalScaleConfig(jData[_s('localScale')])
  } : defaultSculptToolConfig;
}

export function encodeSculptConfig(sculptConfig: ISculptConfig) {
  return {
    [_s('tool')]: encodeSculptToolConfig(sculptConfig.tool),
    [_s('topology')]: encodeSculptTopologyConfig(sculptConfig.topology),
    [_s('rendering')]: encodeSculptRenderingConfig(sculptConfig.rendering)
  };
}

export function decodeSculptConfig(jData: any): ISculptConfig {
  return jData ? {
    tool: decodeSculptToolConfig(jData[_s('tool')]),
    topology: decodeSculptTopologyConfig(jData[_s('topology')]),
    rendering: decodeSculptRenderingConfig(jData[_s('rendering')])
  } : defaultSculptConfig;
}

export function encodeToolConfig(toolConfig: IToolConfig) {
  return {
    [_s('gumball')]: encodeGumballConfig(toolConfig.gumball),
    [_s('annotate')]: encodeAnnotateConfig(toolConfig.annotate),
    [_s('measure')]: encodeMeasureConfig(toolConfig.measure),
    [_s('sculpt')]: encodeSculptConfig(toolConfig.sculpt),
    [_s('snap')]: encodeSnapConfig(toolConfig.snap)
  };
}

export function decodeToolConfig(jData: any): IToolConfig {
  return jData ? {
    gumball: decodeGumballConfig(jData[_s('gumball')]),
    annotate: decodeAnnotateConfig(jData[_s('annotate')]),
    measure: decodeMeasureConfig(jData[_s('measure')]),
    sculpt: decodeSculptConfig(jData[_s('sculpt')]),
    snap: decodeSnapConfig(jData[_s('snap')])
  } : defaultToolConfig;
}