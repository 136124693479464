import {Matrix3, Vector3} from "three";
import {Geometry} from "three/examples/jsm/deprecated/Geometry";

var OFFExporter = function () {
};

OFFExporter.prototype = {

  constructor: OFFExporter,

  parse: (function () {

    var vector = new Vector3();
    var normalMatrixWorld = new Matrix3();

    return function parse(scene) {

      var objects = [];

      scene.traverse(function (object) {

        if (object.isMesh) {

          var geometry = object.geometry;

          if (geometry.isBufferGeometry) {

            geometry = new Geometry().fromBufferGeometry(geometry);

          }

          geometry = geometry.clone();
          geometry.mergeVertices();

          if (geometry.isGeometry) {

            objects.push({

              geometry: geometry,
              matrixWorld: object.matrixWorld

            });

          }

        }

      });

      var output = '', vertexOutput = '', faceOutput = '';

      output += 'OFF\n';

      var totalFaceCnt = 0, totalVertexCnt = 0;

      for (var i = 0, il = objects.length; i < il; i++) {

        var object = objects[i];

        var vertices = object.geometry.vertices;
        var faces = object.geometry.faces;
        var matrixWorld = object.matrixWorld;

        normalMatrixWorld.getNormalMatrix(matrixWorld);

        for (var j = 0, jl = vertices.length; j < jl; j++) {

          vector.copy(vertices[j]).applyMatrix4(matrixWorld);

          vertexOutput += '' + vector.x + ' ' + vector.y + ' ' + vector.z + '\n';

        }

        for (var j = 0, jl = faces.length; j < jl; j++) {

          var face = faces[j];

          faceOutput += '3 ' + (totalVertexCnt + face.a) + ' ' + (totalVertexCnt + face.b) + ' ' + (totalVertexCnt + face.c) + '\n';

        }

        totalFaceCnt += faces.length;
        totalVertexCnt += vertices.length;

      }

      output += totalVertexCnt + ' ' + totalFaceCnt + ' 0\n';

      output += vertexOutput;

      output += faceOutput;

      return output;

    };

  }())

};

export {OFFExporter};
