import {ComponentTypes} from "../types";
import {
  Param_Number,
  Param_Mesh,
  ParamValueTypes,
  getMeshFromRef,
  registerGeometry,
  getRegisteredId, ParamTitles
} from "../../parameter";
import {weldGeometryOnWorker} from "../../workers";
import {Calc} from "../../calc";
import {Scene} from "../../scene";
import {ICalcConfigDiff} from "../../types";
import {getUniqueDesc, WompObjectRef} from "../../../WompObject";

export class WeldModifier {
  static create() {
    let calc = Calc.createInScene(Scene.unset, ComponentTypes.WeldModifier, 'welded-mesh');

    let objectParam = Param_Mesh.create(calc, ParamTitles.Object, true, false, false);
    let weldParam = Param_Number.create(calc, ParamTitles.Weld, true, true, {
      type: ParamValueTypes.Degree,
      defaultValue: 22.5,
      bound: [0, 90]
    });
    let outParam = Param_Mesh.create(calc, ParamTitles.Mesh, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(weldParam);
    calc.addParameter(outParam);
    return calc;
  }

  static async solve(calc: Calc, diff: ICalcConfigDiff) {
    let objectParam = calc.inputByTitle(ParamTitles.Object) as Param_Mesh;
    let meshParam = calc.outputByTitle(ParamTitles.Mesh) as Param_Mesh;
    let weldParam = calc.inputByTitle(ParamTitles.Weld) as Param_Number;
    let weld = weldParam.values[0];

    let values: WompObjectRef[] = [];
    let properties = [];

    for (let i = 0; i < objectParam.length; ++i) {
      let value = objectParam.values[i];
      let property = objectParam.properties[i];

      if (property.weldAngle === weld) {
        values.push(value);
        properties.push(property);
      } else {
        let desc = `weld[${value.objectId},${weld}]`;
        let id = getRegisteredId(calc, desc);

        if (id) {
          const newValue = new WompObjectRef(id, value.matrix);
          values.push(newValue);
          properties.push({...property, hash: getUniqueDesc(newValue), weldAngle: weld});
        } else {
          let mesh = getMeshFromRef(calc, value);
          if (mesh) {
            let welded = await weldGeometryOnWorker(mesh.geometry, weld);

            const newValue = new WompObjectRef(registerGeometry(calc, welded, desc), mesh.matrix);
            values.push(newValue);
            properties.push({...property, hash: getUniqueDesc(newValue), weldAngle: weld});
          }
        }

      }
    }

    objectParam.values = values;
    objectParam.properties = properties;

    meshParam.values = values;
    meshParam.properties = properties;

    return true;
  }
}