import Scrollbars, {ScrollbarProps} from 'react-scrollbars-custom';
import * as React from "react";
import {CSSProperties} from "react";
import {withRef} from "./WithRef";

export interface IScrollPanelProps extends ScrollbarProps {
  children?: any
  thumbSize?: number
  contentId?: string
  thumbColor?: string
  trackColor?: string
  absoluteTrack?: boolean
  styles?: Partial<{
    content: CSSProperties
    trackY: CSSProperties
    trackX: CSSProperties
  }>
  type?: 'circle-on-rail' | 'half-round-bar'
  vertToHoriz?: boolean
}

class ScrollPanel extends React.PureComponent<IScrollPanelProps> {
  scrollBarElement: any;
  scrollLeft: number = 0;

  scrollToBottom() {
    if (this.scrollBarElement) {
      this.scrollBarElement.scrollToBottom();
    }
  }

  render() {
    let {type, thumbColor, thumbSize, trackColor, style, styles, absoluteTrack, contentId, vertToHoriz, children, ...props} = this.props;

    if (!type)
      type = 'half-round-bar';
    if (!thumbColor)
      thumbColor = '#0E0E0F';
    if (!trackColor)
      trackColor = type === 'half-round-bar' ? 'none' : '#0E0E0F';
    if (!thumbSize)
      thumbSize = type === 'half-round-bar' ? 48 : 9;

    return (
      <Scrollbars
        {...props}
        style={style}
        ref={(ref: any) => this.scrollBarElement = ref}
        minimalThumbSize={thumbSize ? thumbSize : undefined}
        maximalThumbSize={thumbSize ? thumbSize : undefined}
        renderer={props => {
          const {elementRef, ...restProps} = props;
          return <div {...restProps} ref={elementRef}/>;
        }}
        wrapperProps={{
          renderer: props => {
            let {elementRef, style, ...restProps} = props;

            if (absoluteTrack) {
              style = {
                ...style,
                right: '0px',
                bottom: '0px'
              };
            }

            if (type === 'half-round-bar') {
              style = {
                ...style,
                right: '4px',
                bottom: '4px'
              };
            }

            return <div {...restProps} style={style} ref={elementRef}/>;
          }
        }}
        scrollerProps={{
          renderer: props => {
            const {elementRef, ...restProps} = props;
            return <div {...restProps} ref={elementRef}/>;
          }
        }}
        contentProps={{
          renderer: props => {
            let {elementRef, style, ...restProps} = props;

            if (styles && styles.content) {
              style = {
                ...style,
                ...styles.content
              };
            }

            return <div {...restProps} style={style} ref={elementRef} id={contentId ? contentId : undefined}/>;
          }
        }}
        trackXProps={{
          renderer: props => {
            let {elementRef, style, ...restProps} = props;
            if (trackColor) {
              style = {
                ...style,
                background: trackColor
              };
            }

            if (type === 'half-round-bar') {
              style = {
                ...style,
                height: '4px'
              };
            }

            if (type === 'circle-on-rail') {
              style = {
                ...style,
                height: '1px',
                marginTop: '6px',
                marginBottom: '6px',
                overflow: 'visible',
              };
            }

            if (styles && styles.trackX) {
              style = {
                ...style,
                ...styles.trackX
              };
            }

            return <div {...restProps} style={style} ref={elementRef}/>;
          }
        }}
        trackYProps={{
          renderer: props => {
            let {elementRef, style, ...restProps} = props;
            if (trackColor) {
              style = {
                ...style,
                background: trackColor
              };
            }

            if (type === 'half-round-bar') {
              style = {
                ...style,
                width: '4px'
              };
            }

            if (type === 'circle-on-rail') {
              style = {
                ...style,
                width: '1px',
                marginLeft: '6px',
                marginRight: '6px',
                overflow: 'visible',
              };
            }

            if (styles && styles.trackY) {
              style = {
                ...style,
                ...styles.trackY
              };
            }

            return <div {...restProps} style={style} ref={elementRef}/>;
          }
        }}
        thumbXProps={{
          renderer: props => {
            let {elementRef, style, ...restProps} = props;
            if (thumbColor) {
              style = {
                ...style,
                background: thumbColor
              };
            }

            if (type === 'half-round-bar') {
              style = {
                ...style,
                height: '6px',
                borderRadius: '1rem 1rem 0 0'
              };
            }

            if (type === 'circle-on-rail') {
              style = {
                ...style,
                borderRadius: '1rem',
                height: '' + thumbSize + 'px',
                width: '' + thumbSize + 'px',
                marginTop: '-' + Math.floor((thumbSize || 0) / 2) + 'px'
              };
            }

            return <div {...restProps} style={style} ref={elementRef}/>;
          }
        }}
        thumbYProps={{
          renderer: props => {
            let {elementRef, style, ...restProps} = props;
            if (thumbColor) {
              style = {
                ...style,
                background: thumbColor
              };
            }

            if (type === 'half-round-bar') {
              style = {
                ...style,
                width: '6px',
                borderRadius: '1rem 0 0 1rem'
              };
            }

            if (type === 'circle-on-rail') {
              style = {
                ...style,
                borderRadius: '1rem',
                height: '' + thumbSize + 'px',
                width: '' + thumbSize + 'px',
                marginLeft: '-' + Math.floor((thumbSize || 0) / 2) + 'px'
              };
            }

            return <div {...restProps} style={style} ref={elementRef}/>;
          }
        }}
        onUpdate={(scrollValues) => {
          this.scrollLeft = scrollValues.scrollLeft;
        }}
        onWheel={(event) => {
          if (vertToHoriz)
            this.scrollBarElement && this.scrollBarElement.scrollTo(this.scrollLeft + event.deltaY + event.deltaX, 0);
        }}
      >
        {children}
      </Scrollbars>
    );
  }
};

export default withRef(ScrollPanel);
