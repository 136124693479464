import {BrepCodec} from "../types";
import numeral from "numeral";
import {decodeBuffer, encodeBuffer} from "./common";
import {WompBrepData} from "../WompObject";

export function encodeBrepGeometryCodecTrans(geom: WompBrepData) {
  if (geom && geom.shape)
    return geom.shape.buffer;
  else
    return new ArrayBuffer(0);
}

export async function encodeBrepGeometry(geom: WompBrepData, format: BrepCodec, outputJson: boolean = true) {
  let t0 = performance.now();

  switch (format) {
    case BrepCodec.Zip: {
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for zip encode`);

      let buffer = encodeBrepGeometryCodecTrans(geom);
      return [format, outputJson ? encodeBuffer(buffer) : buffer];
    }
    case BrepCodec.Trans:
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for trans encode`);

      return [format, encodeBrepGeometryCodecTrans(geom)];
  }

  return [format, new ArrayBuffer(0)];
}

export async function decodeBrepGeometry(data: any) {
  let t0 = performance.now();
  switch (data[0]) {
    case BrepCodec.Zip: {
      let ret = typeof data[1] === 'string' ? decodeBuffer(data[1]) : data[1];
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for zip decode`);

      return {
        shape: new Uint8Array(ret)
      };
    }
    case BrepCodec.Trans: {
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for trans decode`);

      return {
        shape: new Uint8Array(data[1])
      };
    }
  }
  return {};
}

