import {vec3} from "gl-matrix";
import {
  Camera,
  Frustum,
  LineSegments,
  Material,
  Mesh,
  OrthographicCamera, PerspectiveCamera,
  Scene,
  Sprite,
  Vector2,
  Vector3
} from "three";

export function cleanMaterial(material: Material) {
  material.dispose();

  for (const key of Object.keys(material)) {
    //@ts-ignore
    const value = material[key];
    if (value && typeof value === 'object' && 'minFilter' in value && value.dispose) {
      value.dispose();
    }
  }
}

export function cleanScene(scene?: Scene) {
  if (!scene)
    return;

  scene.traverse(object => {
    //@ts-ignore
    if (object.isMesh) {
      let mesh = (object as Mesh);
      mesh.geometry.dispose();

      //@ts-ignore
      if (mesh.material.isMaterial) {
        cleanMaterial(mesh.material as Material);
      } else {
        for (const material of mesh.material as Material[])
          cleanMaterial(material);
      }
      //@ts-ignore
    } else if (object.isSprite) {
      let sprite = (object as Sprite);
      sprite.geometry.dispose();

      cleanMaterial(sprite.material);
      //@ts-ignore
    } else if (object.isLineSegments) {
      let segments = (object as LineSegments);
      segments.geometry.dispose();

      //@ts-ignore
      if (segments.material.isMaterial) {
        cleanMaterial(segments.material as Material);
      } else {
        for (const material of segments.material as Material[])
          cleanMaterial(material);
      }
    }

    //@ts-ignore
    if (object !== scene && object.dispose) {
      //@ts-ignore
      object.dispose();
    }
  });

  scene.clear();
}

export function applySnapIncrement(position: vec3, snap: number) {
  if (snap) {
    vec3.set(
      position,
      Math.round(position[0] / snap) * snap,
      Math.round(position[1] / snap) * snap,
      Math.round(position[2] / snap) * snap
    );
  }
}

export function updateFrustum (camera: Camera, frustum: Frustum, startPoint: Vector3 = new Vector3(0, 0, 0), endPoint: Vector3 = new Vector3(1, 1, 0), deep: number = Number.MAX_VALUE) {

  if ((camera as PerspectiveCamera).isPerspectiveCamera) {

    var tmpPoint = startPoint.clone();
    tmpPoint.x = Math.min(startPoint.x, endPoint.x);
    tmpPoint.y = Math.max(startPoint.y, endPoint.y);
    endPoint.x = Math.max(startPoint.x, endPoint.x);
    endPoint.y = Math.min(startPoint.y, endPoint.y);

    let vecNear = camera.position.clone();
    let vecTopLeft = tmpPoint.clone();
    let vecTopRight = new Vector3(endPoint.x, tmpPoint.y, 0);
    let vecDownRight = endPoint.clone();
    let vecDownLeft = new Vector3(tmpPoint.x, endPoint.y, 0);
    vecTopLeft.unproject(camera);
    vecTopRight.unproject(camera);
    vecDownRight.unproject(camera);
    vecDownLeft.unproject(camera);

    let vectemp1 = vecTopLeft.clone().sub(vecNear);
    let vectemp2 = vecTopRight.clone().sub(vecNear);
    let vectemp3 = vecDownRight.clone().sub(vecNear);
    vectemp1.normalize();
    vectemp2.normalize();
    vectemp3.normalize();

    vectemp1.multiplyScalar(deep);
    vectemp2.multiplyScalar(deep);
    vectemp3.multiplyScalar(deep);
    vectemp1.add(vecNear);
    vectemp2.add(vecNear);
    vectemp3.add(vecNear);

    let planes = frustum.planes;

    planes[0].setFromCoplanarPoints(vecNear, vecTopLeft, vecTopRight);
    planes[1].setFromCoplanarPoints(vecNear, vecTopRight, vecDownRight);
    planes[2].setFromCoplanarPoints(vecDownRight, vecDownLeft, vecNear);
    planes[3].setFromCoplanarPoints(vecDownLeft, vecTopLeft, vecNear);
    planes[4].setFromCoplanarPoints(vecTopRight, vecDownRight, vecDownLeft);
    planes[5].setFromCoplanarPoints(vectemp3, vectemp2, vectemp1);
    planes[5].normal.multiplyScalar(-1);

  } else if ((camera as OrthographicCamera).isOrthographicCamera) {

    let left = Math.min(startPoint.x, endPoint.x);
    let top = Math.max(startPoint.y, endPoint.y);
    let right = Math.max(startPoint.x, endPoint.x);
    let down = Math.min(startPoint.y, endPoint.y);
    
    let vecTopLeft = new Vector3(left, top, -1);
    let vecTopRight = new Vector3(right, top, -1);
    let vecDownRight = new Vector3(right, down, -1);
    let vecDownLeft = new Vector3(left, down, -1);

    let vecFarTopLeft = new Vector3(left, top, 1);
    let vecFarTopRight = new Vector3(right, top, 1);
    let vecFarDownRight = new Vector3(right, down, 1);
    let vecFarDownLeft = new Vector3(left, down, 1);

    vecTopLeft.unproject(camera);
    vecTopRight.unproject(camera);
    vecDownRight.unproject(camera);
    vecDownLeft.unproject(camera);

    vecFarTopLeft.unproject(camera);
    vecFarTopRight.unproject(camera);
    vecFarDownRight.unproject(camera);
    vecFarDownLeft.unproject(camera);

    let planes = frustum.planes;

    planes[0].setFromCoplanarPoints(vecTopLeft, vecFarTopLeft, vecFarTopRight);
    planes[1].setFromCoplanarPoints(vecTopRight, vecFarTopRight, vecFarDownRight);
    planes[2].setFromCoplanarPoints(vecFarDownRight, vecFarDownLeft, vecDownLeft);
    planes[3].setFromCoplanarPoints(vecFarDownLeft, vecFarTopLeft, vecTopLeft);
    planes[4].setFromCoplanarPoints(vecTopRight, vecDownRight, vecDownLeft);
    planes[5].setFromCoplanarPoints(vecFarDownRight, vecFarTopRight, vecFarTopLeft);
    planes[5].normal.multiplyScalar(-1);

  }
}