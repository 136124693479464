import React from "react";
import Editor3dDelegateWrapper from "../../3d/Editor3dDelegateWrapper";
import Editor3dWrapper from "../../3d/Editor3dWrapper";
import iconWomp from "../../../assets/images/icon/hero/womp.svg";
import {Link} from "react-router-dom";
import {HERO_EYE_URL, HERO_FRONT_URL} from "../../common/const";
import iconTwitter from "../../../assets/images/icon/hero/twitter.svg";
import iconInstagram from "../../../assets/images/icon/hero/instagram.svg";
import iconTiktok from "../../../assets/images/icon/hero/tiktok.svg";
import {IAppState} from "../../../store";
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {push} from "connected-react-router";
import {connect} from "react-redux";

interface IHeroFooterProps {
  editorWrapper: Editor3dWrapper
  page: 'about' | 'main'

  goToMain: () => void
}

interface IHeroFooterInnerState {
}

class HeroFooter extends React.PureComponent<IHeroFooterProps, IHeroFooterInnerState> {
  delegateWrapper!: Editor3dDelegateWrapper;

  onLogoClick = (evt: any) => {
    this.setState({message: ''}, async () => {
      this.props.goToMain();
    });
  };

  render() {
    let {page} = this.props;
    return <div className="hero-footer">
      <div className="flex flex-column items-center-m items-center-ns">
        <div className="womp-logo">
          <img src={iconWomp} alt='womp' onClick={this.onLogoClick}/>
          <p>womp</p>
        </div>
        <p className="f11 mt5">womp 3D Inc. all rights reserved.</p>
      </div>
      <div className="flex flex-column f9 lh-3 items-center-m items-center-ns">
        <p className="bold dn-m dn-ns">COMPANY</p>
        <Link className="mt5" to="/about">About</Link>
        <a className="mt5" href="https://jobs.lever.co/womp"
           target="_blank">Careers 🎉</a>
      </div>
      <div className="flex flex-column f9 lh-3 items-center-m items-center-ns">
        <p className="bold dn-m dn-ns">COMPLIANCE</p>
        <a className="mt5" href="https://www.notion.so/womp3d/Womp-Privacy-Policy-5d174d30a85a45699594780938b93643"
           target="_blank">Privacy</a>
        <a className="mt5"
           href="https://www.notion.so/womp3d/Womp-Terms-and-conditions-23eabd026f0a4edd899b1a9681fa16cf"
           target="_blank">terms and conditions</a>
      </div>
      {page === 'main' ?
        <a className="f10 access-button mb4-m mb4-ns" href="mailto:info@womp.xyz">contact us</a> :
        <Link className="f10 access-button mb4-m mb4-ns" to={HERO_EYE_URL}>get early access</Link>
      }
      <div className="flex items-center mv8-m mv8-ns">
        <a className="mr5" href="https://twitter.com/wompxyz" target="_blank">
          <img src={iconTwitter} alt='twitter'/>
        </a>
        <a className="mr5" href="https://www.instagram.com/womp.xyz/" target="_blank">
          <img src={iconInstagram} alt='instagram'/>
        </a>
        <a href="https://www.tiktok.com/@wompxyz?lang=en" target="_blank">
          <img src={iconTiktok} alt='tiktok'/>
        </a>
      </div>
    </div>;
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  return {
    goToMain: () => dispatch(push(HERO_FRONT_URL)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeroFooter);