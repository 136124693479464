/**
 * @author arodic / https://github.com/arodic
 */

import {
  Box3,
  DoubleSide,
  EventDispatcher,
  Group,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneBufferGeometry,
  Quaternion,
  Raycaster,
  SphereBufferGeometry,
  Vector2,
  Vector3,
  BackSide,
  BoxBufferGeometry,
  BufferGeometry,
  Uint32BufferAttribute,
  Float32BufferAttribute,
  ShaderMaterial,
  Color,
  AdditiveBlending, CylinderBufferGeometry
} from 'three';
import {Line2} from 'three/examples/jsm/lines/Line2';
import {LineGeometry} from 'three/examples/jsm/lines/LineGeometry';
import {LineMaterial} from 'three/examples/jsm/lines/LineMaterial';
import lod from 'lodash';

const PI = Math.PI;

// R = X, U = Y, T = Z
const FACE_PICKER_INFO = {
  R: {
    position: new Vector3(.5, 0, 0),
    offset: new Vector3(1, 0, 0),
    width: new Vector3(0, 0, 1),
    height: new Vector3(0, 1, 0),
    axis: 'x',
    axisInd: 0,
    direction: 1,
  },
  U: {
    position: new Vector3(0, .5, 0),
    offset: new Vector3(0, 1, 0),
    width: new Vector3(1, 0, 0),
    height: new Vector3(0, 0, 1),
    axis: 'y',
    axisInd: 1,
    direction: 1,
  },
  T: {
    position: new Vector3(0, 0, .5),
    offset: new Vector3(0, 0, 1),
    width: new Vector3(1, 0, 0),
    height: new Vector3(0, 1, 0),
    axis: 'z',
    axisInd: 2,
    direction: 1,
  },
  L: {
    position: new Vector3(-.5, 0, 0),
    offset: new Vector3(-1, 0, 0),
    width: new Vector3(0, 0, 1),
    height: new Vector3(0, 1, 0),
    axis: 'x',
    axisInd: 0,
    direction: -1,
  },
  D: {
    position: new Vector3(0, -.5, 0),
    offset: new Vector3(0, -1, 0),
    width: new Vector3(1, 0, 0),
    height: new Vector3(0, 0, 1),
    axis: 'y',
    axisInd: 1,
    direction: -1,
  },
  B: {
    position: new Vector3(0, 0, -.5),
    offset: new Vector3(0, 0, -1),
    width: new Vector3(1, 0, 0),
    height: new Vector3(0, 1, 0),
    axis: 'z',
    axisInd: 2,
    direction: -1,
  },
};

const DRAG_MODE = {
  FACE: 1,
};

let MirrorControl = function (camera, scene, controlScene) {
  Object3D.call(this);

  this.domElement = document;
  let curTick = 0;
  let timer = setInterval(() => ++curTick, 1000);
  let lastEventTick;

  let Config = {
    ShowTime: 5
  };

  this.visible = false;
  this.camera = camera;
  this.scene = scene;
  this.controlScene = controlScene;
  this.eye = undefined;
  // this.intersectPlane = new Mesh(
  //   new PlaneBufferGeometry(1000, 1000),
  //   new MeshBasicMaterial({ visible: true, wireframe: false, side: DoubleSide, transparent: true, opacity: 0.1, color: 0x00ff00})
  // );
  this.intersectPlane = new Mesh(
    new PlaneBufferGeometry(300000, 300000),
    new MeshBasicMaterial({visible: false, wireframe: false, side: DoubleSide, transparent: true})
  );
  this.intersectPlane.name = 'mirror-control-intersect-plane';

  this.rayCaster = new Raycaster();
  this.rayCaster.layers.set(0);

  this.oldState = {};

  this.spriteSize = 1.2;
  this.isDragging = false;

  this.mouseOnCanvas = new Vector2();
  this.mouse = new Vector2();

  this.boundingBox = new Box3();
  this.initialMatrix = new Matrix4();
  this.space = 'world';
  this.controlEnabled = true;
  this.elemRect = undefined;

  let scope = this;
  let unitX = new Vector3(1, 0, 0);
  let unitY = new Vector3(0, 1, 0);
  let unitZ = new Vector3(0, 0, 1);
  let units = {x: unitX, y: unitY, z: unitZ};

  let tempVector = new Vector3();
  let tempMatrix = new Matrix4();
  let alignVector = new Vector3();
  let dirVector = new Vector3();

  let objUnitX = new Vector3(1, 0, 0);
  let objUnitY = new Vector3(0, 1, 0);
  let objUnitZ = new Vector3(0, 0, 1);
  let objPosition = new Vector3();
  let objScale = new Vector3();
  let objQuaternion = new Quaternion();
  let boundingBoxSize = new Vector3();
  let boundingBoxCenter = new Vector3();

  let faceHoverPickerName;

  function updateObjectLayers(object, newVisible) {

    if (object.visible && !newVisible) {

      object.traverse(obj => {

        obj.layers.disable(0);
        obj.layers.enable(1);

      });

    } else if (!object.visible && newVisible) {

      object.traverse(obj => {

        obj.layers.disable(1);
        obj.layers.enable(0);

      });

    }

    object.visible = newVisible;

  }

  function createGhostedMaterial(color) {

    let vertexShader = [
      'uniform float p;',
      'varying float intensity;',
      'void main() {',
      ' vec3 transformed = vec3( position );',
      ' vec4 mvPosition = vec4( transformed, 1.0 );',
      ' #ifdef USE_INSTANCING',
      '  mvPosition = instanceMatrix * mvPosition;',
      ' #endif',
      ' vec4 modelViewPosition = modelViewMatrix * mvPosition;',
      ' vec3 vNormal = normalize( normalMatrix * normal );',
      ' intensity = pow(1.0 - abs(dot(vNormal, vec3(0, 0, 1))), p);',
      ' gl_Position = projectionMatrix * modelViewPosition;',
      '}'
    ].join('\n');

    let fragmentShader = [
      'uniform vec3 glowColor;',
      'varying float intensity;',
      'void main() {',
      ' vec3 glow = glowColor * intensity;',
      ' gl_FragColor = vec4( glow, 1.0 );',
      '}'
    ].join('\n');

    return new ShaderMaterial({
      uniforms: {
        p: {value: 2},
        glowColor: {value: new Color(color)}
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      side: DoubleSide, blending: AdditiveBlending,
      transparent: true, depthWrite: false
    });

  }

  function getAxis(name) {
    return name ? FACE_PICKER_INFO[name].axis : undefined;
  }

  function getAxisInd(name) {
    return name ? FACE_PICKER_INFO[name].axisInd : undefined;
  }

  function getDirection(name) {
    return name ? FACE_PICKER_INFO[name].direction : undefined;
  }

  function getUnit(name) {
    return [objUnitX, objUnitY, objUnitZ][getAxisInd(name)];
  }

  this.setSize = function (size) {
    this.spriteSize = size * 12.0;
  };

  this.refreshBoundingClientRect = function () {
    this.elemRect = this.domElement === document ? document.body.getBoundingClientRect() : this.domElement.getBoundingClientRect();
  };

  this.setDelegate = function (delegate) {

    this.unsetDelegate();

    delegate.domElement.addEventListener('mousedown', onPointerDown);
    delegate.domElement.addEventListener('touchstart', onTouchDown);

    delegate.domElement.addEventListener('mousemove', onPointerMove);
    delegate.domElement.addEventListener('touchmove', onPointerMove);

    delegate.domElement.addEventListener('mouseup', onPointerUp);
    delegate.domElement.addEventListener('touchend', onPointerUp);
    delegate.domElement.addEventListener('touchcancel', onPointerUp);
    delegate.domElement.addEventListener('touchleave', onPointerUp);

    this.domElement = delegate.domElement;

    this.refreshBoundingClientRect();
  }

  this.unsetDelegate = function () {

    if (this.domElement === document)
      return;

    this.domElement.removeEventListener('mousedown', onPointerDown);
    this.domElement.removeEventListener('touchstart', onTouchDown);

    this.domElement.removeEventListener('mousemove', onPointerMove);
    this.domElement.removeEventListener('touchmove', onPointerMove);

    this.domElement.removeEventListener('mouseup', onPointerUp);
    this.domElement.removeEventListener('touchend', onPointerUp);
    this.domElement.removeEventListener('touchcancel', onPointerUp);
    this.domElement.removeEventListener('touchleave', onPointerUp);

    this.domElement = document;

  }

  this.dispose = function () {
    this.detach();

    clearInterval(timer);

    scope = null;
  };

  this.attach = function (object) {

    this.object = object;
    this.visible = true;
    this.initialMatrix = object.matrix.clone();
    this.currentAxis = 'R';
    this.distance = 0;

    this.previewObject = this.object.clone(true);

    this.previewObject.traverse((object) => {
      if (object.isMesh)
        object.material = this.materials.object;
    });
    this.previewObject.visible = false;
    this.scene.add(this.previewObject);

    faceHoverPickerName = undefined;

    // this.refresh();
    scope.dispatchEvent({type: 'change'});
    // lastEventTick = -Config.ShowTime - 1;

  };

  this.boxGeometry = new BoxBufferGeometry(1, 1, 1);
  this.boxMaterial = new MeshBasicMaterial({transparent: true, side: DoubleSide, opacity: 0, visible: false});

  this.detach = function () {
    this.object = undefined;
    this.scene.remove(this.previewObject);

    this.visible = false;
  };

  this.getRelativeTransform = function (transform) {
    return transform.copy(this.object.matrix).premultiply(this.initialMatrix.clone().invert());
  };

  this.getObjectBoundingBoxSize = function (size) {
    let relativeTransform = this.getRelativeTransform(new Matrix4());
    return this.boundingBox.clone().applyMatrix4(relativeTransform).getSize(size);
  };

  this.getObjectBoundingBoxCenter = function (center) {
    let relativeTransform = this.getRelativeTransform(new Matrix4());
    return this.boundingBox.clone().applyMatrix4(relativeTransform).getCenter(center);
  };

  this.getObjectUnitAxes = function (x, y, z) {
    x.set(1, 0, 0);
    y.set(0, 1, 0);
    z.set(0, 0, 1);
    if (this.space !== 'world') {

      this.object.matrix.decompose(
        objPosition,
        objQuaternion,
        objScale
      );

      x.applyQuaternion(objQuaternion);
      y.applyQuaternion(objQuaternion);
      z.applyQuaternion(objQuaternion);
    }
  };

  this.getControlGroupMatrix = function () {
    this.object.matrix.decompose(
      objPosition,
      objQuaternion,
      objScale
    );

    return new Matrix4().makeTranslation(-objPosition.x, -objPosition.y, -objPosition.z)
      .premultiply(new Matrix4().makeRotationFromQuaternion(objQuaternion))
      .premultiply(new Matrix4().makeTranslation(objPosition.x, objPosition.y, objPosition.z));
  };

  this.refreshBoundingBoxLines = function () {

    let pos = objPosition;
    let bBoxSize = boundingBoxSize;
    let picker = this.boundingBoxLines;
    let showControl = !this.isDragging;

    if (showControl) {

      picker.visible = true;
      picker.position.copy(pos);

      let positions = [
        [-.5, -.5, -.5, -.5, -.5, .5],
        [.5, -.5, -.5, .5, -.5, .5],
        [-.5, .5, -.5, -.5, .5, .5],
        [.5, .5, -.5, .5, .5, .5],
        [-.5, -.5, -.5, -.5, .5, -.5],
        [-.5, .5, -.5, .5, .5, -.5],
        [.5, .5, -.5, .5, -.5, -.5],
        [.5, -.5, -.5, -.5, -.5, -.5],
        [-.5, -.5, .5, -.5, .5, .5],
        [-.5, .5, .5, .5, .5, .5],
        [.5, .5, .5, .5, -.5, .5],
        [.5, -.5, .5, -.5, -.5, .5],
      ];

      for (let j = 0; j < 12; ++j) {

        for (let i = 0; i < positions[j].length; i += 3) {

          positions[j][i] *= bBoxSize.x;
          positions[j][i + 1] *= bBoxSize.y;
          positions[j][i + 2] *= bBoxSize.z;

        }

        picker.children[j].geometry.setPositions(positions[j]);
        picker.children[j].computeLineDistances();

        picker.children[j].visible = true;

      }

    }

  };

  this.refreshFace = function () {

    let axis = this.currentAxis;
    let distance = this.distance;

    if (this.dragAxis !== undefined) {
      axis = this.dragAxis;
      distance = this.dragDistance;
    }

    let curInfo = FACE_PICKER_INFO[axis];

    this.symFace.material = this.materials.symFace;

    this.symFace.position.copy(objPosition).add(curInfo.position.clone().multiply(boundingBoxSize));

    if (distance)
      this.symFace.position.add(curInfo.position.clone().multiply(new Vector3(distance, distance, distance)).multiplyScalar(2));

    this.symFace.quaternion.setFromUnitVectors(unitZ, curInfo.offset);

    let width = boundingBoxSize.dot(curInfo.width);
    let height = boundingBoxSize.dot(curInfo.height);

    this.symFace.scale.set(width, height, 1);
    this.symFace.visible = true;

  };

  this.refreshFacePicker = function () {

    if (DRAG_MODE.FACE === this.dragMode || !this.isDragging) {

      this.facePicker.visible = true;

      let facePickerArray = this.facePicker.children;

      for (let i = facePickerArray.length; i--;) {

        let curPicker = facePickerArray[i];
        let axis = curPicker.name;

        let curInfo = FACE_PICKER_INFO[axis];

        if (faceHoverPickerName === axis)
          curPicker.material = this.materials.face.hover;
        else
          curPicker.material = this.materials.face.normal;

        curPicker.position.copy(objPosition).add(curInfo.position.clone().multiply(boundingBoxSize));
        curPicker.quaternion.setFromUnitVectors(unitZ, curInfo.offset);

        let width = boundingBoxSize.dot(curInfo.width);
        let height = boundingBoxSize.dot(curInfo.height);

        curPicker.scale.set(width, height, 1);

        let newVisible = !this.isDragging;
        newVisible = newVisible || (DRAG_MODE.FACE === this.dragMode && this.dragAxis === axis);

        updateObjectLayers(curPicker, newVisible);

      }

    }

  };

  this.refreshPreviewObject = function () {

    if (this.previewObject) {
      let axis = getAxis(this.currentAxis);
      let direction = getDirection(this.currentAxis);
      let distance = this.distance;

      if (faceHoverPickerName !== undefined) {
        let axis = getAxis(faceHoverPickerName);
        let direction = getDirection(faceHoverPickerName);
        let distance = 0;
      }

      if (this.dragAxis !== undefined) {
        axis = getAxis(this.dragAxis);
        direction = getDirection(this.dragAxis);
        if (axis)
          distance = this.dragDistance;
      }

      let unit = units[axis];
      let boundingBoxCenter = this.boundingBox.getCenter(new Vector3());

      distance += this.boundingBox.getSize(new Vector3()).dot(units[axis]) / 2;
      distance *= direction * 2;

      if (unit) {
        this.previewObject.matrix.copy(this.object.matrix);
        this.previewObject.matrix
          .premultiply(new Matrix4().makeTranslation(-boundingBoxCenter.x, -boundingBoxCenter.y, -boundingBoxCenter.z))
          .premultiply(new Matrix4().makeScale(1 - unit.x * 2, 1 - unit.y * 2, 1 - unit.z * 2))
          .premultiply(new Matrix4().makeTranslation(unit.x * distance, unit.y * distance, unit.z * distance))
          .premultiply(new Matrix4().makeTranslation(boundingBoxCenter.x, boundingBoxCenter.y, boundingBoxCenter.z));

        this.previewObject.visible = true;
      }
    }

  };

  this.setConfig = function ({currentAxis, distance}) {

    if (distance !== undefined)
      this.distance = distance;

    if (currentAxis !== undefined)
      this.currentAxis = currentAxis;

    scope.dispatchEvent({type: 'change'});

  };

  this.refresh = function () {

    let newState = {};

    newState.enabled = this.enabled;
    this.boundingBoxLines.visible = false;
    this.facePicker.visible = false;
    this.symFace.visible = false;
    if (this.previewObject)
      this.previewObject.visible = false;

    for (let picker of this.facePicker.children)
      updateObjectLayers(picker, false);

    if (this.enabled && this.object !== undefined) {

      newState.space = this.space;
      newState.objectId = this.object.id;

      if (this.space === 'world') {

        this.controlGroup.matrix = new Matrix4();

      } else {

        this.controlGroup.matrix = this.getControlGroupMatrix();

      }

      newState.visible = this.visible;

      if (this.visible) {

        boundingBoxSize = this.getObjectBoundingBoxSize(new Vector3());
        boundingBoxCenter = this.getObjectBoundingBoxCenter(new Vector3());

        if (boundingBoxSize.x > 0 || boundingBoxSize.y > 0 || boundingBoxSize.z > 0) {

          this.eye = this.camera.getWorldDirection(new Vector3());

          this.object.matrix.decompose(
            objPosition,
            objQuaternion,
            objScale
          );

          newState.eye = this.eye.toArray();
          newState.position = objPosition.toArray();
          newState.quaternion = objQuaternion.toArray();
          newState.scale = objScale.toArray();
          newState.dragMode = this.dragMode;
          newState.faceHoverPickerName = faceHoverPickerName;
          newState.dragAxis = this.dragAxis;
          newState.dragDistance = this.dragDistance;
          newState.currentAxis = this.currentAxis;
          newState.distance = this.distance;
          newState.exceedTick = (lastEventTick + Config.ShowTime >= curTick);
          newState.spriteSize = this.spriteSize;

          this.refreshBoundingBoxLines();
          this.refreshFacePicker();
          this.refreshFace();
          this.refreshPreviewObject();

        }

      }

    }

    // Object3D.prototype.updateMatrixWorld.call(this)
    let hadUpdate = !lod.isEqual(newState, this.oldState);
    this.oldState = newState;
    return hadUpdate;

  };

  function getPointer(event) {
    if (!scope.elemRect)
      scope.elemRect = scope.domElement.getBoundingClientRect();

    let pointer = event.changedTouches ? event.changedTouches[0] : event;

    scope.mouseOnCanvas.x = pointer.clientX - scope.elemRect.left;
    scope.mouseOnCanvas.y = pointer.clientY - scope.elemRect.top;

    scope.mouse.x = scope.mouseOnCanvas.x / scope.elemRect.width * 2 - 1;
    scope.mouse.y = -scope.mouseOnCanvas.y / scope.elemRect.height * 2 + 1;

    return {
      x: (pointer.clientX - scope.elemRect.left) / scope.elemRect.width * 2 - 1,
      y: -(pointer.clientY - scope.elemRect.top) / scope.elemRect.height * 2 + 1,
      button: event.button,
      type: event.changedTouches ? 'touch' : 'mouse'
    };
  }

  this.setIntersectPlane = function (baseVector) {
    alignVector.copy(this.eye).cross(baseVector);
    dirVector.copy(baseVector).cross(alignVector);
    tempMatrix.lookAt(tempVector.set(0, 0, 0), dirVector, alignVector);

    this.object.matrix.decompose(
      objPosition,
      objQuaternion,
      objScale
    );

    this.intersectPlane.position.copy(objPosition);
    this.intersectPlane.quaternion.setFromRotationMatrix(tempMatrix);
    this.intersectPlane.updateMatrixWorld();
  };

  this.getCurrentTransform = function () {

    let offset = getUnit(this.currentAxis).clone()
      .multiplyScalar(getDirection(this.currentAxis))
      .multiplyScalar(this.distance + boundingBoxSize.getComponent(getAxisInd(this.currentAxis)) / 2);
    let n = offset.clone().normalize().negate();
    let pos = offset.add(boundingBoxCenter);

    let flipMatrix = new Matrix4().set(
      1 - 2 * n.x * n.x, -2 * n.x * n.y, -2 * n.x * n.z, 0,
      -2 * n.x * n.y, 1 - 2 * n.y * n.y, -2 * n.y * n.z, 0,
      -2 * n.x * n.z, -2 * n.y * n.z, 1 - 2 * n.z * n.z, 0,
      0, 0, 0, 1
    );

    return new Matrix4().makeTranslation(-pos.x, -pos.y, -pos.z)
      .premultiply(flipMatrix)
      .premultiply(new Matrix4().makeTranslation(pos.x, pos.y, pos.z));

  };

  this.pointerDown = function (event, pointer) {

    lastEventTick = -Config.ShowTime - 1;
    if (!this.object) return;
    if (pointer.type === 'mouse' && pointer.button !== 0) return;

    if (!this.enabled || !this.controlEnabled) return;

    this.dragEndPosition = new Vector3(this.mouse.x, this.mouse.y, 0).unproject(this.camera);

    let intersect;
    this.rayCaster.setFromCamera(pointer, this.camera);

    this.eye = this.camera.getWorldDirection(new Vector3());
    this.getObjectUnitAxes(objUnitX, objUnitY, objUnitZ);

    this.object.matrix.decompose(
      objPosition,
      objQuaternion,
      objScale
    );

    intersect = this.rayCaster.intersectObject(this.facePicker, true)[0] || false;

    if (intersect) {

      this.dragMode = DRAG_MODE.FACE;
      this.dragAxis = intersect.object.name;
      this.dragDistance = 0;
      this.dragStartPosition = intersect.point;

      if ('L' === this.dragAxis || 'R' === this.dragAxis) this.setIntersectPlane(objUnitX);
      else if ('U' === this.dragAxis || 'D' === this.dragAxis) this.setIntersectPlane(objUnitY);
      else if ('T' === this.dragAxis || 'B' === this.dragAxis) this.setIntersectPlane(objUnitZ);

      this.isDragging = true;
      event.preventDefault();

      return;

    }

    if (!this.isDragging) {
      this.dragMode = undefined;
      lastEventTick = -Config.ShowTime - 1;
    }

  };

  this.pointerMove = function (event, pointer) {

    if (!this.object) return;

    this.rayCaster.setFromCamera(pointer, this.camera);

    if (!this.isDragging) {

      let intersect;

      faceHoverPickerName = undefined;

      intersect = this.rayCaster.intersectObject(this.facePicker, true)[0] || false;

      if (intersect) {

        faceHoverPickerName = intersect.object.name;
        return;

      }

      return;

    }

    event.preventDefault();

    this.dragEndPosition = new Vector3(this.mouse.x, this.mouse.y, 0).unproject(this.camera);

    let intersect = this.rayCaster.intersectObject(this.intersectPlane, true)[0] || false;

    if (intersect) {

      let ind = getAxisInd(this.dragAxis);
      let direction = getDirection(this.dragAxis);
      this.dragDistance = intersect.point.clone()
        .sub(boundingBoxCenter)
        .dot(getUnit(this.dragAxis)) * direction - boundingBoxSize.getComponent(ind) / 2;

      return;

    }

  };

  this.pointerUp = function (event, pointer) {

    this.isDragging = false;

    if ((pointer.type === 'mouse' && pointer.button !== 0) || !this.dragMode) return;
    lastEventTick = curTick;

    if (this.visible && this.object) {

      faceHoverPickerName = undefined;

      if (this.dragMode) {

        this.currentAxis = this.dragAxis;
        this.distance = this.dragDistance;

        scope.dispatchEvent({type: 'change'});

      }

      this.dragMode = undefined;
      this.dragAxis = undefined;
      this.dragDistance = 0;

    }
  };

  function onPointerDown(event) {
    scope.pointerDown(event, getPointer(event));
  }

  function onTouchDown(event) {
    scope.elemRect = scope.domElement.getBoundingClientRect();
    scope.pointerDown(event, getPointer(event));
  }

  function onPointerMove(event) {
    scope.pointerMove(event, getPointer(event));
  }

  function onPointerUp(event) {
    scope.pointerUp(event, getPointer(event));
  }

  this.createBoundingBoxLines = function () {
    let group = new Group();

    let lineMaterial = new LineMaterial({
      color: 0x0D0032,
      linewidth: 1,
      gapSize: 0.75,
      dashSize: 0.75,
      dashScale: 1,
      dashed: true
    });
    lineMaterial.defines.USE_DASH = "";

    for (let i = 0; i < 13; ++i) {
      let lineGeometry = new LineGeometry();
      lineGeometry.setPositions(new Array(6).fill(0));

      let line = new Line2(lineGeometry, lineMaterial);
      line.computeLineDistances();

      group.add(line);
    }

    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createFacePickerMaterial = function (hover) {

    return new MeshBasicMaterial({
      color: 0x79FF9F,
      transparent: true,
      opacity: hover ? 0.12 : 0,
      side: DoubleSide
    });

  };

  this.createSymFaceMaterial = function () {

    return new MeshBasicMaterial({
      color: 0x79FF9F,
      transparent: true,
      opacity: 0.4,
      side: DoubleSide
    });

  };

  this.createFacePickerUnitForAxis = function (axis) {
    let geometry = new PlaneBufferGeometry(1, 1);

    let hoverPlane = new Mesh(geometry);
    hoverPlane.name = axis;

    return hoverPlane;
  };

  this.createFacePicker = function () {
    let group = new Group();

    for (let axis in FACE_PICKER_INFO)
      group.add(this.createFacePickerUnitForAxis(axis));

    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createSymFace = function () {
    let geometry = new PlaneBufferGeometry(1, 1);
    let mesh = new Mesh(geometry);

    this.controlGroup.add(mesh);
    return mesh;
  };

  this.materials = {
    face: {
      hover: this.createFacePickerMaterial(true),
      normal: this.createFacePickerMaterial(false)
    },
    symFace: this.createSymFaceMaterial(),
    object: createGhostedMaterial(0x002000)
  };

  // Children
  this.controlGroup = new Group();
  this.controlGroup.name = `mirror-control-group`;
  this.controlGroup.matrixAutoUpdate = false;

  this.boundingBoxLines = this.createBoundingBoxLines();
  this.facePicker = this.createFacePicker();
  this.symFace = this.createSymFace();

  this.controlScene.add(this.controlGroup);
  this.controlScene.add(this.intersectPlane);
};

MirrorControl.prototype = Object.assign(Object.create(Object3D.prototype), Object.create(EventDispatcher.prototype),
  {

    constructor: MirrorControl,

    isMirrorControls: true

  });

export {MirrorControl};