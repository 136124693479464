import {
  IEntityChatSessionsState,
  ChatSessionsActionTypes,
  REMOVE_CHAT_SESSION,
  REPLACE_CHAT_SESSIONS, ADD_CHAT_SESSIONS, ADD_CHAT_SESSION_TYPING_USERNAME
} from '../../types/entity/chat-sessions';
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';

const xor = (a: any, b: any) => !!((!!a as any) ^ (!!b as any));

const initialState: IEntityChatSessionsState = {
  byId: {},
  allIds: []
};

export function entityChatSessionsReducer(
  state = initialState,
  action: ChatSessionsActionTypes
): IEntityChatSessionsState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case REPLACE_CHAT_SESSIONS: {
      return {
        byId: lod.keyBy(action.payload.chatSessions, 'id'),
        allIds: lod.map(action.payload.chatSessions, 'id')
      };
    }
    case ADD_CHAT_SESSIONS: {
      return {
        byId: {
          ...state.byId,
          ...lod.keyBy(action.payload.chatSessions, 'id')
        },
        allIds: [
          ...state.allIds,
          ...lod.map(action.payload.chatSessions, 'id').filter(id => !state.byId[id])
        ]
      };
    }
    case ADD_CHAT_SESSION_TYPING_USERNAME: {
      const {[action.payload.id]: value} = state.byId;
      if (value) {
        if (xor(action.payload.typing, value.typingUsernames.includes(action.payload.username))) {
          return {
            byId: {
              ...state.byId,
              [action.payload.id]: {
                ...value,
                typingUsernames: lod.xor(value.typingUsernames, [action.payload.username])
              }
            },
            allIds: state.allIds
          };
        }
      }
      return state;
    }
    case REMOVE_CHAT_SESSION: {
      return {
        byId: lod.omit(state.byId, action.payload.id),
        allIds: state.allIds.filter(id => id !== action.payload.id)
      };
    }
    default:
      return state;
  }
}