import {apiRootPath, s3RootPath} from "./const";
import axios from 'axios';
import {IChannelRoute, IChatSession, IPostProject, IProject, IProjectInfo, IScreenshot} from "../store/types/types";
import {filterChatSessions} from "./chat";
import {encodeWompObjectRef, WompObjectRef} from "../peregrine/WompObject";
import {defaultScene, IModelPricing} from "../peregrine/processor";
import lod from "lodash";

export function filterProjects(projects: any[]): IProject[] {
  return projects.map((project: any) => filterProject(project));
}

export function filterChannels(projects: any[]): { [key: number]: { [key: number]: IChannelRoute } } {
  return lod.mapValues(lod.keyBy(projects, 'id'), project =>
    lod.keyBy(project.channelRoutes, 'id')
  );
}

export function filterProject(project: any): IProject {
  return {
    ...project,
    screenshotIds: project.screenshots === undefined ? [] : project.screenshots.map((s: { id: number; }) => s.id),
    attachments: project.attachments === undefined ? [] : project.attachments,
    projectAccesses: project.projectAccesses === undefined ? [] : project.projectAccesses,
    scn: defaultScene,
    scene: undefined,
    thumbnailZoom: +project.thumbnailZoom,
    thumbnailWidth: +project.thumbnailWidth,
    thumbnailHeight: +project.thumbnailHeight,
    privateChatSession: undefined,
    privateChatSessionId: project.privateChatSessionId ? project.privateChatSessionId : (project.privateChatSession ? project.privateChatSession.id : 0),
    publicChatSession: undefined,
    publicChatSessionId: project.publicChatSessionId ? project.publicChatSessionId : (project.publicChatSession ? project.publicChatSession.id : 0),
    bugChatSession: undefined,
    bugChatSessionId: project.bugChatSessionId ? project.bugChatSessionId : (project.bugChatSession ? project.bugChatSession.id : 0),
    otherChatSessions: undefined,
    channelRoutes: undefined,
    otherChatSessionIds: project.otherChatSessionIds ? project.otherChatSessionIds : (project.otherChatSessions ? project.otherChatSessions.map((c: any) => c.id) : []),
    renderSessions: project.renderSessions ? (project.renderSessions.map((s: any) => ({...s, createdDate: new Date(s.createdDate)}))) : [],
    lastModifiedDate: new Date(project.lastModifiedDate),
    createdDate: new Date(project.createdDate),
    digitalMaterials: undefined,
    directoryId: project.directoryId || 0
  };
}

export function filterScreenshots(project: any): IScreenshot[] {
  return project.screenshots.map((screenshot: any) => {
    return {
      ...screenshot,
      project: undefined,
      projectId: screenshot.project
    };
  });
}

export function filterProjectChatSessions(project: any): IChatSession[] {
  return filterChatSessions([project.privateChatSession, project.publicChatSession, project.botChatSession, ...project.otherChatSessions]);
}

export function _fetchProjects(mode: string, rootId: number, sortBy: string, username: string, needle: string, from: number, count: number) {
  return axios.get(apiRootPath + `/api/get-dps`, {
    params: {mode, rootId, sortBy, needle, username, from, count}
  });
}

export function _postDuplicate(projectId: number, fork: boolean) {
  return axios.post(apiRootPath + `/api/post-dp-duplicate`, {projectId, fork});
}

export function _removeScreenshots(projectId: number, screenshotIds: number[]) {
  return axios.post(apiRootPath + `/api/remove-dp-screenshots`, {projectId, screenshotIds});
}

export function _postShare(projectId: number) {
  return axios.post(apiRootPath + `/api/post-dp-share`, {projectId});
}

export function _postUnshare(projectId: number) {
  return axios.post(apiRootPath + `/api/post-dp-unshare`, {projectId});
}

export function _postRoute(projectId: number, routeId: number, commandCount: number, data: any, files: any[]) {
  let formData = new FormData();
  formData.set('data', JSON.stringify(data));
  formData.set('projectId', '' + projectId);
  formData.set('routeId', '' + routeId);
  formData.set('commandCount', '' + commandCount);
  for (let file of files) {
    formData.append(file.name, new Blob([file.data]));
  }

  return axios.post(apiRootPath + `/api/post-dp-route`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function _deleteProject(id: number) {
  return axios.delete(apiRootPath + `/api/demo-projects/${id}/`);
}

export function _fetchProject(id: number) {
  return axios.get(apiRootPath + `/api/demo-projects/${id}/`);
}

export function _fetchMediaProject(id: number) {
  return fetch(s3RootPath + `/public/projects/${id}.womp`);
}

export function _fetchSharedProject(slug: string, password: string) {
  return axios.get(apiRootPath + `/api/get-shared-project/${slug}`, {
    params: {password}
  });
}

export function _getScreenshot(id: number) {
  return axios.get(apiRootPath + `/api/get-dp-screenshot/${id}`, {
    responseType: 'arraybuffer'
  });
}

export function _getHeatmapAsync(projectId: number, routeId: number, calcId: string, refs: WompObjectRef[]) {
  return axios.post(apiRootPath + `/api/get-heatmap-async`, {
    projectId,
    routeId,
    calcId,
    refs: refs.map(v => encodeWompObjectRef(v))
  });
}

export function _getDetailInfoAsync(projectId: number, routeId: number, calcId: string, refs: WompObjectRef[]) {
  return axios.post(apiRootPath + `/api/get-detail-info-async`, {
    projectId,
    routeId,
    calcId,
    refs: refs.map(v => encodeWompObjectRef(v))
  });
}

export function _postCreate(directoryId: number, isDirectory: boolean, scene: any, forkFromDefault: boolean) {
  return axios.post(apiRootPath + `/api/post-dp-create`, {directoryId, isDirectory, scene, forkFromDefault});
}

export function _postAddToCart(projectId: number, calcId: string, title: string, model: string, materialId: number, materialName: string, option: string, info: IModelPricing | IProjectInfo, type: string, quantityOrPrice: number, preview: string, username: string) {
  return axios.post(apiRootPath + `/api/post-dp-add-to-cart`, {
    projectId,
    option,
    materialId,
    materialName,
    calcId,
    info,
    title,
    preview,
    type,
    quantityOrPrice,
    model,
    username
  });
}

export function _postScreenshot(projectId: number, data: string) {
  return axios.post(apiRootPath + `/api/post-dp-screenshot`, {projectId, data});
}

export function _postDeleteAttachment(projectId: number, index: number) {
  return axios.post(apiRootPath + `/api/post-dp-remove-attachment`, {projectId, index});
}

export function _postAddAttachments(projectId: number, files: File[]) {
  let formData = new FormData();
  for (let file of files)
    formData.append('attachments', file);

  formData.set('projectId', '' + projectId);
  return axios.post(apiRootPath + `/api/post-dp-add-attachments`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function _postProject(projectId: number, options: IPostProject) {
  return axios.post(apiRootPath + `/api/post-dp`, {projectId, ...options});
}

export function _postLike(id: number, liked: boolean) {
  return axios.post(apiRootPath + `/api/post-dp-like`, {
    id, liked
  });
}

export function _postBookmark(id: number, bookmarked: boolean) {
  return axios.post(apiRootPath + `/api/post-dp-bookmark`, {
    id, bookmarked
  });
}

export function _postChangeCollaborators(id: number, collaborators: string[]) {
  return axios.post(apiRootPath + `/api/post-dp-collaborators`, {
    id, collaborators
  });
}

export function _postAcceptInfoRouteId(routeId: number, sourceRouteId: number, accept: boolean) {
  return axios.post(apiRootPath + `/api/post-dp-accept-info-route-id`, {
    routeId, sourceRouteId, accept
  });
}

export function _postCreateChatSession(projectId: number, title: string, description: string, invitedUserNames: string[], image?: File) {
  let formData = new FormData();
  if (image)
    formData.append('image', image);

  formData.set('projectId', '' + projectId);
  formData.set('title', title);
  formData.set('description', description);
  for (let name of invitedUserNames)
    formData.append('invitedUserNames', name);

  return axios.post(apiRootPath + `/api/post-dp-create-chat-session`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function _getDenoisedImage(imageData: string) {
  return axios.post(apiRootPath + `/api/get-denoised-image`, {
    imageData
  });
}

