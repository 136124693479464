import {
  ADD_WAITERS,
  IEntityWaitersState,
  WaitersActionTypes,
  REMOVE_WAITER,
  REPLACE_WAITERS
} from '../../types/entity/waiters';
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';

const initialState: IEntityWaitersState = {
  byId: {},
  allIds: []
};

export function entityWaitersReducer(
  state = initialState,
  action: WaitersActionTypes
): IEntityWaitersState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case REPLACE_WAITERS: {
      return {
        byId: lod.keyBy(action.payload.waiters, 'id'),
        allIds: lod.map(action.payload.waiters, 'id')
      };
    }
    case REMOVE_WAITER: {
      return {
        byId: lod.omit(state.byId, action.payload.id),
        allIds: state.allIds.filter(id => id !== action.payload.id)
      };
    }
    case ADD_WAITERS: {
      return {
        byId: {
          ...state.byId,
          ...lod.keyBy(action.payload.waiters, 'id')
        },
        allIds: [
          ...state.allIds,
          ...lod.map(action.payload.waiters, 'id').filter(id => !state.byId[id])
        ]
      };
    }
    default:
      return state;
  }
}