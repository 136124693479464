import {
  ADD_CHANNEL_ROUTES,
  ADD_CHANNELS,
  ADD_PROGRESS, CHANGE_PROGRESS_RANGE,
  CHANGE_SHOW_CURSOR,
  CHANGE_SYNC_CAMERA, CHANGE_SYNC_STATUS,
  CLEAR_PROGRESS,
  ProjectActionTypes,
  REMOVE_CHANNEL_ROUTES,
  REMOVE_PROGRESS,
  UPDATE_COMMAND_MANAGER
} from "../../types/logic/project";
import {IChannelRoute} from "../../types/types";
import {ICommandManagerState, IServer, ISimpleCommand} from "../../../app/core";

export function addChannelRoutes(id: number, channelRoutes: {[key: number]: IChannelRoute}): ProjectActionTypes {
  return {
    type: ADD_CHANNEL_ROUTES,
    payload: {
      id,
      channelRoutes
    }
  };
}

export function addChannels(channels: {[key: number]: {[key: number]: IChannelRoute}}): ProjectActionTypes {
  return {
    type: ADD_CHANNELS,
    payload: {
      channels
    }
  };
}

export function removeChannelRoutes(id: number, routeIds: number[]): ProjectActionTypes {
  return {
    type: REMOVE_CHANNEL_ROUTES,
    payload: {
      id,
      routeIds
    }
  };
}

export function updateCommandManager(id: number, newState: ICommandManagerState): ProjectActionTypes {
  return {
    type: UPDATE_COMMAND_MANAGER,
    payload: {
      id,
      newState
    }
  };
}

export function changeShowCursor(id: number, username: string, showCursor: boolean): ProjectActionTypes {
  return {
    type: CHANGE_SHOW_CURSOR,
    payload: {
      id,
      username,
      showCursor
    }
  };
}

export function changeSyncCamera(id: number, username: string, syncCamera: boolean): ProjectActionTypes {
  return {
    type: CHANGE_SYNC_CAMERA,
    payload: {
      id,
      username,
      syncCamera
    }
  };
}

export function changeSyncStatus(id: number, routeStartCursor: number, serverCommands: ISimpleCommand[], serverCursor: number): ProjectActionTypes {
  return {
    type: CHANGE_SYNC_STATUS,
    payload: {
      id,
      serverCursor,
      routeStartCursor,
      serverCommands
    }
  };
}

export function changeProgressRange(progressFrom?: number, progressTo?: number): ProjectActionTypes {
  return {
    type: CHANGE_PROGRESS_RANGE,
    payload: {
      progressFrom,
      progressTo
    }
  };
}

export function addProgress(progressId: string): ProjectActionTypes {
  return {
    type: ADD_PROGRESS,
    payload: {
      progressId
    }
  };
}

export function removeProgress(progressId: string): ProjectActionTypes {
  return {
    type: REMOVE_PROGRESS,
    payload: {
      progressId
    }
  };
}

export function clearProgress(): ProjectActionTypes {
  return {
    type: CLEAR_PROGRESS,
    payload: {}
  };
}
