import {
  CHANGE_RESET_PASSWORD_ERROR_VALUE,
  CHANGE_RESET_PASSWORD_FIELD_VALUE,
  IResetPasswordState,
  ResetPasswordActionTypes
} from "../../types/ui/reset-password";
import {INIT_STORE} from "../../types/init";

const initialState: IResetPasswordState = {
  fields: {
    'email': '',
    'password': '',
    'passwordConfirm': ''
  },
  errors: {}
};

export function resetPasswordReducer(
  state = initialState,
  action: ResetPasswordActionTypes
): IResetPasswordState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case CHANGE_RESET_PASSWORD_FIELD_VALUE:
      return {
        ...state,
        fields: Object.assign({},
          state.fields,
          {[action.payload.field]: action.payload.value}),
        // sendResetEmail: Object.assign({},
        //   state.sendResetEmail,
        //   {id: undefined})
      };
    case CHANGE_RESET_PASSWORD_ERROR_VALUE:
      return {
        ...state,
        errors: Object.assign({},
          state.errors,
          {[action.payload.field]: action.payload.value})
      };
    default:
      return state;
  }
}