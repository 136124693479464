import {MeshCodec, MeshTrans, three as threeTypes} from "../types/three";
import {
  decodeBuffer,
  dracoDecoder,
  dracoExporter,
  encodeBuffer,
  offExporter,
  offLoader,
  plyExporter,
  plyLoader,
  stlExporter,
  stlLoader
} from "./common";
import {WompMeshData, WompMesh} from "../WompObject";
import {getMeshDataFromThreeGeometry, getThreeGeometryFromMeshData} from "../converter/three";
import {_p} from "../t";
import {IModelMetaInfo} from "../processor";
import numeral from "numeral";

export const BinaryCodecFormats = [MeshCodec.Draco, MeshCodec.Ply, MeshCodec.Stl, MeshCodec.Off];

export function encodeSculptGeometryCodecTrans(geom: WompMeshData) {
  let threeGeom = getThreeGeometryFromMeshData(geom);

  let res: MeshTrans = {};
  if (threeGeom.index) {
    res['index'] = {
      itemSize: threeGeom.index.itemSize,
      buffer: threeGeom.index.array as unknown as ArrayBuffer
    };
  }

  for (let attributeName in threeGeom.attributes) {
    res[attributeName] = {
      itemSize: threeGeom.attributes[attributeName].itemSize,
      buffer: threeGeom.attributes[attributeName].array as unknown as ArrayBuffer
    };
  }

  return [MeshCodec.Trans, res];
}

export function encodeGeometryCodecTrans(geom: WompMeshData) {
  let threeGeom = getThreeGeometryFromMeshData(geom);

  let res: MeshTrans = {};
  if (threeGeom.index) {
    res['index'] = {
      itemSize: threeGeom.index.itemSize,
      buffer: threeGeom.index.array as unknown as ArrayBuffer
    };
  }

  for (let attributeName in threeGeom.attributes) {
    res[attributeName] = {
      itemSize: threeGeom.attributes[attributeName].itemSize,
      buffer: threeGeom.attributes[attributeName].array as unknown as ArrayBuffer
    };
  }

  return res;
}

export async function encodeGeometry(geom: WompMeshData, format: MeshCodec, outputJson: boolean = true) {
  let t0 = performance.now();
  let threeGeom = getThreeGeometryFromMeshData(geom);

  switch (format) {
    case MeshCodec.Draco: {
      return new Promise<any>((resolve, reject) => {
        let res = dracoExporter.parse(threeGeom, {});
        let buffer = res.buffer;

        resolve([format, outputJson ? encodeBuffer(buffer) : buffer]);

        console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for draco encode`);
      });
    }
    case MeshCodec.Json: {
      let jsonObject = threeGeom.toJSON();
      delete jsonObject.uuid;

      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for json encode`);
      return [format, jsonObject];
    }
    case MeshCodec.Ply: {
      return new Promise<any>((resolve, reject) => {
        plyExporter.parse(new threeTypes.Mesh(threeGeom), (res) => {
          let buffer = res as unknown as ArrayBuffer;

          resolve([format, outputJson ? encodeBuffer(buffer) : buffer]);
          console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for ply encode`);
        }, {binary: true, littleEndian: true});
      });
    }
    case MeshCodec.Stl: {
      return new Promise<any>((resolve, reject) => {
        let res = stlExporter.parse(new threeTypes.Mesh(threeGeom), {binary: true});
        let buffer = (res as unknown as DataView).buffer;

        resolve([format, outputJson ? encodeBuffer(buffer) : buffer]);
        console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for stl encode`);
      });
    }
    case MeshCodec.Off: {
      return new Promise<any>((resolve, reject) => {
        let res = offExporter.parse(new threeTypes.Mesh(threeGeom));

        if (!outputJson) {
          let buffer = new ArrayBuffer(res.length);
          let subView = new DataView(buffer);
          for (let i = 0; i < res.length; ++i)
            subView.setUint8(i, res.charCodeAt(i));
          resolve([format, buffer]);
        } else {
          resolve([format, res]);
        }

        console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for off encode`);
      });
    }
    case MeshCodec.Trans:
      let res: MeshTrans = {};
      if (threeGeom.index) {
        res['index'] = {
          itemSize: threeGeom.index.itemSize,
          buffer: threeGeom.index.array as unknown as ArrayBuffer
        };
      }

      for (let attributeName in threeGeom.attributes) {
        res[attributeName] = {
          itemSize: threeGeom.attributes[attributeName].itemSize,
          buffer: threeGeom.attributes[attributeName].array as unknown as ArrayBuffer
        };
      }
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for trans encode`);

      return [format, res];
  }

  return [format, new ArrayBuffer(0)];
}

export function disposeDracoWorkers() {
  dracoDecoder.dispose();
}

function getBufferAttribute(type: any, buffer: any, itemSize: number) {
  if (buffer.length !== undefined) {
    return new threeTypes.BufferAttribute(buffer, itemSize);
  } else {
    let arr: number[] = [];
    for (let key in buffer) {
      if (isFinite(+key))
        arr[+key] = buffer[key];
    }
    return new threeTypes.BufferAttribute(new type(arr), itemSize);
  }
}

export async function decodeGeometry(data: any) {
  let t0 = performance.now();
  switch (data[0]) {
    case MeshCodec.Draco: {
      const blob = new Blob([typeof data[1] === 'string' ? decodeBuffer(data[1]) : data[1]]);
      if (blob.size > 0) {
        const url = URL.createObjectURL(blob);

        return new Promise<WompMeshData>((resolve, reject) => {
          dracoDecoder.load(url, (geometry) => {
            resolve(getMeshDataFromThreeGeometry(geometry));
            console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for draco decode`);
          });
        });
      } else {
        return {};
      }
    }
    case MeshCodec.Json: {
      let ret = getMeshDataFromThreeGeometry(new threeTypes.BufferGeometryLoader().parse(data[1]));
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for json decode`);
      return ret;
    }
    case MeshCodec.Ply: {
      let ret = getMeshDataFromThreeGeometry(plyLoader.parse(typeof data[1] === 'string' ? decodeBuffer(data[1]) : data[1]));
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for ply decode`);
      return ret;
    }
    case MeshCodec.Stl: {
      let ret = getMeshDataFromThreeGeometry(stlLoader.parse(typeof data[1] === 'string' ? decodeBuffer(data[1]) : data[1]));
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for stl decode`);
      return ret;
    }
    case MeshCodec.Off: {
      let ret = getMeshDataFromThreeGeometry(offLoader.parse(data[1]));
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for off decode`);
      return ret;
    }
    case MeshCodec.Trans: {
      let ret = new threeTypes.BufferGeometry();
      for (let attributeName in data[1]) {
        let attrData = data[1][attributeName];
        if (attributeName === 'index')
          ret.index = getBufferAttribute(Uint32Array, attrData.buffer, attrData.itemSize);
        else
          ret.attributes[attributeName] = getBufferAttribute(Float32Array, attrData.buffer, attrData.itemSize);
      }
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for trans decode`);
      return getMeshDataFromThreeGeometry(ret);
    }
  }
  return {};
}

function isThreeIdentity(mat: threeTypes.Matrix4) {
  let offset = 0;

  for (let i = 0; i < 16; ++i) {
    offset += Math.abs((i % 5 === 0 ? 1 : 0) - mat.elements[i]);
  }

  return offset < 0.0001;
}

export function getBoundingBoxFromModelMetaInfo(global: boolean, metaInfo: IModelMetaInfo) {

  if (global) {

    return new threeTypes.Box3(
      new threeTypes.Vector3(
      metaInfo.position[0] - metaInfo.globalSize[0] / 2,
      metaInfo.position[1] - metaInfo.globalSize[1] / 2,
      metaInfo.position[2] - metaInfo.globalSize[2] / 2
      ),
      new threeTypes.Vector3(
        metaInfo.position[0] + metaInfo.globalSize[0] / 2,
        metaInfo.position[1] + metaInfo.globalSize[1] / 2,
        metaInfo.position[2] + metaInfo.globalSize[2] / 2
      )
    );

  } else {

    return new threeTypes.Box3(
      new threeTypes.Vector3(
        metaInfo.orgCenter[0] + metaInfo.translate[0] - metaInfo.localSize[0] / 2,
        metaInfo.orgCenter[1] + metaInfo.translate[1] - metaInfo.localSize[1] / 2,
        metaInfo.orgCenter[2] + metaInfo.translate[2] - metaInfo.localSize[2] / 2
      ),
      new threeTypes.Vector3(
        metaInfo.orgCenter[0] + metaInfo.translate[0] + metaInfo.localSize[0] / 2,
        metaInfo.orgCenter[1] + metaInfo.translate[1] + metaInfo.localSize[1] / 2,
        metaInfo.orgCenter[2] + metaInfo.translate[2] + metaInfo.localSize[2] / 2
      )
    );

  }

}

export function getBoundingBoxFromObject3D(...objects: threeTypes.Object3D[]) {

  if (objects.length > 1) {

    let box = new threeTypes.Box3();

    for (let object of objects) {

      box.union(getBoundingBoxFromObject3D(object));

    }

    return box;
  }

  if (objects.length === 1) {

    let object = objects[0];

    if (object.children.length > 0) {

      let box = new threeTypes.Box3();

      for (let obj of object.children) {

        box.union(getBoundingBoxFromObject3D(obj));

      }

      return box;

    } else {

      //@ts-ignore
      if (object.isMesh) {

        return getBoundingBoxFromThreeMesh(object as threeTypes.Mesh);

      }

    }

  }

  return new threeTypes.Box3();
}

export function getBoundingBoxFromThreeMesh(mesh: threeTypes.Mesh): threeTypes.Box3 {

  let minX = +Infinity, minY = +Infinity, minZ = +Infinity, maxX = -Infinity, maxY = -Infinity, maxZ = -Infinity;

  let geometry = mesh.geometry as threeTypes.BufferGeometry;

  if (geometry.attributes.position) {

    let position = geometry.attributes.position.array;
    mesh.updateWorldMatrix(true, false);

    if (isThreeIdentity(mesh.matrixWorld)) {

      for (let i = 0; i < position.length; i += 3) {

        minX = Math.min(minX, position[i]);
        minY = Math.min(minY, position[i + 1]);
        minZ = Math.min(minZ, position[i + 2]);

        maxX = Math.max(maxX, position[i]);
        maxY = Math.max(maxY, position[i + 1]);
        maxZ = Math.max(maxZ, position[i + 2]);

      }

    } else {

      let vec = new threeTypes.Vector3();

      for (let i = 0; i < position.length; i += 3) {

        vec.set(position[i], position[i + 1], position[i + 2]);

        vec.applyMatrix4(mesh.matrixWorld);

        minX = Math.min(minX, vec.x);
        minY = Math.min(minY, vec.y);
        minZ = Math.min(minZ, vec.z);

        maxX = Math.max(maxX, vec.x);
        maxY = Math.max(maxY, vec.y);
        maxZ = Math.max(maxZ, vec.z);

      }

    }

  }

  return new threeTypes.Box3(new threeTypes.Vector3(minX, minY, minZ), new threeTypes.Vector3(maxX, maxY, maxZ));

}

export async function encodeWompMesh(mesh: WompMesh, format: MeshCodec, outputJson: boolean = true) {

  return {'geometry': await encodeGeometry(mesh.geometry, format, outputJson), 'matrix': _p(mesh.matrix)};

}
