import {ComponentTypes} from "../types";
import {pigeon as pigeonTypes} from "../../../types";
import {createSimpleGumball, IRenderedObjectInternal} from "../gumball";
import {createObjectFromBrepData, getCurveFromRef, Param_Brep, Param_Curve, ParamTitles} from "../../parameter";
import {Calc} from "../../calc";
import {ObjectTypes} from "../../xobject";
import {mat4} from "gl-matrix";
import {BasicObjectMapper, ObjectFuncParameter, PredictedResult} from "../base";

export class SurfaceGenerator extends BasicObjectMapper {
  static create() {
    let calc = createSimpleGumball();
    calc.component = ComponentTypes.SurfaceGenerator;
    calc.title = 'surface';

    let curveParam = Param_Curve.create(calc, ParamTitles.Object, true, false, false);
    let surfaceParam = Param_Brep.create(calc, ParamTitles.Surface, false, false, true);

    calc.addParameter(curveParam);
    calc.addParameter(surfaceParam);

    return calc;
  }

  static getParameters(calc: Calc, obj: IRenderedObjectInternal) {
    return {
      desc: `surface[${obj.value.objectId}]`,
      matrix: obj.value.matrix,
      parameters: {}
    };
  }

  static predictResult(calc: Calc, obj: IRenderedObjectInternal) {
    if (obj.valueType === ObjectTypes.Curve)
      return PredictedResult.Brep;
    return PredictedResult.Ignore;
  }

  static async mapObject(calc: Calc, obj: IRenderedObjectInternal, parameters: ObjectFuncParameter) {
    if (obj.valueType === ObjectTypes.Curve) {
      let curve = getCurveFromRef(calc, obj.value);
      mat4.identity(curve.matrix);

      let brepData = await pigeonTypes.sweep(curve);

      return createObjectFromBrepData(calc, brepData, parameters.desc, parameters.matrix);
    }

    return obj;
  }
}
