import * as React from 'react';
import {pure} from 'recompose';

interface IProgressBarProps extends React.HTMLProps<HTMLDivElement> {
  percentage: number
}

const ProgressBar: React.FC<IProgressBarProps> = ({className, percentage, ...props}) => {
  return (
    <div {...props} className={(className ? "progress-bar " + className : "progress-bar")}>
      <div style={{width: `${Math.round(percentage)}%`}}/>
    </div>
  );
};

export default pure(ProgressBar);
