import {proxy, wrap} from "comlink";
import {MeshCodec, sculpt as sculptTypes, SculptMeshCodec} from "../../types";
import {BinaryCodecFormats, decodeBuffer, decodeGeometry, encodeGeometry} from "../../utils";
import {getSculptGeometryFromGeometry} from "../../converter";
import {WompMeshData} from "../../WompObject";
import {IFixReorientGeometryConfig} from "./fix";
import Mutex from "async-mutex/lib/Mutex";

// testWorker()

function testCallback() {
  console.log("test callback");
}

export function testWorker() {
  console.log('Do something');
  const worker = new Worker('./operation-worker', {name: 'operation-worker', type: 'module'});
  const workerApi = wrap<import('./operation-worker').PeregrineWorker>(worker);
  workerApi.test(proxy(testCallback));
  console.log('Do another thing');
}

export async function weldGeometryOnWorker(geom: WompMeshData, thresholdAngle: number, useGroup?: boolean, onProgress?: (percentage: number) => void) {
  let geomTrans = (await encodeGeometry(geom, MeshCodec.Trans))[1];
  const worker = new Worker('./operation-worker', {name: 'operation-worker', type: 'module'});
  const workerApi = wrap<import('./operation-worker').PeregrineWorker>(worker);
  return new Promise<WompMeshData>((resolve, reject) => {
    console.log('weld worker started');
    workerApi.weldGeometryTrans(geomTrans, thresholdAngle, useGroup, onProgress ? proxy(onProgress) : undefined).then(async resultTrans => {
      console.log('weld worker success');
      let result = await decodeGeometry([MeshCodec.Trans, resultTrans]);
      resolve(result);
    }).catch((err: any) => {
      console.log('weld worker failure', err);
      reject();
    }).finally(() => {
      worker.terminate();
    });
  });
}

export async function splitGeometryOnWorker(geom: WompMeshData, onProgress?: (percentage: number) => void) {
  let geomTrans = (await encodeGeometry(geom, MeshCodec.Trans))[1];
  const worker = new Worker('./operation-worker', {name: 'operation-worker', type: 'module'});
  const workerApi = wrap<import('./operation-worker').PeregrineWorker>(worker);
  return new Promise<WompMeshData[]>((resolve, reject) => {
    console.log('split worker started');
    workerApi.splitGeometryTrans(geomTrans, onProgress ? proxy(onProgress) : undefined).then(async resultTrans => {
      console.log('split worker success');
      let results = Promise.all(resultTrans.map(async resultTran => decodeGeometry([MeshCodec.Trans, resultTran])));
      resolve(results);
    }).catch((err: any) => {
      console.log('split worker failure', err);
      reject();
    }).finally(() => {
      worker.terminate();
    });
  });
}

export async function fixGeometryOnWorker(geom: WompMeshData, config?: IFixReorientGeometryConfig, onProgress?: (percentage: number) => void) {
  let geomTrans = (await encodeGeometry(geom, MeshCodec.Trans))[1];
  const worker = new Worker('./operation-worker', {name: 'operation-worker', type: 'module'});
  const workerApi = wrap<import('./operation-worker').PeregrineWorker>(worker);
  return new Promise<WompMeshData>((resolve, reject) => {
    console.log('fix worker started');
    workerApi.fixGeometryTrans(geomTrans, config, onProgress ? proxy(onProgress) : undefined).then(async resultTran => {
      console.log('fix worker success');
      let result = await decodeGeometry([MeshCodec.Trans, resultTran]);
      resolve(result);
    }).catch((err: any) => {
      console.log('fix worker failure', err);
      reject();
    }).finally(() => {
      worker.terminate();
    });
  });
}

export async function turnOutGeometryOnWorker(geom: WompMeshData, onProgress?: (percentage: number) => void) {
  let geomTrans = (await encodeGeometry(geom, MeshCodec.Trans))[1];
  const worker = new Worker('./operation-worker', {name: 'operation-worker', type: 'module'});
  const workerApi = wrap<import('./operation-worker').PeregrineWorker>(worker);
  return new Promise<WompMeshData>((resolve, reject) => {
    console.log('turn out worker started');
    workerApi.turnOutGeometryTrans(geomTrans, onProgress ? proxy(onProgress) : undefined).then(async resultTran => {
      console.log('turn out worker success');
      let result = await decodeGeometry([MeshCodec.Trans, resultTran]);
      resolve(result);
    }).catch((err: any) => {
      console.log('turn out worker failure', err);
      reject();
    }).finally(() => {
      worker.terminate();
    });
  });
}

export async function attachGeometriesOnWorker(geomA: WompMeshData, geomB: WompMeshData, maskingA?: number, maskingB?: number, onProgress?: (percentage: number) => void) {
  let geomATrans = (await encodeGeometry(geomA, MeshCodec.Trans))[1];
  let geomBTrans = (await encodeGeometry(geomB, MeshCodec.Trans))[1];
  const worker = new Worker('./operation-worker', {name: 'operation-worker', type: 'module'});
  const workerApi = wrap<import('./operation-worker').PeregrineWorker>(worker);
  return new Promise<WompMeshData>((resolve, reject) => {
    console.log('attach worker started');
    workerApi.attachGeometriesTrans(geomATrans, geomBTrans, maskingA, maskingB, onProgress ? proxy(onProgress) : undefined).then(async resultTran => {
      console.log('attach worker success');
      let result = await decodeGeometry([MeshCodec.Trans, resultTran]);
      resolve(result);
    }).catch((err: any) => {
      console.log('attach worker failure', err);
      reject();
    }).finally(() => {
      worker.terminate();
    });
  });
}

export async function stlInfoGeometryOnWorker(data: ArrayBuffer, onProgress?: (percentage: number) => void) {
  const worker = new Worker('./operation-worker', {name: 'operation-worker', type: 'module'});
  const workerApi = wrap<import('./operation-worker').PeregrineWorker>(worker);
  return new Promise<any>((resolve, reject) => {
    console.log('stl info worker started');
    workerApi.stlInfoGeometryTrans(data, onProgress ? proxy(onProgress) : undefined).then(async (result: any) => {
      console.log('stl info worker success');
      resolve(result);
    }).catch((err: any) => {
      console.log('stl info worker failure', err);
      reject();
    }).finally(() => {
      worker.terminate();
    });
  });
}

export async function stlDecodeGeometryOnWorker(data: ArrayBuffer, onProgress?: (percentage: number) => void) {
  const worker = new Worker('./operation-worker', {name: 'operation-worker', type: 'module'});
  const workerApi = wrap<import('./operation-worker').PeregrineWorker>(worker);
  return new Promise<WompMeshData>((resolve, reject) => {
    console.log('stl decode worker started');
    workerApi.stlDecodeGeometryTrans(data, onProgress ? proxy(onProgress) : undefined).then(async resultTran => {
      console.log('stl decode worker success');
      let result = await decodeGeometry([MeshCodec.Trans, resultTran]);
      resolve(result);
    }).catch((err: any) => {
      console.log('stl decode worker failure', err);
      reject();
    }).finally(() => {
      worker.terminate();
    });
  });
}

export async function encodeGeometryOnWorker(geom: WompMeshData, format: MeshCodec, outputJson: boolean = true) {
  if (format === MeshCodec.None) {
    return [format, new ArrayBuffer(0)];
  } else if (format === MeshCodec.Draco || format === MeshCodec.Trans) {
    return encodeGeometry(geom, format, outputJson);
  } else {
    let geomTrans = (await encodeGeometry(geom, MeshCodec.Trans))[1];
    const worker = new Worker('./operation-worker', {name: 'operation-worker', type: 'module'});
    const workerApi = wrap<import('./operation-worker').PeregrineWorker>(worker);
    return new Promise<any>((resolve, reject) => {
      console.log('encode geometry worker started');
      workerApi.encodeGeometryTrans(geomTrans, format, outputJson).then(async (result: any) => {
        console.log('encode geometry worker success');
        resolve(result);
      }).catch((err: any) => {
        console.log('encode geometry worker failure', err);
        reject();
      }).finally(() => {
        worker.terminate();
      });
    });
  }
}

const dracoDecodeMutex = new Mutex();
export async function decodeGeometryOnWorker(data: any) {
  if (data[0] === MeshCodec.Draco) {
    return dracoDecodeMutex.runExclusive(() => {
      return decodeGeometry(data);
    });
  } else if (data[0] === MeshCodec.Trans) {
    return decodeGeometry(data);
  } else {
    const worker = new Worker('./operation-worker', {name: 'operation-worker', type: 'module'});
    const workerApi = wrap<import('./operation-worker').PeregrineWorker>(worker);
    return new Promise<WompMeshData>((resolve, reject) => {
      console.log('decode geometry worker started');
      workerApi.decodeGeometryTrans(data).then(async (result: any) => {
        console.log('decode geometry worker success');
        let geomTrans = await decodeGeometry([MeshCodec.Trans, result]);
        resolve(geomTrans);
      }).catch((err: any) => {
        console.log('decode geometry worker failure', err);
        reject();
      }).finally(() => {
        worker.terminate();
      });
    });
  }
}

export async function decodeSculptGeometryOnWorker(data: any) {
  if (data[0] === SculptMeshCodec.Draco) {
    let geometry = await dracoDecodeMutex.runExclusive(() => {
      return decodeGeometry([data[0] - 100, data[1]]);
    })
    return getSculptGeometryFromGeometry(geometry);
  } else if (data[1] === SculptMeshCodec.Trans) {
    let geometry = await decodeGeometry([data[0] - 100, data[1]]);
    return getSculptGeometryFromGeometry(geometry);
  } else {
    const worker = new Worker('./operation-worker', {name: 'operation-worker', type: 'module'});
    const workerApi = wrap<import('./operation-worker').PeregrineWorker>(worker);
    return new Promise<sculptTypes.MeshData>((resolve, reject) => {
      console.log('decode sculpt geometry worker started');
      workerApi.decodeGeometryTrans([data[0] - 100, data[1]]).then(async (result: any) => {
        console.log('decode sculpt geometry worker success');
        let geometry = await decodeGeometry([MeshCodec.Trans, result]);
        let sculptGeom = getSculptGeometryFromGeometry(geometry);
        if (sculptGeom)
          resolve(sculptGeom);
        else
          reject();
      }).catch((err: any) => {
        console.log('decode sculpt geometry worker failure', err);
        reject();
      }).finally(() => {
        worker.terminate();
      });
    });
  }
}

export async function decodeGeometriesOnWorker(data: any) {
  let geoms = [];
  if (BinaryCodecFormats.includes(data[0])) {
    let totalBuffer = typeof data[1] === 'string' ? decodeBuffer(data[1]) : data[1];
    let view = new DataView(totalBuffer);
    if (totalBuffer.byteLength >= 4) {
      let bufferCnt = view.getUint32(0, true);
      let offset = 4 * (bufferCnt + 1);

      for (let i = 0; i < bufferCnt; ++i) {
        let bufferLength = view.getUint32((i + 1) * 4, true);
        let buffer = new ArrayBuffer(bufferLength);
        let subView = new Uint8Array(buffer);

        for (let i = 0; i < bufferLength; ++i) {
          subView[i] = view.getUint8(i + offset);
        }

        offset += bufferLength;

        geoms.push(await decodeGeometryOnWorker([data[0], buffer]));
      }
    }
  }

  return geoms;
}

export async function encodeGeometriesOnWorker(geoms: WompMeshData[], format: MeshCodec): Promise<any[]> {
  let buffers = [];
  let totalLength = 4;
  if (BinaryCodecFormats.includes(format)) {
    for (let geom of geoms) {
      let buffer: ArrayBuffer = (await encodeGeometryOnWorker(geom, format, false))[1];
      totalLength += buffer.byteLength + 4;
      buffers.push(buffer);
    }
  }

  let totalBuffer = new ArrayBuffer(totalLength);
  let view = new DataView(totalBuffer);
  let offset = 4;
  view.setUint32(0, buffers.length, true);

  for (let buffer of buffers) {
    view.setUint32(offset, buffer.byteLength, true);
    offset += 4;
  }

  for (let buffer of buffers) {
    let subView = new Uint8Array(buffer);
    for (let i = 0; i < buffer.byteLength; ++i) {
      view.setUint8(i + offset, subView[i]);
    }
    offset += buffer.byteLength;
  }

  return [format, totalBuffer];
}

// export async function runWorker(type: WorkerTypes, title: string, params: any) {
//   let result: Promise<any> | undefined;
//   let id = peregrineId();
//
//   const updateProgress = (percentage: number) => {
//     if (params.updateProgress)
//       params.updateProgress(id, percentage);
//   };
//
//   switch (type) {
//     case WorkerTypes.Split:
//       result = splitGeometryOnWorker(params.geom, updateProgress);
//       break;
//     case WorkerTypes.Weld:
//       result = weldGeometryOnWorker(params.geom, params.thresholdAngle, params.useGroup, updateProgress);
//       break;
//     case WorkerTypes.StlInfo:
//       result = stlInfoGeometryOnWorker(params.data, updateProgress);
//       break;
//     case WorkerTypes.StlDecode:
//       result = stlDecodeGeometryOnWorker(params.data, updateProgress);
//       break;
//   }
//   return new Promise<any>((resolve, reject) => {
//     if (result) {
//       result.then(res => {
//         if (params.updateProgress)
//           params.updateProgress(id, 100, true);
//         resolve(res);
//       }).catch(err => {
//         if (params.updateProgress)
//           params.updateProgress(id, 100, false);
//         reject(err);
//       });
//     } else {
//       reject();
//     }
//   });
// }
