import {
  ADD_LIBRARY_ITEMS,
  LibraryItemsActionTypes,
  REMOVE_LIBRARY_ITEMS,
  REPLACE_LIBRARY_ITEMS
} from '../../types/entity/library-items';
import {ILibraryItem} from '../../types/types';

export function replaceLibraryItems(items: ILibraryItem[]): LibraryItemsActionTypes {
  return {
    type: REPLACE_LIBRARY_ITEMS,
    payload: {
      items
    }
  };
}

export function addLibraryItems(items: ILibraryItem[]): LibraryItemsActionTypes {
  return {
    type: ADD_LIBRARY_ITEMS,
    payload: {
      items
    }
  };
}

export function removeLibraryItems(ids: string[]): LibraryItemsActionTypes {
  return {
    type: REMOVE_LIBRARY_ITEMS,
    payload: {
      ids
    }
  };
}
