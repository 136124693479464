import {ComponentTypes} from "../types";
import {pigeon as pigeonTypes} from "../../../types";
import {createGumball} from "../gumball";
import {Param_Brep, ParamTitles} from "../../parameter";
import {Calc} from "../../calc";
import {mat4, vec3} from "gl-matrix";
import {ThreeCylinderGenerator} from "../three-basic";
import {BasicBrepGenerator} from "./basic-gen";

export class CylinderGenerator extends BasicBrepGenerator {
  static defaultParameter = {
    radius: 10,
    height: 20
  };
  static draggingPreview = ThreeCylinderGenerator.generateMesh();

  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.CylinderGenerator;
    calc.title = 'cylinder';

    let cylinderParam = Param_Brep.create(calc, ParamTitles.Cylinder, false, false, true);

    calc.addParameter(cylinderParam);

    return calc;
  }

  static getDesc(calc: Calc) {
    return 'b-cylinder';
  }

  static async generateBrepGeometry(parameter: any = this.defaultParameter) {
    return pigeonTypes.cylinder(
      this.defaultParameter.radius,
      this.defaultParameter.height
    );
  }

  static generateMatrix(parameter: any = this.defaultParameter) {
    return mat4.fromScaling(
      mat4.create(),
      vec3.fromValues(
        parameter.radius / this.defaultParameter.radius,
        parameter.radius / this.defaultParameter.radius,
        parameter.height / this.defaultParameter.height
      )
    );
  }
}