import {INIT_STORE} from "../../types/init";
import {
  ADD_CHAT_BOT_MESSAGE,
  ChatBotActionTypes,
  IChatBotState,
  REPLACE_CHAT_BOT_MESSAGES
} from "../../types/ui/chat-bot";

const initialState: IChatBotState = {
  chatBotMsgs: []
};

export function chatBotReducer(
  state = initialState,
  action: ChatBotActionTypes
): IChatBotState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case ADD_CHAT_BOT_MESSAGE:
      return {
        ...state,
        chatBotMsgs: [...state.chatBotMsgs, action.payload.message]
      };
    case REPLACE_CHAT_BOT_MESSAGES:
      return {
        ...state,
        chatBotMsgs: [...action.payload.messages]
      };
    default:
      return state;
  }
}