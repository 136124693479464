import {
  ApiWaiterActionTypes,
  FETCH_WAITER, FETCH_WAITERS,
  POST_WAITER, POST_WAITER_ACTION
} from "../../types/api/waiter";

export function fetchWaiterStarted(): ApiWaiterActionTypes {
  return {
    type: FETCH_WAITER,
    payload: {
      fetching: true
    }
  };
}

export function fetchWaiterSuccess(): ApiWaiterActionTypes {
  return {
    type: FETCH_WAITER,
    payload: {
      fetching: false,
      success: true
    }
  };
}

export function fetchWaiterFailure(message: string): ApiWaiterActionTypes {
  return {
    type: FETCH_WAITER,
    payload: {
      fetching: false,
      success: false,
      message
    }
  };
}

export function postWaiterFailure(message: string): ApiWaiterActionTypes {
  return {
    type: POST_WAITER,
    payload: {
      posting: false,
      success: false,
      message: message
    }
  };
}

export function postWaiterStarted(): ApiWaiterActionTypes {
  return {
    type: POST_WAITER,
    payload: {
      posting: true
    }
  };
}

export function postWaiterSuccess(id: number): ApiWaiterActionTypes {
  return {
    type: POST_WAITER,
    payload: {
      posting: false,
      success: true,
      id
    }
  };
}

export function postWaiterActionStarted(): ApiWaiterActionTypes {
  return {
    type: POST_WAITER_ACTION,
    payload: {
      posting: true
    }
  };
}

export function postWaiterActionSuccess(): ApiWaiterActionTypes {
  return {
    type: POST_WAITER_ACTION,
    payload: {
      posting: false,
      success: true
    }
  };
}

export function postWaiterActionFailure(message: string): ApiWaiterActionTypes {
  return {
    type: POST_WAITER_ACTION,
    payload: {
      posting: false,
      success: false,
      message: message
    }
  };
}

export function fetchWaitersFailure(message: string): ApiWaiterActionTypes {
  return {
    type: FETCH_WAITERS,
    payload: {
      fetching: false,
      success: false,
      message: message
    }
  };
}

export function fetchWaitersStarted(): ApiWaiterActionTypes {
  return {
    type: FETCH_WAITERS,
    payload: {
      fetching: true
    }
  };
}

export function fetchWaitersSuccess(items: number[]): ApiWaiterActionTypes {
  return {
    type: FETCH_WAITERS,
    payload: {
      fetching: false,
      success: true,
      items: items
    }
  };
}