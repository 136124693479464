export function convertURIToImageData(URI: string, zoom?: number, minWidth?: number, minHeight?: number) {
  return new Promise(function (resolve, reject) {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let image = new Image();
    image.addEventListener('load', function () {
      let ratio = 0;
      if (zoom === undefined) {
        zoom = 1;
      }

      if (minWidth !== undefined) {
        ratio = Math.max(ratio, minWidth / image.width);
      }

      if (minHeight !== undefined) {
        ratio = Math.max(ratio, minHeight / image.height);
      }

      if (ratio === 0) {
        ratio = 1;
      }

      canvas.width = ratio * image.width;
      canvas.height = ratio * image.height;

      if (context !== null) {
        context.drawImage(image, canvas.width * (1 - zoom) / 2, canvas.height * (1 - zoom) / 2, canvas.width * zoom, canvas.height * zoom);
        resolve(context.getImageData(0, 0, canvas.width, canvas.height));
      }
    }, false);
    image.src = URI;
  });
}

export function cropURIToImageData(URI: string, aspectRatio: number) {
  return new Promise(function (resolve, reject) {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let image = new Image();
    image.addEventListener('load', function () {
      let canvasWidth = image.width, canvasHeight = image.height;
      if (canvasWidth / aspectRatio > canvasHeight)
        canvasWidth = canvasHeight * aspectRatio;

      if (canvasHeight * aspectRatio > canvasWidth)
        canvasHeight = canvasWidth / aspectRatio;

      canvas.width = Math.round(canvasWidth);
      canvas.height = Math.round(canvasHeight);

      if (context !== null) {
        context.drawImage(image, (canvasWidth - image.width) / 2, (canvasHeight - image.height) / 2, image.width, image.height);
        resolve(context.getImageData(0, 0, canvas.width, canvas.height));
      }
    }, false);
    image.src = URI;
  });
}

export function getImageData(URI: string): Promise<any> {
  return new Promise(function (resolve, reject) {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let image = new Image();
    image.addEventListener('load', function () {
      let canvasWidth = image.width, canvasHeight = image.height;

      canvas.width = Math.round(canvasWidth);
      canvas.height = Math.round(canvasHeight);

      if (context !== null) {
        context.drawImage(image, (canvasWidth - image.width) / 2, (canvasHeight - image.height) / 2, image.width, image.height);
        resolve({
          imageData: canvas.toDataURL("image/png"),
          width: canvas.width,
          height: canvas.height
        });
      }
    }, false);
    image.src = URI;
  });
}

export function convertImageDataToData(imageData: ImageData) {
  let canvas = document.createElement('canvas');
  let ctx = canvas.getContext('2d');

  canvas.width = imageData.width;
  canvas.height = imageData.height;
  if (ctx !== null)
    ctx.putImageData(imageData, 0, 0);

  return canvas.toDataURL();
}

export const imgCache = {
  __cache: {} as {[key: string]: any},
  read(src: string) {
    if (!this.__cache[src]) {
      this.__cache[src] = new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          this.__cache[src] = true;
          resolve(this.__cache[src]);
        };
        img.src = src;
      }).then((img) => {
        this.__cache[src] = true;
      });
    }
    // if (this.__cache[src] instanceof Promise) {
    //   throw this.__cache[src];
    // }
    return this.__cache[src];
  }
};