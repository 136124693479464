import {three as threeTypes} from "../types/three";
import {WompMeshData, WompMesh} from "../WompObject";
import {mat4, quat, vec3, vec4} from "gl-matrix";

export function getThreeTransformFromMat4(mat: mat4): threeTypes.Matrix4 {
  let transform = new threeTypes.Matrix4();

  transform.set(
    mat[0],
    mat[4],
    mat[8],
    mat[12],
    mat[1],
    mat[5],
    mat[9],
    mat[13],
    mat[2],
    mat[6],
    mat[10],
    mat[14],
    mat[3],
    mat[7],
    mat[11],
    mat[15]
  );

  return transform;
}

export function getMat4FromThreeTransform(matrix: threeTypes.Matrix4): mat4 {
  return mat4.fromValues(
    matrix.elements[0],
    matrix.elements[1],
    matrix.elements[2],
    matrix.elements[3],
    matrix.elements[4],
    matrix.elements[5],
    matrix.elements[6],
    matrix.elements[7],
    matrix.elements[8],
    matrix.elements[9],
    matrix.elements[10],
    matrix.elements[11],
    matrix.elements[12],
    matrix.elements[13],
    matrix.elements[14],
    matrix.elements[15]
  );
}

export function getVec3FromThreeVector(vec: threeTypes.Vector3, plain?: boolean): vec3 {
  return plain ? [vec.x, vec.y, vec.z] : vec3.fromValues(vec.x, vec.y, vec.z);
}

export function getVec4FromThreeVector4(vec: threeTypes.Vector4, plain?: boolean): vec4 {
  return plain ? [vec.x, vec.y, vec.z, vec.w] : vec4.fromValues(vec.x, vec.y, vec.z, vec.w);
}

export function getQuatFromThreeQuaternion(q: threeTypes.Quaternion, plain?: boolean): quat {
  return plain ? [q.x, q.y, q.z, q.w] : quat.fromValues(q.x, q.y, q.z, q.w);
}

export function getThreeVectorFromVec3(vec: vec3): threeTypes.Vector3 {
  return new threeTypes.Vector3(vec[0], vec[1], vec[2]);
}

export function getMeshDataFromThreeGeometry(threeGeom: threeTypes.BufferGeometry) {
  let meshData: WompMeshData = {};

  if (threeGeom.index)
    meshData.face = threeGeom.index.array as Uint32Array;

  if (threeGeom.attributes.position)
    meshData.position = threeGeom.attributes.position.array as Float32Array;

  if (threeGeom.attributes.normal)
    meshData.normal = threeGeom.attributes.normal.array as Float32Array;

  if (threeGeom.attributes.uv)
    meshData.uv = threeGeom.attributes.uv.array as Float32Array;

  if (threeGeom.attributes.color)
    meshData.color = threeGeom.attributes.color.array as Float32Array;

  if (threeGeom.attributes.masking)
    meshData.masking = threeGeom.attributes.masking.array as Float32Array;

  return meshData;
}

export function getThreeGeometryFromMeshData(meshData: WompMeshData) {
  let threeGeom = new threeTypes.BufferGeometry();
  if (meshData.face)
    threeGeom.index = new threeTypes.BufferAttribute(meshData.face, 1);

  if (meshData.position)
    threeGeom.attributes.position = new threeTypes.BufferAttribute(meshData.position, 3);

  if (meshData.normal)
    threeGeom.attributes.normal = new threeTypes.BufferAttribute(meshData.normal, 3);

  if (meshData.uv)
    threeGeom.attributes.uv = new threeTypes.BufferAttribute(meshData.uv, 2);

  if (meshData.color) {
    let itemSize = 3;
    if (meshData.position && meshData.position.length !== meshData.color.length)
      itemSize = 4;
    threeGeom.attributes.color = new threeTypes.BufferAttribute(meshData.color, itemSize);
  }

  if (meshData.masking)
    threeGeom.attributes.masking = new threeTypes.BufferAttribute(meshData.masking, 1);

  return threeGeom;
}

export function getLineSegmentsGeometryFromMeshData(meshData: WompMeshData) {
  let threeGeom = new threeTypes.BufferGeometry();

  if (meshData.face && meshData.position) {
    let positions = new Float32Array(meshData.face.length / 3 * 6);

    let avg = vec3.create();
    for (let i = 0; i < meshData.position.length; i += 3) {
      avg[0] += meshData.position[i] / meshData.position.length;
      avg[1] += meshData.position[i + 1] / meshData.position.length;
      avg[2] += meshData.position[i + 2] / meshData.position.length;
    }
    vec3.scale(avg, avg, 3);

    for (let i = 0; i < meshData.face.length; i += 3) {
      let minDist = +Infinity, minInd = -1, i2 = i * 2;
      for (let j = 0; j < 3; ++j) {
        let faceInd3 = meshData.face[i + j] * 3;
        let pos = vec3.fromValues(meshData.position[faceInd3], meshData.position[faceInd3 + 1], meshData.position[faceInd3 + 2]);
        let dist = vec3.sqrDist(pos, avg);
        if (dist < minDist) {
          minInd = j;
          minDist = dist;
        }
      }

      let fromInd3 = meshData.face[i + (minInd + 1) % 3] * 3;
      let toInd3 = meshData.face[i + (minInd + 2) % 3] * 3;

      positions[i2] = meshData.position[fromInd3];
      positions[i2 + 1] = meshData.position[fromInd3 + 1];
      positions[i2 + 2] = meshData.position[fromInd3 + 2];

      positions[i2 + 3] = meshData.position[toInd3];
      positions[i2 + 4] = meshData.position[toInd3 + 1];
      positions[i2 + 5] = meshData.position[toInd3 + 2];
    }

    threeGeom.attributes.position = new threeTypes.BufferAttribute(positions, 3);
  }

  return threeGeom;
}


export function getMeshFromThreeMesh(threeMesh: threeTypes.Mesh) {

  return new WompMesh(getMeshDataFromThreeGeometry(threeMesh.geometry as threeTypes.BufferGeometry), getMat4FromThreeTransform(threeMesh.matrixWorld));

}
