import * as threeTypes from 'three';
import {BufferGeometryUtils} from "three/examples/jsm/utils/BufferGeometryUtils";

export {threeTypes as three};
export {BufferGeometryUtils};

export enum MeshCodec {
  None = 0,
  Json = 1,
  Draco = 2,
  Ply = 3,
  Stl = 4,
  Off = 5,
  Trans = 6,
}


export type MeshTrans = { [key: string]: { itemSize: number, buffer: ArrayBuffer } }
