import {vec3} from "gl-matrix";

export function evaluateBezier(target: vec3, points: vec3[], b: number, e: number, t: number) {
  let pts = [];
  for (let i = b; i <= e; ++i)
    pts.push(points[i % points.length]);

  if (e - b === 3) {
    vec3.bezier(target, pts[0], pts[1], pts[2], pts[3], t);
  } else if (e - b === 2) {
    vec3.bezier(
      target,
      pts[0],
      vec3.lerp(vec3.create(), pts[0], pts[1], 1.0 / 3),
      vec3.lerp(vec3.create(), pts[1], pts[2], 2.0 / 3),
      pts[2],
      t
    );
  } else {
    vec3.lerp(
      target,
      pts[0],
      pts[pts.length - 1],
      t
    );
  }
}