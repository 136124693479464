import {
  ADD_ORDERS,
  IEntityOrdersState,
  OrdersActionTypes,
  REMOVE_ORDER,
  REPLACE_ORDERS
} from '../../types/entity/orders';
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';

const initialState: IEntityOrdersState = {
  byId: {},
  allIds: []
};

export function entityOrdersReducer(
  state = initialState,
  action: OrdersActionTypes
): IEntityOrdersState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case REPLACE_ORDERS: {
      return {
        byId: lod.keyBy(action.payload.orders, 'id'),
        allIds: lod.map(action.payload.orders, 'id')
      };
    }
    case ADD_ORDERS: {
      return {
        byId: {
          ...state.byId,
          ...lod.keyBy(action.payload.orders, 'id')
        },
        allIds: [
          ...state.allIds,
          ...lod.map(action.payload.orders, 'id').filter(id => !state.byId[id])
        ]
      };
    }
    case REMOVE_ORDER: {
      return {
        byId: lod.omit(state.byId, action.payload.id),
        allIds: state.allIds.filter(id => id !== action.payload.id)
      };
    }
    default:
      return state;
  }
}