import {InitStoreAction} from "../../init";
import {IInitialObjectWizardState} from "./initial-object";
import {IViewTypeWizardState} from "./view-type";
import {ICameraAngleWizardState} from "./camera-angle";
import {IGumballWizardState} from "./gumball";
import {ChangeDigitalMaterialWizardMaterialAction, IDigitalMaterialWizardState} from "./digital-material";
import {ChangeBackDropWizardEnvironmentAction, IBackDropWizardState} from "./back-drop";
import {ChangeLightMapWizardEnvironmentAction, ILightMapWizardState} from "./light-map";
import {ChangeSculptWizardConfigAction, ChangeSculptWizardSubScreenAction, ISculptWizardState} from "./sculpt";
import {IMirrorWizardState} from "./mirror";
import {IWeldWizardState} from "./weld";
import {ILargeFileWizardState} from "./large-file";
import {
  ChangeSculptToolWizardDimensionAction,
  ISculptToolWizardState
} from "./sculpt-tool";
import {IArrayWizardState} from "./array";
import {ChangeMagnetToolWizardDimensionAction, IMagnetToolWizardState} from "./magnet-tool";
import {InitSceneAction} from "../global";

export enum WizardScreen {
  None = 'none',
  InitialObject = 'initialObject',
  ClickDrag = 'clickDrag',
  ViewType = 'viewType',
  CameraAngle = 'cameraAngle',
  SnapMode = 'snapMode',
  Annotate = 'annotate',
  Measure = 'measure',
  Gumball = 'gumball',
  DigitalMaterial = 'digitalMaterial',
  BackDrop = 'backDrop',
  LightMap = 'lightMap',
  Post = 'post',
  Share = 'share',
  Sculpt = 'sculpt',
  Mirror = 'mirror',
  Array = 'array',
  Weld = 'weld',
  Grid = 'grid',
  LargeFile = 'largeFile',
  SculptTool = 'sculptTool',
  MagnetTool = 'magnetTool',
}

export let WizardDefaultInfos = {
  [WizardScreen.None]: {
    placeholder: 'you can say  "sphere" or "cube"',
    minWidth: 280,
    minHeight: 320
  },
  [WizardScreen.InitialObject]: {
    placeholder: 'type anything like "what is this?"',
    minWidth: 280,
    minHeight: 220
  },
  [WizardScreen.ClickDrag]: {
    placeholder: 'you can say  "sphere" or "cube"',
    minWidth: 280,
    minHeight: 320
  },
  [WizardScreen.ViewType]: {
    placeholder: 'type anything like "what is this?"',
    minWidth: 280,
    minHeight: 400
  },
  [WizardScreen.CameraAngle]: {
    placeholder: 'type anything like "what is this?"',
    minWidth: 280,
    minHeight: 280
  },
  [WizardScreen.SnapMode]: {
    placeholder: 'type anything like "help"',
    minWidth: 280,
    minHeight: 320
  },
  [WizardScreen.Annotate]: {
    placeholder: 'type anything like "help"',
    minWidth: 280,
    minHeight: 320
  },
  [WizardScreen.Measure]: {
    placeholder: 'type anything like "help"',
    minWidth: 280,
    minHeight: 320
  },
  [WizardScreen.Gumball]: {
    placeholder: 'type anything like "help"',
    minWidth: 280,
    minHeight: 360
  },
  [WizardScreen.DigitalMaterial]: {
    placeholder: 'type anything like "metallic green"',
    minWidth: 280,
    minHeight: 560
  },
  [WizardScreen.BackDrop]: {
    placeholder: 'type anything like "metallic green"',
    minWidth: 280,
    minHeight: 480
  },
  [WizardScreen.LightMap]: {
    placeholder: 'type anything like "metallic green"',
    minWidth: 286,
    minHeight: 500
  },
  [WizardScreen.Post]: {
    placeholder: 'type commands',
    minWidth: 300,
    minHeight: 400
  },
  [WizardScreen.Share]: {
    placeholder: 'type commands',
    minWidth: 280,
    minHeight: 480
  },
  [WizardScreen.Sculpt]: {
    placeholder: 'type something like "smooth all"',
    minWidth: 280,
    minHeight: 450
  },
  [WizardScreen.Mirror]: {
    placeholder: 'type anything like "y-plane"',
    minWidth: 280,
    minHeight: 280
  },
  [WizardScreen.Array]: {
    placeholder: 'type anything like "y-plane"',
    minWidth: 280,
    minHeight: 440
  },
  [WizardScreen.Grid]: {
    placeholder: 'type anything like "help"',
    minWidth: 280,
    minHeight: 280
  },
  [WizardScreen.Weld]: {
    placeholder: 'you can say  "sphere" or "cube"',
    minWidth: 280,
    minHeight: 280
  },
  [WizardScreen.LargeFile]: {
    placeholder: 'you can say  "sphere" or "cube"',
    minWidth: 280,
    minHeight: 360
  },
  [WizardScreen.SculptTool]: {
    placeholder: '',
    minWidth: 280,
    minHeight: 150
  },
  [WizardScreen.MagnetTool]: {
    placeholder: '',
    minWidth: 280,
    minHeight: 200
  },
};

export interface IWizardInfo {
  screen?: WizardScreen
  placeholder?: string
  minWidth?: number
  minHeight?: number
}

export const CHANGE_WIZARD_SCREEN = 'CHANGE_WIZARD_SCREEN';
export const CHANGE_WIZARD_DIMENSION = 'CHANGE_WIZARD_DIMENSION';
export const CHANGE_WIZARD_TEXT = 'CHANGE_WIZARD_TEXT';
export const CHANGE_WIZARD_MINIMIZED = 'CHANGE_WIZARD_MINIMIZED';

export interface IWizardState {
  screen: WizardScreen
  placeholder: string
  text: string
  minHeight: number
  minWidth: number
  height: number
  width: number
  x: number
  y: number
  minimized: boolean
  [WizardScreen.InitialObject]: IInitialObjectWizardState
  [WizardScreen.ViewType]: IViewTypeWizardState
  [WizardScreen.CameraAngle]: ICameraAngleWizardState
  [WizardScreen.Gumball]: IGumballWizardState
  [WizardScreen.DigitalMaterial]: IDigitalMaterialWizardState
  [WizardScreen.BackDrop]: IBackDropWizardState
  [WizardScreen.LightMap]: ILightMapWizardState
  [WizardScreen.Sculpt]: ISculptWizardState
  [WizardScreen.Mirror]: IMirrorWizardState
  [WizardScreen.Array]: IArrayWizardState
  [WizardScreen.Weld]: IWeldWizardState
  [WizardScreen.LargeFile]: ILargeFileWizardState
  [WizardScreen.SculptTool]: ISculptToolWizardState
  [WizardScreen.MagnetTool]: IMagnetToolWizardState
}

interface ChangeWizardScreenAction {
  type: typeof CHANGE_WIZARD_SCREEN
  payload: {
    info: IWizardInfo
    screenState?: ISubWizardState
    initPrev?: boolean
    initNext?: boolean
  }
}

interface ChangeWizardTextAction {
  type: typeof CHANGE_WIZARD_TEXT
  payload: {
    text: string
  }
}

interface ChangeWizardDimensionAction {
  type: typeof CHANGE_WIZARD_DIMENSION
  payload: {
    height?: number
    width?: number
    x?: number
    y?: number
  }
}

interface ChangeWizardMinimizedAction {
  type: typeof CHANGE_WIZARD_MINIMIZED
  payload: {
    minimized: boolean
  }
}

export type ISubWizardState = IInitialObjectWizardState |
  IViewTypeWizardState |
  ICameraAngleWizardState |
  IGumballWizardState |
  IDigitalMaterialWizardState |
  IBackDropWizardState |
  ILightMapWizardState |
  ISculptWizardState |
  IMirrorWizardState |
  IWeldWizardState |
  ILargeFileWizardState |
  ISculptToolWizardState |
  IMagnetToolWizardState;

export type WizardActionTypes = ChangeWizardScreenAction |
  ChangeWizardTextAction |
  ChangeWizardDimensionAction |
  ChangeWizardMinimizedAction |
  ChangeSculptToolWizardDimensionAction |
  ChangeMagnetToolWizardDimensionAction |
  ChangeBackDropWizardEnvironmentAction |
  ChangeLightMapWizardEnvironmentAction |
  ChangeDigitalMaterialWizardMaterialAction |
  ChangeSculptWizardConfigAction |
  ChangeSculptWizardSubScreenAction |
  InitStoreAction |
  InitSceneAction