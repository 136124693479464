import {IGAR, IProject} from "../../../store/types/types";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {getGlobalObjects, processAuthentication, refreshScene} from "../global";
import {
  getCalcDetailInfoFailure,
  getCalcDetailInfoStarted,
  getCalcDetailInfoSuccess,
  getCalcHeatmapFailure,
  getCalcHeatmapStarted,
  getCalcHeatmapSuccess
} from "../../../store/actions/api/scene";
import {
  applyGeometryTransform,
  decodeWompObjectRef,
  getHeatmapDesc,
  isIdentity,
  WompMesh,
  WompMeshData,
  WompObjectRef
} from "../../../peregrine/WompObject";
import {
  attachGeometriesOnWorker,
  decodeGeometriesOnWorker,
  decodeGeometryOnWorker,
  fixGeometryOnWorker,
  IHeatmapData,
  ObjectTypes,
  registerSceneGeometry,
  RenderedObjectTypes,
  weldGeometryOnWorker
} from "../../../peregrine/processor";
import {_getDetailInfoAsync, _getHeatmapAsync} from "../../../api/project";
import {removeProgress, updateCommandManager} from "../../../store/actions/logic/project";
import Editor3dWrapper from "../../3d/Editor3dWrapper";
import {composeTransform, decomposeTransform, disposeDracoWorkers} from "../../../peregrine/utils";
import {mat4, vec3} from "gl-matrix";
import {matrixEncodeEqual} from "../../../peregrine/t";
import {MeshCodec, pigeon as pigeonTypes} from "../../../peregrine/types";
import {getSculptMeshFromMesh} from "../../../peregrine/converter";
import {toast} from "react-toastify";
import {removeScenePlaceholderBoxes, updateScenePlaceholderBox} from "./scene";
import {changeServerRenderFrameNo} from "../../../store/actions/ui/scene";
import {store} from "../../../index";
import {ProjectSceneDispatch} from "../../core";

export const getCalcDetailInfoAsync = (project: IProject, calcId: string): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    const {scene} = getGlobalObjects(project.id);

    if (scene) {
      dispatch(getCalcDetailInfoStarted(calcId, {percentage: 0}));

      let calc = scene.getCalcById(calcId);
      if (calc.isInvalid) {
        dispatch(getCalcDetailInfoSuccess(calcId));
        return {success: true};
      }

      let renderedObjs = calc.generateRenderedFullObjects();
      let refs: WompObjectRef[] = [];

      for (let object of renderedObjs) {
        switch (object.type) {
          case RenderedObjectTypes.Mesh:
          case RenderedObjectTypes.Brep:
            refs.push(object.meshRef!);
            break;
        }
      }

      dispatch(getCalcDetailInfoStarted(calcId, {percentage: 10}));
      return _getDetailInfoAsync(project.id, scene.route.id, calcId, refs)
        .then(res => {
          dispatch(getCalcDetailInfoStarted(calcId, {percentage: 20}));
          return {success: true};
        })
        .catch(err => {
          dispatch(getCalcDetailInfoFailure(calcId, err.message));
          return processAuthentication(err, dispatch).then(res => {
            if (res.success) {
              return dispatch(getCalcDetailInfoAsync(project, calcId));
            } else {
              return res;
            }
          });
        });
    } else {
      return {success: true};
    }
  };
};

export const getCalcDetailInfoAsyncResponse = (projectId: number, calcId: string, detailInfo: any): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(getCalcDetailInfoStarted(calcId, {percentage: 90}));

    await dispatch(ProjectSceneDispatch.changeCalcDetailInfo(projectId, undefined, calcId, detailInfo));

    dispatch(getCalcDetailInfoSuccess(calcId));
  };
};

export const getCalcHeatmapAsync = (projectId: number, wrapper: Editor3dWrapper, calcId: string): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    const {scene} = getGlobalObjects(projectId);

    if (scene) {
      dispatch(getCalcHeatmapStarted(calcId, {percentage: 0}));

      let calc = scene.getCalcById(calcId);
      if (calc.isInvalid) {
        dispatch(getCalcHeatmapSuccess(calcId));
        return {success: true};
      }

      let renderedObjs = calc.generateRenderedFullObjects();
      let refs: WompObjectRef[] = [];
      let heatmapData: IHeatmapData[] = [];

      for (let object of renderedObjs) {
        switch (object.type) {
          case RenderedObjectTypes.Mesh:
          case RenderedObjectTypes.Brep:
            if (object.meshRef) {
              let objectId = object.meshRef.objectId;
              let {unitScale, skew} = decomposeTransform(object.meshRef.matrix);

              let desc = getHeatmapDesc(objectId, unitScale, skew);

              let heatmapObjectId = scene.getRegisteredId(desc);

              if (!heatmapObjectId) {
                let objectRef = new WompObjectRef(
                  objectId,
                  composeTransform(vec3.create(), vec3.create(), unitScale, skew)
                );

                let duplicate = false;
                for (let ref of refs) {
                  if (ref.objectId === objectRef.objectId && matrixEncodeEqual(ref.matrix, objectRef.matrix)) {
                    duplicate = true;
                    break;
                  }
                }

                if (!duplicate)
                  refs.push(objectRef);

                heatmapData.push({
                  sourceObjectId: objectId,
                  scale: [unitScale[0], unitScale[1], unitScale[2]],
                  skew: [skew[0], skew[1], skew[2]],
                  objectId: ''
                });
              } else {
                heatmapData.push({
                  sourceObjectId: objectId,
                  scale: [unitScale[0], unitScale[1], unitScale[2]],
                  skew: [skew[0], skew[1], skew[2]],
                  objectId: heatmapObjectId
                });
              }
            }
            break;
          default:
            heatmapData.push({
              sourceObjectId: '',
              scale: [0, 0, 0],
              skew: [0, 0, 0],
              objectId: ''
            });
            break;
        }
      }

      await dispatch(ProjectSceneDispatch.changeCalcHeatmapData(projectId, wrapper, calcId, heatmapData));

      dispatch(getCalcHeatmapStarted(calcId, {percentage: 10}));
      return _getHeatmapAsync(projectId, scene.route.id, calcId, refs)
        .then(res => {
          dispatch(getCalcHeatmapStarted(calcId, {percentage: 20}));
          return {success: true};
        })
        .catch(err => {
          dispatch(getCalcHeatmapFailure(calcId, err.message));
          return processAuthentication(err, dispatch).then(res => {
            if (res.success) {
              return dispatch(getCalcHeatmapAsync(projectId, wrapper, calcId));
            } else {
              return res;
            }
          });
        });
    }

    return {success: true};
  };
};

export const getCalcHeatmapAsyncResponse = (projectId: number, wrapper: Editor3dWrapper, calcId: string, data: any): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const {scene} = getGlobalObjects(projectId);

    dispatch(getCalcHeatmapStarted(calcId, {percentage: 90}));

    let calc = scene.getCalcById(calcId);
    if (calc.isInvalid) {
      dispatch(getCalcHeatmapSuccess(calcId));
      return {success: true};
    }

    let geometries: WompMeshData[] = await decodeGeometriesOnWorker([MeshCodec.Draco, data.heatmap]);
    disposeDracoWorkers();

    let refs = data.refs.map(decodeWompObjectRef);

    let heatmapData = [];
    let needsUpdate = false;
    for (let heatmapDatum of calc.heatmapData) {
      if (!heatmapDatum.sourceObjectId) {
        heatmapData.push(heatmapDatum);
        continue;
      }

      if (!heatmapDatum.objectId) {
        let matrix = composeTransform(vec3.create(), vec3.create(), heatmapDatum.scale, heatmapDatum.skew);
        let refInd = -1;
        for (let i = 0; i < Math.min(refs.length, geometries.length); ++i) {
          let ref = refs[i];
          if (ref.objectId === heatmapDatum.sourceObjectId && matrixEncodeEqual(ref.matrix, matrix)) {
            refInd = i;
            break;
          }
        }

        if (refInd >= 0) {
          let geometry = geometries[refInd];

          let desc = getHeatmapDesc(heatmapDatum.sourceObjectId, heatmapDatum.scale, heatmapDatum.skew);

          let heatmapObjectId = scene.getRegisteredId(desc);

          if (!heatmapObjectId) {

            if (!isIdentity(matrix))
              applyGeometryTransform(geometry, mat4.invert(mat4.create(), matrix));

            const _replaceHeatmapData = (bufferGeometry: WompMeshData) => {

              let color = bufferGeometry.color;

              if (color) {
                let colorArray = new Float32Array(3 * (color.length / 4));

                for (let i = 0, l = color.length / 4; i < l; i++) {
                  colorArray[i * 3] = color[i * 4 + 1];
                  colorArray[i * 3 + 1] = color[i * 4 + 2];
                  colorArray[i * 3 + 2] = color[i * 4 + 3];
                }

                bufferGeometry.color = colorArray;
              }

            };

            _replaceHeatmapData(geometry);

            heatmapObjectId = scene.registerObject(ObjectTypes.Geometry, geometry, desc);

          }

          heatmapData.push({
            ...heatmapDatum,
            objectId: heatmapObjectId
          });
          needsUpdate = true;
        } else {
          heatmapData.push(heatmapDatum);
        }
      } else {
        heatmapData.push(heatmapDatum);
      }
    }

    if (needsUpdate) {
      await dispatch(ProjectSceneDispatch.changeCalcHeatmapData(projectId, wrapper, calcId, heatmapData));
    }

    dispatch(getCalcHeatmapSuccess(calcId));
  };
};

export const getCalcRemeshAsyncResponse = (projectId: number, wrapper: Editor3dWrapper, calcId: string, data: any): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    let geometry = (await decodeGeometriesOnWorker([MeshCodec.Draco, data.data]))[0];
    disposeDracoWorkers();

    if (wrapper.editor && wrapper.editor.sculptControl.sMeshes.length > 0 && wrapper.editor.selection.ids.includes(calcId)) {
      let index = wrapper.editor.selection.ids.indexOf(calcId);
      let sMesh = wrapper.editor.sculptControl.sMeshes[index];
      if (geometry) {
        let otherMesh;

        if (data.remaining)
          otherMesh = wrapper.editor.sculptControl.getMaskedMesh(index);
        else
          otherMesh = wrapper.editor.sculptControl.getUnmaskedMesh(index);

        let wasDynamic = wrapper.editor.sculptControl.sMesh.isDynamic;

        if (otherMesh) {
          if (!isIdentity(otherMesh.matrix))
            applyGeometryTransform(geometry, mat4.invert(mat4.create(), otherMesh.matrix));

          let mesh = new WompMesh(geometry);
          let mergedGeometry = await attachGeometriesOnWorker(otherMesh.geometry, mesh.geometry, data.remaining ? 0 : 1, data.remaining ? 1 : 0);
          let newSMesh = getSculptMeshFromMesh(new WompMesh(mergedGeometry, otherMesh.matrix), wasDynamic);

          wrapper.editor.sculptControl.replaceMesh(sMesh, newSMesh, true);
        } else {
          let newSMesh = getSculptMeshFromMesh(new WompMesh(geometry), wasDynamic);
          wrapper.editor.sculptControl.replaceMesh(sMesh, newSMesh, true);
        }
      }

      wrapper.editor.configure({editControls: true});
      await dispatch(removeScenePlaceholderBoxes(wrapper.editor.selection.ids, wrapper));
    }

    dispatch(removeProgress(`edit: sculpt-remesh`));
  };
};

export const getCalcMirrorAsyncResponse = (projectId: number, wrapper: Editor3dWrapper, calcId: string, data: any): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    let geometry = (await decodeGeometriesOnWorker([MeshCodec.Draco, data.data]))[0];
    disposeDracoWorkers();

    if (wrapper.editor && wrapper.editor.sculptControl.sMeshes.length > 0 && wrapper.editor.selection.ids.includes(calcId)) {
      let index = wrapper.editor.selection.ids.indexOf(calcId);
      let sMesh = wrapper.editor.sculptControl.sMeshes[index];
      if (geometry) {
        geometry = await fixGeometryOnWorker(geometry, {merge: true});

        let wasDynamic = sMesh && sMesh.isDynamic;

        let normal = sMesh.getSymmetryNormal();
        let newSMesh = getSculptMeshFromMesh(new WompMesh(geometry, sMesh.getMatrix()), wasDynamic);

        if (newSMesh) {
          newSMesh.setSymmetryOffset(0);
          newSMesh.setSymmetryNormal(normal);

          let orgOrigin = sMesh.getSymmetryOrigin();
          let origin = newSMesh.getSymmetryOrigin();

          let offset = vec3.dot(vec3.sub(vec3.create(), orgOrigin, origin), normal);

          newSMesh.setSymmetryOffset(offset / newSMesh.computeLocalRadius());

          wrapper.editor.sculptControl.replaceMesh(sMesh, newSMesh, true);
        }
      }

      wrapper.editor.configure({editControls: true});
      await dispatch(removeScenePlaceholderBoxes(wrapper.editor.selection.ids, wrapper));
    }

    dispatch(removeProgress(`edit: sculpt-mirror`));
  };
};

export const getCutAsyncResponse = (isMesh: boolean) => {
  return (projectId: number, wrapper: Editor3dWrapper, operationId: string, data: any): ThunkAction<void, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const {scene} = getGlobalObjects(projectId);

      let operation = scene.operations[operationId];
      if (isMesh) {
        operation.options.waitForMesh = false;
      } else {
        operation.options.waitForBrep = false;
      }

      if (!operation.options.waitForMesh && !operation.options.waitForBrep)
        delete scene.operations[operationId];

      if (operation) {
        let geomsToRegister: { geom: any, type: ObjectTypes, desc: string }[] = [];

        if (isMesh) {
          let geoms: WompMeshData[] = await decodeGeometriesOnWorker([MeshCodec.Draco, data.data]);
          disposeDracoWorkers();

          let i = 0;
          for (let info of operation.infos) {
            if (info.isMesh) {
              let geom = geoms[i];

              geom = await fixGeometryOnWorker(geom, {merge: true});
              geom = await weldGeometryOnWorker(geom, info.weldAngle);
              geomsToRegister.push({geom, type: ObjectTypes.Geometry, desc: info.desc});

              ++i;
            }
          }
        } else {
          let i = 0;
          for (let info of operation.infos) {
            if (!info.isMesh) {
              let [meshData, edgeData] = await pigeonTypes.tessellate(data[i]);
              geomsToRegister.push({geom: data[i], type: ObjectTypes.BrepGeometry, desc: info.desc});
              geomsToRegister.push({geom: meshData, type: ObjectTypes.Geometry, desc: info.desc + '-mesh'});
              geomsToRegister.push({geom: edgeData, type: ObjectTypes.Geometry, desc: info.desc + '-edge'});

              ++i;
            }
          }
        }

        let validCalcIds = Array.from(operation.waitingCalcIdSet).filter(id => !scene.getCalcById(id).isInvalid);

        if (validCalcIds.length > 0) {
          await scene.runExclusive(async () => {
            for (let geom of geomsToRegister)
              registerSceneGeometry(scene, geom.type, geom.geom, geom.desc);

            for (let calcId of validCalcIds)
              scene.updateCalcIdSet.add(calcId);

            await scene.solve();
            scene.createCommand('cut-response', `cut result arrived`);
          });

          await refreshScene(projectId, dispatch, wrapper);
          dispatch(updateCommandManager(projectId, scene.commandManager.commandState));

          if (!operation.options.waitForMesh && !operation.options.waitForBrep)
            await dispatch(removeScenePlaceholderBoxes(validCalcIds, wrapper));
        }
      }
    };
  };
}

export const getBooleanAsyncResponse = (isMesh: boolean) => {
  return (projectId: number, wrapper: Editor3dWrapper, operationId: string, data: any): ThunkAction<void, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
      const {scene} = getGlobalObjects(projectId);

      let operation = scene.operations[operationId];
      delete scene.operations[operationId];

      if (operation) {
        let geomsToRegister: { geom: any, type: ObjectTypes, desc: string }[] = [];

        if (isMesh) {
          let geom: WompMeshData = await decodeGeometryOnWorker([MeshCodec.Draco, data.data]);
          disposeDracoWorkers();

          if (Object.keys(geom).length === 0) {
            toast(`Boolean operation result is empty. Please check if geometries intersect and bound volume.`, {
              className: "toast toast-alert",
              containerId: "default",
              autoClose: 3000
            });
          }

          geom = await fixGeometryOnWorker(geom, {merge: true});
          geom = await weldGeometryOnWorker(geom, operation.infos.weldAngle);
          geomsToRegister.push({geom, type: ObjectTypes.Geometry, desc: operation.infos.desc});
        } else {
          let [meshData, edgeData] = await pigeonTypes.tessellate(data);
          geomsToRegister.push({geom: data, type: ObjectTypes.BrepGeometry, desc: operation.infos.desc});
          geomsToRegister.push({geom: meshData, type: ObjectTypes.Geometry, desc: operation.infos.desc + '-mesh'});
          geomsToRegister.push({geom: edgeData, type: ObjectTypes.Geometry, desc: operation.infos.desc + '-edge'});
        }

        let validCalcIds = Array.from(operation.waitingCalcIdSet).filter(id => !scene.getCalcById(id).isInvalid);

        if (validCalcIds.length > 0) {
          await scene.runExclusive(async () => {
            for (let geom of geomsToRegister)
              registerSceneGeometry(scene, geom.type, geom.geom, geom.desc);

            for (let calcId of validCalcIds)
              scene.updateCalcIdSet.add(calcId);

            await scene.solve();
            scene.createCommand('boolean-response', `boolean result arrived`);
          });

          await refreshScene(projectId, dispatch, wrapper);
          dispatch(updateCommandManager(projectId, scene.commandManager.commandState));

          await dispatch(removeScenePlaceholderBoxes(validCalcIds, wrapper));
        }
      }
    };
  };
}

export const renderAsyncResponse = (projectId: number, wrapper: Editor3dWrapper, data: any): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    if (wrapper.editor) {
      wrapper.editor.setServerRenderImage('data:image/jpeg;base64,' + data.data, data.config);
      dispatch(changeServerRenderFrameNo(data.frame));
    }
  };
};

export const progressAsyncResponse = (projectId: number, wrapper: Editor3dWrapper, data: any): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const {scene} = getGlobalObjects(projectId);

    if (wrapper.editor && scene) {
      if (data.op === 'remesh' || data.op === 'mirror') {
        if (wrapper.editor.selection.ids.length > 0) {
          dispatch(updateScenePlaceholderBox(wrapper.editor.selection.ids[0], {
            progress: data.progress
          }, wrapper));
        }
      } else if (data.op === 'boolean' || data.op === 'cut') {
        let operation = scene.operations[data.operationId];
        let calcIds = Array.from(operation.waitingCalcIdSet);
        for (let calcId of calcIds) {
          dispatch(updateScenePlaceholderBox(calcId, {
            progress: data.progress
          }, wrapper));
        }
      }
    }
  };
};