import Subdivision from "./editing/Subdivision";
import MeshStatic from "./mesh/meshStatic/MeshStatic";

class Utils {
  static subdivideClamp(mesh, limit, linear) {
    Subdivision.LINEAR = !!linear;
    while (mesh.getNbFaces() < limit) {
      var newMesh = new MeshStatic();
      Subdivision.fullSubdivision(mesh, newMesh);
      newMesh.initTopology();
      mesh = newMesh;
    }
    // keep at max 4 multires
    Subdivision.LINEAR = false;

    return mesh;
  }
}

export default Utils;