
export const CHANGE_MAGNET_TOOL_WIZARD_DIMENSION = 'CHANGE_MAGNET_TOOL_WIZARD_DIMENSION';

export interface IMagnetToolWizardState {
  visible: boolean
  height: number
  width: number
  x: number
  y: number
}

export interface ChangeMagnetToolWizardDimensionAction {
  type: typeof CHANGE_MAGNET_TOOL_WIZARD_DIMENSION
  payload: {
    height?: number
    width?: number
    x?: number
    y?: number
  }
}

