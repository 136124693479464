import {Parameter, ParamTypes} from './parameter';
import {Param_Number} from "./param-number";
import {Param_String} from "./param-string";
import {Param_Index} from "./param-index";
import {Param_Boolean} from "./param-boolean";
import {Param_Json} from "./param-json";
import {Param_Point} from "./param-point";
import {Param_Vertex} from "./param-vertex";
import {Param_Curve} from "./param-curve";
import {Param_Brep} from "./param-brep";
import {Param_SculptMesh} from "./param-sculpt-mesh";
import {Param_Mesh} from "./param-mesh";
import {Param_Transform} from "./param-transform";
import {Param_Compound} from "./param-compound";
import {_s} from "../../t";
import {peregrineId} from "../../id";

export * from "./parameter";
export * from "./param-boolean";
export * from "./param-brep";
export * from "./param-compound";
export * from "./param-curve";
export * from "./param-index";
export * from "./param-mesh";
export * from "./param-number";
export * from "./param-point";
export * from "./param-sculpt-mesh";
export * from "./param-json";
export * from "./param-string";
export * from "./param-transform";
export * from "./param-vertex";


export function createParameter(objectType: string): Parameter {
  let param;

  if (objectType === _s(ParamTypes.Number)) {

    param = new Param_Number();

  } else if (objectType === _s(ParamTypes.String)) {

    param = new Param_String();

  } else if (objectType === _s(ParamTypes.Json)) {

    param = new Param_Json();

  } else if (objectType === _s(ParamTypes.Index)) {

    param = new Param_Index();

  } else if (objectType === _s(ParamTypes.Boolean)) {

    param = new Param_Boolean();

  } else if (objectType === _s(ParamTypes.Point)) {

    param = new Param_Point();

  } else if (objectType === _s(ParamTypes.Vertex)) {

    param = new Param_Vertex();

  } else if (objectType === _s(ParamTypes.Curve)) {

    param = new Param_Curve();

  } else if (objectType === _s(ParamTypes.Brep)) {

    param = new Param_Brep();

  } else if (objectType === _s(ParamTypes.SculptMesh)) {

    param = new Param_SculptMesh();

  } else if (objectType === _s(ParamTypes.Mesh)) {

    param = new Param_Mesh();

  } else if (objectType === _s(ParamTypes.Transform)) {

    param = new Param_Transform();

  } else if (objectType === _s(ParamTypes.Compound)) {

    param = new Param_Compound();

  } else {

    param = new Parameter();

  }

  return param;
}

export function duplicateParameter(param: Parameter): Parameter {
  let newParam = createParameter(_s(param.objectType));
  newParam.overwrite(param.data, false);
  newParam.id = peregrineId();

  return newParam;
}

