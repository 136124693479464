import {
  ADD_PROJECT,
  ADD_PROJECTS,
  ADD_SCREENSHOT_IDS,
  CHANGE_FIELDS,
  ProjectsActionTypes,
  REMOVE_PROJECT,
  REMOVE_SCREENSHOT_IDS,
  REPLACE_ATTACHMENTS, REPLACE_CHAT_SESSION_IDS,
  REPLACE_PROJECTS,
  UPDATE_SCENE
} from '../../types/entity/projects';
import {IAttachment, IProject} from '../../types/types';
import {IScene} from "../../../peregrine/processor";

export function replaceProjects(projects: IProject[], config?: any): ProjectsActionTypes {
  return {
    type: REPLACE_PROJECTS,
    payload: {
      projects,
      config
    }
  };
}

export function addProjects(projects: IProject[], config?: any): ProjectsActionTypes {
  return {
    type: ADD_PROJECTS,
    payload: {
      projects,
      config
    }
  };
}

export function addProject(project: IProject): ProjectsActionTypes {
  return {
    type: ADD_PROJECT,
    payload: {
      project
    }
  };
}

export function removeProject(id: number): ProjectsActionTypes {
  return {
    type: REMOVE_PROJECT,
    payload: {
      id
    }
  };
}

export function addScreenshotIds(id: number, screenshotIds: number[]): ProjectsActionTypes {
  return {
    type: ADD_SCREENSHOT_IDS,
    payload: {
      id,
      screenshotIds
    }
  };
}

export function replaceAttachments(id: number, attachments: IAttachment[]): ProjectsActionTypes {
  return {
    type: REPLACE_ATTACHMENTS,
    payload: {
      id,
      attachments
    }
  };
}

export function replaceChatSessionIds(id: number, chatSessionIds: number[]): ProjectsActionTypes {
  return {
    type: REPLACE_CHAT_SESSION_IDS,
    payload: {
      id,
      chatSessionIds
    }
  };
}

export function removeScreenshotIds(id: number, screenshotIds: number[]): ProjectsActionTypes {
  return {
    type: REMOVE_SCREENSHOT_IDS,
    payload: {
      id,
      screenshotIds
    }
  };
}

export function updateScene(id: number, newState: IScene): ProjectsActionTypes {
  return {
    type: UPDATE_SCENE,
    payload: {
      id,
      newState
    }
  };
}

export function changeFields(id: number, fields: Partial<IProject>): ProjectsActionTypes {
  return {
    type: CHANGE_FIELDS,
    payload: {
      id,
      fields
    }
  };
}
