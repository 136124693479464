import * as React from 'react';

interface IPageNotFoundProps {
}

export default class PageNotFound extends React.PureComponent<IPageNotFoundProps> {
  render() {
    return (
      <div className="page-not-found-root">
        <p><span>Page Not Found</span></p>
      </div>
    );
  }
}
