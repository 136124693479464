import {ComponentTypes} from "../types";
import {pigeon as pigeonTypes} from "../../../types";
import {createGumball} from "../gumball";
import {Calc} from "../../calc";
import {Param_Brep, ParamTitles,} from "../../parameter";
import {mat4, vec3} from "gl-matrix";
import {ThreeConeGenerator} from "../three-basic";
import {BasicBrepGenerator} from "./basic-gen";

export class ConeGenerator extends BasicBrepGenerator {
  static defaultParameter = {
    radius: 10,
    height: 20
  };
  static draggingPreview = ThreeConeGenerator.generateMesh();

  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.ConeGenerator;
    calc.title = 'cone';

    let coneParam = Param_Brep.create(calc, ParamTitles.Cone, false, false, true);

    calc.addParameter(coneParam);

    return calc;
  }

  static getDesc(calc: Calc) {
    return 'b-cone';
  }

  static async generateBrepGeometry(parameter: any = this.defaultParameter) {
    return pigeonTypes.cone(
      this.defaultParameter.radius,
      this.defaultParameter.height
    );
  }

  static generateMatrix(parameter: any = this.defaultParameter) {
    parameter = parameter || this.defaultParameter;
    return mat4.fromScaling(
      mat4.create(),
      vec3.fromValues(
        parameter.radius / this.defaultParameter.radius,
        parameter.radius / this.defaultParameter.radius,
        parameter.height / this.defaultParameter.height
      )
    );
  }
}
