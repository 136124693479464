import * as React from 'react';
import {pure} from 'recompose';
import {useCursorHandlers} from "./use-cursor-handler";

interface ICursorDivProps extends React.HTMLProps<HTMLDivElement> {
  disabled?: boolean
  children: any;
}

const CursorDiv: React.FC<ICursorDivProps> = ({children, className, disabled, ...props}) => {
  if (disabled) {
    return <div className={className} {...props}>
      {children}
    </div>;
  }

  const cursorHandlers = useCursorHandlers();

  return (
    <div className={className ? "hide-cursor relative " + className : "hide-cursor relative"}{...props} {...cursorHandlers}>
      {children}
    </div>
  );
};

export default pure(CursorDiv);
