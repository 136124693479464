import {Calc} from "../../calc";
import {
  createObjectFromBrepData,
  getRegisteredBrepIdWithTessellation,
  registerBrepGeometryWithTessellation
} from "../../parameter";
import {Gumball} from "../gumball";
import {WompObjectRef} from "../../../WompObject";
import {ObjectTypes} from "../../xobject";
import {mat4} from "gl-matrix";
import {BasicObjectFunction, BasicObjectGenerator, ObjectFuncParameter, PredictedResult} from "../base";

export class BasicBrepGenerator extends BasicObjectGenerator {
  static getParameters(calc: Calc) {
    return {
      desc: this.getDesc(calc),
      matrix: this.generateMatrix(),
      ...this.getParameter(calc)
    }
  }

  static predictResult(calc: Calc) {
    return PredictedResult.Brep;
  }

  static async mapObject(calc: Calc, parameters: ObjectFuncParameter) {
    let {desc, matrix, ...restParameters} = parameters;
    let brepData = await this.generateBrepGeometry(restParameters);
    return createObjectFromBrepData(calc, brepData, desc, matrix);
  }

  static getDesc(calc: Calc) {
    return '';
  }

  static generateMatrix() {
    return mat4.create();
  }

  static async generateBrepGeometry(parameter: any) {
    return {};
  }

  static getParameter(calc: Calc): any {
    return undefined;
  }
}