import Editor3dWrapper from "../../3d/Editor3dWrapper";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {
  getGlobalObjects,
  refreshObjectWeldings,
  refreshPlaceholders,
  refreshScene,
  waitForRerender,
  waitForStore
} from "../global";
import {AnyAction} from "redux";
import {ISceneEnvironmentInfo, Tools, ViewTypes} from "../../../peregrine/processor";
import {IDigitalMaterial, IGAR, IObjectWelding, IPlaceholderBox} from "../../../store/types/types";
import {toast} from "react-toastify";
import {addProgress, removeProgress, updateCommandManager} from "../../../store/actions/logic/project";
import {postProjectSave} from "../project";
import lod from "lodash";
import {store} from "../../..";
import {changeConfirmDialogOpened} from "../../../store/actions/ui/dialog";
import {
  addPlaceholderBoxes,
  changePreviewDigitalMaterial,
  changePreviewEnvironmentInfo,
  changeServerRenderFrameNo,
  changeTemporaryObjectWeldings,
  removePlaceholderBoxes,
  updatePlaceholderBox
} from "../../../store/actions/ui/scene";
import {
  _downloadRenderImage,
  _postChangeLiveRender,
  _postStartRender,
  _postStopLiveRender,
  _postStopRender
} from "../../../api/render";
import {vec2} from "gl-matrix";
import {ProjectSceneDispatch} from "../../core";

export const postChangeLiveRender = (projectId: number, wrapper: Editor3dWrapper): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const {scene} = getGlobalObjects(projectId);

    if (wrapper.editor) {
      let editor3d = wrapper.editor;
      let config = editor3d.getCurrentRenderScene();

      editor3d.setServerRenderConfig(config);
      dispatch(changeServerRenderFrameNo(0));

      return _postChangeLiveRender(scene.route.id, config).then(async res => {
        if (!res.data.success) {
          editor3d.setServerRenderConfig();
          return {success: false};
        }

        return {success: true};
      });
    } else {
      return {success: false};
    }
  };
};

export const postStartRender = (projectId: number, wrapper: Editor3dWrapper, resolution?: vec2): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    if (wrapper.editor) {
      let editor3d = wrapper.editor;
      let config = editor3d.getCurrentRenderScene(false, resolution);
      let image = '';
      if (editor3d.delegate && editor3d.delegate.overlayCanvasElement)
        image = editor3d.delegate.overlayCanvasElement.toDataURL("image/jpeg");

      return _postStartRender(projectId, 0, config, image).then(async res => {
        return {success: res.data.success};
      });
    } else {
      return {success: false};
    }
  };
};

export const postStopRender = (sessionId: number, remove: boolean): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return _postStopRender(sessionId, remove).then(async res => {
      return {success: res.data.success};
    });
  };
};

export const downloadRenderImage = (sessionId: number): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return _downloadRenderImage(sessionId).then(async res => {
      if (res.data.success) {
        const link = document.createElement('a');
        link.href = res.data.data;
        link.setAttribute('download', `render.png`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      return {success: res.data.success};
    });
  };
};

export const changeViewType = (projectId: number, wrapper: Editor3dWrapper, viewType: number): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    const {scene} = getGlobalObjects(projectId);

    await waitForStore('logic.project.progressIds', (value: any) => !value.includes('change view type'));

    if (scene.viewType !== viewType) {
      dispatch(addProgress('edit: change view type'));
      if (wrapper.editor && wrapper.editor.isViewTypeSupported(viewType)) {
        let editor3d = wrapper.editor;

        const routeId = scene.route.id;

        if (viewType === ViewTypes.ServerRendered) {
          let config = editor3d.getCurrentRenderScene();
          editor3d.setServerRenderImage();
          editor3d.setServerRenderConfig(config);
          dispatch(changeServerRenderFrameNo(0));

          _postStartRender(projectId, routeId, config).then(async res => {
            if (res.data.success) {
              await dispatch(ProjectSceneDispatch.changeViewType(projectId, wrapper, viewType));
            } else {
              editor3d.setServerRenderConfig();
            }
          });
        } else if (scene.viewType === ViewTypes.ServerRendered) {
          _postStopLiveRender(routeId);
          await dispatch(ProjectSceneDispatch.changeViewType(projectId, wrapper, viewType));
          editor3d.setServerRenderConfig();
        } else {
          await dispatch(ProjectSceneDispatch.changeViewType(projectId, wrapper, viewType));
        }
      } else {
        toast('sorry, selected rendering is not supported.', {
          className: "toast toast-alert",
          containerId: 'default',
          autoClose: 3000
        });
      }

      dispatch(removeProgress('edit: change view type'));
    }

    return {success: true};
  };
};

export const saveCameraInfo = (projectId: number, wrapper: Editor3dWrapper): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    if (wrapper.editor) {
      await dispatch(ProjectSceneDispatch.changeCameraInfo(projectId, wrapper, wrapper.editor.getCameraInfo()));
      return await dispatch(postProjectSave(projectId, wrapper, false, true));
    }

    return {success: true};
  };
};

export const resetView = (projectId: number, wrapper: Editor3dWrapper): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    await dispatch(ProjectSceneDispatch.changeCameraInfo(projectId, wrapper, null));
    if (wrapper.editor)
      wrapper.editor.repositionCamera('preview-zoom');
    return await dispatch(postProjectSave(projectId, wrapper, false, true));
  };
};

export const changeScenePreviewEnvironmentInfo = (projectId: number, wrapper: Editor3dWrapper, info?: Partial<ISceneEnvironmentInfo>): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    if (!lod.isEqual(store.getState().ui.scene.previewEnvironmentInfo, info)) {
      dispatch(changePreviewEnvironmentInfo(info));
      await refreshScene(projectId, dispatch, wrapper);
    }
  };
};

export const changeScenePreviewDigitalMaterial = (projectId: number, wrapper: Editor3dWrapper, calcIds: string[], material?: IDigitalMaterial): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    if (!lod.isEqual(new Set(store.getState().ui.scene.previewCalcIds), new Set(calcIds)) || !lod.isEqual(store.getState().ui.scene.previewDigitalMaterial, material)) {
      dispatch(changePreviewDigitalMaterial(calcIds, material));
      await refreshScene(projectId, dispatch, wrapper);
    }
  };
};

export const updateScenePlaceholderBox = (id: string, box: Partial<IPlaceholderBox>, wrapper: Editor3dWrapper): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(updatePlaceholderBox(id, box));

    await refreshPlaceholders(wrapper);
  };
};

export const removeScenePlaceholderBoxes = (ids: string[], wrapper: Editor3dWrapper): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(removePlaceholderBoxes(ids));

    await refreshPlaceholders(wrapper);
  };
};

export const addScenePlaceholderBoxes = (boxes: { [key: string]: IPlaceholderBox }, wrapper: Editor3dWrapper): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(addPlaceholderBoxes(boxes));

    await refreshPlaceholders(wrapper);
  };
};

export const changeSceneTemporaryObjectWeldings = (weldings: { [key: string]: IObjectWelding }, wrapper: Editor3dWrapper): ThunkAction<void, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    dispatch(changeTemporaryObjectWeldings(weldings));

    await refreshObjectWeldings(dispatch, wrapper);
  };
};

export const createCommand = (projectId: number, operation: string, description: string): ThunkAction<Promise<IGAR>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>): Promise<IGAR> => {
    const {scene} = getGlobalObjects(projectId);

    await scene.runExclusive(async () => {
      scene.createCommand(
        operation,
        description
      );
    });

    dispatch(updateCommandManager(projectId, scene.commandManager.commandState));

    return {success: true};
  };
};
