import {
  AdditiveBlending,
  Box3,
  BufferGeometry,
  CircleBufferGeometry,
  Color,
  DoubleSide,
  EventDispatcher,
  Float32BufferAttribute,
  Group,
  Line,
  LineBasicMaterial,
  LineDashedMaterial,
  LineSegments,
  Mesh,
  Points,
  MeshBasicMaterial,
  Object3D,
  Raycaster,
  ShaderMaterial,
  Vector2,
  Vector3,
  Matrix4
} from "three";
import {OutlinePass} from '../render/OutlinePass';

const MARK_STATE = {
  DEFAULT: 1,
  DISABLED: 2,
  HOVER: 3
};

const DRAG_MODE = {
  MARK: 1,
  OBJECT: 2,
  RESTORE: 3
};

// Control Information
const MARK_INFO = {
  X: {
    ROT: [0, 0, 0],
    POS: {
      MIN: [[-0.5, -0.5, -0.5], [-0.5, 0.5, -0.5]],
      MID: [[0, -0.5, -0.5], [0, 0.5, -0.5]],
      MAX: [[0.5, -0.5, -0.5], [0.5, 0.5, -0.5]],
    },
    EYE: [0, 0, 1],
  },
  Y: {
    ROT: [0, 0, 0],
    POS: {
      MIN: [[-0.5, -0.5, -0.5], [0.5, -0.5, -0.5]],
      MID: [[-0.5, 0, -0.5], [0.5, 0, -0.5]],
      MAX: [[-0.5, 0.5, -0.5], [0.5, 0.5, -0.5]],
    },
    EYE: [0, 0, 1],
  },
  Z: {
    ROT: [0, Math.PI / 2, 0],
    POS: {
      MIN: [[0.5, -0.5, -0.5], [0.5, -0.51, -0.5]],
      MID: [[0.5, -0.5, 0], [0.5, -0.51, 0]],
      MAX: [[0.5, -0.5, 0.5], [0.5, -0.51, 0.5]],
    },
    EYE: [1, 0, 0],
  }
};

let AlignControl = function (camera, renderer, scene, composer) {
  Object3D.call(this);

  this.domElement = (renderer.domElement) ? renderer.domElement : document;

  let size = 1.2;
  let ray = new Raycaster();
  let scope = this;

  let boundingBox = new Box3();
  let boundingBoxSize = new Vector3();
  let boundingBoxCenter = new Vector3();

  let selBoundingBox = new Box3();
  let selBoundingBoxSize = new Vector3();
  let selBoundingBoxCenter = new Vector3();

  let selObjId = undefined;
  let markState = {};
  let objPositions = [];
  let lastHoverMark = undefined;
  let dragAxis = undefined;
  let dragAlign = undefined;
  let dragMode = undefined;
  let selObject = undefined;

  // Config
  let Config = {
    Mark: {
      Color: {
        [MARK_STATE.DEFAULT]: 0x000000,
        [MARK_STATE.DISABLED]: 0x444444,
        [MARK_STATE.HOVER]: 0xff0000
      },
      AddLength: 150,
      CircleSize: 40,
      CircleControlName: "CIRCLE",
      LineControlName: "LINE",
      ZAxisLineControlName: "Z_LINE"
    }
  };

  // Temporary Variables
  let tVector3_1 = new Vector3();
  let tVector3_2 = new Vector3();
  let tBox3 = new Box3();

  // Controls
  let bottomPlane = createBottomPlane();
  let zAxisLine = createZAxisLine();
  let markControl = createMarkControl();

  this.visible = false;
  this.isAlignControls = true;
  this.isDragging = false;
  this.objects = undefined;
  this.enabled = true;
  this.eye = undefined;
  this.elemRect = undefined;
  this.camera = camera;
  this.scene = scene;
  this.outlinePass = new OutlinePass(new Vector2(this.domElement.width, this.domElement.height), scene.target, camera);
  this.outlinePass.edgeStrength = 15;
  this.outlinePass.hiddenEdgeColor = new Color(0xff0000);
  this.outlinePass.visibleEdgeColor = new Color(0xff0000);
  composer.addPass(this.outlinePass);

  let hoverObjects = undefined;

  // TODO: BYZ - replace this with better roundabout when it is ready.
  function cloneObject(object) {

    if (object.isInstancedMesh) {

      let clonedObjects = [];

      for (let i = 0; i < object.count; ++i) {
        let mesh = new Mesh(object.geometry);
        mesh.matrixAutoUpdate = false;

        let matrix = new Matrix4();
        object.getMatrixAt(i, matrix);
        mesh.matrix.copy(matrix);
        clonedObjects.push(mesh);
      }

      return clonedObjects;

    } else {

      let clonedObject = object.clone(false);

      for (let i = 0; i < object.children.length; i++) {

        const child = object.children[i];
        clonedObject.add(...cloneObject(child));

      }

      return [clonedObject];

    }

  }

  function createGhostedMaterial(color) {

    let vertexShader = [
      'uniform float p;',
      'varying float intensity;',
      'void main() {',
      ' vec3 transformed = vec3( position );',
      ' vec4 mvPosition = vec4( transformed, 1.0 );',
      ' #ifdef USE_INSTANCING',
      '  mvPosition = instanceMatrix * mvPosition;',
      ' #endif',
      ' vec4 modelViewPosition = modelViewMatrix * mvPosition;',
      ' vec3 vNormal = normalize( normalMatrix * normal );',
      ' intensity = pow(1.0 - abs(dot(vNormal, vec3(0, 0, 1))), p);',
      ' gl_Position = projectionMatrix * modelViewPosition;',
      '}'
    ].join('\n');

    let fragmentShader = [
      'uniform vec3 glowColor;',
      'varying float intensity;',
      'void main() {',
      ' vec3 glow = glowColor * intensity;',
      ' gl_FragColor = vec4( glow, 1.0 );',
      '}'
    ].join('\n');

    return new ShaderMaterial({
      uniforms: {
        p: {value: 2},
        glowColor: {value: new Color(color)}
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      side: DoubleSide, blending: AdditiveBlending,
      transparent: true, depthWrite: false
    });

  }

  function createGhostedObject(object) {
    let hoverObject = cloneObject(object)[0];

    hoverObject.traverse(function (child) {
      if (child.isMesh && child.geometry) {
        child.material = scope.materials.hover;
      }
    });

    return hoverObject;
  }

  function createHoverObjects(axis, align) {
    hoverObjects = [];
    for (let i = 0; i < scope.objects.length; ++i) {
      let hoverObject = createGhostedObject(scope.objects[i]);
      alignObjectTo(hoverObject, axis, align);
      scene.add(hoverObject);
      hoverObjects.push(hoverObject);
    }

    scope.outlinePass.selectedObjects = hoverObjects;
  }

  function removeHoverObjects() {
    if (!hoverObjects) return;

    for (let i = 0; i < hoverObjects.length; ++i) {
      scene.remove(hoverObjects[i]);
    }

    hoverObjects = undefined;
  }

  this.refreshBoundingClientRect = function () {
    this.elemRect = this.domElement === document ? document.body.getBoundingClientRect() : this.domElement.getBoundingClientRect();
  };

  this.setDelegate = function (delegate) {

    this.unsetDelegate();

    delegate.domElement.addEventListener('mousedown', onPointerDown);
    delegate.domElement.addEventListener('touchstart', onTouchDown);

    delegate.domElement.addEventListener('mousemove', onPointerMove);
    delegate.domElement.addEventListener('touchmove', onPointerMove);

    delegate.domElement.addEventListener('mouseup', onPointerUp);
    delegate.domElement.addEventListener('touchend', onPointerUp);
    delegate.domElement.addEventListener('touchcancel', onPointerUp);
    delegate.domElement.addEventListener('touchleave', onPointerUp);

    this.domElement = delegate.domElement;

    this.refreshBoundingClientRect();
  }

  this.unsetDelegate = function () {

    if (this.domElement === document)
      return;

    this.domElement.removeEventListener('mousedown', onPointerDown);
    this.domElement.removeEventListener('touchstart', onTouchDown);

    this.domElement.removeEventListener('mousemove', onPointerMove);
    this.domElement.removeEventListener('touchmove', onPointerMove);

    this.domElement.removeEventListener('mouseup', onPointerUp);
    this.domElement.removeEventListener('touchend', onPointerUp);
    this.domElement.removeEventListener('touchcancel', onPointerUp);
    this.domElement.removeEventListener('touchleave', onPointerUp);

    this.domElement = document;

  }

  this.attachObjects = function (objects) {
    this.objects = objects;
    this.visible = true;

    selObjId = undefined;
    lastHoverMark = undefined;

    calculateBoundingBox();

    selBoundingBox.copy(boundingBox);
    selBoundingBoxSize.copy(boundingBoxSize);
    selBoundingBoxCenter.copy(boundingBoxCenter);

    updateMarkState();

    if (objPositions.length < objects.length) {
      for (let i = objPositions.length; i < objects.length; ++i) objPositions.push(new Vector3());
    }
  };

  this.detach = function () {
    this.objects = undefined;
    this.visible = false;

    removeHoverObjects();
  };

  this.refresh = function () {
    if (!this.enabled)
      return;

    if (!this.objects || !this.visible) {
      bottomPlane.visible = false;
      zAxisLine.visible = false;
      markControl.visible = false;
      return;
    }

    this.eye = this.camera.getWorldDirection(new Vector3());

    bottomPlane.visible = true;
    zAxisLine.visible = true;
    markControl.visible = true;

    // Draw Gloabl Controls
    bottomPlane.scale.set(boundingBoxSize.x, boundingBoxSize.y, 1);
    bottomPlane.position.set(boundingBoxCenter.x, boundingBoxCenter.y, boundingBoxCenter.z - boundingBoxSize.z / 2);

    zAxisLine.scale.set(1, 1, boundingBoxSize.z);
    zAxisLine.position.set(boundingBoxCenter.x, boundingBoxCenter.y, boundingBoxCenter.z);

    // Draw Mark Controls

    for (let axis in MARK_INFO) {
      for (let align in MARK_INFO[axis].POS) {
        updateMarkUnit(axis, align, selBoundingBoxSize, selBoundingBoxCenter);
      }
    }

    updateMarkZAxis(selBoundingBoxSize, selBoundingBoxCenter);

    return false;
  };

  this.setSize = function (_size) {
    size = _size * 12.0;
    // console.log(size)
  };

  this.setCamera = function (_camera) {
    this.camera = _camera;
    this.outlinePass.renderCamera = _camera;
  }

  this.dispose = function () {
    this.detach();

    scope = undefined;
  };

  this.pointerMove = function (pointer) {
    if (!this.objects) return;
    ray.setFromCamera(pointer, this.camera);

    let intersect;

    if (true === this.isDragging) {
      if (DRAG_MODE.MARK === dragMode) {
        intersect = ray.intersectObject(markControl, true)[0] || false;
        if (!intersect) {
          this.isDragging = false;
        }
      } else if (DRAG_MODE.OBJECT === dragMode || DRAG_MODE.RESTORE === dragMode) {
        intersect = ray.intersectObject(selObject, true)[0] || false;
        if (!intersect) {
          selObject = undefined;
          this.isDragging = false;
        }
      }

      return;
    }

    intersect = ray.intersectObject(markControl, true)[0] || false;

    if (!intersect || !intersect.object.parent) {
      if (!lastHoverMark) return;
      removeHoverObjects();
      markState[lastHoverMark] = MARK_STATE.DEFAULT;
      lastHoverMark = undefined;
    } else {
      let mainObj = intersect.object.parent;
      if (mainObj.name === lastHoverMark || markState[mainObj.name] === MARK_STATE.DISABLED) return;
      if (lastHoverMark) {
        removeHoverObjects();
        markState[lastHoverMark] = MARK_STATE.DEFAULT;
        lastHoverMark = undefined;
      }
      if (Config.Mark.CircleControlName !== intersect.object.name) return;

      lastHoverMark = mainObj.name;
      markState[lastHoverMark] = MARK_STATE.HOVER;
      let axis, align;
      [axis, align] = lastHoverMark.split("_");

      createHoverObjects(axis, align);

      selObject = undefined;
    }
  };

  this.pointerDown = function (pointer) {
    if (!this.objects || pointer.button !== 0) return;

    if (!this.enabled)
      return;

    ray.setFromCamera(pointer, this.camera);

    let intersect = ray.intersectObject(markControl, true)[0] || false;
    if (intersect) {
      let curObj = intersect.object.parent;
      if (curObj) {
        [dragAxis, dragAlign] = curObj.name.split("_");
        lastHoverMark = curObj.name;
        dragMode = DRAG_MODE.MARK;
        this.isDragging = true;
        return;
      }
    }

    intersect = ray.intersectObjects(this.objects, true)[0] || false;
    if (intersect) {
      if (selObject && selObject.uuid === intersect.object.uuid) {
        dragMode = DRAG_MODE.RESTORE;
        this.isDragging = true;
      } else {
        selObject = intersect.object;
        dragMode = DRAG_MODE.OBJECT;
        this.isDragging = true;
      }
    }
  };

  this.pointerUp = function (pointer) {
    if (!this.objects || pointer.button !== 0) return;

    if (true === this.isDragging) {
      if (DRAG_MODE.MARK === dragMode) {
        alignTo(dragAxis, dragAlign);
        calculateBoundingBox();

        if (!selObject) {
          selBoundingBox.copy(boundingBox);
          selBoundingBoxSize.copy(boundingBoxSize);
          selBoundingBoxCenter.copy(boundingBoxCenter);
        }

        updateMarkState();

        dragAxis = undefined;
        dragAlign = undefined;
        lastHoverMark = undefined;

        this.dispatchEvent({type: 'change'});
      } else if (DRAG_MODE.OBJECT === dragMode) {
        selBoundingBox = getBoundingBox(selObject);
        selBoundingBox.getSize(selBoundingBoxSize);
        selBoundingBox.getCenter(selBoundingBoxCenter);
      } else if (DRAG_MODE.RESTORE === dragMode) {
        selBoundingBox.copy(boundingBox);
        selBoundingBoxSize.copy(boundingBoxSize);
        selBoundingBoxCenter.copy(boundingBoxCenter);
        selObject = undefined;
      }
    }

    this.isDragging = false;
  };

  function getBoundingBox(obj) {

    for (let i = 0; i < scope.objects.length; ++i) {

      if (scope.objects[i].name === obj.name || (obj.parent && scope.objects[i].name === obj.parent.name))
        return scope.boundingBoxes[i];

    }

  }

  function getPointer(event) {
    if (!scope.elemRect)
      scope.refreshBoundingClientRect();

    let pointer = event.changedTouches ? event.changedTouches[0] : event;

    return {
      x: (pointer.clientX - scope.elemRect.left) / scope.elemRect.width * 2 - 1,
      y: -(pointer.clientY - scope.elemRect.top) / scope.elemRect.height * 2 + 1,
      button: event.button
    };
  }

  function onPointerDown(event) {
    scope.pointerDown(getPointer(event));
  }

  function onTouchDown(event) {
    scope.refreshBoundingClientRect();
    scope.pointerDown(getPointer(event));
  }

  function onPointerMove(event) {
    scope.pointerMove(getPointer(event));
  }

  function onPointerUp(event) {
    scope.pointerUp(getPointer(event));
  }

  function alignObjectTo(obj, axis, align) {
    let boxCenter = new Vector3();

    tBox3 = getBoundingBox(obj);
    tBox3.getCenter(boxCenter);

    tVector3_1.set(0, 0, 0);
    tVector3_2.set(0, 0, 0);

    if ("X" === axis) {
      tVector3_1.set(selBoundingBox.min.x, selBoundingBoxCenter.x, selBoundingBox.max.x);
      tVector3_2.set(tBox3.min.x, boxCenter.x, tBox3.max.x);
    } else if ("Y" === axis) {
      tVector3_1.set(selBoundingBox.min.y, selBoundingBoxCenter.y, selBoundingBox.max.y);
      tVector3_2.set(tBox3.min.y, boxCenter.y, tBox3.max.y);
    } else if ("Z" === axis) {
      tVector3_1.set(selBoundingBox.min.z, selBoundingBoxCenter.z, selBoundingBox.max.z);
      tVector3_2.set(tBox3.min.z, boxCenter.z, tBox3.max.z);
    }

    let offsetVector = new Vector3();
    let offset = 0.0;

    if ("MIN" === align) offset = tVector3_1.x - tVector3_2.x;
    else if ("MID" === align) offset = tVector3_1.y - tVector3_2.y;
    else if ("MAX" === align) offset = tVector3_1.z - tVector3_2.z;

    if ("X" === axis) offsetVector.set(offset, 0, 0);
    else if ("Y" === axis) offsetVector.set(0, offset, 0);
    else if ("Z" === axis) offsetVector.set(0, 0, offset);

    obj.matrix.premultiply(
      new Matrix4().makeTranslation(offsetVector.x, offsetVector.y, offsetVector.z)
    );
  }

  function alignTo(axis, align) {
    // console.log(axis, align)
    for (let i = 0; i < scope.objects.length; ++i) alignObjectTo(scope.objects[i], axis, align);

    removeHoverObjects();
  }

  function isObjectAlignedTo(obj, axis, align) {
    let boxCenter = new Vector3();

    tBox3 = getBoundingBox(obj);
    tBox3.getCenter(boxCenter);

    if ("X" === axis) {
      tVector3_1.set(boundingBox.min.x, boundingBoxCenter.x, boundingBox.max.x);
      tVector3_2.set(tBox3.min.x, boxCenter.x, tBox3.max.x);
    } else if ("Y" === axis) {
      tVector3_1.set(boundingBox.min.y, boundingBoxCenter.y, boundingBox.max.y);
      tVector3_2.set(tBox3.min.y, boxCenter.y, tBox3.max.y);
    } else if ("Z" === axis) {
      tVector3_1.set(boundingBox.min.z, boundingBoxCenter.z, boundingBox.max.z);
      tVector3_2.set(tBox3.min.z, boxCenter.z, tBox3.max.z);
    }

    let v1, v2;

    if ("MIN" === align) {
      v1 = tVector3_1.x;
      v2 = tVector3_2.x;
    } else if ("MID" === align) {
      v1 = tVector3_1.y;
      v2 = tVector3_2.y;
    } else if ("MAX" === align) {
      v1 = tVector3_1.z;
      v2 = tVector3_2.z;
    }

    return Math.abs(v1 - v2) < 1e-6;
  }

  function getMarkName(axis, align) {
    return axis + "_" + align;
  }

  function isAlignedTo(axis, align) {
    for (let i = scope.objects.length - 1; i >= 0; --i) {
      if (!isObjectAlignedTo(scope.objects[i], axis, align))
        return false;
    }
    return true;
  }

  function updateMarkState() {
    for (let axis in MARK_INFO) {
      for (let align in MARK_INFO[axis].POS) {
        if (isAlignedTo(axis, align)) markState[getMarkName(axis, align)] = MARK_STATE.DISABLED;
        else markState[getMarkName(axis, align)] = MARK_STATE.DEFAULT;
      }
    }

    // console.log(markState)
  }

  function updateMarkZAxis(boxSize, boxCenter) {
    let lineObj = markControl.getObjectByName(Config.Mark.ZAxisLineControlName);
    let linePos = lineObj.geometry.attributes.position;

    linePos.array[0] = boxCenter.x + boxSize.x / 2;
    linePos.array[1] = boxCenter.y - boxSize.y / 2;
    linePos.array[2] = boxCenter.z - boxSize.z / 2;
    linePos.array[3] = boxCenter.x + boxSize.x / 2;
    linePos.array[4] = boxCenter.y - boxSize.y / 2;
    linePos.array[5] = boxCenter.z + boxSize.z / 2;

    linePos.needsUpdate = true;
  }

  function updateMarkUnit(axis, align, boxSize, boxCenter) {
    let axisInfo = MARK_INFO[axis];
    let alignPos = axisInfo.POS[align];
    let mainObj = markControl.getObjectByName(getMarkName(axis, align));

    if (!mainObj) return;

    tVector3_1.set(boxCenter.x + boxSize.x * alignPos[0][0], boxCenter.y + boxSize.y * alignPos[0][1], boxCenter.z + boxSize.z * alignPos[0][2]);
    tVector3_2.set(boxCenter.x + boxSize.x * alignPos[1][0], boxCenter.y + boxSize.y * alignPos[1][1], boxCenter.z + boxSize.z * alignPos[1][2]);

    if (tVector3_1.x !== tVector3_2.x) tVector3_2.x += ((tVector3_2.x - tVector3_1.x) > 0 ? 1 : -1) * Config.Mark.AddLength * size / 60.0;
    else if (tVector3_1.y !== tVector3_2.y) tVector3_2.y += ((tVector3_2.y - tVector3_1.y) > 0 ? 1 : -1) * Config.Mark.AddLength * size / 60.0;
    else if (tVector3_1.z !== tVector3_2.z) tVector3_2.z += ((tVector3_2.z - tVector3_1.z) > 0 ? 1 : -1) * Config.Mark.AddLength * size / 60.0;

    let lineObj = mainObj.getObjectByName(Config.Mark.LineControlName);
    let linePos = lineObj.geometry.attributes.position;

    linePos.array[0] = tVector3_1.x;
    linePos.array[1] = tVector3_1.y;
    linePos.array[2] = tVector3_1.z;
    linePos.array[3] = tVector3_2.x;
    linePos.array[4] = tVector3_2.y;
    linePos.array[5] = tVector3_2.z;

    linePos.needsUpdate = true;

    lineObj.material.dispose();
    lineObj.material = new LineBasicMaterial({
      color: Config.Mark.Color[markState[getMarkName(axis, align)]],
      transparent: true,
      depthTest: false
    });

    let circleObj = mainObj.getObjectByName(Config.Mark.CircleControlName);

    circleObj.position.copy(tVector3_2);
    circleObj.rotation.set(axisInfo.ROT[0], axisInfo.ROT[1], axisInfo.ROT[2]);
    circleObj.scale.set(Config.Mark.CircleSize * size / 60.0, Config.Mark.CircleSize * size / 60.0, Config.Mark.CircleSize * size / 60.0);

    circleObj.material.dispose();
    circleObj.material = new MeshBasicMaterial({
      color: Config.Mark.Color[markState[getMarkName(axis, align)]],
      side: DoubleSide,
      transparent: true,
      depthTest: false
    });

    let length = new Vector3(axisInfo.EYE[0], axisInfo.EYE[1], axisInfo.EYE[2]).cross(scope.eye).length();

    let newVisible = length < Math.cos(Math.PI / 18);

    if (circleObj.visible && !newVisible) {

      circleObj.layers.disable(0);
      circleObj.layers.enable(1);
      lineObj.layers.disable(0);
      lineObj.layers.enable(1);

    } else if (!circleObj.visible && newVisible) {

      circleObj.layers.disable(1);
      circleObj.layers.enable(0);
      lineObj.layers.disable(1);
      lineObj.layers.enable(0);

    }

    circleObj.visible = newVisible;
    lineObj.visible = newVisible;
  }

  function calculateBoundingBox() {
    boundingBox = new Box3();
    for (let box of scope.boundingBoxes)
      boundingBox.union(box);

    boundingBox.getSize(boundingBoxSize);
    boundingBox.getCenter(boundingBoxCenter);
  }

  function createBottomPlane() {
    let geometry = new BufferGeometry();
    let position = [];
    let w = 0.5;

    position.push(
      -w, -w, 0,
      w, -w, 0,

      w, -w, 0,
      w, w, 0,

      w, w, 0,
      -w, w, 0,

      -w, w, 0,
      -w, -w, 0
    );

    geometry.attributes.position = new Float32BufferAttribute(position, 3);
    let lineSegments = new LineSegments(geometry, new LineDashedMaterial({
      color: 0x000000,
      dashSize: 0.03,
      gapSize: 0.01,
      transparent: true,
      depthTest: false
    }));
    lineSegments.computeLineDistances();
    lineSegments.visible = false;
    scene.add(lineSegments);
    return lineSegments;
  }

  function createZAxisLine() {
    let geometry = new BufferGeometry();
    geometry.attributes.position = new Float32BufferAttribute([0, 0, -0.5, 0, 0, 0.5], 3);
    let material = new LineDashedMaterial({
      color: 0x000000,
      dashSize: 0.03,
      gapSize: 0.01,
      transparent: true,
      depthTest: false
    });
    let line = new Line(geometry, material);
    line.computeLineDistances();
    line.visible = false;
    scene.add(line);
    return line;
  }

  function createMarkControlUnit(axis, align) {
    let group = new Group();

    // Create Circle
    let circleGeo = new CircleBufferGeometry(1, 50);
    let circleMat = new MeshBasicMaterial({
      color: Config.Mark.Color[MARK_STATE.DEFAULT],
      side: DoubleSide,
      transparent: true,
      depthTest: false
    });
    let circleMesh = new Mesh(circleGeo, circleMat);
    circleMesh.name = Config.Mark.CircleControlName;

    group.add(circleMesh);

    //Create Line
    let lineGeo = new BufferGeometry();
    let linePos = [0, 0, 0, 0, 0, 0];
    lineGeo.attributes.position = new Float32BufferAttribute(linePos, 3);
    let lineSegments = new LineSegments(lineGeo, new LineBasicMaterial({
      color: Config.Mark.Color[MARK_STATE.DEFAULT],
      transparent: true,
      depthTest: false
    }));
    lineSegments.computeLineDistances();
    lineSegments.name = Config.Mark.LineControlName;

    group.add(lineSegments);

    group.name = getMarkName(axis, align);
    return group;
  }

  function createMarkControl() {
    let group = new Group();
    group.name = `align-control-group`;

    for (let axis in MARK_INFO) {
      for (let align in MARK_INFO[axis].POS) {
        group.add(createMarkControlUnit(axis, align));
      }
    }

    // Create ZAxisLine
    let lineGeo = new BufferGeometry();
    let linePos = [0, 0, 0, 0, 0, 0];
    lineGeo.attributes.position = new Float32BufferAttribute(linePos, 3);
    let lineSegments = new LineSegments(lineGeo, new LineDashedMaterial({
      color: Config.Mark.Color[MARK_STATE.DEFAULT],
      side: DoubleSide,
      transparent: true,
      depthTest: false
    }));
    lineSegments.computeLineDistances();
    lineSegments.name = Config.Mark.ZAxisLineControlName;

    group.add(lineSegments);
    group.visible = false;

    scene.add(group);
    return group;
  }

  this.materials = {
    hover: createGhostedMaterial(0x120C40)
  };

};

AlignControl.prototype = Object.assign(Object.create(Object3D.prototype), Object.create(EventDispatcher.prototype),
  {

    constructor: AlignControl,

    isAlignControls: true

  });

export {AlignControl};