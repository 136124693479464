import * as React from 'react';
import Editor3d from './Editor3d';

export interface IEditor3dWrapperProps {
  elemId: string
  cubeView?: boolean
  minorView?: boolean
}

interface IEditor3dWrapperStates {
}

export default class Editor3dWrapper extends React.PureComponent<IEditor3dWrapperProps, IEditor3dWrapperStates> {
  editor?: Editor3d;

  componentDidMount() {
    let {elemId, cubeView, minorView} = this.props;
    this.editor = new Editor3d(elemId);
    if (cubeView)
      this.editor.initCube(elemId);
    if (minorView)
      this.editor.initMinor(elemId);
  }

  componentDidUpdate(prevProps: Readonly<IEditor3dWrapperProps>, prevState: Readonly<IEditor3dWrapperStates>) {
    if (!this.editor)
      return;

    const {minorView, cubeView, elemId} = this.props;
    const {minorView: _minorView, cubeView: _cubeView} = prevProps;

    if (_minorView && !minorView)
      this.editor.disposeMinor();
    else if (!_minorView && minorView)
      this.editor.initMinor(elemId);

    if (_cubeView && !cubeView)
      this.editor.disposeCube();
    else if (!_cubeView && cubeView)
      this.editor.initCube(elemId);
  }

  componentWillUnmount() {
    this.editor && this.editor.dispose();
    this.editor = undefined;
  }

  setActionCallback(actionCallback?: (action: any) => void) {
    if (this.editor) {
      if (actionCallback) {
        this.editor.actionCallback = actionCallback;
      } else {
        this.editor.actionCallback = () => {
        };
      }
    }
  }

  render() {
    const {elemId} = this.props;
    return (
      <div className="editor-3d">
        <div id={elemId}/>
      </div>
    );
  }
}