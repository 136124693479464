import {ComponentTypes, NonMaterialComponentTypes, OutputObjectParamTitle} from "./types";
import {composeTransform, decomposeTransform} from "../../utils";
import {CachedGeometryObjectTypes, GeometryObjectTypes, ObjectTypes} from "../xobject";
import {getMeshFromRef, Param_Compound, Param_Point, Param_Transform, ParamTitles, ParamValueTypes} from "../parameter";
import {duplicateObject} from "./index";
import {Calc} from "../calc";
import {Scene} from "../scene";
import {mat4, vec3} from "gl-matrix";
import {
  getBoundingBoxCenter,
  getBoundingBoxFromMesh,
  getBoundingBoxSize, getUniqueDesc,
  isBoundingBoxEmpty,
  unionBoundingBox,
  WompObjectRef
} from "../../WompObject";
import {IModelProperty} from "../types";

export interface IRenderedObjectInternal {
  value: any
  cache: any
  valueType: ObjectTypes
  property?: IModelProperty
}

export function createGumball() {
  let calc = Calc.createInScene(Scene.unset, ComponentTypes.Gumball, 'gumball');

  calc.addParameter(Param_Transform.create(calc, ParamTitles.Transform, true, false));
  calc.addParameter(Param_Point.create(calc, ParamTitles.OrgCenter, false, false, {type: ParamValueTypes.Float}));
  calc.addParameter(Param_Point.create(calc, ParamTitles.Position, false, true, {type: ParamValueTypes.Offset}));
  calc.addParameter(Param_Point.create(calc, ParamTitles.Scale, false, false, {type: ParamValueTypes.Scale}));
  calc.addParameter(Param_Point.create(calc, ParamTitles.Translate, false, false, {type: ParamValueTypes.Offset}));
  calc.addParameter(Param_Point.create(calc, ParamTitles.Rotate, false, true, {type: ParamValueTypes.Degree}));
  calc.addParameter(Param_Point.create(calc, ParamTitles.LocalSize, false, true, {type: ParamValueTypes.NonZeroLength}));
  calc.addParameter(Param_Point.create(calc, ParamTitles.GlobalSize, false, true, {type: ParamValueTypes.NonZeroLength}));
  calc.addParameter(Param_Point.create(calc, ParamTitles.Skew, false, true, {type: ParamValueTypes.Skew}));

  return calc;
}

export function createSimpleGumball() {
  let calc = Calc.createInScene(Scene.unset, ComponentTypes.Gumball, 'gumball');

  calc.addParameter(Param_Point.create(calc, ParamTitles.OrgCenter, false, false, {type: ParamValueTypes.Float}));
  calc.addParameter(Param_Point.create(calc, ParamTitles.Position, false, true, {type: ParamValueTypes.Offset}));
  calc.addParameter(Param_Point.create(calc, ParamTitles.GlobalSize, false, true, {type: ParamValueTypes.NonZeroLength}));

  return calc;
}

export function copyObject(object: IRenderedObjectInternal) {
  return {
    value: duplicateObject(object.value),
    valueType: object.valueType,
    cache: duplicateObject(object.cache),
    property: duplicateObject(object.property)
  };
}

export function copyObjects(objects: IRenderedObjectInternal[]) {
  return objects.map(copyObject);
}

function getObjectsBoundingBox(calc: Calc, objects: IRenderedObjectInternal[]) {
  let box = [vec3.fromValues(+Infinity, +Infinity, +Infinity), vec3.fromValues(-Infinity, -Infinity, -Infinity)];

  for (let i = 0; i < objects.length; ++i) {
    let value = objects[i].value;
    let valueType = objects[i].valueType;
    let cache = objects[i].cache as [WompObjectRef, WompObjectRef];

    let boundingBox = undefined;

    if (GeometryObjectTypes.includes(valueType)) {
      if (CachedGeometryObjectTypes.includes(valueType)) {
        let mesh = getMeshFromRef(calc, cache[0]);
        if (mesh) {
          boundingBox = getBoundingBoxFromMesh(mesh);
        }
      } else if (valueType === ObjectTypes.Vertex) {
        boundingBox = [value, value];
      } else {
        let mesh = getMeshFromRef(calc, value as WompObjectRef);
        if (mesh) {
          boundingBox = getBoundingBoxFromMesh(mesh);
        }
      }
    }

    if (boundingBox) {
      unionBoundingBox(box, boundingBox);
    }
  }

  return box;
}

export function applyObjectTransform(object: IRenderedObjectInternal, transform: mat4) {
  let value = object.value;
  let cache = object.cache as [WompObjectRef, WompObjectRef];
  let valueType = object.valueType;

  if (GeometryObjectTypes.includes(valueType)) {
    if (CachedGeometryObjectTypes.includes(valueType)) {
      mat4.multiply(value.matrix, transform, value.matrix);
      if (cache) {
        mat4.multiply(cache[0].matrix, transform, cache[0].matrix);
        mat4.multiply(cache[1].matrix, transform, cache[1].matrix);
      }
    } else if (valueType === ObjectTypes.Vertex) {
      vec3.transformMat4(value, value, transform);
    } else {
      mat4.multiply(value.matrix, transform, value.matrix);
    }
  }
  return object;
}

function applyObjectsTransform(objects: IRenderedObjectInternal[], transform: mat4) {
  for (let obj of objects)
    applyObjectTransform(obj, transform);
  return objects;
}

export function setObjectTransform(object: IRenderedObjectInternal, transform: mat4) {
  let value = object.value;
  let cache = object.cache as [WompObjectRef, WompObjectRef];
  let valueType = object.valueType;

  if (GeometryObjectTypes.includes(valueType)) {
    if (CachedGeometryObjectTypes.includes(valueType)) {
      mat4.copy(value.matrix, transform);
      if (cache) {
        mat4.copy(cache[0].matrix, transform);
        mat4.copy(cache[1].matrix, transform);
      }
    } else if (valueType === ObjectTypes.Vertex) {
    } else {
      mat4.copy(value.matrix, transform);
    }
  }

  return object;
}

export function solveSimpleGumball(calc: Calc, objects: IRenderedObjectInternal[]) {
  let orgCenterParam = calc.outputByTitle(ParamTitles.OrgCenter) as Param_Point;
  let positionParam = calc.outputByTitle(ParamTitles.Position) as Param_Point;
  let globalSizeParam = calc.outputByTitle(ParamTitles.GlobalSize) as Param_Point;

  let result = copyObjects(objects);
  let boundingBox = getObjectsBoundingBox(calc, result);

  if (isBoundingBoxEmpty(boundingBox)) {
    orgCenterParam.values = [vec3.create()];
    positionParam.values = [vec3.create()];
    globalSizeParam.values = [vec3.create()];

    return result;
  }

  let orgCenter = getBoundingBoxCenter(boundingBox);

  orgCenterParam.values = [orgCenter];
  positionParam.values = [vec3.clone(orgCenter)];

  orgCenterParam.adjustBounds();
  positionParam.adjustBounds();

  globalSizeParam.values = [getBoundingBoxSize(boundingBox)];

  return result;
}

export function solveGumball(calc: Calc, objects: IRenderedObjectInternal[]) {
  let transformParam = calc.inputByTitle(ParamTitles.Transform) as Param_Transform;
  let orgCenterParam = calc.outputByTitle(ParamTitles.OrgCenter) as Param_Point;
  let positionParam = calc.outputByTitle(ParamTitles.Position) as Param_Point;
  let scaleParam = calc.outputByTitle(ParamTitles.Scale) as Param_Point;
  let rotateParam = calc.outputByTitle(ParamTitles.Rotate) as Param_Point;
  let translateParam = calc.outputByTitle(ParamTitles.Translate) as Param_Point;
  let localSizeParam = calc.outputByTitle(ParamTitles.LocalSize) as Param_Point;
  let globalSizeParam = calc.outputByTitle(ParamTitles.GlobalSize) as Param_Point;
  let skewParam = calc.outputByTitle(ParamTitles.Skew) as Param_Point;

  let result = copyObjects(objects);
  let boundingBox = getObjectsBoundingBox(calc, result);

  if (isBoundingBoxEmpty(boundingBox)) {
    orgCenterParam.values = [vec3.create()];
    scaleParam.values = [vec3.fromValues(100, 100, 100)];
    rotateParam.values = [vec3.create()];
    translateParam.values = [vec3.create()];
    skewParam.values = [vec3.create()];
    localSizeParam.values = [vec3.create()];
    globalSizeParam.values = [vec3.create()];
    positionParam.values = [vec3.create()];

    return result;
  }

  let orgCenter = getBoundingBoxCenter(boundingBox);

  let transform = mat4.fromTranslation(mat4.create(), vec3.negate(vec3.create(), orgCenter));
  let gumballTransform = mat4.create();

  if (transformParam.length > 0) {
    mat4.multiply(gumballTransform, transformParam.values[0], gumballTransform);
  }

  let {translate, rotate, scale, skew} = decomposeTransform(gumballTransform);
  let localTransform = composeTransform(vec3.create(), vec3.create(), scale, skew);

  mat4.multiply(transform, localTransform, transform);

  applyObjectsTransform(result, transform);

  boundingBox = getObjectsBoundingBox(calc, result);
  let localSize = getBoundingBoxSize(boundingBox);

  mat4.multiply(transform, gumballTransform, mat4.invert(mat4.create(), localTransform));
  mat4.multiply(transform, mat4.fromTranslation(mat4.create(), orgCenter), transform);

  applyObjectsTransform(result, transform);

  boundingBox = getObjectsBoundingBox(calc, result);
  let globalSize = getBoundingBoxSize(boundingBox);
  let position = getBoundingBoxCenter(boundingBox);

  vec3.scale(scale, scale, 100);
  vec3.set(rotate, (rotate[0] / Math.PI * 180 + 360) % 360, (rotate[1] / Math.PI * 180 + 360) % 360, (rotate[2] / Math.PI * 180 + 360) % 360);

  orgCenterParam.values = [orgCenter];
  scaleParam.values = [scale];
  rotateParam.values = [rotate];
  translateParam.values = [translate];
  localSizeParam.values = [localSize];
  skewParam.values = [skew];
  globalSizeParam.values = [globalSize];
  positionParam.values = [position];

  orgCenterParam.adjustBounds();
  scaleParam.adjustBounds();
  translateParam.adjustBounds();
  localSizeParam.adjustBounds();
  skewParam.adjustBounds();
  globalSizeParam.adjustBounds();
  positionParam.adjustBounds();

  return result;
}

export class Gumball {
  static create() {
    let calc = createGumball();

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let outParam = Param_Compound.create(calc, ParamTitles.Out, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(outParam);

    return calc;
  }

  static async solve(calc: Calc) {
    return true;
  }

  static hasGumball(calc: Calc) {
    return !calc.inputByTitle(ParamTitles.Transform).isInvalid;
  }

  static solveGumball(calc: Calc, objects: IRenderedObjectInternal[]) {
    let outParam = calc.outputByTitle(OutputObjectParamTitle[calc.component]) as Param_Compound;
    if (outParam.isInvalid)
      return false;

    let result = Gumball.hasGumball(calc) ? solveGumball(calc, objects) : solveSimpleGumball(calc, objects);
    if (result === undefined)
      return false;

    outParam.values = result.map(o => o.value);

    if (outParam.caches)
      outParam.caches = result.map(o => o.cache);

    if (outParam.valueTypes)
      outParam.valueTypes = result.map(o => o.valueType);

    outParam.properties = result.map(o => (
      {
        ...(o.property || {
          material: calc.material,
          valid: true,
          weldAngle: 0
        }),
        hash: getUniqueDesc(o.value),
        modelType: o.valueType,
        voided: calc.voided
      }
    ));

    return true;
  }
}