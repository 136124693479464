/**
 * @author arodic / https://github.com/arodic
 */

import {
  AdditiveBlending,
  Box3,
  BoxBufferGeometry,
  BufferGeometry,
  Color,
  CylinderBufferGeometry,
  DoubleSide,
  EventDispatcher,
  Float32BufferAttribute,
  Group,
  Line,
  Points,
  LineDashedMaterial,
  LineSegments,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneBufferGeometry,
  Raycaster,
  ShaderMaterial,
  SphereBufferGeometry,
  Vector3
} from 'three';
import lod from "lodash";

const DRAG_MODE = {
  OBJECT: 1,
  GAP: 2,
  SCALE: 3
};

const SCALE_PICKER_INFO = {
  L: [[-0.5, 0, -0.5], 1],
  R: [[0.5, 0, -0.5], 1],
  U: [[0, 0.5, -0.5], 1],
  D: [[0, -0.5, -0.5], 1],
  T: [[0, 0, 0.5], 0],
  B: [[0, 0, -0.5], 0]
};

const SCALE_INDICATOR_STATE = {
  NONE: 0,
  LR: 1,
  UD: 2,
  TB: 4,
  NONSHIFT: 7,
  SHIFT: 8,
};

const SCALE_HOVER_STATE = {
  NONE: 0,
  LR: 1,
  UD: 2,
  T: 4,
};

const GAP_HOVER_STATE = {
  NONE: 0,
  X: 1,
  Y: 2,
  Z: 4,
};

const GAP_INDICATOR_STATE = {
  NONE: 0,
  X: 1,
  Y: 2,
  Z: 4,
};

let ArrayControl = function (camera, scene) {
  Object3D.call(this);

  this.domElement = document;
  let timer = setInterval(timerFunc, 1000);
  let curTick = 0;
  let lastEventTick = undefined;

  let Config = {
    Input: {
      WIDTH: 80,
      HEIGHT: 32
    },
    ShowTime: 5
  };
  let inputBoxes = [createInputElement('A_INP_0'), createInputElement('A_INP_1'), createInputElement('A_INP_2')];

  this.visible = false;
  this.camera = camera;
  this.scene = scene;
  this.eye = undefined;
  // this.intersectPlane = new Mesh(new PlaneBufferGeometry(1000, 1000),
  // 							   new MeshBasicMaterial({ visible: true, wireframe: false, side: DoubleSide, transparent: true, opacity: 0.1, color: 0x00ff00}))
  this.intersectPlane = new Mesh(new PlaneBufferGeometry(300000, 300000),
    new MeshBasicMaterial({visible: false, wireframe: false, side: DoubleSide, transparent: true}));
  this.intersectPlane.name = 'array-control-intersect-plane';
  this.ray = new Raycaster();

  this.oldState = {};

  this.arraySize = new Vector3(1, 1, 1);
  this.itemOffset = new Vector3(0, 0, 0);
  this.gapSize = new Vector3(5, 5, 5);
  this.boundingBox = new Box3();

  this.spriteSize = 1.2;
  this.isDragging = false;
  this.isInner = false;
  this.dragMode = undefined;
  this.dragAxis = undefined;
  this.dragStartPosition = undefined;
  this.focusedInputId = -1;
  this.shiftKeyState = false;

  this.objects = [];
  this.gaps = [[], [], []];

  this.elemRect = undefined;

  let scope = this;

  let unitX = new Vector3(1, 0, 0);
  let unitY = new Vector3(0, 1, 0);
  let unitZ = new Vector3(0, 0, 1);
  let tempVector = new Vector3();
  let tempMatrix = new Matrix4();
  let alignVector = new Vector3();
  let dirVector = new Vector3();

  let objectArraySize = new Vector3(1, 1, 1);
  let objectItemOffset = new Vector3(0, 0, 0);
  let objectGapSize = new Vector3(0, 0, 0);

  let scaleHoverState = SCALE_HOVER_STATE.NONE;
  let scaleHoverPickerName = undefined;
  let scaleIndicatorState = SCALE_INDICATOR_STATE.NONE;

  let gapHoverState = GAP_HOVER_STATE.NONE;
  let gapHoverPickerName = undefined;
  let gapIndicatorState = GAP_INDICATOR_STATE.NONE;

  let scaleOffset = new Vector3();

  function commitInputValue(id, val) {
    if (DRAG_MODE.OBJECT === scope.dragMode) {

    } else if (DRAG_MODE.SCALE === scope.dragMode) {

      val = Math.max(Math.round(val), 1);

      scope.arraySize.setComponent(id, val);

      if (scope.itemOffset.getComponent(id) >= val)
        scope.itemOffset.setComponent(id, val - 1);

    } else if (DRAG_MODE.GAP === scope.dragMode) {

      val = +val.toFixed(2);

      scope.gapSize.setComponent(id, val);
    }

    if (scope.visible && scope.object !== undefined)
      scope.dispatchEvent({type: 'change'});

    return val;
  }

  function createInputElement(className) {
    let elem = document.createElement('input');
    elem.style.position = 'fixed';
    elem.style.zIndex = 9999;
    elem.style.top = '0px';
    elem.style.left = '0px';
    elem.style.width = Config.Input.WIDTH + 'px';
    elem.style.height = Config.Input.HEIGHT + 'px';
    elem.autocomplete = 'off';
    elem.className = className;

    elem.onkeypress = (evt) => {
      if (evt.key !== 'Enter') return;

      let target = evt.target || evt.srcElement;
      // let id = parseInt(target.id.split('_')[2])
      // let val = parseFloat(inputBoxes[id].value)
      // commitInputValue(id, val)
      target.blur();
    };

    elem.onkeydown = (evt) => {
      // console.log('tick set', lastEventTick, curTick)
      lastEventTick = curTick;
    };

    elem.onfocus = (evt) => {
      // console.log('tick set', lastEventTick, curTick)
      lastEventTick = curTick;

      let target = evt.target || evt.srcElement;
      let id = parseInt(target.id.split('_')[2]);

      // console.log('focus', scope.focusedInputId, id)
      if (scope.focusedInputId !== id)
        scope.focusedInputId = id;
    };

    elem.onblur = (evt) => {
      // console.log('tick set', lastEventTick, curTick)
      lastEventTick = curTick;

      let target = evt.target || evt.srcElement;
      let id = parseInt(target.id.split('_')[2]);
      let val = parseFloat(inputBoxes[id].value);
      let newVal = commitInputValue(id, val);
      if (newVal !== val) {
        target.value = newVal;
      }

      // console.log('blur', scope.focusedInputId, id)
      if (scope.focusedInputId === id)
        scope.focusedInputId = -1;
    };
    // elem.onchange = function() { lastEventTick = curTick }

    document.body.appendChild(elem);
    return elem;
  }

  function moveElement(elem, tempVector) {
    tempVector.project(scope.camera).setZ(0);

    if (!scope.elemRect)
      scope.refreshBoundingClientRect();

    let rect = scope.elemRect;

    let x = tempVector.x * rect.width / 2 + rect.width / 2 + rect.left - Config.Input.WIDTH / 2;
    let y = -tempVector.y * rect.height / 2 + rect.height / 2 + rect.top - Config.Input.HEIGHT / 2;
    elem.style.top = y + 'px';
    elem.style.left = x + 'px';
  }

  function createGhostedMaterial(color) {

    let vertexShader = [
      'uniform float p;',
      'varying float intensity;',
      'void main() {',
      ' vec3 transformed = vec3( position );',
      ' vec4 mvPosition = vec4( transformed, 1.0 );',
      ' #ifdef USE_INSTANCING',
      '  mvPosition = instanceMatrix * mvPosition;',
      ' #endif',
      ' vec4 modelViewPosition = modelViewMatrix * mvPosition;',
      ' vec3 vNormal = normalize( normalMatrix * normal );',
      ' intensity = pow(1.0 - abs(dot(vNormal, vec3(0, 0, 1))), p);',
      ' gl_Position = projectionMatrix * modelViewPosition;',
      '}'
    ].join('\n');

    let fragmentShader = [
      'uniform vec3 glowColor;',
      'varying float intensity;',
      'void main() {',
      ' vec3 glow = glowColor * intensity;',
      ' gl_FragColor = vec4( glow, 1.0 );',
      '}'
    ].join('\n');

    return new ShaderMaterial({
      uniforms: {
        p: {value: 2},
        glowColor: {value: new Color(color)}
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      side: DoubleSide, blending: AdditiveBlending,
      transparent: true, depthWrite: false
    });

  }

  this.setSize = function (size) {
    this.spriteSize = size * 12.0;
    // console.log(this.spriteSize)
  };

  function timerFunc() {
    ++curTick;
    // console.log(lastEventTick, curTick)
  }

  this.dispose = function () {
    this.detach();

    clearInterval(timer);

    if (inputBoxes) {
      for (let inputBox of inputBoxes) {
        inputBox.remove();
      }
    }

    scope = null;
  };

  this.attach = function (object) {
    this.object = object;
    this.arraySize.set(1, 1, 1);
    this.visible = true;
    this.refresh();

    scope.dispatchEvent({type: 'change'});

    lastEventTick = -Config.ShowTime - 1;
    // console.log('attach', this.object, this.visible)
  };

  this.detach = function () {
    this.object = undefined;
    this.visible = false;

    this.arraySize.set(1, 1, 1);
    // console.log('detach', this.object, this.visible)
  };

  function hideElement(elem) {
    if (elem.style.display !== 'none')
      elem.style.display = 'none';
  }

  function showElement(elem) {
    if (elem.style.display !== 'block')
      elem.style.display = 'block';
  }

  function activateElementEvent(active) {
    for (let i = 0; i < inputBoxes.length; ++i)
      inputBoxes[i].style.pointerEvents = active ? 'auto' : 'none';
  }

  this.refreshBoundingClientRect = function() {
    this.elemRect = this.domElement === document ? document.body.getBoundingClientRect() : this.domElement.getBoundingClientRect();
  };

  this.setDelegate = function (delegate) {

    this.unsetDelegate();

    delegate.domElement.addEventListener('mousedown', onPointerDown);
    delegate.domElement.addEventListener('touchstart', onTouchDown);

    delegate.domElement.addEventListener('mousemove', onPointerMove);
    delegate.domElement.addEventListener('touchmove', onPointerMove);

    delegate.domElement.addEventListener('mouseup', onPointerUp);
    delegate.domElement.addEventListener('touchend', onPointerUp);
    delegate.domElement.addEventListener('touchcancel', onPointerUp);
    delegate.domElement.addEventListener('touchleave', onPointerUp);

    this.domElement = delegate.domElement;

    this.refreshBoundingClientRect();
  }

  this.unsetDelegate = function () {

    if (this.domElement === document)
      return;

    this.domElement.removeEventListener('mousedown', onPointerDown);
    this.domElement.removeEventListener('touchstart', onTouchDown);

    this.domElement.removeEventListener('mousemove', onPointerMove);
    this.domElement.removeEventListener('touchmove', onPointerMove);

    this.domElement.removeEventListener('mouseup', onPointerUp);
    this.domElement.removeEventListener('touchend', onPointerUp);
    this.domElement.removeEventListener('touchcancel', onPointerUp);
    this.domElement.removeEventListener('touchleave', onPointerUp);

    this.domElement = document;

  }

  this.getObjectUnitAxes = function (x, y, z) {
    x.set(1, 0, 0);
    y.set(0, 1, 0);
    z.set(0, 0, 1);
  };

  this.deleteObjectArray = function (dim, inds) {
    let target = this.objects;
    let parent = target;
    if (dim > 0) {
      parent = target;
      target = target[inds[0]];
    }

    if (dim > 1) {
      parent = target;
      target = target[inds[1]];
    }
    if (dim > 2) {
      parent = target;
      target = target[inds[2]];
    }

    if (target === undefined) {
      return;
    }

    if (dim === 3) {
      this.scene.remove(target);
    } else {
      let len = target.length;
      for (let i = len - 1; i >= 0; --i) {
        this.deleteObjectArray(dim + 1, [...inds, i]);
      }
    }
    parent.length = inds[dim - 1];
  };

  this.resizeObjectArray = function (dim, inds) {
    let target = this.objects;
    let parent = target;
    if (dim > 0) {
      parent = target;
      target = target[inds[0]];
    }

    if (dim > 1) {
      parent = target;
      target = target[inds[1]];
    }
    if (dim > 2) {
      parent = target;
      target = target[inds[2]];
    }

    if (dim === 3) {
      if (target === undefined) {
        let newObj = this.object.clone(true);

        newObj.traverse((object) => {
          if (object.isMesh)
            object.material = this.materials.object;
        });
        parent[inds[2]] = newObj;
        this.scene.add(newObj);
      }
    } else {
      let curDimSize = this.arraySize.getComponent(dim);

      if (target === undefined) {
        if (dim === 0)
          this.objects = new Array(curDimSize);
        else
          parent[inds[dim - 1]] = new Array(curDimSize);
      } else if (target.length > curDimSize) {
        let len = target.length;

        for (let i = len - 1; i >= curDimSize; --i) {
          this.deleteObjectArray(dim + 1, [...inds, i]);
        }
      }
      for (let i = 0; i < curDimSize; ++i) {
        this.resizeObjectArray(dim + 1, [...inds, i]);
      }
    }
  };

  this.resizeGapArray = function (dim) {
    let target = this.gaps[dim];
    let curDimSize = Math.max(this.arraySize.getComponent(dim) - 1, 0);

    let len = target.length;
    if (len > curDimSize) {

      for (let i = len - 1; i >= curDimSize; --i) {
        this.scene.remove(target[i]);
        target.length = i;
      }

    } else if (curDimSize > len) {

      for (let i = len; i < curDimSize; ++i) {
        let newGapObject = this.gapObject.clone();
        newGapObject.name = ['X', 'Y', 'Z'][dim];
        newGapObject.material = this.materials.gap[newGapObject.name];
        target.push(newGapObject);
        this.scene.add(newGapObject);
      }
    }
  };


  this.setConfig = function ({gapSize, arraySize, itemOffset}) {

    if (gapSize !== undefined)
      this.gapSize = gapSize;

    if (arraySize !== undefined)
      this.arraySize = arraySize;

    if (itemOffset !== undefined)
      this.itemOffset = itemOffset;

    scope.dispatchEvent({type: 'change'});

  };

  this.refresh = function () {

    let newState = {};

    newState.enabled = this.enabled;

    if (this.object) {
      new Matrix4().decompose(this.controlGroup.position, this.controlGroup.quaternion, this.controlGroup.scale);
    } else {
      this.arraySize.set(0, 0, 0);
      this.itemOffset.set(0, 0, 0);
    }

    for (let i = 0; i < inputBoxes.length; ++i) hideElement(inputBoxes[i]);

    this.resizeObjectArray(0, []);
    this.resizeGapArray(0);
    this.resizeGapArray(1);
    this.resizeGapArray(2);

    this.bottomPlane.visible = false;
    this.zAxisLine.visible = false;
    this.scalePicker.visible = false;

    newState.visible = this.visible;

    if (this.object !== undefined && this.visible) {

      newState.objectId = this.object.id;
      newState.dragMode = this.dragMode;
      newState.isDragging = this.isDragging;
      newState.scaleHoverState = scaleHoverState;
      newState.scaleHoverPickerName = scaleHoverPickerName;
      newState.scaleIndicatorState = scaleIndicatorState;
      newState.gapHoverState = gapHoverState;
      newState.gapHoverPickerName = gapHoverPickerName;
      newState.gapIndicatorState = gapIndicatorState;
      newState.spriteSize = this.spriteSize;
      newState.exceedTick = (lastEventTick + Config.ShowTime >= curTick);
      newState.arraySize = this.arraySize.clone();
      newState.gapSize = this.gapSize.clone();
      newState.itemOffset = this.itemOffset.clone();

      let boundingBoxSize = new Vector3();
      let objCenter = new Vector3();

      this.boundingBox.getSize(boundingBoxSize);
      this.boundingBox.getCenter(objCenter);

      let itemSize = this.gapSize.clone().add(boundingBoxSize);

      for (let i = 0; i < this.arraySize.x; ++i) {
        for (let j = 0; j < this.arraySize.y; ++j) {
          for (let k = 0; k < this.arraySize.z; ++k) {
            this.objects[i][j][k].position.set(
              (i - this.itemOffset.x) * itemSize.x,
              (j - this.itemOffset.y) * itemSize.y,
              (k - this.itemOffset.z) * itemSize.z
            );
            this.objects[i][j][k].updateMatrix();
            if (i === this.itemOffset.x && j === this.itemOffset.y && k === this.itemOffset.z) {
              this.objects[i][j][k].visible = false;
            } else {
              this.objects[i][j][k].visible = true;
            }
          }
        }
      }

      let arrayBoundingBoxSize = boundingBoxSize.clone().multiply(this.arraySize).add(this.gapSize.clone().multiply(this.arraySize.clone().add(new Vector3(-1, -1, -1))));

      let arrayCorner = new Vector3();
      arrayCorner.x = objCenter.x - this.itemOffset.x * itemSize.x - boundingBoxSize.x / 2;
      arrayCorner.y = objCenter.y - this.itemOffset.y * itemSize.y - boundingBoxSize.y / 2;
      arrayCorner.z = objCenter.z - this.itemOffset.z * itemSize.z - boundingBoxSize.z / 2;

      let arrayCenter = arrayCorner.clone().addScaledVector(arrayBoundingBoxSize, 0.5);

      for (let i = 0; i < 3; ++i) {
        let curDimSize = this.arraySize.getComponent(i) - 1;
        for (let j = 0; j < curDimSize; ++j) {
          this.gaps[i][j].scale.set(
            i === 0 ? Math.abs(this.gapSize.x) : arrayBoundingBoxSize.x,
            i === 1 ? Math.abs(this.gapSize.y) : arrayBoundingBoxSize.y,
            i === 2 ? Math.abs(this.gapSize.z) : arrayBoundingBoxSize.z,
          );

          this.gaps[i][j].position.set(
            i === 0 ? arrayCorner.x + (itemSize.x * (j + 1) - this.gapSize.x / 2) : arrayCenter.x,
            i === 1 ? arrayCorner.y + (itemSize.y * (j + 1) - this.gapSize.y / 2) : arrayCenter.y,
            i === 2 ? arrayCorner.z + (itemSize.z * (j + 1) - this.gapSize.z / 2) : arrayCenter.z,
          );
        }
      }

      this.bottomPlane.visible = true;
      this.bottomPlane.position.set(arrayCenter.x, arrayCenter.y, arrayCenter.z - arrayBoundingBoxSize.z / 2);
      this.bottomPlane.scale.set(arrayBoundingBoxSize.x, arrayBoundingBoxSize.y, 1);

      this.zAxisLine.visible = true;
      this.zAxisLine.position.copy(arrayCenter);
      this.zAxisLine.scale.set(1, 1, arrayBoundingBoxSize.z);

      if (this.isDragging && DRAG_MODE.SCALE !== this.dragMode) {

        this.scalePicker.visible = false;

      } else {

        this.scalePicker.visible = true;
        let scalePikerArray = this.scalePicker.children;

        for (let i = scalePikerArray.length; i--;) {

          let curPicker = scalePikerArray[i];
          let curName = curPicker.name;

          curPicker.material = curName === scaleHoverPickerName ? this.materials.scale.hover : this.materials.scale.normal;

          curPicker.position.set(arrayCenter.x + SCALE_PICKER_INFO[curPicker.name][0][0] * arrayBoundingBoxSize.x,
            arrayCenter.y + SCALE_PICKER_INFO[curPicker.name][0][1] * arrayBoundingBoxSize.y,
            arrayCenter.z + SCALE_PICKER_INFO[curPicker.name][0][2] * arrayBoundingBoxSize.z);
          curPicker.scale.set(this.spriteSize / 10, this.spriteSize / 10, this.spriteSize / 10);

        }

      }

      if ((scaleHoverState || DRAG_MODE.SCALE === this.dragMode) && (lastEventTick + Config.ShowTime >= curTick || this.isDragging)) {

        for (let i = 0; i < 3; ++i) {

          let xLen = this.arraySize.getComponent(i);

          // TODO: BYZ - check if this is right
          if (((scaleHoverState & (1 << i)) || DRAG_MODE.SCALE === this.dragMode) && (lastEventTick + Config.ShowTime >= curTick || this.isDragging) && ((SCALE_INDICATOR_STATE.SHIFT & scaleIndicatorState) || (scaleIndicatorState & (1 << i)))) {

            showElement(inputBoxes[i]);

            moveElement(inputBoxes[i], new Vector3(
              i === 0 ? arrayCorner.x : arrayCenter.x,
              i === 1 ? arrayCorner.y : arrayCenter.y,
              i === 2 ? arrayCorner.z : arrayCenter.z,
            ));

            // if (scaleHoverState || lastEventTick + Config.ShowTime < curTick)
            if (this.focusedInputId < 0) {
              inputBoxes[i].value = xLen.toFixed(0);
            }
          }
        }
      }

      if (this.isDragging && DRAG_MODE.GAP !== this.dragMode) {

        for (let i = 0; i < 3; ++i) {
          let curName = ['X', 'Y', 'Z'][i];
          this.materials.gap[curName].visible = false;
        }

      } else {

        for (let i = 0; i < 3; ++i) {
          let curName = ['X', 'Y', 'Z'][i];
          if (curName === gapHoverPickerName)
            this.materials.gap[curName].visible = true;
          else
            this.materials.gap[curName].visible = false;
        }
      }

      // TODO: BYZ - check if this is right
      if ((gapHoverState || DRAG_MODE.GAP === this.dragMode) && (lastEventTick + Config.ShowTime >= curTick || this.isDragging)) {

        for (let i = 0; i < 3; ++i) {

          let xLen = this.gapSize.getComponent(i);

          // TODO: BYZ - check if this is right
          if (((gapHoverState & (1 << i)) || DRAG_MODE.GAP === this.dragMode) && (lastEventTick + Config.ShowTime >= curTick || this.isDragging)) {

            showElement(inputBoxes[i]);

            moveElement(inputBoxes[i], new Vector3(
              i === 0 ? arrayCorner.x : arrayCenter.x,
              i === 1 ? arrayCorner.y : arrayCenter.y,
              i === 2 ? arrayCorner.z : arrayCenter.z,
            ));

            if (this.focusedInputId < 0) {
              inputBoxes[i].value = xLen.toFixed(2);
            }
          }
        }
      }

      this.eye = this.camera.getWorldDirection(new Vector3());

    }

    // Object3D.prototype.updateMatrixWorld.call(this)
    let hadUpdate = !lod.isEqual(newState, this.oldState);
    this.oldState = newState;
    return hadUpdate;
  };

  function getPointer(event) {
    if (!scope.elemRect)
      scope.refreshBoundingClientRect();

    let pointer = event.changedTouches ? event.changedTouches[0] : event;

    // console.log(pointer, rect)
    return {
      x: (pointer.clientX - scope.elemRect.left) / scope.elemRect.width * 2 - 1,
      y: -(pointer.clientY - scope.elemRect.top) / scope.elemRect.height * 2 + 1,
      button: event.button
    };
  }

  this.pointerDown = function (pointer, event) {
    lastEventTick = -Config.ShowTime - 1;
    if (!this.object) return;
    if (pointer.button !== 0) return;

    this.shiftKeyState = event.shiftKey;

    let intersect;
    this.ray.setFromCamera(pointer, this.camera);

    this.getObjectUnitAxes(unitX, unitY, unitZ);
    let orientVectorZ = unitZ.clone().cross(this.eye).length();

    // Check Scale Picker Is Down
    intersect = this.ray.intersectObject(this.scalePicker, true)[0] || false;
    if (intersect) {
      this.dragAxis = intersect.object.name;

      if ('T' !== this.dragAxis && 'B' !== this.dragAxis) {
        if (orientVectorZ < Math.cos(Math.PI / 18)) {
          let boundingBoxSize = new Vector3();
          this.boundingBox.getSize(boundingBoxSize);
          tempVector.copy(this.object.position);
          tempVector.addScaledVector(unitZ, -boundingBoxSize.z / 2);
          this.intersectPlane.position.copy(tempVector);
          this.intersectPlane.quaternion.set(0, 0, 0, 1);
          this.intersectPlane.updateMatrixWorld();
        } else {
          this.setIntersectPlane(unitZ);
        }
      } else {
        this.setIntersectPlane(unitZ);
      }

      intersect = this.ray.intersectObject(this.intersectPlane, true)[0] || false;
      if (intersect) {
        this.dragStartPosition = intersect.point;
        this.dragMode = DRAG_MODE.SCALE;
        this.isDragging = true;
        activateElementEvent(false);

        scaleIndicatorState = SCALE_INDICATOR_STATE.NONE;
        if (this.dragAxis.includes('L') || this.dragAxis.includes('R')) scaleIndicatorState |= SCALE_INDICATOR_STATE.LR;
        if (this.dragAxis.includes('U') || this.dragAxis.includes('D')) scaleIndicatorState |= SCALE_INDICATOR_STATE.UD;
        if (this.dragAxis.includes('T') || this.dragAxis.includes('B')) scaleIndicatorState |= SCALE_INDICATOR_STATE.TB;

        objectArraySize.copy(this.arraySize);
        objectItemOffset.copy(this.itemOffset);
        objectGapSize.copy(this.gapSize);

        return;
      }
    }

    intersect = this.ray.intersectObjects(this.gaps.flatMap(gs => gs))[0] || false;

    if (intersect) {
      this.dragAxis = intersect.object.name;

      if (this.dragAxis === 'X') {
        this.setIntersectPlane(unitX);
      }

      if (this.dragAxis === 'Y') {
        this.setIntersectPlane(unitY);
      }

      if (this.dragAxis === 'Z') {
        this.setIntersectPlane(unitZ);
      }

      intersect = this.ray.intersectObject(this.intersectPlane, true)[0] || false;
      if (intersect) {
        // console.log('drag picker')

        this.dragStartPosition = intersect.point;
        this.dragMode = DRAG_MODE.GAP;
        this.isDragging = true;
        activateElementEvent(false);

        gapIndicatorState = GAP_INDICATOR_STATE.NONE;
        if (this.dragAxis === 'X') gapIndicatorState |= GAP_INDICATOR_STATE.X;
        if (this.dragAxis === 'Y') gapIndicatorState |= GAP_INDICATOR_STATE.Y;
        if (this.dragAxis === 'Z') gapIndicatorState |= GAP_INDICATOR_STATE.Z;

        objectArraySize.copy(this.arraySize);
        objectItemOffset.copy(this.itemOffset);
        objectGapSize.copy(this.gapSize);

        return;
      }
    }

    // Check Start Dragging Object
    intersect = this.ray.intersectObject(this.object, true)[0] || false;
    if (intersect) {
      if (orientVectorZ < Math.cos(Math.PI / 18)) {
        this.intersectPlane.quaternion.set(0, 0, 0, 1);
        this.intersectPlane.updateMatrixWorld();
      } else {
        this.setIntersectPlane(unitZ);
      }

      intersect = this.ray.intersectObject(this.intersectPlane, true)[0] || false;
      if (intersect) {
        // console.log('drag picker')

        this.dragStartPosition = intersect.point;
        this.dragMode = DRAG_MODE.OBJECT;
        this.isDragging = true;
        activateElementEvent(false);

        objectArraySize.copy(this.arraySize);
        objectItemOffset.copy(this.itemOffset);
        objectGapSize.copy(this.gapSize);

        return;
      }
    }

    if (!this.isDragging) {
      this.dragMode = undefined;
      lastEventTick = -Config.ShowTime - 1;
    }
  };

  this.pointerMove = function (pointer, event) {
    if (!this.object) return;

    this.shiftKeyState = event.shiftKey;

    this.ray.setFromCamera(pointer, this.camera);

    if (!this.isDragging) {

      scaleHoverPickerName = undefined;
      scaleHoverState = SCALE_HOVER_STATE.NONE;
      gapHoverPickerName = undefined;
      gapHoverState = GAP_HOVER_STATE.NONE;

      let intersect = this.ray.intersectObjects([this.scalePicker], true)[0] || false;

      if (intersect) {

        let objName = intersect.object.name;

        if (intersect.object.parent === this.scalePicker) {

          scaleHoverPickerName = objName;

          scaleHoverState = SCALE_HOVER_STATE.NONE;
          if (objName.includes('L') || objName.includes('R')) scaleHoverState |= SCALE_HOVER_STATE.LR;
          if (objName.includes('U') || objName.includes('D')) scaleHoverState |= SCALE_HOVER_STATE.UD;
          if (objName.includes('T')) scaleHoverState |= SCALE_HOVER_STATE.T;

        }

      } else {

        intersect = this.ray.intersectObjects(this.gaps.flatMap(gs => gs))[0] || false;

        if (intersect) {

          let objName = intersect.object.name;

          gapHoverPickerName = objName;

          if (objName === 'X') gapHoverState = GAP_HOVER_STATE.X;
          if (objName === 'Y') gapHoverState = GAP_HOVER_STATE.Y;
          if (objName === 'Z') gapHoverState = GAP_HOVER_STATE.Z;

        }
      }

      return;

    }

    let intersect = this.ray.intersectObject(this.intersectPlane, true)[0] || false;
    // console.log('Intersect Plane : ' + intersect)

    if (!intersect) return;

    if (DRAG_MODE.OBJECT === this.dragMode) {

      // if (!this.ctrlKeyState) {

      // 	let offset = intersect.point.clone().addScaledVector(this.dragStartPosition, -1)
      // 	let xyOffset = unitX.clone().multiplyScalar(offset.dot(unitX)).add(unitY.clone().multiplyScalar(offset.dot(unitY)))

      // 	objectMatrix.clone()
      // 		.premultiply(tempMatrix.makeTranslation(xyOffset.x, xyOffset.y, xyOffset.z))
      // 		.decompose(this.object.position, this.object.quaternion, this.object.scale)
      // 	this.object.updateMatrix()

      // }

    } else if (DRAG_MODE.GAP === this.dragMode) {

      let offset = intersect.point.clone().addScaledVector(this.dragStartPosition, -1);

      if (this.dragAxis !== 'X') offset.setX(0);

      if (this.dragAxis !== 'Y') offset.setY(0);

      if (this.dragAxis !== 'Z') offset.setZ(0);

      this.gapSize = objectGapSize.clone().add(offset);

    } else if (DRAG_MODE.SCALE === this.dragMode) {

      let offset = intersect.point.clone().addScaledVector(this.dragStartPosition, -1);

      let boundingBoxSize = new Vector3();
      let objCenter = new Vector3();

      this.boundingBox.getSize(boundingBoxSize);
      this.boundingBox.getCenter(objCenter);

      let itemSize = this.gapSize.clone().add(boundingBoxSize);
      let direction = 1;

      scaleOffset.set(0, 0, 0);

      if (this.dragAxis.includes('T'))
        scaleOffset.z = offset.dot(unitZ);

      if (this.dragAxis.includes('B')) {
        scaleOffset.z = -offset.dot(unitZ);
        direction = -1;
      }

      if (this.dragAxis.includes('R'))
        scaleOffset.x = offset.dot(unitX);

      if (this.dragAxis.includes('L')) {
        scaleOffset.x = -offset.dot(unitX);
        direction = -1;
      }

      if (this.dragAxis.includes('U'))
        scaleOffset.y = offset.dot(unitY);

      if (this.dragAxis.includes('D')) {
        scaleOffset.y = -offset.dot(unitY);
        direction = -1;
      }

      let itemScaleOffset = new Vector3(Math.round(scaleOffset.x / itemSize.x), Math.round(scaleOffset.y / itemSize.y), Math.round(scaleOffset.z / itemSize.z));

      if (this.shiftKeyState) {

        this.scaleShiftState = true;
        scaleIndicatorState |= SCALE_INDICATOR_STATE.SHIFT;

        let max = Math.max(itemScaleOffset.x, itemScaleOffset.y, itemScaleOffset.z);
        itemScaleOffset.set(max, max, max);

      } else {

        this.scaleShiftState = false;
        scaleIndicatorState &= SCALE_INDICATOR_STATE.NONSHIFT;

      }

      this.scaleAltState = !!this.altKeyState;

      let newArraySize = objectArraySize.clone();
      let newItemOffset = objectItemOffset.clone();

      if (this.scaleAltState) {

        newArraySize.addScaledVector(itemScaleOffset, 2);
        newItemOffset.x -= Math.abs(itemScaleOffset.x);
        newItemOffset.y -= Math.abs(itemScaleOffset.y);
        newItemOffset.z -= Math.abs(itemScaleOffset.z);

      } else {

        newArraySize.add(itemScaleOffset);
        if (direction < 0) newItemOffset.x += itemScaleOffset.x;
        if (direction < 0) newItemOffset.y += itemScaleOffset.y;
        if (direction < 0) newItemOffset.z += itemScaleOffset.z;

      }

      if (newItemOffset.x >= 0 && newItemOffset.y >= 0 && newItemOffset.z >= 0 && newItemOffset.x < newArraySize.x && newItemOffset.y < newArraySize.y && newItemOffset.z < newArraySize.z) {
        this.arraySize.copy(newArraySize);
        this.itemOffset.copy(newItemOffset);
      }

    }
  };

  this.pointerUp = function (pointer, event) {
    this.isDragging = false;
    activateElementEvent(true);

    if (pointer.button !== 0 || !this.dragMode) return;

    this.shiftKeyState = event.shiftKey;

    // console.log('tick set', lastEventTick, curTick)
    lastEventTick = curTick;

    if (this.visible && this.object !== undefined)
      scope.dispatchEvent({type: 'change'});
  };

  function onPointerDown(event) {
    scope.pointerDown(getPointer(event), event);
  }

  function onTouchDown(event) {
    scope.refreshBoundingClientRect();
    scope.pointerDown(getPointer(event), event);
  }

  function onPointerMove(event) {
    scope.pointerMove(getPointer(event), event);
  }

  function onPointerUp(event) {
    scope.pointerUp(getPointer(event), event);
  }

  this.setIntersectPlane = function (baseVector) {
    alignVector.copy(this.eye).cross(baseVector);
    dirVector.copy(baseVector).cross(alignVector);
    tempMatrix.lookAt(tempVector.set(0, 0, 0), dirVector, alignVector);
    this.intersectPlane.position.copy(this.object.position);
    this.intersectPlane.quaternion.setFromRotationMatrix(tempMatrix);
    this.intersectPlane.updateMatrixWorld();
  };

  this.createScalePickerMaterial = function (hover) {
    if (hover)
      return new MeshBasicMaterial({color: 0x000000, transparent: true, depthTest: false});
    else
      return new MeshBasicMaterial({color: 0x00ff00, transparent: true, depthTest: false});
  };

  this.createScalePickerUnit = function (name, type, hover) {
    let material = hover ? this.materials.scale.hover : this.materials.scale.normal;
    let mesh;

    if (type === 1)
      mesh = new Mesh(new CylinderBufferGeometry(5, 5, 30, 32), material);
    else
      mesh = new Mesh(new SphereBufferGeometry(5, 32, 32), material);

    if (name === 'U' || name === 'D')
      mesh.rotateZ(Math.PI / 2);

    mesh.name = name;
    return mesh;
  };

  this.createScalePicker = function () {
    let group = new Group();

    for (let name in SCALE_PICKER_INFO) {
      group.add(this.createScalePickerUnit(name, SCALE_PICKER_INFO[name][1], false));
    }

    group.visible = false;

    this.controlGroup.add(group);
    return group;
  };

  this.createZAxisLine = function () {
    let geometry = new BufferGeometry();
    geometry.attributes.position = new Float32BufferAttribute([0, 0, -0.5, 0, 0, 0.5], 3);
    let material = new LineDashedMaterial({
      color: 0x000000,
      dashSize: 0.03,
      gapSize: 0.01,
      transparent: true,
      depthTest: false
    });
    let line = new Line(geometry, material);
    line.computeLineDistances();
    line.visible = false;
    this.controlGroup.add(line);
    return line;
  };

  this.createBottomPlane = function () {
    let geometry = new BufferGeometry();
    let position = [];
    let w = 0.5;

    position.push(
      -w, -w, 0,
      w, -w, 0,

      w, -w, 0,
      w, w, 0,

      w, w, 0,
      -w, w, 0,

      -w, w, 0,
      -w, -w, 0
    );

    geometry.attributes.position = new Float32BufferAttribute(position, 3);
    let lineSegments = new LineSegments(geometry, new LineDashedMaterial({
      color: 0x000000,
      dashSize: 0.03,
      gapSize: 0.01,
      transparent: true,
      depthTest: false
    }));
    lineSegments.computeLineDistances();
    lineSegments.visible = false;

    this.controlGroup.add(lineSegments);
    return lineSegments;
  };

  this.materials = {
    scale: {
      hover: this.createScalePickerMaterial(true),
      normal: this.createScalePickerMaterial(false)
    },
    gap: {
      X: createGhostedMaterial(0x00ff00),
      Y: createGhostedMaterial(0x00ff00),
      Z: createGhostedMaterial(0x00ff00)
    },
    object: createGhostedMaterial(0x0000ff)
  };

  // Children
  this.controlGroup = new Group();
  this.controlGroup.name = `array-control-group`;
  this.controlGroup.matrixAutoUpdate = true;
  this.bottomPlane = this.createBottomPlane();
  this.zAxisLine = this.createZAxisLine();
  this.scalePicker = this.createScalePicker();
  this.gapObject = new Mesh(new BoxBufferGeometry(1, 1, 1), this.materials.gap.x);

  this.scene.add(this.controlGroup);
  this.scene.add(this.intersectPlane);
};

ArrayControl.prototype = Object.assign(Object.create(Object3D.prototype), Object.create(EventDispatcher.prototype),
  {

    constructor: ArrayControl,

    isArrayControls: true

  });

export {ArrayControl};