import {three as threeTypes} from "../types/three";
import {mat4, quat, vec3} from "gl-matrix";
import {
  getMat4FromThreeTransform, getQuatFromThreeQuaternion,
  getThreeTransformFromMat4,
  getVec3FromThreeVector,
  getVec4FromThreeVector4
} from "../converter/three";
import {normalizeScale} from "../WompObject";

export var allFonts: { [id: string]: (threeTypes.Font | undefined)[] } = {};

// /**
//  * Returns an euler angle representation of a quaternion
//  * @param  {vec3} out Euler angles, pitch-yaw-roll
//  * @param  {quat} mat Quaternion
//  * @return {vec3} out
//  */
// function getEuler(out: vec3, mat: quat) {
//   let x = mat[0],
//     y = mat[1],
//     z = mat[2],
//     w = mat[3],
//     x2 = x * x,
//     y2 = y * y,
//     z2 = z * z,
//     w2 = w * w;
//   let unit = x2 + y2 + z2 + w2;
//   let test = x * w - y * z;
//   if (test > 0.499995 * unit) {
//     // singularity at the north pole
//     out[0] = Math.PI / 2;
//     out[1] = 2 * Math.atan2(y, x);
//     out[2] = 0;
//   } else if (test < -0.499995 * unit) {
//     // singularity at the south pole
//     out[0] = -Math.PI / 2;
//     out[1] = 2 * Math.atan2(y, x);
//     out[2] = 0;
//   } else {
//     out[0] = Math.asin(2 * (x * z - w * y));
//     out[1] = Math.atan2(2 * (x * w + y * z), 1 - 2 * (z2 + w2));
//     out[2] = Math.atan2(2 * (x * y + z * w), 1 - 2 * (y2 + z2));
//   }
//
//   return out;
// }

var decompose = require('mat4-decompose');
var recompose = require('mat4-recompose');

export function decomposeTransform(matrix: mat4) {
  let ret = decomposeThreeTransform(getThreeTransformFromMat4(matrix));

  return {
    translate: getVec3FromThreeVector(ret.translate),
    quaternion: getQuatFromThreeQuaternion(ret.quaternion),
    rotate: vec3.fromValues(ret.rotate.x, ret.rotate.y, ret.rotate.z),
    unitScale: getVec3FromThreeVector(ret.unitScale),
    uniform: ret.uniform,
    scale: getVec3FromThreeVector(ret.scale),
    skew: getVec3FromThreeVector(ret.skew),
    perspective: getVec4FromThreeVector4(ret.perspective),
  };
}

export function composeTransform(translate: vec3, rotate: vec3, scale: vec3, skew: vec3) {
  let matrix = composeThreeTransform(
    new threeTypes.Vector3(translate[0], translate[1], translate[2]),
    new threeTypes.Euler(rotate[0], rotate[1], rotate[2], "YXZ"),
    new threeTypes.Vector3(scale[0], scale[1], scale[2]),
    new threeTypes.Vector3(skew[0], skew[1], skew[2])
  );
  return getMat4FromThreeTransform(matrix);
}

export function decomposeThreeTransform(matrix: threeTypes.Matrix4) {
  let t = [0, 0, 0], sc = [0, 0, 0], sk = [0, 0, 0], p = [0, 0, 0, 0], q = [0, 0, 0, 0];
  decompose(matrix.toArray(), t, sc, sk, p, q);

  let quaternion = new threeTypes.Quaternion(...q);
  let rotate = new threeTypes.Euler();
  rotate.setFromQuaternion(quaternion, "YXZ");

  // sk[0] can be NaN when one of scale is 0
  if (isNaN(sk[0]))
    sk[0] = 0;

  let {unitScale, uniform} = normalizeScale(sc as any);

  return {
    translate: new threeTypes.Vector3(...t),
    rotate,
    quaternion,
    unitScale: new threeTypes.Vector3(unitScale[0], unitScale[1], unitScale[2]),
    uniform,
    scale: new threeTypes.Vector3(...sc),
    skew: new threeTypes.Vector3(...sk.reverse()),
    perspective: new threeTypes.Vector4(...p)
  };
}

export function composeThreeTransform(translate: threeTypes.Vector3, rotate: threeTypes.Euler, scale: threeTypes.Vector3, skew: threeTypes.Vector3) {
  let matrix = new threeTypes.Matrix4().toArray();
  recompose(matrix, translate.toArray(), scale.toArray(), skew.toArray().reverse(), [0, 0, 0, 1], new threeTypes.Quaternion().setFromEuler(rotate).toArray());
  let res = new threeTypes.Matrix4();

  return res.set.apply(res, matrix as any).transpose();
}
