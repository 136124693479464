import {Operation, TestOperation} from 'fast-json-patch';
import {_objectKeys, hasOwnProperty} from "fast-json-patch/module/helpers";
import {store} from "../../index";
import {_s} from "../../peregrine/t";
import {peregrineId} from "../../peregrine/id";
import lod from "lodash";
import numeral from "numeral";
import {ISimpleCommand} from "./project-scene";

function _generate(mirror: any, obj: any, patches: Operation[], path: string, invertible: boolean, options?: Partial<ICommandOptions>) {
  if (obj === mirror) {
    return;
  }
  if (typeof obj.toJSON === "function") {
    obj = obj.toJSON();
  }
  let newKeys = _objectKeys(obj);
  let oldKeys = _objectKeys(mirror);
  let deleted = false;

  //if ever "move" operation is implemented here, make sure this test runs OK: "should not generate the same patch twice (move)"
  for (let t = oldKeys.length - 1; t >= 0; t--) {
    let key = oldKeys[t];
    let pathKey = path + "/" + key;
    let oldVal = mirror[key];
    let isImmutable = path.endsWith(`/${_s('objects')}`) && key.length === 23;

    if (hasOwnProperty(obj, key) && !(obj[key] === undefined && oldVal !== undefined && Array.isArray(obj) === false)) {
      let newVal = obj[key];
      let hasChilds = typeof oldVal === "object" && oldVal != null && typeof newVal === "object" && newVal != null;
      let checkDifference = true;
      let isReplacePath = path.endsWith(`/${_s('objects')}`) ||
        path.endsWith(`/${_s('values')}`) ||
        path.endsWith(`/${_s('caches')}`) ||
        path.endsWith(`/${_s('digitalMaterials')}`) ||
        path.endsWith(`/${_s('environments')}`) ||
        path.endsWith(`/${_s('libraryItems')}`);

      if (hasChilds && isReplacePath) {
        if (isImmutable) {
          checkDifference = false;
        } else {
          let tempPatches: Operation[] = [];
          _generate(oldVal, newVal, tempPatches, pathKey, invertible, options);
          if (tempPatches.length === 0) {
            checkDifference = false;
          }
        }
      }

      if (checkDifference) {
        if (hasChilds && !isReplacePath) {
          _generate(oldVal, newVal, patches, pathKey, invertible, options);
        } else {
          if (oldVal !== newVal) {
            if (oldVal === undefined) {
              patches.push({
                op: "add",
                path: pathKey,
                value: newVal
              });
            } else if (newVal === undefined) {
              if (invertible) {
                patches.push({
                  op: "test",
                  path: pathKey,
                  value: oldVal
                });
              }
              patches.push({op: "remove", path: pathKey});
            } else {
              if (!isImmutable) {
                if (invertible) {
                  patches.push({
                    op: "test",
                    path: pathKey,
                    value: oldVal
                  });
                }
                patches.push({
                  op: "replace",
                  path: pathKey,
                  value: newVal
                });
              }
            }
          }
        }
      }
    } else if (Array.isArray(mirror) === Array.isArray(obj)) {
      if (invertible) {
        patches.push({
          op: "test",
          path: pathKey,
          value: oldVal
        });
      }
      patches.push({op: "remove", path: pathKey});
      deleted = true; // property has been deleted
    } else {
      if (invertible) {
        patches.push({op: "test", path: path, value: mirror});
      }
      patches.push({op: "replace", path: path, value: obj});
    }
  }
  if (!deleted && newKeys.length === oldKeys.length) {
    return;
  }
  for (let t = 0; t < newKeys.length; t++) {
    let key = newKeys[t];
    let pathKey = path + "/" + key;

    if (!hasOwnProperty(mirror, key) && obj[key] !== undefined) {
      patches.push({
        op: "add",
        path: pathKey,
        value: obj[key]
      });
    }
  }
}

export function compare(tree1: any, tree2: any, invertible?: boolean, options?: Partial<ICommandOptions>) {
  if (invertible === void 0) {
    invertible = false;
  }
  let patches: Operation[] = [];
  _generate(tree1, tree2, patches, '', invertible, options);
  return patches;
}

export function applyOperation(tree: any, op: string, path: string, value: any): any {
  let slashInd = path.indexOf('/');

  if (slashInd < 0) {
    let key = path;
    if (op === "test") {
      tree[key] !== value && console.warn(tree[key]);
    } else if (op === "add" || op === "replace") {
      if (Array.isArray(tree)) {
        if (!isNaN(+key)) {
          if (tree[+key] !== value) {
            let newTree = [...tree];
            if (op === "add")
              newTree.splice(+key, 0, value);
            else
              newTree[+key] = value;
            return newTree;
          }
        } else {
          console.warn("index is not number", tree, key);
        }
      } else if (typeof tree === "object") {
        if (tree[key] !== value) {
          let newTree = {
            ...tree,
            [key]: value
          };
          return newTree;
        }
      } else {
        console.warn("strange object", tree);
      }
    } else if (op === "remove") {
      if (Array.isArray(tree)) {
        if (!isNaN(+key)) {
          if (typeof tree[+key] !== "undefined") {
            let newTree = [...tree];
            newTree.splice(+key, 1);
            return newTree;
          }
        } else {
          console.warn("index is not number", tree, key);
        }
      } else if (typeof tree === "object") {
        if (typeof tree[key] !== "undefined") {
          let {[key]: v, ...newTree} = tree;
          return newTree;
        }
      } else {
        console.warn("strange object", tree);
      }
    }

    return tree;
  } else {
    let key =  path.substr(0, slashInd);
    let partPath =  path.substr(slashInd + 1);

    if (Array.isArray(tree)) {
      if (!isNaN(+key)) {
        if (typeof tree[+key] !== "undefined") {
          let newPartTree = applyOperation(tree[+key], op, partPath, value);
          if (newPartTree !== tree[+key]) {
            let newTree = [...tree];
            newTree[+key] = newPartTree;
            return newTree;
          }
        }
      } else {
        console.warn("index is not number", tree, key);
      }
    } else if (typeof tree === "object") {
      if (typeof tree[key] !== "undefined") {
        let newPartTree = applyOperation(tree[key], op, partPath, value);
        if (newPartTree !== tree[key]) {
          let newTree = {
            ...tree,
            [key]: newPartTree
          };
          return newTree;
        }
      }
    } else {
      console.warn("strange object", tree);
    }

    return tree;
  }
}

export function applyPatch(tree: any, operations: Operation[]) {
  for (let operation of operations) {
    if (operation.op === "add" || operation.op === "test" || operation.op === "replace") {
      tree = applyOperation(tree, operation.op, operation.path.substr(1), operation.value);
    } else if (operation.op === "remove") {
      tree = applyOperation(tree, operation.op, operation.path.substr(1), undefined);
    }
  }

  return tree;
}

// A is from server which is certainly longer than b.
export function getLatestCommonIndexes(aIds: string[], aC: number, bIds: string[], bC: number) {
  let index = aIds.findIndex(id => id === bIds[0]);
  if (index < 0) {
    console.error('SEVERE ERROR: cannot find common element');
    console.log(aIds, aC, bIds, bC);
    return [-1, -1];
  }

  let off = 0;

  while (index + off <= aC && off <= bC) {
    if (aIds[index + off] !== bIds[off])
      break;
    ++off;
  }

  if (index + off - 1 < 0 || off - 1 < 0) {
    console.error('SEVERE ERROR: cannot find common sequence');
    console.log(aIds, aC, bIds, bC);
  }

  return [index + off - 1, off - 1];
}

export interface ICommand {
  id: string
  index: number
  applyOps: Operation[]
  reverseOps: Operation[]
  operation: string
  username: string
  createdDate: Date
  description: string
  extraData?: any
}

export interface ICommandOptions {
  commandId: string
}

export interface ICommandManagerState {
  commands: ISimpleCommand[]
  cursor: number
}

export class CommandManager {
  protected _commands: ICommand[] = [];
  protected _currentState: any = {};
  protected _cursor: number = 0;

  get state() {
    return this._currentState;
  }

  get commandState(): ICommandManagerState {
    return {
      commands: this._commands.map(c => ({
        id: c.id,
        index: c.index,
        operation: c.operation,
        username: c.username,
        description: c.description,
        createdDate: c.createdDate
      })),
      cursor: this._cursor
    };
  }

  get cursor(): number {
    return this._cursor;
  }

  get commands(): ICommand[] {
    return this._commands;
  }

  init(initialState: any) {
    this._currentState = initialState;
    this._cursor = 0;
    this._commands = [];
  }

  canUndo() {
    return this._cursor > 0;
  }

  canRedo() {
    return this._cursor < this._commands.length;
  }

  moveTo(cursor: number): [ICommand[], ICommand[]] {
    let applyCommands = [];
    let reverseCommands = [];
    while (cursor > this._cursor) {

      let command = this._commands[this._cursor];
      // console.log('before', lod.cloneDeep(command))

      this._currentState = applyPatch(this._currentState, command.applyOps);

      ++this._cursor;

      applyCommands.push(command);
    }

    while (cursor < this._cursor) {

      let command = this._commands[this._cursor - 1];
      // console.log('before', lod.cloneDeep(command))

      this._currentState = applyPatch(this._currentState, command.reverseOps);

      --this._cursor;

      reverseCommands.push(command);
    }

    return [applyCommands, reverseCommands];
  }

  clearAfterCursor() {
    this._commands = this._commands.slice(0, this._cursor);
  }


  clear() {
    this._commands = [];
    this._cursor = 0;
  }

  undo(): ICommand | undefined {
    let t0 = performance.now();
    if (this._cursor <= 0) return;

    let command = this._commands[this._cursor - 1];
    // console.log('before', lod.cloneDeep(command))

    this._currentState = applyPatch(this._currentState, command.reverseOps);

    --this._cursor;

    console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took to undo command`);
    return command;
  }

  redo(): ICommand | undefined {
    let t0 = performance.now();
    if (this._cursor >= this._commands.length) return;

    let command = this._commands[this._cursor];
    // console.log('before', lod.cloneDeep(command))

    this._currentState = applyPatch(this._currentState, command.applyOps);

    ++this._cursor;

    console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took to redo command`);
    return command;
  }

  add(command: ICommand) {
    this._currentState = applyPatch(this._currentState, command.applyOps);

    if (command.reverseOps.length !== 0) {
      this._commands = this._commands.slice(0, this._cursor);
      this._commands.push(command);

      this._cursor = this._commands.length;
    }
  }

  addPrevs(commands: ICommand[]) {
    this._commands = [...commands, ...this._commands];
    this._cursor += commands.length;
  }

  removePrevs(offset: number) {
    this._commands = this._commands.slice(offset);
    this._cursor -= offset;
  }

  createCommand(index: number, operation: string, state: any, description?: string, username?: string, options?: Partial<ICommandOptions>, extraData?: any) {
    let t0 = performance.now();

    let validSubState = lod.pick(this._currentState, Object.keys(state));
    let invertibleOps = compare(validSubState, state, true, options);
    let applyOps: Operation[] = [];
    let reverseOps: Operation[] = [];

    console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took to add command`);
    for (let i = 0; i < invertibleOps.length; ++i) {
      if (invertibleOps[i].op === "test") {
        if (invertibleOps[i + 1].op === "replace") {
          applyOps.push(invertibleOps[i + 1]);
          reverseOps.push({
            op: "replace",
            path: invertibleOps[i].path,
            value: (invertibleOps[i] as TestOperation<string>).value
          });
        } else if (invertibleOps[i + 1].op === "remove") {
          applyOps.push(invertibleOps[i + 1]);
          reverseOps.push({
            op: "add",
            path: invertibleOps[i].path,
            value: (invertibleOps[i] as TestOperation<string>).value
          });
        }
        ++i;
      } else if (invertibleOps[i].op === "add") {
        applyOps.push(invertibleOps[i]);
        reverseOps.push({
          op: "remove",
          path: invertibleOps[i].path
        });
      }
    }

    reverseOps = reverseOps.reverse();

    this._currentState = {
      ...this._currentState,
      ...state
    };

    let command: ICommand = {
      id: options && options.commandId ? options.commandId : peregrineId(),
      index,
      applyOps,
      reverseOps,
      operation,
      description: description || '',
      username: username || store.getState().entities.auth.username || 'anonymous',
      createdDate: new Date(),
      extraData
    };

    if (command.reverseOps.length !== 0) {
      this._commands = this._commands.slice(0, this._cursor);
      this._commands.push(command);

      this._cursor = this._commands.length;
    }

    return command;
  }

  get lastCommand(): ICommand | undefined {
    return this._commands[this._cursor - 1];
  }
}
