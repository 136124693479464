import {ComponentTypes} from "../types";
import {createSimpleGumball, Gumball} from "../gumball";
import {
  BooleanInterfaceTypes,
  getRegisteredBrepIdWithTessellation,
  Param_Boolean,
  Param_Compound,
  ParamTitles
} from "../../parameter";
import {Calc} from "../../calc";
import {ObjectTypes} from "../../xobject";
import {ICalcConfigDiff} from "../../types";
import {three as threeTypes} from "../../../types";
import {getComposeDesc, WompMeshData, WompObjectRef} from "../../../WompObject";
import {mat4} from "gl-matrix";
import {Scene} from "../../scene";
import {getThreeTransformFromMat4} from "../../../converter";
import {_n} from "../../../t";
import hash from "object-hash";

function getPlaneCoeffs(mat: mat4, invert: boolean) {

  let matrix = getThreeTransformFromMat4(mat);
  let normalMatrix = new threeTypes.Matrix3().getNormalMatrix(matrix);

  let n = new threeTypes.Vector3(0, 1, 0).applyMatrix3(normalMatrix).normalize();
  if (invert)
    n.multiplyScalar(-1);

  let passing = new threeTypes.Vector3(0, 0, 0).applyMatrix4(matrix);

  let d = -passing.dot(n);

  return [n.x, n.y, n.z, d];

}

function isPlane(calc: Calc, value: WompObjectRef) {

  let obj = (calc.scene as Scene).getRegisteredObject(value.objectId);
  let geom = obj.value as WompMeshData;

  return geom.position && geom.position.length === 12;

}

export class CutModifier {

  static create() {

    let calc = createSimpleGumball();
    calc.component = ComponentTypes.CutModifier;
    calc.title = 'cut';

    let objectParam = Param_Compound.create(calc, ParamTitles.Object, true, false, false);
    let invertParam = Param_Boolean.create(calc, ParamTitles.Invert, true, true, {
      defaultValue: false,
      interfaceType: BooleanInterfaceTypes.Toggle
    });
    let cutParam = Param_Compound.create(calc, ParamTitles.Cut, false, false, true);

    calc.addParameter(objectParam);
    calc.addParameter(invertParam);
    calc.addParameter(cutParam);

    return calc;

  }

  static async solve(calc: Calc, diff: ICalcConfigDiff) {
    let objectParam = calc.inputByTitle(ParamTitles.Object) as Param_Compound;
    let invertParam = calc.inputByTitle(ParamTitles.Invert) as Param_Boolean;

    let objects = [];

    let invert = invertParam.values[0];

    let infos: { [key: string]: {value: WompObjectRef, isMesh: boolean, weldAngle: number} } = {};
    let planeMatrix;

    for (let i = 0; i < objectParam.length; ++i) {
      let value = objectParam.values[i];
      let valueType = objectParam.valueTypes[i];
      let property = objectParam.properties[i];

      if (isPlane(calc, value)) {
        planeMatrix = value.matrix;
      } else {
        if (valueType === ObjectTypes.Mesh || valueType === ObjectTypes.SculptMesh || valueType === ObjectTypes.Brep) {
          let desc = getComposeDesc(value, 3);
          infos[desc] = {
            value,
            isMesh: valueType !== ObjectTypes.Brep,
            weldAngle: property.weldAngle
          };
        }
      }
    }

    if (Object.keys(infos).length > 0 && planeMatrix) {
      let descs = Object.keys(infos);

      let planeCoeffs: { [key: string]: number[] } = {};
      let missingDescs = [], missingCutDescs: string[] = [];

      for (let desc of descs) {
        let coeffs = getPlaneCoeffs(mat4.multiply(mat4.create(), mat4.invert(mat4.create(), infos[desc].value.matrix), planeMatrix), invert);
        planeCoeffs[desc] = coeffs;

        let cutDesc = `cut[${infos[desc].value.objectId}(${coeffs.map(c => _n(c, 3)).join(',')})]`;
        let matrix = infos[desc].value.matrix;
        let isMesh = infos[desc].isMesh;

        let {id, meshId, edgeId} = getRegisteredBrepIdWithTessellation(calc, cutDesc);

        if ((isMesh && id) || (!isMesh && (id && meshId && edgeId))) {
          let value = new WompObjectRef(id, matrix);
          let cache = isMesh ? null : [new WompObjectRef(meshId, matrix), new WompObjectRef(edgeId, matrix)];

          objects.push({
            value,
            valueType: isMesh ? ObjectTypes.Mesh : ObjectTypes.Brep,
            cache
          });
        } else {
          missingDescs.push(desc);
          missingCutDescs.push(cutDesc);
        }
      }

      if (missingDescs.length > 0) {
        await (calc.scene as Scene).startOperationOnGeometries("cut", calc.id, hash(missingCutDescs, {algorithm: 'md5'}), {
          infos: missingDescs.map((d, i) => ({
            ...infos[d],
            plane: planeCoeffs[d],
            desc: missingCutDescs[i]
          }))
        });

        for (let i = 0; i < objectParam.length; ++i) {
          let value = objectParam.values[i];
          let valueType = objectParam.valueTypes[i];
          let property = objectParam.properties[i];
          let cache = objectParam.caches[i];

          let desc = getComposeDesc(value, 3);

          if (missingDescs.includes(desc)) {
            objects.push({
              value, valueType, cache, property: {
                ...property,
                valid: false
              }
            });
          }
        }
      }
    }

    return Gumball.solveGumball(calc, objects);
  }

}
