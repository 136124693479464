import {InitStoreAction} from "../../types/init";
import {IDigitalMaterial, IObjectWelding, IPlaceholderBox, ITemporaryToolConfig} from "../types";
import {ISceneEnvironmentInfo} from "../../../peregrine/processor";
import {DeepPartial} from "ts-essentials";

export const CHANGE_MINOR_VIEW_ZOOM = 'CHANGE_MINOR_VIEW_ZOOM';
export const ADD_PLACEHOLDER_BOXES = 'ADD_PLACEHOLDER_BOXES';
export const UPDATE_PLACEHOLDER_BOX = 'UPDATE_PLACEHOLDER_BOX';
export const REMOVE_PLACEHOLDER_BOXES = 'REMOVE_PLACEHOLDER_BOXES';
export const CHANGE_TEMPORARY_OBJECT_WELDINGS = 'CHANGE_TEMPORARY_OBJECT_WELDINGS';
export const CHANGE_PREVIEW_DIGITAL_MATERIAL = 'CHANGE_PREVIEW_DIGITAL_MATERIAL';
export const CHANGE_PREVIEW_ENVIRONMENT_INFO = 'CHANGE_PREVIEW_ENVIRONMENT_INFO';
export const CHANGE_TEMPORARY_TOOL_CONFIG = 'CHANGE_TEMPORARY_TOOL_CONFIG';
export const CHANGE_HIGHLIGHTED_CALC_IDS = 'CHANGE_HIGHLIGHTED_CALC_IDS';
export const CHANGE_SERVER_RENDER_FRAME_NO = 'CHANGE_SERVER_RENDER_FRAME_NO';

export interface ISceneState {
  minorViewZoom: number
  placeholderBoxes: {[key: string]: IPlaceholderBox}
  temporaryObjectWeldings: {[key: string]: IObjectWelding}

  previewCalcIds: string[]
  previewDigitalMaterial?: IDigitalMaterial

  previewEnvironmentInfo?: Partial<ISceneEnvironmentInfo>

  temporaryToolConfig: ITemporaryToolConfig

  highlightedCalcIds: {[key: string]: true}

  serverRenderFrameNo: number
}

interface ChangeMinorViewZoomAction {
  type: typeof CHANGE_MINOR_VIEW_ZOOM
  payload: {
    zoom: number
  }
}

interface AddPlaceholderBoxesAction {
  type: typeof ADD_PLACEHOLDER_BOXES
  payload: {
    boxes: {[key: string]: IPlaceholderBox}
  }
}

interface UpdatePlaceholderBoxAction {
  type: typeof UPDATE_PLACEHOLDER_BOX
  payload: {
    id: string
    box: Partial<IPlaceholderBox>
  }
}

interface RemovePlaceholderBoxesAction {
  type: typeof REMOVE_PLACEHOLDER_BOXES
  payload: {
    ids: string[]
  }
}

interface ChangeTemporaryObjectWeldingsAction {
  type: typeof CHANGE_TEMPORARY_OBJECT_WELDINGS
  payload: {
    weldings: {[key: string]: IObjectWelding}
  }
}

interface ChangePreviewDigitalMaterialAction {
  type: typeof CHANGE_PREVIEW_DIGITAL_MATERIAL
  payload: {
    calcIds: string[]
    material?: IDigitalMaterial
  }
}

interface ChangePreviewEnvironmentInfoAction {
  type: typeof CHANGE_PREVIEW_ENVIRONMENT_INFO
  payload: {
    info?: Partial<ISceneEnvironmentInfo>
  }
}

interface ChangeTemporaryToolConfigAction {
  type: typeof CHANGE_TEMPORARY_TOOL_CONFIG
  payload: {
    config: DeepPartial<ITemporaryToolConfig>
  }
}

interface ChangeHighlightedCalcIdsAction {
  type: typeof CHANGE_HIGHLIGHTED_CALC_IDS
  payload: {
    calcIds: string[]
  }
}

interface ChangeServerRenderFrameNoAction {
  type: typeof CHANGE_SERVER_RENDER_FRAME_NO
  payload: {
    frameNo: number
  }
}

export type SceneActionTypes = ChangeMinorViewZoomAction |
  AddPlaceholderBoxesAction |
  RemovePlaceholderBoxesAction |
  UpdatePlaceholderBoxAction |
  ChangeTemporaryObjectWeldingsAction |
  ChangePreviewDigitalMaterialAction |
  ChangePreviewEnvironmentInfoAction |
  ChangeTemporaryToolConfigAction |
  ChangeHighlightedCalcIdsAction |
  ChangeServerRenderFrameNoAction |
  InitStoreAction