import {DRACOLoader} from "../loader/DRACOLoader";
import {DRACOExporter} from "../exporter/DRACOExporter";
import {PLYExporter} from "../exporter/PLYExporter";
import {PLYLoader} from "../loader/PLYLoader";
import {STLExporter} from "../exporter/STLExporter";
import {OFFExporter} from "../exporter/OFFExporter";
import {OFFLoader} from "../loader/OFFLoader";
import {STLLoader} from "../loader/STLLoader";
import {encode, decode} from "base64-arraybuffer";
import {SVGLoader} from "../loader/SVGLoader";

export const dracoDecoder = new DRACOLoader();
export const dracoExporter = new DRACOExporter();
export const plyExporter = new PLYExporter();
export const plyLoader = new PLYLoader();
export const stlExporter = new STLExporter();
export const stlLoader = new STLLoader();
export const offExporter = new OFFExporter();
export const offLoader = new OFFLoader();
export const svgLoader = new SVGLoader();

//@ts-ignore
dracoDecoder.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.4.1/');
dracoDecoder.preload();

export function encodeBuffer(buffer: ArrayBuffer): string {
  return encode(buffer);
}

export function decodeBuffer(encoded: string): ArrayBuffer {
  return decode(encoded);
}
