import {ComponentTypes} from "../types";
import {getPolylineFromArray} from "../../../converter";
import {createGumball} from "../gumball";
import {Param_Curve, Param_Vertex, ParamTitles} from "../../parameter";
import {Calc} from "../../calc";
import {BasicCurveGenerator} from "./basic-curve-gen";

export class PolylineGenerator extends BasicCurveGenerator {
  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.PolylineGenerator;
    calc.title = 'polyline';

    let vertexParam = Param_Vertex.create(calc, ParamTitles.Vertex, true, false, false);
    let polylineParam = Param_Curve.create(calc, ParamTitles.Polyline, false, false, true);

    calc.addParameter(vertexParam);
    calc.addParameter(polylineParam);

    return calc;
  }

  static getCurve(calc: Calc) {
    let vertexParam = calc.inputByTitle(ParamTitles.Vertex) as Param_Vertex;

    return getPolylineFromArray(vertexParam.values, false);
  }
}