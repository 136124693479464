/**
 * @author HypnosNova / https://www.threejs.org.cn/gallery
 */

import {Vector2} from "three";

var SelectionHelper = (function () {

  function SelectionHelper(selectionBox, cssClassName) {

    this.element = document.createElement('div');
    this.element.classList.add(cssClassName);
    this.element.style.pointerEvents = 'none';

    this.startPoint = new Vector2();
    this.pointTopLeft = new Vector2();
    this.pointBottomRight = new Vector2();

    this.domElement = document;

    this.enabled = true;

  }

  SelectionHelper.prototype.onSelectStart = function (event) {

    if (!this.enabled)
      return;

    let clientX = event.clientX;
    let clientY = event.clientY;

    if (event.touches && event.touches.length > 0) {

      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;

    }

    if (clientX === undefined || clientY === undefined)
      return;

    this.domElement.parentElement.appendChild(this.element);

    this.element.style.left = clientX + 'px';
    this.element.style.top = clientY + 'px';
    this.element.style.width = '0px';
    this.element.style.height = '0px';

    this.startPoint.x = clientX;
    this.startPoint.y = clientY;

  };

  SelectionHelper.prototype.onSelectMove = function (event) {

    let clientX = event.clientX;
    let clientY = event.clientY;

    if (event.touches && event.touches.length > 0) {

      clientX = event.touches[0].clientX;
      clientY = event.touches[0].clientY;

    }

    if (clientX === undefined || clientY === undefined)
      return;

    this.pointBottomRight.x = Math.max(this.startPoint.x, clientX);
    this.pointBottomRight.y = Math.max(this.startPoint.y, clientY);
    this.pointTopLeft.x = Math.min(this.startPoint.x, clientX);
    this.pointTopLeft.y = Math.min(this.startPoint.y, clientY);

    this.element.style.left = this.pointTopLeft.x + 'px';
    this.element.style.top = this.pointTopLeft.y + 'px';
    this.element.style.width = (this.pointBottomRight.x - this.pointTopLeft.x) + 'px';
    this.element.style.height = (this.pointBottomRight.y - this.pointTopLeft.y) + 'px';

  };

  SelectionHelper.prototype.onSelectOver = function () {

    if (!this.enabled)
      return;

    if (this.element.parentElement) {
      this.element.parentElement.removeChild(this.element);
    }

  };

  SelectionHelper.prototype.setDelegate = function (delegate) {

    this.domElement = delegate.domElement;

  }

  SelectionHelper.prototype.unsetDelegate = function () {

    this.domElement = document;

  }

  return SelectionHelper;

})();

export {SelectionHelper};
