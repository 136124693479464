import {Box3, Camera, Euler, Frustum, Matrix3, Matrix4, PerspectiveCamera, Plane, Vector3} from "three";
import lod from "lodash";
import {updateFrustum} from "../../common/three";

export interface ISnapBoundingBox {
  local: Box3
  localTransform: Matrix4
  global: Box3
}

// index
//
//    6 ______ 7
//     /|    /|
//  4 /_|__5/ |
//    |2|_8_|_|3    z^ 7| y
//    | /   | /      |/
//    |/____|/       +--> x
//   0       1
//

interface ISnapPointSource {
  id: string
  fromLocal: boolean
  index: number;
}

interface ISnapPoint {
  pos: Vector3
  fromSelection: boolean
  source: ISnapPointSource
}

interface ISnapPlane {
  plane: Plane
  points: ISnapPoint[]
}

export interface ISnapInfo {
  coeffs: number[]
  planes: ISnapPlane[]
  snapMatrix: Matrix4
}

interface ISnapAxis {
  pos: number
  sources: ISnapPointSource[];
}

function isIdentity(mat: Matrix4) {
  let offset = 0;

  for (let i = 0; i < 16; ++i) {
    offset += Math.abs((i % 5 === 0 ? 1 : 0) - mat.elements[i]);
  }

  return offset < 0.0001;
}

function getPointFromIndex(box: Box3, index: number) {
  if (index > 7)
    return box.getCenter(new Vector3());

  let x = (index & 1) ? box.max.x : box.min.x;
  let y = (index & 2) ? box.max.y : box.min.y;
  let z = (index & 4) ? box.max.z : box.min.z;

  return new Vector3(x, y, z);
}

function getPointFromBBoxIndex(bBox: ISnapBoundingBox, local: boolean, index: number) {
  let v = getPointFromIndex(local ? bBox.local : bBox.global, index);
  if (local) {
    let center = bBox.local.getCenter(new Vector3());
    v.sub(center);
    v.applyMatrix4(bBox.localTransform);
    v.add(center);
  }

  return v;
}

function fillWithAbsoluteMax(arr: number[]) {
  let maxValue = 0;
  for (let value of arr) {
    if (Math.abs(value) > Math.abs(maxValue))
      maxValue = value;
  }
  arr.fill(maxValue);
}

const EPSILON = 0.001;

function isZero(n: number) {
  return Math.abs(n) < EPSILON;
}

export default class Snap {

  protected sBoxes: { [key: string]: ISnapBoundingBox } = {};
  protected eBoxes: { [key: string]: ISnapBoundingBox } = {};
  protected sMat: Matrix4 = new Matrix4();
  protected dirs: Vector3[] = [];
  protected scale: boolean = false;
  protected uniform: boolean = false;
  protected local: boolean = false;
  protected sOrg = new Vector3();
  protected gCenter = new Vector3();
  protected lCenter = new Vector3();
  protected gSize = new Vector3();
  protected lSize = new Vector3();
  protected origin = new Vector3();
  protected camera = new Camera();
  protected frustum = new Frustum();
  protected snapIncrement = 0;

  protected axisMap: [{ [key: number]: ISnapAxis }, { [key: number]: ISnapAxis }, { [key: number]: ISnapAxis }] = [{}, {}, {}];
  protected axisPoses: [number[], number[], number[]] = [[], [], []];

  setSelection = (bBoxes: { [key: string]: ISnapBoundingBox }) => {

    this.sBoxes = bBoxes;
    let totalBox = new Box3();
    let totalLBox = new Box3();
    let totalTransform = new Matrix4();

    for (let id in this.sBoxes) {
      let sBox = this.sBoxes[id];
      totalBox.union(sBox.global);

      totalLBox.union(sBox.local);
      totalTransform.copy(sBox.localTransform);
    }

    if (Object.keys(this.sBoxes).length !== 1) {
      totalLBox = new Box3();
      totalTransform = new Matrix4();
    }

    this.sBoxes['total'] = {
      local: totalLBox,
      localTransform: totalTransform,
      global: totalBox
    };

    totalBox.getSize(this.gSize);
    totalBox.getCenter(this.gCenter);
    totalLBox.getSize(this.lSize);
    totalLBox.getCenter(this.lCenter);
  };

  setEnvironment = (bBoxes: { [key: string]: ISnapBoundingBox }) => {

    this.eBoxes = bBoxes;
    this.generateAxes();

  };

  setSnapIncrement = (snapIncrement: number = 0) => {

    this.snapIncrement = snapIncrement;

  };

  setCamera = (camera: Camera) => {

    if (!this.camera.projectionMatrix.equals(camera.projectionMatrix) || !this.camera.matrixWorld.equals(camera.matrixWorld)) {
      this.camera = camera.clone() as Camera;

      this.frustum = new Frustum();
      updateFrustum(this.camera, this.frustum, new Vector3(-1, -1, 0), new Vector3(1, 1, 0));

      this.generateAxes();
    }

  };

  setSelectionMatrix = (matrix: Matrix4) => {

    this.sMat = matrix;

  };

  setMoving = (directions: Vector3[]) => {

    this.scale = false;
    this.uniform = false;
    this.local = false;

    this.dirs = [];
    for (let dir of directions)
      this.dirs.push(dir.clone().normalize());

    if (this.dirs.length === 1)
      this.uniform = true;

  };

  setScale = (directions: Vector3[], scaleOrigin: Vector3, local: boolean, uniform: boolean) => {

    // console.log(directions, scaleOrigin);
    this.scale = true;
    this.sOrg = scaleOrigin;
    this.uniform = uniform;
    this.local = local;

    this.dirs = [];
    for (let dir of directions)
      this.dirs.push(dir.clone().normalize());

    if (this.dirs.length === 1)
      this.uniform = true;

    if (this.local)
      this.origin.copy(this.lSize).multiply(this.sOrg).add(this.lCenter);
    else
      this.origin.copy(this.gSize).multiply(this.sOrg).add(this.gCenter);

  };

  getVelocities = (v: Vector3) => {

    if (!this.scale)
      return new Array(this.dirs.length).fill(1);

    let revLocal = new Matrix4();

    if (this.local) {
      revLocal = this.sBoxes['total'].localTransform.clone().invert();
    }

    let vs = [], worldOrigin = this.origin.clone().applyMatrix4(this.sMat);
    for (let d = 0; d < this.dirs.length; ++d) {
      let dir = this.dirs[d];
      if (this.local) {
        vs.push(v.clone().sub(worldOrigin).applyMatrix4(revLocal).dot(dir));
      } else {
        vs.push(v.clone().sub(worldOrigin).dot(dir));
      }
    }

    return vs;

  };

  solveLinear = (value: number, vector: Vector3, vels: number[]) => {

    let coeffs: number[] = [];
    let len = 0;
    if (isFinite(value)) {
      for (let d = 0; d < this.dirs.length; ++d)
        coeffs[d] = vector.clone().dot(this.dirs[d]);

      for (let d = 0; d < this.dirs.length; ++d) {
        if (isZero(vels[d]) && !isZero(coeffs[d]))
          len = +Infinity;
        else if (!isZero(vels[d]))
          coeffs[d] /= vels[d];
      }

      if (len === 0) {
        for (let d = 0; d < this.dirs.length; ++d) {
          len += coeffs[d] * coeffs[d] * vels[d] * vels[d];
        }
        len = Math.sqrt(len);
      }
    } else {
      len = +Infinity;
    }

    return {len, coeffs};

  };

  snap = (threshold: number, useObjects: boolean = true, useSnapIncrement: boolean = true): ISnapInfo | undefined => {

    if (!useObjects && !useSnapIncrement)
      return;

    let minimalSnaps: { [key: string]: any } = {};
    let maximumC = 0;

    for (let id in this.sBoxes) {
      let sBox = this.sBoxes[id];
      let lt = sBox.local.isEmpty() ? 1 : 2;

      for (let i = 0; i <= 8; ++i) {
        for (let l = 0; l < lt; ++l) {
          let v = getPointFromBBoxIndex(sBox, !!l, i);

          v.applyMatrix4(this.sMat);

          let vels = this.getVelocities(v);
          // console.log(i, vels);

          let axisInds = this.getBoundingAxisIndexes(v, !useObjects, EPSILON);

          let snaps: { [key: number]: any } = {};
          let alwaysSnaps = [];

          for (let c = 0; c < 3; ++c) {
            let unit = new Vector3().setComponent(c, 1);
            let left = this.axisPoses[c][axisInds[c][0]];
            let right = this.axisPoses[c][axisInds[c][1]];

            if (useSnapIncrement && this.snapIncrement) {
              let mod = v.getComponent(c) % this.snapIncrement;
              if (mod <= EPSILON)
                mod += this.snapIncrement;

              if (isZero(mod)) {
                left = v.getComponent(c);
                right = v.getComponent(c);
              } else {
                left = Math.max(left, v.getComponent(c) - mod);
                right = Math.min(right, v.getComponent(c) + this.snapIncrement - mod);
              }
            }

            if (isZero(left - right)) {
              let always = true;
              for (let d = 0; d < this.dirs.length; ++d) {
                if (!isZero(unit.dot(this.dirs[d])) && !isZero(vels[d]))
                  always = false;
              }
              if (always) {
                alwaysSnaps.push(c);
                continue;
              }
            }

            let lds, rds;

            if (this.uniform) {
              let cSum = 0, dirSum = new Vector3(), vel0 = false;
              for (let d = 0; d < this.dirs.length; ++d) {
                if (isZero(vels[d]))
                  vel0 = true;
                cSum += this.dirs[d].getComponent(c);
                dirSum.add(this.dirs[d]);
              }

              if (vel0)
                continue;

              if (isZero(cSum))
                continue;

              lds = dirSum.clone().multiplyScalar((left - v.getComponent(c)) / cSum);
              rds = dirSum.clone().multiplyScalar((right - v.getComponent(c)) / cSum);
              // console.log(lds, rds);
            } else if (this.dirs.length === 2) {
              let n1 = this.dirs[0].clone().cross(this.dirs[1]).normalize();
              let n2 = unit.clone();
              let n3 = n2.clone().cross(n1);

              if (n3.length() === 0)
                continue;

              n3.normalize();
              let p1 = new Plane().setFromNormalAndCoplanarPoint(n1, v);
              let p3 = new Plane().setFromNormalAndCoplanarPoint(n3, v);
              lds = new Vector3(-p1.constant, left, -p3.constant);
              rds = new Vector3(-p1.constant, right, -p3.constant);

              let cMat = new Matrix3().set(
                n1.x, n1.y, n1.z,
                n2.x, n2.y, n2.z,
                n3.x, n3.y, n3.z
              );
              cMat.invert();
              lds.applyMatrix3(cMat).sub(v);
              rds.applyMatrix3(cMat).sub(v);
            } else if (this.dirs.length === 3) {
              console.warn('changing on 3 directions is not tested as it is impossible on 2d device');
              let n = unit.clone();
              let lp = new Plane(n, -left);
              let rp = new Plane(n, -right);
              lds = lp.projectPoint(v, new Vector3()).sub(v);
              rds = rp.projectPoint(v, new Vector3()).sub(v);
            }

            if (lds && rds) {
              let {len: ldsLen, coeffs: leftCoeffs} = this.solveLinear(left, lds, vels);
              let {len: rdsLen, coeffs: rightCoeffs} = this.solveLinear(right, rds, vels);

              if (Math.min(ldsLen, rdsLen) < threshold) {
                if (ldsLen < rdsLen) {
                  // TODO: BYZ - uniform is not working well.
                  if (this.uniform)
                    fillWithAbsoluteMax(leftCoeffs);

                  // console.log(leftCoeffs, ldsLen);

                  snaps[c] = ({
                    length: ldsLen,
                    offset: lds,
                    coeffs: leftCoeffs
                  });
                } else {
                  if (this.uniform)
                    fillWithAbsoluteMax(rightCoeffs);

                  // console.log(rightCoeffs, rdsLen);

                  snaps[c] = ({
                    length: rdsLen,
                    offset: rds,
                    coeffs: rightCoeffs
                  });
                }
              }
            }
          }

          let snap = undefined;
          let acs = alwaysSnaps;
          let nds = [];

          for (let d = 0; d < this.dirs.length; ++d) {
            let noeffect = true;
            for (let c in snaps) {
              if (!isZero(this.dirs[d].dot(snaps[+c].offset))) {
                noeffect = false;
                break;
              }
            }
            nds.push(noeffect);
          }

          if (Object.keys(snaps).length === 1) {
            let c = Object.keys(snaps)[0];
            snap = {length: snaps[+c].length, cs: [+c], coeffs: snaps[+c].coeffs};
            // console.log(snaps, snap);
          } else if (Object.keys(snaps).length > 1) {
            if (this.uniform) {
              let minLen = +Infinity;
              for (let c in snaps) {
                if (snaps[c].length < minLen) {
                  minLen = snaps[c].length;
                }
              }

              let cs = [];
              for (let c in snaps) {
                if (snaps[c].length === minLen) {
                  cs.push(+c);
                }
              }

              snap = {length: minLen, cs, coeffs: snaps[cs[0]].coeffs};
            } else if (this.dirs.length === 2) {
              // TODO: BYZ - this is not correct on local movement/scales.
              if (Object.keys(snaps).length === 3) {
                let maxC = 0;
                for (let c = 1; c < 3; ++c) {
                  if (snaps[c].length > snaps[maxC].length) {
                    maxC = c;
                  }
                }

                delete snaps[maxC];
              }

              let cs = [];
              let coeffs = [];
              let length2 = 0;
              for (let c in snaps) {
                cs.push(+c);
                length2 += snaps[c].length * snaps[c].length;
                for (let ci = 0; ci < snaps[c].coeffs.length; ++ci) {
                  if (!coeffs[ci]) coeffs[ci] = 0;
                  coeffs[ci] += snaps[c].coeffs[ci];
                }
              }

              snap = {length: Math.sqrt(length2), cs, coeffs};
            } else if (this.dirs.length === 3) {
              let cs = [];
              let coeffs = [];
              let length2 = 0;
              for (let c in snaps) {
                cs.push(+c);
                length2 += snaps[c].length * snaps[c].length;
                for (let ci = 0; ci < snaps[c].coeffs.length; ++ci) {
                  if (!coeffs[ci]) coeffs[ci] = 0;
                  coeffs[ci] += snaps[c].coeffs[ci];
                }
              }

              snap = {length: Math.sqrt(length2), cs, coeffs};
            }
          }

          if (alwaysSnaps.length > 0 && !snap) {
            snap = {length: 0, cs: [], coeffs: new Array(this.dirs.length).fill(0)};
          }

          if (snap) {
            // console.log(snap);
            if (maximumC < snap.cs.length) {
              minimalSnaps = {};
            }
            if (maximumC <= snap.cs.length) {
              let coeffKey = snap.coeffs.map(String).join(' ');
              let csKey = snap.cs.map(String).join(' ');
              if (!minimalSnaps[coeffKey])
                minimalSnaps[coeffKey] = {};
              if (!minimalSnaps[coeffKey][csKey])
                minimalSnaps[coeffKey][csKey] = {acs, nds};

              maximumC = snap.cs.length;
            }
          }
        }
      }
    }

    if (Object.keys(minimalSnaps).length === 0)
      return;

    while (true) {
      let newMinimalSnaps: { [key: string]: any } = {};
      for (let cKey1 in minimalSnaps) {
        for (let csKey1 in minimalSnaps[cKey1]) {
          for (let cKey2 in minimalSnaps) {
            for (let csKey2 in minimalSnaps[cKey2]) {
              if (csKey1 !== csKey2 || cKey1 !== cKey2) {
                let {cKey, csKey, combined} = this.combine(
                  minimalSnaps[cKey1][csKey1],
                  cKey1.split(' ').map(Number),
                  csKey1.split(' ').map(Number),
                  minimalSnaps[cKey2][csKey2],
                  cKey2.split(' ').map(Number),
                  csKey2.split(' ').map(Number),
                );
                if (cKey && csKey && combined) {
                  if (!newMinimalSnaps[cKey])
                    newMinimalSnaps[cKey] = {};
                  newMinimalSnaps[cKey][csKey] = combined;
                }
              }
            }
          }
        }
      }

      let oldValue = lod.cloneDeep(minimalSnaps);
      lod.merge(minimalSnaps, newMinimalSnaps);

      if (lod.isEqual(oldValue, minimalSnaps))
        break;
    }

    let maxSnapMatrix = new Matrix4();
    let maxPlanes: { [key: number]: ISnapPoint[] }[] | undefined;
    let maxCoeffs: number[] = [];
    let maxMatchCount = 0;

    let worldOrigin = this.origin.clone().applyMatrix4(this.sMat);

    for (let coeffKey in minimalSnaps) {
      let coeffs = coeffKey.split(' ').map(Number);
      let snapMatrix = new Matrix4();
      if (this.scale) {
        let scale = new Matrix4();

        let unitX = new Vector3(1, 0, 0), unitY = new Vector3(0, 1, 0), unitZ = new Vector3(0, 0, 1);
        for (let d = 0; d < this.dirs.length; ++d) {
          scale.premultiply(new Matrix4().makeScale(
            1 + this.dirs[d].dot(unitX) * coeffs[d],
            1 + this.dirs[d].dot(unitY) * coeffs[d],
            1 + this.dirs[d].dot(unitZ) * coeffs[d]
          ));
        }

        snapMatrix.makeTranslation(-worldOrigin.x, -worldOrigin.y, -worldOrigin.z)
          .premultiply(scale)
          .premultiply(new Matrix4().makeTranslation(worldOrigin.x, worldOrigin.y, worldOrigin.z));

        // console.log(worldOrigin, coeffs);
        // console.log(this.sMat.toArray(), snapMatrix.toArray(), this.sMat.clone().premultiply(snapMatrix).toArray());
      } else {
        let offset = new Vector3();
        for (let d = 0; d < this.dirs.length; ++d) {
          offset.add(this.dirs[d].clone().multiplyScalar(coeffs[d]));
        }

        snapMatrix.makeTranslation(offset.x, offset.y, offset.z);
      }

      let planes: { [key: number]: ISnapPoint[] }[] = [{}, {}, {}];
      let matchCount = 0;
      for (let id in this.sBoxes) {
        let sBox = this.sBoxes[id];
        let lt = sBox.local.isEmpty() ? 1 : 2;

        for (let i = 0; i <= 8; ++i) {
          for (let l = 0; l < lt; ++l) {
            let v = getPointFromBBoxIndex(sBox, !!l, i);

            v.applyMatrix4(this.sMat);
            v.applyMatrix4(snapMatrix);

            let axisInds = this.getBoundingAxisIndexes(v, !useObjects, EPSILON);

            for (let c = 0; c < 3; ++c) {
              let left = this.axisPoses[c][axisInds[c][0]];
              let right = this.axisPoses[c][axisInds[c][1]];

              if (useSnapIncrement && this.snapIncrement) {
                let mod = v.getComponent(c) % this.snapIncrement;
                if (isZero(mod)) {
                  left = v.getComponent(c);
                  right = v.getComponent(c);
                }
              }

              if (isZero(left - right)) {
                let pos = (left + right) / 2;

                if (!planes[c][pos]) {
                  planes[c][pos] = [];
                  ++matchCount;

                  if (this.axisMap[c][pos]) {
                    for (let source of this.axisMap[c][pos].sources) {
                      planes[c][pos].push({
                        pos: this.getPoint(source),
                        fromSelection: false,
                        source
                      });
                    }
                  }
                }

                planes[c][pos].push({
                  pos: v,
                  fromSelection: true,
                  source: {
                    id, fromLocal: !!l, index: i
                  }
                });
              }
            }
          }
        }
      }

      // console.log(coeffKey, matchCount);

      if (matchCount > maxMatchCount) {
        maxMatchCount = matchCount;
        maxPlanes = planes;
        maxCoeffs = coeffs;
        maxSnapMatrix = snapMatrix;
      }
    }

    if (maxPlanes) {
      let res: ISnapInfo = {
        coeffs: maxCoeffs,
        planes: [],
        snapMatrix: maxSnapMatrix
      };

      for (let c in maxPlanes) {
        let unit = new Vector3().setComponent(+c, 1);

        for (let pos in maxPlanes[+c]) {
          let plane: ISnapPlane = {
            plane: new Plane(unit, -pos),
            points: maxPlanes[+c][pos]
          };

          res.planes.push(plane);
        }
      }

      return res;
    }

  };


  protected combine = (s1: any, coeffs1: number[], cs1: number[], s2: any, coeffs2: number[], cs2: number[], ) => {
    let cKey = '', csKey = '', combined;
    let nds = new Array(this.dirs.length).fill(0);
    let coeffs = new Array(this.dirs.length).fill(0);
    for (let d = 0; d < this.dirs.length; ++d) {
      if (!s1.nds[d] && !s2.nds[d]) {
        return {cKey, csKey, combined};
      }
      nds[d] = s1.nds[d] && s2.nds[d];
      if (!s1.nds[d]) {
        coeffs[d] = coeffs1[d];
      } else if (!s2.nds[d]) {
        coeffs[d] = coeffs2[d];
      } else {
        coeffs[d] = coeffs1[d];
        if (!isZero(coeffs1[d] - coeffs2[d])) {
          console.warn('these values should be same', coeffs1[d], coeffs2[d]);
        }
      }
    }

    let acs2 = new Set(s2.acs);
    let acs = Array.from(new Set([...s1.acs].filter(x => acs2.has(x))));
    let css = Array.from(new Set([...cs1, ...cs2])).sort((a, b) => a - b);

    combined = {nds, acs};
    cKey = coeffs.map(String).join(' ');
    csKey = css.map(String).join(' ');

    return {cKey, csKey, combined};
  };

  protected getPoint = (source: ISnapPointSource) => {

    let box;
    if (this.eBoxes[source.id])
      box = this.eBoxes[source.id];

    if (this.sBoxes[source.id])
      box = this.sBoxes[source.id];

    if (box) {
      return getPointFromBBoxIndex(box, source.fromLocal, source.index);
    }

    return new Vector3();

  };

  protected getBoundingAxisIndexes = (v: Vector3, ignore: boolean = false, epsilon: number = 0) => {

    let axisIndexes = [];
    for (let c = 0; c < 3; ++c) {
      let value = v.getComponent(c);

      let l = 0, r = this.axisPoses[c].length - 1, m;

      if (!ignore) {
        while (true) {
          if (l + 1 === r)
            break;
          m = Math.floor((l + r) / 2);
          if (this.axisPoses[c][m] - epsilon > value) {
            r = m;
          } else if (this.axisPoses[c][m] + epsilon < value) {
            l = m;
          } else {
            l = r = m;
            break;
          }
        }
      }

      axisIndexes[c] = [l, r];
    }

    return axisIndexes;

  };

  protected generateAxes = () => {

    this.axisMap = [{}, {}, {}];
    this.axisPoses = [[], [], []];
    for (let c = 0; c < 3; ++c) {
      this.axisMap[c][-Infinity] = {
        pos: -Infinity,
        sources: []
      };

      this.axisMap[c][+Infinity] = {
        pos: +Infinity,
        sources: []
      };

      this.axisPoses[c].push(-Infinity, +Infinity);
    }

    for (let id in this.eBoxes) {
      let eBox = this.eBoxes[id];
      let localIsIndentity = isIdentity(eBox.localTransform);
      for (let c = 0; c < 3; ++c) {
        for (let i = 0; i <= 8; ++i) {
          let v = getPointFromBBoxIndex(eBox, false, i);
          if (this.frustum.containsPoint(v)) {
            if (!this.axisMap[c][v.getComponent(c)]) {
              this.axisMap[c][v.getComponent(c)] = {
                pos: v.getComponent(c),
                sources: []
              };
              this.axisPoses[c].push(v.getComponent(c));
            }

            this.axisMap[c][v.getComponent(c)].sources.push({
              id, fromLocal: false, index: i
            });

            if (localIsIndentity) {
              this.axisMap[c][v.getComponent(c)].sources.push({
                id, fromLocal: true, index: i
              });
            }
          }
        }

        if (!localIsIndentity) {
          for (let i = 0; i <= 8; ++i) {
            let v = getPointFromBBoxIndex(eBox, true, i);
            if (this.frustum.containsPoint(v)) {
              if (!this.axisMap[c][v.getComponent(c)]) {
                this.axisMap[c][v.getComponent(c)] = {
                  pos: v.getComponent(c),
                  sources: []
                };
                this.axisPoses[c].push(v.getComponent(c));
              }

              this.axisMap[c][v.getComponent(c)].sources.push({
                id, fromLocal: true, index: i
              });
            }
          }
        }
      }
    }

    for (let c = 0; c < 3; ++c) {
      this.axisPoses[c].sort((a, b) => a - b);
    }

  };
};