import {ComponentTypes} from "../types";
import {allFonts} from "../../../utils";
import {three as threeTypes} from "../../../types";
import {createGumball} from "../gumball";
import {s3RootPath} from "../../../../api/const";
import {Param_Boolean, Param_Index, Param_Mesh, Param_String, ParamTitles} from "../../parameter";
import {Calc} from "../../calc";
import {WompMesh} from "../../../WompObject";
import {getMeshDataFromThreeGeometry} from "../../../converter";
import {mat4, vec3} from "gl-matrix";
import {BasicMeshGenerator} from "./basic-gen";

export const fontFaces3d: string[] = [
  'arial', 'helvetica', 'roboto', 'times new roman', 'courier new', 'verdan', 'georgia'
];

function loadFont(name: string, bold: boolean, italic: boolean): Promise<{ name: string, bold: boolean, italic: boolean, font: threeTypes.Font }> {
  return new Promise<{ name: string, bold: boolean, italic: boolean, font: threeTypes.Font }>((resolve, reject) => {
    let fileLoader = new threeTypes.FileLoader();
    //@ts-ignore
    fileLoader.setMimeType("application/json");
    fileLoader.setPath(`${s3RootPath}/public/fonts/`);
    let fontLoader = new threeTypes.FontLoader();
    fileLoader.load(name + (bold ? ' bold' : '') + (italic ? ' italic' : '') + '.json', (json) => {
      resolve({name, bold, italic, font: fontLoader.parse(JSON.parse(json as string))});
    });
  });
}

export class ThreeTextGenerator extends BasicMeshGenerator {
  static defaultParameter = {
    text: 'hello',
    font: 'arial',
    bold: false,
    italic: false,
    fontSize: 20,
    height: 20
  };
  static draggingPreview = new WompMesh({});

  static async init() {
    this.draggingPreview = await this.generateMeshAsync();
  }

  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.ThreeTextGenerator;
    calc.title = 'text';

    let list: { [id: string]: string } = {};
    for (let font of fontFaces3d) {
      list[font] = font;
    }

    let textParam = Param_String.create(calc, ParamTitles.Text, this.defaultParameter.text, true, true);
    let fontParam = Param_Index.create(calc, ParamTitles.Font, list, true, true, {defaultValue: this.defaultParameter.font});
    let boldParam = Param_Boolean.create(calc, ParamTitles.Bold, true, true, {defaultValue: this.defaultParameter.bold});
    let italicParam = Param_Boolean.create(calc, ParamTitles.Italic, true, true, {defaultValue: this.defaultParameter.italic});
    let textOutParam = Param_Mesh.create(calc, ParamTitles.Text, false, false, true);

    calc.addParameter(textParam);
    calc.addParameter(fontParam);
    calc.addParameter(boldParam);
    calc.addParameter(italicParam);
    calc.addParameter(textOutParam);

    return calc;
  }

  static getDesc(calc: Calc) {
    let textParam = calc.inputByTitle(ParamTitles.Text) as Param_String;
    let fontParam = calc.inputByTitle(ParamTitles.Font) as Param_Index;
    let boldParam = calc.inputByTitle(ParamTitles.Bold) as Param_Boolean;
    let italicParam = calc.inputByTitle(ParamTitles.Italic) as Param_Boolean;
    let text = textParam.values[0] || this.defaultParameter.text;
    let font = fontParam.values[0] || this.defaultParameter.font;
    let bold = boldParam.values[0] || this.defaultParameter.bold;
    let italic = italicParam.values[0] || this.defaultParameter.italic;

    return `text[${text},${font},${bold},${italic}]`;
  }

  static getParameter(calc: Calc) {
    let textParam = calc.inputByTitle(ParamTitles.Text) as Param_String;
    let fontParam = calc.inputByTitle(ParamTitles.Font) as Param_Index;
    let boldParam = calc.inputByTitle(ParamTitles.Bold) as Param_Boolean;
    let italicParam = calc.inputByTitle(ParamTitles.Italic) as Param_Boolean;
    let text = textParam.values[0] || this.defaultParameter.text;
    let font = fontParam.values[0] || this.defaultParameter.font;
    let bold = boldParam.values[0] || this.defaultParameter.bold;
    let italic = italicParam.values[0] || this.defaultParameter.italic;

    return {...this.defaultParameter, text, font, bold, italic};
  }

  static async generateMeshAsync(parameter: any = this.defaultParameter) {
    // if (allFonts[parameter.font] === undefined) parameter.font = 'arial';

    let index = (parameter.bold ? 1 : 0) + (parameter.italic ? 2 : 0);

    if (!allFonts[parameter.font]) {
      allFonts[parameter.font] = new Array(4);
    }

    const generate = async () => {
      let font = allFonts[parameter.font][index];
      if (font) {
        let value: threeTypes.BufferGeometry = new threeTypes.TextBufferGeometry(parameter.text, {
          size: parameter.fontSize,
          height: this.defaultParameter.height,
          font: font
        });
        value.rotateX(Math.PI / 2);
        value.rotateZ(Math.PI);
        value.computeBoundingBox();

        if (value.boundingBox) {
          value.translate(
            (value.boundingBox.max.x - value.boundingBox.min.x) / 2,
            -(value.boundingBox.max.y - value.boundingBox.min.y) / 2,
            -(value.boundingBox.max.z - value.boundingBox.min.z) / 2
          );
        }

        let geom = getMeshDataFromThreeGeometry(value);

        return new WompMesh(geom, this.generateMatrix(parameter));
      } else {
        return new WompMesh({});
      }
    }

    if (!allFonts[parameter.font][index]) {
      return loadFont(parameter.font, !!(index % 2), !!Math.floor(index / 2)).then(font => {
        allFonts[font.name][index] = font.font;
        return generate();
      });
    } else {
      return generate();
    }
  }

  static generateMatrix(parameter: any = this.defaultParameter) {
    return mat4.fromScaling(
      mat4.create(),
      vec3.fromValues(
        1,
        parameter.height / this.defaultParameter.height,
        1
      )
    );
  }
}
