import {
  ADD_ENVIRONMENTS,
  EnvironmentsActionTypes,
  IEntityEnvironmentsState,
  REMOVE_ENVIRONMENT,
  REPLACE_ENVIRONMENTS
} from '../../types/entity/environments';
import {INIT_STORE} from "../../types/init";
import lod from 'lodash';

const initialState: IEntityEnvironmentsState = {
  byId: {},
  allIds: []
};

export function entityEnvironmentsReducer(
  state = initialState,
  action: EnvironmentsActionTypes
): IEntityEnvironmentsState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case REPLACE_ENVIRONMENTS: {
      return {
        byId: lod.keyBy(action.payload.environments, 'id'),
        allIds: lod.map(action.payload.environments, 'id')
      };
    }
    case ADD_ENVIRONMENTS: {
      return {
        byId: {
          ...state.byId,
          ...lod.keyBy(action.payload.environments, 'id')
        },
        allIds: [
          ...state.allIds,
          ...lod.map(action.payload.environments, 'id').filter(id => !state.byId[id])
        ]
      };
    }
    case REMOVE_ENVIRONMENT: {
      return {
        byId: lod.omit(state.byId, action.payload.id),
        allIds: state.allIds.filter(id => id !== action.payload.id)
      };
    }
    default:
      return state;
  }
}