import {INotification, NormalizedObjects} from "../types";
import {InitStoreAction} from "../init";

export const REPLACE_NOTIFICATIONS = 'REPLACE_NOTIFICATIONS';
export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export interface IEntityNotificationsState extends NormalizedObjects<INotification> {
}

interface ReplaceNotificationsAction {
  type: typeof REPLACE_NOTIFICATIONS
  payload: {
    notifications: INotification[]
  }
}

interface AddNotificationsAction {
  type: typeof ADD_NOTIFICATIONS
  payload: {
    notifications: INotification[]
  }
}

interface RemoveNotificationAction {
  type: typeof REMOVE_NOTIFICATION
  payload: {
    id: number
  }
}

export type NotificationsActionTypes = ReplaceNotificationsAction |
  RemoveNotificationAction |
  AddNotificationsAction |
  InitStoreAction