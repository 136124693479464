import * as React from 'react';
import {pure} from 'recompose';

export interface ILoadingDotsProps {
  symbol?: string
  between?: string
}

const LoadingDots: React.FC<ILoadingDotsProps> = ({symbol, between}) => {
  symbol = symbol || '.';
  between = between || '';
  return (
    <React.Fragment>
      <span className="loading-dot-1">{symbol}</span>
      {between && <span>{between}</span>}
      <span className="loading-dot-2">{symbol}</span>
      {between && <span>{between}</span>}
      <span className="loading-dot-3">{symbol}</span>
    </React.Fragment>
  );
};

export default pure(LoadingDots);
