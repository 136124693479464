import {INIT_STORE} from "../../types/init";
import {
  ApiWaiterActionTypes, FETCH_WAITER, FETCH_WAITERS,
  IApiWaiterState, POST_WAITER, POST_WAITER_ACTION
} from "../../types/api/waiter";
import {FETCH_PROFILES} from "../../types/api/profile";

const initialState: IApiWaiterState = {
  fetchWaiter: {
    fetching: false
  },
  postWaiter: {
    posting: false
  },
  postWaiterAction: {
    posting: false
  },
  getWaiters: {
    fetching: false
  }
};

export function apiWaiterReducer(
  state = initialState,
  action: ApiWaiterActionTypes
): IApiWaiterState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case FETCH_WAITER:
      return {
        ...state,
        fetchWaiter: {
          fetching: action.payload.fetching,
          message: action.payload.message
        }
      };
    case POST_WAITER:
      return {
        ...state,
        postWaiter: {
          posting: action.payload.posting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    case POST_WAITER_ACTION:
      return {
        ...state,
        postWaiterAction: {
          posting: action.payload.posting,
          message: action.payload.message
        }
      };
    case FETCH_WAITERS:
      return {
        ...state,
        getWaiters: {
          fetching: action.payload.fetching,
          message: action.payload.message,
          items: action.payload.items
        }
      };
    default:
      return state;
  }
}