import lod from "lodash";
import {XObject} from "../xobject";
import {ICalcConfigDiff, ICalcSetting, IParameterProperty} from "../types";
import {Relation} from "../relation";
import {getObjectType, Parameter, ParamTypes, ParamValueTypes} from "./parameter";
import {Param_Compound} from "./param-compound";
import {_b, _c, _m, _n, _s, _t} from "../../t";
import {peregrineId} from "../../id";

export class Param_Number extends Parameter {
  protected _objectType = ParamTypes.Number;
  protected _defaultValue: number = 0;
  protected _render = false;

  protected _bound: number[] = [];

  get bound() {
    return this._bound;
  }

  set bound(bound: number[]) {
    if (!lod.isEqual(this._bound, bound)) {
      this._bound = bound;
      this._stateValid = false;
      this._data = {
        ...this._data,
        [_s('bound')]: bound.map(v => _n(v))
      };
    }
  }

  protected _type: string = ParamValueTypes.Float;

  get type() {
    return this._type;
  }

  set type(type: string) {
    if (this._type !== type) {
      this._type = type;
      this._stateValid = false;
      this._data = {
        ...this._data,
        [_s('type')]: _s(type)
      };
    }
  }

  protected _lockBound: boolean[] = [];

  get lockBound() {
    return this._lockBound;
  }

  set lockBound(lockBound: boolean[]) {
    if (!lod.isEqual(this._lockBound, lockBound)) {
      this._lockBound = lockBound;
      this._stateValid = false;
      this._data = {
        ...this._data,
        [_s('lockBound')]: lockBound.map(_b)
      };
    }
  }

  protected _step: number = 1;

  get step() {
    return this._step;
  }

  set step(step: number) {
    if (this._step !== step) {
      this._step = step;
      this._stateValid = false;
      this._data = {
        ...this._data,
        [_s('step')]: _n(step)
      };
    }
  }

  // Runtime Data
  protected _values: number[] = [];

  get values() {
    return this._values;
  }

  set values(values: number[]) {
    if (!lod.isEqual(this._values, values)) {
      this._values = values;
      this._stateValid = false;
      this._dataValid = false;
    }
  }

  get defaultValue() {
    return this._defaultValue;
  }

  set defaultValue(defaultValue: number) {
    if (this._defaultValue !== defaultValue) {
      this._defaultValue = defaultValue;
      this._data = {
        ...this._data,
        [_s('defaultValue')]: _n(defaultValue)
      };
    }
  }

  get length() {
    return this._values.length;
  }

  static create(calc: XObject, title: string, isInput: boolean, isOperable: boolean,
                options?: { type?: string, defaultValue?: number, bound?: number[], lockBound?: boolean[], step?: number }) {
    let param = new Param_Number();
    param._id = peregrineId();
    param._calc = calc;
    param._title = title;
    param._isInput = isInput;
    param._isOperable = isOperable;

    if (options === undefined) options = {};

    options.type !== undefined && (param.type = options.type);

    switch (param._type) {
      case ParamValueTypes.Length:
        param.bound = options.bound === undefined ? [0, 100] : options.bound;
        param.lockBound = options.lockBound === undefined ? [true, false] : options.lockBound;
        param.step = options.step === undefined ? 0.01 : options.step;
        param.defaultValue = options.defaultValue === undefined ? 10 : options.defaultValue;
        break;
      case ParamValueTypes.NonZeroLength:
        param.step = options.step === undefined ? 0.01 : options.step;
        param.bound = options.bound === undefined ? [param._step, 100] : options.bound;
        param.lockBound = options.lockBound === undefined ? [true, false] : options.lockBound;
        param.defaultValue = options.defaultValue === undefined ? 10 : options.defaultValue;
        break;
      case ParamValueTypes.Offset:
        param.bound = options.bound === undefined ? [-100, 100] : options.bound;
        param.lockBound = options.lockBound === undefined ? [false, false] : options.lockBound;
        param.step = options.step === undefined ? 0.01 : options.step;
        param.defaultValue = options.defaultValue === undefined ? 0 : options.defaultValue;
        break;
      case ParamValueTypes.Degree:
        param.bound = options.bound === undefined ? [0, 360] : options.bound;
        param.lockBound = options.lockBound === undefined ? [true, true] : options.lockBound;
        param.step = options.step === undefined ? 0.01 : options.step;
        param.defaultValue = options.defaultValue === undefined ? 0 : options.defaultValue;
        break;
      case ParamValueTypes.Percent:
        param.bound = options.bound === undefined ? [0, 100] : options.bound;
        param.lockBound = options.lockBound === undefined ? [true, true] : options.lockBound;
        param.step = options.step === undefined ? 0.01 : options.step;
        param.defaultValue = options.defaultValue === undefined ? 100 : options.defaultValue;
        break;
      case ParamValueTypes.Skew:
        param.bound = options.bound === undefined ? [-2, 2] : options.bound;
        param.lockBound = options.lockBound === undefined ? [false, false] : options.lockBound;
        param.step = options.step === undefined ? 0.01 : options.step;
        param.defaultValue = options.defaultValue === undefined ? 0 : options.defaultValue;
        break;
      case ParamValueTypes.Scale:
        param.bound = options.bound === undefined ? [-1000, 1000] : options.bound;
        param.lockBound = options.lockBound === undefined ? [false, false] : options.lockBound;
        param.step = options.step === undefined ? 0.01 : options.step;
        param.defaultValue = options.defaultValue === undefined ? 100 : options.defaultValue;
        break;
      case ParamValueTypes.UnsignedInt:
        param.bound = options.bound === undefined ? [1, 100] : options.bound;
        param.lockBound = options.lockBound === undefined ? [true, false] : options.lockBound;
        param.step = options.step === undefined ? 1 : options.step;
        param.defaultValue = options.defaultValue === undefined ? 1 : options.defaultValue;
        break;
      default:
        param.bound = options.bound === undefined ? [-100, +100] : options.bound;
        param.lockBound = options.lockBound === undefined ? [true, true] : options.lockBound;
        param.step = options.step === undefined ? 1 : options.step;
        param.defaultValue = options.defaultValue === undefined ? 0 : options.defaultValue;
    }

    param.values = [param.defaultValue];

    param.setupInitialData();
    return param;
  }

  generateProperties(): IParameterProperty[] {
    let properties: IParameterProperty[] = [];

    for (let i = 0; i < this._values.length; ++i) {
      let value = this._values[i];

      properties.push({
        hash: _n(value)
      });
    }

    return properties;
  }

  generateState() {
    let settings: ICalcSetting[] = [];

    for (let i = 0; i < this._values.length; ++i) {
      settings.push({
        id: this._id + ':' + i,
        type: 'number',
        label: this._title,
        min: this._bound[0],
        max: this._bound[1],
        lockMin: this._lockBound[0],
        lockMax: this._lockBound[1],
        value: this._values[i],
        step: this._step,
        valueType: this._type,
        interfaceType: this._interfaceType
      });
    }
    return settings;
  }

  protected validateGeneratedData() {
    this.dataValues = this._values.map(v => _n(v));

    this._dataValid = true;
  }

  overwrite(jData: any, replaceData: boolean) {
    if (!this.dataValid)
      this.validateGeneratedData();

    super.overwrite(jData, replaceData);

    if (this._data[_s('values')] !== jData[_s('values')] && jData[_s('values')] !== undefined) {
      this.values = jData[_s('values')].map(_m);
    }

    if (this._data[_s('defaultValue')] !== jData[_s('defaultValue')] && jData[_s('defaultValue')] !== undefined) {
      this.defaultValue = _m(jData[_s('defaultValue')]);
    }

    if (this._data[_s('bound')] !== jData[_s('bound')] && jData[_s('bound')] !== undefined) {
      this.bound = jData[_s('bound')].map(_m);
    }

    if (this._data[_s('lockBound')] !== jData[_s('lockBound')] && jData[_s('lockBound')] !== undefined) {
      this.lockBound = jData[_s('lockBound')].map(_c);
    }

    if (this._data[_s('step')] !== jData[_s('step')] && jData[_s('step')] !== undefined) {
      this.step = _m(jData[_s('step')]);
    }

    if (this._data[_s('type')] !== jData[_s('type')] && jData[_s('type')] !== undefined) {
      this.type = _t(jData[_s('type')]);
    }

    if (replaceData) {
      this._data = jData;
      this._dataValid = true;
    }
  }

  solve(diff?: ICalcConfigDiff) {
    if (diff && diff.obj) {
      this._values = [];

      for (let relation of this._prevRelations) {
        let prev = (relation as unknown as Relation).from;
        if (prev.objectType === ParamTypes.Compound) {
          let prevComp = prev as Param_Compound;
          this._values = prevComp.filterValues(getObjectType(this._objectType));
        } else {
          if (prev.objectType === this._objectType) {
            let prevSame = prev as Param_Number;
            this._values = [...this._values, ...prevSame._values];
            this._type = prevSame._type;
            this._bound = prevSame._bound;
            this._lockBound = prevSame._lockBound;
            this._step = prevSame._step;
          } else {
            console.warn('Type mismatch. Please implement type conversion');
          }
        }
      }

      if (this._prevRelations.length === 0 && this._defaultValue !== undefined) {
        this._values = [this._defaultValue];
      }
      this._dataValid = false;
    }

    for (let i = 0; i < this._values.length; ++i) {
      if (isNaN(+this._values[i])) {
        console.warn(`NaN value found in parameter[${this._title}] of calc[${(this._calc as any).title}]`);
        this._values[i] = 0;
        this._dataValid = false;
      }
    }
  }
}
