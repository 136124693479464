import {InitStoreAction} from "../init";

export const CHANGE_INITIAL_POINT = 'CHANGE_INITIAL_POINT';

export interface IHeroState {
  initialPoint: number
}

interface ChangeInitialPointAction {
  type: typeof CHANGE_INITIAL_POINT
  payload: {
    initialPoint: number
  }
}

export type HeroActionTypes = ChangeInitialPointAction |
  InitStoreAction
