import {ComponentTypes} from "../types";
import {pigeon as pigeonTypes} from "../../../types";
import {createGumball} from "../gumball";
import {Param_Brep, Param_Number, ParamTitles, ParamValueTypes} from "../../parameter";
import {Calc} from "../../calc";
import {mat4, vec3} from "gl-matrix";
import {ThreeDonutGenerator} from "../three-basic";
import {BasicBrepGenerator} from "./basic-gen";

export class DonutGenerator extends BasicBrepGenerator {
  static defaultParameter = {
    radius: 20,
    innerRadiusPercent: 50
  };
  static draggingPreview = ThreeDonutGenerator.generateMesh();

  static create() {
    let calc = createGumball();
    calc.component = ComponentTypes.DonutGenerator;
    calc.title = 'donut';

    let innerRadiusParam = Param_Number.create(calc, ParamTitles.InnerRadius, true, true, {
      type: ParamValueTypes.Percent,
      defaultValue: this.defaultParameter.innerRadiusPercent
    });
    let donutParam = Param_Brep.create(calc, ParamTitles.Donut, false, false, true);

    calc.addParameter(innerRadiusParam);
    calc.addParameter(donutParam);

    return calc;
  }

  static getDesc(calc: Calc) {
    let innerRadiusParam = calc.inputByTitle(ParamTitles.InnerRadius) as Param_Number;
    let innerRadiusPercent = innerRadiusParam.values[0] || this.defaultParameter.innerRadiusPercent;

    return `b-donut[${innerRadiusPercent.toFixed(4)}]`;
  }

  static getParameter(calc: Calc) {
    let innerRadiusParam = calc.inputByTitle(ParamTitles.InnerRadius) as Param_Number;
    let innerRadiusPercent = innerRadiusParam.values[0] || this.defaultParameter.innerRadiusPercent;

    return {...this.defaultParameter, innerRadiusPercent};
  }

  static async generateBrepGeometry(parameter: any = this.defaultParameter) {
    return pigeonTypes.donut(
      this.defaultParameter.radius,
      parameter.innerRadiusPercent * this.defaultParameter.radius / 100
    );
  }

  static generateMatrix(parameter: any = this.defaultParameter) {
    return mat4.fromScaling(
      mat4.create(),
      vec3.fromValues(
        parameter.radius / this.defaultParameter.radius,
        parameter.radius / this.defaultParameter.radius,
        parameter.radius / this.defaultParameter.radius
      )
    );
  }
}