import {offsetGeometry} from "./offset";
import {applyGeometryTransform, mergeGeometryVertices, WompMeshData} from "../WompObject";
import {mat4, vec3} from "gl-matrix";

export function extrudeLine(geometry: WompMeshData, direction: string, distance: number, thickness: number, cap: boolean) {

  if (geometry.position === undefined) {

    return geometry;

  }

  let position = geometry.position;
  let vertexCnt = position.length / 3;
  let newIndex;
  let newPosition;

  newIndex = new Uint32Array(vertexCnt * 6);
  newPosition = new Float32Array(vertexCnt * 3 * 2);

  for (let i = 0; i < vertexCnt; ++i) {

    newPosition[i * 3] = position[i * 3];
    newPosition[i * 3 + 1] = position[i * 3 + 1];
    newPosition[i * 3 + 2] = direction === "down" ? position[i * 3 + 2] : (position[i * 3 + 2] + distance);

    newPosition[(vertexCnt + i) * 3] = position[i * 3];
    newPosition[(vertexCnt + i) * 3 + 1] = position[i * 3 + 1];
    newPosition[(vertexCnt + i) * 3 + 2] = direction === "up" ? position[i * 3 + 2] : (position[i * 3 + 2] - distance);

    newIndex[i * 6] = i;
    newIndex[i * 6 + 1] = i + vertexCnt;
    newIndex[i * 6 + 2] = (i + 1) % vertexCnt;

    newIndex[i * 6 + 3] = (i + 1) % vertexCnt;
    newIndex[i * 6 + 4] = i + vertexCnt;
    newIndex[i * 6 + 5] = (i + 1) % vertexCnt + vertexCnt;
  }

  let newGeometry: WompMeshData = {};
  newGeometry.face = newIndex;
  newGeometry.position = newPosition;

  if (thickness !== 0) {

    return offsetGeometry(
      newGeometry,
      thickness,
      {
        includeSource: true,
        includeTarget: true,
        includeSide: cap
      });

  } else {

    return newGeometry;

  }

}

export function extrudeGeometry(geometry: WompMeshData, direction: string, distance: number, cap: boolean) {
  let length = direction === "both" ? distance * 2 : distance;
  let offset = direction === "up" ? 0 : -distance;

  geometry = {...geometry};
  delete geometry['normal'];
  let merged = mergeGeometryVertices(geometry, 1e-6);
  applyGeometryTransform(merged, mat4.fromTranslation(mat4.create(), vec3.fromValues(0, 0, offset)));

  return offsetGeometry(
    merged,
    length,
    {
      includeSource: direction === "down" ? cap : true,
      includeTarget: direction === "down" ? true : cap,
      includeSide: true,
      direction: vec3.fromValues(0, 0, 1)
    });
}