import {svgLoader} from "./common";
import {vec3} from "gl-matrix";

export function parseSVG(data: string) {
  const parsed = svgLoader.parse(data);
  const paths = parsed.paths;
  let curves = [];

  for ( let i = 0; i < paths.length; i ++ ) {
    const path = paths[i];
    for (let subPath of path.subPaths) {
      let points: vec3[] = [];

      // console.log(subPath);

      for (let curve of subPath.curves) {
        if (!curve.isCubicBezierCurve) {
          console.warn('non-cubic bezier was found');
        }

        if (subPath.curves[0] === curve)
          points.push([curve.v0.x, curve.v0.y, 0]);

        points.push([curve.v1.x, curve.v1.y, 0]);
        points.push([curve.v2.x, curve.v2.y, 0]);
        points.push([curve.v3.x, curve.v3.y, 0]);
      }

      curves.push(points);
    }
  }

  return curves;
}
