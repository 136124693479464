import {
  FETCH_ORDERS,
  FETCH_ORDER,
  IOrdersState,
  ApiOrdersActionTypes,
  PUT_ORDER
} from '../../types/api/order';
import {INIT_STORE} from "../../types/init";

const initialState: IOrdersState = {
  orders: {
    fetching: false
  },
  post: {
    posting: false
  },
  order: {
    fetching: false
  },
};

export function apiOrdersReducer(
  state = initialState,
  action: ApiOrdersActionTypes
): IOrdersState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case FETCH_ORDER:
      return {
        ...state,
        order: {
          fetching: action.payload.fetching,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    case FETCH_ORDERS:
      return {
        ...state,
        orders: {
          fetching: action.payload.fetching,
          message: action.payload.message,
          items: action.payload.items
        }
      };
    case PUT_ORDER:
      return {
        ...state,
        post: {
          posting: action.payload.posting,
          message: action.payload.message,
          id: action.payload.id
        }
      };
    default:
      return state;
  }
}