import {IFetchAsync, IFetchItemsAsync, IPostAsync, IPostItemAsync} from "../types";
import {InitStoreAction} from "../init";

export const FETCH_WAITER = 'FETCH_WAITER';
export const POST_WAITER = 'POST_WAITER';
export const POST_WAITER_ACTION = 'POST_WAITER_ACTION';
export const FETCH_WAITERS = 'FETCH_WAITERS';

export interface IApiWaiterState {
  postWaiter: IPostItemAsync<number>
  fetchWaiter: IFetchAsync
  postWaiterAction: IPostAsync
  getWaiters: IFetchItemsAsync<number>
}

interface FetchWaiterAction {
  type: typeof FETCH_WAITER
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
  }
}

interface PostWaiterAction {
  type: typeof POST_WAITER
  payload: {
    posting: boolean
    success?: boolean
    message?: string
    id?: number
  }
}

interface PostWaiterActionAction {
  type: typeof POST_WAITER_ACTION
  payload: {
    posting: boolean
    success?: boolean
    message?: string
  }
}

interface FetchWaitersAction {
  type: typeof FETCH_WAITERS
  payload: {
    fetching: boolean
    success?: boolean
    message?: string
    items?: number[]
  }
}

export type ApiWaiterActionTypes = FetchWaiterAction |
  PostWaiterAction |
  PostWaiterActionAction |
  FetchWaitersAction |
  InitStoreAction;