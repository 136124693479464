import Mesh from './mesh/Mesh';
import MeshStatic from './mesh/meshStatic/MeshStatic';
import MeshDynamic from './mesh/dynamic/MeshDynamic';
import Utils from "./utils";
import SculptManager from "./editing/SculptManager";
import Picking from "./math3d/Picking";
import Enums from "./misc/Enums";
import Tablet from "./misc/Tablet";
import Remesh from "./editing/Remesh";
import HoleFilling from "./editing/HoleFilling";
import SceneProxy from "./SceneProxy";

export const sculpt = {
  Mesh: Mesh,
  MeshStatic: MeshStatic,
  MeshDynamic: MeshDynamic,
  SculptManager: SculptManager,
  Picking: Picking,
  Utils: Utils,
  Tablet: Tablet,
  Enums: Enums,
  Remesh: Remesh,
  HoleFilling: HoleFilling,
  SceneProxy: SceneProxy,
};