import {
  CHANGE_EMAIL_CONFIRMED_VISIBLE,
  CHANGE_PASSOWRD_RESET_VISIBLE,
  CHANGE_SIGN_IN_ERROR_VALUE,
  CHANGE_SIGN_IN_FIELD_VALUE,
  CHANGE_SIGN_IN_MESSAGE,
  ISignInState,
  SignInActionTypes
} from "../../types/ui/sign-in";
import {INIT_STORE} from "../../types/init";

const initialState: ISignInState = {
  fields: {
    'username_or_email': '',
    'password': '',
    'rememberMe': 'false'
  },
  errors: {},
  signInMessage: '',
  emailConfirmedVisible: false,
  passwordResetVisible: false,
};

export function signInReducer(
  state = initialState,
  action: SignInActionTypes
): ISignInState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case CHANGE_SIGN_IN_FIELD_VALUE:
      return {
        ...state,
        fields: Object.assign({},
          state.fields,
          {[action.payload.field]: action.payload.value}),
        // signIn: Object.assign({},
        //   state.signIn,
        //   {id: undefined})
      };
    case CHANGE_SIGN_IN_ERROR_VALUE:
      return {
        ...state,
        errors: Object.assign({},
          state.errors,
          {[action.payload.field]: action.payload.value})
      };
    case CHANGE_EMAIL_CONFIRMED_VISIBLE:
      return {
        ...state,
        emailConfirmedVisible: action.payload.visible
      };
    case CHANGE_PASSOWRD_RESET_VISIBLE:
      return {
        ...state,
        passwordResetVisible: action.payload.visible
      };
    case CHANGE_SIGN_IN_MESSAGE:
      return {
        ...state,
        signInMessage: action.payload.message
      };
    default:
      return state;
  }
}