import {
  CHANGE_EMAIL_CONFIRMED_VISIBLE,
  CHANGE_PASSOWRD_RESET_VISIBLE,
  CHANGE_SIGN_IN_ERROR_VALUE,
  CHANGE_SIGN_IN_FIELD_VALUE,
  CHANGE_SIGN_IN_MESSAGE,
  SignInActionTypes
} from "../../types/ui/sign-in";

export function changeSignInFieldValue(field: string, value: any): SignInActionTypes {
  return {
    type: CHANGE_SIGN_IN_FIELD_VALUE,
    payload: {
      field: field,
      value: value
    }
  };
}

export function changeSignInErrorValue(field: string, value: string): SignInActionTypes {
  return {
    type: CHANGE_SIGN_IN_ERROR_VALUE,
    payload: {
      field: field,
      value: value
    }
  };
}

export function changeSignInMessage(message: string): SignInActionTypes {
  return {
    type: CHANGE_SIGN_IN_MESSAGE,
    payload: {
      message
    }
  };
}

export function changeEmailConfirmedVisible(visible: boolean): SignInActionTypes {
  return {
    type: CHANGE_EMAIL_CONFIRMED_VISIBLE,
    payload: {
      visible: visible
    }
  };
}

export function changePasswordResetVisible(visible: boolean): SignInActionTypes {
  return {
    type: CHANGE_PASSOWRD_RESET_VISIBLE,
    payload: {
      visible: visible
    }
  };
}
