import {
  CHANGE_WIZARD_DIMENSION,
  CHANGE_WIZARD_MINIMIZED,
  CHANGE_WIZARD_SCREEN,
  CHANGE_WIZARD_TEXT,
  IWizardState,
  WizardActionTypes,
  WizardDefaultInfos,
  WizardScreen
} from "../../../types/ui/wizard";
import {INIT_STORE} from "../../../types/init";
import {initialInitialObjectState} from "./initial-object";
import {initialViewTypeState} from "./view-type";
import {initialCameraAngleState} from "./camera-angle";
import {initialGumballState} from "./gumball";
import {initialMirrorState} from "./mirror";
import {initialBackDropState} from "./back-drop";
import {initialDigitalMaterialState} from "./digital-material";
import {initialSculptState} from "./sculpt";
import {initialLightMapState} from "./light-map";
import {initialWeldState} from "./weld";
import {initialLargeFileState} from "./large-file";
import {initialSculptToolState} from "./sculpt-tool";
import lod from "lodash";
import {CHANGE_SCULPT_TOOL_WIZARD_DIMENSION} from "../../../types/ui/wizard/sculpt-tool";
import {CHANGE_BACK_DROP_WIZARD_ENVIRONMENT} from "../../../types/ui/wizard/back-drop";
import {CHANGE_LIGHT_MAP_WIZARD_ENVIRONMENT} from "../../../types/ui/wizard/light-map";
import {CHANGE_DIGITAL_MATERIAL_WIZARD_MATERIAL} from "../../../types/ui/wizard/digital-material";
import {CHANGE_SCULPT_WIZARD_CONFIG, CHANGE_SCULPT_WIZARD_SUB_SCREEN} from "../../../types/ui/wizard/sculpt";
import {initialArrayState} from "./array";
import {initialMagnetToolState} from "./magnet-tool";
import {CHANGE_MAGNET_TOOL_WIZARD_DIMENSION} from "../../../types/ui/wizard/magnet-tool";
import {INIT_SCENE} from "../../../types/ui/global";

const initialState: IWizardState = {
  screen: WizardScreen.None,
  text: '',
  placeholder: WizardDefaultInfos[WizardScreen.None].placeholder,
  minHeight: WizardDefaultInfos[WizardScreen.None].minHeight,
  minWidth: WizardDefaultInfos[WizardScreen.None].minWidth,
  height: WizardDefaultInfos[WizardScreen.None].minHeight,
  width: WizardDefaultInfos[WizardScreen.None].minWidth,
  minimized: false,
  x: 30,
  y: 100,
  [WizardScreen.InitialObject]: initialInitialObjectState,
  [WizardScreen.ViewType]: initialViewTypeState,
  [WizardScreen.CameraAngle]: initialCameraAngleState,
  [WizardScreen.Gumball]: initialGumballState,
  [WizardScreen.DigitalMaterial]: initialDigitalMaterialState,
  [WizardScreen.BackDrop]: initialBackDropState,
  [WizardScreen.LightMap]: initialLightMapState,
  [WizardScreen.Sculpt]: initialSculptState,
  [WizardScreen.Mirror]: initialMirrorState,
  [WizardScreen.Array]: initialArrayState,
  [WizardScreen.Weld]: initialWeldState,
  [WizardScreen.LargeFile]: initialLargeFileState,
  [WizardScreen.SculptTool]: initialSculptToolState,
  [WizardScreen.MagnetTool]: initialMagnetToolState
};

export function wizardReducer(
  state = initialState,
  action: WizardActionTypes
): IWizardState {
  switch (action.type) {
    case INIT_STORE:
      return initialState;
    case INIT_SCENE:
      return initialState;
    case CHANGE_WIZARD_SCREEN:
      let newState = {...state};
      let newScreen = action.payload.info.screen || state.screen;
      if (action.payload.initPrev)
        newState = {...newState, ...lod.pick(initialState, state.screen)};
      if (action.payload.info.screen && action.payload.initNext)
        newState = {...newState, ...lod.pick(initialState, action.payload.info.screen)};
      if (action.payload.screenState)
        newState = {...newState, [newScreen]: {...(newState as any)[newScreen], ...action.payload.screenState}};
      if ((state.screen === WizardScreen.Share || state.screen === WizardScreen.Post) && !(newScreen === WizardScreen.Share || newScreen === WizardScreen.Post))
        newState = {...newState, x: 30, y: 100};
      if (newScreen !== WizardScreen.None)
        newState = {...newState, minimized: false};

      return {
        ...newState,
        ...action.payload.info,
        placeholder: action.payload.info.placeholder ? action.payload.info.placeholder : WizardDefaultInfos[newScreen].placeholder,
        minHeight: action.payload.info.minHeight ? action.payload.info.minHeight : WizardDefaultInfos[newScreen].minHeight,
        minWidth: action.payload.info.minWidth ? action.payload.info.minWidth : WizardDefaultInfos[newScreen].minWidth,
        height: action.payload.info.minHeight ? action.payload.info.minHeight : WizardDefaultInfos[newScreen].minHeight,
        width: action.payload.info.minWidth ? action.payload.info.minWidth : WizardDefaultInfos[newScreen].minWidth
      };
    case CHANGE_WIZARD_DIMENSION:
      return {
        ...state,
        ...action.payload
      };
    case CHANGE_WIZARD_MINIMIZED:
      return {
        ...state,
        minimized: action.payload.minimized
      };
    case CHANGE_WIZARD_TEXT:
      return {
        ...state,
        ...action.payload
      };
    case CHANGE_SCULPT_TOOL_WIZARD_DIMENSION:
      return {
        ...state,
        [WizardScreen.SculptTool]: {
          ...state[WizardScreen.SculptTool],
          ...action.payload
        }
      };
    case CHANGE_MAGNET_TOOL_WIZARD_DIMENSION:
      return {
        ...state,
        [WizardScreen.MagnetTool]: {
          ...state[WizardScreen.MagnetTool],
          ...action.payload
        }
      };
    case CHANGE_BACK_DROP_WIZARD_ENVIRONMENT:
      return {
        ...state,
        [WizardScreen.BackDrop]: {
          ...state[WizardScreen.BackDrop],
          ...action.payload
        }
      };
    case CHANGE_LIGHT_MAP_WIZARD_ENVIRONMENT:
      return {
        ...state,
        [WizardScreen.LightMap]: {
          ...state[WizardScreen.LightMap],
          ...action.payload
        }
      };
    case CHANGE_DIGITAL_MATERIAL_WIZARD_MATERIAL:
      return {
        ...state,
        [WizardScreen.DigitalMaterial]: {
          ...state[WizardScreen.DigitalMaterial],
          ...action.payload
        }
      };
    case CHANGE_SCULPT_WIZARD_SUB_SCREEN:
      return {
        ...state,
        [WizardScreen.Sculpt]: {
          ...state[WizardScreen.Sculpt],
          subScreen: action.payload.subScreen
        }
      };
    case CHANGE_SCULPT_WIZARD_CONFIG:
      return {
        ...state,
        [WizardScreen.Sculpt]: {
          ...state[WizardScreen.Sculpt],
          config: lod.merge(lod.cloneDeep(state[WizardScreen.Sculpt].config), action.payload.config)
        }
      };
    default:
      return state;
  }
}