import {BufferGeometry, FileLoader, Float32BufferAttribute, Loader, Uint32BufferAttribute} from "three";


var OFFLoader = function (manager) {

  Loader.call(this, manager);

};

OFFLoader.prototype = Object.assign(Object.create(Loader.prototype), {

  constructor: OFFLoader,

  load: function (url, onLoad, onProgress, onError) {

    var scope = this;

    var loader = new FileLoader(scope.manager);
    loader.setPath(scope.path);
    loader.setResponseType('arraybuffer');
    loader.load(url, function (text) {

      try {

        onLoad(scope.parse(text));

      } catch (exception) {

        if (onError) {

          onError(exception);

        }

      }

    }, onProgress, onError);

  },

  parse: function (data) {

    var geometry = new BufferGeometry();
    var faceCount = 0, vertexCount = 0;

    var vertices = [];
    var index = [];

    var lines = data.split(/\r?\n/);

    if (lines[0] !== 'OFF') {

      console.error('THREE.OFFLoader: Something isn\'t right with the format');

    }

    if (lines[1] === undefined) {

      return geometry;

    }

    var lengthTokens = lines[1].split(/\s/);

    if (lengthTokens.length > 2) {

      vertexCount = parseFloat(lengthTokens[0]);
      faceCount = parseFloat(lengthTokens[1]);

    }

    for (var i = 0; i < vertexCount; ++i) {
      var tokens = lines[2 + i].split(/\s/);

      vertices.push(parseFloat(tokens[0]), parseFloat(tokens[1]), parseFloat(tokens[2]));
    }

    for (var i = 0; i < faceCount; ++i) {
      var tokens = lines[2 + i + vertexCount].split(/\s/);
      var degree = parseInt(tokens[0]);

      for (var j = 2; j < degree; ++j) {
        index.push(parseInt(tokens[1]), parseInt(tokens[j]), parseInt(tokens[j + 1]));
      }
    }

    geometry.attributes.position = new Float32BufferAttribute(vertices, 3);
    geometry.index = new Uint32BufferAttribute(index, 1);

    return geometry;

  }

});

export {OFFLoader};
