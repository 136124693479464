import {BufferGeometry, Color, Float32BufferAttribute, LineBasicMaterial, LineSegments, VertexColors} from 'three';

function GridHelper(height, width, heightStep, widthStep, thickStep, color1, color2, color3, opacity) {

  height = height || 10;
  width = width || 10;
  heightStep = heightStep || 1;
  widthStep = widthStep || 1;
  opacity = Math.min(Math.max(opacity, 0), 1);
  color1 = new Color(color1 !== undefined ? color1 : 0x444444);
  color2 = new Color(color2 !== undefined ? color2 : 0x888888);
  color3 = new Color(color3 !== undefined ? color3 : 0xffffff);

  let heightCenter = Math.ceil(height / (heightStep * 2));
  let heightDivisions = heightCenter * 2;
  let gridHalfHeight = heightCenter * heightStep;
  let halfHeight = height / 2;

  let widthCenter = Math.ceil(width / (widthStep * 2));
  let widthDivisions = widthCenter * 2;
  let gridHalfWidth = widthCenter * widthStep;
  let halfWidth = width / 2;

  let vertices = [], colors = [];

  let j = 0;

  function fillColor(i, divisions, center) {
    let color = i % center === 0 ? color1 : (Math.abs(i - center) % thickStep === 0 ? color2 : color3);

    color.toArray(colors, j);
    j += 3;
    color.toArray(colors, j);
    j += 3;
  }

  for (let i = 0, k = -gridHalfHeight; i <= heightDivisions; i++, k += heightStep) {

    let rK = Math.max(Math.min(k, halfHeight), -halfHeight);
    vertices.push(rK, 0, -halfWidth, rK, 0, halfWidth);
    fillColor(i, heightDivisions, heightCenter);

  }

  for (let i = 0, k = -gridHalfWidth; i <= widthDivisions; i++, k += widthStep) {

    let rK = Math.max(Math.min(k, halfWidth), -halfWidth);
    vertices.push(-halfHeight, 0, rK, halfHeight, 0, rK);
    fillColor(i, widthDivisions, widthCenter);

  }

  let geometry = new BufferGeometry();
  geometry.attributes.position = new Float32BufferAttribute(vertices, 3);
  geometry.attributes.color = new Float32BufferAttribute(colors, 3);

  let material = new LineBasicMaterial({vertexColors: VertexColors, opacity: opacity, transparent: true});

  LineSegments.call(this, geometry, material);

}

GridHelper.prototype = Object.assign(Object.create(LineSegments.prototype), {

  constructor: GridHelper,

  copy: function (source) {

    LineSegments.prototype.copy.call(this, source);

    this.geometry.copy(source.geometry);
    this.material.copy(source.material);

    return this;

  },

  clone: function () {

    return new this.constructor().copy(this);

  }

});

export {GridHelper};
