import {Matrix4, Mesh, MeshBasicMaterial, SphereBufferGeometry} from 'three';
import {NLightHelperPicker} from './NLightHelperPicker';

function NPointLightHelper(light, sphereSize, lightId, camera, hoverStartEvent, hoverStopEvent, updateCallback) {

  this.light = light;
  this.light.updateMatrixWorld();

  this.lightId = lightId;
  this.updateCallback = updateCallback;

  var geometry = new SphereBufferGeometry(sphereSize, 4, 2);
  var material = new MeshBasicMaterial({wireframe: true, fog: false});

  Mesh.call(this, geometry, material);

  this.matrix = this.light.matrixWorld;
  this.matrixAutoUpdate = false;

  this.positionPicker = new NLightHelperPicker(lightId + ':position', camera, hoverStartEvent, hoverStopEvent, this.onChangeLightPosition.bind(this));
  this.add(this.positionPicker);

  this.update();
}

NPointLightHelper.prototype = Object.create(Mesh.prototype);
NPointLightHelper.prototype.constructor = NPointLightHelper;

NPointLightHelper.prototype.dispose = function () {

  this.geometry.dispose();
  this.material.dispose();

  this.positionPicker.dispose();
};

NPointLightHelper.prototype.setDelegate = function (delegate) {

  this.positionPicker.setDelegate(delegate);

};

NPointLightHelper.prototype.unsetDelegate = function () {

  this.positionPicker.unsetDelegate();

};

NPointLightHelper.prototype.setCamera = function (camera) {

  this.positionPicker.camera = camera;

};

NPointLightHelper.prototype.onChangeLightPosition = function (x, y, z) {
  this.updateCallback(this.lightId, {
    position: [
      x + this.light.position.x,
      y + this.light.position.y,
      z + this.light.position.z
    ]
  });
};

NPointLightHelper.prototype.update = function () {
  this.material.color.copy(this.light.color);

  this.light.updateMatrixWorld();

  this.matrix = this.light.matrixWorld;
  this.matrixAutoUpdate = false;

  this.positionPicker.sprite.matrixAutoUpdate = true;
  new Matrix4().decompose(this.positionPicker.sprite.position, this.positionPicker.sprite.quaternion, this.positionPicker.sprite.scale);
};


export {NPointLightHelper};