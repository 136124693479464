import {CurveCodec} from "../types";
import numeral from "numeral";
import {decodeBuffer, encodeBuffer} from "./common";
import {WompCurveData} from "../WompObject";

export function encodeCurveGeometryCodecTrans(geom: WompCurveData) {
  return new Float32Array([
    geom.degree || 1,
    geom.periodic ? 1 : 0,
    geom.bezier ? 1 : 0,
    ...(geom.points || []).flatMap(p => p)
  ]).buffer;
}

export function decodeCurveGeometryCodecTrans(buffer: ArrayBuffer) {
  let data = new Float32Array(buffer);
  const off = 3;
  let points: number[][] = new Array(Math.max(0, (data.length - off) / 3));

  for (let i = 0; i < (data.length - off) / 3; ++i)
    points[i] = [data[i * 3 + off], data[i * 3 + off + 1], data[i * 3 + off + 2]];

  return {
    degree: data[0] || 1,
    periodic: !!data[1],
    bezier: !!data[2],
    points
  }
}

export async function encodeCurveGeometry(geom: WompCurveData, format: CurveCodec, outputJson: boolean = true) {
  let t0 = performance.now();

  switch (format) {
    case CurveCodec.Zip: {
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for zip encode`);

      let buffer = encodeCurveGeometryCodecTrans(geom);
      return [format, outputJson ? encodeBuffer(buffer) : buffer];
    }
    case CurveCodec.Trans:
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for trans encode`);

      return [format, encodeCurveGeometryCodecTrans(geom)];
  }

  return [format, new ArrayBuffer(0)];
}

export async function decodeCurveGeometry(data: any) {
  let t0 = performance.now();
  switch (data[0]) {
    case CurveCodec.Zip: {
      let ret = typeof data[1] === 'string' ? decodeBuffer(data[1]) : data[1];
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for zip decode`);

      return decodeCurveGeometryCodecTrans(ret);
    }
    case CurveCodec.Trans: {
      console.log(`[${numeral(performance.now() - t0).format('000000,0.0')}]ms took for trans decode`);

      return decodeCurveGeometryCodecTrans(data[1]);
    }
  }
  return {};
}

