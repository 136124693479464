import React, {createRef} from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {history, IAppState} from './store';
import {ThunkDispatch} from "redux-thunk";
import {AnyAction} from "redux";
import {connect} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";
import {toast} from "react-toastify";
import PageNotFound from "./app/view/PageNotFound";
import {fetchConfiguration} from "./app/action/config";
import {changePeregrineEngineLoaded} from "./store/actions/entity/global";
import {loadPeregrine} from "./init";
import {DndProvider} from "react-dnd";
import HTML5Backend from 'react-dnd-html5-backend';
import ReactModal from "react-modal";
import ReactResizeDetector from 'react-resize-detector';
import {changeScreenSize} from "./store/actions/ui/global";
import {IScreenSize} from "./store/types/ui/global";
import Hero2 from "./app/view/front/Hero2";
import Div100vh from 'react-div-100vh';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-tooltip/assets/bootstrap.css';
import Editor3dWrapper from "./app/3d/Editor3dWrapper";
import {CursorContextProvider} from "./app/component/common/cursor/CursorContextProvider";
import {HERO_EYE_URL, HERO_FRONT_URL} from "./app/common/const";
import About from "./app/view/front/About";

toast.configure({
  position: "top-center",
  hideProgressBar: true,
  enableMultiContainer: true,
  closeOnClick: true,
  draggable: false,
  containerId: 'default'
});

ReactModal.setAppElement('#root');

interface IAppProps {
  engineLoaded: boolean
  screenWidth: number

  locationPathName: string

  fetchConfiguration: () => void
  changePeregrineEngineLoaded: (loaded: boolean) => void
  changeScreenSize: (size: IScreenSize) => void
}

class App extends React.PureComponent<IAppProps> {
  pageContentRef: any = createRef();
  editorWrapper: any;

  constructor(props: IAppProps) {
    super(props);
    loadPeregrine(this.engineLoaded);

    this.onLocationChanged(this.props.locationPathName, '');
  }

  componentDidMount() {
    // let {fetchConfiguration} = this.props;
    // fetchConfiguration();
  }

  componentDidUpdate(prevProps: IAppProps) {
    const {locationPathName} = this.props;

    if (locationPathName !== prevProps.locationPathName) {
      this.onLocationChanged(locationPathName, prevProps.locationPathName);
    }
  }

  componentWillUnmount() {
    this.editorWrapper.editor && this.editorWrapper.editor.dispose();
    this.editorWrapper.editor = undefined;
  }

  onLocationChanged = (location: string, prevLocation: string) => {
    let contentDiv = document.getElementById('pageContent');
    if (contentDiv) {
      contentDiv.scrollTop = 0;
    }
  };

  engineLoaded = (success: boolean) => {
    console.log('engine loaded', success);
    this.props.changePeregrineEngineLoaded(success);
  };

  onPageContentContextMenu = (e: any) => {
    e.preventDefault();
  };

  onResize = (width: number, height: number) => {
    this.props.changeScreenSize({width, height});
  };

  renderHero2 = (props: any) => {
    if (this.editorWrapper)
      return <Hero2 {...props} page="hero" editorWrapper={this.editorWrapper}/>;
  };

  renderMain = (props: any) => {
    if (this.editorWrapper)
      return <Hero2 {...props} page="main" editorWrapper={this.editorWrapper}/>;
  };

  renderAbout = (props: any) => {
    if (this.editorWrapper)
      return <About {...props} editorWrapper={this.editorWrapper}/>;
  };

  render() {
    const {locationPathName} = this.props;

    return (
      <CursorContextProvider>
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.onResize}
          targetRef={this.pageContentRef}
        >
          <div ref={this.pageContentRef}>
            <Div100vh
              id="pageContent"
              className="page-content"
              onContextMenu={this.onPageContentContextMenu}
              onTouchStart={(evt) => false}
              onTouchMove={(evt) => false}
            >
              <DndProvider backend={HTML5Backend}>
                <Editor3dWrapper
                  elemId="app-editor"
                  minorView={locationPathName.startsWith('/create/')}
                  cubeView={true} //{locationPathName.startsWith('/create/') || locationPathName.startsWith('/discover/') || locationPathName.startsWith('/shared/')}
                  ref={ref => {
                    if (ref !== null) {
                      this.editorWrapper = ref;
                    }
                  }}
                />
                <ConnectedRouter history={history}>
                  <Switch>
                    <Route exact path={HERO_EYE_URL} render={this.renderHero2}/>
                    <Route exact path={HERO_FRONT_URL} render={this.renderMain}/>
                    <Route exact path='/about' render={this.renderAbout}/>
                    <Route component={PageNotFound}/>
                  </Switch>
                </ConnectedRouter>
              </DndProvider>
            </Div100vh>
          </div>
        </ReactResizeDetector>
      </CursorContextProvider>
    );
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
    engineLoaded: store.entities.global.engineLoaded,
    screenWidth: store.ui.global.screenSize.width,

    locationPathName: store.router.location.pathname
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  return {
    fetchConfiguration: () => dispatch(fetchConfiguration()),
    changePeregrineEngineLoaded: (loaded: boolean) => dispatch(changePeregrineEngineLoaded(loaded)),
    changeScreenSize: (size: IScreenSize) => dispatch(changeScreenSize(size))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);