import {IChat, NormalizedObjects} from "../types";
import {InitStoreAction} from "../init";

export const REPLACE_CHATS = 'REPLACE_CHATS';
export const ADD_CHATS = 'ADD_CHATS';
export const REMOVE_CHAT = 'REMOVE_CHAT';

export interface IEntityChatsState extends NormalizedObjects<IChat> {
}

interface ReplaceChatsAction {
  type: typeof REPLACE_CHATS
  payload: {
    chats: IChat[]
  }
}

interface AddChatsAction {
  type: typeof ADD_CHATS
  payload: {
    chats: IChat[]
  }
}

interface RemoveChatAction {
  type: typeof REMOVE_CHAT
  payload: {
    id: number
  }
}

export type ChatsActionTypes = ReplaceChatsAction |
  AddChatsAction |
  RemoveChatAction |
  InitStoreAction