import {
  DELETE_PROJECT,
  ApiProjectActionTypes,
  FETCH_PROJECTS,
  DUPLICATE_PROJECT,
  DOWNLOAD_PROJECT, FETCH_PROJECT_LOAD, POST_PROJECT_SAVE, POST_PROJECT, CREATE_PROJECT, FETCH_SHARED_PROJECT
} from '../../types/api/project';

export function fetchProjectsStarted(): ApiProjectActionTypes {
  return {
    type: FETCH_PROJECTS,
    payload: {
      fetching: true
    }
  };
}

export function fetchProjectsSuccess(items: number[]): ApiProjectActionTypes {
  return {
    type: FETCH_PROJECTS,
    payload: {
      fetching: false,
      success: true,
      items: items
    }
  };
}

export function fetchProjectsFailure(message: string): ApiProjectActionTypes {
  return {
    type: FETCH_PROJECTS,
    payload: {
      fetching: false,
      success: false,
      message,
      items: []
    }
  };
}

export function deleteProjectStarted(id: number): ApiProjectActionTypes {
  return {
    type: DELETE_PROJECT,
    payload: {
      deleting: true,
      id
    }
  };
}

export function deleteProjectSuccess(id: number): ApiProjectActionTypes {
  return {
    type: DELETE_PROJECT,
    payload: {
      deleting: false,
      success: true,
      id
    }
  };
}

export function deleteProjectFailure(id: number, message: string): ApiProjectActionTypes {
  return {
    type: DELETE_PROJECT,
    payload: {
      deleting: false,
      success: false,
      message,
      id
    }
  };
}

export function duplicateProjectStarted(id: number): ApiProjectActionTypes {
  return {
    type: DUPLICATE_PROJECT,
    payload: {
      posting: true,
      id,
    }
  };
}

export function duplicateProjectSuccess(id: number): ApiProjectActionTypes {
  return {
    type: DUPLICATE_PROJECT,
    payload: {
      posting: false,
      success: true,
      id
    }
  };
}

export function duplicateProjectFailure(id: number, message: string): ApiProjectActionTypes {
  return {
    type: DUPLICATE_PROJECT,
    payload: {
      posting: false,
      success: false,
      message,
      id
    }
  };
}

export function downloadProjectStarted(id: number): ApiProjectActionTypes {
  return {
    type: DOWNLOAD_PROJECT,
    payload: {
      posting: true,
      id,
    }
  };
}

export function downloadProjectSuccess(id: number): ApiProjectActionTypes {
  return {
    type: DOWNLOAD_PROJECT,
    payload: {
      posting: false,
      success: true,
      id
    }
  };
}

export function downloadProjectFailure(id: number, message: string): ApiProjectActionTypes {
  return {
    type: DOWNLOAD_PROJECT,
    payload: {
      posting: false,
      success: false,
      message,
      id
    }
  };
}

export function fetchProjectLoadStarted(id: number, message?: string, extraInfo?: any): ApiProjectActionTypes {
  return {
    type: FETCH_PROJECT_LOAD,
    payload: {
      fetching: true,
      id,
      message,
      extraInfo
    }
  };
}

export function fetchProjectLoadSuccess(id: number, message?: string): ApiProjectActionTypes {
  return {
    type: FETCH_PROJECT_LOAD,
    payload: {
      fetching: false,
      success: true,
      id,
      message
    }
  };
}

export function fetchProjectLoadFailure(id: number, message: string): ApiProjectActionTypes {
  return {
    type: FETCH_PROJECT_LOAD,
    payload: {
      fetching: false,
      success: false,
      id,
      message
    }
  };
}

export function fetchSharedProjectStarted(id: number): ApiProjectActionTypes {
  return {
    type: FETCH_SHARED_PROJECT,
    payload: {
      fetching: true,
      id
    }
  };
}

export function fetchSharedProjectSuccess(id: number): ApiProjectActionTypes {
  return {
    type: FETCH_SHARED_PROJECT,
    payload: {
      fetching: false,
      success: true,
      id
    }
  };
}

export function fetchSharedProjectFailure(message: string): ApiProjectActionTypes {
  return {
    type: FETCH_SHARED_PROJECT,
    payload: {
      fetching: false,
      success: false,
      message
    }
  };
}

export function postProjectSaveStarted(id: number, message?: string, extraInfo?: any): ApiProjectActionTypes {
  return {
    type: POST_PROJECT_SAVE,
    payload: {
      posting: true,
      id,
      message,
      extraInfo
    }
  };
}

export function postProjectSaveSuccess(id: number): ApiProjectActionTypes {
  return {
    type: POST_PROJECT_SAVE,
    payload: {
      posting: false,
      success: true,
      id
    }
  };
}

export function postProjectSaveFailure(id: number, message: string): ApiProjectActionTypes {
  return {
    type: POST_PROJECT_SAVE,
    payload: {
      posting: false,
      success: false,
      id,
      message
    }
  };
}

export function postProjectStarted(id: number): ApiProjectActionTypes {
  return {
    type: POST_PROJECT,
    payload: {
      posting: true,
      id
    }
  };
}

export function postProjectSuccess(id: number): ApiProjectActionTypes {
  return {
    type: POST_PROJECT,
    payload: {
      posting: false,
      success: true,
      id
    }
  };
}

export function postProjectFailure(message: string): ApiProjectActionTypes {
  return {
    type: POST_PROJECT,
    payload: {
      posting: false,
      success: false,
      message: message
    }
  };
}

export function createProjectStarted(): ApiProjectActionTypes {
  return {
    type: CREATE_PROJECT,
    payload: {
      posting: true
    }
  };
}

export function createProjectSuccess(id: number): ApiProjectActionTypes {
  return {
    type: CREATE_PROJECT,
    payload: {
      posting: false,
      success: true,
      id
    }
  };
}

export function createProjectFailure(message: string): ApiProjectActionTypes {
  return {
    type: CREATE_PROJECT,
    payload: {
      posting: false,
      success: false,
      message: message
    }
  };
}