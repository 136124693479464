import {BufferGeometryUtils, sculpt as sculptTypes, three as threeTypes} from "../types";
import {WompMeshData, WompMesh} from "../WompObject";
import {mat4} from "gl-matrix";
import {getMat4FromThreeTransform, getThreeTransformFromMat4} from "./three";

export function createMesh(faces: Uint32Array, vertices: Float32Array, maskings?: Float32Array): sculptTypes.Mesh {
  var newMesh = new sculptTypes.MeshStatic();
  newMesh.setFaces(faces);
  newMesh.setVertices(vertices);
  if (maskings)
    newMesh.setMaskings(maskings);

  newMesh.init();
  return newMesh;
}

export function getMeshFromSculptMesh(sculptMesh: sculptTypes.Mesh, matrix?: mat4): WompMesh | undefined {
  if (!sculptMesh)
    return;

  let t0 = performance.now();
  let mesh = new WompMesh();

  mesh.geometry = getGeometryFromSculptMesh(sculptMesh);

  if (matrix) {
    mesh.matrix = matrix;
  } else {
    mesh.matrix = sculptMesh.getMatrix();
  }

  console.log("sculpt mesh -> mesh " + (performance.now() - t0).toFixed(1) + " milliseconds.");
  return mesh;
}

export function getThreeGeometryFromSculptMesh(sculptMesh: sculptTypes.Mesh): threeTypes.BufferGeometry | undefined {
  if (!sculptMesh)
    return;

  let t0 = performance.now();
  let mesh = new threeTypes.BufferGeometry();

  mesh.index = new threeTypes.BufferAttribute(sculptMesh.getFaces(), 1);
  mesh.attributes.position = new threeTypes.BufferAttribute(sculptMesh.getVertices(), 3);
  mesh.attributes.normal = new threeTypes.BufferAttribute(sculptMesh.getNormals(), 3);
  if (sculptMesh.getMaskings())
    mesh.attributes.masking = new threeTypes.BufferAttribute(sculptMesh.getMaskings(), 1);

  console.log("sculpt mesh -> three mesh " + (performance.now() - t0).toFixed(1) + " milliseconds.");
  return mesh;
}

export function getSculptMeshFromThreeMesh(geometry: threeTypes.BufferGeometry, matrix?: threeTypes.Matrix4, noMerge?: boolean, isDynamic?: boolean): sculptTypes.Mesh | undefined {
  if (!geometry)
    return;

  let t0 = performance.now();
  let mergedGeometry;

  if (noMerge) {
    mergedGeometry = geometry;
  } else {
    let newGeometry = geometry.clone();
    newGeometry.groups = [];
    newGeometry.deleteAttribute('uv');
    newGeometry.deleteAttribute('normal');
    newGeometry.toNonIndexed();
    mergedGeometry = BufferGeometryUtils.mergeVertices(newGeometry, 1e-4);
  }

  if (mergedGeometry.index) {
    let sculptMesh = createMesh(
      mergedGeometry.index.array as Uint32Array,
      mergedGeometry.attributes.position.array as Float32Array,
      mergedGeometry.attributes.masking ? mergedGeometry.attributes.masking.array as Float32Array : undefined
    );

    if (matrix) {
      sculptMesh.setMatrix(getMat4FromThreeTransform(matrix));
    }

    if (isDynamic) {
      sculptMesh = new sculptTypes.MeshDynamic(sculptMesh);
    }

    console.log("three mesh -> sculpt mesh " + (performance.now() - t0).toFixed(1) + " milliseconds.");

    return sculptMesh;
  }
}

export function getGeometryFromSculptGeometry(sculptGeometry: sculptTypes.MeshData): WompMeshData {
  let geometry: WompMeshData = {};

  geometry.face = sculptGeometry._facesABC;
  geometry.position = sculptGeometry._verticesXYZ;
  geometry.normal = sculptGeometry._normalsXYZ;
  if (sculptGeometry._masking)
    geometry.masking = sculptGeometry._masking;

  return geometry;
}

export function getGeometryFromSculptRenderGeometry(sculptRenderGeometry: sculptTypes.RenderData): WompMeshData {
  let geometry: WompMeshData = {};

  geometry.face = sculptRenderGeometry._indexBuffer;
  geometry.position = sculptRenderGeometry._vertexBuffer;
  geometry.normal = sculptRenderGeometry._normalBuffer;
  if (sculptRenderGeometry._maskingBuffer)
    geometry.masking = sculptRenderGeometry._maskingBuffer;

  return geometry;
}

export function getGeometryFromSculptMesh(sculptMesh: sculptTypes.Mesh): WompMeshData {
  let geometry: WompMeshData = {};

  if (sculptMesh.getRenderData()._indexBuffer && sculptMesh.getRenderData()._indexBuffer.length > 0) {
    geometry = getGeometryFromSculptRenderGeometry(sculptMesh.getRenderData());
  } else {
    geometry = getGeometryFromSculptGeometry(sculptMesh.getMeshData());
  }


  return geometry;
}

export function getSculptGeometryFromGeometry(geometry: WompMeshData): sculptTypes.MeshData {
  let sculptGeometry: sculptTypes.MeshData = {};

  sculptGeometry._facesABC = geometry.face;
  sculptGeometry._verticesXYZ = geometry.position;
  sculptGeometry._normalsXYZ = geometry.normal;
  if (geometry.masking)
    sculptGeometry._masking = geometry.masking;

  return sculptGeometry;
}

export function getThreeTransformFromSculptMesh(sculptMesh: sculptTypes.Mesh): threeTypes.Matrix4 | undefined {
  if (!sculptMesh)
    return;

  let elements = sculptMesh.getMatrix();

  return getThreeTransformFromMat4(elements);
}

// export function getSculptGeometryFromThreeGeometry(threeGeom: threeTypes.BufferGeometry): sculptTypes.MeshData {
//   let sculptGeom: sculptTypes.MeshData = {};
//
//   if (threeGeom.index)
//     sculptGeom._facesABC = threeGeom.index.array as Uint32Array;
//
//   if (threeGeom.attributes.position)
//     sculptGeom._verticesXYZ = threeGeom.attributes.position.array as Float32Array;
//
//   if (threeGeom.attributes.normal)
//     sculptGeom._normalsXYZ = threeGeom.attributes.normal.array as Float32Array;
//
//   if (threeGeom.attributes.color)
//     sculptGeom._masking = threeGeom.attributes.color.array as Float32Array;
//
//   return sculptGeom;
// }
//
// export function getThreeGeometryFromSculptGeometry(sculptGeom: sculptTypes.MeshData): threeTypes.BufferGeometry {
//   let threeGeom = new threeTypes.BufferGeometry();
//
//   if (sculptGeom._facesABC)
//     threeGeom.index = new threeTypes.BufferAttribute(sculptGeom._facesABC, 3);
//
//   if (sculptGeom._verticesXYZ)
//     threeGeom.attributes.position = new threeTypes.BufferAttribute(sculptGeom._verticesXYZ, 3);
//
//   if (sculptGeom._normalsXYZ)
//     threeGeom.attributes.normal = new threeTypes.BufferAttribute(sculptGeom._normalsXYZ, 3);
//
//   if (sculptGeom._masking)
//     threeGeom.attributes.color = new threeTypes.BufferAttribute(sculptGeom._masking, 3);
//
//   return threeGeom;
// }

export function getSculptMeshFromMesh(mesh: WompMesh, dynamic?: boolean): sculptTypes.Mesh | undefined {
  if (mesh.geometry.face && mesh.geometry.position) {
    let sculptMesh = createMesh(mesh.geometry.face, mesh.geometry.position, mesh.geometry.masking);
    sculptMesh.setMatrix(mat4.clone(mesh.matrix));

    if (dynamic) sculptMesh = new sculptTypes.MeshDynamic(sculptMesh);
    return sculptMesh;
  }
}